import React, { useContext, useEffect, useState } from 'react'

import { Company, UserCompany } from 'src/core/models'
import { CompanyAdminContext, UserContext } from 'src/core/providers'

import ArkConfirmModal from 'src/core/components/ArkConfirmModal'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkRadio, { ArkRadioProps } from 'src/core/components/ArkRadio'

import { OBJECT_COMPANY_NAME } from 'src/constants/strings'

import styles from './CompanySettingsView.module.css'

export interface Company2FASettingsViewProps {
  company: Company | UserCompany
}

const Company2FASettingsView = (props: Company2FASettingsViewProps) => {
  const { company } = props

  const userContext = useContext(UserContext)
  const companyAdminContext = useContext(CompanyAdminContext)

  const isSiteAdmin = userContext.actions.isSiteAdmin()

  const [showConfirmAlert, setShowConfirmAlert] = useState<boolean>(false)

  const [saving, setSaving] = useState<boolean>(false)
  const [saveResult, setSaveResult] = useState<boolean>(false)
  const [saveError, setSaveError] = useState<Error | undefined>(undefined)

  // individual settings specific to this form
  const [companyValues, setCompanyValues] = useState({
    force2fa: company.force2fa ?? false
  })
  const [companyValuesSaved, setCompanyValuesSaved] = useState({
    force2fa: company.force2fa ?? false
  })
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])

  // -------

  const companySettingsChanges = () => {
    const _changes: Array<string> = []
    if (companyValues) {
      for (const fieldName of Object.keys(companyValues)) {
        const oldValue = companyValuesSaved !== undefined ? (companyValuesSaved as any)[fieldName] : undefined
        const newValue = (companyValues as any)[fieldName]
        if (oldValue !== newValue) {
          _changes.push(fieldName)
        }
      }
    }
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  const resetSaveResults = () => {
    setSaveResult(false)
    setSaveError(undefined)
  }

  const toggleElement = (valueKey: string, siteAdminOnly: boolean = false) => {
    const value = (companyValues as any)[valueKey]
    if (siteAdminOnly && !isSiteAdmin) return (value ? 'ENABLED' : <span className={styles.propertyValueOff}>DISABLED</span>) // just display the value if the user isn't a site admin or higher
    const disabled = saving
    return (
      <ArkRadio
        toggle
        checked={value}
        disabled={disabled}
        onChange={(event: React.FormEvent<HTMLInputElement>, data: ArkRadioProps) => {
          console.log('Company2FASettingsView - toggleElement - onChange - valueKey: ', valueKey, ' data.checked: ', data.checked)
          setCompanyValues({
            ...companyValues,
            [valueKey]: data.checked
          })
          resetSaveResults()
          // NB: see the useEffect that runs settingsChangeCount & updates setProgramChangeCount from it after the setValue state update completes
          // TESTING: auto prompt the user to confirm the change as soon as its made (no submit button)
          const savedValue = (companyValuesSaved as any)[valueKey]
          if (data.checked !== savedValue) {
            setShowConfirmAlert(true)
          }
        }}
      />
    )
  }

  // -------

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = companySettingsChanges()
    setChanges(_changes)
  }, [companyValues])

  // -------

  const onCancel = () => {
    console.log('Company2FASettingsView - onCancel')
  }

  const onClose = () => {
    console.log('Company2FASettingsView - onClose - saving: ', saving)
    setShowConfirmAlert(false)
    if (!saving) setCompanyValues(companyValuesSaved) // reset the form back to the saved values
  }

  const onSubmit = async () => {
    console.log('Company2FASettingsView - onSubmit')
    if (saving) return
    setSaving(true)
    resetSaveResults()

    // // DEBUG ONLY: mock submit...
    // const mockSuccess = async () => {
    //   await new Promise((resolve) => setTimeout(resolve, 2000)) // DBG ONLY
    //   const force2faNewVal = !companyValuesSaved.force2fa
    //   setCompanyValuesSaved({ force2fa: force2faNewVal })
    //   setCompanyValues({ force2fa: force2faNewVal })
    //   setSaveResult(true)
    // }
    // const mockError = async () => {
    //   await new Promise((resolve) => setTimeout(resolve, 2000)) // DBG ONLY
    //   setSaveError(new Error('DEBUG ERROR'))
    //   setSaveResult(false)
    //   setCompanyValues(companyValuesSaved) // reset the form back to the saved values
    //   setChanges([])
    // }
    // // await mockSuccess()
    // await mockError()

    try {
      const updateResult = await companyAdminContext.actions.updateCompanyInfo(company.id, { force2fa: companyValues.force2fa })
      console.log('Company2FASettingsView - onSubmit - updateResult: ', updateResult)
      if (updateResult.company) {
        const newCompanyValuesSaved = { force2fa: updateResult.company?.force2fa }
        setCompanyValuesSaved(newCompanyValuesSaved)
        setCompanyValues(newCompanyValuesSaved) // reset the form back to the saved values
        setChanges([])
        setSaveResult(true)
      } else {
        throw new Error('Invalid response')
      }
    } catch (error) {
      setSaveResult(false)
      setSaveError(error)
      setCompanyValues(companyValuesSaved) // reset the form back to the saved values
      setChanges([])
    }

    setSaving(false)
  }

  const renderConfirmModal = () => {
    const isEnabling2FA = companyValues.force2fa
    return (
      <ArkConfirmModal
        show={showConfirmAlert}
        title={<>Make {OBJECT_COMPANY_NAME} 2FA {isEnabling2FA ? 'Mandatory' : 'Optional'}?</>}
        message={<>
          <p>Are you sure you want to {isEnabling2FA ? 'enforce' : 'make'} {OBJECT_COMPANY_NAME} two factor authentication {!isEnabling2FA ? 'optional' : ''}?</p>
          {isEnabling2FA && (<p>All {OBJECT_COMPANY_NAME} users will be forced to enable &amp; use 2FA to login each time.</p>)}
          {!isEnabling2FA && (<p>All {OBJECT_COMPANY_NAME} users will no longer be forced to use 2FA to login.</p>)}
        </>}
        onCancel={onCancel}
        onConfirm={onSubmit}
        onClose={onClose}
      />
    )
  }

  // -------

  const is2FAEnabled = companyValuesSaved.force2fa

  return (
    <div className={styles.tfaSettings}>
      <div className={styles.content}>
        <ArkPanel>
          <ArkPanel.Properties titleWidth={220}>
            {(saveResult || saveError) && (
              <>
                {saveResult && (
                  <ArkPanel.PropertyRow>
                    <ArkPanel.PropertyRowMessage positive>
                      <ArkMessage.Header>{OBJECT_COMPANY_NAME} 2FA Now {is2FAEnabled ? 'Required' : 'Optional'} </ArkMessage.Header>
                      {is2FAEnabled && (<p>Two factor authentication is now required for all {OBJECT_COMPANY_NAME} users</p>)}
                      {!is2FAEnabled && (<p>Two factor authentication is no longer required for all {OBJECT_COMPANY_NAME} users</p>)}
                    </ArkPanel.PropertyRowMessage>
                  </ArkPanel.PropertyRow>
                )}
                {saveError && (
                  <ArkPanel.PropertyRow>
                    <ArkPanel.PropertyRowMessage negative>
                      <ArkMessage.Header>Error</ArkMessage.Header>
                      <p>{saveError.message}</p>
                    </ArkPanel.PropertyRowMessage>
                  </ArkPanel.PropertyRow>
                )}
              </>
            )}
            <ArkPanel.PropertyRow
              title='Require 2FA:'
              value={<>{toggleElement('force2fa')}</>}
              hint={<>{saving && (<ArkLoader small className={styles.loading} />)}</>} // NB: if adding an actual hint, only show it when not 'saving' so the loader shows in its place
              hasChanges={hasChanges('force2fa')}
              titleClassName={styles.propertyTitle}
              valueClassName={styles.propertyValue}
              titleSize={'xlarge'}
            />
          </ArkPanel.Properties>
        </ArkPanel>
        {renderConfirmModal()}
      </div>
    </div>
  )
}

export { Company2FASettingsView }
