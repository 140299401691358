import React, { useContext, useState } from 'react'

import { ProjectAdminContext } from 'src/core/providers'

import { Program } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkMessage from 'src/core/components/ArkMessage'

import styles from './ProgramRecreateView.module.css'

export type ProjectProgramRecreateCallback = (program: Program) => void

interface IProps {
  companyId: number
  projectId: number
  program: Program
  onDidRecreate?: ProjectProgramRecreateCallback
  className?: string
}

const ProgramRecreateView = (props: IProps) => {
  const { program, className, onDidRecreate } = props
  if (!program) return (<></>)

  const { actions: projectAdminActions } = useContext(ProjectAdminContext) // store: projectAdminStore

  const [isRunning, setIsRunning] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>(undefined)

  const onRecreate = async (): Promise<boolean> => {
    const { companyId, projectId, program } = props
    try {
      setIsRunning(true)
      if (error) setError(undefined)
      const recreateResult = await projectAdminActions.recreateCompanyProjectProgram(companyId, projectId, program.id)
      // console.log('ProgramRecreateView - onRecreate - recreateCompanyProjectProgram - recreateResult: ', recreateResult)
      if (onDidRecreate) onDidRecreate(program)
      setIsRunning(false)
      return recreateResult
    } catch (error) {
      console.error('ProgramRecreateView - onRecreate - recreateCompanyProjectProgram - error: ', error)
      setIsRunning(false)
      setError(error)
      return false
    }
  }

  return (
    <div className={styles.recreate + (className ? ' ' + className : '')}>
      {error && (<>
        <ArkMessage negative>
          <ArkMessage.Header>Error</ArkMessage.Header>
          <ArkMessage.Item>{error.message}</ArkMessage.Item>
        </ArkMessage>
      </>)}
      <ArkButton type="button" color={'blue'} negative size={'small'} loading={isRunning} onClick={onRecreate}>UPDATE PROGRAM</ArkButton>
    </div>
  )
}

export default ProgramRecreateView
