import React, { useEffect, useRef } from 'react'

import { IVideoEngineRealtimeStats } from '../../models/StreamhubVideoEngineModels'

import ArkMessage from 'src/core/components/ArkMessage'

interface IProps {
  realtimeStats?: IVideoEngineRealtimeStats
  error?: Error
}

const StreamhubVideoEngineRealtimeStatsView = (props: IProps) => {
  const mounted = useRef(false)

  const { realtimeStats, error } = props

  // -------

  useEffect(() => {
    console.log('StreamhubVideoEngineRealtimeStatsView - MOUNT')
    mounted.current = true
    return () => {
      console.log('StreamhubVideoEngineRealtimeStatsView - UNMOUNT')
      mounted.current = false
    }
  }, [])

  // -------

  return (
    <div>
      <h4>Realtime Stats</h4>
      {error && (
        <ArkMessage negative>
          <ArkMessage.Header>Error</ArkMessage.Header>
          <p>{error.message}</p>
        </ArkMessage>
      )}
      {realtimeStats && (
        <>
          <div>connected: {realtimeStats.connected}</div>
          <div>bandwidth: {realtimeStats.bandwidth}</div>
          <div>geo: {realtimeStats.geo ? JSON.stringify(realtimeStats.geo) : '-'}</div>
        </>
      )}
    </div>
  )
}

export default StreamhubVideoEngineRealtimeStatsView
