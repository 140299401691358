import React from 'react'

import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkProjectManagerPage from 'src/manager/project/components/ArkProjectManagerPage/ArkProjectManagerPage'
import { OBJECT_PROJECT_NAME } from 'src/constants/strings'

const ProjectManagerLoadingPage = () => (
  <ArkProjectManagerPage disableSectionMainMenu>
    <ArkLoaderView message={'Loading ' + OBJECT_PROJECT_NAME} />
  </ArkProjectManagerPage>
)

export default ProjectManagerLoadingPage
