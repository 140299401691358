import React from 'react'

import ServerAPIClient from '../services/ServerAPIClient'

export interface IAPIStore {
}

export interface IAPIActions {
  getAPIClient: () => ServerAPIClient
}

export interface IAPIContext {
  actions: IAPIActions
  store: IAPIStore
}

export interface IAPIMultiContext {
  apiContext: IAPIContext
}

export const APIContext = React.createContext<IAPIContext>({} as IAPIContext)

export interface APIProviderProps {
  apiClient: ServerAPIClient
  children?: React.ReactNode
}
export interface APIProviderState extends IAPIStore {
}

class APIProvider extends React.Component<APIProviderProps, APIProviderState> {
  constructor (props: APIProviderProps) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
  }

  // -------

  getAPIClient = () => this.props.apiClient

  // -------

  actions: IAPIActions = {
    getAPIClient: this.getAPIClient
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: IAPIStore = {
  //  ...
  // }

  render () {
    return (
      <APIContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for IAPIStore */ }}
      >
        {this.props.children}
      </APIContext.Provider>
    )
  }
}

const withAPIContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withAPIContextHOC = (props: any) => (
    <APIContext.Consumer>
      {(apiContext) => {
        if (apiContext === null) {
          throw new Error('APIConsumer must be used within a APIProvider')
        }
        // console.log('withAPIContext - render - APIContext.Consumer - apiContext.store: ', apiContext.store)
        return (<Component {...props} {...{ apiContext: apiContext }} />)
      }}
    </APIContext.Consumer>
  )
  return withAPIContextHOC
}

export { APIProvider }
export { withAPIContext }
