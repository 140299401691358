import React, { useContext } from 'react'

import { CompanyAdminContext /*, SiteAdminContext */ } from 'src/core/providers'
import { User } from 'src/core/models'

import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_USER_NAME } from 'src/constants/strings'

export type SiteAdminUserReset2FACallback = (user: User) => void

interface IProps {
  user: User
  onReset?: SiteAdminUserReset2FACallback
  className?: string
}

const SiteAdminUserReset2FAButton = (props: IProps) => {
  const { user, className } = props

  // const siteAdminContext = useContext(SiteAdminContext) // NB: `SiteAdminContext` isn't available outside the site admin section currently & we don't yet have a site admin users section to use this from (no api endpoint(s) for it yet)
  const companyAdminContext = useContext(CompanyAdminContext) // NB: TEMP using `CompanyAdminContext` for the api call now until this component is used within the site admin section & the code can move back to `SiteAdminContext`

  const onConfirm = async (): Promise<boolean> => {
    console.log('SiteAdminUserReset2FAButton - onConfirm - user.id:', user.id)
    // NB: DON'T catch the error/exception here, let it throw (or if you do, rethrow it so the calling code can catch & display it)
    const disableResult = await companyAdminContext.actions.siteAdminDisableUser2FA(user.id) // disableUser2FA
    console.log('SiteAdminUserReset2FAButton - onConfirm - siteAdminDisableUser2FA - disableResult: ', disableResult)
    return disableResult
  }

  if (!user) return (<></>)

  return (
    <div className={(className ? ' ' + className : '')}>
      <ArkSpacer />
      <ArkManagerDeleteButton
        itemId={user.id}
        itemName={user.name()}
        itemTypeName={OBJECT_USER_NAME + ' 2FA'}
        itemNamePrefix='for'
        buttonTitle={'DISABLE ' + OBJECT_USER_NAME + ' 2FA'}
        deleteWord={'Disable'} // OBJECT_USER_NAME
        deletedWord={'Disabled'}
        confirmMsgPost={<>?<br />Continue</>} // <p>The user will have to re-setup 2FA again.</p>
        onDelete={onConfirm}
        onDeleteComplete={() => { if (props.onReset) props.onReset(user) }}
        buttonProps={{ color: 'orange' }}
        confirmOkButtonProps={{ color: 'red', basic: false }}
        confirmTextInput={user.email}
      />
    </div>
  )
}

export default SiteAdminUserReset2FAButton
