import React from 'react'
// import ReactDOM from 'react-dom' // OLD: react v17
import { createRoot } from 'react-dom/client' // NEW: react v18

import { loadArkLog } from './core/utilities/ArkLog'

// import './index.css'
// import LegacyApp from './legacy/App' // OLD LEGACY APP
import ArkApp from './App' // NEW (WIP) APP
import * as serviceWorker from './serviceWorker'

loadArkLog()

// OLD: react v17
// ReactDOM.render(
//   // WARNING: TEMP disabled strict mode
//   // TODO: see the following for the reason (also seen this outside of antd on other packages/libs)
//   // TODO: ref: https://github.com/ant-design/ant-design/issues/22493
//   // <LegacyApp />,
//   // NEW (WIP) APP
//   // WARNING: TEMP disabled strict mode
//   // TODO: see the following for the reason (also seen this with antd & other packages/libs)
//   // TODO: ref: https://github.com/Semantic-Org/Semantic-UI-React/issues/3819
//   // <React.StrictMode>
//   <ArkApp />
//   // </React.StrictMode>
//   ,
//   document.getElementById('root')
// )

// NEW: react v18
const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <ArkApp />
  // </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
