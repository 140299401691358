import React from 'react'

import { OBJECT_CARD_NAME } from 'src/constants/strings'
import ArkButton from 'src/core/components/ArkButton'
import ArkCard from 'src/core/components/ArkCard'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { Card, Project } from 'src/core/models'

import styles from './CardSidebar.module.css'
import { getCardPermissionsText, getCardTypeText, getCardUsageText } from './utilities'

interface CardSidebarProps {
  card: Card
  onDeleteClick: () => void
  onDuplicateClick: () => void
  onEditorClick: () => void
  onUpdateClick: () => void
  projects?: Project[]
  section: 'company' | 'project'
}

const CardSidebar = (props: CardSidebarProps) => {
  // console.log('CardSidebar - card:', props.card)

  const edit: boolean = props.section === 'company' || props.card.entity_type === 'PROJECT'
  const permissions: boolean = props.section === 'company'

  return (
    <>
      <ArkPanel bordered className={styles.topPanel}>
        <ArkPanel.Header
          className={styles.topPanelHeader}
          description={props.card.description}
          iconName='card'
          title={props.card.name}
        />
        <ArkIconButton
          disabled={!edit}
          name='settings'
          onClick={props.onUpdateClick}
          size={24}
        />
      </ArkPanel>
      <ArkSpacer />
      <ArkCard
        card={props.card}
        disabled={!edit}
        onClick={props.onEditorClick}
        rounded
      />
      <ArkSpacer />
      <ArkButton
        disabled={!edit}
        fluid
        onClick={props.onEditorClick}
        size='large'
      >
        Edit content
      </ArkButton>
      <ArkSpacer />
      <ArkPanel bordered>
        <ArkPanel.Properties>
          <ArkPanel.PropertyRow
            title='ID:'
            value={props.card.id}
          />
          <ArkPanel.PropertyRow
            title='Type:'
            value={getCardTypeText(props.card)}
          />
          {permissions && (
            <ArkPanel.PropertyRow
              title='Permissions:'
              value={getCardPermissionsText(props.card, props.projects)}
            />
          )}
          <ArkPanel.PropertyRow
            title='Usage:'
            value={getCardUsageText(props.card)}
          />
        </ArkPanel.Properties>
      </ArkPanel>
      <ArkSpacer grow />
      <ArkButton
        fluid
        onClick={props.onDuplicateClick}
        size='large'
      >
        {`Duplicate ${OBJECT_CARD_NAME}`}
      </ArkButton>
      <ArkSpacer />
      <ArkButton
        basic
        disabled={!edit}
        fluid
        negative
        onClick={props.onDeleteClick}
        size='large'
      >
        {`Delete ${OBJECT_CARD_NAME}`}
      </ArkButton>
    </>
  )
}

export default CardSidebar
