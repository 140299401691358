import React, { useContext, useEffect, useRef } from 'react'
import * as yup from 'yup'

import { ProjectAdminContext } from 'src/core/providers'

import { ProjectUser, UserProjectRole } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps, ArkFormFieldOption } from 'src/core/components/ArkForm/ArkForm'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkPanel from 'src/core/components/ArkPanel'

import { OBJECT_PROJECT_NAME, OBJECT_USER_NAME, ROLE_ADMIN_NAME, ROLE_MANAGER_NAME, ROLE_MEMBER_NAME } from 'src/constants/strings'

// TODO: needed? add fields if so, remove this if not...
const formSchema = yup.object().shape({
})

interface IProps {
  companyId: number
  projectId: number
  user: ProjectUser
  onChange?: Function
}

const ProjectUserRolePanel = (props: IProps) => {
  const mounted = useRef(false)

  const { companyId, projectId, user, onChange: _onChange } = props

  const { actions: projectAdminActions } = useContext(ProjectAdminContext)

  const [showForm, setShowForm] = React.useState<boolean>(false)
  const [hasChanges, setHasChanges] = React.useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [hasSaved, setHasSaved] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>(undefined)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const updateProjectUserRole = async (projectRole: UserProjectRole) => {
    if (!user) { return } // NB: shouldn't be able to get here, so not showing an error if it happens
    try {
      const updateResult = await projectAdminActions.updateUserProjectRole(companyId, projectId, user.id, projectRole)
      console.log('ProjectUserRolePanel - updateProjectUserRole - updateResult: ', updateResult)
      if (updateResult) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
        }
        if (_onChange) _onChange()
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          setError(Error('A problem occurred updating the ' + OBJECT_USER_NAME + ' ' + OBJECT_PROJECT_NAME + ' role, please try again.'))
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  // -------

  const reset = () => {
    setShowForm(false)
    setHasChanges(false)
    setIsSubmitting(false)
    setHasSaved(false)
    setError(undefined)
  }

  // -------

  // TESTING: equivalent to the componentDidUpdate method `if (this.props.user?.id !== prevProps.user?.id) { this.reset() }`
  useEffect(() => {
    if (user?.id) {
      reset()
    }
  }, [user?.id])

  // -------

  const onShowForm = () => {
    setShowForm(true)
    setError(undefined)
  }

  // const onHideForm = () => {
  //   setShowForm(false)
  //   setError(undefined)
  // }

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('ProjectUserRolePanel - onFormSubmit - fieldValues: ', fieldValues)
    updateProjectUserRole(fieldValues.projectRole)
  }

  const onValueChanged = (fieldKey: string, fieldValue: any, oldFieldValue: any) => {
    console.log('ProjectUserRolePanel - onValueChanged - fieldKey: ', fieldKey, ' fieldValue: ', fieldValue, ' oldFieldValue: ', oldFieldValue)
    if (fieldKey === 'projectRole') {
      const valueChanged = fieldValue !== user.projectRole
      if ((valueChanged && !hasChanges) || (!valueChanged && hasChanges)) {
        setHasChanges(valueChanged)
      }
      // also clear/reset a past error when the user interacts with the form
      if (error) {
        setError(undefined)
      }
    }
  }

  const onCancel = () => {
    reset()
  }

  const onClose = () => {
    reset()
  }

  // -------

  if (!user) return null

  const formFields: Array<ArkFormField> = []
  const projectRoles: Array<ArkFormFieldOption> = [
    { key: 'member', text: 'standard ' + ROLE_MEMBER_NAME, value: UserProjectRole.member },
    { key: 'manager', text: OBJECT_PROJECT_NAME + ' ' + ROLE_MANAGER_NAME, value: UserProjectRole.manager },
    { key: 'admin', text: OBJECT_PROJECT_NAME + ' ' + ROLE_ADMIN_NAME, value: UserProjectRole.admin }
  ]
  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'projectUserRole',
    // label: 'Edit Project User Role',
    fields: [
      // { type: ArkFormFieldType.FormErrorPlaceholder, key: 'formError' },
      {
        type: ArkFormFieldType.Group,
        key: 'detailsGroup',
        fields: [
          { type: ArkFormFieldType.Radio, key: 'projectRole', required: true, defaultValue: user.projectRole ?? UserProjectRole.member, options: projectRoles }
        ]
      },
      {
        type: ArkFormFieldType.Group,
        key: 'buttons',
        fields: [
          { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { onClick: onCancel, floated: 'left' } },
          { type: ArkFormFieldType.OKButton, key: 'submit', label: 'SAVE', disabled: !hasChanges, disabledTooltip: 'No Changes to Save', fieldProps: { loading: isSubmitting, floated: 'right' } }
        ],
        fieldProps: { widths: 'equal' },
        slimline: true
      }
    ],
    collapsible: false,
    collapsed: false,
    slimline: true,
    bordered: false
  })
  console.log('ProjectUserRolePanel - render - error: ', error)
  return (
    <ArkPanel bordered={showForm} title={showForm ? OBJECT_USER_NAME + ' ' + OBJECT_PROJECT_NAME + ' ROLE' : null}>

      {hasSaved && (
        <div style={{ padding: '0 10px 10px 10px' }}>
          <ArkMessage positive>
            <ArkMessage.Header>{OBJECT_USER_NAME + ' ' + OBJECT_PROJECT_NAME} role updated</ArkMessage.Header>
            <ArkMessage.Item>The user {OBJECT_PROJECT_NAME} role has been updated</ArkMessage.Item>
          </ArkMessage>
          <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={onClose} style={{ marginTop: 10 }}>
            OK
          </ArkButton>
        </div>
      )}

      {!showForm && (
        <ArkButton type="button" positive fluid size="large" onClick={onShowForm}>EDIT {OBJECT_USER_NAME + ' ' + OBJECT_PROJECT_NAME} ROLE</ArkButton>
      )}

      {showForm && !hasSaved && (
        <ArkForm
          formKey="projectUserRoleForm"
          formError={error}
          formFields={formFields}
          formSchema={formSchema}
          onFormSubmit={onFormSubmit}
          onValueChanged={onValueChanged}
          showLabels={true}
        />
      )}

    </ArkPanel>
  )
}

export default ProjectUserRolePanel
