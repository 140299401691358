import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as yup from 'yup'

import { withAuthContext, IAuthMultiContext, withNavContext, INavMultiContext } from '../../../providers'
import { ServerError } from '../../../services/ServerAPIErrors'
import * as ROUTES from '../../../../constants/routes'

import ArkButton from '../../../components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from '../../../components/ArkForm/ArkForm'
import { Divider, Message } from 'semantic-ui-react'

const formSchema = yup.object().shape({
  password: yup.string().min(6).max(40).required(),
  passwordConfirm: yup.string().oneOf([yup.ref('password'), undefined], 'Passwords must match')
})

interface IProps extends IAuthMultiContext, INavMultiContext {
  resetToken: string
}
interface IState {
  error?: Error
  isSubmitting: boolean
  passwordReset: boolean
}

class LoginPasswordResetStage2Form extends Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    const error = !this.props.resetToken ? new Error('Invalid reset token, please check the email link & try again.') : undefined
    this.state = {
      error: error,
      isSubmitting: false,
      passwordReset: false
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { error, isSubmitting, passwordReset } = this.state

    const formFields: Array<ArkFormField> = []
    formFields.push({ type: ArkFormFieldType.Input, key: 'password', label: 'Password', required: true, fieldProps: { type: 'password' } }) // icon: lock
    formFields.push({ type: ArkFormFieldType.Input, key: 'passwordConfirm', label: 'Confirm Password', required: true, fieldProps: { type: 'password' } })
    formFields.push({ type: ArkFormFieldType.Button, key: 'submit', label: 'Reset Password', fieldProps: { loading: isSubmitting, fluid: true } })

    return (
      <>
        {passwordReset && (
          <>
            <Message positive>
              <Message.Header>Password Changed</Message.Header>
              <Message.Item>You password has been successfully changed. You can now login.</Message.Item>
            </Message>
            <ArkButton color="blue" fluid size="large" onClick={() => {
              this.props.navContext.actions.goto(ROUTES.LOGIN)
            }}>
              Login
            </ArkButton>
          </>
        )}
        {!passwordReset && (
          <>
            <ArkForm
              formKey="passwordReset"
              className="password-reset-form"
              inverted
              formError={error}
              formFields={formFields}
              formSchema={formSchema}
              onFormSubmit={this.onFormSubmit}
            ></ArkForm>
            <Divider horizontal inverted></Divider>
            <div style={{ textAlign: 'center', textDecoration: 'underline' }}>
              <Link to={ROUTES.LOGIN}>or Login</Link>
            </div>
          </>
        )}
      </>
    )
  }

  onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    if (this.state.isSubmitting) return

    const { password } = fieldValues

    this.setState({ isSubmitting: true })

    this.props.authContext.actions.resetEmailPassword(this.props.resetToken, password)
      .then(() => {
        if (this._isMounted) this.setState({ isSubmitting: false, passwordReset: true })
        // TODO: ideally re-direct on success to remove the token from the url (stop page refresh showing the form with an expired/used token)
        // TODO: but need to show a success message as well
      })
      .catch((error: any) => {
        console.error('LoginPasswordResetStage2Form - onSubmit - signInWithEmailAndPassword - ERROR: ', error)
        if (error instanceof ServerError) {
          console.error('LoginPasswordResetStage2Form - onSubmit - signInWithEmailAndPassword - ServerError: ', error)
        }
        this.setState({ isSubmitting: false, error })
      })
  }
}

export default withNavContext(withAuthContext(LoginPasswordResetStage2Form))
