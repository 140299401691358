import { IFFMpegStreamProgramData } from './StreamhubModels' // TODO: port/move to here once the old `FFMpeg...` prefixed models are removed

export interface IOutputStream {
  streamId: number
  sourceId: number
  name?: string
  url?: string
  duration?: number // seconds
  isTemp: boolean
  isEnabled: boolean
  isActive: boolean
  status?: IOutputStreamStatus
  pid?: number
  retryEnabled: boolean //    NB: retry<X> fields are currently set as required
  retryDelay: number //       NB: ..(all original entries seemed to have all 4 main fields set,
  retryMaxAttempts: number // NB: ..& just the `retryAt` timestamp one was absent from some so made optional),
  retryCount: number //       NB: ..these could likely be made optional down the line if needed
  createdAt: Date
  updatedAt?: Date
  startedAt?: Date
  retryAt?: Date
  error?: Error // NB: not sending over the socket.io emit usage? adding a string version fallback for that for now
  errorMsg?: string // fallback for socket.io usage while the Error object doesn't seem to send
  programData?: IOutputStreamProgramData // used when a stream was created from a specific program in the main api (instead of the url set manually)
  isDemo?: boolean
  tags?: Array<string>
}

export enum IOutputStreamStatus {
  initial, stopped, starting, running, restarting, stopping, error
}

// used when a stream was created from a specific program in the main api (instead of the url set manually)
export interface IOutputStreamProgramData extends IFFMpegStreamProgramData {}

class OutputStream implements IOutputStream {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    public streamId: number,
    public sourceId: number,
    public name?: string,
    public url?: string,
    public duration?: number,
    public isTemp: boolean = false,
    public isEnabled: boolean = false,
    public isActive: boolean = false,
    public status?: IOutputStreamStatus,
    public pid?: number,
    public retryEnabled: boolean = false,
    public retryDelay: number = 30,
    public retryMaxAttempts: number = 120,
    public retryCount: number = 0,
    public createdAt: Date = new Date(),
    public updatedAt?: Date,
    public startedAt?: Date,
    public retryAt?: Date,
    public error?: Error,
    public errorMsg?: string,
    public programData?: IOutputStreamProgramData,
    public isDemo?: boolean,
    public tags?: Array<string>
  ) {}

  get id (): number { return this.streamId } // alias `streamId` for backwards compatibility with the old FFMpegStream model

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any): OutputStream {
    const stream = new OutputStream(
      json.id,
      json.sourceId,
      json.name,
      json.url,
      json.duration,
      json.isTemp,
      json.isEnabled,
      json.isActive,
      json.status,
      json.pid,
      json.retryEnabled,
      json.retryDelay,
      json.retryMaxAttempts,
      json.retryCount,
      json.createdAt,
      json.updatedAt,
      json.startedAt,
      json.retryAt,
      json.error,
      json.errorMsg,
      json.programData,
      json.isDemo,
      json.tags
    )
    return stream
  }
}

export default OutputStream
