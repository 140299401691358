import React, { useContext, useEffect, useRef, useState } from 'react'
import { generatePath, useParams } from 'react-router-dom'

import * as ROUTES from 'src/constants/routes'
import { UserContext, CompanyAdminContext, NavContext } from 'src/core/providers'
import { CompanyGroup, CompanyUser, Project } from 'src/core/models'

import ArkCompanyManagerPage from 'src/manager/company/components/ArkCompanyManagerPage/ArkCompanyManagerPage'
import CompanyGroupSidebar from './CompanyGroupSidebar'
import CompanyGroupListItem from './CompanyGroupListItem'

import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkManagerListView, { ArkManagerFilteredItem } from 'src/core/components/ArkManagerListView/ArkManagerListView' // SectionSchema
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkModal from 'src/core/components/ArkModal'
import { OBJECT_COMPANY_NAME, OBJECT_COMPANY_SHORTNAME, OBJECT_GROUP_NAME, OBJECT_GROUP_NAME_PLURAL, SECTION_COMPANY_NAME, SECTION_MANAGER_SUFFIX_NAME } from 'src/constants/strings'
import CompanyGroupForm, { GroupFormMode } from './CompanyGroupForm'

type GroupsPageParams = {
  groupId?: string
}

interface IProps {}

const CompanyGroupsPage = (_props: IProps) => {
  const mounted = useRef(false)

  const { groupId: _groupIdStr } = useParams<GroupsPageParams>()

  const userContext = useContext(UserContext)
  const companyAdminContext = useContext(CompanyAdminContext)
  const navContext = useContext(NavContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [groups, setGroups] = useState<Array<CompanyGroup>>([])
  const [filteredGroups, setFilteredGroups] = useState<Array<ArkManagerFilteredItem<CompanyGroup>> | undefined>()
  const [filter, setFilter] = useState<string | undefined>()
  const [selectedGroup, setSelectedGroup] = useState<CompanyGroup | undefined>()
  const [editGroup, setEditGroup] = useState<CompanyGroup | undefined>()
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false)
  const [groupUsers, setGroupUsers] = useState<Array<CompanyUser> | undefined>()
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false)
  const [groupProjects, setGroupProjects] = useState<Array<Project> | undefined>()
  const [_showGroupFormModal, setShowGroupFormModal] = useState<boolean>(false)

  const company = userContext.store.selectedCompany
  if (!company) return null

  // -------

  const loadGroupUsers = async (groupId: number) => {
    if (loadingUsers === true) return false
    const company = userContext.store.selectedCompany
    if (company && groupId) {
      try {
        setLoadingUsers(true)
        const users = await companyAdminContext.actions.getCompanyGroupUsers(company.id, groupId)
        if (mounted.current) {
          setLoadingUsers(false)
          setGroupUsers(users || [])
        }
      } catch (error) {
        console.error('CompanyGroupsPage - loadChannels - error: ', error)
        // TODO: add an error prop & display an error message if this happens
        if (mounted.current) {
          setLoadingUsers(false)
          setGroupUsers([])
        }
      }
    }
  }

  // -------

  const loadGroupProjects = async (groupId: number) => {
    if (loadingProjects === true) return false
    const company = userContext.store.selectedCompany
    if (company && groupId) {
      try {
        setLoadingProjects(true)
        const projects = await companyAdminContext.actions.getCompanyGroupProjectVisibility(company.id, groupId)
        if (mounted.current) {
          setLoadingProjects(false)
          setGroupProjects(projects || [])
        }
      } catch (error) {
        console.error('CompanyGroupsPage - loadChannels - error: ', error)
        // TODO: add an error prop & display an error message if this happens
        if (mounted.current) {
          setLoadingProjects(false)
          setGroupProjects([])
        }
      }
    }
  }

  // -------

  const selectGroup = async (group?: CompanyGroup) => {
    setSelectedGroup(group)
    if (group) {
      await loadGroupUsers(group.id)
      await loadGroupProjects(group.id)
    } else {
      setGroupUsers(undefined)
      setLoadingUsers(false)
      setGroupProjects(undefined)
      setLoadingProjects(false)
    }
    // update the url with the selected group id (or clear it if none selected)
    const newPath = generatePath(ROUTES.COMPANY_MANAGER_GROUPS, { groupId: group?.id })
    console.log('CompanyGroupsPage - selectGroup - newPath:', newPath)
    navContext.actions.goto(newPath) // history.push(newPath)
  }

  const filterGroups = (filterTxt: string, _groups?: Array<CompanyGroup>) => {
    // console.log('CompanyGroupsPage - filterGroups - filterTxt: ', filterTxt, ' groups:', groups, ' _groups:', _groups)
    if (loading) return
    const _filter = filterTxt.length > 0 ? filterTxt : undefined
    const _filteredGroups = _filter
      ? (_groups ?? groups).reduce<Array<ArkManagerFilteredItem<CompanyGroup>>>((r, group) => {
        let nameMatch = false
        if (group.name.toLowerCase().includes(_filter.toLowerCase())) {
          nameMatch = true
        }
        if (nameMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          const _filteredUser: ArkManagerFilteredItem<CompanyGroup> = {
            item: group,
            matchingFields
          }
          r.push(_filteredUser)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<CompanyGroup>>)
      : undefined
    if (selectedGroup && (!(_filteredGroups?.find((filteredGroup) => filteredGroup.item.id === selectedGroup.id)))) {
      selectGroup(undefined) // if a user was selected but isn't in the filtered list deselect them
    }
    setFilter(_filter)
    setFilteredGroups(_filteredGroups)
    // console.log('CompanyGroupsPage - filterUsers - _filteredUsers: ', _filteredUsers)
  }

  // NB: not currently used/needed?
  // const clearFilteredGroups = () => {
  //   setFilter(undefined)
  //   setFilteredGroups(undefined)
  // }

  // -------

  const loadCompanyGroups = async () => {
    if (loading === true) return false
    const companyId = userContext.store.selectedCompany?.id
    if (companyId) {
      try {
        setLoading(true)
        const _groups = await companyAdminContext.actions.getCompanyGroups(companyId) ?? undefined
        // console.log('CompanyGroupsPage - loadCompanyGroups - _groups:', _groups)
        if (mounted.current) {
          setLoading(false)
          setGroups(_groups ?? [])
          setFilteredGroups(undefined)
          if (filter) filterGroups(filter, _groups) // re-filter if it was active (NB: pass in the groups array as the state var update may not have happened yet, at least when the page is filtered & a user is deleted it seemed to happen)
          if (selectedGroup) {
            // console.log('CompanyGroupsPage - loadCompanyGroups - selectedGroup:', selectedGroup)
            const updatedGroup = _groups?.find((group) => group.id === selectedGroup.id)
            setSelectedGroup(updatedGroup) // re-select the group if one was previously selected, so the updated data is shown
          }
        }
        return _groups
      } catch (error) {
        console.error('CompanyGroupsPage - loadCompanyUsers - error: ', error)
        // TODO: add an error prop & display an error message if this happens
        if (mounted.current) {
          setLoading(false)
          setGroups([])
          setFilteredGroups(undefined)
        }
      }
    }
  }

  const updateCompanyGroup = (updatedGroup: CompanyGroup) => {
    // update our groups cache with the supplied group object (instead of needing to re-query the api for it)
    if (groups) {
      const groupIndex = groups.findIndex((g: CompanyGroup) => g.id === updatedGroup.id)
      if (groupIndex >= 0) {
        const _groups = [...groups] // make a shallow copy
        _groups[groupIndex] = updatedGroup
        setGroups(_groups)
      }
    }
    // TODO: will this update as expected, or should the `_groups` array from above be used instead? (as the state var may not have updated yet?) <<<<
    // trigger the sidepanel to update with the updated group object
    if (selectedGroup && groups) {
      const newGroup = groups.find((g) => g.id === selectedGroup?.id)
      setSelectedGroup(newGroup)
    }
  }

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    async function runAsync () {
      // load all company groups
      const _groups = await loadCompanyGroups()
      // if a group id was passed in the url, auto select that group
      if (_groupIdStr && _groups) {
        const groupId = parseInt(_groupIdStr)
        const group = _groups.find((group) => group.id === groupId)
        if (group) selectGroup(group)
        // TESTING: auto scroll to the selected group (if one was found) - ref: https://spacejelly.dev/posts/how-to-scroll-to-an-element-in-react
        // NB: see the `CompanyGroupListItem.module.css` where `.listItem` has `scroll-margin-top` & `scroll-padding-top` set to offset the scroll position to account for the site header & page title, filter & padding/margins etc.
        // TODO: should maybe only scroll if the group item row is not already in view? may also want to replace `scrollIntoView` usage with more manual scroll positioning e.g ref: https://stackoverflow.com/a/49860927
        if (group) {
          const element = document.getElementById('org-group-' + group.id)
          element?.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }
    runAsync()
  }, [])

  // -------

  const renderGroupFilterForm = () => {
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        filterTitle='Filter by name'
        filterValue={filter ?? ''}
        onFilterChange={(_filter: string) => {
          filterGroups(_filter)
        }}
      />
    )
  }

  // -------

  const renderGroupTableRowContent = (group: CompanyGroup, isSelected: boolean, filter?: string) => {
    return (
      <CompanyGroupListItem
        key={group.id}
        companyId={company.id}
        group={group}
        active={isSelected}
        filter={filter}
        elementId={'org-group-' + group.id}
        onClick={() => selectGroup(group)}
        onGroupUpdated={updateCompanyGroup}
      />
    )
  }

  // -------

  const showAddGroupFormModal = () => {
    console.log('CompanyGroupsPage - showAddGroupFormModal')
    // if (selectedGroup) setSelectedGroup(undefined) // clear the selected group if one was previously selected
    setEditGroup(undefined)
    setShowGroupFormModal(true)
    selectGroup(undefined) // clear the selected group if one was previously selected
  }

  const showEditGroupFormModal = (group: CompanyGroup) => {
    console.log('CompanyGroupsPage - showEditGroupFormModal - group: ', group.id)
    // if (selectedGroup && group.id !== selectedGroup.id) setSelectedGroup(group) // NB: shouldn't be needed? (should already be selected if editing?)
    setEditGroup(group)
    setShowGroupFormModal(true)
  }

  const hideGroupFormModal = () => {
    // NB: DON'T clear/reset `editGroup` here, see `didHideGroupModal` which is called once the modal has actually closed & we can safely reset it then
    // NB: this stops a saved edit form flipping the 'updated' success text to 'created' briefly while the modal closes if we reset it straight away here
    // NB: & also stops the form title flipping from edit to add as it closes via the cancel button
    setShowGroupFormModal(false)
  }

  // triggered via modal callbacks once the modal has already closed
  const didHideGroupModal = () => {
    setShowGroupFormModal(false)
    setEditGroup(undefined)
  }

  const renderGroupForm = () => {
    const companyId = userContext.store.selectedCompany?.id
    if (!companyId) return null
    return (
      <CompanyGroupForm
        mode={editGroup ? GroupFormMode.Edit : GroupFormMode.Add}
        companyId={companyId}
        group={editGroup}
        onCancel={() => { hideGroupFormModal() }}
        onDidSave={async (group, formMode) => {
          console.log('CompanyGroupsPage - renderGroupForm - onDidSave - group: ', group, ' formMode: ', formMode)
          // trigger a data re-load to show the added/updated details
          await loadCompanyGroups()
          // if a group was added (instead of updated), auto select the new one (so it shows as selected after the data reload completes)
          if (formMode === GroupFormMode.Add) {
            // if (selectedGroup) setSelectedGroup(undefined) // clear the selected user if one was previously selected
            // setSelectedGroup(group) // TESTING: select the new group (although its not shown in the listing yet, after the data is reloaded it will show & be (re)selected)
            selectGroup(group)
          }
          // NB: we don't auto close/hide the modal form when it saves, leave it up for a success message to show & the user to dismiss manually
        }}
        onClose={() => { hideGroupFormModal() }}
        insideModal={true}
      />
    )
  }

  const renderGroupFormModal = () => {
    return (
      <ArkModal open={_showGroupFormModal} onClose={() => didHideGroupModal()}>
        {renderGroupForm()}
      </ArkModal>
    )
  }

  // -------

  const onEdit = (group: CompanyGroup) => {
    if (group.id !== selectedGroup?.id) return // currently only supporting editing the already selected group (as thats the only way to get to the edit form for now)
    showEditGroupFormModal(group)
  }

  const onDidDeleteGroup = (group: CompanyGroup) => {
    console.log('CompanyGroupsPage - onDidDeleteGroup - group: ', group)
    // trigger a company groups data re-load so the deleted group no longer shows
    loadCompanyGroups()
    selectGroup(undefined)
  }

  // const onUpdateGroup = (groupId: number) => {
  //   console.log('CompanyGroupsPage - onUpdateGroup - groupId: ', groupId)
  //   // trigger a company groups data re-load so the updated group data is reloaded
  //   // NB: `loadCompanyGroups` will also trigger the selected group to be updated
  //   loadCompanyGroups()
  // }

  return (<ArkCompanyManagerPage
    rightSidebar={selectedGroup && <CompanyGroupSidebar
      company={company}
      group={selectedGroup}
      onEdit={onEdit}
      onDidDeleteGroup={onDidDeleteGroup}
      onChange={async () => {
        // TODO: can this be merged with `onGroupUpdated` & instead of reloading all groups, just update the specific group like that callback does in `updateCompanyGroup`??? <<<<
        // trigger a company users data re-load so the updated user values show
        await loadCompanyGroups()
        // trigger the sidepanel to update with the updated user object
        if (selectedGroup && groups) {
          const newGroup = groups.find((group) => group.id === selectedGroup?.id)
          setSelectedGroup(newGroup)
          // also update the group users & projects lists
          if (newGroup) {
            await loadGroupUsers(newGroup.id)
            await loadGroupProjects(newGroup.id)
          }
        }
      }}
      onGroupUpdated={updateCompanyGroup}
      loadingUsers={loadingUsers}
      groupUsers={groupUsers}
      loadingProjects={loadingProjects}
      groupProjects={groupProjects}
    />}
  >
    <ArkManagerContentView
      title={OBJECT_COMPANY_NAME + ' ' + OBJECT_GROUP_NAME_PLURAL}
      breadcrumb={[{
        path: ROUTES.COMPANY_MANAGER,
        title: ROUTES.formatBreadcrumbRootTitle(company.name, `${SECTION_COMPANY_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`)
      }]}
    >
      <ArkManagerListView
        loading={loading}
        items={groups}
        // sections={groupSections}
        // sectionItemCheck={(section: SectionSchema, group: CompanyGroup) => {
        //   const isGuest = user.isGuest
        //   const isProjectAdminOrManager = user.isCompanyAdmin()
        //   switch (section.key) {
        //     case 'admin': return isProjectAdminOrManager
        //     case 'member': return (!isGuest && !isProjectAdminOrManager)
        //     case 'guest': return isGuest
        //   }
        //   return false
        // }}
        selectedItem={selectedGroup}
        itemRow={(group: CompanyGroup, isSelected: boolean) => {
          return renderGroupTableRowContent(group, isSelected, filter)
        }}
        // topbar={this.topBarContent}
        topbarAddItemTitle={'ADD ' + OBJECT_COMPANY_SHORTNAME + ' ' + OBJECT_GROUP_NAME}
        onAdd={() => showAddGroupFormModal()}
        // FILTERING:
        filter={filter}
        filteredItems={filteredGroups}
        filterForm={renderGroupFilterForm()}
        onClearFilter={() => filterGroups('') }
      />
      {renderGroupFormModal()}
    </ArkManagerContentView>
  </ArkCompanyManagerPage>)
}
export default CompanyGroupsPage
