import React, { Component } from 'react'
import { Icon, Message } from 'semantic-ui-react'
import { ArkFormProps } from '../ArkForm/ArkForm'

import ArkInfoView from '../ArkInfoView'
import ArkLoaderView from '../ArkLoaderView'
import ArkManagerTopbar from '../ArkManagerTopbar/ArkManagerTopbar'

import styles from './ArkManagerListView.module.css'

export type ItemSchema = { id: number }
export type SectionSchema = { key: string, title: string, desc?: string, collapsible?: boolean, collapsed?: boolean }

export interface ArkManagerFilteredItem<ItemType extends ItemSchema> {
  item: ItemType
  matchingFields: Array<string>
}

interface IProps<ItemType extends ItemSchema, SectionType extends SectionSchema> {

  items: Array<ItemType>
  sections?: Array<SectionType>
  sectionItemCheck?: (section: SectionType, item: ItemType) => boolean
  itemRow: (item: ItemType, isSelected: boolean, matchingFilterFields?: Array<string>) => React.ReactNode
  loading?: boolean
  selectedItem?: ItemType

  noItemsTitle?: string
  noItemsMessage?: string | React.ReactNode

  filter?: string
  filteredItems?: Array<ArkManagerFilteredItem<ItemType>>

  errors?: Array<Error>

  // topbar - manual
  topbar?: React.ReactNode
  // topbar - dynamic (NB: don't pass in a topbar prop when using it in dynamic mode)
  topbarAddItemTitle?: string
  onAdd?: Function
  otherButtons?: Array<{ title: string, onClick: Function }>
  filterForm?: React.ReactNode
  onClearFilter?: Function
}
interface IState {
  collapsibleSections: Map<string, boolean>
}
class ArkManagerListView<ItemType extends ItemSchema, SectionType extends SectionSchema> extends Component<IProps<ItemType, SectionType>, IState> {
  constructor (props: IProps<ItemType, SectionType>) {
    super(props)
    this.state = {
      collapsibleSections: new Map<string, boolean>()
    }
  }

  render () {
    const showTopbar = this.showTopbar()
    const { filter, errors } = this.props
    const isFiltering = filter !== undefined
    return (
      <div className={styles.managerList + (showTopbar ? ' ' + styles.withTopbar : '')}>
        {showTopbar && (this.renderTopbar())}
        {isFiltering && (this.renderFilteringHeader())}
        {errors && this.renderErrorsHeader()}
        {this.renderTable()}
      </div>
    )
  }

  renderTable = () => {
    const { loading, items, sections, noItemsTitle, noItemsMessage, filter, filteredItems, onClearFilter } = this.props
    const isFiltering = filter !== undefined
    // NB: no longer showing the full screen (page blocking/hiding) loader when just updating, or we loose our page scroll offset etc.
    // NB: we now show an 'updating' loader/spinner in the topbar instead (if the topbar is enabled)
    // TODO: if the topbar is disabled, consider showing the loader over the existing list/table instead?
    const updating = !!(loading && ((!isFiltering && items.length > 0) || (isFiltering && filteredItems !== undefined && filteredItems?.length > 0)))
    if (loading && !updating) return <ArkLoaderView message='Loading' />
    if (!isFiltering && items.length === 0) {
      return (
        <ArkInfoView
          iconName='warning'
          title={noItemsTitle || 'NO ITEMS'}
          message={noItemsMessage || (<div>This section is empty.<br />Use the CREATE button to add your first item.</div>)}
        />
      )
    } else if (isFiltering && (filteredItems === undefined || filteredItems?.length === 0)) {
      return (
        <ArkInfoView
          iconName='warning'
          title='NO RESULTS'
          message={
            <div>
              No matches for your search.<br />
              Try using a different term to filter with.<br />
              {onClearFilter !== undefined && (<span className={styles.filterClear} onClick={() => { onClearFilter() }}>(CLEAR FILTER)</span>)}
            </div>
          }
        />
      )
    } else {
      if (sections && sections.length > 0) {
        return this.renderTableSections(sections, isFiltering ? (filteredItems ?? []) : items, isFiltering)
      } else {
        return this.renderTableRows(isFiltering ? (filteredItems ?? []) : items)
      }
    }
  }

  renderTableSections = (sections: Array<SectionType>, items: Array<ItemType> | Array<ArkManagerFilteredItem<ItemType>>, isFiltering: boolean) => {
    if (!this.props.sectionItemCheck) return null // halt if the sectionItemCheck callback isn't defined
    return sections.map((section) => {
      // loop through all items & check if they're in the current section
      const sectionItems: Array<ItemType> = []
      const filteredSectionItems: Array<ArkManagerFilteredItem<ItemType>> = []
      for (const i of items) {
        // TESTING: TS type guard check - ref: https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
        let item: ItemType
        // let matchingFields: Array<string> | undefined
        if ((i as ArkManagerFilteredItem<ItemType>).matchingFields !== undefined) {
          item = (i as ArkManagerFilteredItem<ItemType>).item
          // matchingFields = (i as ArkManagerFilteredItem<ItemType>).matchingFields
        } else {
          item = i as ItemType
        }
        if (this.props.sectionItemCheck!(section, item)) {
          if (!isFiltering) {
            sectionItems.push(item)
          } else {
            filteredSectionItems.push((i as ArkManagerFilteredItem<ItemType>))
          }
        }
      }
      if ((!isFiltering && sectionItems.length === 0) || (isFiltering && filteredSectionItems.length === 0)) return null // if no items in this section don't render anything
      const onClick = () => {
        console.log('ArkManagerListView - renderTableSections - collapsible - onClick')
        const isOpen = this.state.collapsibleSections.get(section.key) ?? (section.collapsed !== undefined ? !section.collapsed : undefined) ?? true
        // ref: https://stackoverflow.com/a/49532713
        this.setState((prevState: IState, _props: ArkFormProps) => {
          prevState.collapsibleSections.set(section.key, !isOpen)
          return { collapsibleSections: prevState.collapsibleSections }
        })
      }
      const isCollapsible = (section.collapsible === true)
      let isOpen = true
      if (isCollapsible) {
        isOpen = this.state.collapsibleSections.get(section.key) ?? (section.collapsed !== undefined ? !section.collapsed : undefined) ?? true
      }
      return (
        <div key={'section_' + section.key} className={
          styles.section +
          (isCollapsible ? ' ' + styles.sectionCollapsible : '') +
          (!isOpen ? ' ' + styles.sectionClosed : '')
        }>
          <div className={styles.sectionHeader} onClick={onClick}>
            <div className={styles.sectionTitle}>
              {isCollapsible && (<Icon name='dropdown' />)}{section.title}
            </div>
            {section.desc !== undefined && (<div className={styles.sectionDesc}>{section.desc}</div>)}
          </div>
          <div className={styles.sectionItems}>
            {this.renderTableRows(!isFiltering ? sectionItems : filteredSectionItems)}
          </div>
        </div>
      )
    })
  }

  renderTableRows = (items: Array<ItemType> | Array<ArkManagerFilteredItem<ItemType>>) => {
    const { selectedItem } = this.props
    return items.map((i: ItemType | ArkManagerFilteredItem<ItemType>) => {
      // TESTING: TS type guard check - ref: https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
      let item: ItemType
      let matchingFields: Array<string> | undefined
      if ((i as ArkManagerFilteredItem<ItemType>).matchingFields !== undefined) {
        item = (i as ArkManagerFilteredItem<ItemType>).item
        matchingFields = (i as ArkManagerFilteredItem<ItemType>).matchingFields
      } else {
        item = i as ItemType
      }
      const isSelected = !!(selectedItem && selectedItem.id === item.id)
      return this.props.itemRow(item, isSelected, matchingFields)
    })
  }

  getItem = (itemId: number) => {
    for (const item of this.props.items) {
      if (item.id === itemId) return item
    }
    return undefined
  }

  // -------

  renderFilteringHeader = () => {
    const { filter, filteredItems, items, onClearFilter } = this.props
    const isFiltering = filter !== undefined
    if (!isFiltering) return null
    const filteredItemCount = filteredItems?.length ?? 0
    const hiddenItemsCount = items.length - (filteredItems?.length ?? 0)
    return (
      <div className={styles.filterHeader}>
        FILTER: &lsquo;{filter}&rsquo; = {filteredItemCount} match{filteredItemCount !== 1 ? 'es' : ''} found
        {hiddenItemsCount > 0 && (
          <>
            &nbsp;({hiddenItemsCount} item{hiddenItemsCount !== 1 ? 's' : ''} hidden)
          </>
        )}
        {filteredItemCount > 0 && onClearFilter !== undefined && (
          <>
            &nbsp;<span className={styles.filterClear} onClick={() => { onClearFilter() }}>(CLEAR FILTER)</span>
          </>
        )}
      </div>
    )
  }

  // -------

  renderErrorsHeader = () => {
    const { errors } = this.props
    if (!errors || errors.length === 0) return null
    const errorCount = errors.length
    return (
      <div className={styles.errors}>
        <Message negative>
          <Message.Header>{'Error' + (errorCount > 1 ? 's' : '')}</Message.Header>
          {(errors.map((error, index) => {
            return (
              <>
                {errorCount === 1
                  ? (<p>{error.message}</p>)
                  : (<Message.Item key={'error_' + index}>{error.message}</Message.Item>)
                }
              </>
            )
          }))}
        </Message>
      </div>
    )
  }

  // -------

  renderTopbar = () => {
    const { topbar, topbarAddItemTitle, otherButtons, loading, items } = this.props
    const showTopbar = this.showTopbar()
    if (!showTopbar) return (<></>)
    const updating = !!(loading && items.length > 0)
    return (
      <div className={styles.listTopbar}>
        {topbar !== undefined && (topbar)}
        {topbar === undefined && (
          <ArkManagerTopbar
            addButtonTitle={topbarAddItemTitle}
            onAdd={() => {
              // this.showAddForm()
              if (this.props.onAdd) this.props.onAdd()
            }}
            otherButtons={otherButtons}
            filterForm={this.props.filterForm}
            updating={updating}
          />
        )}
      </div>
    )
  }

  showDynamicTopbar = () => {
    const { topbar, topbarAddItemTitle, otherButtons, filterForm } = this.props
    return (topbar === undefined && (topbarAddItemTitle !== undefined || otherButtons !== undefined || filterForm !== undefined))
  }

  showTopbar = () => {
    const { topbar } = this.props
    const showDynamicTopbar = this.showDynamicTopbar()
    return (topbar !== undefined || showDynamicTopbar)
  }
}

export default ArkManagerListView
