/**
 * ProjectUserListItem
 */

import React from 'react'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkListItem, { ArkListItemDetail } from 'src/core/components/ArkListItem'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { ProjectUser } from 'src/core/models'

import ProjectUserAccessToggle from './ProjectUserAccessToggle'

interface ProjectUserListItemProps {
  active: boolean
  companyId: number
  filter?: string
  onClick: () => void
  onUserUpdated: (user: ProjectUser) => void
  projectId: number
  user: ProjectUser
}

const ProjectUserListItem = (props: ProjectUserListItemProps) => {
  const {
    active,
    companyId,
    filter,
    onClick,
    onUserUpdated,
    projectId,
    user
  } = props

  const leftComponent = (
    <ArkAvatar
      type={user.userAvatarType()}
      name={user.name()}
      size='40'
    />
  )

  const rightComponent = (
    <ArkListItemDetail
      hintMessage='This will restrict the user from all access to the project including viewing programs. Disabling a Project Manager will remove their access to manage the project.'
      hintTitle='Toggle Enable/Disable user access'
      title='Access'
    >
      <ProjectUserAccessToggle
        companyId={companyId}
        projectId={projectId}
        user={user}
        disabled={!user.isProjectDirectUser()}
        onChange={(value: boolean) => {
          if (value !== user.projectAccessEnabled) {
            user.projectAccessEnabled = value
            onUserUpdated(user)
          }
        }}
        showStatusText={false}
      />
    </ArkListItemDetail>
  )

  const rightComponentDesktop = (
    <>
      {!user.isGuest && (
        <>
          <ArkListItemDetail title='Role'>
            {user.projectRoleName().toUpperCase()}
          </ArkListItemDetail>
          <ArkSpacer />
          <ArkListItemDetail title='Status'>
            {user.companyStatusName()}
          </ArkListItemDetail>
        </>
      )}
    </>
  )

  const title = user.nameIsEmail() ? '⚠️ Name is pending registration' : user.name() + (user.isSiteAdmin() ? ' - REPRO ADMIN ⚠️' : '')
  const desc = user.isGuest ? (user.lastName ?? undefined) : user.email

  return (
    <ArkListItem
      active={active}
      description={desc}
      filter={filter}
      leftComponent={leftComponent}
      onClick={onClick}
      rightComponent={rightComponent}
      rightComponentDesktop={rightComponentDesktop}
      title={title}
    />
  )
}

export default ProjectUserListItem
