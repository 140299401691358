import React from 'react'

import ArkInfoView from '../../../components/ArkInfoView'
import ArkPage from '../../../components/ArkPage/ArkPage'
interface AccessDeniedPageProps {
  message?: string
}

const AccessDeniedPage = ({ message }: AccessDeniedPageProps) => (
  <ArkPage>
    <ArkInfoView
      iconName='denied'
      title={message || 'ACCESS DENIED'}
    />
  </ArkPage>
)

export default AccessDeniedPage
