import React, { useContext } from 'react'

import { CompanyAdminContext /*, SiteAdminContext */ } from 'src/core/providers'
import { User } from 'src/core/models'

import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_USER_NAME } from 'src/constants/strings'

export type SiteAdminUserWipeCallback = (user: User) => void

interface IProps {
  user: User
  onWiped?: SiteAdminUserWipeCallback
  className?: string
}

const SiteAdminUserWipeButton = (props: IProps) => {
  const { user, className } = props

  // const siteAdminContext = useContext(SiteAdminContext) // NB: `SiteAdminContext` isn't available outside the site admin section currently & we don't yet have a site admin users section to use this from (no api endpoint(s) for it yet)
  const companyAdminContext = useContext(CompanyAdminContext) // NB: TEMP using `CompanyAdminContext` for the api call now until this component is used within the site admin section & the code can move back to `SiteAdminContext`

  const onConfirm = async (): Promise<boolean> => {
    // NB: DON'T catch the error/exception here, let it throw (or if you do, rethrow it so the calling code can catch & display it)
    const deleteResult = await companyAdminContext.actions.siteAdminDeleteUser(user.id) // deleteUser
    console.log('SiteAdminUserWipeButton - onConfirm - siteAdminDeleteUser - deleteResult: ', deleteResult)
    return deleteResult
  }

  if (!user) return (<></>)

  return (
    <div className={(className ? ' ' + className : '')}>
      <ArkSpacer />
      <ArkManagerDeleteButton
        itemId={user.id}
        itemName={user.name()}
        itemTypeName={OBJECT_USER_NAME}
        // itemNamePrefix='for'
        buttonTitle={'WIPE ' + OBJECT_USER_NAME + ' FROM SYSTEM'}
        deleteWord={'Wipe'} // OBJECT_USER_NAME
        deletedWord={'Wiped'}
        confirmMsgPost={<>from the system?<><br /><br /><strong>WARNING: </strong>This completely deletes the user from the whole site!</><br />Continue</>}
        onDelete={onConfirm}
        onDeleteComplete={() => { if (props.onWiped) props.onWiped(user) }}
        buttonProps={{ basic: false, color: 'red' }}
        confirmOkButtonProps={{ color: 'red', basic: false }}
        confirmTextInput={user.email}
      />
    </div>
  )
}

export default SiteAdminUserWipeButton
