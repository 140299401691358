/**
 * OnboardingProvider
 */

import React, { createContext, ReactNode, useRef, useState } from 'react'
import Cookies from 'js-cookie'
import _ from 'lodash'

import { ArkOnboardingPopupProps } from '../components/ArkOnboardingPopup'

const COOKIE_NAME = 'hiddenOnboardingPopups'
const ENABLE_COOKIES = true

export type OnboardingPopupId =
  'projectNodeGraphFilter' |
  'viewerLeftSidebarExpandCollapse'

interface IOnboardingActions {
  hideCurrentPopup: () => void
  reset: () => void
  setPopupPropsForId: (id: OnboardingPopupId, props: ArkOnboardingPopupProps | undefined) => void
  showPopup: (id: OnboardingPopupId) => void
}

interface IOnboardingStore {
  currentPopupProps?: ArkOnboardingPopupProps
}

interface IOnboardingContext {
  actions: IOnboardingActions
  store: IOnboardingStore
}

interface OnboardingProviderProps {
  children: ReactNode
}

export const OnboardingContext = createContext<IOnboardingContext>({} as IOnboardingContext)

const OnboardingProvider = (props: OnboardingProviderProps) => {
  const { children } = props

  const popupPropsArray = useRef<ArkOnboardingPopupProps[]>([])
  const showPopupIds = useRef<OnboardingPopupId[]>([])

  const [currentPopupProps, setCurrentPopupProps] = useState<ArkOnboardingPopupProps>()

  const updateCurrentPopupProps = () => {
    console.log('OnboardingProvider - updateCurrentPopupProps')
    showPopupIds.current = _.chain(showPopupIds.current)
      .map(id => _.find(popupPropsArray.current, { id }))
      .compact()
      .sortBy('priority')
      .map(item => item.id)
      .value()
    const id = _.first(showPopupIds.current)
    const props = _.find(popupPropsArray.current, { id })
    setCurrentPopupProps(props)
  }

  const hideCurrentPopup = () => {
    console.log('OnboardingProvider - hideCurrentPopup')
    if (!currentPopupProps) return
    showPopupIds.current = _.reject(showPopupIds.current, id => id === currentPopupProps.id)
    if (ENABLE_COOKIES) {
      const hiddenPopups = (Cookies.get(COOKIE_NAME) || '').split(',')
      hiddenPopups.push(currentPopupProps.id)
      Cookies.set(COOKIE_NAME, hiddenPopups.join(','))
    }
    updateCurrentPopupProps()
  }

  const reset = () => {
    console.log('OnboardingProvider - reset')
    Cookies.remove(COOKIE_NAME)
  }

  const setPopupPropsForId = (id: OnboardingPopupId, props: ArkOnboardingPopupProps | undefined) => {
    console.log('OnboardingProvider - setPopupPropsForId - id:', id, '- props:', props)
    popupPropsArray.current = _.reject(popupPropsArray.current, item => item.id === id)
    if (props) popupPropsArray.current.push(props)
  }

  const showPopup = (id: OnboardingPopupId) => {
    console.log('OnboardingProvider - showPopup - id:', id)
    if (ENABLE_COOKIES) {
      const hiddenPopups = (Cookies.get(COOKIE_NAME) || '').split(',')
      if (_.includes(hiddenPopups, id)) return
    }
    showPopupIds.current.push(id)
    updateCurrentPopupProps()
  }

  const actions: IOnboardingActions = {
    hideCurrentPopup,
    reset,
    setPopupPropsForId,
    showPopup
  }

  const store: IOnboardingStore = {
    currentPopupProps
  }

  return (
    <OnboardingContext.Provider value={{ actions, store }}>
      {children}
    </OnboardingContext.Provider>
  )
}

export default OnboardingProvider
