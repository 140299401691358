/**
 * ListItem
 */

import React from 'react'

import ArkIcon, { IconName } from 'src/core/components/ArkIcon'
import ArkSpacer from 'src/core/components/ArkSpacer'

import styles from './ListItem.module.css'

interface ListItemProps {
  checked?: boolean
  description?: string | JSX.Element;
  disabled?: boolean
  nested?: boolean
  onClick?: () => void
  optional?: boolean
  readOnly?: boolean
  status?: string | JSX.Element;
  title: string | JSX.Element;
}

const ListItem = (props: ListItemProps) => {
  const { checked, description, disabled, nested, onClick, optional, readOnly, status, title } = props

  let iconColor: string
  let iconName: IconName
  switch (true) {
    case checked:
      iconColor = 'var(--green)'
      iconName = 'check-circle-solid'
      break
    case optional:
      iconColor = 'var(--bd-lighter)'
      iconName = 'minus-circle-solid'
      break
    default:
      iconColor = 'var(--yellow)'
      iconName = 'exclamation-circle-solid'
  }

  const showArrow = !checked && !disabled && !readOnly
  const showDescription = !disabled && !checked && description

  return (
    <div className={styles.container}>
      {!nested && <div className={styles.iconBackground} />}
      <div
        className={`${
          styles.button
        } ${
          checked ? styles.checked : ''
        } ${
          disabled ? styles.disabled : ''
        } ${
          nested ? styles.nested : ''
        } ${
          readOnly ? styles.readOnly : ''
        }`}
        onClick={!checked && !disabled ? onClick : undefined}
      >
        <div className={styles.iconContainer}>
          <ArkIcon
            className={styles.icon}
            color={iconColor}
            name={iconName}
            size={36}
          />
        </div>
        <ArkSpacer />
        <div>
          <div className={`${styles.title} ${checked ? styles.titleChecked : ''}`}>{title}</div>
          {showDescription && <div className={styles.description}>{description}</div>}
          {status && <div className={styles.status}>{status}</div>}
        </div>
        <ArkSpacer grow />
        {showArrow && <ArkIcon className={styles.arrow} name='arrow-right' />}
      </div>
    </div>
  )
}

export default ListItem
