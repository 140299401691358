import React from 'react'
import _ from 'lodash'

import {
  OBJECT_COMPANY_SHORTNAME,
  OBJECT_NOTICE_NAME_PLURAL,
  OBJECT_PROJECT_NAME,
  OBJECT_PROJECT_NAME_PLURAL
} from 'src/constants/strings'
import { ArkManagerFilteredItem } from 'src/core/components/ArkManagerListView/ArkManagerListView'
import { Card, Project } from 'src/core/models'

export const getCardPermissionsText = (card: Card, projects?: Project[]): JSX.Element | string => {
  if (card.entity_type === 'PROJECT') return `${OBJECT_PROJECT_NAME}: ${card.project_id}`
  if (card.permission_all_projects) return `All ${OBJECT_PROJECT_NAME_PLURAL.toLowerCase()}`
  if (_.some(card.permission_specific_projects)) {
    return (
      <div>
        <div>{`${OBJECT_PROJECT_NAME_PLURAL}:`}</div>
        {_.map(card.permission_specific_projects, id => {
          const project: Project | undefined = _.find(projects, { id })
          return <div key={id}>{`• ${project ? project.name : id}`}</div>
        })}
      </div>
    )
  }
  return `No ${OBJECT_PROJECT_NAME_PLURAL.toLowerCase()}`
}

export const getCardUsageText = (card: Card): JSX.Element | string => {
  if (_.some(card.notices)) {
    return (
      <div>
        <div>{`${OBJECT_NOTICE_NAME_PLURAL}:`}</div>
        {_.map(card.notices, notice => {
          return <div key={notice.id}>{`• ${notice.name}`}</div>
        })}
      </div>
    )
  }
  return `No ${OBJECT_NOTICE_NAME_PLURAL.toLowerCase()}`
}

export const getCardTypeText = (card: Card): string => card.entity_type === 'COMPANY'
  ? OBJECT_COMPANY_SHORTNAME
  : OBJECT_PROJECT_NAME

export const getFilteredCards = (cards: Card[], filter: string): ArkManagerFilteredItem<Card>[] => {
  if (!filter) return []
  return _.chain(cards)
    .map(card => {
      const matchingFields: string[] = []
      if (card.name.toLowerCase().includes(filter.toLowerCase())) matchingFields.push('name')
      if (card.description.toLowerCase().includes(filter.toLowerCase())) matchingFields.push('description')
      const type: string = card.entity_type === 'COMPANY' ? 'org' : 'project'
      if (type.includes(filter.toLowerCase())) matchingFields.push('type')
      return _.some(matchingFields) && { item: card, matchingFields }
    })
    .compact()
    .value()
}
