import React, { useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import * as ROUTES from 'src/constants/routes'
import { CompanyAdminContext, ServerConfigContext, UserContext } from 'src/core/providers'

import { CompanyVideoEngine, Project, ProjectVideoEngine } from 'src/core/models'

import ArkCompanyManagerPage from 'src/manager/company/components/ArkCompanyManagerPage/ArkCompanyManagerPage'
import CompanyProjectSidebar from './CompanyProjectSidebar'
import ProjectForm, { ProjectFormMode } from './ProjectForm'
import CompanyProjectListItem from './CompanyProjectListItem'

import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkManagerListView, { ArkManagerFilteredItem } from 'src/core/components/ArkManagerListView/ArkManagerListView'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkModal from 'src/core/components/ArkModal'
import { OBJECT_COMPANY_NAME, OBJECT_PROJECT_NAME, OBJECT_PROJECT_NAME_PLURAL, OBJECT_VIDEO_ENGINE_NAME_PLURAL, SECTION_COMPANY_NAME, SECTION_MANAGER_SUFFIX_NAME } from 'src/constants/strings'

interface IProps {}

const CompanyProjectsPage = (_props: IProps) => {
  const mounted = useRef(false)

  const { store: userStore } = useContext(UserContext) // actions: userActions
  const companyAdminContext = useContext(CompanyAdminContext)
  const serverConfigContext = useContext(ServerConfigContext)

  const defaultVideoEngineId = serverConfigContext.store.serverConfig?.streamingDefaults.streamingServerId

  const [loading, setLoading] = useState<boolean>(false)
  const [projects, setProjects] = useState<Array<Project>>([])
  const [filteredProjects, setFilteredProjects] = useState<Array<ArkManagerFilteredItem<Project>> | undefined>(undefined)
  const [filter, setFilter] = useState<string | undefined>(undefined)
  const [selectedProject, setSelectedProject] = useState<Project | undefined>(undefined)
  const [editProject, setEditProject] = useState<Project | undefined>(undefined)
  const [showProjectFormModal, setShowProjectFormModal] = useState<boolean>(false)
  const [videoEngines, setVideoEngines] = useState<Array<CompanyVideoEngine> | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const selectProject = (project?: Project) => {
    setSelectedProject(project)
  }

  // -------

  const filterProjects = (_filterTxt: string) => {
    if (loading) return
    const _filter = _filterTxt.length > 0 ? _filterTxt : undefined
    const _filteredProjects = filter
      ? projects.reduce<Array<ArkManagerFilteredItem<Project>>>((r, project) => {
        let nameMatch = false
        let descMatch = false
        if (project.name.toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        if (project.desc?.toLowerCase().includes(filter.toLowerCase())) {
          descMatch = true
        }
        if (nameMatch || descMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          if (descMatch) matchingFields.push('desc')
          const filteredUser: ArkManagerFilteredItem<Project> = {
            item: project,
            matchingFields
          }
          r.push(filteredUser)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<Project>>)
      : undefined
    if (selectedProject && (!(filteredProjects?.find((filteredProject) => filteredProject.item.id === selectedProject.id)))) {
      selectProject(undefined) // if a user was selected but isn't in the filtered list deselect them
    }
    setFilter(_filter)
    setFilteredProjects(_filteredProjects)
  }

  // const clearFilteredProjects = () => {
  //   setFilter(undefined)
  //   setFilteredProjects(undefined)
  // }

  // -------

  const _loadProjects = async (companyId: number) => {
    try {
      const _projects = await companyAdminContext.actions.getAllCompanyProjects(companyId)
      console.log('CompanyProjectsPage - _loadProjects - _projects:', _projects)
      return _projects
    } catch (error) {
      console.error('CompanyProjectsPage - _loadProjects - error: ', error)
      throw error // NB: error handled in the calling code
    }
  }

  const _loadVideoEngines = async (companyId: number) => {
    try {
      const companyVideoEngines = await companyAdminContext.actions.getAllCompanyVideoEngines(companyId)
      console.log('CompanyProjectsPage - _loadVideoEngines - companyVideoEngines:', companyVideoEngines)
      if (!companyVideoEngines || companyVideoEngines.length === 0) {
        throw new Error('WARNING: No ' + OBJECT_VIDEO_ENGINE_NAME_PLURAL + ' found for this ' + OBJECT_COMPANY_NAME)
      }
      return companyVideoEngines
    } catch (error) {
      console.error('CompanyProjectsPage - _loadVideoEngines - error: ', error)
      throw error // NB: error handled in the calling code
    }
  }

  // NB: we now return the loaded projects data from here so it can be used directly instead of having to wait for the state to update
  //     (mainly for use in the `onSave` callback from the project add/edit form)
  const loadData = async (): Promise<Array<Project> | undefined> => {
    if (loading === true) return undefined
    const companyId = userStore.selectedCompany?.id
    if (companyId) {
      setLoading(true)
      if (error) setError(undefined)
      try {
        const _videoEngines = await _loadVideoEngines(companyId)
        setVideoEngines(_videoEngines)
      } catch (error) {
        console.error('CompanyProjectsPage - loadData - _videoEngines - error: ', error)
        if (mounted.current) {
          setError(error) // TODO: add a video engine specific error state var? (treat it more as a warning?)
          setVideoEngines(undefined)
        }
      }
      // NB: even if video engines fail to load, we still want to try and load the projects
      try {
        const _projects = await _loadProjects(companyId)
        // console.log('CompanyProjectsPage - loadData - _projects:', _projects)
        if (mounted.current) {
          setProjects(_projects || [])
          setFilteredProjects(undefined)
          setLoading(false)
        }
        if (mounted.current && filter) filterProjects(filter) // re-filter if it was active
        return _projects ?? undefined // return the loaded projects data so it can be used directly
      } catch (error) {
        console.error('CompanyProjectsPage - loadData - _projects - error: ', error)
        if (mounted.current) {
          setError(error)
          setProjects([])
          setFilteredProjects(undefined)
        }
      }
      if (mounted.current) {
        setLoading(false)
      }
    }
    return undefined
  }

  useEffect(() => {
    loadData()
  }, [])

  // -------

  const renderProjectTableRowContent = (project: Project, isSelected: boolean, filter?: string) => {
    const defaultVideoEngineId = serverConfigContext.store.serverConfig?.streamingDefaults.streamingServerId
    const defaultVideoEngine = defaultVideoEngineId ? videoEngines?.find(ve => ve.id === defaultVideoEngineId) : undefined
    const projectVideoEngine = videoEngines?.find(ve => ve.id === project.videoEngine?.id)
    return (
      <CompanyProjectListItem
        active={isSelected}
        filter={filter}
        key={project.id}
        onClick={() => selectProject(project)}
        // onEditClick={() => showEditProjectModal(project)}
        project={project}
        projectVideoEngine={projectVideoEngine as unknown as ProjectVideoEngine}
        defaultVideoEngine={defaultVideoEngine as unknown as ProjectVideoEngine}
      />
    )
  }

  // -------

  const renderProjectFilterForm = () => {
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        filterTitle='Filter by name or description'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          filterProjects(filter)
        }}
      />
    )
  }

  // -------

  const showAddProjectModal = () => {
    setShowProjectFormModal(true)
    setEditProject(undefined)
  }

  const showEditProjectModal = (project: Project) => {
    setShowProjectFormModal(true)
    setEditProject(project)
  }

  // call directly to initiate a modal callback (used when the user clicks custom buttons to close the modal instead of the modals own 'x' button etc.)
  const hideProjectModal = () => {
    // NB: DON'T clear/reset `editProject` here, see `didHideProjectModal` which is called once the modal has actually closed & we can safely reset it then
    // NB: this stops a saved edit form flipping the 'updated' success text to 'created' briefly while the modal closes if we reset it straight away here
    // NB: & also stops the form title flipping from edit to add as it closes via the cancel button
    setShowProjectFormModal(false)
  }

  // triggered via modal callbacks once the modal has already closed
  const didHideProjectModal = () => {
    setShowProjectFormModal(false)
    setEditProject(undefined)
  }

  // -------

  const renderProjectAddEditForm = (project?: Project) => {
    const companyId = userStore.selectedCompany?.id
    if (!companyId) return null
    return (
      <ProjectForm
        mode={project ? ProjectFormMode.Edit : ProjectFormMode.Add}
        companyId={companyId}
        project={project}
        onCancel={() => { hideProjectModal() }}
        onSave={async () => {
          // trigger a projects data re-load to show the newly added project
          // UPDATE: we now grab the updated projects from the `loadData` response so we can re-select the updated project if it was being edited
          //         (the projects state var hasn't updated yet & so we're dealing with stale date pre-save if we try to rely on it like we did previously)
          const _projects = await loadData()
          // console.log('CompanyProjectsPage - renderProjectAddEditForm - onSave - _projects:', _projects)
          // re-select with the updated project so any changes are shown/used
          if (selectedProject && project && selectedProject.id === project.id) {
            const updatedProject = _.find(_projects, (chan) => chan.id === selectedProject?.id)
            // console.log('CompanyProjectsPage - renderProjectAddEditForm - onSave - updatedProject:', updatedProject)
            selectProject(updatedProject)
          }
        }}
        // onDelete={() => {
        //   // trigger a projects data re-load so the deleted project no longer shows
        //   loadProjects()
        // }}
        onClose={() => { hideProjectModal() }}
        insideModal={true}
      />
    )
  }

  const renderAddProjectModal = () => {
    return (
      <ArkModal
        open={showProjectFormModal}
        onClose={() => didHideProjectModal()}
        // NB: disable close on `escape` key press (as the project form can trigger a VE 2nd modal form which would also dismiss on esc, so for now we just ignore it completely)
        // TODO: ideally we'd only disable esc close if a sub-modal/form is showing over this one, but as this modal is handled externally to the project form thats not easy currently...
        closeOnEscape={false}
      >
        {renderProjectAddEditForm(editProject)}
      </ArkModal>
    )
  }

  // -------

  const onEditProject = (project: Project) => {
    showEditProjectModal(project)
  }

  const onDidDeleteProject = (project: Project) => {
    console.log('CompanyProjectsPage - onDidDeleteProject - project: ', project)
    // trigger a company users data re-load so the deleted project no longer shows
    loadData()
    selectProject(undefined)
  }

  // -------

  const company = userStore.selectedCompany
  if (!company) return null

  const rightSidebar = selectedProject && (
    <CompanyProjectSidebar
      company={company}
      project={selectedProject}
      videoEngines={videoEngines}
      defaultVideoEngineId={defaultVideoEngineId}
      onEdit={onEditProject}
      onDidDelete={onDidDeleteProject}
    />
  )

  return (
    <ArkCompanyManagerPage
      onRightSidebarClose={() => setSelectedProject(undefined)}
      rightSidebar={rightSidebar}
    >
      <ArkManagerContentView
        title={OBJECT_PROJECT_NAME_PLURAL}
        breadcrumb={[{
          path: ROUTES.COMPANY_MANAGER,
          title: ROUTES.formatBreadcrumbRootTitle(company.name, `${SECTION_COMPANY_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`)
        }]}
      >
        <ArkManagerListView
          loading={loading}
          items={projects}
          selectedItem={selectedProject}
          itemRow={(project: Project, isSelected: boolean) => {
            return renderProjectTableRowContent(project, isSelected, filter)
          }}
          errors={error ? [error] : undefined}
          // topbar={topBarContent}
          topbarAddItemTitle={'CREATE ' + OBJECT_PROJECT_NAME}
          onAdd={() => showAddProjectModal()}
          // FILTERING:
          filter={filter}
          filteredItems={filteredProjects}
          filterForm={renderProjectFilterForm()}
          onClearFilter={() => filterProjects('') }
        />
        {renderAddProjectModal()}
      </ArkManagerContentView>
    </ArkCompanyManagerPage>
  )
}
export default CompanyProjectsPage
