import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import AuthCode from 'react-auth-code-input'

import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps, ArkFormFieldPlaceholder } from '../../../components/ArkForm/ArkForm'

import { Header } from 'semantic-ui-react'

import styles from './User2FAInputView.module.css'

/**
 * this component can currently display in 2 different input modes:
 *  basic = a single standard ArkForm input field for all input chars (with some styling tweaks)
 *  split = 6 individual character input fields (using the 'react-auth-code-input' component)
 *
 *  NB: basic mode doesn't currently support all the features split mode does (auto submit etc.)
 */

export enum User2FAInputViewMode {
  basic, split
}
const DefaultUser2FAInputViewMode = User2FAInputViewMode.basic

const formSchemaBasic = yup.object().shape({
  otpCode: yup.string().required()
    .min(6, 'Must be 6 characters long')
    .max(6, 'Must be 6 characters long')
    .matches(/^[0-9]+$/, 'Must be only numbers with no spaces')
})
const formSchemaSplit = yup.object().shape({
  otpCode: yup.string().required()
    .min(6, 'Must be 6 characters long')
    .max(6, 'Must be 6 characters long')
    .matches(/^[0-9]+$/, 'Must be only numbers')
})
const formSchemaEmpty = yup.object().shape({}) // NB: we pass in an empty schema to the ArkForm so it doesn't error out on submit & run the validation manually instead

interface IProps {
  inputMode?: User2FAInputViewMode // NB: doesn't currently support switching of this once created
  autoSubmit?: boolean // auto triggers submit once the char length (currently 6) is reached
  isBusy?: boolean // allows the calling code to set the submit button to busy/loading while its running relevant code
  error?: Error
  submitTitle?: string // optional submit button title/text to show
  onSubmit?: (otpCode: string) => void
}

const User2FAInputView = (props: IProps) => {
  const { submitTitle } = props
  const inputMode = props.inputMode ?? DefaultUser2FAInputViewMode

  const generateSplitKey = () => Math.random().toString().slice(0.8)

  const [submitEnabled, setSubmitEnabled] = useState(inputMode === User2FAInputViewMode.basic)
  const [error] = useState<Error>() // , setError - TODO: DEPRECIATE? do we only use the external prop error value, no local one now?
  const [splitKey, setSplitKey] = useState<string | undefined>((inputMode === User2FAInputViewMode.split ? generateSplitKey() : undefined))
  const [splitValue, setSplitValue] = useState<string | undefined>()

  // -------

  const resetSplitCode = () => {
    setSplitKey(generateSplitKey())
  }

  const resetInput = () => {
    const inputMode = props.inputMode ?? DefaultUser2FAInputViewMode
    if (inputMode === User2FAInputViewMode.split) {
      resetSplitCode()
    } else if (inputMode === User2FAInputViewMode.basic) {
      // TODO: implement for the basic/normal field input mode
    }
  }

  // -------

  useEffect(() => {
    resetInput()
  }, [props.error])

  // -------

  // we need to validate the input from the split mode component manually (as its not an ArkField type)
  const validateSplitCode = async (otpCode?: string): Promise<boolean> => {
    if (!otpCode || otpCode.length < 6) {
      return false // skip the more detailed validation if we don't have the min length code
    }
    let validationErrors: {[key: string]: any} | null = null
    await formSchemaSplit.validate({ otpCode: otpCode }, { abortEarly: false })
      .catch(function (err) {
        // err.name // => 'ValidationError'
        // err.errors // => [{ key: 'field_too_short', values: { min: 18 } }]
        // console.log('ArkForm - onSubmit - formSchema.validate - error: ', err)
        validationErrors = err.inner
      })
    // console.log('User2FAInputView - validateSplitCode - validationErrors: ', validationErrors)

    // if (validationErrors['otpCode'])

    return !(validationErrors && Object.keys(validationErrors).length > 0)
  }

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    if (props.isBusy) return
    const inputMode = props.inputMode ?? DefaultUser2FAInputViewMode
    if (inputMode === User2FAInputViewMode.split) {
      const otpCode = splitValue
      // console.log('User2FAInputView - onSubmit - split mode - otpCode: ', otpCode)
      const validCode = await validateSplitCode(otpCode)
      if (otpCode && validCode) {
        // console.log('User2FAInputView - onSubmit - split mode - VALID CODE - SUBMIT...')
        if (props.onSubmit) {
          props.onSubmit(otpCode)
        }
      } else {
        console.log('User2FAInputView - onSubmit - split mode - ERROR')
        // TOOD:
      }
    } else {
      const { otpCode } = fieldValues
      // console.log('User2FAInputView - onSubmit - basic mode - otpCode: ', otpCode)
      if (props.onSubmit) {
        props.onSubmit(otpCode)
      }
    }
  }

  // -------

  // NB: currently using https://www.luisguerrero.me/react-auth-code-input/
  // NB: could otherwise write our own instead, based off something like this ref: https://codedaily.io/tutorials/Create-a-Segmented-Auto-Moving-SMS-Code-Verification-Input-in-React-Native
  // NB: or try an alternative like ref: https://opensourcelibs.com/lib/react-verification-input
  // NB: resetting the field is currently done via changing the components key so it re-renders fresh - ref: https://github.com/drac94/react-auth-code-input/issues/15#issuecomment-872656385
  const renderSplit2FAInput = () => {
    return (
      <AuthCode
        key={splitKey}
        length={6} // characters={6}
        allowedCharacters='numeric' // {/^\d+$/}
        onChange={async (otpCode: string) => {
          // console.log('User2FAInputView - renderSplit2FAInputForm - AuthCode - onChange - otpCode: ', otpCode)
          const validCode = await validateSplitCode(otpCode)
          // console.log('User2FAInputView - renderSplit2FAInputForm - AuthCode - onChange - validCode: ', validCode)
          setSplitValue(otpCode)
          setSubmitEnabled(validCode)
          // auto submit if its enabled & the code is valid (correct length & all numbers)
          if (validCode && props.autoSubmit === true) {
            // console.log('User2FAInputView - onSubmit - split mode - VALID CODE - SUBMIT...')
            if (props.onSubmit) {
              props.onSubmit(otpCode)
            }
          }
        }}
        containerClassName={styles.split2FAContainer}
        inputClassName={styles.split2FAInput}
      />
    )
  }

  // NB: if inputMode === basic a regular ArkForm input field is displayed
  // NB: if inputMode === split it uses a custom component to input the code instead (which requires extra code to wire it in, separate to the form)
  const renderForm = () => {
    const inputMode = props.inputMode ?? DefaultUser2FAInputViewMode
    const loading = props.isBusy
    const formFields: Array<ArkFormField> = []
    if (inputMode === User2FAInputViewMode.basic) {
      formFields.push({ type: ArkFormFieldType.Input, key: 'otpCode', label: undefined, placeholder: 'XXXXXX', required: true, icon: 'lock', fieldProps: { autoComplete: 'off', className: styles.basic2FAInput } })
    }
    formFields.push({ type: ArkFormFieldType.FormErrorPlaceholder, key: 'formError' })
    formFields.push({ type: ArkFormFieldType.Button, key: 'submit', label: submitTitle ?? 'SUBMIT', disabled: !submitEnabled, fieldProps: { loading: loading, fluid: true } })
    return (
      <ArkForm
        formKey="tfaOtp"
        className="tfa-otp-form"
        inverted
        formError={props.error ? props.error : error /* NB: prioritising external errors over local */}
        formFields={formFields}
        formSchema={inputMode === User2FAInputViewMode.basic ? formSchemaBasic : formSchemaEmpty}
        onFormSubmit={onFormSubmit}
      >
        <Header as='h2' inverted>Enter your 2FA code</Header>
        <p>Enter the 6 digit numeric code from your authenticator app:</p>

        <ArkFormFieldPlaceholder fieldKey='formError' />
        {inputMode === User2FAInputViewMode.basic && (<ArkFormFieldPlaceholder fieldKey='otpCode' />)}
        {inputMode === User2FAInputViewMode.split && (renderSplit2FAInput())}
        <ArkFormFieldPlaceholder fieldKey='submit' />

      </ArkForm>
    )
  }

  return renderForm()
}
export default User2FAInputView
