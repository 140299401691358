import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { withAuthContext, IAuthMultiContext } from '../../../providers'
import * as ROUTES from '../../../../constants/routes'

import ArkCenterLayout from '../../../components/ArkCenterLayout'
import ArkLoader from '../../../components/ArkLoader'
import ArkPage from '../../../components/ArkPage/ArkPage'

import { Message, Segment } from 'semantic-ui-react'

/**
 * Accepts a verifyToken url arg & auto submits it to the api, redirects straight to the viewer on success
 * If the user is already verified they get redirected to the viewer
 */

interface IProps extends RouteComponentProps<{ verifyToken: string }>, IAuthMultiContext {
}
interface IState {
  verifyToken?: string
  isSubmitting: boolean
  verifySuccess: boolean
}

class VerifyEmailInputPage extends React.Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      isSubmitting: false,
      verifySuccess: false
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.runVerifyEmailToken()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { verifyToken, isSubmitting, verifySuccess } = this.state
    return (
      <ArkPage>
        <ArkCenterLayout>
          <Segment inverted>
            {verifyToken && isSubmitting && <ArkLoader message='Verifying' />}
            {verifyToken && isSubmitting === false && verifySuccess && (
              <Message positive>
                <Message.Header>Success</Message.Header>
                <p>You&apos;re now verified!</p>
              </Message>
            )}
            {verifyToken && isSubmitting === false && verifySuccess === false && (
              <Message negative>
                <Message.Header>Error</Message.Header>
                <p>Failed to verify the token</p>
              </Message>
            )}
            {!verifyToken && (
              <Message negative>
                <Message.Header>Error</Message.Header>
                <p>Token missing</p>
              </Message>
            )}
          </Segment>
        </ArkCenterLayout>
      </ArkPage>
    )
  }

  runVerifyEmailToken = async () => {
    const verifyToken = this.props.match.params.verifyToken
    if (verifyToken) {
      // NB: you do not have to be logged in to call the verify api endpoint,
      // NB: but we check if you are & skip the attempt if already verified
      let runVerify = false
      const isLoggedIn = this.props.authContext.actions.isLoggedIn()
      if (isLoggedIn) {
        const isLoggedInAndVerified = this.props.authContext.actions.isLoggedInAndVerified()
        if (isLoggedInAndVerified === false) {
          // user is logged in but not yet verified - attempt to verify them with the supplied token
          runVerify = true
        } else { // already verified, ignore this call & redirect to the default logged in page
          this.props.history.push(ROUTES.VIEWER)
        }
      } else { // not logged in - send the verify token
        runVerify = true
      }

      if (runVerify) {
        this.setState({ verifyToken: verifyToken, isSubmitting: true })

        const result = await this.props.authContext.actions.verifyEmailToken(verifyToken)
        // NB: only update the state if the component is still mounted (we haven't already redirected away from the page by the time the result goes to show)
        if (result) {
          if (this._isMounted) this.setState({ isSubmitting: false, verifySuccess: true })
          this.props.history.push(ROUTES.VIEWER)
        } else {
          if (this._isMounted) this.setState({ isSubmitting: false, verifySuccess: false })
        }
      }
    }
  }
}

export default withRouter(withAuthContext(VerifyEmailInputPage))
