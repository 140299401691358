/**
 * ArkLoaderView
 */

import React from 'react'

import ArkLoader, { ArkLoaderProps } from '../ArkLoader'

import styles from './ArkLoaderView.module.css'

interface ArkLoaderViewProps extends ArkLoaderProps {
  className?: string
  message?: string | React.ReactNode
}

const ArkLoaderView = (props: ArkLoaderViewProps) => {
  const { className, message, ...otherProps } = props

  return (
    <div className={`${styles.view} ${className || ''}`}>
      <ArkLoader message={message} {...otherProps} />
    </div>
  )
}

export default ArkLoaderView
