import React from 'react'
import { OBJECT_COMPANY_NAME, OBJECT_PROJECT_NAME_PLURAL } from 'src/constants/strings'

import ArkInfoView from 'src/core/components/ArkInfoView'
import ArkProjectManagerPage from 'src/manager/project/components/ArkProjectManagerPage/ArkProjectManagerPage'

class ProjectManagerNoProjectsPage extends React.Component {
  render () {
    return (
      <ArkProjectManagerPage disableSectionMainMenu>
        <ArkInfoView
          iconName='warning'
          title='No available projects'
          message={<div>
            <p>You don&apos;t currently have access to manage any {OBJECT_PROJECT_NAME_PLURAL} for this {OBJECT_COMPANY_NAME}.</p> {/* <strong>{this.props.company.name}</strong> */}
            <p>Please contact the person who invited you to gain access.</p>
          </div>}
        />
      </ArkProjectManagerPage>)
  }
}
export default ProjectManagerNoProjectsPage
