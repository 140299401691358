import React, { useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import _ from 'lodash'

import { FFMpegSourceAudioType, FFMpegSourceVideoType } from '../../models/StreamhubModels'
import InputSource from '../../models/InputSource'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps, ArkFormFieldPlaceholder, ArkFormFieldOption } from 'src/core/components/ArkForm/ArkForm'
import StreamhubAVInfoSummary from '../../components/StreamhubAVInfoSummary/StreamhubAVInfoSummary'

import { Image, Message } from 'semantic-ui-react'

import styles from './StreamhubSourceForm.module.css'

export enum ArkTestStreamSourceFormMode {
  Add = 'add',
  Edit = 'edit',
}

const formSchema = yup.object().shape({
  name: yup.string().optional(),
  // title: yup.number().required(),
  videoJson: yup.string().optional().test(
    'is-json',
    // eslint-disable-next-line no-template-curly-in-string
    '${path} is not valid JSON',
    (value, _context) => { if (value) { try { JSON.parse(value) } catch (e) { return false } return true } return true }),
  videoFilename: yup.string().optional(),
  audioJson: yup.string().optional().test(
    'is-json',
    // eslint-disable-next-line no-template-curly-in-string
    '${path} is not valid JSON',
    (value, _context) => { if (value) { try { JSON.parse(value) } catch (e) { return false } return true } return true }),
  audioFilename: yup.string().optional(),
  overlaysJson: yup.string().optional().test(
    'is-json',
    // eslint-disable-next-line no-template-curly-in-string
    '${path} is not valid JSON',
    (value, _context) => { if (value) { try { JSON.parse(value) } catch (e) { return false } return true } return true }),
  overlayTitle: yup.string().optional(),
  overlayShowTimecode: yup.boolean().optional()
})

export type ArkFormSaveCallback = (data: {[key: string]: any}) => Promise<boolean>
export type { ArkFormProps }

interface IProps {
  mode: ArkTestStreamSourceFormMode
  streamSource?: InputSource
  streamSourceImageUrl?: string
  sourceMediaFiles?: { video: Array<any>, audio: Array<any> }
  allStreamSources?: Array<InputSource> // for checking if the video file is already used by another source
  onCancel?: Function
  onSave?: ArkFormSaveCallback
  onDelete?: Function
  onClose?: Function
  error?: Error
}

const StreamhubSourceForm = (props: IProps) => {
  const mounted = useRef(false)

  const { streamSource, sourceMediaFiles, allStreamSources } = props

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isSaved, setIsSaved] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>(undefined)

  let videoInputs = streamSource?.videoInputs
  const videoInput = (videoInputs && videoInputs.length > 0 ? videoInputs[0] : undefined)
  const videoFilename = videoInput?.type === FFMpegSourceVideoType.file && videoInput?.filename ? videoInput?.filename : undefined

  let audioInputs = streamSource?.audioInputs
  const audioInput = (audioInputs && audioInputs.length > 0 ? audioInputs[0] : undefined)
  const audioFilename = audioInput?.type === FFMpegSourceAudioType.file && audioInput?.filename ? audioInput?.filename : undefined

  const [sourceName, setSourceName] = useState<string | undefined>(streamSource?.name)
  const [sourceVideoFilename, setSourceVideoFilename] = useState<string | undefined>(videoFilename)

  const [autoSourceNameEnabled, setAutoSourceNameEnabled] = useState<boolean>(false)
  const [autoSourceName, setAutoSourceName] = useState<string | undefined>(undefined) // preview of the auto filled source name (from the selected video asset), when the feature is enabled

  const [sourcesWithSameVideoFile, setSourcesWithSameVideoFile] = useState<Array<InputSource> | undefined>(undefined)

  // -------

  const videoFile = sourceVideoFilename !== undefined ? sourceMediaFiles?.video.find((f) => f.filename === sourceVideoFilename) : undefined
  const videoFileAVInfo = videoFile ? videoFile.avInfo?.videoInfo : undefined
  // console.log('StreamhubSourceForm - videoFile:', videoFile, ' videoFileAVInfo:', videoFileAVInfo)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // TESTING: check if the selected video file is already used by other input sources
  useEffect(() => {
    console.log('StreamhubSourceForm - useEffect(sourceVideoFilename) - sourceVideoFilename:', sourceVideoFilename)
    if (allStreamSources && allStreamSources.length > 0) {
      // find all sources that use the same video file
      const sources = allStreamSources.filter((source) => {
        const videoInputs = source.videoInputs
        if (videoInputs && videoInputs.length > 0) {
          const videoInput = videoInputs[0]
          if (videoInput.type === FFMpegSourceVideoType.file && videoInput.filename === sourceVideoFilename && source.id !== streamSource?.id) {
            return true
          }
        }
        return false
      })
      console.log('StreamhubSourceForm - useEffect(sourceVideoFilename) - sources:', sources)
      setSourcesWithSameVideoFile(sources.length > 0 ? sources : undefined)
    }
  }, [sourceVideoFilename])

  const updateAutoSourceNamePreview = (): string | undefined => {
    console.log('StreamhubSourceForm - updateAutoSourceNamePreview - sourceVideoFilename:', sourceVideoFilename, ' videoFile:', videoFile, ' videoFileAVInfo:', videoFileAVInfo)
    if (sourceVideoFilename !== undefined && videoFile !== undefined && videoFileAVInfo !== undefined) {
      // remove the dir from the filepath if it has one
      const filepath = sourceVideoFilename
      let filename: string | undefined
      let extension: string | undefined
      let dir: string | undefined
      const lastDirIndex = filepath.lastIndexOf('/')
      if (lastDirIndex >= 0 && (lastDirIndex + 1) < filepath.length) {
        filename = filepath.substring(lastDirIndex + 1)
        dir = filepath.substring(0, lastDirIndex + 1)
      } else {
        filename = filepath
      }
      console.log('StreamhubSourceForm - updateAutoSourceNamePreview - filename:', filename, ' dir:', dir)
      // remove the file extension from the filename
      const extIndex = filename.lastIndexOf('.')
      if (extIndex >= 0) {
        extension = filename.substring(extIndex)
        filename = filename.substring(0, extIndex)
      }
      console.log('StreamhubSourceForm - updateAutoSourceNamePreview - filename:', filename, ' extension:', extension)

      // TODO: ideally also remove any video info from the asset filename before we add our own to stop clashes & dupes although that might not be easy to do reliably & may have to be a basic best-guess attempt instead?
      // TESTING: very basic filename cleansing, inc. removal of common video info args (1080P) etc. so they don't clash with our generated names
      filename = filename.replace(/[_-]/gi, ' ') // remove common separator chars, replace with spaces
      filename = filename.replace(/ {2,}/gi, ' ') // replace multiple consecutive spaces with a single space
      filename = filename.replace(/(\d{3,4}p)/gi, '') // remove common resolutions (1080P, 720P etc.)
      filename = filename.replace(/(x264|h264|x265|h265|hvec)/gi, '') // remove common video codecs (x264, x265, h264, h265, hvec etc.)

      // construct the final input source name with the cleansed filename & some key video info fields we want to use to the generated name
      const fieldOrderSuffix = (fieldOrder?: string) => {
        if (fieldOrder) {
          // NB: see the `field_order` section of https://ffmpeg.org/ffprobe-all.html for all options (the below should cover all current ones ffprobe returns)...
          if (fieldOrder === 'progressive') return 'p' // 'Progressive'
          else if (fieldOrder === 'tt') return 'i' // 'Top-Top'
          else if (fieldOrder === 'bb') return 'i' // 'Bottom-Bottom'
          else if (fieldOrder === 'tb') return 'i' // 'Top-Bottom'
          else if (fieldOrder === 'bt') return 'i' // 'Bottom-Top'
          else return ''
        }
        return ''
      }
      let res = videoFileAVInfo.height ? videoFileAVInfo.height : undefined
      res = res !== undefined ? ' ' + res + fieldOrderSuffix(videoFileAVInfo.fieldOrder) : undefined
      const codecName = videoFileAVInfo.codecName?.toUpperCase() ?? undefined
      const infoArgs: Array<string> = []
      if (res) infoArgs.push(res)
      if (codecName) infoArgs.push(codecName)
      if (videoFileAVInfo.formatName) {
        // NB: some ffprobe format names include multiple, e.g: `MOV,MP4,M4A,3GP,3G2,MJ2`, when that happens fallback to the file extension instead (if its available, don't add anything here if not)
        if (videoFileAVInfo.formatName.includes(',')) {
          if (extension) infoArgs.push(extension.replace('.', '').toUpperCase())
        } else {
          infoArgs.push(videoFileAVInfo.formatName.toUpperCase())
        }
      }
      const infoArgsStr = infoArgs.length > 0 ? ' - ' + infoArgs.join(' ').trim() + '' : ''
      const autoName = `${filename.trim()}${infoArgsStr}`.trim()
      console.log('StreamhubSourceForm - updateAutoSourceNamePreview - autoName:', autoName)
      setAutoSourceName(autoName)
      return autoName
    } else {
      setAutoSourceName(undefined)
      return undefined
    }
  }

  // whenever the selected video file changes, update the auto source name preview & apply it to the sourceName if its enabled
  useEffect(() => {
    console.log('StreamhubSourceForm - useEffect(sourceVideoFilename) - sourceVideoFilename:', sourceVideoFilename, ' autoSourceNameEnabled:', autoSourceNameEnabled)
    // grab the generated auto source name & apply it to the source name if the auto source name feature is enabled
    // NB: we grab it here instead of from the state var, as it likely hasn't updated yet
    const _autoSourceName = updateAutoSourceNamePreview()
    if (autoSourceNameEnabled === true) {
      console.log('StreamhubSourceForm - useEffect(sourceVideoFilename) - apply autoSourceName:', _autoSourceName)
      setSourceName(_autoSourceName)
    }
  }, [sourceVideoFilename])

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    const { name, videoFilename, videoJson, audioFilename, audioJson, overlayTitle, overlayShowTimecode, overlaysJson } = fieldValues
    if (!videoFilename && (!videoJson || videoJson === '' || videoJson === '[]')) {
      // TODO: ideally we'd set errors for both fields directly, so they highlight (would likely need to add an optional validation callback to ArkForm to support this?)
      setError(Error('A video input is required, select a video file or enter video args to create one'))
      return
    }
    setError(undefined)
    if (props.onSave) {
      setIsSaved(false)
      setIsSubmitting(true)
      const saved = await props.onSave({ name, videoFilename, videoJson, audioFilename, audioJson, overlayTitle, overlayShowTimecode, overlaysJson })
      if (mounted.current) {
        setIsSaved((saved === true))
        setIsSubmitting(false)
      }
    }
  }

  const onFormValueChanged = async (fieldKey: string, _fieldValue: any, _oldFieldValue: any) => {
    console.log('StreamhubSourceForm - onFormValueChanged - fieldKey: ', fieldKey, ' _fieldValue: ', _fieldValue, ' _oldFieldValue: ', _oldFieldValue)
    if (fieldKey === 'name') {
      setSourceName(_fieldValue)
    } else if (fieldKey === 'videoFilename') {
      setSourceVideoFilename(_fieldValue)
      // const videoFile = sourceMediaFiles?.video.find((f) => f.filename === _fieldValue)
      // console.log('StreamhubSourceForm - onFormValueChanged - videoFilename - videoFile:', videoFile)
    } else if (fieldKey === 'autoSourceName') {
      console.log('StreamhubSourceForm - onFormValueChanged - autoSourceName - checkbox value:', _fieldValue)
      if (_fieldValue === true) {
        setAutoSourceNameEnabled(true)
        setSourceName(autoSourceName)
      } else {
        setAutoSourceNameEnabled(false)
        setSourceName(streamSource?.name ?? undefined) // reset the name to the original source name if editing & one was set, clear it if not
      }
    }
  }

  const onCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, _data: object) => {
    event.preventDefault()
    if (props.onClose) props.onClose()
  }

  // if the filename fields were used, remove them from the raw json shown on the form
  if (videoFilename && videoInputs && videoInputs.length > 0) {
    const videoInputsCopy = _.cloneDeep(videoInputs) // Array.from(videoInputs) // [...videoInputs]
    delete (videoInputsCopy[0] as any).type
    delete (videoInputsCopy[0] as any).filename
    videoInputs = videoInputsCopy
  }
  // if no other fields in the input, remove the whole input entry
  if (videoInputs && videoInputs.length > 0) {
    if (Object.keys(videoInputs[0]).length === 0) {
      const videoInputsCopy = _.cloneDeep(videoInputs)
      videoInputsCopy.splice(0, 1)
      videoInputs = videoInputsCopy
    }
  }
  if (audioFilename && audioInputs && audioInputs.length > 0) {
    const audioInputsCopy = _.cloneDeep(audioInputs)
    delete (audioInputsCopy[0] as any).type
    delete (audioInputsCopy[0] as any).filename
    audioInputs = audioInputsCopy
  }
  // if no other fields in the input, remove the whole input entry
  if (audioInputs && audioInputs.length > 0) {
    if (Object.keys(audioInputs[0]).length === 0) {
      const audioInputsCopy = _.cloneDeep(audioInputs)
      audioInputsCopy.splice(0, 1)
      audioInputs = audioInputsCopy
    }
  }
  // TESTING: if the remaining fields have only null fields, remove the whole input entry
  if (videoInputs && videoInputs.length > 0) {
    const videoInputsCopy = _.cloneDeep(videoInputs)
    if (Object.keys(videoInputsCopy[0]).every((key) => (videoInputsCopy[0] as any)[key] === null)) {
      videoInputsCopy.splice(0, 1)
      videoInputs = videoInputsCopy
    }
  }
  if (audioInputs && audioInputs.length > 0) {
    const audioInputsCopy = _.cloneDeep(audioInputs)
    if (Object.keys(audioInputsCopy[0]).every((key) => (audioInputsCopy[0] as any)[key] === null)) {
      audioInputsCopy.splice(0, 1)
      audioInputs = audioInputsCopy
    }
  }
  const videoInputsJson = (videoInputs && videoInputs.length > 0 ? JSON.stringify(videoInputs) : '')
  const audioInputsJson = (audioInputs && audioInputs.length > 0 ? JSON.stringify(audioInputs) : '')
  const overlaysJson = (streamSource?.overlays && streamSource?.overlays.length > 0 ? JSON.stringify(streamSource?.overlays) : '')

  const formFields: Array<ArkFormField> = []

  formFields.push({
    type: ArkFormFieldType.Input,
    key: 'name',
    label: 'Name/Alias',
    required: false,
    // defaultValue: name,
    value: sourceName ?? '', // NB: flipped to `value` to power it directly instead of only `defaultValue` so the auto program name feature can update it
    fieldProps: {}
  })

  const videoFileOptions: Array<ArkFormFieldOption> = []
  videoFileOptions.push({ key: 'video_none', text: 'Select a video file', value: undefined })
  if (sourceMediaFiles && sourceMediaFiles.video) {
    for (const sourceMediaFile of sourceMediaFiles.video) {
      const filename = sourceMediaFile.filename
      videoFileOptions.push({ key: filename, text: filename, value: filename })
    }
  }

  const audioFileOptions: Array<ArkFormFieldOption> = []
  audioFileOptions.push({ key: 'audio_none', text: 'Select an audio file', value: undefined })
  if (sourceMediaFiles && sourceMediaFiles.audio) {
    for (const sourceMediaFile of sourceMediaFiles.audio) {
      const filename = sourceMediaFile.filename
      audioFileOptions.push({ key: filename, text: filename, value: filename })
    }
  }

  formFields.push({ type: ArkFormFieldType.FormErrorPlaceholder, key: 'formError' })

  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'inputFieldset',
    label: 'Input',
    fields: [
      ...(sourcesWithSameVideoFile !== undefined
        ? [
          {
            type: ArkFormFieldType.Field,
            key: 'videoInputFileDupeNotice',
            content: (
              (<div className='field'>
                <div className={styles.videoInputFileDupeNotice}>
                  <p><strong>NOTE:</strong> This input video file is already used by {sourcesWithSameVideoFile.length} other input source{sourcesWithSameVideoFile.length !== 1 ? 's' : ''}.</p>
                  <p>Check if you can re-use an existing source instead, unless they are customised in some way.</p>
                  <p>Other potentially duplicate input sources:</p>
                  <ul>
                    {sourcesWithSameVideoFile.map((s) => (<li key={s.id}>{s.name ?? `Source ${s.id}`}</li>))}
                  </ul>
                </div>
              </div>))
          }
        ]
        : []
      ),
      {
        type: ArkFormFieldType.Dropdown,
        key: 'videoFilename',
        label: 'Video File',
        className: `${styles.videoFilename} ${sourceVideoFilename !== undefined ? styles.hasValue : ''}`,
        required: false,
        // defaultValue: videoFilename,
        value: sourceVideoFilename,
        options: videoFileOptions,
        fieldProps: { scrolling: true } // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
      },
      ...(sourceVideoFilename !== undefined && videoFile && videoFileAVInfo
        ? [
          {
            type: ArkFormFieldType.Field,
            key: 'videoFileAVInfo',
            content: (<StreamhubAVInfoSummary className={styles.assetAVInfo} filename={sourceVideoFilename} videoAVInfo={videoFileAVInfo} />)
          }
        ]
        : []
      ),
      {
        type: ArkFormFieldType.Checkbox,
        key: 'autoSourceName',
        label: 'Auto Source Name: ' + (autoSourceName ? '' + autoSourceName + '' : '-'),
        required: false,
        disabled: (sourceVideoFilename === undefined || videoFile === undefined || videoFileAVInfo === undefined),
        value: autoSourceNameEnabled
      },
      {
        type: ArkFormFieldType.Fieldset,
        key: 'inputAudioFieldset',
        label: 'Audio',
        className: styles.inputAudioFieldset,
        fields: [
          {
            type: ArkFormFieldType.Field,
            key: 'audioInputNotice',
            content: (
              (<div className='field'>
                <p className={styles.audioInputNotice}>
                  Optionally select an audio file to play over the video. <br />
                  <span className={styles.audioInputWarning}><strong>WARNING:</strong> Adding a separate audio input requires more resources when streaming, so is recommended against unless you really need it! Its better to pre-encode the video with audio if possible.</span>
                </p>
              </div>))
          },
          {
            type: ArkFormFieldType.Dropdown,
            key: 'audioFilename',
            label: 'Audio File',
            required: false,
            defaultValue: audioFilename,
            options: audioFileOptions,
            fieldProps: { scrolling: true } // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
          }
        ],
        collapsible: true,
        collapsed: !(audioFilename !== undefined && audioFilename.length > 0)
      }
    ],
    collapsible: false,
    collapsed: false
  })

  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'overlaysFieldset',
    label: 'Overlays',
    fields: [
      {
        type: ArkFormFieldType.Field,
        key: 'overlaysNotice',
        // wrapperProps: { className: styles.flagsWarningField },
        content: (
          (<div className='field'>
            <p className={styles.overlaysWarning}>
              <strong>WARNING:</strong> Adding overlay text or timecode requires more resources when streaming the source, so is recommended against unless you really need it!
            </p>
          </div>))
      },
      {
        type: ArkFormFieldType.Group,
        key: 'overlayOptions',
        fields: [
          { type: ArkFormFieldType.Input, key: 'overlayTitle', label: 'Overlay Title/Text', required: false, defaultValue: streamSource?.overlayTitle ?? '', fieldProps: {} },
          { type: ArkFormFieldType.Checkbox, key: 'overlayShowTimecode', label: 'Show Timecode', required: false, defaultValue: streamSource?.overlayShowTimecode ?? false, fieldProps: { style: { marginTop: 32, marginLeft: 10 } } }
        ],
        fieldProps: { widths: 'equal' }
      }
    ],
    collapsible: true,
    collapsed: !((streamSource?.overlayTitle?.length !== undefined && streamSource?.overlayTitle?.length > 0) || streamSource?.overlayShowTimecode)
  })

  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'advancedFieldset',
    label: 'Advanced Options',
    fields: [
      { type: ArkFormFieldType.TextArea, key: 'videoJson', label: 'Video Args (JSON)', required: false, defaultValue: videoInputsJson, fieldProps: { rows: 1 } },
      { type: ArkFormFieldType.TextArea, key: 'audioJson', label: 'Audio Args (JSON)', required: false, defaultValue: audioInputsJson, fieldProps: { rows: 1 } },
      { type: ArkFormFieldType.TextArea, key: 'overlaysJson', label: 'Overlays (JSON)', required: false, defaultValue: overlaysJson, fieldProps: { rows: 4 } }
    ],
    collapsible: true,
    collapsed: !!(videoInputsJson === '' && audioInputsJson === '' && overlaysJson === '') // open by default if any of the fields are set
  })

  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { disabled: isSubmitting, onClick: onCancel, floated: 'left' } },
      { type: ArkFormFieldType.OKButton, key: 'submit', label: (props.mode === ArkTestStreamSourceFormMode.Edit ? 'SAVE' : 'CREATE'), fieldProps: { loading: isSubmitting, floated: 'right' } }
    ],
    fieldProps: { widths: 'equal' }
  })

  return (
    <ArkForm
      formKey="streamSource"
      className={`${styles.form} ${styles.sourceForm}`}
      inverted
      header={(props.mode === ArkTestStreamSourceFormMode.Edit ? 'Edit' : 'Add') + ' Stream Source' + (props.streamSource ? ' ' + props.streamSource.id : '')}
      formError={error ?? props.error}
      formFields={formFields}
      formSchema={formSchema}
      updateFieldValuesOnExternalChange={true} // TESTING: allow field value changes applied at this level to be tracked/applied within the ArkForm values
      onFormSubmit={onFormSubmit}
      onValueChanged={onFormValueChanged}
      insideModal={true}
    >
      <div className={styles.wrapper}>
        <div className={styles.image}>
          {props.streamSource && props.streamSourceImageUrl && (
            <Image src={props.streamSourceImageUrl} key={props.streamSource.updatedAt?.toString() ?? props.streamSource.createdAt?.toString() ?? props.streamSource.id ?? '' /* TODO: only change the key when we want to re-render an image after its changed! */} /* ref: https://stackoverflow.com/a/61023057 */ />
          )}
        </div>
        <div className={styles.formFields}>
          {isSaved && (<>
            <Message positive>
              <Message.Header>Stream Source {props.mode === ArkTestStreamSourceFormMode.Edit ? 'Updated' : 'Created'}</Message.Header>
              <Message.Item>The stream source has been {props.mode === ArkTestStreamSourceFormMode.Edit ? 'updated' : 'created'} successfully</Message.Item>
            </Message>
            <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={onCancel} style={{ marginBottom: '20px' }}>
              OK
            </ArkButton>
          </>)}
          {/* streamSource && (<div style={{ paddingBottom: 20 }}>Source ID: {streamSource.id}</div>) */}
          <ArkFormFieldPlaceholder fieldKey="formError" />
          <ArkFormFieldPlaceholder fieldKey="name" />
          <ArkFormFieldPlaceholder fieldKey="inputFieldset" />
          <ArkFormFieldPlaceholder fieldKey="overlaysFieldset" />
          <ArkFormFieldPlaceholder fieldKey="advancedFieldset" />
          <ArkFormFieldPlaceholder fieldKey="buttons" />
        </div>
      </div>
    </ArkForm>
  )
}

export default StreamhubSourceForm
