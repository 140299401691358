import React, { useContext } from 'react'

import * as CONFIG from 'src/constants/config'
import { STATUS_ENABLED } from 'src/constants/config'

import { NavContext, useProjectStatus, UserContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import { Channel, ProjectGroup, ProjectUser } from 'src/core/models'

import ProjectGroupAccessToggle from './ProjectGroupAccessToggle'
import ProjectGroupUsersPanel from './ProjectGroupUsersPanel'
import ProjectGroupChannelsPanel from './ProjectGroupChannelsPanel'
import ProjectGroupProgramsPanel from './ProjectGroupProgramsPanel'
import ProjectGroupDeleteView, { ProjectGroupDeleteCallback } from './ProjectGroupDeleteView'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkButton from 'src/core/components/ArkButton'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_CHANNEL_NAME_PLURAL, OBJECT_USER_NAME_PLURAL, OBJECT_GROUP_NAME, OBJECT_COMPANY_SHORTNAME } from 'src/constants/strings'

import styles from './ProjectGroupSidebar.module.css'

export type ProjectGroupEditCallback = (group: ProjectGroup) => void

interface IProps {
  companyId: number
  projectId: number
  group?: ProjectGroup
  onChange?: Function
  onEdit?: ProjectGroupEditCallback
  onDidDelete?: ProjectGroupDeleteCallback
  onGroupUpdated: (group: ProjectGroup) => void

  loadingUsers: boolean
  groupUsers?: Array<ProjectUser>
  loadingChannels: boolean
  groupChannels?: Array<Channel>
}

const ProjectGroupSidebar = (props: IProps) => {
  const { companyId, projectId, group, onChange, onEdit: _onEdit, onDidDelete: _onDidDelete, onGroupUpdated: _onGroupUpdated, loadingUsers, groupUsers, loadingChannels, groupChannels } = props

  const userContext = useContext(UserContext)
  const navContext = useContext(NavContext)
  const projectStatus = useProjectStatus()

  if (!group) return null
  // const isDefaultGroup = group.isDefaultGroup
  const isParentGroup = group.isParentGroup
  // const groupType = isDefaultGroup ? 'Default (Full)' : (isOrganisationGroup ? 'Organisation' : 'Full')

  // FIXME refactor since moving from list item
  const renderGroupUsers = () => {
    const userCount = !loadingUsers ? groupUsers?.length ?? 0 : undefined
    return (
      <div className={styles.groupUsers + ' ' + styles.list}>
        <ArkHeader as='h3' inverted>{OBJECT_USER_NAME_PLURAL}{userCount !== undefined ? ': ' + userCount : ''}</ArkHeader>
        {loadingUsers && <div className={styles.loader}><ArkLoader small inline /></div>}
        {!loadingUsers && groupUsers && groupUsers.length > 0 && (
          <ul>
            {groupUsers.map((user: ProjectUser) => {
              return (
                <li key={'group_user_' + user.id}>
                  <ArkAvatar
                    type={user.userAvatarType()}
                    name={user.name()}
                    size="30"
                  />
                </li>
              )
            })}
          </ul>
        )}
        {!loadingUsers && (!groupUsers || groupUsers.length === 0) && (<span>⚠️ No {OBJECT_USER_NAME_PLURAL} assigned to this {OBJECT_GROUP_NAME}</span>)}
      </div>
    )
  }

  const renderGroupChannels = () => {
    const channelCount = !loadingChannels ? groupChannels?.length ?? 0 : undefined
    return (
      <div className={styles.groupChannels + ' ' + styles.list}>
        <ArkHeader as='h3' inverted>{OBJECT_CHANNEL_NAME_PLURAL}{channelCount !== undefined ? ': ' + channelCount : ''}</ArkHeader>
        {loadingChannels && <div className={styles.loader}><ArkLoader small inline /></div>}
        {!loadingChannels && groupChannels && groupChannels.length > 0 && (
          <ul>
            {groupChannels.map((channel: Channel) => {
              return (
                <li key={'group_channel_' + channel.id}>
                  <ArkAvatar
                    className={styles.channelAvatar}
                    name={channel.name}
                    size="30"
                    textSizeRatio={2.2}
                    round={false}
                    color={channel.colour}
                    onlineStatus={STATUS_ENABLED ? projectStatus.getChannelOnlineStatus(channel.id) : undefined}
                    variant='channel'
                  />
                </li>
              )
            })}
          </ul>
        )}
        {!loadingChannels && (!groupChannels || groupChannels.length === 0) && (<span>⚠️ No {OBJECT_CHANNEL_NAME_PLURAL} assigned to this {OBJECT_GROUP_NAME}</span>)}
      </div>
    )
  }

  const onEdit = () => {
    if (_onEdit && group) _onEdit(group)
  }

  const onGotoOrgGroupAdmin = () => {
    console.log('ProjectGroupSidebar - onGotoOrgGroupAdmin')
    const orgGroupId = group.mirrorGroupId
    navContext.actions.goto(ROUTES.COMPANY_MANAGER_GROUPS.replace(':groupId?', (orgGroupId ? '' + orgGroupId : '')))
  }

  const isCompanyAdminOrHigher = userContext.actions.isCompanyAdminOrHigher()

  return (
    <>
      <ArkPanel bordered className={styles.sidebarHeader}>
        <ArkPanel.Header className={styles.sidebarTitle} title={group.name} iconName={'group'} />
        <ArkIconButton
          className={styles.menuButton}
          name='settings'
          onClick={onEdit}
          size={24}
        />
      </ArkPanel>

      <ArkSpacer />

      {/* <ArkButton type="button" fluid size="large" onClick={onEdit}>EDIT {OBJECT_GROUP_NAME}</ArkButton> */}

      <ArkPanel bordered className={styles.sidebarPanel}>
        <ArkPanel.Properties>
          {/* <ArkPanel.PropertyRow title='ID:' value={group.id} /> */}
          {/* <ArkPanel.PropertyRow title={OBJECT_GROUP_NAME + ' Type:'} value={groupType} /> */}
          {/* <ArkPanel.PropertyRow title='Status:' value={group.enabled ? 'ENABLED' : 'DISABLED'} /> */}{/* NB: now hidden as it only dupes the access togle below, so is somewhat pointless */}
          <ArkPanel.PropertyRow title={'Access:'} value={<>
            <ProjectGroupAccessToggle
              companyId={companyId}
              projectId={projectId}
              group={group}
              onChange={(value: boolean) => {
                console.log('CompanyGroupListItem - CompanyGroupAccessToggle - onChange - value:', value)
                if (value !== group.enabled) {
                  group.enabled = value
                  _onGroupUpdated(group)
                }
              }}
              showStatusText={false}
            />
          </>} />
          {group.isMirroredGroup() && (
            <>
              <ArkPanel.PropertyRow title={'Mirrored ' + OBJECT_COMPANY_SHORTNAME + ' ' + OBJECT_GROUP_NAME + ':'} value={group.mirrorGroup?.name ?? 'N/A'} />
              {isCompanyAdminOrHigher && (
                <ArkButton fluid size='large' color='blue' className={styles.mirroredGroupAdminBtn} onClick={onGotoOrgGroupAdmin}>EDIT {OBJECT_COMPANY_SHORTNAME} {OBJECT_GROUP_NAME}</ArkButton>
              )}
            </>
          )}
        </ArkPanel.Properties>
      </ArkPanel>

      <ArkSpacer />

      {renderGroupUsers()}

      <ArkSpacer large />

      {renderGroupChannels()}

      <ArkSpacer large />

      {!isParentGroup && (
        <>

          <ProjectGroupUsersPanel
            companyId={companyId}
            projectId={projectId}
            group={group}
            onChange={onChange}
          />

          <ArkSpacer />

          <ProjectGroupChannelsPanel
            companyId={companyId}
            projectId={projectId}
            group={group}
            onChange={onChange}
          />

          {/* NB: we don't support group>program mapping in the viewer yet, so hiding this on the live build until we do */}
          {/* UPDATE: commented out for all uses for now, so we can tweak the layout/spacing to work properly for initial lauch */}
          {CONFIG.PROJECT_MGR_GROUP_PROGRAMS_ENABLED && (
            <>
              <ArkSpacer />
              <ProjectGroupProgramsPanel
                companyId={companyId}
                projectId={projectId}
                group={group}
                onChange={onChange}
              />
            </>
          )}

        </>
      )}

      <ArkSpacer grow />

      <ProjectGroupDeleteView
        companyId={companyId}
        projectId={projectId}
        group={group}
        onDidDelete={_onDidDelete}
      />
    </>
  )
}

export default ProjectGroupSidebar
