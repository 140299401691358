import React from 'react'

import ArkViewerPage from 'src/viewer/components/ArkViewerPage/ArkViewerPage'
import Ark2FAAuthView from 'src/core/components/Ark2FAAuthView'

export interface Viewer2FAAuthPageProps {}

const Viewer2FAAuthPage = (_props: Viewer2FAAuthPageProps) => {
  return (
    <ArkViewerPage disableSectionMainMenu>
      <Ark2FAAuthView />
    </ArkViewerPage>
  )
}
export default Viewer2FAAuthPage
