import React, { useContext } from 'react'

import ServerAPIClient from '../services/ServerAPIClient'
import ServerAuthAPI, { ServerEventTypeAuth } from '../services/ServerAuthAPI' // ServerEventTypeCompanyAuth, ServerEventTypeProjectAuth
import { AuthSession, IAuthLoginService, User } from '../models'

export enum AuthStatus {
  loading, tfa, loggedIn, loggedOut // TODO: what should the auth status be set to if we hit errors, loggedOut, or is it worth (re)adding a specific .error status? (which would imply being logged out?)
}

export enum AuthCompanyStatus {
  loading, tfa, verified, unrestricted
}

export enum AuthProjectStatus {
  loading, tfa, verified, unrestricted
}

export interface IAuthStore {
  // auth
  authStatus?: AuthStatus
  authUpdated?: Date
  authError?: Error
  // cache (used during multi stage login & registration handling, as well as invites)
  cacheType?: 'login' | 'register' | 'sso'
  cacheEmail?: string
  cacheName?: { firstName?: string, lastName?: string }
  // company & project 2FA
  // TODO: DEPRECIATE - org/project forced 2fa switch auth
  // TODO: track the token as a state var so routers/components can listen for changes & update (needs to be kept in sync wth the auth api like the auth status)
  // TODO: could otherwise track the company/project 'auth status' here rather than the token itself maybe? (so its closer in setup & usage to the main auth state vars here?)
  // companyAuthStatus?: AuthCompanyStatus
  // projectAuthStatus?: AuthProjectStatus
  // logout callback
  logoutCallback?: () => void // NB: when this is specified the default post login redirect to home is skipped & its up to the callback to redirect where it wants too
}

export interface IAuthActions {
  // -------
  isLoggedIn: () => boolean
  isLoggedInAndVerified: () => boolean
  getAuthToken: () => string | undefined
  getAuthDeviceUUID: () => string | undefined
  // -------
  registerUserWithEmailAndPassword: (email: string, password: string, firstName?: string, lastName?: string, phone?: string) => Promise<void>
  loginWithEmailAndPassword: (email: string, password: string) => Promise<void>
  // -------
  registerUserWithSSOToken: (email: string, loginServiceId: number, companyServiceId: number, ssoToken: string, firstName?: string, lastName?: string, phone?: string) => Promise<void>
  loginWithSSOToken: (email: string, loginServiceId: number, companyServiceId: number, ssoToken: string) => Promise<void>
  // -------
  logout: (callback?: () => void) => void
  // -------
  forgotEmailPassword: (email: string) => Promise<boolean>
  resetEmailPassword: (resetToken: string, newPassword: string) => Promise<boolean>
  // -------
  // checkEmailExists: (email: string) => Promise<boolean>
  emailLoginLookup: (email: string) => Promise<IAuthLoginService>
  // -------
  loginExternalDevice: (deviceUUID: string) => Promise<boolean>
  // -------
  clearAuthError: () => void
  // -------
  resendVerifyEmail: () => Promise<boolean>
  verifyEmailToken: (verifyToken: string) => Promise<boolean>
  // -------
  sendPhoneSMSCode: () => Promise<boolean>
  verifyPhoneSMSCode: (verifyCode: string) => Promise<boolean>
  // -------
  requiresTFACode: () => boolean
  getAuthTFAToken: () => string | undefined
  verify2FA: (otpCode: string) => Promise<User | undefined>
  // -------
  updateUserInfo: (userId: number, values: {[key: string]: any}) => Promise<User>
  // -------
  isCompanyAuthTFARequired: () => boolean
  // getCompanyAuthToken: () => string | undefined
  // hasCompanyAuthToken: () => boolean
  // verifyCompany2FA: (companyId: number, otpCode: string) => Promise<boolean | undefined>
  // -------
  isProjectAuthTFARequired: () => boolean
  // getProjectAuthToken: () => string | undefined
  // hasProjectAuthToken: () => boolean
  // verifyProject2FA: (companyId: number, projectId: number, otpCode: string) => Promise<boolean | undefined>
  // -------
  getUserAuthSessions: () => Promise<Array<AuthSession>>
  updateUserAuthSessionDeviceName: (deviceUUID: string, deviceName: string) => Promise<boolean>
  logoutUserAuthSession: (deviceUUID: string) => Promise<boolean>
  logoutAllUserAuthSessions: () => Promise<boolean>
  // -------
  cacheLoginEmail: (email?: string) => void
  cacheRegisterEmail: (email?: string, firstName?: string, lastName?: string) => void
  cacheSSOEmail: (email?: string) => void
  // -------
  cacheClear: () => void
}

export interface IAuthContext {
  actions: IAuthActions
  store: IAuthStore
}

export interface IAuthMultiContext {
  authContext: IAuthContext
}

export const AuthContext = React.createContext<IAuthContext>({} as IAuthContext)

export const useAuth = () => useContext(AuthContext)

export interface AuthProviderProps {
  apiClient: ServerAPIClient
  authApi: ServerAuthAPI
  children?: React.ReactNode
}
export interface AuthProviderState extends IAuthStore {
}

class AuthProvider extends React.Component<AuthProviderProps, AuthProviderState> {
  constructor (props: AuthProviderProps) {
    super(props)
    this.state = {}
    // listen for auth change events (login/logout, maybe token refreshes too?)
    this.props.authApi.on(ServerEventTypeAuth, this.onAuthChange)

    // TODO: DEPRECIATE - org/project forced 2fa switch auth
    // this.props.authApi.on(ServerEventTypeCompanyAuth, this.onCompanyAuthChange)
    // this.props.authApi.on(ServerEventTypeProjectAuth, this.onProjectAuthChange)
  }

  componentDidMount () {
    this.load()
  }

  // -------

  // checks if a user auth token is cached & re-validates it to check the login status
  // NB: see onAuthChange for success handling
  // NB: the ServerAuthAPI initLoggedInUser call will trigger the onAuthChange on success
  // NB: or trigger a logout if it fails, if the user wasn't logged in (no user object in the local storage) it'll do nothing
  load = async () => {
    console.log('AuthProvider - load')
    try {
      this.setState({ authStatus: AuthStatus.loading, authError: undefined })
      const user = await this.props.authApi.initLoggedInUser()
      if (user) {
        this.setState({ authStatus: AuthStatus.loggedIn, authUpdated: new Date(), authError: undefined })
      } else {
        // TESTING HERE: load previously cached auth details (for login/reg/sso handling left mid-way through)
        // NB: make sure to call this BEFORE setting the authState (otherwise other components will see the logged out state & not the cached auth details & run before they get set)
        this.loadAuthCache()
        this.setState({ authStatus: AuthStatus.loggedOut, authUpdated: new Date(), authError: undefined })
      }
    } catch (error) {
      console.error('AuthProvider - load - error: ', error)
      this.setState({ authStatus: AuthStatus.loggedOut, authUpdated: new Date(), authError: error as Error }) // authStatus: AuthStatus.error
    }
  }

  // -------

  // ServerAuthAPI events

  onAuthChange = (user: User) => {
    console.log('AuthProvider - onAuthChange - user: ', user, ' authUserStatus: ', this.state.authStatus)
    const { authStatus } = this.state
    if (user && authStatus !== AuthStatus.loggedIn) {
      console.log('AuthProvider - onAuthChange - LOGIN')
      this.setState({ authStatus: AuthStatus.loggedIn, authUpdated: new Date(), authError: undefined, logoutCallback: undefined })
    } else if (user && authStatus === AuthStatus.loggedIn) {
      console.log('AuthProvider - onAuthChange - UPDATE (LOGGED IN)')
      this.setState({ authStatus: AuthStatus.loggedIn, authUpdated: new Date(), authError: undefined, logoutCallback: undefined })
      this.cacheClear() // clear any cached login/registration data
    } else if (!user && authStatus !== AuthStatus.loggedOut) {
      console.log('AuthProvider - onAuthChange - LOGOUT')
      this.setState({ authStatus: AuthStatus.loggedOut, authUpdated: new Date(), authError: undefined })
      // post logout callback - run it if one was set & then clear/reset it
      // console.log('AuthProvider - onAuthChange - LOGOUT - this.state.logoutCallback:', this.state.logoutCallback)
      if (this.state.logoutCallback) {
        this.state.logoutCallback()
        this.setState({ logoutCallback: undefined })
      }
    } else if (!user && authStatus === AuthStatus.loggedOut) {
      console.log('AuthProvider - onAuthChange - UPDATE (LOGGED OUT)')
      this.setState({ authStatus: AuthStatus.loggedOut, authUpdated: new Date(), authError: undefined, logoutCallback: undefined })
    }
  }

  // -------

  isLoggedIn = () =>
    this.props.authApi.isLoggedIn()

  isLoggedInAndVerified = () => {
    const user = this.props.authApi.authUser
    return !!(this.props.authApi.isLoggedIn() && user && user?.emailVerified)
  }

  // -------

  getAuthToken = () =>
    this.props.authApi.authToken

  getAuthDeviceUUID = () =>
    this.props.authApi.authDeviceUUID

  // -------

  registerUserWithEmailAndPassword = async (email: string, password: string, firstName?: string, lastName?: string, phone?: string) => {
    try {
      this.setState({ authStatus: AuthStatus.loading, authError: undefined })
      // onAuthChange will fire on success & update the auth status
      await this.props.authApi.registerUserWithEmailAndPassword(email, password, firstName, lastName, phone)
      // NB: onAuthChange will fire on success & update the auth status etc.
      // TODO: what about 2fa?? do we need to handle it here?? <<<<
    } catch (error) {
      console.error('AuthProvider - registerUserWithEmailAndPassword - error: ', error)
      const err: Error | undefined = error
      this.setState({ authStatus: AuthStatus.loggedOut, authUpdated: new Date(), authError: err }) // authStatus: AuthStatus.error
    }
  }

  loginWithEmailAndPassword = async (email: string, password: string) => {
    try {
      this.setState({ authStatus: AuthStatus.loading, authError: undefined })
      // onAuthChange will fire on success & update the auth status
      const user = await this.props.authApi.loginUserWithEmailAndPassword(email, password)
      // check if TFA is required (login response will be null, & onAuthChange won't fire, so we catch & handle it here)
      if (!user && !this.isLoggedIn() && this.requiresTFACode()) {
        console.log('AuthProvider - loginWithEmailAndPassword - 2FA REQUIRED...')
        this.setState({ authStatus: AuthStatus.tfa, authError: undefined }) // TESTING: set a specific tfa/2fa status for the view component to show the tfa input
      }
    } catch (error) {
      console.error('AuthProvider - loginWithEmailAndPassword - error: ', error)
      this.setState({ authStatus: AuthStatus.loggedOut, authUpdated: new Date(), authError: error as Error }) // authStatus: AuthStatus.error
    }
  }

  registerUserWithSSOToken = async (email: string, loginServiceId: number, companyServiceId: number, ssoToken: string, firstName?: string, lastName?: string, phone?: string): Promise<void> => {
    console.log('AuthProvider - registerUserWithSSOToken - email:', email, ' loginServiceId:', loginServiceId, ' companyServiceId:', companyServiceId, ' ssoToken:', ssoToken, ' firstName:', firstName, ' lastName:', lastName, ' phone:', phone)
    try {
      this.setState({ authStatus: AuthStatus.loading, authError: undefined })
      // onAuthChange will fire on success & update the auth status
      await this.props.authApi.registerUserWithSSOToken(email, loginServiceId, companyServiceId, ssoToken, firstName, lastName, phone)
      // NB: onAuthChange will fire on success & update the auth status etc.
      // NB: no (custom) 2FA/TFA to deal with here (unlike the regular email/password login handling), SSO should handle it all
    } catch (error) {
      console.error('AuthProvider - registerUserWithSSOToken - error: ', error)
      const err: Error | undefined = error
      this.setState({ authStatus: AuthStatus.loggedOut, authUpdated: new Date(), authError: err }) // authStatus: AuthStatus.error
    }
  }

  loginWithSSOToken = async (email: string, loginServiceId: number, companyServiceId: number, ssoToken: string) => {
    console.log('AuthProvider - loginWithSSOToken - email:', email, ' loginServiceId:', loginServiceId, ' companyServiceId:', companyServiceId, ' ssoToken:', ssoToken)
    try {
      this.setState({ authStatus: AuthStatus.loading, authError: undefined })
      // onAuthChange will fire on success & update the auth status
      await this.props.authApi.loginWithSSOToken(email, loginServiceId, companyServiceId, ssoToken)
      // NB: no 2FA/TFA to deal with here (unlike the regular email/password login handling)
    } catch (error) {
      console.error('AuthProvider - loginWithSSOToken - error: ', error)
      this.setState({ authStatus: AuthStatus.loggedOut, authUpdated: new Date(), authError: error as Error }) // authStatus: AuthStatus.error
    }
  }

  // logout the current user
  // @param callback - optional callback to run after the logout completes (eg: to redirect the logged out user to a specific page)
  // NB: when `callback` is specified the default post login redirect to home is skipped & its up to the callback to redirect where it wants too (or the user will remain on the page they were on when/before they logged out)
  logout = async (callback?: () => void) => {
    console.log('AuthProvider - logout')
    if (callback) {
      await new Promise((resolve) => this.setState({ logoutCallback: callback }, () => resolve(this.state)))
    } else {
      if (this.state.logoutCallback) await new Promise((resolve) => this.setState({ logoutCallback: undefined }, () => resolve(this.state)))
    }
    this.cacheClear() // clear any cached login/registration data (so it doesn't attempt to relogin as soon as the logout completes)
    this.props.authApi.logout()
    // NB: no result or errors to handle on logout, onAuthChange will fire with the change & trigger the auth status update
  }

  // -------

  forgotEmailPassword = async (email: string) =>
    this.props.authApi.forgotEmailPassword(email)

  resetEmailPassword = async (resetToken: string, newPassword: string) =>
    this.props.authApi.resetEmailPassword(resetToken, newPassword)

  // -------

  // NB: this currently returns its result directly, NOT via the provider state, & throws its error directly
  // TODO: how best to handle this via the provider state, if it should/makes sense?
  // TODO: if moving this to use the provider state, likely clear the authError on submit if ones set?
  // TODO: rename to something like 'lookupLoginEmail' or similar perhaps?
  // TODO: July 2023 - see the comments in the ServerAuthAPI for this method re okta/auth0 sso api handling changes in the response from this api call <<<
  emailLoginLookup = async (email: string) =>
    this.props.authApi.emailLoginLookup(email)

  // -------

  loginExternalDevice = async (deviceUUID: string) =>
    this.props.authApi.loginExternalDevice(deviceUUID)

  // -------

  // TESTING: allow the auth error (if one is set) to be cleared manually
  // currently testing this from the email lookup form, so token expired & similar errors show straight away on (auto) logout
  // instead of showing only once the user gets to the password loging form (which is after the email lookup one)
  // NB: wouldn't currently need this if the checkEmailExists/emailLoginLookup handled its updates via the provider state instead of returning direct
  clearAuthError = () => {
    // NB: NOT updating the authUpdated date as the authStatus isn't updated here
    this.setState({ authError: undefined })
  }

  // -------

  resendVerifyEmail = async () =>
    this.props.authApi.resendVerifyEmail()

  verifyEmailToken = async (verifyToken: string) =>
    this.props.authApi.verifyEmailToken(verifyToken)

  // -------

  sendPhoneSMSCode = async (): Promise<boolean> => {
    return this.props.authApi.sendPhoneSMSCode()
  }

  verifyPhoneSMSCode = async (verifyCode: string): Promise<boolean> => {
    return this.props.authApi.verifyPhoneSMSCode(verifyCode)
  }

  // -------

  requiresTFACode = () =>
    this.props.authApi.requiresTFACode()

  getAuthTFAToken = () =>
    this.props.authApi.authTFAToken ?? undefined

  verify2FA = async (otpCode: string) => {
    return await this.props.authApi.verify2FA(otpCode) ?? undefined
  }

  // -------

  updateUserInfo = async (userId: number, values: {[key: string]: any}): Promise<User> =>
    await this.props.authApi.updateUserInfo(userId, values)

  // -------

  isCompanyAuthTFARequired = () =>
    this.props.authApi.companyAuthTFARequired

  // TODO: DEPRECIATE - org/project forced 2fa switch auth

  // getCompanyAuthToken = () =>
  //   this.props.authApi.companyAuthTFAToken

  // hasCompanyAuthToken = () =>
  //   this.getCompanyAuthToken() !== undefined

  // verifyCompany2FA = async (companyId: number, otpCode: string): Promise<boolean | undefined> => {
  //   return await this.props.authApi.verifyCompany2FA(companyId, otpCode)
  // }

  // loadCompanyAuthStatus = () => {
  //   // TODO: <<<<
  //   // companyAuthStatus
  // }

  // onCompanyAuthChange = () => {
  //   const { companyAuthStatus } = this.state
  //   console.log('AuthProvider - onCompanyAuthChange - isCompanyAuthTFARequired: ', this.isCompanyAuthTFARequired(), ' hasCompanyAuthToken: ', this.hasCompanyAuthToken(), ' companyAuthStatus: ', companyAuthStatus)
  //   if (this.isCompanyAuthTFARequired()) {
  //     // TODO: just because we have a company 2fa auth token doesn't mean we know if its still valid? although we could let it be used & then let the error trigger further handling & state changes?..
  //     if (this.hasCompanyAuthToken()) {
  //       this.setState({ companyAuthStatus: AuthCompanyStatus.verified }) // TODO: <<<< ??
  //     } else {
  //       this.setState({ companyAuthStatus: AuthCompanyStatus.tfa })
  //     }
  //   } else {
  //     this.setState({ companyAuthStatus: AuthCompanyStatus.unrestricted })
  //   }
  // }

  // -------

  isProjectAuthTFARequired = () =>
    this.props.authApi.projectAuthTFARequired

  // TODO: DEPRECIATE - org/project forced 2fa switch auth

  // getProjectAuthToken = () =>
  //   this.props.authApi.projectAuthTFAToken

  // hasProjectAuthToken = () =>
  //   this.getProjectAuthToken() !== undefined

  // verifyProject2FA = async (companyId: number, projectId: number, otpCode: string): Promise<boolean | undefined> => {
  //   return await this.props.authApi.verifyProject2FA(companyId, projectId, otpCode)
  // }

  // loadProjectAuthStatus = () => {
  //   // TODO: <<<<
  //   // projectAuthStatus
  // }

  // onProjectAuthChange = () => {
  //   const { projectAuthStatus } = this.state
  //   console.log('AuthProvider - onProjectAuthChange - isProjectAuthTFARequired: ', this.isProjectAuthTFARequired(), ' hasProjectAuthToken: ', this.hasProjectAuthToken(), ' projectAuthStatus: ', projectAuthStatus)
  //   if (this.isProjectAuthTFARequired()) {
  //     // TODO: just because we have a project 2fa auth token doesn't mean we know if its still valid? although we could let it be used & then let the error trigger further handling & state changes?..
  //     if (this.hasProjectAuthToken()) {
  //       this.setState({ projectAuthStatus: AuthProjectStatus.verified }) // TODO: <<<< ??
  //     } else {
  //       this.setState({ projectAuthStatus: AuthProjectStatus.tfa })
  //     }
  //   } else {
  //     this.setState({ projectAuthStatus: AuthProjectStatus.unrestricted })
  //   }
  // }

  // -------

  getUserAuthSessions = async () => {
    try {
      const authSessions = await this.props.authApi.getUserAuthSessions()
      console.log('AuthProvider - getUserAuthSessions - authSessions: ', authSessions)
      // sort/order by activity date (newest first)
      authSessions.sort((a: AuthSession, b: AuthSession) => a.lastActivity !== undefined && b.lastActivity !== undefined ? (a.lastActivity.getTime() - b.lastActivity.getTime()) * -1 : 0)
      return authSessions
    } catch (error) {
      console.error('AuthProvider - getUserAuthSessions - error: ', error)
      throw error
    }
  }

  updateUserAuthSessionDeviceName = async (deviceUUID: string, deviceName: string) => {
    return await this.props.authApi.updateUserAuthSessionDeviceName(deviceUUID, deviceName)
  }

  logoutUserAuthSession = async (deviceUUID: string) => {
    try {
      const result = await this.props.authApi.logoutUserAuthSession(deviceUUID)
      console.log('AuthProvider - logoutUserAuthSession - result: ', result)
      return result
    } catch (error) {
      console.error('AuthProvider - logoutUserAuthSession - error: ', error)
      throw error
    }
  }

  logoutAllUserAuthSessions = async () => {
    return await this.props.authApi.logoutAllUserAuthSessions()
  }

  // -------

  // NB: these auth cache functions now also save the cache data to local storage & reload it on init if it was saved within the last hour

  cacheLoginEmail = (email?: string) => {
    this.setState({ cacheType: 'login', cacheEmail: email })
    localStorage.setItem('auth', JSON.stringify({ cacheType: 'login', cacheEmail: email, cacheTime: new Date() }))
  }

  cacheRegisterEmail = (email?: string, firstName?: string, lastName?: string) => {
    this.setState({
      cacheType: 'register',
      cacheEmail: email,
      cacheName: firstName !== undefined || lastName !== undefined ? { firstName, lastName } : undefined
    })
    localStorage.setItem('auth', JSON.stringify({
      cacheType: 'register',
      cacheEmail: email,
      cacheName: firstName !== undefined || lastName !== undefined ? { firstName, lastName } : undefined,
      cacheTime: new Date()
    }))
  }

  cacheSSOEmail = (email?: string) => {
    this.setState({ cacheType: 'sso', cacheEmail: email })
    localStorage.setItem('auth', JSON.stringify({ cacheType: 'sso', cacheEmail: email, cacheTime: new Date() }))
  }

  cacheClear = () => {
    console.log('AuthProvider - cacheClear')
    this.setState({ cacheType: undefined, cacheEmail: undefined, cacheName: undefined })
    localStorage.removeItem('auth')
  }

  getAuthCache = () => {
    const jsonData = localStorage.getItem('auth')
    const authData = jsonData ? JSON.parse(jsonData) : undefined
    return authData
  }

  // NB: this is called on init to load any cached auth data from local storage if it hasn't expired yet (clears expired data if it has)
  loadAuthCache = () => {
    const authData = this.getAuthCache()
    console.log('AuthProvider - loadAuthCache - authData:', authData)
    if (authData) {
      // invalidate/ignore (& clear) the auth cache if its older than 15 mins..
      // ..forcing the user to restart their login/register/sso if the page reloads after the timeout period
      const timeNow = new Date()
      const cacheInvalidTime = new Date(timeNow.getTime() - (1000 * 60 * 15)) // 15 mins (check if the cache time is x mins before now)
      const cacheTime = authData.cacheTime ? new Date(authData.cacheTime) : undefined
      console.log('AuthProvider - loadAuthCache - \ntimeNow:         ', timeNow, ' \ncacheInvalidTime:', cacheInvalidTime, ' \ncacheTime:       ', cacheTime)
      if (cacheTime && cacheTime > cacheInvalidTime) {
        console.log('AuthProvider - loadAuthCache - cache valid - loading...')
        this.setState({ cacheType: authData.cacheType, cacheEmail: authData.cacheEmail, cacheName: authData.cacheName })
      } else {
        console.log('AuthProvider - loadAuthCache - cache expired - clearing...')
        this.cacheClear()
      }
    }
  }

  // -------

  // TODO: email verification
  // TODO: 2FA
  // TODO: invites (here or probably in a dedicated provider?)

  // -------

  actions: IAuthActions = {
    isLoggedIn: this.isLoggedIn,
    isLoggedInAndVerified: this.isLoggedInAndVerified,
    getAuthToken: this.getAuthToken,
    getAuthDeviceUUID: this.getAuthDeviceUUID,
    registerUserWithEmailAndPassword: this.registerUserWithEmailAndPassword,
    loginWithEmailAndPassword: this.loginWithEmailAndPassword,
    registerUserWithSSOToken: this.registerUserWithSSOToken,
    loginWithSSOToken: this.loginWithSSOToken,
    logout: this.logout,
    forgotEmailPassword: this.forgotEmailPassword,
    resetEmailPassword: this.resetEmailPassword,
    emailLoginLookup: this.emailLoginLookup,
    loginExternalDevice: this.loginExternalDevice,
    clearAuthError: this.clearAuthError,
    resendVerifyEmail: this.resendVerifyEmail,
    verifyEmailToken: this.verifyEmailToken,
    sendPhoneSMSCode: this.sendPhoneSMSCode,
    verifyPhoneSMSCode: this.verifyPhoneSMSCode,
    requiresTFACode: this.requiresTFACode,
    verify2FA: this.verify2FA,
    updateUserInfo: this.updateUserInfo,
    isCompanyAuthTFARequired: this.isCompanyAuthTFARequired,
    // TODO: DEPRECIATE - org/project forced 2fa switch auth
    // getCompanyAuthToken: this.getCompanyAuthToken,
    // hasCompanyAuthToken: this.hasCompanyAuthToken,
    // verifyCompany2FA: this.verifyCompany2FA,
    isProjectAuthTFARequired: this.isProjectAuthTFARequired,
    // TODO: DEPRECIATE - org/project forced 2fa switch auth
    // getProjectAuthToken: this.getProjectAuthToken,
    // hasProjectAuthToken: this.hasProjectAuthToken,
    // verifyProject2FA: this.verifyProject2FA,
    getAuthTFAToken: this.getAuthTFAToken,
    getUserAuthSessions: this.getUserAuthSessions,
    updateUserAuthSessionDeviceName: this.updateUserAuthSessionDeviceName,
    logoutUserAuthSession: this.logoutUserAuthSession,
    logoutAllUserAuthSessions: this.logoutAllUserAuthSessions,
    cacheLoginEmail: this.cacheLoginEmail,
    cacheRegisterEmail: this.cacheRegisterEmail,
    cacheSSOEmail: this.cacheSSOEmail,
    cacheClear: this.cacheClear
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: IAuthStore = {
  //  ...
  // }

  render () {
    return (
      <AuthContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for IAuthStore */ }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

const withAuthContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withAuthContextHOC = (props: any) => (
    <AuthContext.Consumer>
      {(authContext) => {
        if (authContext === null) {
          throw new Error('AuthConsumer must be used within an AuthProvider')
        }
        // console.log('withAuthContext - render - AuthContext.Consumer - authContext.store: ', authContext.store)
        return (<Component {...props} {...{ authContext: authContext }} />)
      }}
    </AuthContext.Consumer>
  )
  return withAuthContextHOC
}

export { AuthProvider }
export { withAuthContext }
