/**
 * ArkDivider
 */

import React from 'react'

import { Divider, DividerProps } from 'semantic-ui-react'

import styles from './ArkDivider.module.css'

export interface ArkDividerProps extends DividerProps {}

const ArkDivider = (props: ArkDividerProps) =>
  <Divider {...props} className={`${styles.divider} ${props.className || ''} ${props.children === undefined ? styles.empty : ''}`} />
export default ArkDivider
