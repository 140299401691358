/**
 * WebRTCBroadcaster
 * NB: TypeScript ported version adapted & extended from webrtcjs v0.9
 * refs:
 *  https://millo-l.github.io/Implementing-WebRTC-using-ReactJS-and-Typescript-1-N-P2P/
 *  https://medium.com/geekculture/how-to-create-a-video-chat-with-react-typescript-and-webrtc-8a70f6159cdc
 */

declare global {
  interface Window {
    webRTCBroadcasterInstance?: WebRTCBroadcaster
  }
}

// NB: this is a mix of `RTCPeerConnectionState` values & some additional custom values (currently just 'session deleted')
// NB: getting a linter warning: `'RTCPeerConnectionState' is not defined.eslintno-undef` using the default directly, so mapped to our own version & added the custom value(s) used below
export type WebRTCBroadcasterConnectionState = 'closed' | 'connected' | 'connecting' | 'disconnected' | 'failed' | 'new' | 'session deleted'

export interface WebRTCBroadcasterSettings {
  whipUrl?: string // NB: made optional so we can start the input before we know the target url (required when we want to start broadcasting/sending)
  logLevel?: 'info' | 'error'
  videoElement?: any // TODO: type - ref to the local video preview DOM element
  videoBandwidth?: number
  width?: number
  height?: number
  videoRequired?: boolean
  audioRequired?: boolean
  onInputsStarted?: Function
  onInputsStopped?: Function
  onInputsRestarting?: Function
  onInputsRestarted?: Function
  onConnectionStateChange?: (connectionState: WebRTCBroadcasterConnectionState) => void
  onPublisherCreated?: Function
  onOffer?: Function
  onAnswer?: Function
  onConnectionError?: Function
  onIceconnectionStateChange?: Function
  videoSelect?: any // TODO: type
  audioSelect?: any // TODO: type
  [key: string]: any // TEMP: allow any other fields (also allows dynamic callback lookup/refs to still work for now - see `callback` below) TODO: eventually remove this
}

export default class WebRTCBroadcaster {
  settings: WebRTCBroadcasterSettings
  stream?: MediaStream
  pc?: RTCPeerConnection
  location?: URL

  constructor (options: WebRTCBroadcasterSettings) {
    const defaults: WebRTCBroadcasterSettings = {
      whipUrl: '',
      logLevel: 'error',
      videoElement: undefined,
      videoBandwidth: 0,
      width: 640,
      height: 480,
      videoRequired: true,
      audioRequired: true,
      onInputsStarted: undefined,
      onInputsStopped: undefined,
      onInputsRestarting: undefined,
      onInputsRestarted: undefined,
      onConnectionStateChange: undefined,
      onPublisherCreated: undefined,
      onOffer: undefined,
      onAnswer: undefined,
      onConnectionError: undefined,
      videoSelect: undefined,
      audioSelect: undefined
    }

    // Merge defaults and options, without modifying defaults
    this.settings = Object.assign({}, defaults, options)

    console.log('WebRTCBroadcaster - settings:', this.settings)
    this.callback('onPublisherCreated', this.settings)

    if (typeof window !== 'undefined') {
      window.webRTCBroadcasterInstance = this // Firefox GC workaround
    }
  }

  callback (cbName: string, cbPayload: any) {
    if (typeof this.settings[cbName] === 'function') {
      this.settings[cbName].apply(this, [cbPayload])
    }
  }

  async startInputs () {
    console.log('WebRTCBroadcaster - startInputs - this.settings:', this.settings)

    const constraints: { [key: string]: any } = {}
    const videoSource = this.settings.videoSelect ? this.settings.videoSelect.value : undefined
    const audioSource = this.settings.audioSelect ? this.settings.audioSelect.value : undefined
    if (this.settings.videoRequired) {
      constraints.video = {
        width: this.settings.width,
        height: this.settings.height,
        deviceId: videoSource ? { exact: videoSource } : undefined
      }
    } else {
      constraints.video = false
    }
    if (this.settings.audioRequired) {
      constraints.audio = {
        deviceId: audioSource ?? 'default' // TESTING
      }
    } else {
      constraints.audio = false
    }
    console.log('WebRTCBroadcaster - startInputs - constraints:', constraints)

    if (constraints.video === false && constraints.audio === false) {
      console.log('WebRTCBroadcaster - startInputs - WARNING: no video/audio input enabled')
      this.stream = undefined
      return false
    }

    try {
      this.stream = await navigator.mediaDevices.getUserMedia(constraints)

      const tracks = this.stream.getTracks()
      console.log('WebRTCBroadcaster - startInputs - tracks:', tracks)
      const videoTracks = this.stream.getVideoTracks()
      console.log('WebRTCBroadcaster - startInputs - videoTracks:', videoTracks)
      if (videoTracks.length > 0) {
        const videoTrack = videoTracks[0]
        const videoConstraints = videoTrack.getConstraints()
        console.log('WebRTCBroadcaster - startInputs - videoConstraints:', videoConstraints)
      }
    } catch (error: any) {
      console.error('WebRTCBroadcaster - startInputs - getUserMedia - error:', error)
      this.stream = undefined
      this.callback('onConnectionError', error) // TESTING: trigger the connection error callback for this (NB: is this the best way to handle it here? or add a dedicated callback, or just (re)throw & let the calling code handle?)
      return false
    }

    if (this.settings.videoElement) {
      this.settings.videoElement.srcObject = this.stream
    }

    this.callback('onInputsStarted', this.stream)

    return true
  }

  async stopInputs () {
    console.log('WebRTCBroadcaster - stopInputs')
    // TODO: don't allow stopping of inputs while broadcasting (this.pc is set? other vars as well?) <<<
    // TESTING: stop any active stream tracks first (frees them up, & for webcamera inputs turns off the 'live/active' light)
    if (this.stream) {
      const tracks = this.stream.getTracks()
      console.log('WebRTCBroadcaster - stopInputs - tracks:', tracks)
      this.stream.getTracks().forEach(function (track) { // ref: https://stackoverflow.com/a/12436772
        track.stop()
      })
    }
    this.stream = undefined
    this.settings.videoElement.srcObject = null
    this.callback('onInputsStopped', null)
  }

  async restartInputs () {
    console.log('WebRTCBroadcaster - restartInputs')
    this.callback('onInputsRestarting', null)
    await this.stopInputs()
    const startResult = await this.startInputs()
    if (startResult) this.callback('onInputsRestarted', null)
    return startResult
  }

  async setVideoInput (videoSelect?: any) { // TODO: videoSelect type?
    console.log('WebRTCBroadcaster - setVideoInput - videoSelect:', videoSelect)
    this.settings.videoRequired = videoSelect !== undefined
    this.settings.videoSelect = { value: videoSelect }
    await this.restartInputs()
  }

  async setAudioInput (audioSelect?: any) { // TODO: videoSelect type?
    console.log('WebRTCBroadcaster - setAudioInput - audioSelect:', audioSelect)
    this.settings.audioRequired = audioSelect !== undefined
    this.settings.audioSelect = { value: audioSelect }
    await this.restartInputs()
  }

  setWhipUrl (whipUrl?: string) {
    console.log('WebRTCBroadcaster - setWhipUrl - whipUrl:', whipUrl)
    // TODO: don't allow changing this while broadcasting?
    this.settings.whipUrl = whipUrl
  }

  setVideoBandwidth (videoBandwidth?: number) {
    console.log('WebRTCBroadcaster - setVideoBandwidth - videoBandwidth:', videoBandwidth)
    this.settings.videoBandwidth = videoBandwidth
    // NB: only used when starting a broadcast so should be no need to restart the inputs here
  }

  async setVideoSize (width?: number, height?: number) {
    console.log('WebRTCBroadcaster - setVideoSize - width:', width, ' height:', height)
    this.settings.width = width
    this.settings.height = height
    await this.restartInputs()
  }

  async publish () {
    console.log('WebRTCBroadcaster - publish - settings.videoSelect.value:', this.settings.videoSelect.value, ' this.settings.whipUrl:', this.settings.whipUrl)
    // const constraints: { [key: string]: any } = {}
    // const videoSource = this.settings.videoSelect ? this.settings.videoSelect.value : undefined
    // const audioSource = this.settings.audioSelect ? this.settings.audioSelect.value : undefined
    // if (this.settings.videoRequired) {
    //   constraints.video = {
    //     width: this.settings.width,
    //     height: this.settings.height,
    //     deviceId: videoSource ? { exact: videoSource } : undefined
    //   }
    // } else {
    //   constraints.video = false
    // }
    // if (this.settings.audioRequired) {
    //   constraints.audio = {
    //     deviceId: audioSource ?? 'default' // TESTING
    //   }
    // } else {
    //   constraints.audio = false
    // }
    // console.log('WebRTCBroadcaster - publish - constraints:', constraints)

    // try {
    //   this.stream = await navigator.mediaDevices.getUserMedia(constraints)
    // } catch (error: any) {
    //   console.error('WebRTCBroadcaster - publish - getUserMedia - error:', error)
    //   this.callback('onConnectionError', error) // TESTING: trigger the connection error callback for this (NB: is this the best way to handle it here? or add a dedicated callback, or just (re)throw & let the calling code handle?)
    //   return false
    // }

    if (!this.stream) {
      console.log('WebRTCBroadcaster - publish - ERROR: !this.stream')
      // TODO: throw an error?
      this.callback('onConnectionError', 'Failed to start (no stream)')
      return false
    }

    // TODO: handle if no target url?
    if (!this.settings.whipUrl) {
      console.log('WebRTCBroadcaster - publish - ERROR: !this.settings.whipUrl')
      // TODO: throw an error?
      this.callback('onConnectionError', 'Failed to start (no url)')
      return false
    }

    this.pc = new RTCPeerConnection()

    if (this.pc.connectionState !== undefined) {
      // ref: https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionstatechange_event
      this.pc.onconnectionstatechange = (event: any) => { // TODO: event type - just `Event`?
        console.log('WebRTCBroadcaster - publish - connectionState - event:', event)
        // TESTING: halt if we don't have a valid ref - throw an error and/or trigger a callback if it happens?
        if (!this.pc) {
          // TOOD: trigger onConnectionError? (or another suitable callback, as this might be post connection start??) <<<<<
          return
        }
        switch (this.pc.connectionState) {
          default:
            console.log('WebRTCBroadcaster - publish - connectionState:', this.pc.connectionState)
            this.callback('onConnectionStateChange', this.pc.connectionState)
            break
        }
      }
    } else {
      this.pc.oniceconnectionstatechange = (_event: any) => { // TODO: event type
        console.log('WebRTCBroadcaster - publish - iceConnectionState:', this.pc?.iceConnectionState)
        this.callback('onIceconnectionStateChange', this.pc?.iceConnectionState)
      }
    }

    if (this.settings.videoElement) {
      this.settings.videoElement.srcObject = this.stream
    }

    if (this.pc && this.stream) {
      this.stream.getTracks().forEach(track => this.pc!.addTrack(track, this.stream!))
    }

    // Create SDP offer
    const offer = await this.pc.createOffer()

    if (!offer || !offer.sdp) {
      console.error('WebRTCBroadcaster - publish - Failed to init SDP offer')
      // TODO: anything to clear up?
      // TODO: any callbacks to trigger? onConnectionError?? <<<<
      this.callback('onConnectionError', 'Failed to start')
      return false
    }

    // !!!!!!!!! Start offer mungling!!!!!!!!!!!!!!
    // mangle sdp to add NACK support for opus
    // To add NACK in offer we have to add it manually see https://bugs.chromium.org/p/webrtc/issues/detail?id=4543 for details

    const opusCodecId = offer.sdp.match(/a=rtpmap:(\d+) opus\/48000\/2/)

    if (opusCodecId !== null) {
      offer.sdp = offer.sdp.replace('opus/48000/2\r\n', 'opus/48000/2\r\na=rtcp-fb:' + opusCodecId[1] + ' nack\r\n')
    }
    // !!!!!!!!!!! Stop offer mungling !!!!!!!!!!!!!!!1

    console.log('WebRTCBroadcaster - publish - offer:', offer.sdp)
    this.callback('onOffer', offer.sdp)

    await this.pc.setLocalDescription(offer)

    console.log('WebRTCBroadcaster - publish - url:', this.settings.whipUrl)

    let fetched: Response | undefined
    try {
      // Do the post request to the WHIP endpoint with the SDP offer
      fetched = await fetch(this.settings.whipUrl, {
        method: 'POST',
        body: offer.sdp,
        headers: { 'Content-Type': 'application/sdp' },
        keepalive: true
      })
      if (!fetched.ok) {
        console.error('WebRTCBroadcaster - publish - Connection error ' + fetched.status) // todo handle connection error w/o try/catch
        this.callback('onConnectionError', 'Connection error ' + fetched.status) // TODO: wrap in an Error object?
        console.error('WebRTCBroadcaster - publish - fetched:', fetched)
        return false
      }
    } catch (error) {
      console.error('WebRTCBroadcaster - publish - Connection error:', error) // todo handle connection error w/o try/catch
      this.callback('onConnectionError', 'Connection error') // TODO: wrap in an Error object?
      // TESTING: don't continue?
      // TODO: any clean-up needed here?
      return false
    }

    if (fetched && fetched.headers && fetched.headers.get('location')) {
      this.location = new URL(fetched.headers.get('location')!, this.settings.whipUrl)
    }

    // Get the SDP answer
    const answer = await fetched.text()
    console.log('WebRTCBroadcaster - publish - answer:', answer)
    this.callback('onAnswer', answer)

    await this.pc.setRemoteDescription({ type: 'answer', sdp: answer })

    window.webRTCBroadcasterInstance?.pc?.getSenders().forEach((sender: any) => { // TODO: sender type?
      if (sender.track.kind === 'video') {
        const parameters = sender.getParameters()
        if (!parameters.encodings || undefined === parameters.encodings[0]) {
          parameters.encodings = [{}] // old safari need this
        }
        const bandwidth = this.settings.videoBandwidth ?? 0 // TODO: allow it to be 0?
        if (Number.isNaN(bandwidth)) {
          delete parameters.encodings[0].maxBitrate
        } else {
          parameters.encodings[0].maxBitrate = bandwidth * 1000
        }
        console.log('WebRTCBroadcaster - publish - parameters:', parameters)
        sender.setParameters(parameters)
          .then(() => {
            console.log('WebRTCBroadcaster - publish - bandwidth limit is set', bandwidth)
          })
          .catch((e: any) => {
            console.error('WebRTCBroadcaster - publish - error:', e)
          })
      }
    })

    return true // TODO: does this work as a 'did start' check calling code can use, or can they only rely on callbacks (& if thats the case, need to make sure relevant callbacks are always triggered, including for any error type or early exit when trying to start publishing/broadcasting)
  }

  async stop () {
    if (!this.pc) {
      // Already stopped
      return
    }

    if (this.location) {
      let fetched
      try {
        // Send a delete
        fetched = await fetch(this.location.toString(), {
          method: 'DELETE',
          keepalive: true
        })

        if (!fetched.ok) {
          console.error('WebRTCBroadcaster - failed to delete session ' + fetched.status) // todo handle connection error w/o try/catch
          this.callback('onConnectionError', 'failed to delete session ' + fetched.status)
          console.error(fetched)
          return
        }
      } catch (error) {
        console.error('WebRTCBroadcaster - failed to delete session [' + this.location + '] with error ' + error) // todo handle connection error w/o try/catch
        this.callback('onConnectionError', 'Connection error ' + error)
      }
      this.callback('onConnectionStateChange', 'session deleted')
    }

    // this.settings.videoElement.srcObject = null // NB: leave the local preview running (see `stopInputs` instead)

    // wait a little before pc.close to send some frames to Nimble to make it handle DELETE requests
    // if we run close right after DELETE nimble will wait to ice timeout and delete session only after that
    await new Promise(resolve => setTimeout(resolve, 200))
    this.pc.close()
    this.pc = undefined

    this.callback('onConnectionStateChange', 'disconnected')
  }
}
