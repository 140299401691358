/**
 * ProjectManagerHomePage
 */

import React, { useContext } from 'react'

import * as ROUTES from 'src/constants/routes'
import { OBJECT_PROJECT_NAME, PAGE_DASHBOARD_NAME, SECTION_MANAGER_SUFFIX_NAME } from 'src/constants/strings'
import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkProjectManagerPage from 'src/manager/project/components/ArkProjectManagerPage/ArkProjectManagerPage'
import ArkProjectStatusBanner from 'src/core/components/ArkProjectStatusBanner'
import { UserContext } from 'src/core/providers/UserProvider'

import HomeView from './HomeView'

const ProjectManagerHomePage = () => {
  const { store: userStore } = useContext(UserContext)
  const { selectedProject } = userStore

  if (!selectedProject) return null

  return (
    <ArkProjectManagerPage>
      <ArkManagerContentView
        breadcrumb={[{
          path: ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_VIEW, selectedProject.id),
          title: `${OBJECT_PROJECT_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`
        }]}
        title={PAGE_DASHBOARD_NAME}
      >
        <ArkProjectStatusBanner />
        <HomeView />
      </ArkManagerContentView>
    </ArkProjectManagerPage>
  )
}

export default ProjectManagerHomePage
