import React, { useEffect, useRef } from 'react'

// refs:
//  https://medium.com/@pdx.lucasm/canvas-with-react-js-32e133c05258

export interface WatermarkCanvasProps {
  width: number
  height: number
  draw: (ctx: CanvasRenderingContext2D) => void
  className?: string
  canvasRef?: React.RefObject<HTMLCanvasElement>
}

const WatermarkCanvas = (props: WatermarkCanvasProps) => {
  const { width, height, draw, canvasRef: _canvasRef, ...otherProps } = props
  const canvasRef = _canvasRef ?? useRef<HTMLCanvasElement>(null)

  // const draw = (ctx: CanvasRenderingContext2D) => {
  //   ctx.fillStyle = '#000000'
  //   ctx.beginPath()
  //   ctx.arc(50, 100, 20, 0, (2 * Math.PI))
  //   ctx.fill()
  // }

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d')
    if (canvas && context) {
      // context.fillStyle = '#FFFFFF'
      // context.fillRect(0, 0, context.canvas.width, context.canvas.height)
      draw(context)
      // const dataURL = canvas.toDataURL('image/png')
      // console.log(dataURL)
    }
  }, [draw])

  return (<canvas ref={canvasRef} width={width} height={height} {...otherProps} />)
}

export default WatermarkCanvas
