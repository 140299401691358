/**
 * ArkNumberInput
 */

import React from 'react'

import NumberInput from 'semantic-ui-react-numberinput'

import styles from './ArkNumberInput.module.css'

// ref: https://github.com/pksilen/semantic-ui-react-numberinput
// NB: this isn't a native SUI component, & isn't setup in quite the same way SUI components are (also no typings available)

// NB: props taken from the libs github readme examples
// NB: ideally these would be moved into the `semantic-ui-react-numberinput.d.ts` typings & we'd inherit it here instead of declaring it manually
export interface ArkNumberInputProps { // extends NumberInputProps
  // required
  value: string
  onChange: (newValue: string) => void
  // optional
  allowEmptyValue?: boolean
  allowMouseWheel?: boolean
  buttonPlacement?: 'right' | 'leftAndRight' | 'none'
  id?: string
  className?: string
  defaultValue?: number
  disabled?: boolean
  doubleClickStepAmount?: number
  minValue?: number
  maxValue?: number
  maxLength?: number
  placeholder?: string
  precision?: number
  showError?: boolean
  showTooltips?: boolean
  size?: 'mini' | 'small' | 'large' | 'big' | 'huge' | 'massive'
  stepAmount?: number
  valueType?: 'integer' | 'decimal'
  darkMode?: boolean // ADDED: custom prop
}

const ArkNumberInput: React.FC<ArkNumberInputProps> = (props: ArkNumberInputProps) => {
  const { className, darkMode, ...otherProps } = props
  return (
    <NumberInput
      {...otherProps}
      className={`${styles.numberInput} ${className || ''}${darkMode ? ` ${styles.darkMode}` : ''}`}
    />
  )
}

export default ArkNumberInput
