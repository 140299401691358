import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

import { User2FAContext, NavContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import User2FAGenerateView from './User2FAGenerateView'
import User2FAInputView, { User2FAInputViewMode } from './User2FAInputView'
import UserPhoneVerifyForm from '../UserPhone/UserPhoneVerifyForm'

import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkButton from '../../../components/ArkButton'

import { Message } from 'semantic-ui-react'

import styles from './User2FAManageView.module.css'

interface IProps {
  onStartEnable?: Function
}

enum User2FAEnableStage {
  summary,
  phoneEntry,
  phoneVerify,
  tfaConfirm
}

const phoneInputFormSchema = yup.object().shape({
  phone: yup.string().min(6).max(15).required()
})

const User2FAEnableView = (props: IProps) => {
  const mounted = useRef(false)

  const user2FAContext = useContext(User2FAContext)
  const navContext = useContext(NavContext)

  const [stage, _setStage] = useState<User2FAEnableStage>(User2FAEnableStage.summary) // NB: use gotoStage() to change betwee stages
  const [, _setPrevStage] = useState<User2FAEnableStage>(User2FAEnableStage.summary)

  const [isLoading, setIsLoading] = useState(false)

  const [secretOTPAuthUrl, setSecretOTPAuthUrl] = useState<string>()
  const [secretBase32, setSecretBase32] = useState<string>()
  const [phoneInputFormError, setPhoneInputFormError] = useState<Error>()
  const [phoneVerifyCodeSent, setPhoneVerifyCodeSent] = useState<boolean | undefined>(undefined)

  const [confirm2FAEnableSuccess, setConfirm2FAEnableSuccess] = useState<boolean | undefined>(undefined) // NOTE: this component is unmounted on success before we can show a message, so we now handle it at the parent level instead
  const [confirm2FAEnableError, setConfirm2FAEnableError] = useState<Error>()

  // -------

  const reset = () => {
    setIsLoading(false)
    setSecretOTPAuthUrl(undefined)
    setSecretBase32(undefined)
    setPhoneInputFormError(undefined)
    setPhoneVerifyCodeSent(undefined)
    setConfirm2FAEnableSuccess(undefined)
    setConfirm2FAEnableError(undefined)
  }

  const gotoStage = (_stage: User2FAEnableStage) => {
    if (stage !== _stage) {
      if (_stage === User2FAEnableStage.summary) reset() // reset data when if we cancel & go back to the summary stage/view
      _setPrevStage(stage)
      _setStage(_stage)
    }
  }

  // -------

  const onStart2FAEnable = () => {
    if (props.onStartEnable) props.onStartEnable() // notify the parent component the user started the enable process (so it can remove old success messages)
    gotoStage(User2FAEnableStage.phoneEntry)
  }

  const renderSummary = () => {
    return (
      <div className={styles.tfaEnableSummary}>
        <Message warning>
          <Message.Header>Enable Two Factor Authentication (2FA)?</Message.Header>
          <p>
            2FA is currently disabled. <br/ >
            We recommend it is enabled to help secure your account.
          </p>
        </Message>
        <ArkButton type="button" positive basic fluid size="large" onClick={onStart2FAEnable}>ENABLE 2FA</ArkButton>
        <div className={styles.cancel}>
          <a href="#" onClick={(event) => {
            event.preventDefault()
            navContext.actions.goto(ROUTES.ACCOUNT_SETTINGS)
          }}>Back</a>
        </div>
      </div>
    )
  }

  // -------

  const generate2FAEnableTokens = async (phoneNumber: string) => {
    try {
      setIsLoading(true)
      const result = await user2FAContext.actions.generate2FA(phoneNumber)
      console.log('User2FAEnableView - generate2FAEnableTokens - result: ', result)
      if (result) {
        setIsLoading(false)
        setSecretOTPAuthUrl(result.secretOTPAuthUrl)
        setSecretBase32(result.secretBase32)
        setPhoneVerifyCodeSent(result.phoneVerifyCodeSent ?? false)
        return result
      } else {
        throw new Error('Invalid response')
      }
    } catch (error) {
      console.error('User2FAEnableView - generate2FAEnableTokens - error: ', error)
      setIsLoading(false)
      setSecretOTPAuthUrl(undefined)
      setSecretBase32(undefined)
      setPhoneInputFormError(error)
      return null
    }
  }

  const onPhoneNoSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    if (isLoading) return
    const { phone } = fieldValues
    const generateResult = await generate2FAEnableTokens(phone)
    console.log('User2FAEnableView - onPhoneNoSubmit - generateResult: ', generateResult)
    // only move to the next form stage if the generate api call didn't error out (remain on the initial stage to show the error if one was returned)
    if (generateResult) {
      // NB: the state vars won't have updated yet, so we access the result directly (instead of listening to changes via useEffect & triggering the change that way)
      const _phoneNo = generateResult.phoneNumber
      const _phoneNoVerified = generateResult.phoneVerified
      console.log('User2FAEnableView - onPhoneNoSubmit - _phoneNo: ', _phoneNo, ' _phoneNoVerified: ', _phoneNoVerified)
      // check if the phone number has changed & needs verifying
      if (_phoneNo && !_phoneNoVerified) {
        // show the phone number verify form stage first
        gotoStage(User2FAEnableStage.phoneVerify)
      } else if (_phoneNo && _phoneNoVerified) {
        // otherwise show the 2fa generate confirmation stage straight away
        gotoStage(User2FAEnableStage.tfaConfirm)
      }
      // NB: if no phone number is returned somehow, we remain on the field (shouldn't happen without an error which we'd show in the phone no entry form)
    }
  }

  const renderPhoneNoInputForm = () => {
    const loading = isLoading
    const phoneNumber = user2FAContext.actions.userPhoneNumber() // pre-fill with the users current phone number if its set
    const phoneInputFormFields: Array<ArkFormField> = []
    phoneInputFormFields.push({ type: ArkFormFieldType.PhoneNo, key: 'phone', label: 'Phone Number', required: true, defaultValue: phoneNumber, fieldProps: { large: true } })
    phoneInputFormFields.push({ type: ArkFormFieldType.Button, key: 'submit', label: 'SUBMIT', fieldProps: { loading: loading, fluid: true } })
    return (
      <div className={styles.tfaPhoneInput}>
        <p>Confirm your phone number before enabling two factor authentication.</p>
        <ArkForm
          formKey="tfaPhone"
          inverted
          formError={phoneInputFormError}
          formFields={phoneInputFormFields}
          formSchema={phoneInputFormSchema}
          onFormSubmit={onPhoneNoSubmit}
        ></ArkForm>
        <div className={styles.cancel}>
          <a href="#" onClick={(event) => {
            event.preventDefault()
            gotoStage(User2FAEnableStage.summary)
          }}>Cancel</a>
        </div>
      </div>
    )
  }

  // -------

  const renderPhoneNoVerifyForm = () => {
    console.log('User2FAEnableView - renderPhoneNoVerifyForm - phoneVerifyCodeSent: ', phoneVerifyCodeSent)
    return (
      <div className={styles.tfaPhoneVerify}>
        <UserPhoneVerifyForm
          hasSentCode={phoneVerifyCodeSent}
          onVerified={() => {
            console.log('User2FAEnableView - renderPhoneNoVerifyForm - UserPhoneVerifyForm - onVerified')
            // move to the next stage
            // NB: the phoner verify form already triggers a user data update, so the main user data will contain the new phone verified value
            gotoStage(User2FAEnableStage.tfaConfirm)
          }}
          // NB: not currently using the phone verify onCancel or onBack callbacks within the 2fa generate view (theres no way to trigger them with the current usage)
          // onCancel={() => {
          //   console.log('User2FAEnableView - renderPhoneNoVerifyForm - UserPhoneVerifyForm - onCancel')
          // }}
          // onBack={() => {
          //   console.log('User2FAEnableView - renderPhoneNoVerifyForm - UserPhoneVerifyForm - onBack')
          // }}
        />
        <div className={styles.cancel}>
          <a href="#" onClick={(event) => {
            event.preventDefault()
            gotoStage(User2FAEnableStage.summary)
          }}>Cancel</a>
        </div>
      </div>
    )
  }

  // -------

  const confirm2FAEnable = async (otpCode: string) => {
    if (isLoading) return
    try {
      setIsLoading(true)
      setConfirm2FAEnableSuccess(undefined)
      setConfirm2FAEnableError(undefined)
      const result = await user2FAContext.actions.enable2FA(otpCode)
      if (result) {
        if (mounted.current) {
          setIsLoading(false)
          setConfirm2FAEnableSuccess(true)
        }
      } else {
        throw new Error('Failed to enable 2FA')
      }
    } catch (error) {
      console.error('User2FAEnableView - enable2fa - error: ', error)
      if (mounted.current) {
        setIsLoading(false)
        setConfirm2FAEnableSuccess(false)
        setConfirm2FAEnableError(error)
      }
    }
  }

  const on2FAEnableRetry = () => {
    // NB: only reset the '2fa enable' related state vars, so we can re-show the 2fa confirm form & retry with the same 'generate 2fa' data
    setConfirm2FAEnableSuccess(undefined)
    setConfirm2FAEnableError(undefined)
    gotoStage(User2FAEnableStage.tfaConfirm)
  }

  const renderTFAConfirmForm = () => {
    // TODO: handle if secretOTPAuthUrl or secretBase32 are undefined <<<
    const hasResult = confirm2FAEnableSuccess !== undefined || confirm2FAEnableError !== undefined
    return (
      <div className={styles.tfaConfirm}>
        {/* {confirm2FAEnableSuccess && (
          <Message positive>
            <Message.Header>2FA Enabled</Message.Header>
            <Message.Item>2FA is now enabled on your account.</Message.Item>
          </Message>
        )} */}
        {confirm2FAEnableError && (
          <>
            <Message negative>
              <Message.Header>Error</Message.Header>
              <p>{confirm2FAEnableError.message}</p>
            </Message>
            <ArkButton type="button" fluid size="large" onClick={on2FAEnableRetry}>RETRY</ArkButton>
            <div className={styles.cancel}>
              <a href="#" onClick={(event) => {
                event.preventDefault()
                gotoStage(User2FAEnableStage.summary)
              }}>Cancel</a>
            </div>
          </>
        )}
        {!hasResult && (<>
          {secretOTPAuthUrl && secretBase32 && (
            <User2FAGenerateView secretOTPAuthUrl={secretOTPAuthUrl} secretBase32={secretBase32} />
          )}
          <User2FAInputView
            inputMode={User2FAInputViewMode.split}
            autoSubmit={true}
            isBusy={isLoading}
            onSubmit={(otpCode) => {
              confirm2FAEnable(otpCode)
            }}
          />
          <div className={styles.cancel}>
            <a href="#" onClick={(event) => {
              event.preventDefault()
              gotoStage(User2FAEnableStage.summary)
            }}>Cancel</a>
          </div>
        </>)}
      </div>
    )
  }

  // -------

  const renderStage = () => {
    switch (stage) {
      case User2FAEnableStage.summary:
        return renderSummary()
      case User2FAEnableStage.phoneEntry:
        return renderPhoneNoInputForm()
      case User2FAEnableStage.phoneVerify:
        return renderPhoneNoVerifyForm()
      case User2FAEnableStage.tfaConfirm:
        return renderTFAConfirmForm()
    }
  }

  // -------

  useEffect(() => {
    console.log('User2FAEnableView - MOUNTED')
    mounted.current = true
    return () => {
      mounted.current = false
      console.log('User2FAEnableView - UN-MOUNTED')
    }
  }, [])

  // -------

  return (
    <div className={styles.enable2FA}>
      <h1 className={styles.title}>Turn On 2FA</h1>
      {renderStage()}
    </div>
  )
}
export default User2FAEnableView
