import React from 'react'

import { withAuthContext, IAuthMultiContext, withNavContext, INavMultiContext } from '../../../providers'
import * as ROUTES from '../../../../constants/routes'

import User2FAInputView, { User2FAInputViewMode } from '../../../pages/account/User2FAPage/User2FAInputView'

import { Message, Segment } from 'semantic-ui-react'

interface IProps extends IAuthMultiContext, INavMultiContext {
}
interface IState {
  isLoading: boolean
  verifySuccess: boolean
  verifyError: boolean
  error?: Error
}

class Login2FAForm extends React.Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      isLoading: false,
      verifySuccess: false,
      verifyError: false
    }
  }

  componentDidMount () {
    this._isMounted = true
    // redirect to the main login page if the user doesn't have a tfa token from the first part of the login
    // TODO: move this up to at least the main page level, if not a high level provider?
    const authTFAToken = this.props.authContext.actions.getAuthTFAToken()
    if (!authTFAToken) {
      this.props.navContext.actions.goto(ROUTES.LOGIN)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { isLoading, error, verifySuccess } = this.state
    return (
      <Segment inverted>
        {verifySuccess && (
          <Message positive>
            <Message.Header>2FA Login Success</Message.Header>
            <Message.Item>You are now logged in.</Message.Item>
          </Message>
        )}
        <User2FAInputView
          inputMode={User2FAInputViewMode.split}
          autoSubmit={true}
          isBusy={isLoading}
          error={error}
          onSubmit={(otpCode) => {
            this.verify2fa(otpCode)
          }}
        />
      </Segment>
    )
  }

  verify2fa = async (otpCode: string) => {
    try {
      this.setState({ isLoading: true })
      const result = await this.props.authContext.actions.verify2FA(otpCode)
      if (this._isMounted) {
        if (result) {
          this.setState({
            isLoading: false,
            verifySuccess: true,
            verifyError: false,
            error: undefined
          })
        } else {
          this.setState({
            isLoading: false,
            verifySuccess: false,
            verifyError: true,
            error: undefined
          })
        }
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        verifySuccess: false,
        verifyError: true,
        error: error as Error
      })
    }
  }
}
export default withNavContext(withAuthContext(Login2FAForm))
