import { GlobalConfigProps } from '../global-config'

const defaultConfig: GlobalConfigProps = {
  appName: 'RePro Stream',
  favicon: '/favicon.ico',
  logo: '/logo.svg',
  preferPassthrough: false,
  showPoweredByFooter: false,
  showAutoSoloTopBar: true,
  supportDocURL: 'https://docs.reprostream.com',
  supportEmail: 'support@reprostream.com',
  registrationEnabled: false,
  appDeeplinkURL: 'https://view.repro.stream'
}

export default defaultConfig
