import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { Project } from 'src/core/models'
import { ProjectAdminContext, ServerProjectWatermarkDoesNotExistError, ServerProjectWatermarkErrorReadingError } from 'src/core/providers'

import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkPanel from 'src/core/components/ArkPanel'

import { WatermarkScreenPreview } from './WatermarkScreenPreview'

import { OBJECT_PROJECT_NAME } from 'src/constants/strings'
import * as ROUTES from 'src/constants/routes'

import styles from '../ProjectSettingsView.module.css'

export interface ProjectSettingsWatermarkSummaryViewProps {
  companyId: number
  project: Project
}

const ProjectSettingsWatermarkSummaryView = (props: ProjectSettingsWatermarkSummaryViewProps) => {
  const { companyId, project } = props

  const projectAdminContext = useContext(ProjectAdminContext)

  const mounted = useRef(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<Error | undefined>(undefined)
  const [watermarkImage, setWatermarkImage] = useState<string | undefined>()

  const watermarkingEnabled = project.companyTranscoderSettings?.allowWatermarking

  // TODO: will also need to take into account company level transcoder settings once its supported at that level
  const projectTranscoderSettings = project.transcoderSettings
  const transcodersEnabled = projectTranscoderSettings?.transcodersEnabled
  // console.log('ProjectSettingsWatermarkSummaryView - render - transcodersEnabled:', transcodersEnabled)

  // -------

  const loadWatermarkImage = async () => {
    try {
      console.log('ProjectSettingsWatermarkSummaryView - loadWatermarkImage - project: ', project)
      // halt if watermarking is disabled for the company (even if a watermark may have been uploaded before hand, we lock off all access for now)
      if (watermarkingEnabled === false) return
      setLoading(true)
      setLoadingError(undefined)
      setWatermarkImage(undefined)
      const image = await projectAdminContext.actions.getProjectWatermarkImage(companyId, project?.id, false)
      if (mounted.current) {
        setWatermarkImage(image)
      }
    } catch (error) {
      if (error instanceof ServerProjectWatermarkDoesNotExistError) {
        console.log('ProjectSettingsWatermarkSummaryView - loadWatermarkImage - ServerProjectWatermarkDoesNotExistError')
        // do nothing
      } else if (error instanceof ServerProjectWatermarkErrorReadingError) {
        console.log('ProjectSettingsWatermarkView - _loadOriginalWatermarkImage - ServerProjectWatermarkErrorReadingError')
        if (mounted.current) setLoadingError(error)
      } else {
        console.log('ProjectSettingsWatermarkSummaryView - loadWatermarkImage - error: ', error)
        if (mounted.current) setLoadingError(error)
      }
    }
    if (mounted.current) setLoading(false)
  }

  // -------

  useEffect(() => {
    console.log('ProjectSettingsWatermarkSummaryView - MOUNTED')
    mounted.current = true
    return () => {
      mounted.current = false
      console.log('ProjectSettingsWatermarkSummaryView - UN-MOUNTED')
    }
  }, [])

  // -------

  // load the watermark on component init/mount
  // NB: run an async function via useEffect - ref: https://stackoverflow.com/a/53572588
  useEffect(() => {
    async function loadAsync () {
      await loadWatermarkImage()
    }
    loadAsync()
  }, [])

  // -------

  return (
    <div className={styles.watermarkSummary}>
      <div className={styles.content}>
        <ArkPanel bordered>
          <ArkPanel.Header title={OBJECT_PROJECT_NAME + ' watermark'} bottomBorder className={styles.header}></ArkPanel.Header>
          <ArkPanel.Properties>
            <ArkPanel.PropertyRow>
              <ArkPanel.PropertyRowContent>
                {loading && (<ArkLoaderView message='Loading' className={styles.loading} />)}
                {!loading && loadingError && (
                  <ArkPanel.Properties>
                    <ArkPanel.PropertyRow>
                      <ArkPanel.PropertyRowMessage negative>
                        <ArkMessage.Header>Error</ArkMessage.Header>
                        <p>{loadingError.message}</p>
                      </ArkPanel.PropertyRowMessage>
                    </ArkPanel.PropertyRow>
                  </ArkPanel.Properties>
                )}
                {!loading && !loadingError && (
                  <>
                    {watermarkImage && (
                      // <img src={watermarkImage} alt={OBJECT_PROJECT_NAME + ' watermark image'}/>
                      <WatermarkScreenPreview
                        className={styles.screenPreview}
                        screenSize={{ width: 320, height: 180 }}
                        watermarkURL={watermarkImage}
                        // TODO: provide real values here <<<<<<<<<<<<<<<
                        scaleXPcnt={project.transcoderSettings?.watermarkWidth}
                        posXPcnt={project.transcoderSettings?.watermarkX}
                        posYPcnt={project.transcoderSettings?.watermarkY}
                      />
                    )}
                    {!watermarkImage && watermarkingEnabled !== false && (
                      <div className={styles.noWatermark}>
                        no {OBJECT_PROJECT_NAME} watermark set
                      </div>
                    )}
                    {watermarkingEnabled === false && (
                      <div className={styles.watermarkingDisabled}>
                        Watermarking is currently disabled
                      </div>
                    )}
                  </>
                )}
                {!loading && watermarkingEnabled !== false &&
                  /* NB: opted to show the link to the edit watermark page even if an error, incase its not a blocker */
                  (
                    <div className={styles.editLink}>
                      <Link to={ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_SETTINGS_WATERMARK, project.id)}>{watermarkImage ? 'Edit' : 'Add'} Watermark</Link>
                    </div>
                  )
                }
                {!loading && watermarkingEnabled !== false && transcodersEnabled === false && (
                  <div className={styles.transcoderDisabledWarning}>
                    (<>Watermarked ABR Transcodes will be <br />available when transcoding is enabled</>)
                  </div>
                )}
              </ArkPanel.PropertyRowContent>
            </ArkPanel.PropertyRow>
          </ArkPanel.Properties>
        </ArkPanel>
      </div>
    </div>
  )
}

export { ProjectSettingsWatermarkSummaryView }
