import React, { useContext } from 'react'

import ArkLoaderView from '../../components/ArkLoaderView'
import ArkPage from '../../components/ArkPage/ArkPage'
import { GlobalConfigContext } from 'src/core/providers'

const LoadingPage = () => {
  const { store: configStore } = useContext(GlobalConfigContext)
  return (
    <ArkPage>
      <ArkLoaderView message={`Loading  ${configStore.config.appName}`} />
    </ArkPage>
  )
}
export default LoadingPage
