import { BaseModel } from './base_model'

export interface IVideoEnginePortRange { start: number, end: number }
// export interface IVideoEngineSRTPortRanges { in: Array<IVideoEnginePortRange>, out: Array<IVideoEnginePortRange> }

// NB: all fields are optional when updating, as we only send the fields that are being updated
export interface IVideoEngineUpdateData {
  // base fields
  name?: string
  domain?: string
  ip?: string
  isActive?: boolean // site-admin only
  // org
  companyId?: number // TODO: can we set this for all, or should the primary video engine block company assignments?
  // control
  controlServerKey?: string
  controlClientId?: string
  controlAPIKey?: string
  controlGroupId?: string
  // ports
  sldpPort?: number,
  sldpPortWS?: number,
  sldpPortWSS?: number,
  rtmpPort?: number,
  rtmpsPort?: number,
  rtspPort?: number,
  icecastInputPort?: number,
  icecastPlaybackPort?: number,
  srtInputPortRanges?: Array<IVideoEnginePortRange>,
  srtOutputPortRanges?: Array<IVideoEnginePortRange>,
  // transcoders
  templateABRId?: string,
  templateTranscoderBaseId?: string,
  templateTranscoderFHDId?: string,
  templateTranscoderHDId?: string,
  // watermark
  watermarkEnvAccessKeyId?: string,
  watermarkEnvBucket?: string,
  watermarkEnvPath?: string,
  watermarkEnvSecretAccessKey?: string,
  watermarkLocalPath?: string
}
// NB: redeclare an fields that are required when adding/creating a new entry vs updating
export interface IVideoEngineAddData extends IVideoEngineUpdateData {
  name: string // name is required when adding
  domain: string
  ip: string
  // TODO: any other required fields needed when adding?
}

// company specific video engine update fields (has limited fields company admins can update vs the full model)
// NB: all fields are optional when updating, as we only send the fields that are being updated
export interface ICompanyVideoEngineUpdateData {
  srtInputPortRanges?: Array<IVideoEnginePortRange>,
  srtOutputPortRanges?: Array<IVideoEnginePortRange>
}

export class VideoEngine extends BaseModel {
  constructor (
    // base fields
    public id: number,
    public name: string,
    public domain: string,
    public ip: string,
    public isActive: boolean,
    // org
    public companyId: number | undefined = undefined,
    // control
    public controlServerKey: string,
    public controlClientId: string,
    public controlAPIKey: string,
    public controlGroupId: string,
    // ports
    public sldpPort: number,
    public sldpPortWS: number,
    public sldpPortWSS: number,
    public rtmpPort: number,
    public rtmpsPort: number,
    public rtspPort: number,
    public icecastInputPort: number,
    public icecastPlaybackPort: number,
    public srtInputPortRanges: Array<IVideoEnginePortRange>,
    public srtOutputPortRanges: Array<IVideoEnginePortRange>,
    // transcoders
    public templateABRId: string,
    public templateTranscoderBaseId: string,
    public templateTranscoderFHDId: string,
    public templateTranscoderHDId: string,
    // watermark
    public watermarkEnvAccessKeyId: string,
    public watermarkEnvBucket: string,
    public watermarkEnvPath: string,
    public watermarkEnvSecretAccessKey: string,
    public watermarkLocalPath: string
  ) {
    super()
    // console.log('VideoEngine - init')
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : VideoEngine | null {
    if (!json) {
      return null
    }
    return new VideoEngine(
      // base fields
      json.id,
      json.name,
      json.domain,
      json.ip,
      !!(json.flag_active),
      // org
      json.company_id ?? undefined,
      // control
      json.control_server_key,
      json.control_client_id,
      json.control_api_key,
      json.control_group_id,
      // ports
      json.sldp_port,
      json.sldp_port_ws,
      json.sldp_port_wss,
      json.rtmp_port,
      json.rtmps_port,
      json.rtsp_port,
      json.icecast_input_port,
      json.icecast_playback_port,
      json.ports.port_ranges_in ?? [],
      json.ports.port_ranges_out ?? [],
      // transcoders
      json.template_abr_id,
      json.template_transcoder_base_id,
      json.template_transcoder_fhd_id,
      json.template_transcoder_hd_id,
      // watermark
      json.watermark_env_access_key_id,
      json.watermark_env_bucket,
      json.watermark_env_path,
      json.watermark_env_secret_access_key,
      json.watermark_local_path
    )
  }

  static propertyToJSONKeyMap () {
    return {
      // base fields
      id: 'id',
      name: 'name',
      domain: 'domain',
      ip: 'ip',
      isActive: 'flag_active',
      // org
      companyId: 'company_id',
      // control
      controlServerKey: 'control_server_key',
      controlClientId: 'control_client_id',
      controlAPIKey: 'control_api_key',
      controlGroupId: 'control_group_id',
      // ports
      sldpPort: 'sldp_port',
      sldpPortWS: 'sldp_port_ws',
      sldpPortWSS: 'sldp_port_wss',
      rtmpPort: 'rtmp_port',
      rtmpsPort: 'rtmps_port',
      rtspPort: 'rtsp_port',
      icecastInputPort: 'icecast_input_port',
      icecastPlaybackPort: 'icecast_playback_port',
      srtInputPortRanges: 'ports.port_ranges_in', // NB: currently just labelled as 'ports.port_ranges_in' API side (this might change to be SRT specific in the future)
      srtOutputPortRanges: 'ports.port_ranges_out', // NB: currently just labelled as 'ports.port_ranges_out' API side (this might change to be SRT specific in the future)
      // transcoders
      templateABRId: 'template_abr_id',
      templateTranscoderBaseId: 'template_transcoder_base_id',
      templateTranscoderFHDId: 'template_transcoder_fhd_id',
      templateTranscoderHDId: 'template_transcoder_hd_id',
      // watermark
      watermarkEnvAccessKeyId: 'watermark_env_access_key_id',
      watermarkEnvBucket: 'watermark_env_bucket',
      watermarkEnvPath: 'watermark_env_path',
      watermarkEnvSecretAccessKey: 'watermark_env_secret_access_key',
      watermarkLocalPath: 'watermark_local_path'
    }
  }
}

// company specific video engine model (has limited fields company admins can see vs the full model)
export class CompanyVideoEngine extends BaseModel {
  constructor (
    // base fields
    public id: number,
    public name: string,
    public domain: string,
    public ip: string,
    public isActive: boolean,
    // ports
    public srtInputPortRanges: Array<IVideoEnginePortRange>,
    public srtOutputPortRanges: Array<IVideoEnginePortRange>,
    // org
    public companyId: number | undefined = undefined
  ) {
    super()
    // console.log('CompanyVideoEngine - init')
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : CompanyVideoEngine | null {
    if (!json) {
      return null
    }
    return new CompanyVideoEngine(
      // base fields
      json.id,
      json.name,
      json.domain,
      json.ip,
      !!(json.flag_active),
      // ports
      json.ports.port_ranges_in ?? [],
      json.ports.port_ranges_out ?? [],
      // org
      json.company_id ?? undefined
    )
  }

  static propertyToJSONKeyMap () {
    return {
      // base fields
      id: 'id',
      name: 'name',
      domain: 'domain',
      ip: 'ip',
      isActive: 'flag_active',
      // ports
      srtInputPortRanges: 'ports.port_ranges_in', // NB: currently just labelled as 'ports.port_ranges_in' API side (this might change to be SRT specific in the future)
      srtOutputPortRanges: 'ports.port_ranges_out', // NB: currently just labelled as 'ports.port_ranges_out' API side (this might change to be SRT specific in the future)
      // org
      companyId: 'company_id'
    }
  }
}

// project specific video engine model (has limited fields project admins & viewers can see vs the full or company versions of the data model)
// TODO: this is currently just a stub/placeholder data model until the api implements the project specific video engine related data, fields are subject to change...
export class ProjectVideoEngine extends BaseModel {
  constructor (
    // base fields
    public id: number,
    public name: string,
    // public domain: string,
    // public ip: string,
    public isActive: boolean,
    // org & project
    public companyId: number | undefined = undefined,
    public projectId: number | undefined = undefined
  ) {
    super()
    // console.log('ProjectVideoEngine - init')
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : ProjectVideoEngine | null {
    if (!json) {
      return null
    }
    return new ProjectVideoEngine(
      // base fields
      json.id,
      json.name,
      // json.domain,
      // json.ip,
      !!(json.flag_active),
      // org & project
      json.company_id ?? undefined,
      json.project_id ?? undefined
    )
  }

  static propertyToJSONKeyMap () {
    return {
      // base fields
      id: 'id',
      name: 'name',
      // domain: 'domain',
      // ip: 'ip',
      isActive: 'flag_active',
      // org
      companyId: 'company_id',
      projectId: 'project_id'
    }
  }
}
