import StreamhubAPIClient from './StreamhubAPIClient'

import { FFMpegAudioInput, FFMpegSourceAudioType, FFMpegSourceVideoType, FFMpegText, FFMpegVideoInput } from '../models/StreamhubModels'
import InputSource from '../models/InputSource'

export default class StreamhubSourcesAPI {
  apiClient: StreamhubAPIClient

  constructor (apiClient: StreamhubAPIClient) {
    this.apiClient = apiClient
  }

  async fetchSources (): Promise<Array<InputSource>> {
    console.log('StreamhubSourcesAPI - fetchSources')
    try {
      const response = await this.apiClient.apiGet('/source', {})
      const streamSources: Array<InputSource> = []
      if (response.data && response.data.sources && response.data.sources) {
        const sourcesData = response.data.sources
        for (const sourceData of sourcesData) {
          const streamSource = InputSource.fromJSON(sourceData)
          if (streamSource) {
            streamSources.push(streamSource)
          }
        }
      }
      return streamSources
    } catch (error) {
      console.error('StreamhubSourcesAPI - fetchSources - error: ', error)
      throw error
    }
  }

  // NB: currently only supports a single video & audio input (will ignore any additional ones set)
  async createSource (source: InputSource): Promise<InputSource> {
    if (!source.videoInputs || source.videoInputs.length === 0) {
      throw Error('Source videoInputs must contain at least 1 video input')
    }
    try {
      const data: { [key: string]: any } = {
        video: source.videoInputs[0]
      }
      if (source.name) data.name = source.name
      if (source.audioInputs && source.audioInputs.length > 0) {
        data.audio = source.audioInputs[0]
      }
      if (source.overlays) {
        data.overlays = source.overlays
      }
      if (source.overlayTitle) {
        data.overlayTitle = source.overlayTitle
      }
      data.overlayShowTimecode = source.overlayShowTimecode
      const response = await this.apiClient.apiPost('/source', data)
      if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
        if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
          const result = response.data.result
          if (result === true && response.data.source) {
            const source = InputSource.fromJSON(response.data.source)
            if (!source) throw Error('Failed to create source')
            return source
          }
        }
      }
    } catch (error) {
      console.error('StreamhubSourcesAPI - createSource - error: ', error)
      throw error
    }
    throw Error('Failed to create source') // fallback error
  }

  // NB: currently only supports a single video & audio input (will ignore any additional ones set)
  async updateSource (source: InputSource): Promise<InputSource> {
    if (!source.videoInputs || source.videoInputs.length === 0) {
      throw Error('InputSource videoInputs must contain at least 1 video input')
    }
    try {
      const data: { [key: string]: any } = {
        id: source.id,
        video: source.videoInputs[0]
      }
      if (source.name) data.name = source.name
      if (source.audioInputs && source.audioInputs.length > 0) {
        data.audio = source.audioInputs[0]
      }
      if (source.overlays) {
        data.overlays = source.overlays
      }
      if (source.overlayTitle) {
        data.overlayTitle = source.overlayTitle
      }
      data.overlayShowTimecode = source.overlayShowTimecode
      const response = await this.apiClient.apiPut('/source', data)
      if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
        const result = response.data.result
        if (result === true && response.data.source) {
          const source = InputSource.fromJSON(response.data.source)
          if (!source) throw Error('Failed to parse update source response')
          return source
        }
      }
    } catch (error) {
      console.error('StreamhubSourcesAPI - updateSource - error: ', error)
      throw error
    }
    throw Error('Failed to update source') // fallback error
  }

  // NB: will always return true on success or throw an Error (never false)
  async deleteSource (sourceId: number): Promise<true> {
    try {
      const response = await this.apiClient.apiDelete('/source', { id: sourceId })
      if (response.data && response.data.result === true) return true
    } catch (error) {
      console.error('StreamhubSourcesAPI - deleteSource - error: ', error)
      // if (error.code === 409) {} // NB: could check for the specific error code, but its currently enough to just show the custom error msg from the body
      if (error.response && error.response.data && error.response.data.error) {
        throw Error(error.response.data.error)
      }
      throw error
    }
    throw Error('Failed to delete source') // fallback error
  }

  // -------

  // parses separate input & json fields into an InputSource object
  // TODO: currently only supports the new filename short-alias or the json equivalent for each field type, not both at the same time
  // TODO: should likely allow videoFilename & videoInputJson to be parsed as one, with the json data adding to the default filename (but how does that work if multiple json video entries added, only use the first for the merge?)
  // TODO: do the same for audio & overlays..
  async getStreamSourceFromJson (sourceId: number, name?: string, videoFilename?: string, videoInputsJson?: string, audioFilename?: string, audioInputsJson?: string, overlayTitle?: string, overlayShowTimecode?: boolean, overlaysJson?: string) {
    const videoInputs: Array<FFMpegVideoInput> = []
    if (videoFilename) {
      const videoInput = new FFMpegVideoInput(FFMpegSourceVideoType.file)
      videoInput.filename = videoFilename
      videoInputs.push(videoInput)
    } else if (videoInputsJson) {
      try {
        const videoInputsJsonStr = '{"videoInputs": ' + videoInputsJson.replace('\\"', '"') + '}' // wrap the json array with an object so we can parse it
        const videoInputsData = JSON.parse(videoInputsJsonStr)
        if (videoInputsData && videoInputsData.videoInputs) {
          for (const videoInputData of videoInputsData.videoInputs) {
            const videoType: FFMpegSourceVideoType = FFMpegSourceVideoType[videoInputData.type as keyof typeof FFMpegSourceVideoType] // enum lookup - ref: https://stackoverflow.com/a/56076148
            if (videoType) {
              const videoInput = new FFMpegVideoInput(videoType, videoInputData.duration, videoInputData.fps, videoInputData.resolution, videoInputData.inputArgsStr)
              videoInputs.push(videoInput)
            }
          }
        }
      } catch (e) {
        console.error('StreamhubSourcesAPI - getStreamSourceFromJson - videoInputs - error: ', e)
      }
    }

    const audioInputs: Array<FFMpegAudioInput> = []
    if (audioFilename) {
      const audioInput = new FFMpegAudioInput(FFMpegSourceAudioType.file)
      audioInput.filename = audioFilename
      audioInputs.push(audioInput)
    } else if (audioInputsJson) {
      try {
        const audioInputsJsonStr = '{"audioInputs": ' + audioInputsJson.replace('\\"', '"') + '}' // wrap the json array with an object so we can parse it
        const audioInputsData = JSON.parse(audioInputsJsonStr)
        if (audioInputsData && audioInputsData.audioInputs) {
          for (const audioInputData of audioInputsData.audioInputs) {
            const audioType: FFMpegSourceAudioType = FFMpegSourceAudioType[audioInputData.type as keyof typeof FFMpegSourceAudioType] // enum lookup - ref: https://stackoverflow.com/a/56076148
            if (audioType) {
              const audioInput = new FFMpegAudioInput(audioType, audioInputData.duration, audioInputData.inputArgsStr)
              audioInputs.push(audioInput)
            }
          }
        }
      } catch (e) {
        console.error('StreamhubSourcesAPI - getStreamSourceFromJson - audioInputs - error: ', e)
      }
    }

    const overlays: Array<FFMpegText> = []
    if (overlayTitle || overlayShowTimecode) {
      // NB: set directly on the source object further down
    } else if (overlaysJson) {
      try {
        const overlaysJsonStr = '{"overlays": ' + overlaysJson.replace('\\"', '"') + '}' // wrap the json array with an object so we can parse it
        const overlaysData = JSON.parse(overlaysJsonStr)
        if (overlaysData && overlaysData.overlays) {
          for (const overlayData of overlaysData.overlays) {
            const overlay = new FFMpegText(overlayData.cmd, overlayData.isTimecode)
            overlays.push(overlay)
          }
        }
      } catch (e) {
        console.error('StreamhubSourcesAPI - getStreamSourceFromJson - audioInputs - error: ', e)
      }
    }

    const source = new InputSource(sourceId, name, videoInputs, audioInputs, overlays)
    if (overlayTitle) source.overlayTitle = overlayTitle
    if (overlayShowTimecode !== undefined) source.overlayShowTimecode = overlayShowTimecode ?? false

    return source
  }

  // NB: currently we can only add/set a single video & audio input when creatings or updating them
  // NB: but they get stored & the response returns them as an array (so in future we could support adding multiple)
  async createSourceFromJson (name?: string, videoFilename?: string, videoInputsJson?: string, audioFilename?: string, audioInputsJson?: string, overlayTitle?: string, overlayShowTimecode?: boolean, overlaysJson?: string): Promise<InputSource> {
    try {
      // NB: uses a dummy id (which is ignored when calling the api to create the new source)
      const newSource = await this.getStreamSourceFromJson(-1, name, videoFilename, videoInputsJson, audioFilename, audioInputsJson, overlayTitle, overlayShowTimecode, overlaysJson)
      if (newSource && newSource.videoInputs && newSource.videoInputs.length > 0) {
        return this.createSource(newSource)
      }
    } catch (error) {
      console.error('StreamhubSourcesAPI - createSourceFromJson - ERROR: ', error)
      throw error
    }
    throw Error('Failed to create source') // fallback error
  }

  async updateSourceFromJson (sourceId: number, name?: string, videoFilename?: string, videoInputsJson?: string, audioFilename?: string, audioInputsJson?: string, overlayTitle?: string, overlayShowTimecode?: boolean, overlaysJson?: string): Promise<InputSource> {
    try {
      const newSource = await this.getStreamSourceFromJson(sourceId, name, videoFilename, videoInputsJson, audioFilename, audioInputsJson, overlayTitle, overlayShowTimecode, overlaysJson)
      if (newSource && newSource.videoInputs && newSource.videoInputs.length > 0) {
        return this.updateSource(newSource)
      }
    } catch (error) {
      console.error('StreamhubSourcesAPI - updateSourceFromJson - ERROR: ', error)
      throw error
    }
    throw Error('Failed to update source') // fallback error
  }

  // -------

  getSourcePreviewImageURL (source: InputSource, width?: number): string {
    // NB: we append a timestamp to the end of the url, so when it changes we get the new version of the image instead of a cached one
    return this.apiClient.apiBaseUrl + '/source/' + source.id + '/preview' + '?' + (width ? 'width=' + width : '') + '&t=' + source.updatedAt?.toString() ?? source.createdAt?.toString() ?? source.id // Date.now()
  }

  // -------
}
