import React, { useContext, useEffect, useState } from 'react'

import { Program, Project } from 'src/core/models'
import { ProjectAdminContext } from 'src/core/providers'

import { ProjectAccessSettingsView } from './ProjectAccessSettingsView'
import { ProjectSettingsWatermarkSummaryView } from './watermark/ProjectSettingsWatermarkSummaryView'
import { ProjectTranscoderSettingsView } from './ProjectTranscoderSettingsView'
import { ProjectUserSettingsView } from './ProjectUserSettingsView'

import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkMessage from 'src/core/components/ArkMessage'

import { OBJECT_PROJECT_NAME } from 'src/constants/strings'

import styles from './ProjectSettingsView.module.css'

export interface ProjectSettingsViewProps {
  // NB: we only really need the company & project id's to be passed in & not selected objects from the user provider,
  // NB: as we need to load full data for both which isn't (currently) set for the site wide user selection data
  // NB: BUT, to catch changes to the project force 2fa status which need to force a page/view reload, we no pass in the project object
  //     (but only use it for that reason here, & still query the additional project data with a dedicated api call)
  companyId: number
  selectedProject: Project // projectId: number
}

const ProjectSettingsView = (props: ProjectSettingsViewProps) => {
  const { companyId, selectedProject } = props

  const projectAdminContext = useContext(ProjectAdminContext)

  const [project, setProject] = useState<Project | undefined>(undefined) // NB: includes additional project info including company transcoder settings vs the default selectedProject loaded version

  const [programs, setPrograms] = useState<Array<Program> | undefined>(undefined)

  const [loadingProject, setLoadingProject] = useState<boolean>(false)
  const [loadingPrograms, setLoadingPrograms] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<Error | undefined>(undefined)

  const loadProjectInfo = async () => {
    if (loadingProject) return
    setLoadingProject(true)
    setLoadingError(undefined)
    // setProject(undefined) // NB: commented out - we don't want to clear it when reloading/refreshing
    // await new Promise(resolve => setTimeout(resolve, 2000)) // DEBUG ONLY: add a delay to test the loading state
    try {
      // NB: project managers do have access to the project info api endpoint so its ok to use that here from an access point of view
      // NB: & its project data includes the company transcoder settings we need in some settings sub-views/panels
      const _project = await projectAdminContext.actions.getProjectInfo(companyId, selectedProject.id)
      console.log('ProjectSettingsView - _loadProjectInfo - _project: ', _project)
      if (_project) {
        setProject(_project)
      } else {
        throw new Error('Failed to load project details')
      }
    } catch (error) {
      setLoadingError(error)
    }
    setLoadingProject(false)
  }

  const loadPrograms = async () => {
    if (loadingPrograms) return
    try {
      setLoadingPrograms(true)
      setLoadingError(undefined)
      const programs = await projectAdminContext.actions.getAllCompanyProjectPrograms(companyId, selectedProject.id)
      setPrograms(programs ?? undefined)
    } catch (error) {
      setLoadingError(error)
    }
    setLoadingPrograms(false)
  }

  const loadData = async () => {
    await loadProjectInfo()
    if (!loadingError) await loadPrograms()
  }

  // TESTING: reload the project info if the selected project updates (e.g. when transcoder settings have changed, so all the sub-settings components get the new updated values)
  // NB: currently not triggering a full `loadData` as programs data isn't expected to change with a selectedProject change (unless the project is changed to a different one completely, but with current handling we'd redirect to the project dashboard when that happens & so it would never trigger)
  // NB: if for some reason that changes in the future, it shouldn't be a problem to flip to full data reloading here if needed...
  useEffect(() => {
    console.log('ProjectSettingsView - useEffect - selectedProject:', selectedProject)
    console.log('ProjectSettingsView - useEffect - selectedProject.transcoderSettings - transcodersEnabled:', selectedProject.transcoderSettings?.transcodersEnabled)
    loadProjectInfo()
  }, [selectedProject])

  // -------

  // load the full project info on component init/mount or if the company id or project id or data changes
  // NB: run an async function via useEffect - ref: https://stackoverflow.com/a/53572588
  useEffect(() => {
    async function loadAsync () {
      await loadData()
    }
    // load if we haven't loaded already or reload if the project selection has changed
    if (!loadingProject && (!project || project.id !== selectedProject.id)) {
      loadAsync()
    }
  }, [companyId, selectedProject])

  // -------

  // NB: only show the loading indicator on initial page load, not reloads (or it forces the child settings components to fully reload as well)
  if (!project && !programs && (loadingProject || loadingPrograms)) return (<ArkLoaderView message={'Loading ' + OBJECT_PROJECT_NAME + ' Settings'} className={styles.loading} />)

  if (loadingError) {
    return (
      <div className={`${styles.settingsError}`}>
        <ArkMessage negative>
          <ArkMessage.Header>Error</ArkMessage.Header>
          <p>{loadingError.message}</p>
        </ArkMessage>
      </div>
    )
  }

  if (!project) { // !company ||
    return (
      <div className={`${styles.settingsError}`}>
        <ArkMessage negative>
          <ArkMessage.Header>Error</ArkMessage.Header>
          <p>Failed to load {OBJECT_PROJECT_NAME} settings data</p>
        </ArkMessage>
      </div>
    )
  }

  // -------

  return (
    <div className={styles.settingsView}>
      <div className={styles.col1}>
        <ProjectTranscoderSettingsView companyId={companyId} project={project} projectPrograms={programs ?? []} />
      </div>
      <div className={styles.col2}>
        <ProjectSettingsWatermarkSummaryView companyId={companyId} project={project} />
        <ProjectAccessSettingsView companyId={companyId} project={project} />
        <ProjectUserSettingsView companyId={companyId} project={project} />
      </div>
    </div>
  )
}

export { ProjectSettingsView }
