import React from 'react'

import { withProjectAdminContext, IProjectAdminMultiContext } from 'src/core/providers'

import { Channel } from 'src/core/models'

import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_CHANNEL_NAME } from 'src/constants/strings'

export type ProjectChannelDeleteCallback = (channel: Channel) => void

interface IProps extends IProjectAdminMultiContext {
  companyId: number
  projectId: number
  channel: Channel
  onDidDelete?: ProjectChannelDeleteCallback
}
interface IState {}

class ChannelDeleteView extends React.Component<IProps & { className?: string }, IState> {
  render () {
    const { channel, className } = this.props
    if (!channel) return (<></>)
    return (
      <div className={(className ? ' ' + className : '')}>
        <ArkSpacer />
        <ArkManagerDeleteButton
          itemId={channel.id}
          itemName={channel.name}
          itemTypeName={OBJECT_CHANNEL_NAME}
          buttonTitle={'DELETE ' + OBJECT_CHANNEL_NAME}
          onDelete={this.onDelete}
          onDeleteComplete={() => { if (this.props.onDidDelete) this.props.onDidDelete(channel) }}
        />
      </div>
    )
  }

  onDelete = async (_itemId: number): Promise<boolean> => {
    // NB: DON'T catch the error/exception here, let it throw (or if you do, rethrow it so the calling code can catch & display it)
    const { companyId, projectId, channel } = this.props
    const deleteResult = await this.props.projectAdminContext.actions.deleteCompanyProjectChannel(companyId, projectId, channel.id)
    console.log('ChannelDeleteView - onDelete - deleteCompanyProjectChannel - deleteResult: ', deleteResult)
    return deleteResult
  }
}

export default withProjectAdminContext(ChannelDeleteView)
