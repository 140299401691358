import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import * as ROUTES from 'src/constants/routes'
import {
  OBJECT_NOTICE_NAME,
  OBJECT_NOTICE_NAME_TITLE,
  OBJECT_PROJECT_NAME,
  SECTION_MANAGER_SUFFIX_NAME
} from 'src/constants/strings'
import ArkCheckbox from 'src/core/components/ArkCheckbox'
import ArkIcon from 'src/core/components/ArkIcon'
import ArkListItem, { ArkListItemDetail } from 'src/core/components/ArkListItem'
import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkManagerListView from 'src/core/components/ArkManagerListView/ArkManagerListView'
import { Channel, Notice, Project } from 'src/core/models'
import ProjectCardProvider, { ProjectCardContextValue, useProjectCard } from 'src/core/providers/ProjectCardProvider'
import ProjectChannelProvider, { ProjectChannelContextValue, useProjectChannel } from 'src/core/providers/ProjectChannelProvider'
import ProjectNoticeProvider, { ProjectNoticeContextValue, useProjectNotice } from 'src/core/providers/ProjectNoticeProvider'
import { IUserContext, useUser } from 'src/core/providers/UserProvider'

import ArkProjectManagerPage from '../../components/ArkProjectManagerPage/ArkProjectManagerPage'
import NoticeCreateUpdateModal from './NoticeCreateUpdateModal'
import NoticeSidebar from './NoticeSidebar'
import { getFilteredNotices, getNoticeDismissibleText } from './utilities'

const ProjectNoticesPage = () => {
  const projectCard: ProjectCardContextValue = useProjectCard()
  const projectChannel: ProjectChannelContextValue = useProjectChannel()
  const projectNotice: ProjectNoticeContextValue = useProjectNotice()
  const user: IUserContext = useUser()

  const [filter, setFilter] = useState<string>('')
  const [selectedNoticeId, setSelectedNoticeId] = useState<number>()
  const [showCreateUpdateModal, setShowCreateUpdateModal] = useState<boolean>(false)

  const channels: Channel[] = projectChannel.getChannels()
  const notices: Notice[] = projectNotice.getNotices()
  const project: Project = user.store.selectedProject!
  const selectedNotice: Notice | undefined = _.find(notices, { id: selectedNoticeId })

  useEffect(() => {
    // console.log('ProjectNoticesPage - load')
    (async (): Promise<void> => {
      await projectCard.fetchCardsInteractive()
      await projectChannel.fetchChannelsInteractive()
      await projectNotice.fetchNoticesInteractive()
      // console.log('ProjectNoticesPage - loaded')
    })()
    return () => {
      // console.log('ProjectNoticesPage - unload')
      projectCard.clearCards()
      projectChannel.clearChannels()
      projectNotice.clearNotices()
    }
  }, [])

  const onCreateUpdateSubmit = async (props: Partial<Notice>) => {
    // console.log('ProjectNoticesPage - onCreateUpdateSubmit - props:', props)

    // create
    if (!selectedNotice) {
      const notice: Notice | undefined = await projectNotice.createNoticeInteractive(props)
      if (!notice) return
      setShowCreateUpdateModal(false)
      setSelectedNoticeId(notice.id)
      return
    }

    // update
    if (!await projectNotice.updateNoticeInteractive(selectedNotice.id, props)) return
    setShowCreateUpdateModal(false)
  }

  const onDeleteClick = async () => {
    // console.log('ProjectNoticesPage - onDeleteClick')
    if (!await projectNotice.deleteNoticeInteractive(selectedNotice!.id)) return
    setSelectedNoticeId(undefined)
  }

  const onDuplicateClick = async () => {
    // console.log('ProjectNoticesPage - onDuplicateClick')
    const notice: Notice | undefined = await projectNotice.duplicateNoticeInteractive(selectedNotice!.id)
    if (!notice) return
    setSelectedNoticeId(notice.id)
  }

  const rightSidebarComponent: JSX.Element | undefined = selectedNotice && (
    <NoticeSidebar
      channels={channels}
      notice={selectedNotice}
      onDeleteClick={onDeleteClick}
      onDuplicateClick={onDuplicateClick}
      onUpdateClick={() => setShowCreateUpdateModal(true)}
    />
  )

  const listItem = (notice: Notice) => (
    <ArkListItem
      active={notice.id === selectedNoticeId}
      description={notice.description}
      disabled={!notice.enabled}
      filter={filter}
      key={notice.id}
      leftComponent={<ArkIcon name='notice' />}
      onClick={() => setSelectedNoticeId(notice.id)}
      rightComponent={
        <>
          <ArkListItemDetail title='Dismissible'>{getNoticeDismissibleText(notice).toUpperCase()}</ArkListItemDetail>
          <ArkListItemDetail title='Priority'>{notice.priority}</ArkListItemDetail>
          <ArkListItemDetail title='Enabled'>
            <ArkCheckbox
              checked={notice.enabled}
              onChange={(event) => {
                event.stopPropagation()
                projectNotice.updateNoticeInteractive(notice.id, { enabled: !notice.enabled })
              }}
              toggle
            />
          </ArkListItemDetail>
        </>
      }
      title={notice.name}
    />
  )

  return (
    <>
      <ArkProjectManagerPage
        onRightSidebarClose={() => setSelectedNoticeId(undefined)}
        rightSidebar={rightSidebarComponent}
      >
        <ArkManagerContentView
          breadcrumb={[{
            path: ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_VIEW, project.id),
            title: ROUTES.formatBreadcrumbRootTitle(project.name, `${OBJECT_PROJECT_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`)
          }]}
          title={OBJECT_NOTICE_NAME_TITLE}
        >
          <ArkManagerListView
            filter={filter || undefined}
            filteredItems={getFilteredNotices(notices, filter)}
            filterForm={(
              <ArkManagerFilterForm
                filterTitle='Filter by name or description'
                filterValue={filter}
                onFilterChange={setFilter}
              />
            )}
            itemRow={listItem}
            items={notices}
            loading={projectNotice.getFetching()}
            onAdd={() => {
              setSelectedNoticeId(undefined)
              setShowCreateUpdateModal(true)
            }}
            onClearFilter={() => setFilter('')}
            selectedItem={selectedNotice}
            topbarAddItemTitle={`CREATE ${OBJECT_NOTICE_NAME}`}
          />
        </ArkManagerContentView>
      </ArkProjectManagerPage>
      <NoticeCreateUpdateModal
        notice={selectedNotice}
        onClose={() => setShowCreateUpdateModal(false)}
        onSubmit={onCreateUpdateSubmit}
        open={showCreateUpdateModal}
      />
    </>
  )
}

const Providers = () => (
  <ProjectCardProvider>
    <ProjectChannelProvider>
      <ProjectNoticeProvider>
        <ProjectNoticesPage />
      </ProjectNoticeProvider>
    </ProjectChannelProvider>
  </ProjectCardProvider>
)

export default Providers
