/**
 * ArkProjectDropdownRenderer
 */

import React, { useContext } from 'react'

import { Project, UserProject } from '../../../core/models'
import { withUserContext } from '../../../core/providers'
import { UserContext } from '../../providers/UserProvider'
import ArkProjectDropdown from '../ArkProjectDropdown'

export interface ArkProjectDropdownRendererProps {
  className?: string
}

const ArkProjectDropdownRenderer = (props: ArkProjectDropdownRendererProps) => {
  const { className } = props

  const { actions, store } = useContext(UserContext)
  const { deselectCurrentProject, selectProject } = actions
  const { adminProjects, loadingCompanyData, loadingProjectData, selectedProject, userProjects } = store

  const onSelect = (id: number) => {
    id ? selectProject(id) : deselectCurrentProject()
  }

  const allProjects: Array<UserProject | Project> = [...(userProjects || [])] // add all the user projects to the dropdown
  // admin only projects - if the user selected company has adminProjects set, the user is a company admin so add any additional projects not added from the userProjects list
  if (adminProjects) {
    for (const project of adminProjects) {
      const userProject = userProjects?.find((p) => p.id === project.id)
      if (!userProject) allProjects.push(project)
    }
  }

  // disable the project dropdown if the parent says so, or if the parent user data selections are loading/updating
  const loadingParentData = loadingCompanyData || loadingProjectData

  return (
    <ArkProjectDropdown
      className={className}
      loading={loadingParentData}
      onSelect={onSelect}
      placeholder={loadingParentData ? 'Loading' : undefined}
      projects={allProjects}
      selectedId={selectedProject?.id}
    />
  )
}

export default withUserContext(ArkProjectDropdownRenderer)
