import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

import { Channel, ProjectSummary, ProjectUser } from 'src/core/models'

import { ServerContext, ProjectAdminContext } from 'src/core/providers'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkForm, { ArkFormField, ArkFormFieldOption, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkPreviewCopyView from 'src/core/components/ArkPreviewCopyView'

import { DEBUG_MODE, PROJECT_GUEST_LINK_GENERATOR_DEBUG } from 'src/constants/config'
import { OBJECT_CHANNEL_NAME, OBJECT_GUEST_NAME } from 'src/constants/strings'

import styles from './ProjectGuestLinkConstructorView.module.css'

// TODO:
const formSchema = yup.object().shape({})

export interface ProjectGuestLinkConstructorViewProps {
  companyId: number
  projectId: number
  user: ProjectUser
}

const ProjectGuestLinkConstructorView = (props: ProjectGuestLinkConstructorViewProps) => {
  const { companyId, projectId, user } = props
  const projectAdminContent = useContext(ProjectAdminContext)
  const serverContext = useContext(ServerContext)

  // NB: basic check if running in debug mode AND loading from a local dev build (won't catch if you've set the IP to something else!)
  const isLocalDev = DEBUG_MODE && ['localhost', '127.0.0.1'].includes(window.location.hostname)
  console.log('ProjectGuestLinkConstructorView - isLocalDev:', isLocalDev)

  const mounted = useRef(false)
  const [projectSummary, setProjectSummary] = useState<ProjectSummary | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error>()

  const [userChannels, setUserChannels] = useState<Array<Channel> | undefined>() // a list of all the channels the user has access too
  const [currentChannelId, setCurrentChannelId] = useState<number | undefined>()
  const [enableLocalDevURL, setEnableLocalDevURL] = useState<boolean>(isLocalDev) // NB: enabling this by default when loaded while running on a local dev build

  const [shareLink, setShareLink] = useState<string>()
  const [shareLinkBaseURL, setShareLinkBaseURL] = useState<string>()
  const [shareLinkArgs, setShareLinkArgs] = useState<Map<string, string>>()

  // NB: we don't currently have an easy way to load the project channels for a specific user from the api
  // NB: so we use the project summary endpoint to get the whole project data & then filter down to what we need for this page once its loaded (not ideal, but works for now..)
  const loadProjectSummary = async () => {
    if (loading) return
    setLoading(true)
    try {
      const _projectSummary = await projectAdminContent.actions.getProjectSummary(companyId, projectId)
      console.log('ProjectGuestLinkConstructorView - loadProjectSummary - _projectSummary: ', _projectSummary)
      // await new Promise((resolve) => setTimeout(resolve, 2000)) // DBG ONLY
      if (!mounted.current) return // halt if this component is no longer mounted (user navigated away before the api call returned)
      setProjectSummary(_projectSummary)
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }

  const updateShareLink = () => {
    console.log('ProjectGuestLinkConstructorView - updateShareLink - user.guestInfo:', user.guestInfo)
    const guestShareLink = user.guestInfo?.url
    if (!guestShareLink) {
      setError(new Error('Failed to load the share link'))
      return
    }
    // setShareLink(guestShareLink)

    // deconstruct the guest share url supplied by the api into its base url & each query arg
    // NB: if worried about or hit compatibility issues with `URLSearchParams` look at a lib like `query-string` - ref: https://stackoverflow.com/a/53079875
    const queryStartIndex = guestShareLink.indexOf('?')
    let baseURL = queryStartIndex >= 0 ? guestShareLink.substring(0, queryStartIndex) : guestShareLink
    const queryString = queryStartIndex >= 0 ? guestShareLink.substring(queryStartIndex) : guestShareLink
    const queryArgsIterator = new URLSearchParams(queryString)
    // const queryArgs = Array.from(queryArgsIterator.entries())
    const queryArgs = new Map<string, string>()
    for (const [key, val] of queryArgsIterator.entries()) queryArgs.set(key, val)

    // special handling if running via in local dev build - flip to use a custom base url that redirects to localhost instead of an actual server domain
    // NB: currently assuming each guest share url supports the extra `-local` version (needs to have been pre-setup at the url service to support this for each base url)
    if (isLocalDev && enableLocalDevURL) baseURL = baseURL + '-local'
    // console.log('ProjectGuestLinkConstructorView - updateShareLink - baseURL:', baseURL, ' queryString:', queryString)
    // console.log('ProjectGuestLinkConstructorView - updateShareLink - queryArgs:', queryArgs)

    // TEMP-WORKAROUND: the api server can wrongly give a `env=undefined` value, override it if so
    const guestEnv = queryArgs.get('env')
    // console.log('QuickviewPage - env - guestEnv:', guestEnv, ' typeof:', typeof guestEnv)
    if (guestEnv === 'undefined') {
      console.log('QuickviewPage - env - API BUG DETECTED - guestEnv === \'undefined\' (string) - OVERRIDE...')
      const apiServerType = serverContext.store.apiServerType
      if (apiServerType) {
        queryArgs.set('env', apiServerType)
      } else {
        queryArgs.delete('env') // remove the `env=` arg if we don't have a server selected
      }
    }

    // TODO: add in any configurable args to the url (overriding defaults if they conflict)
    if (currentChannelId !== undefined) {
      queryArgs.set('channel_id', '' + currentChannelId)
    }

    // reconstruct the final link url
    let finalURL = baseURL + '?'
    for (const [key, val] of queryArgs) {
      finalURL += `${key}=${val}&`
    }
    if (finalURL.endsWith('&') || finalURL.endsWith('?')) finalURL = finalURL.substring(0, finalURL.length - 1)
    console.log('ProjectGuestLinkConstructorView - updateShareLink - finalURL:', finalURL)

    setShareLinkBaseURL(baseURL)
    setShareLinkArgs(queryArgs)
    setShareLink(finalURL)
  }

  const loadUserMappings = () => {
    console.log('ProjectGuestLinkConstructorView - loadUserMappings - projectSummary:', projectSummary)
    if (!projectSummary) return

    // grab a ref to the fields we use from the project summary data
    const groupUsersLookup = projectSummary.groupUsersLookup
    const groupChannelsLookup = projectSummary.groupChannelsLookup
    const projectChannels = projectSummary.channels

    // get a list of channels the user currently has access too
    // NB: to do this we:
    //  > 1) grab a list of all group ids the user is mapped/assigned too
    //  > 2) then grab a list of the channel ids mapped to those groups
    //  > 3) then grab the channels for those channel ids (so we have the channel names available to show in the UI)
    const userGroupIds: Array<number> = []
    const userChannelIds: Array<number> = []
    const userChannels: Array<Channel> = []
    if (groupUsersLookup) {
      for (const [groupId, userIds] of groupUsersLookup) {
        if (userIds.includes(user.id)) userGroupIds.push(groupId)
      }
    }
    if (groupChannelsLookup && userGroupIds.length > 0) {
      for (const userGroupId of userGroupIds) {
        const groupChannelIds = groupChannelsLookup.get(userGroupId)
        if (groupChannelIds) {
          for (const userChannelId of groupChannelIds) {
            // don't add dupe channel ids if it already exists from a previously added group mapping
            if (!userChannelIds.includes(userChannelId)) {
              userChannelIds.push(userChannelId)
            }
          }
        }
      }
    }
    if (projectChannels && projectChannels.length > 0 && userChannelIds.length > 0) {
      for (const userChannelId of userChannelIds) {
        const projectChannel = projectChannels.find(channel => channel.id === userChannelId)
        if (projectChannel) userChannels.push(projectChannel)
      }
    }
    console.log('ProjectGuestLinkConstructorView - loadUserMappings - userGroupIds:', userGroupIds)
    console.log('ProjectGuestLinkConstructorView - loadUserMappings - userChannelIds:', userChannelIds)
    console.log('ProjectGuestLinkConstructorView - loadUserMappings - userChannels:', userChannels)
    setUserChannels(userChannels)
    updateShareLink()
  }

  useEffect(() => {
    console.log('ProjectGuestLinkConstructorView - MOUNTED')
    mounted.current = true
    return () => {
      mounted.current = false
      console.log('ProjectGuestLinkConstructorView - UN-MOUNTED')
    }
  }, [])

  useEffect(() => {
    async function runAsync () {
      await loadProjectSummary()
    }
    runAsync()
  }, [])

  useEffect(() => {
    loadUserMappings()
  }, [projectSummary])

  // update the share link whenever one of the form input values change
  useEffect(() => {
    updateShareLink()
  }, [currentChannelId, enableLocalDevURL])

  const subFormFields: Array<ArkFormField> = []

  subFormFields.push(
    {
      type: ArkFormFieldType.Field,
      key: 'guestLinkNotice',
      content: (
        (<p className={styles.guestLinkNotice}>
          Generate a guest link to send to users allowing them quick access without registration.
        </p>)
      )
    }
  )

  subFormFields.push(
    {
      type: ArkFormFieldType.Field,
      key: 'guestName',
      content: (
        (<div className={styles.guestName}>
          <span className={styles.title}>Guest Link Name:</span><span className={styles.name}>{user.name()}</span>
        </div>)
      )
    }
  )

  const userChannelOptions: Array<ArkFormFieldOption> = []
  userChannelOptions.push({ key: 'channel_' + 'none', text: 'Let recipient choose on entry', value: 0 })
  const userChannelCount = userChannels?.length ?? 0
  if (userChannels) {
    for (const userChannel of userChannels) {
      userChannelOptions.push({
        key: 'channel_' + userChannel.id,
        // text: userChannel.name,
        value: userChannel.id,
        // TESTING: ArkForm.Dropdown new custom `children` options prop support (to allow for non-text content)
        children: (
          <div className={styles.channelOption + ' text'}>{/* NB: set a global/generic 'text' className to inherit the correct text colour */}
            <ArkAvatar
              className={styles.channelAvatar}
              maxInitials={3}
              name={userChannel.name}
              round={false}
              size='30'
            />
            {userChannel.name}
          </div>
        )
      })
    }
  }

  subFormFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'detailsFieldset',
    // label: 'Link Options',
    fields: [
      { type: ArkFormFieldType.Field, key: 'divider1', content: (<hr className={styles.channelsDivider} />) },
      {
        type: ArkFormFieldType.Field,
        key: 'channelHeader',
        content: (
          <>
            <div className={styles.channelTitle}>{OBJECT_CHANNEL_NAME}</div>
            <div className={styles.channelDesc}>
              {userChannelCount > 0 && (
                <>
                  <p>This guest link currently has access to {userChannelCount} channel{userChannelCount !== 1 ? 's' : ''}</p>
                  <p>Optionally select which channel to display when recipients open the link.</p>
                </>
              )}
              {userChannelCount === 0 && (
                <div className={styles.noChannelsWarning}>
                  <div className={styles.warningIcon}>⚠️</div>
                  <div className={styles.warningMsg}>
                    <p>This guest link does not have access to any channels.</p>
                    <p>After sharing make sure there is access by activating this guest link in a group that has channel access.</p>
                  </div>
                </div>
              )}
            </div>
          </>
        ),
        wrapperProps: { className: styles.channelHeader }
      },
      {
        type: ArkFormFieldType.Dropdown,
        key: 'channel',
        // label: OBJECT_CHANNEL_NAME,
        required: false,
        defaultValue: 0,
        options: userChannelOptions,
        fieldProps: { scrolling: true } // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
        // description: (
        //   <div className={styles.channelDesc}>
        //     <p>This guest user currently has access to {userChannelCount} channel{userChannelCount !== 0 ? 's' : ''}</p>
        //     {userChannelCount > 0 && (<p>Optionally select which channel to display when the user opens the link.</p>)}
        //   </div>
        // )
      },
      ...(DEBUG_MODE && isLocalDev
        ? [
          {
            type: ArkFormFieldType.Checkbox,
            key: 'localDevURL',
            label: 'Local DEV URL',
            required: false,
            defaultValue: enableLocalDevURL,
            fieldProps: { style: { marginTop: 10, marginLeft: 0 } }
          }
        ]
        : []
      ),
      { type: ArkFormFieldType.Field, key: 'divider2', content: (<hr className={styles.channelsDivider} />) }
    ],
    collapsible: false,
    collapsed: false,
    bordered: false,
    className: styles.channelsFieldset
  })

  subFormFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      // { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { onClick: this.onCancel, floated: 'left' } },
      // { type: ArkFormFieldType.OKButton, key: 'submit', label: (this.props.mode === CompanyFormMode.Edit ? 'SAVE' : 'CREATE'), fieldProps: { loading: isSubmitting, floated: 'right' } }
    ],
    fieldProps: { widths: 'equal' }
  })

  // wrap the whole form in a fieldset
  const formFields: Array<ArkFormField> = []
  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'formFieldset',
    // label: '',
    fields: [
      ...subFormFields,
      {
        type: ArkFormFieldType.Field,
        key: 'shareLink',
        content: (
          (
            <div className={styles.link}>
              {/* <Header as="h3" inverted>
                {OBJECT_GUEST_NAME} Share Link:
              </Header> */}
              <div className={styles.linkTitle}>{OBJECT_GUEST_NAME} Share Link:</div>
              <div className={styles.linkWrapper}>
                <div className={styles.linkPreview}>
                  <pre>{shareLink}</pre>
                </div>
                <div className={styles.linkCopy}>
                  <ArkPreviewCopyView title={OBJECT_GUEST_NAME + ' URL:'} value={shareLink} compactPopup showViewButton={false} />
                </div>
              </div>
            </div>
          )
        )
      }
    ],
    collapsible: false,
    collapsed: false
  })

  const onFormSubmit = async (_fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
  }

  const onFormValueChanged = async (fieldKey: string, fieldValue: any, _oldFieldValue: any) => {
    console.log('ProjectGuestLinkConstructorView - onFormValueChanged - fieldKey:', fieldKey, ' fieldValue:', fieldValue, ' typeof: ', typeof fieldValue)
    if (fieldKey === 'channel' && typeof fieldValue === 'number') {
      setCurrentChannelId(fieldValue > 0 ? fieldValue : undefined)
    } else if (fieldKey === 'localDevURL') {
      setEnableLocalDevURL(fieldValue)
    }
  }

  return (
    <>
      <ArkHeader as="h2" inverted>
        {OBJECT_GUEST_NAME} Share Link Configurator
      </ArkHeader>
      {error && (
        <ArkMessage negative>
          <ArkMessage.Header>Error</ArkMessage.Header>
          <ArkMessage.Item>{error.message}</ArkMessage.Item>
        </ArkMessage>
      )}
      {loading && (<ArkLoaderView message={'Loading...'} />)}
      {!loading && !error && (
        <>
          <ArkForm
            formKey="guestLinkConstructor"
            inverted
            formError={error}
            formFields={formFields}
            formSchema={formSchema}
            onFormSubmit={onFormSubmit}
            onValueChanged={onFormValueChanged}
            showLabels={true}
            insideModal={true}
          />
          <span>ℹ️ Apple TV App and guest links:<br></br>Sign in using the guest link on the iOS or web app and then use that to authorise using the Apple TV QR code option.</span>
          {PROJECT_GUEST_LINK_GENERATOR_DEBUG && (
            <div className={styles.linkDbg}>
              <ArkHeader as="h3" inverted>
                {OBJECT_GUEST_NAME} Share Link Debug:
              </ArkHeader>
              <pre>
                baseURL: {shareLinkBaseURL}<br />
                {shareLinkArgs && Array.from(shareLinkArgs.keys()).map(key => <div key={'arg_' + key}>{key}: {shareLinkArgs.get(key)}</div>)}
              </pre>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default ProjectGuestLinkConstructorView
