/**
 * ArkIconButton
 */

import React, { MouseEventHandler, ReactNode, useState } from 'react'

import ArkIcon, { IconName } from '../ArkIcon'

import styles from './ArkIconButton.module.css'

interface ArkIconButtonProps {
  active?: boolean
  activeColor?: string
  children?: ReactNode
  className?: string
  color?: string
  disabled?: boolean
  hidden?: boolean
  hoverColor?: string
  name?: IconName
  onClick?: () => void
  open?: boolean
  size?: number
  width?: number
}

const ArkIconButton = (props: ArkIconButtonProps) => {
  const {
    active,
    activeColor,
    children,
    className,
    color,
    disabled,
    hidden,
    hoverColor,
    name,
    onClick,
    open,
    size,
    width,
    ...otherProps
  } = props

  const [isHover, setIsHover] = useState(false)

  const _onClick: MouseEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation()
    !disabled && onClick && onClick()
  }

  let theColor = color
  if (active) theColor = activeColor || 'var(--blue)'
  if (isHover && !disabled && hoverColor) theColor = hoverColor

  return (
    <div>
      <div
        className={`${
          styles.button
        } ${
          !hoverColor && styles.hoverOpacity
        } ${
          className || ''
        } ${
          disabled ? styles.disabled : ''
        } ${
          hidden ? styles.hidden : ''
        } ${
          open ? styles.open : ''
        }`}
        onClick={_onClick}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={{ width }}
        {...otherProps}
      >
        {name && <ArkIcon color={theColor} name={name} size={size} />}
        {children}
      </div>
    </div>
  )
}

export default ArkIconButton
