import React from 'react'

import ArkAdminPage from '../../components/ArkAdminPage/ArkAdminPage'

interface IProps {}
interface IState {}

class AdminActivityPage extends React.Component<IProps, IState> {
  render () {
    return (<ArkAdminPage>
      <div className="admin-activity">
        ADMIN &gt; ACTIVITY
      </div>
    </ArkAdminPage>)
  }
}
export default AdminActivityPage
