import _ from 'lodash'

export const isChrome = () => {
  return navigator.userAgent.toLocaleLowerCase().includes('chrome')
}

export const isIos = () => {
  const userAgent = navigator.userAgent.toLocaleLowerCase()
  return _.some(['ipad', 'iphone', 'ipod'], value => userAgent.includes(value))
}
