import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import { AUDIO_LEVELS_ENABLED, AUDIO_LEVELS_INTERVAL } from 'src/constants/config'

import { useViewer } from './ViewerProvider'

type AudioLevels = {
  [id: number]: number
}

type AudioLevelsContextValue = {
  getChannelAudioLevelPostFader: () => number
  getChannelAudioLevelPreFader: () => number
  getProgramAudioLevelPostFader: (id: number) => number
  getProgramAudioLevelPostFaderDisabled: (id: number) => boolean
  getProgramAudioLevelPreFader: (id: number) => number
  getProgramNoAudio: (id: number) => boolean
  setProgramAudioLevel: (id: number, value: number) => void
}

const AudioLevelsContext = createContext<AudioLevelsContextValue>({} as AudioLevelsContextValue)

export const useAudioLevels = () => useContext(AudioLevelsContext)

type AudioLevelsProviderProps = {
  children: ReactNode
}

const AudioLevelsProvider = (props: AudioLevelsProviderProps) => {
  const viewer = useViewer()

  const _audioLevelsRef = useRef<AudioLevels>({})

  const [audioLevels, setAudioLevels] = useState<AudioLevels>({})

  /**
   * timer (force re-render)
   */

  useEffect(() => {
    // console.log('AudioLevelsProvider - timer - start')
    if (!AUDIO_LEVELS_ENABLED) return
    const timer = setInterval(() => {
      // console.log('AudioLevelsProvider - timer - loop')
      setAudioLevels({ ..._audioLevelsRef.current })
    }, AUDIO_LEVELS_INTERVAL)
    return () => {
      // console.log('AudioLevelsProvider - timer - stop')
      clearInterval(timer)
    }
  }, [viewer])

  /**
   * programs
   */

  const getProgramNoAudio = (id: number): boolean => {
    // console.log('AudioLevelsProvider - getProgramNoAudio - id:', id)
    return audioLevels[id] === undefined
  }

  const getProgramAudioLevelPreFader = (id: number): number => {
    // console.log('AudioLevelsProvider - getProgramAudioLevelPreFader - id:', id)
    return audioLevels[id] || 0
  }

  const getProgramAudioLevelPostFader = (id: number): number => {
    // console.log('AudioLevelsProvider - getProgramAudioLevelPostFader - id:', id)
    if (viewer.getProgramMute(id)) return 0
    return getProgramAudioLevelPreFader(id) * viewer.getProgramVolume(id)
  }

  const getProgramAudioLevelPostFaderDisabled = (id: number): boolean => {
    // console.log('AudioLevelsProvider - getProgramAudioLevelPostFaderDisabled - id:', id)
    if (audioLevels[id] === undefined) return true
    if (viewer.getProgramVolume(id) === 0) return true
    const autoSoloId: number | undefined = viewer.getChannelAutoSoloProgram()
    if (autoSoloId) return autoSoloId !== id
    if (viewer.getProgramSolo(id)) return false
    if (viewer.getSomeProgramsSolo()) return true
    if (viewer.getProgramMute(id)) return true
    return false
  }

  const setProgramAudioLevel = (id: number, value: number) => {
    // console.log('AudioLevelsProvider - setProgramAudioLevel - id:', id, 'value:', value)
    if (!AUDIO_LEVELS_ENABLED) return
    // if (!viewer.audioStarted) return
    _audioLevelsRef.current[id] = value
  }

  /**
   * channel
   */

  const getChannelAudioLevelPreFader = (): number => {
    // console.log('AudioLevelsProvider - getChannelAudioLevelPreFader')
    return _.chain(viewer.programs)
      .filter(program => !viewer.getProgramMute(program.id))
      .map(program => getProgramAudioLevelPostFader(program.id))
      .push(0) // default
      .max()
      .value()
  }

  const getChannelAudioLevelPostFader = (): number => {
    // console.log('AudioLevelsProvider - getChannelAudioLevelPostFader')
    if (viewer.getChannelMute()) return 0
    return getChannelAudioLevelPreFader() * viewer.getChannelVolume()
  }

  return (
    <AudioLevelsContext.Provider value={{
      getChannelAudioLevelPostFader,
      getChannelAudioLevelPreFader,
      getProgramAudioLevelPostFader,
      getProgramAudioLevelPostFaderDisabled,
      getProgramAudioLevelPreFader,
      getProgramNoAudio,
      setProgramAudioLevel
    }}>
      {props.children}
    </AudioLevelsContext.Provider>
  )
}

export default AudioLevelsProvider
