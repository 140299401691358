import React, { useContext, useEffect, useRef, useState } from 'react'

import { NavContext, ProjectAdminContext } from 'src/core/providers'

import { ProjectGroup, Channel, GroupChannelOperation } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkDataMappingForm, { ArkDataMappingItemChanges, ArkManagerFilteredItem } from 'src/core/components/ArkDataMappingForm/ArkDataMappingForm'
import ArkHighlightedText from 'src/core/components/ArkHighlightedText'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import * as ROUTES from 'src/constants/routes'

import { OBJECT_CHANNEL_NAME_PLURAL, OBJECT_GROUP_NAME } from 'src/constants/strings'

import dataMappingFormStyles from 'src/core/components/ArkDataMappingForm/ArkDataMappingForm.module.css'

interface IProps {
  companyId: number
  projectId: number
  group: ProjectGroup
  onChange?: Function
}

const ProjectGroupChannelsPanel = (props: IProps) => {
  const mounted = useRef(false)

  const { companyId, projectId, group, onChange: _onChange } = props

  const projectAdminContext = useContext(ProjectAdminContext)
  const navContext = useContext(NavContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [projectChannels, setProjectChannels] = useState<Array<Channel>>([])
  // const [groupChannels, setGroupChannels] = useState<Array<Channel>>([])

  const [filteredProjectChannels, setFilteredProjectChannels] = useState<Array<ArkManagerFilteredItem<Channel>> | undefined>()
  const [filter, setFilter] = useState<string | undefined>()

  const [selectedIds, setSelectedIds] = useState<Array<number>>([])

  // const [savedIds, setSavedIds] = useState<Array<number> | undefined>() // NB: not currently used, so skipping it
  // const [saveErrors, setSaveErrors] = useState<Map<number, any> | undefined>() // NB: not currently used, so skipping it

  const [showForm, setShowForm] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasUpdated, setHasUpdated] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const resetForm = () => {
    if (isSubmitting) return // don't allow resetting while submitting
    setIsSubmitting(false)
    setHasUpdated(false)
    setError(undefined)
    // setSavedIds(undefined)
    // setSaveErrors(undefined)
  }

  const resetView = () => {
    resetForm()
    setShowForm(false)
  }

  // -------

  // TESTING: equivalent to the componentDidUpdate method `if (this.props.group.id !== prevProps.group.id) { this.resetView() }`
  useEffect(() => {
    if (group.id) {
      resetView()
    }
  }, [group.id])

  // -------

  const _loadProjectChannels = async () => {
    if (companyId && projectId) {
      try {
        const projectChannels = await projectAdminContext.actions.getAllCompanyProjectChannels(companyId, projectId)
        return projectChannels
      } catch (error) {
        console.error('ProjectGroupChannelsPanel - _loadProjectChannels - error: ', error)
        throw error
      }
    }
  }

  const _loadGroupChannels = async () => {
    if (companyId && projectId && group.id) {
      try {
        const groupChannels = await projectAdminContext.actions.getProjectGroupChannels(companyId, projectId, group.id)
        return groupChannels
      } catch (error) {
        console.error('ProjectGroupChannelsPanel - _loadGroupChannels - error: ', error)
        throw error
      }
    }
  }

  const loadChannelsData = async () => {
    console.log('ProjectGroupChannelsPanel - loadChannelsData')
    if (loading === true) return false
    try {
      setLoading(true)
      setError(undefined)
      const projectChannels = await _loadProjectChannels()
      const groupChannels = await _loadGroupChannels()
      // TESTING: re-set the selectedIds to show the mappings saved from the api
      // NB: this will override any edits/changes to the form selection by the user, if this gets called after initial load
      const selectedIds: Array<number> = []
      if (groupChannels) {
        for (const groupChannel of groupChannels) {
          selectedIds.push(groupChannel.id)
        }
      }
      console.log('ProjectGroupChannelsPanel - loadChannelsData - selectedIds: ', selectedIds)
      setLoading(false)
      setProjectChannels(projectChannels || [])
      // setGroupChannels(groupChannels || [])
      setSelectedIds(selectedIds)
    } catch (error) {
      console.error('ProjectGroupChannelsPanel - loadChannelsData - error: ', error)
      setLoading(false)
      setProjectChannels([])
      // setGroupChannels([])
      setSelectedIds([])
      setError(error as Error)
    }
  }

  // -------

  const onShowForm = async () => {
    console.log('ProjectGroupChannelsPanel - onShowForm')
    setShowForm(true)
    setError(undefined)
    await loadChannelsData() // TESTING: moved this to only trigger when the form should show (avoid making api queries till we need the data)
  }

  // TODO: should this be called?
  // const onHideForm = () => {
  //   console.log('ProjectGroupChannelsPanel - onHideForm')
  //   setShowForm(false)
  //   setError(undefined)
  // }

  // -------

  // TESTING: takes the result of ArkDataMappingItemChanges (additions & deletions) & converts it to the format the api endpoint expects
  // TODO: move to the relevant api class so its re-usable else-where...
  const generateGroupChannelUpdateOperations = (groupId: number, itemChanges: ArkDataMappingItemChanges) => {
    const addOperations: Array<GroupChannelOperation> = []
    const delOperations: Array<GroupChannelOperation> = []
    for (const addItem of itemChanges.add) {
      addOperations.push({
        operation: 0, // 0 === ADD
        group_id: groupId,
        channel_id: addItem
      })
    }
    for (const delItem of itemChanges.del) {
      delOperations.push({
        operation: 2, // 2 == DELETE
        group_id: groupId,
        channel_id: delItem
      })
    }
    return [...delOperations, ...addOperations]
  }

  // -------

  const saveChanges = async (companyId: number, projectId: number, group: ProjectGroup, selectedIds: Array<number>, selectedIdChanges: ArkDataMappingItemChanges) => {
    setIsSubmitting(true)
    setHasUpdated(false)
    setError(undefined)
    // setSavedIds(undefined)
    // setSaveErrors(undefined)

    // convert the list off adds & dels to an operations list in the format the api expects
    const operations = generateGroupChannelUpdateOperations(group.id, selectedIdChanges)
    console.log('ProjectGroupChannelsPanel - saveChanges - operations: ', operations)

    if (operations.length === 0) {
      console.log('ProjectGroupChannelsPanel - saveChanges - 0 operations - HALT')
      // TODO: show an error/warning?
      setIsSubmitting(false)
      setHasUpdated(false)
      return
    }

    let savedIds: Array<number> | undefined
    let newSelectedIds: Array<number> | undefined
    let saveErrors: Map<number, any> | undefined
    let hasUpdated = false
    let error: Error | undefined

    try {
      // run all the add & delete mapping operations
      const result = await projectAdminContext.actions.updateProjectGroupChannels(companyId, projectId, group.id, operations)
      console.log('ProjectGroupChannelsPanel - saveChanges - addCompanyProjectGroupChannels - result: ', result, ' typeof: ', typeof result, ' instanceof Map: ', result instanceof Map)

      if (result === true) { // all operations succeeded
        if (mounted.current) {
          hasUpdated = true
          savedIds = operations.map(operation => operation.channel_id)
          newSelectedIds = selectedIds
        }
      } else if (result instanceof Map) { // 1 or more operations had errors (but some may have still succeeded)
        // all operations failed
        if (result.size === operations.length) {
          hasUpdated = false
        } else { // only some operations failed
          hasUpdated = true // set true if some added/updated ok
          savedIds = [] // get a list of successful operations
          for (const operation of operations) {
            if (result.has(operation.channel_id) === false) {
              savedIds.push(operation.channel_id)
            }
          }
          newSelectedIds = selectedIds.filter((selectedId) => !result.has(selectedId))
        }
        saveErrors = result // the result contains errors for each operation that failed
        error = { message: 'A problem occurred saving one or more of the changes, please try again.' } as any
      } else { // general/fallback error (with the whole api call)
        hasUpdated = false
        error = { message: 'A problem occurred saving the changes, please try again.' } as any
      }

      console.log('ProjectGroupChannelsPanel - saveChanges - hasUpdated: ', hasUpdated, ' savedIds: ', savedIds, ' newSelectedIds: ', newSelectedIds, ' saveErrors:', saveErrors, ' error:', error)
      if (mounted.current) {
        setIsSubmitting(false)
        setHasUpdated(hasUpdated)
        setSelectedIds(newSelectedIds ?? [])
        // setSavedIds(savedIds)
        // setSaveErrors(saveErrors)
        setError(error)
      }
      if (hasUpdated) {
        if (_onChange) _onChange()
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setHasUpdated(hasUpdated)
        // setSavedIds(savedIds)
        // setSaveErrors(saveErrors)
        setError(error as Error)
      }
    }
  }

  // -------

  const filterProjectChannels = (_filter: string) => {
    if (loading) return
    // NB: we currently filter out items even if they're enabled/mapped, but when you submit they will still submit as enabled/mapped (we just don't visually show them for now)
    // TODO: should we show filtered out items that are enabled/mapped in some way, to make it obvious they'll remain selected when you save the mapping form?
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredProjectChannels = filter
      ? projectChannels.reduce<Array<ArkManagerFilteredItem<Channel>>>((r, channel) => {
        let nameMatch = false
        if (channel.name.toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        if (nameMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          const filteredItem: ArkManagerFilteredItem<Channel> = {
            item: channel,
            matchingFields
          }
          r.push(filteredItem)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<Channel>>)
      : undefined
    setFilter(filter)
    setFilteredProjectChannels(filteredProjectChannels)
  }

  // const clearFilteredProjectChannels = () => {
  //   setFilter(undefined)
  //   setFilteredProjectChannels(undefined)
  // }

  // -------

  const renderProjectChannelsFilterForm = () => {
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        className={dataMappingFormStyles.filterForm}
        filterTitle='Filter by name'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          filterProjectChannels(filter)
        }}
      />
    )
  }

  // -------

  if (!group) return null
  return (
    <ArkPanel bordered={showForm} title={showForm ? OBJECT_GROUP_NAME + ' ' + OBJECT_CHANNEL_NAME_PLURAL : null}>

      {!showForm && (
        <ArkButton type="button" fluid size="large" loading={loading} onClick={onShowForm}>EDIT {OBJECT_GROUP_NAME} {OBJECT_CHANNEL_NAME_PLURAL}</ArkButton>
      )}

      {showForm && (
        <>
          <ArkDataMappingForm
            id="group-channels"
            sourceItems={projectChannels /* .map(projectChannel => { return { id: projectChannel.id, title: projectChannel.name } }) */}
            mappedIds={selectedIds}
            isLoading={loading}
            isSaving={isSubmitting}
            successMessage={hasUpdated && 'The ' + OBJECT_GROUP_NAME + ' ' + OBJECT_CHANNEL_NAME_PLURAL + ' have been updated' }{/* TODO: add details of how many where added/updated/removed from the chhanel? */...[]}
            errorMessage={error && error.message}
            noItemsMessage={'No ' + OBJECT_CHANNEL_NAME_PLURAL + ' available'}
            noItemsBtnTitle={'Manage ' + OBJECT_CHANNEL_NAME_PLURAL}
            noItemsBtnOnClick={() => {
              navContext.actions.goto(ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_CHANNELS, projectId))
            }}
            onCancel={() => { resetView() }}
            onSave={(mappedIds?: Array<number>, changes?: ArkDataMappingItemChanges) => {
              console.log('ProjectGroupChannelsPanel - ArkDataMappingForm - onSave - mappedIds: ', mappedIds, ' changes: ', changes)
              if (mappedIds && changes) {
                saveChanges(companyId, projectId, group, mappedIds, changes)
              }
            }}
            // filtering & custom item rendering support:
            filterForm={renderProjectChannelsFilterForm()}
            filterText={filter}
            filteredSourceItems={filteredProjectChannels}
            itemCompare={(newItem: Channel, oldItem: Channel) => {
              return newItem.id === oldItem.id && newItem.name === oldItem.name
            }}
            itemRow={(projectChannel: Channel, _isMapped: boolean) => {
              return <ArkHighlightedText highlight={filter} text={projectChannel.name}/>
            }}
            onClearFilter={() => filterProjectChannels('')}
          />
          <ArkSpacer />
        </>
      )}

    </ArkPanel>
  )
}

export default ProjectGroupChannelsPanel
