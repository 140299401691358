import React from 'react'
import { NavLink } from 'react-router-dom'

import { withNavContext, INavMultiContext } from 'src/core/providers/NavProvider'
import { IResponsiveMultiContext, withResponsiveContext } from 'src/core/providers/ResponsiveProvider'

import { StreamhubServer, StreamhubSocketStatus, withStreamhubServerContext, IStreamhubServerMultiContext } from 'src/admin/pages/streamhub/providers/StreamhubServerProvider'
import * as ROUTES from 'src/constants/routes'

import { StreamhubStreamsProvider } from 'src/admin/pages/streamhub/providers/StreamhubStreamsProvider'
import { StreamhubSourcesProvider } from 'src/admin/pages/streamhub/providers/StreamhubSourcesProvider'
import { StreamhubAssetsProvider } from 'src/admin/pages/streamhub/providers/StreamhubAssetsProvider'
import { StreamhubProcessesProvider } from 'src/admin/pages/streamhub/providers/StreamhubProcessesProvider'
import { StreamhubVideoEngineProvider } from 'src/admin/pages/streamhub/providers/StreamhubVideoEngineProvider'

import ArkButton from 'src/core/components/ArkButton'
import ArkDropdown, { ArkDropdownProps } from 'src/core/components/ArkDropdown'

import ArkAdminPage from 'src/admin/components/ArkAdminPage/ArkAdminPage'
import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'

import { Tab } from 'semantic-ui-react'
import { PAGE_STREAMHUB_NAME, SECTION_ADMIN_NAME } from 'src/constants/strings'

import styles from 'src/admin/pages/streamhub/pages/Streamhub.module.css'

interface IProps extends INavMultiContext, IStreamhubServerMultiContext, IResponsiveMultiContext {
  children?: React.ReactNode
}
interface IState {}

class StreamhubPage extends React.Component<IProps, IState> {
  render () {
    const servers = this.props.streamhubServerContext.actions.getServers()
    const currentServer = this.props.streamhubServerContext.store.currentServer
    return (
      <ArkAdminPage>
        <ArkManagerContentView
          title={PAGE_STREAMHUB_NAME}
          breadcrumb={[{
            path: ROUTES.ADMIN,
            title: SECTION_ADMIN_NAME
          }]}
        >
          {!currentServer && this.renderServerSelectPage(servers, currentServer)}
          {currentServer && this.renderCurrentServerPage(servers, currentServer)}
        </ArkManagerContentView>
      </ArkAdminPage>
    )
  }

  renderServerSelectPage = (servers: Array<StreamhubServer>, currentServer?: StreamhubServer) => {
    return (
      <>
        {this.renderServersDropdown(servers, currentServer)}
      </>
    )
  }

  renderCurrentServerPage = (servers: Array<StreamhubServer>, currentServer: StreamhubServer) => {
    const isMobile = this.props.responsiveContext.store.isMobile
    return (
      <div className={`${styles.streamhubPage}${isMobile ? ' ' + styles.mobile : ''}`}>
        <div className={styles.streamhubHeader}>
          {this.renderCurrentServerHeader(servers, currentServer)}
          {this.renderCurrentServerPageTabs()}
        </div>
        <div className={styles.streamhubContent}>
          {this.renderCurrentServerContent()}
        </div>
      </div>
    )
  }

  renderCurrentServerHeader = (servers: Array<StreamhubServer>, currentServer: StreamhubServer) => {
    const socketStatus = this.props.streamhubServerContext.store.socketStatus
    const isConnecting = socketStatus === StreamhubSocketStatus.connecting
    const isConnected = socketStatus === StreamhubSocketStatus.connected
    return (
      <div
        className={
          styles.currentServerHeader +
          (isConnected ? ' ' + styles.socketConnected : '') +
          (!isConnecting && !isConnected ? ' ' + styles.socketNoConnection : '')
        }
      >
        <div className={styles.border}>
          <div className={styles.leftMenu}>
            <div className={styles.currentServerName}>
              <span className={styles.currentServerTitle}>SERVER:</span><span className={styles.currentServerValue}>{currentServer.title ?? currentServer.url}</span>
              {servers.length > 1 && (
                <ArkButton size='mini' className={styles.currentServerClose} onClick={() => { this.onChangeServer() }}>X</ArkButton>
              )}
            </div>
            {this.renderCurrentServerSocketStatus()}
          </div>
          <div className={styles.rightMenu}>
            {this.renderRefreshAuthToken()}
          </div>
        </div>
      </div>
    )
  }

  renderCurrentServerSocketStatus = () => {
    const socketStatus = this.props.streamhubServerContext.store.socketStatus
    const isConnecting = socketStatus === StreamhubSocketStatus.connecting
    const isConnected = socketStatus === StreamhubSocketStatus.connected
    const isDisconnected = socketStatus === StreamhubSocketStatus.disconnected
    const hasError = socketStatus === StreamhubSocketStatus.error
    return (
      <div
        className={
          styles.currentServerSocket +
          (isConnecting ? ' ' + styles.socketConnecting : '') +
          (isConnected ? ' ' + styles.socketConnected : '') +
          (isDisconnected ? ' ' + styles.socketDisconnected : '') +
          (hasError ? ' ' + styles.socketError : '')
        }
      >
        <span className={styles.statusTitle}>SOCKET STATUS:</span><span className={styles.statusValue}>{socketStatus}</span>
        {(isConnected) && (
          <ArkButton color={'red'} basic size="tiny" className={styles.socketDisconnect} onClick={() => { this.onSocketConnectionToggle(false) }}>DISCONNECT</ArkButton>
        )}
        {(isDisconnected || hasError) && (
          <ArkButton color={'green'} basic size="tiny" className={styles.socketConnect} onClick={() => { this.onSocketConnectionToggle(true) }}>CONNECT</ArkButton>
        )}
      </div>
    )
  }

  renderRefreshAuthToken = () => {
    const socketStatus = this.props.streamhubServerContext.store.socketStatus
    const isConnected = socketStatus === StreamhubSocketStatus.connected
    const updatingServerAuthToken: boolean = this.props.streamhubServerContext.store.updatingServerAuthToken
    return (
      <div>
        <ArkButton color={isConnected ? 'green' : 'orange'} basic size="tiny" className={styles.socketRefresh} loading={updatingServerAuthToken} onClick={this.onSocketRefreshAuth}>REFRESH AUTH</ArkButton>
        {/* <div className={styles.socketBtn + ' ' + styles.socketRefresh} onClick={() => { this.onSocketRefreshAuthDbg() }}>REFRESH DBG</div> */}
      </div>
    )
  }

  renderCurrentServerContent = () => {
    const { children } = this.props
    const apiClient = this.props.streamhubServerContext.store.apiClient
    if (!apiClient) return null
    return (
      <>
        <StreamhubStreamsProvider apiClient={apiClient}>
          <StreamhubSourcesProvider apiClient={apiClient}>
            <StreamhubAssetsProvider apiClient={apiClient}>
              <StreamhubProcessesProvider apiClient={apiClient}>
                <StreamhubVideoEngineProvider apiClient={apiClient}>
                  {children}
                </StreamhubVideoEngineProvider>
              </StreamhubProcessesProvider>
            </StreamhubAssetsProvider>
          </StreamhubSourcesProvider>
        </StreamhubStreamsProvider>
      </>
    )
  }

  renderCurrentServerPageTabs = () => {
    const currentServer = this.props.streamhubServerContext.store.currentServer
    // NB: we only use the SUI Tab component for the tab bar UI, set it to trigger router route changes when clicked
    // NB: we don't render the content within the Tab like it expects, we instead show the route content below it (in an outer call)
    const panes = [
      { menuItem: { key: 'streams', id: 'streamsTab', content: 'Streams', to: ROUTES.ADMIN_STREAMHUB.replace(':serverKey', currentServer?.key ?? ''), as: NavLink, exact: true } },
      { menuItem: { key: 'sources', id: 'sourcesTab', content: 'Sources', to: ROUTES.ADMIN_STREAMHUB_SOURCES.replace(':serverKey', currentServer?.key ?? ''), as: NavLink, exact: true } },
      { menuItem: { key: 'processes', id: 'processesTab', content: 'Processes', to: ROUTES.ADMIN_STREAMHUB_PROCESSES.replace(':serverKey', currentServer?.key ?? ''), as: NavLink, exact: true } },
      { menuItem: { key: 'assets', id: 'assetsTab', content: 'Assets', to: ROUTES.ADMIN_STREAMHUB_ASSETS.replace(':serverKey', currentServer?.key ?? ''), as: NavLink, exact: true } },
      { menuItem: { key: 'videoEngines', id: 'videoEnginesTab', content: 'Video Engines', to: ROUTES.ADMIN_STREAMHUB_VIDEO_ENGINES.replace(':serverKey', currentServer?.key ?? ''), as: NavLink, exact: true } }
    ]
    return (
      <Tab
        className={styles.tabs}
        menu={{ inverted: false, attached: true, tabular: true }} // color: 'grey'
        panes={panes}
        activeIndex={-1}
        renderActiveOnly={false}
      />
    )
  }

  renderServersDropdown = (servers: Array<StreamhubServer>, currentServer?: StreamhubServer) => {
    const options: Array<{}> = []
    let index = 0
    for (const server of servers) {
      options.push(
        {
          key: 'server_' + index,
          text: server.title ?? server.url,
          value: '' + server.key // .url
        }
      )
      index++
    }
    return (
      <div className={styles.selectServer}>
        <ArkDropdown
          fluid selection
          // className="server-select"
          placeholder='Select Streamhub Server'
          options={options}
          value={currentServer?.key ?? undefined}
          onChange={(event: React.SyntheticEvent<HTMLElement, Event>, data: ArkDropdownProps) => {
            // this.context.actions.selectServerWithKey(data.value as string)
            const serverKey = data.value as string
            this.props.navContext.actions.goto(ROUTES.ADMIN_STREAMHUB.replace(':serverKey', serverKey))
          }}
        />
      </div>
    )
  }

  onChangeServer = () => {
    // this.context.actions.selectServer(undefined)
    this.props.navContext.actions.goto(ROUTES.ADMIN_STREAMHUB.replace('/:serverKey', ''))
  }

  onSocketConnectionToggle = (connect: boolean) => {
    if (connect) {
      this.props.streamhubServerContext.store.socketClient?.connect()
    } else {
      this.props.streamhubServerContext.store.socketClient?.disconnect()
    }
  }

  onSocketRefreshAuth = async () => {
    console.log('StreamhubPage - onSocketRefreshAuth')
    await this.props.streamhubServerContext.actions.updateServerAuthToken()
  }

  onSocketRefreshAuthDbg = async () => {
    console.log('StreamhubPage - onSocketRefreshAuthDbg')
    await this.props.streamhubServerContext.actions.updateServerAuthToken(true)
  }
}
export default withResponsiveContext(withStreamhubServerContext(withNavContext(StreamhubPage)))
