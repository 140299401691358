import { DEFAULT_NOTICE_CONDITIONS, NoticeConditions } from '../models'

export const decodeNoticeConditions = (value: NoticeConditions | string | null | undefined): NoticeConditions => {
  try {
    // console.log('core/utilities/notice - decodeNoticeConditions - value:', value)
    if (!value) return DEFAULT_NOTICE_CONDITIONS
    const conditions: NoticeConditions = typeof value === 'string' ? JSON.parse(value) : value
    // FIXME add type guard
    if (conditions.version !== DEFAULT_NOTICE_CONDITIONS.version) throw new Error('version mismatch')
    return conditions
  } catch (error) {
    console.error('core/utilities/notice - decodeNoticeConditions - error:', error)
    return DEFAULT_NOTICE_CONDITIONS
  }
}
