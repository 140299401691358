import React, { useState, useContext, useEffect } from 'react'
import * as yup from 'yup'

import { UserAccountContext } from '../UserAccountProvider'
import { AuthContext, UserContext } from '../../../providers'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from '../../../components/ArkForm/ArkForm'

import ArkDivider from 'src/core/components/ArkDivider'
import ArkBorder from 'src/core/components/ArkBorder'
import ArkButton from 'src/core/components/ArkButton'

import { Message } from 'semantic-ui-react'

import styles from './UserPhoneVerifyForm.module.css'

const formSchema = yup.object().shape({
  firstName: yup.string().min(0).max(255), // .required(), // TODO: set required once api endpoint uses this?
  lastName: yup.string().min(0).max(255) // .required(), // TODO: set required once api endpoint uses this?
})

interface IProps {
  onCancel?: Function
  onBack?: Function
  onVerified?: Function
  hasSentCode?: boolean
}

const UserPhoneVerifyForm = ({ hasSentCode, ...props }: IProps) => {
  const userAccountContext = useContext(UserAccountContext)
  const userContext = useContext(UserContext)
  const authContext = useContext(AuthContext)

  const user = userContext.store.user

  // sending a new code via sms
  const [isSending, setIsSending] = useState(false)
  const [hasSent, setHasSent] = useState(false)
  const [sendError, setSendError] = useState<Error>()

  // verifying the sms otp code
  const [isVerifying, setIsVerifying] = useState(false)
  const [hasVerified, setHasVerified] = useState(user?.phoneVerified ?? false) // catches if the users phone is already verified (will just show the success msg straight away)
  const [verifyError, setVerifyError] = useState<Error>()

  const phoneNumber = user?.phoneNumber

  const resetSendOTPForm = () => {
    if (isSending) setIsSending(false)
    if (hasSent) setHasSent(false)
    if (sendError) setSendError(undefined)
  }

  const resetVerifyOTPForm = () => {
    if (isVerifying) setIsVerifying(false)
    if (hasVerified) setHasVerified(false)
    if (verifyError) setVerifyError(undefined)
  }

  const onSendOTPCode = async () => {
    console.log('UserPhoneVerifyForm - onSendOTPCode')
    if (isSending) return
    try {
      resetSendOTPForm()
      resetVerifyOTPForm()
      setIsSending(true)
      const sendResult = await authContext.actions.sendPhoneSMSCode()
      console.log('UserPhoneVerifyForm - onSendOTPCode - sendResult: ', sendResult)
      setIsSending(false)
      setHasSent(true)
      // trigger the parent modal to resize to fit the new content
      userAccountContext.actions.contentChanged()
    } catch (error) {
      setIsSending(false)
      setSendError(error)
      // trigger the parent modal to resize to fit the new content
      userAccountContext.actions.contentChanged()
    }
  }

  // verify form submit
  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    const { phoneOTP } = fieldValues
    console.log('UserPhoneVerifyForm - onFormSubmit - phoneOTP: ', phoneOTP)
    if (isVerifying) return
    // resetSendOTPForm()
    resetVerifyOTPForm()
    setIsVerifying(true)
    try {
      const verifyResult = await authContext.actions.verifyPhoneSMSCode(phoneOTP)
      console.log('UserPhoneVerifyForm - onFormSubmit - verifyResult: ', verifyResult)
      setIsVerifying(false)
      if (verifyResult) {
        setHasVerified(true)
        // TESTING: trigger a user data reload so the new phone verification state is updated (gets shown when the user navigates back to the parent profile view/menu)
        await userContext.actions.reloadUserData()
        if (props.onVerified) props.onVerified()
      } else {
        throw new Error('Failed to verify')
      }
    } catch (error) {
      setIsVerifying(false)
      setVerifyError(error)
      resetSendOTPForm()
    }
  }

  // const onCancel = () => { if (props.onCancel) props.onCancel() }
  const onBack = () => { if (props.onBack) props.onBack() }

  // check if the parent indicates the sms code has been sent (as part of some pre-run api call other than the manual code send trigged here, e.g. when enabling 2fa)
  useEffect(() => {
    if (hasSentCode !== undefined && hasSentCode !== hasSent) {
      setHasSent(hasSentCode)
    }
  }, [hasSentCode])

  const formFields: Array<ArkFormField> = []
  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'otpGroup',
    fields: [
      { type: ArkFormFieldType.Input, key: 'phoneOTP', label: 'SMS Verify Code', required: true, defaultValue: '', fieldProps: { autoComplete: 'off' } },
      { type: ArkFormFieldType.OKButton, key: 'otpSubmit', label: 'SUBMIT SMS CODE', fieldProps: { loading: isVerifying, floated: 'right', className: styles.fieldVerifyCodeBtn } }
    ],
    fieldProps: { widths: 'equal' }
  })
  return (
    <>
      <ArkBorder light padded title={'Verify Your Phone Number'}>

        <div className={styles.phoneNo}>
          <span className={styles.phoneNoTitle}>Phone No:</span> {phoneNumber}
        </div>

        {!hasVerified && (<>
          <ArkDivider horizontal section className={styles.dividerTitle}>Step 1. Request an SMS code</ArkDivider>
          {sendError && (
            <Message negative>
              <Message.Header>SMS Code Error</Message.Header>
              <Message.Content>
                Failed to send an SMS verify code to your phone.<br />
                Please try again or contact us if the problem persists.<br />
                Error: {sendError.message}
              </Message.Content>
            </Message>
          )}
          {!hasSent && (
            <div className={styles.requestCode}>
              <div className={styles.requestCodeTitle}>Request a new SMS Verify Code: </div>
              <div className={styles.requestCodeBtn}>
                <ArkButton type="button" color="blue" fluid basic size="large" className={styles.requestCodeBtn} onClick={onSendOTPCode} loading={isSending}>SEND NEW SMS CODE</ArkButton>
              </div>
            </div>
          )}
          {hasSent && (
            <div className={styles.requestCodeSent}>
              <Message positive>
                <Message.Header>SMS Code Sent</Message.Header>
                <Message.Content>
                  An SMS verify code has been sent to your phone.<br />
                  Enter it below to complete the verification.
                </Message.Content>
              </Message>
            </div>
          )}

          <ArkDivider horizontal section className={styles.dividerTitle}>Step 2. Submit the SMS code</ArkDivider>
          <ArkForm
            formKey="verifyPhoneNo"
            inverted
            formError={verifyError}
            formFields={formFields}
            formSchema={formSchema}
            onFormSubmit={onFormSubmit}
            showLabels={true}
            insideModal={true}
          />
        </>)}

        {hasVerified && (
          <div className={styles.verifiedMsg}>
            <Message positive>
              <Message.Header>Phone Number Verified</Message.Header>
              <Message.Content>
                Your phone number has been successfully verified.
                <ArkButton type="button" color="blue" fluid basic size="large" className={styles.verifiedBackBtn} onClick={onBack} loading={isSending}>BACK</ArkButton>
              </Message.Content>
            </Message>
          </div>
        )}

      </ArkBorder>
    </>
  )
}

export default UserPhoneVerifyForm
