/**
 * ArkLog
 * A `console.log()` wrapper to filter logs.
 *
 * To prevent incorrect files and line numbers being displayed in the console (e.g. `ArkLog.ts:42`)
 * you must add `ArkLog.ts` to Chrome's "Framework Ignore List". This is also known as "blackboxing".
 *
 * In Chrome Developer Tools:
 * - "Settings" (F1) > "Ignore List" > "Add pattern…"
 * - Enter `ArkLog.ts` (without quotes) > "Add"
 */

import { ARK_LOG_ENABLED, ARK_LOG_FILTER, ARK_LOG_SILENCED } from '../../constants/config'
import { formatLoggingDateTime } from './date'

enum LogType {
  Error,
  Info,
  Log,
  Warn,
}

export const loadArkLog = () => {
  if (!ARK_LOG_ENABLED) {
    return
  }

  if (ARK_LOG_SILENCED) {
    console.info = () => {}
    console.log = () => {}
    console.warn = () => {}
    return
  }

  // Store stock functions
  const consoleError = console.error
  const consoleInfo = console.info
  const consoleLog = console.log
  const consoleWarn = console.warn

  consoleLog('ArkLog - loaded')
  if (ARK_LOG_FILTER) {
    consoleLog('ArkLog - filter:', ARK_LOG_FILTER)
  }

  const log = (type: LogType, message?: any, ...optionalParams: any[]) => {
    // Filter with a regular expression
    if (ARK_LOG_FILTER && typeof message === 'string') {
      if (!message.match(ARK_LOG_FILTER)) {
        return
      }
    }

    // Call stock functions
    switch (type) {
      case LogType.Error:
        consoleError(`[${formatLoggingDateTime(new Date())}] ${message}`, ...optionalParams)
        break
      case LogType.Info:
        consoleInfo(`[${formatLoggingDateTime(new Date())}] ${message}`, ...optionalParams)
        break
      case LogType.Log:
        consoleLog(`[${formatLoggingDateTime(new Date())}] ${message}`, ...optionalParams)
        break
      case LogType.Warn:
        consoleWarn(`[${formatLoggingDateTime(new Date())}] ${message}`, ...optionalParams)
        break
    }
  }

  // Override stock functions
  console.error = (...params: any) => log(LogType.Error, ...params)
  console.info = (...params: any) => log(LogType.Info, ...params)
  console.log = (...params: any) => log(LogType.Log, ...params)
  console.warn = (...params: any) => log(LogType.Warn, ...params)
}
