import { FFMpegOutputOptions } from './FFMpegOptions'
import { FFMpegMainAVInfo, FFMpegSourceAudioType, FFMpegSourceVideoType } from './StreamhubModels'

export interface IInputSource {
  sourceId: number // int id alternative to the mongodb string version
  name?: string
  videoInputs?: Array<IInputVideo> // Types.Array
  audioInputs?: Array<IInputAudio>
  overlays?: Array<IInputTextOverlay>
  overlayTitle?: string
  overlayShowTimecode: boolean
  outputOptions?: FFMpegOutputOptions
  avInfo?: FFMpegMainAVInfo
  createdAt: Date
  updatedAt?: Date
}

export interface IInputVideo {
  type: FFMpegSourceVideoType
  duration?: number
  fps?: number
  resolution?: string
  inputArgsStr?: string
  filename?: string
}

export interface IInputAudio {
  type: FFMpegSourceAudioType
  duration?: number
  inputArgsStr?: string
  filename?: string
}

export interface IInputTextOverlay {
  cmd: string // TODO: don't save the ffmpeg overlay command/arg unless its specially set, instead just save the text value & generate the cmd dynamically when needed?
  isTimecode: boolean
}

class InputSource implements IInputSource {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    public sourceId: number,
    public name?: string,
    public videoInputs?: Array<IInputVideo>,
    public audioInputs?: Array<IInputAudio>,
    public overlays?: Array<IInputTextOverlay>,
    public overlayTitle?: string,
    public overlayShowTimecode: boolean = false,
    public outputOptions?: FFMpegOutputOptions,
    public avInfo?: FFMpegMainAVInfo,
    public createdAt: Date = new Date(),
    public updatedAt?: Date
  ) {}

  get id (): number { return this.sourceId } // alias `sourceId` for backwards compatibility with the old FFMpegSource model

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : InputSource | null {
    if (!json) return null
    return new InputSource(
      json.id ?? json.sourceId,
      json.name,
      json.videoInputs,
      json.audioInputs,
      json.overlays,
      json.overlayTitle,
      json.overlayShowTimecode,
      json.outputOptions,
      json.avInfo,
      json.createdAt,
      json.updatedAt
    )
  }
}

export default InputSource
