/**
 * SectionProvider
 * - TODO: rename to SectionSwitcherProvider? (the NavProvider handles base section details, this is more focused on the logic for the section switcher focsed components)
 */

// TODO: original access checks only checked the current/select company related access,
// TODO: ..but for the section switcher(s) we likely want it to show sections if the user has any access to it,
// TODO: ..even if not for the current company but they do for another one,
// TODO: ..maybe its disabled & dimmed if its not usable in the current company, but shows to avoid confusion?
// TODO: ..(or always allow it to be clicked so they can get to the section & then switch company if they need too?)

import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import { NavContext, NavSection, UserContext, UserStatus } from '.'

import * as ROUTES from 'src/constants/routes'

interface ISectionSwitcherActions {
  gotoSection: (section?: NavSection) => void
}

interface ISectionSwitcherStore {
  showSiteAdminLink: boolean
  showCompanyAdminLink: boolean
  showProjectManagerLink: boolean
  showProjectViewerLink: boolean
}

interface ISectionSwitcherContext {
  actions: ISectionSwitcherActions
  store: ISectionSwitcherStore
}

interface SectionProviderProps {
  children: ReactNode
}

export const SectionSwitcherContext = createContext<ISectionSwitcherContext>({} as ISectionSwitcherContext)

const SectionSwitcherProvider = (props: SectionProviderProps) => {
  const { children } = props

  const { actions: userActions, store: userStore } = useContext(UserContext)
  const { actions: navActions } = useContext(NavContext)

  const { user, userStatus, selectedCompany, selectedProject, selectedChannel } = userStore

  const [showSiteAdminLink, setShowAdmin] = useState<boolean>(false)
  const [showCompanyAdminLink, setShowCompany] = useState<boolean>(false)
  const [showProjectManagerLink, setShowProject] = useState<boolean>(false)
  const [showProjectViewerLink, setShowViewer] = useState<boolean>(false)

  useEffect(() => {
    // don't show any section links while not logged in or not verified
    if (userStatus !== UserStatus.loggedIn || !user || !user.emailVerified) {
      setShowAdmin(false)
      setShowCompany(false)
      setShowProject(false)
      setShowViewer(false)
      return
    }
    const isSiteAdmin = userActions.isSiteAdmin()
    const isCompanyAdminOrHigherInSelectedCompany = userActions.isCompanyAdminOrHigher()
    const isProjectManagerOrHigherInSelectedCompany = userActions.isProjectManagerOrHigher()
    // NB: we always show the viewer section button, even if the user has no current projects they can actually view,
    // NB: ..so they can at least nav to the main site & so see relevant status/warning messages etc.
    console.log('SectionProvider - isSiteAdmin: ', isSiteAdmin, ' isCompanyAdminOrHigherInSelectedCompany: ', isCompanyAdminOrHigherInSelectedCompany, ' isProjectManagerOrHigherInSelectedCompany: ', isProjectManagerOrHigherInSelectedCompany)

    // TESTING: newer access checks across all companies the user has access to in some way
    console.log('SectionProvider - user?.projectAdminLookup: ', user?.projectAdminLookup)
    // loop through all companies & check if the user is a company admin in any of them
    const isCompanyAdminOrHigherInAnyCompany = userActions.isCompanyAdminOrHigherInAnyCompany()
    const isProjectManagerOrHigherInAnyCompany = userActions.isProjectManagerOrHigherInAnyCompany()
    console.log('SectionProvider - isCompanyAdminOrHigherInAnyCompany: ', isCompanyAdminOrHigherInAnyCompany, ' isProjectManagerOrHigherInAnyCompany: ', isProjectManagerOrHigherInAnyCompany)

    const _showAdmin = isSiteAdmin
    const _showCompany = isSiteAdmin || isCompanyAdminOrHigherInAnyCompany
    const _showProject = isSiteAdmin || isProjectManagerOrHigherInAnyCompany
    console.log('SectionProvider - showAdmin:', _showAdmin + ' (was: ' + showSiteAdminLink + ')', ' showCompany:', _showCompany + ' (was: ' + showCompanyAdminLink + ')', ' showProject:', _showProject + ' (was: ' + showProjectManagerLink + ')')

    if (showSiteAdminLink !== _showAdmin) setShowAdmin(_showAdmin)
    if (showCompanyAdminLink !== _showCompany) setShowCompany(_showCompany)
    if (showProjectManagerLink !== _showProject) setShowProject(_showProject)
    setShowViewer(true) // NB: we currently always show the viewer section option when logged in
  }, [userStatus, selectedCompany, selectedProject])

  const _gotoProjectViewer = async () => {
    const project = selectedProject
    const channel = selectedChannel
    if (project && channel) {
      navActions.goto(ROUTES.getProjectChannelRoute(ROUTES.VIEW_PROJECT_CHANNEL, project.id, channel.id))
    } else if (project) {
      navActions.goto(ROUTES.getProjectRoute(ROUTES.VIEW_PROJECT, project.id))
    } else {
      navActions.goto(ROUTES.VIEWER)
    }
  }

  const _gotoProjectManager = async () => {
    const project = selectedProject
    if (project) {
      navActions.goto(ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_VIEW, project.id))
    } else {
      navActions.goto(ROUTES.PROJECT_MANAGER_SELECT)
    }
  }

  const _gotoCompanyManager = () => {
    navActions.goto(ROUTES.COMPANY_MANAGER)
  }

  const _gotoAdmin = () => {
    navActions.goto(ROUTES.ADMIN)
  }

  const gotoSection = (section?: NavSection) => {
    console.log('SectionProvider - section:', section)
    switch (section) {
      case NavSection.viewer: _gotoProjectViewer(); break
      case NavSection.project: _gotoProjectManager(); break
      case NavSection.company: _gotoCompanyManager(); break
      case NavSection.admin: _gotoAdmin(); break
    }
  }

  const actions: ISectionSwitcherActions = {
    gotoSection
  }

  const store: ISectionSwitcherStore = {
    showSiteAdminLink,
    showCompanyAdminLink,
    showProjectManagerLink,
    showProjectViewerLink
  }

  return (
    <SectionSwitcherContext.Provider value={{ actions, store }}>
      {children}
    </SectionSwitcherContext.Provider>
  )
}

export { SectionSwitcherProvider }
