import ServerAPIClient from './ServerAPIClient'
import { ServerConfig } from '../models/server_config'

class ServerConfigAPI {
  private _apiClient: ServerAPIClient

  constructor (apiClient: ServerAPIClient) {
    this._apiClient = apiClient
  }

  getServerConfig = async (): Promise<ServerConfig> => {
    try {
      const response = await this._apiClient.apiGet('/config')
      if (response.data && response.data.result && response.data.result) {
        const configData = response.data.result
        const serverConfig = ServerConfig.fromJSON(configData)
        if (serverConfig) return serverConfig
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerConfigAPI - getServerConfig - error: ', error)
      throw error
    }
  }
}

export default ServerConfigAPI
