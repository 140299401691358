import React from 'react'

import { StreamhubProcessesContext } from '../../providers/StreamhubProcessesProvider'

import StreamhubPage from '../../components/StreamhubPage/StreamhubPage'
import StreamhubProcessesView from './StreamhubProcessesView'

class StreamhubProcessesPage extends React.Component<{}, {}> {
  render () {
    return (
      <StreamhubPage>
        <StreamhubProcessesContext.Consumer>
          {(_processesContext) => {
            return (<StreamhubProcessesView />)
          }}
        </StreamhubProcessesContext.Consumer>
      </StreamhubPage>
    )
  }
}
export default StreamhubProcessesPage
