/**
 * ArkPanel
 */

import React from 'react'

import styles from './ArkPanel.module.css'

import { Item } from 'semantic-ui-react'

export interface ArkPanelFooterProps {
  children?: React.ReactNode
  className?: string
  topBorder?: boolean
}

const ArkPanelFooter = (props: ArkPanelFooterProps) => {
  const { children, className, topBorder } = props
  return (
    <Item.Group className={styles.footerPanel + (topBorder ? ' ' + styles.bordered : '') + (className ? ' ' + className : '')}>
      <Item>
        <Item.Content>
          {children}
        </Item.Content>
      </Item>
    </Item.Group>
  )
}

export default ArkPanelFooter
