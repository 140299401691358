import React, { useEffect, useRef } from 'react'
import { IVideoEngineServer } from '../../models/StreamhubVideoEngineModels'
import ArkDropdown, { ArkDropdownProps } from 'src/core/components/ArkDropdown'

import styles from './StreamhubVideoEngines.module.css'

interface IProps {
  videoEngineServers: Array<IVideoEngineServer>
  selectedVideoEngineServer?: IVideoEngineServer
  onServerSelect: (serverId: string) => void
}

const StreamhubVideoEngineServerSelectView = (props: IProps) => {
  const mounted = useRef(false)

  const { videoEngineServers, selectedVideoEngineServer, onServerSelect } = props

  // -------

  useEffect(() => {
    console.log('StreamhubVideoEngineServerSelectView - MOUNT')
    mounted.current = true
    return () => {
      console.log('StreamhubVideoEngineServerSelectView - UNMOUNT')
      mounted.current = false
    }
  }, [])

  // -------

  const renderServersDropdown = (servers: Array<IVideoEngineServer>, currentServer?: IVideoEngineServer) => {
    const options: Array<{}> = []
    let index = 0
    for (const server of servers) {
      options.push(
        {
          key: 'server_' + index,
          text: server.name,
          value: '' + server.id
        }
      )
      index++
    }
    return (
      <div className={styles.selectServer}>
        <ArkDropdown
          fluid selection
          // className="server-select"
          placeholder='Select Video Engine Server'
          options={options}
          value={currentServer?.id ?? undefined}
          onChange={(event: React.SyntheticEvent<HTMLElement, Event>, data: ArkDropdownProps) => {
            const serverId = data.value as string
            console.log('StreamhubVideoEngineServerSelectView - renderServersDropdown - serverId:', serverId)
            onServerSelect(serverId)
          }}
        />
      </div>
    )
  }

  // -------

  return (
    <>
      {renderServersDropdown(videoEngineServers, selectedVideoEngineServer)}
    </>
  )
}

export default StreamhubVideoEngineServerSelectView
