import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import ArkPage from '../../components/ArkPage/ArkPage'
import { UserContext, UserStatus } from '../../providers'
import ArkSectionSwitcherLargeView from 'src/core/components/ArkSectionSwitcherLargeView/ArkSectionSwitcherLargeView'

import * as ROUTES from '../../../constants/routes'
import ArkInactiveOrgView from 'src/core/components/ArkInactiveOrgView'

const HomePage = () => {
  const history = useHistory()

  const userContext = useContext(UserContext)

  // TODO: WARNING: move the redirect handling to the main router instead of here!?! (still seems to be required/used & we really shouldn't be relying on it here)
  // TODO: should we have separate logged-in vs logged-out HomePage's?
  // check logged in
  const isLoggedIn = userContext.store.userStatus === UserStatus.loggedIn
  useEffect(() => {
    if (!isLoggedIn) {
      console.log('HomePage - mount - NOT LOGGED IN - REDIRECTING TO LOGIN PAGE...')
      history.push(ROUTES.LOGIN)
    }
  }, [])
  if (!isLoggedIn) return null // NB: don't render below if we're not logged in!
  const selectedCompany = userContext.store.selectedCompany
  const isActive = selectedCompany?.isActive ?? false
  console.log('HomePage - mount - LOGGED IN - isActive:', isActive, ' selectedCompany:', selectedCompany)
  return (
    <ArkPage
      showSelectCompany
      showSelectProject
    >
      {/* NB: show the switcher if no company is selected - so it then shows the 'select company' popup once you navigate to a section */}
      {(isActive || selectedCompany === undefined) && (<ArkSectionSwitcherLargeView />)}
      {(!isActive && selectedCompany !== undefined) && (<ArkInactiveOrgView />)}
    </ArkPage>
  )
}

export default HomePage
