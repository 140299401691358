import React, { useState, useEffect } from 'react'

import { useDebounceEffect } from '../../hooks/debounce'
import ArkIcon from '../ArkIconButton'

import styles from './ArkModal.module.css'

import { Modal, TransitionablePortal } from 'semantic-ui-react'

// useful refs:
//  https://github.com/Semantic-Org/Semantic-UI-React/issues/2923
//  https://carlanderson.xyz/how-to-animate-on-height-auto/ # using method 3 for some of the resizing calcs/updates

interface IProps {
  open: boolean
  onClose?: Function
  header?: string | React.ReactNode
  children?: React.ReactNode
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen'
  scrolling?: boolean
  disablePadding?: boolean // removes the default padding SUI modal adds around the content
  className?: string
  animate?: boolean
  closeOnEscape?: boolean // defaults to true
}

const ArkModal = ({ open, children, ...props }: IProps) => {
  const [isAnimating, setIsAnimating] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(open)
  const [height, setHeight] = useState<number | undefined>()

  const onContentChange = (resetScroll: boolean = false) => {
    if (props.animate !== true) return
    const modalElement = document.querySelector<HTMLElement>('.ui.modals .ui.modal')
    const contentElement = document.querySelector<HTMLElement>('.ui.modals .ui.modal .content')
    const headerElement = document.querySelector<HTMLElement>('.ui.modals .ui.modal .header')
    if (modalElement && contentElement) {
      // clear any previously set height before we read the calculated values
      contentElement.style.height = ''

      // read the current modal content heights & calc the total inc. the header
      const contentHeight = contentElement.offsetHeight // window.getComputedStyle(contentElement).height
      const headerHeight = headerElement?.offsetHeight ?? 0 // headerElement ? window.getComputedStyle(headerElement).height : undefined
      const totalHeight = contentHeight + headerHeight

      // wait for a frame/tick before updating values
      requestAnimationFrame(() => {
        // set the height of the modal using the calculated values, so the css transition animations kick in (they don't when its auto calc'd within css)
        // NB: the .modal has `overflow: hidden` set so excess content doesn't show during the height resize transition
        modalElement.style.height = totalHeight + 'px'

        // scroll to the top
        if (resetScroll) {
          requestAnimationFrame(() => {
            contentElement.scrollTop = 0
            // UPDATE: only seem to need to set the scrollTop on the main contentElement now, not the inner content itself?
            // UPDATE: commented out but left in for future ref, just incase we find a reason its also needed (works on the 2fa enable 2nd page without)
            // const innerContentElement = document.querySelector<HTMLElement>('.ui.modals .ui.modal .content div') // TODO: better css filter! <<
            // if (innerContentElement) innerContentElement.scrollTop = 0
          })
        }
      })

      setIsAnimating(true)
      setHeight(totalHeight)
    }
  }

  const updateHeightIfNeeded = (resetScroll: boolean = false) => {
    const contentElement = document.querySelector<HTMLElement>('.ui.modal .content')
    const headerElement = document.querySelector<HTMLElement>('.ui.modal .header')
    const contentHeight = contentElement?.offsetHeight ?? 0 // window.getComputedStyle(contentElement).height
    const headerHeight = headerElement?.offsetHeight ?? 0
    const totalHeight = contentHeight + headerHeight
    if (totalHeight !== height) {
      onContentChange(resetScroll)
    }
  }

  // const onContentSubtreeChange = () => {
  //   updateHeightIfNeeded(true)
  // }

  const onWindowResize = () => {
    if (props.animate !== true) return
    updateHeightIfNeeded()
  }

  const onOpen = () => {
    setTimeout(() => document.body.classList.add('modal-fade-in'), 0)
    // if (props.animate) {
    //   // listen out for changes to the modal content div element
    //   const contentElement = document.querySelector<HTMLElement>('.ui.modal .content')
    //   contentElement?.addEventListener('DOMSubtreeModified', onContentSubtreeChange, false)
    // }
  }

  const onClose = () => {
    document.body.classList.remove('modal-fade-in')
    setIsOpen(false)
    // setTimeout(() => {
    //   // if (this.props.onClose) this.props.onClose()
    // }, 1500)
    // if (props.animate) {
    //   // clean-up the modal content listener
    //   const contentElement = document.querySelector<HTMLElement>('.ui.modal .content')
    //   contentElement?.removeEventListener('DOMSubtreeModified', onContentSubtreeChange)
    // }
  }

  const onCloseComplete = () => {
    if (props.onClose) props.onClose()
  }

  // useEffect refs:
  //  https://www.codegrepper.com/code-examples/javascript/componentdidupdate+in+functional+component

  // TODO: is this the same as `componentDidUpdate` `if (prevProps.open !== this.props.open) { this.setState({ open: this.props.open }) }`?
  useEffect(() => {
    if (open !== isOpen) setIsOpen(open)
  }, [open])

  // TODO: is this the same as `componentDidUpdate` `if (prevProps.children !== this.props.children) { this.onContentChange(true) }`?
  useEffect(() => {
    onContentChange(true)
  }, [children])

  // listen out for window resize events & trigger our own resize handler to process them
  useEffect(() => {
    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  })

  // TESTING: catch the 'enter' key being pressed, stop it from causing a refresh/re-open transition of the modal
  // ref: https://stackoverflow.com/a/59147255
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      // console.log('ArkModal - useEffect - keydown - listener - event: ', event)
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        // console.log('ArkModal - useEffect - keydown - ENTER KEY PRESSED')
        event.preventDefault()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  })

  useDebounceEffect(() => {
    setIsAnimating(false)
  }, [height], 500)

  const closeIcon = (
    <ArkIcon
      className={styles.closeButton}
      color='var(--bd-lighter)'
      hoverColor='var(--tx-light)'
      name='close'
      size={24}
    />
  )

  // NB: transition animations ref: https://semantic-ui-react-6oxtwdsfb-semantic-ui.vercel.app/modules/transition
  // NB: - click the 'Transition' button at the top left of the page (next to the 'Props' one) to see all the 'animation' options
  // e.g: 'fade' / 'fade up' /
  return (
    <>
      <TransitionablePortal
        open={isOpen}
        onOpen={() => onOpen()}
        onClose={() => onCloseComplete()}
        transition={{ animation: 'fade up', duration: 500 }}
        closeOnDocumentClick={false} // Prevent nested popups triggering close
        closeOnEscape={props.closeOnEscape !== undefined ? props.closeOnEscape : true}
      >
        <Modal
          className={`${styles.modal} ${isAnimating ? styles.modalAnimating : ''} ${props.className || ''}`}
          onClose={() => { onClose() }}
          open={true}
          closeOnEscape={false} // NB: `closeOnEscape` is handled at the parent `TransitionablePortal` level, keep it disabled here so it doesn't clash/override that handling
          closeOnDimmerClick={false}
          closeIcon={closeIcon}
          size={props.size}
          dimmer="blurring"
        >
          {props.header && (<Modal.Header as='h2' className={styles.header}>{props.header}</Modal.Header>)}
          <Modal.Content
            className={`${styles.content} ${props.disablePadding ? styles.noPadding : ''}`}
            scrolling={props.scrolling}
          >
            {children}
          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    </>
  )
}
export default ArkModal
