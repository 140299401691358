import React, { useContext } from 'react'

import { CompanyAdminContext } from 'src/core/providers'

import { Company, Project } from 'src/core/models'

import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { OBJECT_PROJECT_NAME } from 'src/constants/strings'

export type ProjectDidDeleteCallback = (project: Project) => void

interface IProps {
  company: Company
  project: Project
  onDidDelete?: ProjectDidDeleteCallback
  className?: string
}

const ProjectDeleteView = (props: IProps) => {
  const { company, project, onDidDelete: _onDidDelete, className } = props

  const companyAdminContext = useContext(CompanyAdminContext)

  const onDelete = async (_itemId: number): Promise<boolean> => {
    // NB: DON'T catch the error/exception here, let it throw (or if you do, rethrow it so the calling code can catch & display it)
    const deleteResult = await companyAdminContext.actions.deleteCompanyProject(company.id, project.id)
    console.log('ProjectDeleteView - onDelete - deleteCompanyProject - deleteResult: ', deleteResult)
    return deleteResult
  }

  if (!project) return null
  const companyName = company.name ?? 'this company'

  return (
    <div className={(className ? ' ' + className : '')}>
      <ArkSpacer />
      <ArkManagerDeleteButton
        itemId={project.id}
        itemName={project.name}
        itemTypeName={OBJECT_PROJECT_NAME}
        buttonTitle={'DELETE ' + OBJECT_PROJECT_NAME}
        confirmMsgPost={<>from <strong>{companyName}</strong></>}
        onDelete={onDelete}
        onDeleteComplete={() => { if (_onDidDelete) _onDidDelete(project) }}
        confirmTextInput={project.name} // require the user to type the project name before being allowed to delete it
      />
    </div>
  )
}

export default ProjectDeleteView
