import { BaseModel } from './base_model'

// NB: only currently used when viewing all auth sessions for the logged in or given user (not used for auth login checks etc.)
export class AuthSession extends BaseModel {
  id: number
  userId: number
  deviceUUID: string
  deviceName?: string
  deviceOS?: string
  ipAddress?: string
  isActive?: boolean
  lastActivity?: Date
  lastCompanyId?: number
  lastProjectId?: number

  constructor (
    id: number,
    userId: number,
    deviceUUID: string,
    deviceName?: string,
    deviceOS?: string,
    ipAddress?: string,
    isActive?: boolean,
    lastActivity?: Date,
    lastCompanyId?: number,
    lastProjectId?: number
  ) {
    super()
    this.id = id
    this.userId = userId
    this.deviceUUID = deviceUUID
    this.deviceName = deviceName
    this.deviceOS = deviceOS
    this.ipAddress = ipAddress
    this.isActive = isActive
    this.lastActivity = lastActivity
    this.lastCompanyId = lastCompanyId
    this.lastProjectId = lastProjectId
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : AuthSession | null {
    if (!json) {
      return null
    }
    return new AuthSession(
      json.id,
      json.user_id,
      json.device_uuid,
      json.device_name,
      json.os,
      json.ip_address,
      json.is_active,
      json.date_last_activity ? new Date(json.date_last_activity) : undefined,
      json.last_company_id,
      json.last_project_id
    )
  }
}
