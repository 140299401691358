import React, { useState } from 'react'
import { ColorResult, HuePicker, TwitterPicker } from 'react-color'
import { DEFAULT_PICKER_COLOR } from 'src/constants/config'

import styles from './ArkFormColourField.module.css'

export interface ArkFormColourFieldResult {
  colour: string
}
export type ArkFormColourFieldResultCallback = (result: ArkFormColourFieldResult) => void

interface IProps {
  label?: string | React.ReactNode
  required?: boolean
  defaultValue?: string
  onChangeComplete?: ArkFormColourFieldResultCallback
  className?: string
}

const ArkFormColourField = (props: IProps) => {
  const { label, required, defaultValue, onChangeComplete, className } = props

  const defaultColor = DEFAULT_PICKER_COLOR

  const [colour, setColour] = useState<string>(defaultValue ?? defaultColor) // default colour - must match last picker color
  const [showPicker, setShowPicker] = useState<boolean>(false)

  // -------

  const togglePicker = () => {
    setShowPicker(!showPicker)
  }

  // const showPicker = () => {
  //   setShowPicker(true)
  // }

  // const hidePicker = () => {
  //   setShowPicker(false)
  // }

  // -------

  const onColourChange = (color: ColorResult, _event: React.ChangeEvent<HTMLInputElement>) => {
    setColour(color.hex.toUpperCase())
  }

  const onColourChangeComplete = (color: ColorResult, _event: React.ChangeEvent<HTMLInputElement>) => {
    setColour(color.hex.toUpperCase())
    if (onChangeComplete) onChangeComplete({ colour: color.hex.toUpperCase() })
  }

  // -------

  const renderPicker = () => {
    return (
      <>
        <div className={styles.pickerOverlay} onClick={togglePicker}></div>
        <div className={styles.picker}>
          <div className={styles.picker1}>
            <TwitterPicker
              color={colour}
              onChange={onColourChange}
              onChangeComplete={onColourChangeComplete}
              triangle='hide'
              width='350'
              colors={['#FF2600', '#0693E3', '#FCB900', '#00D084', '#7BDCB5', '#8ED1FC', '#0693E3', '#EB144C', '#F78DA7', '#9900EF', defaultColor]}
            />
          </div>
          <div className={styles.picker2}>
            <HuePicker
              color={colour}
              onChange={onColourChange}
              onChangeComplete={onColourChangeComplete}
              width='350'
            />
          </div>
          <div className={styles.arrow}></div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={'field ' + styles.field + (required ? ' required' : '') + (className ? ' ' + className : '')}>
        {label && (<label>{label}</label>)}
        <div className={styles.btn /* 'colour-picker-btn' */} style={{ backgroundColor: colour }} onClick={togglePicker}>
        </div>
        {showPicker && (renderPicker())}
      </div>
    </>
  )
}

export default ArkFormColourField
