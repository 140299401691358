import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { withUserContext, IUserMultiContext, withAPIContext, IAPIMultiContext, SiteAdminProvider } from '../core/providers'

import * as ROUTES from '../constants/routes'

import AdminAccessDeniedPage from './pages/access_denied/AdminAccessDeniedPage'
import AdminActivityPage from './pages/activity/AdminActivityPage'
import AdminCompaniesPage from './pages/companies/AdminCompaniesPage'
import AdminHomePage from './pages/home/AdminHomePage'
import AdminSettingsPage from './pages/settings/AdminSettingsPage'
import AdminVideoEnginesPage from './pages/video_engines/AdminVideoEnginesPage'
import StreamhubRouter from './pages/streamhub/StreamhubRouter'

interface IProps extends IUserMultiContext, IAPIMultiContext {}
interface IState {}

class AdminRouter extends React.Component<IProps, IState> {
  render () {
    // check the user has access to this area of the site
    const user = this.props.userContext.store.user
    if (!user?.isSiteAdmin()) {
      return (<AdminAccessDeniedPage />)
    }

    return (
      <SiteAdminProvider apiClient={this.props.apiContext.actions.getAPIClient()}>
        <Switch>
          <Route exact path={ROUTES.ADMIN} component={AdminHomePage} />
          <Route path={ROUTES.ADMIN_COMPANIES} component={AdminCompaniesPage} />
          <Route path={ROUTES.ADMIN_ACTIVITY} component={AdminActivityPage} />
          <Route path={ROUTES.ADMIN_SETTINGS} component={AdminSettingsPage} />
          <Route path={ROUTES.ADMIN_STREAMHUB} component={StreamhubRouter} />
          <Route path={ROUTES.ADMIN_VIDEO_ENGINES} component={AdminVideoEnginesPage} />
        </Switch>
      </SiteAdminProvider>
    )
  }
}

export default withAPIContext(withUserContext(AdminRouter))
