import React from 'react'

import { withSiteAdminContext, ISiteAdminMultiContext } from 'src/core/providers'

import { VideoEngine } from 'src/core/models'

import VideoEngineDeleteButton from './VideoEngineDeleteButton'

import ArkSpacer from 'src/core/components/ArkSpacer'

export type VideoEngineDidDeleteCallback = (videoEngine: VideoEngine) => void

interface IProps extends ISiteAdminMultiContext {
  videoEngine: VideoEngine
  onDidDelete?: VideoEngineDidDeleteCallback
}
interface IState {}

class VideoEngineDeleteView extends React.Component<IProps & { className?: string }, IState> {
  render () {
    const { videoEngine, className } = this.props
    if (!videoEngine) return null
    return (
      <div className={(className ? ' ' + className : '')}>
        <ArkSpacer />
        <VideoEngineDeleteButton
          videoEngine={videoEngine}
          onDidDelete={() => { if (this.props.onDidDelete) this.props.onDidDelete(videoEngine) }}
        />
      </div>
    )
  }
}

export default withSiteAdminContext(VideoEngineDeleteView)
