/**
 * ArkSlider
 */

import React, { InputHTMLAttributes } from 'react'

import styles from './ArkSlider.module.css'

interface ArkSliderProps extends InputHTMLAttributes<HTMLInputElement> {
  max?: number
  min?: number
  muted?: boolean
  step?: number
  value: number
}

const ArkSlider = (props: ArkSliderProps) => {
  const { className, disabled, max = 100, min = 0, muted, value } = props

  const percent: number = ((value - min) * 100) / (max - min)

  return (
    <div
      className={`${
        styles.container
      } ${
        disabled ? styles.disabled : ''
      }  ${
        muted ? styles.muted : ''
      } ${
        className || ''
      }`}
    >
      <div className={styles.track}>
        <div className={styles.bar} style={{ width: `${percent}%` }} />
      </div>
      <input
        type='range'
        {...props}
        className={`${styles.input} ${disabled ? styles.inputDisabled : ''}`}
      />
    </div>
  )
}

export default ArkSlider
