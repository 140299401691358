import React, { useState } from 'react'

import ArkButton from 'src/core/components/ArkButton'
import ArkConfirmModal, { ArkConfirmModalMode } from 'src/core/components/ArkConfirmModal'

import styles from './CompanySettingsView.module.css'

export interface CompanyProgramTranscodeSyncViewProps {
  projectsTotalCount?: number
  projectsNeedSyncCount?: number
  force?: boolean
  syncing: boolean
  allow: boolean // disabled the sync option while certain other actions are running that we don't want to run at the same time
  syncResult?: boolean
  syncError?: Error
  onSyncTranscoder?: (force?: boolean) => Promise<void>
  className?: string
}

const CompanyProgramTranscodeSyncView = (props: CompanyProgramTranscodeSyncViewProps) => {
  const { projectsTotalCount, projectsNeedSyncCount, force, syncing, allow, syncResult, syncError, onSyncTranscoder: _onSyncTranscoder, className } = props
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

  // TODO: what if a company currently has 0 projects? (nothing to sync & so should just halt with null?)
  const syncRequired = !force
  // const syncRequiredAllProjects = projectsNeedSyncCount !== undefined && projectsTotalCount !== undefined && projectsNeedSyncCount === projectsTotalCount

  const onSyncTranscoder = () => {
    if (_onSyncTranscoder) _onSyncTranscoder(force)
  }

  const onShowConfirmModal = () => {
    setShowConfirmModal(true)
  }

  const renderConfirmModal = () => {
    return (
      <ArkConfirmModal
        show={showConfirmModal}
        mode={ArkConfirmModalMode.warning}
        title='Sync Transcoder Settings?'
        message={
          <>
            {/* Are you sure you want to sync transcoder settings now?
            WARNING: Doing so will restart all active ABR delivery for each synced project & may briefly interrupt ABR playback. */}
            <div>
              <p>Are you sure you want to {force ? 'force ' : ''}sync transcoder settings now?</p>
              {!force && (
                <p className={styles.notice}>{projectsNeedSyncCount}/{projectsTotalCount} projects have transcoder settings waiting to be applied.</p>
              )}
              {force && (
                <p>All projects will have transcoder settings synced.</p>
              )}
              <p><span className={styles.title}>WARNING:</span> Doing so will restart all active programs for each synced project &amp; may briefly interrupt playback.</p>
            </div>
          </>
        }
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setShowConfirmModal(false)
          onSyncTranscoder()
        }}
        onClose={() => setShowConfirmModal(false)}
      />
    )
  }

  let syncBtnTitle = ''
  if (syncRequired) {
    syncBtnTitle = `SYNC ${projectsNeedSyncCount && projectsNeedSyncCount > 0 ? projectsNeedSyncCount + '/' : ''}${projectsTotalCount && projectsTotalCount > 1 ? projectsTotalCount + ' ' : ''}PROJECTS` // ${syncRequiredAllProjects ? 'ALL' : ''}
  } else {
    syncBtnTitle = `FORCE SYNC TRANSCODERS${projectsTotalCount && projectsTotalCount > 1 ? ' (' + projectsTotalCount + ' PROJECTS)' : ''}`
  }

  return (
    <div className={`${styles.transcoderSyncView} ${className}`}>
      {(syncResult !== undefined) && (
        <>
          {syncResult && (
            <div className={styles.transcoderSyncSuccess}>
              <div className={styles.title}>SYNC SUCCESS</div>
              <div className={styles.text}>Transcoder settings have been applied successfully.</div>
            </div>
          )}
          {!syncResult && ( // TODO: does this apply to the company sync endpoint? or only the project specifc one?
            <div className={styles.transcoderSyncSuccess}>
              <div className={styles.title}>SYNC NOT NEEDED</div>
              <div className={styles.text}>Transcoder settings have already been applied to all project programs.</div>
            </div>
          )}
        </>
      )}
      {syncError && (
        <div className={styles.transcoderSyncError}>
          <div className={styles.title}>SYNC ERROR</div>
          <div className={styles.text}>{syncError.message}</div>
        </div>
      )}
      {syncRequired && projectsNeedSyncCount !== undefined && projectsNeedSyncCount > 0 && (syncResult !== true) && (
        <div className={styles.transcoderSyncWarning}>
          <div>
            <p className={styles.notice}>{projectsNeedSyncCount}/{projectsTotalCount} projects have transcoder settings waiting to be applied.</p>
            <p><span className={styles.title}>WARNING:</span> Doing so will restart all active programs for each synced project &amp; may briefly interrupt playback.</p>
          </div>
          <ArkButton type='button' size='medium' color='orange' loading={syncing} disabled={!allow} onClick={onShowConfirmModal}>{syncBtnTitle}</ArkButton>
        </div>
      )}
      {!syncRequired && (
        <ArkButton type='button' size='medium' color='orange' fluid loading={syncing} disabled={!allow} onClick={onShowConfirmModal}>{syncBtnTitle}</ArkButton>
      )}
      {renderConfirmModal()}
    </div>
  )
}

export default CompanyProgramTranscodeSyncView
