/**
 * global configuration & white-labelling
 */

import { WEB_CONFIG_OVERRIDE } from './config'
import defaultConfig from './configs/default-config'

type GlobalConfigProps = {
  appName: string
  favicon: string
  logo: string
  preferPassthrough: boolean // NOTE clear browser cache to take effect
  showPoweredByFooter: boolean
  showAutoSoloTopBar: boolean
  supportDocURL: string
  supportEmail: string
  registrationEnabled: boolean
  appDeeplinkURL: string
}

let variantConfig: Partial<GlobalConfigProps> = {}

// use code splitting with a dynamic import to load the client/site specific config file if needed (which won't be imported or included in builds its not needed)
export function loadGlobalConfig (callback?: (config: GlobalConfigProps) => void) {
  console.log('loadGlobalConfig - WEB_CONFIG_OVERRIDE:', WEB_CONFIG_OVERRIDE)
  // TESTING: to exclude the variant config file(s) from builds its not used in we seem to need to use the `process.env` var directly, rather than the `WEB_CONFIG_OVERRIDE` var loaded from it
  // NB: so currently using both the `process.env` vars & the `WEB_CONFIG_OVERRIDE` var loaded from it (just to make it obvious what value it is) to allow the variant config file(s) to be excluded from the build
  if (process.env.REACT_APP_WEB_CONFIG_OVERRIDE && process.env.REACT_APP_WEB_CONFIG_OVERRIDE.length > 0 && WEB_CONFIG_OVERRIDE) { // if (WEB_CONFIG_OVERRIDE) {
    import(`./configs/${WEB_CONFIG_OVERRIDE}-config`).then((module) => {
      variantConfig = module.default
      console.log('loadGlobalConfig - variantConfig:', variantConfig)
      const _config = { ...defaultConfig, ...variantConfig }
      if (callback) callback(_config)
    }).catch((err) => {
      throw err
    })
  } else {
    if (callback) callback(defaultConfig)
  }
}

export const DefaultGlobalConfig = defaultConfig
export type { GlobalConfigProps }
