/**
 * ArkCheckbox
 */

import React from 'react'

import { Checkbox, CheckboxProps, Popup } from 'semantic-ui-react'

import styles from './ArkCheckbox.module.css'

export interface ArkCheckboxProps extends CheckboxProps {
  disabled?: boolean
  disabledMessage?: string | React.ReactNode
}

// NB: now wrapped in a `Popup` component to show a custom message when the checkbox is disabled & a message is provided
const ArkCheckbox = (props: ArkCheckboxProps) => {
  const { disabled: checkboxDisabled, disabledMessage, ...otherProps } = props
  const checkbox = <Checkbox disabled={checkboxDisabled} {...otherProps} className={`${styles.checkbox} ${props.className || ''}`} />
  const showDisabledPopup = checkboxDisabled !== false && !!disabledMessage
  return (
    <Popup
      className={styles.disabledPopup}
      content={disabledMessage}
      disabled={!showDisabledPopup} // NB: disable/hide the popup if the checkbox is not disabled & there is no message (this is NOT the same as the `disabled` prop on the checkbox)
      trigger={checkbox}
      position='bottom right'
      offset={[0, 5]}
      size='small' // mini, tiny, small, large, huge
      // open // DEBUG ONLY: uncomment/enable for easy styling
    />
  )
}

export default ArkCheckbox
