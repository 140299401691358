import React, { useState } from 'react'

import ArkButton from 'src/core/components/ArkButton'

import { Icon, Popup } from 'semantic-ui-react'
import ArkIcon from 'src/core/components/ArkIcon'
import ArkSpacer from 'src/core/components/ArkSpacer'

import styles from './ArkPreviewCopyView.module.css'

export interface ArkPreviewCopyViewProps {
  title: string | React.ReactNode
  value?: string
  showViewButton?: boolean // whether to add/show the 'view' button - defaults to true if not set
  showCopyButton?: boolean // whether to add/show the 'copy' button - defaults to true if not set
  viewTimeout?: number // seconds to display the password view popup before it auto hides again (defaults to 10 seconds if not set)
  copyResultTimeout?: number // seconds to display the copy result popup (defaults to 2 if not set)
  disabled?: boolean
  compactPopup?: boolean
}

const ArkPreviewCopyView = (props: ArkPreviewCopyViewProps) => {
  const {
    title,
    value,
    showViewButton = true,
    showCopyButton = true,
    viewTimeout = 10,
    copyResultTimeout = 2,
    disabled = (value === undefined),
    compactPopup = false
  } = props

  if (!showViewButton && !showCopyButton) return null // render nothing if both view & copy buttons are disabled

  const [showViewPopup, setShowViewPopup] = useState<boolean>(false)
  const [showCopyResult, setShowCopyResult] = useState<boolean>(false)

  const [viewTimeoutRef, setViewTimeoutRef] = useState<ReturnType<typeof setTimeout> | undefined>()
  const [copyTimeoutRef, setCopyTimeoutRef] = useState<ReturnType<typeof setTimeout> | undefined>()
  const [copyResult, setCopyResult] = useState<boolean | undefined>()

  // -------

  // NB: only focusing on chrome support for this currently, will need to extend to support fallbacks for some other browsers
  // TODO: move this to a centralised helper/util file so we can use it in various components/classes
  // ref: https://stackoverflow.com/a/30810322
  const copyToClipboard = async (text: string) => {
    if (!navigator.clipboard) {
      // TODO: add fallback copy browser support here if we want to support other browsers that don't support navigator.clipboard (see the SO ref above for more details)
      return false
    }
    try {
      await navigator.clipboard.writeText(text)
      return true
    } catch (err) {
      console.error('ArkPreviewCopyView - copyToClipboard - err: ', err)
      return false
    }
  }

  // -------

  const viewPassword = (show: boolean) => {
    if (viewTimeoutRef) {
      clearTimeout(viewTimeoutRef)
      setViewTimeoutRef(undefined)
    }
    setShowViewPopup(show)
    // auto hide the url after x seconds if its set to show
    if (show) {
      const timeout = viewTimeout * 1000
      const timeoutRef = setTimeout(() => {
        setShowViewPopup(false)
        setViewTimeoutRef(undefined)
        // if (this.props.onShowURLChange) this.props.onShowURLChange(false)
      }, timeout)
      if (timeoutRef) {
        setViewTimeoutRef(timeoutRef)
      }
    }
  }

  const toggleViewPassword = () => {
    viewPassword(!showViewPopup)
  }

  const copyPassword = async () => {
    if (copyTimeoutRef) {
      clearTimeout(copyTimeoutRef)
    }
    if (!value) {
      // console.log('ArkPreviewCopyView - copyPassword - field not set')
      setCopyResult(false)
      setShowCopyResult(true)
    } else {
      const copyResult = await copyToClipboard(value)
      // console.log('ArkPreviewCopyView - copyPassword - copyResult: ', copyResult)
      setCopyResult(copyResult)
      setShowCopyResult(true)
    }
    const timeout = copyResultTimeout * 1000
    const timeoutRef = setTimeout(() => {
      setCopyResult(undefined) // hide the copy alert after x seconds
      setShowCopyResult(false)
      setCopyTimeoutRef(undefined)
    }, timeout)
    if (timeoutRef) {
      setCopyTimeoutRef(timeoutRef)
    }
  }

  const viewButton = (
    <ArkButton
      icon
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        event.stopPropagation()
        toggleViewPassword()
      }}
      disabled={disabled}
    >
      {showViewPopup
        ? <ArkIcon color='var(--tx-light)' name={'eye-slash'} size={14} />
        : <ArkIcon color='var(--tx-light)' name={'eye'} size={14} />
      }
    </ArkButton>
  )

  const copyButton = (
    <ArkButton
      icon
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        event.stopPropagation()
        copyPassword()
      }}
      disabled={disabled}
    >
      <ArkIcon color='var(--tx-light)' name={'copy'} size={14} />
    </ArkButton>
  )

  return (
    <>
      {/* <label>
        {label}
      </label> */}
      <div>
        {showViewButton && (
          <Popup
            open={showViewPopup}
            size='small'
            flowing
            header={title + ': '}
            content={(value ?? '')}
            trigger={viewButton}
            hideOnScroll={true}
            position='left center'
            popper={{ className: styles.popupWrapper + ' popupWrapper' }} // add a className to the parent element so we can disable blurring on it via css
            className={styles.popup + (compactPopup ? ' ' + styles.compact : '')}
          />
        )}
        {showViewButton && showCopyButton && (<ArkSpacer horizontal />)}
        {showCopyButton && (
          <Popup
            open={showCopyResult}
            size='small'
            flowing
            header={(copyResult === false ? 'Error' : undefined)}
            content={(copyResult === true
              ? (<><Icon name='check circle' size='large' />{title} Copied</>)
              : (<>Failed to copy {title}</>)
            )}
            trigger={copyButton}
            style={{ color: (copyResult === true ? 'green' : 'red') }}
            hideOnScroll={true}
            popper={{ className: styles.popupWrapper + ' popupWrapper' }} // add a className to the parent element so we can disable blurring on it via css
            className={styles.popup + (compactPopup ? ' ' + styles.compact : '')}
          />
        )}
      </div>
    </>
  )
}

export default ArkPreviewCopyView
