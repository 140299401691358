import React from 'react'
import { withRouter, RouteComponentProps, matchPath } from 'react-router-dom'

import {
  AuthSSOContext, AuthSSOProvider,
  withAuthContext, IAuthMultiContext,
  withCompanyInviteContext, ICompanyInviteMultiContext,
  withGlobalConfigProvider, IGlobalConfigMultiContext,
  withNavContext, INavMultiContext,
  withServerContext, IServerMultiContext
} from '../../../providers'
import { AuthLoginServiceType, IAuthLoginService } from 'src/core/models'

import ArkButton from '../../../components/ArkButton'
import ArkCenterLayout from '../../../components/ArkCenterLayout'
import ArkPage from '../../../components/ArkPage/ArkPage'

import * as ROUTES from '../../../../constants/routes'

import RegisterForm from './RegisterForm'
import RegisterSSOForm from './RegisterSSOForm'
import LoginEmailLookupForm from '../Login/LoginEmailLookupForm'
import LoginNotUserView from '../Login/LoginNotUserView'
import RegisterSSOView from '../Register/RegisterSSOView'

import { Divider, Header, Label, Segment } from 'semantic-ui-react'

import styles from './RegisterPage.module.css'

enum RegisterPageStage {
  email, details, ssoLogin, ssoDetails, tfa // phone
}

interface IProps extends IAuthMultiContext, ICompanyInviteMultiContext, INavMultiContext, IServerMultiContext, IGlobalConfigMultiContext, RouteComponentProps {}
interface IState {
  registerStage: RegisterPageStage
  loginService?: IAuthLoginService // NB: only used for SSO logins here (so we know which sso service to use & its config details)
  email?: string
  authName?: { firstName?: string, lastName?: string }
  isSSOCallback: boolean
  isInvite: boolean
}

class RegisterPage extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = {
      registerStage: RegisterPageStage.email,
      isSSOCallback: false,
      isInvite: false
    }
  }

  componentDidMount () {
    let authEmail: string | undefined
    let authName: { firstName?: string, lastName?: string } | undefined
    if (this.props.authContext.store.cacheEmail && this.props.authContext.store.cacheType === 'register') {
      authEmail = this.props.authContext.store.cacheEmail
      if (this.props.authContext.store.cacheName) {
        authName = this.props.authContext.store.cacheName
      }
      // this.props.authContext.actions.cacheClear() // NB: no longer clearing the cache here so page refreshes mid login/register resume with the cached email
      console.log('RegisterPage - componentDidMount - cacheEmail set + cacheType === \'register\' - authEmail:', authEmail, ' authName:', authName)
    } else if (this.props.authContext.store.cacheEmail && this.props.authContext.store.cacheType !== 'register') {
      console.log('RegisterPage - componentDidMount - cacheEmail set + cacheType !== \'register\' - cacheType:' + this.props.authContext.store.cacheType + ' cacheEmail:', this.props.authContext.store.cacheEmail)
    } else {
      console.log('RegisterPage - componentDidMount - cacheEmail NOT set - cacheType:' + this.props.authContext.store.cacheType + ' cacheEmail:', this.props.authContext.store.cacheEmail)
    }

    // TESTING: if registration is disabled, check if we are here via an invite, so we can skip the registration disabled checks
    // NB: if the page refreshes (for whatever reason) - `inviteRegistrationRequired` won't be true anymore when redirected from the invite process
    // NB: so we also check if an invite is cached via `getCachedInvite` & if its for the email we have cached for registration & allow registration if so (ignore/skip if there isn't an email or matching invite cached)??
    const registrationEnabled = this.props.configContext.store.config.registrationEnabled
    let _isInvite = false
    if (authEmail !== undefined && !registrationEnabled) {
      // check if we were (directly) redirected here from the invite process (NB: this will be false if the page is reloaded since then)
      const inviteRegistrationRequired = this.props.companyInviteContext.store.registrationRequired
      // console.log('RegisterPage - componentDidMount - invite-check - inviteRegistrationRequired:', inviteRegistrationRequired)
      // fallback - if the page was reloaded since the invite redirect `registrationRequired` will be false, so check if we have an invite cached & if its for the email we have cached for this registration
      if (!inviteRegistrationRequired) {
        _isInvite = this.props.companyInviteContext.actions.hasCachedInviteForEmail(authEmail)
      } else {
        _isInvite = true
      }
    }
    console.log('RegisterPage - componentDidMount - invite-check - authEmail:', authEmail, ' registrationEnabled:', registrationEnabled, ' _isInvite:', _isInvite)
    // if (_isInvite) this.setState({ isInvite: true })

    if (authEmail) {
      // UPDATE: instead of skipping the email lookup stage when an email was cached (e.g redirected from the login page)
      // UPDATE: ..we auto re-run the lookup so we can check the login service type/provider & handle it accordingly (instead of caching that as well from the login page, to avoid stale cache issues or potential abuse of the cache)
      // this.setState({ email: authEmail, authName: authName, registerStage: RegisterPageStage.details })
      this.setState({ email: authEmail, authName: authName, registerStage: RegisterPageStage.email, isInvite: _isInvite })
    }

    // check if this is an sso callback route/path (redirected back from the external okta/auth0 login service/provider)
    // ..if so treat this as an sso login stage & hand over further sso processing to the dedicated sso view & its provider (it figures out which login service etc.)
    // NB: we don't have access to the AuthSSOProvider at this level/commponent, so we manually check the route/path here
    // NB: now only setting to true if theres args in the sso callback url, don't consider it a callback if they aren't (as they've already been parsed?)
    const isSSOCallbackPath = matchPath(this.props.location.pathname, { path: ROUTES.REGISTER_SSO, exact: false, strict: false })
    const hasSSOCallbackArgs = this.props.location.search !== ''
    console.log('RegisterPage - componentDidMount - isSSOCallbackPath:', isSSOCallbackPath, ' hasSSOCallbackArgs:', hasSSOCallbackArgs, ' this.props.location:', this.props.location)
    if (isSSOCallbackPath && hasSSOCallbackArgs && this.state.registerStage !== RegisterPageStage.ssoLogin) {
      this.setState({ registerStage: RegisterPageStage.ssoLogin, isSSOCallback: true }) // TODO: reset/update if the page url changes while this page/component remains loaded?
    }
  }

  render () {
    const { registerStage, loginService } = this.state
    let page: JSX.Element | null = null
    switch (registerStage) {
      case RegisterPageStage.email: page = this._renderEmailLookupPage(); break
      case RegisterPageStage.details: page = this._renderDetailsPage(); break
      case RegisterPageStage.ssoLogin: page = this._renderSSOLoginPage(loginService); break
      case RegisterPageStage.ssoDetails: page = this._renderSSODetailsPage(loginService); break
      case RegisterPageStage.tfa: page = this._renderEnable2FAPage(); break
    }

    return (
      <ArkPage>
        <ArkCenterLayout>
          <Segment inverted>{page}</Segment>
        </ArkCenterLayout>
      </ArkPage>
    )
  }

  _onRegisterCallback = (accessToken: string, _email?: string) => {
    const { email, isSSOCallback } = this.state
    console.log('RegisterPage - _onRegisterCallback - accessToken:', accessToken, ' _email:', _email, ' email(state):', email, ' isSSOCallback(state):', isSSOCallback)
    console.log('RegisterPage - _onRegisterCallback - authContext - cacheType:' + this.props.authContext.store.cacheType + ' cacheEmail:', this.props.authContext.store.cacheEmail, ' cacheType:', this.props.authContext.store.cacheType)

    if (_email !== undefined && email !== _email) {
      console.log('RegisterPage - _onRegisterCallback - UPDATING email(state) with _email:', _email)
      this.setState({ email: _email })
    } else if (this.props.authContext.store.cacheType === 'sso' && this.props.authContext.store.cacheEmail !== undefined && email !== this.props.authContext.store.cacheEmail) {
      console.log('RegisterPage - _onRegisterCallback - UPDATING email(state) with cacheEmail:', this.props.authContext.store.cacheEmail, ' was:', email)
      this.setState({ email: this.props.authContext.store.cacheEmail })
    }
    this.setState({ registerStage: RegisterPageStage.ssoDetails })

    // update the url/path to remove the sso specific portion of it (now that portion of the auth is done)
    // UPDATE: this redirect breaks the `isSSOCallback` check/bool, so skipping/leaving it as-is for the time being (ideally it would be fixed with the redirect in place, can we just re-set the state var after the url/location change?)
    // this.props.navContext.actions.goto(ROUTES.REGISTER)
  }

  _onRegisterCancel = () => {
    console.log('RegisterPage - _onRegisterCancel')
    this.props.authContext.actions.cacheClear()
    this.props.authContext.actions.clearAuthError()
    this.setState({ registerStage: RegisterPageStage.email, loginService: undefined, email: undefined })
  }

  _renderEmailLookupPage = () => {
    const registrationEnabled = this.props.configContext.store.config.registrationEnabled
    const isInvite = this.state.isInvite
    return (
      <>
        <Header as="h2" textAlign="center">Register</Header>

        {(registrationEnabled || isInvite) ? this._renderEmailLookupForm() : this._renderRegisterFormDisabled()}

        <Divider horizontal inverted>Or</Divider>

        <div className={styles.login}>
          <Label size="large">
            <p>Already have an account?</p>
          </Label>
          <ArkButton fluid size="large" onClick={() => {
            this.props.navContext.actions.goto(ROUTES.LOGIN)
          }}>
            Login
          </ArkButton>
        </div>
      </>
    )
  }

  _renderRegisterFormDisabled = () => {
    return (
      <div className={styles.login}>
        <Label size="large" className={styles.registerWarningLabel}>
          <p>⚠️ Registration is disabled!</p>
        </Label>
      </div>
    )
  }

  _renderEmailLookupForm = () => {
    console.log('RegisterPage - _renderEmailLookupForm - this.state.email:', this.state.email)
    return (
      <LoginEmailLookupForm
        email={this.state.email}
        autoRun={this.state.email !== undefined}
        onEmailLookup={(email: string, loginService: IAuthLoginService) => {
          console.log('RegisterPage - onEmailLookup - email:', email, ' loginService:', loginService)
          const isNewUser = loginService.type === AuthLoginServiceType.NewUser
          const loginServiceType = isNewUser ? (loginService.ssoConfig?.type ?? AuthLoginServiceType.NewUser) : loginService.type
          console.log('RegisterPage - onEmailLookup - isNewUser:', isNewUser, ' loginServiceType:', loginServiceType)
          if (isNewUser && (loginServiceType === AuthLoginServiceType.EmailPassword || loginServiceType === AuthLoginServiceType.NewUser)) {
            this.setState({
              registerStage: RegisterPageStage.details,
              email
            })
          } else if (isNewUser && loginServiceType === AuthLoginServiceType.SSOOktaOIDC) {
            this.setState({
              registerStage: RegisterPageStage.ssoLogin,
              loginService: loginService,
              email
            })
          } else if (isNewUser && loginServiceType === AuthLoginServiceType.SSOAuth0) {
            this.setState({
              registerStage: RegisterPageStage.ssoLogin,
              loginService: loginService,
              email
            })
          } else if (isNewUser) {
            console.log('RegisterPage - onEmailLookup - WARNING: isNewUser - loginServiceType:', loginServiceType, ' - UNKNOWN/UNHANDLED LOGIN SERVICE TYPE <<<<')
            // TODO: throw/show an error??
          } else {
            console.log('LoginPage - onEmailLookup - !isNewUser - GOTO LOGIN PAGE...')
            // redirect to the login page & cache the email so the loaded page can check for it & skip the email entry step
            this.props.authContext.actions.cacheLoginEmail(email)
            this.props.navContext.actions.goto(ROUTES.LOGIN)
          }
        }}
      />
    )
  }

  _renderDetailsPage = () => {
    const { email, authName } = this.state
    return (
      <>
        <Header as="h2" textAlign="center">Register</Header>
        <RegisterForm
          email={email}
          firstName={authName?.firstName}
          lastName={authName?.lastName}
        />
        <LoginNotUserView
          email={email ?? ''}
          onClick={() => {
            this.props.authContext.actions.cacheClear()
            // NB: now clearing the `email` state here otherwise it'll auto re-run the email lookup & block you from changing the email (mainly done here to match the login page behaviour)
            this.setState({ registerStage: RegisterPageStage.email, email: undefined })
          }}
        />
      </>
    )
  }

  _renderEnable2FAPage = () => {
    return (<></>)
  }

  _renderSSOLoginPage = (loginService?: IAuthLoginService) => {
    const { email, isSSOCallback } = this.state
    console.log('RegisterPage - _renderSSOLoginPage - email:', email, ' loginService:', loginService, ' isSSOCallback:', isSSOCallback)
    const apiClient = this.props.serverContext.store.apiClient
    const authApi = this.props.serverContext.store.authApi
    // NB: we might not have the email available here if we're coming back from the okta/auth0 callback..
    // ..so render/call it regardless & let it check/handle internally (loads the email from the cache if its set etc.)
    if (!apiClient || !authApi) return null
    return (
      <AuthSSOProvider apiClient={apiClient} authApi={authApi}>
        <RegisterSSOView
          email={email}
          loginService={loginService}
          isSSOCallback={isSSOCallback}
          onRegisterCallback={this._onRegisterCallback}
          onCancel={this._onRegisterCancel}
        />
      </AuthSSOProvider>
    )
  }

  _renderSSODetailsPage = (loginService?: IAuthLoginService) => {
    const { email, authName } = this.state
    console.log('RegisterPage - _renderSSODetailsPage - email:', email, ' loginService:', loginService)
    const apiClient = this.props.serverContext.store.apiClient
    const authApi = this.props.serverContext.store.authApi
    // NB: we might not have the email available here if we're coming back from the okta/auth0 callback..
    // ..so render/call it regardless & let it check/handle internally (loads the email from the cache if its set etc.)
    if (!apiClient || !authApi) return null
    return (
      <AuthSSOProvider apiClient={apiClient} authApi={authApi}>
        <AuthSSOContext.Consumer>
          {(authSSOContext) => {
            console.log('RegisterPage - _renderSSODetailsPage - authSSOContext: ', authSSOContext)
            // grab the users first & last name from the SSO user data/object
            // TODO: DON'T make this okta specific <<<<<<
            const { oktaUser } = authSSOContext.store
            const firstName = oktaUser?.given_name ?? authName?.firstName
            const lastName = oktaUser?.family_name ?? authName?.lastName
            const lockName = !!firstName && !!lastName // don't allow the name fields to be edited if we have them from the SSO provider
            return (
              <>
                <Header as="h2" textAlign="center">Register</Header>
                <RegisterSSOForm
                  email={email}
                  firstName={firstName}
                  lastName={lastName}
                  lockName={lockName}
                />
                <LoginNotUserView
                  email={email ?? ''}
                  onClick={() => {
                    this.props.authContext.actions.cacheClear()
                    // NB: now clearing the `email` state here otherwise it'll auto re-run the email lookup & block you from changing the email (mainly done here to match the login page behaviour)
                    this.setState({ registerStage: RegisterPageStage.email, email: undefined })
                  }}
                />
              </>)
          }}
        </AuthSSOContext.Consumer>
      </AuthSSOProvider>
    )
  }
}
export default withGlobalConfigProvider(withRouter(withNavContext(withAuthContext(withCompanyInviteContext(withServerContext(RegisterPage))))))
