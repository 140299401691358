import React, { useContext, useEffect, useState } from 'react'
import { RowData, RowSelectionState, createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import OutputStream, { IOutputStreamStatus } from 'src/admin/pages/streamhub/models/OutputStream'

import { StreamhubSourcesContext } from 'src/admin/pages/streamhub/providers/StreamhubSourcesProvider'

import ArkButton from 'src/core/components/ArkButton'
import ArkCheckbox from 'src/core/components/ArkCheckbox'
import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'

import { formatDate, formatDateTime } from 'src/core/utilities/date'

import { CheckboxProps, Image } from 'semantic-ui-react'

import styles from '../Streamhub.module.css'

/**
 * TanStack Table (formerly React Table) trial usage
 *
 * refs:
 *  - https://tanstack.com/table/v8/docs/introduction
 *  - https://tanstack.com/table/v8/docs/guide/column-defs
 *  - https://tanstack.com/table/latest/docs/guide/row-selection
 *  - https://medium.com/@jordammendes/build-powerfull-tables-in-reactjs-with-tanstack-9d57a3a63e35
 *  - https://codesandbox.io/examples/package/@tanstack/react-table
 *
 * NB: may also want to consider alternative table libraries, including:
 *  - https://react-tables.com # NB: this is a different library to the TanStack (original `react-table`) version we're trialing
 *  - https://komarovalexander.github.io/ka-table
 */

// extend the column meta object to include custom values - ref: https://tanstack.com/table/v8/docs/api/core/column-def#meta
declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    cellClassName?: string
    headerClassName?: string
  }
  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // interface CellContext<TData extends RowData, TValue> {
  //   row: Row<TData>
  // }
}

// type StreamTableColumns = {
//   id: number
//   name?: string
//   // preview: string
//   // details: string
//   // status: string
//   // url: string
//   // actions: string
// }

export enum StreamhubStreamsActionType {
  start = 'start',
  stop = 'stop',
  restart = 'restart',
  edit = 'edit',
  delete = 'delete',
  deleteComplete = 'deleteComplete'
}

export type StreamhubStreamsActionCallback = (streamId: number, actionType: StreamhubStreamsActionType) => Promise<boolean | undefined>
export type StreamhubStreamsSelectionChangeCallback = (selectedStreamIds: Array<number>) => void

interface IProps {
  streams?: Array<OutputStream>
  onAction?: StreamhubStreamsActionCallback
  onSelectChange?: StreamhubStreamsSelectionChangeCallback
}

const StreamhubStreamsTable = (props: IProps) => {
  const { streams, onAction, onSelectChange } = props

  const sourcesContext = useContext(StreamhubSourcesContext)

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const startStream = async (streamId: number) => {
    if (onAction) await onAction(streamId, StreamhubStreamsActionType.start)
  }
  const stopStream = async (streamId: number) => {
    if (onAction) await onAction(streamId, StreamhubStreamsActionType.stop)
  }
  const restartStream = async (streamId: number) => {
    if (onAction) await onAction(streamId, StreamhubStreamsActionType.restart)
  }
  const showEditStreamModal = async (streamId: number) => {
    if (onAction) await onAction(streamId, StreamhubStreamsActionType.edit)
  }
  const onDeleteStream = async (streamId: number) => {
    if (onAction) {
      return await onAction(streamId, StreamhubStreamsActionType.delete) ?? false
    }
    return false
  }
  const onDeleteStreamComplete = async (streamId: number) => {
    if (onAction) await onAction(streamId, StreamhubStreamsActionType.deleteComplete)
  }

  const columnHelper = createColumnHelper<OutputStream>()
  const columns = React.useMemo( // <ColumnDef<OutputStream, unknown>[]>
    () => [
      // columnHelper.accessor('id', {
      //   header: () => '-',
      //   cell: props => props.getValue()
      //   // footer: props => props.column.id
      // }),
      // columnHelper.accessor('name', {
      //   header: () => 'Name',
      //   cell: props => props.getValue() ?? 'N/A'
      // }),
      // columnHelper.accessor(
      //   stream =>
      //       <div className={styles.streamDetails}>
      //         <div className={styles.streamName}>{stream.name ? stream.name : ''}</div>
      //       </div>
      //     ,
      //   {
      //     id: 'details',
      //     header: () => 'Details'
      //   })
      columnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <ArkCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            // NB: by default toggling when some rows are selected will select all rows
            // NB: via getToggleAllRowsSelectedHandler / getToggleAllPageRowsSelectedHandler directly, or calling toggleAllRowsSelected / toggleAllPageRowsSelected manually
            // onChange={table.getToggleAllRowsSelectedHandler()} // or getToggleAllPageRowsSelectedHandler
            // ALTERNATIVE: use a custom handler to invert the default `indeterminate` toggle behavior, & instead deselect all rows when some are selected
            onChange={(_event: React.FormEvent<HTMLInputElement>, _data: CheckboxProps) => {
              const allRowsSelected = table.getIsAllRowsSelected() // getIsAllPageRowsSelected
              const someRowsSelected = table.getIsSomeRowsSelected() // getIsSomePageRowsSelected
              console.log('StreamhubStreamsTable - columns - select header - onChange - allRowsSelected:', allRowsSelected, ' someRowsSelected:', someRowsSelected)
              table.toggleAllRowsSelected(!(allRowsSelected || someRowsSelected)) // toggleAllPageRowsSelected
            }}
          />
        ),
        cell: ({ row }) => (
          <ArkCheckbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            // label='Programs'
            // onChange={(_event, data) => setShowPrograms(!!data.checked)}
            // toggle
          />
        ),
        meta: {
          headerClassName: styles.streamSelect
        }
      }),
      columnHelper.display({
        id: 'preview',
        header: () => '',
        cell: data => {
          const stream = data.row.original
          const streamSource = sourcesContext.store.sources?.find((streamSource) => streamSource.id === stream.sourceId)
          return (
            <>
              <div className={styles.idRow + ' ' + styles.idStream}>Stream ID: {stream.id}</div>
              {streamSource && (<Image src={sourcesContext.actions.getSourcePreviewImageURL(streamSource, 100)} />)}
              <div className={styles.idRow + ' ' + styles.idSource}>Source: {stream.sourceId}</div>
            </>
          )
        },
        meta: {
          cellClassName: styles.streamIdAndImg
        }
      }),
      columnHelper.display({
        id: 'details',
        header: () => 'Stream Details',
        cell: data => {
          const stream = data.row.original
          const programData = stream.programData
          const streamSource = sourcesContext.store.sources?.find((streamSource) => streamSource.id === stream.sourceId)
          return (
            <div className={styles.streamDetails}>
              <div className={styles.streamName}>{stream.name ? stream.name : ''}</div>
              {programData && (
                <>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>server:</div><div className={styles.detailsValue}>{programData.server}</div>
                  </div>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>org:</div><div className={styles.detailsValue}>{programData.companyName} ({programData.companyId})</div>
                  </div>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>project:</div><div className={styles.detailsValue}>{programData.projectName} ({programData.projectId})</div>
                  </div>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>program:</div><div className={styles.detailsValue}>{programData.programName} ({programData.programId})</div>
                  </div>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>program updated:</div><div className={styles.detailsValue}>{programData.updatedAt ? formatDate(programData.updatedAt) : ''}</div>
                  </div>
                </>
              )}
              {streamSource && (
                <div className={styles.detailsRow}>
                  <div className={styles.detailsTitle}>source:</div><div className={styles.detailsValue}>
                    <div className={styles.sourceName}>{streamSource.name ? `${streamSource.name}` : `Source ${streamSource.id}`}</div>{/* ${streamSource.id}) */}
                  </div>
                </div>
              )}
            </div>
          )
        },
        meta: {
          cellClassName: styles.streamDetailsCell
        }
      }),
      columnHelper.display({
        id: 'status',
        header: () => 'Status',
        cell: data => {
          const stream = data.row.original
          const streamStatus = stream.status // streamUpdates?.get(stream.id)
          // const streamUpdating = streamStatus === IOutputStreamStatus.starting || streamStatus === IOutputStreamStatus.stopping || streamStatus === IOutputStreamStatus.restarting
          const streamUpdateError = streamStatus === IOutputStreamStatus.error
          const streamRetrying = streamUpdateError && stream.retryEnabled && stream.retryCount < stream.retryMaxAttempts
          const streamRetriesFailed = streamUpdateError && stream.retryEnabled && stream.retryCount >= stream.retryMaxAttempts
          return (
            <>
              <div className={styles.statusRow}>
                <div className={styles.statusTitle}>enabled:</div><div className={styles.statusValue}>{stream.isEnabled ? 'ENABLED' : 'DISABLED'}</div>
              </div>
              <div className={styles.statusRow}>
                <div className={styles.statusTitle}>active:</div><div className={styles.statusValue}>{streamUpdateError ? 'ERROR' : (stream.isActive ? 'ACTIVE' : 'STOPPED')}</div>
              </div>
              <div className={styles.statusRow}>
                <div className={styles.statusTitle}>status:</div><div className={styles.statusValue}>{stream.status !== undefined ? IOutputStreamStatus[stream.status] : 'N/A'}</div>
              </div>
              {(stream.status === IOutputStreamStatus.running) && (
                <>
                  <div className={styles.statusRow}>
                    <div className={styles.statusTitle}>started:</div>
                    <div className={styles.statusValue}>
                      {stream.isActive && stream.startedAt ? formatDateTime(stream.startedAt) : (!streamRetrying && !streamRetriesFailed ? '-' : '')}
                    </div>
                  </div>
                  <div className={styles.statusRow}>
                    <div className={styles.statusTitle}>process:</div>
                    <div className={styles.statusValue}>{stream.pid ? stream.pid : '-'}</div>
                  </div>
                </>
              )}
              {(streamRetrying || streamRetriesFailed) && (
                <div>
                  {!streamRetriesFailed && (
                    <>
                      ERROR STARTING<br />
                      &gt; RETRY DELAY: {stream.retryDelay} secs<br />
                      &gt; RETRY ATTEMPTS: {stream.retryCount} / {stream.retryMaxAttempts}<br />
                    </>
                  )}
                  {streamRetriesFailed && (
                    <>
                      ERROR STARTING<br />
                      RETRY ATTEMPTS FAILED
                    </>
                  )}
                </div>
              )}
            </>
          )
        }
      }),
      columnHelper.display({
        id: 'url',
        header: () => 'URL',
        cell: data => {
          const stream = data.row.original
          let streamUrl = stream.url
          // TESTING: hide any url args (mainly to hide the long passphrase, but generally just to shorten it to the key url path)
          if (streamUrl?.includes('?')) {
            const queryIndex = streamUrl.indexOf('?')
            if (queryIndex >= 0) {
              streamUrl = streamUrl.substring(0, queryIndex)
            }
          }
          const tags: Array<string> = stream.tags ?? []
          return (
            <>
              <div className={styles.streamUrl}>{streamUrl}</div>
              {tags.length > 0 && (
                <div className={styles.streamTags}>
                  {tags.map((tag, index) => (
                    <div key={index} className={styles.streamTag}>{tag}</div>
                  ))}
                </div>
              )}
            </>
          )
        }
      }),
      columnHelper.display({
        id: 'actions',
        header: () => 'Actions',
        cell: data => {
          const stream = data.row.original
          const streamStatus = stream.status // streamUpdates?.get(stream.id)
          const streamUpdating = streamStatus === IOutputStreamStatus.starting || streamStatus === IOutputStreamStatus.stopping || streamStatus === IOutputStreamStatus.restarting
          const streamUpdateError = streamStatus === IOutputStreamStatus.error
          const streamRetrying = streamUpdateError && stream.retryEnabled && stream.retryCount < stream.retryMaxAttempts
          // const streamRetriesFailed = streamUpdateError && stream.retryEnabled && stream.retryCount >= stream.retryMaxAttempts
          return (
            <div className={styles.actionButtons}>
              {streamStatus === IOutputStreamStatus.running && (
                <ArkButton size='mini' className={styles.restartButton} onClick={() => { restartStream(stream.id) }}>R</ArkButton>
              )}
              <div className={styles.startStopButtonWrapper}>
                <ArkButton
                  color={streamUpdateError ? 'orange' : (stream.isActive ? 'red' : 'green')}
                  size='mini'
                  loading={streamUpdating || streamRetrying}
                  style={{ display: 'block', color: 'red !important' }}
                  onClick={() => {
                    console.log('StreamhubStreamsTable - columns - actions - start/stop button - streamStatus: ', streamStatus, ' stream: ', stream)
                    if (streamStatus === IOutputStreamStatus.running || streamRetrying) {
                      stopStream(stream.id)
                    } else if (streamStatus === IOutputStreamStatus.stopped || streamStatus === IOutputStreamStatus.error || streamStatus === IOutputStreamStatus.initial) {
                      startStream(stream.id)
                    }
                  }}
                  className={streamUpdateError ? styles.buttonError : ''}
                >
                  {streamStatus === IOutputStreamStatus.running ? 'STOP' : 'START'}
                </ArkButton>
                {streamUpdateError && (
                  <div className={styles.startError}>
                    ERROR
                    {stream.errorMsg && (<div className={styles.startErrorMsg}>{stream.errorMsg}</div>)}
                  </div>
                )}
              </div>
              <ArkButton size='mini' className={styles.buttonLeftPad} onClick={() => { showEditStreamModal(stream.id) }}>EDIT</ArkButton>
              <ArkManagerDeleteButton
                className={styles.buttonLeftPad}
                itemId={stream.id}
                itemName={'Stream ' + stream.id}
                itemTypeName='Stream'
                buttonTitle='X'
                onDelete={onDeleteStream}
                onDeleteComplete={onDeleteStreamComplete}
                fluid={false}
                size='mini'
                disabled={stream.isActive}
                style={{ display: 'inline' }}
                buttonStyle={{ fontSize: '12px', padding: '8px 10px', marginLeft: 6 }}
              />
            </div>
          )
        },
        meta: {
          cellClassName: styles.tableActions
        }
      })
    ],
    []
  )

  const table = useReactTable({
    data: streams ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection
    },
    getRowId: row => `${row.id}` // use a custom (string) table row id instead of just the index
  })

  // listen for changes to the row selection state & trigger the `onSelectChange` callback when it changes
  useEffect(() => {
    // console.log('StreamhubStreamsTable - useEffect - rowSelection:', rowSelection)
    // TODO: flip to use `rowSelection` directly? (or just use the `table` state directly?)
    const selectedStreamIds = table.getRowModel().rows.filter(row => row.getIsSelected()).map(row => row.original.id)
    // console.log('StreamhubStreamsTable - useEffect - selectedStreamIds:', selectedStreamIds)
    if (onSelectChange) onSelectChange(selectedStreamIds)
  }, [rowSelection])

  // listen for changes to the `streams` data source (e.g. from external filtering) & clear any selected rows that are no longer in the data source
  useEffect(() => {
    // console.log('StreamhubStreamsTable - useEffect(streams) - streams.length:', streams?.length)
    const currentRowSelectionCount = Object.keys(rowSelection).length
    // console.log('StreamhubStreamsTable - useEffect(streams) - currentRowSelectionCount:', currentRowSelectionCount)
    if (currentRowSelectionCount > 0) {
      // console.log('StreamhubStreamsTable - useEffect(streams) - check if any selected rows are no longer in the data source...')
      const validIds: Array<number> = []
      for (const streamIdKey in rowSelection) {
        const streamId = parseInt(streamIdKey)
        // console.log('StreamhubStreamsTable - useEffect(streams) - streamId:', streamId)
        const stream = streams?.find(stream => stream.id === streamId)
        // console.log('StreamhubStreamsTable - useEffect(streams) - streamId:', streamId, ' stream:', stream)
        if (stream) validIds.push(streamId)
      }
      // console.log('StreamhubStreamsTable - useEffect(streams) - validIds:', validIds)
      if (validIds.length !== Object.keys(rowSelection).length) {
        // console.log('StreamhubStreamsTable - useEffect(streams) - WARNING: validIds !== rowSelection.length - clear any selected rows that are no longer in the data source...')
        const newRowSelection = validIds.map(id => ({ [id]: true })).reduce((acc, val) => ({ ...acc, ...val }), {})
        console.log('StreamhubStreamsTable - useEffect(streams) - newRowSelection:', newRowSelection, ' oldRowSelection:', rowSelection)
        table.setRowSelection(newRowSelection)
      }
      // clear any selected rows if the data source changes
      // table.setRowSelection([])
    }
  }, [streams])

  return (
    <div className="flex justify-center h-screen">
      <table className={styles.table + ' ' + styles.streamsTable}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} className={header.column.columnDef.meta?.headerClassName}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} className={styles.streamRow}>{/* onClick={row.getToggleSelectedHandler()} */}
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} className={cell.column.columnDef.meta?.cellClassName}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div/>
    </div>
  )
}

export default StreamhubStreamsTable
