/**
 * ProgramNode
 */

import React from 'react'
import { NodeProps } from 'react-flow-renderer'

import { NodeDataProgram } from '../types'
import BaseNode from './BaseNode'

import styles from './ProgramNode.module.css'

const ProgramNode = (props: NodeProps<NodeDataProgram>) => {
  const { data } = props
  if (!data) return null
  const { colour, name, online, shortName } = data

  const popupBody = (
    <div className={online === 'online' ? styles.popupOnlineOn : styles.popupOnlineOff}>
      {online === 'online' ? '⊛ ONLINE' : '⊘ OFFLINE'}
    </div>
  )

  return (
    <BaseNode
      iconName='program-filled'
      popupBody={popupBody}
      popupName={name}
      popupTitle='Program'
      {...props}
    >
      <div className={styles.container} style={{ backgroundColor: colour }}>
        <div className={styles.name}>{shortName}</div>
      </div>
    </BaseNode>
  )
}

export default ProgramNode
