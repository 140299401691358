import React from 'react'

import StreamhubAssetsAPI, { StreamhubAssets, StreamhubAsset, StreamhubAssetFileType } from '../services/StreamhubAssetsAPI'
import StreamhubAPIClient from '../services/StreamhubAPIClient'

// export type { StreamhubAssetFileType }

// refs:
//  https://www.digitalocean.com/community/tutorials/react-crud-context-hooks
//  https://kentcdodds.com/blog/how-to-use-react-context-effectively
//  https://www.bezkoder.com/react-hooks-crud-axios-api/
//  https://davidlozzi.com/2021/02/08/enhancing-reducer-actions-in-react-context/
//  https://www.linkedin.com/pulse/react-multiple-contexts-class-component-vikram-gupta

export enum StreamhubAssetsStatus {
  initial, loading, done, error
}

export enum StreamhubAssetInfoStatus {
  initial, loading, done, error
}

export enum StreamhubAssetAction {
  upload, delete
}
export enum StreamhubAssetStatus {
  initial, running, done, error
}

export interface IStreamhubAssetsStore {
  // assets
  assets?: StreamhubAssets
  assetsStatus?: StreamhubAssetsStatus
  assetsError?: Error
  // asset info
  assetInfo?: any // TODO: this doesn't have an interface/type yet (raw parsed json)
  assetInfoStatus?: StreamhubAssetInfoStatus
  assetInfoError?: Error
  // asset
  asset?: StreamhubAsset
  assetAction?: StreamhubAssetAction
  assetStatus?: StreamhubAssetStatus
  assetError?: Error
  // assets scanning
  assetsScanStatus?: StreamhubAssetStatus
  assetsScanResult?: any // NB: currently no interface/type for this (raw parsed json)
  assetsScanError?: Error
}

export interface IStreamhubAssetsActions {
  // assets listing
  fetchAssets: () => Promise<void>
  // asset info
  fetchAssetInfo: (fileType: StreamhubAssetFileType, filename: string) => Promise<void>
  // asset actions
  uploadAsset: (fileType: StreamhubAssetFileType, fileFormData: FormData) => Promise<void>
  deleteAsset: (fileType: StreamhubAssetFileType, filename: string) => Promise<void>
  // asset scanning
  scanAssets: () => Promise<void>
  resetScanAssetsState: () => void
}

export interface IStreamhubAssetsContext {
  actions: IStreamhubAssetsActions;
  store: IStreamhubAssetsStore;
}

export interface IStreamhubAssetsMultiContext {
  streamhubAssetsContext: IStreamhubAssetsContext
}

export const StreamhubAssetsContext = React.createContext<IStreamhubAssetsContext>({} as IStreamhubAssetsContext)

export interface StreamhubAssetsProviderProps {
  apiClient: StreamhubAPIClient
  assetsAPI?: StreamhubAssetsAPI
  children?: React.ReactNode
}
export interface StreamhubAssetsProviderState extends IStreamhubAssetsStore {
  assetsAPI: StreamhubAssetsAPI
}

class StreamhubAssetsProvider extends React.Component<StreamhubAssetsProviderProps, StreamhubAssetsProviderState> {
  constructor (props: StreamhubAssetsProviderProps) {
    super(props)
    this.state = {
      assetsAPI: props.assetsAPI ?? new StreamhubAssetsAPI(this.props.apiClient)
    }
  }

  // -------

  fetchAssets = async () => {
    this.setState({ assetsStatus: StreamhubAssetsStatus.loading, assetsError: undefined })
    try {
      const assets = await this.state.assetsAPI.fetchAssets()
      // console.log('StreamhubAssetsProvider - fetchAssets - assets:', assets)
      this.setState({ assets, assetsStatus: StreamhubAssetsStatus.done })
      // await new Promise(resolve => setTimeout(resolve, 2000)) // DEBUG ONLY: add a delay to test the loading state
      // throw new Error('DEBUG ERROR')
    } catch (error) {
      console.error('StreamhubAssetsProvider - fetchAssets - error:', error)
      this.setState({ assets: undefined, assetsStatus: StreamhubAssetsStatus.error, assetsError: error })
    }
  }

  // -------

  fetchAssetInfo = async (fileType: StreamhubAssetFileType, filename: string) => {
    this.setState({ assetInfoStatus: StreamhubAssetInfoStatus.loading, assetInfo: undefined, assetInfoError: undefined })
    try {
      const assetInfo = await this.state.assetsAPI.fetchAssetFileInfo(fileType, filename)
      this.setState({ assetInfo, assetInfoStatus: StreamhubAssetInfoStatus.done })
    } catch (error) {
      this.setState({ assetInfo: undefined, assetInfoStatus: StreamhubAssetInfoStatus.error, assetInfoError: error })
    }
  }

  // -------

  uploadAsset = async (fileType: StreamhubAssetFileType, fileFormData: FormData) => {
    try {
      // TODO: we only currently get a bool response from the server on upload, so never get an 'asset' data object to be able to set it
      // TOOD: delete maybe the same, should the asset object ref be replaced instead with assetFilename & assetFileType vars instead?
      this.setState({ asset: undefined, assetAction: StreamhubAssetAction.upload, assetStatus: StreamhubAssetStatus.running, assetError: undefined })
      await this.state.assetsAPI.uploadAssetFile(fileType, fileFormData)
      this.setState({ asset: undefined, assetAction: StreamhubAssetAction.upload, assetStatus: StreamhubAssetStatus.done, assetError: undefined })
    } catch (error) {
      this.setState({ asset: undefined, assetAction: StreamhubAssetAction.upload, assetStatus: StreamhubAssetStatus.error, assetError: error })
    }
  }

  deleteAsset = async (fileType: StreamhubAssetFileType, filename: string) => {
    try {
      // TODO: we only currently get a bool response from the server on delete, so never get an 'asset' data object to be able to set it
      // TOOD: delete maybe the same, should the asset object ref be replaced instead with assetFilename & assetFileType vars instead?
      this.setState({ asset: undefined, assetAction: StreamhubAssetAction.delete, assetStatus: StreamhubAssetStatus.running, assetError: undefined })
      await this.state.assetsAPI.deleteAssetFile(fileType, filename)
      this.setState({ asset: undefined, assetAction: StreamhubAssetAction.delete, assetStatus: StreamhubAssetStatus.done, assetError: undefined })
    } catch (error) {
      this.setState({ asset: undefined, assetAction: StreamhubAssetAction.delete, assetStatus: StreamhubAssetStatus.error, assetError: error })
    }
  }

  // -------

  // asset scanning
  scanAssets = async (): Promise<void> => {
    if (this.state.assetsScanStatus === StreamhubAssetStatus.running) return
    try {
      this.setState({ assetsScanStatus: StreamhubAssetStatus.running, assetsScanResult: undefined, assetsScanError: undefined })
      const result = await this.state.assetsAPI.scanAssets()
      this.setState({ assetsScanStatus: StreamhubAssetStatus.done, assetsScanResult: result })
    } catch (error) {
      this.setState({ assetsScanStatus: StreamhubAssetStatus.error, assetsScanError: error })
    }
  }

  resetScanAssetsState = () => {
    if (this.state.assetsScanStatus !== StreamhubAssetStatus.running) {
      this.setState({ assetsScanStatus: StreamhubAssetStatus.initial, assetsScanResult: undefined, assetsScanError: undefined })
    }
  }

  // -------

  actions: IStreamhubAssetsActions = {
    // assets
    fetchAssets: this.fetchAssets,
    // asset info
    fetchAssetInfo: this.fetchAssetInfo,
    // asset actions
    uploadAsset: this.uploadAsset,
    deleteAsset: this.deleteAsset,
    // assets scanning
    scanAssets: this.scanAssets,
    resetScanAssetsState: this.resetScanAssetsState
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: IStreamhubAssetsStore = {
  //   status: this.state?.status ?? 'initial',
  //   assets: this.state?.assets,
  //   assetInfo: this.state?.assetInfo,
  //   error: this.state?.error
  // }

  render () {
    return (
      <StreamhubAssetsContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for IStreamhubAssetsStore */ }}
      >
        {this.props.children}
      </StreamhubAssetsContext.Provider>
    )
  }
}

const withStreamhubAssetsContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withStreamhubAssetsContextHOC = (props: any) => (
    <StreamhubAssetsContext.Consumer>
      {(streamhubAssetsContext) => {
        if (streamhubAssetsContext === null) {
          throw new Error('StreamhubAssetsContext must be used within an StreamhubAssetsProvider')
        }
        // console.log('withStreamhubAssetsContext - render - StreamhubAssetsContext.Consumer - streamhubAssetsContext.store: ', streamhubAssetsContext.store)
        return (<Component {...props} {...{ streamhubAssetsContext: streamhubAssetsContext }} />)
      }}
    </StreamhubAssetsContext.Consumer>
  )
  return withStreamhubAssetsContextHOC
}

export { StreamhubAssetsProvider }
export { withStreamhubAssetsContext }
