import React, { useContext } from 'react'
import { APIServerType } from 'src/constants/server_types'
import { ServerContext, UserContext, UserStatus } from 'src/core/providers'

import ArkInfoView from 'src/core/components/ArkInfoView'

import { DEMO_BOOKING_URL_PROD, DEMO_BOOKING_URL_STAGING } from 'src/constants/config'
import { OBJECT_COMPANY_NAME } from 'src/constants/strings'

import styles from './ArkInactiveOrgView.module.css'

const ArkInactiveOrgView = () => {
  const userContext = useContext(UserContext)
  const isLoggedIn = userContext.store.userStatus === UserStatus.loggedIn
  if (!isLoggedIn) return null
  const isActive = userContext.store.selectedCompany?.isActive ?? false
  if (isActive) return null
  const isDemo = userContext.store.selectedCompany?.isDemo ?? false
  let demoBookingsURL = ''
  if (isDemo) {
    // TODO: move the server url logic to a server specific config file/service? e.g within the ServerProvider/ServerContext?
    demoBookingsURL = DEMO_BOOKING_URL_PROD
    const serverContext = useContext(ServerContext)
    if (serverContext.store.apiServerType !== APIServerType.Production) {
      demoBookingsURL = DEMO_BOOKING_URL_STAGING // NB: currently only supporting production & staging variations of the bookings site/server
    }
  }
  return (
    <div className={styles.inactiveOrg}>
      {/* TODO: add an appropriate icon, title & message here.. */}
      {!isDemo && (
        <ArkInfoView
          iconName='denied'
          title={'inactive ' + OBJECT_COMPANY_NAME}
          message={
            <>
              <p>...</p>
            </>
          }
          messageClassName={styles.message}
        />
      )}
      {isDemo && (
        <ArkInfoView
          iconName='denied'
          title={'demo ' + OBJECT_COMPANY_NAME}
          message={
            <>
              <p>This is a demo {OBJECT_COMPANY_NAME} that is not currently scheduled to be accessible.</p>
              <p>Please check your demo bookings <a href={demoBookingsURL} target="_blank" rel="noreferrer">here</a> or contact support on <a className={styles.email} href="mailto:support@reprostream.com">support@reprostream.com</a></p>
            </>
          }
          messageClassName={styles.message}
        />
      )}
    </div>
  )
}

export default ArkInactiveOrgView
