import React, { useContext } from 'react'

import { CompanyAdminContext } from 'src/core/providers'

import { Company, CompanyUser } from 'src/core/models'

import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { OBJECT_COMPANY_NAME, OBJECT_USER_NAME } from 'src/constants/strings'

export type CompanyUserDeleteCallback = (user: CompanyUser) => void

interface IProps {
  company: Company
  user: CompanyUser
  onDelete?: CompanyUserDeleteCallback
  className?: string
}

const CompanyUserDeleteView = (props: IProps) => {
  const { company, user, className } = props
  const companyAdminContext = useContext(CompanyAdminContext)

  const onDelete = async (_itemId: number): Promise<boolean> => {
    // NB: DON'T catch the error/exception here, let it throw (or if you do, rethrow it so the calling code can catch & display it)
    const deleteResult = await companyAdminContext.actions.removeUserFromCompany(company.id, user.id)
    console.log('CompanyUserDeleteView - onDelete - removeUserFromProject - deleteResult: ', deleteResult)
    return deleteResult
  }

  if (!user) return (<></>)
  const companyName = company.name ?? 'this company'
  return (
    <div className={(className ? ' ' + className : '')}>
      <ArkSpacer />
      <ArkManagerDeleteButton
        itemId={user.id}
        itemName={user.name()}
        itemTypeName={OBJECT_USER_NAME}
        buttonTitle={'REMOVE ' + OBJECT_USER_NAME + ' FROM ' + OBJECT_COMPANY_NAME}
        deleteWord='Remove'
        deletedWord='Removed'
        confirmMsgPost={<>from <strong>{companyName}</strong></>}
        onDelete={onDelete}
        onDeleteComplete={() => { if (props.onDelete) props.onDelete(user) }}
      />
    </div>
  )
}

export default CompanyUserDeleteView
