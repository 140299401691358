/**
 * mock data
 */

import { ArkAvatarType } from 'src/core/components/ArkAvatar'
import { NodeGraphData } from './types'

const MOCK_DATA: NodeGraphData = {
  programs: [
    { id: 1, colour: '#FF2600', name: 'MU A Cam', online: 'online', shortName: 'MUA' },
    { id: 2, colour: '#0693E3', name: 'MU B Cam', online: 'online', shortName: 'MUB' },
    { id: 3, colour: '#FCB900', name: 'MU C Cam', online: 'online', shortName: 'MUC' },
    { id: 4, colour: '#00D084', name: 'MU D Cam', online: 'offline', shortName: 'MUD' },
    { id: 5, colour: '#FF2600', name: '2U A Cam', online: 'online', shortName: '2UA' },
    { id: 6, colour: '#0693E3', name: '2U B Cam', online: 'online', shortName: '2UB' },
    { id: 7, colour: '#FCB900', name: '2U C Cam', online: 'online', shortName: '2UC' },
    { id: 8, colour: '#00D084', name: '2U D Cam', online: 'online', shortName: '2UD' },
    { id: 9, colour: '#FF2600', name: 'Splinter A Cam', online: 'online', shortName: 'SUA' },
    { id: 10, colour: '#0693E3', name: 'Splinter B Cam', online: 'online', shortName: 'SUB' }
  ],
  channels: [
    { id: 1, colour: '#EB144C', name: 'Main Unit', programs: [1, 2, 3, 4] },
    { id: 2, colour: '#9900EF', name: '2nd Unit', programs: [5, 6, 7, 8] },
    { id: 3, colour: '#8ED1FC', name: 'Splinter Unit', programs: [9, 10] }
  ],
  groups: [
    { id: 1, channels: [], disabled: false, mirrored: false, name: 'All Project Users', users: [] },
    { id: 2, channels: [1], disabled: false, mirrored: false, name: 'Main Unit', users: [2] },
    { id: 3, channels: [2], disabled: false, mirrored: false, name: '2nd Unit', users: [2, 3] },
    { id: 4, channels: [3], disabled: false, mirrored: false, name: 'Splinter Unit', users: [4] },
    { id: 5, channels: [1, 2, 3], disabled: false, mirrored: false, name: 'Exec All Access', users: [1] }
  ],
  users: [
    { id: 1, avatarType: ArkAvatarType.user, guest: false, name: 'James Metcalfe' },
    { id: 2, avatarType: ArkAvatarType.user, guest: false, name: 'Joe Morsman' },
    { id: 3, avatarType: ArkAvatarType.user, guest: false, name: 'Phil Menni' },
    { id: 4, avatarType: ArkAvatarType.user, guest: false, name: 'James Grant' }
  ]
}

export default MOCK_DATA
