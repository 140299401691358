import React, { useEffect, useRef } from 'react'

import { IVideoEngineLiveStream } from '../../models/StreamhubVideoEngineModels'

import StreamhubVideoEngineLiveStreamsFilterForm, { StreamhubVideoEngineLiveStreamsFilterFormPublisherType, StreamhubVideoEngineLiveStreamsFilterFormStatus, StreamhubVideoEngineLiveStreamsFilterValues } from './StreamhubVideoEngineLiveStreamsFilterForm'
import StreamhubVideoEngineLiveStreamsTable from './StreamhubVideoEngineLiveStreamsTable'

import { STREAMHUB_SERVERS } from '../../config/config'

import ArkMessage from 'src/core/components/ArkMessage'

interface IProps {
  liveStreams?: Array<IVideoEngineLiveStream>
  error?: Error
}

const StreamhubVideoEngineLiveStreamsView = (props: IProps) => {
  const mounted = useRef(false)

  const { liveStreams, error } = props

  // const videoEngineContext = useContext(StreamhubVideoEngineContext)

  // NB: now defaulting to 'online' streams with the 'MPEGTS' protocol (so we skip the transcoded ABR variations & just look for the incoming/source passthroughs by default)
  const _defaultFilterValues: StreamhubVideoEngineLiveStreamsFilterValues = {
    status: StreamhubVideoEngineLiveStreamsFilterFormStatus.online,
    protocol: 'MPEGTS'
  }
  const [filterValues, setFilterValues] = React.useState<StreamhubVideoEngineLiveStreamsFilterValues>(_defaultFilterValues)

  // -------

  useEffect(() => {
    console.log('StreamhubVideoEngineLiveStreamsView - MOUNT')
    mounted.current = true
    return () => {
      console.log('StreamhubVideoEngineLiveStreamsView - UNMOUNT')
      mounted.current = false
    }
  }, [])

  // -------

  const isFilterActive = () => {
    if (filterValues.application !== undefined) return true
    if (filterValues.stream !== undefined) return true
    if (filterValues.protocol !== undefined) return true
    if (filterValues.status !== undefined && filterValues.status !== 0) return true
    if (filterValues.publisherType !== undefined && filterValues.publisherType !== 0) return true
    return false
  }

  const filterLiveStreams = (streams: Array<IVideoEngineLiveStream>) => {
    if (!isFilterActive()) return streams
    const filteredStreams: Array<IVideoEngineLiveStream> = []
    for (const stream of streams) {
      const applicationOk = (filterValues.application !== undefined ? (stream.application !== undefined && stream.application.toLowerCase().indexOf(filterValues.application.toLowerCase()) >= 0) : true)
      const streamOk = (filterValues.stream !== undefined ? (stream.stream !== undefined && stream.stream.toLowerCase().indexOf(filterValues.stream.toLowerCase()) >= 0) : true)
      const protocolOk = (filterValues.protocol !== undefined ? (stream.protocol !== undefined && stream.protocol.toLowerCase().indexOf(filterValues.protocol.toLowerCase()) >= 0) : true)
      const statusOk = (filterValues.status !== undefined
        ? (filterValues.status === StreamhubVideoEngineLiveStreamsFilterFormStatus.online
          ? (stream.status === 'online')
          : (filterValues.status === StreamhubVideoEngineLiveStreamsFilterFormStatus.offline
            ? (stream.status === 'offline')
            : true))
        : true)
      const streamhubServerLocalIPs = STREAMHUB_SERVERS.map((server) => server.localIP)
      const publisherTypeOk = (filterValues.publisherType !== undefined
        ? (filterValues.publisherType === StreamhubVideoEngineLiveStreamsFilterFormPublisherType.isStreamhub
          ? stream.publisher_ip && (streamhubServerLocalIPs.includes(stream.publisher_ip))
          : (filterValues.publisherType === StreamhubVideoEngineLiveStreamsFilterFormPublisherType.isExternal
            ? (stream.publisher_ip === undefined || !(streamhubServerLocalIPs.includes(stream.publisher_ip)))
            : true))
        : true)
      if (applicationOk && streamOk && protocolOk && statusOk && publisherTypeOk) {
        filteredStreams.push(stream)
      }
    }
    return filteredStreams
  }

  const renderStreamsSummary = (currentStreams?: Array<IVideoEngineLiveStream>, allStreams?: Array<IVideoEngineLiveStream>, isFilterActive: boolean = false) => {
    const currentStreamsCount = currentStreams && currentStreams.length > 0 ? currentStreams.length : 0
    const allStreamsCount = allStreams && allStreams.length > 0 ? allStreams.length : 0
    const currentActiveStreamsCount = currentStreams ? currentStreams.filter((s) => s.status === 'online').length : 0
    const allActiveStreamsCount = allStreams ? allStreams.filter((s) => s.status === 'online').length : 0
    return (
      <>
        Showing: {isFilterActive ? <>{currentStreamsCount} / </> : null}{allStreamsCount} live stream{allStreamsCount !== 1 ? 's' : ''}
        &nbsp;({isFilterActive ? <>{currentActiveStreamsCount} / </> : null}{allActiveStreamsCount} online)
      </>
    )
  }

  // -------

  const _isFilterActive = isFilterActive()
  const _allStreams = liveStreams
  const _currentStreams = _allStreams && _isFilterActive ? filterLiveStreams(_allStreams) : _allStreams
  // const streamhubServerLocalIPs = STREAMHUB_SERVERS.map((server) => server.localIP)

  return (
    <div>
      <h4>Live Streams</h4>
      {error && (
        <ArkMessage negative>
          <ArkMessage.Header>Error</ArkMessage.Header>
          <p>{error.message}</p>
        </ArkMessage>
      )}
      {liveStreams && (
        <>
          <StreamhubVideoEngineLiveStreamsFilterForm
            filterValues={filterValues}
            onFilterChange={(fieldKey: string, value?: string | number | Array<string>) => {
              console.log('StreamhubVideoEngineLiveStreamsView - onFilterChange - fieldKey:', fieldKey, ' value:', value)
              const prevFilterValues = filterValues
              const newFilterValues: StreamhubVideoEngineLiveStreamsFilterValues = { ...prevFilterValues }
              switch (fieldKey) {
                case 'application': if (newFilterValues.application === undefined || typeof newFilterValues.application === 'string') { newFilterValues.application = value as string } break
                case 'stream': if (newFilterValues.stream === undefined || typeof newFilterValues.stream === 'string') { newFilterValues.stream = value as string } break
                case 'protocol': if (newFilterValues.protocol === undefined || typeof newFilterValues.protocol === 'string') { newFilterValues.protocol = value as string } break
                case 'status': if (newFilterValues.status === undefined || typeof newFilterValues.status === 'number') { newFilterValues.status = value as number } break
                case 'publisherType': if (newFilterValues.publisherType === undefined || typeof newFilterValues.publisherType === 'number') { newFilterValues.publisherType = value as number } break
              }
              console.log('StreamhubVideoEngineLiveStreamsView - onFilterChange - newFilterValues:', newFilterValues)
              setFilterValues(newFilterValues)
            }}
            onFilterClear={() => {
              console.log('StreamhubVideoEngineLiveStreamsView - onFilterClear')
              const newFilterValues = { ..._defaultFilterValues }
              setFilterValues(newFilterValues)
            }}
          />
          <br />
          <div>{renderStreamsSummary(_currentStreams, _allStreams, _isFilterActive)}</div>
          <StreamhubVideoEngineLiveStreamsTable
            liveStreams={_currentStreams}
          />
          {/* <ul>
            {_currentStreams.map((liveStream, index) => {
              return (
                <li key={'live_stream_' + index}>
                  <div>id: {liveStream.id}</div>
                  <div>application: {liveStream.application}</div>
                  <div>stream: {liveStream.stream}</div>
                  <div>status: {liveStream.status}</div>
                  <div>description: {liveStream.description}</div>
                  <div>protocol: {liveStream.protocol}</div>
                  <div>video_codec: {liveStream.video_codec}</div>
                  <div>audio_codec: {liveStream.audio_codec}</div>
                  <div>bandwidth: {liveStream.bandwidth}</div>
                  <div>resolution: {liveStream.resolution}</div>
                  <div>publisher_ip: {liveStream.publisher_ip ?? '-'}{(liveStream.publisher_ip && streamhubServerLocalIPs.includes(liveStream.publisher_ip)) ? ' (streamhub)' : ''}</div>
                  <div>publish_time: {liveStream.publish_time}</div>
                  <div>tags: {JSON.stringify(liveStream.tags)}</div>
                  <div>data: {JSON.stringify(liveStream.data)}</div>
                </li>
              )
            })}
          </ul> */}
        </>
      )}
    </div>
  )
}

export default StreamhubVideoEngineLiveStreamsView
