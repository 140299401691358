import React from 'react'
import _ from 'lodash'

import { OBJECT_CHANNEL_NAME_PLURAL } from 'src/constants/strings'
import { ArkManagerFilteredItem } from 'src/core/components/ArkManagerListView/ArkManagerListView'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { Channel, Notice, NoticeConditions } from 'src/core/models'
import { decodeNoticeConditions } from 'src/core/utilities/notice'
import { getOnlineStatusText } from 'src/core/utilities/status'

export const getFilteredNotices = (notices: Notice[], filter: string): ArkManagerFilteredItem<Notice>[] => {
  if (!filter) return []
  return _.chain(notices)
    .map(card => {
      const matchingFields: string[] = []
      if (card.name.toLowerCase().includes(filter.toLowerCase())) matchingFields.push('name')
      if (card.description.toLowerCase().includes(filter.toLowerCase())) matchingFields.push('description')
      return _.some(matchingFields) && { item: card, matchingFields }
    })
    .compact()
    .value()
}

export const getNoticeChannelsText = (notice: Notice, channels?: Channel[]): JSX.Element | string => {
  if (notice.all_channels) return `All ${OBJECT_CHANNEL_NAME_PLURAL.toLowerCase()}`
  if (_.some(notice.channels)) {
    return (
      <div>
        <div>{`${OBJECT_CHANNEL_NAME_PLURAL}:`}</div>
        {_.map(notice.channels, id => {
          const channel: Channel | undefined = _.find(channels, { id })
          return <div key={id}>{`• ${channel ? channel.name : id}`}</div>
        })}
      </div>
    )
  }
  return `No ${OBJECT_CHANNEL_NAME_PLURAL}`
}

export const getNoticeConditionsText = (notice: Notice): JSX.Element[] | string => {
  if (!notice.conditions) return 'No conditions'
  const conditions: NoticeConditions = decodeNoticeConditions(notice.conditions)
  const elements: JSX.Element[] = []

  // date range
  if (conditions.dateRange) {
    elements.push((
      <div key='dateRange'>
        <div>Date & time range:</div>
        <div>{`${new Date(conditions.dateRange.start).toUTCString().replace(':00 GMT', ' UTC')} >`}</div>
        <div>{new Date(conditions.dateRange.end).toUTCString().replace(':00 GMT', ' UTC')}</div>
      </div>
    ))
  }

  // online status
  if (conditions.onlineStatuses) {
    elements.push(
      <div key='onlineStatuses'>
        {conditions.dateRange && <ArkSpacer />}
        <div>Online status:</div>
        {_.map(conditions.onlineStatuses, onlineStatus =>
          <div key={onlineStatus}>• {getOnlineStatusText(onlineStatus)}</div>
        )}
      </div>
    )
  }

  return elements
}

export const getNoticeDismissibleText = (notice: Notice) : string => {
  if (notice.dismissible) return 'Yes'
  if (notice.dismissible_admin) return 'Admins'
  return 'No'
}
