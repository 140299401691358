import { BaseModel } from './base_model'

export class ProjectCounts extends BaseModel {
  channelsCount: number
  groupsCount: number
  programsCount: number

  constructor (
    channelsCount: number,
    groupsCount: number,
    programsCount: number
  ) {
    super()
    this.channelsCount = channelsCount
    this.groupsCount = groupsCount
    this.programsCount = programsCount
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : ProjectCounts | null {
    if (!json) return null
    // TESTING: if none of the count fields are present in the json data return null
    const propertyToJSONKeyMap = ProjectCounts.propertyToJSONKeyMap()
    let fieldCount = 0
    for (const jsonFieldName of Object.values(propertyToJSONKeyMap)) {
      // console.log('ProjectCounts - fromJSON - jsonFieldName: ', jsonFieldName, '=', json[jsonFieldName])
      if (json[jsonFieldName] !== undefined) fieldCount++
    }
    // console.log('ProjectCounts - fromJSON - fieldCount: ', fieldCount)
    if (fieldCount === 0) return null
    return new ProjectCounts(
      json.channels_count,
      json.groups_count,
      json.programs_count
      // TODO: project_manager_count
    )
  }

  // TESTING: maps our class property keys to their json equivalent
  static propertyToJSONKeyMap () {
    return {
      channelsCount: 'channels_count',
      groupsCount: 'groups_count',
      programsCount: 'programs_count'
      // TODO: project_manager_count
    }
  }
}
