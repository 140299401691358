import { BaseModel } from './base_model'

export class CompanyCounts extends BaseModel {
  usersActiveCount: number
  usersPendingCount: number
  usersInvitedCount: number
  projectsCount: number
  programsCount: number

  constructor (
    usersActiveCount: number,
    usersPendingCount: number,
    usersInvitedCount: number,
    projectsCount: number,
    programsCount: number
  ) {
    super()
    this.usersActiveCount = usersActiveCount
    this.usersPendingCount = usersPendingCount
    this.usersInvitedCount = usersInvitedCount
    this.projectsCount = projectsCount
    this.programsCount = programsCount
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : CompanyCounts | null {
    if (!json) return null
    // TESTING: if none of the count fields are present in the json data return null
    const propertyToJSONKeyMap = CompanyCounts.propertyToJSONKeyMap()
    let fieldCount = 0
    for (const jsonFieldName of Object.values(propertyToJSONKeyMap)) {
      // console.log('CompanyCounts - fromJSON - jsonFieldName: ', jsonFieldName, '=', json[jsonFieldName])
      if (json[jsonFieldName] !== undefined) fieldCount++
    }
    // console.log('CompanyCounts - fromJSON - fieldCount: ', fieldCount)
    if (fieldCount === 0) return null
    return new CompanyCounts(
      json.users_active_count,
      json.users_pending_count,
      json.users_invited_count,
      json.projects_count,
      json.programs_count
    )
  }

  // TESTING: maps our class property keys to their json equivalent
  static propertyToJSONKeyMap () {
    return {
      usersActiveCount: 'users_active_count',
      usersPendingCount: 'users_pending_count',
      usersInvitedCount: 'users_invited_count',
      projectsCount: 'projects_count',
      programsCount: 'programs_count'
    }
  }
}
