import React, { ReactNode, useEffect, useState } from 'react'
import useResizeObserver from 'use-resize-observer'

import styles from './ArkAspectRatio.module.css'

export interface ArkAspectRatioProps {
  children: ReactNode
  ratio?: number
}

const ArkAspectRatio = (props: ArkAspectRatioProps) => {
  const { ratio = 16 / 9 } = props

  const [bodySize, setBodySize] = useState<{ height: number, width: number }>({ height: 0, width: 0 })

  const {
    height: containerHeight = 0,
    ref: containerRef,
    width: containerWidth = 0
  } = useResizeObserver<HTMLDivElement>()

  useEffect(() => {
    const horizontalConstraint: boolean = containerHeight * ratio > containerWidth
    setBodySize({
      height: horizontalConstraint ? containerWidth / ratio : containerHeight,
      width: horizontalConstraint ? containerWidth : containerHeight * ratio
    })
  }, [containerHeight, containerWidth])

  const show: boolean = bodySize.height > 0 && bodySize.width > 0

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.body} style={{ height: bodySize.height, width: bodySize.width }}>
        {show && props.children}
      </div>
    </div>
  )
}

export default ArkAspectRatio
