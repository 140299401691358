import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

import * as ROUTES from 'src/constants/routes'
import {
  OBJECT_CHANNEL_NAME,
  OBJECT_CHANNEL_NAME_PLURAL,
  OBJECT_PROGRAM_NAME_PLURAL,
  OBJECT_PROJECT_NAME,
  ROLE_ADMIN_NAME,
  ROLE_MANAGER_NAME,
  SECTION_MANAGER_SUFFIX_NAME
} from 'src/constants/strings'
import ArkInfoView from 'src/core/components/ArkInfoView'
import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkProjectStatusBanner from 'src/core/components/ArkProjectStatusBanner'

import { useUser } from 'src/core/providers/UserProvider'
import { ChannelState, useViewer } from '../../providers/ViewerProvider'
import ProgramsView from '../ProgramsView/ProgramsView'
import ChannelHeader from './ChannelHeader'
import ChannelNotices from './ChannelNotices'

import styles from './ChannelView.module.css'

const ChannelView = () => {
  const user = useUser()
  const viewer = useViewer()

  const channelRef = useRef<HTMLDivElement>(null)

  const onFullscreenClick = () => {
    // console.log('ChannelView - onFullscreenClick')
    try {
      if (viewer.fullscreen) document.exitFullscreen()
      else channelRef.current!.requestFullscreen()
    } catch (error) {
      console.error('ChannelView - onFullscreenClick - error:', error)
    }
  }

  const admin: boolean = user.actions.isAdmin()
  const adminRoute: string = viewer.project?.id
    ? ROUTES.PROJECT_MANAGER_CHANNELS.replace(':projectId', viewer.project.id.toString())
    : '#'
  const channelState: ChannelState = viewer.getChannelState()

  const Loading = () => <ArkLoaderView message={`Loading ${OBJECT_CHANNEL_NAME}`.toUpperCase()} />

  const NoChannels = () => (
    <ArkInfoView
      iconName='warning'
      title={`No ${OBJECT_CHANNEL_NAME_PLURAL} available`.toUpperCase()}
      message={(
        <>
          <div>This {OBJECT_PROJECT_NAME} might need further setup or you do not have the required access.</div>
          {admin
            ? <div>Add {OBJECT_CHANNEL_NAME_PLURAL} and {OBJECT_PROGRAM_NAME_PLURAL} in the <Link to={adminRoute}>{OBJECT_PROJECT_NAME} {SECTION_MANAGER_SUFFIX_NAME}</Link>.</div>
            : <div>Please contact an {ROLE_ADMIN_NAME} or {ROLE_MANAGER_NAME} role to remedy this.</div>}
        </>
      )}
    />
  )

  const SelectChannel = () => (
    <ArkInfoView
      iconName='warning'
      title={`No ${OBJECT_CHANNEL_NAME} selected`.toUpperCase()}
      message={`Select a ${OBJECT_CHANNEL_NAME} from the menu above.`}
    />
  )

  const NoPrograms = () => (
    <ArkInfoView
      iconName='warning'
      title={`No ${OBJECT_PROGRAM_NAME_PLURAL} available`.toUpperCase()}
      message={(
        <>
          <div>This {OBJECT_CHANNEL_NAME} might need further setup or you do not have the required access.</div>
          {admin
            ? <div>Add {OBJECT_PROGRAM_NAME_PLURAL} in the <Link to={adminRoute}>{OBJECT_PROJECT_NAME} {SECTION_MANAGER_SUFFIX_NAME}</Link>.</div>
            : <div>Please contact an {ROLE_ADMIN_NAME} or {ROLE_MANAGER_NAME} role to remedy this.</div>}
        </>
      )}
    />
  )

  return (
    <div className={styles.channel} ref={channelRef}>
      <ArkProjectStatusBanner slimMargin={true} />
      <ChannelHeader onFullscreenClick={onFullscreenClick} />
      {channelState === ChannelState.Loading && <Loading />}
      {channelState === ChannelState.NoChannels && <NoChannels />}
      {channelState === ChannelState.NoChannel && <SelectChannel />}
      {channelState === ChannelState.Notices && <ChannelNotices />}
      {channelState === ChannelState.NoPrograms && <NoPrograms />}
      {channelState === ChannelState.Programs && <ProgramsView />}
    </div>
  )
}

export default ChannelView
