import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

import { ProjectAdminContext } from 'src/core/providers'

import { ProjectUser } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { PROJECT_GUEST_SHOW_DESC } from 'src/constants/config'
import { OBJECT_GUEST_NAME, OBJECT_PROJECT_NAME, OBJECT_USER_NAME } from 'src/constants/strings'

const formSchemaAdd = yup.object().shape({
  guestName: yup.string().optional().min(0).max(255),
  guestDesc: yup.string().optional().min(0).max(255)
})
const formSchemaEdit = yup.object().shape({
  guestName: yup.string().required().min(1).max(255), // when editing make sure a name is always set when submitting (even if its the default auto assigned one)
  guestDesc: yup.string().optional().min(0).max(255)
})
// NB: looked at using yup `.when` handling to vary the form field validation depending on if a name was specified or not,
// NB: but its got a few quirks (which were mostly worked around in the code below), but would also really need to be made add/edit form type aware too
// NB: so commented this out (& left in for future ref for other forms that might find it useful) & went for 2 schemas, 1 for each add/edit mode instead
// UPDATE: if looking to (re)use this - it will now also need updating for yup v1.0+ support - refs: https://github.com/jquense/yup/issues/1906 / https://github.com/jquense/yup/pull/1542
// const formSchema = yup.object().shape({
//   // guestName: yup.string().optional().min(1).max(255),
//   guestName: yup.string().when('guestName', (value) => {
//     if (value) {
//       return yup.string().optional().min(1).max(255)
//     } else {
//       return yup.string().optional().min(0).max(255)
//     }
//   }),
//   guestDesc: yup.string().optional().min(0).max(255)
// }, [['guestName', 'guestName']])

export enum ProjectGuestFormMode {
  Add = 'add',
  Edit = 'edit',
}

interface ProjectGuestFormProps {
  companyId: number
  projectId: number
  projectUsers: Array<ProjectUser>
  user?: ProjectUser // if editing the guest
  onCancel?: Function
  onSave?: Function
  onClose?: Function
  insideModal?: boolean // ArkForm prop - enable when showing this form within a modal (so fieldset label bg's match)
}

const ProjectGuestForm = (props: ProjectGuestFormProps) => {
  const mounted = useRef(false)
  const { user, onCancel, onSave, onClose, insideModal } = props

  const projectAdminContext = useContext(ProjectAdminContext)

  const mode = user ? ProjectGuestFormMode.Edit : ProjectGuestFormMode.Add
  const isEditMode = mode === ProjectGuestFormMode.Edit
  const formSchema = isEditMode ? formSchemaEdit : formSchemaAdd

  // const [loading, setLoading] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSaved, setHasSaved] = useState<boolean>(false)
  const [savedGuestName, setSavedGuestName] = useState<string | undefined>()
  const [error, setError] = useState<Error | undefined>()

  // -------

  useEffect(() => {
    console.log('ProjectGuestForm - MOUNTED')
    mounted.current = true
    return () => {
      mounted.current = false
      console.log('ProjectGuestForm - UN-MOUNTED')
    }
  }, [])

  // -------

  const addGuest = async (guestName?: string, guestDesc?: string) => {
    const { companyId, projectId } = props
    setIsSubmitting(true)
    setHasSaved(false)
    setSavedGuestName(undefined)
    setError(undefined)
    try {
      await new Promise(resolve => setTimeout(resolve, 500)) // add a brief delay, so the user see's the loading indicator on quick api calls
      const newGuestUser = await projectAdminContext.actions.addSharedGuestUserToProject(companyId, projectId, { name: guestName, desc: guestDesc })
      console.log('ProjectGuestForm - addGuest - newGuestUser: ', newGuestUser)
      // throw a fallback error if the result doesn't include the new guest users details & it didn't trigger an exception for some reason (shouldn't happen under normal usage, but covering it just incase)
      if (!newGuestUser) throw new Error('Failed to add guest')
      if (mounted.current) {
        setIsSubmitting(false)
        setHasSaved(true)
        if (newGuestUser && newGuestUser.firstName) setSavedGuestName(newGuestUser.name())
      }
      if (onSave) onSave()
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  // allow the guest name fields (used as name & desc) to be updated
  const updateGuest = async (userId: number, guestName?: string, guestDesc?: string) => {
    const { companyId, projectId } = props
    console.log('ProjectGuestForm - updateGuest - userId: ', userId, ' guestName:', guestName, ' guestDesc:', guestDesc)
    setIsSubmitting(true)
    setHasSaved(false)
    setSavedGuestName(undefined)
    setError(undefined)
    try {
      await new Promise(resolve => setTimeout(resolve, 500)) // add a brief delay, so the user see's the loading indicator on quick api calls
      const updatedGuestUser = await projectAdminContext.actions.updateSharedGuestUser(companyId, projectId, userId, guestName, guestDesc)
      console.log('ProjectGuestForm - updateGuest - updatedGuestUser: ', updatedGuestUser)
      //  throw a fallback error if the result doesn't include the updated guest users details & it didn't trigger an exception for some reason
      if (!updatedGuestUser) throw new Error('Failed to edit guest')
      if (mounted.current) {
        setIsSubmitting(false)
        setHasSaved(true)
        if (updatedGuestUser && updatedGuestUser.firstName) setSavedGuestName(updatedGuestUser.name())
      }
      if (onSave) onSave()
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('ProjectGuestForm - onFormSubmit - fieldValues: ', fieldValues)
    const { guestName, guestDesc } = fieldValues
    if (mode === ProjectGuestFormMode.Add) {
      await addGuest(guestName, guestDesc)
    } else if (mode === ProjectGuestFormMode.Edit && props.user) {
      await updateGuest(props.user.id, guestName, guestDesc)
    }
  }

  const _onCancel = () => {
    if (onCancel) onCancel()
  }

  const _onClose = () => {
    if (onClose) onClose()
  }

  const formFields: Array<ArkFormField> = []
  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'guestFieldset',
    // label: OBJECT_GUEST_NAME + ' ' + 'Name (Optional)',
    fields: [
      {
        type: ArkFormFieldType.Field,
        key: 'guestNotice',
        content: (
          (<>
            <p>Guest links can be shared with multiple people to grant them short term access to view channel programs without creating their own accounts.</p>
            <p>You can manage guest links like normal project members &amp; assign them to different groups to control which channels & programs they have access to.</p>
            <p>Create different guest links if you want different groups of guests to have access to different channels.</p>
            <hr />
          </>)
        )
      },
      {
        type: ArkFormFieldType.Input,
        key: 'guestName',
        label: 'Guest Name/Alias' + (!isEditMode ? ' (Optional)' : ''),
        required: isEditMode,
        description: (!isEditMode ? 'Note: if no name is given a random one will be generated' : undefined),
        defaultValue: (isEditMode && user ? user.firstName ?? '' : ''),
        placeholder: (isEditMode ? user?.firstName : undefined),
        // fieldProps: { style: { maxWidth: '50%', minWidth: 200 } },
        wrapperProps: { style: { marginBottom: '8px' } } // NB: current default vertical spacing/margin within a fieldset (but no group?) seems to be 0, temp forcing to add a little space between feeds
      },
      ...(PROJECT_GUEST_SHOW_DESC
        ? [
          {
            type: ArkFormFieldType.Input,
            key: 'guestDesc',
            label: 'Description (Optional)',
            required: false,
            defaultValue: (isEditMode && user ? user.lastName ?? '' : '')
          }
        ]
        : []
      )
    ],
    // fieldProps: { widths: 2, inline: false, grouped: false },
    collapsible: false,
    collapsed: false
  })
  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { onClick: _onCancel, floated: 'left' } },
      { type: ArkFormFieldType.OKButton, key: 'submit', label: (mode === ProjectGuestFormMode.Edit ? 'SAVE' : 'ADD'), fieldProps: { loading: isSubmitting, floated: 'right' } }
    ],
    fieldProps: { widths: 'equal' }
  })

  return (
    <>
      <ArkHeader as="h2" inverted>
        {(mode === ProjectGuestFormMode.Edit ? 'Edit' : 'Add')} {OBJECT_GUEST_NAME} to {OBJECT_PROJECT_NAME}
      </ArkHeader>

      {hasSaved && (<>
        <ArkMessage positive>
          <ArkMessage.Header>{OBJECT_GUEST_NAME} {isEditMode ? <>Updated</> : <>Added</>}</ArkMessage.Header>
          <ArkMessage.Item>
            {isEditMode
              ? <>Updated {OBJECT_GUEST_NAME} {OBJECT_USER_NAME} {savedGuestName ? <><strong>{savedGuestName}</strong> </> : ''}successfully</>
              : <>A {OBJECT_GUEST_NAME} {OBJECT_USER_NAME} {savedGuestName ? <>named <strong>{savedGuestName}</strong> </> : ''}has been added successfully</>
            }
          </ArkMessage.Item>
        </ArkMessage>
        <ArkSpacer />
        <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={_onClose}>
          OK
        </ArkButton>
      </>)}

      {!hasSaved && (
        <ArkForm
          formKey="guestInvite"
          inverted
          formError={error}
          formFields={formFields}
          formSchema={formSchema}
          onFormSubmit={onFormSubmit}
          showLabels={true}
          insideModal={insideModal}
        >
        </ArkForm>
      )}
    </>
  )
}

export default ProjectGuestForm
