import React, { useContext, useEffect } from 'react'

import { UserContext } from 'src/core/providers'

import ArkProjectManagerPage from 'src/manager/project/components/ArkProjectManagerPage/ArkProjectManagerPage'
import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkProjectStatusBanner from 'src/core/components/ArkProjectStatusBanner'

import { ProjectSettingsView } from './ProjectSettingsView'

import { OBJECT_PROJECT_NAME, SECTION_MANAGER_SUFFIX_NAME, PAGE_SETTINGS_NAME } from 'src/constants/strings'
import * as ROUTES from 'src/constants/routes'

export interface ProjectSettingsPageProps {}

const ProjectSettingsPage = (_props: ProjectSettingsPageProps) => {
  const userContext = useContext(UserContext)

  const company = userContext.store.selectedCompany
  const project = userContext.store.selectedProject

  useEffect(() => {
    console.log('ProjectSettingsPage - useEffect - userContext.store.selectedProject: ', userContext.store.selectedProject)
  }, [userContext.store.selectedProject])

  const renderContent = () => {
    if (!company || !project) return null
    return (
      <ArkManagerContentView
        title={PAGE_SETTINGS_NAME}
        breadcrumb={[{
          path: ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_VIEW, project.id),
          title: ROUTES.formatBreadcrumbRootTitle(project.name, `${OBJECT_PROJECT_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`)
        }]}
      >
        <ArkProjectStatusBanner />
        <ProjectSettingsView companyId={company.id} selectedProject={project} />
      </ArkManagerContentView>
    )
  }

  return (
    <ArkProjectManagerPage>
      {renderContent()}
    </ArkProjectManagerPage>
  )
}
export default ProjectSettingsPage
