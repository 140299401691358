import React from 'react'

import { withUserContext, IUserMultiContext } from 'src/core/providers'

import ArkProjectManagerPage from 'src/manager/project/components/ArkProjectManagerPage/ArkProjectManagerPage'
import ArkCompanySelectView from 'src/core/components/ArkCompanySelectView'

interface IProps extends IUserMultiContext {}
interface IState {}

class ProjectCompaniesSelectPage extends React.Component<IProps, IState> {
  render () {
    return (
      <ArkProjectManagerPage disableSectionMainMenu>
        <ArkCompanySelectView />
      </ArkProjectManagerPage>
    )
  }
}

export default withUserContext(ProjectCompaniesSelectPage)
