import React, { CSSProperties } from 'react'

import ArkButton, { ArkButtonProps } from '../ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps /*, ArkFormFieldPlaceholder */ } from '../ArkForm/ArkForm'
import ArkModal from '../ArkModal'

import { Message, Header, SemanticSIZES } from 'semantic-ui-react'

export type ItemDeleteCallback = (itemId: number) => Promise<boolean>
export type ItemDeleteCompleteCallback = (itemId: number) => void
export type ItemDeleteAllowedCallback = (itemId: number) => boolean

interface IProps {
  itemId: number
  itemName: string
  itemTypeName: string // User/Channel/Group/Program - NB: supply this capitalised so it can be used in a few places (& is auto lowercased when needed)
  itemNamePrefix?: string // if you want to add a prefix word before the name e.g. 'for' = '<action> for <name>'
  buttonTitle: string
  deleteWord?: string // if you want to override the 'Delete' wording in the form specify this arg e.g. 'Remove' (same as itemTypeName capitalise it so it shows ok in the right places)
  deletedWord?: string // also specify this when overriding deleteWord to show the correct wording in the success message e.g 'Removed'
  confirmMsgPost?: string | React.ReactNode // optionally add text after the confirm message text (& before the '?' at the end) e.g. 'from this company'
  confirmOkButtonProps?: ArkButtonProps // additional button props for the ok button within the confirm modal
  confirmTextInput?: string // requires the user to type this text to confirm the delete (e.g. 'DELETE' or 'REMOVE', or the item name/title)
  confirmTextMsg?: string // title/msg shown if the confirmTextInput is required
  fluid?: boolean
  size?: SemanticSIZES
  disabled?: boolean
  style?: CSSProperties
  buttonProps?: ArkButtonProps // additional button props for the initial delete/action button (before the modal is shown)
  buttonStyle?: CSSProperties // additional styles for the initial delete/action button (before the modal is shown)
  onDelete: ItemDeleteCallback // NB: throw an Error to have it displayed in an error message
  onDeleteComplete?: ItemDeleteCompleteCallback
  onDeleteAllowed?: ItemDeleteAllowedCallback
  notAllowedTitle?: string // if onDeleteAllowed is implemented & returns false, this will be shown (or a generic fallback msg otherwise)
  notAllowedMsg?: string
}
interface IState {
  showConfirmDelete: boolean
  isSubmitting: boolean
  hasDeleted: boolean
  error?: Error
  confirmTextError?: Error
  confirmTextOk: boolean
  showDeleteNotAllowed: boolean
}

class ArkManagerDeleteButton extends React.Component<IProps & { className?: string }, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      showConfirmDelete: false,
      isSubmitting: false,
      hasDeleted: false,
      confirmTextOk: false,
      showDeleteNotAllowed: false
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { buttonTitle, className, fluid, size, disabled, style, buttonStyle, buttonProps } = this.props
    const hasColorButtonProp = buttonProps !== undefined && buttonProps.color !== undefined
    return (
      <div className={(className ? ' ' + className : undefined)} style={style}>
        <ArkButton type="button" negative={!hasColorButtonProp} basic fluid={fluid ?? true} size={size ?? 'large'} disabled={disabled} style={buttonStyle} onClick={this.onDelete} {...(buttonProps ?? {})}>{buttonTitle}</ArkButton>
        {this.renderDeleteModal()}
        {this.renderDeleteNotAllowedModal()}
      </div>
    )
  }

  // -------

  reset = () => {
    this.setState({
      showConfirmDelete: false,
      isSubmitting: false,
      hasDeleted: false,
      error: undefined,
      confirmTextError: undefined,
      confirmTextOk: false,
      showDeleteNotAllowed: false
    })
  }

  // -------

  onDelete = () => {
    const { onDeleteAllowed, itemId } = this.props
    if (onDeleteAllowed) {
      const deleteAllowed = onDeleteAllowed(itemId)
      if (!deleteAllowed) {
        this.setState({ showDeleteNotAllowed: true })
        return
      }
    }
    this.setState({ showConfirmDelete: true, showDeleteNotAllowed: false })
  }

  onDeleteCancel = () => {
    if (this.state.isSubmitting) return // don't allow cancel to work if already submitting
    this.setState({ showConfirmDelete: false, showDeleteNotAllowed: false })
    this.reset()
  }

  onDeleteConfirm = async () => {
    const { itemId, itemTypeName } = this.props
    try {
      this.setState({ isSubmitting: true, hasDeleted: false })
      const deleteResult = await this.props.onDelete(itemId)
      if (deleteResult === true) {
        if (this._isMounted) {
          this.setState({ isSubmitting: false, hasDeleted: true })
        }
      } else {
        if (this._isMounted) {
          this.setState({
            isSubmitting: false,
            error: Error('A problem occurred deleting the ' + itemTypeName.toLowerCase() + ', please try again.')
          })
        }
      }
    } catch (error) {
      console.error('ArkManagerDeleteButton - onDeleteConfirm - onDelete - error: ', error)
      if (this._isMounted) {
        this.setState({ isSubmitting: false, error })
      }
    }
  }

  onDeleteComplete = async () => {
    const { itemId } = this.props
    this.setState({ showConfirmDelete: false })
    this.reset()
    if (this.props.onDeleteComplete) this.props.onDeleteComplete(itemId)
  }

  // -------

  renderDeleteModal = () => {
    const { itemName, itemTypeName, itemNamePrefix, deleteWord, deletedWord, confirmMsgPost, confirmOkButtonProps, confirmTextInput, confirmTextMsg } = this.props
    const { isSubmitting, hasDeleted, error, confirmTextError, confirmTextOk, showDeleteNotAllowed } = this.state
    if (showDeleteNotAllowed) return null
    const formFields: Array<ArkFormField> = []
    // add a custom confirm text input field if the feature is enabled
    if (confirmTextInput) {
      // add a custom title for the confirm text, instead of using the label, so we can avoid the capitalised styling (& further customise the text style/format as needed)
      formFields.push({
        type: ArkFormFieldType.Field,
        key: 'confirmTextTitle',
        content: <>{confirmTextMsg ?? 'Type \'' + confirmTextInput + '\' to confirm:'}</>,
        fieldProps: { style: { marginBottom: 5 } }
      })
      formFields.push({
        type: ArkFormFieldType.Input,
        key: 'confirmText',
        // label: confirmTextMsg ?? 'Type \'' + confirmTextInput + '\' to confirm:',
        placeholder: confirmTextInput,
        fieldProps: { type: 'text', required: true, autoFocus: true, autoComplete: 'off' }
      })
    }
    formFields.push({
      type: ArkFormFieldType.Group,
      key: 'buttons',
      fields: [
        { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { onClick: this.onDeleteCancel /*, floated: 'left' */ } },
        {
          type: ArkFormFieldType.OKButton,
          key: 'submit',
          label: deleteWord ? deleteWord.toUpperCase() : 'DELETE',
          disabled: confirmTextInput !== undefined && !confirmTextOk,
          fieldProps: {
            loading: isSubmitting,
            floated: 'right',
            ...(confirmOkButtonProps ?? {})
          }
        }
      ],
      fieldProps: { widths: 'equal' /* widths: 1, floated: 'right' */, style: { border: '1px solid red !important' } },
      slimline: true
    })
    return (
      <ArkModal size='tiny' open={this.state.showConfirmDelete} onClose={() => hasDeleted ? this.onDeleteComplete() : this.onDeleteCancel()}>
        {!hasDeleted && (<>
          <div style={{ paddingBottom: (!error ? (confirmTextInput === undefined ? 20 : 10) : 0) }}>
            <Header as='h2' inverted>{deleteWord ?? 'Delete'} {itemTypeName}?</Header>
            {!error && (
              <p>Are you sure you want to {deleteWord ? deleteWord.toLowerCase() : 'delete'} {itemTypeName.toLowerCase()} {itemNamePrefix ? (itemNamePrefix + ' ') : ''}<strong>{itemName}</strong>{confirmMsgPost ? (<> {confirmMsgPost}</>) : ''}?</p>
            )}
          </div>
        </>)}

        {error && (<>
          <Message negative>
            <Message.Header>Error</Message.Header>
            <Message.Item>{error.message}</Message.Item>
          </Message>
          <ArkButton color="blue" fluid basic size="large" onClick={this.onDeleteCancel}>OK</ArkButton>
        </>)}

        {/* {confirmTextError && (<>
          <Message negative>
            <Message.Header>Error</Message.Header>
            <Message.Item>{confirmTextError.message}</Message.Item>
          </Message>
        </>)} */}

        {hasDeleted && (<>
          <Header as='h2' inverted>{deleteWord ?? 'Delete'} {itemTypeName}?</Header>{/* NB: still add a header so the message doesn't overlap the modal close button in the top right */}
          <Message positive>
            <Message.Header>{itemTypeName} {deletedWord ?? 'Deleted'}</Message.Header>
            <p>{(itemTypeName && itemTypeName[0].toUpperCase() + itemTypeName.slice(1))} {itemNamePrefix ? (itemNamePrefix + ' ') : ''}<strong>{itemName}</strong> has been {deletedWord ? deletedWord.toLowerCase() : 'deleted'}</p>
          </Message>
          <ArkButton color="blue" fluid basic size="large" onClick={this.onDeleteComplete} style={{ marginTop: 10 }}>OK</ArkButton>
        </>)}

        {!error && !hasDeleted && (<>
          <ArkForm
            formKey="groupDelete"
            inverted
            formFields={formFields}
            formError={error ?? confirmTextError}
            onFormSubmit={this.onFormSubmit}
            onValueChanged={this.onValueChanged}
            autoComplete='off'
          ></ArkForm>
        </>)}
      </ArkModal>
    )
  }

  renderDeleteNotAllowedModal = () => {
    const { notAllowedTitle, notAllowedMsg, itemName, itemTypeName, deleteWord, deletedWord } = this.props
    const { showDeleteNotAllowed } = this.state
    if (!showDeleteNotAllowed) return null
    return (
      <ArkModal size='tiny' open={this.state.showDeleteNotAllowed} onClose={() => this.onDeleteCancel()}>
        <Message warning>
          <Message.Header>{notAllowedTitle ?? ((deleteWord ?? 'Delete') + ' not allowed')}</Message.Header>
          <Message.Item>{notAllowedMsg ?? 'The ' + itemTypeName.toLowerCase() + ' \'' + itemName + '\' is not allowed to be ' + (deletedWord ? deletedWord.toLowerCase() : 'deleted')}</Message.Item>
        </Message>
        <ArkButton color="blue" fluid basic size="large" onClick={this.onDeleteCancel}>OK</ArkButton>
      </ArkModal>
    )
  }

  onValueChanged = (fieldKey: string, fieldValue: any, _oldFieldValue: any) => {
    const { confirmTextInput } = this.props
    const { confirmTextOk } = this.state
    // console.log('ArkManagerDeleteButton - onValueChanged - fieldKey: ', fieldKey, ' fieldValue: ', fieldValue, ' oldFieldValue: ', oldFieldValue)
    if (fieldKey === 'confirmText') {
      if (confirmTextInput !== undefined) {
        if (fieldValue === confirmTextInput && confirmTextOk === false) {
          this.setState({ confirmTextOk: true, confirmTextError: undefined })
        } else if (fieldValue !== confirmTextInput && confirmTextOk === true) {
          this.setState({ confirmTextOk: false, confirmTextError: undefined })
        }
      }
    }
  }

  onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    const { confirmTextInput } = this.props
    const { confirmTextOk } = this.state
    // console.log('ArkManagerDeleteButton - onFormSubmit - fieldValues: ', fieldValues)
    if (confirmTextInput && !confirmTextOk) {
      // NB: this isn't really needed with the ok/accept button now disabled until the text matches, but leaving it here for now incase we want to enable some form of confirmText error message handling alongside it in the future
      if (fieldValues.confirmText !== confirmTextInput) {
        this.setState({ confirmTextError: Error('The text you entered does not match the required text.') })
        return
      }
    }
    // NB: only the submit button triggers this, so we treat it as an 'ok' press, the cancel button fires its own callback
    this.onDeleteConfirm()
  }
}

export default ArkManagerDeleteButton
