import React from 'react'

import ArkInfoView from '../ArkInfoView'

const ArkAccessDeniedView = () => (
  <ArkInfoView
    iconName='denied'
    title='ACCESS DENIED'
    message={<div>You do not have access to this area.</div>}
  />
)

export default ArkAccessDeniedView
