/**
 * ArkLargeButton
 */

import React, { ReactNode, useContext } from 'react'

import { ResponsiveContext } from '../../providers/ResponsiveProvider'
import ArkButton from '../ArkButton'
import ArkIcon, { IconName } from '../ArkIcon'
import ArkSpacer from '../ArkSpacer'

import styles from './ArkLargeButton.module.css'

/**
 * button
 */

interface ArkLargeButtonProps {
  iconName: IconName
  onClick: () => void
  show: boolean
  title: string
}

const ArkLargeButton = ({ iconName, onClick, show, title }: ArkLargeButtonProps) => {
  const { store } = useContext(ResponsiveContext)
  const { isMobile } = store

  if (!show) return null

  return (
    <ArkButton className={`${styles.button} ${isMobile ? styles.buttonMobile : ''}`} onClick={onClick}>
      <ArkIcon name={iconName} size={64} />
      <ArkSpacer size={isMobile ? 20 : 30} />
      <div className={styles.title}>{title}</div>
    </ArkButton>
  )
}
export default ArkLargeButton

/**
 * group
 */

export interface ArkLargeButtonGroupProps {
  children: ReactNode
}

export const ArkLargeButtonGroup = ({ children }: ArkLargeButtonGroupProps) =>
  <div className={styles.group}>{children}</div>
