import React, { useContext, useEffect } from 'react'

import { AuthContext, AuthSSOContext } from 'src/core/providers'

import { COMPANY_LOGIN_SERVICE_SSO_AUTO_LOGIN, COMPANY_LOGIN_SERVICE_SSO_DEBUG, COMPANY_LOGIN_SERVICE_TYPE_AUTH0 } from 'src/constants/config'

import ArkButton from 'src/core/components/ArkButton'
import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkMessage from 'src/core/components/ArkMessage'

import LoginNotUserView from '../../Login/LoginNotUserView'

// import styles from './Auth0SSOLoginView.module.css'

interface Auth0SSOLoginViewProps {
  email?: string
  isSSOCallback?: boolean // true if this is loaded after/from a redirect from the SSO provider
  onCancel?: () => void
}

const Auth0SSOLoginView = (props: Auth0SSOLoginViewProps) => {
  const { email, isSSOCallback, onCancel } = props

  const authContext = useContext(AuthContext)
  const authSSOContext = useContext(AuthSSOContext)

  const {
    isLoadingConfig,
    isInitialised,
    isInitialising,
    isRunning,
    isRedirecting,
    isAuthenticated,
    userEmail,
    cachedEmail,
    // ssoServiceType,
    // ssoServiceConfig,
    ssoAccessToken: accessToken,
    ssoError: error,
    auth0User
  } = authSSOContext.store

  const hasAuthParams = authSSOContext.actions.auth0HasAuthParams()
  // const cachedEmail = authSSOContext.actions.getAuthSSOCache()?.email
  const tokenEmail = auth0User?.email

  if (COMPANY_LOGIN_SERVICE_SSO_AUTO_LOGIN) {
    // auto run the sso login handling (either the initial 'show sso login ui', the callback from the sso provider, or submitting the access token to our api to complete the full sso login process with our server)
    // NB: bails if no email address is passed in or cached
    useEffect(() => {
      async function runAsync () {
        console.log('Auth0SSOLoginView - useEffect - initAndLogin (before) - isInitialised:', isInitialised, ' isInitialising:', isInitialising, ' isRunning:', isRunning, ' email:', email, ' cachedEmail:', cachedEmail, ' isSSOCallback:', isSSOCallback, ' hasAccessToken:', accessToken !== undefined)
        // if (email || cachedEmail) {
        // TODO: port to add `ssoConfig` support - disabled/commented out for now until this is updated, see the `OktaSSOLoginView` for more recent handling <<<<
        // NB: set `submitAccessToken` to `true` when `isSSORedirect` is true so it auto submits to our api straight away (if thats always the case can we just remove the arg, or use it only as an override to disable the submit when debugging instead?)
        // await authSSOContext.actions.setupSSOServiceAndLogin(AuthSSOServiceType.Auth0, email /* ?? cachedEmail! */, isSSORedirect ?? false, isSSORedirect ?? false)
        console.log('Auth0SSOLoginView - useEffect - initAndLogin (end) - isInitialised:', isInitialised, ' isInitialising:', isInitialising, ' isRunning:', isRunning, ' email:', email, ' cachedEmail:', cachedEmail, ' isSSOCallback:', isSSOCallback, ' hasAccessToken:', accessToken !== undefined)
        // }
      }
      if (!isInitialised && !isInitialising && !isRunning) runAsync()
    }, [isInitialised, isInitialising, isRunning, email, isSSOCallback, accessToken])
  }

  if (isInitialising || isRedirecting) return (<ArkLoaderView message={'Loading'} />)
  if (isRunning) return (<ArkLoaderView message={'Logging In'} />)

  return (
    <div>
      <div>
        {/* <h1>Auth0 Login</h1> */}
        {error && (
          <>
            <ArkMessage negative>
              <ArkMessage.Header>Login Error</ArkMessage.Header>
              {/* <ArkMessage.Item>{error.message}</ArkMessage.Item> */}
              <p>{error.message}</p>
            </ArkMessage>
            <LoginNotUserView
              email={email ?? cachedEmail ?? ''}
              title={'Go Back'}
              onClick={() => {
                if (onCancel) onCancel()
              }}
            />
          </>
        )}
        {/* TODO: show a back/cancel button if we're not loading/logging-in etc??? */}
      </div>

      {/* <div>
        {(isInitialising || isRedirecting) && (<ArkLoaderView message={'Loading'} />)}
        {!(isInitialising || isRedirecting) && isRunning && (<ArkLoaderView message={'Logging In'} />)}
      </div> */}

      {/* DEV/DEBUG MODE: */}
      {COMPANY_LOGIN_SERVICE_SSO_DEBUG && (
        <>
          <div>
            <br />
            <ArkButton fluid onClick={async () => {
              console.log('Auth0SSOLoginView - isAuthenticated btn - onClick - email:', email)
              // TODO: port to add `ssoConfig` support - disabled/commented out for now until this is updated, see the `OktaSSOLoginView` for more recent handling <<<<
              // TODO: may not yet return `isAuthenticated` status? (at least not for all providers and/or pre/post submit & callback stages...)
              // const isAuthenticated = await authSSOContext.actions.setupSSOServiceAndLogin(AuthSSOServiceType.Auth0, email ?? cachedEmail!, isSSORedirect ?? false)
              console.log('Auth0SSOLoginView - isAuthenticated btn - onClick - isAuthenticated:', isAuthenticated)
            }}>AUTH0 - LOAD AND LOGIN {!isSSOCallback ? <>(STAGE 1)</> : <>(STAGE 2)</>}</ArkButton>
            <br />
            {isAuthenticated && (
              <div>
                {auth0User && auth0User.email && accessToken && (
                  <ArkButton fluid onClick={async () => {
                    const companyServiceId = authSSOContext.store.ssoServiceConfig?.companyServiceId
                    if (companyServiceId !== undefined) {
                      authContext.actions.loginWithSSOToken(auth0User.email!, COMPANY_LOGIN_SERVICE_TYPE_AUTH0, companyServiceId, accessToken)
                    }
                  }}>API - LOGIN WITH ACCESS TOKEN</ArkButton>
                )}

                <br />
                <ArkButton fluid onClick={async () => {
                  await authSSOContext.actions.logoutSSOProvider()
                }}>AUTH0 - LOGOUT<br />(RESET AND CLEAR AUTH0 ACCESS)</ArkButton>
                <br />
              </div>
            )}
          </div>
          <div>
            <h3>Auth0 Debug:</h3>
            <div>email (props): {email ?? '-'}</div>
            <div>email (cache): {cachedEmail ?? '-'}</div>
            <div>email (token): {tokenEmail ?? '-'}</div>
            <div>email (used): {userEmail ?? '-'}</div>
            <br />
            <div>auth0 - isSSOCallback: {isSSOCallback ? 'YES' : 'NO'}</div>
            <div>auth0 - hasAuthParams: {hasAuthParams ? 'YES' : 'NO'}</div>
            <div>auth0 - isLoadingConfig: {isLoadingConfig ? 'YES' : 'NO'}</div>
            <div>auth0 - isInitialised: {isInitialised ? 'YES' : 'NO'}</div>
            <div>auth0 - isInitialising: {isInitialising ? 'YES' : 'NO'}</div>
            <div>auth0 - isRunning: {isRunning ? 'YES' : 'NO'}</div>
            <div>auth0 - isRedirecting: {isRedirecting ? 'YES' : 'NO'}</div>
            <div>auth0 - isAuthenticated: {isInitialised ? (isAuthenticated ? 'YES' : 'NO') : '-'}</div>
            <div>auth0 - hasAccessToken: {isInitialised ? (accessToken ? 'YES' : 'NO') : '-'}</div>
            <br />
            {auth0User && (<div>
              <div>auth0 - user.name: {auth0User.name}</div>
              <div>auth0 - email: {auth0User.email}</div>
              <div>auth0 - email-verified: {auth0User.email_verified ? 'YES' : 'NO'}</div>
            </div>)}
            {/* {isAuthenticated && (
              <div>
                <br />
                {accessToken && (
                  <div>
                    <pre style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>{JSON.stringify(accessToken)}</pre>
                  </div>
                )}
                <br />
              </div>
            )} */}
          </div>
        </>
      )}
      {/* <hr />
      <LoginNotUserView
        email={email ?? cachedEmail ?? ''}
        onClick={() => {
          if (onCancel) onCancel()
        }}
      /> */}
    </div>
  )
}

export default Auth0SSOLoginView
