import React from 'react'

import { withUserContext, IUserMultiContext } from '../../providers'
import { Company, Project, UserProject } from '../../models'

import ArkBorder from '../ArkBorder'
import ArkButton from '../ArkButton'
import ArkIcon from '../ArkIcon'
import ArkInfoView from '../ArkInfoView'
import ArkLoaderView from '../ArkLoaderView'
import ArkModal from '../ArkModal'

import { OBJECT_PROJECT_NAME, OBJECT_PROJECT_NAME_PLURAL } from 'src/constants/strings'

import { List } from 'semantic-ui-react'

import styles from './ArkProjectSelectView.module.css'
import ArkHeader from '../ArkHeader'

interface IProps extends IUserMultiContext {
  company: Company
  projects: Array<Project | UserProject>
}
interface IState {
  showModal: boolean
  selectingProject: boolean
}

class ArkProjectSelectView extends React.Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      showModal: true,
      selectingProject: false
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    // const adminProjects = this.props.userContext.store.adminProjects
    // if (!adminProjects) return null // NB: the parent code checks if no adminProjects & stops this component from firing
    return (
      <>
        {/* <h1>{this.props.title}</h1>
        {this.renderProjectList(adminProjects)} */}
        {this.renderNotice()}
        {this.renderProjectListModal()}
      </>
    )
  }

  renderNotice () {
    return (
      <ArkInfoView
        iconName='warning'
        title={'Select ' + OBJECT_PROJECT_NAME}
        message={
          <div>
            <p>Please select a {OBJECT_PROJECT_NAME}.</p>
            <ArkButton color="blue" fluid basic size="large" onClick={() => this.setState({ showModal: true }) }>SHOW {OBJECT_PROJECT_NAME_PLURAL}</ArkButton>
          </div>
        }
      />
    )
  }

  renderProjectListModal = () => {
    const company = this.props.userContext.store.selectedCompany
    if (!company) return null
    return (
      <ArkModal
        header={<span className={styles.modalTitle}>{company.name} - {OBJECT_PROJECT_NAME_PLURAL}</span>}
        size='tiny'
        scrolling
        disablePadding
        open={this.state.showModal}
        onClose={() => this.setState({ showModal: false })}
        className={styles.modal}
      >
        <ArkBorder title={<ArkHeader as="h3" inverted={true}>Choose <span className={styles.lowercase}>a</span> {OBJECT_PROJECT_NAME}</ArkHeader>}>
          {this.renderProjectList(this.props.projects)}
        </ArkBorder>
        {this.state.selectingProject && (
          <div className={styles.loadingOverlay}><ArkLoaderView message='Loading' /></div>
        )}
      </ArkModal>
    )
  }

  renderProjectList = (projects: Array<Project | UserProject>) => {
    return (
      <div
        className={styles.projectlist}
        data-test-id="ark-project-select" // e2e testing identifier
      >
        <List divided relaxed selection animated verticalAlign='middle' size='large' inverted>
          {projects.map((project: Project) => {
            const projectAccessDisabled = (project instanceof UserProject && !project.userAccessEnabled)
            return (
              <List.Item
                key={'project_' + project.id}
                onClick={() => { this.selectProject(project.id) }}
                disabled={this.state.selectingProject}
                className={(projectAccessDisabled ? styles.accessDisabled : '')}
              >
                <List.Content>
                  <div className='listIcon'><ArkIcon name='project' size={28}/></div>
                  <div className='listBody'>
                    <List.Header>
                      {project.name}
                      {(projectAccessDisabled ? <span className={styles.accessDisabledMsg}>(your access is disabled)</span> : undefined)}
                    </List.Header>
                    {project.desc ? <List.Description>{project.desc}</List.Description> : null}
                  </div>
                </List.Content>
              </List.Item>
            )
          })}
        </List>
      </div>
    )
  }

  selectProject = async (projectId: number) => {
    this.setState({ selectingProject: true })
    // await new Promise(resolve => setTimeout(resolve, 1000)) // DEBUG ONLY <<<
    await this.props.userContext.actions.selectProject(projectId)
    if (this._isMounted) {
      this.setState({ selectingProject: false })
    }
  }
}

export default withUserContext(ArkProjectSelectView)
