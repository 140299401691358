import React from 'react'

import { withUserContext, IUserMultiContext } from './'

export enum UserSelectionsDataStatus {
  initial, loading, done, error
}

export interface IUserSelectionsStore {
}

export interface IUserSelectionsActions {
}

export interface IUserSelectionsContext {
  actions: IUserSelectionsActions
  store: IUserSelectionsStore
}

export interface IUserSelectionsMultiContext {
  userSelectionsContext: IUserSelectionsContext
}

export const UserSelectionsContext = React.createContext<IUserSelectionsContext>({} as IUserSelectionsContext)

export interface UserSelectionsProviderProps extends IUserMultiContext {
  children?: React.ReactNode
}
export interface UserSelectionsProviderState extends IUserSelectionsStore {
}

class UserSelectionsProvider extends React.Component<UserSelectionsProviderProps, UserSelectionsProviderState> {
  constructor (props: UserSelectionsProviderProps) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
  }

  // -------

  // -------

  actions: IUserSelectionsActions = {
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: IUserSelectionsStore = {
  //  ...
  // }

  render () {
    return (
      <UserSelectionsContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for IUserSelectionsStore */ }}
      >
        {this.props.children}
      </UserSelectionsContext.Provider>
    )
  }
}

const withUserSelectionsContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withUserSelectionsContextHOC = (props: any) => (
    <UserSelectionsContext.Consumer>
      {(userSelectionsContext) => {
        if (userSelectionsContext === null) {
          throw new Error('UserSelectionsConsumer must be used within a UserSelectionsProvider')
        }
        // console.log('withUserSelectionsContext - render - UserSelectionsContext.Consumer - userSelectionsContext.store: ', userSelectionsContext.store)
        return (<Component {...props} {...{ userSelectionsContext: userSelectionsContext }} />)
      }}
    </UserSelectionsContext.Consumer>
  )
  return withUserSelectionsContextHOC
}

const UserSelectionsProviderWithContext = withUserContext(UserSelectionsProvider)

export { UserSelectionsProviderWithContext as UserSelectionsProvider }
export { withUserSelectionsContext }
