import React from 'react'

import StreamhubPage from '../../components/StreamhubPage/StreamhubPage'
import StreamhubSourcesView from './StreamhubSourcesView'

class StreamhubSourcesPage extends React.Component<{}, {}> {
  render () {
    return (
      <StreamhubPage>
        <StreamhubSourcesView />
      </StreamhubPage>
    )
  }
}
export default StreamhubSourcesPage
