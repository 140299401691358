import React from 'react'

import styles from './StreamhubAVInfoSummary.module.css'

export interface StreamhubAVInfoSummaryProps {
  filename?: string
  videoAVInfo: any
  className?: string
}

const StreamhubAVInfoSummary = (props: StreamhubAVInfoSummaryProps) => {
  const { filename, videoAVInfo, className } = props

  const formatFieldOrder = (fieldOrder?: string) => {
    if (fieldOrder) {
      // NB: see the `field_order` section of https://ffmpeg.org/ffprobe-all.html for all options (the below should cover all current ones ffprobe returns)...
      if (fieldOrder === 'progressive') return 'p' // 'Progressive'
      else if (fieldOrder === 'tt') return 'i' // 'Top-Top'
      else if (fieldOrder === 'bb') return 'i' // 'Bottom-Bottom'
      else if (fieldOrder === 'tb') return 'i' // 'Top-Bottom'
      else if (fieldOrder === 'bt') return 'i' // 'Bottom-Top'
      else return 'Unknown'
    }
    return 'N/A'
  }

  const formatVideoFormat = (formatName?: string, filename?: string) => {
    // NB: some ffprobe format names include multiple, e.g: `MOV,MP4,M4A,3GP,3G2,MJ2`, when that happens fallback to the file extension instead (if its available, don't add anything here if not)
    if (formatName && formatName.includes(',') && filename !== undefined) {
      const extIndex = filename.lastIndexOf('.')
      if (extIndex >= 0) {
        const extension = filename.substring(extIndex)
        return extension.replace('.', '').toUpperCase()
      }
    }
    return formatName?.toUpperCase()
  }

  return (
    <div className={`${styles.avInfoSummary}${className ? ' ' + className : ''}`}>
      <span className={styles.title}>Res:</span> {videoAVInfo.width}x{videoAVInfo.height}&nbsp;
      <span className={styles.title}>Field Order:</span><span className={styles.value}>{formatFieldOrder(videoAVInfo.fieldOrder)}</span>&nbsp;
      <span className={styles.title}>Codec:</span> {videoAVInfo.codecName?.toUpperCase()}&nbsp;
      <span className={styles.title}>Format:</span> {formatVideoFormat(videoAVInfo.formatName, filename)}&nbsp;
      <span className={styles.title}>FPS:</span> {videoAVInfo.fps?.toFixed(2)}&nbsp;
      <span className={styles.title}>Pixel Format:</span> {videoAVInfo.pixelFormat?.toUpperCase()}&nbsp;
      <span className={styles.title}>Duration:</span> {videoAVInfo.duration?.toFixed(2)}
    </div>
  )
}

export default StreamhubAVInfoSummary
