/**
 * ProjectChannelListItem
 */

import React from 'react'

import { Channel, OnlineStatus } from 'src/core/models'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkListItem from 'src/core/components/ArkListItem'

import { pluralize } from 'src/core/utilities/strings'

import { DEFAULT_PICKER_COLOR } from 'src/constants/config'

import styles from './ProjectChannelListItem.module.css'

interface ProjectChannelListItemProps {
  active: boolean
  channel: Channel
  filter?: string
  onClick: () => void
  onEditClick?: () => void
  onlineStatus?: OnlineStatus
}

const ProjectChannelListItem = (props: ProjectChannelListItemProps) => {
  const {
    active,
    channel,
    filter,
    onClick,
    onEditClick,
    onlineStatus
  } = props

  const leftComponent = (
    <ArkAvatar
      className={styles.avatar}
      color={channel.colour || DEFAULT_PICKER_COLOR}
      maxInitials={3}
      name={channel.name}
      round={false}
      size='40'
      onlineStatus={onlineStatus}
      variant='channel'
    />
  )

  return (
    <ArkListItem
      active={active}
      description={`${channel.programCount} ${pluralize('program', channel.programCount)}`}
      filter={filter}
      leftComponent={leftComponent}
      onClick={onClick}
      onEditClick={onEditClick}
      title={channel.name}
    />
  )
}

export default ProjectChannelListItem
