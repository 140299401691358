/**
 * PrivateRoute
 * - a custom react router route component that adds some basic auth requirement checks to route declarations
 * - it either shows/loads the target page/route if the auth checks pass
 * - or shows a related error page if logged in but no valid access, or redirects to the login page if no/invalid auth is found
 * ref: https://stackoverflow.com/questions/43164554/how-to-implement-authenticated-routes-in-react-router-4
 */
import React from 'react'
import { Route, Redirect, RouteComponentProps } from 'react-router-dom'

import * as ROUTES from 'src/constants/routes' // TODO: can we make move this import out of this component & instead pass in as a prop/config in some way? (keep this component generic)
import { User } from 'src/core/models'

import VerifyEmailWarningPage from 'src/core/pages/auth/Verify/VerifyEmailWarningPage'
import AccessDeniedPage from 'src/core/pages/auth/AccessDenied/AccessDeniedPage'

export enum AccessLevel {
  NoAuth, // not logged in
  UserUnverified, // logged in but email isn't verified/confifmed yet, so access to some areas is limited
  UserVerified, // logged in with a verified/confirmed email
}

type TParams = {
  component: React.ComponentClass<any, any> | React.FunctionComponent
  authUser?: User
  accessLevel: AccessLevel
  path: string
  allowGuest?: boolean // defaults to true unless specifically disabled
  args?: any
}
const PrivateRoute: React.FC<TParams> = ({ component, authUser, accessLevel, allowGuest, ...rest }: TParams) => {
  // console.log('PrivateRoute - path: ', rest.path, ' authUser: ', authUser, ' accessLevel: ', accessLevel, ' authRole', authRole, ' args: ', rest.args)

  // NB: this was originally from old firebase era code, that has since had `authUser.isAnonymous` & `authRole` support removed
  // NB: ..plus some other aspects changed to work with our custom api back-end
  // TODO: we may want to re-think/re-structure this whole component eventually, its used in a much more limited way these days
  // TODO: ..compared to the earlier firebase version, it now is mainly used to handle general 'no access' & 'is user verified' checks only

  let allowAccess = false
  if (accessLevel === AccessLevel.NoAuth) { // no auth required - all users allowed access
    allowAccess = true
  } else if (accessLevel === AccessLevel.UserUnverified) { // unverified users allowed, pages that allow access without the user verifying their email address
    allowAccess = !!((authUser))
  } else if (accessLevel === AccessLevel.UserVerified) { // must be a verified user
    allowAccess = !!(authUser && authUser.emailVerified)
  }

  // guest user restriction (when the config option is enabled)
  if (allowAccess && allowGuest === false && authUser?.isGuest) {
    allowAccess = false
  }

  // TESTING: if the user doesn't have access - redirect to login it no-auth or anon, show verify email message if that was required & failed, or show an access denied message otherwise
  const redirectPath = ROUTES.LOGIN
  let showAccessDenied = false
  let showVerifyEmail = false
  const accessDeniedMsg = 'ACCESS DENIED'
  if (!allowAccess || !authUser) {
    if (authUser) {
      showAccessDenied = true
      if (authUser && !authUser.emailVerified) {
        showVerifyEmail = true
      }
    }
    // NB: will use the redirectPath if no user
  }
  return (
    <Route
      {...rest}
      render={(routeProps: RouteComponentProps) => {
        const { location } = routeProps
        return allowAccess
          ? React.createElement(component, { ...routeProps, args: rest.args })
          : showAccessDenied
            ? showVerifyEmail
              ? (<VerifyEmailWarningPage />)
              : (<AccessDeniedPage message={accessDeniedMsg} />)
            : <Redirect to={{ pathname: redirectPath, state: { from: location } }} />
      }}
    />
  )
}

export default PrivateRoute
