import React, { ReactNode } from 'react'

import styles from './ArkFormLabel.module.css'

export interface ArkFormLabelProps {
  children: ReactNode
  required?: boolean
  right?: string;
}

const ArkFormLabel = (props: ArkFormLabelProps) => (
  <div className={styles.container}>
    <div className={styles.label}>
      {props.children}
      {props.required && <span className={styles.required}> *</span>}
    </div>
    {props.right && <span className={styles.right}>{props.right}</span>}
  </div>
)

export default ArkFormLabel
