import React from 'react'
import { Dimmer } from 'semantic-ui-react'

import ArkIcon from 'src/core/components/ArkIcon'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { ProgramWatchdogStatus } from './ProgramWatchdog'
import { formatError, getStatusMessage } from './utilities'

import styles from './ProgramStatusView.module.css'

const DEBUG_ALWAYS_SHOW = false

interface ProgramStatusViewProps {
  playerError?: string
  programError?: string
  status: ProgramWatchdogStatus
  thumb: boolean
}

const ProgramStatusView = (props: ProgramStatusViewProps) => {
  const { playerError, programError, status, thumb } = props

  const error: string | undefined = programError || playerError

  if (!DEBUG_ALWAYS_SHOW && !error && status === ProgramWatchdogStatus.Playing) return null

  return (
    <div className={styles.container}>
      <Dimmer active className={styles.dimmer}>
        {error && thumb
          ? <ArkIcon color='grey' name='warning' />
          : <ArkLoader small={thumb} />
        }
        {!thumb && (
          <>
            <ArkSpacer large />
            <div
              data-test-id="ark-program-loading-msg" // e2e testing identifier
            >
              {getStatusMessage(status)}
            </div>
            {error && (
              <div
                className={styles.loadingErrorMessage}
                data-test-id="ark-program-error" // e2e testing identifier
              >
                {formatError(error)}
              </div>
            )}
          </>
        )}
      </Dimmer>
    </div>
  )
}

export default ProgramStatusView
