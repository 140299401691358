import { AxiosResponse } from 'axios'
import { EventEmitter } from 'events'
import { deviceDetect, deviceType, mobileModel, osName, osVersion, browserName, browserVersion } from 'react-device-detect'

import ServerAPIClient from './ServerAPIClient'
import { ServerError, ServerAuthTokenExpiredError, ServerErrorCodes, ServerAuthPasswordPolicyError, ServerAuth2FAPhoneNumberNotVerifiedError } from './ServerAPIErrors' /*, ServerEventType */
import { AuthLoginServiceType, AuthSession, IAuthLoginService, User } from '../models'

import { COMPANY_LOGIN_SERVICE_TYPE_AUTH0, COMPANY_LOGIN_SERVICE_TYPE_EMAIL, COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC, COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML, DEBUG_MODE } from 'src/constants/config'

export const ServerEventTypeAuth = 'auth' // NB: replacement for ServerEventType.auth (while deciding if we ditch events all together)
// export const ServerEventTypeCompanyAuth = 'companyAuth' // TODO: DEPRECIATE - org/project forced 2fa switch auth
// export const ServerEventTypeProjectAuth = 'projectAuth' // TODO: DEPRECIATE - org/project forced 2fa switch auth

class ServerAuthAPI extends EventEmitter {
  private _apiClient: ServerAPIClient

  public authUser?: User
  public authToken?: string
  public authRefreshToken?: string
  public authDeviceUUID?: string
  public authTFAToken?: string
  public authEmail?: string // (only) used during the new 2 part login/registration flows (inc. company invite pre-filling of fields)
  public authName?: { firstName?: string, lastName?: string } // (only) used during the new 2 part login/registration flows (just for the company invite pre-fill for now)
  public loading: boolean = false
  public initalStateLoaded: boolean = false

  public companyAuthTFARequired: boolean = false
  public projectAuthTFARequired: boolean = false
  // TODO: DEPRECIATE - org/project forced 2fa switch auth
  // public companyAuthTFAToken?: string
  // public projectAuthTFAToken?: string

  private _authLoadRetryCount: number = 0

  constructor (apiClient: ServerAPIClient) {
    super()
    this._apiClient = apiClient
    this._load()
  }

  private _load = async () => {
    this.loadDeviceUUID()
  }

  isLoggedIn = () => {
    return this.authToken !== undefined && this.authUser !== undefined
  }

  requiresTFACode = () => {
    return !(this.authTFAToken === undefined && this.isLoggedIn() === false)
  }

  updateAuthToken (authToken?: string) {
    this._apiClient.authToken = authToken
    this.authToken = authToken
  }

  updateAuthRefreshToken (authRefreshToken?: string) {
    this.authRefreshToken = authRefreshToken
  }

  updateAuthTFAToken (authTFAToken?: string) {
    this.authTFAToken = authTFAToken
  }

  // looks up an email address to see if its registered & what login method/service to use
  // returns: 0 = new/not-registered email, 1 = email/password login, 2 = okta/auth0 sso login
  // NB: was originally named `checkEmailExists` & just returned a bool, but now also accounts for the login method/service to use (email/sso etc.)
  emailLoginLookup = async (email: string) : Promise<IAuthLoginService> => {
    // WARNING: api `v0.3.5` changes how this api endpoint responds to support the new (partial/early) sso handling
    // WARNING: for the time being we handle both the old AND new api handling responses until all servers are updated with sso support
    // okta/auth0 sso check_email api changes:
    // - ADDED: error 404 response: user not invited
    // - CHANGED: data.result is no longer a bool, its an object with more details (see below)
    // - ADDED: data.result.flag_registration_ok field > false = invited (can finish registration), true = user registered
    // - ADDED: data.result.login_service_id = the service/auth provider to use (email/sso etc.)
    // NB: we don't have the /config api call loaded when we use this, so can't check the server version here, instead we just check for the new fields in the response
    // TODO: migrate to the newer okta/auth0 sso specific api handling once all servers are updated
    try {
      const data: any = { email: email }
      const response = await this._apiClient.apiPost('/auth/check_email', data)
      if (response.data) {
        console.log('ServerAuthAPI - emailLoginLookup - response.data:', response.data, ' typeof response.data.result:', typeof response.data.result)
        // api `v0.3.5` specific handling...
        // NB: updated to handle changes with the `v0.3.13` api response changes (slightly different structure & more SSO details)
        if (typeof response.data.result === 'object') {
          if (response.data.result.login_service && typeof response.data.result.login_service === 'object') {
            const loginServiceId = response.data.result.login_service.login_service_id

            // NB: `v0.3.13` specific handling (can now return multiple active login services, although for now we only use/grab the first, like the `v0.3.5` did server side previously)
            // TODO: consider how we might eventually support choosing if multiple matching acitve sso service types are returned
            // TODO: what about the `other_login_services`, will we ever need to handle those during login/registration?
            const activeLoginServices = response.data.result.login_service.active_login_services
            // console.log('ServerAuthAPI - emailLoginLookup - activeLoginServices:', activeLoginServices)
            let activeLoginService: any | undefined
            if (loginServiceId !== COMPANY_LOGIN_SERVICE_TYPE_EMAIL && activeLoginServices && typeof activeLoginServices === 'object' && Array.isArray(activeLoginServices) && activeLoginServices.length > 0) {
              // TODO: currently the `v0.3.13` active login services data doesn't include a `login_service_id` field, so we have to assume the first one is the one we want
              // activeLoginService = activeLoginServices.find((loginService: any) => loginService.login_service_id === loginServiceId) // NB: it does have an `id` field, but that is `1` in my current test, so doesn't seem match the login_service_id, maybe its more an instance id?
              activeLoginService = activeLoginServices[0] // TEMP: just grab the first one for now (& assume/hope its the correct login service type!)
            }
            // console.log('ServerAuthAPI - emailLoginLookup - activeLoginService:', activeLoginService)

            // TEMP: legacy support for api versions BEFORE `v0.3.13`
            // TODO: DEPRECIATE THIS ONCE ALL SERVERS ARE UPDATED TO API VERSION `v0.3.13+`
            if (activeLoginServices === undefined) {
              console.log('ServerAuthAPI - emailLoginLookup - LEGACY API DETECTED - FALLBACK HANDLING...')
              activeLoginService = response.data.result.login_service
            }

            // check if the user is logging in (already registered)
            if ((response.data.result.flag_registration_ok !== undefined && response.data.result.flag_registration_ok === true)) {
              if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_EMAIL) {
                return { type: AuthLoginServiceType.EmailPassword }
              } else if (activeLoginService !== undefined) {
                if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC) {
                  return { type: AuthLoginServiceType.SSOOktaOIDC, ssoConfig: { companyServiceId: activeLoginService.id, clientId: activeLoginService.client_id, issuer: activeLoginService.issuer, type: AuthLoginServiceType.SSOOktaOIDC } }
                } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML) {
                  return { type: AuthLoginServiceType.SSOOktaSAML, ssoConfig: { companyServiceId: activeLoginService.id, clientId: activeLoginService.client_id, issuer: activeLoginService.issuer, type: AuthLoginServiceType.SSOOktaSAML } } // TODO: check config args <<<<
                } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_AUTH0) {
                  return { type: AuthLoginServiceType.SSOAuth0, ssoConfig: { companyServiceId: activeLoginService.id, clientId: activeLoginService.client_id, issuer: activeLoginService.issuer, type: AuthLoginServiceType.SSOAuth0 } }
                } else {
                  // NB: throw an unsupported login service error
                  throw new Error('Invalid login service')
                }
              } else {
                throw new Error('Invalid login service response')
              }
            // new user - not registered yet
            } else if ((response.data.result.flag_registration_ok !== undefined && response.data.result.flag_registration_ok === false)) {
              console.log('ServerAuthAPI - emailLoginLookup - flag_registration_ok === false - new user (not registered)')
              // TESTING: SSO (okta) based new users trigger an alternative response than the normal email based new users, so the api can return the SSO details to use...
              // return { type: AuthLoginServiceType.NewUser }
              if (response.data.result.login_service.login_service_id === COMPANY_LOGIN_SERVICE_TYPE_EMAIL) {
                return { type: AuthLoginServiceType.NewUser }
              } else if (activeLoginService !== undefined) {
                if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC) {
                  return { type: AuthLoginServiceType.NewUser, ssoConfig: { companyServiceId: activeLoginService.id, clientId: activeLoginService.client_id, issuer: activeLoginService.issuer, type: AuthLoginServiceType.SSOOktaOIDC } }
                } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML) {
                  return { type: AuthLoginServiceType.NewUser, ssoConfig: { companyServiceId: activeLoginService.id, clientId: activeLoginService.client_id, issuer: activeLoginService.issuer, type: AuthLoginServiceType.SSOOktaSAML } } // TODO: check config args <<<<
                } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_AUTH0) {
                  return { type: AuthLoginServiceType.NewUser, ssoConfig: { companyServiceId: activeLoginService.id, clientId: activeLoginService.client_id, issuer: activeLoginService.issuer, type: AuthLoginServiceType.SSOAuth0 } }
                } else {
                  // NB: throw an unsupported login service error
                  throw new Error('Invalid login service')
                }
              } else {
                throw new Error('Invalid login service response')
              }
            } else {
              // NB: throw a new/unregistered user email error, as the api should return with a 404 status & be handled separately in normal use (so this should never occur)
              throw new Error('Invalid new user response')
            }
          } else {
            // NB: throw an invalid login service data error
            throw new Error('Invalid login service response')
          }
        }

        // pre api `v0.3.5` handling...
        // TODO: REMOVE THIS once all api servers support api `v0.3.5` SSO logins <<<<
        // return !!(response.data.result !== undefined && response.data.result === true)
        return (response.data.result !== undefined && response.data.result === true) ? { type: AuthLoginServiceType.EmailPassword } : { type: AuthLoginServiceType.NewUser }
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerAuthAPI - emailLoginLookup - error:', error)
      // api `v0.3.5` specific handling...
      // NB: ONLY when not using SSO for the user?
      if (error instanceof ServerError && error.statusCode === 404) {
        // TODO: handle properly - currently just returning false for now to mimic the old api response
        // TODO: ..this now indicates the user isn't registered in any/either service/auth provider & isn't mid registration in api `v0.3.5+`
        // TODO: ..so may/will need to be handled differently in the future
        return { type: AuthLoginServiceType.NewUser }
      }
      // this.emit(ServerEventTypeAuth, null) // TESTING
      throw error
    }
  }

  registerUserWithEmailAndPassword = async (email: string, password: string, firstName?: string, lastName?: string, phone?: string) => {
    this.authUser = undefined // TODO: don't just wipe, if its not null halt the login attempt?

    this.updateAuthToken(undefined)
    this.updateAuthRefreshToken(undefined)
    this.updateAuthTFAToken(undefined)

    // await new Promise(resolve => setTimeout(resolve, 1000))
    // throw new ServerError('DUMMY ERROR') // DEBUG ONLY <<<
    // return

    try {
      const headers = this.getDeviceAuthHeaders()
      const data: any = { email: email, password: password }
      if (firstName) data.name = firstName
      if (lastName) data.last_name = lastName
      if (phone) data.phone_number = phone
      const response = await this._apiClient.apiPost('/auth/register', data, headers)

      let user: User | undefined
      if (response.status === 201) {
        if (response.data) {
          if (response.data.result) {
            // TESTING: re-use the login response processing as the register response is now the same
            user = this._processLoginSuccessResponse(response)
          }
        }
      } else {
        console.log('ServerAuthAPI - registerUserWithEmailAndPassword - WARNING: UNEXPECTED STATUS CODE: ', response.status)
        // TODO: throw an error?
      }
      this.emit(ServerEventTypeAuth, user) // TODO: no need to re-call this? the _processLoginSuccessResponse() call above does already??
      return user
    } catch (error) {
      console.error('ServerAuthAPI - registerUserWithEmailAndPassword - error: ', error)

      if (error && error instanceof ServerError && error.data && error.data.error_code) {
        const errorCode = error.data.error_code
        if (errorCode === ServerErrorCodes.passwordPolicy && error.data.cause) {
          console.error('ServerAuthAPI - registerUserWithEmailAndPassword - PASSWORD POLICY ERROR - error.data: ', error.data)
          throw new ServerAuthPasswordPolicyError(error.message, error.data.cause.policyRules, error.data.cause.policyViolations)
        }
      }

      // this.emit(ServerEventTypeAuth, null) // TESTING
      throw error
    }
  }

  loginUserWithEmailAndPassword = async (email: string, password: string) => {
    this.authUser = undefined // TODO: don't just wipe, if its not null halt the login attempt?

    this.updateAuthToken(undefined)
    this.updateAuthRefreshToken(undefined)
    this.updateAuthTFAToken(undefined)

    // await new Promise(resolve => setTimeout(resolve, 1000))
    // throw new ServerError('DUMMY ERROR') // DEBUG ONLY <<<
    // return

    try {
      const headers = this.getDeviceAuthHeaders()
      console.log('ServerAuthAPI - loginUserWithEmailAndPassword - headers: ', headers)
      const data: any = { email: email, password: password }
      const response = await this._apiClient.apiPost('/auth/login', data, headers)
      console.log('ServerAuthAPI - loginUserWithEmailAndPassword - response: ', response)
      let user: User | undefined

      if (response.data) {
        if (response.data.result) {
          // TESTING: 2fa - on successful login with 2fa required we get a 206 'Partial Content' response to indicate it needs action
          // UPDATE: currently returning a 200 with a data response that conains: {..."result": {"tfa_enabled": true, "tfa_token": "..."}}
          if (response.status === 206) {
            console.log('ServerAuthAPI - loginUserWithEmailAndPassword - response.status == 206 - HANDLE 2FA...')
          }
          if (response.data.result.tfa_enabled && response.data.result.tfa_enabled === true) {
            console.log('ServerAuthAPI - loginUserWithEmailAndPassword - TFA_ENABLED - HANDLE 2FA - tfa_token: ', response.data.result.tfa_token)
            if (response.data.result.tfa_token) {
              this.authTFAToken = response.data.result.tfa_token
              // NB: save the tfa token to localStorage, even though we don't have any other user details yet, cache this for page reloads mid-login with 2fa
              // NB: normally would save other params to the user localStorage, but its ok to just save with this value only during a login
              // localStorage.setItem('user', JSON.stringify({
              //   authTFAToken: this.authTFAToken
              // }))
              this.saveAuthUserCache()
            }
            return undefined // TODO: return something to indicate its a 2fa login & the first part was a success & the 2nd part needs actioning
          }

          user = this._processLoginSuccessResponse(response)
        }
      }
      return user
    } catch (error) {
      console.error('ServerAuthAPI - loginUserWithEmailAndPassword - error: ', error)

      // TODO: just moved here from the higher Server wrapper - finish porting/implementing auth errors here & then all the other auth api calls...
      if (error && error.response) {
        console.error('Server - loginUserWithEmailAndPassword - error.response: ', error.response)
        // Unauthorized error
        if (error.response.status === 401) {
          // TODO: check for email not verified response
          // TODO: check for 2fa requirement?

          // generic error message handling
          if (error.response.data && error.response.data.status && error.response.data.status === 'Error' && error.response.data.error) {
            // TODO: add a more specific error type?
            throw new ServerError('Error: ' + error.response.data.error, error.response.data.status)
          }
        }
        // TODO: handle other error types...
      }
      throw error
    }
  }

  registerUserWithSSOToken = async (email: string, loginServiceId: number, companyServiceId: number, ssoToken: string, firstName?: string, lastName?: string, phone?: string) => {
    console.log('ServerAuthAPI - registerUserWithSSOToken - email:', email, ' loginServiceId:', loginServiceId, ' companyServiceId:', companyServiceId, ' ssoToken:', ssoToken, ' firstName:', firstName, ' lastName:', lastName, ' phone:', phone)
    this.authUser = undefined // TODO: don't just wipe, if its not null halt the login attempt?

    this.updateAuthToken(undefined)
    this.updateAuthRefreshToken(undefined)
    this.updateAuthTFAToken(undefined)

    // await new Promise(resolve => setTimeout(resolve, 1000))
    // throw new ServerError('DUMMY ERROR') // DEBUG ONLY <<<
    // return

    try {
      const headers = this.getDeviceAuthHeaders()
      const data: any = { email: email }
      // okta_token / auth0_token
      if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC) {
        data.okta_token = ssoToken
      } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML) {
        data.okta_token = ssoToken // TODO: check this is correct for okta saml <<<<
      } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_AUTH0) {
        data.auth0_token = ssoToken
      } else {
        throw new Error('Invalid login service')
      }
      if (firstName) data.name = firstName
      if (lastName) data.last_name = lastName
      if (phone) data.phone_number = phone

      const response = await this._apiClient.apiPost('/auth/register?cls_id=' + companyServiceId, data, headers) // login_service_id= loginServiceId

      let user: User | undefined
      if (response.status === 201) {
        if (response.data) {
          if (response.data.result) {
            // TESTING: re-use the login response processing as the register response is now the same
            user = this._processLoginSuccessResponse(response)
          }
        }
      } else {
        console.log('ServerAuthAPI - registerUserWithSSOToken - WARNING: UNEXPECTED STATUS CODE: ', response.status)
        // TODO: throw an error?
      }
      this.emit(ServerEventTypeAuth, user) // TODO: no need to re-call this? the _processLoginSuccessResponse() call above does already??
      return user
    } catch (error) {
      console.error('ServerAuthAPI - registerUserWithSSOToken - error: ', error)

      // if (error && error instanceof ServerError && error.data && error.data.error_code) {
      //   const errorCode = error.data.error_code
      //   if (errorCode === ServerErrorCodes.passwordPolicy && error.data.cause) {
      //     console.error('ServerAuthAPI - registerUserWithSSOToken - PASSWORD POLICY ERROR - error.data: ', error.data)
      //     throw new ServerAuthPasswordPolicyError(error.message, error.data.cause.policyRules, error.data.cause.policyViolations)
      //   }
      // }

      // this.emit(ServerEventTypeAuth, null) // TESTING
      throw error
    }
  }

  loginWithSSOToken = async (email: string, loginServiceId: number, companyServiceId: number, ssoToken: string) => {
    console.log('ServerAuthAPI - loginWithSSOToken - email:', email, ' loginServiceId:', loginServiceId, ' companyServiceId:', companyServiceId, ' ssoToken:', ssoToken)
    this.authUser = undefined // TODO: don't just wipe, if its not null halt the login attempt?

    this.updateAuthToken(undefined)
    this.updateAuthRefreshToken(undefined)
    this.updateAuthTFAToken(undefined)

    // await new Promise(resolve => setTimeout(resolve, 1000))
    // throw new ServerError('DUMMY ERROR') // DEBUG ONLY <<<
    // return

    try {
      const headers = this.getDeviceAuthHeaders()
      console.log('ServerAuthAPI - loginWithSSOToken - headers: ', headers)
      const data: any = {
        email: email
      }
      if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC) {
        data.okta_token = ssoToken
      } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML) {
        data.okta_token = ssoToken
      } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_AUTH0) {
        data.auth0_token = ssoToken
      }
      console.log('ServerAuthAPI - loginWithSSOToken - data: ', data)

      // TESTING: new api `v0.3.13` SSO changes - specify the `cls_id` arg instead of `login_service_id`
      let apiPath = '/auth/login?cls_id=' + companyServiceId
      // TEMP: legacy support for api versions BEFORE `v0.3.13`
      // TODO: DEPRECIATE THIS ONCE ALL SERVERS ARE UPDATED TO API VERSION `v0.3.13+`
      if (companyServiceId === undefined) {
        console.log('ServerAuthAPI - loginWithSSOToken - LEGACY API DETECTED - FALLBACK HANDLING...')
        apiPath = '/auth/login?login_service_id=' + loginServiceId
      }
      const response = await this._apiClient.apiPost(apiPath, data, headers) // login_service_id= loginServiceId
      console.log('ServerAuthAPI - loginWithSSOToken - response: ', response)
      let user: User | undefined
      if (response.data) {
        if (response.data.result) {
          // NB: NOT handling 2fa responses for okta/auth0 sso logins as our 2fa shouldn't be used/enabled for sso based login accounts (they should use the okta/auth0 2fa/mfa instead)
          user = this._processLoginSuccessResponse(response)
        }
      }
      return user
    } catch (error) {
      console.error('ServerAuthAPI - loginWithSSOToken - error: ', error)

      // TODO: just moved here from the higher Server wrapper - finish porting/implementing auth errors here & then all the other auth api calls...
      if (error && error.response) {
        console.error('Server - loginWithSSOToken - error.response: ', error.response)
        // Unauthorized error
        if (error.response.status === 401) {
          // TODO: check for email not verified response
          // TODO: check for 2fa requirement? (or not valid/needed for okta/auth0 sso based logins, as that should have already been handled by the okta/auth0 side)

          // generic error message handling
          if (error.response.data && error.response.data.status && error.response.data.status === 'Error' && error.response.data.error) {
            // TODO: add a more specific error type?
            throw new ServerError('Error: ' + error.response.data.error, error.response.data.status)
          }
        }
        // TODO: handle other error types...
      }
      throw error
    }
  }

  // TESTING: SAML specific
  loginForSSOLoginService = async (email: string, loginServiceId: number, companyServiceId: number) => {
    console.log('ServerAuthAPI - loginForSSOLoginService - email:', email, ' loginServiceId:', loginServiceId, ' companyServiceId:', companyServiceId)
    try {
      const headers = this.getDeviceAuthHeaders()
      console.log('ServerAuthAPI - loginForSSOLoginService - headers: ', headers)
      const data: any = {
        email: email
      }
      // const apiPath = '/auth/login/sso/' + email + '?cls_id=' + companyServiceId
      let apiPath = '/auth/login/sso' + '?cls_id=' + companyServiceId

      // NB: enable local redirect if running in local debug/dev mode, instead of redirecting to the hosted web-app dns for the server (e.g. force it to redirect to http://localhost:3000)
      if (DEBUG_MODE) {
        apiPath += '&redirect_local=true'
      }

      const response = await this._apiClient.apiPost(apiPath, data, headers) // login_service_id= loginServiceId
      console.log('ServerAuthAPI - loginForSSOLoginService - response: ', response)
      // const response = await this._apiClient.apiGet(apiPath, headers) // login_service_id= loginServiceId
      // console.log('ServerAuthAPI - loginForSSOLoginService - response: ', response)
      // TODO: <<<<

      if (response.data?.result?.entry_point) {
        // TESTING: redirect to the sso login url
        window.location.href = response.data?.result?.entry_point
      } else {
        console.error('ServerAuthAPI - loginForSSOLoginService - ERROR: no entry_point in response')
        throw new Error('Invalid response')
      }

      // TESTING: alternative `fetch` based calls - fetch args/options ref: https://javascript.info/fetch-api
      // const apiHeaders = this._apiClient.getApiHeaders(headers)
      // console.log('ServerAuthAPI - loginForSSOLoginService - apiHeaders: ', apiHeaders)
      // const requestOptions = {
      //   method: 'GET', // 'POST',
      //   headers: {
      //     ...apiHeaders,
      //     ...{
      //       // Accept: 'application/json',
      //       'Content-Type': 'application/json'
      //     }
      //   }
      //   // redirect: 'error' // 'manual'
      //   // mode: 'no-cors'
      //   // body: JSON.stringify(data)
      // }
      // console.log('ServerAuthAPI - loginForSSOLoginService - requestOptions: ', requestOptions)
      // const apiBaseUrl = this._apiClient.apiBaseUrl
      // const response = await fetch(apiBaseUrl + apiPath, requestOptions as any)
      // console.log('ServerAuthAPI - loginForSSOLoginService - response: ', response)
      // const responseData = await response.json()
      // console.log('ServerAuthAPI - loginForSSOLoginService - responseData: ', responseData)
      // // TODO: <<<<

      // TESTING: directly redirect to the api url (NB: currently won't work as required a `device-uuid` header which we can't set this way)
      // window.location.href = this._apiClient.apiBaseUrl + apiPath
    } catch (error) {
      console.error('ServerAuthAPI - loginForSSOLoginService - error: ', error)
      throw error
    }
  }

  // TODO: is this needed? DEPRECIATE if not
  loginUserWithTFACode = async (_code: string) => {
    console.warn('ServerAuthAPI - loginUserWithTFACode - TODO: NOT IMPLEMENTED?')

    // make sure we have a the authTFAToken cached from the initial password login
    if (this.authTFAToken) {
      // TODO: ?
    }
    return null
  }

  // tvOS login (& later other external devices)
  loginExternalDevice = async (deviceUUID: string) => {
    console.log('ServerAuthAPI - loginExternalDevice - deviceUUID: ', deviceUUID)
    try {
      const headers = this.getDeviceAuthHeaders()
      console.log('ServerAuthAPI - loginExternalDevice - headers: ', headers)
      const response = await this._apiClient.apiGet('/auth/device/code/' + deviceUUID, headers)
      console.log('ServerAuthAPI - loginExternalDevice - response: ', response)
      if (response.status === 200) {
        return true // NB: just return a simple success/error bool result for now
      }
      throw new Error('Device login failed') // NB: generic fallback error
    } catch (error) {
      console.error('ServerAuthAPI - loginExternalDevice - error: ', error)
      throw error
    }
  }

  resendVerifyEmail = async () => {
    // TODO: only allow this call if the user is flagged as unverified?
    const email = this.authUser?.email
    if (!email) {
      console.error('ServerAuthAPI - resendVerifyEmail - ERROR: authUser has no email set')
      return false // TODO: throw an error?
    }
    try {
      const response = await this._apiClient.apiPost('/auth/resend_email', { email: email })
      if (response.status === 200 || response.status === 201) {
        return true
      }
    } catch (error) {
      console.error('ServerAuthAPI - loginUserWithEmailAndPassword - error: ', error)
      // throw error // NB: for now we just return a simple success/error bool result
    }
    return false
  }

  verifyEmailToken = async (verifyToken: string) => {
    if (!verifyToken || verifyToken.length === 0) {
      return false
    }
    try {
      const response = await this._apiClient.apiGet('/auth/verify/' + verifyToken)
      if (response.status === 200 || response.status === 201) {
        const user = await this.loadLoggedInUser()
        this.loading = false
        this.emit(ServerEventTypeAuth, user)

        return true
      }
    } catch (error) {
      console.error('ServerAuthAPI - verifyEmailToken - error: ', error)
      // throw error // NB: for now we just return a simple success/error bool result
    }
    return false
  }

  forgotEmailPassword = async (email: string) => {
    const data: {[key: string]: any} = {
      email: email
    }
    try {
      const response = await this._apiClient.apiPost('/auth/forgot_pass/', data)
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerAuthAPI - forgotEmailPassword - error: ', error)
      throw error /// / NB: for now we just return a simple success/error bool result
    }
  }

  resetEmailPassword = async (resetToken: string, newPassword: string) => {
    const data: {[key: string]: any} = {
      password: newPassword
    }
    try {
      const response = await this._apiClient.apiPost('/auth/reset_pass/', data, { 'reset-pass-token': resetToken })
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerAuthAPI - resetEmailPassword - error: ', error)
      throw error /// / NB: for now we just return a simple success/error bool result
    }
  }

  logout = () => {
    this.authUser = undefined
    this.updateAuthToken(undefined)
    this.updateAuthRefreshToken(undefined)
    this.updateAuthTFAToken(undefined)
    this.authEmail = undefined
    this.authName = undefined
    // TODO: DEPRECIATE - org/project forced 2fa switch auth
    // this.updateCompanyAuthToken(undefined)
    // this.updateProjectAuthToken(undefined)
    // NB: we don't clear the authDeviceUUID/deviceUUID on logout
    // TODO: clear cached versions of the token(s) in other classes?
    this._authLoadRetryCount = 0
    // localStorage.removeItem('user')
    this.clearAuthUserCache()
    this.emit(ServerEventTypeAuth, undefined)
  }

  tokenExpired = () => {
    console.warn('ServerAuthAPI - tokenExpired - this.loading: ', this.loading, ' this.initalStateLoaded: ', this.initalStateLoaded)
    if (this.initalStateLoaded) {
      this.logout()
    } else {
      this.initalStateLoaded = true
      this.loading = false
      this.logout()
    }
  }

  // handles if a user was logged in & has auth details cached in localStoarge
  initLoggedInUser = async () => {
    // check if an auth token is saved locally, if so use it to trigger a User update
    // TODO: move most/all of this within ServerAuthAPI? (now the user object is also moving inside it)
    const jsonData = localStorage.getItem('user')
    const userData = jsonData ? JSON.parse(jsonData) : undefined
    if (userData) {
      this.updateAuthToken(userData.authToken)
      this.updateAuthRefreshToken(userData.authRefreshToken)
      this.updateAuthTFAToken(userData.authTFAToken)

      // TODO: DEPRECIATE - org/project forced 2fa switch auth
      // this.updateCompanyAuthToken(userData.companyAuthTFAToken)
      // this.updateProjectAuthToken(userData.projectAuthTFAToken)

      // TESTING: if no authToken but there is an authTFAToken (2fa) don't attempt login but leave the tfa token saved (don't trigger a logout either)
      if (!userData.authToken && userData.authTFAToken) {
        console.log('ServerAuthAPI - initLoggedInUser - ONLY 2FA TOKEN SET - HALT LOAD')
        this.initalStateLoaded = true // TESTING HERE: without this you can get stuck on the initial loading screen
        return null
      }

      // load the user data as a way to test the auth token (& trigger a token refresh attempt if its expired/invalid, logout if that fails)
      return await this.loadLoggedInUser()
    } else {
      this.initalStateLoaded = true // not logged in so flag this as true
      return null
    }
  }

  // calls /auth/me to get the auth user object
  // can also be used as a way to validate the current auth token on page (re)loads etc.
  loadLoggedInUser = async (): Promise<User | undefined> => {
    // TODO: halt if not logged in (no auth token)?
    try {
      this.loading = true
      const response = await this._apiClient.apiGet('/auth/me')
      let user: User | undefined
      if (response.data) {
        if (response.data.result) {
          if (response.data.result && response.data.result.id) {
            const userId = response.data.result.id
            user = User.fromJSON(userId, response.data.result) ?? undefined
            this.authUser = user
          }
          // TODO: only update if the details have changed
          // localStorage.setItem('user', JSON.stringify({
          //   id: this.authUser ? this.authUser.id : 0,
          //   authToken: this.authToken,
          //   authRefreshToken: this.authRefreshToken,
          //   authTFAToken: this.authTFAToken
          // }))
          this.saveAuthUserCache()
        }
      }
      this.loading = false
      this.initalStateLoaded = true
      this.emit(ServerEventTypeAuth, user)
      return user
    } catch (error) {
      console.error('ServerAuthAPI - loadLoggedInUser - error: ', error)

      this.loading = false
      this.initalStateLoaded = true

      // NB: the ServerAPIClient (apiGet function call) now handles expired with tokens
      // NB: it attempts to renew the auth token with the refresh token & resumes the original api call on success
      // NB: & triggers a logout if the renwal fails (the refresh token has also expired)

      // if the error isn't an auth token expired one, trigger a logout if we reach here (auth token expiry already calls logout)
      if (!(error instanceof ServerAuthTokenExpiredError)) {
        this.logout()
      }

      throw error
    }
  }

  refreshAuthToken = async () => {
    this.authToken = undefined

    // if authRefreshToken isn't set return with an error instantly (no way to refresh auth without it)
    if (!this.authRefreshToken) {
      return false
    }

    try {
      const response = await this._apiClient.apiGet('/auth/refresh_token', { 'refresh-token': this.authRefreshToken }, false)
      if (response.data) {
        if (response.data.result) {
          if (response.data.result.access_token) {
            this.authToken = response.data.result.access_token
          }
        }
      }

      this._apiClient.authToken = this.authToken

      if (this.authToken) {
        // localStorage.setItem('user', JSON.stringify({
        //   id: this.authUser ? this.authUser.id : 0,
        //   authToken: this.authToken,
        //   authRefreshToken: this.authRefreshToken,
        //   authTFAToken: this.authTFAToken
        // }))
        this.saveAuthUserCache()
        return true
      }
    } catch (error) {
      console.error('ServerAuthAPI - refreshAuthToken - error: ', error)
      // TODO: don't throw here, handle it internally(?)
      // throw error
    }
    return false
  }

  _processLoginSuccessResponse = (response: AxiosResponse<any>): User | undefined => {
    let user: User | undefined

    let responseData = response.data.result
    // TEMP: sso logins currently respond with a sub `loginResponse` object, so we need to handle that here for now
    // TODO: remove this once the api has been updated to return the same response structure for all login types
    if (responseData.loginResponse !== undefined) {
      responseData = responseData.loginResponse
    }

    if (responseData.access_token) {
      this.updateAuthToken(responseData.access_token)
    }
    if (responseData.refresh_token) {
      this.updateAuthRefreshToken(responseData.refresh_token)
    }
    // TODO:
    // TODO: what about this.updateAuthTFAToken(..) is that handled before/separate to this callback?
    // TODO:
    if (responseData.user && responseData.user.id) {
      const userId = responseData.user.id
      user = User.fromJSON(userId, responseData.user) ?? undefined
      this.authUser = user
    }

    this._authLoadRetryCount = 0

    // TODO: DEPRECIATE - org/project forced 2fa switch auth
    // // TESTING: 2fa company/project auth tokens (from the last selections made from this device)
    // // if the server responds with company or project 2fa auth tokens they 'should' be for the current selection(s)
    // // we can't easily check that from the local cache data with the current setup during login
    // // as that data isn't loaded yet, & as user selections are handled separately from the auth side of things currently
    // // so for the time being, if the tokens are present we blindly set them without checking which company/project id they're for
    // // if they're invalid the api calls will fail & indicate a fresh auth is needed anyway, so that shouldn't effect usage if that does somehow happen
    // // the user should just have to enter the new 2fa OTP to get a fresh token instead (although not tested this fully yet, but thats the theory)
    // // TODO: in an ideal world we'd load the local cached data for this user 'during' login (not after), perhaps in just a temp way
    // // TODO: ..& then we'd compare that to the last_company/last_project fields in this 2fa api response (which seem to be the full objects for each data type)
    // // TODO: ..if they match then we load the supplied tokens
    // // TODO: OR:
    // // TODO: even better, we eventually move company/project/channel & section selection (& possibly anything else related)
    // // TODO: ..to the server side instead of local cache & power it all directly off that
    // // TODO: ..but that will require more handling api side & quite a lot of changes in the web-app, so leaving it client side for now
    // if (responseData.last_company && responseData.company_token) {
    //   console.log('ServerAuthAPI - _processLoginSuccessResponse - last_company: ', responseData.last_company.id, ' company_token: ', responseData.company_token)
    //   this.updateCompanyAuthToken(responseData.company_token)
    // }
    // if (responseData.last_project && responseData.project_token) {
    //   console.log('ServerAuthAPI - _processLoginSuccessResponse - last_project: ', responseData.last_project.id, ' project_token: ', responseData.project_token)
    //   this.updateProjectAuthToken(responseData.project_token)
    // }

    // TODO: handle if we have a token but no user? consider all invalid & force re-login?
    // localStorage.setItem('user', JSON.stringify({
    //   id: this.authUser ? this.authUser.id : 0,
    //   authToken: this.authToken,
    //   authRefreshToken: this.authRefreshToken,
    //   authTFAToken: this.authTFAToken
    // }))
    this.saveAuthUserCache()

    console.log('ServerAuthAPI - _processLoginSuccessResponse - emit auth - this.authToken: ', this.authToken)
    this.emit(ServerEventTypeAuth, user)

    // clear temp vars only used during registration/login
    this.authEmail = undefined
    this.authName = undefined

    return user
  }

  // -------

  sendPhoneSMSCode = async (): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiGet('/auth/verify_phone_number')
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerAuthAPI - sendPhoneSMSCode - error: ', error)
      throw error /// / NB: for now we just return a simple success/error bool result
    }
  }

  verifyPhoneSMSCode = async (verifyCode: string): Promise<boolean> => {
    const data: {[key: string]: any} = {
      otp: verifyCode
    }
    try {
      const response = await this._apiClient.apiPut('/auth/verify_phone_number', data)
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerAuthAPI - verifyPhoneSMSCode - error: ', error)
      throw error /// / NB: for now we just return a simple success/error bool result
    }
  }

  // -------

  generate2FA = async (phoneNumber: string) => {
    // TODO: once the api user object tells us if 2fa is enabled check & halt if it is
    try {
      const response = await this._apiClient.apiPost('/auth/2fa/enable/generate', { phone_number: phoneNumber })
      if (response.status === 200 || response.status === 201) {
        const result = response.data.result
        if (result) {
          const tfaToken: string = result.tfa_token ?? null
          const secretOTPAuthUrl: string = result.secret?.otpauth_url ?? null
          const secretBase32: string = result.secret?.base32 ?? null

          // TESTING: WARNING: these user phone fields are only returned on localdev for now, considering adding them to the api (may change structure/naming if they do get added!)
          const phoneNumber = result.phone_number ?? null
          const phoneVerified = result.phone_number_verified ?? null
          const phoneVerifyCodeSent = result.phone_number_verify_sent ?? false

          this.authTFAToken = tfaToken // cache the tfa token

          // TESTING: testing saving it to localStorage to be avilable across page refreshes
          // localStorage.setItem('user', JSON.stringify({
          //   id: this.authUser ? this.authUser.id : 0,
          //   authToken: this.authToken,
          //   authRefreshToken: this.authRefreshToken,
          //   authTFAToken: this.authTFAToken
          // }))
          this.saveAuthUserCache()

          return { tfaToken, secretOTPAuthUrl, secretBase32, phoneNumber, phoneVerified, phoneVerifyCodeSent }
        }
      }
      throw Error('Failed to generate the 2FA token')
    } catch (error) {
      console.error('ServerAuthAPI - generate2FA - error: ', error)
      throw error // NB: for now we just return a simple success/error bool result
    }
  }

  enable2FA = async (otpCode: string) => {
    // TODO: once the api user object tells us if 2fa is enabled check & halt if it is
    // halt if no tfa token to use in the request
    if (!this.authTFAToken) {
      throw new ServerError('No 2fa Token') // TODO: add an auth specific error type?
    }
    try {
      const response = await this._apiClient.apiPost('/auth/2fa/enable/verify', { otp: otpCode }, { 'tfa-token': this.authTFAToken })
      if (response.status === 200 || response.status === 201) {
        // TESTING: refresh the user object, so the 2fa details are updated in the local data
        // TODO: is there more of a 'refresh user' method that should be called instead of 'load'?
        // TODO: should the higher up Server be called/triggered instead & let it trigger user updates & anything else it might need as a result of the user object changing?
        const user = await this.loadLoggedInUser()
        this.emit(ServerEventTypeAuth, user)

        return true
      }
    } catch (error) {
      console.error('ServerAuthAPI - enable2FA - error: ', error)
      if (error.response && error.response.status === 403) {
        const errorCode = error.response?.data?.error_code
        if (errorCode === ServerErrorCodes.auth2FAPhoneNumberNotVerified) {
          throw new ServerAuth2FAPhoneNumberNotVerifiedError(error.response.data.error)
        }
      }
      throw error
    }
    return false
  }

  // NB: disabling 2FA is a 2 step process, fist generate a 2fa disable tfa token with one api call here
  // NB: then call a 2nd endpoint with the tfa token & a valid otp code to confirm the disable action
  // NB: its currently up to the calling code to cache the disable tfa token & supply it in the disable verify step
  // TODO: is this a different tfa token to the one returned by the login & enable 2fa endpoints?
  // TODO: can we ever have a tfa from more than one at the same time, or is it only ever 1 active across all/any 2fa endpoint?
  // TODO: e.g. can we store all tfa tokens in a single var, or do we need one per endpoint (if we are storing/caching all of them)
  disable2FAGenerateTFAToken = async () : Promise<string | null> => {
    try {
      const response = await this._apiClient.apiGet('/auth/2fa/disable/generate')
      if (response.status === 200 || response.status === 201) {
        const result = response.data.result
        if (result && result.tfa_token) {
          return response.data.result.tfa_token
        }
      }
      return null
    } catch (error) {
      console.error('ServerAuthAPI - disable2FAGenerateTFAToken - error: ', error)
      throw error
    }
  }

  // NB: requires the tfa token returned from a separate api call made by disable2FAGenerateTFAToken
  disable2FA = async (otpCode: string, tfaDisableToken: string) => {
    // TODO: once the api user object tells us if 2fa is enabled check & halt if its not
    // halt if no tfa token to use in the request
    // if (!this.authTFAToken) {
    //   throw new ServerError('No 2fa Token') // TODO: add an auth specific error type?
    // }
    try {
      const response = await this._apiClient.apiPost('/auth/2fa/disable/verify', { otp: otpCode }, { 'tfa-token': tfaDisableToken })
      if (response.status === 200 || response.status === 201) {
        // TESTING: refresh the user object, so the 2fa details are updated in the local data
        // TODO: is there more of a 'refresh user' method that should be called instead of 'load'?
        // TODO: should the higher up Server be called/triggered instead & let it trigger user updates & anything else it might need as a result of the user object changing?
        const user = await this.loadLoggedInUser()
        this.emit(ServerEventTypeAuth, user)

        return true
      }
    } catch (error) {
      console.error('ServerAuthAPI - disable2FA - error: ', error)
      throw error
    }
    return false
  }

  // TODO: rename to be login specific (you now also need to verify enabling & disabling of 2fa)
  verify2FA = async (otpCode: string) => {
    // TODO: once the api user object tells us if 2fa is enabled check & halt if its not
    // halt if no tfa token to use in the request
    if (!this.authTFAToken) {
      throw new ServerError('No 2fa Token') // TODO: add an auth specific error type?
    }
    try {
      const response = await this._apiClient.apiPost('/auth/2fa/verify_otp?method=Authenticator', { otp: otpCode }, { 'tfa-token': this.authTFAToken })
      if (response.status === 200 || response.status === 201) {
        const user = this._processLoginSuccessResponse(response)
        return user
      }
    } catch (error) {
      console.error('ServerAuthAPI - verify2FA - error: ', error)
      throw error // TODO: parse & return more specific errors?
    }
    return null
  }

  // -------

  updateUserInfo = async (userId: number, values: {[key: string]: any}): Promise<User> => {
    // TESTING: convert the class property keys to their json equivalent
    const userJSONKeyMap = User.propertyToJSONKeyMap()
    console.log('ServerAuthAPI - updateUserInfo - userJSONKeyMap: ', userJSONKeyMap)
    const data: {[key: string]: any} = {}
    for (const fieldName of Object.keys(values)) {
      console.log('ServerAuthAPI - updateUserInfo - fieldName: ', fieldName, ' = ', (userJSONKeyMap as any)[fieldName])
      if (Object.prototype.hasOwnProperty.call(userJSONKeyMap, fieldName)) {
        // check if the field has a user json key mapped
        const jsonKey = userJSONKeyMap[fieldName as keyof typeof userJSONKeyMap]
        data[jsonKey] = values[fieldName]
      } else {
        // no json key map for this value key - use it directly
        data[fieldName] = values[fieldName]
      }
    }
    console.log('ServerAuthAPI - updateUserInfo - data: ', data, ' length: ', Object.keys(data).length)
    // TODO: halt if data is empty
    if (Object.keys(data).length === 0) {
      throw new Error('No valid fields to update')
    }
    // TODO: don't allow certain fields to be edited via this call, force them to use dedicated update functions instead?
    try {
      const response = await this._apiClient.apiPut('/auth/update', data)
      console.log('ServerAuthAPI - updateProjectInfo - response: ', response)
      if (response.data && response.data.result && response.data.result) {
        const userData = response.data.result
        const updatedUser = User.fromJSON(userData.id, userData)
        if (!updatedUser) {
          throw new Error('Failed to parse user data')
        }
        return updatedUser
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerAuthAPI - updateUserInfo - error: ', error)
      throw error
    }
  }

  // -------
  // company specific 2FA

  setCompany2FARequired = (required: boolean) => {
    console.log('ServerAuthAPI - setCompany2FARequired - required: ', required)
    this.companyAuthTFARequired = required
    // TODO: DEPRECIATE - org/project forced 2fa switch auth
    // this.companyAuthTFAToken = undefined
    // this.emit(ServerEventTypeCompanyAuth, undefined) // TESTING
  }

  // TODO: DEPRECIATE - org/project forced 2fa switch auth
  // verifyCompany2FA = async (companyId: number, otpCode: string): Promise<boolean | undefined> => {
  //   try {
  //     const response = await this._apiClient.apiPost('/company/login', { otp: otpCode }, { 'company-id': companyId })
  //     if (response.status === 200) {
  //       const result = response.data.result
  //       if (result && result.company_token) {
  //         this.updateCompanyAuthToken(result.company_token)
  //         this.emit(ServerEventTypeCompanyAuth, true) // TESTING
  //         return true
  //       }
  //       throw new ServerError('Invalid response')
  //     }
  //   } catch (error) {
  //     console.error('ServerAuthAPI - verifyCompany2FA - error: ', error)
  //     throw error // TODO: parse & return more specific errors?
  //   }
  // }

  // TODO: DEPRECIATE - org/project forced 2fa switch auth
  // updateCompanyAuthToken (companyAuthToken?: string) {
  //   console.log('ServerAuthAPI - updateCompanyAuthToken - companyAuthToken: ', companyAuthToken)
  //   this._apiClient.companyAuthToken = companyAuthToken
  //   this.companyAuthTFAToken = companyAuthToken
  //   if (!this.companyAuthTFARequired && this.companyAuthTFAToken) this.companyAuthTFARequired = true // TESTING: if a company token is being set & the flag isn't enabled update it (if a token is being set this should be the case)
  //   this.saveAuthUserCache()
  // }

  // -------
  // project specific 2FA

  setProject2FARequired = (required: boolean) => {
    console.log('ServerAuthAPI - setProject2FARequired - required: ', required)
    this.projectAuthTFARequired = required
    // TODO: DEPRECIATE - org/project forced 2fa switch auth
    // this.projectAuthTFAToken = undefined
    // this.emit(ServerEventTypeProjectAuth, undefined) // TESTING
  }

  // TODO: DEPRECIATE - org/project forced 2fa switch auth
  // verifyProject2FA = async (companyId: number, projectId: number, otpCode: string): Promise<boolean | undefined> => {
  //   try {
  //     const response = await this._apiClient.apiPost('/projects/login', { otp: otpCode }, { 'company-id': companyId, 'project-id': projectId })
  //     if (response.status === 200) {
  //       const result = response.data.result
  //       if (result && result.project_token) {
  //         this.updateProjectAuthToken(result.project_token)
  //         this.emit(ServerEventTypeProjectAuth, true) // TESTING
  //         return true
  //       }
  //       throw new ServerError('Invalid response')
  //     }
  //   } catch (error) {
  //     console.error('ServerAuthAPI - verifyProject2FA - error: ', error)
  //     throw error // TODO: parse & return more specific errors?
  //   }
  // }

  // TODO: DEPRECIATE - org/project forced 2fa switch auth
  // updateProjectAuthToken (projectAuthToken?: string) {
  //   console.log('ServerAuthAPI - updateProjectAuthToken - projectAuthToken: ', projectAuthToken)
  //   this._apiClient.projectAuthToken = projectAuthToken
  //   this.projectAuthTFAToken = projectAuthToken
  //   if (!this.projectAuthTFARequired && projectAuthToken) this.projectAuthTFARequired = true // TESTING: if a project token is being set & the flag isn't enabled update it (if a token is being set this should be the case)
  //   this.saveAuthUserCache()
  // }

  // -------

  getUserAuthSessions = async () => {
    try {
      const response = await this._apiClient.apiGet('/auth/sessions')
      console.log('ServerAuthAPI - getUserAuthSessions - response: ', response)
      const authSessions: Array<AuthSession> = []
      if (response.status === 200) {
        if (response.data && response.data.result && response.data.result) {
          const sessionsData = response.data.result
          for (const sessionData of sessionsData) {
            const authSession = AuthSession.fromJSON(sessionData)
            if (authSession) authSessions.push(authSession)
          }
        }
      }
      return authSessions
    } catch (error) {
      console.error('ServerAuthAPI - getUserAuthSessions - error: ', error)
      throw error // TODO: parse & return more specific errors?
    }
  }

  updateUserAuthSessionDeviceName = async (deviceUUID: string, deviceName: string) => {
    try {
      const response = await this._apiClient.apiPut('/auth/sessions/' + deviceUUID, { device_name: deviceName })
      console.log('ServerAuthAPI - updateUserAuthSessionDeviceName - response: ', response)
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerAuthAPI - updateUserAuthSessionDeviceName - error: ', error)
      throw error
    }
  }

  logoutUserAuthSession = async (deviceUUID: string) => {
    try {
      const response = await this._apiClient.apiDelete('/auth/sessions?device_uuid=' + deviceUUID, {})
      console.log('ServerAuthAPI - logoutUserAuthSession - response: ', response)
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerAuthAPI - logoutUserAuthSession - error: ', error)
      throw error
    }
  }

  logoutAllUserAuthSessions = async () => {
    try {
      const response = await this._apiClient.apiDelete('/auth/sessions', {})
      console.log('ServerAuthAPI - logoutAllUserAuthSessions - response: ', response)
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerAuthAPI - logoutAllUserAuthSessions - error: ', error)
      throw error
    }
  }

  // -------

  // returns an object with the user/client device headers used to identify this login auth session
  getDeviceAuthHeaders = () => {
    // load the main device details (from the browser user agent)
    const deviceData = deviceDetect(undefined)
    // console.log('ServerAuthAPI - getDeviceHeaders - deviceData: ', deviceData, ' deviceType: ', deviceType, ' deviceData.osName: ', deviceData.osName, ' osName: ', osName, 'osVersion: ', osVersion, ' deviceData.browserName: ', deviceData.browserName, ' browserName: ', browserName)
    // construct the user friendly display name (other fields are mainly for identifying a particular session)
    let deviceName = osName + ' ' + browserName + ' ' + 'Web Browser'
    if (deviceType === 'mobile' || deviceType === 'tablet') {
      deviceName = mobileModel + ' ' + browserName // + deviceData.osName +
    }
    // contruct the header object with all device/client fields ready to append to the api call headers that uses them (currently registration & login endpoints)
    const headers: any = {
      'device-name': deviceName,
      'device-os': osName.toLowerCase() + '~' + osVersion.toLowerCase(), // e.g. 'mac os~10.x.x'
      // WARNING: the api doesn't not support/use these yet - added as an example for hopeful addition soon
      // TODO: check back once the api supports these (or similar) & make sure all field names are correct, adapt as needed...
      'device-type': deviceType, // desktop/mobile/tablet (NB: currently returns 'browser' for desktop chrome, think desktop will always be 'browser' for this field?)
      client: browserName.toLowerCase() + '~' + (deviceData?.browserMajorVersion ?? browserVersion).toLowerCase(), // NB: using browserVersion as a fallback if deviceData?.browserMajorVersion isn't available (iOS?) - TODO: drop any point versions from the fallback full version?
      platform: 'web'
    }
    console.log('ServerAuthAPI - getDeviceHeaders - headers: ', headers)
    return headers
  }

  // -------

  saveAuthUserCache = () => {
    // NB: during 2fa login we call this when we only have the authTFAToken
    // NB: so we conditionally add the fields only if they're set to support this
    localStorage.setItem('user', JSON.stringify({
      ...(this.authUser ? { id: this.authUser ? this.authUser.id : 0 } : {}),
      ...(this.authToken ? { authToken: this.authToken } : {}),
      ...(this.authRefreshToken ? { authRefreshToken: this.authRefreshToken } : {}),
      ...(this.authTFAToken ? { authTFAToken: this.authTFAToken } : {})
      // TODO: DEPRECIATE - org/project forced 2fa switch auth
      // ...(this.companyAuthTFAToken ? { companyAuthTFAToken: this.companyAuthTFAToken } : {}),
      // ...(this.projectAuthTFAToken ? { projectAuthTFAToken: this.projectAuthTFAToken } : {})
    }))
  }

  clearAuthUserCache = () => {
    localStorage.removeItem('user')
  }

  // -------

  // save basic SSO auth details to reload after returning from the SSO login page
  // TODO: add provider/service type?
  saveAuthSSOCache = (serviceType: AuthLoginServiceType, email: string) => {
    console.log('ServerAuthAPI - saveAuthSSOCache - email:', email)
    localStorage.setItem('sso', JSON.stringify({
      serviceType,
      email
    }))
  }

  getAuthSSOCache = (): { serviceType: AuthLoginServiceType, email: string } | undefined => {
    const jsonData = localStorage.getItem('sso')
    const ssoData = jsonData ? JSON.parse(jsonData) : undefined
    console.log('ServerAuthAPI - getAuthSSOCache - ssoData:', ssoData)
    return ssoData
  }

  clearAuthSSOCache = () => {
    localStorage.removeItem('sso')
  }

  // -------

  // checks if a uuid has been saved for this user (browser & possibly session specific)
  // loads it if so, creates & saves one to the localStorage if not
  loadDeviceUUID = async () => {
    const jsonData = localStorage.getItem('device')
    const deviceData = jsonData ? JSON.parse(jsonData) : null
    if (deviceData && deviceData.uuid) {
      this.authDeviceUUID = deviceData.uuid
    } else {
      const deviceUUID = this.generateUUID()
      localStorage.setItem('device', JSON.stringify({
        uuid: deviceUUID
      }))
      this.authDeviceUUID = deviceUUID
    }
    this._apiClient.authDeviceUUID = this.authDeviceUUID // update the client so it gets added to all requests
  }

  // ref: https://stackoverflow.com/a/8809472
  generateUUID = () => {
    let d = new Date().getTime() // Timestamp
    let d2 = (performance && performance.now && (performance.now() * 1000)) || 0 // Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 // random number between 0 and 16
      if (d > 0) { // Use timestamp until depleted
        r = (d + r) % 16 | 0
        d = Math.floor(d / 16)
      } else { // Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0
        d2 = Math.floor(d2 / 16)
      }
      // return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16) // TODO: testing wrapping 'r & 0x3' in brackets to fix the a 'Unexpected mix of '&' and '|'' warning, is that the correct way to apply brackets to this??
    })
  }
}

export default ServerAuthAPI
