/**
 * CompanyUserListItem
 */

import React from 'react'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkListItem, { ArkListItemDetail } from 'src/core/components/ArkListItem'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { CompanyUser } from 'src/core/models'

interface CompanyUserListItemProps {
  active: boolean
  filter?: string
  onClick: () => void
  user: CompanyUser
}

const CompanyUserListItem = (props: CompanyUserListItemProps) => {
  const {
    active,
    filter,
    onClick,
    user
  } = props

  const leftComponent = (
    <ArkAvatar
      type={user.userAvatarType()}
      name={user.name()}
      size='40'
    />
  )

  const rightComponentDesktop = (
    <>
      <ArkListItemDetail title='Role'>
        {user.companyRoleName().toUpperCase()}
      </ArkListItemDetail>
      <ArkSpacer />
      <ArkListItemDetail title='Status'>
        {user.companyStatusName()}
      </ArkListItemDetail>
    </>
  )

  const title = user.nameIsEmail() ? '⚠️ Name is pending registration' : user.name() + (user.isSiteAdmin() ? ' - REPRO ADMIN ⚠️' : '')
  const desc = user.isGuest ? 'GUEST LINK' : user.email

  return (
    <ArkListItem
      active={active}
      description={desc}
      filter={filter}
      leftComponent={leftComponent}
      onClick={onClick}
      rightComponentDesktop={rightComponentDesktop}
      title={title}
    />
  )
}

export default CompanyUserListItem
