import React from 'react'

import ArkForm from '../ArkForm/ArkForm'

import { Header, Table, Message } from 'semantic-ui-react'

import ArkButton from '../ArkButton'
import ArkCheckbox, { ArkCheckboxProps } from '../ArkCheckbox'
import ArkLoaderView from '../ArkLoader'
import ArkSpacer from '../ArkSpacer'

import styles from './ArkDataMappingForm.module.css'

// TESTING:
// created a custom component to manage data mapping of multiple items to another item (e.g. channels to groups)

// NB: currently only focusing on simple adding & removal of items to a target
// TODO: some data type mappings (e.g. channel>programs) also have params that can be set for each mapped item,
// TODO: ..to support that we'll need to support more than just the 'selectedIds' currently (it'll need to be an object per item)
// TODO: ..& we'd need to add a 3rd operation 'update' type & handling for it...

// NB: slightly re-worked how item data is input & its usage, so its closer to how the `ArkManagerListView` works, did it while adding filtering support easier & allowing more customised item rendering

export type ItemSchema = { id: number }

export interface ArkManagerFilteredItem<ItemType extends ItemSchema> {
  item: ItemType
  matchingFields: Array<string>
}

export type ArkDataMappingItemChanges = { add: Array<number>, del: Array<number> }

export type ArkDataMappingSaveCallback = (mappedIds?: Array<number>, changes?: ArkDataMappingItemChanges) => void

interface IProps<ItemType extends ItemSchema> {

  sourceItems: Array<ItemType> // all available items to choose from
  mappedIds?: Array<number> // the ids of the currently enabled/added/mapped items
  isLoading?: boolean // enable when the parent component is (re)loading the source or mapped data
  isSaving?: boolean // enable when the parent component is process a save call from onSave (its up to the parent component to manage the data state) NB: this now triggers an update of selected ids from the prop values instead of local state when it goes from true to false
  title?: string // React.ReactNode // optional title to show
  id?: string // to identify this form from others (used as a field id prefix to stop checkbox & potentially other fields intefering with other forms on the same page)
  disabled?: boolean // shows the mapping form but disables the toggles & the save button (for use on items that can't be mapped in certain scenarios, like default group > users)

  filteredSourceItems?: Array<ArkManagerFilteredItem<ItemType>> // the source items to show if currently filtering them
  filterText?: string // the current filter query/text if one is currently being used (so we can highlight the relevant text)
  filterForm?: React.ReactNode

  itemRow: (item: ItemType, isMapped: boolean) => React.ReactNode // specify a callback to render each item
  itemCompare?: (newItem: ItemType, oldItem: ItemType) => boolean // optional callback fires for each item when `componentDidUpdate` triggers - return false if any of its fields changed, if omitted the default check only checks for chanegs of id (so if the array order changed or items were added/removed?)

  onCancel?: Function
  onSave?: ArkDataMappingSaveCallback
  onClearFilter?: Function

  successMessage?: string | React.ReactNode
  errorMessage?: string | React.ReactNode
  disabledMessage?: string | React.ReactNode

  noItemsMessage?: string | React.ReactNode
  noItemsBtnTitle?: string
  noItemsBtnOnClick?: Function

  className?: string
}
interface IState {
  newMappedIds: Array<number>
}

class ArkDataMappingForm<ItemType extends ItemSchema> extends React.Component<IProps<ItemType>, IState> {
  constructor (props: IProps<ItemType>) {
    super(props)
    this.state = {
      newMappedIds: []
    }
  }

  componentDidMount () {
    this.updateMappedItemsFromProps()
  }

  componentDidUpdate (prevProps: IProps<ItemType>, _prevState: IState) {
    // TESTING: check for sourceItems changes - ref: https://stackoverflow.com/a/42995029
    const sourceItemsDiff = this.props.sourceItems.filter((item1) => {
      // NB: inverting the response from the `some(..)` call using a `!` prefix, just incase you missed it ;)
      return !prevProps.sourceItems.some((item2) => {
        // use the supplied comparison callback if one is specified, fallback to a basic id only check if not
        // NB: (which would only pick up if the items array order changed, or items were added/removed so ids no longer match the previous array?)
        // NB: original code `ArkDataMappingItem.compare(item1, item2)` checked if the `id` & `title` had changed
        // NB: ..(before we defaulted to only an `id` as a required field & left it up to the calling code to handle their other related fields)
        let compare = false
        if (this.props.itemCompare !== undefined) {
          compare = this.props.itemCompare(item1, item2)
        } else {
          compare = item1.id === item2.id
        }
        // if (compare) console.log('ArkDataMappingForm - componentDidUpdate - sourceItemsDiff - item1: ', item1, 'item2: ', item2)
        return compare
      })
    })
    // console.log('ArkDataMappingForm - componentDidUpdate - sourceItemsDiff: ', sourceItemsDiff)

    // check for mappedIds changes
    const mappedIds = this.props.mappedIds ?? []
    const oldMappedIds = prevProps.mappedIds ?? []
    const mappedIdsChanged = (JSON.stringify([...mappedIds].sort()) !== JSON.stringify([...oldMappedIds].sort())) // NB: making a copy (using [...array]) so sort is called on a copy of the data, not the original! (sorts in place)
    // console.log('ArkDataMappingForm - componentDidUpdate - mappedIdsChanged: ', mappedIdsChanged)

    // TESTING: catch when saving finishes (isSaving transitions from true to false) & trigger the selected ids to update from the props, clearing whatever was in the local newMappedIds state array (so if any save errors the current ids state can be updated from the passed in props to reflect the end result instead of remaining in their altered checked/unchecked states)
    const saveFinished = (prevProps.isSaving === true && this.props.isSaving === false)

    if (sourceItemsDiff.length > 0 || mappedIdsChanged || saveFinished) {
      console.log('ArkDataMappingForm - componentDidUpdate - updateMappedItemsFromProps...')
      // TESTING: this will override any unsaved/not-submitted changes to the form!
      this.updateMappedItemsFromProps()
    }
  }

  render () {
    const {
      sourceItems,
      title,
      isSaving,
      disabled,
      onCancel,
      successMessage,
      errorMessage,
      disabledMessage,
      noItemsMessage,
      noItemsBtnTitle,
      noItemsBtnOnClick,
      className,
      id
    } = this.props
    const hasChanged = false // TODO: <<< check if any changes from the source data & disable the save button until there is
    const hasItems = sourceItems.length > 0
    return (
      <>
        {title && (<Header as="h3" inverted>{title}</Header>)}

        {successMessage && React.isValidElement(successMessage) && (successMessage)}
        {successMessage && !React.isValidElement(successMessage) && (<>
          <Message positive className={styles.msg}>
            <Message.Header>SAVED</Message.Header>
            <p>{successMessage}</p>
          </Message>
        </>)}

        {errorMessage && React.isValidElement(errorMessage) && (errorMessage)}
        {errorMessage && !React.isValidElement(errorMessage) && (<>
          <Message negative className={styles.msg}>
            <Message.Header>{title ?? ''} ERROR</Message.Header>
            <p>{errorMessage}</p>
          </Message>
        </>)}

        {disabled && disabledMessage && React.isValidElement(disabledMessage) && (disabledMessage)}
        {disabled && disabledMessage && !React.isValidElement(disabledMessage) && (
          <Message color='yellow' className={styles.msg}>
            <Message.Header>Please Note</Message.Header>
            <p>{disabledMessage}</p>
          </Message>
        )}

        {this.props.filterForm !== undefined && this.props.filterForm}

        <ArkForm formKey={'dataMapping' + (id ? '_' + id : '')} className={className} onSubmit={this.onSubmit}>

          {hasItems && (this.renderItemsTable())}
          {!hasItems && (<div className={styles.noItems}>{noItemsMessage}</div>)}

          <div className={styles.arkFormSubmitButtons}>
            <ArkButton type="button" color="orange" basic size="large" disabled={isSaving} onClick={() => { if (onCancel) onCancel() }} style={{ marginLeft: 5, marginRight: 10 }}>
              Close
            </ArkButton>
            <ArkSpacer grow />
            {!this.props.disabled && hasItems && (
              <ArkButton type="submit" color="blue" basic size="large" floated='right' disabled={this.props.disabled ?? hasChanged} loading={isSaving}>
                Save
              </ArkButton>
            )}
            {!hasItems && noItemsBtnTitle && noItemsBtnOnClick && (
              <ArkButton type="button" color="blue" basic size="large" floated='right' onClick={() => { noItemsBtnOnClick() }}>
                {noItemsBtnTitle}
              </ArkButton>
            )}
          </div>

        </ArkForm>
      </>
    )
  }

  // -------

  updateMappedItemsFromProps = () => {
    // NB: make sure to make a copy of the props array into the local state version, so we don't alter the prop directly!
    // NB: this only makes a shallow copy, which is fine for our base number based arrays
    // WARNING: if changing mappedIds to an array of objects/multidimensional in the future, this will likely need to support deep copies!
    // WARNING: useful ref: https://www.freecodecamp.org/news/how-to-clone-an-array-in-javascript-1d3183468f6a/
    const newMappedIds = this.props.mappedIds ? [...this.props.mappedIds] : []
    this.setState({ newMappedIds })
  }

  // -------

  onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (this.props.isSaving) { return }

    // NB: nothing to validate in this form (all checkboxes)

    // process the selections to get a list of additions & deletions to send to the api
    const oldMappedIds = this.props.mappedIds ?? []
    const mappedIdChanges = this.processChanges(oldMappedIds, this.state.newMappedIds)

    // trigger the onSave callback so the parent component can handle the mapping form submission
    if (this.props.onSave) this.props.onSave(this.state.newMappedIds, mappedIdChanges)
  }

  // -------

  renderItemsTable = () => {
    const { isLoading, sourceItems, filteredSourceItems, filterText, onClearFilter } = this.props
    const { newMappedIds } = this.state
    const isFiltering = filterText !== undefined
    if (isLoading) return <ArkLoaderView message='Loading' />
    return (
      <>
        {this.renderFilteringHeader()}
        {isFiltering && filteredSourceItems?.length === 0 && (
          <div className={styles.noItems}>
            No matches for your search.<br />
            Try using a different term to filter with.<br />
            {onClearFilter !== undefined && (<><span className={styles.filterClear} onClick={() => { onClearFilter() }}>(CLEAR FILTER)</span></>)}
          </div>
        )}
        <Table inverted collapsing /* singleLine selectable */ className={styles.itemsTable}>
          <Table.Body>
            {this.renderTableRows((filterText !== undefined ? (filteredSourceItems ?? []) : sourceItems), newMappedIds, filterText)}
          </Table.Body>
        </Table>
        {this.renderFilteringFooter()}
      </>
    )
  }

  renderTableRows = (items: Array<ItemType> | Array<ArkManagerFilteredItem<ItemType>>, mappedIds: Array<number>, _filterText?: string) => {
    const { itemRow } = this.props
    const selectedItem: any = null // TODO: if we want to support selection highlighting? <<<<
    return items.map((i: ItemType | ArkManagerFilteredItem<ItemType>) => {
      // TESTING: TS type guard check - ref: https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
      let item: ItemType
      // let matchingFields: Array<string> | undefined
      if ((i as ArkManagerFilteredItem<ItemType>).matchingFields !== undefined) {
        item = (i as ArkManagerFilteredItem<ItemType>).item
        // matchingFields = (i as ArkManagerFilteredItem<ItemType>).matchingFields
      } else {
        item = i as ItemType
      }
      const itemSaveError = undefined // saveErrors && saveErrors.get(channel.id) // TODO: PORT - do we need/get item specific errors? <<<<
      const itemSaved = false // saveError === undefined && savedIds && savedIds.includes(channel.id) ? true : false // TODO: <<<<
      const isChecked = mappedIds.includes(item.id)
      return (
        <Table.Row
          key={'item_' + item.id}
          active={selectedItem && selectedItem.id === item.id}
          onClick={() => { /* this.setState({ selectedChannel: channel }) */ }}
          error={itemSaveError !== undefined}
          positive={itemSaved}
        >
          <Table.Cell className={styles.itemCheckbox}>
            <ArkCheckbox toggle
              id={(this.props.id ?? '') + 'item_' + item.id}
              name={'item_' + item.id}
              checked={isChecked}
              disabled={this.props.disabled}
              onChange={(event: React.FormEvent<HTMLInputElement>, data: ArkCheckboxProps) => {
                // keep the mappedIds array in-sync with the form edits
                const mappedIds = this.state.newMappedIds
                if (data.checked) {
                  mappedIds.push(item.id)
                } else {
                  const index = mappedIds.indexOf(item.id)
                  if (index >= 0) {
                    mappedIds.splice(index, 1)
                  }
                }
                this.setState({ newMappedIds: mappedIds })
              }}
            />
          </Table.Cell>
          <Table.Cell className={styles.itemTitle}>
            {itemRow(item, isChecked)}
            {itemSaveError !== undefined && (<>
              <br />
              ERROR: {/* saveError.errorMessage // TODO: PORT - see TODO further up - do we need/get item specific errors, maybe just highlight it in red but no item specific msg? <<<<<< */}
            </>)}
          </Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>
      )
    })
  }

  // -------

  renderFilteringHeader = () => {
    const { filterText, filteredSourceItems, onClearFilter } = this.props
    const isFiltering = filterText !== undefined
    if (!isFiltering) return null
    const filteredItemCount = filteredSourceItems?.length ?? 0
    return (
      <div className={styles.filterHeader}>
        FILTER: &lsquo;{filterText}&rsquo; = {filteredItemCount} match{filteredItemCount !== 1 ? 'es' : ''} found
        {onClearFilter !== undefined && (<>&nbsp;<span className={styles.filterClear} onClick={() => { onClearFilter() }}>(CLEAR FILTER)</span></>)}
      </div>
    )
  }

  renderFilteringFooter = () => {
    const { filterText, filteredSourceItems, sourceItems } = this.props
    const isFiltering = filterText !== undefined
    if (!isFiltering) return null
    const hiddenSourceItemsCount = sourceItems.length - (filteredSourceItems?.length ?? 0)
    return (
      <div className={styles.filterFooter}>
        NOTE: {hiddenSourceItemsCount} hidden item{hiddenSourceItemsCount !== 1 ? 's' : ''} with current filter
      </div>
    )
  }

  // -------

  // TESTING: compares 2 versions of the same arrays & returns the additions & deletions to get from the old array to the new one
  // UPDATE: flipped from comparing Channel objects to simpler channel id numbers
  // TODO: to support later 'update' detection for extended data type mappings this will likely need flipping back to an object so the extra params can be supplied & compared against
  // TODO: can this be made to use generics (if flipping back to object comparisons) so we can reuse this for any data type (that maybe extends/inherits from a class prototype with an 'id' field?)
  processChanges = (oldItems: Array<number>, newItems: Array<number>) : ArkDataMappingItemChanges => {
    const addItems: Array<number> = []
    const delItems: Array<number> = []
    // find all new items added
    for (const newItem of newItems) {
      let existingItem = false
      for (const oldItem of oldItems) {
        if (newItem === oldItem) {
          existingItem = true
          break
        }
      }
      if (existingItem === false) {
        addItems.push(newItem)
      }
    }
    // find any old items removed
    for (const oldItem of oldItems) {
      let removeItem = true
      for (const newItem of newItems) {
        if (oldItem === newItem) {
          removeItem = false
          break
        }
      }
      if (removeItem) {
        delItems.push(oldItem)
      }
    }
    return { add: addItems, del: delItems }
  }
}

export default ArkDataMappingForm
