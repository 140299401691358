import React, { useState, useContext } from 'react'
import * as yup from 'yup'

import { UserContext } from '../../../../core/providers'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from '../../../components/ArkForm/ArkForm'
import ArkSpacer from '../../../../core/components/ArkSpacer'

import { Message } from 'semantic-ui-react'

const formSchema = yup.object().shape({
  firstName: yup.string().min(0).max(255), // .required(), // TODO: set required once api endpoint uses this?
  lastName: yup.string().min(0).max(255) // .required(), // TODO: set required once api endpoint uses this?
})

interface IProps {
  onCancel?: Function
}

const UserEditNameForm = ({ ...props }: IProps) => {
  const userContext = useContext(UserContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasSaved, setHasSaved] = useState(false)
  const [error, setError] = useState<Error>()

  const user = userContext.store.user
  const firstName = user?.firstName
  const lastName = user?.lastName
  console.log('UserEditNameForm - user: ', user)

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    if (isSubmitting) return
    setIsSubmitting(true)
    if (hasSaved) setHasSaved(false)
    if (error) setError(undefined)
    // await new Promise(resolve => setTimeout(resolve, 1000)) // TODO: replace with real api call <<<<<
    const { firstName: fName, lastName: lName } = fieldValues
    // console.log('UserEditNameForm - onFormSubmit - fName: ', fName, ' lName: ', lName)
    try {
      await userContext.actions.updateUserName(fName, lName)
      setIsSubmitting(false)
      setHasSaved(true)
    } catch (error) {
      setIsSubmitting(false)
      setError(error)
    }
  }
  const onCancel = () => { if (props.onCancel) props.onCancel() }

  const formFields: Array<ArkFormField> = []
  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'nameFieldset',
    label: 'Edit Name',
    fields: [
      {
        type: ArkFormFieldType.Group,
        key: 'detailsGroup',
        fields: [
          { type: ArkFormFieldType.Input, key: 'firstName', label: 'First Name', required: true, defaultValue: firstName },
          { type: ArkFormFieldType.Input, key: 'lastName', label: 'Last Name', required: true, defaultValue: lastName }
        ],
        fieldProps: { widths: 'equal' }
      }
    ],
    // fieldProps: { widths: 2, inline: false, grouped: false },
    collapsible: false,
    collapsed: false
  })
  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { onClick: onCancel, floated: 'left' } },
      { type: ArkFormFieldType.OKButton, key: 'submit', label: 'SAVE', fieldProps: { loading: isSubmitting, floated: 'right' } }
    ],
    fieldProps: { widths: 'equal' },
    slimline: true
  })
  return (
    <>
      {(hasSaved) && (
        <Message positive>
          <Message.Header>Name Updated</Message.Header>
          <Message.Item>Your name has been updated successfully</Message.Item>
        </Message>
      )}
      <ArkSpacer size={30} />
      <ArkForm
        formKey="userName"
        inverted
        formError={error}
        formFields={formFields}
        formSchema={formSchema}
        onFormSubmit={onFormSubmit}
        showLabels={true}
        insideModal={true}
      />
    </>
  )
}

export default UserEditNameForm
