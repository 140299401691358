/**
 * ArkPlayerBadge
 */

import React, { ReactNode } from 'react'

import styles from './ArkPlayerBadge.module.css'

export interface ArkPlayerBadgeProps {
  children: ReactNode
  title?: string
}

const ArkPlayerBadge = (props: ArkPlayerBadgeProps) => {
  const { children, title } = props
  return (
    <div className={styles.badge}>
      <div className={styles.leftBorder} />
      <div className={styles.middle}>
        <div className={styles.top}>
          <div className={styles.topLeftBorder} />
          {title && <div className={styles.title}>{title}</div>}
          <div className={styles.topRightBorder} />
        </div>
        <div className={styles.body}>{children}</div>
        <div className={styles.bottomBorder} />
      </div>
      <div className={styles.rightBorder} />
    </div>
  )
}

export default ArkPlayerBadge
