import React from 'react'

import ArkAdminPage from '../../components/ArkAdminPage/ArkAdminPage'

interface IProps {}
interface IState {}

class AdminSettingsPage extends React.Component<IProps, IState> {
  render () {
    return (<ArkAdminPage>
      <div className="admin-settings">
        ADMIN &gt; SETTINGS
      </div>
    </ArkAdminPage>)
  }
}
export default AdminSettingsPage
