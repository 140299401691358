import React, { useEffect, useState } from 'react'

import WatermarkScreenBackground, { WatermarkScreenBackgroundPreset } from './WatermarkScreenBackground'
// import ArkIcon from 'src/core/components/ArkIcon'

import styles from './WatermarkScreenPreview.module.css'

export interface WatermarkScreenPreviewProps {
  screenSize: { width: number, height: number } // the size of the screen area to render in pixels (NB: currently up to the calling code to set it to fit the needed aspect ratio)
  watermarkURL?: string // the watermark image url ready to use directly in a standard img element (e.g. a public url or blob/file ref via URL.createObjectURL())
  scaleXPcnt?: number // the width of the watermark image as a scale percentage based on the screen width
  posXPcnt?: number // the x pos (top left) as a percentage of the screen width
  posYPcnt?: number // the y pos (top left) as a percentage of the screen height
  className?: string
}

const WatermarkScreenPreview = (props: WatermarkScreenPreviewProps) => {
  const { screenSize, watermarkURL, scaleXPcnt = 100, posXPcnt = 0, posYPcnt = 0, className } = props

  const [watermarkImage, setWatermarkImage] = useState<HTMLImageElement | undefined>(undefined)
  const [watermarkImageSize, setWatermarkImageSize] = useState<{ width: number, height: number} | undefined>(undefined)

  // run whenever the watermark image url is updated
  useEffect(() => {
    if (watermarkURL) {
      // clear any previously cached values
      if (watermarkImageSize) setWatermarkImageSize(undefined)
      if (watermarkImage) setWatermarkImage(undefined)
      // create an Image object from the watermarkURL so we can parse its dimensions once its been loaded
      const img = new Image()
      img.onload = () => {
        console.log('WatermarkScreenPreview - img.onload - img: ', img, '<w> x <h>: ', img.width + ' x ' + img.height)
        setWatermarkImageSize({ width: img.width, height: img.height })
      }
      img.src = watermarkURL
      setWatermarkImage(img)
    }
  }, [watermarkURL])

  // show a loading indicator until the watermark image dimensions have loaded & been parsed
  const loading = watermarkImage === undefined || watermarkImageSize === undefined
  if (loading) {
    return (
      <div className={`${styles.preview}${className ? ' ' + className : ''}`} style={{ width: screenSize.width, height: screenSize.height }}>
        <div className={styles.loading}>LOADING...</div>
      </div>
    )
  }

  // if we reach here, the watermark images actual dimensions are now available
  // so we can proceed to calc the size & position to render the watermark preview at (within our mock preview screen area)...
  console.log('WatermarkScreenPreview - watermarkImageSize: ', watermarkImageSize, ' screenSize: ', screenSize, ' scaleXPcnt: ', scaleXPcnt)

  // calc the watermark render width based on the scaleXPcnt - rounded to the nearest whole int
  const watermarkPreviewWidth = Math.round(screenSize.width * (scaleXPcnt / 100))
  // calc the percentage the watermark width was scaled by (so we can apply the same to its height)
  const watermarkPreviewWidthScale = watermarkPreviewWidth / watermarkImageSize.width
  console.log('WatermarkScreenPreview - watermarkPreview - width: ', watermarkPreviewWidth, ' widthScale: ', watermarkPreviewWidthScale)
  // calc the watermark render height based on the width scaling calcs above - rounded to the nearest whole int
  const watermarkPreviewHeight = Math.round(watermarkImageSize.height * watermarkPreviewWidthScale)
  console.log('WatermarkScreenPreview - watermarkPreview - height: ', watermarkPreviewHeight)
  // the final watermark preview render size/dimensions within the (mock preview) screen area
  const watermarkPreviewSize = { width: watermarkPreviewWidth, height: watermarkPreviewHeight }

  // calc the watermark position within the (mock preview) screen area
  const watermarkPreviewPos = { x: 0, y: 0 }
  if (posXPcnt !== 0 || posYPcnt !== 0) {
    watermarkPreviewPos.x = Math.round(screenSize.width * (posXPcnt / 100))
    watermarkPreviewPos.y = Math.round(screenSize.height * (posYPcnt / 100))
  }

  return (
    <div className={`${styles.preview}${className ? ' ' + className : ''}`} style={{ width: screenSize.width, height: screenSize.height }}>
      <WatermarkScreenBackground size={screenSize} className={styles.screenBG} preset={WatermarkScreenBackgroundPreset.ColourGrey}>
        {/* <ArkIcon name='play-circle' size={84} className={styles.playIcon} /> */}
        <div className={styles.img} style={{
          left: watermarkPreviewPos.x,
          top: watermarkPreviewPos.y,
          width: watermarkPreviewSize.width,
          height: watermarkPreviewSize.height
          // NB: opting to not support dynamic opacity & x/y flipping via css so this preview mimic's the servers features closer, instead the passed in watermark image should already have those pre-applied (e.g. via an html canvas export)
          // opacity: (watermarkTransparency / 100),
          // transform: (watermarkFlipHorizontal && !watermarkFlipVeritcally ? 'scaleX(-1)' : (!watermarkFlipHorizontal && watermarkFlipVeritcally ? 'scaleY(-1)' : (watermarkFlipHorizontal && watermarkFlipVeritcally ? 'scale(-1)' : 'none')))
        }}>
          {watermarkURL && (<img src={watermarkURL} width={watermarkPreviewSize.width} height={watermarkPreviewSize.height} />)}
        </div>
      </WatermarkScreenBackground>
    </div>
  )
}

export { WatermarkScreenPreview }
