import React, { useContext, useEffect, useRef, useState } from 'react'
import { Prompt, useHistory } from 'react-router'
import { Location } from 'history'
import _ from 'lodash'

import { Project } from 'src/core/models'
import { ProjectAdminContext, ServerProjectWatermarkDoesNotExistError, ServerProjectWatermarkErrorReadingError, UserContext } from 'src/core/providers'

import ArkButton from 'src/core/components/ArkButton'
import ArkConfirmModal from 'src/core/components/ArkConfirmModal'
import ArkForm, { ArkFormField, ArkFormFieldOption, ArkFormFieldType } from 'src/core/components/ArkForm/ArkForm'
import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkSlider from 'src/core/components/ArkSlider'

import WatermarkCanvas from './WatermarkCanvas'
import WatermarkScreenBackground, { WatermarkScreenBackgroundPreset } from './WatermarkScreenBackground'

import { PROJECT_WATERMARK_DEBUG_ENABLED /*, PROJECT_WATERMARK_ORIGINS_ENABLED */ } from 'src/constants/config'

import styles from './ProjectSettingsWatermarkView.module.css'

enum WatermarkOrigin {
  TopLeft, TopMiddle, TopRight,
  CentreLeft, CentreMiddle, CentreRight,
  BottomLeft, BottomMiddle, BottomRight
}

export interface ProjectSettingsWatermarkViewProps {
}

const ProjectSettingsWatermarkView = (_props: ProjectSettingsWatermarkViewProps) => {
  const userContext = useContext(UserContext)
  const projectAdminContext = useContext(ProjectAdminContext)

  const targetScreenSizeDefault = { width: 1920, height: 1080 }
  const previewScreenSizeDefault = { width: 768, height: 432 }
  const watermarkWidthPcntDefault = 25
  const watermarkOffsetHorizontalPcntDefault = 10
  const watermarkOffsetVerticalPcntDefualt = 10
  const watermarkTransparencyDefault = 100
  const watermarkFlipHorizontalDefault = false
  const watermarkFlipVeritcallyDefault = false
  const watermarkMaxDimensions = targetScreenSizeDefault

  // project data
  const [project, setProject] = useState<Project | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<Error | undefined>(undefined)
  // image upload
  const [imageFile, setImageFile] = useState<File | undefined>(undefined)
  const [imageURL, setImageURL] = useState<string | undefined>()
  const [image, setImage] = useState<HTMLImageElement | undefined>()
  const [imageDimensions, setImageDimensions] = useState<{width: number, height: number} | undefined>()
  const imageInputRef = React.useRef<HTMLInputElement>(null)
  // watermark settings
  const [watermarkWidthPcnt, setWatermarkWidthPcnt] = useState<number>(watermarkWidthPcntDefault)
  // const [watermarkHeightPcnt, setWatermarkHeightPcnt] = useState<number>(100)
  // const [watermarkLockAR, setWatermarkLockAR] = useState<boolean>(true)
  const [watermarkTransparency, setWatermarkTransparency] = useState<number>(watermarkTransparencyDefault)
  const [watermarkFlipHorizontal, setWatermarkFlipHorizontal] = useState<boolean>(watermarkFlipHorizontalDefault)
  const [watermarkFlipVeritcally, setWatermarkFlipVeritcally] = useState<boolean>(watermarkFlipVeritcallyDefault)
  const [watermarkOffsetHorizontalPcnt, setWatermarkOffsetHorizontalPcnt] = useState<number>(watermarkOffsetHorizontalPcntDefault)
  const [watermarkOffsetVerticalPcnt, setWatermarkOffsetVerticalPcnt] = useState<number>(watermarkOffsetVerticalPcntDefualt)
  const [watermarkOrigin/*, setWatermarkOrigin */] = useState<WatermarkOrigin>(WatermarkOrigin.TopLeft)
  // calculated settings
  const [watermarkSize, setWatermarkSize] = useState<{width: number, height: number}>({ width: 100, height: 100 })
  // preview values
  const [targetScreenSize] = useState<{width: number, height: number}>(targetScreenSizeDefault) // the default screen size the watermark is scaled for
  const [previewScreenSize] = useState<{width: number, height: number}>(previewScreenSizeDefault) // the preview screen size we show in the web-ui (should be the same aspect ratio as the targetScreenSize above)
  const [previewWatermarkPos, setPreviewWatermarkPos] = useState<{x: number, y: number}>({ x: 0, y: 0 })
  const [previewBGPreset, setPreviewBGPreview] = useState<WatermarkScreenBackgroundPreset>(WatermarkScreenBackgroundPreset.ColourGrey)
  // canvas
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [canvasExportUrl, setCanvasExportUrl] = useState<string | undefined>(undefined)
  // watermark api values
  const [apiWatermarkSaving, setApiWatermarkSaving] = useState<boolean>(false)
  const [apiWatermarkSaved, setApiWatermarkSaved] = useState<boolean>(false)
  const [apiWatermarkConfirmDelete, setApiWatermarkConfirmDelete] = useState<boolean>(false)
  const [apiWatermarkDeleting, setApiWatermarkDeleting] = useState<boolean>(false)
  const [apiWatermarkDeleted, setApiWatermarkDeleted] = useState<boolean>(false)
  const [apiWatermarkError, setApiWatermarkError] = useState<Error | undefined>(undefined) // general errors across multiple api calls
  // const [apiWatermarkLoading, setApiWatermarkLoading] = useState<boolean>(false) // currently the api only tells us if the watermark exists via a dedicated endpoint (scale & pos are part of the main project model as well, loaded externally to this for now)
  const [apiWatermarkExists, setApiWatermarkExists] = useState<boolean | undefined>(undefined)
  const [apiWatermarkImageBlob, setApiWatermarkImageBlob] = useState<Blob | undefined>(undefined)
  const [apiWatermarkImage, setApiWatermarkImage] = useState<string | undefined>(undefined)
  // change tracking
  const [hasDataChanges, setHasDataChanges] = useState<boolean>(false)
  const [hasSourceImageChanges, setHasSourceImageChanges] = useState<boolean>(false)
  const [hasExportImageFileChanges, setHasExportImageFileChanges] = useState<boolean>(false)
  const [hasExportImageDataChanges, setHasExportImageDataChanges] = useState<boolean>(false)
  const hasChanges = useRef<boolean>(false) // NB: ref so we can access it in non-react JS callbacks (like addEventListener)
  // unsaved changes alert
  const [showUnsavedChangesAlert, setShowUnsavedChangesAlert] = useState<boolean>(false)
  const [unsavedChangesAlertConfirmed, setUnsavedChangesAlertConfirmed] = useState<boolean>(false)
  const [unsavedRedirectLocation, setUnsavedRedirectLocation] = useState<Location<unknown> | undefined>(undefined)
  const history = useHistory()

  // -------

  const updateCalcs = () => {
    // console.log('ProjectSettingsWatermarkView - updateCalcs')

    // NB: not allowing manual image height adjustment for MVP (with or without the aspect ratio locked), to keep the code simpler

    // TODO: halt if imageDimensions aren't loaded/available yet? (instead of using a fallback value)
    let width = imageDimensions?.width ?? 100
    let height = imageDimensions?.height ?? 50

    // TODO: cap the image dimensions here? (no larger than the target screen size)
    if (width > watermarkMaxDimensions.width || height > watermarkMaxDimensions.height) {
      console.log('ProjectSettingsWatermarkView - updateCalcs - IMAGE DIMENSIONS LARGER THAN MAX - CAP...')
      // check which axis is the largest percentage above the max size
      const widthDiffPcnt = width / watermarkMaxDimensions.width
      const heightDiffPcnt = height / watermarkMaxDimensions.height
      console.log('ProjectSettingsWatermarkView - updateCalcs - widthDiffPcnt: ', widthDiffPcnt, ' heightDiffPcnt: ', heightDiffPcnt)
      if (widthDiffPcnt > heightDiffPcnt || widthDiffPcnt === heightDiffPcnt) {
        // resize to fit the width
        const scalePcnt = watermarkMaxDimensions.width / width
        const newWidth = Math.round(width * scalePcnt)
        const newHeight = Math.round(height * scalePcnt)
        console.log('ProjectSettingsWatermarkView - updateCalcs - scale by width - scalePcnt: ', scalePcnt, ' newWidth: ', newWidth, ' newHeight: ', newHeight)
        width = newWidth
        height = newHeight
      } else {
        // resize to fit the height
        const scalePcnt = watermarkMaxDimensions.height / height
        const newWidth = Math.round(width * scalePcnt)
        const newHeight = Math.round(height * scalePcnt)
        console.log('ProjectSettingsWatermarkView - updateCalcs - scale by height - scalePcnt: ', scalePcnt, ' newWidth: ', newWidth, ' newHeight: ', newHeight)
        width = newWidth
        height = newHeight
      }
    }

    // preview specifc positioning - calc the scaling factor for the watermark to fit within our smaller web preview
    const previewScaleX = previewScreenSize.width / targetScreenSize.width
    const previewScaleY = previewScreenSize.height / targetScreenSize.height

    // setup vars used to calc positioning further down depending on the origin
    let originX = 0
    let originY = 0
    let offsetX = 0
    let offsetY = 0
    let dirX = 1
    let dirY = 1
    switch (watermarkOrigin) {
      case WatermarkOrigin.TopLeft:
        break
      case WatermarkOrigin.TopMiddle:
        originX = targetScreenSize.width / 2
        offsetX = (width / 2) * -1
        break
      case WatermarkOrigin.TopRight:
        originX = targetScreenSize.width
        offsetX = width * -1
        dirX = -1
        break
      case WatermarkOrigin.CentreLeft:
        originY = targetScreenSize.height / 2
        offsetY = (height / 2) * -1
        break
      case WatermarkOrigin.CentreMiddle:
        originX = targetScreenSize.width / 2
        originY = targetScreenSize.height / 2
        offsetX = (width / 2) * -1
        offsetY = (height / 2) * -1
        break
      case WatermarkOrigin.CentreRight:
        originX = targetScreenSize.width
        originY = targetScreenSize.height / 2
        offsetX = width * -1
        offsetY = (height / 2) * -1
        dirX = -1
        break
      case WatermarkOrigin.BottomLeft:
        originY = targetScreenSize.height
        offsetY = height * -1
        dirY = -1
        break
      case WatermarkOrigin.BottomMiddle:
        originX = targetScreenSize.width / 2
        originY = targetScreenSize.height
        offsetX = (width / 2) * -1
        offsetY = height * -1
        dirY = -1
        break
      case WatermarkOrigin.BottomRight:
        originX = targetScreenSize.width
        originY = targetScreenSize.height
        offsetX = width * -1
        offsetY = height * -1
        dirX = -1
        dirY = -1
        break
    }
    // console.log('ProjectSettingsWatermarkView - updateCalcs - originX: ', originX, ' originY: ', originY, ' offsetX: ', offsetX, ' offsetY: ', offsetY, ' dirX: ', dirX, ' dirY: ', dirY)

    // add any horizontal or vertical offset to the position
    const offsetPcntX = (watermarkOffsetHorizontalPcnt > 0) ? (watermarkOffsetHorizontalPcnt / 100) : 0
    const offsetPcntY = (watermarkOffsetVerticalPcnt > 0) ? (watermarkOffsetVerticalPcnt / 100) : 0

    // calc the watermark position based on its origin/anchor-point & the control settings
    // const x = originX + offsetX + (offsetPcntX > 0 ? ((targetScreenSize.width * offsetPcntX) * dirX) : 0)
    // const y = originY + offsetY + (offsetPcntY > 0 ? ((targetScreenSize.height * offsetPcntY) * dirY) : 0)
    // console.log('ProjectSettingsWatermarkView - updateCalcs - width: ', width, ' height: ', height, ' x: ', x, ' y: ', y)

    setWatermarkSize({ width, height })

    // preview specific values (scaled to match the smaller preview area)
    // const previewWatermarkWidth = Math.round(width * previewScaleX)
    // const previewWatermarkHeight = Math.round(height * previewScaleY)
    const previewX = Math.round((originX * previewScaleX) + (offsetX * previewScaleX) + (offsetPcntX > 0 ? (((targetScreenSize.width * offsetPcntX) * previewScaleX) * dirX) : 0))
    const previewY = Math.round((originY * previewScaleY) + (offsetY * previewScaleY) + (offsetPcntY > 0 ? (((targetScreenSize.height * offsetPcntY) * previewScaleY) * dirY) : 0))
    // console.log('ProjectSettingsWatermarkView - updateCalcs - previewWidth: ', previewWatermarkWidth, ' previewHeight: ', previewWatermarkHeight, ' previewX: ', previewX, ' previewY: ', previewY)
    // setPreviewWatermarkSize({ width: previewWatermarkWidth, height: previewWatermarkHeight })
    setPreviewWatermarkPos({ x: previewX, y: previewY })
  }

  // -------

  // indicates if any data changes need to be synced/saved to the server
  const updateHasDataChanges = () => {
    let _hasChanges = false
    if (project?.transcoderSettings) {
      const transcoderSettings = project?.transcoderSettings
      if (watermarkWidthPcnt !== transcoderSettings.watermarkWidth) _hasChanges = true
      if (watermarkOffsetHorizontalPcnt !== transcoderSettings.watermarkX) _hasChanges = true
      if (watermarkOffsetVerticalPcnt !== transcoderSettings.watermarkY) _hasChanges = true
      // TODO: add flip & opacity once the api supports them as data fields in some way
      // TODO: origin changes?
    }
    // console.log('ProjectSettingsWatermarkView - updateHasDataChanges - hasChanges: ', hasChanges)
    if (_hasChanges !== hasDataChanges) setHasDataChanges(_hasChanges)
  }

  // // indicates if the original source image need to be synced/uploaded to the server
  // const updateHasSourceImageChanges = () => {
  //   let hasChanges = false
  //   // TODO: <<<<
  //   if (hasChanges !== hasSourceImageChanges) setHasSourceImageChanges(hasChanges)
  // }

  // indicates if the canvas export image need to be synced/uploaded to the server
  const updateHasExportImageChanges = () => {
    // NB: if a new source image has been selected this will already be true (so no point checking for the data changes, we'll need to upload it anyway)
    // if (!hasExportImageFileChanges) {
    let hasChanges = false
    if (project?.transcoderSettings) {
      const transcoderSettings = project?.transcoderSettings
      if (watermarkTransparency !== (transcoderSettings.watermarkOpacity ?? watermarkTransparencyDefault)) hasChanges = true
      if (watermarkFlipHorizontal !== (transcoderSettings.watermarkFlipX !== undefined ? transcoderSettings.watermarkFlipX : watermarkFlipHorizontalDefault)) hasChanges = true
      if (watermarkFlipVeritcally !== (transcoderSettings.watermarkFlipY !== undefined ? transcoderSettings.watermarkFlipY : watermarkFlipVeritcallyDefault)) hasChanges = true
    }
    if (hasChanges !== hasExportImageDataChanges) setHasExportImageDataChanges(hasChanges)
    // }
  }

  // -------

  // run whenever the local or api image file is updated or loaded
  useEffect(() => {
    if (imageFile) {
      // TODO: has createObjectURL been depreciated (seen conflicting info on this?)
      // TODO: should free the memory when done/change or on component dealloc - see/search `revokeObjectURL`
      const url = URL.createObjectURL(imageFile)
      console.log('ProjectSettingsWatermarkView - useEffect: [imageFile] - url: ', url)
      setImageURL(url)

      // load the image dimensions
      const img = new Image()
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height })
      }
      img.src = url
      setImage(img)
    }
  }, [imageFile])

  // TESTING: run whenever an api image loads
  useEffect(() => {
    async function runAsync () {
      if (imageDimensions) setImageDimensions(undefined)
      if (image) setImage(undefined)
      if (imageFile) setImageFile(undefined)
      if (!apiWatermarkImage || !apiWatermarkImageBlob) return
      console.log('ProjectSettingsWatermarkView - apiWatermarkImageBlob.size: ', apiWatermarkImageBlob.size)
      // TESTING: image dimensions
      const img = new Image()
      img.onload = () => {
        console.log('ProjectSettingsWatermarkView - img.onload - img: ', img)
        console.log('ProjectSettingsWatermarkView - img.onload - <w> x <h>: ', img.width + ' x ' + img.height)
        setImageDimensions({ width: img.width, height: img.height })
      }
      img.src = apiWatermarkImage
      setImage(img)
      setImageURL(apiWatermarkImage)

      const originalFileInfo = project?.transcoderSettings?.watermarkFiles?.original
      const originalFilename = originalFileInfo?.filenameOriginal ?? '[saved image]'
      const _imageFile = new File([apiWatermarkImageBlob], originalFilename, { type: 'image/png' })
      console.log('ProjectSettingsWatermarkView - img.onload - _imageFile: ', _imageFile)
      setImageFile(_imageFile)
    }
    runAsync()
  }, [apiWatermarkImage, apiWatermarkImageBlob])

  // re-calc the final watermark position & scale from the various settings whenever they change
  useEffect(() => {
    updateCalcs()
  }, [imageDimensions, watermarkWidthPcnt, watermarkOffsetHorizontalPcnt, watermarkOffsetVerticalPcnt, watermarkOrigin])

  // change status tracking
  useEffect(() => {
    updateHasDataChanges()
  }, [
    imageDimensions,
    watermarkWidthPcnt,
    watermarkOffsetHorizontalPcnt,
    watermarkOffsetVerticalPcnt,
    watermarkOrigin,
    watermarkTransparency,
    watermarkFlipHorizontal,
    watermarkFlipVeritcally
  ])
  useEffect(() => {
    updateHasExportImageChanges()
  }, [
    watermarkTransparency,
    watermarkFlipHorizontal,
    watermarkFlipVeritcally
  ])

  // update the hasChanges ref whenever any of the change related state vars change (so we can access the change ref in non-react JS callbacks like addEventListener)
  useEffect(() => {
    const hasAnyChanges = hasDataChanges || hasSourceImageChanges || hasExportImageFileChanges || hasExportImageDataChanges
    hasChanges.current = hasAnyChanges
  }, [hasDataChanges, hasSourceImageChanges, hasExportImageFileChanges, hasExportImageDataChanges])

  // -------

  // warn the user if they try to navigate away from the page with unsaved changes
  // NB: this handling is split to cover 2 scenarios:
  //     1) navigating away to another page/route within the app (react router specific), can customise the alert message (& potentially the alert, although we leave it default for now)
  //     2) refreshing or closing the browser tab/window, can only use a default browser alert, no custom message
  // refs:
  //  https://javascript.plainenglish.io/how-to-alert-a-user-before-leaving-a-page-in-react-a2858104ca94
  //  https://stackoverflow.com/questions/32841757/detecting-user-leaving-page-with-react-router

  // NB: for internal page changes (scenario 1) we now show a custom alert instead of a standard browser one
  // ref: https://blog.formpl.us/how-to-create-a-custom-route-blocking-modal-in-react-9306139ee3c7

  const onExitWithUnsavedChangesConfirm = () => {
    console.log('ProjectSettingsWatermarkView - onExitWithUnsavedChangesConfirm')
    setShowUnsavedChangesAlert(false)
    setUnsavedChangesAlertConfirmed(true)
  }

  const onExitWithUnsavedChangesCancel = () => {
    console.log('ProjectSettingsWatermarkView - onExitWithUnsavedChangesCancel')
    setShowUnsavedChangesAlert(false)
    setUnsavedChangesAlertConfirmed(false)
    setUnsavedRedirectLocation(undefined)
  }

  const renderUnsavedChangesAlert = () => {
    return (
      <ArkConfirmModal
        show={showUnsavedChangesAlert}
        title='Unsaved Changes'
        message='You have unsaved changes, are you sure you want to leave?'
        onCancel={onExitWithUnsavedChangesCancel}
        onConfirm={onExitWithUnsavedChangesConfirm}
      />
    )
  }

  const renderUnsavedChangesRouterPrompt = () => {
    const hasAnyChanges = hasDataChanges || hasSourceImageChanges || hasExportImageFileChanges || hasExportImageDataChanges
    return (
      <>
        <Prompt
          when={hasAnyChanges}
          // OLD: standard browser styled alert:
          // message='You have unsaved changes, are you sure you want to leave?'
          // NEW: custom modal alert (requires some extra state vars & handling to make it work fully):
          message={(location: Location<unknown>) => {
            console.log('ProjectSettingsWatermarkView - renderUnsavedChangesRouterPrompt - location: ', location, 'unsavedChangesAlertConfirmed:', unsavedChangesAlertConfirmed)
            if (!unsavedChangesAlertConfirmed) {
              setShowUnsavedChangesAlert(true)
              setUnsavedRedirectLocation(location)
              return false
            }
            return true
          }}
        />
        {renderUnsavedChangesAlert()}
      </>
    )
  }

  // custom modal redirect handling
  // - listen out for when the custom unsaved changes modal alert has been confirmed
  // - & redirect to the original destination url the user clicked before the alert was shown
  useEffect(() => {
    if (unsavedChangesAlertConfirmed) {
      if (unsavedRedirectLocation) history.push(unsavedRedirectLocation.pathname)
    }
  }, [unsavedChangesAlertConfirmed])

  const unsavedChangesAlert = (e: BeforeUnloadEvent) => {
    e.preventDefault()
    // show a browser alert if the user has unsaved changes (triggered by a `beforeunload` event listener)
    // NB: we can't access react functional state vars in a JS callbacks like addEventListener this uses
    // NB: so we instead access a ref of the value instead - ref: https://stackoverflow.com/a/53846698
    if (hasChanges.current) {
      // NB: chrome (& other browsers) removed support for custom messages
      //     so we just set the value as a bool now & have to rely on a default browser message instead
      //     ref: https://stackoverflow.com/a/38880926
      e.returnValue = true
    }
    return null
  }

  // listen out for the user changing the browser url or closing the browser tab/window
  useEffect(() => {
    window.addEventListener('beforeunload', unsavedChangesAlert)
    return () => {
      window.removeEventListener('beforeunload', unsavedChangesAlert)
    }
  }, [])

  // -------

  const resetApiStatusVars = () => {
    // setApiWatermarkSaving(false)
    setApiWatermarkSaved(false)
    // setApiWatermarkDeleting(false)
    setApiWatermarkDeleted(false)
    setApiWatermarkError(undefined)
  }

  // -------

  const onImageSelect = () => {
    if (imageInputRef.current) imageInputRef.current.click()
  }

  const onImageChange = (event?: React.FormEvent<HTMLInputElement>) => {
    if (event?.currentTarget.files && event?.currentTarget.files.length > 0) {
      resetApiStatusVars()
      const file = event?.currentTarget.files[0]
      setImageFile(file)
      setCanvasExportUrl(undefined)
      setHasSourceImageChanges(true)
      // TESTING: reset the width & position values when a new image is selected locally (incase the old values push the image offscreen)
      setWatermarkWidthPcnt(watermarkWidthPcntDefault)
      setWatermarkOffsetHorizontalPcnt(watermarkOffsetHorizontalPcntDefault)
      setWatermarkOffsetVerticalPcnt(watermarkOffsetVerticalPcntDefualt)
      // TESTING: also reset the other values while we're at it..
      setWatermarkTransparency(watermarkTransparencyDefault)
      setWatermarkFlipHorizontal(watermarkFlipHorizontalDefault)
      setWatermarkFlipVeritcally(watermarkFlipVeritcallyDefault)
    }
  }

  const onClearImage = () => {
    setImageFile(undefined)
    setImageURL(undefined)
    setImage(undefined)
    setImageDimensions(undefined)
    if (imageInputRef.current) imageInputRef.current.value = ''
    setCanvasExportUrl(undefined)
    setApiWatermarkImage(undefined)
    setApiWatermarkImageBlob(undefined)
    setApiWatermarkExists(undefined)
    setApiWatermarkError(undefined)
    setHasDataChanges(false)
    setHasSourceImageChanges(false)
    setHasExportImageFileChanges(false)
    setHasExportImageDataChanges(false)
  }

  const onClearLocalImage = () => {
    if (apiWatermarkExists) return
    onClearImage()
  }

  const onUpdateWatermarkkWidthPcnt = (pcnt: number) => {
    setWatermarkWidthPcnt(pcnt)
    // if (watermarkLockAR) setWatermarkHeightPcnt(pcnt)
  }
  // const onUpdateWatermarkkHeightPcnt = (pcnt: number) => {
  //   setWatermarkHeightPcnt(pcnt)
  //   if (watermarkLockAR) setWatermarkWidthPcnt(pcnt)
  // }
  // const onUpdateWatermarkLockAR = (locked: boolean) => {
  //   setWatermarkLockAR(locked)
  //   if (locked) {
  //     onUpdateWatermarkkHeightPcnt(watermarkWidthPcnt) // if pcnt is locked, set the height pcnt based on the width
  //   }
  // }

  // const onUpdateWatermarkOrigin = (origin: WatermarkOrigin) => {
  //   setWatermarkOrigin(origin)
  // }

  const onExportCanvas = () => {
    if (canvasRef && canvasRef.current) {
      const canvas = canvasRef.current
      const dataURL = canvas.toDataURL('image/png')
      setCanvasExportUrl(dataURL)
    }
  }

  // -------

  const _loadProjectInfo = async (companyId: number, projectId: number) => {
    setProject(undefined)
    try {
      // NB: project managers do have access to the project info api endpoint so its ok to use that here from an access point of view
      // NB: & its project data includes the company transcoder settings we need in some settings sub-views/panels
      const _project = await projectAdminContext.actions.getProjectInfo(companyId, projectId)
      console.log('ProjectSettingsWatermarkView - _loadProjectInfo - _project: ', _project)
      if (_project) {
        setProject(_project)
        if (_project.transcoderSettings) {
          // TESTING: for MVP we're now checking if our watermark extra data field is set & if not we asume/know no image has been set, so don't load any values, use the local defaults
          if (_project.transcoderSettings.watermarkExtraData) {
            if (_project.transcoderSettings.watermarkWidth !== undefined) setWatermarkWidthPcnt(_project.transcoderSettings.watermarkWidth)
            if (_project.transcoderSettings.watermarkX !== undefined) setWatermarkOffsetHorizontalPcnt(_project.transcoderSettings.watermarkX)
            if (_project.transcoderSettings.watermarkY !== undefined) setWatermarkOffsetVerticalPcnt(_project.transcoderSettings.watermarkY)
            if (_project.transcoderSettings.watermarkOpacity !== undefined) setWatermarkTransparency(_project.transcoderSettings.watermarkOpacity)
            if (_project.transcoderSettings.watermarkFlipX !== undefined) setWatermarkFlipHorizontal(_project.transcoderSettings.watermarkFlipX)
            if (_project.transcoderSettings.watermarkFlipY !== undefined) setWatermarkFlipVeritcally(_project.transcoderSettings.watermarkFlipY)
          }
        }
      } else {
        throw new Error('Failed to load project details')
      }
    } catch (error) {
      setLoadingError(error)
    }
  }

  // const _loadWatermarkExists = async (companyId: number, projectId: number) => {
  //   try {
  //     const watermarkExists = await projectAdminContext.actions.getProjectWatermarkExists(companyId, projectId)
  //     console.log('ProjectSettingsWatermarkView - loadWatermarkExists - watermarkExists: ', watermarkExists)
  //     setApiWatermarkExists(watermarkExists)
  //   } catch (error) {
  //     if (error instanceof ServerProjectWatermarkErrorReadingError) {
  //       console.log('ProjectSettingsWatermarkView - loadWatermarkExists - ServerProjectWatermarkDoesNotExistError')
  //       // TODO: <<<
  //       // TODO: <<<
  //       // TODO: <<<
  //       setApiWatermarkExists(true) // TODO: <<< TEMP <<< so we can delete it
  //     } else {
  //       console.error('ProjectSettingsWatermarkView - loadWatermarkExists - error: ', error)
  //       setLoadingError(error)
  //     }
  //   }
  // }

  const _loadOriginalWatermarkImage = async (companyId: number, projectId: number) => {
    try {
      console.log('ProjectSettingsWatermarkView - _loadOriginalWatermarkImage')
      setApiWatermarkImage(undefined)
      setApiWatermarkImageBlob(undefined)
      const imageBlob = await projectAdminContext.actions.getProjectWatermarkImageBlob(companyId, projectId, true)
      if (imageBlob) {
        const image = URL.createObjectURL(imageBlob)
        setApiWatermarkImageBlob(imageBlob)
        setApiWatermarkImage(image)
        setApiWatermarkExists(true)
      } else {
        throw new Error('Error loading watermark image')
      }
    } catch (error) {
      if (error instanceof ServerProjectWatermarkDoesNotExistError) {
        console.log('ProjectSettingsWatermarkView - _loadOriginalWatermarkImage - ServerProjectWatermarkDoesNotExistError')
        setApiWatermarkExists(false)
      } else if (error instanceof ServerProjectWatermarkErrorReadingError) {
        console.log('ProjectSettingsWatermarkView - _loadOriginalWatermarkImage - ServerProjectWatermarkErrorReadingError')
        // if this triggers a watermark image is set but the server had an error loading & returning it
        // so we flag that it exists so the user can get to the delete button, but also display an error
        // (using the api instead of loading error var so the rest of the UI still shows)
        setApiWatermarkExists(true)
        setApiWatermarkError(error)
      } else {
        console.log('ProjectSettingsWatermarkView - _loadOriginalWatermarkImage - error: ', error)
        setLoadingError(error)
      }
    }
    setLoading(false)
  }

  const loadData = async () => {
    if (loading) return
    const company = userContext.store.selectedCompany
    const project = userContext.store.selectedProject
    if (!company || !project) return
    setLoading(true)
    setLoadingError(undefined)
    // load the project object/data
    await _loadProjectInfo(company.id, project.id)
    if (!loadingError && project) {
      // check if watermarking is currently disabled (company wide setting)
      const watermarkingEnabled = project?.companyTranscoderSettings?.allowWatermarking
      if (watermarkingEnabled !== false) {
        // proceed to load the watermark image if one is set
        // await _loadWatermarkExists(company.id, project.id)
        await _loadOriginalWatermarkImage(company.id, project.id)
      }
    }
    setLoading(false)
  }

  // -------

  const onSave = async () => {
    if (apiWatermarkSaving || apiWatermarkDeleting) return
    if (canvasRef && canvasRef.current && imageFile) {
      const company = userContext.store.selectedCompany
      const project = userContext.store.selectedProject
      if (company && project) {
        try {
          const canvas = canvasRef.current
          const originalBlob: Blob = imageFile
          const editedBlob: Blob = await new Promise(resolve => canvas.toBlob((blob) => {
            if (blob) resolve(blob)
            else throw new Error('Failed to export edited image')
          }, 'image/png')) // NB: make sure to specify the image content type so the server can read/parse it
          resetApiStatusVars()
          setApiWatermarkSaving(true)
          // check if any image related changes were made so we need to upload the original & edited images
          const uploadImages = hasSourceImageChanges || hasExportImageFileChanges || hasExportImageDataChanges
          console.log('ProjectSettingsWatermarkView - onSave - uploadImages: ', uploadImages)
          let uploadResult = false
          if (uploadImages) {
            // TESTING: set the original & edited image filenames, making sure the edited one is set to use a .png extension
            const originalFilename = imageFile?.name
            let editedFilename = imageFile?.name
            if (editedFilename) {
              const fileNameNoExt = editedFilename.substring(0, editedFilename.lastIndexOf('.'))
              editedFilename = fileNameNoExt + '-edited.png'
            }
            console.log('ProjectSettingsWatermarkView - onSave - originalFilename: ', originalFilename, ' editedFilename: ', editedFilename)
            // upload the watermark image if there were any image related to changes to the original or edited/exported images (skip if not)
            uploadResult = await projectAdminContext.actions.uploadProjectWatermark(company.id, project.id, originalBlob, editedBlob, originalFilename, editedFilename)
            console.log('ProjectSettingsWatermarkView - onSave - uploadResult: ', uploadResult)
          }
          // submit data changes if an image upload happened & was successful or no image upload was needed
          if (uploadResult || !uploadImages) {
            // save watermark meta data (requires a separate project update api call)
            const watermarkData: { [key: string]: any } = {
              watermarkWidth: watermarkWidthPcnt,
              watermarkX: watermarkOffsetHorizontalPcnt,
              watermarkY: watermarkOffsetVerticalPcnt,
              watermarkExtraData: { opacity: watermarkTransparency, flip_x: watermarkFlipHorizontal, flip_y: watermarkFlipVeritcally }
            }
            console.log('ProjectSettingsWatermarkView - onSave - updateProjectInfo - watermarkData: ', watermarkData)
            const savedProject = await projectAdminContext.actions.updateProjectInfo(company.id, project.id, watermarkData)
            console.log('ProjectSettingsWatermarkView - onSave - savedProject: ', savedProject)
            if (savedProject) { setApiWatermarkSaved(true) } // NB: should only be true if we reach here, will throw an error if if fails
            setApiWatermarkSaving(false)
            setHasSourceImageChanges(false)
            setHasExportImageFileChanges(false)
            setHasExportImageDataChanges(false)
            // await loadWatermarkExists(company.id, project.id) // trigger an update of the api watermark status
            await loadData() // trigger an update of the api project data & watermark status
          } else {
            setApiWatermarkSaving(false)
          }
        } catch (error) {
          console.error('ProjectSettingsWatermarkView - onSave - error: ', error)
          setApiWatermarkSaving(false)
          setApiWatermarkError(error)
        }
      }
    }
  }

  const onDelete = () => {
    console.log('ProjectSettingsWatermarkView - onDelete')
    setApiWatermarkConfirmDelete(true)
  }

  const onDeleteCancel = () => {
    console.log('ProjectSettingsWatermarkView - onDeleteCancel')
    setApiWatermarkConfirmDelete(false)
  }

  const onDeleteConfirm = async () => {
    setApiWatermarkConfirmDelete(false)
    const company = userContext.store.selectedCompany
    const project = userContext.store.selectedProject
    if (!company || !project) return
    if (apiWatermarkSaving || apiWatermarkDeleting) return
    try {
      resetApiStatusVars()
      setApiWatermarkDeleting(true)
      const watermarkDeleted = await projectAdminContext.actions.deleteProjectWatermark(company.id, project.id)
      console.log('ProjectSettingsWatermarkView - onDeleteConfirm - watermarkDeleted: ', watermarkDeleted)
      if (watermarkDeleted) { // NB: should only be true if we reach here, will throw an error if any issues
        // TESTING: manually wipe/reset watermark vars after deleting the image
        // TODO: maybe just get the api to wipe all these when an image is deleted instead of trying to do it manually here? <<<
        // save watermark meta data (requires a separate project update api call)
        const watermarkData: { [key: string]: any } = {
          watermarkWidth: watermarkWidthPcntDefault, // TODO: ideally we'd just null these out, but thats not saving api side?
          watermarkX: watermarkOffsetHorizontalPcntDefault, // TODO: ideally we'd just null these out, but thats not saving api side?
          watermarkY: watermarkOffsetVerticalPcntDefualt, // TODO: ideally we'd just null these out, but thats not saving api side?
          watermarkExtraData: null // NB: this seems to null it out ok
        }
        console.log('ProjectSettingsWatermarkView - onDelete - updateProjectInfo - watermarkData: ', watermarkData)
        const savedProject = await projectAdminContext.actions.updateProjectInfo(company.id, project.id, watermarkData)
        console.log('ProjectSettingsWatermarkView - onDelete - savedProject: ', savedProject)
        if (savedProject) {
          // force reset the extra state values that aren't reset when loading the latest project data
          setWatermarkFlipHorizontal(watermarkFlipHorizontalDefault)
          setWatermarkFlipVeritcally(watermarkFlipVeritcallyDefault)
          setWatermarkTransparency(watermarkTransparencyDefault)
        }
      }
      if (watermarkDeleted) setApiWatermarkDeleted(true)
      setApiWatermarkDeleting(false)
      // await loadWatermarkExists(company.id, project.id) // trigger an update of the api watermark status
      onClearImage()
      await loadData() // trigger an update of the api project data & watermark status
    } catch (error) {
      console.error('ProjectSettingsWatermarkView - onDelete - error: ', error)
      setApiWatermarkDeleting(false)
      setApiWatermarkError(error)
    }
  }

  // -------

  // load the watermark related data on component init/mount
  // NB: run an async function via useEffect - ref: https://stackoverflow.com/a/53572588
  useEffect(() => {
    async function loadAsync () {
      await loadData()
    }
    loadAsync()
  }, [])

  // -------

  // formats filesize in bytes to a more human readable version
  // ref: https://stackoverflow.com/a/18650828
  function formatBytes (bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  // -------

  const renderImgUpload = () => {
    const disabled = apiWatermarkSaving || apiWatermarkDeleting
    return (
      <div className={`${styles.upload} ${styles.panel}`}>
        <input type="file" /* multiple */ accept=".jpeg,.jpg,.png" ref={imageInputRef} className={styles.imgUpload} onChange={onImageChange} />
        <ArkButton color="blue" basic size="tiny" fluid className={styles.imgUploadBtn} disabled={disabled} onClick={onImageSelect}>
          {(apiWatermarkExists || imageFile !== undefined) ? 'CHANGE IMAGE' : 'SELECT IMAGE' /* 'UPLOAD' */}
        </ArkButton>
        {imageFile !== undefined && (
          <ArkButton color="orange" basic size="tiny" className={styles.imgUploadRemoveBtn} disabled={disabled || apiWatermarkImage !== undefined} onClick={onClearLocalImage}>
            CLEAR
          </ArkButton>
        )}
      </div>
    )
  }

  const renderImgPreview = () => {
    return (
      <div className={`${styles.preview} ${styles.panel}`}>
        {!imageURL && (<div className={styles.noImg}></div>)}
        {imageURL && (<img src={imageURL} />)}
      </div>
    )
  }

  const renderImgStats = () => {
    const filename = imageFile ? _.truncate(imageFile.name, { length: 25 }) : ''
    return (
      <div className={`${styles.stats} ${styles.panel}`}>
        <div className={styles.title}>Current:</div>
        {(!image && !apiWatermarkExists) && (<>No watermark set</>)}
        {(!image && apiWatermarkExists) && (<>Error loading image</>)}
        {image && (
          <>
            <div className={styles.filename}>{filename}</div>
            <div className={styles.size}>{imageDimensions?.width}px x {imageDimensions?.height}px {imageFile?.size ? formatBytes(imageFile?.size) : 'N/A'}</div>
          </>
        )}
      </div>
    )
  }

  // -------

  const renderSlider = (key: string, value: number, min: number, max: number, onChange: (value: number) => void, title: string, valueSuffix?: string) => {
    return (
      <div key={key} className={styles.slider}>
        <div className={styles.sliderLabels}>
          <div className={styles.sliderTitle}>
            {title}
          </div>
          <div className={styles.sliderValue}>
            {value}{valueSuffix ?? ''}
          </div>
        </div>
        <ArkSlider
          key={key + '_slider'}
          value={value}
          min={min}
          max={max}
          onChange={(event) => { onChange(parseInt(event.target.value)) }}
        />
      </div>
    )
  }

  const renderCheckbox = (key: string, value: boolean, onChange: (value: boolean) => void, title: string) => {
    return (
      <div key={key} className={styles.checkbox}>
        <input key={key + '_checkbox'} type="checkbox" checked={value} onChange={(event) => onChange(event.target.checked)} />
        <span className={styles.checkboxTitle}>
          {title}
        </span>
      </div>
    )
  }

  // -------

  const renderWatermarkSizeControls = () => {
    return (
      <div className={styles.watermarkSize}>
        {renderSlider('watermarkWidthPcnt', watermarkWidthPcnt, 1, 100, (value) => {
          onUpdateWatermarkkWidthPcnt(value)
        }, 'Width: ', '% of screen')}
        {/* {renderSlider('watermarkHeightPcnt', watermarkHeightPcnt, 0, 100, (value) => {
          onUpdateWatermarkkHeightPcnt(value)
        }, 'Height: ', '% of screen')}
        {renderCheckbox('watermarkLockAR', watermarkLockAR, (value) => {
          onUpdateWatermarkLockAR(value)
        }, 'Lock Aspect Ratio')} */}
      </div>
    )
  }

  const renderWatermarkTransparencyControls = () => {
    return (
      <div className={styles.watermarkTransparency}>
        {renderSlider('watermarkTransparency', watermarkTransparency, 0, 100, (value) => {
          setWatermarkTransparency(value)
        }, 'Transparency: ', '%')}
      </div>
    )
  }

  const renderWatermarkFlipControlsHorizontal = () => {
    return (
      renderCheckbox('watermarkFlipHorizontal', watermarkFlipHorizontal, (value) => {
        setWatermarkFlipHorizontal(value)
      }, 'Flip Horizontally')
    )
  }

  const renderWatermarkFlipControlsVeritcal = () => {
    return (
      renderCheckbox('watermarkFlipVeritcally', watermarkFlipVeritcally, (value) => {
        setWatermarkFlipVeritcally(value)
      }, 'Flip Veritcally')
    )
  }

  // NB: not currently used
  // const renderWatermarkFlipControls = () => {
  //   return (
  //     <div className={styles.watermarkFlip}>
  //       {renderWatermarkFlipControlsHorizontal()}
  //       {renderWatermarkFlipControlsVeritcal()}
  //     </div>
  //   )
  // }

  const renderWatermarkOffsetControls = () => {
    return (
      <div className={styles.watermarkOffset}>
        {renderSlider('watermarkOffsetHorizontalPcnt', watermarkOffsetHorizontalPcnt, 0, 100, (value) => {
          setWatermarkOffsetHorizontalPcnt(value)
        }, 'Horizontal Offset: ', '%')}
        {renderSlider('watermarkOffsetVerticalPcnt', watermarkOffsetVerticalPcnt, 0, 100, (value) => {
          setWatermarkOffsetVerticalPcnt(value)
        }, 'Veritcal Offset: ', '%')}
      </div>
    )
  }

  // NB: not currently used (may want to re-enable & finish adding support for this in the future)
  // const renderWatermarkOriginControls = () => {
  //   if (!PROJECT_WATERMARK_ORIGINS_ENABLED) return null
  //   return (
  //     <div className={styles.watermarkOrigin}>
  //       <div className={styles.title}>DEFAULT POSITION</div>
  //       <div className={styles.grid}>
  //         <div className={`${styles.originItem} ${watermarkOrigin === WatermarkOrigin.TopLeft ? styles.current : ''}`} onClick={() => onUpdateWatermarkOrigin(WatermarkOrigin.TopLeft)}>TL</div>
  //         <div className={`${styles.originItem} ${watermarkOrigin === WatermarkOrigin.TopMiddle ? styles.current : ''}`} onClick={() => onUpdateWatermarkOrigin(WatermarkOrigin.TopMiddle)}>TM</div>
  //         <div className={`${styles.originItem} ${watermarkOrigin === WatermarkOrigin.TopRight ? styles.current : ''}`} onClick={() => onUpdateWatermarkOrigin(WatermarkOrigin.TopRight)}>TR</div>
  //         <div className={`${styles.originItem} ${watermarkOrigin === WatermarkOrigin.CentreLeft ? styles.current : ''}`} onClick={() => onUpdateWatermarkOrigin(WatermarkOrigin.CentreLeft)}>CL</div>
  //         <div className={`${styles.originItem} ${watermarkOrigin === WatermarkOrigin.CentreMiddle ? styles.current : ''}`} onClick={() => onUpdateWatermarkOrigin(WatermarkOrigin.CentreMiddle)}>CM</div>
  //         <div className={`${styles.originItem} ${watermarkOrigin === WatermarkOrigin.CentreRight ? styles.current : ''}`} onClick={() => onUpdateWatermarkOrigin(WatermarkOrigin.CentreRight)}>CR</div>
  //         <div className={`${styles.originItem} ${watermarkOrigin === WatermarkOrigin.BottomLeft ? styles.current : ''}`} onClick={() => onUpdateWatermarkOrigin(WatermarkOrigin.BottomLeft)}>BL</div>
  //         <div className={`${styles.originItem} ${watermarkOrigin === WatermarkOrigin.BottomMiddle ? styles.current : ''}`} onClick={() => onUpdateWatermarkOrigin(WatermarkOrigin.BottomMiddle)}>BM</div>
  //         <div className={`${styles.originItem} ${watermarkOrigin === WatermarkOrigin.BottomRight ? styles.current : ''}`} onClick={() => onUpdateWatermarkOrigin(WatermarkOrigin.BottomRight)}>BR</div>
  //       </div>
  //     </div>
  //   )
  // }

  const renderWatermarkPreview = () => {
    const renderWatermarkWidth = Math.round(previewScreenSize.width * (watermarkWidthPcnt / 100)) // previewWatermarkSize.width
    // calc the watermark height to scale it to match the width while maintaining its aspect ratio
    const scale = renderWatermarkWidth / watermarkSize.width
    // const renderWatermarkHeight = Math.round((previewWatermarkSize.height * (watermarkWidthPcnt / 100)) * ar) // (previewScreenSize.height * (watermarkHeightPcnt / 100)) // previewWatermarkSize.height
    const renderWatermarkHeight = Math.round((imageDimensions?.height ?? 0) * scale)
    // console.log('ProjectSettingsWatermarkView - renderWatermarkPreview - scale: ', scale, ' renderWatermarkHeight: ', renderWatermarkHeight)
    return (
      <div className={`${styles.preview}`} style={{ width: previewScreenSize.width, height: previewScreenSize.height }}>
        <WatermarkScreenBackground size={previewScreenSize} className={styles.screenBG} preset={previewBGPreset}>
          {/* <ArkIcon name='play-circle' size={84} className={styles.playIcon} /> */}
          <div className={styles.img} style={{
            left: previewWatermarkPos.x,
            top: previewWatermarkPos.y,
            width: renderWatermarkWidth, // previewWatermarkSize.width,
            height: renderWatermarkHeight, // previewWatermarkSize.height,
            opacity: (watermarkTransparency / 100),
            transform: (watermarkFlipHorizontal && !watermarkFlipVeritcally ? 'scaleX(-1)' : (!watermarkFlipHorizontal && watermarkFlipVeritcally ? 'scaleY(-1)' : (watermarkFlipHorizontal && watermarkFlipVeritcally ? 'scale(-1)' : 'none'))),
            backgroundImage: 'url(' + imageURL + ')',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
          }}>
            {/* {imageURL && (<img src={imageURL} width={'100%'} height={'100%'} />)} */}
          </div>
        </WatermarkScreenBackground>
      </div>
    )
  }

  const renderWatermarkPreviewBGSelector = () => {
    const options = [
      { id: WatermarkScreenBackgroundPreset.ColourWhite, title: 'White' },
      { id: WatermarkScreenBackgroundPreset.ColourGrey, title: 'Grey' },
      { id: WatermarkScreenBackgroundPreset.ColourBlack, title: 'Black' },
      { id: WatermarkScreenBackgroundPreset.Image01, title: 'SMPTE HD Bars' },
      { id: WatermarkScreenBackgroundPreset.Image02, title: 'Colour' },
      { id: WatermarkScreenBackgroundPreset.Image03, title: 'Greyscale' }
    ]
    const currentIndex = options.findIndex((option) => option.id === previewBGPreset)
    const lastIndex = WatermarkScreenBackgroundPreset.Image03

    const formFields: Array<ArkFormField> = []
    const fieldOptions: Array<ArkFormFieldOption> = []
    for (const option of options) {
      const title = option.title
      fieldOptions.push({ key: title, text: title, value: option.id })
    }
    formFields.push({
      type: ArkFormFieldType.Dropdown,
      key: 'previewBG',
      label: 'Preview Background:',
      required: false,
      defaultValue: currentIndex,
      options: fieldOptions,
      fieldProps: {
        className: styles.previewBGField,
        scrolling: true // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
      }
    })

    return (
      <div className={`${styles.previewBGSelector}`}>
        <ArkForm
          formKey="previewBGSelect"
          inverted
          formFields={formFields}
          onValueChanged={(fieldKey: string, fieldValue: any, _oldFieldValue: any) => {
            if (fieldKey === 'previewBG') {
              if (fieldValue >= 0 && fieldValue <= lastIndex) {
                setPreviewBGPreview(fieldValue)
              }
            }
          }}
        ></ArkForm>
      </div>
    )
  }

  const renderWatermarkCanvas = () => {
    const draw = (ctx: CanvasRenderingContext2D) => {
      // ctx.fillStyle = '#000000'
      // ctx.beginPath()
      // ctx.arc(50, 100, 20, 0, (2 * Math.PI))
      // ctx.fill()
      if (image) {
        ctx.save()
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        ctx.globalAlpha = (watermarkTransparency / 100)
        if (watermarkFlipHorizontal || watermarkFlipVeritcally) {
          const translateX = watermarkFlipHorizontal ? ctx.canvas.width : 0
          const translateY = watermarkFlipVeritcally ? ctx.canvas.height : 0
          const scaleX = watermarkFlipHorizontal ? -1 : 1
          const scaleY = watermarkFlipVeritcally ? -1 : 1
          ctx.translate(translateX, translateY)
          ctx.scale(scaleX, scaleY)
        }
        ctx.drawImage(image, 0, 0, watermarkSize.width, watermarkSize.height)
        ctx.restore()
      }
    }
    return (
      <div className={styles.watermarkCanvas}>
        <WatermarkCanvas width={watermarkSize.width} height={watermarkSize.height} draw={draw} canvasRef={canvasRef} />
        {PROJECT_WATERMARK_DEBUG_ENABLED && (<><br /><ArkButton color="blue" basic size="tiny" onClick={onExportCanvas}>DBG EXPORT</ArkButton></>)}
      </div>
    )
  }

  const renderWatermarkSave = () => {
    if (!imageFile) return null
    const hasAnyChanges = hasDataChanges || hasSourceImageChanges || hasExportImageFileChanges || hasExportImageDataChanges
    return (
      <div className={styles.watermarkSave}>
        <ArkButton color="blue" size="tiny" fluid loading={apiWatermarkSaving} disabled={!hasAnyChanges} onClick={onSave}>SAVE AND APPLY</ArkButton>
      </div>
    )
  }

  const renderWatermarkDelete = () => {
    if (!apiWatermarkExists) return null
    return (
      <div className={styles.watermarkDelete}>
        <ArkButton color="red" size="tiny" basic fluid loading={apiWatermarkDeleting} onClick={onDelete}>REMOVE WATERMARK</ArkButton>
        {<ArkConfirmModal
          show={apiWatermarkConfirmDelete}
          title='Remove Watermark?'
          message='Are you sure you want to remove the watermark from this project?'
          onCancel={onDeleteCancel}
          onConfirm={onDeleteConfirm}
        />}
      </div>
    )
  }

  const renderWatermarkApiResults = () => {
    if (apiWatermarkSaved || apiWatermarkDeleted || apiWatermarkError) {
      return (
        <>
          {apiWatermarkSaved && (
            <ArkMessage positive>
              <ArkMessage.Header>Watermark Settings Saved</ArkMessage.Header>
              <p>It can take up to 60 seconds to update on end program streams</p>
            </ArkMessage>
          )}
          {apiWatermarkDeleted && (
            <ArkMessage positive>
              <ArkMessage.Header>Watermark Deleted</ArkMessage.Header>
              <p>It can take up to 60 seconds to update on end program streams</p>
            </ArkMessage>
          )}
          {apiWatermarkError && (
            <ArkMessage negative>
              <ArkMessage.Header>Watermark Error</ArkMessage.Header>
              <p>{apiWatermarkError.message}</p>
            </ArkMessage>
          )}
        </>
      )
    }
    return null
  }

  // DBG ONLY:
  const renderWatermarkExportPreview = () => {
    if (!canvasExportUrl) return null
    return (
      <div className={styles.watermarkExportPreview}>
        <img src={canvasExportUrl} />
      </div>
    )
  }

  // -------

  if (loading) return (<ArkLoaderView message={'Loading watermark settings'} className={styles.loading} />)

  if (loadingError) {
    return (
      <div className={`${styles.watermarkDataError}`}>
        <ArkMessage negative>
          <ArkMessage.Header>Error</ArkMessage.Header>
          <p>{loadingError.message}</p>
        </ArkMessage>
      </div>
    )
  }

  const watermarkingEnabled = project?.companyTranscoderSettings?.allowWatermarking
  if (watermarkingEnabled === false) {
    return (
      <div className={styles.watermarkView}>
        <ArkMessage warning className={styles.watermarkingDisabled}>
          <ArkMessage.Header>Warning</ArkMessage.Header>
          <p>Watermarking is currently disabled</p>
        </ArkMessage>
      </div>
    )
  }

  const hasImage = imageFile !== undefined

  return (
    <div className={styles.watermarkView}>
      {renderWatermarkApiResults()}
      <div className={styles.topPanel}>
        <div className={styles.imagePanel}>
          {renderImgPreview()}
          <div className={styles.imageControls}>
            {renderImgStats()}
            {renderImgUpload()}
          </div>
        </div>
        {hasImage && (
          <div className={styles.controlsPanel}>
            <div className={styles.controlsCol1}>
              {renderWatermarkOffsetControls()}
              {renderWatermarkFlipControlsHorizontal()}
            </div>
            <div className={styles.controlsCol2}>
              {renderWatermarkSizeControls()}
              {renderWatermarkTransparencyControls()}
              {renderWatermarkFlipControlsVeritcal()}
            </div>
          </div>
        )}
      </div>
      {(hasImage || apiWatermarkExists) && (<>
        <div className={styles.mainPanel}>
          <div className={styles.watermarkPanel}>
            {/* <div className={styles.controls}>
              <div className={styles.controlsCol1}>
                {renderWatermarkSizeControls()}
                {renderWatermarkTransparencyControls()}
                {renderWatermarkFlipControls()}
              </div>
              <div className={styles.controlsCol2}>
                {renderWatermarkOffsetControls()}
                {renderWatermarkOriginControls()}
              </div>
            </div> */}
            {renderWatermarkPreview()}
            {(hasImage || apiWatermarkExists) && (
              <div className={`${styles.rightPanel}`}>
                {renderWatermarkPreviewBGSelector()}
                <div className={`${styles.saveDelControls}`}>
                  {renderWatermarkSave()}
                  {renderWatermarkDelete()}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.watermarkExport + (!PROJECT_WATERMARK_DEBUG_ENABLED && (' ' + styles.watermarkExportHidden))}>
          {renderWatermarkCanvas()}
          {PROJECT_WATERMARK_DEBUG_ENABLED && renderWatermarkExportPreview()}
        </div>
      </>)}
      {renderUnsavedChangesRouterPrompt()}
    </div>
  )
}

export { ProjectSettingsWatermarkView }
