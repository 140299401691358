import React from 'react'

import { withUserContext, IUserMultiContext } from 'src/core/providers'

import ArkProjectManagerPage from 'src/manager/project/components/ArkProjectManagerPage/ArkProjectManagerPage'
import ArkProjectSelectView from 'src/core/components/ArkProjectSelectView'

interface IProps extends IUserMultiContext {}
interface IState {}

class ProjectManagerProjectSelectPage extends React.Component<IProps, IState> {
  render () {
    // const adminProjects = this.props.userContext.store.adminProjects
    const companyAdminOrProjectManagerOrAdminProjects = this.props.userContext.actions.getCompanyAdminOrProjectManagerOrAdminProjects()
    if (!companyAdminOrProjectManagerOrAdminProjects || companyAdminOrProjectManagerOrAdminProjects.length === 0) {
      console.warn('ProjectSelectPage - WARNING: NO ADMIN PROJECTS - HALT <<<')
      return null // NB: the parent code checks if no adminProjects & stops this component from firing (shouldn't ever reach here, you'll get a blank page if you do!)
    }
    return (
      <ArkProjectManagerPage disableSectionMainMenu>
        <ArkProjectSelectView projects={companyAdminOrProjectManagerOrAdminProjects} />
      </ArkProjectManagerPage>
    )
  }
}

export default withUserContext(ProjectManagerProjectSelectPage)
