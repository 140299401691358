/**
 * ArkHeader
 */

import React from 'react'

import { Header, HeaderProps } from 'semantic-ui-react'

import styles from './ArkHeader.module.css'

export interface ArkHeaderProps extends HeaderProps {}

const ArkHeader: React.FC<ArkHeaderProps> = (props: ArkHeaderProps) => {
  const { className, children } = props
  return (
    <Header
      {...props}
      className={`${styles.header} ${className || ''}`}
    >{children}</Header>
  )
}

export default ArkHeader
