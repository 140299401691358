import React from 'react'

import { Switch, Route } from 'react-router-dom'

import { withUserContext, withNavContext, IUserMultiContext, INavMultiContext, IAuthMultiContext, withAuthContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import { UserProject } from 'src/core/models'

import ViewerCompaniesSelectPage from './pages/companies/ViewerCompaniesSelectPage'
import ViewerProjectSelectPage from './pages/projects/ViewerProjectSelectPage'
import ViewerNoProjectsPage from './pages/projects/ViewerNoProjectsPage'
import ViewerAccessDeniedPage from './pages/projects/ViewerAccessDeniedPage'
import ViewerLoadingPage from './pages/projects/ViewerLoadingPage'
import ViewerNoViewAccessPage from './pages/projects/ViewerNoViewAccessPage'
import ViewerInactiveOrgPage from './pages/projects/ViewerInactiveOrgPage'
import ViewerHomePage from './pages/home/ViewerHomePage'
import ViewerSettingsPage from './pages/settings/ViewerSettingsPage'
import Viewer2FAAuthPage from './pages/auth/Viewer2FAAuthPage'

import SupportPage from 'src/core/pages/support/SupportPage'

interface IProps extends IUserMultiContext, IAuthMultiContext, INavMultiContext {}
interface IState {}

class ViewerRouter extends React.Component<IProps, IState> {
  render () {
    const { selectedCompany: company, selectedProject: project, userProjects: projects, loadingCompanyData } = this.props.userContext.store

    // catch if no company selected
    if (!company) {
      return (<ViewerCompaniesSelectPage />)
    }

    // show a loader when initially loading the selected company (on page load on switching companies, so project won't be set compared to an update of current selections)
    // NB: we don't want this to fire when updating the selected company (& other selections), otherwise this can trigger briefly & cause pages to reload & so reset when we don't want them too
    if (!project && loadingCompanyData) {
      return <ViewerLoadingPage />
    }

    // disabled/inactive company
    if (!company.isActive) {
      return <ViewerInactiveOrgPage />
    }

    // catch if the user doesn't have access to the specified project (they specified the project id in the url, but no project was selected)
    // NB: this will also currently fire if you try to load a project that doesn't exist for this company (may exist for another)
    // NB: although the UserProvider may handle that scenario soon & would redirect if the user has access before we hit here
    if (!project && this.props.navContext.store.urlIds.projectId !== undefined) {
      return (<ViewerAccessDeniedPage />)
    }

    // catch if the user has admin-only access or access disabled for the project
    if (project && (!(project instanceof UserProject) || !project.userAccessEnabled)) {
      return (<ViewerNoViewAccessPage />)
    }

    // company 2fa
    const user = this.props.userContext.store.user
    const userTFAEnabled = user?.tfaEnabled || false
    const companyAuthTFARequired = this.props.authContext.actions.isCompanyAuthTFARequired()
    console.log('ViewerRouter - companyAuthTFARequired: ', companyAuthTFARequired, ' userTFAEnabled:', userTFAEnabled)
    if (companyAuthTFARequired && !userTFAEnabled) {
      return (<Viewer2FAAuthPage />)
    }

    // catch if the user does not have access to any projects in the selected company
    // includes checking the userAccessEnabled flag
    let userCompanyProjectCount = projects ? projects.length : 0
    if (projects && userCompanyProjectCount > 0) {
      for (const project of projects) {
        if (!project.userAccessEnabled) {
          userCompanyProjectCount -= 1
        }
      }
    }
    if (userCompanyProjectCount === 0) {
      return (<ViewerNoProjectsPage />)
    }

    // catch if no project selected (& none specified in the url otherwise its caught further up)
    if (!project) {
      return (<ViewerProjectSelectPage />)
    }

    // project 2fa
    const projectAuthTFARequired = this.props.authContext.actions.isProjectAuthTFARequired()
    console.log('ViewerRouter - projectAuthTFARequired: ', projectAuthTFARequired, ' userTFAEnabled:', userTFAEnabled)
    if (projectAuthTFARequired && !userTFAEnabled) {
      return (<Viewer2FAAuthPage />)
    }

    return (
      <Switch>
        <Route path={ROUTES.VIEW_PROJECT_CHANNEL} component={ViewerHomePage} />
        <Route path={ROUTES.VIEWER_SETTINGS} component={ViewerSettingsPage} />
        <Route path={ROUTES.VIEWER_SUPPORT} component={SupportPage} />
        <Route exact path={ROUTES.VIEWER} component={ViewerHomePage} />
        <Route path={ROUTES.VIEW_PROJECT} component={ViewerHomePage} />
      </Switch>
    )
  }
}

export default withNavContext(withUserContext(withAuthContext(ViewerRouter)))
