import React from 'react'

import { withSiteAdminContext, ISiteAdminMultiContext } from 'src/core/providers'

import { Company } from 'src/core/models'

import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { OBJECT_COMPANY_NAME } from 'src/constants/strings'

export type CompanyDidDeleteCallback = (company: Company) => void

interface IProps extends ISiteAdminMultiContext {
  company: Company
  onDidDelete?: CompanyDidDeleteCallback
}
interface IState {}

class CompanyDeleteView extends React.Component<IProps & { className?: string }, IState> {
  render () {
    const { company, className } = this.props
    if (!company) return (<></>)
    return (
      <div className={(className ? ' ' + className : '')}>
        <ArkSpacer />
        <ArkManagerDeleteButton
          itemId={company.id}
          itemName={company.name}
          itemTypeName={OBJECT_COMPANY_NAME}
          buttonTitle={'DELETE ' + OBJECT_COMPANY_NAME}
          onDelete={this.onDelete}
          onDeleteComplete={() => { if (this.props.onDidDelete) this.props.onDidDelete(company) }}
        />
      </div>
    )
  }

  onDelete = async (_itemId: number): Promise<boolean> => {
    // NB: DON'T catch the error/exception here, let it throw (or if you do, rethrow it so the calling code can catch & display it)
    const { company } = this.props
    const deleteResult = await this.props.siteAdminContext.actions.deleteCompany(company.id)
    return deleteResult
  }
}

export default withSiteAdminContext(CompanyDeleteView)
