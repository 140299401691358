import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { DEFAULT_PICKER_COLOR, STATUS_ENABLED } from 'src/constants/config'
import * as ROUTES from 'src/constants/routes'
import { OBJECT_PROGRAM_NAME } from 'src/constants/strings'
import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkHint from 'src/core/components/ArkHint'
import ArkIcon from 'src/core/components/ArkIcon'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkOnlineStatusIndicator from 'src/core/components/ArkOnlineStatusIndicator'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { OnlineStatus, Program, Project, UserProject } from 'src/core/models'
import { useProjectStatus, UserContext } from 'src/core/providers'
import { getOnlineStatusText } from 'src/core/utilities/status'

import ProgramDeleteView, { ProjectProgramDeleteCallback } from './ProgramDeleteView'
import ProjectProgramProtocolURLsView from './ProjectProgramProtocolURLsView'
import ProgramRecreateView, { ProjectProgramRecreateCallback } from './ProgramRecreateView'

import styles from './ProjectProgramSidebar.module.css'

export type ProjectProgramEditCallback = (program: Program) => void

interface IProps {
  companyId: number
  project: UserProject | Project
  program?: Program
  serverProgramConfigVersion?: number // the current program config version the server uses (so we can check if the program is older & can/should be re-created/updated)
  onEdit?: ProjectProgramEditCallback
  onDidDelete?: ProjectProgramDeleteCallback
  onDidRecreate?: ProjectProgramRecreateCallback
}

const ProjectProgramSidebar = (props: IProps) => {
  const projectStatus = useProjectStatus()

  const { companyId, project, program, serverProgramConfigVersion, onEdit: _onEdit, onDidDelete, onDidRecreate } = props
  if (!companyId || !project || !program) return (<></>)

  const { actions: userActions } = useContext(UserContext)
  const isSiteAdmin = userActions.isSiteAdmin()

  const showAudioOnlyNoProtocolWarning = (program.isAudioOnly && project.transcoderSettings !== undefined && !project.transcoderSettings.hasAudioOnlyProtocolsEnabled())
  const showAudioOnlyAnd360VideoWarning = (program.isAudioOnly && program.is360Video)

  const programUpdateAvailable = isSiteAdmin && program.shouldUpdateConfigVersion(serverProgramConfigVersion) // NB: skip if the user isn't a site admin

  const onEdit = () => {
    if (_onEdit && program) _onEdit(program)
  }

  const onlineStatus: OnlineStatus | false = STATUS_ENABLED && projectStatus.getProgramOnlineStatus(program.id)

  // console.log('ProjectProgramSidebar - program:', props.program)

  return (
    <>

      <ArkPanel bordered className={styles.sidebarHeader}>
        <ArkPanel.Header
          className={styles.sidebarTitle}
          title={program.name}
          icon={
            <ArkAvatar name={program.name ?? ''}
              initials={program.shortNameCapitalised() ?? ''}
              maxInitials={3}
              size="40"
              round={false}
              color={program.colour ?? DEFAULT_PICKER_COLOR}
              onlineStatus={onlineStatus || undefined}
              variant='program'
            />
          }
        />
        <ArkIconButton
          className={styles.menuButton}
          name='settings'
          onClick={onEdit}
          size={24}
        />
      </ArkPanel>

      {/* <ArkButton type="button" fluid size="large" onClick={onEdit}>EDIT {OBJECT_PROGRAM_NAME}</ArkButton> */}

      <ArkSpacer />

      <ArkPanel bordered>
        <ArkPanel.Properties>
          <ArkPanel.PropertySection title={OBJECT_PROGRAM_NAME + ' Details'} titleClassName={styles.panelTitle}>
            <ArkPanel.PropertyRow title='ID:' value={program.id} />
            <ArkPanel.PropertyRow title='Name:' value={program.name} />
            <ArkPanel.PropertyRow title='Short Name:' value={program.shortName} />
            {onlineStatus
              ? (
                <ArkPanel.PropertyRow
                  title='Status:'
                  value={(
                    <>
                      <ArkOnlineStatusIndicator
                        onlineStatus={onlineStatus}
                        size={12}
                        variant='program'
                      />
                      <ArkSpacer />
                      <div>{getOnlineStatusText(onlineStatus)}</div>
                    </>
                  )}
                />)
              : (
                <ArkPanel.PropertyRow title='Status:' value={
                  program.online === 'online'
                    ? <span className={`${styles.status} ${styles.online}`}>⊛ ONLINE</span>
                    : <span className={`${styles.status} ${styles.offline}`}>⊘ OFFLINE</span>
                } />
              )}
          </ArkPanel.PropertySection>
          {(program.isAudioOnly || program.is360Video) && (
            <ArkPanel.PropertyRow
              title='Flags:'
              value={
                <>
                  {(program.isAudioOnly && (<div>Audio Only</div>))}
                  {(program.is360Video && (<div>360° Video</div>))}
                </>
              }
              titleClassName={styles.programFlagTitle}
              valueClassName={styles.programFlagValues}
            />
          )}
          {(showAudioOnlyNoProtocolWarning || showAudioOnlyAnd360VideoWarning) && (
            <ArkPanel.PropertyRow>
              <ArkPanel.PropertyRowContent>
                {showAudioOnlyNoProtocolWarning && (
                  <div className={styles.programFlagWarningNotice}>
                    <div className={styles.warningIcon}><ArkIcon name='warning' size={28} color={'#f3be0e'} /></div>
                    <div className={styles.warningText}>
                      Audio only program without audio only protocol set.
                      &nbsp;<Link to={ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_SETTINGS, project.id)}>View project settings</Link>
                    </div>
                  </div>
                )}
                {showAudioOnlyAnd360VideoWarning && (
                  <div className={styles.programFlagWarningNotice}>
                    <div className={styles.warningIcon}><ArkIcon name='warning' size={28} color={'#f3be0e'} /></div>
                    <div className={styles.warningText}>Audio only will override 360 video and no video will be shown.</div>
                  </div>
                )}
              </ArkPanel.PropertyRowContent>
            </ArkPanel.PropertyRow>
          )}
          {programUpdateAvailable && (
            <ArkPanel.PropertyRow>
              <ArkPanel.PropertyRowContent>
                <div className={styles.programUpdateAvailableNotice}>
                  <div className={styles.warningIcon}><ArkIcon name='warning' size={28} color={'#f3be0e'} /></div>
                  <div className={styles.warningText}>
                    Program version update available: v{program.programConfigVersion} &gt; v{serverProgramConfigVersion}
                  </div>
                  <div className={styles.warningHint}>
                    <ArkHint
                      iconSize={16}
                      message={'There are program config changes available that may improve or fix performance of this program. If you are unsure then please contact support.'}
                      popupPosition='bottom right'
                      popupSize='small'
                      title={'Config Changes Available'}
                      type='info-circle'
                    />
                  </div>
                </div>
                <div className={styles.programUpdateAction}>
                  <ProgramRecreateView
                    companyId={companyId}
                    projectId={project.id}
                    program={program}
                    onDidRecreate={onDidRecreate}
                  />
                </div>
              </ArkPanel.PropertyRowContent>
            </ArkPanel.PropertyRow>
          )}
          <ArkPanel.PropertyDivider />

          {program && (<ProjectProgramProtocolURLsView program={program} />)}

          <ArkPanel.PropertySpacer small />
        </ArkPanel.Properties>
      </ArkPanel>

      <ArkSpacer grow />

      <ProgramDeleteView
        companyId={companyId}
        projectId={project.id}
        program={program}
        onDidDelete={onDidDelete}
      />
    </>
  )
}

export default ProjectProgramSidebar
