import React from 'react'

import { withAuthContext, IAuthMultiContext } from 'src/core/providers'

import ArkButton from 'src/core/components/ArkButton'
import ArkDivider from 'src/core/components/ArkDivider'

import { Segment, Header, Message, Label } from 'semantic-ui-react'

interface IProps extends IAuthMultiContext {
  showVerifyRequiredMsg: boolean
}
interface IState {
  emailSent: boolean
  emailError: boolean
  isSubmitting: boolean
}

class VerifyEmailForm extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = {
      emailSent: false,
      emailError: false,
      isSubmitting: false
    }
  }

  render () {
    const { isSubmitting } = this.state
    return (
      <Segment inverted>

        <Header as="h2" textAlign="center">
          Check Your Email
        </Header>

        {this.state.emailSent && (
          <Message positive>
            <Message.Header>Email Sent</Message.Header>
            <p>A verify link has been sent to your email address.</p>
            <p>Please follow the link in the email to complete your verification.</p>
          </Message>
        )}
        {this.state.emailError && (
          <Message negative>
            <Message.Header>Email Error</Message.Header>
            <p>There was a problem re-sending a verify link to your email address.</p>
            <p>Please try again or contact us if the problem persists.</p>
          </Message>
        )}

        {this.state.emailSent === false && (
          <Label size="large">
            {/* {this.props.showVerifyRequiredMsg && (
              <p>You must verify your account to access this area.</p>
            )} */}
            <p>We sent a verification email when you registered...</p>
            <p>Please follow the link in the verification email sent to you during sign-up.</p>
            <ArkDivider />
            <p>If you can&apos;t find the email, click below to re-send it.</p>
            <ArkButton color="blue" fluid basic size="large" onClick={this.resendVerifyEmail} loading={isSubmitting}>
              Re-Send Verify Email
            </ArkButton>
          </Label>
        )}

      </Segment>
    )
  }

  resendVerifyEmail = async () => {
    this.setState({ isSubmitting: true })
    const result = await this.props.authContext.actions.resendVerifyEmail()
    this.setState({
      emailSent: result,
      emailError: result !== true, // TODO: get resendVerifyEmail to throw errors with a relevant message to show (for now we just show a generic error msg here)
      isSubmitting: false
    })
  }
}

export default withAuthContext(VerifyEmailForm)
