import { Image } from './image'
import { Notice } from './notice'

export type Card = {
  id: number
  entity_type: 'COMPANY' | 'PROJECT'
  notices: Notice[]
  owner: number // aka company_id
  project_id: number | null
  variant: 'default'
  createdAt: string
  updatedAt: string

  // permissions
  permission_all_projects: boolean
  permission_specific_projects: number[]

  // details
  name: string
  description: string

  // content
  colour: string | null
  colour_scheme: 'dark' | 'light'
  title: string | null
  message: string | null
  footer: string | null
  custom_data: string | null

  // images
  background_id: number | null
  background_opacity: number // 0 > 100
  background_size: 'contain' | 'cover'
  background: Image | null
  logo_id: number | null
  logo: Image | null
}

export const DEFAULT_COMPANY_CARD: Card = {
  id: 0,
  entity_type: 'COMPANY',
  notices: [],
  owner: 0,
  project_id: null,
  variant: 'default',
  createdAt: '1970-01-01T00:00:00.000Z',
  updatedAt: '1970-01-01T00:00:00.000Z',

  // permissions
  permission_all_projects: true,
  permission_specific_projects: [],

  // details
  name: '',
  description: '',

  // content
  colour: '#000000',
  colour_scheme: 'dark',
  title: '',
  message: '',
  footer: '',
  custom_data: null,

  // images
  background_id: null,
  background_opacity: 100,
  background_size: 'contain',
  background: null,
  logo_id: null,
  logo: null
}

export const DEFAULT_PROJECT_CARD: Card = {
  id: 0,
  entity_type: 'PROJECT',
  notices: [],
  owner: 0,
  project_id: 0,
  variant: 'default',
  createdAt: '1970-01-01T00:00:00.000Z',
  updatedAt: '1970-01-01T00:00:00.000Z',

  // permissions
  permission_all_projects: false,
  permission_specific_projects: [],

  // details
  name: '',
  description: '',

  // content
  colour: '#000000',
  colour_scheme: 'dark',
  title: '',
  message: '',
  footer: '',
  custom_data: null,

  // images
  background_id: null,
  background_opacity: 100,
  background_size: 'contain',
  background: null,
  logo_id: null,
  logo: null
}
