/**
 * ArkAudioMeter
 */

import React from 'react'

import { AUDIO_METERS_MULTIPLIER } from '../../../constants/config'

import styles from './ArkAudioMeter.module.css'

interface ArkAudioMeterProps {
  className?: string
  color?: string
  disabled?: boolean
  value?: number | undefined
}

const ArkAudioMeter = (props: ArkAudioMeterProps) => {
  const { className, color = 'var(--tx-light)', disabled, value = 0 } = props

  const _color = disabled ? 'var(--red)' : color
  const _value = Math.min(value * AUDIO_METERS_MULTIPLIER, 1)
  const height = disabled ? 0 : Math.round(_value * 100)

  return (
    <div className={`${styles.meter} ${className || ''}`}>
      <div className={styles.track} style={{ backgroundColor: _color }} />
      <div className={styles.bar} style={{ backgroundColor: _color, height: `${height}%` }} />
    </div>
  )
}

export default ArkAudioMeter
