import React from 'react'

import StreamhubPage from '../../components/StreamhubPage/StreamhubPage'
import StreamhubAssetsView from './StreamhubAssetsView'

class StreamhubAssetsPage extends React.Component<{}, {}> {
  render () {
    return (
      <StreamhubPage>
        <StreamhubAssetsView />
      </StreamhubPage>
    )
  }
}
export default StreamhubAssetsPage
