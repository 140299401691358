/**
 * LeftSidebar
 */

import React, { ReactNode, useContext } from 'react'

import { LayoutContext } from '../../providers/LayoutProvider'
import { ResponsiveContext } from '../../providers/ResponsiveProvider'
import ArkCompanyDropdown from '../ArkCompanyDropdown/ArkCompanyDropdownRenderer'
import ArkIconButton from '../ArkIconButton'
import ArkOnboardingPopup from '../ArkOnboardingPopup'
import ArkProjectDropdown from '../ArkProjectDropdown/ArkProjectDropdownRenderer'
import ArkResponsive from '../ArkResponsive'
import ArkSpacer from '../ArkSpacer'

import styles from './LeftSidebar.module.css'

interface LeftSidebarProps {
  children?: ReactNode
}

const LeftSidebar = (props: LeftSidebarProps) => {
  const { children } = props

  const { actions: layoutActions, store: layoutStore } = useContext(LayoutContext)
  const { setIsLeftSidebarOpen, setLeftSidebarState } = layoutActions
  const { isLeftSidebarOpen, leftSidebarState } = layoutStore

  const { store: responsiveStore } = useContext(ResponsiveContext)
  const { isDesktop, isMobile } = responsiveStore

  const onCollapseClick = () => {
    if (leftSidebarState === 'compact') setLeftSidebarState('hidden')
    if (leftSidebarState === 'full') setLeftSidebarState('compact')
  }

  const onExpandClick = () => {
    if (leftSidebarState === 'compact') setLeftSidebarState('full')
    if (leftSidebarState === 'hidden') setLeftSidebarState('compact')
  }

  const controlsComponent = (
    <div className={styles.controlsContainer}>
      <ArkOnboardingPopup
        content='Use these to expand and collapse the main menu.'
        id='viewerLeftSidebarExpandCollapse'
        offset={[40, 5]}
      >
        <div className={styles.controls}>
          <ArkIconButton
            disabled={leftSidebarState === 'hidden'}
            name='angle-left'
            onClick={onCollapseClick}
            size={24}
          />
          <ArkIconButton
            disabled={leftSidebarState === 'full'}
            name='angle-right'
            onClick={onExpandClick}
            size={24}
          />
        </div>
      </ArkOnboardingPopup>
    </div>
  )

  return (
    <>
      <ArkResponsive mobileOnly>
        <div
          className={`${styles.background} ${isLeftSidebarOpen ? styles.show : ''}`}
          onClick={() => setIsLeftSidebarOpen(false)}
        />
      </ArkResponsive>
      <ArkResponsive desktopOnly>
        <div
          className={`${
            styles.spacer
          } ${
            leftSidebarState === 'compact' ? styles.compact : ''
          } ${
            leftSidebarState === 'hidden' ? styles.hidden : ''
          }`}
        />
      </ArkResponsive>
      <div
        className={`${
          styles.container
        } ${
          isDesktop && leftSidebarState === 'compact' ? styles.compact : ''
        } ${
          isDesktop && leftSidebarState === 'hidden' ? styles.hidden : ''
        } ${
          isMobile ? styles.mobile : ''
        } ${
          isMobile && isLeftSidebarOpen ? styles.open : ''
        }`}
      >
        <div className={styles.main}>
          <ArkResponsive mobileOnly>
            <ArkSpacer size={60} />
            <div className={styles.dropdowns}>
              <ArkCompanyDropdown />
              <ArkSpacer />
              <ArkProjectDropdown />
            </div>
          </ArkResponsive>
          {children}
          <ArkSpacer />
        </div>
        <ArkResponsive desktopOnly>
          {controlsComponent}
        </ArkResponsive>
      </div>
    </>
  )
}

export default LeftSidebar
