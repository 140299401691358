import React from 'react'
import { Label } from 'semantic-ui-react'

import { AUDIO_LEVELS_ENABLED, AUTO_SOLO_ENABLED, SOLO_ENABLED } from 'src/constants/config'
import ArkAudioMeter from 'src/core/components/ArkAudioMeter'
import ArkButton from 'src/core/components/ArkButton'
import ArkSpacer from 'src/core/components/ArkSpacer'
import ArkVolumeInput from 'src/core/components/ArkVolumeInput'

import styles from './ChannelMixerPanel.module.css'

// FIXME rename "master" to "channel"

interface ChannelMixerPanelProps {
  audioLevelPostFader: number
  audioLevelPostFaderDisabled: boolean
  audioLevelPreFader: number
  audioStarted?: boolean
  autoSolo: boolean
  color?: string
  master?: boolean
  mute: boolean
  muteDisabled?: boolean
  name?: string
  noAudio: boolean
  onAutoSoloClick?: () => void
  onMuteClick: () => void
  onSoloClick?: () => void
  onStartAudioClick?: () => void
  onVolumeChange: (value: number) => void
  solo?: boolean
  soloDisabled?: boolean
  volume: number
}

const ChannelMixerPanel = ({
  audioLevelPostFader,
  audioLevelPostFaderDisabled,
  audioLevelPreFader,
  audioStarted,
  autoSolo,
  color,
  master,
  mute,
  muteDisabled,
  name,
  noAudio,
  onAutoSoloClick,
  onMuteClick,
  onSoloClick,
  onStartAudioClick,
  onVolumeChange,
  solo,
  soloDisabled,
  volume
}: ChannelMixerPanelProps) => {
  return (
    <div className={`${styles.panel} ${master ? styles.panelMaster : ''}`}>
      {/* buttons */}
      {master && AUTO_SOLO_ENABLED && (
        <ArkButton
          className={styles.noPaddingButton}
          color={autoSolo ? 'green' : undefined}
          fluid
          onClick={onAutoSoloClick}
        >
          AUTO SOLO
        </ArkButton>
      )}
      {!master && SOLO_ENABLED && (
        <div className={styles.autoSolo}>
          <ArkButton
            color={solo ? 'green' : undefined}
            disabled={soloDisabled}
            fluid
            onClick={onSoloClick}
          >
            SOLO
          </ArkButton>
          {autoSolo && (
            <Label circular className={styles.autoSoloLabel} color='green' size='tiny'>AUTO</Label>
          )}
        </div>
      )}
      <ArkSpacer />
      {master && !audioStarted
        ? (
          <ArkButton
            className={styles.noPaddingButton}
            color='green'
            fluid
            onClick={onStartAudioClick}
          >
            START AUDIO
          </ArkButton>
        )
        : (
          <ArkButton
            color={mute ? 'red' : undefined}
            disabled={muteDisabled}
            fluid
            onClick={onMuteClick}
          >
            MUTE
          </ArkButton>
        )}

      <ArkSpacer size={20} />

      {/* fader */}
      <div className={styles.fader}>
        {/* pre audio meter */}
        {AUDIO_LEVELS_ENABLED && (
          <div className={styles.faderPre}>
            <ArkAudioMeter color='var(--yellow)' disabled={noAudio} value={audioLevelPreFader} />
            <div className={`${styles.faderLabel} ${styles.faderLabelPre}`}>PRE</div>
          </div>
        )}

        <ArkSpacer large />

        {/* volume input */}
        <ArkVolumeInput large onChange={onVolumeChange} size={250} value={volume} vertical />

        <ArkSpacer large />

        {/* post audio meter */}
        {AUDIO_LEVELS_ENABLED && (
          <div className={styles.faderPost}>
            <ArkAudioMeter
              color='var(--green)'
              disabled={audioLevelPostFaderDisabled}
              value={audioLevelPostFader} />
            <div className={`${styles.faderLabel} ${styles.faderLabelPost}`}>POST</div>
          </div>
        )}

      </div>

      <ArkSpacer size={20} />

      {/* status */}
      {noAudio
        ? (
          <div className={styles.disabledLabel}>NO AUDIO</div>
        )
        : (
          <div className={styles.volume}>
            <div className={`${styles.volumeLabel} ${mute ? styles.volumeLabelMute : ''}`}>
              {`VOL: ${Math.round(volume * 100)}%`}
            </div>
          </div>
        )}

      <ArkSpacer size={20} />

      {/* name */}
      <div className={styles.name} style={color ? { borderColor: color } : undefined}>
        <div className={styles.nameLabel}>{master ? 'MASTER' : name}</div>
      </div>
    </div>
  )
}

export default ChannelMixerPanel
