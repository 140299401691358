import { ChannelGridLayout, ChannelLayout } from 'src/core/types/channel'

import { LocalConfig, LocalConfigChannel, LocalConfigCompany, LocalConfigProgram, LocalConfigProject } from './types'

export const DEFAULT_CONFIG: LocalConfig = {
  version: 3,
  companies: {}
}

export const DEFAULT_COMPANY: LocalConfigCompany = {
  id: 0,
  projects: {}
}

export const DEFAULT_PROJECT: LocalConfigProject = {
  id: 0,
  channels: {}
}

export const DEFAULT_CHANNEL: LocalConfigChannel = {
  id: 0,
  abr: true,
  advancedParameters: '',
  autoSolo: true,
  autoSoloProgram: undefined,
  buffer: 500,
  gridLayout: ChannelGridLayout.GridAuto,
  layout: ChannelLayout.Grid,
  mute: false,
  passthrough: false,
  programs: {},
  resolution: '1080p',
  selectedProgram: undefined,
  volume: 1
}

export const DEFAULT_PROGRAM: LocalConfigProgram = {
  id: 0,
  mute: false,
  solo: false,
  volume: 0.65
}
