/**
 * ProgramsView
 */

import React from 'react'

import { ChannelLayout } from '../../../core/types/channel'
import { useViewer } from '../../providers/ViewerProvider'
import ProgramsBottomBarView from './ProgramsBottomBarView'
import ProgramsGridView from './ProgramsGridView'

const ProgramsView = () => {
  const viewer = useViewer()

  if (viewer.getChannelLayout() === ChannelLayout.BottomBar) {
    return <ProgramsBottomBarView />
  } else {
    return <ProgramsGridView />
  }
}
export default ProgramsView
