import React from 'react'

import ArkProjectManagerPage from 'src/manager/project/components/ArkProjectManagerPage/ArkProjectManagerPage'
import Ark2FAAuthView from 'src/core/components/Ark2FAAuthView'

export interface Project2FAAuthPageProps {}

const Project2FAAuthPage = (_props: Project2FAAuthPageProps) => {
  return (
    <ArkProjectManagerPage disableSectionMainMenu>
      <Ark2FAAuthView />
    </ArkProjectManagerPage>
  )
}

export default Project2FAAuthPage
