import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import PrivateRoute, { AccessLevel } from './core/components/PrivateRoute/PrivateRoute'
import { withUserContext, IUserMultiContext, UserStatus, withNavContext, INavMultiContext, withCompanyInviteContext, ICompanyInviteMultiContext, withAuthContext, IAuthMultiContext } from './core/providers'
import * as ROUTES from './constants/routes'
import { PROJECT_GUEST_PROFILE_ACCESS_ENABLED } from './constants/config'

import ViewerRouter from './viewer/ViewerRouter'
import ProjectManagerRouter from './manager/project/ProjectManagerRouter'
import CompanyManagerRouter from './manager/company/CompanyManagerRouter'
import AdminRouter from './admin/AdminRouter'

import LoadingPage from './core/pages/loading/LoadingPage'

import HomePage from './core/pages/home/HomePage'
import { LoginPage, LoginPasswordResetPage } from './core/pages/auth/Login'
import RegisterPage from './core/pages/auth/Register'
import VerifyEmailInputPage from './core/pages/auth/Verify/VerifyEmailInputPage'
import LoginExternalDevicePage from './core/pages/auth/Login/LoginExternalDevicePage'
import SupportPage from './core/pages/support/SupportPage'
import CompanyInviteAcceptPage from './core/pages/invite/CompanyInvite/CompanyInviteAcceptPage'
import QuickviewPage from './core/pages/quickview/QuickviewPage'

import UserAccountRouter from './core/pages/account/UserAccountRouter'

interface IProps extends IUserMultiContext, INavMultiContext, ICompanyInviteMultiContext, IAuthMultiContext {}
interface IState {}

// NB: the <Router> element is now added at the top App level, so the NavProvider can also make use of it
class AppRouter extends React.Component<IProps, IState> {
  componentDidUpdate (prevProps: IProps) {
    // TODO:
    // TODO: move this redirect handling up to a (dedicated?) provider?? <<<<<<<
    // TODO:
    // TODO: what if this.props.userContext.store is empty? how/when can that happen, am seeing it during login without a page reload
    const currentPath = this.props.navContext.store.currentPath
    const userJustLoggedIn = !!(this.props.userContext.store.user && !prevProps.userContext.store.user)
    const userJustLoggedOut = !!(!this.props.userContext.store.user && prevProps.userContext.store.user)
    // console.log('AppRouter - componentDidUpdate - userJustLoggedIn: ', userJustLoggedIn, ' userJustLoggedOut: ', userJustLoggedOut, ' currentPath: ', currentPath)
    if (userJustLoggedIn) {
      // if the user just logged in, check if they came via a company invite link (it'll be cached in local storage)
      // TODO: should we only check for a cached company invite after just logging in, or always do it on page load incase the invite failed to get used iniitally?
      // if (userJustLoggedIn) {
      const cachedInvite = this.props.companyInviteContext.actions.getCachedInvite()
      const inviteToken = cachedInvite && cachedInvite.companyInvite ? cachedInvite.companyInvite as string : undefined
      if (inviteToken) {
        console.log('AppRouter - componentDidUpdate - JUST LOGGED IN - WITH CACHED INVITE - REDIRECT TO INVITE ACCEPT..')
        this.props.navContext.actions.goto(ROUTES.USER_ACCEPT_COMPANY_INVITE.replace(':inviteToken', inviteToken))
      } else if (currentPath === ROUTES.LOGIN || currentPath === ROUTES.LOGIN_SSO || currentPath === ROUTES.REGISTER || currentPath === ROUTES.REGISTER_SSO) {
        // TESTING: check if a redirect path is set from `PrivateRoute` redirecting to login (or register?) from..
        // TESTING: ..when the user first navigated there & got redirected to login/register first, redirect them back to their path now they've logged in
        const redirectFrom = this.props.navContext.actions.getRedirectPath()
        console.log('AppRouter - componentDidUpdate - JUST LOGGED IN - redirectFrom:', redirectFrom)
        if (redirectFrom && redirectFrom.pathname) {
          const path = redirectFrom.pathname + (redirectFrom.search || '') + (redirectFrom.hash || '') // TESTING: add the search & hash back in before redirecting to it
          console.log('AppRouter - componentDidUpdate - JUST LOGGED IN - REDIRECT TO PREV PATH:', path)
          this.props.navContext.actions.goto(path)
        } else {
          console.log('AppRouter - componentDidUpdate - JUST LOGGED IN - REDIRECT TO HOME..')
          this.props.navContext.actions.goto(ROUTES.HOME)
        }
      }
    } else if (userJustLoggedOut) {
      // redirect to the login page if the user just logged out (and they're not already on the login page)
      // NB: skip the default post logout redirect if a custom logout callback is set in the auth provider
      const hasLogoutCallback = this.props.authContext.store.logoutCallback !== undefined
      // console.log('AppRouter - componentDidUpdate - userJustLoggedOut:', userJustLoggedOut, ' currentPath:', currentPath, ' hasLogoutCallback:', hasLogoutCallback)
      if (!hasLogoutCallback && currentPath !== ROUTES.LOGIN) {
        console.log('AppRouter - componentDidUpdate - JUST LOGGED OUT - REDIRECT TO LOGIN..')
        this.props.navContext.actions.goto(ROUTES.LOGIN)
      }
    }
  }

  render () {
    // show a full page loading indicator until the initial server data loading completes
    if (this.props.userContext.store.userStatus === UserStatus.init || this.props.userContext.store.userStatus === UserStatus.loading) {
      return (
        <Router>
          <LoadingPage />
        </Router>
      )
    }

    const authUser = this.props.userContext.store.user

    const routes = (
      <>
        <Switch>

          {/* PUBLIC ROUTES */}
          <Route exact path={ROUTES.LOGIN} component={LoginPage} />
          <Route exact path={ROUTES.LOGIN_SSO} component={LoginPage} />{/* TESTING: pass the SSO callback route to the main login page where it handles it accordingly */}
          <Route exact path={ROUTES.LOGIN_PASSWORD_FORGOT} component={LoginPasswordResetPage} />
          <Route exact path={ROUTES.LOGIN_PASSWORD_RESET} component={LoginPasswordResetPage} />
          <Route exact path={ROUTES.LOGIN_EXTERNAL_DEVICE} component={LoginExternalDevicePage} />
          <Route exact path={ROUTES.REGISTER} component={RegisterPage} />
          <Route exact path={ROUTES.REGISTER_SSO} component={RegisterPage} />
          <Route exact path={ROUTES.USER_VERIFY} component={VerifyEmailInputPage} />
          <Route exact path={ROUTES.USER_VERIFY_TOKEN} component={VerifyEmailInputPage} />

          {/* NB: these are public routes which prompts to login if needed */}
          <Route path={ROUTES.USER_ACCEPT_COMPANY_INVITE} component={CompanyInviteAcceptPage} />
          <Route exact path={ROUTES.USER_ACCEPT_COMPANY_INVITE_BASE} component={CompanyInviteAcceptPage} />
          <Route exact path={ROUTES.QUICKVIEW_BASE} component={QuickviewPage} />

          {/* TODO: redirect differently depending if logged in or not <<<<< */}
          {/* <Redirect exact from={ROUTES.HOME} to={ROUTES.VIEWER} /> */}
          <Route exact path={ROUTES.HOME} component={HomePage} />

          <Route path={ROUTES.SUPPORT} component={SupportPage} />

          {/* PRIVATE ROUTES */}
          {/* project viewer */}
          <PrivateRoute path={ROUTES.VIEW_PROJECT} component={ViewerRouter} authUser={authUser} accessLevel={AccessLevel.UserVerified} />
          <PrivateRoute path={ROUTES.VIEWER} component={ViewerRouter} authUser={authUser} accessLevel={AccessLevel.UserVerified} />

          {/* project manager */}
          <PrivateRoute path={ROUTES.PROJECT_MANAGER_VIEW} component={ProjectManagerRouter} authUser={authUser} accessLevel={AccessLevel.UserVerified} />
          <PrivateRoute path={ROUTES.PROJECT_MANAGER_SELECT} component={ProjectManagerRouter} authUser={authUser} accessLevel={AccessLevel.UserVerified} />

          {/* company manager */}
          <PrivateRoute path={ROUTES.COMPANY_MANAGER} component={CompanyManagerRouter} authUser={authUser} accessLevel={AccessLevel.UserVerified} />

          {/* god admin */}
          <PrivateRoute path={ROUTES.ADMIN} component={AdminRouter} authUser={authUser} accessLevel={AccessLevel.UserVerified} />

          {/* account (NB: guest access disabled) */}
          <PrivateRoute path={ROUTES.ACCOUNT} component={UserAccountRouter} authUser={authUser} accessLevel={AccessLevel.UserVerified} allowGuest={PROJECT_GUEST_PROFILE_ACCESS_ENABLED} />

        </Switch>
      </>
    )
    return routes
  }
}

export default withCompanyInviteContext(withNavContext(withUserContext(withAuthContext(AppRouter))))
