/**
 * Channel types
 */

export type ChannelGridCoords = {
  x: number;
  y: number;
}

export const DEFAULT_COORDS: ChannelGridCoords = { x: 1, y: 1 }

export enum ChannelGridLayout {
  GridAuto,
  Grid1x1,
  Grid1x2,
  Grid1x3,
  Grid2x1,
  Grid2x2,
  Grid2x3,
  Grid3x1,
  Grid3x2,
  Grid3x3,
}

export type ChannelGridCoordsLayout = {
  coords: ChannelGridCoords;
  layout: ChannelGridLayout;
}

export const ChannelGridCoordsLayoutMap: ChannelGridCoordsLayout[] = [
  { coords: { x: 1, y: 1 }, layout: ChannelGridLayout.Grid1x1 },
  { coords: { x: 1, y: 2 }, layout: ChannelGridLayout.Grid1x2 },
  { coords: { x: 1, y: 3 }, layout: ChannelGridLayout.Grid1x3 },
  { coords: { x: 2, y: 1 }, layout: ChannelGridLayout.Grid2x1 },
  { coords: { x: 2, y: 2 }, layout: ChannelGridLayout.Grid2x2 },
  { coords: { x: 2, y: 3 }, layout: ChannelGridLayout.Grid2x3 },
  { coords: { x: 3, y: 1 }, layout: ChannelGridLayout.Grid3x1 },
  { coords: { x: 3, y: 2 }, layout: ChannelGridLayout.Grid3x2 },
  { coords: { x: 3, y: 3 }, layout: ChannelGridLayout.Grid3x3 }
]

export enum ChannelLayout {
  BottomBar,
  Grid,
}

export const ChannelLayoutDefault = ChannelLayout.BottomBar
