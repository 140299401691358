/**
 * ArkCenterLayout
 */

import React, { ReactNode } from 'react'

import styles from './ArkCenterLayout.module.css'

export interface ArkCenterLayoutProps {
  children: ReactNode
  className?: string
}

const ArkCenterLayout = (props: ArkCenterLayoutProps) => {
  const { children, className } = props
  return (
    <div className={`${styles.container} ${className || ''}`}>
      {children}
    </div>
  )
}

export default ArkCenterLayout
