import React, { useRef, useState } from 'react'
import useResizeObserver from 'use-resize-observer'

import { Card } from 'src/core/models'

import ArkIcon from '../ArkIcon'
import styles from './ArkCard.module.css'

export interface ArkCardProps {
  card: Card
  className?: string
  disabled?: boolean
  dismissible?: boolean
  onClick?: () => void
  onDismiss?: () => void
  rounded?: boolean
  viewer?: boolean
}

const ArkCard = (props: ArkCardProps) => {
  const container = useRef<HTMLDivElement>(null)

  const [containerHeight, setContainerHeight] = useState<number>()

  useResizeObserver<HTMLDivElement>({
    onResize: (size) => setContainerHeight(size.height),
    ref: container
  })

  const scale: number = containerHeight ? containerHeight / 1080 : 1

  return (
    <div
      className={`${
        styles.container
      } ${
        !props.disabled && props.onClick && styles.clickable
      } ${
        props.rounded && styles.rounded
      } ${
        props.className
      }`}
      onClick={props.disabled ? undefined : props.onClick}
      ref={container}
      style={{ ...(props.card.colour && { backgroundColor: props.card.colour }) }}
    >
      <div
        className={styles.content} style={{ transform: `translate(-50%, -50%) scale(${scale})` }}>
        <div className={styles.body}>
          <div
            className={styles.background}
            style={{
              ...(props.card.background && { backgroundImage: `url('${props.card.background.url}')` }),
              backgroundSize: props.card.background_size,
              opacity: props.card.background_opacity / 100
            }}
          />
          <div
            className={`${
              styles.text
            } ${
              props.card.colour_scheme === 'dark'
                ? styles.textDark
                : styles.textLight
            }`}
          >
            <div className={styles.title}>{props.card.title}</div>
            <div className={styles.message}>{props.card.message}</div>
            <div className={styles.footer}>{props.card.footer}</div>
          </div>
        </div>
      </div>
      {props.viewer && (
        <>
          {props.dismissible
            ? (
              <div className={styles.dismissible} onClick={props.onDismiss}>
                <ArkIcon className={styles.dismissibleIcon} color='var(--tx-muted)' name='close' />
              </div>
            )
            : (
              <div className={styles.notDismissible}>
                <ArkIcon className={styles.notDismissibleIcon} color='var(--th-danger)' name='padlock-closed' />
              </div>
            )}
        </>
      )}
    </div>
  )
}

export default ArkCard
