import React, { useContext, useEffect, useRef, useState } from 'react'

import { NavContext, UserContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import { Company, CompanyVideoEngine, Project } from 'src/core/models'

import ProjectDeleteView, { ProjectDidDeleteCallback } from './ProjectDeleteView'

import ArkButton from 'src/core/components/ArkButton'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_PROJECT_NAME, SECTION_MANAGER_SUFFIX_NAME } from 'src/constants/strings'

import styles from './CompanyProjectSidebar.module.css'

export type ProjectEditCallback = (project: Project) => void

interface IProps {
  company: Company
  project?: Project
  videoEngines?: Array<CompanyVideoEngine> // NB: already loaded in the parent component, so we just pass it in
  defaultVideoEngineId?: number
  onEdit?: ProjectEditCallback
  onDidDelete?: ProjectDidDeleteCallback
}

const CompanyProjectSidebar = (props: IProps) => {
  const mounted = useRef(false)

  const { company, project, videoEngines, defaultVideoEngineId, onEdit: _onEdit, onDidDelete: _onDidDelete } = props

  const { actions: userActions } = useContext(UserContext) // store: userStore
  const { actions: navActions } = useContext(NavContext) // store: navStore

  const [loadingProject, setLoadingProject] = useState<boolean>(false)
  const [projectDeleted, setProjectDeleted] = useState<boolean>(false)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const resetView = () => {
    // reset the state
    setLoadingProject(false)
    setProjectDeleted(false)
  }

  // -------

  // TESTING: equivalent to the componentDidUpdate method `if (this.props.company?.id !== prevProps.company?.id || this.props.project?.id !== prevProps.project?.id) { this.resetView() }`
  useEffect(() => {
    if (company?.id || project?.id) {
      resetView()
    }
  }, [company?.id, project?.id])

  // -------

  const onEdit = () => {
    if (_onEdit && project) _onEdit(project)
  }

  const onDidDeleteProject = (project: Project) => {
    if (_onDidDelete) _onDidDelete(project)
    setProjectDeleted(true)
  }

  // -------

  const onOpenProjectManager = async () => {
    if (project) {
      setLoadingProject(true)
      await userActions.selectProject(project.id)
      navActions.goto(ROUTES.PROJECT_MANAGER_VIEW.replace(':projectId', '' + project.id))
      if (mounted.current) setLoadingProject(false) // reset this just incase the nav failed for some unlikely reason
    }
  }

  if (!project) return null

  const defaultVideoEngine = defaultVideoEngineId ? videoEngines?.find(ve => ve.id === defaultVideoEngineId) : undefined
  const projectVideoEngine = videoEngines?.find(ve => ve.id === project.videoEngine?.id)
  const projectVideoEngineIsDefault = projectVideoEngine?.id === defaultVideoEngine?.id
  const projectVideoEngineIsActive = projectVideoEngine?.isActive
  const projectVideoEngineTitle = projectVideoEngine ? (projectVideoEngine.name + (projectVideoEngineIsDefault ? ' (Default)' : '') + (projectVideoEngineIsActive !== undefined ? ' (ENABLED)' : '(DISABLED)')) : 'N/A'

  return (
    <>

      <ArkPanel bordered className={styles.sidebarHeader}>
        <ArkPanel.Header className={styles.sidebarTitle} title={project.name} iconName='project' description={project.desc} />
        <ArkIconButton
          className={styles.menuButton}
          name='settings'
          onClick={onEdit}
          size={24}
        />
      </ArkPanel>

      {!projectDeleted && (<>

        <ArkSpacer />

        {/* <ArkButton type="button" fluid size="large" onClick={this.onEdit}>EDIT {OBJECT_PROJECT_NAME}</ArkButton> */}

        <ArkPanel bordered>
          <ArkPanel.Properties>
            <ArkPanel.PropertyRow title='id:' value={project.id} />
            <ArkPanel.PropertyRow title='VIDEO ENGINE:' value={projectVideoEngineTitle} />
            <ArkPanel.PropertyRow title='MAX USERS:' value={project.projectMaxUsers} />
            <ArkPanel.PropertyRow title='2FA:' value={project.force2fa ? 'REQUIRED' : 'OPTIONAL'} />
          </ArkPanel.Properties>
        </ArkPanel>

        <ArkSpacer />

        <ArkButton
          type="button"
          fluid
          size="large"
          loading={loadingProject}
          onClick={onOpenProjectManager}
        >
          OPEN IN {OBJECT_PROJECT_NAME} {SECTION_MANAGER_SUFFIX_NAME}
        </ArkButton>
      </>)}

      <ArkSpacer grow />

      <ProjectDeleteView
        company={company}
        project={project}
        onDidDelete={onDidDeleteProject}
      />

    </>
  )
}

export default CompanyProjectSidebar
