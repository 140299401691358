import React from 'react'
import _ from 'lodash'

import { viewerMenuItmes, supportMenuItems } from '../../../constants/menus'
import { withUserContext, IUserMultiContext } from '../../../core/providers'

import ArkPage from '../../../core/components/ArkPage/ArkPage'
import ArkSectionSidebarMenu from '../../../core/components/ArkSectionSidebarMenu/ArkSectionSidebarMenu'

interface IProps extends IUserMultiContext {
  children?: React.ReactNode
  disableSectionMainMenu?: boolean
  noScroll?: boolean // FIXME remove after completing mobile responsiveness
}
interface IState {}

class ArkViewerPage extends React.Component<IProps, IState> {
  render () {
    const { children, noScroll } = this.props
    return (
      <ArkPage
        leftSidebar={this.sidebarLeftContent()}
        noScroll={noScroll}
        section='viewer'
        showSelectCompany
        showSelectProject
      >
        {children}
      </ArkPage>
    )
  }

  sidebarLeftContent = () => {
    // const company = this.props.userContext.store.selectedCompany
    const project = this.props.userContext.store.selectedProject
    const channel = this.props.userContext.store.selectedChannel

    // check if the user has access to the specified project (if one is selected)
    // UPDATE: the section sidebar menu no longer uses these checks & just shows the menu items dimmed-out/disabled if the user doesn't have access
    // let showProjectMenu = false
    // if (project) {
    //   showProjectMenu = (project !== undefined)
    // }

    const mainMenuItems = viewerMenuItmes(project?.id, channel?.id)

    // disable the main menu items if the parent says so, or if the parent user data selections are loading/updating
    const loadingParentData = this.props.userContext.store.loadingCompanyData || this.props.userContext.store.loadingProjectData
    if (this.props.disableSectionMainMenu || loadingParentData) {
      _.forEach(mainMenuItems, (item) => { item.disable = true })
    }

    const bottomMenuItems = supportMenuItems()

    return (
      <ArkSectionSidebarMenu
        mainMenuItems={mainMenuItems}
        bottomMenuItems={bottomMenuItems}
      />
    )
  }
}
export default withUserContext(ArkViewerPage)
