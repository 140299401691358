/**
 * ArkHint
 *
 */

import React, { FC, SVGProps } from 'react'

import { ReactComponent as HintQuestionCircle } from 'src/assets/icons/question-circle.svg'
import { ReactComponent as HintExclamationCircle } from 'src/assets/icons/exclamation-circle.svg'
import { ReactComponent as HintExclamationTriangle } from 'src/assets/icons/exclamation-triangle.svg'
import { ReactComponent as HintInfoCircle } from 'src/assets/icons/info-circle.svg'
import { Popup, PopupContentProps, PopupProps, SemanticShorthandItem } from 'semantic-ui-react'
import styles from './ArkHint.module.css'

export type HintType =
  | 'question-circle'
  | 'exclamation-circle'
  | 'exclamation-triangle'
  | 'info-circle'

export type PopupSize = 'mini' | 'tiny' | 'small' | 'large' | 'huge'

export type PopupPosition =
  | 'top left'
  | 'top center'
  | 'top right'
  | 'bottom left'
  | 'bottom center'
  | 'bottom right'

interface ArkHintProps extends PopupProps {
  className?: string
  type: HintType
  iconSize?: number
  triggerText?: string | React.ReactNode // optionally add text or custom content next to the icon that also triggers the hint popup
  title?: string | React.ReactNode
  message?: string | React.ReactNode | SemanticShorthandItem<PopupContentProps>
  popupSize?: PopupSize
  popupPosition?: PopupPosition // NB: leave undefined to use the Popup default auto position
  color?: string
  disabledColor?: string
  disabled?: boolean
  open?: boolean // mainly for debug usage, or if you want to control when to open it manually (although we may need to expose some extra Popup callbacks to help with that)
}

const ArkHint = (props: ArkHintProps) => {
  const {
    className,
    type,
    iconSize = 28,
    triggerText,
    title = 'Hint',
    message = 'Hint message..',
    popupSize = 'small',
    popupPosition, // = 'bottom center', // NB: now making the default undefined, so auto Popup positioning kicks in by default, & you can optionally override it per use if wanted
    color = 'var(--tx-light)',
    disabledColor = 'var(--tx-dim)',
    disabled = false,
    open,
    ...otherProps
  } = props

  let Icon: FC<SVGProps<SVGSVGElement>>
  switch (type) {
    case 'question-circle': Icon = HintQuestionCircle; break
    case 'exclamation-circle': Icon = HintExclamationCircle; break
    case 'exclamation-triangle': Icon = HintExclamationTriangle; break
    case 'info-circle': Icon = HintInfoCircle; break
  }

  let trigger = <Icon className={`${styles.triggerIcon} ${props.className ? ' ' + props.className : ''}`} fill={disabled ? disabledColor : color} height={iconSize} />
  if (triggerText) {
    trigger = <div className={styles.triggerWithText}>{trigger}<span className={styles.triggerText}>{triggerText}</span></div>
  }

  return <Popup
    size={popupSize}
    className={`${styles.hint} ${className || ''}`}
    // flowing // NB: disabled/comment-out now so default sizing kicks in, & the calling code can choose if/when to enable this if they need it
    header={title}
    content={message}
    trigger={trigger}
    hideOnScroll={true}
    position={popupPosition}
    popper={{ className: styles.hintWrapper /*, style: { zIndex: 2000 } */ }} // adds a custom className to the parent element so we can then disable blurring on it via css
    disabled={disabled}
    open={open}
    hoverable
    {...otherProps}
  />
}

export default ArkHint
