import { User as Auth0User } from '@auth0/auth0-spa-js'
import { UserClaims as OktaUser } from '@okta/okta-auth-js'

export class AuthSSOUser {
  getEmail () : string | undefined {
    throw new Error('Not implemented')
  }
}

export class AuthSSOUserOkta extends AuthSSOUser {
  oktaUser: OktaUser

  constructor (oktaUser: OktaUser) {
    super()
    this.oktaUser = oktaUser
  }

  getEmail () : string | undefined {
    return this.oktaUser.email
  }
}

export class AuthSSOUserAuth0 extends AuthSSOUser {
  auth0User: Auth0User

  constructor (auth0User: Auth0User) {
    super()
    this.auth0User = auth0User
  }

  getEmail () : string | undefined {
    return this.auth0User.email
  }
}
