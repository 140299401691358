import React, { useContext, useEffect, useRef } from 'react'

import { ProjectAdminContext } from 'src/core/providers'

import { ProjectUser } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkModal from 'src/core/components/ArkModal'

import { OBJECT_PROJECT_NAME } from 'src/constants/strings'

// NB: this is currently a near identical copy of the CompanyUserReInviteView component (UPDATE: since ported from a class to function based component)
// NB: we even call the same endpoint (as a project invite is really a company invite, if you're already a company user when invited to a project you're auto added to it)
// NB: but opted to dupe it & tweak some wording to make it more project specific, & we may want to further customise it for projects...

export type ProjectUserReInviteResultCallback = (result: boolean) => void

interface IProps {
  companyId: number
  // projectId: number // NB: we don't actually need the projectId currently
  user: ProjectUser
  onResult?: ProjectUserReInviteResultCallback
  className?: string
}

const ProjectUserReInviteView = (props: IProps) => {
  const mounted = useRef(false)

  const { companyId, user, onResult: _onResult, className } = props

  const { actions: projectAdminActions } = useContext(ProjectAdminContext)

  const [showConfirmAlert, setShowConfirmAlert] = React.useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [hasSent, setHasSent] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>(undefined)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const reset = () => {
    setShowConfirmAlert(false)
    setIsSubmitting(false)
    setHasSent(false)
    setError(undefined)
  }

  // -------

  const onShowConfirmAlert = () => {
    setShowConfirmAlert(true)
  }

  const onCancel = () => {
    if (isSubmitting) return // don't allow cancel to work if already submitting
    setShowConfirmAlert(false)
    reset()
  }

  const onSendReInvite = async () => {
    try {
      // console.log('ProjectUserReInviteView - onSendReInvite')
      setIsSubmitting(true)
      setHasSent(false)
      const inviteResult = await projectAdminActions.reinviteUserToCompany(companyId, user.id)
      // console.log('ProjectUserReInviteView - onSendReInvite - inviteUserToCompany - inviteResult: ', inviteResult)
      if (inviteResult) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSent(true)
        }
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          setError(Error('A problem occurred sending the invite, please try again.'))
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  const onReInviteComplete = async () => {
    setShowConfirmAlert(false)
    if (_onResult) _onResult(true) // NB: only triggering the callback on success (error shows in place)
  }

  // -------

  const onFormSubmit = async (_fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    // NB: only the submit button triggers this, so we treat it as an 'ok' press, the cancel button fires its own callback
    onSendReInvite()
  }

  const renderDeleteModal = () => {
    const formFields: Array<ArkFormField> = []
    formFields.push({
      type: ArkFormFieldType.Group,
      key: 'buttons',
      fields: [
        { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { onClick: onCancel /*, floated: 'left' */ } },
        { type: ArkFormFieldType.OKButton, key: 'submit', label: 'RESEND', fieldProps: { loading: isSubmitting, floated: 'right' } }
      ],
      fieldProps: { widths: 'equal' /* widths: 1, floated: 'right' */, style: { border: '1px solid red !important' } },
      slimline: true
    })
    return (
      <ArkModal size='tiny' open={showConfirmAlert} onClose={() => onCancel()}>
        {!hasSent && (<>
          <div style={{ paddingBottom: 20 }}>
            <ArkHeader as='h2' inverted>Resend Invite?</ArkHeader>
            <p>Are you sure you want to resend the {OBJECT_PROJECT_NAME} invite to <strong>{user.name()}</strong>?</p>
          </div>
        </>)}

        {error && (<>
          <ArkMessage negative>
            <ArkMessage.Header>Error</ArkMessage.Header>
            <ArkMessage.Item>{error.message}</ArkMessage.Item>
          </ArkMessage>
          <ArkButton onClick={onCancel}>OK</ArkButton>
        </>)}

        {hasSent && (<>
          <ArkMessage positive>
            <ArkMessage.Header>Invite Sent Again</ArkMessage.Header>
            <ArkMessage.Item>A project invite has been sent again to <strong>{user.name()}</strong></ArkMessage.Item>
          </ArkMessage>
          <ArkButton color="blue" fluid basic size="large" onClick={onReInviteComplete}>OK</ArkButton>
        </>)}

        {!error && !hasSent && (<>
          <ArkForm
            formKey="projectUserReinvite"
            inverted
            formFields={formFields}
            formError={error}
            onFormSubmit={onFormSubmit}
          ></ArkForm>
        </>)}
      </ArkModal>
    )
  }

  // -------

  return (
    <div className={(className ? ' ' + className : '')}>
      <ArkButton type="button" color='yellow' basic fluid size="large" onClick={onShowConfirmAlert}>RESEND INVITE</ArkButton>
      {renderDeleteModal()}
    </div>
  )
}

export default ProjectUserReInviteView
