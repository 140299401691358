import { BaseModel } from './base_model'
import { IProgramDefaults, IProgramScheme } from './program'

export interface IServerDetails {
  ip: string
  url: string
  serverTime: Date
  serverTimezoneOffset: number
}

export interface IServerLoginService {
  id: number
  name: string
}

export interface IStreamingDefaults { // extends IProgramDefaults {
  streamingServerId?: number // NB: optional until all api servers have this field added
}

export class ServerConfig extends BaseModel {
  apiVersion: string
  serverDetails: IServerDetails
  programDefaults: IProgramDefaults // the default values used for certain program fields (srt keylength etc.)
  programSchemes: Array<IProgramScheme> // a list of program protocols & their scheme url prefixes, e.g. srt = `srt://`
  programABRLevels: {[key: number]: Array<number>} // a map of target stream resolutions & the sub/lower resolutions they support e.g. `{ 720: [360, 520, 720], 1080: ... }`

  streamingDefaults: IStreamingDefaults

  supportedProtocols: Array<string>
  supportedAuthProtocols: Array<string>

  projectConfigVersion: number
  programConfigVersion: number

  loginServices: Array<IServerLoginService>

  // TODO: add additional config fields the api now supplies:
  //  default_auth_valid_minutes
  //  default_max_users_project
  //  version_info
  //  viewer_video_formats

  constructor (
    apiVersion: string,
    serverDetails: IServerDetails,
    programDefaults: IProgramDefaults,
    programSchemes: Array<IProgramScheme>,
    programABRLevels: {[key: number]: Array<number>},
    streamingDefaults: IStreamingDefaults,
    supportedProtocols: Array<string>,
    supportedAuthProtocols: Array<string>,
    projectConfigVersion: number,
    programConfigVersion: number,
    loginServices: Array<IServerLoginService>
  ) {
    super()
    this.apiVersion = apiVersion
    this.serverDetails = serverDetails
    this.programDefaults = programDefaults
    this.programSchemes = programSchemes
    this.programABRLevels = programABRLevels
    this.streamingDefaults = streamingDefaults
    this.supportedProtocols = supportedProtocols
    this.supportedAuthProtocols = supportedAuthProtocols
    this.projectConfigVersion = projectConfigVersion
    this.programConfigVersion = programConfigVersion
    this.loginServices = loginServices
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : ServerConfig | null {
    if (!json) return null
    const serverDetails: IServerDetails = {
      ip: json.server_ip,
      url: json.server_url,
      serverTime: new Date(json.server_time),
      serverTimezoneOffset: json.server_timezone_offset
    }

    // NB: the api supplies the program & streaming defaults in a single `streaming_defaults` object, we split them out here
    const programDefaults: IProgramDefaults = {}
    programDefaults.srtKeyLength = json.streaming_defaults?.srt_key_length
    programDefaults.srtLatency = json.streaming_defaults?.srt_latency
    programDefaults.srtMaxBandwidth = json.streaming_defaults?.srt_max_bandwidth
    const streamingDefaults: IStreamingDefaults = {}
    streamingDefaults.streamingServerId = json.streaming_defaults?.streaming_server_id

    const programSchemes = this.parseSchemes(json.schemes)
    const programABRLevels = this.parseABRLevels(json.abr_levels)
    const supportedAppProtocols = this.parseSupportedAppProtocols(json.supported_app_protocols)
    const supportedAuthProtocols = this.parseSupportedAuthProtocols(json.supported_auth_protocols)
    const loginServices = this.parseLoginServices(json.login_services)
    console.log('ServerConfig - fromJSON - loginServices: ', loginServices)
    return new ServerConfig(
      json.app_version,
      serverDetails,
      programDefaults,
      programSchemes,
      programABRLevels,
      streamingDefaults,
      supportedAppProtocols,
      supportedAuthProtocols,
      json.project_config_version,
      json.program_config_version,
      loginServices
    )
  }

  // helper to parse the flat object/map of protocol schemes to an array of IProgramScheme entries
  // NB: some protocols have 2 entries, one with a '_secure' suffix & a 2nd without
  // NB: other protocols only have a single entry without a '_secure' suffix but are actually secure (only) e.g: srt, sldp/wss
  // NB: so special handling is added to check for this & flag the single entries as secure to catch that scenario while keeping this dynamic (no hard-coded checks for specific protocols etc.)
  // json data e.g: `schemes: { srt_scheme: 'srt://', wss_scheme: 'wss://', rtmp_scheme: 'rtmp://', rtmp_secure_scheme: 'rtmps://' ...}
  // NB: the api may extend/rework the schemes data structure in the future to break it down so we don't need to parse it from the key naming, but this works enough for our current needs & isn't a major priority so we agreed to keep it as-is for now
  static parseSchemes (schemesData: object) {
    if (!schemesData || typeof schemesData !== 'object') return []
    const schemeKeys = Object.keys(schemesData)
    const programSchemes: Array<IProgramScheme> = schemeKeys.map((schemeKey: string) => {
      // remove the '_scheme' suffix from the end of the key
      let schemeKeyNoSuffix = schemeKey.substring(0, schemeKey.lastIndexOf('_scheme'))
      // check if the scheme has a '_secure' suffix & remove it if so
      let schemeIsSecure = schemeKeyNoSuffix.lastIndexOf('_secure') >= 0
      if (schemeIsSecure) schemeKeyNoSuffix = schemeKeyNoSuffix.substring(0, schemeKeyNoSuffix.lastIndexOf('_secure'))
      // additional check for protocols that are only secure, they don't have a non-secure entry & also don't have a '_secure' suffix, so we assume its secure if theres only one entry of it in the schemes array/object
      if (!schemeIsSecure) {
        if (!schemeKeys.includes(schemeKeyNoSuffix + '_secure_scheme')) {
          schemeIsSecure = true
        }
      }
      const programScheme: IProgramScheme = {
        protocol: schemeKeyNoSuffix,
        scheme: (schemesData as any)[schemeKey],
        isSecure: schemeIsSecure
      }
      return programScheme
    })
    return programSchemes
  }

  // helper to parse the multi-dimension abr levels data field
  // NB: the json parsing requires string keys, but as these are actually just numeric resolution values e.g. 1080, 720 etc. we convert & use them as numbers once parsed
  static parseABRLevels (abrLevelsData: object) {
    const abrLevels: {[key: number]: Array<number>} = {}
    if (!abrLevelsData || typeof abrLevelsData !== 'object') return abrLevels
    const abrLevelKeys = Object.keys(abrLevelsData)
    abrLevelKeys.forEach(abrLevelKey => {
      const abrLevelValues = (abrLevelsData as any)[abrLevelKey]
      abrLevels[parseInt(abrLevelKey)] = abrLevelValues
    })
    return abrLevels
  }

  // helper to parse the supprted app protocols field
  // NB: the api doesn't currently include 'SRT' in this response as its always enabled (& doesn't have a direct wmspanel flag/entry), so we add it here for now
  // NB: sorting the returned protocol array before returning it
  static parseSupportedAppProtocols (supportedAppProtocolsData: Array<string>) {
    const supportedAppProtocols: Array<string> = []
    if (supportedAppProtocolsData && typeof supportedAppProtocolsData === 'object' && Array.isArray(supportedAppProtocolsData) && supportedAppProtocolsData.length > 0) {
      supportedAppProtocolsData.forEach(protocol => supportedAppProtocols.push(protocol))
    }
    // FIXME: see if the api can include SRT?
    if (!supportedAppProtocols.includes('SRT')) supportedAppProtocols.push('SRT')
    // return supportedAppProtocols.sort((a: string, b: string) => a.localeCompare(b, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
    return supportedAppProtocols
  }

  // helper to parse the supprted auth protocols field
  static parseSupportedAuthProtocols (supportedAuthProtocolsData: Array<string>) {
    const supportedAuthProtocols: Array<string> = []
    if (supportedAuthProtocolsData && typeof supportedAuthProtocolsData === 'object' && Array.isArray(supportedAuthProtocolsData) && supportedAuthProtocolsData.length > 0) {
      supportedAuthProtocolsData.forEach(protocol => supportedAuthProtocols.push(protocol))
    }
    // FIXME: see if the api can always return SRT?
    if (!supportedAuthProtocols.includes('SRT')) supportedAuthProtocols.push('SRT')
    return supportedAuthProtocols.sort((a: string, b: string) => a.localeCompare(b, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
  }

  static parseLoginServices (loginServicesData: Array<any>) {
    const loginServices: Array<IServerLoginService> = []
    if (loginServicesData && typeof loginServicesData === 'object' && Array.isArray(loginServicesData) && loginServicesData.length > 0) {
      // TODO: check the required fields exist & are the correct type & parse them into/as an IServerLoginService instance if so
      loginServicesData.forEach(loginServiceData => loginServices.push(loginServiceData))
    }
    return loginServices.sort((a: IServerLoginService, b: IServerLoginService) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
  }
}
