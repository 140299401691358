import React, { useContext, useEffect, useRef, useState } from 'react'

import { Project, UserProject } from 'src/core/models'
// import { IProjectUpdateData } from 'src/core/models/project'
import { ProjectAdminContext, UserContext } from 'src/core/providers'

import ArkButton from 'src/core/components/ArkButton'
import ArkHint, { HintType, PopupPosition, PopupSize } from 'src/core/components/ArkHint'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkNumberInput from 'src/core/components/ArkNumberInput'
import ArkPanel from 'src/core/components/ArkPanel'

import { OBJECT_PROJECT_NAME, OBJECT_USER_NAME, PAGE_SETTINGS_NAME } from 'src/constants/strings'

import styles from './ProjectSettingsView.module.css'

// TODO: access checks currently only work for the currently selected company & project, so remove the props & grab them from the provider instead?
// TODO: (as it can't be used any other way unless access checks are expanded to work outside of current user selections)
// NB: same company/project checks (& limitations) as ProjectTranscoderSettingsView
export interface ProjectUserSettingsViewProps {
  companyId: number
  project: UserProject | Project
}

const ProjectUserSettingsView = (props: ProjectUserSettingsViewProps) => {
  const { companyId, project } = props

  const mounted = useRef(false)

  const userContext = useContext(UserContext)
  const projectAdminContext = useContext(ProjectAdminContext)

  const isSiteAdmin = userContext.actions.isSiteAdmin()
  const isCompanyAdminOrHigher = userContext.actions.isCompanyAdminOrHigher()

  const [saving, setSaving] = useState<boolean>(false)
  const [saveResult, setSaveResult] = useState<boolean>(false)
  const [saveError, setSaveError] = useState<Error | undefined>(undefined)
  const [saveWarnings, setSaveWarnings] = useState<Array<Error> | undefined>(undefined)

  // individual program settings specific to this form
  const [projectValues, setProjectValues] = useState({
    maxUsers: project.projectMaxUsers
  })
  const [projectValuesSaved, setProjectValuesSaved] = useState({
    maxUsers: project.projectMaxUsers
  })
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])

  // -------

  const projectSettingsChanges = () => {
    const _changes: Array<string> = []
    if (projectValues) {
      for (const fieldName of Object.keys(projectValues)) {
        const oldValue = projectValuesSaved !== undefined ? (projectValuesSaved as any)[fieldName] : undefined
        const newValue = (projectValues as any)[fieldName]
        if (oldValue !== newValue) {
          _changes.push(fieldName)
        }
      }
    }
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  const resetSaveResults = () => {
    setSaveResult(false)
    setSaveError(undefined)
    setSaveWarnings(undefined)
  }

  const numberElement = (valueKey: string, siteAdminOnly: boolean = false) => {
    const value = (projectValues as any)[valueKey]
    // console.log('ProjectUserSettingsView - numberElement - valueKey:', valueKey, ' value:', value, ' siteAdminOnly:', siteAdminOnly)
    if ((siteAdminOnly && !isSiteAdmin) || !isCompanyAdminOrHigher) return value ?? 'NO LIMIT' // just display the value if the user isn't a site admin
    return (
      <ArkNumberInput
        className={styles.numberInput}
        value={'' + (value !== undefined ? value : '')}
        minValue={0}
        maxValue={1000}
        allowEmptyValue={true}
        size='mini'
        maxLength={4}
        onChange={(newValue: string) => {
          // console.log('ProjectUserSettingsView - numberElement - onChange - newValue: ', newValue)
          const newValueInt = newValue.length > 0 ? parseInt(newValue) : 0
          setProjectValues({
            ...projectValues,
            [valueKey]: newValueInt
          })
          resetSaveResults()
        }}
      />
    )
  }

  const hintElement = (hintType: HintType, hintIconSize: number, hintPopupSize: PopupSize, hintTitle: string, hintMessage: string, hintPopupPosition: PopupPosition) => {
    return (
      <ArkHint type={hintType} iconSize={hintIconSize} popupSize={hintPopupSize} title={hintTitle} message={hintMessage} popupPosition={hintPopupPosition}></ArkHint>
    )
  }

  // -------

  const onSave = async () => {
    console.log('ProjectUserSettingsView - onSave')
    // const companyId = userContext.store.selectedCompany?.id
    if (!companyId) {
      // TODO: handle if no company is selected? (shouldn't be if this page is showing/loaded?)
      return
    }
    const changedValues: {[key: string]: any} = {}
    let changesCount = 0
    for (const fieldName of changes) {
      const newValue = (projectValues as any)[fieldName]
      changedValues[fieldName] = newValue
      changesCount++
    }
    console.log('ProjectUserSettingsView - onSave - changedValues: ', changedValues, ' changesCount: ', changesCount)
    // NB: currently only supporting `maxUsers` here, so hard-coded the check & the api update data to keep this code simpler for now
    if (changesCount > 0 && changedValues.maxUsers !== undefined) {
      resetSaveResults()
      setSaving(true)
      // await new Promise((resolve) => setTimeout(resolve, 1000)) // DEBUG ONLY
      try {
        // TODO: flip to use `updateCompanyProject` for this? although thats currently only accessible at the company/org admin level (e.g. when creating a new project), not project level (e.g. project settings) where this is currently used
        // NB: e.g: usage of `updateCompanyProject`...
        // const projectData: IProjectUpdateData = {}
        // const savedProject = await projectAdminContext.actions.updateCompanyProject(companyId, project.id, projectData)
        // console.log('ProjectUserSettingsView - onSave - savedProject: ', savedProject)
        // TESTING: update the value via the `updateProjectInfo` api call instead of the main `updateCompanyProject` one (as that provider isn't available in project settings where this is currently used)
        const reloadProjectData = true // trigger the user project data to be reloaded so it contains the new value
        const updateResult = await projectAdminContext.actions.updateProjectInfo(companyId, project.id, { projectMaxUsers: projectValues.maxUsers }, reloadProjectData)
        console.log('ProjectUserSettingsView - onSave - updateResult: ', updateResult)
        if (updateResult) {
          if (mounted.current) {
            const newProjectValuesSaved = { maxUsers: projectValues.maxUsers }
            setProjectValuesSaved(newProjectValuesSaved)
            setProjectValues(newProjectValuesSaved) // reset the form back to the saved values
            setChanges([])
            setSaveResult(true)
          }
        } else {
          throw new Error('Invalid response')
        }
      } catch (error) {
        if (mounted.current) {
          setSaveResult(false)
          setSaveError(error)
          setProjectValues(projectValuesSaved) // reset the form back to the saved values
          setChanges([])
        }
      }
      setSaving(false)
    } else {
      console.log('ProjectUserSettingsView - onSave - WARNING: no valid changes to save')
    }
  }

  const onReset = async () => {
    setProjectValues(projectValuesSaved) // reset the form data back to the saved values
    setChanges([])
    resetSaveResults()
  }

  // -------

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = projectSettingsChanges()
    setChanges(_changes)
  }, [projectValues])

  // TESTING: listen out for changes to the project data & update the form values if the project max users value changes
  // TODO: is this ok to handle like this, should the form be reset if the base value has changed since it was edited, or should the user be warned its changed or similar?
  useEffect(() => {
    console.log('ProjectUserSettingsView - useEffect(project) - project:', project)
    if (project.projectMaxUsers !== projectValues.maxUsers) {
      console.log('ProjectUserSettingsView - useEffect(project) - maxUsers changed - from:', projectValues.maxUsers, ' to:', project.projectMaxUsers)
      setProjectValues({
        ...projectValues,
        maxUsers: project.projectMaxUsers
      })
    }
  }, [project])

  // -------

  useEffect(() => {
    console.log('ProjectUserSettingsView - MOUNTED')
    mounted.current = true
    return () => {
      mounted.current = false
      console.log('ProjectUserSettingsView - UN-MOUNTED')
    }
  }, [])

  // -------

  // if (loading) return <ArkLoaderView message='Loading' />
  return (
    <div className={styles.userSettings}>
      <div className={styles.content}>
        <ArkPanel bordered>
          <ArkPanel.Header title={OBJECT_PROJECT_NAME + ' ' + OBJECT_USER_NAME + ' ' + PAGE_SETTINGS_NAME} bottomBorder className={styles.header}></ArkPanel.Header>
          <>
            <ArkPanel.Properties>
              {(saveResult || saveError || saveWarnings) && (
                <>
                  {saveResult && (
                    <ArkPanel.PropertyRow>
                      <ArkPanel.PropertyRowMessage positive>
                        <ArkMessage.Header>Saved</ArkMessage.Header>
                        <p>Your changes have been saved</p>
                      </ArkPanel.PropertyRowMessage>
                    </ArkPanel.PropertyRow>
                  )}
                  {saveError && (
                    <ArkPanel.PropertyRow>
                      <ArkPanel.PropertyRowMessage negative>
                        <ArkMessage.Header>Error</ArkMessage.Header>
                        <p>{saveError.message}</p>
                      </ArkPanel.PropertyRowMessage>
                    </ArkPanel.PropertyRow>
                  )}
                  {saveWarnings && saveWarnings.length > 0 && (
                    <ArkPanel.PropertyRow>
                      <ArkPanel.PropertyRowMessage warning>
                        <ArkMessage.Header>Warning</ArkMessage.Header>
                        {saveWarnings.length === 1 && (
                          <p>{saveWarnings[0].message}</p>
                        )}
                        {saveWarnings.length > 1 && (
                          <ArkMessage.List>
                            {saveWarnings.map((saveWarning: Error, index: number) => (
                              <ArkMessage.Item key={'warning_' + index}>{saveWarning.message}</ArkMessage.Item>
                            ))}
                          </ArkMessage.List>
                        )}
                      </ArkPanel.PropertyRowMessage>
                    </ArkPanel.PropertyRow>
                  )}
                </>
              )}
              <ArkPanel.PropertyRow
                title='Max Users:'
                value={numberElement('maxUsers', true)}
                hint={hintElement(
                  'info-circle',
                  22,
                  'small',
                  'Maximum total users for this project',
                  '', // TODO: <<<<
                  'bottom left'
                )}
                hasChanges={hasChanges('maxUsers')}
                titleSize={'xlarge'}
              />
            </ArkPanel.Properties>
            {isCompanyAdminOrHigher && (
              <ArkPanel.Footer className={styles.userFooter} topBorder>
                <div className={styles.footerWrapper}>
                  {changes.length === 0 && (<div></div>)}
                  <div className={styles.buttons}>
                    <ArkButton type='button' size='medium' color='red' disabled={changes.length === 0 || saving} onClick={onReset}>CLEAR CHANGES</ArkButton>
                    <ArkButton type='button' size='medium' color='green' disabled={changes.length === 0} loading={saving} onClick={onSave}>SAVE CHANGES</ArkButton>
                  </div>
                </div>
              </ArkPanel.Footer>
            )}
          </>
        </ArkPanel>
      </div>
    </div>
  )
}

export { ProjectUserSettingsView }
