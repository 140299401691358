import StreamhubAPIClient from './StreamhubAPIClient'

export type StreamhubAsset = { [key: string] : any }
export type StreamhubAssets = { video: Array<StreamhubAsset>, audio: Array<StreamhubAsset> }
export type StreamhubAssetFileType = 'video' | 'audio'

export default class StreamhubAssetsAPI {
  apiClient: StreamhubAPIClient

  constructor (apiClient: StreamhubAPIClient) {
    this.apiClient = apiClient
  }

  fetchAssets = async (): Promise<StreamhubAssets> => { // getSourceMediaFiles = async () => {
    try {
      const response = await this.apiClient.apiGet('/source/files?loadMeta=1&loadInfo=1', {})
      if (response.data && response.data.files) {
        const videoFilenames: Array<{ [key: string] : any }> = response.data.files.video ?? []
        const audioFilenames: Array<{ [key: string] : any }> = response.data.files.audio ?? []
        return { video: videoFilenames, audio: audioFilenames }
      }
    } catch (error) {
      console.error('StreamhubAssetsAPI - fetchAssets - error: ', error)
      throw error
    }
    throw Error('Failed to load assets') // fallback error
  }

  fetchAssetFileInfo = async (fileType: StreamhubAssetFileType, filename: string) => {
    try {
      // NB: WAS PUT now GET (used PUT instead of GET on this endpoint, just so we can pass the args in the body instead of url)
      const response = await this.apiClient.apiGet('/source/files/info' + '?filetype=' + fileType + '&filename=' + filename) //, { filetype: fileType, filename: filename }
      if (response.data && response.data.file) {
        return response.data.file
      }
    } catch (error) {
      console.error('StreamhubAssetsAPI - fetchAssetFileInfo - error: ', error)
      throw error
    }
    throw Error('Failed to load asset file info') // fallback error
  }

  uploadAssetFile = async (fileType: StreamhubAssetFileType, fileFormData: FormData) => {
    try {
      const response = await this.apiClient.apiPost('/source/files/' + fileType, fileFormData)
      if (response.status === 200) return true // TODO: anything more to check or return?
    } catch (error) {
      console.error('StreamhubAssetsAPI - uploadAssetFile - error: ', error)
      throw error
    }
    throw Error('Failed to upload asset file') // fallback error
  }

  deleteAssetFile = async (filetype: StreamhubAssetFileType, filename: string) => {
    try {
      const response = await this.apiClient.apiDelete('/source/files/' + filetype + '/' + filename)
      if (response.status === 200) return true // TODO: anything more to check or return?
    } catch (error) {
      console.error('StreamhubAssetsAPI - deleteAssetFile - error: ', error)
      if (error.response && error.response.data && error.response.data.error) {
        throw Error(error.response.data.error)
      }
      throw error
    }
    throw Error('Failed to delete asset file') // fallback error
  }

  scanAssets = async () => {
    try {
      // await new Promise(resolve => setTimeout(resolve, 2000)) // DEBUG ONLY: add a delay to test the loading state
      // throw new Error('DEBUG ERROR')
      const response = await this.apiClient.apiPost('/source/files/scan', {})
      if (response.data && response.data.result) {
        // NB: the api does also response with full details for each scanned file, regardless if its added/updated/deleted or not, but currently we just use the 'counts' summary)
        return { counts: response.data.result.counts }
      }
    } catch (error) {
      console.error('StreamhubAssetsAPI - scanAssets - error: ', error)
      throw error
    }
    throw Error('Failed to scan assets') // fallback error
  }
}
