import React, { Component } from 'react'

import ArkButton from '../ArkButton'
import ArkLoader from '../ArkLoader'

import styles from './ArkManagerTopbar.module.css'

interface IProps {
  // NB: you can either set the topbars left & right content directly (full control)
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
  // NB: OR you can toggle specific features on/off via props & let this component handle layout & trigger callbacks etc.
  addButtonTitle?: string
  onAdd?: Function
  otherButtons?: Array<{ title: string, onClick: Function }>
  filterForm?: React.ReactNode
  // shared props
  updating?: boolean
}
interface IState {
}
class ArkManagerTopbar extends Component<IProps, IState> {
  render () {
    if (this.props.leftContent || this.props.rightContent) {
      return this.renderLeftAndRightContent()
    }
    return this.renderDynamicTopbar()
  }

  renderLeftAndRightContent = () => {
    const { leftContent, rightContent } = this.props
    return (
      <div className={styles.topbar}>
        <span className={styles.topbarLeft}>
          {/* <div className={styles.topbarSelect}><ArkDropdown clearable options={orderBy} selection /></div> */}
          {/* <span className={styles.topbarIcon}></span> */}
          {/* <span className={styles.topbarIcon}></span> */}
          {leftContent}
          {this.renderUpdating()}
        </span>
        <span className={styles.topbarRight}>
          {/* <span className={styles.topbarButton}><ArkButton onClick={() => this.showAddProgramModal()}>CREATE PROGRAM</ArkButton></span> */}
          {/* <span className={styles.topbarIcon}></span> */}
          {rightContent}
        </span>
      </div>
    )
  }

  renderDynamicTopbar = () => {
    const { addButtonTitle, onAdd, otherButtons } = this.props
    const showAddButton = addButtonTitle !== undefined
    return (
      <div className={styles.topbar}>
        <span className={styles.topbarLeft}>
          {this.renderFilterForm()}
          {this.renderUpdating()}
        </span>
        <span className={styles.topbarRight}>
          {otherButtons && otherButtons.map((otherButton, index) =>
            (<ArkButton key={'otherBtn_' + index} onClick={() => { otherButton.onClick() }}>{otherButton.title}</ArkButton>))}
          {showAddButton && (<ArkButton onClick={() => { if (onAdd) onAdd() }}>{addButtonTitle}</ArkButton>)}
        </span>
      </div>
    )
  }

  renderUpdating = () => {
    if (!this.props.updating) return null
    return <ArkLoader horizontal message='Updating...' small />
  }

  renderFilterForm = () => {
    return this.props.filterForm
  }
}
export default ArkManagerTopbar
