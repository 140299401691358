import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import useResizeObserver from 'use-resize-observer'

import ArkPagination from 'src/core/components/ArkPagination'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { useViewer } from 'src/viewer/providers/ViewerProvider'

import ProgramView from '../ProgramView/ProgramView'

import styles from './ProgramsBottomBarView.module.css'

// FIXME rename "active" to "selected"

const MAX_ITEMS = 9

const ProgramsBottomBarView = () => {
  const viewer = useViewer()

  if (!_.some(viewer.programs)) return null

  const mainContainer = useRef<HTMLDivElement>(null)
  const topContainer = useRef<HTMLDivElement>(null)
  const topPositioner = useRef<HTMLDivElement>(null)
  const bottomContainer = useRef<HTMLDivElement>(null)
  const bottomPositioners = useRef<(HTMLDivElement|null)[]>([])

  const [topContainerRect, setTopContainerRect] = useState<DOMRect>(new DOMRect())
  const [topPositionerRect, setTopPositionerRect] = useState<DOMRect>(new DOMRect())
  const [bottomContainerRect, setBottomContainerRect] = useState<DOMRect>(new DOMRect())

  const [activePage, setActivePage] = useState(1)

  const resize = () => {
    if (topContainer.current) setTopContainerRect(topContainer.current.getBoundingClientRect())
    if (topPositioner.current) setTopPositionerRect(topPositioner.current.getBoundingClientRect())
    if (bottomContainer.current) setBottomContainerRect(bottomContainer.current.getBoundingClientRect())
  }

  useResizeObserver<HTMLDivElement>({ onResize: _.debounce(resize), ref: topContainer })

  useEffect(resize, [])

  /**
   * actions
   */

  const onProgramClick = (id: number) => {
    // console.log('ProgramsBottomBarView - onProgramClick - id:', id)
    viewer.setChannelSelectedProgram(id)
    if (viewer.getChannelAutoSolo()) {
      viewer.setChannelAutoSoloProgram(viewer.getChannelAutoSoloProgram() === id ? undefined : id)
    }
  }

  /**
   * top
   */

  // dimensions
  let topItemWidth = 0
  let topItemHeight = 0
  if (topContainerRect.width * (9 / 16) > topContainerRect.height) {
    // vertically constrained
    topItemWidth = topContainerRect.height * (16 / 9)
    topItemHeight = topContainerRect.height
  } else {
    // horizontally constrained
    topItemWidth = topContainerRect.width
    topItemHeight = topContainerRect.width * (9 / 16)
  }

  // const topPositionerComponent = (
  const topPositionerComponent = topContainerRect.width && (
    <div
      className={styles.topPositioner}
      ref={topPositioner}
      style={{ width: topItemWidth, height: topItemHeight }}
    />
  )

  const topItemStyle = {
    left: topPositionerRect.left - topContainerRect.left,
    top: topPositionerRect.top - topContainerRect.top,
    width: topPositionerRect.width,
    height: topPositionerRect.height
  }

  /**
   * bottom
   */

  const numberOfItemsPerPage = Math.min(_.size(viewer.programs), MAX_ITEMS)
  const numberOfPages = Math.ceil(_.size(viewer.programs) / numberOfItemsPerPage)
  const numberOfPrograms = _.size(viewer.programs)
  const isLastPage = activePage === numberOfPages

  const bottomItemWidth = Math.min(bottomContainerRect.width / numberOfItemsPerPage, 160)
  const bottomItemHeight = bottomItemWidth * (9 / 16)

  const numberOfItems = isLastPage ? numberOfPrograms - (numberOfItemsPerPage * (activePage - 1)) : numberOfItemsPerPage

  const bottomPositionerComponents = _.map(_.range(0, numberOfItems), index => (
    <div
      key={'prog_bbp_' + index}
      className={styles.bottomPositioner}
      ref={ref => { bottomPositioners.current[index] = ref }}
      style={{ width: bottomItemWidth, height: bottomItemHeight }}
    />
  ))

  /**
   * programs
   */

  const showBottom: boolean = _.some(viewer.programs)

  const activeRange: number[] = _.range(numberOfItemsPerPage * (activePage - 1), Math.min(numberOfPrograms, numberOfItemsPerPage * activePage))

  const programComponents: JSX.Element[] = _.map(viewer.programs, (program, index) => {
    const isSelected: boolean = program.id === viewer.getChannelSelectedProgram()
    const isHidden: boolean = !isSelected && !_.includes(activeRange, index)
    const isSmall: boolean = numberOfItemsPerPage > 4
    const pageIndex: number = _.indexOf(activeRange, index)
    const bottomPositionerRect: DOMRect = bottomPositioners.current[pageIndex]?.getBoundingClientRect() || new DOMRect()
    const bottomItemStyle: CSSProperties = {
      top: bottomPositionerRect.top - topContainerRect.top,
      left: bottomPositionerRect.left - bottomContainerRect.left,
      width: bottomPositionerRect.width,
      height: bottomPositionerRect.height
    }
    return (
      <React.Fragment key={'prog_bb_' + index}>
        <div className={`${styles.item} ${isHidden ? styles.itemHidden : ''}`} style={isSelected ? topItemStyle : bottomItemStyle}>
          <ProgramView
            controls={isSelected}
            key={program.id}
            onClick={() => onProgramClick(program.id)}
            program={program}
            thumb={!isSelected}
          />
        </div>
        {showBottom && isSelected && (
          <div
            className={`${
              styles.active
            } ${
              isSmall ? styles.activeSmall : ''
            } ${
              viewer.getChannelAutoSoloProgram() === program.id ? styles.activeAutoSolo : ''
            }`}
            onClick={() => onProgramClick(program.id)}
            style={bottomItemStyle}
          >
            <div className={styles.activeName}>{program.name}</div>
            <div className={styles.activeLabel}>VIEWING</div>
          </div>
        )}
      </React.Fragment>
    )
  })

  /**
   * pagination
   */

  const paginationComponent = numberOfPages > 1 && (
    <div className={styles.pagination}>
      <ArkPagination
        activePage={activePage}
        firstItem={null}
        lastItem={null}
        onPageChange={(_event, data) => setActivePage(typeof data.activePage === 'number' ? data.activePage : 1)}
        size='mini'
        totalPages={numberOfPages}
      />
    </div>
  )

  /**
   * render
   */

  return (
    <div className={styles.mainContainer} ref={mainContainer}>
      <div className={styles.topContainerOuter} ref={topContainer}>
        <div className={styles.topContainerInner}>
          {topPositionerComponent}
        </div>
      </div>
      {showBottom && (
        <>
          <ArkSpacer />
          <div className={styles.bottomContainerOuter} ref={bottomContainer}>
            <div className={styles.bottomContainerInner}>
              {bottomPositionerComponents}
            </div>
          </div>
        </>
      )}
      {programComponents}
      {paginationComponent}
    </div>
  )
}
export default ProgramsBottomBarView
