// list all supported ffmpeg-launcher server urls here (NB: object should match the StreamhubServer interface)
export const STREAMHUB_SERVERS = [
  // { key: 'dev-local', url: 'http://localhost:3003', title: 'DEV Local', socketUrl: 'http://localhost:3003', localIP: '127.0.0.1' },
  { key: 'live-office', url: 'https://streamhub1.reprostream.com/api', title: 'Live (Office)', socketUrl: 'https://streamhub1.reprostream.com', localIP: '192.168.6.1' }
  // { key: 'live-do', url: 'https://streamhub.reprostream.com/api', title: 'Live (OLD - Digital Ocean)', socketUrl: 'https://streamhub.reprostream.com' }
]

export const STREAMHUB_DATA_SOURCE_OVERRIDE = false // 'json' // TEMP/DEBUG-ONLY: force the streamhub api to use a certain `data-source` (while we transition from the old `json` source to the newer `db` one), set to `undefined` or `false` to leave it up to the server

export const STREAMHUB_TABLE_TYPE: number = 1 // 1 = new TanStack Table (React Table) rendering, 0 = old Semantic UI Table based rendering, 2 == both (for debug comparison)
