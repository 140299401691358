import React from 'react'

import { adminMenuItems } from '../../../constants/menus'
import { withUserContext, IUserMultiContext } from '../../../core/providers'

import ArkPage from '../../../core/components/ArkPage/ArkPage'
import ArkSectionSidebarMenu from '../../../core/components/ArkSectionSidebarMenu/ArkSectionSidebarMenu'

interface IProps extends IUserMultiContext {
  children?: React.ReactNode
  args?: any // PrivateRoute optional args (object with any number of key:value entries specified at the router declaration level)
  rightSidebar?: React.ReactNode
  hideSidebars?: boolean
  onRightSidebarClose?: () => void
}
interface IState {}

class ArkAdminPage extends React.Component<IProps, IState> {
  render () {
    const { children, onRightSidebarClose } = this.props
    return (
      <ArkPage
        leftSidebar={this.sidebarLeftContent()}
        onRightSidebarClose={onRightSidebarClose}
        rightSidebar={this.rightSidebar()}
        section='admin'
        showSelectCompany
      >
        {children}
      </ArkPage>
    )
  }

  sidebarLeftContent = () => {
    if (this.props.hideSidebars) return null
    return (<ArkSectionSidebarMenu mainMenuItems={adminMenuItems()} />)
  }

  rightSidebar = () => {
    if (this.props.hideSidebars) return null
    if (!this.props.rightSidebar) return null
    return (<>
      {this.props.rightSidebar}
    </>)
  }

  footerContent = () => {
    return (<></>)
  }
}
export default withUserContext(ArkAdminPage)
