/**
 * AppNotice
 */

import React, { useContext } from 'react'

import ArkCenterLayout from 'src/core/components/ArkCenterLayout'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { isIos } from 'src/core/utilities/platform'
import { GlobalConfigContext } from 'src/core/providers'

import { ReactComponent as AppStoreBadge } from 'src/assets/badges/app-store.svg'

import styles from './AppNotice.module.css'

export interface AppNoticeProps {
  children: React.ReactNode
}

const AppNotice = ({ children }: AppNoticeProps) => {
  if (!isIos()) return <>{children}</>
  const { store: configStore } = useContext(GlobalConfigContext)
  return (
    <ArkCenterLayout>
      <div className={styles.container}>
        <h1>App required</h1>
        <p>The RePro Stream app is required to view channels on iOS.</p>
        <ArkSpacer size={20} />
        <a className={styles.badge} href={configStore.config.appDeeplinkURL}>
          <AppStoreBadge />
        </a>
      </div>
    </ArkCenterLayout>
  )
}

export default AppNotice
