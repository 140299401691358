import React, { ReactNode, createContext, useState } from 'react'

import StreamhubAPIClient from '../services/StreamhubAPIClient'
import StreamhubVideoEngineAPI from '../services/StreamhubVideoEngineAPI'
import { IVideoEngineDataSlice, IVideoEngineLiveStream, IVideoEngineRealtimeStats, IVideoEngineRealtimeStream, IVideoEngineServer } from '../models/StreamhubVideoEngineModels'

interface IStreamhubVideoEngineActions {
  fetchVideoEngineServers: () => Promise<Array<IVideoEngineServer>>
  fetchVideoEngineServer: (serverId: string) => Promise<IVideoEngineServer>
  fetchVideoEngineDataSlices: () => Promise<Array<IVideoEngineDataSlice>>
  fetchVideoEngineRealtimeStats: (serverId: string, dataSliceId: string, streamPath?: string) => Promise<IVideoEngineRealtimeStats>
  fetchVideoEngineRealtimeStreams: (serverId: string, dataSliceId: string) => Promise<Array<IVideoEngineRealtimeStream>>
  fetchVideoEngineLiveStreams: (serverId: string, status?: string, protocol?: string) => Promise<Array<IVideoEngineLiveStream>>
}

interface IStreamhubVideoEngineStore {
}

export interface IStreamhubVideoEngineContext {
  actions: IStreamhubVideoEngineActions
  store: IStreamhubVideoEngineStore
}

interface StreamhubVideoEngineProviderProps {
  apiClient: StreamhubAPIClient
  videoEngineAPI?: StreamhubVideoEngineAPI
  children: ReactNode
}

export interface IStreamhubVideoEngineMultiContext {
  streamhubVideoEngineContext: IStreamhubVideoEngineContext
}

export const StreamhubVideoEngineContext = createContext<IStreamhubVideoEngineContext>({} as IStreamhubVideoEngineContext)

const StreamhubVideoEngineProvider = (props: StreamhubVideoEngineProviderProps) => {
  const { children } = props

  const [videoEngineAPI] = useState<StreamhubVideoEngineAPI>(props.videoEngineAPI ?? new StreamhubVideoEngineAPI(props.apiClient))

  const fetchVideoEngineServers = async (): Promise<Array<IVideoEngineServer>> => videoEngineAPI.fetchVideoEngineServers()

  const fetchVideoEngineServer = async (serverId: string): Promise<IVideoEngineServer> => videoEngineAPI.fetchVideoEngineServer(serverId)

  const fetchVideoEngineDataSlices = async (): Promise<Array<IVideoEngineDataSlice>> => videoEngineAPI.fetchVideoEngineDataSlices()

  const fetchVideoEngineRealtimeStats = async (serverId: string, dataSliceId: string, streamPath?: string): Promise<IVideoEngineRealtimeStats> => videoEngineAPI.fetchVideoEngineRealtimeStats(serverId, dataSliceId, streamPath)

  const fetchVideoEngineRealtimeStreams = async (serverId: string, dataSliceId: string): Promise<Array<IVideoEngineRealtimeStream>> => videoEngineAPI.fetchVideoEngineRealtimeStreams(serverId, dataSliceId)

  const fetchVideoEngineLiveStreams = async (serverId: string, status?: string, protocol?: string): Promise<Array<IVideoEngineLiveStream>> => videoEngineAPI.fetchVideoEngineLiveStreams(serverId, status, protocol)

  const actions: IStreamhubVideoEngineActions = {
    fetchVideoEngineServers,
    fetchVideoEngineServer,
    fetchVideoEngineDataSlices,
    fetchVideoEngineRealtimeStats,
    fetchVideoEngineRealtimeStreams,
    fetchVideoEngineLiveStreams
  }

  const store: IStreamhubVideoEngineStore = {
  }

  return (
    <StreamhubVideoEngineContext.Provider value={{ actions, store }}>
      {children}
    </StreamhubVideoEngineContext.Provider>
  )
}

export { StreamhubVideoEngineProvider }
