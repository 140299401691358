import React from 'react'

import ArkCompanyManagerPage from 'src/manager/company/components/ArkCompanyManagerPage/ArkCompanyManagerPage'
import Ark2FAAuthView from 'src/core/components/Ark2FAAuthView'

export interface Company2FAAuthPageProps {}

const Company2FAAuthPage = (_props: Company2FAAuthPageProps) => {
  return (
    <ArkCompanyManagerPage disableSectionMainMenu>
      <Ark2FAAuthView />
    </ArkCompanyManagerPage>
  )
}

export default Company2FAAuthPage
