/**
 * ArkFormNumberInputField
 */
import React from 'react'

import ArkNumberInput from '../ArkNumberInput'

import { Label, LabelProps, SemanticShorthandItem } from 'semantic-ui-react'

import styles from './ArkFormNumberInputField.module.css'

export interface ArkFormNumberInputFieldResult {
  value: number | undefined
}
export type ArkFormNumberInputFieldChangeCallback = (result: ArkFormNumberInputFieldResult) => void
// export type ArkFormNumberInputFieldResultCallback = (result: ArkFormNumberInputFieldResult) => void

interface IProps {
  label?: string | React.ReactNode
  required?: boolean
  defaultValue?: number
  allowEmptyValue?: boolean
  allowMouseWheel?: boolean
  buttonPlacement?: 'right' | 'leftAndRight' | 'none'
  id?: string
  className?: string
  disabled?: boolean
  doubleClickStepAmount?: number
  minValue?: number
  maxValue?: number
  maxLength?: number
  placeholder?: string
  precision?: number
  showError?: boolean
  showTooltips?: boolean
  size?: 'mini' | 'small' | 'large' | 'big' | 'huge' | 'massive'
  width?: number
  stepAmount?: number
  valueType?: 'integer' | 'decimal'
  onChange?: ArkFormNumberInputFieldChangeCallback
  // onChangeComplete?: ArkFormNumberInputFieldResultCallback
  error?: boolean | SemanticShorthandItem<LabelProps> // NB: mimicing the SUI Form field error prop
  darkMode?: boolean // ADDED: custom prop
}
interface IState {
  value: number | undefined
}

class ArkFormNumberInputField extends React.Component<IProps, IState> {
  defaultValue: number | undefined = undefined
  defaultMinValue: number = 0
  defaultMaxValue: number = 100
  defaultMaxLength: number = 4
  defaultSize: 'mini' | 'small' | 'large' | 'big' | 'huge' | 'massive' = 'mini'

  constructor (props: IProps) {
    super(props)
    this.state = {
      value: props.defaultValue ?? this.defaultValue
    }
  }

  render () {
    let style: React.CSSProperties | undefined
    if (this.props.width) {
      if (!style) style = {}
      style.width = this.props.width
    }
    return (
      <>
        <div className={
          'field' +
          ' ' + styles.field +
          (this.props.required ? ' required' : '') +
          (this.props.className ? ' ' + this.props.className : '')
        }>
          {this.props.label && (typeof this.props.label === 'string' ? <label>{this.props.label}</label> : <>{this.props.label}</>)}
          <div className={styles.fieldWrapper} style={style}>{/* NB: added a wrapper to allow us to assign styles here (mainly custom widths), as the underlying component doesn't support a `style` prop */}
            <ArkNumberInput
              className={styles.numberInput}
              value={'' + (this.state.value !== undefined ? this.state.value : '')}
              allowEmptyValue={(this.props.required ? false : (this.props.allowEmptyValue ?? true))}
              allowMouseWheel={this.props.allowMouseWheel}
              buttonPlacement={this.props.buttonPlacement}
              id={this.props.id}
              // className={this.props.className}
              disabled={this.props.disabled}
              doubleClickStepAmount={this.props.doubleClickStepAmount}
              minValue={this.props.minValue !== undefined ? this.props.minValue : this.defaultMinValue}
              maxValue={this.props.maxValue !== undefined ? this.props.maxValue : this.defaultMaxValue}
              maxLength={this.props.maxLength ?? this.defaultMaxLength}
              placeholder={this.props.placeholder}
              precision={this.props.precision}
              showError={this.props.showError}
              showTooltips={this.props.showTooltips}
              size={this.props.size ?? this.defaultSize}
              stepAmount={this.props.stepAmount}
              valueType={this.props.valueType}
              onChange={(newValue: string) => {
                const newValueInt = newValue.length > 0 ? parseInt(newValue) : undefined
                // console.log('ArkFormNumberInputField - onChange - newValue: ', newValue, ' newValueInt:', newValueInt)
                this.setState({ value: newValueInt })
                if (this.props.onChange) this.props.onChange({ value: newValueInt })
              }}
              darkMode={this.props.darkMode}
            />
          </div>
          {this.props.error && this.renderError()}
        </div>
      </>
    )
  }

  renderError = () => {
    const error = this.props.error
    if (error && typeof error !== 'boolean' && (error as any).content) {
      // mimic the SUI Form errors
      return (<Label color='red' prompt {...(error as any)} />)
    }
    return null
  }
}

export default ArkFormNumberInputField
