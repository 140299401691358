import React from 'react'

import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkCompanyManagerPage from 'src/manager/company/components/ArkCompanyManagerPage/ArkCompanyManagerPage'
import { OBJECT_COMPANY_NAME } from 'src/constants/strings'

const CompanyManagerLoadingPage = () => (
  <ArkCompanyManagerPage>
    <ArkLoaderView message={'Loading ' + OBJECT_COMPANY_NAME} />
  </ArkCompanyManagerPage>
)

export default CompanyManagerLoadingPage
