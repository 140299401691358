/**
 * ArkSegment
 */

import React from 'react'

import { Segment, SegmentProps } from 'semantic-ui-react'

import styles from './ArkSegment.module.css'

export interface ArkSegmentProps extends SegmentProps {}

const ArkSegment: React.FC<ArkSegmentProps> = (props: ArkSegmentProps) => {
  const { className, children } = props
  return (
    <Segment
      {...props}
      className={`${styles.segment} ${className || ''}`}
    >{children}</Segment>
  )
}

export default ArkSegment
