/**
 * ArkProjectDropdown
 */

import React from 'react'

import ArkDropdown, { ArkDropdownProps } from '../ArkDropdown'
import { Project, UserProject } from '../../models'
import { USER_SELECTION_DROPDOWNS_DESELECT_ENABLED } from '../../../constants/config'
import { OBJECT_PROJECT_NAME, OBJECT_PROJECT_NAME_PLURAL } from '../../../constants/strings'

import styles from './ArkProjectDropdown.module.css'

export type ArkProjectDropdownSelectCallback = (selectedId: number) => void

export interface ArkProjectDropdownProps extends ArkDropdownProps {
  projects: Array<UserProject | Project>
  selectedId?: number
  loading?: boolean
  onSelect?: ArkProjectDropdownSelectCallback
}

const ArkProjectDropdown = (props: ArkProjectDropdownProps) => {
  const {
    projects,
    selectedId: currentProjectId,
    loading,
    onSelect,
    ...otherProps
  } = props

  const projectOptions = []
  if (projects) {
    // const projectNameMaxChars = 25
    for (const project of projects) {
      const projectName = project.name
      // if (projectName.trim().length > projectNameMaxChars) projectName = projectName.substring(0, projectNameMaxChars).trim() + '..'
      if (project instanceof UserProject) {
        // user (viewer access) project
        projectOptions.push({
          key: 'project_' + project.id,
          text: projectName,
          value: project.id,
          description: (!project.userAccessEnabled ? 'disabled' : undefined)
        })
      } else {
        // admin only project
        projectOptions.push({
          key: 'project_' + project.id,
          text: projectName,
          value: project.id,
          description: 'admin only'
        })
      }
    }
  }

  // DEBUG ONLY: allow the current project to be totally de-selected
  if (USER_SELECTION_DROPDOWNS_DESELECT_ENABLED && currentProjectId) { projectOptions.push({ key: 'project_deselect', text: 'Deselect ' + OBJECT_PROJECT_NAME, value: 0 }) }

  const disabled = loading || projectOptions.length === 0

  const selectedText = projectOptions.find((option) => option.value === currentProjectId)?.text ?? null // 'Select Project'
  const selectedDesc = projectOptions.find((option) => option.value === currentProjectId)?.description ?? null
  const trigger = selectedText
    ? (
      <div className={styles.dropdownTrigger + ' item'}>
        <span className="text">{selectedText}</span>
        <span className="description">{selectedDesc}</span>
      </div>
    )
    : null

  const loadingTxt = 'Loading...'
  const selectTxt = 'Select ' + OBJECT_PROJECT_NAME
  const noResultsTxt = 'No ' + OBJECT_PROJECT_NAME_PLURAL + ' available' // TODO: use OBJECT_PROJECT_NAME_PLURAL? but need to capitalise it, or change the msg case..
  const placeholderTxt = loading ? loadingTxt : (projectOptions.length === 0 ? noResultsTxt : selectTxt)
  const triggerTxt = currentProjectId ? undefined : placeholderTxt // NB: the 'trigger' value is used when a project is selected (see notes in ArkDropdown for more info)

  return (
    <ArkDropdown
      fluid
      // selection
      placeholder={placeholderTxt}
      noResultsMessage={noResultsTxt}
      text={triggerTxt} // NB: the 'trigger' value is used when a project is selected (see notes in ArkDropdown for more info)
      trigger={trigger}
      options={projectOptions}
      value={currentProjectId}
      disabled={disabled}
      onChange={(_event: React.SyntheticEvent<HTMLElement, Event>, data: ArkDropdownProps) => {
        const projectId = typeof data.value === 'string' ? parseInt(data.value) : data.value as number
        if (onSelect) onSelect(projectId)
      }}
      {...otherProps}
      className={`${styles.projectDropdown} ${props.className || ''} selection`}
      data-test-id="ark-project-dropdown" // e2e testing identifier
    />
  )
}
export default ArkProjectDropdown
