import StreamhubAPIClient from './StreamhubAPIClient'
import { FFMpegProcess } from '../models/StreamhubModels'

export default class StreamhubProcessesAPI {
  apiClient: StreamhubAPIClient

  constructor (apiClient: StreamhubAPIClient) {
    this.apiClient = apiClient
  }

  async fetchProcesses (): Promise<Array<FFMpegProcess>> {
    try {
      const response = await this.apiClient.apiGet('/processes', {})
      const processes: Array<FFMpegProcess> = []
      if (response.data && response.data.processes) {
        const processesData = response.data.processes
        for (const processData of processesData) {
          // const ffmpegProcess = FFMpegProcess.fromJSON(processData)
          const ffmpegProcess = new FFMpegProcess(processData.pid, processData.command, processData.cpu, undefined, undefined)
          if (ffmpegProcess) {
            processes.push(ffmpegProcess)
          }
        }
      }
      return processes
    } catch (error) {
      console.error('StreamhubProcessesAPI - fetchProcesses - error: ', error)
      throw error
    }
  }

  killProcess = async (pid: number): Promise<boolean> => {
    console.log('StreamhubProcessesAPI - killProcess - pid:', pid)
    try {
      const response = await this.apiClient.apiPost('/processes/kill/' + pid, {})
      console.log('StreamhubProcessesAPI - killProcess - response: ', response)
      if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
        const result = response.data.result
        return (result === true)
      }
      return false
      // throw Error('TEST')
    } catch (error) {
      console.error('StreamhubProcessesAPI - killProcess - error: ', error)
      throw error
    }
  }

  killAllProcesses = async (): Promise<boolean> => {
    console.log('StreamhubProcessesAPI - killAllProcesses')
    try {
      const response = await this.apiClient.apiPost('/processes/killall', {})
      console.log('StreamhubProcessesAPI - killAllProcesses - response: ', response)
      if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
        const result = response.data.result
        return (result === true)
      }
      return false
      // throw Error('TEST')
    } catch (error) {
      console.error('StreamhubProcessesAPI - killAllProcesses - error: ', error)
      throw error
    }
  }
}
