import React, { useContext, useEffect, useRef, useState } from 'react'

import { NavContext, User2FAContext, UserContext } from '../../../providers'
import * as ROUTES from 'src/constants/routes'

import User2FAInputView, { User2FAInputViewMode } from './User2FAInputView'

import ArkButton from 'src/core/components/ArkButton'
import ArkConfirmModal, { ArkConfirmModalMode } from 'src/core/components/ArkConfirmModal'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import styles from './User2FAManageView.module.css'

interface IProps {
  onStartDisable?: Function
}

const User2FADisableView = (props: IProps) => {
  const mounted = useRef(false)

  const user2FAContext = useContext(User2FAContext)
  const userContext = useContext(UserContext)
  const navContext = useContext(NavContext)

  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState<boolean>() // NOTE: this component is unmounted on success before we can show a message, so we now handle it at the parent level instead
  const [error, setError] = useState<Error>()
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [showForm, setShowForm] = useState(false)
  const [auth2FADisableTFAToken, setAuth2FADisableTFAToken] = useState<string>()

  // -------

  const onShowForm = () => {
    setShowForm(true)
    setError(undefined)
  }

  const onHideForm = () => {
    setShowForm(false)
    setError(undefined)
  }

  // -------

  const generate2FADisableTFAToken = async () => {
    try {
      if (props.onStartDisable) props.onStartDisable() // notify the parent component the user started the disable process (so it can remove old success messages)
      setIsLoading(true)
      // NB: first call this endpoint to generate a new 2fa disable tfa token (needed when submitting the otp code in the second step)
      const result = await user2FAContext.actions.disable2FAGenerateTFAToken()
      if (result) {
        if (mounted.current) {
          setIsLoading(false)
          setAuth2FADisableTFAToken(result)
          setResult(false)
          setError(undefined)
          onShowForm() // show the input form
        }
      } else {
        throw new Error('Failed to initiate 2FA disable, please try again')
      }
    } catch (error) {
      console.error('User2FADisableView - generated2FADisableTFAToken - error: ', error)
      if (mounted.current) {
        setIsLoading(false)
        setAuth2FADisableTFAToken(undefined)
        setResult(false)
        setError(error)
      }
    }
  }

  const disable2fa = async (otpCode: string) => {
    if (!auth2FADisableTFAToken) {
      return // TODO: show an error?
    }
    try {
      setIsLoading(true)
      // NB: you must supply the generated 2fa disable tfa token from a previous call to the related endpoint
      const result = await user2FAContext.actions.disable2FA(otpCode, auth2FADisableTFAToken)
      if (result) {
        setIsLoading(false)
        setResult(true)
        setError(undefined)
      } else {
        setIsLoading(false)
        setResult(false)
        setError(new Error('Invalid response')) // TODO: <<<
      }
    } catch (error) {
      console.error('User2FADisableView - disable2fa - error: ', error)
      setIsLoading(false)
      setResult(false)
      setError(error)
    }
  }

  // -------

  const renderDisable2faWithOTP = () => {
    return (
      <>
        <User2FAInputView
          inputMode={User2FAInputViewMode.split}
          autoSubmit={true}
          isBusy={isLoading}
          onSubmit={(otpCode) => disable2fa(otpCode)}
          submitTitle='DISABLE 2FA'
          error={error}
        />
        <div className={styles.cancel}>
          <a href="#" onClick={(event) => {
            event.preventDefault()
            onHideForm()
          }}>Cancel</a>
        </div>
      </>
    )
  }

  // -------

  const renderConfirmModal = () => {
    return (
      <ArkConfirmModal
        show={showConfirmModal}
        mode={ArkConfirmModalMode.warning}
        title='Disable 2FA?'
        message={<>Are you sure you want to disable Two Factor Authentication (2FA)?</>}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setShowConfirmModal(false)
          generate2FADisableTFAToken() // start the 2FA disable process
        }}
        onClose={() => setShowConfirmModal(false)}
      />
    )
  }

  const _showConfirmModal = () => {
    setShowConfirmModal(true)
  }

  // -------

  useEffect(() => {
    console.log('User2FADisableView - MOUNTED')
    mounted.current = true
    return () => {
      mounted.current = false
      console.log('User2FADisableView - UN-MOUNTED')
    }
  }, [])

  // -------

  const user = userContext.store.user
  if (!user) return null
  return (
    <div className={styles.disable2FA}>

      {(showForm || result || error) && (
        <>
          <h1>Disable 2FA</h1>

          {/* {result && result === true && (
            <Message positive>
              <Message.Header>2FA Disabled</Message.Header>
              <Message.Item>2FA is now disabled on your account.</Message.Item>
            </Message>
          )} */}

          {/* NB: now using the error display within `User2FAInputView` instead of here */}
          {/* {error && (
            <Message negative>
              <Message.Header>Error</Message.Header>
              <p>{error.message}</p>
            </Message>
          )} */}
        </>
      )}

      {!showForm && (
        <>
          <ArkSpacer negative />
          <ArkPanel>
            <ArkPanel.Properties>
              <ArkPanel.PropertyRow title='2FA:' value={user.tfaEnabled ? 'enabled' : 'disabled'} />
            </ArkPanel.Properties>
          </ArkPanel>
          <ArkSpacer size={20} />
          <ArkButton type="button" negative basic fluid size="large" onClick={_showConfirmModal} loading={isLoading}>DISABLE 2FA</ArkButton>{/* generate2FADisableTFAToken */}
          <div className={styles.cancel}>
            <a href="#" onClick={(event) => {
              event.preventDefault()
              navContext.actions.goto(ROUTES.ACCOUNT_SETTINGS)
            }}>Back</a>
          </div>
        </>
      )}

      {showForm && (
        <>
          {renderDisable2faWithOTP()}
        </>
      )}

      {renderConfirmModal()}

    </div>
  )
}
export default User2FADisableView
