import React from 'react'

import StreamhubPage from '../../components/StreamhubPage/StreamhubPage'
import StreamhubStreamsView from './StreamhubStreamsView'

class StreamhubStreamsPage extends React.Component<{}, {}> {
  render () {
    return (
      <StreamhubPage>
        <StreamhubStreamsView />
      </StreamhubPage>
    )
  }
}
export default StreamhubStreamsPage
