import React, { Component } from 'react'
import * as yup from 'yup'

import { withAuthContext, IAuthMultiContext, AuthStatus } from '../../../providers'

import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from '../../../components/ArkForm/ArkForm'

const formSchema = yup.object().shape({
  password: yup.string().min(5).max(60).required()
})

export type LoginEmailPasswordCallback = (result: boolean) => void

interface IProps extends IAuthMultiContext {
  email: string
  onLoginSuccess?: LoginEmailPasswordCallback // TODO: rename & return a way to indicate if password auth was ok but 2fa is required, vs invalid password
}
interface IState {}

class LoginEmailPasswordForm extends Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      isSubmitting: false
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const loading = this.props.authContext.store.authStatus === AuthStatus.loading
    const err = this.props.authContext.store.authError

    // NB: now adding a disabled email field so browser password save/auto-fill works with this split 2-stage email > password login process
    // NB: tried to set it as hidden, but that stops the autofill from fully working, so showing & disabling it seems to be the best compramise for now (could maybe use css to hide it while keeping autofill working if we want?)
    const formFields: Array<ArkFormField> = []
    formFields.push({ type: ArkFormFieldType.Input, key: 'email', label: 'Email Address', defaultValue: this.props.email, required: true, fieldProps: { disabled: true } })
    formFields.push({ type: ArkFormFieldType.Input, key: 'password', label: 'Password', required: true, fieldProps: { type: 'password' } })
    formFields.push({ type: ArkFormFieldType.Button, key: 'submit', label: 'Login', fieldProps: { loading: loading, fluid: true } })

    return (
      <ArkForm
        formKey="emailPassword"
        className="email-password-form"
        inverted
        formError={err}
        formFields={formFields}
        formSchema={formSchema}
        onFormSubmit={this.onFormSubmit}
      ></ArkForm>
    )
  }

  onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    const { email } = this.props
    const { password } = fieldValues
    const loading = this.props.authContext.store.authStatus === AuthStatus.loading
    if (password && loading === false) {
      // on success the AuthProvider will trigger a redirect away, if an error happens its propagated down from the auth provider in the render function
      await this.props.authContext.actions.loginWithEmailAndPassword(email, password)
    }
  }
}

export default withAuthContext(LoginEmailPasswordForm)
