/**
 * ArkCompanyDropdown
 */

import React from 'react'

import ArkDropdown, { ArkDropdownProps } from '../../../core/components/ArkDropdown'
import { UserCompany, UserCompanyRole } from '../../../core/models'
import { USER_SELECTION_DROPDOWNS_DESELECT_ENABLED } from '../../../constants/config'
import { OBJECT_COMPANY_NAME, OBJECT_COMPANY_NAME_PLURAL } from '../../..//constants/strings'

import styles from './ArkCompanyDropdown.module.css'

export type ArkCompanyDropdownSelectCallback = (selectedId: number) => void

export interface ArkCompanyDropdownProps extends ArkDropdownProps {
  companies: Array<UserCompany>
  selectedId?: number
  onSelect?: ArkCompanyDropdownSelectCallback
}

const ArkCompanyDropdown = (props: ArkCompanyDropdownProps) => {
  const {
    companies: userCompanies,
    selectedId: currentCompanyId,
    onSelect,
    ...otherProps
  } = props

  const companyOptions = []
  if (userCompanies) {
    for (const company of userCompanies) {
      companyOptions.push(
        {
          key: 'comp_' + company.id,
          text: company.name,
          value: company.id,
          description: (company.userCompanyRole === UserCompanyRole.admin ? 'admin' : undefined)
          // image: { avatar: true, src: 'https://react.semantic-ui.com/images/avatar/small/matt.jpg' }, // TODO: <<
        }
      )
    }
  }

  // DEBUG ONLY: allow the current company to be totally de-selected
  if (USER_SELECTION_DROPDOWNS_DESELECT_ENABLED && currentCompanyId) { companyOptions.push({ key: 'comp_deselect', text: 'Deselect ' + OBJECT_COMPANY_NAME, value: 0 }) }

  const selectedText = companyOptions.find((option) => option.value === currentCompanyId)?.text ?? null // 'Select Project'
  const selectedDesc = companyOptions.find((option) => option.value === currentCompanyId)?.description ?? null
  const trigger = selectedText
    ? (
      <div className={styles.dropdownTrigger + ' item'}>
        <span className="text">{selectedText}</span>
        <span className="description">{selectedDesc}</span>
      </div>
    )
    : null

  return (
    <ArkDropdown
      fluid
      // selection
      placeholder={'Select ' + OBJECT_COMPANY_NAME}
      noResultsMessage={'No ' + OBJECT_COMPANY_NAME_PLURAL + ' available'}
      text={currentCompanyId ? undefined : 'Select ' + OBJECT_COMPANY_NAME} // NB: the 'trigger' value is used when a project is selected (see notes in ArkDropdown for more info)
      trigger={trigger}
      options={companyOptions}
      value={(currentCompanyId && currentCompanyId > 0) ? currentCompanyId : undefined}
      onChange={(event: React.SyntheticEvent<HTMLElement, Event>, data: ArkDropdownProps) => {
        const companyId = typeof data.value === 'string' ? parseInt(data.value) : data.value as number
        if (onSelect) onSelect(companyId)
      }}
      {...otherProps}
      className={`${styles.companyDropdown} ${props.className || ''} selection`}
      data-test-id="ark-company-dropdown" // e2e testing identifier
    />
  )
}
export default ArkCompanyDropdown
