import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

import { CompanyAdminContext } from 'src/core/providers'
import { UserCompanyRole } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkMessage from 'src/core/components/ArkMessage'

import { OBJECT_COMPANY_NAME, OBJECT_USER_NAME, ROLE_ADMIN_NAME } from 'src/constants/strings'

const formSchema = yup.object().shape({
  email: yup.string().email().required().label('Email'),
  firstName: yup.string().min(0).max(255).label('First name'),
  lastName: yup.string().min(0).max(255).label('Last name'),
  admin: yup.boolean().optional().label(OBJECT_COMPANY_NAME + ' ' + ROLE_ADMIN_NAME)
})

interface IProps {
  companyId: number
  onCancel?: Function
  onSave?: Function
  onDelete?: Function
  onClose?: Function
  insideModal?: boolean // ArkForm prop - enable when showing this form within a modal (so fieldset label bg's match)
}

const CompanyAddUserForm = (props: IProps) => {
  const mounted = useRef(false)

  const companyAdminContext = useContext(CompanyAdminContext)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSaved, setHasSaved] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const inviteUserToCompany = async (email: string, firstName: string, lastName: string, isCompanyAdmin: boolean) => {
    const { companyId } = props
    try {
      setIsSubmitting(true)
      setHasSaved(false)
      setError(undefined)
      await new Promise(resolve => setTimeout(resolve, 500)) // add a brief delay, so the user see's the loading indicator on quick api calls
      const companyRole = isCompanyAdmin ? UserCompanyRole.admin : UserCompanyRole.member
      const inviteResult = await companyAdminContext.actions.inviteUserToCompany(companyId, email, firstName, lastName, companyRole)
      console.log('CompanyAddUserForm - onSubmit - inviteUserToCompany - inviteResult: ', inviteResult)
      if (inviteResult) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
        }
        if (props.onSave) props.onSave()
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          setError(Error('A problem occurred inviting the user, please try again.'))
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('CompanyAddUserForm - onFormSubmit - fieldValues: ', fieldValues)
    const { email, firstName, lastName, admin } = fieldValues
    inviteUserToCompany(email, firstName, lastName, admin)
  }

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
  }

  const onClose = () => {
    if (props.onClose) props.onClose()
  }

  // -------

  const formFields: Array<ArkFormField> = []

  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'inviteUser',
    // label: 'Invite User',
    fields: [
      { type: ArkFormFieldType.Input, key: 'email', label: 'Email address', required: true },
      { type: ArkFormFieldType.Input, key: 'firstName', label: 'First name', required: false },
      { type: ArkFormFieldType.Input, key: 'lastName', label: 'Last name', required: false },
      { type: ArkFormFieldType.Checkbox, key: 'admin', label: 'Set ' + OBJECT_USER_NAME + ' as ' + OBJECT_COMPANY_NAME + ' ' + ROLE_ADMIN_NAME, required: false, defaultValue: false }
    ],
    // fieldProps: { widths: 2, inline: false, grouped: false },
    collapsible: false,
    collapsed: false
  })

  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { onClick: onCancel, floated: 'left' } },
      { type: ArkFormFieldType.OKButton, key: 'submit', label: 'INVITE', fieldProps: { loading: isSubmitting, floated: 'right' } }
    ],
    fieldProps: { widths: 'equal' }
  })

  return (
    <>
      <ArkHeader as="h2" inverted>
        Invite {OBJECT_USER_NAME} to {OBJECT_COMPANY_NAME}
      </ArkHeader>

      {hasSaved && (<>
        <ArkMessage positive>
          <ArkMessage.Header>{OBJECT_USER_NAME} invited</ArkMessage.Header>
          <ArkMessage.Item>The {OBJECT_USER_NAME} has been invited successfully</ArkMessage.Item>
        </ArkMessage>
        <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={onClose} style={{ marginTop: 15 }}>
          OK
        </ArkButton>
      </>)}

      {!hasSaved && (
        <ArkForm
          formKey="program"
          inverted
          formError={error}
          formFields={formFields}
          formSchema={formSchema}
          onFormSubmit={onFormSubmit}
          showLabels={true}
          insideModal={props.insideModal}
        >
        </ArkForm>)}
    </>
  )
}

export default CompanyAddUserForm
