/**
 * ArkManagerFilterForm
 */

import React from 'react'
import * as yup from 'yup'

import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormProps } from '../../../core/components/ArkForm/ArkForm'
import ArkButton from '../ArkButton'
import ArkIcon from '../ArkIcon'

import styles from './ArkManagerFilterForm.module.css'

export type ArkManagerFilterFormOnFilterChange = (filter: string) => void

interface ArkManagerFilterFormProps {
  autoComplete?: boolean
  className?: string
  filterTitle?: string
  filterValue: string // NB: requiring the calling code to track & pass in the current filter value/text so we can support clearing it (from anywhere)
  formSchema?: yup.AnySchema // ObjectSchema
  onFilterChange?: ArkManagerFilterFormOnFilterChange
}

const ArkManagerFilterForm = (props: ArkManagerFilterFormProps) => {
  const { autoComplete, className, filterTitle, filterValue, formSchema } = props

  const onFilterChange = (_event: React.FormEvent<HTMLFormElement>, data: ArkFormProps) => {
    if (props.onFilterChange) props.onFilterChange(data.value)
  }
  const formFields: Array<ArkFormField> = []
  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      { type: ArkFormFieldType.Field, key: 'searchIcon', content: (<ArkIcon name='search' />) },
      {
        type: ArkFormFieldType.Input,
        key: 'search',
        value: filterValue ?? '',
        placeholder: filterTitle,
        fieldProps: {
          /* fluid: true, */
          width: 13,
          onChange: onFilterChange,
          autoComplete: autoComplete === false ? 'off' : undefined,
          icon: (
            <ArkButton
              className={styles.clearFilter}
              onClick={() => {
                console.log('onFilterClear')
                if (props.onFilterChange) props.onFilterChange('')
              }}
            >
              <ArkIcon name='close' size={28} color='var(--bd-lighter)' />
            </ArkButton>
          )
        }
      }
      // { type: ArkFormFieldType.Button, key: 'submit', label: 'FILTER', fieldProps: { as: ArkButton, size: undefined, loading: isSubmitting } }
    ],
    slimline: true,
    fieldProps: { /* widths: 'equal' */ inline: true }
  })

  // const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
  //   console.log('ArkManagerFilterForm - onFormSubmit - fieldValues: ', fieldValues)
  // }

  const renderForm = () => (
    <ArkForm
      className={styles.form + (className ? ' ' + className : '')}
      formKey="managerFilterForm"
      inverted
      // formError={error}
      formFields={formFields}
      formSchema={formSchema}
      // onFormSubmit={onFormSubmit}
      showLabels={true}
    />
  )

  return (
    renderForm()
  )
}

export default ArkManagerFilterForm
