import React, { useContext } from 'react'

import { ProjectAdminContext } from 'src/core/providers'

import { Program } from 'src/core/models'

import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_PROGRAM_NAME } from 'src/constants/strings'

export type ProjectProgramDeleteCallback = (program: Program) => void

interface IProps {
  companyId: number
  projectId: number
  program: Program
  onDidDelete?: ProjectProgramDeleteCallback
  className?: string
}

const ProgramDeleteView = (props: IProps) => {
  const { program, className, onDidDelete } = props
  if (!program) return (<></>)

  const { actions: projectAdminActions } = useContext(ProjectAdminContext) // store: projectAdminStore

  const onDelete = async (_itemId: number): Promise<boolean> => {
    // NB: DON'T catch the error/exception here, let it throw (or if you do, rethrow it so the calling code can catch & display it)
    const { companyId, projectId, program } = props
    const deleteResult = await projectAdminActions.deleteCompanyProjectProgram(companyId, projectId, program.id)
    console.log('ProgramDeleteView - onDelete - deleteCompanyProjectProgram - deleteResult: ', deleteResult)
    return deleteResult
  }

  return (
    <div className={(className ? ' ' + className : '')}>
      <ArkSpacer />
      <ArkManagerDeleteButton
        itemId={program.id}
        itemName={program.name}
        itemTypeName={OBJECT_PROGRAM_NAME}
        buttonTitle={'DELETE ' + OBJECT_PROGRAM_NAME}
        onDelete={onDelete}
        onDeleteComplete={() => { if (onDidDelete) onDidDelete(program) }}
      />
    </div>
  )
}

export default ProgramDeleteView
