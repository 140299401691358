/**
 * CompanyManagerHomeView
 */

import React, { useContext, useEffect, useRef, useState } from 'react'

import * as ROUTES from 'src/constants/routes'

import { CompanyAdminContext, NavContext } from 'src/core/providers'
import { Company, CompanyCounts, CompanyTranscoderSettings } from 'src/core/models'

import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkSectionCard, { ArkSectionCardGroup } from 'src/core/components/ArkSectionCard'

import { pluralize } from 'src/core/utilities/strings'

import styles from './CompanyManagerHomeView.module.css'

export interface CompanyManagerHomeViewProps {
  companyId: number
}

const CompanyManagerHomeView = (props: CompanyManagerHomeViewProps) => {
  const { companyId } = props

  const mounted = useRef(false)
  const navContext = useContext(NavContext)

  // const userContext = useContext(UserContext)
  const companyAdminContext = useContext(CompanyAdminContext)

  const navigateTo = (section: string) => {
    navContext?.actions.goto(section)
  }

  const openSupport = () => {
    window.open(ROUTES.SUPPORT)
  }

  const [loading, setLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<Error | undefined>(undefined)

  // const [companyCounts, setCompanyCounts] = useState<CompanyCounts | undefined>()
  // const [transcoderSettings, setTranscoderSettings] = useState<CompanyTranscoderSettings | undefined>(undefined)
  // const companyPlanType = 1
  const [companyPlanName, setCompanyPlanName] = useState<string | undefined>()
  const [companyProjectCount, setCompanyProjectCount] = useState<number | undefined>()
  const [companyUserCount, setCompanyUserCount] = useState<number | undefined>()
  const [companyProgramCount, setCompanyProgramCount] = useState<number | undefined>()
  const [companyProgramMaxCount, setCompanyProgramMaxCount] = useState<number | undefined>()

  // -------

  const resetLoadingResults = () => {
    setLoadingError(undefined)
  }

  const _updateCounts = (company: Company) => {
    const counts: CompanyCounts | undefined = company.counts
    const _transcoderSettings: CompanyTranscoderSettings | undefined = company.transcoderSettings
    console.log('CompanyManagerHomeView - _updateCounts - counts:', counts, '_transcoderSettings:', _transcoderSettings, 'mounted:', mounted.current)
    const totalUsersCount = counts ? (counts.usersActiveCount + counts.usersInvitedCount + counts.usersPendingCount) : undefined
    const maxPrograms = _transcoderSettings ? _transcoderSettings.maxProgramsGPU : undefined // + _transcoderSettings.maxProgramsCPU
    setCompanyPlanName(company.isDemo ? 'Demo Mode' : 'Beta Enterprise') // NB: hard-coded for MVP (just flips between demo & a default)
    setCompanyProjectCount(counts?.projectsCount)
    setCompanyUserCount(totalUsersCount)
    setCompanyProgramCount(counts?.programsCount)
    setCompanyProgramMaxCount(maxPrograms)
  }

  const loadCompanyInfo = async () => {
    if (loading) return
    resetLoadingResults()
    setLoading(true)
    try {
      // await new Promise((resolve) => setTimeout(resolve, 2000)) // DBG ONLY
      // setLoadingError(new Error('DEBUG ERROR'))
      const company = await companyAdminContext.actions.getCompanyInfo(companyId)
      // console.log('CompanyManagerHomeView - loadCompanyInfo - company: ', company, ' mounted.current: ', mounted.current)
      console.log('CompanyManagerHomeView - loadCompanyInfo - mounted.current: ', mounted.current)
      // await new Promise((resolve) => setTimeout(resolve, 2000)) // DBG ONLY
      if (!mounted.current) return // halt if this component is no longer mounted (user navigated away before the api call returned)
      if (company) _updateCounts(company)
    } catch (error) {
      if (mounted.current) setLoadingError(error)
    }
    if (mounted.current) setLoading(false)
  }

  // -------

  useEffect(() => {
    console.log('CompanyManagerHomeView - MOUNTED')
    mounted.current = true
    return () => {
      mounted.current = false
      console.log('CompanyManagerHomeView - UN-MOUNTED')
    }
  }, [])

  useEffect(() => {
    async function runAsync () {
      await loadCompanyInfo()
    }
    runAsync()
  }, [])

  // -------

  if (loading) return <ArkLoaderView message='Loading' />
  return (
    <div className={styles.container}>
      <div className={`${styles.content}`}>
        {!loading && loadingError && (
          <ArkMessage negative>
            <ArkMessage.Header>Error</ArkMessage.Header>
            <p>{loadingError.message}</p>
          </ArkMessage>
        )}
        {!loading && !loadingError && (
          <>
            <ArkSectionCardGroup>
              <ArkSectionCard
                iconName='dashboard'
                manageTitle='Contact support'
                name={companyPlanName || ''}
                onManageClick={openSupport}
                title='Current plan:'
              />
              <ArkSectionCard
                iconName='project'
                manageTitle='Manage projects'
                name={pluralize('Project', companyProjectCount)}
                number={companyProjectCount}
                onManageClick={() => navigateTo(ROUTES.COMPANY_MANAGER_PROJECTS)}
              />
              <ArkSectionCard
                iconName='user-company'
                manageTitle='Manage users'
                name={pluralize('User', companyUserCount)}
                number={companyUserCount}
                onManageClick={() => navigateTo(ROUTES.COMPANY_MANAGER_USERS)}
              />
              <ArkSectionCard
                iconName='program'
                manageTitle='Manage programs'
                name='Programs'
                number={companyProgramCount}
                numberMax={companyProgramMaxCount}
                onManageClick={() => navigateTo(ROUTES.COMPANY_MANAGER_SETTINGS)}
              />
            </ArkSectionCardGroup>
            <div className={styles.mainContainer}>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CompanyManagerHomeView
