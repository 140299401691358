import React, { Component } from 'react'
import * as yup from 'yup'

import { withProjectAdminContext, IProjectAdminMultiContext } from 'src/core/providers'

import { Channel } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkMessage from 'src/core/components/ArkMessage'

import { DEFAULT_PICKER_COLOR } from 'src/constants/config'
import { OBJECT_CHANNEL_NAME } from 'src/constants/strings'

const formSchema = yup.object().shape({
  name: yup.string().min(4).max(25).required().label(OBJECT_CHANNEL_NAME + ' name')
  // autoSolo: yup.boolean().optional().label('Auto Solo')
})

export enum ChannelFormMode {
  Add = 'add',
  Edit = 'edit',
}

interface IProps extends IProjectAdminMultiContext {
  mode: ChannelFormMode
  companyId: number
  projectId: number
  channel?: Channel
  onCancel?: Function
  onSave?: Function
  onClose?: Function
  insideModal?: boolean // ArkForm prop - enable when showing this form within a modal (so fieldset label bg's match)
}
interface IState {
  error?: Error
  isSubmitting: boolean
  hasSaved: boolean
}

class ChannelForm extends Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      isSubmitting: false,
      // error: new Error('ERROR: TEST'),
      hasSaved: false
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { error, isSubmitting, hasSaved } = this.state
    console.log('ChannelForm - render - this.props.channel: ', this.props.channel)

    const formFields: Array<ArkFormField> = []

    formFields.push({
      type: ArkFormFieldType.Fieldset,
      key: 'detailsFieldset',
      // label: 'Channel Details',
      fields: [
        {
          type: ArkFormFieldType.Group,
          key: 'detailsGroup',
          fields: [
            { type: ArkFormFieldType.Colour, key: 'colour', label: 'Colour', required: false, defaultValue: this.props.channel?.colour ?? undefined, fieldProps: { width: 4 } },
            { type: ArkFormFieldType.Input, key: 'name', label: OBJECT_CHANNEL_NAME + ' name', required: true, defaultValue: this.props.channel?.name ?? undefined, fieldProps: { width: 8 } }
          ]
          // fieldProps: { widths: 'equal' }
        }
        // { type: ArkFormFieldType.Checkbox, key: 'autoSolo', label: 'Enable Auto Solo', required: false, defaultValue: this.props.channel?.enableAutoSolo ?? undefined }
      ],
      // fieldProps: { widths: 2, inline: false, grouped: false },
      collapsible: false,
      collapsed: false
    })

    formFields.push({
      type: ArkFormFieldType.Group,
      key: 'buttons',
      fields: [
        { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { onClick: this.onCancel, floated: 'left' } },
        { type: ArkFormFieldType.OKButton, key: 'submit', label: (this.props.mode === ChannelFormMode.Edit ? 'SAVE' : 'ADD'), fieldProps: { loading: isSubmitting, floated: 'right' } }
      ],
      fieldProps: { widths: 'equal' }
    })

    return (
      <>
        <ArkHeader as="h2" inverted>
          {this.props.mode === ChannelFormMode.Edit ? 'Edit' : 'Add'} {OBJECT_CHANNEL_NAME}
        </ArkHeader>

        {hasSaved && (<>
          <ArkMessage positive>
            <ArkMessage.Header>{OBJECT_CHANNEL_NAME} {this.props.mode === ChannelFormMode.Edit ? 'Updated' : 'Created'}</ArkMessage.Header>
            <ArkMessage.Item>The {OBJECT_CHANNEL_NAME} has been {this.props.mode === ChannelFormMode.Edit ? 'updated' : 'created'} successfully</ArkMessage.Item>
          </ArkMessage>
          <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={this.onClose}>
            OK
          </ArkButton>
        </>)}

        {!hasSaved && (
          <ArkForm
            formKey="channel"
            inverted
            formError={error}
            formFields={formFields}
            formSchema={formSchema}
            onFormSubmit={this.onFormSubmit}
            showLabels={true}
            insideModal={this.props.insideModal}
          />)}
      </>
    )
  }

  onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('ChannelForm - onFormSubmit - fieldValues: ', fieldValues)
    if (this.props.mode === ChannelFormMode.Add) {
      this.addChannel(fieldValues.name, fieldValues.colour ?? DEFAULT_PICKER_COLOR) //, fieldValues.autoSolo)
    } else if (this.props.mode === ChannelFormMode.Edit) {
      this.updateChannel(fieldValues.name, fieldValues.colour ?? DEFAULT_PICKER_COLOR) //, fieldValues.autoSolo)
    }
  }

  onCancel = () => {
    console.log('ChannelForm - onCancel')
    if (this.props.onCancel) this.props.onCancel()
  }

  onClose = () => {
    console.log('ChannelForm - onClose')
    if (this.props.onClose) this.props.onClose()
  }

  // -------

  addChannel = async (name: string, colour?: string, enableAutoSolo?: boolean) => {
    if (this.state.isSubmitting) return
    const { companyId, projectId } = this.props
    this.setState({ isSubmitting: true, error: undefined })
    try {
      const savedChannel = await this.props.projectAdminContext.actions.addCompanyProjectChannel(companyId, projectId, name, colour, enableAutoSolo)
      console.log('ChannelForm - addChannel - savedChannel: ', savedChannel)
      if (savedChannel) {
        if (this._isMounted) this.setState({ isSubmitting: false, hasSaved: true })
        if (this.props.onSave) this.props.onSave()
      } else {
        if (this._isMounted) {
          this.setState({
            isSubmitting: false,
            error: Error('A problem occurred adding the ' + OBJECT_CHANNEL_NAME + ', please try again.')
          })
        }
      }
    } catch (error) {
      if (this._isMounted) this.setState({ isSubmitting: false, error })
    }
  }

  updateChannel = async (name: string, colour?: string, enableAutoSolo?: boolean) => {
    const { companyId, projectId, channel } = this.props
    if (this.state.isSubmitting || !channel) return
    this.setState({ isSubmitting: true, error: undefined })
    try {
      const updateChannel = new Channel(channel.id, name, projectId)
      updateChannel.colour = colour
      if (enableAutoSolo !== undefined) updateChannel.enableAutoSolo = enableAutoSolo
      const savedChannel = await this.props.projectAdminContext.actions.updateCompanyProjectChannel(companyId, projectId, updateChannel)
      console.log('ChannelForm - updateChannel - savedChannel: ', savedChannel)
      if (savedChannel) {
        if (this._isMounted) this.setState({ isSubmitting: false, hasSaved: true })
        if (this.props.onSave) this.props.onSave()
      } else {
        if (this._isMounted) {
          this.setState({
            isSubmitting: false,
            error: Error('A problem occurred updating the ' + OBJECT_CHANNEL_NAME + ', please try again.')
          })
        }
      }
    } catch (error) {
      if (this._isMounted) this.setState({ isSubmitting: false, error })
    }
  }
}

export default withProjectAdminContext(ChannelForm)
