export type Image = {
  id: number
  company_id: number
  createdAt: string
  updatedAt: string

  content_type: string
  custom_data: string | null
  ext: string
  file_name: string
  file_name_original: string
  is_original: boolean
  path: string

  url: string
}

export const DEFAULT_IMAGE: Image = {
  id: 0,
  company_id: 0,
  createdAt: '1970-01-01T00:00:00.000Z',
  updatedAt: '1970-01-01T00:00:00.000Z',

  content_type: '',
  custom_data: null,
  ext: '',
  file_name: '',
  file_name_original: '',
  is_original: true,
  path: '',

  url: ''
}
