/**
 * ArkFormTagsField
 * @description A form field for tags
 *
 * LIB 1 TRIAL: react-tags
 *  ref: https://github.com/react-tags/react-tags
 *
 * LIB 2 TRIAL: react-tag-autocomplete
 *  ref: https://github.com/i-like-robots/react-tag-autocomplete#readme
 *
 * LIB 3 TRIAL: react-multi-select-component
 *  ref: https://github.com/hc-oss/react-multi-select-component
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

// import { WithContext as ReactTags, Tag } from 'react-tag-input'
import { ReactTags, Tag } from 'react-tag-autocomplete'
import { MultiSelect } from 'react-multi-select-component'

import { Label, LabelProps, SemanticShorthandItem } from 'semantic-ui-react'

import styles from './ArkFormTagsField.module.css'

export type ArkFormTagsFieldChangeCallback = (tags: Array<string>) => void

interface IProps {
  id?: string
  name?: string
  label?: string | React.ReactNode
  required?: boolean
  defaultValue?: Array<string>
  value?: Array<string>
  suggestions?: Array<string>
  placeholder?: string
  className?: string
  onChange?: ArkFormTagsFieldChangeCallback
  onFocus?: () => void
  onBlur?: () => void
  error?: boolean | SemanticShorthandItem<LabelProps> // NB: mimicing the SUI Form field error prop
  elementType?: 1 | 2 | 3 // 1 = react-tags (not currently supported), 2 = react-tag-autocomplete (for tag entry & viewing), 3 = react-multi-select-component (currently for viewing/selection only)
  styleType?: 1 | 2 // 1 = looks more like input boxes, 2 = looks more like our dropdowns > NB: only currently applies to elementType: 3
}

const ArrowRenderer = ({ expanded }: { expanded: boolean }) => <i className={`${styles.arrow}${expanded ? ` ${styles.active}` : ''}`} aria-hidden="true"></i>

const ArkFormTagsField = (props: IProps) => {
  const mounted = useRef(false)
  const { defaultValue, value, suggestions, placeholder, elementType, styleType } = props

  // LIB 2 TRIAL: react-tag-autocomplete
  const [tags, setTags] = useState<Array<Tag>>(value ? (value.map((tag) => ({ value: tag, label: tag }))) : (defaultValue ? (defaultValue.map((tag) => ({ value: tag, label: tag }))) : []))

  const tagSuggestions: Array<Tag> = useMemo(() => [
    ...(suggestions ? suggestions.map((tag) => ({ value: tag, label: tag })) : [])
  ], [suggestions])

  const onAdd = useCallback(
    (newTag: Tag) => {
      setTags([...tags, newTag])
    },
    [tags]
  )

  const onDelete = useCallback(
    (tagIndex: number) => {
      setTags(tags.filter((_, i) => i !== tagIndex))
    },
    [tags]
  )

  const handleInputFocus = () => {
    // console.log('ArkFormTagsField - handleInputFocus')
    if (props.onFocus) props.onFocus()
  }
  const handleInputBlur = () => {
    // console.log('ArkFormTagsField - handleInputBlur')
    if (props.onBlur) props.onBlur()
  }

  const renderError = () => {
    const error = props.error
    if (error && typeof error !== 'boolean' && (error as any).content) {
      // mimic the SUI Form errors
      return (<Label color='red' prompt {...(error as any)} />)
    }
    return null
  }

  useEffect(() => {
    // console.log('ArkFormTagsField - useEffect[tags] - tags:', tags)
    // NB: don't fire this until the component is mounted (otherwise it'll fire on initial render with the default value)
    if (props.onChange && mounted.current) props.onChange(tags.map((tag) => (tag.value as string) ?? ''))
  }, [tags])

  useEffect(() => {
    // console.log('ArkFormTagsField - useEffect[defaultValue] - defaultValue:', defaultValue, ' tags(current):', tags)
  }, [defaultValue])

  useEffect(() => {
    // console.log('ArkFormTagsField - useEffect[value] - value:', value, ' mounted.current:', mounted.current)
    // TESTING: ignore when the component is still mounting (seems to wipe the tags otherwise)
    if (mounted.current === false) return
    // check for changes & update the internal tags array if there are (as this also fires when tags are changed within this component & trigger the calling code to update its value ref)
    if (value === undefined && tags.length > 0) {
      setTags([])
    } else if (value !== undefined) {
      let hasChanges = false
      for (const val of value) {
        if (!tags.find((tag) => tag.value === val)) {
          hasChanges = true
          break
        }
      }
      if (hasChanges === false && tags.length !== value.length) {
        hasChanges = true
      }
      // console.log('ArkFormTagsField - useEffect[value] - hasChanges:', hasChanges)
      if (hasChanges) {
        setTags(value.map((tag) => ({ value: tag, label: tag })))
      }
    }
  }, [value])

  // -------

  useEffect(() => {
    console.log('ArkFormTagsField - MOUNT')
    mounted.current = true
    return () => {
      console.log('ArkFormTagsField - UNMOUNT')
      mounted.current = false
    }
  }, [])

  // -------

  const renderTagsElement2 = () => {
    // console.log('ArkFormTagsField - renderTagsElement2 - tags:', tags, ' tagSuggestions:', tagSuggestions)
    return (
      <ReactTags
        id={props.id}
        allowNew={true}
        selected={tags} // current tags
        suggestions={tagSuggestions}
        labelText={''} // "Select tags"
        newOptionText="Add: %value%"
        noOptionsText="No matching tags"
        placeholderText={placeholder ?? 'Add new tag'}
        onAdd={onAdd}
        onDelete={onDelete}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        collapseOnSelect={false} // whether to collapse the suggestions dropdown after selecting a tag
        allowBackspace={false} // if the input field is empty, whether pressing backspace/delete should start deleting the last tag
        allowResize={false} // enable the text input to automatically resize to fit its value. Defaults to true
        isDisabled={false} // TODO: expose this prop <<<<
        classNames={{
          root: styles.root,
          rootIsActive: styles.isActive,
          rootIsDisabled: styles.isDisabled,
          rootIsInvalid: styles.isInvalid,
          label: styles.tagsLabel, // NB: we hide the default label & use our form label instead
          tagList: styles.list,
          tagListItem: styles.listItem,
          tag: styles.tag,
          tagName: styles.tagName, // TODO: is this used?
          comboBox: styles.combobox,
          input: styles.comboboxInput,
          listBox: styles.listbox,
          option: styles.listboxOption,
          optionIsActive: styles.listboxOptionAction,
          highlight: styles.listboxOptionHighlight
        }}
      />
    )
  }

  // TESTING: alternative lib for tag selection (rather than input/creation)
  // NB: mainly to better support limited space vs the other input lib we use
  //     (which works well for input, but not great for selection in small regions)
  const renderTagsElement3 = () => {
    return (
      <MultiSelect
        // id={props.id}
        className={`dark ${styles.rmsc} ${styles.dark} ${styleType === 2 ? ' ' + styles.dropdownStyle : ''} ${props.className ?? ''}`}
        options={tagSuggestions}
        value={tags}
        onChange={(newTags: any) => setTags(newTags)}
        labelledBy="tags"
        disableSearch={true} // NB: only showing the selected tags when in this mode
        hasSelectAll={false}
        isCreatable={false} // NB: not allowing new tags to be created in this mode
        // onCreateOption={(newTag: any) => console.log('onCreateOption:', newTag)}
        overrideStrings={{ // ref: https://react-multi-select-component.pages.dev/?path=/story/recipes-localization--page
          allItemsAreSelected: 'All tags',
          selectAll: 'Select All',
          selectAllFiltered: 'Select All (Filtered)',
          selectSomeItems: 'tags',
          search: 'Search',
          create: 'Create',
          clearSearch: 'Clear Search',
          clearSelected: 'Clear Selected',
          noOptions: 'No Tags'
        }}
        ClearSelectedIcon={null} // disable/hide the 'clear selected' button within the element
        ArrowRenderer={ArrowRenderer}
        disabled={false} // TODO: expose this prop <<<<
      />
    )
  }

  const renderTagsElement = () => {
    const _elementType = elementType ?? 2
    if (_elementType === 2) return renderTagsElement2()
    else if (_elementType === 3) return renderTagsElement3()
    return renderTagsElement2() // NB: default to react-tag-autocomplete (type: 2), type: 1 = react-tags not currently enabled/usable
  }

  return (
    <>
      <div className={
        'field' +
        ' ' + styles.field +
        (props.className ? ' ' + props.className : '') +
        (props.required ? ' required' : '')
      }>
        {props.label && (<label>{props.label}</label>)}
        {renderTagsElement()}
        {props.error && renderError()}
      </div>
    </>
  )

  // LIB 1 TRIAL: react-tags
  /*
  const [tags, setTags] = React.useState<Array<Tag>>(defaultValue ? defaultValue.map((tag) => ({ id: tag, text: tag })) : [])

  const tagSuggestions: Array<Tag> = useMemo(() => [
    ...(suggestions ? suggestions.map((tag) => ({ id: tag, text: tag })) : [])
  ], [])

  // key codes that trigger a tag to be added
  const KeyCodes = {
    comma: 188,
    enter: 13
  }
  const delimiters = useMemo(() => [KeyCodes.comma, KeyCodes.enter], [])

  const handleDelete = (i: number) => {
    setTags(tags.filter((tag, index) => index !== i))
  }

  const handleAddition = (tag: Tag) => {
    setTags([...tags, tag])
  }

  const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
    const newTags = tags.slice()

    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)

    // re-render
    setTags(newTags)
  }

  const handleTagClick = (index: number) => {
    console.log('The tag at index ' + index + ' was clicked')
  }

  const handleInputFocus = (_value: string) => {
    // console.log('ArkFormTagsField - handleInputFocus - value: ', value)
    if (props.onFocus) props.onFocus()
  }
  const handleInputBlur = (_value: string) => {
    // console.log('ArkFormTagsField - handleInputBlur - value: ', value)
    if (props.onBlur) props.onBlur()
  }

  const renderError = () => {
    const error = props.error
    if (error && typeof error !== 'boolean' && (error as any).content) {
      // mimic the SUI Form errors
      return (<Label color='red' prompt {...(error as any)} />)
    }
    return null
  }

  useEffect(() => {
    if (props.onChange) props.onChange(tags.map((tag) => tag.id))
  }, [tags])

  return (
    <>
      <div className={
        'field' +
        ' ' + styles.field +
        (props.required ? ' required' : '')
      }>
        {props.label && (<label>{props.label}</label>)}
        <ReactTags
          id={props.id}
          name={props.name}
          tags={tags}
          suggestions={tagSuggestions}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          handleInputFocus={handleInputFocus}
          handleInputBlur={handleInputBlur}
          inputFieldPosition="inline" // position of the input field relative to the tags - options: top, bottom, inline
          autocomplete
          autofocus={false} // don't focus the input field on mount (as its likely used alongside other fields that might take priority) - TODO: allow this to be configured?
          allowDeleteFromEmptyInput={false} // if the input field is empty, whether pressing delete should start deleting the last tag
          minQueryLength={1} // minimum number of chars to start showing suggestions (default: 2)
        />
        {props.error && renderError()}
      </div>
    </>
  )
  */
}

export default ArkFormTagsField
