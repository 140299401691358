import React from 'react'
import _ from 'lodash'

import {
  IProjectAdminMultiContext,
  IUserMultiContext,
  ProjectStatusProps,
  withProjectAdminContext,
  withProjectStatusContext,
  withUserContext
} from 'src/core/providers'

import { STATUS_ENABLED } from 'src/constants/config'
import * as ROUTES from 'src/constants/routes'

import { Channel, Program } from 'src/core/models'

import ArkProjectManagerPage from 'src/manager/project/components/ArkProjectManagerPage/ArkProjectManagerPage'
import ProjectChannelSidebar from './ProjectChannelSidebar'
import ChannelForm, { ChannelFormMode } from './ChannelForm'
import ProjectChannelListItem from './ProjectChannelListItem'

import ArkProjectStatusBanner from 'src/core/components/ArkProjectStatusBanner'
import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkManagerListView, { ArkManagerFilteredItem } from 'src/core/components/ArkManagerListView/ArkManagerListView'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkModal from 'src/core/components/ArkModal'

import { OBJECT_CHANNEL_NAME, OBJECT_CHANNEL_NAME_PLURAL, OBJECT_PROJECT_NAME, SECTION_MANAGER_SUFFIX_NAME } from 'src/constants/strings'

interface IProps extends IUserMultiContext, IProjectAdminMultiContext, ProjectStatusProps {
}
interface IState {
  loading: boolean
  channels: Array<Channel>
  filteredChannels?: Array<ArkManagerFilteredItem<Channel>>
  filter?: string
  selectedChannel?: Channel
  loadingPrograms: boolean
  channelPrograms?: Array<Program>
  editChannel?: Channel
  showChannelFormModal: boolean
}

class ProjectChannelsPage extends React.Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      loading: false,
      channels: [],
      filteredChannels: undefined,
      filter: undefined,
      loadingPrograms: false,
      showChannelFormModal: false
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.loadChannels()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { loading, channels, channelPrograms, loadingPrograms, selectedChannel, filter, filteredChannels } = this.state
    const company = this.props.userContext.store.selectedCompany
    const project = this.props.userContext.store.selectedProject
    if (!company || !project) return null

    const rightSidebar = selectedChannel && (
      <ProjectChannelSidebar
        companyId={company.id}
        projectId={project.id}
        channel={selectedChannel}
        onChange={() => {
          // trigger a programs data re-load to show changes to any channels (e.g. program were added/removed from a channel)
          this.loadChannels()
          if (selectedChannel) {
            this.selectChannel(selectedChannel) // re-select the channel so it reloads its programs incase they've changed
          }
        }}
        onEdit={this.onEdit}
        onDidDelete={this.onDidDelete}
        loadingPrograms={loadingPrograms}
        programs={channelPrograms || []}
      />
    )

    return (
      <ArkProjectManagerPage
        onRightSidebarClose={() => this.setState({ selectedChannel: undefined })}
        rightSidebar={rightSidebar}
      >
        <ArkManagerContentView
          title={OBJECT_CHANNEL_NAME_PLURAL}
          breadcrumb={[{
            path: ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_VIEW, project.id),
            title: ROUTES.formatBreadcrumbRootTitle(project.name, `${OBJECT_PROJECT_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`)
          }]}
        >
          <ArkProjectStatusBanner />
          <ArkManagerListView
            loading={loading}
            items={channels}
            selectedItem={selectedChannel}
            itemRow={(channel: Channel, isSelected: boolean) => {
              return this.renderChannelTableRowContent(channel, isSelected, filter)
            }}
            topbarAddItemTitle={'CREATE ' + OBJECT_CHANNEL_NAME}
            onAdd={() => this.showAddChannelModal()}
            // FILTERING:
            filter={filter}
            filteredItems={filteredChannels}
            filterForm={this.renderChannelFilterForm()}
            onClearFilter={() => this.filterChannels('') }
          />
          {this.renderAddEditModal()}
        </ArkManagerContentView>
      </ArkProjectManagerPage>
    )
  }

  renderChannelTableRowContent = (channel: Channel, isSelected: boolean, filter?: string) => {
    return (
      <ProjectChannelListItem
        active={isSelected}
        channel={channel}
        filter={filter}
        key={channel.id}
        onClick={() => this.selectChannel(channel)}
        // onEditClick={() => this.showEditChannelModal(channel)}
        onlineStatus={STATUS_ENABLED ? this.props.projectStatus.getChannelOnlineStatus(channel.id) : undefined}
      />
    )
  }

  // -------

  renderChannelFilterForm = () => {
    const { filter } = this.state
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        filterTitle='Filter by name'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          this.filterChannels(filter)
        }}
      />
    )
  }

  // -------

  filterChannels = (_filter: string) => {
    const { loading, channels, selectedChannel } = this.state
    if (loading) return
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredChannels = filter
      ? channels.reduce<Array<ArkManagerFilteredItem<Channel>>>((r, channel) => {
        let nameMatch = false
        if (channel.name.toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        if (nameMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          const filteredUser: ArkManagerFilteredItem<Channel> = {
            item: channel,
            matchingFields
          }
          r.push(filteredUser)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<Channel>>)
      : undefined
    if (selectedChannel && (!(filteredChannels?.find((filteredChannel) => filteredChannel.item.id === selectedChannel.id)))) {
      this.selectChannel(undefined) // if a user was selected but isn't in the filtered list deselect them
    }
    this.setState({ filter, filteredChannels })
  }

  clearFilteredChannels = () => {
    this.setState({ filter: undefined, filteredChannels: undefined })
  }

  // -------

  selectChannel = (channel?: Channel) => {
    this.setState({ selectedChannel: channel, channelPrograms: [] })
    if (channel) {
      this.loadChannelPrograms(channel.id)
    }
  }

  // -------

  loadChannels = async () => {
    if (this.state.loading === true) return false
    const company = this.props.userContext.store.selectedCompany
    const project = this.props.userContext.store.selectedProject
    if (company && project) {
      try {
        this.setState({ loading: true })
        const channels = await this.props.projectAdminContext.actions.getAllCompanyProjectChannels(company.id, project.id)
        if (this._isMounted) {
          this.setState({
            loading: false,
            channels: channels || []
          })
        }
        if (this._isMounted && this.state.filter) this.filterChannels(this.state.filter) // re-filter if it was active
      } catch (error) {
        console.error('ProjectChannelsPage - loadChannels - error: ', error)
        // TODO: add an error prop & display an error message if this happens
        if (this._isMounted) this.setState({ loading: false, channels: [], filteredChannels: undefined })
      }
    }
  }

  loadChannelPrograms = async (channelId: number) => {
    if (this.state.loadingPrograms === true) return false
    const company = this.props.userContext.store.selectedCompany
    const project = this.props.userContext.store.selectedProject
    if (company && project && channelId) {
      try {
        this.setState({ loadingPrograms: true, channelPrograms: [] })
        const channelPrograms = await this.props.projectAdminContext.actions.getAllCompanyProjectChannelPrograms(company.id, project.id, channelId)
        if (this._isMounted) {
          this.setState({
            loadingPrograms: false,
            channelPrograms: channelPrograms || []
          })
        }
      } catch (error) {
        console.error('ProjectChannelsPage - loadChannelPrograms - error: ', error)
        // TODO: add an error prop & display an error message if this happens
        if (this._isMounted) this.setState({ loadingPrograms: false, channelPrograms: [] })
      }
    }
  }

  // -------

  onEdit = (_channel: Channel) => {
    this.showEditChannelModal(_channel)
  }

  onDidDelete = (_channel: Channel) => {
    // trigger a programs data re-load so the deleted project no longer shows
    this.loadChannels()
    this.selectChannel(undefined)
  }

  // -------

  showAddChannelModal = () => {
    this.setState({ showChannelFormModal: true })
  }

  showEditChannelModal = (channel: Channel) => {
    this.setState({ showChannelFormModal: true, editChannel: channel })
  }

  hideChannelModal = () => {
    // NB: DON'T clear/reset `editChannel` here, see `didHideChanneModal` which is called once the modal has actually closed & we can safely reset it then
    // NB: this stops a saved edit form flipping the 'updated' success text to 'created' briefly while the modal closes if we reset it straight away here
    // NB: & also stops the form title flipping from edit to add as it closes via the cancel button
    this.setState({ showChannelFormModal: false }) // editChannel: undefined
  }

  // triggered via modal callbacks once the modal has already closed
  didHideChanneModal = () => {
    this.setState({ showChannelFormModal: false, editChannel: undefined })
  }

  // -------

  renderAddEditModal = () => {
    return (
      <ArkModal open={this.state.showChannelFormModal} onClose={() => this.didHideChanneModal()}>
        {this.renderChannelAddEditForm(this.state.editChannel)}
      </ArkModal>
    )
  }

  renderChannelAddEditForm = (channel?: Channel) => {
    const company = this.props.userContext.store.selectedCompany
    const project = this.props.userContext.store.selectedProject
    if (!company || !project) return null
    return (
      <ChannelForm
        mode={this.state.editChannel ? ChannelFormMode.Edit : ChannelFormMode.Add}
        companyId={company.id}
        projectId={project.id}
        channel={channel}
        onCancel={() => { this.hideChannelModal() }}
        onSave={async () => {
          // trigger a channels data re-load to show the newly added one
          await this.loadChannels()
          // re-select with the updated channel so any changes are shown/used
          if (this.state.selectedChannel && channel && this.state.selectedChannel.id === channel.id) {
            const updatedChannel = _.find(this.state.channels, (chan) => chan.id === this.state.selectedChannel?.id)
            this.selectChannel(updatedChannel)
          }
          // NB: we don't auto close/hide the modal form when it saves, leave it up for a success message to show & the user to dismiss manually
        }}
        onClose={() => { this.hideChannelModal() }}
        insideModal={true}
      />
    )
  }
}
export default withProjectAdminContext(withUserContext(withProjectStatusContext(ProjectChannelsPage)))
