/**
 * CompanySettingsView
 */

import React, { useContext } from 'react'

import { UserContext } from 'src/core/providers'

import { CompanyAccessSettingsView } from './CompanyAccessSettingsView'
import { CompanyProgramSettingsView } from './CompanyProgramSettingsView'
import { CompanyProjectSettingsView } from './CompanyProjectSettingsView'
import { CompanyUserSettingsView } from './CompanyUserSettingsView'

import styles from './CompanySettingsView.module.css'

export interface CompanySettingsViewProps {}

const CompanySettingsView = (_props: CompanySettingsViewProps) => {
  const userContext = useContext(UserContext)
  const company = userContext.store.selectedCompany
  if (!company) return null
  return (
    <div className={styles.settingsPage}>
      <div>
        <CompanyProgramSettingsView />
      </div>
      <div>
        <CompanyAccessSettingsView company={company} />
        <CompanyUserSettingsView company={company} />
        <CompanyProjectSettingsView company={company} />
      </div>
    </div>
  )
}

export default CompanySettingsView
