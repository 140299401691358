/**
 * ArkSpacer
 */

import React from 'react'

import styles from './ArkSpacer.module.css'

export interface ArkSpacerProps {
  grow?: boolean
  horizontal?: boolean
  large?: boolean
  negative?: boolean;
  size?: number
  small?: boolean
}

const ArkSpacer = (props: ArkSpacerProps) => {
  const { grow, horizontal, negative, large, size, small } = props

  let theSize = 10
  if (small) theSize = 5
  else if (large) theSize = 15
  else if (size !== undefined) theSize = size
  if (negative) theSize *= -1

  return (
    <div
      className={`${grow ? styles.grow : ''} ${horizontal ? styles.horizontal : ''}`}
      style={{ margin: `0 ${theSize}px ${theSize}px 0` }}
    />
  )
}
export default ArkSpacer
