import React, { useContext } from 'react'
import _ from 'lodash'

import ServerAPIClient from '../services/ServerAPIClient'
import ServerAuthAPI from '../services/ServerAuthAPI'
import ServerConfigAPI from '../services/ServerConfigAPI'

import * as CONFIG from '../../constants/config'
import { APIServerType } from '../../constants/server_types'

export interface IServerStore {
  apiClient?: ServerAPIClient
  authApi?: ServerAuthAPI
  serverConfigApi?: ServerConfigAPI // we don't use this here, just using this class as the access point to it (same as we do the api client & auth api classes)
  servers?: { [key: string]: { name: string, url: string} }
  apiServerType?: APIServerType
  apiServerUrl?: string
  loading: boolean
}

export interface IServerActions {
  selectServer: (serverKey: string) => boolean
}

export interface IServerContext {
  actions: IServerActions
  store: IServerStore
}

export interface IServerMultiContext {
  serverContext: IServerContext
}

export const ServerContext = React.createContext<IServerContext>({} as IServerContext)

export const useServer = () => useContext(ServerContext)

export interface ServerProviderProps {
  children?: React.ReactNode
}
export interface ServerProviderState extends IServerStore {
}

class ServerProvider extends React.Component<ServerProviderProps, ServerProviderState> {
  constructor (props: ServerProviderProps) {
    super(props)
    this.state = {
      apiClient: undefined,
      authApi: undefined,
      serverConfigApi: undefined,
      servers: undefined,
      apiServerType: undefined,
      apiServerUrl: undefined,
      loading: false
    }
  }

  componentDidMount () {
    this._loadServers()
  }

  // -------

  // loads/sets up the api client & auth api once we have the server data loaded
  _load () {
    console.log('ServerProvider - _load') // - CONFIG.SERVERS: ', CONFIG.SERVERS)
    const apiClient = new ServerAPIClient(CONFIG.API_SERVER_URL!)
    const authApi = new ServerAuthAPI(apiClient)
    const serverConfigApi = new ServerConfigAPI(apiClient)
    apiClient.authApi = authApi
    this.setState({
      apiServerType: CONFIG.API_SERVER_TYPE!, // ?? 'production',
      apiServerUrl: CONFIG.API_SERVER_URL!,
      apiClient,
      authApi,
      serverConfigApi,
      loading: false
    })
  }

  _cacheServerSelection (key: string) {
    localStorage.setItem('server', JSON.stringify({ key }))
  }

  _getCachedServerSelection () {
    const jsonData = localStorage.getItem('server')
    const serverData = jsonData ? JSON.parse(jsonData) : null
    if (serverData && serverData.key) {
      console.log('_loadCachedServerSelection - serverData.key: ', serverData.key)
      return serverData.key
    }
    return undefined
  }

  _clearCachedServerSelection = () => {
    localStorage.removeItem('server')
  }

  // on non-production builds use code splitting with a dynamic import to load a separate servers file (which won't be imported or included in production builds)
  // this conditional loading happens in an async/delayed fashion, & so requires some special handling compared to normal syncronous imports
  // this also means the main App component requires some special handling to detect when we're loading the additional servers info & show a loading screen until its available
  // WARNING: we seem to NEED to use a direct `process.env.` check for the bundled chunk to NOT be built into other builds
  // WARNING: ..without it the chunk is included in all builds (even production) - TODO: but need to check if the chunk is loaded/used (but we still don't want it 'in' the final build unless its needed)
  // WARNING: ..this means the current dynamic `env-config.js` usage via the `generate-env.sh` script is not enough to prevent the chunk being included in production builds & so can't be made fully dynamic (so have to be careful what is 'built' vs what is configured via the `env-config.js` file)
  // refs:
  //  https://www.freecodecamp.org/news/how-to-implement-runtime-environment-variables-with-create-react-app-docker-and-nginx-7f9d42a91d70/
  //  https://create-react-app.dev/docs/code-splitting/
  _loadServers () {
    console.log('ServerProvider - _loadServers')
    if (process.env.REACT_APP_API_SERVER_TYPE !== 'production' && CONFIG.SERVER_SWITCHING_ENABLED) {
      let apiServerType = CONFIG.API_SERVER_TYPE
      let apiServerUrl = CONFIG.API_SERVER_URL
      // trigger the conditional import of the special `servers.ts` config file
      import('../../constants/servers')
        .then(({ API_SERVERS: servers, API_SERVER_DEFAULT: defaultServer }) => {
          console.log('ServerProvider - _loadServers - DATA LOADED - servers: ', servers, ' defaultServer: ', defaultServer)

          // apply the config server type from the loaded options if its different from the env default pre set at build time
          const defaultApiServerType = apiServerType
          const defaultApiServerUrl = apiServerUrl
          if (defaultServer && servers && servers[defaultServer]) {
            const configServer = servers[defaultServer]
            const configServerType = APIServerType[_.capitalize(defaultServer) as keyof typeof APIServerType] // ref: https://stackoverflow.com/questions/17380845/how-do-i-convert-a-string-to-enum-in-typescript#comment98790295_17381004
            if (defaultApiServerType !== configServerType || defaultApiServerUrl !== configServer.url) {
              apiServerType = configServerType
              apiServerUrl = configServer.url
              console.log('ServerProvider - _loadServers - API_SERVER DEFAULT OVERRIDE LOADED - SERVER CHANGED FROM ' + defaultApiServerType + ' TO ' + apiServerType + ' (' + defaultApiServerUrl + ' > ' + apiServerUrl + ')')
            }
          }

          // TESTING: load the cached server selection & apply it if one was previously selected & is still valid/allowed (clear it if not)
          const cachedServerTypeKey = this._getCachedServerSelection()
          if (cachedServerTypeKey) {
            console.log('ServerProvider - _loadServers - cachedServerTypeKey: ', cachedServerTypeKey)
            const cachedServerType = APIServerType[_.capitalize(cachedServerTypeKey) as keyof typeof APIServerType]
            if (servers[cachedServerType]) {
              if (cachedServerType !== apiServerType) {
                console.log('ServerProvider - _loadServers - API_SERVER CACHED OVERRIDE LOADED - SERVER CHANGED FROM ' + apiServerType + ' TO ' + cachedServerType)
                apiServerType = cachedServerType
                apiServerUrl = servers[cachedServerType].url
              }
            } else {
              this._clearCachedServerSelection()
            }
          }

          if (defaultApiServerType === apiServerType && defaultApiServerUrl === apiServerUrl) {
            console.log('ServerProvider - _loadServers - API_SERVER NO OVERRIDE - DEFAULT USED: ' + apiServerType)
          }

          console.log('ServerProvider - _loadServers - API_SERVER type: ' + apiServerType + ' url: ' + apiServerUrl)
          const apiClient = new ServerAPIClient(apiServerUrl!)
          const authApi = new ServerAuthAPI(apiClient)
          apiClient.authApi = authApi

          this.setState({
            apiServerType: apiServerType, // ?? 'production',
            apiServerUrl,
            servers,
            apiClient,
            authApi,
            loading: false
          })
          console.log('ServerProvider - _loadServers - this.state.servers: ', this.state.servers)
        })
        .catch(err => {
          // failed to load the code split file, the code will fallback to the build time server settings instead
          console.error('ServerProvider - _loadServers - ERROR: ', err)
          // SERVERS_LOADING = false
          this.setState({
            apiServerType: apiServerType, // ?? 'production',
            apiServerUrl,
            servers: undefined,
            loading: false
            // FIXME: flag the error in some way?
          })
          // load the default server instead
          this._load()
        })
    } else {
      this._load()
    }
  }

  selectServer = (serverKey: string) => {
    console.log('ServerProvider - selectServer - serverKey: ', serverKey, ' servers: ', this.state.servers)
    const { apiServerType, apiServerUrl, apiClient } = this.state
    if (this.state.servers && serverKey && this.state.servers[serverKey]) {
      console.log('ServerProvider - selectServer - CHANGING TO SERVER: ', serverKey, '=', this.state.servers[serverKey])
      const server = this.state.servers[serverKey]
      const currentApiServerType = apiServerType
      const currentApiServerURL = apiServerUrl
      if (currentApiServerType !== serverKey || currentApiServerURL !== server.url) {
        const newApiServerType = APIServerType[_.capitalize(serverKey) as keyof typeof APIServerType] // ref: https://stackoverflow.com/questions/17380845/how-do-i-convert-a-string-to-enum-in-typescript#comment98790295_17381004
        const newApiServerUrl = server.url
        console.log('ServerProvider - selectServer - API_SERVER CHANGED FROM ' + currentApiServerType + '=' + currentApiServerURL + ' TO ' + newApiServerType + '=' + newApiServerUrl)
        if (apiClient) apiClient.apiBaseUrl = newApiServerUrl
        // FIXME: if already logged in (to a different server), invalid the login (NB: low priority (not needed) while we only support server select at login/reg time)
        this.setState({
          apiServerType: newApiServerType,
          apiServerUrl: newApiServerUrl
        })
        this._cacheServerSelection(newApiServerType)
      }
      return true
    }
    return false
  }

  // -------

  actions: IServerActions = {
    selectServer: this.selectServer
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: ISiteAdminStore = {
  //  ...
  // }

  render () {
    return (
      <ServerContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for IServerStore */ }}
      >
        {this.props.children}
      </ServerContext.Provider>
    )
  }
}

const withServerContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withServerContextHOC = (props: any) => (
    <ServerContext.Consumer>
      {(serverContext) => {
        if (serverContext === null) {
          throw new Error('ServerConsumer must be used within a ServerProvider')
        }
        // console.log('withServerContext - render - ServerContext.Consumer - serverContext.store: ', serverContext.store)
        return (<Component {...props} {...{ serverContext: serverContext }} />)
      }}
    </ServerContext.Consumer>
  )
  return withServerContextHOC
}

export { ServerProvider }
export { withServerContext }
