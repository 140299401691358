/**
 * RightSidebar
 */

import React, { ReactNode, useContext, useEffect, useState } from 'react'

import { ResponsiveContext } from '../../providers/ResponsiveProvider'
import ArkIconButton from '../ArkIconButton'
import ArkResponsive from '../ArkResponsive'

import styles from './RightSidebar.module.css'

interface RightSidebarProps {
  children?: ReactNode
  onClose?: () => void
}

const RightSidebar = (props: RightSidebarProps) => {
  const { children, onClose } = props

  const [isOpen, setIsOpen] = useState(false)

  const { store: responsiveStore } = useContext(ResponsiveContext)
  const { isMobile } = responsiveStore

  useEffect(() => {
    if (children) setTimeout(() => setIsOpen(true))
  }, [children])

  const onCloseClick = () => {
    setIsOpen(false)
    onClose && setTimeout(onClose, 500)
  }

  return (
    <>
      <ArkResponsive mobileOnly>
        <div className={`${styles.background} ${isOpen ? styles.show : ''}`} onClick={onCloseClick} />
      </ArkResponsive>
      <ArkResponsive desktopOnly>
        <div className={`${styles.spacer} ${isOpen ? styles.open : ''}`} />
      </ArkResponsive>
      <div
        className={`${styles.container} ${isMobile ? styles.mobile : ''} ${isOpen ? styles.open : ''}`}
      >
        <div className={styles.content}>
          <div className={styles.main}>
            {/* <ArkIconButton
              className={styles.closeButton}
              color='var(--bd-lighter)'
              hoverColor='var(--tx-light)'
              name='close'
              onClick={onCloseClick}
              size={24}
            /> */}
            {children}
          </div>
        </div>
        <div className={styles.controlsContainer}>
          <div className={styles.controls}>
            <ArkIconButton
              disabled={!isOpen}
              name='angle-right'
              onClick={onCloseClick}
              size={24}
              className={styles.closeBtn}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default RightSidebar
