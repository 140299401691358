/**
 * ChecklistView
 */

import React, { useContext } from 'react'

import * as ROUTES from 'src/constants/routes'

import { DEFAULT_GROUP_NAME, NodeGraphContext } from '../nodeGraph/NodeGraphProvider'
import Line from './components/Line'
import ListItem from './components/ListItem'
import { ChecklistContext } from './ChecklistProvider'

const ChecklistView = () => {
  const { store: checklistStore } = useContext(ChecklistContext)
  const {
    isCreateProgramEnabled,
    isCreateProgramChecked,
    isCreateChannelEnabled,
    isCreateChannelChecked,
    isCreateGroupEnabled,
    isCreateGroupChecked,
    isAddProgramToChannelEnabled,
    isAddProgramToChannelChecked,
    isAddChannelToGroupEnabled,
    isAddChannelToGroupChecked,
    isAddChannelToGroupDefaultGroup,
    isAddUserToProjectEnabled,
    isAddUserToProjectChecked,
    isAddUserToGroupEnabled,
    isAddUserToGroupChecked,
    isAddUserToGroupDefaultGroup,
    isUserCanViewProgramEnabled,
    isUserCanViewProgramChecked,
    isChecklistComplete,
    showChecklist
  } = checklistStore

  const { actions: nodeGraphActions } = useContext(NodeGraphContext)
  const { navigateTo } = nodeGraphActions

  if (!showChecklist) return null

  return (
    <>
      {/* create project */}
      <ListItem checked title='Create project' />
      <Line active />

      {/* create program */}
      <ListItem
        checked={isCreateProgramChecked}
        description='A program is the destination transport that you connect your video streams to. Example: Cam A'
        disabled={!isCreateProgramEnabled}
        onClick={() => navigateTo(ROUTES.PROJECT_MANAGER_PROGRAMS)}
        title={<>Create your first <strong>program</strong></>}
      />
      <Line active={isCreateProgramEnabled && isCreateProgramChecked} />

      {/* create channel */}
      <ListItem
        checked={isCreateChannelChecked}
        description='Channels package multiple programs together, usually where a group of video sources orginate. Example: Main Unit Channel'
        disabled={!isCreateChannelEnabled}
        onClick={() => navigateTo(ROUTES.PROJECT_MANAGER_CHANNELS)}
        title={<>Create your first <strong>channel</strong></>}
      />
      <Line active={isCreateChannelEnabled && isCreateChannelChecked}>

        {/* create group (optional) */}
        <ListItem
          checked={isCreateGroupChecked}
          description={`Groups are where you connect users to channels. Example: Main Unit Group. *Optional (you can use '${DEFAULT_GROUP_NAME}')`}
          disabled={!isCreateGroupEnabled}
          onClick={() => navigateTo(ROUTES.PROJECT_MANAGER_GROUPS)}
          optional
          title={<>Create your first <strong>group</strong></>}
        />
      </Line>

      {/* add program to channel */}
      <ListItem
        checked={isAddProgramToChannelChecked}
        description='Programs are viewed through channels which we will connect to a group in the next step.'
        disabled={!isAddProgramToChannelEnabled}
        onClick={() => navigateTo(ROUTES.PROJECT_MANAGER_CHANNELS)}
        title={<>Add a <strong>program</strong> to a <strong>channel</strong></>}
      />
      <Line active={isAddProgramToChannelEnabled && isAddProgramToChannelChecked} />

      {/* add channel to group */}
      <ListItem
        checked={isAddChannelToGroupChecked}
        description='Users in the group will be able to see all programs in the channel.'
        disabled={!isAddChannelToGroupEnabled}
        onClick={() => navigateTo(ROUTES.PROJECT_MANAGER_GROUPS)}
        status={isAddChannelToGroupDefaultGroup ? `Note: Project is using '${DEFAULT_GROUP_NAME}' default group.` : undefined}
        title={<>Add a <strong>channel</strong> to a <strong>group</strong></>}
      />
      <Line active={isAddChannelToGroupEnabled && isAddChannelToGroupChecked} />

      {/* add user to project */}
      <ListItem
        checked={isAddUserToProjectChecked}
        description='Standard users are view only, invite another user to the project now.'
        disabled={!isAddUserToProjectEnabled}
        onClick={() => navigateTo(ROUTES.PROJECT_MANAGER_USERS)}
        title={<>Invite other <strong>users</strong> to <strong>project</strong></>}
      />
      <Line active={isAddUserToProjectEnabled && isAddUserToProjectChecked} />

      {/* add user to group */}
      <ListItem
        checked={isAddUserToGroupChecked}
        description={'Add user to a group that\'s connected to a channel.'}
        disabled={!isAddUserToGroupEnabled}
        onClick={() => navigateTo(ROUTES.PROJECT_MANAGER_GROUPS)}
        status={isAddUserToGroupDefaultGroup ? `Note: Project is using '${DEFAULT_GROUP_NAME}' default group.` : undefined}
        title={<>Add a <strong>user</strong> to a <strong>group</strong></>}
      />
      <Line active={isAddUserToGroupEnabled && isAddUserToGroupChecked} />

      {/* user can view program */}
      <ListItem
        checked={isUserCanViewProgramChecked}
        description='Use the project map to the left to make sure there is a Program > Channel > Group > User connection flow.'
        disabled={!isUserCanViewProgramEnabled}
        readOnly
        title={<>A <strong>user</strong> can view a <strong>program</strong></>}
      />
      <Line active={isUserCanViewProgramEnabled && isUserCanViewProgramChecked} />

      {/* checklist complete */}
      <ListItem checked={isChecklistComplete} disabled={!isChecklistComplete} title='Project set up complete' />
    </>
  )
}

export default ChecklistView
