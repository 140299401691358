import React from 'react'
import { Form, TextArea, TextAreaProps } from 'semantic-ui-react'

import ArkFormLabel from '../ArkForm/ArkFormLabel'
import ArkIconButton from '../ArkIconButton'
import styles from './ArkTextArea.module.css'

export interface ArkTextAreaProps extends TextAreaProps {
  label?: string
  onClear?: () => void
  required?: boolean
}

const ArkTextArea = (props: ArkTextAreaProps) => {
  const { className, label, onClear, required, ...otherProps } = props

  const showClear: boolean = !!onClear && !!props.value

  return (
    <div>
      {label && (
        <ArkFormLabel required={required}>{label}</ArkFormLabel>
      )}
      {/* https://react.semantic-ui.com/addons/text-area/#types-text-area */}
      <Form>
        <div className={styles.container}>
          <TextArea
            className={`${
              styles.textArea
            } ${
              showClear && styles.textAreaClear
            } ${
              className
            }`}
            {...otherProps}
          />
          {showClear && (
            <ArkIconButton
              className={styles.clearButton}
              color='var(--bd-lighter)'
              hoverColor='var(--tx-light)'
              name='close'
              onClick={onClear}
              size={18}
            />
          )}
        </div>
      </Form>
    </div>
  )
}

export default ArkTextArea
