/**
 * ArkRadio
 */

import React from 'react'

import { Radio, RadioProps } from 'semantic-ui-react'

import styles from './ArkRadio.module.css'

export interface ArkRadioProps extends RadioProps {}

const ArkRadio: React.FC<ArkRadioProps> = (props: ArkRadioProps) => {
  const { className, children } = props
  return (
    <Radio
      {...props}
      className={`${styles.radio} ${className || ''}`}
    >{children}</Radio>
  )
}

export default ArkRadio
