import React from 'react'

import { withUserContext, IUserMultiContext, withNavContext, INavMultiContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import { Company } from 'src/core/models'

import CompanyDeleteView, { CompanyDidDeleteCallback } from './CompanyDeleteView'

import ArkButton from 'src/core/components/ArkButton'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { SECTION_COMPANY_NAME, SECTION_MANAGER_SUFFIX_NAME } from 'src/constants/strings'

import styles from './AdminCompanySidebar.module.css'

export type CompanyEditCallback = (company: Company) => void

interface IProps extends IUserMultiContext, INavMultiContext {
  company?: Company
  onEdit?: CompanyEditCallback
  onDidDelete?: CompanyDidDeleteCallback
}
interface IState {
  companyDeleted: boolean
}

class AdminCompanySidebar extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = {
      companyDeleted: false
    }
  }

  render () {
    const { company } = this.props
    if (!company) return (<></>)
    return (
      <>

        <ArkPanel bordered className={styles.sidebarHeader}>
          <ArkPanel.Header className={styles.sidebarTitle} title={company.name} iconName='company' />
          <ArkIconButton
            className={styles.menuButton}
            name='settings'
            onClick={this.onEdit}
            size={24}
          />
        </ArkPanel>

        {!this.state.companyDeleted && (<>

          <ArkSpacer />
          {/* <ArkButton type="button" fluid size="large" onClick={this.onEdit}>EDIT {SECTION_COMPANY_NAME}</ArkButton> */}

          <ArkPanel bordered>
            <ArkPanel.Properties>
              <ArkPanel.PropertyRow title='id:' value={company.id} />
              <ArkPanel.PropertyRow title='STATUS:' value={company.isActive ? 'ACTIVE' : 'DISABLED'} />
              <ArkPanel.PropertyRow title='TYPE:' value={company.isDemo ? 'DEMO' : 'FULL'} />
              <ArkPanel.PropertyRow title='2FA:' value={company.force2fa ? 'REQUIRED' : 'OPTIONAL'} />
              <ArkPanel.PropertyRow title='MAX USERS:' value={company.maxUsers} />
              <ArkPanel.PropertyRow title='PROJECT DEFAULT MAX USERS:' value={company.projectDefaultMaxUsers} />
            </ArkPanel.Properties>
          </ArkPanel>

          <ArkSpacer />

          <ArkButton type="button" fluid size="large" onClick={this.onOpenCompanyManager}>OPEN IN {SECTION_COMPANY_NAME + ' ' + SECTION_MANAGER_SUFFIX_NAME}</ArkButton>
        </>)}

        <ArkSpacer grow />

        <CompanyDeleteView
          company={company}
          onDidDelete={this.onDidDeleteCompany}
        />

      </>
    )
  }

  // -------

  resetView = () => {
    this.setState({ companyDeleted: false }) // reset the state
  }

  // -------

  onEdit = () => {
    if (this.props.onEdit && this.props.company) this.props.onEdit(this.props.company)
  }

  onDidDeleteCompany = (company: Company) => {
    if (this.props.onDidDelete) this.props.onDidDelete(company)
    this.setState({ companyDeleted: true })
  }

  // -------

  onOpenCompanyManager = () => {
    const { company } = this.props
    if (company) {
      // select the company & navigate to its company manager section
      this.props.userContext.actions.selectCompany(company.id) // TODO: disable the auto redirect this does & rely on the manual one below?
      this.props.navContext.actions.goto(ROUTES.COMPANY_MANAGER) // NB: specifically navigate to the company manger page for the newly selected company
    }
  }
}

export default withNavContext(withUserContext(AdminCompanySidebar))
