import React from 'react'
import { Switch, Route } from 'react-router-dom'

import {
  IAPIMultiContext,
  IAuthMultiContext,
  INavMultiContext,
  IUserMultiContext,
  ProjectAdminProvider,
  ProjectStatusProvider,
  withAPIContext,
  withAuthContext,
  withNavContext,
  withUserContext
} from 'src/core/providers'

import * as ROUTES from 'src/constants/routes'

import { UserProject, UserCompanyRole, UserProjectRole } from 'src/core/models'

import ProjectCompaniesSelectPage from './pages/companies/ProjectCompaniesSelectPage'
import ProjectManagerProjectSelectPage from './pages/projects/ProjectManagerProjectSelectPage'
import ProjectManagerAccessDeniedPage from './pages/projects/ProjectManagerAccessDeniedPage'
import ProjectManagerLoadingPage from './pages/projects/ProjectManagerLoadingPage'
import ProjectManagerNoProjectsPage from './pages/projects/ProjectManagerNoProjectsPage'
import ProjectManagerInactiveOrgPage from './pages/projects/ProjectManagerInactiveOrgPage'
import ProjectManagerHomePage from './pages/home/ProjectManagerHomePage'
import ProjectGroupsPage from './pages/groups/ProjectGroupsPage'
import ProjectUsersPage from './pages/users/ProjectUsersPage'
import ProjectCardsPage from './pages/cards/ProjectCardsPage'
import ProjectNoticesPage from './pages/notices/ProjectNoticesPage'
import ProjectChannelsPage from './pages/channels/ProjectChannelsPage'
import ProjectProgramsPage from './pages/programs/ProjectProgramsPage'
// import ProjectActivityPage from './pages/activity/ProjectActivityPage'
import ProjectSettingsPage from './pages/settings/ProjectSettingsPage'
import ProjectSettingsWatermarkPage from './pages/settings/watermark/ProjectSettingsWatermarkPage'
import ProjectBroadcastPage from './pages/broadcast/ProjectBroadcastPage'
import Project2FAAuthPage from './pages/auth/Project2FAAuthPage'

import SupportPage from 'src/core/pages/support/SupportPage'

interface IProps extends IUserMultiContext, IAuthMultiContext, IAPIMultiContext, INavMultiContext {
}
interface IState {
}

class ProjectManagerRouter extends React.Component<IProps, IState> {
  render () {
    const { selectedCompany: company, selectedProject: project, loadingCompanyData } = this.props.userContext.store

    // catch if no company selected
    if (!company) {
      return (<ProjectCompaniesSelectPage />)
    }

    // show a loader when initially loading the selected company (on page load on switching companies, so project won't be set compared to an update of current selections)
    // NB: we don't want this to fire when updating the selected company (& other selections), otherwise this can trigger briefly & cause pages to reload & so reset when we don't want them too
    if (!project && loadingCompanyData) {
      return <ProjectManagerLoadingPage />
    }

    // show a loading screen when switching company or project while in the project manager section
    // this allows the loader to be shown instantly on switch instead of waiting for some initial api calls & then triggering other lower down loading checks/UI
    // NB: this should NOT show/trigger if updating the current selection, only if the selection changes, so data updates don't reset the current manager page content (& its selections)
    const loadingParentData = this.props.userContext.store.loadingCompanyData || this.props.userContext.store.loadingProjectData
    const updatingParentData = this.props.userContext.store.updatingCompanyData || this.props.userContext.store.updatingProjectData
    if (loadingParentData && !updatingParentData) {
      return <ProjectManagerLoadingPage />
    }

    // disabled/inactive company
    if (!company.isActive) {
      return <ProjectManagerInactiveOrgPage />
    }

    // company 2fa
    const user = this.props.userContext.store.user
    const userTFAEnabled = user?.tfaEnabled || false
    const companyAuthTFARequired = this.props.authContext.actions.isCompanyAuthTFARequired()
    console.log('ProjectManagerRouter - companyAuthTFARequired: ', companyAuthTFARequired, ' userTFAEnabled:', userTFAEnabled)
    if (companyAuthTFARequired && !userTFAEnabled) {
      return (<Project2FAAuthPage />)
    }

    // catch if the user doesn't have (any) access to the specified project  (they specified the project id in the url, but no project was selected)
    // NB: this will also currently fire if you try to load a project that doesn't exist for this company (may exist for another)
    // NB: although the UserProvider may handle that scenario soon & would redirect if the user has access before we hit here
    if (!project && this.props.navContext.store.urlIds.projectId !== undefined) {
      return (<ProjectManagerAccessDeniedPage />)
    }

    // catch if the user doesn't have admin/manager access to the specified project, or access disabled for the project
    const isCompanyAdmin = company.userCompanyRole === UserCompanyRole.admin
    let isProjectAdminOrManager = false
    if (project instanceof UserProject) {
      isProjectAdminOrManager = project.userProjectRole === UserProjectRole.admin || project.userProjectRole === UserProjectRole.manager
    }
    const hasAdminAccess = isCompanyAdmin || isProjectAdminOrManager
    const accessDisabled = ((project instanceof UserProject) && !isCompanyAdmin ? !project.userAccessEnabled : false)
    if (project && (!hasAdminAccess || accessDisabled)) {
      return (<ProjectManagerAccessDeniedPage />)
    }

    console.log('ProjectManagerRouter - project:', project)

    // catch if the user doesn't have access to admin/manage any projects
    const companyAdminOrProjectManagerOrAdminProjects = this.props.userContext.actions.getCompanyAdminOrProjectManagerOrAdminProjects()
    if (!project && companyAdminOrProjectManagerOrAdminProjects.length === 0) {
      return (<ProjectManagerNoProjectsPage />)
    }

    // catch if no project selected (& none specified in the url otherwise its caught further up)
    if (!project) {
      return (<ProjectManagerProjectSelectPage />)
    }

    // project 2fa
    const projectAuthTFARequired = this.props.authContext.actions.isProjectAuthTFARequired()
    console.log('ProjectManagerRouter - projectAuthTFARequired: ', projectAuthTFARequired, ' userTFAEnabled:', userTFAEnabled)
    if (projectAuthTFARequired && !userTFAEnabled) {
      return (<Project2FAAuthPage />)
    }

    // NB: could use `${path}/groups`, `${path}/users` type path args if we want to keep the parent/root path variable
    return (
      <ProjectAdminProvider apiClient={this.props.apiContext.actions.getAPIClient()}>
        <ProjectStatusProvider>
          <Switch>
            <Route path={ROUTES.PROJECT_MANAGER_GROUPS} component={ProjectGroupsPage} />
            <Route path={ROUTES.PROJECT_MANAGER_USERS} component={ProjectUsersPage} />
            <Route path={ROUTES.PROJECT_MANAGER_CHANNELS} component={ProjectChannelsPage} />
            <Route path={ROUTES.PROJECT_MANAGER_PROGRAMS} component={ProjectProgramsPage} />
            <Route path={ROUTES.PROJECT_MANAGER_CARDS} component={ProjectCardsPage} />
            <Route path={ROUTES.PROJECT_MANAGER_NOTICES} component={ProjectNoticesPage} />
            {/* <Route path={ROUTES.PROJECT_MANAGER_ACTIVITY} component={ProjectActivityPage} /> */}
            <Route path={ROUTES.PROJECT_MANAGER_SETTINGS_WATERMARK} component={ProjectSettingsWatermarkPage} />
            <Route path={ROUTES.PROJECT_MANAGER_SETTINGS} component={ProjectSettingsPage} />

            <Route path={ROUTES.PROJECT_MANAGER_BROADCAST} component={ProjectBroadcastPage} />

            <Route path={ROUTES.PROJECT_MANAGER_SUPPORT} component={SupportPage} />

            <Route exact path={ROUTES.PROJECT_MANAGER_SELECT} component={ProjectManagerHomePage} />
            <Route path={ROUTES.PROJECT_MANAGER_VIEW} component={ProjectManagerHomePage} />
          </Switch>
        </ProjectStatusProvider>
      </ProjectAdminProvider>
    )
  }
}

export default withNavContext(withAPIContext(withUserContext(withAuthContext(ProjectManagerRouter))))
