/**
 * Line
 */

import React, { ReactNode } from 'react'

import styles from './Line.module.css'

interface LineProps {
  active: boolean
  children?: ReactNode
}

const Line = ({ active, children }: LineProps) => (
  <div className={styles.container}>
    <div className={`${styles.line} ${active ? styles.active : ''}`} />
    {children}
  </div>
)

export default Line
