import React, { ReactNode, useEffect, useState } from 'react'
import _ from 'lodash'
import { Button, Form, Input } from 'semantic-ui-react'

import ArkButton, { ArkButtonGroup } from 'src/core/components/ArkButton'
import ArkCheckbox from 'src/core/components/ArkCheckbox'
import ArkDropdown from 'src/core/components/ArkDropdown'
import ArkIcon from 'src/core/components/ArkIcon'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkPopup from '../../../core/components/ArkPopup'
import ArkSlider from 'src/core/components/ArkSlider'
import ArkSpacer from 'src/core/components/ArkSpacer'
import {
  PROGRAM_BUFFER_MAX,
  PROGRAM_BUFFER_MIN,
  PROGRAM_BUFFER_OPTION_BALANCED,
  PROGRAM_BUFFER_OPTION_FAST,
  PROGRAM_BUFFER_OPTION_STABLE,
  PROGRAM_BUFFER_STEP,
  PROGRAM_OPTIONS_SHOW_ADVANCED,
  PROGRAM_OPTIONS_SHOW_ADVANCED_PARAMETERS,
  PROGRAM_OPTIONS_SHOW_BUFFER,
  VIDEO_PLAYER_ABR_RESOLUTIONS,
  VIDEO_PLAYER_SUPPORTED_RESOLUTIONS
} from '../../../constants/config'
import { useDebounceEffect } from 'src/core/hooks/debounce'
import { PlayerResolution } from '../../../core/types/player'

import styles from './ProgramOptionsPopup.module.css'

const CHANNEL_DEFAULT = 'Channel default'

interface ProgramOptionsPopupProps {
  abr: boolean
  advancedParameters: string
  buffer: number
  customOptions: boolean
  onAbrChange: (value: boolean) => void
  onAdvancedParametersChange: (value: string) => void
  onBufferChange: (value: number) => void
  onCustomOptionsChange: (value: boolean) => void
  onOpenChange: (value: boolean) => void
  onPassthroughChange: (value: boolean) => void
  onResolutionChange: (value: PlayerResolution) => void
  onRestartClick: () => void
  onStoppedChange: (value: boolean) => void
  onThreeSixtyOnChange: (value: boolean) => void
  onThreeSixtyPreferPassthroughChange: (value: boolean) => void
  open: boolean
  passthrough: boolean
  resolution: PlayerResolution | undefined
  restarting: boolean
  stopped: boolean
  threeSixtyAvailable: boolean
  threeSixtyOn: boolean
  threeSixtyPreferPassthrough: boolean
  triggerComponent: ReactNode
}

const ProgramOptionsPopup = (props: ProgramOptionsPopupProps) => {
  const {
    abr,
    advancedParameters,
    buffer,
    customOptions,
    onAbrChange,
    onAdvancedParametersChange,
    onBufferChange,
    onCustomOptionsChange,
    onOpenChange,
    onPassthroughChange,
    onResolutionChange,
    onRestartClick,
    onStoppedChange,
    onThreeSixtyOnChange,
    onThreeSixtyPreferPassthroughChange,
    open,
    passthrough,
    resolution,
    restarting,
    stopped,
    threeSixtyAvailable,
    threeSixtyOn,
    threeSixtyPreferPassthrough,
    triggerComponent
  } = props

  const [_advancedParameters, _setAdvancedParameters] = useState(advancedParameters)
  const [_buffer, _setBuffer] = useState(buffer)
  const [isAdvancedParametersValid, setIsAdvancedParametersValid] = useState(true)
  const [showAdvanced, setShowAdvanced] = useState(false)

  useDebounceEffect(() => {
    onBufferChange(_buffer)
    if (_buffer !== buffer) onRestartClick()
  }, [_buffer], 1000)

  useEffect(() => {
    setIsAdvancedParametersValid(true)
  }, [_advancedParameters])

  useEffect(() => {
    _setAdvancedParameters(advancedParameters)
  }, [advancedParameters])

  /**
   * actions
   */

  const onAdvancedParametersSubmit = () => {
    console.log('ProgramOptionsPopup - onAdvancedParametersSubmit')
    try {
      if (_advancedParameters) JSON.parse(_advancedParameters)
      setIsAdvancedParametersValid(true)
      onAdvancedParametersChange(_advancedParameters)
      onRestartClick()
    } catch (_error) {
      setIsAdvancedParametersValid(false)
    }
  }

  /**
   * render
   */

  // resolution
  const resolutionOptions = _.map(VIDEO_PLAYER_SUPPORTED_RESOLUTIONS, value => ({
    active: value === resolution,
    onClick: () => onResolutionChange(value),
    text: `${abr && _.includes(VIDEO_PLAYER_ABR_RESOLUTIONS, value) ? 'Up to ' : ''}${value}`,
    value
  }))

  // playback
  const playbackOptions = [
    {
      active: !stopped,
      onClick: () => onStoppedChange(false),
      text: !stopped ? 'Started' : 'Start',
      value: !stopped ? 'Started' : 'Start'
    },
    {
      active: stopped,
      onClick: () => onStoppedChange(true),
      text: stopped ? 'Stopped' : 'Stop',
      value: stopped ? 'Stopped' : 'Stop'
    },
    {
      active: restarting,
      disabled: stopped,
      onClick: !stopped ? onRestartClick : undefined,
      text: restarting ? 'Restarting…' : 'Restart',
      value: restarting ? 'Restarting…' : 'Restart'
    }
  ]
  let playbackValue = CHANNEL_DEFAULT
  if (restarting) playbackValue = 'Restarting…'
  else if (stopped === false) playbackValue = 'Started'
  else if (stopped === true) playbackValue = 'Stopped'

  const isThreeSixtyForcePassthrough = threeSixtyAvailable && threeSixtyOn && threeSixtyPreferPassthrough
  const isPassthrough = passthrough || isThreeSixtyForcePassthrough

  return (
    <ArkPopup
      className={styles.popup}
      onClose={() => onOpenChange(false)}
      onOpen={() => onOpenChange(true)}
      open={open}
      position='top right'
      title='PROGRAM VIDEO OPTIONS'
      trigger={triggerComponent}
    >
      {/* custom options */}
      <Button.Group fluid>
        <ArkButton active={!customOptions} onClick={() => onCustomOptionsChange(false)}>Default</ArkButton>
        <ArkButton active={customOptions} onClick={() => onCustomOptionsChange(true)}>Custom</ArkButton>
      </Button.Group>
      <ArkSpacer large />

      {!customOptions && (
        <>
          <ArkSpacer size={20} />
          <div className={styles.message}>Default: Video options inherited from channel (top bar)</div>
          <ArkSpacer />
        </>
      )}

      {customOptions && (
        <>
          {/* 360 */}
          {threeSixtyAvailable && (
            <>
              <div className={styles.label}>360° (BETA):</div>
              <ArkSpacer small />
              <ArkSpacer />
              <ArkCheckbox
                checked={threeSixtyOn}
                label='360° view'
                onChange={(_event, data) => onThreeSixtyOnChange(!!data.checked)}
                size='mini'
                toggle
              />
              <ArkSpacer />
              <ArkCheckbox
                checked={threeSixtyPreferPassthrough}
                disabled={!threeSixtyOn}
                label='Prefer passthrough'
                onChange={(_event, data) => onThreeSixtyPreferPassthroughChange(!!data.checked)}
                size='mini'
                toggle
              />
              <ArkSpacer large />
            </>
          )}

          <div className={styles.label}>Quality:</div>
          <ArkSpacer small />

          {/* passthrough */}
          <ArkButtonGroup fluid size='small'>
            <ArkButton
              active={!isPassthrough}
              color={!isPassthrough ? 'blue' : undefined}
              disabled={isThreeSixtyForcePassthrough}
              onClick={() => onPassthroughChange(false)}
            >
              TRANSCODER
            </ArkButton>
            <ArkButton
              active={passthrough}
              color={isPassthrough ? 'blue' : undefined}
              disabled={isThreeSixtyForcePassthrough}
              onClick={() => onPassthroughChange(true)}
            >
              PASSTHROUGH
            </ArkButton>
          </ArkButtonGroup>
          <ArkSpacer large />

          {!isPassthrough && (
            <>
              {/* abr */}
              <ArkCheckbox
                checked={abr && _.includes(VIDEO_PLAYER_ABR_RESOLUTIONS, resolution)}
                label='Automatically adjust quality'
                onChange={(_event, data) => onAbrChange(!!data.checked)}
                size='mini'
                toggle
              />
              <ArkSpacer large />

              {/* resolution */}
              <ArkDropdown
                fluid
                options={resolutionOptions}
                selection value={resolution || CHANNEL_DEFAULT}
              />
            </>
          )}

          {/* buffer */}
          {PROGRAM_OPTIONS_SHOW_BUFFER && (
            <>
              <ArkSpacer large />
              <div className={styles.bufferLabelContainer}>
                <div className={styles.label}>Buffer:</div>
                <span className={styles.bufferValue}>
                  {`${_buffer.toLocaleString()} ms`}
                </span>
              </div>
              <ArkSpacer small />
              <Button.Group fluid size='tiny'>
                <ArkButton
                  active={_buffer === PROGRAM_BUFFER_OPTION_FAST}
                  color={_buffer === PROGRAM_BUFFER_OPTION_FAST ? 'blue' : undefined}
                  onClick={() => _setBuffer(PROGRAM_BUFFER_OPTION_FAST)}
                >
                  Fast
                </ArkButton>
                <ArkButton
                  active={_buffer === PROGRAM_BUFFER_OPTION_BALANCED}
                  color={_buffer === PROGRAM_BUFFER_OPTION_BALANCED ? 'blue' : undefined}
                  onClick={() => _setBuffer(PROGRAM_BUFFER_OPTION_BALANCED)}
                >
                  Balanced
                </ArkButton>
                <ArkButton
                  active={_buffer === PROGRAM_BUFFER_OPTION_STABLE}
                  color={_buffer === PROGRAM_BUFFER_OPTION_STABLE ? 'blue' : undefined}
                  onClick={() => _setBuffer(PROGRAM_BUFFER_OPTION_STABLE)}
                >
                  Stable
                </ArkButton>
              </Button.Group>
              <ArkSpacer small />
              <ArkSlider
                max={PROGRAM_BUFFER_MAX}
                min={PROGRAM_BUFFER_MIN}
                onChange={event => _setBuffer(parseInt(event.target.value))}
                step={PROGRAM_BUFFER_STEP}
                value={_buffer}
              />
            </>
          )}

          {/* advanced */}
          {PROGRAM_OPTIONS_SHOW_ADVANCED && (
            <>
              <ArkSpacer large />
              <div className={styles.advancedButton} onClick={() => setShowAdvanced(!showAdvanced)}>
                <div>Advanced options</div>
                <ArkIcon name={showAdvanced ? 'angle-double-up' : 'angle-double-down'} size={18} />
              </div>
            </>
          )}

          {showAdvanced && (
            <>
              <ArkSpacer small />

              {/* playback */}
              <div className={styles.label}>Playback state:</div>
              <ArkSpacer small />
              <ArkDropdown fluid options={playbackOptions} selection value={playbackValue} />
              <ArkSpacer />

              {/* advanced parameters */}
              {PROGRAM_OPTIONS_SHOW_ADVANCED_PARAMETERS && (
                <>
                  <div className={styles.label}>Advanced parameters (JSON):</div>
                  <ArkSpacer small />
                  <Form className={styles.advancedParametersForm} onSubmit={onAdvancedParametersSubmit}>
                    <Form.Field className={styles.advancedParametersFormField} error={!isAdvancedParametersValid}>
                      <Input
                        className={styles.advancedParametersInput}
                        fluid
                        onChange={event => _setAdvancedParameters(event.target.value)}
                        value={_advancedParameters}
                      />
                    </Form.Field>
                    <ArkIconButton
                      className={styles.advancedParametersButton}
                      color='var(--blue)'
                      name='arrow-right'
                      onClick={onAdvancedParametersSubmit}
                      size={24}
                    />
                  </Form>
                  <ArkSpacer small />
                  <div className={styles.description}>Example: <code>{'{"aspect_ratio":"4:3"}'}</code></div>
                </>
              )}
            </>
          )}
        </>
      )}
    </ArkPopup>
  )
}

export default ProgramOptionsPopup
