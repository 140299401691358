/**
 * ArkMainMenuButton
 */

import React from 'react'

import ArkIcon, { IconName } from '../ArkIcon'

import styles from './ArkMainMenuButton.module.css'

interface ArkMainMenuButtonProps {
  active?: boolean
  compact?: boolean
  disabled?: boolean
  iconName: IconName
  onClick: () => void
  title: string
}

const ArkMainMenuButton = (props: ArkMainMenuButtonProps) => {
  const { active, compact, disabled, iconName, onClick, title } = props

  return (
    <div
      className={`${
        styles.button
      } ${
        active ? styles.active : ''
      } ${
        compact ? styles.compact : ''
      } ${
        disabled ? styles.disabled : ''
      }`}
      onClick={onClick}
    >
      <div className={styles.iconContainer}>
        <ArkIcon name={iconName} size={20} />
      </div>
      <div className={`${styles.title} ${compact ? styles.titleCompact : ''}`}>{title}</div>
    </div>
  )
}

export default ArkMainMenuButton
