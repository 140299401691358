/**
 * ProjectGroupListItem
 */

import React from 'react'

import { ProjectGroup } from 'src/core/models'

import ProjectGroupAccessToggle from './ProjectGroupAccessToggle'

import ArkListItem, { ArkListItemDetail } from 'src/core/components/ArkListItem'
import ArkSpacer from 'src/core/components/ArkSpacer'

import styles from './ProjectGroupListItem.module.css'

interface ProjectGroupListItemProps {
  companyId: number
  projectId: number
  group: ProjectGroup
  active: boolean
  filter?: string
  onClick: () => void
  onEditClick?: () => void
  onGroupUpdated: (group: ProjectGroup) => void
}

const ProjectGroupListItem = (props: ProjectGroupListItemProps) => {
  const {
    companyId,
    projectId,
    group,
    active,
    filter,
    onClick,
    onEditClick,
    onGroupUpdated
  } = props

  const rightComponent = (
    <ArkListItemDetail
      hintMessage='...' // TODO: <<<<
      hintTitle='Toggle Enable/Disable group access'
      title='Access'
    >
      <ProjectGroupAccessToggle
        companyId={companyId}
        projectId={projectId}
        group={group}
        onChange={(value: boolean) => {
          console.log('CompanyGroupListItem - CompanyGroupAccessToggle - onChange - value:', value)
          if (value !== group.enabled) {
            group.enabled = value
            onGroupUpdated(group)
          }
        }}
        showStatusText={false}
      />
    </ArkListItemDetail>
  )

  const rightComponentDesktop = (
    <>
      <ArkListItemDetail horizontal title='Users:'>
        <div className={styles.detailText}>{group.mirrorGroup?.usersCount ?? group.usersCount}</div>
      </ArkListItemDetail>
      <ArkSpacer />
      <ArkListItemDetail horizontal title='Channels:'>
        <div className={styles.detailText}>{group.channelsCount}</div>
      </ArkListItemDetail>
    </>
  )

  const groupEnabled = (group.isMirroredGroup() && group.mirrorGroup?.enabled === false) ? false : group.enabled

  return (
    <ArkListItem
      active={active}
      description={group.desc}
      disabled={!groupEnabled}
      filter={filter}
      onClick={onClick}
      onEditClick={onEditClick}
      rightComponent={rightComponent}
      rightComponentDesktop={rightComponentDesktop}
      title={group.name}
    />
  )
}

export default ProjectGroupListItem
