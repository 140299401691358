/**
 * FilterModal
 */

import React, { useContext } from 'react'

import ArkModal from 'src/core/components/ArkModal'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { NodeGraphContext } from '../NodeGraphProvider'
import FilterView from './FilterView'

import styles from './FilterModal.module.css'

const FilterModal = () => {
  const { actions, store } = useContext(NodeGraphContext)
  const { setShowFilter } = actions
  const { showFilter } = store

  if (!showFilter) return null

  return (
    <ArkModal className={styles.modal} onClose={() => setShowFilter(false)} open>
      <div className={styles.title}>Project map filters</div>
      <ArkSpacer />
      <FilterView />
    </ArkModal>
  )
}

export default FilterModal
