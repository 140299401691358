import { Program } from 'src/core/models'
import { PlayerResolution } from 'src/core/types/player'

import { ProgramWatchdogStatus } from './ProgramWatchdog'

export const formatError = (error: string) => {
  if (error === 'NO_PLAYABLE_SOURCE_FOUND') return 'Error: No playable source found'
  return `Error: ${error}`
}

export const formatTime = (time: number) => {
  const hours = Math.floor(time / 3600)
  const hoursStr = (hours >= 10) ? '' + hours : '0' + hours
  const minutes = Math.floor((time % 3600) / 60)
  const minutesStr = (minutes >= 10) ? '' + minutes : '0' + minutes
  const seconds = Math.floor(time % 60)
  const secondsStr = (seconds >= 10) ? '' + seconds : '0' + seconds
  return hoursStr + ':' + minutesStr + ':' + secondsStr
}

export const getProgramError = (program: Program, passthrough: boolean): string | undefined => {
  const abrUrl = program.getSLDPOutputURL(false)
  const passthroughUrl = program.getSLDPOutputURL(true)
  if (!passthrough && !abrUrl) {
    return 'ABR stream not available'
  }
  if (passthrough && !passthroughUrl) {
    return 'Passthrough stream not available'
  }
  if (!abrUrl && !passthroughUrl) {
    return 'No stream available'
  }
}

export const getResolutionText = (resolution: PlayerResolution, abr: boolean, passthrough: boolean): string => {
  if (passthrough) return 'Passthrough'
  if (abr) return `Up to ${resolution}`
  return resolution
}

export const getStatusMessage = (status: ProgramWatchdogStatus): string => {
  switch (status) {
    case ProgramWatchdogStatus.Connecting:
      return 'Connecting...'
    case ProgramWatchdogStatus.Playing:
      return 'Playing'
    case ProgramWatchdogStatus.Reconnecting:
      return 'Reconnecting...'
    case ProgramWatchdogStatus.Restarting:
      return 'Restarting...'
    default:
      return ''
  }
}

export const parseAdvancedParameters = (parameters: string): {} => {
  try {
    return JSON.parse(parameters)
  } catch {
    return {}
  }
}
