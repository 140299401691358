import React from 'react'

import { withUserContext, IUserMultiContext, withSiteAdminContext, ISiteAdminMultiContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import { Company } from 'src/core/models'

import ArkAdminPage from 'src/admin/components/ArkAdminPage/ArkAdminPage'
import AdminCompanySidebar from './AdminCompanySidebar'
import CompanyForm, { CompanyFormMode } from './CompanyForm'

import AdminCompanyListItem from './AdminCompanyListItem'

import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkManagerListView, { ArkManagerFilteredItem } from 'src/core/components/ArkManagerListView/ArkManagerListView'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkModal from 'src/core/components/ArkModal'
import { OBJECT_COMPANY_NAME, OBJECT_COMPANY_NAME_PLURAL, SECTION_ADMIN_NAME } from 'src/constants/strings'

interface IProps extends IUserMultiContext, ISiteAdminMultiContext {
}
interface IState {
  loading: boolean
  companies: Array<Company>
  filteredCompanies?: Array<ArkManagerFilteredItem<Company>>
  filter?: string
  selectedCompany?: Company
  editCompany?: Company
  showAddEditModal: boolean
}

class AdminCompaniesPage extends React.Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      loading: false,
      companies: [],
      filteredCompanies: undefined,
      filter: undefined,
      showAddEditModal: false
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.loadCompanies()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { loading, companies, selectedCompany, filter, filteredCompanies } = this.state

    // TODO: PORT if/once onChange support is added to the sidebar (if we add support of editing companies directly via the sidebar instead of just the edit modal)
    // onChange={async () => {
    //   // trigger a company users data re-load so the updated user values show
    //   await this.loadCompanyUsers()
    //   // trigger the sidepanel to update with the updated user object
    //   if (this.state.selectedUser && this.state.users) {
    //     const newUser = this.state.users.find((user) => user.id === this.state.selectedUser?.id)
    //     this.setState({ selectedUser: newUser })
    //   }
    // }}

    const rightSidebarComponent = selectedCompany && (
      <AdminCompanySidebar
        company={selectedCompany}
        onEdit={this.onEdit}
        onDidDelete={this.onDidDelete}
      />
    )

    return (
      <ArkAdminPage
        onRightSidebarClose={() => this.setState({ selectedCompany: undefined })}
        rightSidebar={rightSidebarComponent}
      >
        <ArkManagerContentView
          title={OBJECT_COMPANY_NAME_PLURAL}
          breadcrumb={[{
            path: ROUTES.ADMIN,
            title: SECTION_ADMIN_NAME
          }]}
        >
          <ArkManagerListView
            loading={loading}
            items={companies}
            selectedItem={selectedCompany}
            itemRow={(company: Company, isSelected: boolean) => {
              return this.renderCompanyTableRowContent(company, isSelected, filter)
            }}
            topbarAddItemTitle={'CREATE ' + OBJECT_COMPANY_NAME}
            onAdd={() => this.showAddModal()}
            // FILTERING:
            filter={filter}
            filteredItems={filteredCompanies}
            filterForm={this.renderCompanyFilterForm()}
            onClearFilter={() => this.filterCompanies('') }
          />
          {this.renderCompanyFormModal()}
        </ArkManagerContentView>
      </ArkAdminPage>)
  }

  // -------

  renderCompanyTableRowContent = (company: Company, isSelected: boolean, filter?: string) => {
    return (
      <AdminCompanyListItem
        active={isSelected}
        company={company}
        filter={filter}
        key={company.id}
        onClick={() => this.selectCompany(company)}
        // onEditClick={() => this.showEditCompanyModal(company)}
      />
    )
  }

  // -------

  renderCompanyFilterForm = () => {
    const { filter } = this.state
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        filterTitle='Filter by name'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          this.filterCompanies(filter)
        }}
      />
    )
  }

  // -------

  filterCompanies = (_filter: string) => {
    const { loading, companies, selectedCompany } = this.state
    if (loading) return
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredCompanies = filter
      ? companies.reduce<Array<ArkManagerFilteredItem<Company>>>((r, company) => {
        let nameMatch = false
        if (company.name.toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        if (nameMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          const filteredUser: ArkManagerFilteredItem<Company> = {
            item: company,
            matchingFields
          }
          r.push(filteredUser)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<Company>>)
      : undefined
    if (selectedCompany && (!(filteredCompanies?.find((filteredCompany) => filteredCompany.item.id === selectedCompany.id)))) {
      this.selectCompany(undefined) // if an item was selected but isn't in the filtered list deselect them
    }
    this.setState({ filter, filteredCompanies })
  }

  clearFilteredGroups = () => {
    this.setState({ filter: undefined, filteredCompanies: undefined })
  }

  // -------

  selectCompany = (company?: Company) => {
    this.setState({ selectedCompany: company })
  }

  // -------

  loadCompanies = async () => {
    if (this.state.loading === true) return false
    if (!this._isMounted) return false
    try {
      this.setState({ loading: true })
      const companies = await this.props.siteAdminContext.actions.getAllCompanies()
      if (this._isMounted) {
        this.setState({
          loading: false,
          companies: companies || []
        })
        // TESTING: update the selectedCompany whenever the company is reloaded (incase its been edited)
        if (this.state.selectedCompany) {
          const _company = companies?.find(c => c.id === this.state.selectedCompany?.id)
          this.setState({ selectedCompany: _company })
        }
      }
    } catch (error) {
      console.error('AdminCompaniesPage - loadUsers - error: ', error)
      if (this._isMounted) {
        this.setState({
          loading: false,
          companies: []
          // TODO: add an error prop & display an error message if this happens
        })
      }
    }
  }

  // -------

  onEdit = (_company: Company) => {
    this.showEditModal(_company)
  }

  onDidDelete = (_company: Company) => {
    // trigger a data re-load so the deleted company no longer shows
    this.loadCompanies()
    this.selectCompany(undefined)
  }

  // -------

  showAddModal = () => {
    this.setState({ showAddEditModal: true })
  }

  showEditModal = (company: Company) => {
    this.setState({ showAddEditModal: true, editCompany: company })
  }

  // call directly to initiate a modal callback (used when the user clicks custom buttons to close the modal instead of the modals own 'x' button etc.)
  hideAddEditModal = () => {
    // NB: DON'T clear/reset `editCompany` here, see `didHideCompanyModal` which is called once the modal has actually closed & we can safely reset it then
    // NB: this stops a saved edit form flipping the 'updated' success text to 'created' briefly while the modal closes if we reset it straight away here
    // NB: & also stops the form title flipping from edit to add as it closes via the cancel button
    this.setState({ showAddEditModal: false }) // , editCompany: undefined
  }

  // triggered via modal callbacks once the modal has already closed
  didHideAddEditModal = () => {
    this.setState({ showAddEditModal: false, editCompany: undefined })
  }

  // -------

  renderCompanyFormModal = () => {
    return (
      <ArkModal open={this.state.showAddEditModal} onClose={() => this.didHideAddEditModal()}>
        {this.renderCompanyForm(this.state.editCompany)}
      </ArkModal>
    )
  }

  // -------

  renderCompanyForm = (company?: Company) => {
    return (
      <CompanyForm
        mode={company ? CompanyFormMode.Edit : CompanyFormMode.Add}
        company={company}
        onCancel={() => { this.hideAddEditModal() }}
        onSave={() => {
          // trigger a companies data re-load to show the newly added company
          this.loadCompanies()
        }}
        // onDelete={() => {
        //   // trigger a data re-load so the deleted company no longer shows
        //   this.loadCompanies()
        // }}
        onClose={() => { this.hideAddEditModal() }}
        insideModal={true}
      />
    )
  }
}
export default withSiteAdminContext(withUserContext(AdminCompaniesPage))
