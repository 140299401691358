import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Buffer } from 'buffer'

import { QUICKLINK_DEBUG_DELAY, QUICKLINK_DEBUG_MODE } from 'src/constants/config'

import { AuthContext, AuthStatus, NavContext, ServerContext, UserContext, UserStatus } from 'src/core/providers'

import ArkButton from 'src/core/components/ArkButton'
import ArkCenterLayout from 'src/core/components/ArkCenterLayout'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkPage from 'src/core/components/ArkPage/ArkPage'

import * as ROUTES from 'src/constants/routes'
import { OBJECT_COMPANY_NAME, OBJECT_PROJECT_NAME } from 'src/constants/strings'

import { ButtonProps, Message } from 'semantic-ui-react'

import styles from './QuickviewPage.module.css'
import ArkSegment from 'src/core/components/ArkSegment'

// a custom hook that builds on useLocation to parse the query string
// ref: https://v5.reactrouter.com/web/example/query-parameters
const useURLQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

interface QuickviewPageProps {}

const QuickviewPage = (_props: QuickviewPageProps) => {
  const authContext = useContext(AuthContext)
  const userContext = useContext(UserContext)
  const navContext = useContext(NavContext)
  const serverContext = useContext(ServerContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [loginError, setLoginError] = useState<Error | undefined>(undefined)
  const [loginWarning, setLoginWarning] = useState<Error | undefined>(undefined)
  const [canAccessLink, setCanAccessLink] = useState<boolean>(false) // true if current (non-guest) user has access to the linked company/project (& potentially channel)
  const [authStatus, setAuthStatus] = useState<AuthStatus | undefined>(authContext.store.authStatus) // NB: keep a local state var to track the auth status, so changes force a re-render & so update all vars & UI

  const [showLogoutNotice, setShowLogoutNotice] = useState<boolean>(false)
  const [showChooseAccessNotice, setShowChooseAccessNotice] = useState<boolean>(false)
  const [waiting, setWaiting] = useState<boolean>(false)

  // example quickview url: http://localhost:3000/quickview?env=staging&guest_key=abc123&org_id=1&project_id=1

  // parse url query args
  const urlQuery = useURLQuery()
  const guestKey = urlQuery.get('guest_key') ?? undefined
  const guestLoginData = guestKey ? Buffer.from(guestKey, 'base64') : undefined
  const guestLoginDataSplit = guestLoginData ? guestLoginData.toString().split(':') : undefined
  const guestLoginUsername = guestLoginDataSplit && guestLoginDataSplit.length === 2 ? guestLoginDataSplit[0] : undefined
  const guestLoginPass = guestLoginDataSplit && guestLoginDataSplit.length === 2 ? guestLoginDataSplit[1] : undefined
  const guestKeyError = (!guestLoginUsername || !guestLoginPass) ? Error('Invalid guest credentials supplied') : undefined

  const guestCompanyId = urlQuery.has('org_id') && urlQuery.get('org_id') ? parseInt(urlQuery.get('org_id')!) : undefined
  const guestProjectId = urlQuery.has('project_id') && urlQuery.get('project_id') ? parseInt(urlQuery.get('project_id')!) : undefined
  const guestChannelId = urlQuery.has('channel_id') && urlQuery.get('channel_id') ? parseInt(urlQuery.get('channel_id')!) : undefined
  // TODO: flag an error if either aren't set/available (before or after login? maybe allow the login first & then show an error?)

  const isLoggedOut = userContext.store.userStatus === UserStatus.loggedOut && !userContext.store.user
  const alreadyLoggedInAsGuest = guestLoginUsername && !isLoggedOut && userContext.store.user?.email === guestLoginUsername
  const alreadyLoggedInUser = !isLoggedOut && userContext.store.user?.email !== guestLoginUsername
  const canLogin = isLoggedOut && !guestKeyError

  // -------

  useEffect(() => {
    console.log('QuickviewPage - useEffect - authStatus:', authContext.store.authStatus ? AuthStatus[authContext.store.authStatus] : authContext.store.authStatus)
    setAuthStatus(authContext.store.authStatus)
    // TOOD: act on login?
    // if (authContext.store.authStatus === AuthStatus.loggedIn) {
    // }
  }, [authContext.store.authStatus])

  // NB: not needed anymore - TODO: DEPRECIATE/remove once all handling is confirmed working as expected
  // useEffect(() => {
  //   console.log('QuickviewPage - useEffect - userStatus:', UserStatus[userContext.store.userStatus])
  //   // TOOD: act on login?
  //   // if (userContext.store.userStatus === UserStatus.loggedIn) {
  //   // }
  //   // TODO: act on logout IF we've just logged out (use a state var to track we should run something here)...
  //   if (userContext.store.userStatus === UserStatus.loggedOut) {
  //     console.log('QuickviewPage - useEffect - userStatus - logged out...')
  //     console.log('QuickviewPage - useEffect - userStatus - logged out - canLogin:', canLogin, ' guestLoginUsername:', guestLoginUsername, ' guestLoginPass:', guestLoginPass)
  //     async function runAsync () {
  //       if (QUICKLINK_DEBUG_DELAY) {
  //         setWaiting(true)
  //         await new Promise((resolve) => setTimeout(resolve, 1000 * QUICKLINK_DEBUG_DELAY)) // DEBUG ONLY: add a delay before triggering the action (to be able to clearly see the UI while in this mid-way state)
  //       }
  //       onLoginClick()
  //     }
  //     runAsync()
  //   }
  // }, [userContext.store.userStatus])

  // -------

  const onLogin = async (skipLoginCheck: boolean = false) => {
    console.log('QuickviewPage - onLogin - skipLoginCheck:', skipLoginCheck)
    // TESTING: as this can now be called in a callback, the component vars maybe stale, so re-check them here
    // TODO: should/can we also check the `guestKeyError` here as well, skipping it for now, as it should remain the same while on this page
    // UPDATE: the userContext store seems to be cached as well? (TODO: could try accessing via helper functions within the provider instead?)
    // UPDATE: ..added `skipLoginCheck` & using that when called via the logout callback for now instead
    // const _isLoggedOut = userContext.store.userStatus === UserStatus.loggedOut && !userContext.store.user
    // const _canLogin = _isLoggedOut && !guestKeyError
    // console.log('QuickviewPage - onLoginClick - _canLogin:', _canLogin, ' _isLoggedOut:', _isLoggedOut, ' userContext.store.userStatus:', userContext.store.userStatus, ' userContext.store.user:', userContext.store.user)
    if ((skipLoginCheck || canLogin) && guestLoginUsername && guestLoginPass) {
      setWaiting(true)
      if (QUICKLINK_DEBUG_DELAY) {
        await new Promise((resolve) => setTimeout(resolve, 1000 * QUICKLINK_DEBUG_DELAY)) // DEBUG ONLY: add a delay before triggering the action (to be able to clearly see the UI while in this mid-way state)
      }
      await new Promise((resolve) => setTimeout(resolve, 2000)) // DEBUG ONLY <<<<
      // NB: this does NOT throw on error - instead it updates the `AuthContext` `authError` store state var instead
      const loginResult = await authContext.actions.loginWithEmailAndPassword(guestLoginUsername, guestLoginPass)
      console.log('QuickviewPage - onLoginClick - loginResult:', loginResult)
    }
  }
  const onLoginClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps, skipLoginCheck: boolean = false) => {
    console.log('QuickviewPage - onLoginClick - skipLoginCheck:', skipLoginCheck, ' canLogin:', canLogin, ' guestLoginUsername:', guestLoginUsername, ' guestLoginPass:', guestLoginPass)
    onLogin(skipLoginCheck)
  }

  const onLogoutClick = async () => {
    console.log('QuickviewPage - onLogoutClick - isLoggedOut:', isLoggedOut)
    if (!isLoggedOut) {
      setWaiting(true)
      if (QUICKLINK_DEBUG_DELAY) {
        await new Promise((resolve) => setTimeout(resolve, 1000 * QUICKLINK_DEBUG_DELAY)) // DEBUG ONLY: add a delay before triggering the action (to be able to clearly see the UI while in this mid-way state)
      }
      await authContext.actions.logout(() => {
        console.log('QuickviewPage - onLogoutClick - logged out')
        onLogin(true)
      })
    }
  }

  const onRedirectClick = async () => {
    console.log('QuickviewPage - onRedirectClick - guestCompanyId:', guestCompanyId, ' guestProjectId:', guestProjectId, ' guestChannelId:', guestChannelId)
    if (alreadyLoggedInAsGuest || alreadyLoggedInUser) {
      if (guestCompanyId) {
        setWaiting(true)
        if (QUICKLINK_DEBUG_DELAY) {
          await new Promise((resolve) => setTimeout(resolve, 1000 * QUICKLINK_DEBUG_DELAY)) // DEBUG ONLY: add a delay before triggering the action (to be able to clearly see the UI while in this mid-way state)
        }
        await userContext.actions.selectCompany(guestCompanyId, false)
        let path = ROUTES.VIEWER
        if (QUICKLINK_DEBUG_DELAY) {
          await new Promise((resolve) => setTimeout(resolve, 1000 * QUICKLINK_DEBUG_DELAY)) // DEBUG ONLY: add a delay before triggering the action (to be able to clearly see the UI while in this mid-way state)
        }
        if (guestProjectId && !guestChannelId) {
          await userContext.actions.selectProject(guestProjectId, false)
          path = ROUTES.VIEW_PROJECT.replace(':projectId', guestProjectId ? '' + guestProjectId : '')
        } else if (guestProjectId && guestChannelId) {
          await userContext.actions.selectProject(guestProjectId, false)
          await userContext.actions.selectChannel(guestChannelId, false)
          path = ROUTES.VIEW_PROJECT_CHANNEL.replace(':projectId', guestProjectId ? '' + guestProjectId : '').replace(':channelId', guestChannelId ? '' + guestChannelId : '')
        }
        navContext.actions.goto(path)
      }
    }
    // console.log('QuickviewPage - onRedirectClick - done')
  }

  const onCancelClick = async () => {
    navContext.actions.goto(ROUTES.HOME)
  }

  // -------

  const run = async () => {
    console.log('QuickviewPage - run')
    if (loaded || loading) return
    setLoading(true)

    // show the loading screen for a minimum amount of time (otherwise you just see a single frame flash of the UI)
    // NB: could otherwise hide the quickview title & maybe show a loading spinner by default instead?
    await new Promise((resolve) => setTimeout(resolve, 500))

    // let _canAccessLink = false
    let _canAccessProject = false
    const _autoRedirectToProject = false // TODO: make use of this below (flip it to a `let` var when we do)
    let _loginError: Error | undefined
    let _loginWarning: Error | undefined

    // non-production server selection (only supported on non-production builds!)
    // on non-production builds the server provider has a list of available servers that can be switched between (skip/ignore if they're not)
    if (serverContext.store.servers) {
      const guestEnv = urlQuery.get('env') ?? undefined
      console.log('QuickviewPage - guestEnv:', guestEnv, ' typeof:', typeof guestEnv)
      if (guestEnv) {
        console.log('QuickviewPage - serverContext.store.servers:', serverContext.store.servers)
        if (serverContext.store.servers[guestEnv]) {
          serverContext.actions.selectServer(guestEnv)
        } else {
          console.log('QuickviewPage - WARNING: env arg specified but invalid env value:', guestEnv)
          // TODO: do we show an error of some sort here & halt, or just try the rest of the guest link handling & see if it works?
        }
      }
    }

    // TESTING: if a user is already logged in, check if they already have viewer access to the requested company/project/channel without the guest link
    if (userContext.store.userStatus === UserStatus.loggedIn && userContext.store.user) {
      console.log('QuickviewPage - run - USER ALREADY LOGGED IN:', userContext.store.user)

      // TESTING: `User` model `projectViewLookup` based company & project viewer access check
      // NB: we can't check channel access here as the `projectViewLookup` doesn't include channel access, only company & project
      // TODO: is there any benefit to also checking `projectAdminLookup` access as a semi-fallback, or as thats only for admin not viewer access, should we just ignore it? (currently skipping it)
      if (guestCompanyId !== undefined && guestProjectId !== undefined) {
        const companyProjectViewLookup = userContext.store.user.projectViewLookup?.get(guestCompanyId)
        if (companyProjectViewLookup) {
          if (companyProjectViewLookup.includes(guestProjectId)) {
            // TODO: how should we handle the optional channel id here?
            // TODO: ..the user might not have access to the specific channel, only other channels within the project (or potentially no channels?)
            // TODO: ..should we offer them the option to choose to use current user or guest user rather than auto redirecting?
            // TODO: ..so worst case they could always reload the guest link & flip to the guest user if they find they don't have access for now,
            // TODO: ..if we decide not to try & lookup the channel access here? (& channel ids are purely optional for guest links)
            _canAccessProject = true
            setCanAccessLink(true)
          }
        }
      }
    }
    console.log('QuickviewPage - run - _canAccessProject:', _canAccessProject)

    if (guestKeyError) {
      _loginError = guestKeyError
    } else if (!guestCompanyId) {
      _loginWarning = Error('Link error - no ' + OBJECT_COMPANY_NAME + ' specified')
    } else if (!guestProjectId) {
      _loginWarning = Error('Link error - no ' + OBJECT_PROJECT_NAME + ' specified')
    }

    const authError = authContext.store.authError
    if (authError) {
      _loginError = authError
    }

    if (!_loginError && !_loginWarning) {
      // if (!isLoggedOut && !alreadyLoggedInAsGuest && !_canAccessProject) {
      //   // setLoginWarning(Error('Already logged in as different user. Please logout first before using this link.'))
      //   setShowLogoutNotice(true)
      // }
      if (alreadyLoggedInUser && _canAccessProject) {
        // prompt the user to select between their current logged in user or the guest link access, both have access to the project
        // but the logged in user might not have the same access to all/specific channels within the project, so we offer a choice
        setShowChooseAccessNotice(true)
      } else if (alreadyLoggedInUser && !_canAccessProject) {
        // prompt the logged in user to logout & use the guest link instead
        setShowLogoutNotice(true)
      } else {
        // NB: if the user is not logged in currently, or is already logged in as the guest user, we can proceed (handled further down)
        // TODO: should we also show UI/buttons to trigger the auto handling manually as a fallback?
        // _autoRedirectToProject = true // TESTING HERE: is this needed?
      }
    }

    setLoaded(true)
    setLoading(false)

    if (_loginError) setLoginError(_loginError)
    if (_loginWarning) setLoginWarning(_loginWarning)

    if (!QUICKLINK_DEBUG_MODE) {
      if (canLogin) onLogin()
      if (alreadyLoggedInAsGuest || _autoRedirectToProject) onRedirectClick()
    }
  }

  // run on component mount
  useEffect(() => {
    if (!loaded && !loading) {
      run()
    }
  }, [loading, loaded])

  // -------

  const renderError = (error: Error) => {
    return (
      <Message negative>
        <Message.Header>Error</Message.Header>
        <p>{error.message}</p>
      </Message>
    )
  }

  const renderWarning = (error: Error) => {
    return (
      <Message warning>
        <Message.Header>Error</Message.Header>
        <p>{error.message}</p>
      </Message>
    )
  }

  const renderDebugUI = () => {
    const authStatusTitle = (status?: AuthStatus) => status ? AuthStatus[status] : 'N/A'
    return (
      <div className={styles.dbg}>
        {/* <h1>Quickview (DEBUG MODE)</h1> */}

        <h3>URL Args:</h3>
        <ul className={styles.values}>
          <li><span className={styles.title}>env:</span>{urlQuery.get('env')}</li>
          <li><span className={styles.title}>guest_key:</span>{urlQuery.get('guest_key')}</li>
          <li><span className={styles.title}>org_id:</span>{urlQuery.get('org_id')}</li>
          <li><span className={styles.title}>project_id:</span>{urlQuery.get('project_id')}</li>
        </ul>

        <h3>Login Status &amp; Args:</h3>
        <ul className={styles.values}>
          <li><span className={styles.title}>current auth:</span>{isLoggedOut ? 'LOGGED OUT' : 'LOGGED IN'}</li>
          <li><span className={styles.title}>auth status (local state):</span>{authStatusTitle(authStatus)}</li>
          <li><span className={styles.title}>auth status (provider):</span>{authStatusTitle(authStatus)}</li>
          <li><span className={styles.title}>logged in as the guest:</span>{alreadyLoggedInAsGuest ? 'YES' : 'NO'}</li>
          <li><span className={styles.title}>can login:</span>{canLogin ? 'YES' : 'NO'}</li>
          <li><span className={styles.title}>guest user:</span>{guestLoginUsername}</li>
          <li><span className={styles.title}>guest pass:</span>{guestLoginPass}</li>
        </ul>

        {alreadyLoggedInUser && (
          <>
            <h3>Existing User Status:</h3>
            <ul className={styles.values}>
              <li><span className={styles.title}>current user:</span>{userContext.store.user?.email ?? '-'}</li>
              <li><span className={styles.title}>can access link:</span>{canAccessLink ? 'YES' : 'NO'}</li>
            </ul>
          </>
        )}

        <h3>Action/Result:</h3>
        {canLogin && (<ArkButton onClick={onLoginClick}>LOGIN AS GUEST</ArkButton>)}
        {(alreadyLoggedInAsGuest || canAccessLink) && (<ArkButton onClick={onRedirectClick}>GOTO DESTINATION</ArkButton>)}
      </div>
    )
  }

  const busy = loading || waiting
  return (
    <ArkPage>
      <ArkCenterLayout>
        <ArkSegment
          inverted
          className={styles.quickview}
          data-test-id="ark-quickview-view" // e2e testing identifier
        >
          <h1>Quickview</h1>
          {QUICKLINK_DEBUG_MODE && (renderDebugUI())}
          {loginError && (renderError(loginError))}
          {loginWarning && (renderWarning(loginWarning))}
          {showLogoutNotice && (
            <Message
              warning
              data-test-id="ark-quickview-switch-to-guest-msg" // e2e testing identifier
            >
              <Message.Header>Switch User?</Message.Header>
              <p>You are already logged in with a different user.</p>
              <p>You don&apos;t currently have viewer access to the linked project.</p>
              <p>Do you want to switch to the guest user?</p>
              <ArkButton
                onClick={onCancelClick}
                disabled={busy}
                data-test-id="ark-quickview-cancel-btn" // e2e testing identifier
              >CANCEL</ArkButton>
              <ArkButton
                onClick={onLogoutClick}
                disabled={busy}
                data-test-id="ark-quickview-guest-user-btn" // e2e testing identifier
              >GUEST USER</ArkButton>
            </Message>
          )}
          {showChooseAccessNotice && (
            <Message
              warning
              data-test-id="ark-quickview-switch-user-choice-msg" // e2e testing identifier
            >
              <Message.Header>Switch User?</Message.Header>
              <p>You are already logged in with a different user.</p>
              <p>You have viewer access to the linked project, but may not have access to the same channels &amp; programs as the guest user.</p>
              <p>Do you want to view as your current user or switch to the guest user?</p>
              <ArkButton
                onClick={onRedirectClick}
                disabled={busy}
                data-test-id="ark-quickview-current-user-btn" // e2e testing identifier
              >CURRENT USER</ArkButton>
              <ArkButton
                onClick={onLogoutClick}
                disabled={busy}
                data-test-id="ark-quickview-guest-user-btn" // e2e testing identifier
              >GUEST USER</ArkButton>
            </Message>
          )}
          {busy && (<ArkLoader message='Loading' />)}
        </ArkSegment>
      </ArkCenterLayout>
    </ArkPage>
  )
}

export default QuickviewPage
