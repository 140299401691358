/**
 * ArkTruncatedText
 * - for special text rendering with support for max length & hover popup with the full length version
 */
import React, { useState } from 'react'

import ArkHighlightedText from 'src/core/components/ArkHighlightedText'

import styles from './ArkTruncatedText.module.css'

export interface ArkTruncatedTextProps {
  children?: string // NB: requiring children to be a string/text so we can optionally truncate it (not a regular child React.ReactNode element)
  maxLength?: number
  subject?: string // what this text displays (e.g: 'Email'), used in the copy success message e.g: 'Email copied' (if not set it'll show a generic 'copied' type text instead)
  highlight?: string // optionally highlight certain chars if they're not truncated (for use when filtering/searching)
}

const ArkTruncatedText = (props: ArkTruncatedTextProps) => {
  const { children, maxLength, subject, highlight } = props
  if (!children) return null

  const [showPopup, setShowPopup] = useState<boolean>()
  const [hasCopied, setHasCopied] = useState<boolean>()

  const textLength = children?.length ?? 0
  const truncate = maxLength !== undefined && textLength > maxLength

  // NB: only focusing on chrome support for this currently, will need to extend to support fallbacks for some other browsers
  // ref: https://stackoverflow.com/a/30810322
  const copyToClipboard = async (text: string) => {
    if (!navigator.clipboard) {
      // TODO: add fallback copy browser support here if we want to support other browsers that don't support navigator.clipboard (see the SO ref above for more details)
      return false
    }
    try {
      await navigator.clipboard.writeText(text)
      return true
    } catch (err) {
      console.error('ArkTruncatedText - copyToClipboard - err: ', err)
      return false
    }
  }

  const onHoverStart = async () => {
    if (truncate) setShowPopup(true)
    setHasCopied(false)
  }

  const onHoverEnd = async () => {
    setShowPopup(false)
    setHasCopied(false)
  }

  const onCopy = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    const copyResult = await copyToClipboard(children)
    if (copyResult) {
      setHasCopied(true)
      if (!showPopup) setShowPopup(true)
    } else {
      setHasCopied(false)
    }
  }

  return (
    <span className={styles.text + (truncate ? ' ' + styles.showPopup : '')} onMouseEnter={onHoverStart} onMouseLeave={onHoverEnd}>
      {truncate && (
        <span className={styles.innerText} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onCopy(event)}>
          {/* FIXME: what about cropped text that would be highlighted? highlight the first x chars before the crop/truncate? */}
          <ArkHighlightedText highlight={highlight}>{children.substring(0, maxLength) + '...'}</ArkHighlightedText>
        </span>)}
      {showPopup && (
        <span className={styles.popup} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onCopy(event)}>
          {truncate && (children)}
          {hasCopied && (<span className={styles.copied}>{subject ? subject + ' copied to clipboard' : 'Copied to clipboard' }</span>)}
        </span>
      )}
      {!truncate && (
        <span className={styles.innerText} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onCopy(event)}>
          <ArkHighlightedText highlight={highlight}>{children}</ArkHighlightedText>
        </span>)}
    </span>
  )
}
export default ArkTruncatedText
