import React from 'react'
import QRCode from 'qrcode.react'

import styles from './User2FAManageView.module.css'

interface IProps {
  secretOTPAuthUrl: string
  secretBase32: string
}

const User2FAGenerateView = (props: IProps) => {
  const { secretOTPAuthUrl, secretBase32 } = props
  return (
    <div className={styles.generate2FA}>
      <h3>Use your authenticator app with this QR Code:</h3>
      <div className={styles.generate2FAQR}>
        <QRCode value={secretOTPAuthUrl ?? ''} size={150} />
      </div>
      <h3>Or enter set up key:</h3>
      <p className={styles.generate2FAKey}>{secretBase32}</p>
    </div>
  )
}
export default User2FAGenerateView
