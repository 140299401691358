import { io, Socket } from 'socket.io-client'
import { APIServerType } from 'src/constants/server_types'

export enum StreamhubSocketStatus {
  inital = 'inital',
  connecting = 'connecting',
  connected = 'connected',
  disconnected = 'disconnected',
  error = 'error'
}

export type StreamhubSocketStatusChangeCallback = (socketStatus: StreamhubSocketStatus) => void

export type StreamhubSocketListenerCallback = (...args: any[]) => void

class StreamhubSocketClient {
  public socketUrl: string
  public authToken: string | null = null
  public authDeviceUUID: string | null = null
  public apiServerType: APIServerType | null = null
  public onStatusChange?: StreamhubSocketStatusChangeCallback

  private socket?: Socket
  public socketStatus: StreamhubSocketStatus
  private listeners: Map<string, StreamhubSocketListenerCallback>

  constructor (socketUrl: string, authToken?: string, authDeviceUUID?: string, apiServerType?: APIServerType, onStatusChange?: StreamhubSocketStatusChangeCallback) {
    this.socketUrl = socketUrl
    this.authToken = authToken ?? null
    this.authDeviceUUID = authDeviceUUID ?? null
    this.apiServerType = apiServerType ?? null
    this.onStatusChange = onStatusChange
    this.socketStatus = StreamhubSocketStatus.inital
    this.listeners = new Map<string, StreamhubSocketListenerCallback>()
  }

  _updateSocketStatus = (newStatus: StreamhubSocketStatus) => {
    this.socketStatus = newStatus
    if (this.onStatusChange) this.onStatusChange(newStatus)
  }

  startSocket = () => {
    if (!this.socketUrl) return
    console.log('StreamhubSocketClient - startSocket')
    this._updateSocketStatus(StreamhubSocketStatus.connecting)
    this.socket = io(this.socketUrl, {
      auth: {
        token: this.authToken,
        deviceUUID: this.authDeviceUUID,
        ...(this.apiServerType ? { server: this.apiServerType } : {})
      }
    })
    this._socketListeners()
  }

  stopSocket = () => {
    if (!this.socket) return
    console.log('StreamhubSocketClient - stopSocket')
    this.socket.disconnect()
  }

  connect = () => {
    if (!this.socket) return
    console.log('StreamhubSocketClient - connect')
    this.socket.connect()
  }

  disconnect = () => {
    if (!this.socket) return
    console.log('StreamhubSocketClient - disconnect')
    this.socket.disconnect()
  }

  updateAuthToken = (authToken: string | null) => {
    console.log('StreamhubSocketClient - updateAuthToken')
    if (this.socket) (this.socket.auth as any).token = authToken
  }

  // TODO: allow more than one class/caller to register for the same listener key?? <<<
  addSocketListener = (key: string, listener: (...args: any[]) => void) => {
    if (!this.socket) return
    if (this.listeners.get(key) !== undefined) return
    this.listeners.set(key, listener)
    this.socket.on(key, listener)
  }

  removeSocketListener = (key: string) => {
    if (this.listeners.get(key) === undefined) return
    if (this.socket) this.socket.removeListener(key, this.listeners.get(key))
    this.listeners.delete(key)
  }

  _socketListeners = () => {
    if (!this.socket) return
    const socket = this.socket

    socket.on('connect_error', (err) => {
      console.log('StreamhubSocketClient - _socketListeners - connect_error - err instanceof Error: ', err instanceof Error) // true
      console.log('StreamhubSocketClient - _socketListeners - connect_error - err.message: ', err.message) // not authorized
      console.log('StreamhubSocketClient - _socketListeners - connect_error - err.data: ', (err as any).data) // { content: "Please retry later" }
      this._updateSocketStatus(StreamhubSocketStatus.error)
    })

    socket.on('connect', () => {
      console.log('StreamhubSocketClient - _socketListeners - connect - socket.id: ', socket.id) // x8WIv7-mJelg7on_ALbx
      this._updateSocketStatus(StreamhubSocketStatus.connected)
      // socket.emit('chat message', 'hello')
    })

    socket.on('disconnect', () => {
      console.log('StreamhubSocketClient - _socketListeners - disconnect')
      this._updateSocketStatus(StreamhubSocketStatus.disconnected)
    })

    // TODO: DEPRECIATE
    // socket.on('chat message', (msg: any) => {
    //   console.log('StreamhubSocketClient - socketListeners - chat message: ' + msg)
    // })
  }
}

export default StreamhubSocketClient
export { StreamhubSocketClient }
