/**
 * ArkResponsive
 */

import React, { ReactNode, useContext } from 'react'

import { ResponsiveContext } from '../../providers/ResponsiveProvider'

export interface ArkResponsiveProps {
  children: ReactNode
  desktopOnly?: boolean
  mobileOnly?: boolean
}

const ArkResponsive = (props: ArkResponsiveProps) => {
  const { children, desktopOnly, mobileOnly } = props

  const { store } = useContext(ResponsiveContext)
  const { isDesktop, isMobile } = store

  if (desktopOnly && !isDesktop) return null
  if (mobileOnly && !isMobile) return null

  return <>{children}</>
}

export default ArkResponsive
