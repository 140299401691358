/**
 * ArkMessage
 */

import React from 'react'

import { Message, MessageProps } from 'semantic-ui-react'

import styles from './ArkMessage.module.css'

export interface ArkMessageProps extends MessageProps {}

// TESTING sub-components (dot notation) - ref: https://skovy.dev/using-component-dot-notation-with-typescript-to-create-a-set-of-components/
interface ArkMessageSubComponents {
  Content: typeof Message.Content
  Header: typeof Message.Header
  List: typeof Message.List
  Item: typeof Message.Item
}

const ArkMessage: React.FC<ArkMessageProps> & ArkMessageSubComponents = (props: ArkMessageProps) => {
  const { className, children } = props
  return (
    <Message
      {...props}
      className={`${styles.message} ${className || ''}`}
    >{children}</Message>
  )
}

export default ArkMessage

ArkMessage.Content = Message.Content
ArkMessage.Header = Message.Header
ArkMessage.List = Message.List
ArkMessage.Item = Message.Item
