import React from 'react'

import UserEditNameForm from './UserEditNameForm'

interface IProps {
  onCancel?: Function
}

const UserEditNameView = ({ onCancel, ..._props }: IProps) => {
  return (
    <UserEditNameForm
      onCancel={onCancel}
    />
  )
}

export default UserEditNameView
