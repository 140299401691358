/**
 * useDebounceEffect hook
 */

import { DependencyList, EffectCallback, useEffect } from 'react'

export const useDebounceEffect = (effect: EffectCallback, deps: DependencyList, ms: number) => useEffect(() => {
  const handler = setTimeout(() => effect(), ms)
  return () => clearTimeout(handler)
}, deps)
