/**
 * GlobalConfigProvider
 */

import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import { DefaultGlobalConfig, GlobalConfigProps, loadGlobalConfig } from 'src/constants/global-config'

interface IGlobalConfigActions {
}

interface IGlobalConfigStore {
  loading: boolean
  config: GlobalConfigProps
}

export interface IGlobalConfigContext {
  actions: IGlobalConfigActions
  store: IGlobalConfigStore
}

interface GlobalConfigProviderProps {
  children: ReactNode
}

export interface IGlobalConfigMultiContext {
  configContext: IGlobalConfigContext
}

export const GlobalConfigContext = createContext<IGlobalConfigContext>({} as IGlobalConfigContext)

export const useGlobalConfig = () => useContext(GlobalConfigContext)

const GlobalConfigProvider = (props: GlobalConfigProviderProps) => {
  const { children } = props
  const [loading, setLoading] = useState<boolean>(true)
  const [config, setConfig] = useState<GlobalConfigProps>(DefaultGlobalConfig)

  // init/mount
  useEffect(() => {
    // load the site specific global config
    try {
      loadGlobalConfig((config: GlobalConfigProps) => {
        console.log('GlobalConfigProvider - useEffect([]) - loadGlobalConfig - callback(done) - config: ', config)
        setConfig(config)
        setLoading(false)
        // setTimeout(() => { setLoading(false) }, 1000) // NB: DEBUG ONLY alternative (pause while loading so we can see the loading UI, commented out the `setLoading` line above this when uncommenting this one)
      })
    } catch (err) {
      console.log('GlobalConfigProvider - useEffect([]) - loadGlobalConfig - err: ', err)
      // TODO: how to handle? still call `setLoading(true)` or don't allow the site to load at all??
      // TODO: (maybe add an error state var & set the calling code to check & show a full page error that blocks the site from loading when this occurs (should only happen if a config/build issue occurs)
    }
  }, [])

  const actions: IGlobalConfigActions = {
  }

  const store: IGlobalConfigStore = {
    loading,
    config
  }

  return (
    <GlobalConfigContext.Provider value={{ actions, store }}>
      {children}
    </GlobalConfigContext.Provider>
  )
}

const withGlobalConfigProvider = <P extends object>(Component: React.ComponentType<P>) => {
  const withGlobalConfigProviderHOC = (props: any) => (
    <GlobalConfigContext.Consumer>
      {(configContext) => {
        if (configContext === null) {
          throw new Error('GlobalConfigConsumer must be used within a GlobalConfigProvider')
        }
        return (<Component {...props} {...{ configContext: configContext }} />)
      }}
    </GlobalConfigContext.Consumer>
  )
  return withGlobalConfigProviderHOC
}

export { GlobalConfigProvider }
export { withGlobalConfigProvider }
