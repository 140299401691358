import React, { Component } from 'react'
import * as yup from 'yup'

import { withAuthContext, IAuthMultiContext, withServerContext, IServerMultiContext } from '../../../providers'
import { IAuthLoginService } from 'src/core/models'

import ArkForm, { ArkFormField, ArkFormFieldOption, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from '../../../../core/components/ArkForm/ArkForm'

const formSchema = yup.object().shape({
  email: yup.string().email().required()
})

export type LoginEmailLookupCallback = (email: string, loginService: IAuthLoginService) => void

interface IProps extends IAuthMultiContext, IServerMultiContext {
  email?: string
  autoRun?: boolean
  onEmailLookup?: LoginEmailLookupCallback
}
interface IState {
  isSubmitting: boolean
  lookupError?: Error
  formKey: string
}

class LoginEmailLookupForm extends Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      isSubmitting: false,
      formKey: Date.now().toString(36) + Math.random().toString(36).substring(2) // basic random key - ref: https://stackoverflow.com/a/44078785
    }
  }

  componentDidMount () {
    this._isMounted = true
    console.log('LoginEmailLookupForm - componentDidMount - this.props - email:', this.props.email, ' autoRun:', this.props.autoRun)
    if (this.props.email && this.props.autoRun === true) {
      console.log('LoginEmailLookupForm - componentDidMount - autoRun enabled - submitting form...')
      this.runEmailLookup(this.props.email)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  async componentDidUpdate (prevProps: IProps) {
    // check if the email prop has changed & if so, reset the form key to force a re-render
    if (this.props.email !== prevProps.email) {
      console.log('LoginEmailLookupForm - componentDidUpdate - email changed from:', prevProps.email, ' to:', this.props.email)
      this.setState({ formKey: Date.now().toString(36) + Math.random().toString(36).substring(2) })
      // auto submit if autoRun is enabled
      console.log('LoginEmailLookupForm - componentDidUpdate - this.props - email:', this.props.email, ' autoRun:', this.props.autoRun)
      if (this.props.email && this.props.autoRun === true) {
        console.log('LoginEmailLookupForm - componentDidUpdate - autoRun enabled - submitting form...')
        this.runEmailLookup(this.props.email)
      }
    }
  }

  render () {
    const { lookupError, isSubmitting } = this.state
    const authError = this.props.authContext.store.authError

    const formFields: Array<ArkFormField> = []

    if (this.props.serverContext.store.servers) {
      const serverOptions: Array<ArkFormFieldOption> = []
      for (const serverKey in this.props.serverContext.store.servers) {
        const server = this.props.serverContext.store.servers[serverKey]
        serverOptions.push({ key: serverKey, text: server.name, value: serverKey })
      }
      formFields.push({
        type: ArkFormFieldType.Select,
        key: 'server',
        label: 'Server',
        required: true,
        defaultValue: this.props.serverContext.store.apiServerType,
        options: serverOptions,
        fieldProps: {
          // WARNING: this stops the select field updating within the ArkForm currently, so we have to ignore the submitted value & rely on the one from here only
          onChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: any) => {
            const server = data.value
            if (server !== this.props.serverContext.store.apiServerType) {
              console.log('LoginEmailLookupForm - render - Select - server - onChange - CHANGE TO SERVER: ', server)
              const selectServerResult = this.props.serverContext.actions.selectServer(server)
              console.log('LoginEmailLookupForm - selectServerResult: ', selectServerResult)
              // FIXME: update the UI/selection if the server selection fails?
            }
          }
        }
      })
    }

    console.log('LoginEmailLookupForm - render - email:', this.props.email, ' formKey:', this.state.formKey)
    formFields.push({
      type: ArkFormFieldType.Input,
      key: 'email',
      label: 'Email Address',
      required: true,
      defaultValue: this.props.email
    })
    formFields.push({ type: ArkFormFieldType.Button, key: 'submit', label: 'Continue', fieldProps: { loading: isSubmitting, fluid: true } })

    return (
      <ArkForm
        key={this.state.formKey} // NB: unique key added to force a re-render when the email prop changes
        formKey="emailLookup"
        className="email-lookup-form"
        inverted
        formError={lookupError ?? authError}
        formFields={formFields}
        formSchema={formSchema}
        onFormSubmit={this.onFormSubmit}
        onValueChanged={this.onFormValueChanged}
      ></ArkForm>
    )
  }

  onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    const { email } = fieldValues
    await this.runEmailLookup(email)
  }

  onFormValueChanged = async (fieldKey: string, _fieldValue: any, _oldFieldValue: any) => {
    // TESTING: if an authError is set, clear it once the user starts to type/edit the email
    if (fieldKey === 'email' && this.props.authContext.store.authError !== undefined) {
      this.props.authContext.actions.clearAuthError()
    }
  }

  runEmailLookup = async (email: string) => {
    if (email && this.state.isSubmitting === false) {
      this.setState({ isSubmitting: true })
      // TODO: July 2023 - see the comments in the ServerAuthAPI for the `checkEmailExists/emailLoginLookup` method re okta/auth0 sso api handling changes in the response from this api call <<<
      try {
        const loginService = await this.props.authContext.actions.emailLoginLookup(email)
        console.log('LoginEmailLookupForm - onFormSubmit - loginService:', loginService)
        if (this._isMounted) this.setState({ isSubmitting: false })
        if (this.props.onEmailLookup) this.props.onEmailLookup(email, loginService)
      } catch (error) {
        if (this._isMounted) this.setState({ lookupError: error, isSubmitting: false })
      }
    }
  }
}

export default withAuthContext(withServerContext(LoginEmailLookupForm))
