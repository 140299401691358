import React, { useContext, useEffect, useRef, useState } from 'react'

import { CompanyAdminContext } from 'src/core/providers'

import { CompanyGroup, Project } from 'src/core/models'

// import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkButton from 'src/core/components/ArkButton'
import ArkDataMappingForm, { ArkDataMappingItemChanges, ArkManagerFilteredItem } from 'src/core/components/ArkDataMappingForm/ArkDataMappingForm'
import ArkHighlightedText from 'src/core/components/ArkHighlightedText'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_COMPANY_SHORTNAME, OBJECT_GROUP_NAME, OBJECT_GROUP_NAME_PLURAL, OBJECT_PROJECT_NAME, OBJECT_PROJECT_NAME_PLURAL } from 'src/constants/strings'

import styles from './CompanyGroupProjectsPanel.module.css'
import dataMappingFormStyles from 'src/core/components/ArkDataMappingForm/ArkDataMappingForm.module.css'

// NB: this mimic's the `GroupUserOperation` data type handling the 'group > user' update api endpoint uses (0 === ADD, 2 === DELETE)
// NB: the api doesn't current support bulk 'group > project' updates, but added this so the base data mapping form logic can work in the same way (& the final api update calls just loop through these & apply them 1 by 1 for now)
export type GroupProjectOperation = { operation: number, project_id: number }

interface IProps {
  companyId: number
  group: CompanyGroup
  onChange?: Function
}

const CompanyGroupProjectsPanel = (props: IProps) => {
  const mounted = useRef(false)

  const { companyId, group, onChange: _onChange } = props

  const companyAdminContext = useContext(CompanyAdminContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [companyProjects, setCompanyProjects] = useState<Array<Project>>([])
  // const [groupProjects, setGroupProjects] = useState<Array<Project>>([])

  const [filteredCompanyProjects, setFilteredCompanyProjects] = useState<Array<ArkManagerFilteredItem<Project>> | undefined>()
  const [filter, setFilter] = useState<string | undefined>()

  const [selectedIds, setSelectedIds] = useState<Array<number>>([])
  const [selectedIdsKey, setSelectedIdsKey] = useState<Date>(new Date()) // TESTING: key to force re-render of the data mapping form (needed to get the form to reset if a save error occurs when no other changes are made & it doesn't reset the toggle state)

  // const [savedIds, setSavedIds] = useState<Array<number> | undefined>() // NB: not currently used, so skipping it
  // const [saveErrors, setSaveErrors] = useState<Map<number, any> | undefined>() // NB: not currently used, so skipping it

  const [showForm, setShowForm] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasUpdated, setHasUpdated] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const resetForm = () => {
    if (isSubmitting) return // don't allow resetting while submitting
    setIsSubmitting(false)
    setHasUpdated(false)
    setError(undefined)
    // setSavedIds(undefined)
    // setSaveErrors(undefined)
  }

  const resetView = () => {
    resetForm()
    setShowForm(false)
  }

  // -------

  // TESTING: equivalent to the componentDidUpdate method `if (this.props.group.id !== prevProps.group.id) { this.resetView() }`
  useEffect(() => {
    if (group.id) {
      resetView()
    }
  }, [group.id])

  // -------

  const _loadCompanyProjects = async () => {
    if (companyId) {
      try {
        const companyProjects = await companyAdminContext.actions.getAllCompanyProjects(companyId)
        return companyProjects
      } catch (error) {
        console.error('CompanyGroupProjectsPanel - _loadCompanyProjects - error: ', error)
        throw error
      }
    }
  }

  const _loadGroupProjects = async () => {
    if (companyId && group.id) {
      try {
        const groupProjectVisibility = await companyAdminContext.actions.getCompanyGroupProjectVisibility(companyId, group.id)
        return groupProjectVisibility
      } catch (error) {
        console.error('CompanyGroupProjectsPanel - _loadGroupProjects - error: ', error)
        throw error
      }
    }
  }

  const loadProjectsData = async () => {
    console.log('CompanyGroupProjectsPanel - loadProjectsData')
    if (loading === true) return false
    try {
      setLoading(true)
      setError(undefined)
      const companyProjects = await _loadCompanyProjects()
      console.log('CompanyGroupProjectsPanel - loadProjectsData - companyProjects: ', companyProjects)
      const groupProjects = await _loadGroupProjects()
      console.log('CompanyGroupProjectsPanel - loadProjectsData - groupProjects: ', groupProjects)
      // TESTING: re-set the selectedIds to show the mappings saved from the api
      // NB: this will override any edits/changes to the form selection by the user, if this gets called after initial load
      const selectedIds: Array<number> = []
      if (groupProjects) {
        for (const groupProject of groupProjects) {
          selectedIds.push(groupProject.id)
        }
      }
      console.log('CompanyGroupProjectsPanel - loadProjectsData - selectedIds: ', selectedIds)
      setLoading(false)
      setCompanyProjects(companyProjects || [])
      // setGroupUsers(groupUsers || [])
      setSelectedIds(selectedIds)
    } catch (error) {
      console.error('CompanyGroupProjectsPanel - loadProjectsData - error: ', error)
      setLoading(false)
      setCompanyProjects([])
      // setGroupUsers([])
      setSelectedIds([])
      setError(error as Error)
    }
  }

  // -------

  const onShowForm = async () => {
    console.log('CompanyGroupProjectsPanel - onShowForm')
    setShowForm(true)
    setError(undefined)
    await loadProjectsData() // TESTING: moved this to only trigger when the form should show (avoid making api queries till we need the data)
  }

  // TODO: should this be called?
  // const onHideForm = () => {
  //   console.log('CompanyGroupProjectsPanel - onHideForm')
  //   setShowForm(false)
  //   setError(undefined)
  // }

  // -------

  // TESTING: takes the result of ArkDataMappingItemChanges (additions & deletions) & converts it to the format the api endpoint expects
  // TODO: move to the relevant api class so its re-usable else-where...
  const generateGroupProjectUpdateOperations = (groupId: number, itemChanges: ArkDataMappingItemChanges) => {
    const addOperations: Array<GroupProjectOperation> = []
    const delOperations: Array<GroupProjectOperation> = []
    for (const addItem of itemChanges.add) {
      addOperations.push({
        operation: 0, // 0 === ADD
        // group_id: groupId,
        project_id: addItem
      })
    }
    for (const delItem of itemChanges.del) {
      delOperations.push({
        operation: 2, // 2 == DELETE
        // group_id: groupId,
        project_id: delItem
      })
    }
    return [...delOperations, ...addOperations]
  }

  // -------

  const saveChanges = async (companyId: number, group: CompanyGroup, newSelectedIds: Array<number>, selectedIdChanges: ArkDataMappingItemChanges) => {
    console.log('CompanyGroupProjectsPanel - saveChanges - companyId:', companyId, ' group:', group, ' newSelectedIds:', newSelectedIds, ' selectedIdChanges:', selectedIdChanges)

    setIsSubmitting(true)
    setHasUpdated(false)
    setError(undefined)
    // setSavedIds(undefined)
    // setSaveErrors(undefined)

    // convert the list off adds & dels to an operations list in the format the api expects
    const operations = generateGroupProjectUpdateOperations(group.id, selectedIdChanges)
    console.log('CompanyGroupProjectsPanel - saveChanges - operations: ', operations)

    if (operations.length === 0) {
      console.log('CompanyGroupProjectsPanel - saveChanges - 0 operations - HALT')
      // TODO: show an error/warning?
      setIsSubmitting(false)
      setHasUpdated(false)
      return
    }

    let savedIds: Array<number> | undefined
    let savedSelectedIds: Array<number> | undefined
    let saveErrors: Map<number, any> | undefined
    let hasUpdated = false
    let error: Error | undefined

    try {
      const operationsTotal = operations.length
      let operationsCount = 0
      for (const operation of operations) {
        try {
          console.log('CompanyGroupProjectsPanel - saveChanges - operation: ', operation)
          // 0 === ADD
          // 2 == DELETE
          operationsCount++
          let visible = false
          if (operation.operation === 0) {
            visible = true
          } else if (operation.operation === 2) {
            visible = false
          } else {
            // unknown/invalid operation
            throw new Error('Invalid operation type: ' + operation.operation)
          }
          const result = await companyAdminContext.actions.updateCompanyGroupProjectVisibility(companyId, group.id, operation.project_id, visible)
          // // DEBUG ONLY: <<<<
          // // DEBUG ONLY: test error handling if a specific project id fails to update (while others save as normal, so we can test the mixed success/error handling)
          // // DEBUG ONLY: NB: comment the above updateCompanyGroupProjectVisibility line & uncomment the below to test the error handling
          // let result = false
          // if (operation.project_id === 18) {
          //   throw new Error('TEST ERROR - operation.project_id: ' + operation.project_id)
          // } else {
          //   result = await companyAdminContext.actions.updateCompanyGroupProjectVisibility(companyId, group.id, operation.project_id, visible)
          // }
          // // DEBUG ONLY: <<<<
          console.log('CompanyGroupProjectsPanel - saveChanges - updateCompanyGroupProjectVisibility - result: ', result)
          if (result) {
            if (!savedIds) savedIds = []
            savedIds?.push(operation.project_id)
          }
          // add a brief pause before running any more operations (so we don't flood the api with requests if toggling many items at once)
          if (operationsCount < operationsTotal) {
            await new Promise(resolve => setTimeout(resolve, 500))
          }
        } catch (error) {
          console.error('CompanyGroupProjectsPanel - saveChanges - operation error: ', error)
          if (!saveErrors) saveErrors = new Map<number, any>()
          saveErrors.set(operation.project_id, error)
        }
      }
      // TESTING: sum up the results of the operations to determine if the whole set was successful or not??
      savedSelectedIds = [...selectedIds] // NB: start with the current selectedIds (state vars), then add/remove the operations that succeeded
      for (const operation of operations) {
        const projectId = operation.project_id
        if (savedIds?.includes(projectId)) {
          if (operation.operation === 0) { // ADD
            if (!savedSelectedIds.includes(projectId)) savedSelectedIds.push(operation.project_id)
          } else if (operation.operation === 2) { // DELETE
            if (savedSelectedIds.includes(projectId)) {
              savedSelectedIds = savedSelectedIds.filter((_selectedId) => _selectedId !== projectId)
            }
          }
        }
      }
      hasUpdated = (savedIds !== undefined && savedIds?.length > 0) // set to true if one or more operations succeeded (even if some failed)
      console.log('CompanyGroupProjectsPanel - saveChanges - savedIds:', savedIds, ' savedSelectedIds:', savedSelectedIds, ' saveErrors:', saveErrors)
      // TESTING: handle errors (if any)
      if (saveErrors && saveErrors.size > 0) {
        if (savedIds && savedIds.length > 0) {
          error = { message: 'A problem occurred saving one or more of the changes, please try again.' } as any
        } else { // all operations failed
          error = { message: 'A problem occurred saving the changes, please try again.' } as any
          // TESTING: update the key to force a re-render of the data mapping form (to force the toggles to update when they might not, if no changes were saved)
          // NB: only needed if no ids were changed
          // NB: this prevents the toggle animating, like it normally would do when it resets, so isn't ideal, but at least keeps the form in sync
          setSelectedIdsKey(new Date())
        }
      }
      console.log('CompanyGroupProjectsPanel - saveChanges - hasUpdated:', hasUpdated, ' savedIds:', savedIds, ' savedSelectedIds:', savedSelectedIds, ' saveErrors:', saveErrors, ' error:', error)
      if (mounted.current) {
        setIsSubmitting(false)
        setHasUpdated(hasUpdated)
        setSelectedIds(savedSelectedIds ?? [])
        // setSavedIds(savedIds)
        // setSaveErrors(saveErrors)
        setError(error)
      }
      if (hasUpdated) {
        if (_onChange) _onChange()
      }
    } catch (error) {
      console.error('CompanyGroupProjectsPanel - saveChanges - error: ', error)
      if (mounted.current) {
        setIsSubmitting(false)
        setHasUpdated(hasUpdated)
        // setSavedIds(savedIds)
        // setSaveErrors(saveErrors)
        setError(error as Error)
      }
    }
  }

  // -------

  const filterProjectProjects = (_filter: string) => {
    if (loading) return
    // NB: we currently filter out items even if they're enabled/mapped, but when you submit they will still submit as enabled/mapped (we just don't visually show them for now)
    // TODO: should we show filtered out items that are enabled/mapped in some way, to make it obvious they'll remain selected when you save the mapping form?
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredCompanyProjects = filter
      ? companyProjects.reduce<Array<ArkManagerFilteredItem<Project>>>((r, project) => {
        // TODO: also allow filtering by user email here (if we actually show it in the listing & not just the name??)
        let nameMatch = false
        // let emailMatch = false
        if (project.name.toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        // if (group.desc?.toLowerCase().includes(filter.toLowerCase())) {
        //   emailMatch = true
        // }
        // console.log('filterUsers - user.name(): ', user.name(), ' nameMatch: ', nameMatch, ' emailMatch: ', emailMatch)
        if (nameMatch) { // || emailMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          // if (emailMatch) matchingFields.push('email')
          const filteredItem: ArkManagerFilteredItem<Project> = {
            item: project,
            matchingFields
          }
          r.push(filteredItem)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<Project>>)
      : undefined
    setFilter(filter)
    setFilteredCompanyProjects(filteredCompanyProjects)
  }

  // const clearFilteredProjectPrograms = () => {
  //   setFilter(undefined)
  //   setFilteredCompanyUsers(undefined)
  // }

  // -------

  const renderProjectUserFilterForm = () => {
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        className={dataMappingFormStyles.filterForm}
        filterTitle='Filter by name'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          filterProjectProjects(filter)
        }}
      />
    )
  }

  // -------

  if (!group) return null
  return (
    <ArkPanel bordered={showForm} title={showForm ? OBJECT_COMPANY_SHORTNAME + ' ' + OBJECT_GROUP_NAME + ' ' + OBJECT_PROJECT_NAME_PLURAL : null}>

      {!showForm && (
        // eslint-disable-next-line no-undef
        <ArkButton type="button" fluid size="large" loading={loading} onClick={onShowForm}>EDIT {OBJECT_COMPANY_SHORTNAME} {OBJECT_GROUP_NAME} {OBJECT_PROJECT_NAME_PLURAL}</ArkButton>
      )}

      {showForm && (
        <>
          <ArkDataMappingForm
            id="group-users"
            key={selectedIdsKey.toString()} // NB: key to force re-render of the data mapping form under certain conditions (e.g. to reset the form if a save error occurs when no other changes are made & it doesn't reset the toggle state)
            // title="Group Users"
            sourceItems={companyProjects /* .map(companyUser => { return { id: companyUser.id, title: companyUser.name() } }) */}
            mappedIds={selectedIds}
            isLoading={loading}
            isSaving={isSubmitting}
            // TODO: currently if some items save & others error, we show both success & error messages, wording of both is tweaked if thats the case, but we could make it more clear if we detail which saved ok & which errored?
            successMessage={hasUpdated && ((error !== undefined ? 'Some of the ' : 'The ') + OBJECT_GROUP_NAME + ' ' + OBJECT_PROJECT_NAME + ' changes have been saved.')}{/* TODO: add details of how many where added/updated/removed from the chhanel? */...[]}
            errorMessage={error && error.message}
            disabled={group.isVisibleToAllProjects}
            disabledMessage={'This ' + OBJECT_GROUP_NAME + ' currently has access enabled for all ' + OBJECT_PROJECT_NAME_PLURAL + '. If you want to limit access to only certain ' + OBJECT_GROUP_NAME_PLURAL + ', disable that first using settings cog above & then come back here to manage the ' + OBJECT_PROJECT_NAME_PLURAL + '.'}
            onCancel={() => { resetView() }}
            onSave={(mappedIds?: Array<number>, changes?: ArkDataMappingItemChanges) => {
              console.log('CompanyGroupProjectsPanel - ArkDataMappingForm - onSave - mappedIds: ', mappedIds, ' changes: ', changes)
              if (mappedIds && changes) {
                saveChanges(companyId, group, mappedIds, changes)
              }
            }}
            // filtering & custom item rendering support:
            filterForm={renderProjectUserFilterForm()}
            filterText={filter}
            filteredSourceItems={filteredCompanyProjects}
            itemCompare={(newItem: Project, oldItem: Project) => {
              return newItem.id === oldItem.id && newItem.name === oldItem.name
            }}
            itemRow={(project: Project, _isMapped: boolean) => {
              return (
                <div className={styles.projectRow}>
                  {/* <ArkAvatar
                    type={user.userAvatarType()}
                    name={user.name()}
                    size='30'
                    filter={filter}
                    filterHighlight={true}
                  /> */}
                  <ArkHighlightedText highlight={filter} text={project.name} className={styles.text} />
                </div>
              )
            }}
            onClearFilter={() => filterProjectProjects('')}
          />
          <ArkSpacer />
        </>
      )}

    </ArkPanel>
  )
}

export default CompanyGroupProjectsPanel
