import React, { useContext } from 'react'

import { SiteAdminContext } from 'src/core/providers'
import { VideoEngine } from 'src/core/models'

import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_VIDEO_ENGINE_NAME } from 'src/constants/strings'

export type VideoEngineDidDeleteCallback = (videoEngine: VideoEngine) => void

interface IProps {
  videoEngine: VideoEngine
  onDidDelete?: VideoEngineDidDeleteCallback
  className?: string
}

const VideoEngineDeleteButton = (props: IProps) => {
  const { videoEngine, className } = props

  const siteAdminContext = useContext(SiteAdminContext)

  const onConfirm = async (): Promise<boolean> => {
    // prompt the user to disable the video engine before they can delete it
    if (videoEngine.isActive) {
      throw new Error(`Disable the ${OBJECT_VIDEO_ENGINE_NAME} before deleting`)
    }
    // NB: DON'T catch the error/exception here, let it throw (or if you do, rethrow it so the calling code can catch & display it)
    const deleteResult = await siteAdminContext.actions.deleteVideoEngine(videoEngine.id)
    console.log('VideoEngineDeleteButton - onConfirm - deleteVideoEngine - deleteResult: ', deleteResult)
    return deleteResult
  }

  if (!videoEngine) return null

  return (
    <div className={(className ? ' ' + className : '')}>
      <ArkSpacer />
      <ArkManagerDeleteButton
        itemId={videoEngine.id}
        itemName={videoEngine.name + ' (' + videoEngine.domain + ')'}
        itemTypeName={OBJECT_VIDEO_ENGINE_NAME}
        // itemNamePrefix='for'
        buttonTitle={'DELETE ' + OBJECT_VIDEO_ENGINE_NAME}
        deleteWord={'Delete'}
        deletedWord={'Deleted'}
        confirmMsgPost={<>?<><br /><br /><strong>WARNING: </strong>This completely deletes the {OBJECT_VIDEO_ENGINE_NAME} server entry!</><br />Continue</>}
        onDelete={onConfirm}
        onDeleteComplete={() => { if (props.onDidDelete) props.onDidDelete(videoEngine) }}
        // buttonProps={{ basic: false, color: 'red' }}
        confirmOkButtonProps={{ color: 'red', basic: false }}
        confirmTextInput={videoEngine.domain ?? videoEngine.name}
        onDeleteAllowed={() => { return !videoEngine.isActive }}
        notAllowedTitle={`Disable the ${OBJECT_VIDEO_ENGINE_NAME} before deleting`}
        notAllowedMsg={`To delete the ${OBJECT_VIDEO_ENGINE_NAME} you must disable it first.`}
      />
    </div>
  )
}

export default VideoEngineDeleteButton
