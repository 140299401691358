import React from 'react'

import ArkCompanyManagerPage from '../../components/ArkCompanyManagerPage/ArkCompanyManagerPage'
import ArkInactiveOrgView from 'src/core/components/ArkInactiveOrgView'

const CompanyManagerInactiveOrgPage = () => (
  <ArkCompanyManagerPage>
    <ArkInactiveOrgView />
  </ArkCompanyManagerPage>
)

export default CompanyManagerInactiveOrgPage
