/**
 * ArkListItem
 */

import React, { MouseEventHandler, ReactNode, useContext } from 'react'

import { ResponsiveContext } from 'src/core/providers/ResponsiveProvider'

import ArkButton from '../ArkButton'
import ArkHighlightedText from '../ArkHighlightedText'
import ArkSpacer from '../ArkSpacer'

import styles from './ArkListItem.module.css'

export interface ArkListItemProps {
  active?: boolean
  className?: string
  description?: string // included in the filter highlighting
  descriptionNoFilter?: string | React.ReactNode // skips the filter highlighting so it can include any content
  disabled?: boolean
  filter?: string // TESTING FILTERING
  id?: string
  leftComponent?: ReactNode
  onClick: () => void;
  onEditClick?: () => void;
  rightComponent?: ReactNode
  rightComponentDesktop?: ReactNode // hidden on mobile
  title: string
}

const ArkListItem = (props: ArkListItemProps) => {
  const {
    active,
    className,
    description,
    descriptionNoFilter,
    disabled,
    filter,
    id,
    leftComponent,
    onClick,
    onEditClick,
    rightComponent,
    rightComponentDesktop,
    title
  } = props

  const { store } = useContext(ResponsiveContext)
  const { isDesktop, isMobile } = store

  const _onEditClick: MouseEventHandler = (event) => {
    event.stopPropagation()
    onEditClick && onEditClick()
  }

  return (
    <div
      className={`${
        styles.container
      }${
        active ? ' ' + styles.active : ''
      }${
        disabled ? ' ' + styles.disabled : ''
      }${
        isMobile ? ' ' + styles.mobile : ''
      }${
        className ? ' ' + className : ''
      }`}
      id={id}
      onClick={onClick}
    >
      <div className={styles.left}>
        {leftComponent && (
          <>
            {leftComponent}
            <ArkSpacer />
          </>
        )}
      </div>
      <div className={styles.middle}>
        <div className={styles.title}>
          <ArkHighlightedText highlight={filter}>{title}</ArkHighlightedText>
        </div>
        {(description || descriptionNoFilter) && (
          <>
            <ArkSpacer small />
            <div className={styles.description}>
              {description && (<ArkHighlightedText highlight={filter}>{description}</ArkHighlightedText>)}
              {descriptionNoFilter}
            </div>
          </>
        )}
      </div>
      <div className={styles.right}>
        {isDesktop && rightComponentDesktop && (
          <>
            <ArkSpacer />
            {rightComponentDesktop}
          </>
        )}
        {rightComponent && (
          <>
            <ArkSpacer />
            {rightComponent}
          </>
        )}
        {onEditClick && (
          <>
            <ArkSpacer large />
            <ArkButton onClick={_onEditClick}>Edit</ArkButton>
          </>
        )}
      </div>
    </div>
  )
}

export default ArkListItem
