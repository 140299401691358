import React, { useContext, useEffect, useRef, useState } from 'react'

import { CompanyAdminContext, UserContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import { CompanyVideoEngine } from 'src/core/models'

import ArkCompanyManagerPage from '../../components/ArkCompanyManagerPage/ArkCompanyManagerPage'
import CompanyVideoEngineSidebar from './CompanyVideoEngineSidebar'
import CompanyVideoEngineListItem from './CompanyVideoEngineListItem'
import CompanyVideoEngineForm from './CompanyVideoEngineForm'

import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkManagerListView, { ArkManagerFilteredItem } from 'src/core/components/ArkManagerListView/ArkManagerListView'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkModal from 'src/core/components/ArkModal'

import { OBJECT_COMPANY_NAME, OBJECT_VIDEO_ENGINE_NAME_PLURAL, SECTION_COMPANY_NAME, SECTION_MANAGER_SUFFIX_NAME } from 'src/constants/strings'

interface IProps {
}

const CompanyVideoEnginesPage = (_props: IProps) => {
  const mounted = useRef(false)

  const userContext = useContext(UserContext)
  const companyAdminContext = useContext(CompanyAdminContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<Error | undefined>(undefined)
  const [videoEngines, setVideoEngines] = useState<Array<CompanyVideoEngine>>([])
  const [filteredVideoEngines, setFilteredVideoEngines] = useState<Array<ArkManagerFilteredItem<CompanyVideoEngine>> | undefined>(undefined)
  const [filter, setFilter] = useState<string | undefined>(undefined)
  const [selectedVideoEngine, setSelectedVideoEngine] = useState<CompanyVideoEngine | undefined>(undefined)
  const [editVideoEngine, setEditVideoEngine] = useState<CompanyVideoEngine | undefined>(undefined)
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const company = userContext.store.selectedCompany
  if (!company) return null

  const loadVideoEngines = async () => {
    if (loading === true || loadingError !== undefined) return false
    if (!mounted.current) return false
    try {
      setLoading(true)
      const videoEngines = await companyAdminContext.actions.getAllCompanyVideoEngines(company.id)
      console.log('AdminVideoEnginesPage - loadVideoEngines - videoEngines: ', videoEngines)
      if (mounted.current) {
        setLoading(false)
        setVideoEngines(videoEngines || [])
        // update the selectedVideoEngine whenever the video engine is reloaded (incase its been edited)
        if (selectedVideoEngine) {
          const _videoEngine = videoEngines?.find(c => c.id === selectedVideoEngine?.id)
          setSelectedVideoEngine(_videoEngine)
        }
      }
    } catch (error) {
      console.error('AdminVideoEnginesPage - loadVideoEngines - error: ', error)
      if (mounted.current) {
        setLoading(false)
        setVideoEngines([])
        setLoadingError(error)
      }
    }
  }

  useEffect(() => {
    loadVideoEngines()
  }, [])

  // -------

  const selectVideoEngine = (_videoEngine?: CompanyVideoEngine) => {
    setSelectedVideoEngine(_videoEngine)
  }

  // -------

  const filterVideoEngines = (_filter: string) => {
    if (loading) return
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredVideoEngines = filter
      ? videoEngines.reduce<Array<ArkManagerFilteredItem<CompanyVideoEngine>>>((r, videoEngine) => {
        let nameMatch = false
        if (videoEngine.name.toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        if (nameMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          const filteredUser: ArkManagerFilteredItem<CompanyVideoEngine> = {
            item: videoEngine,
            matchingFields
          }
          r.push(filteredUser)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<CompanyVideoEngine>>)
      : undefined
    if (selectedVideoEngine && (!(filteredVideoEngines?.find((filteredVideoEngine) => filteredVideoEngine.item.id === selectedVideoEngine.id)))) {
      selectVideoEngine(undefined) // if an item was selected but isn't in the filtered list deselect them
    }
    setFilter(filter)
    setFilteredVideoEngines(filteredVideoEngines)
  }

  // const clearFilteredGroups = () => {
  //   setFilter(undefined)
  //   setFilteredVideoEngines(undefined)
  // }

  // -------

  // const showAddModal = () => {
  //   setShowAddEditModal(true)
  // }

  const showEditModal = (videoEngine: CompanyVideoEngine) => {
    setShowAddEditModal(true)
    setEditVideoEngine(videoEngine)
  }

  // call directly to initiate a modal callback (used when the user clicks custom buttons to close the modal instead of the modals own 'x' button etc.)
  const hideAddEditModal = () => {
    // NB: DON'T clear/reset `editVideoEngine` here, see `didHideVideoEngineModal` which is called once the modal has actually closed & we can safely reset it then
    // NB: this stops a saved edit form flipping the 'updated' success text to 'created' briefly while the modal closes if we reset it straight away here
    // NB: & also stops the form title flipping from edit to add as it closes via the cancel button
    setShowAddEditModal(false)
  }

  // triggered via modal callbacks once the modal has already closed
  const didHideAddEditModal = () => {
    setShowAddEditModal(false)
    setEditVideoEngine(undefined)
  }

  // -------

  const onEdit = (_videoEngine: CompanyVideoEngine) => {
    showEditModal(_videoEngine)
  }

  const onDidDelete = (_videoEngine: CompanyVideoEngine) => {
    // trigger a data re-load so the deleted video engine no longer shows
    loadVideoEngines()
    selectVideoEngine(undefined)
  }

  // -------

  const renderVideoEngineForm = (videoEngine: CompanyVideoEngine) => {
    return (
      <CompanyVideoEngineForm
        companyId={company.id}
        videoEngine={videoEngine}
        onCancel={() => { hideAddEditModal() }}
        onClose={() => { hideAddEditModal() }}
        onSave={() => {
          // trigger a video engines data re-load to show any changes
          loadVideoEngines()
        }}
        insideModal={true}
      />
    )
  }

  const renderVideoEngineFormModal = () => {
    return (
      <ArkModal open={showAddEditModal && editVideoEngine !== undefined} onClose={() => didHideAddEditModal()}>
        {editVideoEngine && renderVideoEngineForm(editVideoEngine)}
      </ArkModal>
    )
  }

  // -------

  const renderVideoEngineTableRowContent = (videoEngine: CompanyVideoEngine, isSelected: boolean, filter?: string) => {
    const userCompanies = userContext.store.userCompanies // NB: if the VE is assigned to an org/company & we're seeing it here, then the org should be in the user's companies list (they don't have access to the 'all companies' list)
    const company = videoEngine?.companyId ? userCompanies?.find(c => c.id === videoEngine.companyId) : undefined
    return (
      <CompanyVideoEngineListItem
        active={isSelected}
        videoEngine={videoEngine}
        videoEngineCompany={company}
        filter={filter}
        key={videoEngine.id}
        onClick={() => selectVideoEngine(videoEngine)}
        // onEditClick={() => this.showEditVideoEngineModal(videoEngine)}
      />
    )
  }

  // -------

  const renderVideoEngineFilterForm = () => {
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        filterTitle='Filter by name'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          filterVideoEngines(filter)
        }}
      />
    )
  }

  // -------

  // const company = userContext.store.selectedCompany
  // if (!company) return null

  const rightSidebarComponent = selectedVideoEngine && (
    <CompanyVideoEngineSidebar
      companyId={company.id}
      videoEngine={selectedVideoEngine}
      companies={userContext.store.userCompanies}
      onEdit={onEdit}
      onDidDelete={onDidDelete}
    />
  )

  return (
    <ArkCompanyManagerPage
      onRightSidebarClose={() => setSelectedVideoEngine(undefined)}
      rightSidebar={rightSidebarComponent}
    >
      <ArkManagerContentView
        title={OBJECT_COMPANY_NAME + ' ' + OBJECT_VIDEO_ENGINE_NAME_PLURAL}
        breadcrumb={[{
          path: ROUTES.COMPANY_MANAGER,
          title: ROUTES.formatBreadcrumbRootTitle(company.name, `${SECTION_COMPANY_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`)
        }]}
      >
        <ArkManagerListView
          loading={loading}
          items={videoEngines}
          selectedItem={selectedVideoEngine}
          itemRow={(videoEngine: CompanyVideoEngine, isSelected: boolean) => {
            return renderVideoEngineTableRowContent(videoEngine, isSelected, filter)
          }}
          // topbarAddItemTitle={'CREATE ' + OBJECT_VIDEO_ENGINE_NAME}
          // onAdd={() => showAddModal()}
          errors={loadingError ? [loadingError] : undefined}
          // no items:
          noItemsTitle={('No ' + OBJECT_VIDEO_ENGINE_NAME_PLURAL).toUpperCase()}
          noItemsMessage={<div>...</div>} // TODO: <<<<
          // FILTERING:
          filter={filter}
          filteredItems={filteredVideoEngines}
          filterForm={renderVideoEngineFilterForm()}
          onClearFilter={() => filterVideoEngines('') }
        />
        {renderVideoEngineFormModal()}
      </ArkManagerContentView>
    </ArkCompanyManagerPage>
  )
}
export default CompanyVideoEnginesPage
