/**
 * FilterPopup
 */

import React, { ReactNode, useContext } from 'react'

import ArkPopup from 'src/core/components/ArkPopup'

import { NodeGraphContext } from '../NodeGraphProvider'
import FilterView from './FilterView'

interface FilterButtonProps {
  trigger: ReactNode
}

const FilterPopup = (props: FilterButtonProps) => {
  const { actions, store } = useContext(NodeGraphContext)
  const { setShowFilter } = actions
  const { showFilter } = store

  const { trigger } = props

  return (
    <ArkPopup
      color='var(--bd-mid)'
      onClose={() => setShowFilter(false)}
      onOpen={() => setShowFilter(true)}
      open={showFilter}
      position='bottom right'
      title='PROJECT MAP FILTERS'
      trigger={trigger}
    >
      <FilterView />
    </ArkPopup>
  )
}

export default FilterPopup
