import React, { useContext, useEffect, useState } from 'react'
import * as yup from 'yup'

import { HOTLINK_ENABLED } from 'src/constants/config'

import { CompanyTranscoderSettings, Program, Project, ProjectTranscoderSettings } from 'src/core/models'
import { ProjectAdminContext, ServerConfigContext, UserContext } from 'src/core/providers'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldOption, ArkFormFieldPlaceholder, ArkFormFieldType } from 'src/core/components/ArkForm/ArkForm'
import ArkHint, { HintType, PopupPosition, PopupSize } from 'src/core/components/ArkHint'
import ArkIcon from 'src/core/components/ArkIcon'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkRadio, { ArkRadioProps } from 'src/core/components/ArkRadio'

import ProjectTranscoderSettingsSyncView from './ProjectTranscoderSettingsSyncView'

import { OBJECT_PROJECT_NAME, OBJECT_PROGRAM_NAME, PAGE_SETTINGS_NAME } from 'src/constants/strings'

import styles from './ProjectSettingsView.module.css'

const formSchema = yup.object().shape({
})

// TODO: access checks currently only work for the currently selected company & project, so remove the props & grab them from the provider instead?
// TODO: (as it can't be used any other way unless access checks are expanded to work outside of current user selections)
// NB: same company/project checks (& limitations) as Project2FASettingsView
export interface ProjectTranscoderSettingsViewProps {
  companyId: number
  project: Project
  projectPrograms: Array<Program>
}

const ProjectTranscoderSettingsView = (props: ProjectTranscoderSettingsViewProps) => {
  const { companyId, project, projectPrograms } = props

  const userContext = useContext(UserContext)
  const projectAdminContext = useContext(ProjectAdminContext)
  const serverConfigContext = useContext(ServerConfigContext)

  // const isSiteAdmin = userContext.actions.isSiteAdmin()
  // const isCompanyAdmin = userContext.store.selectedCompany?.id === companyId && userContext.actions.isCompanyAdmin()
  // const isProjectAdmin = userContext.store.selectedProject?.id === project.id && userContext.actions.isProjectAdmin()
  // NB: making sure the specified company & project are the selected ones before checking the access level (as access checks are currently limited to the selected company)
  const isProjectAdminOrHigher = userContext.store.selectedCompany?.id === companyId &&
    userContext.store.selectedProject?.id === project.id &&
    userContext.actions.isProjectAdminOrHigher()
  console.log('ProjectTranscoderSettingsView - isProjectAdminOrHigher:', isProjectAdminOrHigher, ' project.transcoderSynced:', project.transcoderSynced)

  const [showNoAudioOnlyProtocolWarning, setShowNoAudioOnlyProtocolWarning] = useState<boolean>(false)

  const [saving, setSaving] = useState<boolean>(false)
  const [saveResult, setSaveResult] = useState<boolean>(false)
  const [saveError, setSaveError] = useState<Error | undefined>(undefined)

  // NB: we track required vs forced sync handling separately
  const [isSyncing, setIsSyncing] = useState<boolean>(false)
  const [syncMode, setSyncMode] = useState<undefined | 'required' | 'force'>(undefined)
  const [syncRequiredResult, setSyncRequiredResult] = useState<boolean | undefined>(undefined)
  const [syncRequiredError, setSyncRequiredError] = useState<Error | undefined>(undefined)
  const [syncForcedResult, setSyncForcedResult] = useState<boolean | undefined>(undefined)
  const [syncForcedError, setSyncForcedError] = useState<Error | undefined>(undefined)

  // current project transcoder settings (NB: some company level transcoding settings take priority, so we also load those to compare against)
  const [projectTranscoderSettings, setProjectTranscoderSettings] = useState<ProjectTranscoderSettings | undefined>(project.transcoderSettings)
  // individual program transcoder settings specific to this form
  const [projectTranscoderValues, setProjectTranscoderValues] = useState({
    protocols: project.transcoderSettings?.protocols ?? [],
    allowSLDPPassthrough: project.transcoderSettings?.allowSLDPPassthrough ?? false,
    allowSRTPassthrough: project.transcoderSettings?.allowSRTPassthrough ?? false,
    transcodersEnabled: project.transcoderSettings?.transcodersEnabled ?? false,
    hotlinkValidMins: project.transcoderSettings?.hotlinkValidMins // TODO: set default fallback here?
  })
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])
  const [protocolChanges, setProtocolChanges] = useState<Array<string>>([])
  // additional company related data
  const [companyTranscoderSettings, setCompanyTranscoderSettings] = useState<CompanyTranscoderSettings | undefined>(undefined)
  const [companyTranscoderValues, setCompanyTranscoderValues] = useState({
    // NB: only adding company transcoder values that are also present at the project level
    allowSLDPPassthrough: false,
    allowSRTPassthrough: false
  })
  const [transcoderWarnings, setTranscoderWarnings] = useState<Array<string>>([])

  const supportedProtocols = serverConfigContext.store.serverConfig?.supportedProtocols ?? []
  // TESTING: move SLDP & SRT protocols to the front/start of the array
  if (supportedProtocols.includes('SLDP') && supportedProtocols.indexOf('SLDP') !== 0) {
    supportedProtocols.splice(supportedProtocols.indexOf('SLDP'), 1) // remove the SLDP element
    supportedProtocols.unshift('SLDP') // insert the SLDP element to the front of the array
  }
  if (supportedProtocols.includes('SRT')) {
    const targetSRTIndex = supportedProtocols.includes('SLDP') ? 1 : 0
    if (supportedProtocols.indexOf('SRT') !== targetSRTIndex) {
      supportedProtocols.splice(supportedProtocols.indexOf('SRT'), 1) // remove the SRT element
      supportedProtocols.splice(targetSRTIndex, 0, 'SRT') // insert the SRT element to index 1, just below/after SLDP if its in the array, or the first element if not
    }
  }
  console.log('ProjectTranscoderSettingsView - supportedProtocols:', supportedProtocols)

  const alwaysEnabledProtocols = projectTranscoderSettings?.requiredProtocols ?? []
  const audioOnlyProtocols = projectTranscoderSettings?.audioOnlyProtocols ?? []
  console.log('ProjectTranscoderSettingsView - projectTranscoderSettings:', projectTranscoderSettings, ' audioOnlyProtocols:', audioOnlyProtocols)

  // -------

  const transcoderSettingsChanges = () => {
    console.log('ProjectTranscoderSettingsView - transcoderSettingsChanges')
    const _changes: Array<string> = []
    if (projectTranscoderValues) {
      for (const fieldName of Object.keys(projectTranscoderValues)) {
        const oldValue = (projectTranscoderSettings !== undefined ? (projectTranscoderSettings as any)[fieldName] : undefined) ?? [] // NB: default to an empty array if the server didn't give us a value for some reason (e.g. a project created before protocol support was added)
        const newValue = (projectTranscoderValues as any)[fieldName]
        console.log('ProjectTranscoderSettingsView - transcoderSettingsChanges - fieldName: ', fieldName, ' oldValue: ', oldValue, ' newValue: ', newValue)
        if (typeof oldValue === 'object' && typeof newValue === 'object') {
          // compare the old & new arrays - ref: https://stackoverflow.com/a/19746771
          if (Array.isArray(oldValue) && Array.isArray(newValue)) {
            const newValueSorted = newValue.slice().sort()
            const arraysMatch = oldValue.length === newValue.length && oldValue.slice().sort().every((value, index) => (value === newValueSorted[index]))
            if (!arraysMatch) {
              _changes.push(fieldName)
            }
          } else {
            // TODO: handle other object types (just 'object' itself??) - if/when we have a non array object..
          }
        } else {
          if (oldValue !== newValue) {
            _changes.push(fieldName)
          }
        }
      }
      console.log('ProjectTranscoderSettingsView - transcoderSettingsChanges - _changes:', _changes)
    }
    return _changes
  }

  // protocols specific change tracking (so we can track which protocols have changed)
  const protocolSettingsChanges = () => {
    console.log('ProjectTranscoderSettingsView - protocolSettingsChanges')
    const _changes: Array<string> = []
    if (changes.includes('protocols')) {
      // loop through each supported protocol & check if its project specific state has changed
      supportedProtocols.forEach(protocol => {
        const oldValue = projectTranscoderSettings?.protocols?.includes(protocol) ?? false
        const newValue = projectTranscoderValues.protocols.includes(protocol)
        if (oldValue !== newValue) {
          _changes.push(protocol)
        }
      })
    }
    console.log('ProjectTranscoderSettingsView - protocolSettingsChanges - _changes:', _changes)
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }
  // special handling for individual protocol entries (which all share a single project field)
  const protocolHasChanged = (protocol: string): boolean => {
    return hasChanges('protocols') && protocolChanges && protocolChanges.includes(protocol)
  }

  const resetSaveResults = () => {
    setSaveResult(false)
    setSaveError(undefined)
  }

  const resetSyncResults = () => {
    setSyncMode(undefined)
    setSyncRequiredResult(undefined)
    setSyncRequiredError(undefined)
    setSyncForcedResult(undefined)
    setSyncForcedError(undefined)
  }

  const transcoderToggleElement = (valueKey: string) => {
    // TESTING: current project level transcoder settings can only override the company level version if its enabled
    // TESTING: if the company value is disabled then the project value should also be disabled (& shouldn't be editable for now)
    // NB: if adding other project settings down the line, we may need to split this handling if company level settings don't always act the same...
    // UPDATE: extending for certain non company level transcoder values
    const hasCompanyValue = valueKey in (companyTranscoderValues as any)
    console.log('ProjectTranscoderSettingsView - transcoderToggleElement - valueKey:', valueKey, ' hasCompanyValue:', hasCompanyValue)
    const companyValue = (companyTranscoderValues as any)[valueKey]
    const value = hasCompanyValue && companyValue === false ? companyValue : (projectTranscoderValues as any)[valueKey]
    if (!isProjectAdminOrHigher) return (value ? 'ENABLED' : <span className={styles.propertyValueOff}>DISABLED</span>) // just display the value if the user isn't a project admin or higher
    const disabled = companyValue === false
    return (
      <ArkRadio
        toggle
        checked={value}
        disabled={disabled}
        onChange={(event: React.FormEvent<HTMLInputElement>, data: ArkRadioProps) => {
          console.log('ProjectTranscoderSettingsView - transcoderToggleElement - onChange - valueKey: ', valueKey, ' data.checked: ', data.checked)
          setProjectTranscoderValues({
            ...projectTranscoderValues,
            [valueKey]: data.checked
          })
          resetSaveResults()
          // NB: see the useEffect that runs transcoderSettingsChangeCount & updates setProgramChangeCount from it after the setValue state update completes
        }}
      />
    )
  }

  const protocolToggleElement = (valueKey: string) => {
    // NB: all enabled protocols are added within a single array property with the project transcoder settings, so we handle them specially here (all protocols share one array property/field)
    // NB: these currently DON'T have a company level override (project specifc)
    const alwaysEnabled = alwaysEnabledProtocols.includes(valueKey)
    const audioOnly = audioOnlyProtocols.includes(valueKey)
    const value = alwaysEnabled ? true : projectTranscoderValues.protocols.includes(valueKey)
    if (!isProjectAdminOrHigher) return (value ? 'ENABLED' : <span className={styles.propertyValueOff}>DISABLED</span>) // just display the value if the user isn't a project admin or higher
    const disabled = alwaysEnabled
    return (
      <>
        <ArkRadio
          toggle
          checked={value}
          disabled={disabled}
          onChange={(event: React.FormEvent<HTMLInputElement>, data: ArkRadioProps) => {
            console.log('ProjectTranscoderSettingsView - protocolToggleElement - onChange - valueKey: ', valueKey, ' data.checked: ', data.checked)
            if (data.checked && !projectTranscoderValues.protocols.includes(valueKey)) {
              // add the protocol to the array
              setProjectTranscoderValues({
                ...projectTranscoderValues,
                protocols: [...projectTranscoderValues.protocols, valueKey]
              })
            } else if (!data.checked && projectTranscoderValues.protocols.includes(valueKey)) {
              // remove the protocol from the array
              setProjectTranscoderValues({
                ...projectTranscoderValues,
                protocols: projectTranscoderValues.protocols.filter(protocol => protocol !== valueKey)
              })
            }
            resetSaveResults()
            // NB: see the useEffect that runs transcoderSettingsChangeCount & updates setProgramChangeCount from it after the setValue state update completes
          }}
        />
        {alwaysEnabled ? <span className={styles.protocolAlwaysEnabled}>(Required)</span> : null}
        {audioOnly ? <span className={styles.protocolAudioOnly}>(Audio Only)</span> : null}
      </>
    )
  }

  const updateNumericElement = (valueKey: string, newValue: number) => {
    const value = (projectTranscoderValues as any)[newValue]
    if (value !== newValue) {
      setProjectTranscoderValues({
        ...projectTranscoderValues,
        [valueKey]: newValue
      })
    }
  }

  const hintElement = (hintType: HintType, hintIconSize: number, hintPopupSize: PopupSize, hintTitle: string, hintMessage: string, hintPopupPosition: PopupPosition) => {
    return (
      <ArkHint type={hintType} iconSize={hintIconSize} popupSize={hintPopupSize} title={hintTitle} message={hintMessage} popupPosition={hintPopupPosition}></ArkHint>
    )
  }

  const updateTranscoderWarnings = () => {
    console.log('ProjectTranscoderSettingsView - updateTranscoderWarnings - projectTranscoderValues: ', projectTranscoderValues)
    const vals = projectTranscoderValues
    const warnings: Array<string> = []
    // check if transcoders & SLDP are disabled, but SRT is still enabled
    if (vals.transcodersEnabled === false && vals.allowSLDPPassthrough === false && vals.allowSRTPassthrough === true) {
      console.log('ProjectTranscoderSettingsView - updateTranscoderWarnings - TRANSCODERS AND SLDP DISABLED (SRT STILL ENABLED)...')
      warnings.push('With SLDP Passthrough and Transcoders off - it will mean viewing on the RePro Web App (browser viewing) is impossible.')
    // check if transcoders, SLDP, & SRT are all disabled
    } else if (vals.transcodersEnabled === false && vals.allowSLDPPassthrough === false && vals.allowSRTPassthrough === false) {
      console.log('ProjectTranscoderSettingsView - updateTranscoderWarnings - TRANSCODERS, SLDP AND SRT ALL DISABLED...')
      warnings.push('With SLDP Passthrough, SRT Passthrough and Transcoders off - viewing on RePro iOS and web apps, as well as endpoint SRT decoders will be impossible. All other Protocols (if any are enabled) will work.') // like RTMP, RTMPS etc
    // check if just SRT is disabled
    } else if (vals.allowSRTPassthrough === false) {
      console.log('ProjectTranscoderSettingsView - updateTranscoderWarnings - SRT DISABLED')
      warnings.push('With SRT Passthrough off - it will not be possible to use hardware SRT decoders at the receiving end. The RePro iOS App will also lose SRT mode viewing.')
    }
    setTranscoderWarnings(warnings)
  }

  // -------

  const _applyProjectTranscoderSettings = (_transcoderSettings?: ProjectTranscoderSettings) => {
    console.log('ProjectTranscoderSettingsView - _applyProjectTranscoderSettings - _transcoderSettings: ', _transcoderSettings)
    setProjectTranscoderSettings(_transcoderSettings)
    if (_transcoderSettings) {
      setProjectTranscoderValues({
        ...projectTranscoderValues,
        protocols: _transcoderSettings.protocols,
        allowSLDPPassthrough: _transcoderSettings.allowSLDPPassthrough,
        allowSRTPassthrough: _transcoderSettings.allowSRTPassthrough,
        transcodersEnabled: _transcoderSettings.transcodersEnabled,
        hotlinkValidMins: _transcoderSettings.hotlinkValidMins
      })
    }
  }

  const _applyCompanyTranscoderSettings = (_transcoderSettings?: CompanyTranscoderSettings) => {
    setCompanyTranscoderSettings(_transcoderSettings)
    if (_transcoderSettings) {
      setCompanyTranscoderValues({
        ...companyTranscoderValues,
        allowSLDPPassthrough: _transcoderSettings.allowSLDPPassthrough,
        allowSRTPassthrough: _transcoderSettings.allowSRTPassthrough
      })
    }
  }

  // -------

  const onSave = async () => {
    const changedValues: {[key: string]: any} = {}
    let changesCount = 0
    for (const fieldName of changes) {
      const newValue = (projectTranscoderValues as any)[fieldName]
      changedValues[fieldName] = newValue
      changesCount++
    }
    console.log('ProjectTranscoderSettingsView - onSave - changedValues: ', changedValues, ' changesCount: ', changesCount)
    if (changesCount > 0) {
      resetSaveResults()
      setSaving(true)
      // await new Promise((resolve) => setTimeout(resolve, 1000)) // DEBUG ONLY
      try {
        // update/save the company info - returns a new Project object with the latest values
        // NB: the Project returned here does NOT include the company transcoder settings, but those shouldn't have changed, so we ignore that for now
        const savedProject = await projectAdminContext.actions.updateProjectInfo(companyId, project.id, changedValues)
        _applyProjectTranscoderSettings(savedProject?.transcoderSettings)
        console.log('ProjectTranscoderSettingsView - onSave - savedProject: ', savedProject)
        setSaveResult(true)
        resetSyncResults()

        // TESTING: trigger a project data update so project protocol changes are available (e.g. to update the audio only protocols state)
        // TODO: only trigger the update if protocols have changed (audio only protocols currently?)
        // WARNING: this causes the whole page (& higher up the component tree/stack) to reload & any success message to be reset/hidden
        // WARNING: (& any other forms on that page with unsaved changes are likely to be reset? although currently none that don't auto save on change, but could have some down the line...)
        // NB: originally called the main `reloadUserData` function but added this new project specific update data one (which still has certain issues/limitations & so causes the whole page to reload)
        await userContext.actions.updateSelectedProject()
      } catch (error) {
        console.error('ProjectTranscoderSettingsView - onSave - error: ', error)
        setSaveResult(false)
        setSaveError(error)
        resetSyncResults()
      }
      setSaving(false)
    }
  }

  const onReset = async () => {
    _applyProjectTranscoderSettings(projectTranscoderSettings)
    resetSaveResults()
  }

  const onSyncTranscoder = async (force?: boolean) => {
    if (!isProjectAdminOrHigher) return
    try {
      console.log('ProjectTranscoderSettingsView - onSyncTranscoder - force:', force)
      resetSyncResults()
      setIsSyncing(true)
      setSyncMode(force ? 'force' : 'required')
      if (!force) {
        setSyncRequiredResult(undefined)
        setSyncRequiredError(undefined)
      } else {
        setSyncForcedResult(undefined)
        setSyncForcedError(undefined)
      }
      const _syncResult = await projectAdminContext.actions.syncProjectTranscoderSettings(companyId, project.id, force)
      console.log('ProjectTranscoderSettingsView - onSyncTranscoder - _syncResult: ', _syncResult)
      // DEBUG ONLY - START
      // await new Promise((resolve) => setTimeout(resolve, 1000)) // DEBUG ONLY
      // // throw new Error('DEBUG')
      // const _syncResult = true
      // DEBUG ONLY - END
      if (!force) {
        setSyncRequiredResult(_syncResult)
      } else {
        setSyncForcedResult(_syncResult)
      }
    } catch (error) {
      console.error('ProjectTranscoderSettingsView - onSyncTranscoder - error: ', error)
      if (!force) {
        setSyncRequiredResult(undefined)
        setSyncRequiredError(error)
      } else {
        setSyncForcedResult(undefined)
        setSyncForcedError(error)
      }
    }
    setIsSyncing(false)
    setSyncMode(undefined)
  }

  // -------

  // load the company level transcoder settings from the passed in project object
  useEffect(() => {
    _applyCompanyTranscoderSettings(project.companyTranscoderSettings)
  }, [project])

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = transcoderSettingsChanges()
    setChanges(_changes)
  }, [projectTranscoderValues])
  // run the protocol specific changes after the main transcoder changes have been processed
  useEffect(() => {
    const _protocolChanges = protocolSettingsChanges()
    setProtocolChanges(_protocolChanges)
  }, [changes])

  // check & flag if audio only programs are in use but no audio only protocols are enabled (triggers a warning to show if so)
  useEffect(() => {
    let _hasAudioOnlyPrograms = false
    if (projectPrograms.length > 0) {
      for (const program of projectPrograms) {
        if (program.isAudioOnly) {
          _hasAudioOnlyPrograms = true
          break
        }
      }
    }
    if (_hasAudioOnlyPrograms && !projectTranscoderSettings?.hasAudioOnlyProtocolsEnabled(projectTranscoderValues.protocols)) {
      setShowNoAudioOnlyProtocolWarning(true)
    } else {
      setShowNoAudioOnlyProtocolWarning(false)
    }
  }, [projectPrograms, projectTranscoderSettings, projectTranscoderValues])

  // check & flag if certain combos of transcoder settings are disabled
  useEffect(() => {
    updateTranscoderWarnings()
  }, [projectTranscoderValues])

  // -------

  const transcoderSummary = () => {
    if (!companyTranscoderSettings) return null
    let maxRes = '360p'
    if (companyTranscoderSettings.allowABR1080) {
      maxRes = '1080p'
    } else if (companyTranscoderSettings.allowABR720) {
      maxRes = '720p'
    }
    return (
      (companyTranscoderSettings && (
        <>
          Max Resolution: {maxRes}
          {!companyTranscoderSettings.allowWatermarking && (
            <>
              <br />Watermarking: Disabled
            </>
          )}
        </>
      ))
    )
  }

  // -------

  // NB: the original fields are NOT using ArkForm currently, but as additional field types have been needed, (& also toggle support recently added to ArkForm) have decided to start using it for at least new fields for now
  // TODO: look to port all form fields to use ArkForm?
  const formFields: Array<ArkFormField> = []

  if (HOTLINK_ENABLED) {
    const defaultValidMins = 30 // TODO: move to api supplied server config once it adds a field for this
    const hotlinkValidMinOptions: Array<ArkFormFieldOption> = []
    hotlinkValidMinOptions.push({ key: 'default', text: (defaultValidMins + ' mins (default)'), value: defaultValidMins, selectedText: defaultValidMins + ' mins' })
    hotlinkValidMinOptions.push({ key: '20', text: '20 mins', value: 20 })
    hotlinkValidMinOptions.push({ key: '15', text: '15 mins', value: 15 })
    hotlinkValidMinOptions.push({ key: '10', text: '10 mins', value: 10 })
    hotlinkValidMinOptions.push({ key: '5', text: '5 mins', value: 5 })
    hotlinkValidMinOptions.push({ key: '2', text: '2 mins', value: 2 })
    hotlinkValidMinOptions.push({ key: '1', text: '1 min', value: 1 }) // DEBUG ONLY?
    formFields.push(
      {
        type: ArkFormFieldType.Dropdown,
        key: 'hotlinkValidMins',
        // label: 'Hotlink Valid Mins',
        required: false,
        defaultValue: (projectTranscoderSettings?.hotlinkValidMins ? projectTranscoderSettings?.hotlinkValidMins : 0), // TODO: <<<
        options: hotlinkValidMinOptions,
        className: styles.hotlinkValidMins,
        // wrapperProps: { style: { marginBottom: '8px' } } // NB: current default vertical spacing/margin within a fieldset (but no group?) seems to be 0, temp forcing to add a little space between feeds
        fieldProps: { scrolling: true } // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
      }
    )
  }
  const onFormValueChanged = async (fieldKey: string, _fieldValue: any, _oldFieldValue: any) => {
    if (fieldKey === 'hotlinkValidMins') {
      console.log('ProjectTranscoderSettingsView - onFormValueChanged - hotlinkValidMins - CHANGED FROM:', _oldFieldValue, ' TO:', _fieldValue)
      updateNumericElement(fieldKey, _fieldValue)
    }
  }

  // const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {}

  // -------

  return (
    <div className={styles.transcoderSettings}>
      <div className={styles.content}>
        <ArkForm
          formKey="projectTranscoderSettings"
          inverted
          // formError={error}
          formFields={formFields}
          formSchema={formSchema}
          // onFormSubmit={onFormSubmit}
          onValueChanged={onFormValueChanged}
          showLabels={true}
          placeholderInAnyProps={true} // enable searching for form field placeholders in any prop (so `ArkPanel.PropertyRow` `value` based field wrappers work with `ArkFormFieldPlaceholder`)
        >
          <ArkPanel bordered>
            <ArkPanel.Header title={OBJECT_PROJECT_NAME + ' ' + OBJECT_PROGRAM_NAME + ' ' + PAGE_SETTINGS_NAME} bottomBorder className={styles.transcoderHeader}></ArkPanel.Header>
            {(<>
              <ArkPanel.Properties>
                {(saveResult || saveError) && (
                  <>
                    {saveResult && (
                      <ArkPanel.PropertyRow>
                        <ArkPanel.PropertyRowMessage positive>
                          <ArkMessage.Header>Saved</ArkMessage.Header>
                          <p>Your changes have been saved</p>
                        </ArkPanel.PropertyRowMessage>
                      </ArkPanel.PropertyRow>
                    )}
                    {saveError && (
                      <ArkPanel.PropertyRow>
                        <ArkPanel.PropertyRowMessage negative>
                          <ArkMessage.Header>Error</ArkMessage.Header>
                          <p>{saveError.message}</p>
                        </ArkPanel.PropertyRowMessage>
                      </ArkPanel.PropertyRow>
                    )}
                  </>
                )}
                <div className={styles.protocolsHeader}>Protocols:</div>
                {showNoAudioOnlyProtocolWarning && (
                  <ArkPanel.PropertyRow>
                    <ArkPanel.PropertyRowContent>
                      <div className={styles.noAudioOnlyProtocolWarning}>
                        <div className={styles.warningIcon}><ArkIcon name='warning' size={28} color={'#f3be0e'} /></div>
                        <div className={styles.warningText}>Some project programs are audio only but no audio only protocols are enabled.</div>
                      </div>
                    </ArkPanel.PropertyRowContent>
                  </ArkPanel.PropertyRow>
                )}
                {/* TESTING: quick mock-up of all protocols listed in a single ArkPanel.PropertyRow (would need to add a checkbox for each one & custom handling for it) */}
                {/* <ArkPanel.PropertyRow
                  title='Protocols:'
                  value={<>{supportedProtocols.map(protocol => <>{protocol}<br /></>)}</>}
                  hint={undefined}
                  hasChanges={hasChanges('protocols')}
                  titleClassName={styles.propertyTitle}
                  valueClassName={styles.propertyValue}
                /> */}
                {/* TESTING: quick mock-up of each protocols being in the left title column of a panel */}
                {supportedProtocols.map(protocol => {
                  return (
                    <ArkPanel.PropertyRow
                      key={'protocol_' + protocol}
                      title={protocol}
                      value={protocolToggleElement(protocol)}
                      hint={undefined}
                      hasChanges={protocolHasChanged(protocol)}
                      titleClassName={styles.propertyTitle}
                      valueClassName={styles.propertyValue}
                    />
                  )
                })}
                <ArkPanel.PropertyDivider />
                <ArkPanel.PropertyRow
                  title='SLDP Passthrough:'
                  value={transcoderToggleElement('allowSLDPPassthrough')}
                  hint={hintElement(
                    'info-circle',
                    22,
                    'small',
                    'Low Delay Passthrough (Web and iOS app)',
                    'Allows a non-watermarked, non transcoded passthrough version that can viewed on web and iOS using low delay delivery method.', 'bottom left'
                  )}
                  hasChanges={hasChanges('allowSLDPPassthrough')}
                  titleClassName={styles.propertyTitle}
                  valueClassName={styles.propertyValue}
                />
                <ArkPanel.PropertyRow
                  title='SRT Passthrough:'
                  value={transcoderToggleElement('allowSRTPassthrough')}
                  hint={hintElement(
                    'info-circle',
                    22,
                    'small',
                    'SRT Passthrough (iOS app only)',
                    'Allows a non-watermarked, non transcoded passthrough version that can viewed on iOS only using Secure Reliable Transport method.',
                    'bottom left'
                  )}
                  hasChanges={hasChanges('allowSRTPassthrough')}
                />
                <ArkPanel.PropertyRow
                  title='Transcoders Enabled:'
                  value={transcoderToggleElement('transcodersEnabled')}
                  hint={hintElement(
                    'info-circle',
                    22,
                    'small',
                    'Transcoders (ABR & Watermark)',
                    '⚠️ Caution! Turning this off will stop all ABR transcoding for the project. Transcoding is not required for SRT only pipelines or receiving in passthrough only (SRT & SLDP passthrough viewing in iOS app or SRT to hardware decoder for example).',
                    'bottom left'
                  )}
                  hasChanges={hasChanges('transcodersEnabled')}
                />
                {(transcoderWarnings.length > 0) && (
                  <ArkPanel.PropertyRow>
                    <ArkPanel.PropertyRowContent>
                      <div className={styles.transcoderDisabledWarnings}>
                        <div className={styles.title}>WARNING</div>
                        {transcoderWarnings.map((warning, index) => {
                          return (
                            <div key={'transcoder_warning_' + index} className={styles.warningMsg}>
                              {warning}
                            </div>
                          )
                        })}
                      </div>
                    </ArkPanel.PropertyRowContent>
                  </ArkPanel.PropertyRow>
                )}
                {HOTLINK_ENABLED && (<>
                  <ArkPanel.PropertyDivider />
                  <ArkPanel.PropertyRow>
                    <ArkPanel.PropertyRowContent>
                      <div className={styles.hotlinkHeader}>Hotlink Protection:</div>
                      <p className={styles.hotlinkNotice}>Set hotlink protection individually per program in each program settings.</p>
                    </ArkPanel.PropertyRowContent>
                  </ArkPanel.PropertyRow>
                  <ArkPanel.PropertyRow
                    title='Hotlink expiry period:'
                    value={<ArkFormFieldPlaceholder fieldKey="hotlinkValidMins" />}
                    hint={hintElement(
                      'info-circle',
                      22,
                      'small',
                      'Hotlink expiry period',
                      'Set a period that program urls will be valid before they expire. A shorter period is more secure but may mean when refreshing players it takes longer to load more often. A longer period means less need to load latest valid hotlink url and thus refreshing players is usually quicker - but can be considered less secure. The expiry period means how long a program link is valid for - it does not mean that players need to restart after the period - any program url loaded will last until it is stopped playing.',
                      'bottom left'
                    )}
                    hasChanges={hasChanges('hotlinkValidMins')}
                  />
                </>)}
                <ArkPanel.PropertyDivider />
                <ArkPanel.PropertyRow>
                  <ArkPanel.PropertyRowContent>
                    <div className={styles.transcoderSummary}>
                      {transcoderSummary()}
                    </div>
                  </ArkPanel.PropertyRowContent>
                </ArkPanel.PropertyRow>
                <ArkPanel.PropertyDivider />
                <ArkPanel.PropertyRow>
                  <ArkPanel.PropertyRowContent className={styles.transcoderSync}>
                    <div className={styles.transcoderSyncTitle}>Transcoder Settings Sync</div>
                    {/* NB: only show the 'sync required' prompt when needed */}
                    {isProjectAdminOrHigher && (project.transcoderSynced === false || syncRequiredResult !== undefined || syncRequiredError !== undefined) && (
                      <ProjectTranscoderSettingsSyncView
                        className={styles.transcoderSyncRequired}
                        syncing={isSyncing && syncMode === 'required'}
                        allow={!isSyncing}
                        syncResult={syncRequiredResult}
                        syncError={syncRequiredError}
                        onSyncTranscoder={() => onSyncTranscoder(false)}
                      />
                    )}
                    {/* NB: show the 'force sync' prompt at all times */}
                    {isProjectAdminOrHigher && (
                      <ProjectTranscoderSettingsSyncView
                        className={styles.transcoderSyncForce}
                        syncing={isSyncing && syncMode === 'force'}
                        allow={!isSyncing}
                        syncResult={syncForcedResult}
                        syncError={syncForcedError}
                        onSyncTranscoder={() => onSyncTranscoder(true)}
                        force={true}
                      />
                    )}
                  </ArkPanel.PropertyRowContent>
                </ArkPanel.PropertyRow>
                {/* {(isProjectAdminOrHigher && (project.transcoderSynced === false || syncResult !== undefined || syncError !== undefined) && (
                  <>
                    <ArkPanel.PropertyDivider />
                    <ArkPanel.PropertyRow>
                      <ArkPanel.PropertyRowContent className={styles.transcoderSync}>
                        {(syncResult !== undefined) && (
                          <>
                            {syncResult && (
                              <div className={styles.transcoderSyncSuccess}>
                                <div className={styles.title}>SYNC SUCCESS</div>
                                <div className={styles.text}>Transcoder settings have been applied to the project programs.</div>
                              </div>
                            )}
                            {!syncResult && (
                              <div className={styles.transcoderSyncSuccess}>
                                <div className={styles.title}>SYNC NOT NEEDED</div>
                                <div className={styles.text}>Transcoder settings have already been applied to the project programs.</div>
                              </div>
                            )}
                          </>
                        )}
                        {(syncResult === undefined) && (
                          <>
                          {syncError && (
                            <div className={styles.transcoderSyncError}>
                              <div className={styles.title}>SYNC ERROR</div>
                              <div className={styles.text}>{syncError.message}</div>
                            </div>
                          )}
                          <div className={styles.transcoderSyncWarning}>
                            <div>
                              WARNING: Transcoder settings need to be applied to this project.
                              Doing so will restart all active programs &amp; may briefly interrupt playback.
                            </div>
                            <ArkButton type='button' size='medium' color='orange' loading={syncing} onClick={onSyncTranscoder}>SYNC</ArkButton>
                          </div>
                          </>
                        )}
                      </ArkPanel.PropertyRowContent>
                    </ArkPanel.PropertyRow>
                  </>
                ))} */}
              </ArkPanel.Properties>
              {isProjectAdminOrHigher && (
                <ArkPanel.Footer className={styles.transcoderFooter} topBorder>
                  <div className={styles.footerWrapper}>
                    {/* {changes.length > 0 && (<>{changes.length} CHANGES: {changes.join(', ')}</>)} */}
                    {/* {changes.length === 0 && (<>NO CHANGES</>)} */}
                    {/* {changes.length > 0 && (
                      <div className={styles.saveChanges}>
                        Save changes made
                      </div>
                    )} */}
                    {changes.length === 0 && (<div></div>)}
                    <div className={styles.buttons}>
                      <ArkButton type='button' size='medium' color='red' disabled={changes.length === 0 || saving} onClick={onReset}>CLEAR CHANGES</ArkButton>
                      <ArkButton type='button' size='medium' color='green' disabled={changes.length === 0} loading={saving} onClick={onSave}>SAVE CHANGES</ArkButton>
                    </div>
                  </div>
                </ArkPanel.Footer>
              )}
            </>)}
          </ArkPanel>
        </ArkForm>
      </div>
    </div>
  )
}

export { ProjectTranscoderSettingsView }
