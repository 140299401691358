import React, { useEffect, useState } from 'react'

import ArkForm, { ArkFormField, ArkFormFieldType } from '../ArkForm/ArkForm'
import ArkModal from '../ArkModal'

import { Header } from 'semantic-ui-react'

export enum ArkConfirmModalMode {
  default, // NB: placeholder only for the time being until all use cases have a dedicated mode/type & are updated to use them
  warning
  // TODO: add other confirm modal modes/types to cover all uses cases that we want to support with certain styling for each one
}

export interface ArkConfirmModalProps {
  title: string | React.ReactNode
  message: string | React.ReactNode
  show: boolean
  mode?: ArkConfirmModalMode // TODO: make this required once all cases are covered by ArkConfirmModalMode
  onConfirm: Function
  onCancel?: Function
  onClose?: Function
  hideCancelButton?: boolean
}

const ArkConfirmModal = (props: ArkConfirmModalProps) => {
  const { title, message, show, mode, onCancel: _onCancel, onConfirm: _onConfirm, onClose: _onClose, hideCancelButton } = props

  const [showConfirm, setShowConfirm] = useState<boolean>(show)

  useEffect(() => {
    setShowConfirm(show)
  }, [show])

  const onCancel = () => {
    console.log('ArkConfirmModal - onCancel - showConfirm: ', showConfirm)
    // ignore if its already hidden (e.g. cancel is clicked & this fires straight away & then again when the modal actually closes)
    if (showConfirm) {
      setShowConfirm(false)
      if (_onCancel) _onCancel()
    }
  }

  const onConfirm = () => {
    console.log('ArkConfirmModal - onConfirm')
    setShowConfirm(false)
    _onConfirm()
  }

  const onClose = () => {
    console.log('ArkConfirmModal - onClose')
    if (_onClose) _onClose()
  }

  const renderModal = () => {
    const showCancelButton = hideCancelButton !== true
    const formFields: Array<ArkFormField> = []
    formFields.push({
      type: ArkFormFieldType.Group,
      key: 'buttons',
      fields: [
        ...(showCancelButton
          ? [
            {
              type: ArkFormFieldType.CancelButton,
              key: 'cancel',
              label: 'CANCEL',
              fieldProps: { onClick: onCancel /*, floated: 'left' */ }
            }
          ]
          : []),
        {
          type: ArkFormFieldType.OKButton,
          key: 'submit',
          label: 'OK',
          fieldProps: {
            /* loading: isSubmitting, */
            floated: 'right',
            ...(mode === ArkConfirmModalMode.warning && { color: 'orange', basic: false })
          }
        }
      ],
      fieldProps: { widths: 'equal' /* widths: 1, floated: 'right' */, style: { border: '1px solid red !important' } },
      slimline: true
    })
    return (
      <ArkModal size='tiny' open={showConfirm} onClose={() => onClose()}>
        <div style={{ paddingBottom: 20 }}>
          <Header as='h2' inverted>{title}</Header>
          <div>{message}</div>
        </div>
        <ArkForm
          formKey="modalConfirm" // WARNING: there should never be more than 1 of these on the page at a time? (or we should require a unique key & append/use it here to work around it, so the form handling works as expected? (enter key handling))
          inverted
          formFields={formFields}
          // formError={error}
          onFormSubmit={onConfirm}
        ></ArkForm>
      </ArkModal>
    )
  }

  return (
    <>
      {renderModal()}
    </>
  )
}

export default ArkConfirmModal
