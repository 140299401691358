/**
 * ArkSectionCard
 */

import React, { useContext } from 'react'

import { ResponsiveContext } from 'src/core/providers/ResponsiveProvider'

import ArkButton from '../ArkButton'
import ArkIcon, { IconName } from '../ArkIcon'
import ArkSpacer from '../ArkSpacer'

import styles from './ArkSectionCard.module.css'

/**
* card
*/

interface ArkSectionCardProps {
  iconName: IconName
  manageTitle?: string
  name: string
  number?: number
  numberHidden?: number
  numberMax?: number
  onNumberHiddenClick?: () => void
  onManageClick?: () => void
  title?: string
}

const ArkSectionCard = (props: ArkSectionCardProps) => {
  const {
    iconName,
    manageTitle = 'Manage',
    name,
    number,
    numberHidden,
    numberMax,
    onNumberHiddenClick,
    onManageClick,
    title
  } = props

  const { store } = useContext(ResponsiveContext)
  const { isMobile } = store

  return (
    <div className={`${styles.card} ${isMobile ? styles.mobile : ''}`}>
      <div className={styles.cardTop}>
        <ArkIcon name={iconName} size={36} />
        <div className={styles.cardRight}>
          {title && <div className={styles.cardTitle}>{title}</div>}
          <div className={styles.cardName}>
            {number !== undefined && (
              <>
                <div className={styles.cardNumber}>
                  {number}
                  {numberMax !== undefined && <span>{`/${numberMax}`}</span>}
                </div>
                <ArkSpacer />
              </>
            )}
            {name}
          </div>
          {!isMobile && !!numberHidden && (
            <>
              <ArkSpacer />
              <div className={styles.cardHidden} onClick={onNumberHiddenClick}>{`(${numberHidden} hidden)`}</div>
            </>
          )}
        </div>
      </div>
      <ArkSpacer grow />
      {onManageClick !== undefined && (
        <ArkButton className={styles.cardButton} floated='right' onClick={onManageClick} size='tiny'>
          {manageTitle}
        </ArkButton>
      )}
    </div>
  )
}

export default ArkSectionCard

/**
* group
*/

export interface ArkSectionCardGroupProps {
  children: React.ReactNode
}

export const ArkSectionCardGroup = (props: ArkSectionCardGroupProps) => {
  const { children } = props

  const { store } = useContext(ResponsiveContext)
  const { isMobile } = store

  return (
    <div className={`${styles.group} ${isMobile ? styles.mobile : ''}`}>
      {children}
    </div>
  )
}
