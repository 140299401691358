import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as yup from 'yup'

import { withAuthContext, IAuthMultiContext } from '../../../providers'
import { ServerError } from '../../../services/ServerAPIErrors'
import * as ROUTES from '../../../../constants/routes'

import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from '../../../components/ArkForm/ArkForm'
import { Divider, Message } from 'semantic-ui-react'

const formSchema = yup.object().shape({
  email: yup.string().email().required()
})

interface IProps extends IAuthMultiContext {
}
interface IState {
  error?: Error
  isSubmitting: boolean
  emailSent: boolean
}

class LoginPasswordResetStage1Form extends Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      isSubmitting: false,
      emailSent: false
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { error, isSubmitting, emailSent } = this.state

    const formFields: Array<ArkFormField> = []
    formFields.push({ type: ArkFormFieldType.Input, key: 'email', label: 'Email Address', defaultValue: '', required: true })
    formFields.push({ type: ArkFormFieldType.Button, key: 'submit', label: 'Reset Password', fieldProps: { loading: isSubmitting, fluid: true } })

    return (
      <>
        {emailSent && (
          <>
            <Message positive>
              <Message.Header>Email Sent</Message.Header>
              <Message.Item>A password reset link has been sent to your email.<br /><strong>Please follow the instructions in the email to reset your password.</strong></Message.Item>
            </Message>
            <div style={{ textAlign: 'center', textDecoration: 'underline' }}>
              <Link to={ROUTES.LOGIN}>Login</Link>
            </div>
          </>
        )}
        {!emailSent && (
          <>
            <ArkForm
              formKey="passwordReset"
              className="password-reset-form"
              inverted
              formError={error}
              formFields={formFields}
              formSchema={formSchema}
              onFormSubmit={this.onFormSubmit}
            ></ArkForm>
            <Divider horizontal inverted></Divider>
            <div style={{ textAlign: 'center', textDecoration: 'underline' }}>
              <Link to={ROUTES.LOGIN}>or Login</Link>
            </div>
          </>
        )}
      </>
    )
  }

  onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    if (this.state.isSubmitting) return

    const { email } = fieldValues

    this.setState({ isSubmitting: true })

    this.props.authContext.actions.forgotEmailPassword(email)
      .then(() => {
        if (this._isMounted) this.setState({ isSubmitting: false, emailSent: true })
      })
      .catch((error: any) => {
        console.error('LoginPasswordResetStage1Form - onFormSubmit - signInWithEmailAndPassword - ERROR: ', error)
        if (error instanceof ServerError) {
          console.error('LoginPasswordResetStage1Form - onFormSubmit - signInWithEmailAndPassword - ServerError: ', error)
        }
        this.setState({ isSubmitting: false, error })
      })
  }
}

export default withAuthContext(LoginPasswordResetStage1Form)
