import React, { useContext, useEffect, useRef } from 'react'
import * as yup from 'yup'

import { ProjectAdminContext } from 'src/core/providers'

import { CompanyUser, ProjectUser, UserProjectRole, ProjectInviteUser, ProjectInviteUserType } from 'src/core/models'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkButton from 'src/core/components/ArkButton'
import ArkDivider from 'src/core/components/ArkDivider'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps, ArkFormFieldOption } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkMessage from 'src/core/components/ArkMessage'

import { OBJECT_COMPANY_NAME, OBJECT_PROJECT_NAME, OBJECT_USER_NAME, ROLE_ADMIN_NAME, ROLE_MANAGER_NAME, ROLE_MEMBER_NAME } from 'src/constants/strings'

import styles from './ProjectAddUserForm.module.css'

const formSchema = yup.object().shape({
  // NB: nullable & transform added to allow empty string to be treated as 'not set'
  // refs: https://github.com/jquense/yup/issues/1086#issuecomment-988143675 & https://stackoverflow.com/a/14227612
  invite_email: yup.string().optional().email().min(4).max(255).nullable().transform((value) => value && value.trim().length > 0 ? value : null).label('Email')
})

interface IProps {
  companyId: number
  projectId: number
  projectUsers: Array<ProjectUser>
  onCancel?: Function
  onSave?: Function
  onClose?: Function
  insideModal?: boolean // ArkForm prop - enable when showing this form within a modal (so fieldset label bg's match)
}

const ProjectAddUserForm = (props: IProps) => {
  const mounted = useRef(false)

  const { companyId, projectId, projectUsers, onCancel: _onCancel, onSave: _onSave, onClose: _onClose, insideModal } = props

  const { actions: projectAdminActions } = useContext(ProjectAdminContext)

  const [loading, setLoading] = React.useState<boolean>(false)
  const [companyUsers, setCompanyUsers] = React.useState<Array<CompanyUser>>([])
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [hasSaved, setHasSaved] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>(undefined)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const loadCompanyUsers = async () => {
    if (loading === true) return false
    if (companyId) {
      try {
        setLoading(true)
        const companyUsers = await projectAdminActions.getCompanyUsers(companyId)
        // TESTING: filter out any users already added to this project
        const nonProjectUsers: Array<CompanyUser> = []
        if (companyUsers) {
          if (projectUsers) {
            for (const companyUser of companyUsers) {
              let isNonProjectUser = true
              for (const projectUser of projectUsers) {
                if (companyUser.id === projectUser.id) {
                  isNonProjectUser = false
                  break
                }
              }
              if (isNonProjectUser) {
                nonProjectUsers.push(companyUser)
              }
            }
          }
        }
        if (mounted.current) {
          setLoading(false)
          setCompanyUsers(nonProjectUsers)
        }
      } catch (error) {
        console.error('ProjectAddUserForm - loadCompanyUsers - error: ', error)
        if (mounted.current) {
          // TODO: add an error prop & display an error message if this happens
          setLoading(false)
          setCompanyUsers([])
        }
      }
    }
  }

  useEffect(() => {
    loadCompanyUsers()
  }, [])

  // -------

  const inviteUser = async (inviteType: ProjectInviteUserType, userId?: number, email?: string, projectRole?: UserProjectRole) => {
    console.log('ProjectAddUserForm - inviteUser - inviteType: ', inviteType, ' userId: ', userId, ' email: ', email, ' projectRole: ', projectRole)
    try {
      setIsSubmitting(true)
      setHasSaved(false)
      setError(undefined)
      await new Promise(resolve => setTimeout(resolve, 500)) // add a brief delay, so the user see's the loading indicator on quick api calls
      const inviteData: ProjectInviteUser = { type: inviteType }
      inviteData.role = projectRole ?? UserProjectRole.member
      if (inviteType === ProjectInviteUserType.user) inviteData.userId = userId
      if (inviteType === ProjectInviteUserType.email) inviteData.email = email
      const inviteResult = await projectAdminActions.inviteUserToProject(companyId, projectId, inviteData)
      console.log('ProjectAddUserForm - inviteUser - inviteUserToProject - inviteResult: ', inviteResult)
      if (inviteResult) {
        // parse the invite result - NB: returns a ProjectInviteUserResult which may be a success or contain an error (instead of certain errors being thrown)
        if (inviteResult.result === true) {
          if (mounted.current) {
            setIsSubmitting(false)
            setHasSaved(true)
          }
          if (_onSave) _onSave()
        } else {
          if (mounted.current) {
            setIsSubmitting(false)
            setError(inviteResult.error ?? Error('A problem occurred inviting the user, please try again.')) // display the api returned error if one is set, otherwise show a generic error msg
          }
        }
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          setError(Error('A problem occurred inviting the user, please try again.'))
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('ProjectAddUserForm - onFormSubmit - fieldValues: ', fieldValues)
    const { user, invite_email: email, projectRole } = fieldValues
    const userId = user && user !== '' ? parseInt(user) : undefined
    console.log('ProjectAddUserForm - onFormSubmit - userId: ', userId, ' email: ', email, ' projectRole: ', projectRole)

    // don't allow form submission if a user is selected (in the dropdown) & the email field is set, or both are empty
    const userSet = !!(userId && userId > 0)
    const emailSet = !!(email && email.trim().length !== 0)
    const userAndEmailSet = userSet && emailSet
    const userAndEmailNotSet = !userSet && !emailSet
    console.log('ProjectAddUserForm - onFormSubmit - userSet: ', userSet, ' emailSet: ', emailSet, ' userAndEmailSet: ', userAndEmailSet, ' userAndEmailNotSet: ', userAndEmailNotSet)

    if (userAndEmailSet || userAndEmailNotSet) {
      // TODO: ideally we'd set errors for both fields directly, so they highlight (would likely need to add an optional validation callback to ArkForm to support this?)
      setError(Error('Select an existing ' + OBJECT_COMPANY_NAME + ' ' + OBJECT_USER_NAME + ' OR enter an email of a new ' + OBJECT_USER_NAME + ' to invite'))
      return
    }

    setError(undefined)

    const inviteType = (userSet ? ProjectInviteUserType.user : ProjectInviteUserType.email)
    inviteUser(inviteType, (userSet ? userId : undefined), (emailSet ? email : undefined), projectRole)
  }

  const onCancel = () => {
    if (_onCancel) _onCancel()
  }

  const onClose = () => {
    if (_onClose) _onClose()
  }

  // -------

  if (loading) return <ArkLoaderView message='Loading' />

  const formFields: Array<ArkFormField> = []

  const companyUserOptions: Array<ArkFormFieldOption> = []
  companyUserOptions.push({ key: 'user_none', text: 'Select ' + OBJECT_COMPANY_NAME + ' ' + OBJECT_USER_NAME, value: 0 })
  if (companyUsers) {
    for (const user of companyUsers) {
      if (user.isGuest) continue // don't add guest users (they can't be added to other projects)
      companyUserOptions.push({
        key: 'user_' + user.id,
        // text: user.name() + ' - (' + user.email + ')',
        value: user.id,
        // TESTING: ArkForm.Dropdown new custom `children` options prop support (to allow for non-text content)
        children: (
          <div className={styles.userOption + ' text'}>{/* NB: set a global/generic 'text' className to inherit the correct text colour */}
            <ArkAvatar
              type={user.userAvatarType()}
              className={styles.userAvatar}
              name={user.name()}
              size='30'
            />
            <div className={styles.userName}>{user.name() + ' - (' + user.email + ')'}</div>
          </div>
        )
      })
    }
  }

  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'inviteUser',
    label: 'Existing or new ' + OBJECT_USER_NAME,
    fields: [
      { type: ArkFormFieldType.Field, key: 'or_1', content: (<ArkDivider horizontal>ADD EXISTING</ArkDivider>) },
      {
        type: ArkFormFieldType.Dropdown,
        key: 'user',
        label: OBJECT_COMPANY_NAME + ' ' + OBJECT_USER_NAME,
        required: false,
        defaultValue: undefined,
        options: companyUserOptions,
        fieldProps: { scrolling: true } // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
      },
      { type: ArkFormFieldType.Field, key: 'break_1', content: (<p>The {OBJECT_USER_NAME} will be automatically added to this {OBJECT_PROJECT_NAME}.</p>) },
      { type: ArkFormFieldType.Field, key: 'or_2', content: (<ArkDivider horizontal>OR INVITE NEW</ArkDivider>) },
      { type: ArkFormFieldType.Input, key: 'invite_email', label: 'New email address', required: false, fieldProps: { autoComplete: 'off' } },
      { type: ArkFormFieldType.Field, key: 'email_note', content: (<p>This will email an invite for the {OBJECT_COMPANY_NAME} and add {OBJECT_USER_NAME} to this {OBJECT_PROJECT_NAME}.</p>) }
    ],
    // fieldProps: { widths: 2, inline: false, grouped: false },
    collapsible: false,
    collapsed: false
  })

  const projectRoles: Array<ArkFormFieldOption> = [
    { key: 'member', text: 'standard ' + ROLE_MEMBER_NAME, value: UserProjectRole.member },
    { key: 'manager', text: OBJECT_PROJECT_NAME + ' ' + ROLE_MANAGER_NAME, value: UserProjectRole.manager },
    { key: 'admin', text: OBJECT_PROJECT_NAME + ' ' + ROLE_ADMIN_NAME, value: UserProjectRole.admin }
  ]
  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'projectRoleFieldset',
    label: OBJECT_PROJECT_NAME + ' role',
    fields: [
      { type: ArkFormFieldType.Radio, key: 'projectRole', required: true, defaultValue: UserProjectRole.member, options: projectRoles }
    ],
    collapsible: false,
    collapsed: false
  })

  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { onClick: onCancel, floated: 'left' } },
      { type: ArkFormFieldType.OKButton, key: 'submit', label: 'ADD', fieldProps: { loading: isSubmitting, floated: 'right' } }
    ],
    fieldProps: { widths: 'equal' }
  })

  return (
    <>
      <ArkHeader as="h2" inverted>
        Add {OBJECT_USER_NAME} to {OBJECT_PROJECT_NAME}
      </ArkHeader>

      {hasSaved && (<>
        <ArkMessage positive>
          <ArkMessage.Header>{OBJECT_USER_NAME} added</ArkMessage.Header>
          <ArkMessage.Item>The {OBJECT_USER_NAME} has been added successfully</ArkMessage.Item>
        </ArkMessage>
        <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={onClose} style={{ marginTop: 15 }}>
          OK
        </ArkButton>
      </>)}

      {!hasSaved && (
        <ArkForm
          formKey="userInvite"
          inverted
          formError={error}
          formFields={formFields}
          formSchema={formSchema}
          onFormSubmit={onFormSubmit}
          showLabels={true}
          insideModal={insideModal}
        >
        </ArkForm>)}
    </>
  )
}

export default ProjectAddUserForm
