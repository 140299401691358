/**
 * ArkBorder
 */

import React from 'react'

import { Header } from 'semantic-ui-react'

import styles from './ArkBorder.module.css'

export interface ArkBorderProps {
  title?: string | React.ReactNode
  children?: React.ReactNode
  className?: string
  light?: boolean
  padded?: boolean
}

const ArkBorder = (props: ArkBorderProps) => {
  const { title, children, className, light, padded, ...otherProps } = props
  return (
    <div {...otherProps} className={`${styles.border} ${className || ''} ${light ? styles.borderLight : ''} ${padded ? styles.padded : ''}`}>
      {title && (
        <>
          <div className={styles.label}>
            {(typeof title === 'string') && (<Header as="h3" inverted={true}>{title}</Header>)}
            {(typeof title !== 'string') && (title)}
          </div>
          <div className={styles.labelPad}></div>
        </>
      )}
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
}
export default ArkBorder
