import React from 'react'

import StreamhubProcessesAPI from '../services/StreamhubProcessesAPI'
import StreamhubAPIClient from '../services/StreamhubAPIClient'
import { FFMpegProcess } from '../models/StreamhubModels'

export enum StreamhubProcessDataStatus {
  initial, loading, updating, done, error
}

export interface IStreamhubProcessesStore {
  // processes
  processes?: Array<FFMpegProcess>
  processesStatus?: StreamhubProcessDataStatus
  processesError?: Error
}

export interface IStreamhubProcessesActions {
  fetchProcesses: () => Promise<void>
  killProcess: (pid: number) => Promise<boolean>
  killAllProcesses: () => Promise<boolean>
}

export interface IStreamhubProcessesContext {
  actions: IStreamhubProcessesActions;
  store: IStreamhubProcessesStore;
}

export interface StreamhubProcessesProviderProps {
  apiClient: StreamhubAPIClient
  processesAPI?: StreamhubProcessesAPI
  children?: React.ReactNode
}
export interface StreamhubProcessesProviderState extends IStreamhubProcessesStore {
  processesAPI: StreamhubProcessesAPI
}

export const StreamhubProcessesContext = React.createContext<IStreamhubProcessesContext>({} as IStreamhubProcessesContext)

export class StreamhubProcessesProvider extends React.Component<StreamhubProcessesProviderProps, StreamhubProcessesProviderState> {
  constructor (props: StreamhubProcessesProviderProps) {
    super(props)
    this.state = {
      processesAPI: props.processesAPI ?? new StreamhubProcessesAPI(this.props.apiClient)
    }
  }

  // -------

  fetchProcesses = async () => {
    const { processesStatus } = this.state
    const newStatus = processesStatus && processesStatus === StreamhubProcessDataStatus.done ? StreamhubProcessDataStatus.updating : StreamhubProcessDataStatus.loading
    this.setState({ processesStatus: newStatus, processesError: undefined })
    try {
      const processes = await this.state.processesAPI.fetchProcesses()
      this.setState({ processes, processesStatus: StreamhubProcessDataStatus.done })
      // await new Promise(resolve => setTimeout(resolve, 2000)) // DEBUG ONLY: add a delay to test the loading state
      // throw new Error('DEBUG ERROR')
    } catch (error) {
      console.error('StreamhubProcessesProvider - fetchProcesses - error:', error)
      this.setState({ processes: undefined, processesStatus: StreamhubProcessDataStatus.error, processesError: error })
    }
  }

  // -------

  killProcess = async (pid: number): Promise<boolean> => {
    return await this.state.processesAPI.killProcess(pid)
  }

  killAllProcesses = async (): Promise<boolean> => {
    return await this.state.processesAPI.killAllProcesses()
  }

  // -------

  actions: IStreamhubProcessesActions = {
    fetchProcesses: this.fetchProcesses,
    killProcess: this.killProcess,
    killAllProcesses: this.killAllProcesses
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: IStreamhubProcessesStore = {
  //  ...
  // }

  render () {
    return (
      <StreamhubProcessesContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for IStreamhubProcessesStore */ }}
      >
        {this.props.children}
      </StreamhubProcessesContext.Provider>
    )
  }
}

// interface StreamhubProcessesConsumerProps { children: React.ReactNode }
// const StreamhubProcessesConsumer: React.FC<StreamhubProcessesConsumerProps> = (props: StreamhubProcessesConsumerProps) => {
//   const { children } = props
//   return (
//     <StreamhubProcessesContext.Consumer>
//       {context => {
//         if (context === undefined) {
//           throw new Error('StreamhubProcessesConsumer must be used within a StreamhubProcessesProvider')
//         }
//         return children(context)
//       }}
//     </StreamhubProcessesContext.Consumer>
//   )
// }
