/**
 * ArkBreadcrumb
 */

import React, { useContext } from 'react'
import { Breadcrumb, BreadcrumbProps } from 'semantic-ui-react'

import { ResponsiveContext } from '../../providers/ResponsiveProvider'

import styles from './ArkBreadcrumb.module.css'

const ArkBreadcrumb = (props: BreadcrumbProps) => {
  const { className } = props

  const { store } = useContext(ResponsiveContext)
  const { isMobile } = store

  return (
    <Breadcrumb
      {...props}
      className={`${styles.breadcrumb} ${className || ''}`}
      icon='right angle'
      size={isMobile ? 'big' : 'massive'}
    />
  )
}

export default ArkBreadcrumb
