import React, { useContext } from 'react'

import { AuthContext, NavContext, UserContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import ArkButton from 'src/core/components/ArkButton'
import ArkCenterLayout from 'src/core/components/ArkCenterLayout'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkSegment from 'src/core/components/ArkSegment'

import { OBJECT_COMPANY_NAME, OBJECT_PROJECT_NAME } from 'src/constants/strings'

export interface Viewer2FAAuthViewProps {}

const Ark2FAAuthView = (_props: Viewer2FAAuthViewProps) => {
  const userContext = useContext(UserContext)
  const authContext = useContext(AuthContext)
  const navContext = useContext(NavContext)
  const user = userContext.store.user
  const userTFAEnabled = user?.tfaEnabled || false
  const companyAuthTFARequired = authContext.actions.isCompanyAuthTFARequired()
  const projectAuthTFARequired = authContext.actions.isProjectAuthTFARequired()
  console.log('Ark2FAAuthView - companyAuthTFARequired:', companyAuthTFARequired, ' projectAuthTFARequired:', projectAuthTFARequired, ' userTFAEnabled:', userTFAEnabled)
  if ((companyAuthTFARequired || projectAuthTFARequired) && !userTFAEnabled) {
    return (
      <ArkCenterLayout>
        <ArkSegment inverted>
          <ArkHeader as='h2' inverted>Two Factor Auth Required</ArkHeader>
          {companyAuthTFARequired && (<p>To access this {OBJECT_COMPANY_NAME} you must enable Two Factor Authentication on your account.</p>)}
          {projectAuthTFARequired && (<p>To access this {OBJECT_PROJECT_NAME} you must enable Two Factor Authentication on your account.</p>)}
          <ArkButton fluid positive onClick={() => {
            navContext.actions.goto(ROUTES.ACCOUNT_SETTINGS_2FA, navContext.actions.getCurrentLocation())
          }}>
            &gt; Goto 2FA Settings
          </ArkButton>
        </ArkSegment>
      </ArkCenterLayout>
    )
  }

  return null
}

export default Ark2FAAuthView
