// WARNING: this does NOT verify the token signature so careful using the results of it
// refs: https://stackoverflow.com/a/38552302
export const parseJwt = (token: string) => {
  console.log('utils - token - parseJwt - token:', token)
  if (!token.includes('.')) throw new Error('Invalid token format') // super basic check so the `split` below doesn't error (the json parser will throw a suitable error if the input is invalid)
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  console.log('utils - token - parseJwt - jsonPayload:\'' + jsonPayload + '\'')
  return JSON.parse(jsonPayload)
}
