/**
 * MainView
 */

import React, { useContext, useState } from 'react'

import { PROJECT_CHECKLIST_ENABLED, PROJECT_NODE_GRAPH_ENABLED } from 'src/constants/config'
import * as ROUTES from 'src/constants/routes'

import ArkButton, { ArkButtonGroup } from 'src/core/components/ArkButton'
import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkResponsive from 'src/core/components/ArkResponsive'
import ArkSectionCard, { ArkSectionCardGroup } from 'src/core/components/ArkSectionCard'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { ResponsiveContext } from 'src/core/providers/ResponsiveProvider'
import { pluralize } from 'src/core/utilities/strings'

import ChecklistCard from './checklist/ChecklistCard'
import ChecklistModal from './checklist/ChecklistModal'
import ChecklistProvider from './checklist/ChecklistProvider'
import NodeGraphProvider, { NodeGraphContext } from './nodeGraph/NodeGraphProvider'
import NodeGraphView from './nodeGraph/NodeGraphView'

import styles from './HomeView.module.css'

const MainView = () => {
  const { store: responsiveStore } = useContext(ResponsiveContext)
  const { isMobile } = responsiveStore

  const { actions: nodeGraphActions, store: nodeGraphStore } = useContext(NodeGraphContext)
  const { navigateTo, setShowFilter } = nodeGraphActions
  const {
    isFullscreen,
    isLoading,
    numberOfChannels,
    numberOfGroups,
    numberOfHiddenChannels,
    numberOfHiddenGroups,
    numberOfHiddenPrograms,
    numberOfHiddenUsers,
    numberOfPrograms,
    numberOfUsers,
    projectVideoEngine
  } = nodeGraphStore

  const [mobileTab, setMobileTab] = useState<'overview' | 'graph'>('overview')

  const isNoScroll = isMobile ? mobileTab === 'graph' : false
  const showSectionCards = isMobile ? mobileTab === 'overview' : true
  const showNodeGraphCard = isMobile ? mobileTab === 'graph' : true

  const projectVideoEngineActive = projectVideoEngine?.isActive ?? false

  return (
    <div className={`${styles.container} ${isNoScroll ? styles.containerNoScroll : ''}`}>

      <ArkSpacer size={projectVideoEngineActive ? 20 : 10} />{/* NB: use less space when the 'project offline' banner will be showing (see the parent `ProjectManagerHomePage` for where the `ArkProjectStatusBanner` is added) */}

      {/* mobile tabs */}
      <ArkResponsive mobileOnly>
        <ArkButtonGroup fluid>
          <ArkButton active={mobileTab === 'overview'} onClick={() => setMobileTab('overview')}>OVERVIEW</ArkButton>
          <ArkButton active={mobileTab === 'graph'} onClick={() => setMobileTab('graph')}>GRAPH</ArkButton>
        </ArkButtonGroup>
        <ArkSpacer size={20} />
      </ArkResponsive>

      {/* section cards */}
      {showSectionCards && !isFullscreen && (
        <>
          <ArkSectionCardGroup>
            <ArkSectionCard
              iconName='program'
              manageTitle='Video Engine'
              title={'Video Engine'}
              name={projectVideoEngine ? projectVideoEngine.name + (projectVideoEngine.isActive ? ' (ACTIVE)' : ' (DISABLED)') : 'N/A'}
              // number={numberOfPrograms}
              // numberHidden={numberOfHiddenPrograms}
              // onNumberHiddenClick={() => setShowFilter(true)}
              // onManageClick={() => {}}
            />
            <ArkSectionCard
              iconName='program'
              manageTitle='Manage programs'
              name={pluralize('Program', numberOfPrograms)}
              number={numberOfPrograms}
              numberHidden={numberOfHiddenPrograms}
              onNumberHiddenClick={() => setShowFilter(true)}
              onManageClick={() => navigateTo(ROUTES.PROJECT_MANAGER_PROGRAMS)}
            />
            <ArkSectionCard
              iconName='channel'
              manageTitle='Manage channels'
              name={pluralize('Channel', numberOfChannels)}
              number={numberOfChannels}
              numberHidden={numberOfHiddenChannels}
              onNumberHiddenClick={() => setShowFilter(true)}
              onManageClick={() => navigateTo(ROUTES.PROJECT_MANAGER_CHANNELS)}
            />
            <ArkSectionCard
              iconName='group'
              manageTitle='Manage groups'
              name={pluralize('Group', numberOfGroups)}
              number={numberOfGroups}
              numberHidden={numberOfHiddenGroups}
              onNumberHiddenClick={() => setShowFilter(true)}
              onManageClick={() => navigateTo(ROUTES.PROJECT_MANAGER_GROUPS)}
            />
            <ArkSectionCard
              iconName='user-project'
              manageTitle='Manage users'
              name={pluralize('User', numberOfUsers)}
              number={numberOfUsers}
              numberHidden={numberOfHiddenUsers}
              onNumberHiddenClick={() => setShowFilter(true)}
              onManageClick={() => navigateTo(ROUTES.PROJECT_MANAGER_USERS)}
            />
          </ArkSectionCardGroup>
          <ArkResponsive desktopOnly>
            <ArkSpacer size={20} />
          </ArkResponsive>
        </>
      )}

      {/* node graph & desktop checklist */}
      {PROJECT_NODE_GRAPH_ENABLED && showNodeGraphCard && (
        <div className={styles.mainContainer}>
          <NodeGraphView />
          {PROJECT_CHECKLIST_ENABLED && (
            <ArkResponsive desktopOnly>
              <ChecklistCard />
            </ArkResponsive>
          )}
        </div>
      )}

      {/* mobile checklist */}
      {PROJECT_CHECKLIST_ENABLED && (
        <ArkResponsive mobileOnly>
          <ChecklistModal />
        </ArkResponsive>
      )}

      {isLoading && <ArkLoaderView className={styles.loader} message='Loading data' />}
    </div>
  )
}

const ProjectManagerHomePageProviders = () => (
  <NodeGraphProvider>
    <ChecklistProvider>
      <MainView />
    </ChecklistProvider>
  </NodeGraphProvider>
)

export default ProjectManagerHomePageProviders
