/**
 * ArkPanel
 */

import React, { useState } from 'react'

import styles from './ArkPanel.module.css'

import { Message, MessageProps /*, Table */ } from 'semantic-ui-react'

export interface ArkPanelPropertiesProps {
  children?: React.ReactNode
  titleWidth?: number | string
  valueWidth?: number | string
  hintWidth?: number | string
  uppercaseValues?: boolean // NB: defaults to true to match the existing style before this was added
}
export interface ArkPanelPropertySectionProps {
  title: string
  collapsible?: boolean
  collapsed?: boolean // default collapsed state if collapsible == true (ignored if not)
  children?: React.ReactNode
  titleClassName?: string
}
export interface ArkPanelPropertyRowProps {
  children?: React.ReactNode
  title?: string | React.ReactNode
  value?: string | React.ReactNode
  hint?: string | React.ReactNode
  titleSize?: 'default' | 'small' | 'medium' | 'large' | 'xlarge' // NB: `default` is used when undefined
  titleWidth?: number | string // NB: try to use `titleSize` for standardised sizes in most cases unless you need a really different value
  valueWidth?: number | string
  hintWidth?: number | string
  rowClassName?: string
  titleClassName?: string
  valueClassName?: string
  hintClassName?: string
  hasChanges?: boolean
}
export interface ArkPanelPropertyRowTitleProps {
  children?: React.ReactNode
  width?: number | string
  className?: string
}
export interface ArkPanelPropertyRowValueProps {
  children?: React.ReactNode
  width?: number | string
  className?: string
}
export interface ArkPanelPropertyRowHintProps {
  children?: React.ReactNode
  width?: number | string
  className?: string
}
export interface ArkPanelPropertyRowContentProps {
  children?: React.ReactNode
  className?: string
}
export interface ArkPanelPropertyRowMessageProps extends MessageProps {
  // children?: React.ReactNode
  // className?: string
}

export interface ArkPanelPropertDividerProps {
  children?: React.ReactNode
  className?: string
}

export interface ArkPanelPropertSpacerProps { // NB: loosely based on ArkSpacer
  large?: boolean
  size?: number
  small?: boolean
}

const ArkPanelPropertyRowTitle = (props: ArkPanelPropertyRowTitleProps) => {
  return (<div className={styles.title + (props.className ? ' ' + props.className : '')} style={ props.width ? { flexBasis: props.width } : undefined }>{props.children}</div>)
}

const ArkPanelPropertyRowValue = (props: ArkPanelPropertyRowValueProps) => {
  return (<div className={styles.value + (props.className ? ' ' + props.className : '')} style={ props.width ? { flexBasis: props.width } : undefined }>{props.children}</div>)
}

const ArkPanelPropertyRowHint = (props: ArkPanelPropertyRowHintProps) => {
  return (<div className={styles.hint + (props.className ? ' ' + props.className : '')} style={ props.width ? { flexBasis: props.width } : undefined }>{props.children}</div>)
}

// TODO: can .value be flipped to use `children` instead? (so ArkForm wouldn't need special handling just to support this), do we need it to support additional children here?? <<<
const ArkPanelPropertyRow = (props: ArkPanelPropertyRowProps) => {
  const { children, hasChanges } = props
  const titleSizeClass = props.titleSize ? props.titleSize : undefined
  return (
    <div className={styles.property + (hasChanges ? ' ' + styles.hasChanges : '') + (props.rowClassName ? ' ' + props.rowClassName : '')}>
      {(props.title || props.value) && (
        <>
          <ArkPanelPropertyRowTitle className={props.titleClassName + (titleSizeClass ? ' ' + titleSizeClass : '')} width={props.titleWidth}>{props.title}</ArkPanelPropertyRowTitle>
          <ArkPanelPropertyRowValue className={props.valueClassName} width={props.valueWidth}>{props.value}</ArkPanelPropertyRowValue>
          {props.hint && (<ArkPanelPropertyRowHint className={props.hintClassName} width={props.hintWidth}>{props.hint}</ArkPanelPropertyRowHint>)}
        </>
      )}
      {children}
    </div>
  )
}

const ArkPanelPropertyRowContent = (props: ArkPanelPropertyRowContentProps) => {
  return (<div className={styles.content + (props.className ? ' ' + props.className : '')}>{props.children}</div>)
}

const ArkPanelPropertyRowMessage = (props: ArkPanelPropertyRowMessageProps) => {
  const { className, children, ...otherProps } = props
  return (
    <div className={styles.message + (className ? ' ' + className : '')}>
      <Message {...otherProps}>
        {children}
      </Message>
    </div>
  )
}

const ArkPanelPropertyDivider = (_props: ArkPanelPropertDividerProps) => {
  return (<div className={styles.propertyDivider}></div>)
}

const ArkPanelPropertySpacer = (props: ArkPanelPropertSpacerProps) => {
  const { large, size, small } = props
  let theSize = 10
  if (small) theSize = 5
  else if (large) theSize = 15
  else if (size !== undefined) theSize = size
  return (<div className={styles.propertySpacer} style={{ margin: `0 ${theSize}px ${theSize}px 0` }}></div>)
}

const ArkPanelPropertySection = (props: ArkPanelPropertySectionProps) => {
  const { title, collapsible, collapsed, children, titleClassName } = props
  const [expanded, setExpanded] = useState<boolean>((collapsible === true && collapsed === true))
  const onClick = () => {
    if (!collapsible) return
    setExpanded(!expanded)
  }
  return (
    <div className={`${styles.section}${collapsible ? ' ' + styles.sectionCollapsible : ''}${!expanded ? ' ' + styles.open : ''}`}>
      <div className={styles.sectionTitle + (titleClassName ? ' ' + titleClassName : '')} onClick={onClick}>
        <i className={`${styles.sectionArrow} ${expanded ? styles.sectionArrowClosed : styles.sectionArrowOpen}`} />
        {title}
      </div>
      <div className={`${styles.sectionContent}`}>
        {children}
      </div>
    </div>
  )
}

const ArkPanelProperties = (props: ArkPanelPropertiesProps) => {
  const { children, titleWidth, valueWidth, hintWidth, uppercaseValues } = props
  // TESTING:
  // if a title/value/hint width are set at this higher level we set them as css vars so the child css can use them
  // if they're not set, the default values are used (hard-coded in the css)
  // NB: this seems to work with multiple properties on a single panel/page, each one uses the values passed into it
  const style = titleWidth || valueWidth || hintWidth
    ? {
      ...(titleWidth !== undefined ? { '--panel-title-width': Number.isInteger(titleWidth) ? titleWidth + 'px' : titleWidth } : {}),
      ...(valueWidth !== undefined ? { '--panel-value-width': Number.isInteger(valueWidth) ? valueWidth + 'px' : valueWidth } : {}),
      ...(hintWidth !== undefined ? { '--panel-hint-width': Number.isInteger(hintWidth) ? hintWidth + 'px' : hintWidth } : {})
    } as React.CSSProperties
    : undefined
  const _uppercaseValues = uppercaseValues !== undefined ? uppercaseValues : true // default to true
  return (
    <div className={`${styles.propertiesPanel}${_uppercaseValues ? ' ' + styles.uppercaseValues : ''}`} style={ style }>
      {children}
    </div>
  )
}

export default ArkPanelProperties
export {
  ArkPanelPropertyRow,
  ArkPanelPropertyRowTitle,
  ArkPanelPropertyRowValue,
  ArkPanelPropertyRowHint,
  ArkPanelPropertyRowContent,
  ArkPanelPropertyRowMessage,
  ArkPanelPropertyDivider,
  ArkPanelPropertySpacer,
  ArkPanelPropertySection
}
