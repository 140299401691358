// change this to true to put [ ] around all used strings to test implementation
const useDummyStrings = false

// objects and roles
export const OBJECT_COMPANY_NAME = useDummyStrings ? '[organisation]' : 'organisation'
export const OBJECT_COMPANY_NAME_PLURAL = useDummyStrings ? '[organisations]' : 'organisations'
export const OBJECT_COMPANY_SHORTNAME = useDummyStrings ? '[org]' : 'org'
export const OBJECT_COMPANY_SHORTNAME_PLURAL = useDummyStrings ? '[orgs]' : 'orgs'
export const OBJECT_PROJECT_NAME = useDummyStrings ? '[project]' : 'project'
export const OBJECT_PROJECT_NAME_PLURAL = useDummyStrings ? '[projects]' : 'projects'
export const OBJECT_CHANNEL_NAME = useDummyStrings ? '[channel]' : 'channel'
export const OBJECT_CHANNEL_NAME_PLURAL = useDummyStrings ? '[channels]' : 'channels'
export const OBJECT_PROGRAM_NAME = useDummyStrings ? '[program]' : 'program'
export const OBJECT_PROGRAM_NAME_PLURAL = useDummyStrings ? '[programs]' : 'programs'
export const OBJECT_GROUP_NAME = useDummyStrings ? '[group]' : 'group'
export const OBJECT_GROUP_NAME_PLURAL = useDummyStrings ? '[groups]' : 'groups'
export const OBJECT_USER_NAME_PLURAL = useDummyStrings ? '[users]' : 'users'
export const OBJECT_USER_NAME = useDummyStrings ? '[user]' : 'user'
export const OBJECT_GUEST_NAME = useDummyStrings ? '[guest]' : 'guest'
export const OBJECT_TEAM_NAME = useDummyStrings ? '[team]' : 'team'
export const OBJECT_BROADCAST_NAME = useDummyStrings ? '[broadcast]' : 'broadcast (beta)'
export const OBJECT_VIDEO_ENGINE_NAME = useDummyStrings ? '[video_engine]' : 'video engine'
export const OBJECT_VIDEO_ENGINE_NAME_PLURAL = useDummyStrings ? '[video_engines]' : 'video engines'

export const OBJECT_CARD_NAME = useDummyStrings ? '[card]' : 'Card'
export const OBJECT_CARD_NAME_PLURAL = useDummyStrings ? '[cards]' : 'Cards'
export const OBJECT_CARD_NAME_TITLE = useDummyStrings ? '[cards]' : 'Cards (beta)'
export const OBJECT_IMAGE_NAME = useDummyStrings ? '[image]' : 'Image'
export const OBJECT_IMAGE_NAME_PLURAL = useDummyStrings ? '[images]' : 'Images'
export const OBJECT_NOTICE_NAME = useDummyStrings ? '[notice]' : 'Notice'
export const OBJECT_NOTICE_NAME_PLURAL = useDummyStrings ? '[notices]' : 'Notices'
export const OBJECT_NOTICE_NAME_TITLE = useDummyStrings ? '[notices]' : 'Notices (beta)'

export const SECTION_ADMIN_NAME = useDummyStrings ? '[admin]' : 'admin'
export const SECTION_COMPANY_NAME = useDummyStrings ? '[org]' : 'org'
export const SECTION_PROJECT_NAME = useDummyStrings ? '[project]' : 'project'
export const SECTION_VIEWER_NAME = useDummyStrings ? '[viewer]' : 'viewer'

export const PAGE_DASHBOARD_NAME = useDummyStrings ? '[dashboard]' : 'dashboard'
export const PAGE_ACTIVITY_NAME = useDummyStrings ? '[activity]' : 'activity'
export const PAGE_SETTINGS_NAME = useDummyStrings ? '[settings]' : 'settings'
export const PAGE_SETTINGS_WATERMARK_NAME = useDummyStrings ? '[watermark]' : 'watermark'
export const PAGE_STREAMHUB_NAME = useDummyStrings ? '[streamhub]' : 'streamhub'
export const PAGE_VIDEO_ENGINES_NAME = useDummyStrings ? '[video_engines]' : 'video engines'
export const PAGE_SUPPORT_NAME = useDummyStrings ? '[support]' : 'support'
export const PAGE_BROADCAST_NAME = useDummyStrings ? '[broadcast (beta)]' : 'broadcast (beta)'

export const SECTION_MANAGER_SUFFIX_NAME = useDummyStrings ? '[manager]' : 'manager'

export const ROLE_MEMBER_NAME = useDummyStrings ? '[member]' : 'member'
export const ROLE_ADMIN_NAME = useDummyStrings ? '[admin]' : 'admin'
export const ROLE_MANAGER_NAME = useDummyStrings ? '[manager]' : 'manager'
export const ROLE_OWNER_NAME = useDummyStrings ? '[owner]' : 'owner' // NB: not a normal role, the project owner flag takes priority over the role when displaying
export const ROLE_MIRROR_GROUP_NAME = useDummyStrings ? '[mirrored]' : 'mirrored' // NB: not a normal role, project user is not directly added to the project, but is mirrored from a group
