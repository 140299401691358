/**
 * ResponsiveProvider
 */

import React, { createContext, ReactNode, useEffect, useState } from 'react'

import { RESPONSIVE_MOBILE_BREAKPOINT } from '../../constants/config'

interface IResponsiveActions {
  //
}

interface IResponsiveStore {
  isDesktop: boolean
  isMobile: boolean
}

interface IResponsiveContext {
  actions: IResponsiveActions
  store: IResponsiveStore
}

export interface IResponsiveMultiContext {
  responsiveContext: IResponsiveContext
}

interface ResponsiveProviderProps {
  children: ReactNode
}

export const ResponsiveContext = createContext<IResponsiveContext>({} as IResponsiveContext)

const ResponsiveProvider = (props: ResponsiveProviderProps) => {
  const { children } = props

  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const listener = () => {
      if (window.innerWidth > RESPONSIVE_MOBILE_BREAKPOINT) {
        setIsDesktop(true)
        setIsMobile(false)
      } else {
        setIsDesktop(false)
        setIsMobile(true)
      }
    }
    listener()
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [])

  const actions: IResponsiveActions = {
    isDesktop,
    isMobile
  }

  const store: IResponsiveStore = {
    isDesktop,
    isMobile
  }

  return (
    <ResponsiveContext.Provider value={{ actions, store }}>
      {children}
    </ResponsiveContext.Provider>
  )
}

const withResponsiveContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withResponsiveContextHOC = (props: any) => (
    <ResponsiveContext.Consumer>
      {(responsiveContext) => {
        if (responsiveContext === null) {
          throw new Error('ResponsiveConsumer must be used within a ResponsiveProvider')
        }
        // console.log('withResponsiveContext - render - ResponsiveContext.Consumer - responsiveContext.store: ', responsiveContext.store)
        return (<Component {...props} {...{ responsiveContext: responsiveContext }} />)
      }}
    </ResponsiveContext.Consumer>
  )
  return withResponsiveContextHOC
}

export default ResponsiveProvider
export { withResponsiveContext }
