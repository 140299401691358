import React from 'react'

import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkViewerPage from '../../components/ArkViewerPage/ArkViewerPage'

const ViewerLoadingPage = () => (
  <ArkViewerPage disableSectionMainMenu>
    <ArkLoaderView message={'Loading'} />
  </ArkViewerPage>
)

export default ViewerLoadingPage
