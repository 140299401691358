import React from 'react'

import { withUserContext, IUserMultiContext, withNavContext, INavMultiContext } from 'src/core/providers'
// import * as ROUTES from 'src/constants/routes'

import { Company, VideoEngine } from 'src/core/models'

import VideoEngineDeleteView, { VideoEngineDidDeleteCallback } from './VideoEngineDeleteView'

// import ArkButton from 'src/core/components/ArkButton'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { SECTION_COMPANY_NAME } from 'src/constants/strings'

import styles from './AdminVideoEngineSidebar.module.css'

export type VideoEngineEditCallback = (videoEngine: VideoEngine) => void

interface IProps extends IUserMultiContext, INavMultiContext {
  videoEngine?: VideoEngine
  companies?: Array<Company> // for company/org assigned video engines - to get the org display name to show (NB: in the future the VE response may add the org name directly so we could remove this requirement then)
  onEdit?: VideoEngineEditCallback
  onDidDelete?: VideoEngineDidDeleteCallback
}
interface IState {
  videoEngineDeleted: boolean
}

class AdminVideoEngineSidebar extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = {
      videoEngineDeleted: false
    }
  }

  render () {
    const { videoEngine, companies } = this.props
    if (!videoEngine) return null
    const videoEngineCompany = videoEngine.companyId ? companies?.find(company => company.id === videoEngine.companyId) : undefined
    return (
      <>

        <ArkPanel bordered className={styles.sidebarHeader}>
          <ArkPanel.Header className={styles.sidebarTitle} title={videoEngine.name} iconName='video-engine' />
          <ArkIconButton
            className={styles.menuButton}
            name='settings'
            onClick={this.onEdit}
            size={24}
          />
        </ArkPanel>

        {!this.state.videoEngineDeleted && (<>

          <ArkSpacer />
          {/* <ArkButton type="button" fluid size="large" onClick={this.onEdit}>EDIT {SECTION_COMPANY_NAME}</ArkButton> */}

          <ArkPanel bordered>
            <ArkPanel.Properties uppercaseValues={false}>
              <ArkPanel.PropertyRow title='id:' value={videoEngine.id} />
              <ArkPanel.PropertyRow title='Name:' value={videoEngine.name} />
              <ArkPanel.PropertyRow title='Domain:' value={videoEngine.domain} />
              <ArkPanel.PropertyRow title='IP:' value={videoEngine.ip} />
              <ArkPanel.PropertyRow title='Status:' value={videoEngine.isActive ? 'ACTIVE' : 'DISABLED'} />
              <ArkPanel.PropertyRow title={SECTION_COMPANY_NAME + ':'} value={videoEngineCompany?.name ?? videoEngine.companyId ?? '-'} />
              <ArkPanel.PropertySection title='Control' collapsible={true} collapsed={true} titleClassName={styles.adminActionsTitle}>
                <ArkPanel.PropertyRow title='Server Key:' value={videoEngine.controlServerKey} />
                <ArkPanel.PropertyRow title='Client Id:' value={videoEngine.controlClientId} />
                <ArkPanel.PropertyRow title='API Key:' value={videoEngine.controlAPIKey} />{/* TODO: mask the key <<<< */}
                <ArkPanel.PropertyRow title='Group Id:' value={videoEngine.controlGroupId} />
              </ArkPanel.PropertySection>
              <ArkPanel.PropertySection title='Ports' collapsible={true} collapsed={true} titleClassName={styles.adminActionsTitle}>
                < ArkPanel.PropertyRow title='SRT In:' value={
                  <div>
                    {videoEngine.srtInputPortRanges.map((range, index) => {
                      if (range.start === range.end) return (<div key={index}>{range.start}</div>)
                      return (<div key={index}>{range.start} - {range.end}</div>)
                    })}
                  </div>
                } />
                < ArkPanel.PropertyRow title='SRT Out:' value={
                  <div>
                    {videoEngine.srtOutputPortRanges.map((range, index) => {
                      if (range.start === range.end) return (<div key={index}>{range.start}</div>)
                      return (<div key={index}>{range.start} - {range.end}</div>)
                    })}
                  </div>
                } />
                <ArkPanel.PropertyRow title='SLDP Out:' value={videoEngine.sldpPort} />
                <ArkPanel.PropertyRow title='SLDP WS Out:' value={videoEngine.sldpPortWS} />
                <ArkPanel.PropertyRow title='SLDP WSS Out:' value={videoEngine.sldpPortWSS} />
                <ArkPanel.PropertyRow title='RTMP In/Out:' value={videoEngine.rtmpPort} />
                <ArkPanel.PropertyRow title='RTMPS In/Out:' value={videoEngine.rtmpsPort} />
                <ArkPanel.PropertyRow title='RTSP In/Out:' value={videoEngine.rtspPort} />
                <ArkPanel.PropertyRow title='Icecast In:' value={videoEngine.icecastInputPort} />
                <ArkPanel.PropertyRow title='Icecast Out:' value={videoEngine.icecastPlaybackPort} />
              </ArkPanel.PropertySection>
              <ArkPanel.PropertySection title='Transcoders' collapsible={true} collapsed={true} titleClassName={styles.adminActionsTitle}>
                <ArkPanel.PropertyRow title='Template Base Id:' value={videoEngine.templateTranscoderBaseId} />
                <ArkPanel.PropertyRow title='Template HD Id:' value={videoEngine.templateTranscoderHDId} />
                <ArkPanel.PropertyRow title='Template FHD Id:' value={videoEngine.templateTranscoderFHDId} />
                <ArkPanel.PropertyRow title='Template ABR Id:' value={videoEngine.templateABRId} />
              </ArkPanel.PropertySection>
              <ArkPanel.PropertySection title='Watermark' collapsible={true} collapsed={true} titleClassName={styles.adminActionsTitle}>
                <ArkPanel.PropertyRow title='Env Access Key Id:' value={videoEngine.watermarkEnvAccessKeyId} />
                <ArkPanel.PropertyRow title='Env Bucket:' value={videoEngine.watermarkEnvBucket} />
                <ArkPanel.PropertyRow title='Env Path:' value={videoEngine.watermarkEnvPath} />
                <ArkPanel.PropertyRow title='Env Secret:' value={videoEngine.watermarkEnvSecretAccessKey} />
                <ArkPanel.PropertyRow title='Local Path:' value={videoEngine.watermarkLocalPath} />
              </ArkPanel.PropertySection>
            </ArkPanel.Properties>
          </ArkPanel>

          <ArkSpacer />

          {/* <ArkButton type="button" fluid size="large" onClick={this.onOpenCompanyManager}>OPEN IN {SECTION_COMPANY_NAME + ' ' + SECTION_MANAGER_SUFFIX_NAME}</ArkButton> */}
        </>)}

        <ArkSpacer grow />

        <VideoEngineDeleteView
          videoEngine={videoEngine}
          onDidDelete={this.onDidDeleteCompany}
        />

      </>
    )
  }

  // -------

  resetView = () => {
    this.setState({ videoEngineDeleted: false }) // reset the state
  }

  // -------

  onEdit = () => {
    if (this.props.onEdit && this.props.videoEngine) this.props.onEdit(this.props.videoEngine)
  }

  onDidDeleteCompany = (videoEngine: VideoEngine) => {
    if (this.props.onDidDelete) this.props.onDidDelete(videoEngine)
    this.setState({ videoEngineDeleted: true })
  }

  // -------

  // onOpenCompanyManager = () => {
  //   const { videoEngine } = this.props
  //   // TODO: PORT OR DEPRECIATE?
  //   // if (videoEngine) {
  //   //   // select the company & navigate to its company manager section
  //   //   this.props.userContext.actions.selectCompany(videoEngine.id) // TODO: disable the auto redirect this does & rely on the manual one below?
  //   //   this.props.navContext.actions.goto(ROUTES.COMPANY_MANAGER) // NB: specifically navigate to the company manger page for the newly selected company
  //   // }
  // }
}

export default withNavContext(withUserContext(AdminVideoEngineSidebar))
