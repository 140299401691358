import React, { useContext } from 'react'

import { UserContext } from 'src/core/providers'

import styles from './ArkProjectStatusBanner.module.css'

enum ArkProjectVideoEngineStatus {
  None, Enabled, Disabled
}

interface IProps {
  slimMargin?: boolean // disables the default top margin - for use on pages/areas where its not shown directly under a page title (e.g. the viewer)
}

const ArkProjectStatusBanner = (props: IProps) => {
  const { slimMargin } = props

  const { store: userStore } = useContext(UserContext)

  const company = userStore.selectedCompany
  const project = userStore.selectedProject
  if (!company || !project) return null

  let projectVideoEngineStatus = ArkProjectVideoEngineStatus.None
  if (project.videoEngine !== undefined) {
    projectVideoEngineStatus = project.videoEngine.isActive ? ArkProjectVideoEngineStatus.Enabled : ArkProjectVideoEngineStatus.Disabled
  }

  if (projectVideoEngineStatus === ArkProjectVideoEngineStatus.Enabled) return null

  return (
    <>
      {projectVideoEngineStatus === ArkProjectVideoEngineStatus.None && (
        <div className={`${styles.projectStatus}${slimMargin === true ? ' ' + styles.projectStatusSlim : ''} ${styles.videoEngineNone}`}>
          <div className={styles.title}>WARNING: PROJECT OFFLINE</div>
          <div className={styles.desc}>No video engine is currently assigned to this project. All programs are currently offline.</div>
        </div>
      )}
      {projectVideoEngineStatus === ArkProjectVideoEngineStatus.Disabled && (
        <div className={`${styles.projectStatus}${slimMargin === true ? ' ' + styles.projectStatusSlim : ''} ${styles.videoEngineDisabled}`}>
          <div className={styles.title}>WARNING: PROJECT OFFLINE</div>
          <div className={styles.desc}>The assigned video engine is currently disabled. All programs are currently offline.</div>
        </div>
      )}
      {/* {projectVideoEngineStatus === ArkProjectVideoEngineStatus.Enabled && (
        <div className={`${styles.projectStatus} ${styles.videoEngineEnabled}`}>
          <div className={styles.title}>PROJECT ONLINE</div>
        </div>
      )} */}
    </>
  )
}

export default ArkProjectStatusBanner
