import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { DropdownItemProps } from 'semantic-ui-react'

import {
  OBJECT_CARD_NAME,
  OBJECT_COMPANY_SHORTNAME,
  OBJECT_PROJECT_NAME,
  OBJECT_PROJECT_NAME_PLURAL
} from 'src/constants/strings'
import ArkButton from 'src/core/components/ArkButton'
import ArkCheckbox from 'src/core/components/ArkCheckbox'
import ArkDropdown from 'src/core/components/ArkDropdown'
import ArkFieldset from 'src/core/components/ArkFieldset'
import ArkFormLabel from 'src/core/components/ArkForm/ArkFormLabel'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkInput from 'src/core/components/ArkInput'
import ArkModal from 'src/core/components/ArkModal'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { Card, DEFAULT_COMPANY_CARD, Project } from 'src/core/models'
import { useCompanyProject } from 'src/core/providers/CompanyProjectProvider'

import styles from './CardCreateUpdateModal.module.css'

// FIXME use <ArkForm>

interface CardCreateUpdateModalProps {
  card?: Card
  onClose: () => void
  onSubmit: (props: Partial<Card>) => void
  open: boolean
  section: 'company' | 'project'
}

const CardCreateUpdateModal = (props: CardCreateUpdateModalProps) => {
  // console.log('CardCreateUpdateModal - card:', props.card)

  const companyProject = useCompanyProject()

  const [allProjects, setAllProjects] = useState<boolean>(false)
  const [description, setDescription] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [projectIds, setProjectIds] = useState<number[]>([])
  const [submitting, setSubmitting] = useState<boolean>(false)

  const create: boolean = !props.card
  const permissions: boolean = props.section === 'company' && (create || props.card!.entity_type === 'COMPANY')
  const projects: Project[] = props.section === 'company' ? companyProject.getProjects() : []

  const projectOptions: DropdownItemProps[] = _.map(projects, project => ({
    text: project.name,
    value: project.id
  }))

  useEffect(() => {
    if (!props.open) return
    setDescription(props.card?.description || DEFAULT_COMPANY_CARD.description)
    setName(props.card?.name || DEFAULT_COMPANY_CARD.name)
    if (permissions) {
      setAllProjects(
        props.card ? props.card.permission_all_projects : DEFAULT_COMPANY_CARD.permission_all_projects
      )
      setProjectIds(props.card?.permission_all_projects ? [] : props.card?.permission_specific_projects || [])
    }
    setSubmitting(false)
  }, [props.open])

  const onSubmit: () => void = () => {
    // console.log('CardCreateUpdateModal - onSubmit')
    if (!name) {
      window.alert('Invalid name.') // FIXME add toast
      return
    }
    setSubmitting(true)
    const newProps: Partial<Card> = {
      name,
      description,
      ...(permissions && {
        permission_all_projects: allProjects,
        permission_specific_projects: _.orderBy(projectIds, 'name')
      })
    }
    // console.log('CardCreateUpdateModal - onSubmit - props:', newProps)
    props.onSubmit(newProps)
  }

  return (
    <ArkModal onClose={props.onClose} open={props.open}>
      <ArkHeader as="h2" inverted>
        {`${
          create ? 'Create' : 'Edit'
        } ${
          create
            ? (props.section === 'company' ? OBJECT_COMPANY_SHORTNAME : OBJECT_PROJECT_NAME)
            : (props.card!.entity_type === 'COMPANY' ? OBJECT_COMPANY_SHORTNAME : OBJECT_PROJECT_NAME)
        } ${
          OBJECT_CARD_NAME
        }`}
      </ArkHeader>
      <ArkFieldset legend='Details'>
        <ArkInput
          label='Name'
          onChange={(event) => setName(event.target.value)}
          placeholder={`My ${OBJECT_CARD_NAME}`}
          required
          value={name}
        />
        <ArkSpacer large />
        <ArkInput
          label='Description'
          onChange={(event) => setDescription(event.target.value)}
          placeholder='My description'
          value={description}
        />
      </ArkFieldset>
      <ArkSpacer large />
      {permissions && (
        <>
          <ArkFieldset legend='Permissions'>
            <div>{`Specify which ${OBJECT_PROJECT_NAME_PLURAL} can use this ${OBJECT_CARD_NAME}.`}</div>
            <ArkSpacer large />
            <ArkCheckbox
              checked={allProjects}
              label={`All ${OBJECT_PROJECT_NAME_PLURAL}`}
              onChange={() => setAllProjects(!allProjects)}
              toggle
            />
            <ArkSpacer large />
            <ArkFormLabel>{`Specific ${OBJECT_PROJECT_NAME_PLURAL.toLowerCase()}`}</ArkFormLabel>
            <ArkDropdown
              disabled={allProjects}
              fluid
              multiple
              onChange={(_event, data) => setProjectIds(data.value as number[])}
              options={projectOptions}
              placeholder={`Select ${OBJECT_PROJECT_NAME_PLURAL.toLowerCase()}`}
              selection
              value={allProjects ? [] : projectIds}
            />
          </ArkFieldset>
          <ArkSpacer large />
        </>
      )}
      <div className={styles.footer}>
        <ArkButton
          basic
          className={styles.footerButton}
          color='orange'
          onClick={props.onClose}
          size='large'
        >
          Cancel
        </ArkButton>
        <ArkSpacer grow />
        <ArkButton
          basic
          className={styles.footerButton}
          color='blue'
          loading={submitting}
          onClick={onSubmit}
          size='large'
        >
          {create ? 'Create' : 'Save'}
        </ArkButton>
      </div>
    </ArkModal>
  )
}

export default CardCreateUpdateModal
