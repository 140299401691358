import React from 'react'

import OutputStream, { IOutputStreamStatus, IOutputStreamProgramData } from '../models/OutputStream'

import StreamhubStreamsAPI from '../services/StreamhubStreamsAPI'
import StreamhubAPIClient from '../services/StreamhubAPIClient'

import { withStreamhubServerContext, IStreamhubServerMultiContext } from './StreamhubServerProvider'

export enum StreamhubStreamsStatus {
  initial, loading, updating, done, error
}

export enum StreamhubStreamAction {
  create, update, delete
}
export enum StreamhubStreamStatus { // TODO: rename this? how does it compare/relate to the newer & server supplied IOutputStreamStatus??
  initial, running, done, error
}

export interface IStreamhubStreamsStore {
  // streams
  streams?: Array<OutputStream>
  streamsStatus?: StreamhubStreamsStatus
  streamsError?: Error
  streamTags?: Array<string>
  // stream
  stream?: OutputStream
  streamAction?: StreamhubStreamAction
  streamStatus?: StreamhubStreamStatus
  streamError?: Error
  // stream selection
  selectedStream?: OutputStream
  // stream updates
  // streamUpdates: Map<number, StreamhubStreamUpdateStatus>
}

export interface IStreamhubStreamsActions {
  // streams
  fetchStreams: () => Promise<void>
  // stream
  selectStream: (stream?: OutputStream) => void
  selectStreamWithId: (streamId?: number) => void
  createStream: (sourceId: number, name?: string, url?: string, duration?: number, retryEnabled?: boolean, retryDelay?: number, retryMaxAttempts?: number, programData?: IOutputStreamProgramData, isTemp?: boolean, tags?: Array<string>) => Promise<void>
  updateStream: (streamId: number, sourceId: number, name?: string, url?: string, duration?: number, retryEnabled?: boolean, retryDelay?: number, retryMaxAttempts?: number, programData?: IOutputStreamProgramData, isTemp?: boolean, tags?: Array<string>) => Promise<void>
  deleteStream: (stream: OutputStream) => Promise<void>
  deleteStreamWithId: (streamId: number) => Promise<void>
  // start/stop streams
  startStream: (streamId: number) => Promise<OutputStream | null>
  startStreams: (streamIds: Array<number>) => Promise<Array<OutputStream>>
  restartStream: (streamId: number) => Promise<OutputStream | null>
  restartStreams: (streamIds: Array<number>) => Promise<Array<OutputStream>>
  restartAllActiveStreams: () => Promise<boolean>
  stopStream: (streamId: number) => Promise<OutputStream | null>
  stopStreams: (streamIds: Array<number>) => Promise<Array<OutputStream>>
  stopAllActiveStreams: () => Promise<boolean>
  // quick streams (legacy)
  startQuickStream: (sourceId: number, outputUrl: string) => Promise<OutputStream | null>
  stopQuickStream: (streamId: number) => Promise<boolean>
  stopAllQuickStreams: () => Promise<boolean>
}

export interface IStreamhubStreamsContext {
  actions: IStreamhubStreamsActions
  store: IStreamhubStreamsStore
}

export const StreamhubStreamsContext = React.createContext<IStreamhubStreamsContext>({} as IStreamhubStreamsContext)

export interface StreamhubStreamsProviderProps extends IStreamhubServerMultiContext {
  apiClient: StreamhubAPIClient
  streamsAPI?: StreamhubStreamsAPI
  children?: React.ReactNode
}
export interface StreamhubStreamsProviderState extends IStreamhubStreamsStore {
  streamsAPI: StreamhubStreamsAPI
}

class StreamhubStreamsProviderBase extends React.Component<StreamhubStreamsProviderProps, StreamhubStreamsProviderState> {
  constructor (props: StreamhubStreamsProviderProps) {
    super(props)
    this.state = {
      streamsAPI: props.streamsAPI ?? new StreamhubStreamsAPI(this.props.apiClient)
      // streamUpdates: new Map<number, StreamhubStreamUpdateStatus>()
    }
  }

  componentDidMount () {
    this._addSocketListeners()
  }

  componentWillUnmount () {
    this._removeSocketListeners()
  }

  // -------

  _addSocketListeners = () => {
    this.props.streamhubServerContext.store.socketClient?.addSocketListener('streamUpdate', (streamData: Object) => {
      console.log('StreamhubStreamsProvider - addSocketListeners - streamUpdate - streamData: ', streamData)
      const stream = OutputStream.fromJSON(streamData)
      console.log('StreamhubStreamsProvider - addSocketListeners - streamUpdate - stream: ', stream)
      console.log('StreamhubStreamsProvider - addSocketListeners - streamUpdate - status:', stream.status, '==', stream.status ? IOutputStreamStatus[stream.status] : '-')
      if (stream && stream.id) {
        const streams = this.state.streams
        // update the related streams entry
        const index = streams?.findIndex((s) => s.id === stream.id)
        if (streams && index !== undefined && index >= 0) {
          console.log('StreamhubStreamsProvider - addSocketListeners - streamUpdate - updating stream:', stream.id)
          streams[index] = stream
          this.setState({ streams })
        } else {
          console.log('StreamhubStreamsProvider - addSocketListeners - streamUpdate - ERROR: stream not found:', stream.id)
        }
        // TESTING:
        // TODO: how would we detect error, or starting/stopping via just socket updates? (could do without starting/stopping, but may need to move the error server side so its returned in the stream object itself & so avilable here?)
        // const streamUpdates = this.state.streamUpdates
        // streamUpdates.set(stream.id, (stream && stream.isActive ? StreamhubStreamUpdateStatus.started : StreamhubStreamUpdateStatus.stopped))
        // console.log('StreamhubStreamsProvider - addSocketListeners - streamUpdate - streamUpdates.get(stream.id): ', streamUpdates.get(stream.id))
        // this.setState({ streamUpdates })
      }
    })
  }

  _removeSocketListeners = () => {
    this.props.streamhubServerContext.store.socketClient?.removeSocketListener('streamUpdate')
  }

  // -------

  fetchStreams = async () => {
    const { streamsStatus } = this.state
    const newStatus = streamsStatus && streamsStatus === StreamhubStreamsStatus.done ? StreamhubStreamsStatus.updating : StreamhubStreamsStatus.loading
    await new Promise((resolve) => this.setState({ streamsStatus: newStatus, streamsError: undefined }, () => { resolve(true) }))
    try {
      // await new Promise(resolve => setTimeout(resolve, 2000)) // DEBUG ONLY: add a delay to test the loading state
      const streams = await this.state.streamsAPI.fetchStreams()
      // console.log('StreamhubStreamsProvider - fetchStreams - streams: ', streams)
      this.updateStreamTags(streams)
      await new Promise((resolve) => this.setState({ streams, streamsStatus: StreamhubStreamsStatus.done }, () => { resolve(true) }))
      // throw new Error('DEBUG ERROR')
      // this.setState({ streams: [], streamsStatus: StreamhubStreamsStatus.done })
    } catch (error) {
      console.error('StreamhubStreamsProvider - fetchStreams - error:', error)
      await new Promise((resolve) => this.setState({ streams: undefined, streamsStatus: StreamhubStreamsStatus.error, streamsError: error }, () => { resolve(true) }))
    }
  }

  // creates an array of unique tags from all streams & updates the state var with them (instead of the server supplying the tags separately, which it may be extended to do in the future)
  updateStreamTags = (streams: Array<OutputStream>) => {
    const streamTags: Array<string> = []
    for (const stream of streams) {
      if (stream.tags && stream.tags.length > 0) {
        for (const tag of stream.tags) {
          if (!streamTags.includes(tag)) {
            streamTags.push(tag)
          }
        }
      }
    }
    console.log('StreamhubStreamsProvider - updateStreamTags - streamTags: ', streamTags)
    this.setState({ streamTags })
  }

  // -------

  selectStream = (stream?: OutputStream) => {
    this.setState({ selectedStream: stream })
  }

  // NB: requires fetchStreams to have been called so the stream can be looked up by its id
  selectStreamWithId = (streamId?: number) => {
    if (!streamId) {
      this.selectStream(undefined)
      return
    }
    const stream = this.state.streams?.find((stream) => stream.id === streamId)
    if (stream) {
      this.selectStream(stream)
    }
  }

  // -------

  createStream = async (sourceId: number, name?: string, url?: string, duration?: number, retryEnabled?: boolean, retryDelay?: number, retryMaxAttempts?: number, programData?: IOutputStreamProgramData, isTemp: boolean = false, tags?: Array<string>) => {
    try {
      await new Promise((resolve) => this.setState({ stream: undefined, streamAction: StreamhubStreamAction.create, streamStatus: StreamhubStreamStatus.running, streamError: undefined }, () => { resolve(true) }))
      const newStream = await this.state.streamsAPI.createStream(sourceId, name, url, duration, retryEnabled, retryDelay, retryMaxAttempts, programData, isTemp, tags)
      await new Promise((resolve) => this.setState({ stream: newStream, streamAction: StreamhubStreamAction.create, streamStatus: StreamhubStreamStatus.done, streamError: undefined }, () => { resolve(true) }))
    } catch (error) {
      await new Promise((resolve) => this.setState({ stream: undefined, streamAction: StreamhubStreamAction.create, streamStatus: StreamhubStreamStatus.error, streamError: error }, () => { resolve(true) }))
    }
  }

  updateStream = async (streamId: number, sourceId: number, name?: string, url?: string, duration?: number, retryEnabled?: boolean, retryDelay?: number, retryMaxAttempts?: number, programData?: IOutputStreamProgramData, isTemp: boolean = false, tags?: Array<string>) => {
    try {
      await new Promise((resolve) => this.setState({ streamAction: StreamhubStreamAction.update, streamStatus: StreamhubStreamStatus.running, streamError: undefined }, () => { resolve(true) })) // NB: not updating the stream state until after the update (incase it fails)
      const newStream = await this.state.streamsAPI.updateStream(streamId, sourceId, name, url, duration, retryEnabled, retryDelay, retryMaxAttempts, programData, isTemp, tags)
      await new Promise((resolve) => this.setState({ stream: newStream, streamAction: StreamhubStreamAction.update, streamStatus: StreamhubStreamStatus.done, streamError: undefined }, () => { resolve(true) }))
    } catch (error) {
      await new Promise((resolve) => this.setState({ streamAction: StreamhubStreamAction.update, streamStatus: StreamhubStreamStatus.error, streamError: error }, () => { resolve(true) })) // NB: leaving the existing stream state as it was before the update attempt
    }
  }

  deleteStream = async (stream: OutputStream) => {
    try {
      await new Promise((resolve) => this.setState({ stream: stream, streamAction: StreamhubStreamAction.delete, streamStatus: StreamhubStreamStatus.running, streamError: undefined }, () => { resolve(true) }))
      await this.state.streamsAPI.deleteStream(stream.id)
      await new Promise((resolve) => this.setState({ stream: stream, streamAction: StreamhubStreamAction.delete, streamStatus: StreamhubStreamStatus.done, streamError: undefined }, () => { resolve(true) }))
    } catch (error) {
      await new Promise((resolve) => this.setState({ stream: stream, streamAction: StreamhubStreamAction.delete, streamStatus: StreamhubStreamStatus.error, streamError: error }, () => { resolve(true) }))
    }
  }

  // NB: requires fetchStreams to have been called so the stream can be looked up by its id (only needed to set the local stream state for the result)
  deleteStreamWithId = async (streamId: number) => {
    const stream = this.state.streams?.find((stream) => stream.id === streamId)
    if (!stream) {
      const error = Error('Stream not found')
      await new Promise((resolve) => this.setState({ stream: undefined, streamAction: StreamhubStreamAction.delete, streamStatus: StreamhubStreamStatus.error, streamError: error }, () => { resolve(true) }))
      return
    }
    await this.deleteStream(stream)
  }

  // -------

  // TODO: careful if converting these to update the local state instead of return the values
  // TODO: as multiple of these start/stop calls could be triggered closely together & may overlap before the api responds
  // TODO: so can't really share a single state value for these responses (like we currently do for the create/update/delete ones, but those are highly unlikely to have overlapping calls)
  // TODO: maybe update the cached streams array (loaded from fetchStreams initially) with the streams status instead of reporting back via a specific start/stop set of state vars?

  startStream = async (streamId: number): Promise<OutputStream | null> => {
    // const { streams /*, streamUpdates */ } = this.state
    try {
      // streamUpdates.set(streamId, StreamhubStreamUpdateStatus.starting)
      // this.setState({ streamUpdates })
      const startResult = await this.state.streamsAPI.startStream(streamId)
      // await new Promise((resolve) => setTimeout(resolve, 500)) // add a delay so the UI shows the loading indicator long enough to see it
      // streamUpdates.set(streamId, (startResult && startResult.isActive ? StreamhubStreamUpdateStatus.started : StreamhubStreamUpdateStatus.error))
      // this.setState({ streamUpdates })
      // if (startResult) {
      //   // update the related streams entry
      //   const index = streams?.findIndex((stream) => stream.id === streamId)
      //   if (streams && index !== undefined && index >= 0) {
      //     streams[index] = startResult
      //     this.setState({ streams })
      //   }
      // }
      return startResult
    } catch (error: any) {
      // streamUpdates.set(streamId, StreamhubStreamUpdateStatus.error)
      // this.setState({ streamUpdates })
      // TODO: parse the error from here, or just let it come through via the stream object socket updates??
      console.error('StreamhubStreamsProvider - startStream - error: ', error)

      // TESTING: trigger a stream specific error state (as if we'd got an error from the web socket)
      // TODO: only do this for certain type of errors? if the network call failed to get-to/return-from the api?
      // TODO: if the stream already has an error msg set, should it not be reset here?
      if (this.state.streams) {
        const streams = [...this.state.streams]
        const streamIndex = streams.findIndex((s) => s.id === streamId)
        if (streamIndex >= 0) {
          streams[streamIndex].status = IOutputStreamStatus.error
          streams[streamIndex].errorMsg = error.message ?? error
          this.setState({ streams })
        }
      }

      throw error
    }
  }

  startStreams = async (streamIds: Array<number>): Promise<Array<OutputStream>> => {
    return await this.state.streamsAPI.startStreams(streamIds)
  }

  restartStream = async (streamId: number): Promise<OutputStream | null> => {
    // const { streams /*, streamUpdates */ } = this.state
    try {
      // streamUpdates.set(streamId, StreamhubStreamUpdateStatus.starting)
      // this.setState({ streamUpdates })
      const restartResult = await this.state.streamsAPI.restartStream(streamId)
      // await new Promise((resolve) => setTimeout(resolve, 1000)) // add a delay so the UI shows the loading indicator long enough to see it
      // streamUpdates.set(streamId, (restartResult ? StreamhubStreamUpdateStatus.started : StreamhubStreamUpdateStatus.error))
      // this.setState({ streamUpdates })
      // if (restartResult) {
      //   // update the related streams entry
      //   const index = streams?.findIndex((stream) => stream.id === streamId)
      //   if (streams && index !== undefined && index >= 0) {
      //     streams[index] = restartResult
      //     this.setState({ streams })
      //   }
      // }
      return restartResult
    } catch (error: any) {
      // streamUpdates.set(streamId, StreamhubStreamUpdateStatus.error)
      // this.setState({ streamUpdates })
      // TODO: parse the error from here, or just let it come through via the stream object socket updates??
      console.error('StreamhubStreamsProvider - restartStream - error: ', error)

      // TESTING: trigger a stream specific error state (as if we'd got an error from the web socket)
      // TODO: only do this for certain type of errors? if the network call failed to get-to/return-from the api?
      // TODO: if the stream already has an error msg set, should it not be reset here?
      // TODO: do we want to track how the error was triggered, as when this happens, we might not currently show its still in theory running & tapping it again (while showing an error) just triggers a stop when it was at least running (although that will then bring the status in sync..)
      if (this.state.streams) {
        const streams = [...this.state.streams]
        const streamIndex = streams.findIndex((s) => s.id === streamId)
        if (streamIndex >= 0) {
          streams[streamIndex].status = IOutputStreamStatus.error
          streams[streamIndex].errorMsg = error.message ?? error
          this.setState({ streams })
        }
      }

      throw error
    }
  }

  restartStreams = async (streamIds: Array<number>): Promise<Array<OutputStream>> => {
    return await this.state.streamsAPI.restartStreams(streamIds)
  }

  restartAllActiveStreams = async (): Promise<boolean> => {
    return await this.state.streamsAPI.restartAllActiveStreams()
  }

  stopStream = async (streamId: number): Promise<OutputStream | null> => {
    // const { streams /*, streamUpdates */ } = this.state
    try {
      // streamUpdates.set(streamId, StreamhubStreamUpdateStatus.stopping)
      // this.setState({ streamUpdates })
      const stopResult = await this.state.streamsAPI.stopStream(streamId)
      // await new Promise((resolve) => setTimeout(resolve, 1000)) // add a delay so the UI shows the loading indicator long enough to see it
      // streamUpdates.set(streamId, (stopResult ? StreamhubStreamUpdateStatus.stopped : StreamhubStreamUpdateStatus.error))
      // this.setState({ streamUpdates })
      // if (stopResult) {
      //   // update the related streams entry
      //   const index = streams?.findIndex((stream) => stream.id === streamId)
      //   if (streams && index !== undefined && index >= 0) {
      //     streams[index] = stopResult
      //     this.setState({ streams })
      //   }
      // }
      return stopResult
    } catch (error: any) {
      // streamUpdates.set(streamId, StreamhubStreamUpdateStatus.error)
      // this.setState({ streamUpdates })
      // TODO: parse the error from here, or just let it come through via the stream object socket updates??
      console.error('StreamhubStreamsProvider - stopStream - error: ', error)
      throw error
    }
  }

  stopStreams = async (streamIds: Array<number>): Promise<Array<OutputStream>> => {
    return await this.state.streamsAPI.stopStreams(streamIds)
  }

  stopAllActiveStreams = async (): Promise<boolean> => {
    return await this.state.streamsAPI.stopAllActiveStreams()
  }

  // -------

  startQuickStream = async (sourceId: number, outputUrl: string): Promise<OutputStream | null> => {
    return await this.state.streamsAPI.startQuickStream(sourceId, outputUrl)
  }

  stopQuickStream = async (streamId: number): Promise<boolean> => {
    return await this.state.streamsAPI.stopQuickStream(streamId)
  }

  stopAllQuickStreams = async (): Promise<boolean> => {
    return await this.state.streamsAPI.stopAllQuickStreams()
  }

  // -------

  actions: IStreamhubStreamsActions = {
    // streams
    fetchStreams: this.fetchStreams,
    // stream
    selectStream: this.selectStream,
    selectStreamWithId: this.selectStreamWithId,
    createStream: this.createStream,
    updateStream: this.updateStream,
    deleteStream: this.deleteStream,
    deleteStreamWithId: this.deleteStreamWithId,
    // start/stop streams
    startStream: this.startStream,
    startStreams: this.startStreams,
    restartStream: this.restartStream,
    restartStreams: this.restartStreams,
    restartAllActiveStreams: this.restartAllActiveStreams,
    stopStream: this.stopStream,
    stopStreams: this.stopStreams,
    stopAllActiveStreams: this.stopAllActiveStreams,
    // quick streams (legacy)
    startQuickStream: this.startQuickStream,
    stopQuickStream: this.stopQuickStream,
    stopAllQuickStreams: this.stopAllQuickStreams
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: IStreamhubStreamsStore = {
  //  ...
  // }

  render () {
    return (
      <StreamhubStreamsContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for IStreamhubStreamsStore */ }}
      >
        {this.props.children}
      </StreamhubStreamsContext.Provider>
    )
  }
}

const withStreamhubStreamsContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withStreamhubStreamsContextHOC = (props: any) => (
    <StreamhubStreamsContext.Consumer>
      {(streamhubStreamsContext) => {
        if (streamhubStreamsContext === null) {
          throw new Error('StreamhubStreamsContext must be used within an StreamhubStreamsProvider')
        }
        // console.log('withStreamhubStreamsContext - render - StreamhubStreamsContext.Consumer - streamhubStreamsContext.store: ', streamhubStreamsContext.store)
        return (<Component {...props} {...{ streamhubStreamsContext: streamhubStreamsContext }} />)
      }}
    </StreamhubStreamsContext.Consumer>
  )
  return withStreamhubStreamsContextHOC
}

const StreamhubStreamsProvider = withStreamhubServerContext(StreamhubStreamsProviderBase)
export { StreamhubStreamsProvider }
export { withStreamhubStreamsContext }
