import React from 'react'
import _ from 'lodash'

import ArkAspectRatio from 'src/core/components/ArkAspectRatio'
import ArkCard from 'src/core/components/ArkCard'
import { Channel, Notice } from 'src/core/models'
import { IViewerContext, useViewer } from 'src/viewer/providers/ViewerProvider'
import { IViewerNoticeContext, useViewerNotice } from 'src/viewer/providers/ViewerNoticeProvider'
import { IUserContext, useUser } from 'src/core/providers/UserProvider'

const ChannelNotices = () => {
  const viewer: IViewerContext = useViewer()
  const viewerNotice: IViewerNoticeContext = useViewerNotice()
  const user: IUserContext = useUser()

  const channel: Channel | undefined = viewer.channel
  if (!channel) {
    console.error('ChannelNotices - render - no channel')
    return null
  }

  const notices: Notice[] = viewerNotice.getFilteredNotices(channel)
  const notice: Notice | undefined = _.first(notices)
  if (!notice) {
    console.error('ChannelNotices - render - no notice')
    return null
  }

  if (!notice.card) {
    console.error('ChannelNotices - render - no card')
    return null
  }

  // console.log('ChannelNotices - render - notice:', notice)

  return (
    <ArkAspectRatio>
      <ArkCard
        card={notice.card}
        dismissible={notice.dismissible || (notice.dismissible_admin && user.actions.isAdmin())}
        onDismiss={() => viewerNotice.dismissNotice(channel.id, notice.id)}
        viewer
      />
    </ArkAspectRatio>
  )
}

export default ChannelNotices
