import React from 'react'

import { withUserContext, IUserMultiContext } from '../../../core/providers'

import ArkViewerPage from '../../components/ArkViewerPage/ArkViewerPage'
import ArkProjectSelectView from '../../../core/components/ArkProjectSelectView'

interface IProps extends IUserMultiContext {}
interface IState {}

class ViewerProjectSelectPage extends React.Component<IProps, IState> {
  render () {
    return (
      <ArkViewerPage disableSectionMainMenu>
        <ArkProjectSelectView projects={this.props.userContext.store.userProjects} />
      </ArkViewerPage>
    )
  }
}

export default withUserContext(ViewerProjectSelectPage)
