/**
 * ChecklistCard
 */

import React, { useContext } from 'react'

import ArkIconButton from 'src/core/components/ArkIconButton'
import { NodeGraphContext } from '../nodeGraph/NodeGraphProvider'
import { ChecklistContext } from './ChecklistProvider'
import ChecklistView from './ChecklistView'

import styles from './ChecklistCard.module.css'

const ChecklistCard = () => {
  const { store: nodeGraphStore } = useContext(NodeGraphContext)
  const { isFullscreen } = nodeGraphStore

  const { actions, store } = useContext(ChecklistContext)
  const { setShowChecklist } = actions
  const { showChecklistCard } = store

  if (!showChecklistCard || isFullscreen) return null

  return (
    <div className={styles.card}>
      <div className={styles.body}>
        <div className={styles.header}>
          <div className={styles.title}>Project set up checklist</div>
        </div>
        <ChecklistView />
      </div>
      <ArkIconButton
        className={styles.closeButton}
        color='var(--bd-lighter)'
        hoverColor='var(--tx-light)'
        name='close'
        onClick={() => setShowChecklist(false)}
        size={24}
      />
    </div>
  )
}

export default ChecklistCard
