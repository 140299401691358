/**
 * ArkHighlightedText
 * - highlights chars/words within a block of text (e.g. for use when searching/filtering)
 * - currently just basic highlighting for our current needs within admin listing pages
 *
 * NB: partially based around this & some other coments on the same SO post - ref: https://stackoverflow.com/a/47803998
 *
 * NB: consider using `react-highlight-words` (or similar) if we want more features - ref: https://www.npmjs.com/package/react-highlight-words
 */
import React from 'react'
import _ from 'lodash'

import styles from './ArkHighlightedText.module.css'

export interface ArkHighlightedTextProps {
  className?: string // NB: if you specify a className it will always wrap the children/text with a span, if you omit it a span will only be added if a highlight string is specified (to keep the output light/minimal)
  children?: string // NB: requiring children to be a string/text so we can optionally highlight it (not a regular child React.ReactNode element)
  highlight?: string
  text?: string // NB: alternative prop to children - only used if children === undefined (had a scenario where the string was always being passed in as an array object for some odd reason despite the syntax all looking ok?)
}

const ArkHighlightedText = (props: ArkHighlightedTextProps) => {
  const { className, children, highlight, text } = props
  if (!children && !text) return null
  if (!highlight && !className) return (<>{children ?? text}</>)
  if (!highlight && className) return (<span className={styles.text + (className ? ' ' + className : '')}>{children ?? text}</span>)
  // console.log('ArkHighlightedText - typeof children: ', typeof children, ' = ', children)
  if (typeof children !== 'string' && !text) return (<>{children}</>)
  const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
  const sourceText = children ?? text
  const parts = sourceText?.split(regex) ?? []
  return (
    <span className={styles.highlightText + (className ? ' ' + className : '')}>
      {parts.filter(part => part).map((part, i) => (
        regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
      ))}
    </span>
  )
}
export default ArkHighlightedText
