import React from 'react'
import _ from 'lodash'
import { DropdownItemProps } from 'semantic-ui-react'

import { AUTO_SOLO_ENABLED, STATUS_ENABLED, USER_SELECTION_DROPDOWNS_DESELECT_ENABLED } from 'src/constants/config'
import { OBJECT_CHANNEL_NAME, OBJECT_CHANNEL_NAME_PLURAL } from 'src/constants/strings'
import ArkButton from 'src/core/components/ArkButton'
import ArkDropdown from 'src/core/components/ArkDropdown'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkOnlineStatusIndicator from 'src/core/components/ArkOnlineStatusIndicator'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { useGlobalConfig, useProjectStatus } from 'src/core/providers'
import { useViewer } from 'src/viewer/providers/ViewerProvider'

import ChannelAudioToolbar from './ChannelAudioToolbar'
import ChannelLayoutPopup from './ChannelLayoutPopup'
import ChannelOptionsPopup from './ChannelOptionsPopup'

import styles from './ChannelHeader.module.css'

interface ChannelHeaderProps {
  onFullscreenClick: () => void
}

const ChannelHeader = (props: ChannelHeaderProps) => {
  const globalConfig = useGlobalConfig()
  const projectStatus = useProjectStatus()
  const viewer = useViewer()

  const channelOptions: DropdownItemProps[] = _.map(viewer.channels, channel => (
    {
      content: (
        <div className={styles.channelDropdownOption}>
          {STATUS_ENABLED && (
            <>
              <ArkOnlineStatusIndicator
                onlineStatus={projectStatus.getChannelOnlineStatus(channel.id)}
                size={12}
                variant='channel'
              />
              <ArkSpacer />
            </>
          )}
          <div>{channel.name}</div>
        </div>
      ),
      value: channel.id
    }
  ))

  const channelId: number | undefined = viewer.channel?.id

  return (
    <div className={`${styles.header} ${viewer.fullscreen && styles.fullscreen}`}>
      <ArkDropdown
        className={styles.channelDropdown}
        clearable={USER_SELECTION_DROPDOWNS_DESELECT_ENABLED}
        data-test-id="ark-channel-dropdown" // e2e testing identifier
        onChange={(_event, data) => data.value ? viewer.selectChannel(data.value as number) : viewer.deselectChannel()}
        options={channelOptions}
        selection
        text={_.some(channelOptions) ? `Select ${OBJECT_CHANNEL_NAME}` : `No ${OBJECT_CHANNEL_NAME_PLURAL} available`}
        trigger={_.find(channelOptions, { value: channelId })?.content}
        value={channelId}
      />

      <ArkSpacer grow />
      {AUTO_SOLO_ENABLED && globalConfig.store.config.showAutoSoloTopBar && (
        <>
          <ArkButton
            color={viewer.getChannelAutoSolo() ? 'green' : undefined}
            data-test-id="ark-channel-solo-btn" // e2e testing identifier
            disabled={!channelId}
            onClick={() => viewer.setChannelAutoSolo(!viewer.getChannelAutoSolo())}
          >
            AUTO SOLO
          </ArkButton>
          <ArkSpacer />
        </>
      )}
      {viewer.audioStarted
        ? (
          <ArkButton
            color={viewer.getChannelMute() ? 'red' : undefined}
            data-test-id="ark-channel-mute-btn" // e2e testing identifier
            disabled={!channelId}
            onClick={() => viewer.setChannelMute(!viewer.getChannelMute())}
          >
            MUTE
          </ArkButton>
        )
        : (
          <ArkButton
            color='green'
            data-test-id="ark-channel-start-audio-btn" // e2e testing identifier
            onClick={() => viewer.startAudio()}
          >
            START AUDIO
          </ArkButton>
        )}
      <ArkSpacer />
      <ChannelAudioToolbar disabled={!channelId} />
      <ArkSpacer />
      <ArkIconButton
        data-test-id="ark-channel-mixer-btn" // e2e testing identifier
        disabled={!channelId}
        name='volume-off'
        onClick={() => viewer.setShowMixer(!viewer.showMixer)}
      />
      <ChannelOptionsPopup disabled={!channelId} />
      <ArkSpacer />
      <ChannelLayoutPopup disabled={!channelId} />
      <ArkIconButton
        data-test-id="ark-channel-restart-btn" // e2e testing identifier
        disabled={!channelId || viewer.restarting}
        name='restart'
        onClick={viewer.restart}
        size={24}
      />
      <ArkIconButton
        data-test-id="ark-channel-fullscreen-btn" // e2e testing identifier
        name={viewer.fullscreen ? 'fullscreen-off' : 'fullscreen-on'}
        onClick={props.onFullscreenClick}
      />
    </div>
  )
}

export default ChannelHeader
