import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import { Redirect, useLocation } from 'react-router-dom'

import { AuthContext, /* AuthStatus, */ GlobalConfigContext, NavContext, UserContext, UserStatus } from 'src/core/providers'

import ArkButton from 'src/core/components/ArkButton'
import ArkCenterLayout from 'src/core/components/ArkCenterLayout'
import ArkConfirmModal from 'src/core/components/ArkConfirmModal'
import ArkDivider from 'src/core/components/ArkDivider'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkPage from 'src/core/components/ArkPage/ArkPage'
import ArkSegment from 'src/core/components/ArkSegment'

import * as ROUTES from 'src/constants/routes'

import styles from './LoginExternalDevicePage.module.css'

// a custom hook that builds on useLocation to parse the query string
// ref: https://v5.reactrouter.com/web/example/query-parameters
const useURLQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const formSchema = yup.object().shape({
  deviceUUID: yup.string().required() // TODO: length etc? <<<<
})

interface IProps {}

const LoginExternalDevicePage = (_props: IProps) => {
  const mounted = useRef(false)

  const authContext = useContext(AuthContext)
  const userContext = useContext(UserContext)
  const navContext = useContext(NavContext)
  const configContext = useContext(GlobalConfigContext)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [submitResult, setSubmitResult] = useState<boolean | undefined>(undefined)
  const [submitError, setSubmitError] = useState<Error | undefined>(undefined)

  const [loginConfirmed, setLoginConfirmed] = useState<boolean>(false)
  const [showLogoutConfirmAlert, setShowLogoutConfirmAlert] = useState<boolean>(false)

  // example tvOS device auth request url: http://localhost:3000/auth?auth_code=abc123

  // parse url query args
  const urlQuery = useURLQuery()
  const deviceCode = urlQuery.get('auth_code') ?? undefined

  const location = useLocation()
  // console.log('LoginExternalDevicePage - location:', location)

  const appName = configContext.store.config.appName
  const isLoggedOut = userContext.store.userStatus === UserStatus.loggedOut && !userContext.store.user
  const userEmail = userContext.store.user?.email
  const userName = userContext.store.user?.name()
  const isGuest = userContext.store.user?.isGuest ?? false

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const init = async () => {
    console.log('LoginExternalDevicePage - init - isLoggedOut:', isLoggedOut, ' isGuest:', isGuest, ' deviceCode:', deviceCode)
    // TODO: check if we're logged in, if not cache the device uuid from the url if one is specified & prompt the user to login (likely show a prompt/msg saying why first...)
  }

  useEffect(() => {
    init()
  }, [])

  // -------

  // useEffect(() => {
  //   console.log('LoginExternalDevicePage - useEffect - authStatus:', authContext.store.authStatus ? AuthStatus[authContext.store.authStatus] : authContext.store.authStatus)
  // }, [authContext.store.authStatus])

  // useEffect(() => {
  //   console.log('LoginExternalDevicePage - useEffect - userStatus:', UserStatus[userContext.store.userStatus])
  // }, [userContext.store.userStatus])

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    const { deviceUUID } = fieldValues
    // console.log('LoginExternalDevicePage - onFormSubmit - deviceUUID:', deviceUUID)
    try {
      setIsSubmitting(true)
      setSubmitResult(undefined)
      if (submitError) setSubmitError(undefined)
      const result = await authContext.actions.loginExternalDevice(deviceUUID)
      console.log('LoginExternalDevicePage - onFormSubmit - loginExternalDevice - result:', result)
      setSubmitResult(true)
      setIsSubmitting(false)
    } catch (error) {
      console.error('LoginExternalDevicePage - onFormSubmit - loginExternalDevice - error:', error)
      setSubmitError(error)
      setSubmitResult(false)
      setIsSubmitting(false)
    }
  }

  const onFormValueChanged = async (fieldKey: string, _fieldValue: any, _oldFieldValue: any) => {
    // console.log('LoginExternalDevicePage - onFormValueChanged - fieldKey:', fieldKey, ' fieldValue:', fieldValue)
    // if an error is set, clear it once the user starts to type/edit the device uuid input
    if (fieldKey === 'deviceUUID' && submitError !== undefined) {
      setSubmitError(undefined)
    }
  }

  // -------

  const onClose = () => {
    // redirect to the home page
    navContext.actions.goto(ROUTES.HOME)
  }

  const onLoginConfirm = async () => {
    // set the login confirmed flag to redirect to the login page
    setLoginConfirmed(true)
  }

  const onLogout = async () => {
    setShowLogoutConfirmAlert(true)
  }
  const onLogoutConfirmed = async () => {
    setShowLogoutConfirmAlert(false)
    // logout the current user & redirect back here after re-logging in
    await authContext.actions.logout(() => {
      console.log('LoginExternalDevicePage - onLogoutConfirmed - logged out')
      // NB: by setting this logout callback with the auth provider it will stop the default auto redirect post login (to the home/login page)
      //     ..& so instead reloads this deviec auth page, which will then redirect to the login page with the 'from' arg set & so post login will redirect back here
      // NB: now also auto setting the 'loginConfirmed' flag below so we skip the login prompt on this page, & go straight to the login page (as the user already confirmed they want to logout & re-login as another user)
      if (mounted.current) {
        setLoginConfirmed(true)
      }
    })
  }

  // -------

  const _renderLoggedOut = () => {
    return (
      <div className={styles.loginMsg}>
        <p>Login locally so you can then authorise the device.</p>
        <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={onLoginConfirm}>
          Login
        </ArkButton>
      </div>
    )
  }

  const _renderConfirmLogoutModal = () => {
    return (
      <ArkConfirmModal
        show={showLogoutConfirmAlert}
        title={<>Use a Different Account?</>}
        message={<>
          <p>Do you want to logout to switch to a different account?</p>
          <p>You will be redirected back here after.</p>
        </>}
        onCancel={() => {}}
        onConfirm={() => { onLogoutConfirmed() }}
        onClose={() => { setShowLogoutConfirmAlert(false) }}
      />
    )
  }

  const _renderForm = () => {
    const formFields: Array<ArkFormField> = []
    formFields.push({
      type: ArkFormFieldType.Input,
      key: 'deviceUUID',
      label: 'Device Code',
      required: true,
      className: styles.deviceCodeInput,
      defaultValue: deviceCode ?? undefined,
      fieldProps: { maxLength: 6, autoFocus: true }
    })
    formFields.push({ type: ArkFormFieldType.OKButton, key: 'submit', label: 'Authorise Device', fieldProps: { loading: isSubmitting, fluid: true } })
    formFields.push(
      {
        type: ArkFormFieldType.Field,
        key: 'divider',
        // wrapperProps: { className: styles.flagsWarningField },
        content: (<ArkDivider horizontal>OR</ArkDivider>)
      }
    )

    formFields.push(...[
      {
        type: ArkFormFieldType.Button,
        key: 'logout',
        label: 'Use a different account',
        fieldProps: { fluid: true, onClick: onLogout, /* floated: 'right', */ basic: true, color: 'yellow' },
        disabled: isSubmitting
      },
      {
        type: ArkFormFieldType.CancelButton,
        key: 'cancel',
        label: 'Cancel',
        fieldProps: { fluid: true, onClick: onClose /*, floated: 'left' */ },
        disabled: isSubmitting
      }
    ])

    const userTxt = isGuest ? <><strong>Guest User: {userName}</strong></> : <><strong>{userName}</strong> ({userEmail})</>
    return (
      <>
        <p>
          Enter the device code displayed on the Apple TV {appName} app to login as {userTxt} on that device.
        </p>
        <ArkForm
          formKey="deviceLogin"
          className={styles.deviceLoginForm}
          inverted
          formError={submitError}
          formFields={formFields}
          formSchema={formSchema}
          onFormSubmit={onFormSubmit}
          onValueChanged={onFormValueChanged}
        ></ArkForm>
      </>
    )
  }

  const _renderSuccess = () => {
    return (
      <>
        {submitResult && (<>
          <ArkMessage positive>
            <ArkMessage.Header>Device Authorisation Success</ArkMessage.Header>
            <ArkMessage.Item>You should now be logged in on the other device.</ArkMessage.Item>
          </ArkMessage>
          <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={onClose} style={{ marginTop: 15 }}>
            OK
          </ArkButton>
        </>)}
      </>
    )
  }

  const _renderContent = () => {
    const isLoggedIn = !isLoggedOut
    return (
      <>
        <ArkHeader as="h2" textAlign="center">{appName} Device Authorisation</ArkHeader>
        {isLoggedIn && !submitResult && _renderForm()}
        {isLoggedIn && submitResult && _renderSuccess()}
        {!isLoggedIn && _renderLoggedOut()}
        {_renderConfirmLogoutModal()}
      </>
    )
  }

  // if the user isn't logged in, redirect to the login page after they've confirmed y want to login
  // NB: sets the current location as the 'from' state so it redirects back here after (with the device code still set if its in the url)
  if (loginConfirmed) {
    return (
      <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: location } }} />
    )
  }

  return (
    <ArkPage>
      <ArkCenterLayout className={styles.deviceLogin}>
        <ArkSegment inverted className={styles.deviceLoginSegment}>{_renderContent()}</ArkSegment>
      </ArkCenterLayout>
    </ArkPage>
  )
}

export default LoginExternalDevicePage
