import React from 'react'

import ArkButton from 'src/core/components/ArkButton'
import ArkInfoView from 'src/core/components/ArkInfoView'
import ArkPage from 'src/core/components/ArkPage/ArkPage'

import { PAGE_SUPPORT_NAME } from 'src/constants/strings'
import { SUPPORT_AUTO_REDIRECT_ON_LOAD } from 'src/constants/config'
import { IGlobalConfigMultiContext, withGlobalConfigProvider } from 'src/core/providers'

import styles from './SupportPage.module.css'

interface IProps extends IGlobalConfigMultiContext {}
interface IState {}

class SupportPage extends React.Component<IProps, IState> {
  componentDidMount () {
    // auto load the external support url on page load (in the same tab, its upto the calling links to open this in a new tab if they want to)
    if (SUPPORT_AUTO_REDIRECT_ON_LOAD) this.gotoSupportDocs()
  }

  render () {
    return (
      <ArkPage>
        {this.renderNotice()}
      </ArkPage>
    )
  }

  renderNotice () {
    return (
      <ArkInfoView
        iconName='support'
        title={PAGE_SUPPORT_NAME}
        message={
          <div>
            <p>View our knowledge base documentation or email support.</p>
            <ArkButton className={styles.supportButton} color="blue" fluid basic size="large" onClick={() => {
              this.gotoSupportDocs()
            }}>VIEW DOCUMENTATION</ArkButton>
            <ArkButton className={styles.supportButton} color="blue" fluid basic size="large" onClick={() => {
              this.gotoSupportEmail()
            }}>EMAIL {PAGE_SUPPORT_NAME.toUpperCase()}</ArkButton>
            <ArkButton className={styles.supportButton} color="red" fluid basic size="large" onClick={() => {
              this.closeSupportWindow()
            }}>CLOSE THIS {PAGE_SUPPORT_NAME.toUpperCase()} TAB</ArkButton>
          </div>
        }
        titleClassName={styles.supportTitle}
      />
    )
  }

  gotoSupportDocs = () => {
    const globalConfig = this.props.configContext.store.config
    window.location.href = globalConfig.supportDocURL
  }

  gotoSupportEmail = () => {
    const globalConfig = this.props.configContext.store.config
    window.location.href = 'mailto:' + globalConfig.supportEmail + '?subject=' + globalConfig.appName + ' web app support request.'
  }

  closeSupportWindow = () => {
    window.close()
  }
}
export default withGlobalConfigProvider(SupportPage)
