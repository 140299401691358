import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'

import { useDebounceEffect } from 'src/core/hooks/debounce'

import { AuthStatus, useAuth } from '../AuthProvider'
import { DEFAULT_CHANNEL, DEFAULT_COMPANY, DEFAULT_CONFIG, DEFAULT_PROGRAM, DEFAULT_PROJECT } from './defaults'
import { LocalConfig, LocalConfigChannel, LocalConfigProgram } from './types'
import { GlobalConfigContext } from '../GlobalConfigProvider'

type ChannelParams = {
  companyId: number;
  projectId: number;
  channelId: number;
}

type ProgramParams = {
  companyId: number;
  projectId: number;
  channelId: number;
  programId: number;
}

type LocalConfigContextValue = {
  getLocalConfigChannel: (params: ChannelParams) => LocalConfigChannel
  getLocalConfigProgram: (params: ProgramParams) => LocalConfigProgram
  setLocalConfigChannel: (channel: Partial<LocalConfigChannel>, params: ChannelParams) => void
  setLocalConfigProgram: (program: Partial<LocalConfigProgram>, params: ProgramParams) => void
}

const LocalConfigContext = createContext<LocalConfigContextValue>({} as LocalConfigContextValue)

export const useLocalConfig = () => useContext(LocalConfigContext)

type LocalConfigProviderProps = {
  children: ReactNode
}

const LocalConfigProvider = (props: LocalConfigProviderProps) => {
  const auth = useAuth()
  const { store: configStore } = useContext(GlobalConfigContext)

  const [_config, _setConfig] = useState<LocalConfig>(DEFAULT_CONFIG)

  const reset = (): void => {
    // console.log('LocalConfigProvider - reset')
    _setConfig(DEFAULT_CONFIG)
  }

  useEffect(() => {
    if (auth.store.authStatus === AuthStatus.loggedOut) reset()
  }, [auth.store.authStatus])

  const load = (): void => {
    try {
      // console.log('LocalConfigProvider - load')
      const cookie: string | undefined = Cookies.get('config')
      if (!cookie) {
        // console.log('LocalConfigProvider - load - no cookie')
        return
      }
      const config: LocalConfig = JSON.parse(cookie)
      // console.log('LocalConfigProvider - load - config:', config)
      if (config.version !== DEFAULT_CONFIG.version) {
        reset()
        return
      }
      _setConfig(config)
    } catch (error) {
      console.error('LocalConfigProvider - load - error:', error)
    }
  }

  useEffect(() => {
    load()
  }, [])

  const save = useCallback((): void => {
    try {
      // console.log('LocalConfigProvider - save - config:', _config)
      const cookie: string = JSON.stringify(_config)
      Cookies.set('config', cookie)
    } catch (error) {
      console.error('LocalConfigProvider - save - error:', error)
    }
  }, [_config])

  useDebounceEffect(() => {
    save()
  }, [_config], 250)

  // console methods
  useEffect(() => {
    // printLocalConfig()
    (window as any).printLocalConfig = () => _config;

    // resetLocalConfig()
    (window as any).resetLocalConfig = reset
  }, [_config])

  /**
   * validate
   */

  const validateChannelParams = (params: ChannelParams): void => {
    if (!params.companyId) throw Error('LocalConfigProvider - validateChannelParams - invalid companyId')
    if (!params.projectId) throw Error('LocalConfigProvider - validateChannelParams - invalid projectId')
    if (!params.channelId) throw Error('LocalConfigProvider - validateChannelParams - invalid channelId')
  }

  const validateProgramParams = (params: ProgramParams): void => {
    if (!params.companyId) throw Error('LocalConfigProvider - validateProgramParams - invalid companyId')
    if (!params.projectId) throw Error('LocalConfigProvider - validateProgramParams - invalid projectId')
    if (!params.channelId) throw Error('LocalConfigProvider - validateProgramParams - invalid channelId')
    if (!params.programId) throw Error('LocalConfigProvider - validateProgramParams - invalid programId')
  }

  /**
   * defaults
   */

  const createDefaults = (params: {
    companyId?: number,
    projectId?: number,
    channelId?: number,
    programId?: number
  }) => {
    // console.log('LocalConfigProvider - createDefaults - params:', params)

    // create company
    if (
      params.companyId &&
      !_config
        .companies[params.companyId]
    ) {
      _config
        .companies[params.companyId] = {
          ...DEFAULT_COMPANY,
          id: params.companyId
        }
    }

    // create project
    if (
      params.companyId &&
      params.projectId &&
      !_config
        .companies[params.companyId]
        .projects[params.projectId]
    ) {
      _config
        .companies[params.companyId]
        .projects[params.projectId] = {
          ...DEFAULT_PROJECT,
          id: params.projectId
        }
    }

    // create channel
    if (
      params.companyId &&
      params.projectId &&
      params.channelId &&
      !_config
        .companies[params.companyId]
        .projects[params.projectId]
        .channels[params.channelId]
    ) {
      _config
        .companies[params.companyId]
        .projects[params.projectId]
        .channels[params.channelId] = {
          ...DEFAULT_CHANNEL,
          id: params.channelId,
          // TESTING: apply global config specific defaults
          passthrough: configStore.config.preferPassthrough
        }
    }

    // create program
    if (
      params.companyId &&
      params.projectId &&
      params.channelId &&
      params.programId &&
      !_config.companies[params.companyId]
        .projects[params.projectId]
        .channels[params.channelId]
        .programs[params.programId]
    ) {
      _config
        .companies[params.companyId]
        .projects[params.projectId]
        .channels[params.channelId]
        .programs[params.programId] = {
          ...DEFAULT_PROGRAM,
          id: params.programId
        }
    }
  }

  /**
   * channels
   */

  const getLocalConfigChannel = (params: ChannelParams): LocalConfigChannel => {
    // console.log('LocalConfigProvider - getLocalConfigChannel - params:', params)
    validateChannelParams(params)
    createDefaults(params)
    return _config
      .companies[params.companyId]
      .projects[params.projectId]
      .channels[params.channelId]
  }

  const setLocalConfigChannel = (channel: Partial<LocalConfigChannel>, params: ChannelParams) => {
    // console.log('LocalConfigProvider - setLocalConfigChannel - channel:', channel, 'params:', params)
    validateChannelParams(params)
    createDefaults(params)
    const currentChannel: LocalConfigChannel = _config
      .companies[params.companyId]
      .projects[params.projectId]
      .channels[params.channelId]
    _config
      .companies[params.companyId]
      .projects[params.projectId]
      .channels[params.channelId] = { ...currentChannel, ...channel }
    _setConfig({ ..._config })
  }

  /**
   * programs
   */

  const getLocalConfigProgram = (params: ProgramParams): LocalConfigProgram => {
    // console.log('LocalConfigProvider - getLocalConfigProgram - params:', params)
    validateProgramParams(params)
    createDefaults(params)
    return _config
      .companies[params.companyId]
      .projects[params.projectId]
      .channels[params.channelId]
      .programs[params.programId]
  }

  const setLocalConfigProgram = (program: Partial<LocalConfigProgram>, params: ProgramParams) => {
    // console.log('LocalConfigProvider - setLocalConfigProgram - program:', program, 'params:', params)
    validateProgramParams(params)
    createDefaults(params)
    const currentProgram: LocalConfigProgram = _config
      .companies[params.companyId]
      .projects[params.projectId]
      .channels[params.channelId]
      .programs[params.programId]
    _config
      .companies[params.companyId]
      .projects[params.projectId]
      .channels[params.channelId]
      .programs[params.programId] = { ...currentProgram, ...program }
    _setConfig({ ..._config })
  }

  return (
    <LocalConfigContext.Provider value={{
      getLocalConfigChannel,
      getLocalConfigProgram,
      setLocalConfigChannel,
      setLocalConfigProgram
    }}>
      {props.children}
    </LocalConfigContext.Provider>
  )
}

export default LocalConfigProvider
