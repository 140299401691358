import StreamhubAPIClient from './StreamhubAPIClient'

import OutputStream, { IOutputStreamProgramData } from '../models/OutputStream'

export default class StreamhubStreamsAPI {
  apiClient: StreamhubAPIClient

  constructor (apiClient: StreamhubAPIClient) {
    this.apiClient = apiClient
  }

  fetchStreams = async (): Promise<Array<OutputStream>> => {
    try {
      const response = await this.apiClient.apiGet('/streams', {})
      const streams: Array<OutputStream> = []
      if (response.data && response.data.streams && response.data.streams) {
        const streamsData = response.data.streams
        for (const streamData of streamsData) {
          const stream = OutputStream.fromJSON(streamData)
          if (stream) {
            streams.push(stream)
          }
        }
      }
      return streams
    } catch (error) {
      console.error('StreamhubStreamsAPI - fetchStreams - error: ', error)
      throw error
    }
  }

  // -------

  createStream = async (sourceId: number, name?: string, url?: string, duration?: number, retryEnabled?: boolean, retryDelay?: number, retryMaxAttempts?: number, programData?: IOutputStreamProgramData, isTemp: boolean = false, tags?: Array<string>): Promise<OutputStream> => {
    try {
      const data: { [key: string]: any } = {
        sourceId: sourceId
      }
      if (name) data.name = name
      if (url) data.url = url
      if (duration) data.duration = duration
      if (retryEnabled !== undefined) data.retryEnabled = retryEnabled
      if (retryDelay !== undefined) data.retryDelay = retryDelay
      if (retryMaxAttempts !== undefined) data.retryMaxAttempts = retryMaxAttempts
      if (isTemp) data.isTemp = isTemp
      if (tags) data.tags = tags
      if (programData) {
        data.server = programData.server
        data.companyId = programData.companyId
        data.projectId = programData.projectId
        data.programId = programData.programId
        data.companyName = programData.companyName
        data.projectName = programData.projectName
        data.programName = programData.programName
      }
      console.log('StreamhubStreamsAPI - createStream - data:', data)

      const response = await this.apiClient.apiPost('/stream', data)
      if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
        if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
          const result = response.data.result
          if (result === true && response.data.stream) {
            const stream = OutputStream.fromJSON(response.data.stream)
            return stream
          }
        }
      }
    } catch (error) {
      console.error('StreamhubStreamsAPI - createStream - error: ', error)
      throw error
    }
    throw Error('Failed to create stream') // fallback error
  }

  updateStream = async (streamId: number, sourceId: number, name?: string, url?: string, duration?: number, retryEnabled?: boolean, retryDelay?: number, retryMaxAttempts?: number, programData?: IOutputStreamProgramData, isTemp: boolean = false, tags?: Array<string>): Promise<OutputStream> => {
    try {
      const data: { [key: string]: any } = {
        id: streamId,
        sourceId: sourceId
      }
      if (name) data.name = name
      if (url) data.url = url
      if (duration) data.duration = duration
      if (retryEnabled !== undefined) data.retryEnabled = retryEnabled
      if (retryDelay !== undefined) data.retryDelay = retryDelay
      if (retryMaxAttempts !== undefined) data.retryMaxAttempts = retryMaxAttempts
      if (isTemp) data.isTemp = isTemp
      if (programData) {
        data.server = programData.server
        data.companyId = programData.companyId
        data.projectId = programData.projectId
        data.programId = programData.programId
        data.companyName = programData.companyName
        data.projectName = programData.projectName
        data.programName = programData.programName
      }
      if (tags) {
        data.tags = tags
      }
      console.log('StreamhubStreamsAPI - updateStream - data:', data)

      const response = await this.apiClient.apiPut('/stream', data)
      if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
        const result = response.data.result
        if (result === true && response.data.stream) {
          const stream = OutputStream.fromJSON(response.data.stream)
          return stream
        }
      }
    } catch (error) {
      console.error('StreamhubStreamsAPI - updateStream - error: ', error)
      throw error
    }
    throw Error('Failed to update source') // fallback error
  }

  // NB: UNTESTED
  deleteStream = async (streamId: number): Promise<boolean> => {
    try {
      const response = await this.apiClient.apiDelete('/stream', { id: streamId })
      return (response.status === 200) // (response.data && response.data.result === true)
    } catch (error) {
      console.error('StreamhubStreamsAPI - deleteStream - error: ', error)
      // if (error.code === 409) {} // NB: could check for the specific error code, but its currently enough to just show the custom error msg from the body
      if (error.response && error.response.data && error.response.data.error) {
        throw Error(error.response.data.error)
      }
      throw error
    }
  }

  // -------

  startStream = async (streamId: number): Promise<OutputStream | null> => {
    const streams = await this.startStreams([streamId])
    if (streams && streams.length > 0) return streams[0]
    throw new Error('Invalid response')
  }

  startStreams = async (streamIds: Array<number>): Promise<Array<OutputStream>> => {
    try {
      // NB: the endpoint supports starting multiple at once, we currently only use it with 1 at a time (& only parse a single stream from the response)
      const data = streamIds.map(id => { return { id } })
      const response = await this.apiClient.apiPost('/streams/start', data)
      console.log('StreamhubStreamsAPI - startStreams - response: ', response)
      if (response.data && (response.data.streams !== undefined || response.data.streams !== null)) {
        // NB: returns an array of stream objects, check each ones `isActive` field to know if it started ok
        //     (WARNING: will it be true if still starting?? or do we need to check the status field instead???)
        const streamsResult = response.data.streams
        if (streamsResult && streamsResult.length > 0) {
          const streams: Array<OutputStream> = []
          for (const r of streamsResult) {
            const stream = OutputStream.fromJSON(r)
            if (stream) streams.push(r)
          }
          return streams
        }
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('StreamhubStreamsAPI - startStreams - error: ', error)
      throw error
    }
  }

  stopStream = async (streamId: number): Promise<OutputStream> => {
    const streams = await this.stopStreams([streamId])
    if (streams && streams.length > 0) return streams[0]
    throw new Error('Invalid response')
  }

  stopStreams = async (streamIds: Array<number>): Promise<Array<OutputStream>> => {
    try {
      const data = streamIds.map(id => { return { id } })
      const response = await this.apiClient.apiPost('/streams/stop', data)
      if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
        // NB: returns an array of stream objects, check each ones `isActive` field to know if it stopped ok
        const result = response.data.result
        if (result && result.length > 0) {
          const streams: Array<OutputStream> = []
          for (const r of result) {
            const stream = OutputStream.fromJSON(r)
            if (stream) streams.push(r)
          }
          return streams
        }
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('StreamhubStreamsAPI - stopStream - error: ', error)
      throw error
    }
  }

  restartStream = async (streamId: number): Promise<OutputStream> => {
    const streams = await this.restartStreams([streamId])
    if (streams && streams.length > 0) return streams[0]
    throw new Error('Invalid response')
  }

  restartStreams = async (streamIds: Array<number>): Promise<Array<OutputStream>> => {
    try {
      const data = streamIds.map(id => { return { id } })
      const response = await this.apiClient.apiPost('/streams/restart', data)
      if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
        // NB: returns an array of stream objects, check each ones `isActive` field to know if it stopped ok
        const result = response.data.result
        if (result && result.length > 0) {
          const streams: Array<OutputStream> = []
          for (const r of result) {
            const stream = OutputStream.fromJSON(r)
            if (stream) streams.push(r)
          }
          return streams
        }
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('StreamhubStreamsAPI - restartStream - error: ', error)
      throw error
    }
  }

  stopAllActiveStreams = async (): Promise<boolean> => {
    try {
      const response = await this.apiClient.apiPost('/streams/stopall', {})
      if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
        const result = response.data.result
        return (result === true)
      }
      return false
    } catch (error) {
      console.error('StreamhubStreamsAPI - stopAllStreams - error: ', error)
      throw error
    }
  }

  restartAllActiveStreams = async (): Promise<boolean> => {
    try {
      const response = await this.apiClient.apiPost('/streams/restartall', {})
      if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
        const result = response.data.result
        return (result === true)
      }
      return false
    } catch (error) {
      console.error('StreamhubStreamsAPI - restartAllStreams - error: ', error)
      throw error
    }
  }

  // -------
  // NB: OLD(ER) quick/temp streams (only partially supported by the api currently, use the newer persistent ones for now, may use these for future stress testing only)

  startQuickStream = async (sourceId: number, outputUrl: string): Promise<OutputStream | null> => {
    try {
      // NB: the endpoint supports starting multiple at once, we currently only use it with 1 at a time (& only parse a single stream from the response)
      const response = await this.apiClient.apiPost('/streams/quick-start', [{ sourceId: sourceId, url: outputUrl }])
      if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
        if (response.data.stream && response.data.stream.length > 0) {
          const stream = OutputStream.fromJSON(response.data.stream[0])
          return stream
        }
      }
      return null
    } catch (error) {
      console.error('StreamhubStreamsAPI - startQuickStream - error: ', error)
      throw error
    }
  }

  // TODO: stopQuickStream
  stopQuickStream = async (streamId: number): Promise<boolean> => {
    try {
      const response = await this.apiClient.apiPost('/streams/quick-stop', { id: streamId })
      if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
        const result = response.data.result
        return (result === true)
      }
      return false
    } catch (error) {
      console.error('StreamhubStreamsAPI - stopQuickStream - error: ', error)
      throw error
    }
  }

  // TODO: stopAllQuickStreams
  stopAllQuickStreams = async (): Promise<boolean> => {
    try {
      const response = await this.apiClient.apiPost('/streams/quick-stopall', {})
      if (response.data && (response.data.result !== undefined || response.data.result !== null)) {
        const result = response.data.result
        return (result === true)
      }
      return false
    } catch (error) {
      console.error('StreamhubStreamsAPI - stopAllQuickStreams - error: ', error)
      throw error
    }
  }

  // -------
}
