import React, { useState } from 'react'

import ArkButton from 'src/core/components/ArkButton'
import ArkConfirmModal, { ArkConfirmModalMode } from 'src/core/components/ArkConfirmModal'

import styles from './ProjectSettingsView.module.css'

export interface ProjectTranscoderSettingsSyncViewProps {
  force?: boolean
  syncing: boolean
  allow: boolean // disabled the sync option while certain other actions are running that we don't want to run at the same time
  syncResult?: boolean
  syncError?: Error
  onSyncTranscoder?: (force?: boolean) => Promise<void>
  className?: string
}

const ProjectTranscoderSettingsSyncView = (props: ProjectTranscoderSettingsSyncViewProps) => {
  const { force, syncing, allow, syncResult, syncError, onSyncTranscoder: _onSyncTranscoder, className } = props
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

  const syncRequired = !force

  const onSyncTranscoder = () => {
    if (_onSyncTranscoder) _onSyncTranscoder(force)
  }

  const onShowConfirmModal = () => {
    setShowConfirmModal(true)
  }

  const renderConfirmModal = () => {
    return (
      <ArkConfirmModal
        show={showConfirmModal}
        mode={ArkConfirmModalMode.warning}
        title='Sync Transcoder Settings?'
        message={
          <>
            <div>
              <p>Are you sure you want to {force ? 'force ' : ''}sync transcoder settings now?</p>
              {!force && (
                <p className={styles.notice}>Transcoder settings need to be applied to this project.</p>
              )}
              <p><span className={styles.title}>WARNING:</span> Doing so will restart all active programs &amp; may briefly interrupt playback.</p>
            </div>
          </>
        }
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setShowConfirmModal(false)
          onSyncTranscoder()
        }}
        onClose={() => setShowConfirmModal(false)}
      />
    )
  }

  return (
    <div className={`${styles.transcoderSyncView} ${className}`}>
      {(syncResult !== undefined) && (
        <>
          {syncResult && (
            <div className={styles.transcoderSyncSuccess}>
              <div className={styles.title}>SYNC SUCCESS</div>
              <div className={styles.text}>Transcoder settings have been applied to the project programs.</div>
            </div>
          )}
          {!syncResult && (
            <div className={styles.transcoderSyncSuccess}>
              <div className={styles.title}>SYNC NOT NEEDED</div>
              <div className={styles.text}>Transcoder settings have already been applied to the project programs.</div>
            </div>
          )}
        </>
      )}
      {syncError && (
        <div className={styles.transcoderSyncError}>
          <div className={styles.title}>SYNC ERROR</div>
          <div className={styles.text}>{syncError.message}</div>
        </div>
      )}
      {syncRequired && (syncResult !== true) && (
        <div className={styles.transcoderSyncWarning}>
          <div>
            WARNING: Transcoder settings need to be applied to this project.
            Doing so will restart all active programs &amp; may briefly interrupt playback.
          </div>
          <ArkButton type='button' size='medium' color='orange' loading={syncing} disabled={!allow} onClick={onShowConfirmModal}>SYNC</ArkButton>
        </div>
      )}
      {!syncRequired && (
        <ArkButton type='button' size='medium' color='orange' fluid loading={syncing} disabled={!allow} onClick={onShowConfirmModal}>FORCE SYNC TRANSCODERS</ArkButton>
      )}
      {renderConfirmModal()}
    </div>
  )
}

export default ProjectTranscoderSettingsSyncView
