import React from 'react'

import ArkAudioMeterToolbar from 'src/core/components/ArkAudioMeterToolbar'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkSpacer from 'src/core/components/ArkSpacer'
import ArkVolumeInput from 'src/core/components/ArkVolumeInput'
import { AUDIO_LEVELS_ENABLED } from 'src/constants/config'
import { useAudioLevels } from 'src/viewer/providers/AudioLevelsProvider'
import { useViewer } from 'src/viewer/providers/ViewerProvider'

import styles from './ChannelAudioToolbar.module.css'

interface ChannelAudioToolbarProps {
  disabled?: boolean
}

const ChannelAudioToolbar = (props: ChannelAudioToolbarProps) => {
  const { disabled } = props

  const audioLevels = useAudioLevels()
  const viewer = useViewer()

  return (
    <div className={`${styles.toolbar} ${viewer.fullscreen ? styles.fullscreen : ''}`}>
      <div className={styles.label}>MASTER</div>
      <ArkSpacer />
      <ArkVolumeInput
        disabled={disabled}
        onChange={viewer.setChannelVolume}
        size={120}
        value={viewer.getChannelVolume()}
      />
      {AUDIO_LEVELS_ENABLED
        ? (
          <ArkIconButton disabled={disabled} onClick={() => viewer.setShowMixer(true)}>
            <ArkAudioMeterToolbar
              color='var(--yellow)'
              value={audioLevels.getChannelAudioLevelPreFader()}
            />
            <ArkSpacer small />
            <ArkAudioMeterToolbar
              color='var(--green)'
              disabled={!viewer.audioStarted || viewer.getChannelMute() || viewer.getChannelVolume() === 0}
              value={audioLevels.getChannelAudioLevelPostFader()}
            />
          </ArkIconButton>
        )
        : <ArkSpacer />}
    </div>
  )
}

export default ChannelAudioToolbar
