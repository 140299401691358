import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom' //, Redirect

import { withUserContext, IUserMultiContext, withCompanyInviteContext, ICompanyInviteMultiContext, UserStatus } from '../../../../core/providers'
import * as ROUTES from '../../../../constants/routes'

import { CompanyInviteUserDetails /*, UserStatus */ } from '../../../../core/models'

import ArkButton from '../../../components/ArkButton'
import ArkCenterLayout from '../../../components/ArkCenterLayout'
import ArkLoader from '../../../components/ArkLoader'
import ArkPage from '../../../components/ArkPage/ArkPage'

import { Message, Segment } from 'semantic-ui-react'
import { OBJECT_COMPANY_NAME } from 'src/constants/strings'

// NB: this page should be public, we catch & handle if the user clicks an invite link when they're not logged in yet
// NB: we store the invite in localStorage & prompt the user to login/register, & then resume the invite accept call once login finishes

interface IProps extends RouteComponentProps<{inviteToken: string}>, IUserMultiContext, ICompanyInviteMultiContext {}
interface IState {}

class CompanyInviteAcceptPage extends React.Component<IProps, IState> {
  _isMounted: boolean = false

  componentDidMount () {
    this._isMounted = true
    this.runLookupInviteToken()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const inviteDetailsLoading = this.props.companyInviteContext.store.inviteDetailsLoading
    return (
      <ArkPage>
        <ArkCenterLayout>
          <Segment inverted>
            {inviteDetailsLoading && this.renderLoadingInviteDetails()}
            {!inviteDetailsLoading && this.renderProcessingInviteDetails()}
          </Segment>
        </ArkCenterLayout>
      </ArkPage>
    )
  }

  renderLoadingInviteDetails = () => {
    return <ArkLoader message='Checking' />
  }

  renderProcessingInviteDetails = () => {
    const {
      inviteExpired,
      inviteAlreadyAccepted,
      loginRequired,
      registrationRequired,
      isSubmitting,
      success,
      error
    } = this.props.companyInviteContext.store
    return (
      <>
        {error && this.renderError(error)}

        {inviteExpired && this.renderInviteExpired()}
        {inviteAlreadyAccepted && this.renderInviteAlreadyAccepted()}

        {(loginRequired || registrationRequired) && this.renderRegisterOrLoginPrompt()}

        {isSubmitting && this.renderVerifyingInvite()}

        {success && this.renderInviteAccepted()}
      </>
    )
  }

  renderError = (error: Error) => {
    const isLoggedIn = this.props.userContext.store.userStatus === UserStatus.loggedIn
    return (
      <>
        <Message negative>
          <Message.Header>Error</Message.Header>
          <p>{error.message}</p>
        </Message>
        {isLoggedIn && this.renderOpenViewerButton()}
        {!isLoggedIn && this.renderLoginButton()}
      </>
    )
  }

  renderInviteExpired = () => {
    const isLoggedIn = this.props.userContext.store.userStatus === UserStatus.loggedIn
    return (
      <>
        <Message warning>
          <Message.Header>{OBJECT_COMPANY_NAME} Invite Expired</Message.Header>
          <Message.Content style={{ marginTop: 5, marginBottom: 5 }}>
            <p>Your invitation has expired.<br />Please contact the person who invited you to send a new invite.</p>
            {/* TODO: in later phases we'll likely want to add a way to 'request a re-invite' that notifies the company admins to prod them to do it? */}
            {/* TODO: and/or we may want to allow the user to re-trigger a new invite to be sent on demand? (of a company level setting was enabled to allow it perhaps?) */}
          </Message.Content>
        </Message>
        {/* TODO: showing the same buttons as 'already accepted' for now, just so the user has some prompt to follow */}
        {/* TODO: will at least want to consider what to show when they're logged in, as they may not have any company access yet */}
        {isLoggedIn && this.renderOpenViewerButton()}
        {!isLoggedIn && this.renderLoginButton()}
      </>
    )
  }

  renderInviteAlreadyAccepted = () => {
    const isLoggedIn = this.props.userContext.store.userStatus === UserStatus.loggedIn
    return (
      <>
        <Message warning>
          <Message.Header>Company Invite Already Accepted</Message.Header>
          <Message.Content style={{ marginTop: 5, marginBottom: 5 }}>
            <p>You have already accepted this company invite.</p>
          </Message.Content>
        </Message>
        {isLoggedIn && this.renderOpenViewerButton()}
        {!isLoggedIn && this.renderLoginButton()}
      </>
    )
  }

  renderVerifyingInvite = () => {
    return <ArkLoader message='Verifying' />
  }

  renderRegisterOrLoginPrompt = () => {
    const { inviteDetails, loginRequired, registrationRequired } = this.props.companyInviteContext.store
    if (loginRequired) {
      return (
        <>
          <Message info>
            <Message.Header>Login Required</Message.Header>
            <p>Please login to accept the {OBJECT_COMPANY_NAME} invite</p>
          </Message>
          {this.renderLoginButton(inviteDetails)}
        </>
      )
    } else if (registrationRequired) {
      return (
        <>
          <Message info>
            <Message.Header>Registration Required</Message.Header>
            <p>Please register to accept the {OBJECT_COMPANY_NAME} invite</p>
          </Message>
          {this.renderRegisterButton(inviteDetails)}
        </>
      )
    }
  }

  renderInviteAccepted = () => {
    return (
      <>
        <Message positive>
          <Message.Header>Success</Message.Header>
          <p>You&apos;ve accepted the {OBJECT_COMPANY_NAME} invitation.</p>
        </Message>
        {this.renderOpenViewerButton()}
      </>
    )
  }

  // -----

  renderLoginButton = (inviteDetails?: CompanyInviteUserDetails) => {
    return (
      <ArkButton fluid positive onClick={() => {
        this.props.history.push({
          pathname: ROUTES.LOGIN,
          search: '', // '?query=abc',
          state: inviteDetails ? { inviteDetails: inviteDetails } : undefined
        })
      }}>
        LOGIN
      </ArkButton>
    )
  }

  renderRegisterButton = (inviteDetails?: CompanyInviteUserDetails) => {
    return (
      <ArkButton fluid positive onClick={() => {
        this.props.history.push({
          pathname: ROUTES.REGISTER,
          search: '', // '?query=abc',
          state: inviteDetails ? { inviteDetails: inviteDetails } : undefined
        })
      }}>
        REGISTER
      </ArkButton>
    )
  }

  renderOpenViewerButton = () => {
    const { companyId } = this.props.companyInviteContext.store
    return (
      <ArkButton
        fluid
        positive
        onClick={() => {
          // if the user is already (email) verified & we have a companyId from the invite details auto select it, otherwise deselect..
          // ..if a company is already selected so when we redirect to the viewer the user is prompted to choose & will see the new company listed
          if (this.props.userContext.actions.isVerified() && companyId) {
            this.props.userContext.actions.selectCompany(companyId)
          } else if (this.props.userContext.store.selectedCompany) {
            this.props.userContext.actions.deselectCurrentCompany()
          }
          // NB: manually redirect to the viewer, as the select/deselect calls won't when on the invite page (to stop auto directing away on page load)
          this.props.history.push(ROUTES.VIEWER)
        }}
      >
        CONTINUE
      </ArkButton>
    )
  }

  // -----

  runLookupInviteToken = async () => {
    await this.props.companyInviteContext.actions.lookupInviteTokenAndProcess(this.props.match.params.inviteToken)
  }
}

export default withRouter(withCompanyInviteContext(withUserContext(CompanyInviteAcceptPage)))
