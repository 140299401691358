import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import _ from 'lodash'

import { CompanyAdminContext } from 'src/core/providers'

import { CompanyVideoEngine, ICompanyVideoEngineUpdateData } from 'src/core/models/video_engine'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormMultiError, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkMessage from 'src/core/components/ArkMessage'

import { OBJECT_VIDEO_ENGINE_NAME } from 'src/constants/strings'

import styles from './CompanyVideoEngineForm.module.css'

const formSchema = yup.object().shape({
  // name: yup.string().min(4).max(25).label(OBJECT_VIDEO_ENGINE_NAME + ' Name')
  // TODO: add other fields <<<<
})

// TESTING: dynamic field key validation (with the keys at the root level)
// ref: https://github.com/jquense/yup/issues/130#issuecomment-339549828
// UPDATE: disabled/skipped for now, as the per-field inline errors don't really work/look ok within the port range fields by default & will need tweaking to fit
// const mapValues = require('lodash/mapValues')
// const baseSchema = {
//   name: yup.string().min(4).max(25).label(OBJECT_VIDEO_ENGINE_NAME + ' Name')
//   // TODO: add other fields <<<<
// }
// const formSchema = yup.lazy(obj => yup.object(
//   mapValues(obj, (v: any, k: any) => {
//     console.log('VideoEngineForm - formSchema - k:', k, ' v:', v)
//     if ((k.startsWith('srtInputPortRanges_') || k.startsWith('srtOutputPortRanges_')) && (k.endsWith('_start'))) {
//       console.log('VideoEngineForm - formSchema - PORT RANGE FIELD - START - k:', k, ' v:', v)
//       return yup.number().integer().required().min(1).max(65535).label('Start Port').typeError('Must be between 1-65535')
//     } else if ((k.startsWith('srtInputPortRanges_') || k.startsWith('srtOutputPortRanges_')) && (k.endsWith('_end'))) {
//       console.log('VideoEngineForm - formSchema - PORT RANGE FIELD - END - k:', k, ' v:', v)
//       return yup.number().integer().required().min(1).max(65535).label('End Port').typeError('Must be between 1-65535')
//     } else {
//       return (baseSchema as any)[k]
//     }
//   })
// ))

// export enum VideoEngineFormMode {
//   Add = 'add',
//   Edit = 'edit',
// }

interface IProps {
  companyId: number
  videoEngine: CompanyVideoEngine
  onCancel?: Function
  onSave?: Function
  onClose?: Function
  insideModal?: boolean // ArkForm prop - enable when showing this form within a modal (so fieldset label bg's match)
  // NB: not currently supporting deleting via this form
}

const CompanyVideoEngineForm = (props: IProps) => {
  const mounted = useRef(false)

  const { companyId, videoEngine, onCancel, onClose, onSave, insideModal } = props

  const companyAdminContext = useContext(CompanyAdminContext)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSaved, setHasSaved] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>(undefined)

  // form field values (saved & current)
  const [savedValues, setSavedValues] = useState({
    // ports
    // sldpPort: videoEngine.sldpPort ?? '',
    // sldpPortWS: videoEngine.sldpPortWS ?? '',
    // sldpPortWSS: videoEngine.sldpPortWSS ?? '',
    // rtmpPort: videoEngine.rtmpPort ?? '',
    // rtmpsPort: videoEngine.rtmpsPort ?? '',
    // rtspPort: videoEngine.rtspPort ?? '',
    // icecastInputPort: videoEngine.icecastInputPort ?? '',
    // icecastPlaybackPort: videoEngine.icecastPlaybackPort ?? '',
    srtInputPortRanges: videoEngine.srtInputPortRanges ? _.cloneDeep(videoEngine.srtInputPortRanges) : [], // NB: deep clone the arrays to avoid direct mutations to the underlying object
    srtOutputPortRanges: videoEngine.srtOutputPortRanges ? _.cloneDeep(videoEngine.srtOutputPortRanges) : []
  })
  const [formValues, setFormValues] = useState({
    // TODO: duping the `savedValues` seems to break change checks, as the formValues are always the same as the savedValues, is it referencing the same object instead of creating a copy, even if using the `...` spread operator?
    // TODO: check the `ProgramForm` which already uses this to make sure its working (it has change tracking as well, but presume its working on that form?)
    // ...savedValues
    // TEMP: reverted back to creating the fields individually for now (which for this form with only a few fields is fine, but if we want to use the above on others should look into a fix/work-around)
    // ports
    srtInputPortRanges: videoEngine.srtInputPortRanges ? _.cloneDeep(videoEngine.srtInputPortRanges) : [], // NB: deep clone the arrays to avoid direct mutations to the underlying object
    srtOutputPortRanges: videoEngine.srtOutputPortRanges ? _.cloneDeep(videoEngine.srtOutputPortRanges) : []
  })
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])
  // note which fields are numeric, so they can be parsed as numbers when onValueChange is called
  // TODO: memoize this?
  const numericFields: Array<string> = [] // 'companyId', 'sldpPort', 'sldpPortWS', 'sldpPortWSS', 'rtmpPort', 'rtmpsPort', 'rtspPort', 'icecastInputPort', 'icecastPlaybackPort']

  const generateRandomFieldHash = () => [...Array(10)].map(() => Math.random().toString(36)[2]).join('') // ref: https://stackoverflow.com/a/47496558
  const [formFieldHashes, setFormFieldHashes] = useState({ // unique hash for each field to force re-render when the field indexes change (e.g. when removing a port range)
    srtInputPortRanges: generateRandomFieldHash(),
    srtOutputPortRanges: generateRandomFieldHash()
  })

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const getFormValueChanges = () => {
    const _changes: Array<string> = []
    if (formValues) {
      for (const fieldName of Object.keys(formValues)) {
        const oldValue = savedValues !== undefined ? (savedValues as any)[fieldName] : undefined
        const newValue = (formValues as any)[fieldName]
        // console.log('VideoEngineForm - getFormValueChanges - fieldName:', fieldName, ' oldValue:', oldValue, ' newValue:', newValue)
        if (typeof oldValue === 'object' && typeof newValue === 'object') {
          // console.log('VideoEngineForm - getFormValueChanges - object comparison...')
          if (!_.isEqual(oldValue, newValue)) {
            // TESTING: look for differences between the arrays (& ideally get the indexes for all changes)
            // UPDATE: skipping this for now & just noting if anything in the array has changed
            // const diff1 = _.differenceWith(newValue, oldValue, _.isEqual)
            // const diff2 = _.differenceWith(oldValue, newValue, _.isEqual)
            // const intersection1 = _.intersectionBy(oldValue, newValue, _.isEqual)
            // console.log('VideoEngineForm - getFormValueChanges - diff1:', diff1, ' diff2:', diff2, ' intersection1:', intersection1)
            _changes.push(fieldName)
          }
        } else {
          if (oldValue !== newValue) {
            _changes.push(fieldName)
          }
        }
      }
    }
    console.log('VideoEngineForm - getFormValueChanges - _changes:', _changes)
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  const resetSaveResults = () => {
    setHasSaved(false)
    setError(undefined)
  }

  // -------

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = getFormValueChanges()
    setChanges(_changes)
  }, [formValues])

  // -------

  // NB: this supports org/company admin level video engine update fields only (currently just SRT input & output port ranges)
  const updateVideoEngine = async (fieldValues: ArkFormFieldValues) => {
    if (isSubmitting || !videoEngine) return
    resetSaveResults()
    setIsSubmitting(true)
    try {
      const saveData: ICompanyVideoEngineUpdateData = {}
      const videoEngineJSONKeyMap = CompanyVideoEngine.propertyToJSONKeyMap()
      for (const fieldName of Object.keys(videoEngineJSONKeyMap)) {
        if (fieldName === 'id') continue // skip the id field (not editable)
        if (hasChanges(fieldName)) { // fieldValues[fieldName] !== undefined) {
          (saveData as any)[fieldName] = fieldValues[fieldName]
        }
      }
      // TESTING: add the port ranges (from the dedicated form values state vars)
      // NB: these aren't added above from the raw form fieldValues data as the port ranges are stored in a different format (& with a randomised hash in the key as well)
      // UPDATE: if one of the SRT input/output port range fields has changed, both must be submitted (or the api returns a `warning` response with a 200 http status)
      // console.log('VideoEngineForm - updateVideoEngine - changes:', changes)
      if (changes.includes('srtInputPortRanges') || changes.includes('srtOutputPortRanges')) saveData.srtInputPortRanges = formValues.srtInputPortRanges
      if (changes.includes('srtInputPortRanges') || changes.includes('srtOutputPortRanges')) saveData.srtOutputPortRanges = formValues.srtOutputPortRanges
      console.log('VideoEngineForm - updateVideoEngine - saveData: ', saveData)
      // TODO: throw an error/warning if no changes have been made? (so nothing to save/udpate)
      const savedVideoEngine = await companyAdminContext.actions.updateCompanyVideoEngine(companyId, videoEngine.id, saveData)
      console.log('VideoEngineForm - updateVideoEngine - savedVideoEngine: ', savedVideoEngine)
      if (savedVideoEngine) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
          setSavedValues(formValues)
        }
        if (onSave) onSave()
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          throw new Error('A problem occurred adding the ' + OBJECT_VIDEO_ENGINE_NAME + ', please try again.')
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('VideoEngineForm - onFormSubmit - fieldValues: ', fieldValues)
    // TESTING: port range error handling
    // NB: done here instead of inline via yup/formSchema as the line error displays don't look good in the port range fields currently
    const errorMsgs: Array<string> = []
    if (error) setError(undefined) // reset previous local/component-level errors (if any)
    if (changes.includes('srtInputPortRanges')) {
      const srtInputPortRanges = formValues.srtInputPortRanges
      for (const range of srtInputPortRanges) {
        if (range.start > range.end || range.start === 0 || range.end === 0) {
          console.error('VideoEngineForm - onFormSubmit - PORT RANGE ERROR - srtInputPortRanges - invalid range:', range)
          errorMsgs.push('Invalid SRT input port range: ' + range.start + ' - ' + range.end)
        }
      }
    }
    if (changes.includes('srtOutputPortRanges')) {
      const srtOutputPortRanges = formValues.srtOutputPortRanges
      for (const range of srtOutputPortRanges) {
        if (range.start > range.end || range.start === 0 || range.end === 0) {
          console.error('VideoEngineForm - onFormSubmit - PORT RANGE ERROR - srtOutputPortRanges - invalid range:', range)
          errorMsgs.push('Invalid SRT output port range: ' + range.start + ' - ' + range.end)
        }
      }
    }
    if (errorMsgs.length > 0) {
      setError(new ArkFormMultiError(errorMsgs))
      return // halt - don't save/update the video engine entry when any port range errors are found
    }
    // NB: only video engine updates are supported at the org/company admin level (can't create new video engines)
    updateVideoEngine(fieldValues)
  }

  const onValueChanged = (fieldKey: string, fieldValue: any, oldFieldValue: any) => {
    console.log('VideoEngineForm - onValueChanged - fieldKey: ', fieldKey, ' fieldValue: ', fieldValue, ' oldFieldValue: ', oldFieldValue)
    // TESTING: special handling for port ranges, check if the fieldKey is a port range field
    if (fieldKey.startsWith('srtInputPortRanges') || fieldKey.startsWith('srtOutputPortRanges')) {
      // parse the index & start/end values from the fieldKey
      const parts = fieldKey.split('_')
      if (parts.length !== 4) {
        console.error('VideoEngineForm - onValueChanged - PORT RANGE FIELD - invalid fieldKey (parts):', fieldKey)
        return
      }
      const fieldValueInt = parseInt(fieldValue)
      if (isNaN(fieldValueInt)) {
        console.error('VideoEngineForm - onValueChanged - PORT RANGE FIELD - invalid fieldValue - fieldKey:', fieldKey, ' fieldValue:', fieldValue, ' fieldValueInt:', fieldValueInt)
        return
      }
      const rangeFieldKey = parts[0]
      const rangeIndex = parseInt(parts[2])
      const rangeFieldType = parts[3] === 'start' ? 'start' : parts[3] === 'end' ? 'end' : undefined
      if (!rangeFieldType) {
        console.error('VideoEngineForm - onValueChanged - PORT RANGE FIELD - invalid fieldKey (field type):', fieldKey)
        return
      }
      // console.log('VideoEngineForm - onValueChanged - PORT RANGE FIELD - rangeFieldKey: ', rangeFieldKey, ' rangeFieldType:', rangeFieldType, ' rangeIndex:', rangeIndex, ' fieldValue: ', fieldValue, ' oldFieldValue: ', oldFieldValue)
      if (rangeFieldKey === 'srtInputPortRanges' || rangeFieldKey === 'srtOutputPortRanges') {
        const oldRanged = (formValues as any)[rangeFieldKey]
        const newRanges = [...oldRanged]
        // console.log('VideoEngineForm - onValueChanged - PORT RANGE FIELD - oldRanged:', oldRanged)
        const range = newRanges[rangeIndex]
        if (rangeFieldType === 'start') {
          range.start = fieldValueInt
        } else if (rangeFieldType === 'end') {
          range.end = fieldValueInt
        }
        console.log('VideoEngineForm - onValueChanged - PORT RANGE FIELD - newRanges:', newRanges)
        console.log('VideoEngineForm - onValueChanged - PORT RANGE FIELD - savedValues(BEFORE):', savedValues)
        setFormValues({
          ...formValues,
          [rangeFieldKey]: newRanges
        })
        console.log('VideoEngineForm - onValueChanged - PORT RANGE FIELD - savedValues(AFTER):', savedValues)
      } else {
        console.error('VideoEngineForm - onValueChanged - PORT RANGE FIELD - invalid fieldKey (rangeFieldKey):', fieldKey)
      }
      return
    }
    // TESTING: numeric field handling (if the field isn't empty)
    // NB: initially looked to compare the old or saved value type to determine if the new value should be converted to a number, but if they are undefined that won't work, so now listing all numeric fields instead (towards the top of this component)
    if (numericFields.includes(fieldKey) && fieldValue !== '') {
      let fieldValueInt = parseInt(fieldValue)
      if (isNaN(fieldValueInt)) {
        fieldValueInt = 0 // NB: non numeric values will be set to 0 for now (TODO: or should they be set as empty string like the default empty form field value currently is?)
      }
      setFormValues({
        ...formValues,
        [fieldKey]: fieldValueInt
      })
    } else {
      setFormValues({
        ...formValues,
        [fieldKey]: fieldValue
      })
    }
    // NB: if the form can remain on screen after saving, call `resetSaveResults()` here
  }

  const _onCancel = () => {
    if (onCancel) onCancel()
  }

  // triggers after dismissing the success message
  const _onClose = async () => {
    if (!isSubmitting) setFormValues(savedValues) // reset the form back to the saved values
    if (onClose) onClose()
  }

  // -------

  // const _createInputTextField = (fieldKey: string, label: string, defaultValue?: string | number | boolean, required: boolean = false, className?: string, width: number | undefined = 10) => {
  //   let _className = className
  //   if (hasChanges(fieldKey)) { if (_className) _className += ' ' + styles.hasChanged; else _className = styles.hasChanged }
  //   return {
  //     type: ArkFormFieldType.Input,
  //     key: fieldKey,
  //     label: label,
  //     required: required,
  //     defaultValue: defaultValue,
  //     fieldProps: { width: width },
  //     className: _className // hasChanges(fieldKey) ? styles.hasChanged : undefined
  //   }
  // }

  const _createInputNumberRangeField = (rangeIndex: number, fieldKey: string, fieldHash: string, label: string, defaultValue?: { start: number, end: number }, required: boolean = false, className?: string, width: number | undefined = 10) => {
    const classNameStart = styles.inputNumberRangeStart
    const classNameEnd = styles.inputNumberRangeEnd
    return {
      type: ArkFormFieldType.Group,
      key: fieldKey + '_' + fieldHash + '_' + rangeIndex,
      className: styles.inputNumberRangeGroup + (className ? ' ' + className : ''),
      fields: [
        {
          type: ArkFormFieldType.Input,
          key: fieldKey + '_' + fieldHash + '_' + rangeIndex + '_start',
          // key: fieldKey + '[' + fieldHash + '][' + rangeIndex + '][start]',
          // key: fieldKey + '.' + fieldHash + '.' + rangeIndex + '.start',
          label: (rangeIndex === 0 ? 'Start' : undefined),
          required: required,
          defaultValue: defaultValue?.start,
          fieldProps: { width: width },
          className: classNameStart
        },
        {
          type: ArkFormFieldType.Input,
          key: fieldKey + '_' + fieldHash + '_' + rangeIndex + '_end',
          // key: fieldKey + '[' + fieldHash + '][' + rangeIndex + '][end]',
          // key: fieldKey + '.' + fieldHash + '.' + rangeIndex + '.end',
          label: (rangeIndex === 0 ? 'End' : undefined),
          required: required,
          defaultValue: defaultValue?.end,
          fieldProps: { width: width },
          className: classNameEnd
        },
        {
          type: ArkFormFieldType.Button,
          key: fieldKey + '_' + fieldHash + '_remove',
          label: 'x',
          className: styles.inputNumberRangeRemoveBtn,
          fieldProps: {
            size: 'mini',
            compact: true,
            onClick: () => {
              // console.log('VideoEngineForm - _createInputNumberRangeFields - REMOVE - rangeIndex:', rangeIndex)
              const newRanges = [...(formValues as any)[fieldKey]]
              // console.log('VideoEngineForm - _createInputNumberRangeFields - REMOVE - newRanges(BEFORE):', newRanges)
              newRanges.splice(rangeIndex, 1)
              // console.log('VideoEngineForm - _createInputNumberRangeFields - REMOVE - newRanges(AFTER):', newRanges)
              setFormValues({
                ...formValues,
                [fieldKey]: newRanges
              })
              // update the field hash to force a re-render (as indexes may have changed)
              const newFieldHashes = { ...formFieldHashes }
              setFormFieldHashes({
                ...newFieldHashes,
                [fieldKey]: generateRandomFieldHash()
              })
            }
          }
          // disabled: changes.length === 0
        }
      ]
      // fieldProps: { widths: 'equal', style: { justifyContent: 'space-between', alignItems: 'center', gap: '10px' } }
    }
  }

  const _createInputNumberRangeFields = (fieldKey: string, label: string, defaultValue?: Array<{ start: number, end: number }>, required: boolean = false, className?: string, width: number | undefined = 10) => {
    const currentFieldValue = (formValues as any)[fieldKey]
    const currentFieldHash = (formFieldHashes as any)[fieldKey]
    // console.log('VideoEngineForm - _createInputNumberRangeFields - fieldKey: ', fieldKey, ' defaultValue:', defaultValue, ' currentFieldValue: ', currentFieldValue, ' currentFieldHash:', currentFieldHash)
    return {
      type: ArkFormFieldType.Fieldset,
      key: fieldKey,
      label: label,
      className: className + ' ' + styles.inputNumberRangeFields + ' ' + (hasChanges(fieldKey) ? styles.hasChanged : undefined),
      fields: [
        ...(currentFieldValue && currentFieldValue.length > 0
          ? currentFieldValue.map((range: { start: number, end: number }, index: number) => {
            const isLast = index === currentFieldValue.length - 1
            const rangeClassName = isLast ? styles.inputNumberRangeGroupLast : undefined
            return _createInputNumberRangeField(index, fieldKey, currentFieldHash, label, range, required, rangeClassName, width)
          })
          : []),
        {
          type: ArkFormFieldType.Button,
          key: fieldKey + '_add',
          label: 'ADD',
          className: styles.inputNumberRangeFieldsAddBtn,
          fieldProps: {
            size: 'mini',
            compact: true,
            onClick: () => {
              // console.log('VideoEngineForm - _createInputNumberRangeFields - ADD ' + label)
              const newRanges = [...currentFieldValue]
              newRanges.push({ start: 0, end: 0 })
              setFormValues({
                ...formValues,
                [fieldKey]: newRanges
              })
            }
          }
          // disabled: changes.length === 0
        }
      ],
      fieldProps: { style: { flexGrow: 1 /*, flexBasis: '67%', minWidth: '200px' */ } },
      collapsible: false,
      collapsed: false
    }
  }

  const formFields: Array<ArkFormField> = []

  formFields.push(
    {
      type: ArkFormFieldType.Group,
      key: 'controlsAndPortsGroup',
      className: styles.controlsAndPortsGroup,
      fields: [
        {
          type: ArkFormFieldType.Fieldset,
          key: 'portsFieldset',
          label: 'Ports', // OBJECT_VIDEO_ENGINE_NAME +
          className: styles.portsFieldset,
          fields: [
            _createInputNumberRangeFields('srtInputPortRanges', 'SRT In', videoEngine?.srtInputPortRanges ?? undefined, false, styles.portField),
            _createInputNumberRangeFields('srtOutputPortRanges', 'SRT Out', videoEngine?.srtOutputPortRanges ?? undefined, false, styles.portField)
            // _createInputTextField('sldpPort', 'SLDP Out', videoEngine?.sldpPort ?? undefined, false, styles.portField),
            // _createInputTextField('sldpPortWS', 'SLDP WS Out', videoEngine?.sldpPortWS ?? undefined, false, styles.portField),
            // _createInputTextField('sldpPortWSS', 'SLDP WSS Out', videoEngine?.sldpPortWSS ?? undefined, false, styles.portField),
            // _createInputTextField('rtmpPort', 'RTMP In/Out', videoEngine?.rtmpPort ?? undefined, false, styles.portField),
            // _createInputTextField('rtmpsPort', 'RTMPS In/Out', videoEngine?.rtmpsPort ?? undefined, false, styles.portField),
            // _createInputTextField('rtspPort', 'RTSP In/Out', videoEngine?.rtspPort ?? undefined, false, styles.portField),
            // _createInputTextField('icecastInputPort', 'Icecast In', videoEngine?.icecastInputPort ?? undefined, false, styles.portField),
            // _createInputTextField('icecastPlaybackPort', 'Icecast Out', videoEngine?.icecastPlaybackPort ?? undefined, false, styles.portField)
          ],
          fieldProps: { style: { flexGrow: 1, flexBasis: '50%', minWidth: '300px' } },
          collapsible: false,
          collapsed: false
        }
      ],
      fieldProps: { widths: 'equal', style: { justifyContent: 'space-between', gap: '10px' } }
    }
  )

  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      {
        type: ArkFormFieldType.CancelButton,
        key: 'cancel',
        label: 'CANCEL',
        fieldProps: { onClick: _onCancel, floated: 'left' }
      },
      {
        type: ArkFormFieldType.OKButton,
        key: 'submit',
        label: 'SAVE',
        fieldProps: { loading: isSubmitting, floated: 'right' },
        disabled: changes.length === 0,
        disabledTooltip: 'No Changes to Save'
      }
    ],
    fieldProps: { widths: 'equal' }
  })

  return (
    <>
      <ArkHeader as="h2" inverted>
        Edit {OBJECT_VIDEO_ENGINE_NAME} Ports
      </ArkHeader>

      {hasSaved && (<>
        <ArkMessage positive>
          <ArkMessage.Header>{OBJECT_VIDEO_ENGINE_NAME} Updated</ArkMessage.Header>
          <ArkMessage.Item>The {OBJECT_VIDEO_ENGINE_NAME} has been updated successfully</ArkMessage.Item>
        </ArkMessage>
        <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={_onClose} style={{ marginTop: 15 }}>
          OK
        </ArkButton>
      </>)}

      {!hasSaved && (
        <ArkForm
          className={styles.videoEngineForm}
          formKey="videoEngine"
          inverted
          formError={error}
          formFields={formFields}
          formSchema={formSchema}
          onFormSubmit={onFormSubmit}
          onValueChanged={onValueChanged}
          showLabels={true}
          insideModal={insideModal}
        />)}
    </>
  )
}

export default CompanyVideoEngineForm
