/**
 * ChannelLayoutPopup
 */

import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button } from 'semantic-ui-react'

import ArkButton from '../../../core/components/ArkButton'
import ArkCheckbox from '../../../core/components/ArkCheckbox'
import ArkIconButton from '../../../core/components/ArkIconButton'
import ArkPopup from '../../../core/components/ArkPopup'
import ArkSpacer from '../../../core/components/ArkSpacer'
import {
  ChannelGridCoords,
  ChannelGridCoordsLayout,
  ChannelGridCoordsLayoutMap,
  ChannelGridLayout,
  ChannelLayout
} from '../../../core/types/channel'
import { createMatrix, getCoords } from '../../../core/utilities/channel'
import { useViewer } from '../../providers/ViewerProvider'

import styles from './ChannelLayoutPopup.module.css'

/**
 * ToolbarBottomBarIcon
 */

const ToolbarBottomBarIcon = () => (
  <div className={styles.toolbarGridIcon}>
    <div className={styles.toolbarGridIconGrid}>
      <div className={styles.toolbarBottomBarIconGridItemLarge} />
      <div className={styles.toolbarBottomBarIconGridItemSmall} />
      <div className={styles.toolbarBottomBarIconGridItemSmall} />
      <div className={styles.toolbarBottomBarIconGridItemSmall} />
    </div>
  </div>
)

/**
 * ToolbarGridIcon
 */

 interface ToolbarIconProps {
  activeMatrix: boolean[][]
}

const ToolbarGridIcon = (props: ToolbarIconProps) => {
  const { activeMatrix } = props

  const gridItems = _.chain(createMatrix())
    .map((row, y) =>
      _.map(row, (_col, x) => {
        const active = activeMatrix[y][x]
        return (
          <div
            className={`${styles.toolbarGridIconGridItem} ${active ? styles.toolbarGridIconGridItemActive : ''}`}
            key={`${x}-${y}`}
          />
        )
      })
    )
    .flatten()
    .value()

  return (
    <div className={styles.toolbarGridIcon}>
      <div className={styles.toolbarGridIconGrid}>
        {gridItems}
      </div>
    </div>
  )
}

/**
 * GridItem
 */

interface GridItemProps {
  active?: boolean
  auto: boolean
  hover?: boolean
  onClick: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
  text?: string
}

const GridItem = (props: GridItemProps) => {
  const { active, auto, hover, onClick, onMouseEnter, onMouseLeave, text } = props

  return (
    <div
      className={styles.gridItemContainer}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={`
        ${styles.gridItem}
        ${active ? styles.gridItemActive : ''}
        ${auto && active ? styles.gridItemAutoActive : ''}
        ${hover ? styles.gridItemHover : ''}`}
      >
        {text && <div className={styles.gridItemText}>{text}</div>}
      </div>
    </div>
  )
}

/**
 * ChannelLayoutPopup
 */

interface ChannelLayoutPopupProps {
  disabled: boolean
}

const ChannelLayoutPopup = (props: ChannelLayoutPopupProps) => {
  const { disabled } = props

  const viewer = useViewer()

  const [activeMatrix, setActiveMatrix] = useState(createMatrix())
  const [hoverMatrix, setHoverMatrix] = useState(createMatrix())
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const gridLayout: ChannelGridLayout = viewer.getChannelGridLayout()

  useEffect(() => {
    if (gridLayout === ChannelGridLayout.GridAuto) {
      setActiveMatrix(createMatrix(viewer.autoGridCoords))
    } else {
      const coords = _.find(ChannelGridCoordsLayoutMap, { layout: gridLayout })?.coords
      setActiveMatrix(createMatrix(coords))
    }
  }, [gridLayout, viewer.autoGridCoords])

  const onGridClick = () => {
    // console.log('ChannelLayoutPopup - onGridClick')
    viewer.setChannelLayout(ChannelLayout.Grid)
  }

  const onAutoGridClick = () => {
    // console.log('ChannelLayoutPopup - onAutoGridClick')
    viewer.setChannelLayout(ChannelLayout.Grid)
    if (gridLayout === ChannelGridLayout.GridAuto) {
      const layout: ChannelGridCoordsLayout | undefined = _.find(
        ChannelGridCoordsLayoutMap,
        { coords: viewer.autoGridCoords }
      )
      if (layout) viewer.setChannelGridLayout(layout.layout)
    } else {
      viewer.setChannelGridLayout(ChannelGridLayout.GridAuto)
    }
  }

  const onBottomBarClick = () => {
    // console.log('ChannelLayoutPopup - onBottomBarClick')
    viewer.setChannelLayout(ChannelLayout.BottomBar)
  }

  const onGridItemClick = (coords: ChannelGridCoords) => {
    // console.log('ChannelLayoutPopup - onGridItemClick - coords:', coords)
    viewer.setChannelLayout(ChannelLayout.Grid)
    const layout: ChannelGridCoordsLayout | undefined = _.find(ChannelGridCoordsLayoutMap, { coords })
    if (layout !== undefined) viewer.setChannelGridLayout(layout.layout)
  }

  const onGridItemMouseEnter = (grid: ChannelGridCoords) => {
    // console.log('ChannelLayoutPopup - onGridItemMouseEnter - coords:', coords)
    setHoverMatrix(createMatrix(grid))
  }

  const onGridItemMouseLeave = () => {
    // console.log('ChannelLayoutPopup - onGridItemMouseLeave:')
    setHoverMatrix(createMatrix())
  }

  const gridItems = _.chain(createMatrix())
    .map((row, y2) =>
      _.map(row, (_col, x2) => {
        const x = x2 + 1
        const y = y2 + 1
        return (
          <GridItem
            active={activeMatrix[y2][x2]}
            auto={gridLayout === ChannelGridLayout.GridAuto}
            hover={hoverMatrix[y2][x2]}
            key={`${y}-${x}`}
            onClick={() => onGridItemClick({ x, y })}
            onMouseEnter={() => onGridItemMouseEnter({ x, y })}
            onMouseLeave={() => onGridItemMouseLeave()}
            // text={`${x} x ${y}`} // DEBUG
          />
        )
      })
    )
    .flatten()
    .value()

  const channelLayout: ChannelLayout = viewer.getChannelLayout()

  const getGridLabel = () => {
    const hoverCoords = getCoords(hoverMatrix)
    if (hoverCoords) return `${hoverCoords.x} x ${hoverCoords.y}`
    if (channelLayout === ChannelLayout.BottomBar) return 'SWITCHER'
    if (gridLayout === ChannelGridLayout.GridAuto) return `AUTO ${viewer.autoGridCoords.x} x ${viewer.autoGridCoords.y}`
    const activeCoords = getCoords(activeMatrix)
    if (activeCoords) return `${activeCoords.x} x ${activeCoords.y}`
  }

  const trigger = (
    <ArkIconButton
      data-test-id="ark-channel-layout-btn" // e2e testing identifier
      disabled={disabled}
      open={isOpen}
    >
      {channelLayout === ChannelLayout.BottomBar
        ? (
          <ToolbarBottomBarIcon />
        )
        : (
          <ToolbarGridIcon activeMatrix={activeMatrix} />
        )}
    </ArkIconButton>
  )

  const isGrid: boolean = channelLayout === ChannelLayout.Grid

  return (
    <ArkPopup
      className={styles.popup}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      title='LAYOUT'
      trigger={trigger}
    >
      <Button.Group className={styles.buttons}>
        <ArkButton className={styles.button}
          active={!isGrid}
          color={!isGrid ? 'blue' : undefined}
          onClick={onBottomBarClick}
          data-test-id="ark-channel-layout-bottom-bar-btn" // e2e testing identifier
        >
          SWITCHER
        </ArkButton>
        <ArkButton className={styles.button}
          active={isGrid}
          color={isGrid ? 'blue' : undefined}
          onClick={onGridClick}
          data-test-id="ark-channel-layout-grid-btn" // e2e testing identifier
        >
          GRID
        </ArkButton>
      </Button.Group>
      {isGrid && (
        <>
          <ArkSpacer size={20} />
          <ArkCheckbox
            checked={gridLayout === ChannelGridLayout.GridAuto}
            label='AUTO GRID'
            onClick={onAutoGridClick}
            toggle
          />
          <ArkSpacer size={20} />
          <div className={styles.gridContainer}>
            <div className={styles.grid}>{gridItems}</div>
          </div>
          <ArkSpacer large />
          <div className={styles.gridLabel}>{getGridLabel()}</div>
        </>
      )}
    </ArkPopup>
  )
}

export default ChannelLayoutPopup
