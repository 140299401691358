import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import _ from 'lodash'

import { Channel, Project, UserCompany } from '../models'
import { IProjectAdminContext, useProjectAdmin } from './ProjectAdminProvider'
import { IUserContext, useUser } from './UserProvider'

export type ProjectChannelContextValue = {
  clearChannels: () => void,
  fetchChannels: () => Promise<Channel[] | undefined>
  fetchChannelsInteractive: () => Promise<Channel[] | undefined>
  getChannels: () => Channel[]
  getFetching: () => boolean
}

const ProjectChannelContext = createContext<ProjectChannelContextValue>({} as ProjectChannelContextValue)

export const useProjectChannel = () => useContext(ProjectChannelContext)

type ProjectChannelProviderProps = {
  children: ReactNode
}

const ProjectChannelProvider = (props: ProjectChannelProviderProps) => {
  const projectAdmin: IProjectAdminContext = useProjectAdmin()
  const user: IUserContext = useUser()

  const company: UserCompany = user.store.selectedCompany!
  const project: Project = user.store.selectedProject!

  const [channels, setChannels] = useState<Channel[]>([])
  const [fetching, setFetching] = useState<boolean>(true)

  useEffect(() => {
    // console.log('ProjectChannelProvider - load')
    return () => {
      // console.log('ProjectChannelProvider - unload')
    }
  }, [])

  const clearChannels = (): void => {
    // console.log('ProjectChannelProvider - clearCards')
    setChannels([])
  }

  const fetchChannels = async (): Promise<Channel[] | undefined> => {
    try {
      // console.log('ProjectChannelProvider - fetchChannels')
      setChannels([])
      setFetching(true)
      const newChannels: Channel[] | null =
        await projectAdmin.actions.getAllCompanyProjectChannels(company.id, project.id)
      if (newChannels === null) throw Error('fetch channels failed')
      setChannels(newChannels)
      setFetching(false)
      return newChannels
    } catch (error) {
      console.error('ProjectChannelProvider - fetchChannels - error:', error.message)
    }
  }

  const fetchChannelsInteractive = async (): Promise<Channel[] | undefined> => {
    try {
      // console.log('ProjectChannelProvider - fetchChannelsInteractive')
      const newChannels: Channel[] | undefined = await fetchChannels()
      if (!newChannels) throw Error('fetch channels failed')
      return newChannels
    } catch (error) {
      console.error('ProjectChannelProvider - fetchChannelsInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  const getChannels = (): Channel[] => {
    // console.log('ProjectChannelProvider - getChannels')
    return _.orderBy(channels, 'name')
  }

  const getFetching = (): boolean => {
    // console.log('ProjectChannelProvider - getFetching')
    return fetching
  }

  return (
    <ProjectChannelContext.Provider value={{
      clearChannels,
      fetchChannels,
      fetchChannelsInteractive,
      getChannels,
      getFetching
    }}>
      {props.children}
    </ProjectChannelContext.Provider>
  )
}

export default ProjectChannelProvider
