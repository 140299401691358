/**
 * AdminCompanyListItem
 */

import React from 'react'

import ArkIcon from 'src/core/components/ArkIcon'
import ArkListItem from 'src/core/components/ArkListItem'
import { Company } from 'src/core/models'

interface AdminCompanyListItemProps {
  active: boolean
  company: Company
  filter?: string
  onClick: () => void
  onEditClick?: () => void
}

const AdminCompanyListItem = (props: AdminCompanyListItemProps) => {
  const {
    active,
    company,
    filter,
    onClick,
    onEditClick
  } = props

  return (
    <ArkListItem
      active={active}
      filter={filter}
      leftComponent={<ArkIcon name='company' size={28} />}
      onClick={onClick}
      onEditClick={onEditClick}
      title={company.name}
    />
  )
}

export default AdminCompanyListItem
