import { BaseModel } from './base_model'
import { Channel, ProjectGroup, Program, Project, ProjectUser } from '.'

export type ProjectSummaryEntityLookup = Map<number, Array<number>>

export class ProjectSummary extends BaseModel {
  project: Project
  // entities
  users?: Array<ProjectUser>
  groups?: Array<ProjectGroup> // TODO: what about OrgGroups & potentially other variations? <<<<
  channels?: Array<Channel>
  programs?: Array<Program>
  // TODO: tags?
  // entity mappings
  groupUsersLookup?: ProjectSummaryEntityLookup
  groupChannelsLookup?: ProjectSummaryEntityLookup
  groupProgramsLookup?: ProjectSummaryEntityLookup
  channelProgramsLookup?: ProjectSummaryEntityLookup

  constructor (
    project: Project,
    users?: Array<ProjectUser>,
    groups?: Array<ProjectGroup>,
    channels?: Array<Channel>,
    programs?: Array<Program>,
    groupUsersLookup?: ProjectSummaryEntityLookup,
    groupChannelsLookup?: ProjectSummaryEntityLookup,
    groupProgramsLookup?: ProjectSummaryEntityLookup,
    channelProgramsLookup?: ProjectSummaryEntityLookup
  ) {
    super()
    this.project = project
    this.users = users
    this.groups = groups
    this.channels = channels
    this.programs = programs
    this.groupUsersLookup = groupUsersLookup
    this.groupChannelsLookup = groupChannelsLookup
    this.groupProgramsLookup = groupProgramsLookup
    this.channelProgramsLookup = channelProgramsLookup
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : ProjectSummary | null {
    if (!json || !json.project || !json.entities) return null
    const project = Project.fromJSON(json.project.id, json.project)
    if (!project) return null
    const users: Array<ProjectUser> | undefined = json.entities.users && json.entities.users.map((projectUserData: any) => {
      // NB: merging in the additional company_role, company_status, & project_role fields into the main user json so its all parses in one
      return ProjectUser.fromJSON(projectUserData.user.id ?? 0, {
        ...projectUserData.user,
        ...{ company_role: projectUserData.company_role },
        ...{ company_status: projectUserData.company_status },
        ...{ project_role: projectUserData.project_role, project_access_enabled: projectUserData.enabled ?? false },
        ...{ flag_direct_user: projectUserData.flag_direct_user }
      })
    })
    const groups: Array<ProjectGroup> | undefined = json.entities.groups && json.entities.groups.map((groupData: any) => {
      return ProjectGroup.fromJSON(groupData.id, groupData)
    })
    const channels: Array<Channel> | undefined = json.entities.channels && json.entities.channels.map((channelData: any) => {
      return Channel.fromJSON(channelData.id, channelData, false)
    })
    const programs: Array<Program> | undefined = json.entities.programs && json.entities.programs.map((programData: any) => {
      return Program.fromJSON(programData.id, programData)
    })

    // parse the lookups/mappings - schema: { <id> : [<child_id_1>, <child_id_2>, ...], ... }
    // NB: due to json limitations, the parent/root id keys comes in as a string, so we convert it to a number as we parse them
    const groupUsersLookup: ProjectSummaryEntityLookup | undefined = ProjectSummary.parseLookupMappings(json.mappings?.groups_users)
    const groupChannelsLookup: ProjectSummaryEntityLookup | undefined = ProjectSummary.parseLookupMappings(json.mappings?.groups_channels)
    const groupProgramsLookup: ProjectSummaryEntityLookup | undefined = ProjectSummary.parseLookupMappings(json.mappings?.groups_programs)
    const channelProgramsLookup: ProjectSummaryEntityLookup | undefined = ProjectSummary.parseLookupMappings(json.mappings?.channels_programs)
    return new ProjectSummary(
      project,
      users,
      groups,
      channels,
      programs,
      groupUsersLookup,
      groupChannelsLookup,
      groupProgramsLookup,
      channelProgramsLookup
    )
  }

  static parseLookupMappings = (mappingsData: any) => {
    let lookup: ProjectSummaryEntityLookup | undefined
    if (mappingsData) {
      const lookupDataOrig = mappingsData
      lookup = new Map<number, Array<number>>()
      for (const parentIdStr of Object.keys(lookupDataOrig)) {
        lookup.set(parseInt(parentIdStr), lookupDataOrig[parentIdStr])
      }
    }
    return lookup
  }
}
