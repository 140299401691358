import React, { useContext, useEffect, useRef, useState } from 'react'

import { SiteAdminContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import { Company, VideoEngine } from 'src/core/models'

import ArkAdminPage from 'src/admin/components/ArkAdminPage/ArkAdminPage'
import AdminVideoEngineSidebar from './AdminVideoEngineSidebar'
import AdminVideoEngineListItem from './AdminVideoEngineListItem'
import VideoEngineForm, { VideoEngineFormMode } from './VideoEngineForm'

import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkManagerListView, { ArkManagerFilteredItem } from 'src/core/components/ArkManagerListView/ArkManagerListView'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkModal from 'src/core/components/ArkModal'

import { OBJECT_VIDEO_ENGINE_NAME, OBJECT_VIDEO_ENGINE_NAME_PLURAL, SECTION_ADMIN_NAME } from 'src/constants/strings'

interface IProps {
}

const AdminVideoEnginesPage = (_props: IProps) => {
  const mounted = useRef(false)

  const siteAdminContext = useContext(SiteAdminContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<Error | undefined>(undefined)
  const [videoEngines, setVideoEngines] = useState<Array<VideoEngine>>([])
  const [companies, setCompanies] = useState<Array<Company>>([]) // for video engine editing - assigning to a company & displaying company/org assignment
  const [filteredVideoEngines, setFilteredVideoEngines] = useState<Array<ArkManagerFilteredItem<VideoEngine>> | undefined>(undefined)
  const [filter, setFilter] = useState<string | undefined>(undefined)
  const [selectedVideoEngine, setSelectedVideoEngine] = useState<VideoEngine | undefined>(undefined)
  const [editVideoEngine, setEditVideoEngine] = useState<VideoEngine | undefined>(undefined)
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const _loadVideoEngines = async () => {
    try {
      const _videoEngines = await siteAdminContext.actions.getAllVideoEngines()
      return _videoEngines
    } catch (error) {
      console.error('AdminVideoEnginesPage - loadVideoEngines - error: ', error)
      throw error
    }
  }

  const _loadCompanies = async () => {
    try {
      const _companies = await siteAdminContext.actions.getAllCompanies()
      return _companies
    } catch (error) {
      console.error('AdminVideoEnginesPage - loadCompanies - error: ', error)
      throw error
    }
  }

  const loadData = async () => {
    if (loading === true || loadingError !== undefined) return false
    if (!mounted.current) return false
    try {
      setLoading(true)
      const _videoEngines = await _loadVideoEngines()
      const _companies = await _loadCompanies()
      console.log('AdminVideoEnginesPage - loadData - _videoEngines:', _videoEngines)
      console.log('AdminVideoEnginesPage - loadData - _companies:', _companies)
      if (mounted.current) {
        setVideoEngines(_videoEngines || [])
        setCompanies(_companies || [])
        // update the selectedVideoEngine whenever the video engine is reloaded (incase its been edited)
        if (selectedVideoEngine) {
          const _videoEngine = _videoEngines?.find(c => c.id === selectedVideoEngine?.id)
          setSelectedVideoEngine(_videoEngine)
        }
        setLoading(false)
      }
    } catch (error) {
      console.error('AdminVideoEnginesPage - loadData - error:', error)
      if (mounted.current) {
        setLoading(false)
        setVideoEngines([])
        setCompanies([])
        setLoadingError(error)
      }
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  // -------

  const selectVideoEngine = (_videoEngine?: VideoEngine) => {
    setSelectedVideoEngine(_videoEngine)
  }

  // -------

  const filterVideoEngines = (_filter: string) => {
    if (loading) return
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredVideoEngines = filter
      ? videoEngines.reduce<Array<ArkManagerFilteredItem<VideoEngine>>>((r, videoEngine) => {
        let nameMatch = false
        if (videoEngine.name.toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        if (nameMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          const filteredUser: ArkManagerFilteredItem<VideoEngine> = {
            item: videoEngine,
            matchingFields
          }
          r.push(filteredUser)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<VideoEngine>>)
      : undefined
    if (selectedVideoEngine && (!(filteredVideoEngines?.find((filteredVideoEngine) => filteredVideoEngine.item.id === selectedVideoEngine.id)))) {
      selectVideoEngine(undefined) // if an item was selected but isn't in the filtered list deselect them
    }
    setFilter(filter)
    setFilteredVideoEngines(filteredVideoEngines)
  }

  // const clearFilteredGroups = () => {
  //   setFilter(undefined)
  //   setFilteredVideoEngines(undefined)
  // }

  // -------

  const showAddModal = () => {
    setShowAddEditModal(true)
  }

  const showEditModal = (videoEngine: VideoEngine) => {
    setShowAddEditModal(true)
    setEditVideoEngine(videoEngine)
  }

  // call directly to initiate a modal callback (used when the user clicks custom buttons to close the modal instead of the modals own 'x' button etc.)
  const hideAddEditModal = () => {
    // NB: DON'T clear/reset `editVideoEngine` here, see `didHideVideoEngineModal` which is called once the modal has actually closed & we can safely reset it then
    // NB: this stops a saved edit form flipping the 'updated' success text to 'created' briefly while the modal closes if we reset it straight away here
    // NB: & also stops the form title flipping from edit to add as it closes via the cancel button
    setShowAddEditModal(false)
  }

  // triggered via modal callbacks once the modal has already closed
  const didHideAddEditModal = () => {
    setShowAddEditModal(false)
    setEditVideoEngine(undefined)
  }

  // -------

  const onEdit = (_videoEngine: VideoEngine) => {
    showEditModal(_videoEngine)
  }

  const onDidDelete = (_videoEngine: VideoEngine) => {
    // trigger a data re-load so the deleted video engine no longer shows
    selectVideoEngine(undefined)
    loadData()
  }

  // -------

  const renderVideoEngineForm = (videoEngine?: VideoEngine) => {
    return (
      <VideoEngineForm
        mode={videoEngine ? VideoEngineFormMode.Edit : VideoEngineFormMode.Add}
        videoEngine={videoEngine}
        companies={companies}
        onCancel={() => { hideAddEditModal() }}
        onSave={() => {
          // trigger a video engines data re-load to show the newly added one
          loadData()
        }}
        // onDelete={() => {
        //   // trigger a data re-load so the deleted video engine no longer shows
        //   this.loadVideoEngines()
        // }}
        onClose={() => { hideAddEditModal() }}
        insideModal={true}
      />
    )
  }

  const renderVideoEngineFormModal = () => {
    return (
      <ArkModal open={showAddEditModal} onClose={() => didHideAddEditModal()}>
        {renderVideoEngineForm(editVideoEngine)}
      </ArkModal>
    )
  }

  // -------

  const renderVideoEngineTableRowContent = (videoEngine: VideoEngine, isSelected: boolean, filter?: string) => {
    const company = videoEngine?.companyId ? companies.find(c => c.id === videoEngine.companyId) : undefined
    return (
      <AdminVideoEngineListItem
        active={isSelected}
        videoEngine={videoEngine}
        videoEngineCompany={company}
        filter={filter}
        key={videoEngine.id}
        onClick={() => selectVideoEngine(videoEngine)}
        // onEditClick={() => this.showEditVideoEngineModal(videoEngine)}
      />
    )
  }

  // -------

  const renderVideoEngineFilterForm = () => {
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        filterTitle='Filter by name'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          filterVideoEngines(filter)
        }}
      />
    )
  }

  // -------

  const rightSidebarComponent = selectedVideoEngine && (
    <AdminVideoEngineSidebar
      videoEngine={selectedVideoEngine}
      companies={companies}
      onEdit={onEdit}
      onDidDelete={onDidDelete}
    />
  )

  return (
    <ArkAdminPage
      onRightSidebarClose={() => setSelectedVideoEngine(undefined)}
      rightSidebar={rightSidebarComponent}
    >
      <ArkManagerContentView
        title={OBJECT_VIDEO_ENGINE_NAME_PLURAL}
        breadcrumb={[{
          path: ROUTES.ADMIN,
          title: SECTION_ADMIN_NAME
        }]}
      >
        <ArkManagerListView
          loading={loading}
          items={videoEngines}
          selectedItem={selectedVideoEngine}
          itemRow={(videoEngine: VideoEngine, isSelected: boolean) => {
            return renderVideoEngineTableRowContent(videoEngine, isSelected, filter)
          }}
          topbarAddItemTitle={'CREATE ' + OBJECT_VIDEO_ENGINE_NAME}
          onAdd={() => showAddModal()}
          errors={loadingError ? [loadingError] : undefined}
          // FILTERING:
          filter={filter}
          filteredItems={filteredVideoEngines}
          filterForm={renderVideoEngineFilterForm()}
          onClearFilter={() => filterVideoEngines('') }
        />
        {renderVideoEngineFormModal()}
      </ArkManagerContentView>
    </ArkAdminPage>
  )
}
export default AdminVideoEnginesPage
