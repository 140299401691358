import React, { useContext } from 'react'

import ArkCompanyManagerPage from 'src/manager/company/components/ArkCompanyManagerPage/ArkCompanyManagerPage'
import ArkAccessDeniedView from 'src/core/components/ArkAccessDeniedView/ArkAccessDeniedView'
import { UserContext } from 'src/core/providers'

const CompanyAccessDeniedPage = () => {
  // NB: if the user has company admin (or higher) access to any company (but maybe not the selected one) we show the sidebar menu but it'll be disabled
  //     otherwise we hide both sidebars & so the sidebar menu completely
  const { actions: userActions } = useContext(UserContext)
  const isCompanyAdminOrHigherInAnyCompany = userActions.isCompanyAdminOrHigherInAnyCompany()
  return (
    <ArkCompanyManagerPage disableSectionMainMenu={isCompanyAdminOrHigherInAnyCompany} hideSidebars={!isCompanyAdminOrHigherInAnyCompany}>
      <ArkAccessDeniedView />
    </ArkCompanyManagerPage>
  )
}
export default CompanyAccessDeniedPage
