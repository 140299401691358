import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import _ from 'lodash'

import { Project, UserCompany } from '../models'
import { ICompanyAdminContext, useCompanyAdmin } from './CompanyAdminProvider'
import { IUserContext, useUser } from './UserProvider'

export type CompanyProjectContextValue = {
  clearProjects: () => void,
  fetchProjects: () => Promise<Project[] | undefined>
  fetchProjectsInteractive: () => Promise<Project[] | undefined>
  getProjects: () => Project[]
  getFetching: () => boolean
}

const CompanyProjectContext = createContext<CompanyProjectContextValue>({} as CompanyProjectContextValue)

export const useCompanyProject = () => useContext(CompanyProjectContext)

type CompanyProjectProviderProps = {
  children: ReactNode
}

const CompanyProjectProvider = (props: CompanyProjectProviderProps) => {
  const companyAdmin: ICompanyAdminContext = useCompanyAdmin()
  const user: IUserContext = useUser()

  const company: UserCompany = user.store.selectedCompany!

  const [fetching, setFetching] = useState<boolean>(true)
  const [projects, setProjects] = useState<Project[]>([])

  useEffect(() => {
    // console.log('CompanyProjectProvider - load')
    return () => {
      // console.log('CompanyProjectProvider - unload')
    }
  }, [])

  const clearProjects = (): void => {
    // console.log('CompanyProjectProvider - clearCards')
    setProjects([])
  }

  const fetchProjects = async (): Promise<Project[] | undefined> => {
    try {
      // console.log('CompanyProjectProvider - fetchProjects')
      setProjects([])
      setFetching(true)
      const newProjects: Project[] | null = await companyAdmin.actions.getAllCompanyProjects(company.id)
      if (newProjects === null) throw Error('fetch projects failed')
      setProjects(newProjects)
      setFetching(false)
      return newProjects
    } catch (error) {
      console.error('CompanyProjectProvider - fetchProjects - error:', error.message)
    }
  }

  const fetchProjectsInteractive = async (): Promise<Project[] | undefined> => {
    try {
      // console.log('CompanyProjectProvider - fetchProjectsInteractive')
      const newProjects: Project[] | undefined = await fetchProjects()
      if (!newProjects) throw Error('fetch projects failed')
      return newProjects
    } catch (error) {
      console.error('CompanyProjectProvider - fetchProjectsInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  const getProjects = (): Project[] => {
    // console.log('CompanyProjectProvider - getProjects')
    return _.orderBy(projects, 'name')
  }

  const getFetching = (): boolean => {
    // console.log('CompanyProjectProvider - getFetching')
    return fetching
  }

  return (
    <CompanyProjectContext.Provider value={{
      clearProjects,
      fetchProjects,
      fetchProjectsInteractive,
      getProjects,
      getFetching
    }}>
      {props.children}
    </CompanyProjectContext.Provider>
  )
}

export default CompanyProjectProvider
