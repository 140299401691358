import React, { createContext, useState } from 'react'

import { UserAccountSection, UserAccountSectionPage } from './UserAccountData'

interface IUserAccountActions {
  setSection: (section?: UserAccountSection) => void
  setSectionPage: (sectionPage?: UserAccountSectionPage) => void
  contentChanged: () => void // call this to trigger a modal resize/update
}

interface IUserAccountStore {
  section?: UserAccountSection
  sectionPage?: UserAccountSectionPage
  contentUpdateDate?: Date
}

interface IUserAccountContext {
  actions: IUserAccountActions
  store: IUserAccountStore
}

interface UserAccountProviderProps {
  children: React.ReactNode
}

export const UserAccountContext = createContext<IUserAccountContext>({} as IUserAccountContext)

const UserAccountProvider = (props: UserAccountProviderProps) => {
  const { children } = props

  const [section, setSection] = useState<UserAccountSection | undefined>()
  const [sectionPage, setSectionPage] = useState<UserAccountSectionPage | undefined>()
  const [contentUpdateDate, setContentUpdateDate] = useState<Date | undefined>()

  const contentChanged = () => {
    // TESTING: but update a state var we can trigger the child/listener components to update
    // NB: doesn't seem like the child needs to do anything specific, just having this update seems to be enough to trigger a modal resize
    setContentUpdateDate(new Date())
  }

  const actions: IUserAccountActions = {
    setSection,
    setSectionPage,
    contentChanged: contentChanged
  }
  const store: IUserAccountStore = {
    section,
    sectionPage,
    contentUpdateDate
  }
  return (
    <UserAccountContext.Provider value={{ actions, store }}>
      {children}
    </UserAccountContext.Provider>
  )
}

export default UserAccountProvider
