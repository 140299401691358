/**
 * LayoutProvider
 */

import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { OnboardingContext } from './OnboardingProvider'

type LeftSidebarState = 'full' | 'compact' | 'hidden'

export type Section = 'admin' | 'company' | 'project' | 'viewer'

interface ILayoutActions {
  setCurrentSection: (section?: Section) => void
  setIsLeftSidebarOpen: (show: boolean) => void
  setLeftSidebarState: (state: LeftSidebarState) => void
}

interface ILayoutStore {
  currentSection?: Section
  isLeftSidebarOpen: boolean
  leftSidebarState: LeftSidebarState
}

interface ILayoutContext {
  actions: ILayoutActions;
  store: ILayoutStore;
}

interface LayoutProviderProps {
  children: ReactNode
}

export const LayoutContext = createContext<ILayoutContext>({} as ILayoutContext)

const LayoutProvider = (props: LayoutProviderProps) => {
  const { children } = props

  const history = useHistory()

  const { actions: onboardingActions } = useContext(OnboardingContext)
  const { showPopup } = onboardingActions

  const currentSection = useRef<Section>()
  const siteLeftSidebarState = useRef<LeftSidebarState>('full')
  const viewerLeftSidebarState = useRef<LeftSidebarState>('compact')

  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(false) // mobile-only
  const [leftSidebarState, setLeftSidebarState] = useState<LeftSidebarState>('full') // desktop-only

  useEffect(() => {
    // mobile-only - auto-close on navigate
    return history.listen(() => {
      setTimeout(() => setIsLeftSidebarOpen(false), 300)
    })
  }, [history])

  useEffect(() => {
    if (currentSection.current === 'viewer') {
      viewerLeftSidebarState.current = leftSidebarState

      // if the viewer sidebar is larger than the site one, expand the site one
      if (leftSidebarState === 'full') siteLeftSidebarState.current = 'full'

      // onboarding popup trigger
      if (leftSidebarState === 'compact') setTimeout(() => showPopup('viewerLeftSidebarExpandCollapse'), 500)
    } else {
      siteLeftSidebarState.current = leftSidebarState

      // if the site sidebar is smaller than the viewer one, collapse the viewer one
      if (leftSidebarState === 'compact' && viewerLeftSidebarState.current === 'full') {
        viewerLeftSidebarState.current = 'compact'
      }
      if (
        leftSidebarState === 'hidden' &&
        (viewerLeftSidebarState.current === 'full' || viewerLeftSidebarState.current === 'compact')
      ) {
        viewerLeftSidebarState.current = 'hidden'
      }
    }
  }, [leftSidebarState])

  const setCurrentSection = (section?: Section) => {
    if (section === currentSection.current) return
    if (currentSection.current) {
      // with expand/collapse animation
      setTimeout(() => {
        setLeftSidebarState(section === 'viewer' ? viewerLeftSidebarState.current : siteLeftSidebarState.current)
      })
    } else {
      // without expand/collapse animation
      setLeftSidebarState(section === 'viewer' ? viewerLeftSidebarState.current : siteLeftSidebarState.current)
    }
    currentSection.current = section
  }

  const actions: ILayoutActions = {
    setCurrentSection,
    setIsLeftSidebarOpen,
    setLeftSidebarState
  }

  const store: ILayoutStore = {
    currentSection: currentSection.current,
    isLeftSidebarOpen,
    leftSidebarState
  }

  return (
    <LayoutContext.Provider value={{ actions, store }}>
      {children}
    </LayoutContext.Provider>
  )
}

export default LayoutProvider
