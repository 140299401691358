import React from 'react'

import ArkCenterLayout from '../../../components/ArkCenterLayout'
import ArkPage from '../../../components/ArkPage/ArkPage'

import VerifyEmailForm from './VerifyEmailForm'

interface IProps {}
interface IState {}
class VerifyEmailWarningPage extends React.Component<IProps, IState> {
  render () {
    return (
      <ArkPage>
        <ArkCenterLayout>
          <VerifyEmailForm showVerifyRequiredMsg={true} />
        </ArkCenterLayout>
      </ArkPage>
    )
  }
}

export default VerifyEmailWarningPage
