/**
 * ArkOnboardingPopup
 */

import React, { useContext, useEffect } from 'react'
import { PopupProps } from 'semantic-ui-react'

import { OnboardingContext, OnboardingPopupId } from '../../providers/OnboardingProvider'

export interface ArkOnboardingPopupProps extends PopupProps {
  content: string
  id: OnboardingPopupId
  priority?: number
  show?: boolean // automatically show popup on render
}

const ArkOnboardingPopup = (props: ArkOnboardingPopupProps) => {
  const { children, id, show } = props

  const { actions } = useContext(OnboardingContext)
  const { setPopupPropsForId, showPopup } = actions

  useEffect(() => {
    setPopupPropsForId(id, props)
    if (show) showPopup(id)
    return () => setPopupPropsForId(id, undefined)
  }, [])

  return <div id={id}>{children}</div>
}

export default ArkOnboardingPopup
