import React, { useContext, useEffect, useRef, useState } from 'react'

import { Project, UserProject } from 'src/core/models'
import { ProjectAdminContext, UserContext } from 'src/core/providers'

import ArkConfirmModal from 'src/core/components/ArkConfirmModal'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkRadio, { ArkRadioProps } from 'src/core/components/ArkRadio'

import { OBJECT_PROJECT_NAME, PAGE_SETTINGS_NAME } from 'src/constants/strings'

import styles from './ProjectSettingsView.module.css'

// TODO: access checks currently only work for the currently selected company & project, so remove the props & grab them from the provider instead?
// TODO: (as it can't be used any other way unless access checks are expanded to work outside of current user selections)
// NB: same company/project checks (& limitations) as ProjectTranscoderSettingsView
export interface ProjectAccessSettingsViewProps {
  companyId: number
  project: UserProject | Project
}

const ProjectAccessSettingsView = (props: ProjectAccessSettingsViewProps) => {
  const { companyId, project } = props

  const mounted = useRef(false)

  const userContext = useContext(UserContext)
  const projectAdminContext = useContext(ProjectAdminContext)

  // const isSiteAdmin = userContext.actions.isSiteAdmin()
  // const isCompanyAdmin = userContext.store.selectedCompany?.id === companyId && userContext.actions.isCompanyAdmin()
  // const isProjectAdmin = userContext.store.selectedProject?.id === project.id && userContext.actions.isProjectAdminOrHigher()
  // NB: making sure the specified company & project are the selected ones before checking the access level (as access checks are currently limited to the selected company)
  const isProjectAdminOrHigher = userContext.store.selectedCompany?.id === companyId &&
    userContext.store.selectedProject?.id === project.id &&
    userContext.actions.isProjectAdminOrHigher()

  const [showConfirmAlert, setShowConfirmAlert] = useState<boolean>(false)

  const [saving, setSaving] = useState<boolean>(false)
  const [saveResult, setSaveResult] = useState<boolean>(false)
  const [saveError, setSaveError] = useState<Error | undefined>(undefined)

  // TODO: what about company level 2fa settings
  // TODO: if force2fa is enabled at the company level do we just show this as force enabled & don't allow edits?
  // TODO: or is this separate/additional to that?

  // individual program settings specific to this form
  const [projectValues, setProjectValues] = useState({
    force2fa: project.force2fa ?? false
  })
  const [projectValuesSaved, setProjectValuesSaved] = useState({
    force2fa: project.force2fa ?? false
  })
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])

  // -------

  const projectSettingsChanges = () => {
    const _changes: Array<string> = []
    if (projectValues) {
      for (const fieldName of Object.keys(projectValues)) {
        const oldValue = projectValuesSaved !== undefined ? (projectValuesSaved as any)[fieldName] : undefined
        const newValue = (projectValues as any)[fieldName]
        if (oldValue !== newValue) {
          _changes.push(fieldName)
        }
      }
    }
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  const resetSaveResults = () => {
    setSaveResult(false)
    setSaveError(undefined)
  }

  const toggleElement = (valueKey: string) => {
    // NB: prepping this to optionally support company level overrides for certain settings (like the project transcoder settings do)
    // NB: don't currently use/check the company level values yet, but we may do down the line...
    const companyValue = undefined // (companyTranscoderValues as any)[valueKey]
    const value = companyValue === false ? companyValue : (projectValues as any)[valueKey]
    if (!isProjectAdminOrHigher) return (value ? 'ENABLED' : <span className={styles.propertyValueOff}>DISABLED</span>) // just display the value if the user isn't a project admin or higher
    const disabled = companyValue === false
    return (
      <ArkRadio
        toggle
        checked={value}
        disabled={disabled}
        onChange={(event: React.FormEvent<HTMLInputElement>, data: ArkRadioProps) => {
          console.log('ProjectAccessSettingsView - toggleElement - onChange - valueKey: ', valueKey, ' data.checked: ', data.checked)
          setProjectValues({
            ...projectValues,
            [valueKey]: data.checked
          })
          resetSaveResults()
          // NB: see the useEffect that runs settingsChangeCount & updates setProgramChangeCount from it after the setValue state update completes
          // TESTING: auto prompt the user to confirm the change as soon as its made (no submit button)
          const savedValue = (projectValuesSaved as any)[valueKey]
          if (data.checked !== savedValue) {
            setShowConfirmAlert(true)
          }
        }}
      />
    )
  }

  // -------

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = projectSettingsChanges()
    setChanges(_changes)
  }, [projectValues])

  // -------

  // -------

  const onCancel = () => {
    console.log('ProjectAccessSettingsView - onCancel')
  }

  const onClose = () => {
    console.log('ProjectAccessSettingsView - onClose - saving: ', saving)
    setShowConfirmAlert(false)
    if (!saving) setProjectValues(projectValuesSaved) // reset the form back to the saved values
  }

  const onSubmit = async () => {
    console.log('ProjectAccessSettingsView - onSubmit')
    if (saving) return
    setSaving(true)
    resetSaveResults()

    // // DEBUG ONLY: mock submit...
    // const mockSuccess = async () => {
    //   await new Promise((resolve) => setTimeout(resolve, 2000)) // DBG ONLY
    //   const force2faNewVal = !projectValuesSaved.force2fa
    //   setProjectValuesSaved({ force2fa: force2faNewVal })
    //   setProjectValues({ force2fa: force2faNewVal })
    //   setSaveResult(true)
    // }
    // const mockError = async () => {
    //   await new Promise((resolve) => setTimeout(resolve, 2000)) // DBG ONLY
    //   setSaveError(new Error('DEBUG ERROR'))
    //   setSaveResult(false)
    //   setProjectValues(projectValuesSaved) // reset the form back to the saved values
    //   setChanges([])
    // }
    // // await mockSuccess()
    // await mockError()

    // TODO: PORT to be project specifc (not company)
    try {
      const reloadProjectData = true // trigger the user project data to be reloaded so it contains the new value
      const updateResult = await projectAdminContext.actions.updateProjectInfo(companyId, project.id, { force2fa: projectValues.force2fa }, reloadProjectData)
      console.log('ProjectAccessSettingsView - onSubmit - updateResult: ', updateResult)
      if (updateResult) {
        if (mounted.current) {
          const newProjectValuesSaved = { force2fa: updateResult.force2fa }
          setProjectValuesSaved(newProjectValuesSaved)
          setProjectValues(newProjectValuesSaved) // reset the form back to the saved values
          setChanges([])
          setSaveResult(true)
        }
      } else {
        throw new Error('Invalid response')
      }
    } catch (error) {
      if (mounted.current) {
        setSaveResult(false)
        setSaveError(error)
        setProjectValues(projectValuesSaved) // reset the form back to the saved values
        setChanges([])
      }
    }

    setSaving(false)
  }

  const renderConfirmModal = () => {
    const isEnabling2FA = projectValues.force2fa
    return (
      <ArkConfirmModal
        show={showConfirmAlert}
        title={<>Make {OBJECT_PROJECT_NAME} 2FA {isEnabling2FA ? 'Mandatory' : 'Optional'}?</>}
        message={<>
          <p>Are you sure you want to {isEnabling2FA ? 'enforce' : 'make'} {OBJECT_PROJECT_NAME} two factor authentication {!isEnabling2FA ? 'optional' : ''}?</p>
          {isEnabling2FA && (<p>All {OBJECT_PROJECT_NAME} users will be forced to enable &amp; use 2FA to login each time.</p>)}
          {!isEnabling2FA && (<p>All {OBJECT_PROJECT_NAME} users will no longer be forced to use 2FA to login.</p>)}
        </>}
        onCancel={onCancel}
        onConfirm={onSubmit}
        onClose={onClose}
      />
    )
  }

  // -------

  useEffect(() => {
    console.log('ProjectAccessSettingsView - MOUNTED')
    mounted.current = true
    return () => {
      mounted.current = false
      console.log('ProjectAccessSettingsView - UN-MOUNTED')
    }
  }, [])

  // -------

  const is2FAEnabled = projectValuesSaved.force2fa

  return (
    <div className={styles.accessSettings}>
      <div className={styles.content}>
        <ArkPanel bordered>
          <ArkPanel.Header title={OBJECT_PROJECT_NAME + ' ' + 'access' + ' ' + PAGE_SETTINGS_NAME} bottomBorder className={styles.header}></ArkPanel.Header>
          <ArkPanel.Properties titleWidth={220}>
            {(saveResult || saveError) && (
              <>
                {saveResult && (
                  <ArkPanel.PropertyRow>
                    <ArkPanel.PropertyRowMessage positive>
                      <ArkMessage.Header>{OBJECT_PROJECT_NAME} 2FA Now {is2FAEnabled ? 'Required' : 'Optional'} </ArkMessage.Header>
                      {is2FAEnabled && (<p>Two factor authentication is now required for all {OBJECT_PROJECT_NAME} users</p>)}
                      {!is2FAEnabled && (<p>Two factor authentication is no longer required for all {OBJECT_PROJECT_NAME} users</p>)}
                    </ArkPanel.PropertyRowMessage>
                  </ArkPanel.PropertyRow>
                )}
                {saveError && (
                  <ArkPanel.PropertyRow>
                    <ArkPanel.PropertyRowMessage negative>
                      <ArkMessage.Header>Error</ArkMessage.Header>
                      <p>{saveError.message}</p>
                    </ArkPanel.PropertyRowMessage>
                  </ArkPanel.PropertyRow>
                )}
              </>
            )}
            <ArkPanel.PropertyRow
              title='Require 2FA:'
              value={<>{toggleElement('force2fa')}</>}
              hint={<>{saving && (<ArkLoader small className={styles.loading} />)}</>} // NB: if adding an actual hint, only show it when not 'saving' so the loader shows in its place
              hasChanges={hasChanges('force2fa')}
              titleClassName={styles.propertyTitle}
              valueClassName={styles.propertyValue}
            />
          </ArkPanel.Properties>
        </ArkPanel>
        {renderConfirmModal()}
      </div>
    </div>
  )
}

export { ProjectAccessSettingsView }
