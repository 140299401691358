import React from 'react'
import { OBJECT_PROJECT_NAME } from 'src/constants/strings'

import ArkInfoView from 'src/core/components/ArkInfoView'
import ArkProjectManagerPage from 'src/manager/project/components/ArkProjectManagerPage/ArkProjectManagerPage'

const ProjectManagerAccessDeniedPage = () => (
  <ArkProjectManagerPage disableSectionMainMenu>
    <ArkInfoView
      iconName='denied'
      title={OBJECT_PROJECT_NAME + ' access denied'}
      message={<div>You don&apos;t currently have access to manage this {OBJECT_PROJECT_NAME}.</div>}
    />
  </ArkProjectManagerPage>
)

export default ProjectManagerAccessDeniedPage
