import { BaseModel } from './base_model'
import { ActionPermissions } from './'

export enum GroupType {
  project = 1, // NB: was `std` with a value of `0`
  orgNew = 2,
  mirrored = 3,
  parent = 4 // NB: was `org` (organisational) with a value of `1` (pre company/org group type being added), now named 'parent' instead
}

// NB: these args are named from the api & used with api calls, so keeping them in this format instead of camelCase (could otherwise map them if we want to keep camelCase in our code)
// eslint-disable-next-line camelcase
export type GroupChannelOperation = { operation: number, group_id: number, channel_id: number }

// -------

// project group
export interface IProjectGroupUpdateData {
  // all fields are optional when updating a project group
  name?: string
  // projectId?: number // NB: this can't be changed once set, so skipped here
  // isDefaultGroup?: boolean // NB: api supplied field only
  isParentGroup?: boolean
  parentGroupId?: number
  mirrorGroupId?: number
  desc?: string
  enabled?: boolean
  // allowPermissions?: boolean // NB: not currently used
  // actionPermissions?: ActionPermissions // TODO: can we update/set this? (or is it an api supplied field only?)
  // usersCount: number // NB: api supplied field only
  // channelsCount: number // NB: api supplied field only
  // programsCount: number // NB: api supplied field only
}
export interface IProjectGroupAddData extends IProjectGroupUpdateData {
  // (re)declare any fields that are required when adding a project group
  name: string
  // projectId: number // NB: handled as a separate arg instead of in this interface
}

// -------

// company/org group
export interface ICompanyGroupUpdateData {
  // all fields are optional when updating a company/org group
  // NB: skipped project group specific fields here
  name?: string
  desc?: string
  enabled?: boolean
  isVisibleToAllProjects?: boolean
}
export interface ICompanyGroupAddData extends ICompanyGroupUpdateData {
  // (re)declare any fields that are required when adding a company/org group
  name: string
}

// -------

// project group
export class ProjectGroup extends BaseModel {
  id: number
  name: string

  projectId: number
  isDefaultGroup: boolean
  isParentGroup: boolean
  parentGroupId?: number
  mirrorGroupId?: number

  desc?: string

  enabled: boolean

  allowPermissions: boolean
  actionPermissions?: ActionPermissions

  usersCount: number
  channelsCount: number
  programsCount: number

  subGroups?: Array<ProjectGroup> // TODO: DEPRECIATE? no longer adding models inside others directly (so it matches the api responses closer)

  mirrorGroup?: CompanyGroup // mirrored groups include a reference to the company/org group they mirror so we can get certain details from it (like user count which will be 0 at the project group level)

  constructor (
    id: number,
    name: string,
    projectId: number,
    isDefaultGroup: boolean,
    isParentGroup: boolean,
    parentGroupId?: number,
    mirrorGroupId?: number,
    desc?: string,
    enabled: boolean = false,
    allowPermissions: boolean = false,
    actionPermissions?: ActionPermissions,
    usersCount: number = 0,
    channelsCount: number = 0,
    programsCount: number = 0,
    mirrorGroup?: CompanyGroup
  ) {
    super()
    this.id = id
    this.name = name
    this.projectId = projectId
    this.isDefaultGroup = isDefaultGroup
    this.isParentGroup = isParentGroup
    this.parentGroupId = parentGroupId ?? undefined
    this.mirrorGroupId = mirrorGroupId ?? undefined
    this.desc = desc
    this.enabled = enabled
    this.allowPermissions = allowPermissions
    this.actionPermissions = actionPermissions
    this.usersCount = usersCount
    this.channelsCount = channelsCount
    this.programsCount = programsCount
    this.mirrorGroup = mirrorGroup
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (id: number, json: any) : ProjectGroup | null {
    if (!json) return null
    const actionPermissions = ActionPermissions.fromJSON(json) ?? undefined
    const mirrorGroup = json.mirrored_group ? (CompanyGroup.fromJSON(json.mirrored_group.id, json.mirrored_group) ?? undefined) : undefined
    return new ProjectGroup(
      id,
      json.name,
      json.project_id,
      json.flag_default ?? false,
      json.flag_parent_group,
      json.parent_group_id,
      json.mirrored_group_id,
      json.description,
      json.enabled,
      json.allow_permissions,
      actionPermissions,
      json.users_count,
      json.channels_count,
      json.programs_count,
      mirrorGroup
    )
  }

  isMirroredGroup () : boolean {
    return this.mirrorGroupId !== undefined && this.mirrorGroupId !== null
  }
}

// -------

// company/org group
// TODO: make this the base class and have ProjectGroup extend it? (or keep the both separate?? as each has some unique fields, maybe create a shared base class for the common fields instead? can it be made so it can't be instantiated directly? or just declare it as an interface?)
// NB: referred to as OrgGroup in the api, but named CompanyGroup here to match the original `Company<Type>` based naming in the web-app (might rename in the future)
export class CompanyGroup extends ProjectGroup {
  // CompanyGroup specific fields
  isVisibleToAllProjects: boolean

  constructor (
    // Group fields
    id: number,
    name: string,
    // isDefaultGroup: boolean = false, // NB: org groups do NOT support a `default` group (the flag is currently supplied by the api but is always false) - TODO: depreciate this?
    // isParentGroup: boolean,
    // parentGroupId?: number,
    // mirrorGroupId?: number,
    desc?: string,
    enabled: boolean = false,
    allowPermissions: boolean = false,
    actionPermissions?: ActionPermissions,
    usersCount: number = 0,
    channelsCount: number = 0,
    programsCount: number = 0,
    // CompanyGroup specific fields
    isVisibleToAllProjects: boolean = false
  ) {
    // TODO: temp passing 0 for the projectId below, & false & undefined for isDefaultGroup, isParentGroup, parentGroupId & mirrorGroupId fields (which don't apply to org groups) <<<<
    super(id, name, 0, false, false, undefined, undefined, desc, enabled, allowPermissions, actionPermissions, usersCount, channelsCount, programsCount)
    this.isVisibleToAllProjects = typeof isVisibleToAllProjects === 'boolean' || typeof isVisibleToAllProjects === 'number' ? !!isVisibleToAllProjects : false
  }

  static fromJSON (id: number, json: any) : CompanyGroup | null {
    if (!json) return null
    const actionPermissions = ActionPermissions.fromJSON(json) ?? undefined
    return new CompanyGroup(
      id,
      json.name,
      // json.flag_default ?? false,
      // json.flag_parent_group,
      // json.parent_group_id,
      // json.mirrored_group_id,
      json.description,
      json.enabled,
      json.allow_permissions,
      actionPermissions,
      json.users_count,
      json.channels_count,
      json.programs_count,
      json.always_allow_org_group_visibility
    )
  }
}
