import React from 'react'

// import { UserContext } from 'src/core/providers'
import { Company, CompanyGroup, CompanyUser, Project } from 'src/core/models'

import CompanyGroupAccessToggle from './CompanyGroupAccessToggle'
import CompanyGroupDeleteView from './CompanyGroupDeleteView'
import CompanyGroupUsersPanel from './CompanyGroupUsersPanel'
import CompanyGroupProjectsPanel from './CompanyGroupProjectsPanel'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_GROUP_NAME, OBJECT_PROJECT_NAME_PLURAL, OBJECT_USER_NAME_PLURAL } from 'src/constants/strings'

import styles from './CompanyGroupSidebar.module.css'

export type CompanyGroupEditCallback = (group: CompanyGroup) => void
export type CompanyGroupDidDeleteCallback = (group: CompanyGroup) => void

interface IProps {
  company: Company
  group?: CompanyGroup
  onChange?: Function
  onEdit?: CompanyGroupEditCallback // parent component should show the edit group modal/UI
  onDidDeleteGroup?: CompanyGroupDidDeleteCallback // the group has been deleted (parent component should update the list of groups & clear the sidebar selection)
  onGroupUpdated: (group: CompanyGroup) => void
  // NB: the parent component passes in the list of group users, so it also passes in its loading state as well
  loadingUsers: boolean
  groupUsers?: Array<CompanyUser>
  loadingProjects: boolean
  groupProjects?: Array<Project>
}

const CompanyGroupSidebar = (props: IProps) => {
  const {
    company,
    group,
    onChange,
    onEdit: _onEdit,
    onDidDeleteGroup: _onDidDeleteGroup,
    onGroupUpdated: _onGroupUpdated,
    loadingUsers,
    groupUsers,
    loadingProjects,
    groupProjects
  } = props

  if (!group) return null

  // -------

  const renderGroupUsers = () => {
    const userCount = !loadingUsers ? groupUsers?.length ?? 0 : undefined
    return (
      <div className={styles.groupUsers + ' ' + styles.list}>
        <ArkHeader as='h3' inverted>{OBJECT_USER_NAME_PLURAL}{userCount !== undefined ? ': ' + userCount : ''}</ArkHeader>
        {loadingUsers && <div className={styles.loader}><ArkLoader small inline /></div>}
        {!loadingUsers && groupUsers && groupUsers.length > 0 && (
          <ul>
            {groupUsers.map((user: CompanyUser) => {
              return (
                <li key={'group_user_' + user.id}>
                  <ArkAvatar
                    type={user.userAvatarType()}
                    name={user.name()}
                    size="30"
                  />
                </li>
              )
            })}
          </ul>
        )}
        {!loadingUsers && (!groupUsers || groupUsers.length === 0) && (<span>⚠️ No {OBJECT_USER_NAME_PLURAL} assigned to this {OBJECT_GROUP_NAME}</span>)}
      </div>
    )
  }

  const renderGroupProjects = () => {
    const projectCount = !loadingProjects ? groupProjects?.length ?? 0 : undefined
    const isVisibleToAllProjects = group.isVisibleToAllProjects
    return (
      <div className={styles.groupProjects + ' ' + styles.list}>
        <ArkHeader as='h3' inverted>{OBJECT_PROJECT_NAME_PLURAL}{projectCount !== undefined ? ': ' + projectCount : ''}</ArkHeader>
        {loadingProjects && <div className={styles.loader}><ArkLoader small inline /></div>}
        {!loadingProjects && groupProjects && groupProjects.length > 0 && !isVisibleToAllProjects && (
          <ul>
            {groupProjects.map((project: Project, index: number) => {
              return (
                <li key={'group_project_' + project.id}>
                  {/* <ArkAvatar
                    type={project.userAvatarType()}
                    name={project.name}
                    size="30"
                  /> */}
                  {project.name}{index < groupProjects.length - 1 ? ', ' : ''}
                </li>
              )
            })}
          </ul>
        )}
        {!loadingProjects && groupProjects && groupProjects.length > 0 && isVisibleToAllProjects && (
          <span>Visible to all {OBJECT_PROJECT_NAME_PLURAL}</span>
        )}
        {!loadingProjects && (!groupProjects || groupProjects.length === 0) && (<span>⚠️ No {OBJECT_PROJECT_NAME_PLURAL} assigned to this {OBJECT_GROUP_NAME}</span>)}
      </div>
    )
  }

  // -------

  const onEdit = () => {
    if (_onEdit) _onEdit(group)
  }

  const onDidDeleteGroup = (group: CompanyGroup) => {
    if (_onDidDeleteGroup) _onDidDeleteGroup(group)
  }

  // -------

  return (
    <>
      <ArkPanel bordered className={styles.sidebarHeader}>
        <ArkPanel.Header className={styles.sidebarTitle} title={group.name} iconName={'group'} />
        <ArkIconButton
          className={styles.menuButton}
          name='settings'
          onClick={onEdit}
          size={24}
        />
      </ArkPanel>

      <ArkSpacer />

      {/* <ArkButton type="button" fluid size="large" onClick={onEdit}>EDIT {OBJECT_GROUP_NAME}</ArkButton> */}

      <ArkPanel bordered className={styles.sidebarPanel}>
        <ArkPanel.Properties>
          {/* <ArkPanel.PropertyRow title='ID:' value={group.id} /> */}
          {/* <ArkPanel.PropertyRow title={OBJECT_GROUP_NAME + ' Type:'} value={groupType} /> */}
          {/* <ArkPanel.PropertyRow title='Status:' value={group.enabled ? 'ENABLED' : 'DISABLED'} /> */}{/* NB: now hidden as it only dupes the access togle below, so is somewhat pointless */}
          <ArkPanel.PropertyRow title={'Access:'} value={<>
            <CompanyGroupAccessToggle
              companyId={company.id}
              group={group}
              onChange={(value: boolean) => {
                console.log('CompanyGroupListItem - CompanyGroupAccessToggle - onChange - value:', value)
                if (value !== group.enabled) {
                  group.enabled = value
                  _onGroupUpdated(group)
                }
              }}
              showStatusText={false}
            />
          </>} />
        </ArkPanel.Properties>
      </ArkPanel>

      <ArkSpacer />

      {renderGroupUsers()}

      <ArkSpacer large />

      {renderGroupProjects()}

      <ArkSpacer />

      <ArkSpacer large />

      <>

        <CompanyGroupUsersPanel
          companyId={company.id}
          group={group}
          onChange={onChange}
        />

        <ArkSpacer />

        <CompanyGroupProjectsPanel
          companyId={company.id}
          group={group}
          onChange={onChange}
        />
      </>

      <ArkSpacer grow />

      <CompanyGroupDeleteView
        companyId={company.id}
        group={group}
        onDidDelete={onDidDeleteGroup}
      />
    </>
  )
}

export default CompanyGroupSidebar
