import { BaseModel } from './base_model'

export type GroupProgramOperation = {
  // NB: these args are named from the api & used with api calls, so keeping them in this format instead of camelCase (could otherwise map them if we want to keep camelCase in our code)
  // eslint-disable-next-line camelcase
  operation: number, group_id: number, program_id: number
}

export type ChannelProgramOperation = {
  // NB: these args are named from the api & used with api calls, so keeping them in this format instead of camelCase (could otherwise map them if we want to keep camelCase in our code)
  // eslint-disable-next-line camelcase
  operation: number, channel_id: number, program_id: number,
  // eslint-disable-next-line camelcase
  first_position?: boolean, enabled?: boolean, flag_solo?: boolean, volume_level?: number
}

export interface IProgramDefaults {
  srtKeyLength?: number
  srtLatency?: number
  srtMaxBandwidth?: number
}

export interface IProgramScheme {
  protocol: string
  scheme: string
  isSecure: boolean
}

// NB: currently sharing the same object/interface for both inputs & outputs as most fields are common in both
// NB: could otherwise split them out to IProgramInput & IProgramOutput type fields if we do need to split them up down the line..
export interface IProgramProtocolData {
  protocol: string // protocol key
  name: string // protocol name/key
  fullURL: string
  baseURL?: string
  port?: number
  user?: string
  pass?: string
  // TODO: add `scheme?: string` (as optional or required?)?
  extraFields?: {[ key: string ]: any} // allow any protocol specific properties to be set
}
export interface IProgramInputProtocolData extends IProgramProtocolData {}
export interface IProgramOutputProtocolData extends IProgramProtocolData {
  fullABRURL?: string
  // WARNING: this `hotlinkEnabled` flag currently may not be correct as of API version `v0.3.23`
  // WARNING: it doesn't seem to be updating as expected api side
  // WARNING: & we should instead rely on the `Program.hotlinkProtocols` array of protocol keys instead for now which does seem correct
  // NOTE: temp renamed the var to make it more obvious its not used anywhere currently
  // TODO: waiting for the api to confirm if this is a bug or something else
  // TODO: rename this var back once the api is fixed (or we find out its not a bug & how/when to use it vs the alternative data source)
  _hotlinkEnabled: boolean
}

export interface IProgramInputAuthData {
  user: string
  pass: string
}

export interface IProgramUpdateData {
  // base fields:
  name?: string
  shortName?: string
  colour?: string
  isAudioOnly?: boolean
  is360Video?: boolean
  // srt general:
  srtLatency?: number // TODO: DEPRECIATE (once all API servers are running v0.3.28+)
  srtMaxBandwidth?: number // TODO: DEPRECIATE (once all API servers are running v0.3.28+)
  // srt input:
  srtInputUrl?: string
  srtInputKeyLength?: number
  srtInputPassphraseEnabled?: boolean
  srtInputLatency?: number // ADDED: API v0.3.28+
  srtInputMaxBandwidth?: number // ADDED: API v0.3.28+
  // srt output:
  srtOutputUrl?: string
  srtOutputKeyLength?: number
  srtOutputPassphraseEnabled?: boolean
  srtOutputLatency?: number // ADDED: API v0.3.28+
  srtOutputMaxBandwidth?: number // ADDED: API v0.3.28+
  // srt ports:
  srtCustomPortsEnabled?: boolean
  srtInputPort?: number
  srtOutputPort?: number
  // hotlink:
  hotlinkEnabled?: boolean
  hotlinkProtocols?: Array<string>
}
export interface IProgramAddData extends IProgramUpdateData {
  name: string
}

export class Program extends BaseModel {
  id: number
  name: string
  shortName?: string
  colour?: string

  programConfigVersion: number

  online: 'online' | 'offline'

  isAudioOnly?: boolean
  is360Video?: boolean

  inputs: Map<string, IProgramInputProtocolData>
  outputs: Map<string, IProgramOutputProtocolData>

  srtCustomPortsEnabled: boolean
  srtInputPort?: number
  srtOutputPort?: number

  inputAuth?: IProgramInputAuthData

  serverURL?: string
  serverIP?: string

  hotlinkEnabled?: boolean
  hotlinkProtocols?: Array<string> // NB: server forces all protocol keys to be upper case
  hotlinkValidMins?: number
  hotlinkCreatedAt?: Date
  // TODO: don't use Date objects here, use ms offsets maybe? does this apply client side (likely): https://blog.insiderattack.net/how-not-to-measure-time-in-programming-11089d546180
  // TODO: maybe use performance.now() instead? ref: https://developer.mozilla.org/en-US/docs/Web/API/Performance/now
  hotlinkExpiresAt?: Date // NB: this may not be exact (& if/when the server supplies it, we may need to take client side time differences into account)

  constructor (
    id: number,
    name: string,
    shortName: string | undefined = undefined,
    colour: string | undefined = undefined,

    programConfigVersion: number,

    online: 'online' | 'offline',

    isAudioOnly?: boolean,
    is360Video?: boolean,

    inputs: Map<string, IProgramInputProtocolData> = new Map<string, IProgramInputProtocolData>(),
    outputs: Map<string, IProgramOutputProtocolData> = new Map<string, IProgramOutputProtocolData>(),

    srtCustomPortsEnabled: boolean = false,
    srtInputPort?: number,
    srtOutputPort?: number,

    inputAuth?: IProgramInputAuthData,

    serverURL?: string,
    serverIP?: string,

    hotlinkEnabled?: boolean,
    hotlinkProtocols?: Array<string>,
    hotlinkValidMins?: number, // NB: read only at the Program level, can be edited at the Project level
    hotlinkCreatedAt?: Date,
    hotlinkExpiresAt?: Date

  ) {
    super()
    this.id = id
    this.name = name
    this.shortName = shortName
    this.colour = colour

    this.programConfigVersion = programConfigVersion

    this.online = online

    this.isAudioOnly = isAudioOnly
    this.is360Video = is360Video

    this.inputs = inputs
    this.outputs = outputs

    this.srtCustomPortsEnabled = srtCustomPortsEnabled
    this.srtInputPort = srtInputPort
    this.srtOutputPort = srtOutputPort

    this.inputAuth = inputAuth

    this.serverURL = serverURL
    this.serverIP = serverIP

    this.hotlinkEnabled = hotlinkEnabled
    this.hotlinkProtocols = hotlinkProtocols
    this.hotlinkValidMins = hotlinkValidMins
    this.hotlinkCreatedAt = hotlinkCreatedAt
    this.hotlinkExpiresAt = hotlinkExpiresAt
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  // -------

  static parseInputData (inputJson: any, serverIP?: string) { // serverURL?: string,
    // console.log('Program - parseInputData - inputJson:', inputJson)
    const results = new Map<string, IProgramInputProtocolData>()
    if (inputJson && typeof inputJson === 'object' && inputJson.protocols && typeof inputJson.protocols === 'object' && Array.isArray(inputJson.protocols)) {
      for (const protocolData of inputJson.protocols) {
        // console.log('Program - parseInputData - protocolData:', protocolData)
        const protocolEnabled = !!(protocolData.is_enabled !== undefined && protocolData.is_enabled) // if the `is_enabled` isn't set default to false so we don't parse it
        // console.log('Program - parseInputData - protocolEnabled:', protocolEnabled)
        if (protocolEnabled) {
          // console.log('Program - parseInputData (enabled) - protocolData:', protocolData)
          // make sure the protocol data has all the required fields, skip it if not
          let hasRequiredFields = true
          const requiredJsonFields = ['key', 'name', 'full_url']
          for (const requiredJsonField of requiredJsonFields) {
            if (!Object.prototype.hasOwnProperty.call(protocolData, requiredJsonField)) { // ref: https://stackoverflow.com/a/455340
              hasRequiredFields = false
              break
            }
          }
          if (hasRequiredFields) {
            // create the base input with the required fullURL field
            const inputProtocol: IProgramInputProtocolData = {
              protocol: protocolData.key,
              name: protocolData.name,
              fullURL: protocolData.full_url
            }
            const optionalJsonFields = ['base_url', 'port', 'passphrase'] // TOOD: `user` & `pass`? (or does the api not supply those as dedicated fields currently, perhaps only for certain protocols?)
            if (protocolData.base_url !== undefined) inputProtocol.baseURL = protocolData.base_url as string
            if (protocolData.port !== undefined) inputProtocol.port = (typeof protocolData.port === 'number') ? protocolData.port : parseInt(protocolData.port as string)
            if (protocolData.passphrase !== undefined) inputProtocol.pass = protocolData.passphrase as string
            const ignoreJsonFields: Array<string> = [] // ['enable_passphrase', 'is_enabled'] // TODO: <<<<
            for (const fieldKey of Object.keys(protocolData)) {
              // console.log('Program - parseInputData - fieldKey:', fieldKey)
              if (!requiredJsonFields.includes(fieldKey) && !optionalJsonFields.includes(fieldKey) && !ignoreJsonFields.includes(fieldKey)) {
                if (!inputProtocol.extraFields) inputProtocol.extraFields = {}
                inputProtocol.extraFields[fieldKey] = protocolData[fieldKey]
              }
            }
            // if a base url is set also add the server IP (the server sends it at the 'all programs' level & we inject it at the per program level for easy/quick ref)
            // TODO: should `extraFields.ip` be made a dedicated/root field?
            if (inputProtocol.baseURL !== undefined && inputProtocol.extraFields?.ip === undefined) {
              if (!inputProtocol.extraFields) inputProtocol.extraFields = {}
              inputProtocol.extraFields.ip = serverIP
            }
            // console.log('Program - parseInputData - inputProtocol:', inputProtocol)
            results.set(inputProtocol.name, inputProtocol) // TODO: flip to .key once everything else has been updated to support it
          } else {
            console.log('Program - parseInputData - IGNORING PROTOCOL WITH MISSING REQUIRED FIELDS - protocolData:', protocolData)
          }
        } else {
          // console.log('Program - parseInputData - IGNORING DISABLED PROTOCOL - protocolData:', protocolData)
        }
      }
    }
    return results
  }

  static parseOutputData (outputJson: any, serverIP?: string) { // serverURL?: string,
    // console.log('Program - parseOutputData - outputJson:', outputJson)
    const results = new Map<string, IProgramOutputProtocolData>()
    if (outputJson && typeof outputJson === 'object' && outputJson.protocols && typeof outputJson.protocols === 'object' && Array.isArray(outputJson.protocols)) {
      for (const protocolData of outputJson.protocols) {
        // console.log('Program - parseOutputData - protocolData:', protocolData)
        const protocolEnabled = !!(protocolData.is_enabled !== undefined && protocolData.is_enabled) // if the `is_enabled` isn't set default to false so we don't parse it
        // console.log('Program - parseOutputData - protocolEnabled:', protocolEnabled)
        if (protocolEnabled) {
          // console.log('Program - parseOutputData (enabled) - protocolData:', protocolData)
          // make sure the protocol data has all the required fields, skip it if not
          let hasRequiredFields = true
          const requiredJsonFields = ['key', 'name', 'full_url', 'is_hotlink_protected']
          for (const requiredJsonField of requiredJsonFields) {
            if (!Object.prototype.hasOwnProperty.call(protocolData, requiredJsonField)) { // ref: https://stackoverflow.com/a/455340
              hasRequiredFields = false
              break
            }
          }
          if (hasRequiredFields) {
            // create the base input with the required fullURL field
            const outputProtocol: IProgramOutputProtocolData = {
              protocol: protocolData.key,
              name: protocolData.name,
              fullURL: protocolData.full_url,
              _hotlinkEnabled: protocolData.is_hotlink_protected // TODO: see the comments for this field in the `IProgramOutputProtocolData` declaration (possible api side issues, so using the `Program.hotlinkProtocols` array instead as the source of truth for this for now)
            }
            const optionalJsonFields = ['base_url', 'full_url_abr', 'port', 'passphrase'] // TOOD: `user` & `pass`? (or does the api not supply those as dedicated fields currently, perhaps only for certain protocols?)
            if (protocolData.base_url !== undefined) outputProtocol.baseURL = protocolData.base_url as string
            if (protocolData.full_url_abr !== undefined) outputProtocol.fullABRURL = protocolData.full_url_abr as string
            if (protocolData.port !== undefined) outputProtocol.port = (typeof protocolData.port === 'number') ? protocolData.port : parseInt(protocolData.port as string)
            if (protocolData.passphrase !== undefined) outputProtocol.pass = protocolData.passphrase as string
            const ignoreJsonFields: Array<string> = [] // ['enable_passphrase', 'is_enabled'] // TODO: <<<<
            for (const fieldKey of Object.keys(protocolData)) {
              // console.log('Program - parseOutputData - fieldKey:', fieldKey)
              if (!requiredJsonFields.includes(fieldKey) && !optionalJsonFields.includes(fieldKey) && !ignoreJsonFields.includes(fieldKey)) {
                if (!outputProtocol.extraFields) outputProtocol.extraFields = {}
                outputProtocol.extraFields[fieldKey] = protocolData[fieldKey]
              }
            }
            // if a base url is set also add the server IP (the server sends it at the 'all programs' level & we inject it at the per program level for easy/quick ref)
            // TODO: should `extraFields.ip` be made a dedicated/root field?
            if (outputProtocol.baseURL !== undefined && outputProtocol.extraFields?.ip === undefined) {
              if (!outputProtocol.extraFields) outputProtocol.extraFields = {}
              outputProtocol.extraFields.ip = serverIP
            }
            // console.log('Program - parseOutputData - outputProtocol:', outputProtocol)
            results.set(outputProtocol.name, outputProtocol) // TODO: flip to .key once everything else has been updated to support it
          } else {
            console.log('Program - parseOutputData - IGNORING PROTOCOL WITH MISSING REQUIRED FIELDS - protocolData:', protocolData)
          }
        } else {
          // console.log('Program - parseOutputData - IGNORING DISABLED PROTOCOL - protocolData:', protocolData)
        }
      }
    }
    return results
  }

  // -------

  // NOTE: make sure any parsing of `playback` & `input` json fields treats them as optional, as they will be null when the projects assigned video engine is either disabled or no VE is currently assigned
  static fromJSON (id: number, json: any) : Program | null {
    if (!json) {
      return null
    }
    // console.log('Program - fromJSON - json:', json)

    // read in the hotlink cert valid mins & created at timestamp
    // NB: the api re-generates a new hotlink auth each time the program is (re)loaded, so we can assume its valid from 'now + hotlinkValidMins' (give or take the api call time)
    // NB: may not want to use Date objects if we want to be more accurate (do we need to be for this?) & avoid NTP time jumps/shifts, lookup `performance.now()` instead & see comments for the var declarations towards the top of this class
    const hotlinkValidMins: number | undefined = json.playback?.auth.auth_valid_minutes
    const hotlinkCreatedAt: Date | undefined = json.playback?.auth.auth_token_created_at !== undefined ? new Date(json.playback.auth.auth_token_created_at) : undefined
    // calc the hotlink token expiry time
    let hotlinkExpiresAt: Date | undefined
    if (hotlinkValidMins && hotlinkCreatedAt) {
      hotlinkExpiresAt = new Date(hotlinkCreatedAt.getTime() + (hotlinkValidMins * 60000)) // ref: https://stackoverflow.com/a/1214753
    }

    // input & output prorgam urls
    const inputs = Program.parseInputData(json.input, json.server_ip) // json.server_url, // ['app_login', 'app_password', 'enable_passphrase'],
    const outputs = Program.parseOutputData(json.playback, json.server_ip) // json.server_url, // ['auth', 'enable_passphrase'],

    const inputAuth: IProgramInputAuthData | undefined = json.input?.auth?.app_login && json.input?.auth?.app_password ? { user: json.input?.auth?.app_login, pass: json.input?.auth?.app_password } : undefined

    // NB: the current version of the api returns the srt ports as strings, so we parse to ints before storing with a fallback NaN check for good measure
    // NB: the api will update this to return numbers in the future which are also handled below, so this shouldn't need updating until we decide to remove the string parsing in the future if we decide its worth it (no harm in leaving it in as a fallback really)
    let srtInputPort: number | undefined = typeof json.custom_port_in === 'string' ? parseInt(json.custom_port_in) : (json.custom_port_in ?? undefined)
    let srtOutputPort: number | undefined = typeof json.custom_port_out === 'string' ? parseInt(json.custom_port_out) : (json.custom_port_out ?? undefined)
    if (srtInputPort && isNaN(srtInputPort)) srtInputPort = undefined
    if (srtOutputPort && isNaN(srtOutputPort)) srtOutputPort = undefined

    return new Program(
      id,
      json.program_name,
      json.short_name,
      json.colour,

      json.config_version,

      json.online,

      json.flag_audio_only,
      json.flag_360_video,

      inputs,
      outputs,

      json.flag_custom_ports ?? false,
      srtInputPort,
      srtOutputPort,

      inputAuth,

      json.server_url,
      json.server_ip,

      json.playback?.auth.auth_enabled, // = hotlinkEnabled
      json.playback?.auth.auth_protocols, // = hotlinkProtocols
      hotlinkValidMins,
      hotlinkCreatedAt,
      hotlinkExpiresAt
    )
  }

  // -----

  shortNameCapitalised = () => {
    return this.shortName?.toUpperCase()
  }

  // -----

  getInputProtocolData = (protocol: string) => {
    return this.inputs.get(protocol)
  }

  getOutputProtocolData = (protocol: string) => {
    return this.outputs.get(protocol)
  }

  getDisplayOutputs = () => {
    // don't display sldp as a manual output options (embedded web only)
    return new Map([...this.outputs].filter(([key]) => key !== 'SLDP'))
  }

  getSRTInputData = () => this.getInputProtocolData('SRT')
  getSRTOutputData = () => this.getOutputProtocolData('SRT')

  getSLDPInputData = () => this.getInputProtocolData('SLDP')
  getSLDPOutputData = () => this.getOutputProtocolData('SLDP')
  getSLDPOutputURL = (passthough: boolean = false) => {
    const sldpOutputData = this.getSLDPOutputData()
    return (passthough ? sldpOutputData?.fullURL : sldpOutputData?.fullABRURL) ?? undefined
  }

  // -----

  // NB: no longer needed for the player side (unless we want to visually indicate when hotlink playback is in use)
  // NB: as the server only returns the relevant playback url for the same vars depending if hotlink is enabled or not

  isHotlinkEnabledForProtocol = (protocol: string) => {
    return (!!this.hotlinkEnabled) && (this.hotlinkProtocols?.includes(protocol) ?? false)
  }

  isHotlinkEnabledForSRT = () => {
    return this.isHotlinkEnabledForProtocol('SRT')
  }

  isHotlinkEnabledForSLDP = () => {
    return this.isHotlinkEnabledForProtocol('SLDP')
  }

  // helper that returns true if hotlink auth is enabled for this program & the current auth token is considered expired
  // NB: currently considering 'expired' as either being actually expired, or within x% of expiring soon
  // NB: as we only support playback of SLDP in the webplayer currently, this is focused only on that protocol
  shouldRenewSLDPHotlink = () => {
    if (this.online !== 'online') return false
    const hotlinkEnabled = this.isHotlinkEnabledForSLDP()
    if (!hotlinkEnabled) return false
    // treating no hotlink created or expiry dates, or no valid duration as it not being enabled (as we can't act on it)
    if (!this.hotlinkCreatedAt || !this.hotlinkExpiresAt || this.hotlinkValidMins === undefined) return false
    const timeNow = new Date()
    // check if its already expired
    if (this.hotlinkExpiresAt <= timeNow) return true
    // check if it expires soon (within 80% of its normal time)
    const expiresSoonTime = new Date(this.hotlinkCreatedAt.getTime() + ((this.hotlinkValidMins * 0.8) * 60000))
    if (expiresSoonTime <= timeNow) return true
    return false
  }

  // helper that returns true if the programs config version is older than the current server program config version
  shouldUpdateConfigVersion = (serverProgramConfigVersion?: number) => {
    return serverProgramConfigVersion !== undefined && this.programConfigVersion < serverProgramConfigVersion
  }
}
