import ServerAPIClient from './ServerAPIClient'
import { ServerError, ServerErrorCodes } from './ServerAPIErrors'

import { VideoEngine, CompanyVideoEngine } from '../models'
import { ICompanyVideoEngineUpdateData, IVideoEngineAddData, IVideoEngineUpdateData } from '../models/video_engine'

// 'Port not found' response if looking up a port for a video engine & its not in a pre-declared valid range for that engine
export class ServerVideoEngineInvalidPortError extends ServerError {
  constructor (message?: string) {
    super((message === undefined ? 'Invalid Port' : message), 404, ServerErrorCodes.videoEngineInvalidPort) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

export enum ServerVideoEnginePortType {
  Input = 'input', // unavailable (already assigned as an input port to a program)
  Output = 'output', // unavailable (already assigned as an output port to a program)
}
export enum ServerVideoEnginePortStatus {
  Assigned = 'assigned', // assigned/unavailable (already assigned to a program)
  Free = 'free', // available (but need to check the input/output type to ensure it can be used for the desired type)
  Invalid = 'invalid' // invalid port number (not within any valid input or output range for the video engine server)
}

export interface IServerVideoEnginePortStatusResult {
  portStatus: ServerVideoEnginePortStatus
  portType?: ServerVideoEnginePortType
  programId?: number
  error?: Error
  rawResult?: { id?: number, input: boolean, port: string, program_id: number | null, streaming_server_id: number } // the full raw result data from the api (TODO: update `port` to `number` once the api updates it from a string)
}

class ServerVideoEngineAPI {
  private _apiClient: ServerAPIClient

  constructor (apiClient: ServerAPIClient) {
    this._apiClient = apiClient
  }

  // -------

  // access: god user only
  // NB: there is now an altnerative `/server` endpoint for org admins that returns just the servers they have access too (& more limited fields?)
  getAllVideoEngines = async (): Promise<Array<VideoEngine> | null> => {
    try {
      const response = await this._apiClient.apiGet('/server/global')
      const videoEngines: Array<VideoEngine> = []
      if (response.data && response.data.result && response.data.result) {
        const videoEnginesData = response.data.result
        for (const videoEngineData of videoEnginesData) {
          // NB: CompanyCounts aren't currently supplied for this endpoint (just the direct company data itself)
          const videoEngine = VideoEngine.fromJSON(videoEngineData)
          if (videoEngine) {
            videoEngines.push(videoEngine)
          }
        }
      }
      // UPDATE: currently leaving the order as the default they were created in, commented out the sort call below
      // TODO: should the current default be forced to the top? might require querying the config api endpoint to get it, unless the api also returns it here, or can be extended to add a flag in this response?
      // sort/order the video engines array by name
      // videoEngines.sort((a: Company, b: Company) => a.name.localeCompare(b.name))
      // sort/order the video engines array by org name using a more intelligent natural sort
      // videoEngines.sort((a: VideoEngine, b: VideoEngine) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
      return videoEngines
    } catch (error) {
      console.error('ServerVideoEngineAPI - getAllVideoEngines - error: ', error)
      throw error
    }
  }

  // access: god user only
  addVideoEngine = async (videoEngineData: IVideoEngineAddData): Promise<VideoEngine> => {
    // console.log('ServerVideoEngineAPI - addVideoEngine - videoEngineData: ', videoEngineData)
    const videoEngineJSONKeyMap = VideoEngine.propertyToJSONKeyMap()
    // console.log('ServerVideoEngineAPI - addVideoEngine - videoEngineJSONKeyMap: ', videoEngineJSONKeyMap)
    const data: {[key: string]: any} = {}
    for (const fieldName of Object.keys(videoEngineData)) {
      // console.log('ServerVideoEngineAPI - addVideoEngine - fieldName: ', fieldName, ' = ', (videoEngineJSONKeyMap as any)[fieldName])
      if (Object.prototype.hasOwnProperty.call(videoEngineJSONKeyMap, fieldName)) {
        // check if the field has a user json key mapped
        let jsonKey = videoEngineJSONKeyMap[fieldName as keyof typeof videoEngineJSONKeyMap]
        // TESTING: special handling for srt ports fields, remove the `ports.` prefix from the key
        if (jsonKey.startsWith('ports.')) {
          jsonKey = jsonKey.replace('ports.', '')
        }
        data[jsonKey] = (videoEngineData as any)[fieldName]
      } else {
        // no json key map for this value key - use it directly
        data[fieldName] = (videoEngineData as any)[fieldName]
      }
    }
    console.log('ServerVideoEngineAPI - addVideoEngine - data: ', data, ' length: ', Object.keys(data).length)
    // check if required fields are present, throw error if not (NB: these are in the api JSON key format, not local var names)
    const requiredFields = ['name', 'domain', 'ip', 'flag_active']
    const missingFields = requiredFields.filter(function (field) {
      return !Object.keys(data).includes(field)
    })
    if (missingFields.length > 0) {
      throw new Error(`Missing required field(s): ${missingFields.join(', ')}`)
    }
    try {
      const response = await this._apiClient.apiPost('/server', data, {})
      // console.log('ServerVideoEngineAPI - addVideoEngine - response: ', response)
      let videoEngine: VideoEngine | null = null
      if (response.status === 201 && response.data && response.data.result && response.data.result) {
        const _videoEngineData = response.data.result
        videoEngine = VideoEngine.fromJSON(_videoEngineData)
      }
      if (!videoEngine) throw new Error('Invalid response')
      return videoEngine
    } catch (error) {
      console.error('ServerVideoEngineAPI - addVideoEngine - error: ', error)
      throw error
    }
  }

  // access: god user only
  updateVideoEngine = async (videoEngineId: number, videoEngineData: IVideoEngineUpdateData): Promise<VideoEngine> => {
    // console.log('ServerVideoEngineAPI - updateVideoEngine - videoEngineId: ', videoEngineId, ' videoEngineData: ', videoEngineData)
    const videoEngineJSONKeyMap = VideoEngine.propertyToJSONKeyMap()
    // console.log('ServerVideoEngineAPI - updateVideoEngine - videoEngineJSONKeyMap: ', videoEngineJSONKeyMap)
    const data: {[key: string]: any} = {}
    for (const fieldName of Object.keys(videoEngineData)) {
      // console.log('ServerVideoEngineAPI - updateVideoEngine - fieldName: ', fieldName, ' = ', (videoEngineJSONKeyMap as any)[fieldName])
      if (Object.prototype.hasOwnProperty.call(videoEngineJSONKeyMap, fieldName)) {
        // check if the field has a user json key mapped
        let jsonKey = videoEngineJSONKeyMap[fieldName as keyof typeof videoEngineJSONKeyMap]
        console.log('ServerVideoEngineAPI - updateVideoEngine - jsonKey: ', jsonKey, ' = ', (videoEngineData as any)[fieldName])
        // TESTING: support nested json keys (eg. `ports.port_ranges_in`), split the nested keys and create the nested object structure from them
        // UPDATE: although the api supplies srt ports in a nested object, it currently seems to accept them in the root of the object
        // if (jsonKey.includes('.')) {
        //   // nested json key - split and create the nested object
        //   const keys = jsonKey.split('.')
        //   let nestedObj = data
        //   for (let i = 0; i < keys.length - 1; i++) {
        //     if (!nestedObj[keys[i]]) {
        //       nestedObj[keys[i]] = {}
        //     }
        //     nestedObj = nestedObj[keys[i]]
        //   }
        //   nestedObj[keys[keys.length - 1]] = (videoEngineData as any)[fieldName]
        //   // console.log('ServerVideoEngineAPI - updateVideoEngine - nestedObj: ', nestedObj)
        // } else {
        //   data[jsonKey] = (videoEngineData as any)[fieldName]
        // }
        // TESTING: special handling for srt ports fields, remove the `ports.` prefix from the key
        if (jsonKey.startsWith('ports.')) {
          jsonKey = jsonKey.replace('ports.', '')
        }
        // TESTING: special handling for the company id field, convert a `<= 0` value to `null` to indicate 'no company'
        let value = (videoEngineData as any)[fieldName]
        if (jsonKey === 'company_id' && (videoEngineData as any)[fieldName] <= 0) {
          value = null
        }
        data[jsonKey] = value
      } else {
        // no json key map for this value key - use it directly
        data[fieldName] = (videoEngineData as any)[fieldName]
      }
    }
    console.log('ServerVideoEngineAPI - updateVideoEngine - data: ', data, ' length: ', Object.keys(data).length)
    // halt if no fields to update
    if (Object.keys(data).length === 0) {
      throw new Error('No fields to update')
    }
    try {
      const response = await this._apiClient.apiPut('/server/' + videoEngineId + '/global', data, {})
      // console.log('ServerVideoEngineAPI - updateVideoEngine - response: ', response)
      let videoEngine: VideoEngine | null = null
      if (response.status === 200 && response.data && response.data.result && response.data.result) {
        const _videoEngineData = response.data.result
        videoEngine = VideoEngine.fromJSON(_videoEngineData)
      }
      if (!videoEngine) throw new Error('Invalid response')
      return videoEngine
    } catch (error) {
      console.error('ServerVideoEngineAPI - updateVideoEngine - error: ', error)
      throw error
    }
  }

  // access: god user only
  deleteVideoEngine = async (videEngineId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiDelete('/server/' + videEngineId, undefined)
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerVideoEngineAPI - deleteVideoEngine - error: ', error)
      throw error
    }
  }

  // -------

  // access: org/company admin+ only
  // NB: this is the org/company specific version of the endpoint, which returns only the video engine servers the org has access to (org specific & global VE's unless 'orgOnly' is enabled), & only limited fields (not the full server data)
  getAllCompanyVideoEngines = async (companyId: number): Promise<Array<CompanyVideoEngine>> => {
    try {
      const orgOnly = false // TODO: expose this arg?
      const response = await this._apiClient.apiGet('/server' + (orgOnly ? '?owned_only=true' : ''), { 'company-id': companyId })
      const videoEngines: Array<CompanyVideoEngine> = []
      if (response.data && response.data.result && response.data.result) {
        const videoEnginesData = response.data.result
        for (const videoEngineData of videoEnginesData) {
          // NB: CompanyCounts aren't currently supplied for this endpoint (just the direct company data itself)
          const videoEngine = CompanyVideoEngine.fromJSON(videoEngineData)
          if (videoEngine) {
            videoEngines.push(videoEngine)
          }
        }
      }
      // UPDATE: currently leaving the order as the default they were created in, commented out the sort call below
      // TODO: should the current default be forced to the top? might require querying the config api endpoint to get it, unless the api also returns it here, or can be extended to add a flag in this response?
      // sort/order the video engines array by name
      // videoEngines.sort((a: Company, b: Company) => a.name.localeCompare(b.name))
      // sort/order the video engines array by org name using a more intelligent natural sort
      // videoEngines.sort((a: CompanyVideoEngine, b: CompanyVideoEngine) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
      return videoEngines
      // throw new Error('DEBUG: failed to load available video engines')
      // return null
    } catch (error) {
      console.error('ServerVideoEngineAPI - getAllCompanyVideoEngines - error: ', error)
      throw error
    }
  }

  // access: org/company admin+ only
  // NB: this is the org/company specific version of the endpoint, which only allows certain fields to be updated for a video engine server, & only if the video engine is assigned/mapped to the org/company (that the user is an org admin of)
  // NB: this is a dupe of the `updateVideoEngine` function (& all its warts) but tweaked for the org/company specific data model usage & endpoint
  updateCompanyVideoEngine = async (companyId: number, videoEngineId: number, videoEngineData: ICompanyVideoEngineUpdateData): Promise<CompanyVideoEngine> => {
    // console.log('ServerVideoEngineAPI - updateCompanyVideoEngine - videEngineId: ', videEngineId, ' videoEngineData: ', videoEngineData)
    const videoEngineJSONKeyMap = CompanyVideoEngine.propertyToJSONKeyMap()
    // console.log('ServerVideoEngineAPI - updateCompanyVideoEngine - videoEngineJSONKeyMap: ', videoEngineJSONKeyMap)
    const data: {[key: string]: any} = {}
    for (const fieldName of Object.keys(videoEngineData)) {
      // console.log('ServerVideoEngineAPI - updateCompanyVideoEngine - fieldName: ', fieldName, ' = ', (videoEngineJSONKeyMap as any)[fieldName])
      if (Object.prototype.hasOwnProperty.call(videoEngineJSONKeyMap, fieldName)) {
        // check if the field has a user json key mapped
        let jsonKey = videoEngineJSONKeyMap[fieldName as keyof typeof videoEngineJSONKeyMap]
        console.log('ServerVideoEngineAPI - updateCompanyVideoEngine - jsonKey: ', jsonKey, ' = ', (videoEngineData as any)[fieldName])
        // TESTING: support nested json keys (eg. `ports.port_ranges_in`), split the nested keys and create the nested object structure from them
        // UPDATE: although the api supplies srt ports in a nested object, it currently seems to accept them in the root of the object
        // if (jsonKey.includes('.')) {
        //   // nested json key - split and create the nested object
        //   const keys = jsonKey.split('.')
        //   let nestedObj = data
        //   for (let i = 0; i < keys.length - 1; i++) {
        //     if (!nestedObj[keys[i]]) {
        //       nestedObj[keys[i]] = {}
        //     }
        //     nestedObj = nestedObj[keys[i]]
        //   }
        //   nestedObj[keys[keys.length - 1]] = (videoEngineData as any)[fieldName]
        //   // console.log('ServerVideoEngineAPI - updateCompanyVideoEngine - nestedObj: ', nestedObj)
        // } else {
        //   data[jsonKey] = (videoEngineData as any)[fieldName]
        // }
        // TESTING: special handling for srt ports fields, remove the `ports.` prefix from the key
        if (jsonKey.startsWith('ports.')) {
          jsonKey = jsonKey.replace('ports.', '')
        }
        // TESTING: special handling for the company id field, convert a `<= 0` value to `null` to indicate 'no company'
        // let value = (videoEngineData as any)[fieldName]
        // if (jsonKey === 'company_id' && (videoEngineData as any)[fieldName] <= 0) {
        //   value = null
        // }
        // data[jsonKey] = value
        data[jsonKey] = (videoEngineData as any)[fieldName]
      } else {
        // no json key map for this value key - use it directly
        data[fieldName] = (videoEngineData as any)[fieldName]
      }
    }
    console.log('ServerVideoEngineAPI - updateCompanyVideoEngine - data: ', data, ' length: ', Object.keys(data).length)
    // halt if no fields to update
    if (Object.keys(data).length === 0) {
      throw new Error('No fields to update')
    }
    try {
      const response = await this._apiClient.apiPut('/server/' + videoEngineId, data, { 'company-id': companyId })
      // console.log('ServerVideoEngineAPI - updateCompanyVideoEngine - response: ', response)
      let videoEngine: CompanyVideoEngine | null = null
      if (response.status === 200 && response.data && response.data.result && response.data.result) {
        const _videoEngineData = response.data.result
        videoEngine = CompanyVideoEngine.fromJSON(_videoEngineData)
      }
      if (!videoEngine) throw new Error('Invalid response')
      return videoEngine
    } catch (error) {
      console.error('ServerVideoEngineAPI - updateCompanyVideoEngine - error: ', error)
      throw error
    }
  }

  // -------

  // access: project admin/manager+ (?)
  checkVideoEnginePortStatus = async (companyId: number, projectId: number, videoEngineId: number, port: number): Promise<IServerVideoEnginePortStatusResult> => {
    try {
      const response = await this._apiClient.apiGet('/server/' + videoEngineId + '/port/' + port, { 'company-id': companyId, 'project-id': projectId })
      // TODO: handle the response data & return a summary of the result
      // NB: current response summary:
      // - 404 status code = invalid port range on that video engine server (handled in the `catch` block)
      // - otherwise its always a 200 response & we need to check the result values to see if its assigned to another program or not
      // - in the response, if the port is within any valid (pre-assigned) range, input = false indicates its an output port otherwise its an input port, regardless if its assigned to a program or not
      // - if the port is free/available program_id will be null & so safe to assign
      if (response.status === 200) {
        const programId = response.data.result?.program_id ?? undefined
        console.log('ServerVideoEngineAPI - checkVideoEnginePortStatus - programId:', programId)
        // TODO: `ServerVideoEnginePortStatus.Invalid` ??? <<<<
        const portType: ServerVideoEnginePortType | undefined = response.data.result?.input ? ServerVideoEnginePortType.Input : (response.data.result?.input === false ? ServerVideoEnginePortType.Output : undefined)
        const portStatus: ServerVideoEnginePortStatus = programId !== undefined ? ServerVideoEnginePortStatus.Assigned : ServerVideoEnginePortStatus.Free
        const portStatusResult: IServerVideoEnginePortStatusResult = {
          portStatus: portStatus,
          portType: portType,
          programId: programId,
          rawResult: response.data.result
        }
        console.log('ServerVideoEngineAPI - checkVideoEnginePortStatus - portStatusResult:', portStatusResult)
        return portStatusResult
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerVideoEngineAPI - checkVideoEnginePortStatus - error: ', error, ' error.statusCode:', error.statusCode)
      // check for invalid port range error & return as a standard port status result instead of throwing an error (includes the error in the result)
      if (error.statusCode === 404 && error.errorCode === ServerErrorCodes.videoEngineInvalidPort) {
        const portError = new ServerVideoEngineInvalidPortError(error.message)
        return {
          portStatus: ServerVideoEnginePortStatus.Invalid,
          error: portError
          // NB: `rawResult` isn't available for this error
        } as IServerVideoEnginePortStatusResult
      }
      throw error
    }
  }
}

export default ServerVideoEngineAPI
