import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Header } from 'semantic-ui-react'

import ArkBreadcrumb from '../ArkBreadcrumb'

import styles from './ArkManagerContentView.module.css'

export interface ArkBreadcrumbItem {
  title: string | React.ReactNode
  path?: string
}

interface IProps extends RouteComponentProps {
  topbar?: React.ReactNode
  children?: React.ReactNode
  title?: string | React.ReactNode
  breadcrumb?: Array<ArkBreadcrumbItem>
}
interface IState {
}
class ArkManagerContentView extends Component<IProps, IState> {
  render () {
    const { children, topbar } = this.props
    return (
      <div className={styles.container}>
        {topbar || this.renderNavbarContent()}
        {children}
      </div>
    )
  }

  renderNavbarContent = () => {
    const { title, breadcrumb } = this.props
    if (breadcrumb) {
      return this.renderBreadcrumb(breadcrumb, title)
    }
    if (title) {
      return this.renderTitle(title)
    }
    return null
  }

  renderBreadcrumb = (breadcrumb: Array<ArkBreadcrumbItem>, title?: string | React.ReactNode) => {
    const sections = []
    for (const item of breadcrumb) {
      // sections.push(<Breadcrumb.Section link>{item.title}</Breadcrumb.Section>)
      // sections.push(<Breadcrumb.Divider />)
      sections.push({
        key: item.path,
        content: item.title,
        link: true,
        onClick: () => {
          if (item.path) this.props.history.push(item.path)
        }
      })
    }
    if (title) {
      // sections.push(<Breadcrumb.Section active>{title}</Breadcrumb.Section>)
      sections.push({ key: 'current', content: title, link: false })
    }
    return <ArkBreadcrumb sections={sections} />
  }

  // renderBreadcrumbItem = (item: ArkBreadcrumbItem) => {
  //   return (<Breadcrumb.Section link>{item.title}</Breadcrumb.Section>)
  // }

  renderTitle = (title: string | React.ReactNode) => {
    return (<Header as='h1' inverted>{title ?? ''}</Header>)
  }
}
export default withRouter(ArkManagerContentView)
