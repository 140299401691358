import React, { useContext } from 'react'

import { UserContext } from '../../../../core/providers'
import ArkPanel from '../../../../core/components/ArkPanel'
import ArkSpacer from '../../../../core/components/ArkSpacer'

import styles from './UserProfileView.module.css'

interface IProps {}

const UserProfileView = (_props: IProps) => {
  const userContext = useContext(UserContext)
  const user = userContext.store.user
  if (!user) return null
  return (
    <>
      <ArkSpacer negative />
      <ArkPanel>
        <ArkPanel.Properties>
          <ArkPanel.PropertyRow title='name:' value={user.firstName + ' ' + user.lastName} />
          <ArkPanel.PropertyRow title='email:' value={user.email + (user.emailVerified ? (!user.emailVerified ? ' (un-verified)' : '') : '')} />
          <ArkPanel.PropertyRow title='phone:' value={<>
            {(user.phoneNumber ?? '-')}
            {(user.phoneNumber
              ? (!user.phoneVerified
                ? <span className={styles.phoneUnverified}> (unverified)</span>
                : <span className={styles.phoneVerified}> (verified)</span>
              )
              : ''
            )}
          </>}
          />
          <ArkPanel.PropertyRow title='2FA:' value={user.tfaEnabled ? 'enabled' : 'disabled'} />
        </ArkPanel.Properties>
      </ArkPanel>
      <ArkSpacer size={20} />
    </>
  )
}

export default UserProfileView
