/**
 * ArkButton
 */

import React from 'react'

import { Button, ButtonGroup, ButtonGroupProps, ButtonProps, SemanticCOLORS } from 'semantic-ui-react'

import styles from './ArkButton.module.css'

export interface ArkButtonProps extends ButtonProps {
  activeColor?: SemanticCOLORS
}

const ArkButton = (props: ArkButtonProps) => {
  const { active, activeColor, className, color } = props
  const hasColor = color !== undefined // TODO: also take `activeColor` into account? (only if `active === true`?)
  return (
    <Button
      {...props}
      className={`${styles.button} ${!hasColor && styles.defaultColor} ${className || ''}`}
      color={active ? activeColor || 'blue' : color}
    />
  )
}

export default ArkButton

export const ArkButtonGroup = (props: ButtonGroupProps) => {
  const { className, ...otherProps } = props

  return (
    <ButtonGroup className={`${styles.group} ${className}`} {...otherProps} />
  )
}
