import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { withUserContext, IUserMultiContext, withAuthContext, IAuthMultiContext, withAPIContext, IAPIMultiContext, CompanyAdminProvider } from 'src/core/providers'

import * as ROUTES from 'src/constants/routes'

import { UserCompany, UserCompanyRole } from 'src/core/models'

import CompanyCardsPage from './pages/cards/CompanyCardsPage'
import CompanyManagerHomePage from './pages/home/CompanyManagerHomePage'
import CompanyProjectsPage from './pages/projects/CompanyProjectsPage'
import CompanyGroupsPage from './pages/groups/CompanyGroupsPage'
import CompanyUsersPage from './pages/users/CompanyUsersPage'
import CompanyActivityPage from './pages/activity/CompanyActivityPage'
import CompanySettingsPage from './pages/settings/CompanySettingsPage'
import CompanyCompaniesSelectPage from './pages/companies/CompanyCompaniesSelectPage'
import CompanyAccessDeniedPage from './pages/access_denied/CompanyAccessDeniedPage'
import CompanyManagerLoadingPage from './pages/loading/CompanyManagerLoadingPage'
import Company2FAAuthPage from './pages/auth/Company2FAAuthPage'
import CompanyManagerInactiveOrgPage from './pages/inactive_org/CompanyManagerInactiveOrgPage'
import CompanyVideoEnginesPage from './pages/video_engines/CompanyVideoEnginesPage'

interface IProps extends IUserMultiContext, IAuthMultiContext, IAPIMultiContext {}
interface IState {}

class CompanyManagerRouter extends React.Component<IProps, IState> {
  render () {
    // catch if no company selected
    const company: UserCompany | undefined = this.props.userContext.store.selectedCompany
    if (!company) {
      return (<CompanyCompaniesSelectPage />)
    }

    const loadingCompanyData = this.props.userContext.store.loadingCompanyData
    const updatingCompanyData = this.props.userContext.store.updatingCompanyData
    if (loadingCompanyData && !updatingCompanyData) {
      return <CompanyManagerLoadingPage />
    }

    // disabled/inactive company
    if (!company.isActive) {
      return <CompanyManagerInactiveOrgPage />
    }

    // check the user has access to this area of the site
    if (company.userCompanyRole !== UserCompanyRole.admin) {
      return (<CompanyAccessDeniedPage />)
    }

    // company 2fa
    const user = this.props.userContext.store.user
    const userTFAEnabled = user?.tfaEnabled || false
    const companyAuthTFARequired = this.props.authContext.actions.isCompanyAuthTFARequired()
    console.log('CompanyManagerRouter - companyAuthTFARequired:', companyAuthTFARequired, ' userTFAEnabled:', userTFAEnabled)
    if (companyAuthTFARequired && !userTFAEnabled) {
      return (<Company2FAAuthPage />)
    }

    return (
      <CompanyAdminProvider apiClient={this.props.apiContext.actions.getAPIClient()}>
        <Switch>
          <Route exact path={ROUTES.COMPANY_MANAGER} component={CompanyManagerHomePage} />
          <Route exact path={ROUTES.COMPANY_MANAGER_PROJECTS} component={CompanyProjectsPage} />
          <Route path={ROUTES.COMPANY_MANAGER_GROUPS} component={CompanyGroupsPage} />
          <Route exact path={ROUTES.COMPANY_MANAGER_CARDS} component={CompanyCardsPage} />
          <Route exact path={ROUTES.COMPANY_MANAGER_USERS} component={CompanyUsersPage} />
          <Route path={ROUTES.COMPANY_MANAGER_VIDEO_ENGINES} component={CompanyVideoEnginesPage} />
          <Route exact path={ROUTES.COMPANY_MANAGER_ACTIVITY} component={CompanyActivityPage} />
          <Route exact path={ROUTES.COMPANY_MANAGER_SETTINGS} component={CompanySettingsPage} />
        </Switch>
      </CompanyAdminProvider>
    )
  }
}

export default withAPIContext(withUserContext(withAuthContext(CompanyManagerRouter)))
