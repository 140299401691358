import React from 'react'

import InputSource from '../models/InputSource'

import StreamhubSourcesAPI from '../services/StreamhubSourcesAPI'
import StreamhubAPIClient from '../services/StreamhubAPIClient'

export enum StreamhubSourcesStatus {
  initial, loading, updating, done, error
}

export enum StreamhubSourceAction {
  create, update, delete
}
export enum StreamhubSourceStatus {
  initial, running, done, error
}

export interface IStreamhubSourcesStore {
  // sources
  sources?: Array<InputSource>
  sourcesStatus?: StreamhubSourcesStatus
  sourcesError?: Error
  // source
  source?: InputSource
  sourceAction?: StreamhubSourceAction
  sourceStatus?: StreamhubSourceStatus
  sourceError?: Error
  // source selection
  selectedSource?: InputSource
}

export interface IStreamhubSourcesActions {
  // sources
  fetchSources: () => Promise<void>
  // source
  selectSource: (source?: InputSource) => void
  selectSourceWithId: (sourceId?: number) => void
  createSource: (source: InputSource) => Promise<void>
  createSourceFromJson: (name?: string, videoFilename?: string, videoInputsJson?: string, audioFilename?: string, audioInputsJson?: string, overlayTitle?: string, overlayShowTimecode?: boolean, overlaysJson?: string) => Promise<void>
  updateSource: (source: InputSource) => Promise<void>
  updateSourceFromJson: (sourceId: number, name?: string, videoFilename?: string, videoInputsJson?: string, audioFilename?: string, audioInputsJson?: string, overlayTitle?: string, overlayShowTimecode?: boolean, overlaysJson?: string) => Promise<void>
  deleteSource: (source: InputSource) => Promise<void>
  deleteSourceWithId: (sourceId: number) => Promise<void>
  // source image
  getSourcePreviewImageURL: (source: InputSource, width?: number) => string
}

export interface IStreamhubSourcesContext {
  actions: IStreamhubSourcesActions
  store: IStreamhubSourcesStore
}

export interface StreamhubSourcesProviderProps {
  apiClient: StreamhubAPIClient
  sourcesAPI?: StreamhubSourcesAPI
  children?: React.ReactNode
}
export interface StreamhubSourcesProviderState extends IStreamhubSourcesStore {
  sourcesAPI: StreamhubSourcesAPI
}

export const StreamhubSourcesContext = React.createContext<IStreamhubSourcesContext>({} as IStreamhubSourcesContext)

export class StreamhubSourcesProvider extends React.Component<StreamhubSourcesProviderProps, StreamhubSourcesProviderState> {
  constructor (props: StreamhubSourcesProviderProps) {
    super(props)
    this.state = {
      sourcesAPI: props.sourcesAPI ?? new StreamhubSourcesAPI(this.props.apiClient)
    }
  }

  // -------

  fetchSources = async () => {
    const { sourcesStatus } = this.state
    const newStatus = sourcesStatus && sourcesStatus === StreamhubSourcesStatus.done ? StreamhubSourcesStatus.updating : StreamhubSourcesStatus.loading
    await new Promise((resolve) => this.setState({ sourcesStatus: newStatus, sourcesError: undefined }, () => { resolve(true) }))
    try {
      const sources = await this.state.sourcesAPI.fetchSources()
      await new Promise((resolve) => this.setState({ sources, sourcesStatus: StreamhubSourcesStatus.done }, () => { resolve(true) }))
      // await new Promise(resolve => setTimeout(resolve, 2000)) // DEBUG ONLY: add a delay to test the loading state
      // throw new Error('DEBUG ERROR')
    } catch (error) {
      console.error('StreamhubSourcesProvider - fetchSources - error:', error)
      await new Promise((resolve) => this.setState({ sources: undefined, sourcesStatus: StreamhubSourcesStatus.error, sourcesError: error }, () => { resolve(true) }))
    }
  }

  // -------

  selectSource = (source?: InputSource) => {
    this.setState({ selectedSource: source })
  }

  // NB: requires fetchSources to have been called so the source can be looked up by its id
  selectSourceWithId = (sourceId?: number) => {
    if (!sourceId) {
      this.selectSource(undefined)
      return
    }
    const source = this.state.sources?.find((source) => source.id === sourceId)
    if (source) {
      this.selectSource(source)
    }
  }

  // -------

  createSource = async (source: InputSource) => {
    try {
      await new Promise((resolve) => this.setState({ source: undefined, sourceAction: StreamhubSourceAction.create, sourceStatus: StreamhubSourceStatus.running, sourceError: undefined }, () => resolve(this.state)))
      const newSource = await this.state.sourcesAPI.createSource(source)
      await new Promise((resolve) => this.setState({ source: newSource, sourceAction: StreamhubSourceAction.create, sourceStatus: StreamhubSourceStatus.done, sourceError: undefined }, () => resolve(this.state)))
    } catch (error) {
      await new Promise((resolve) => this.setState({ source: undefined, sourceAction: StreamhubSourceAction.create, sourceStatus: StreamhubSourceStatus.error, sourceError: error }, () => resolve(this.state)))
    }
  }

  createSourceFromJson = async (name?: string, videoFilename?: string, videoInputsJson?: string, audioFilename?: string, audioInputsJson?: string, overlayTitle?: string, overlayShowTimecode?: boolean, overlaysJson?: string) => {
    try {
      await new Promise((resolve) => this.setState({ source: undefined, sourceAction: StreamhubSourceAction.create, sourceStatus: StreamhubSourceStatus.running, sourceError: undefined }, () => resolve(this.state)))
      const newSource = await this.state.sourcesAPI.createSourceFromJson(name, videoFilename, videoInputsJson, audioFilename, audioInputsJson, overlayTitle, overlayShowTimecode, overlaysJson)
      await new Promise((resolve) => this.setState({ source: newSource, sourceAction: StreamhubSourceAction.create, sourceStatus: StreamhubSourceStatus.done, sourceError: undefined }, () => resolve(this.state)))
    } catch (error) {
      await new Promise((resolve) => this.setState({ source: undefined, sourceAction: StreamhubSourceAction.create, sourceStatus: StreamhubSourceStatus.error, sourceError: error }, () => resolve(this.state)))
    }
  }

  updateSource = async (source: InputSource) => {
    try {
      await new Promise((resolve) => this.setState({ sourceAction: StreamhubSourceAction.update, sourceStatus: StreamhubSourceStatus.running, sourceError: undefined }, () => resolve(this.state))) // NB: not updating the source state until after the update (incase it fails)
      const newSource = await this.state.sourcesAPI.updateSource(source)
      await new Promise((resolve) => this.setState({ source: newSource, sourceAction: StreamhubSourceAction.update, sourceStatus: StreamhubSourceStatus.done, sourceError: undefined }, () => resolve(this.state)))
    } catch (error) {
      await new Promise((resolve) => this.setState({ sourceAction: StreamhubSourceAction.update, sourceStatus: StreamhubSourceStatus.error, sourceError: error }, () => resolve(this.state))) // NB: leaving the existing source state as it was before the update attempt
    }
  }

  updateSourceFromJson = async (sourceId: number, name?: string, videoFilename?: string, videoInputsJson?: string, audioFilename?: string, audioInputsJson?: string, overlayTitle?: string, overlayShowTimecode?: boolean, overlaysJson?: string) => {
    try {
      await new Promise((resolve) => this.setState({ sourceAction: StreamhubSourceAction.update, sourceStatus: StreamhubSourceStatus.running, sourceError: undefined }, () => resolve(this.state))) // NB: not updating the source state until after the update (incase it fails)
      const newSource = await this.state.sourcesAPI.updateSourceFromJson(sourceId, name, videoFilename, videoInputsJson, audioFilename, audioInputsJson, overlayTitle, overlayShowTimecode, overlaysJson)
      await new Promise((resolve) => this.setState({ source: newSource, sourceAction: StreamhubSourceAction.update, sourceStatus: StreamhubSourceStatus.done, sourceError: undefined }, () => resolve(this.state)))
    } catch (error) {
      await new Promise((resolve) => this.setState({ sourceAction: StreamhubSourceAction.update, sourceStatus: StreamhubSourceStatus.error, sourceError: error }, () => resolve(this.state))) // NB: leaving the existing source state as it was before the update attempt
    }
  }

  deleteSource = async (source: InputSource) => {
    try {
      await new Promise((resolve) => this.setState({ source: source, sourceAction: StreamhubSourceAction.delete, sourceStatus: StreamhubSourceStatus.running, sourceError: undefined }, () => resolve(this.state)))
      await this.state.sourcesAPI.deleteSource(source.id)
      await new Promise((resolve) => this.setState({ source: source, sourceAction: StreamhubSourceAction.delete, sourceStatus: StreamhubSourceStatus.done, sourceError: undefined }, () => resolve(this.state)))
    } catch (error) {
      await new Promise((resolve) => this.setState({ source: source, sourceAction: StreamhubSourceAction.delete, sourceStatus: StreamhubSourceStatus.error, sourceError: error }, () => resolve(this.state)))
    }
  }

  // NB: requires fetchSources to have been called so the source can be looked up by its id (only needed to set the local source state for the result)
  deleteSourceWithId = async (sourceId: number) => {
    const source = this.state.sources?.find((source) => source.id === sourceId)
    if (!source) {
      const error = Error('Source not found')
      await new Promise((resolve) => this.setState({ source: undefined, sourceAction: StreamhubSourceAction.delete, sourceStatus: StreamhubSourceStatus.error, sourceError: error }, () => resolve(this.state)))
      return
    }
    await this.deleteSource(source)
  }

  // -------

  // TODO: this is only a helper function, it shouldn't go via the state (as it maybe called/used alot in a single render)
  // TODO: it needs access to the apiClient (to get the current url) otherwise it could be moved to within the InputSource model maybe
  // TODO: move this out of the provider (but somewhere with access to the server url), or maybe move it to the store within this provider, as it doesn't edit anything?
  // NB: returns the url direct instead of via the provider state (as it can be used for each entry in the list)
  getSourcePreviewImageURL = (source: InputSource, width?: number): string => {
    return this.state.sourcesAPI.getSourcePreviewImageURL(source, width)
  }

  // -------

  actions: IStreamhubSourcesActions = {
    // sources
    fetchSources: this.fetchSources,
    // source
    selectSource: this.selectSource,
    selectSourceWithId: this.selectSourceWithId,
    createSource: this.createSource,
    createSourceFromJson: this.createSourceFromJson,
    updateSource: this.updateSource,
    updateSourceFromJson: this.updateSourceFromJson,
    deleteSource: this.deleteSource,
    deleteSourceWithId: this.deleteSourceWithId,
    // source image
    getSourcePreviewImageURL: this.getSourcePreviewImageURL
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: IStreamhubSourcesStore = {
  //  ...
  // }

  render () {
    return (
      <StreamhubSourcesContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for IStreamhubSourcesStore */ }}
      >
        {this.props.children}
      </StreamhubSourcesContext.Provider>
    )
  }
}
