import React, { useContext } from 'react'

import { CompanyAdminContext } from 'src/core/providers'

import { CompanyGroup } from 'src/core/models'

import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { OBJECT_COMPANY_SHORTNAME, OBJECT_GROUP_NAME } from 'src/constants/strings'

export type CompanyGroupDeleteCallback = (group: CompanyGroup) => void

interface IProps {
  companyId: number
  group: CompanyGroup
  onDidDelete?: CompanyGroupDeleteCallback
  className?: string
}

const CompanyGroupDeleteView = (props: IProps) => {
  const { companyId, group, onDidDelete: _onDidDelete, className } = props

  const companyAdminContext = useContext(CompanyAdminContext)

  const onDelete = async (_itemId: number): Promise<boolean> => {
    // NB: DON'T catch the error/exception here, let it throw (or if you do, rethrow it so the calling code can catch & display it)
    const deleteResult = await companyAdminContext.actions.deleteCompanyGroup(companyId, group.id)
    console.log('CompanyGroupDeleteView - onDelete - deleteCompanyGroup - deleteResult:', deleteResult)
    return deleteResult
  }
  if (!group) return null
  return (
    <div className={(className ? ' ' + className : '')}>
      <ArkSpacer />
      <ArkManagerDeleteButton
        itemId={group.id}
        itemName={group.name}
        itemTypeName={OBJECT_COMPANY_SHORTNAME + ' ' + OBJECT_GROUP_NAME}
        buttonTitle={'DELETE ' + OBJECT_COMPANY_SHORTNAME + ' ' + OBJECT_GROUP_NAME}
        onDelete={onDelete}
        onDeleteComplete={() => { if (_onDidDelete) _onDidDelete(group) }}
        // TODO: remove the below args once we confirm org groups don't support the 'default' group flag/handling
        // onDeleteAllowed={() => { return !group.isDefaultGroup }}
        // notAllowedTitle={'Default ' + OBJECT_GROUP_NAME + ' warning'}
        // notAllowedMsg={'The default ' + OBJECT_GROUP_NAME + ' cannot be deleted'}
      />
    </div>
  )
}

export default CompanyGroupDeleteView
