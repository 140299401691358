import React from 'react'

import StreamhubPage from '../../components/StreamhubPage/StreamhubPage'
import StreamhubVideoEnginesView from './StreamhubVideoEnginesView'

class StreamhubVideoEnginesPage extends React.Component<{}, {}> {
  render () {
    return (
      <StreamhubPage>
        <StreamhubVideoEnginesView />
      </StreamhubPage>
    )
  }
}
export default StreamhubVideoEnginesPage
