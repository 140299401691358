/**
 * utilities
 */

import _ from 'lodash'

import { DEFAULT_GROUP_NAME } from '../nodeGraph/NodeGraphProvider'
import { NodeGraphData } from '../nodeGraph/types'

export const getSomeChannels = (data?: NodeGraphData) => {
  return _.some(data?.channels)
}

export const getSomeChannelsInGroups = (data?: NodeGraphData) => {
  return _.chain(data?.groups)
    .map('channels')
    .flatten()
    .some()
    .value()
}

export const getSomeChannelsInUserGroups = (data?: NodeGraphData) => {
  return _.chain(data?.groups)
    .filter(group => group.name !== DEFAULT_GROUP_NAME)
    .map('channels')
    .flatten()
    .some()
    .value()
}

export const getSomeOtherUsers = (data?: NodeGraphData) => {
  return _.size(data?.users) > 1
}

export const getSomePrograms = (data?: NodeGraphData) => {
  return _.some(data?.programs)
}

export const getSomeProgramsConnectedToUsers = (data?: NodeGraphData) => {
  return _.chain(data?.groups)
    .map(group => {
      if (!_.some(group.channels) || !_.some(group.users)) return false
      const channels = _.map(group.channels, id => _.find(data?.channels, { id }))
      return _.chain(channels)
        .map('programs')
        .flatten()
        .some()
        .value()
    })
    .some()
    .value()
}

export const getSomeProgramsInChannels = (data?: NodeGraphData) => {
  return _.chain(data?.channels)
    .map('programs')
    .flatten()
    .some()
    .value()
}

export const getSomeUserGroups = (data?: NodeGraphData) => {
  return _.chain(data?.groups)
    .filter(group => group.name !== DEFAULT_GROUP_NAME)
    .some()
    .value()
}

export const getSomeUsersInGroupsWithChannels = (data?: NodeGraphData) => {
  return _.chain(data?.groups)
    .filter(group => _.some(group.channels))
    .map('users')
    .flatten()
    .uniq()
    .some()
    .value()
}

export const getSomeUsersInUserGroups = (data?: NodeGraphData) => {
  return _.chain(data?.groups)
    .filter(group => group.name !== DEFAULT_GROUP_NAME)
    .map('users')
    .flatten()
    .some()
    .value()
}
