import React, { useContext, useEffect, useState } from 'react'

import { UserContext } from 'src/core/providers'
import { ProjectUser, UserCompanyStatus } from 'src/core/models'

import ProjectUserDeleteView, { ProjectUserDeleteCallback } from './ProjectUserDeleteView'
import ProjectUserRolePanel from './ProjectUserRolePanel'
import ProjectUserReInviteView from './ProjectUserReInviteView'
import ProjectUserAccessToggle from './ProjectUserAccessToggle'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkButton from 'src/core/components/ArkButton'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'
import ArkTruncatedText from 'src/core/components/ArkTruncatedText'

import { OBJECT_COMPANY_SHORTNAME, OBJECT_GUEST_NAME, OBJECT_PROJECT_NAME, OBJECT_USER_NAME } from 'src/constants/strings'
import { PROJECT_GUEST_EDIT_ENABLED, USER_EMAIL_TRUNCATE_LENGTH } from 'src/constants/config'

import styles from './ProjectUserSidebar.module.css'

export type ProjectUserSidebarOnChangeUser = (updatedUser: ProjectUser) => void
export type ProjectUserSidebarOnEditCallback = (user: ProjectUser) => void
export type ProjectUserSidebarOnGuestLinkCallback = (user: ProjectUser) => void

interface IProps {
  companyId: number
  projectId: number
  user?: ProjectUser
  onChange?: Function // generic callback that means the user data changed in some way & should be reloaded
  onChangeUser?: ProjectUserSidebarOnChangeUser // the user changed & the updated user object is supplied, so the calling code just needs to update its cache instead of re-query the api (used for simple changes)
  onEditUser?: ProjectUserSidebarOnEditCallback
  onDeleteUser?: ProjectUserDeleteCallback
  onGuestLinkConfigurator?: ProjectUserSidebarOnGuestLinkCallback
}

const ProjectUserSidebar = (props: IProps) => {
  const {
    companyId,
    projectId,
    user,
    onChange: _onChange,
    onChangeUser: _onChangeUser,
    onEditUser: _onEditUser,
    onDeleteUser: _onDeleteUser,
    onGuestLinkConfigurator: _onGuestLinkConfigurator
  } = props

  const { store: userStore } = useContext(UserContext)

  const [userDeleted, setUserDeleted] = useState<boolean>(false)

  // -------

  const resetView = () => {
    console.log('ProjectUserSidebar - resetView')
    if (userDeleted) setUserDeleted(false) // reset the state
  }

  // -------

  // TESTING: equivalent to the componentDidUpdate method `if (this.props.user?.id !== prevProps.user?.id) { this.resetView() }`
  useEffect(() => {
    if (user?.id) {
      resetView()
    }
  }, [user?.id])

  // -------

  const onDeleteUser = (user: ProjectUser) => {
    if (_onDeleteUser) _onDeleteUser(user)
    setUserDeleted(true)
  }

  const onEditUser = (user: ProjectUser) => {
    if (_onEditUser) _onEditUser(user)
  }

  const onGuestLinkConfigurator = (user: ProjectUser) => {
    if (_onGuestLinkConfigurator) _onGuestLinkConfigurator(user)
  }

  // -------

  if (!user) return null
  const userCompanyStatusInvited = user.companyStatus === UserCompanyStatus.invited || user.companyStatus === UserCompanyStatus.pending
  return (
    <>

      <ArkPanel bordered className={styles.sidebarHeader}>
        <ArkPanel.Header
          className={styles.sidebarTitle}
          title={user.name()}
          icon={
            <ArkAvatar
              type={user.userAvatarType()}
              name={user.name()}
              size="40"
            />}
        />
      </ArkPanel>

      {!userDeleted && (<>

        <ArkSpacer />

        <ArkPanel bordered>
          <ArkPanel.Properties>
            {/* <ArkPanel.PropertyRow title='id:' value={user.id} /> */}
            {!user.isGuest && (<ArkPanel.PropertyRow title='Email:' value={<ArkTruncatedText maxLength={USER_EMAIL_TRUNCATE_LENGTH} subject='Email'>{user.email}</ArkTruncatedText>} />)}
            {user.isGuest && (
              <>
                <ArkPanel.PropertyRow title='User Type:' value={'GUEST LINK'} />
                {user.lastName && (<ArkPanel.PropertyRow title='Description:' value={user.lastName} />)}
              </>
            )}
            {!user.isGuest && (
              <>
                <ArkPanel.PropertyRow title='Status:' value={user.companyStatusName() /* + ' (' + ProjectUser.userStatusName(user.status) + ')' */} />
                <ArkPanel.PropertyRow title={OBJECT_COMPANY_SHORTNAME + ' Role:'} value={user.companyRoleName()} />
                <ArkPanel.PropertyRow title={OBJECT_PROJECT_NAME + ' Role:'} value={user.projectRoleName()} />
              </>
            )}
            <ArkPanel.PropertyRow title={' Access:'} value={<>
              <ProjectUserAccessToggle
                companyId={companyId}
                projectId={projectId}
                user={user}
                disabled={!user.isProjectDirectUser()}
                onChange={(value: boolean) => {
                  if (value !== user.projectAccessEnabled) {
                    // update the local user ref rather than reloading to get the change
                    user.projectAccessEnabled = value
                    // trigger the parent to update its cache with the updated user object (which will cause the user prop to update & the change to propagate back down)
                    if (_onChangeUser) _onChangeUser(user)
                  }
                }}
              />
            </>} />
            {user.isGuest && user.guestInfo && (
              <>
                <ArkPanel.PropertyDivider />
                {/* NB: temp hiding the inline preview/copy guest link buttons here & forcing use of the guest link configurator (link/button further down) modal (as it has some url work-arounds, & offers some extra options we'll likely want to use like channel selection) */}
                {/* <ArkPanel.PropertyRow title={OBJECT_GUEST_NAME + ' URL:'} value={<ArkPreviewCopyView title={OBJECT_GUEST_NAME + ' URL:'} value={user.guestInfo.url} compactPopup />} /> */}
              </>
            )}
          </ArkPanel.Properties>
        </ArkPanel>

        {user.isGuest && user.guestInfo && (
          <>
            <ArkSpacer />
            <ArkButton type="button" basic color="grey" fluid={true} size={'large'} onClick={() => onGuestLinkConfigurator(user)}>{/* Advanced */}View {OBJECT_GUEST_NAME} Link</ArkButton>
          </>
        )}

        <ArkSpacer />

        {!user.isGuest && user.isProjectDirectUser() && (
          <ProjectUserRolePanel
            companyId={companyId}
            projectId={projectId}
            user={user}
            onChange={_onChange}
          />
        )}

        {userCompanyStatusInvited && (
          <>
            <ArkSpacer />
            <ProjectUserReInviteView
              companyId={companyId}
              // projectId={projectId}
              user={user}
              onResult={() => console.log('ProjectUserSidebar - render - CompanyUserReInviteView')}
            />
          </>
        )}
      </>)}

      {PROJECT_GUEST_EDIT_ENABLED && user.isGuest && userStore.user?.isSiteAdmin() && (
        // if the selected/showing user is a guest & the logged in user accessing this page/sidebar is a site-admin (god) show an edit option
        // NB: we ONLY currently allow guests to be edited by anyone other than the user themselves (which happens via the user profiles section of the site, not here)
        <ArkButton type="button" basic color="grey" fluid={true} size={'large'} onClick={() => onEditUser(user)}>Edit {OBJECT_GUEST_NAME} {OBJECT_USER_NAME}</ArkButton>
      )}

      <ArkSpacer grow />

      <ProjectUserDeleteView
        companyId={companyId}
        projectId={projectId}
        user={user}
        onDelete={onDeleteUser}
      />

    </>
  )
}

export default ProjectUserSidebar
