import React, { useContext, useEffect, useRef, useState } from 'react'

import * as ROUTES from 'src/constants/routes'
import { UserContext, CompanyAdminContext } from 'src/core/providers'
import { CompanyUser, UserCompanyStatus } from 'src/core/models'

import ArkCompanyManagerPage from 'src/manager/company/components/ArkCompanyManagerPage/ArkCompanyManagerPage'
import CompanyUserSidebar from './CompanyUserSidebar'
import CompanyUserForm from './CompanyUserForm'
import CompanyUserListItem from './CompanyUserListItem'

import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkManagerListView, { ArkManagerFilteredItem, SectionSchema } from 'src/core/components/ArkManagerListView/ArkManagerListView'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkModal from 'src/core/components/ArkModal'
import { OBJECT_COMPANY_NAME, OBJECT_USER_NAME_PLURAL, SECTION_COMPANY_NAME, SECTION_MANAGER_SUFFIX_NAME } from 'src/constants/strings'

interface IProps {}

const CompanyUsersPage = (_props: IProps) => {
  const mounted = useRef(false)

  const userContext = useContext(UserContext)
  const companyAdminContext = useContext(CompanyAdminContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [users, setUsers] = useState<Array<CompanyUser>>([])
  const [filteredUsers, setFilteredUsers] = useState<Array<ArkManagerFilteredItem<CompanyUser>> | undefined>()
  const [filter, setFilter] = useState<string | undefined>()
  const [selectedUser, setSelectedUser] = useState<CompanyUser | undefined>()
  const [showUserFormModal, setShowUserFormModal] = useState<boolean>(false)

  // -------

  const selectUser = (user?: CompanyUser) => {
    setSelectedUser(user)
  }

  const filterUsers = (filterTxt: string, _users?: Array<CompanyUser>) => {
    // console.log('CompanyUsersPage - filterUsers - filterTxt: ', filterTxt, ' users:', users, ' _users:', _users)
    if (loading) return
    const _filter = filterTxt.length > 0 ? filterTxt : undefined
    const _filteredUsers = _filter
      ? (_users ?? users).reduce<Array<ArkManagerFilteredItem<CompanyUser>>>((r, user) => {
        let nameMatch = false
        let emailMatch = false
        if (user.name().toLowerCase().includes(_filter.toLowerCase())) {
          nameMatch = true
        }
        if (!user.isGuest && user.email?.toLowerCase().includes(_filter.toLowerCase())) {
          emailMatch = true
        }
        if (nameMatch || emailMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          if (emailMatch) matchingFields.push('email')
          const _filteredUser: ArkManagerFilteredItem<CompanyUser> = {
            item: user,
            matchingFields
          }
          r.push(_filteredUser)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<CompanyUser>>)
      : undefined
    if (selectedUser && (!(_filteredUsers?.find((filteredUser) => filteredUser.item.id === selectedUser.id)))) {
      selectUser(undefined) // if a user was selected but isn't in the filtered list deselect them
    }
    setFilter(_filter)
    setFilteredUsers(_filteredUsers)
    // console.log('CompanyUsersPage - filterUsers - _filteredUsers: ', _filteredUsers)
  }

  // NB: not currently used/needed?
  // const clearFilteredUsers = () => {
  //   setFilter(undefined)
  //   setFilteredUsers(undefined)
  // }

  // -------

  const loadCompanyUsers = async () => {
    if (loading === true) return false
    const companyId = userContext.store.selectedCompany?.id
    if (companyId) {
      try {
        setLoading(true)
        const users = await companyAdminContext.actions.getCompanyUsers(companyId)
        // console.log('CompanyUsersPage - loadCompanyUsers - users: ', users)
        // TEMP: filter out guest users from this page until we have a way to know/show which project they are for
        const usersNoGuests = users?.filter(user => !user.isGuest) || []
        if (mounted.current) {
          setLoading(false)
          setUsers(usersNoGuests)
          setFilteredUsers(undefined)
          if (filter) filterUsers(filter, usersNoGuests) // re-filter if it was active (NB: pass in the users array as the state var update may not have happened yet, at least when the page is filtered & a user is deleted it seemed to happen)
          if (selectedUser) {
            const updatedUser = users?.find((user) => user.id === selectedUser.id)
            setSelectedUser(updatedUser) // re-select the user if one was previously selected, so the updated data is shown
          }
        }
      } catch (error) {
        console.error('CompanyUsersPage - loadCompanyUsers - error: ', error)
        // TODO: add an error prop & display an error message if this happens
        if (mounted.current) {
          setLoading(false)
          setUsers([])
          setFilteredUsers(undefined)
        }
      }
    }
  }

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    async function runAsync () {
      await loadCompanyUsers()
    }
    runAsync()
  }, [])

  // -------

  const renderUserFilterForm = () => {
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        filterTitle='Filter by name or email'
        filterValue={filter ?? ''}
        onFilterChange={(_filter: string) => {
          filterUsers(_filter)
        }}
      />
    )
  }

  // -------

  const renderUserTableRowContent = (user: CompanyUser, isSelected: boolean, filter?: string) => {
    return (
      <CompanyUserListItem
        active={isSelected}
        filter={filter}
        key={user.id}
        onClick={() => selectUser(user)}
        user={user}
      />
    )
  }

  // -------

  const onDeleteUser = (user: CompanyUser) => {
    console.log('CompanyUsersPage - onDeleteUser - user: ', user)
    // trigger a company users data re-load so the deleted user no longer shows
    loadCompanyUsers()
    selectUser(undefined)
  }

  const onUpdateUser = (userId: number) => {
    console.log('CompanyUsersPage - onUpdateUser - userId: ', userId)
    // trigger a company users data re-load so the updated user data is reloaded
    // NB: `loadCompanyUsers` will also trigger the selected user to be updated
    loadCompanyUsers()
  }

  // -------

  const showAddUserModal = () => {
    console.log('CompanyUsersPage - showAddUserModal')
    setShowUserFormModal(true)
  }

  // NB: not currently supporting editing of users via the user form (as thats for invites)
  // showEditUserModal = (user: CompanyUser) => {
  //   console.log('CompanyUsersPage - showEditUserModal - user: ', user.id)
  //   this.setState({ showUserFormModal: true, editUser: user })
  // }

  const hideUserModal = () => {
    setShowUserFormModal(false)
  }

  const renderAddUserForm = () => {
    const companyId = userContext.store.selectedCompany?.id
    if (!companyId) return null
    return (
      <CompanyUserForm
        companyId={companyId}
        onCancel={() => { hideUserModal() }}
        onSave={() => {
          if (selectedUser) setSelectedUser(undefined) // clear the selected user if one was previously selected
          // trigger a company users data re-load to show the newly added project
          loadCompanyUsers()
        }}
        onClose={() => { hideUserModal() }}
        insideModal={true}
      />
    )
  }

  const renderAddUserModal = () => {
    return (
      <ArkModal open={showUserFormModal} onClose={() => hideUserModal()}>
        {renderAddUserForm()}
      </ArkModal>
    )
  }

  const company = userContext.store.selectedCompany
  if (!company) return null

  // TESTING: calculate tallies to display for each section e.g: `Admin/Managers (10/14 registered | 13/14 enabled | 2 RePro Admin)`
  const userSectionKeys = ['admin', 'member', 'guest']
  type SectionTallies = { total: number, registered: number, siteAdmins: number }
  const userSectionTallies: Map<string, SectionTallies> = new Map()
  for (const userSectionKey of userSectionKeys) {
    userSectionTallies.set(userSectionKey, { total: 0, registered: 0, siteAdmins: 0 })
  }
  const _users = filteredUsers ?? users
  for (const _user of _users) {
    const user = _user instanceof CompanyUser ? _user : _user.item
    const isCompanyAdmin = user.isCompanyAdmin()
    const isGuestUser = user.isGuest
    const isAdminUser = isCompanyAdmin
    const isMember = !isAdminUser && !isGuestUser
    if (isGuestUser || isAdminUser || isMember) {
      const sectionTallies = userSectionTallies.get((isAdminUser ? 'admin' : (isGuestUser ? 'guest' : 'member')))
      if (sectionTallies) {
        sectionTallies.total++
        if (user.companyStatus === UserCompanyStatus.active) sectionTallies.registered++
        if (user.isSiteAdmin()) sectionTallies.siteAdmins++
      }
    }
  }
  const formatSectionTallies = (tallies: SectionTallies) => {
    const total = tallies.total
    return '(' +
      `${tallies.registered}/${total} registered` +
      (tallies.siteAdmins > 0 ? ` | ${tallies.siteAdmins} RePro Admin` : '') +
      ')'
  }
  const userSections: Array<SectionSchema> = [
    { key: 'admin', title: 'Admins/Managers', collapsible: true, desc: formatSectionTallies(userSectionTallies.get('admin')!) },
    { key: 'member', title: 'Members', collapsible: true, desc: formatSectionTallies(userSectionTallies.get('member')!) },
    { key: 'guest', title: 'Guest Access', collapsible: true, desc: formatSectionTallies(userSectionTallies.get('guest')!) }
  ]

  return (<ArkCompanyManagerPage
    rightSidebar={selectedUser && <CompanyUserSidebar
      company={company}
      user={selectedUser}
      onDeleteUser={onDeleteUser}
      onUpdateUser={onUpdateUser}
      onChange={async () => {
        // trigger a company users data re-load so the updated user values show
        await loadCompanyUsers()
        // trigger the sidepanel to update with the updated user object
        if (selectedUser && users) {
          const newUser = users.find((user) => user.id === selectedUser?.id)
          setSelectedUser(newUser)
        }
      }}
    />}
  >
    <ArkManagerContentView
      title={OBJECT_COMPANY_NAME + ' ' + OBJECT_USER_NAME_PLURAL}
      breadcrumb={[{
        path: ROUTES.COMPANY_MANAGER,
        title: ROUTES.formatBreadcrumbRootTitle(company.name, `${SECTION_COMPANY_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`)
      }]}
    >
      <ArkManagerListView
        loading={loading}
        items={users}
        sections={userSections}
        sectionItemCheck={(section: SectionSchema, user: CompanyUser) => {
          const isGuest = user.isGuest
          const isProjectAdminOrManager = user.isCompanyAdmin()
          switch (section.key) {
            case 'admin': return isProjectAdminOrManager
            case 'member': return (!isGuest && !isProjectAdminOrManager)
            case 'guest': return isGuest
          }
          return false
        }}
        selectedItem={selectedUser}
        itemRow={(user: CompanyUser, isSelected: boolean) => {
          return renderUserTableRowContent(user, isSelected, filter)
        }}
        // topbar={this.topBarContent}
        topbarAddItemTitle={'INVITE ' + OBJECT_USER_NAME_PLURAL}
        onAdd={() => showAddUserModal()}
        // FILTERING:
        filter={filter}
        filteredItems={filteredUsers}
        filterForm={renderUserFilterForm()}
        onClearFilter={() => filterUsers('') }
      />
      {renderAddUserModal()}
    </ArkManagerContentView>
  </ArkCompanyManagerPage>)
}
export default CompanyUsersPage
