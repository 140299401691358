// basic date/time formatting - ref: https://stackoverflow.com/a/25275808
// TOOD: consider using momentjs or luxon (newer?) for all date formatting throughout the app?
export const formatDateTime = (date: Date) => {
  if (!date) return ''
  // console.log('formatDateTime - date: ', date)
  if (date.getHours === undefined) { // work-around if the Date wasn't created with 'new', seems to happen with api supplied date refs?
    date = new Date(date)
  }
  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours > 0 ? hours : 12 // the hour '0' should be '12'
  // minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + ampm
  const month = date.getMonth() + 1
  const strMonth = month < 10 ? '0' + month : month
  return strTime + ' ' + date.getDate() + '/' + strMonth + '/' + date.getFullYear()
}

export const formatDate = (date: Date) => {
  if (!date) return ''
  // console.log('formatDateTime - date: ', date)
  if (date.getHours === undefined) { // work-around if the Date wasn't created with 'new', seems to happen with api supplied date refs?
    date = new Date(date)
  }
  const month = date.getMonth() + 1
  const strMonth = month < 10 ? '0' + month : month
  return date.getDate() + '/' + strMonth + '/' + date.getFullYear()
}

// logging specific date/time format 'yyyy-MM-dd HH:mm:ss'
export const formatLoggingDateTime = (date: Date) => {
  if (!date) return ''
  if (date.getHours === undefined) { // work-around if the Date wasn't created with 'new', seems to happen with api supplied date refs?
    date = new Date(date)
  }
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  // minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds)
  const month = date.getMonth() + 1
  const strMonth = month < 10 ? '0' + month : month
  return date.getFullYear() + '-' + strMonth + '-' + date.getDate() + ' ' + strTime
}
