import React from 'react'

import UserPhoneVerifyForm from './UserPhoneVerifyForm'

interface IProps {
  onCancel?: Function
  onBack?: Function
}

const UserPhoneVerifyView = ({ onCancel, onBack, ..._props }: IProps) => {
  return (
    <UserPhoneVerifyForm
      onCancel={onCancel}
      onBack={onBack}
    />
  )
}

export default UserPhoneVerifyView
