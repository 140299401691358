import React from 'react'

import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkPopup from 'src/core/components/ArkPopup'
import ArkVolumeInput from 'src/core/components/ArkVolumeInput'

import styles from './ProgramVolumePopup.module.css'

const DEBUG_ALWAYS_SHOW = false

interface ProgramVolumePopupProps {
  onOpenChange: (value: boolean) => void // FIXME "onOpen" & "onClose" instead
  onValueChange: (value: number) => void
  open: boolean;
  value: number
}

const ProgramVolumePopup = (props: ProgramVolumePopupProps) => {
  const {
    onOpenChange,
    onValueChange,
    open,
    value
  } = props

  const trigger =
  <ArkIconButton
    className={styles.button}
    name='volume-off'
    open={DEBUG_ALWAYS_SHOW || open}
    size={24}
    width={35}
  >
    <div className={styles.meterTrack}>
      <div className={styles.meterBar} style={{ height: `${value * 100}%` }} />
    </div>
  </ArkIconButton>

  return (
    <ArkPopup
      className={styles.popup}
      onClose={() => onOpenChange(false)}
      onOpen={() => onOpenChange(true)}
      open={DEBUG_ALWAYS_SHOW || open}
      position='top center'
      trigger={trigger}
    >
      <ArkVolumeInput onChange={onValueChange} value={value} vertical />
    </ArkPopup>
  )
}

export default ProgramVolumePopup
