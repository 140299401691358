/**
 * AdminVideoEngineListItem
 */

import React from 'react'

import { Company, VideoEngine } from 'src/core/models'

import ArkIcon from 'src/core/components/ArkIcon'
import ArkListItem, { ArkListItemDetail } from 'src/core/components/ArkListItem'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { SECTION_COMPANY_NAME } from 'src/constants/strings'

import styles from './AdminVideoEngineListItem.module.css'

interface IProps {
  active: boolean
  videoEngine: VideoEngine
  videoEngineCompany?: Company
  filter?: string
  onClick: () => void
  onEditClick?: () => void
}

const AdminVideoEngineListItem = (props: IProps) => {
  const {
    active,
    videoEngine,
    videoEngineCompany,
    filter,
    onClick,
    onEditClick
  } = props

  const rightComponentDesktop = (
    <div className={styles.details}>
      <ArkListItemDetail title={SECTION_COMPANY_NAME} className={styles.org}>
        {videoEngineCompany?.name ?? videoEngine.companyId ?? '-'}
      </ArkListItemDetail>
      <ArkListItemDetail title='Domain' className={styles.domain}>
        {videoEngine.domain}
      </ArkListItemDetail>
      <ArkSpacer />
      {/* <ArkListItemDetail title='IP' className={styles.ip}>
        {videoEngine.ip}
      </ArkListItemDetail> */}
      <ArkSpacer />
      <ArkListItemDetail title='Status' className={styles.status}>
        {videoEngine.isActive ? 'ACTIVE' : 'DISABLED'}
      </ArkListItemDetail>
    </div>
  )

  return (
    <ArkListItem
      active={active}
      filter={filter}
      leftComponent={<ArkIcon name='video-engine' size={28} />}
      onClick={onClick}
      onEditClick={onEditClick}
      rightComponentDesktop={rightComponentDesktop}
      title={videoEngine.name}
    />
  )
}

export default AdminVideoEngineListItem
