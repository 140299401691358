/**
 * ArkPanel
 */

import React from 'react'

import ArkPanelProperties, {
  ArkPanelPropertyRow,
  ArkPanelPropertyRowTitle,
  ArkPanelPropertyRowValue,
  ArkPanelPropertyRowHint,
  ArkPanelPropertyRowContent,
  ArkPanelPropertyRowMessage,
  ArkPanelPropertyDivider,
  ArkPanelPropertySpacer,
  ArkPanelPropertySection
} from './ArkPanelProperties'
import ArkPanelHeader from './ArkPanelHeader'
import ArkPanelFooter from './ArkPanelFooter'

import styles from './ArkPanel.module.css'

import { Header } from 'semantic-ui-react'

export interface ArkPanelProps {
  title?: string | React.ReactNode
  children?: React.ReactNode
  bordered?: boolean
  className?: string
}

// TESTING sub-components (dot notation) - ref: https://skovy.dev/using-component-dot-notation-with-typescript-to-create-a-set-of-components/
interface ArkPanelSubComponents {
  Properties: typeof ArkPanelProperties,
  PropertyRow: typeof ArkPanelPropertyRow
  PropertyRowTitle: typeof ArkPanelPropertyRowTitle
  PropertyRowValue: typeof ArkPanelPropertyRowValue
  PropertyRowHint: typeof ArkPanelPropertyRowHint
  PropertyRowContent: typeof ArkPanelPropertyRowContent
  PropertyRowMessage: typeof ArkPanelPropertyRowMessage
  PropertyDivider: typeof ArkPanelPropertyDivider
  PropertySpacer: typeof ArkPanelPropertySpacer
  PropertySection: typeof ArkPanelPropertySection
  Header: typeof ArkPanelHeader
  Footer: typeof ArkPanelFooter
}

const ArkPanel: React.FC<ArkPanelProps> & ArkPanelSubComponents = (props: ArkPanelProps) => {
  const { title, children, bordered, className } = props
  return (
    <div className={styles.panel + (bordered ? ' ' + styles.panelBorder : '') + (className ? ' ' + className : '')}>
      {title && typeof title === 'string' && (<Header as='h3' inverted className={styles.panelTitle}>{title}</Header>)}
      {title && React.isValidElement(title) && (title)}
      {children}
    </div>
  )
}

ArkPanel.Properties = ArkPanelProperties
ArkPanel.PropertyRow = ArkPanelPropertyRow
ArkPanel.PropertyRowTitle = ArkPanelPropertyRowTitle
ArkPanel.PropertyRowValue = ArkPanelPropertyRowValue
ArkPanel.PropertyRowHint = ArkPanelPropertyRowHint
ArkPanel.PropertyRowContent = ArkPanelPropertyRowContent
ArkPanel.PropertyRowMessage = ArkPanelPropertyRowMessage
ArkPanel.PropertyDivider = ArkPanelPropertyDivider
ArkPanel.PropertySpacer = ArkPanelPropertySpacer
ArkPanel.PropertySection = ArkPanelPropertySection
ArkPanel.Header = ArkPanelHeader
ArkPanel.Footer = ArkPanelFooter

export default ArkPanel
