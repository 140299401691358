/**
 * ArkInfoView
 */

import React from 'react'

import ArkIcon, { IconName } from '../ArkIcon'
import ArkSpacer from '../ArkSpacer'

import styles from './ArkInfoView.module.css'

interface ArkInfoViewProps {
  iconName?: IconName
  title: string
  message?: string | React.ReactNode | JSX.Element
  buttons?: JSX.Element
  className?: string
  compact?: boolean
  titleClassName?: string
  messageClassName?: string
}

const ArkInfoView = (props: ArkInfoViewProps) => {
  const { iconName, message, title, className, compact, titleClassName, messageClassName } = props

  return (
    <div className={`${styles.view} ${className || ''}`}>
      {iconName && (
        <>
          <ArkIcon color='var(--tx-light-mid)' name={iconName} size={!compact ? 84 : undefined} />
          {!compact && <ArkSpacer size={40} />}
        </>
      )}
      {!compact && (
        <>
          <div className={styles.title + (titleClassName ? ' ' + titleClassName : '')}>{title}</div>
          {message && (
            <>
              <ArkSpacer size={30} />
              <div className={`${styles.message} ${messageClassName || ''}`}>{message}</div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ArkInfoView
