import React from 'react'

import { OBJECT_CARD_NAME, OBJECT_NOTICE_NAME } from 'src/constants/strings'
import ArkButton from 'src/core/components/ArkButton'
import ArkCard from 'src/core/components/ArkCard'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { Channel, Notice } from 'src/core/models'

import styles from './NoticeSidebar.module.css'
import { getNoticeChannelsText, getNoticeConditionsText, getNoticeDismissibleText } from './utilities'

interface NoticeSidebarProps {
  channels: Channel[]
  notice: Notice
  onDeleteClick: () => void
  onDuplicateClick: () => void
  onUpdateClick: () => void
}

const NoticeSidebar = (props: NoticeSidebarProps) => {
  // console.log('NoticeSidebar - notice:', props.notice)

  return (
    <>
      <ArkPanel bordered className={styles.topPanel}>
        <ArkPanel.Header
          className={styles.topPanelHeader}
          description={props.notice.description}
          iconName='notice'
          title={props.notice.name}
        />
        <ArkIconButton
          name='settings'
          onClick={props.onUpdateClick}
          size={24}
        />
      </ArkPanel>
      <ArkSpacer />
      {props.notice.card && (
        <>
          <ArkCard card={props.notice.card} rounded />
          <ArkSpacer />
        </>
      )}
      <ArkPanel bordered>
        <ArkPanel.Properties>
          <ArkPanel.PropertyRow
            title='ID:'
            value={props.notice.id}
          />
          <ArkPanel.PropertyRow
            title='Card:'
            value={props.notice.card?.name || `No ${OBJECT_CARD_NAME.toLowerCase()}`}
          />
          <ArkPanel.PropertyRow
            title='Channels:'
            value={getNoticeChannelsText(props.notice, props.channels)}
          />
          <ArkPanel.PropertyRow
            title='Enabled:'
            value={props.notice.enabled ? 'Yes' : 'No'}
          />
          <ArkPanel.PropertyRow
            title='Dismissible:'
            value={getNoticeDismissibleText(props.notice)}
          />
          <ArkPanel.PropertyRow
            title='Priority:'
            value={props.notice.priority}
          />
          <ArkPanel.PropertyRow
            title='Conditions:'
            value={getNoticeConditionsText(props.notice)}
          />
        </ArkPanel.Properties>
      </ArkPanel>
      <ArkSpacer grow />
      <ArkButton
        fluid
        onClick={props.onDuplicateClick}
        size='large'
      >
        {`Duplicate ${OBJECT_NOTICE_NAME}`}
      </ArkButton>
      <ArkSpacer />
      <ArkButton
        basic
        fluid
        negative
        onClick={props.onDeleteClick}
        size='large'
      >
        {`Delete ${OBJECT_NOTICE_NAME}`}
      </ArkButton>
    </>
  )
}

export default NoticeSidebar
