import React, { ReactNode } from 'react'

import styles from './ArkFormText.module.css'

export interface ArkFormTextProps {
  alignRight?: boolean
  children: ReactNode
}

const ArkFormText = (props: ArkFormTextProps) => (
  <div className={`${styles.text} ${props.alignRight && styles.right}`}>
    {props.children}
  </div>
)

export default ArkFormText
