import React, { useContext, useEffect, useRef, useState } from 'react'

import { FFMpegProcess } from '../../models/StreamhubModels'
import { StreamhubProcessesContext, StreamhubProcessDataStatus } from '../../providers/StreamhubProcessesProvider'
import { StreamhubSourcesContext } from '../../providers/StreamhubSourcesProvider'

import ArkButton from 'src/core/components/ArkButton'
import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'

import { Header, Message, Table, Image } from 'semantic-ui-react'

import styles from '../Streamhub.module.css'

interface IProps {
  onDataChanged?: Function
}

const StreamhubProcessesView = (props: IProps) => {
  const { onDataChanged: _onDataChanged } = props

  const mounted = useRef(false)

  const processesContext = useContext(StreamhubProcessesContext)
  const sourcesContext = useContext(StreamhubSourcesContext)

  const [loading, setLoading] = useState<boolean>(false)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const loadData = async () => {
    setLoading(true)
    await processesContext.actions.fetchProcesses()
    await sourcesContext.actions.fetchSources() // also load sources data, so we can show source preview images
    setLoading(false)
  }

  useEffect(() => {
    async function loadAsync () {
      await loadData()
    }
    if (!loading) {
      loadAsync()
    }
  }, [])

  // -------

  const onDataChanged = () => {
    loadData()
    if (_onDataChanged) _onDataChanged()
  }

  // -------

  // ArkManagerDeleteButton onDelete callback, return true on success, or throw an error to have it displayed by the delete modal
  const onKillProcess = async (processId: number) => {
    try {
      const stopResult = await processesContext.actions.killProcess(processId)
      if (stopResult) {
        // TODO: reload relevant data...
        return true
      }
    } catch (error) {
      console.error('StreamhubProcessesView - onKillProcess - error: ', error)
      throw error // NB: re-throwing it so the calling `ArkManagerDeleteButton` handler can catch & show it in the confirm popup
    }
    return false
  }

  // ArkManagerDeleteButton onDeleteComplete callback, called once the success result has been dismissed by the user
  const onKillProcessComplete = () => {
    onDataChanged()
  }

  // -------

  const onKillAllProcesses = async () => {
    try {
      const stopResult = await processesContext.actions.killAllProcesses()
      if (stopResult) {
        // TODO: reload relevant data...
        return true
      }
    } catch (error) {
      console.error('StreamhubProcessesView - onKillAllProcesses - error: ', error)
      throw error // NB: re-throwing it so the calling `ArkManagerDeleteButton` handler can catch & show it in the confirm popup
    }
    return false
  }

  // ArkManagerDeleteButton onDeleteComplete callback, called once the success result has been dismissed by the user
  const onKillAllProcessesComplete = () => {
    onDataChanged()
  }

  // -------

  const renderFFMpegProcessesTableRows = (processes: Array<FFMpegProcess>) => {
    return processes.map((process: FFMpegProcess) => {
      const commandData = process.getOuputData().commandData
      const streamId = commandData?.streamId
      const sourceId = commandData?.sourceId
      const programId = commandData?.programId
      const streamSource = sourcesContext.store.sources?.find((streamSource) => streamSource.id === sourceId)
      // console.log('StreamhubProcessesView - renderFFMpegProcessesTableRows - process:', process, ' commandData:', commandData, ' streamId:', streamId, ' sourceId:', sourceId, ' programId:', programId, ' streamSource:', streamSource)
      return (
        <Table.Row key={'process_' + process.pid}>
          <Table.Cell className={styles.streamIdAndImg}>
            <>
              <div className={styles.idRow + ' ' + styles.idStream}>Stream ID: {streamId ?? 'N/A'}</div>
              {streamSource && (<Image src={sourcesContext.actions.getSourcePreviewImageURL(streamSource, 100)} />)}
              <div className={styles.idRow + ' ' + styles.idSource}>Source: {sourceId ?? 'N/A'}</div>
            </>
          </Table.Cell>
          <Table.Cell className={styles.pid}>{process.pid}</Table.Cell>
          <Table.Cell className={styles.cpu}>{process.cpu ?? '-'}</Table.Cell>
          <Table.Cell className={styles.cmd}>
            <div>
              {/* <Header as='h4' inverted>Process {process.pid}</Header> */}
              {/* <pre>{JSON.stringify(commandData, null, 4)}</pre> */}
              {(sourceId || streamId || programId) && (
                <div className={styles.ids}>
                  <span className={styles.processTitle}>StreamId:</span><span className={styles.processValue}>{streamId ?? 'N/A'}</span>
                  <span className={styles.processTitle}>SourceId:</span><span className={styles.processValue}>{sourceId ?? 'N/A'}</span>
                  <span className={styles.processTitle}>ProgramId:</span><span className={styles.processValue}>{programId ?? 'N/A'}</span>
                </div>
              )}
              {Object.keys(commandData).map((key) => {
                // console.log('StreamhubProcessesView - renderFFMpegProcessesTableRows - key:', key, ' commandData:', commandData)
                if (['sourceId', 'streamId', 'programId'].includes(key)) return null
                const commandDataField = (commandData as any)[key]
                return (
                  <div key={'process_' + process.pid + '_' + key}>
                    <span className={styles.processTitle}>{key}:</span>
                    <span className={styles.processValue}>{commandDataField?.toString() ?? '-'}</span>
                  </div>
                )
              })}
              <div className={styles.processCmd}>
                <details>
                  <summary><span className={styles.processTitle}>full cmd:</span></summary>
                  <span className={styles.processCmdValue}>{process.command}</span>
                </details>
              </div>
            </div>
          </Table.Cell>
          <Table.Cell className={styles.actions}>
            <ArkManagerDeleteButton
              className={styles.buttonLeftPad}
              itemId={process.pid}
              itemName={'Process ' + process.pid}
              itemTypeName='Process'
              buttonTitle='X'
              deleteWord='kill'
              deletedWord='killed'
              onDelete={onKillProcess}
              onDeleteComplete={onKillProcessComplete}
              fluid={false}
              size='mini'
              // disabled={stream.isActive}
              style={{ display: 'inline' }}
              buttonStyle={{ fontSize: '12px', padding: '8px 10px', marginLeft: 6 }}
            />
          </Table.Cell>
        </Table.Row>
      )
    })
  }

  const renderFFMpegProcessesTable = () => {
    const loading = processesContext.store.processesStatus === StreamhubProcessDataStatus.loading
    if (loading) return <ArkLoaderView message='Loading' />
    if (processesContext.store.processesError) {
      return (
        <Message negative>
          <Message.Header>Error</Message.Header>
          <p>{processesContext.store.processesError.message ?? ''}</p>
        </Message>
      )
    }
    if (!processesContext.store.processes || processesContext.store.processes.length === 0) {
      return (
        <Message warning>
          <Message.Header>No Active Processes</Message.Header>
        </Message>
      )
    }
    return (
      <>
        <Table celled inverted className={styles.table}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>PID</Table.HeaderCell>
              <Table.HeaderCell>CPU</Table.HeaderCell>
              <Table.HeaderCell>CMD Data</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {renderFFMpegProcessesTableRows(processesContext.store.processes)}
          </Table.Body>
        </Table>
      </>
    )
  }

  const renderProcessessSummary = (processes?: Array<FFMpegProcess>) => {
    const processessCount = processes?.length ?? 0
    return (
      <>
        Showing: {processessCount} processes{processessCount !== 1 ? 's' : ''}
      </>
    )
  }

  // -------

  return (
    <div className={styles.processes}>
      <div className={styles.contentHeader}>
        <div className={styles.contentHeaderMain}>
          <Header as='h2' inverted>Running FFMpeg Processes</Header>
          <div className={styles.listSummary}>
            {renderProcessessSummary(processesContext.store.processes)}
          </div>
          <div className={styles.actions}>
            <ArkButton color='blue' size='mini' className={styles.refreshBtn} onClick={async () => { onDataChanged() }}>REFRESH</ArkButton>
          </div>
        </div>
      </div>
      {renderFFMpegProcessesTable()}
      {processesContext.store.processes && processesContext.store.processes.length > 0 && (
        <>
          {/* <ArkButton inverted onClick={() => { this.killAllProcesses() }}>KILL ALL PROCESSES</ArkButton> */}
          <ArkManagerDeleteButton
            className={styles.buttonLeftPad}
            itemId={0} // process.pid
            itemName={'PROCESSES'}
            itemTypeName='ALL'
            buttonTitle='KILL ALL PROCESSES'
            deleteWord='kill'
            deletedWord='killed'
            onDelete={onKillAllProcesses}
            onDeleteComplete={onKillAllProcessesComplete}
            fluid={false}
            size='mini'
            // disabled={stream.isActive}
            style={{ display: 'inline' }}
            buttonStyle={{ fontSize: '12px', padding: '8px 10px', marginLeft: 6 }}
          />
        </>
      )}
    </div>
  )
}

export default StreamhubProcessesView
