/**
 * ArkListItemDetail
 */

import React, { ReactNode } from 'react'

import ArkHint from '../ArkHint'
import ArkSpacer from '../ArkSpacer'

import styles from './ArkListItemDetail.module.css'

export interface ArkListItemDetailProps {
  className?: string
  children: ReactNode
  hintMessage?: string
  hintTitle?: string
  horizontal?: boolean
  title?: string
}

const ArkListItemDetail = (props: ArkListItemDetailProps) => {
  const {
    className,
    children,
    hintMessage,
    hintTitle,
    horizontal,
    title
  } = props

  return (
    <div className={`${styles.container} ${horizontal ? styles.horizontal : ''} ${className ?? ''}`}>
      {title && (
        <>
          <div className={styles.title}>
            {title}
            { (hintTitle || hintMessage) && (
              <>
                <ArkSpacer small />
                <ArkHint
                  iconSize={16}
                  message={hintMessage}
                  popupPosition='bottom left'
                  popupSize='small'
                  title={hintTitle}
                  type='info-circle'
                />
              </>
            )}
          </div>
          <ArkSpacer small />
        </>
      )}
      {children}
    </div>
  )
}

export default ArkListItemDetail
