import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import _ from 'lodash'

import { CARDS_MOCK_API_ENABLED } from 'src/constants/config'
import {
  OBJECT_CARD_NAME,
  OBJECT_COMPANY_SHORTNAME,
  OBJECT_NOTICE_NAME,
  OBJECT_PROJECT_NAME
} from 'src/constants/strings'

import { Card, DEFAULT_COMPANY_CARD, UserCompany } from '../models'
import ServerAPIClient from '../services/ServerAPIClient'
import { MockAPIContextValue, useMockAPI } from './MockAPIProvider'
import { IServerContext, useServer } from './ServerProvider'
import { IUserContext, useUser } from './UserProvider'

/**
 * similar to <ProjectCardProvider>. changes made here should be considered there
 *
 * interactive functions include dialog boxes (e.g. 'Are you sure you want to delete "Card A"?')
 */

export type CompanyCardContextValue = {
  clearCards: () => void,
  createCard: (props: Partial<Card>) => Promise<Card | undefined>
  createCardInteractive: (props: Partial<Card>) => Promise<Card | undefined>
  deleteCard: (cardId: number) => Promise<boolean>
  deleteCardInteractive: (cardId: number) => Promise<boolean>
  duplicateCard: (cardId: number) => Promise<Card | undefined>
  duplicateCardInteractive: (cardId: number) => Promise<Card | undefined>
  fetchCards: () => Promise<Card[] | undefined>
  fetchCardsInteractive: () => Promise<Card[] | undefined>
  getCards: () => Card[]
  getFetching: () => boolean
  updateCard: (cardId: number, props: Partial<Card>) => Promise<Card | undefined>
  updateCardInteractive: (cardId: number, props: Partial<Card>) => Promise<Card | undefined>
}

const CompanyCardContext = createContext<CompanyCardContextValue>({} as CompanyCardContextValue)

export const useCompanyCard = () => useContext(CompanyCardContext)

type CompanyCardProviderProps = {
  children: ReactNode
}

const CompanyCardProvider = (props: CompanyCardProviderProps) => {
  const mockAPI: MockAPIContextValue = useMockAPI()
  const server: IServerContext = useServer()
  const user: IUserContext = useUser()

  const company: UserCompany = user.store.selectedCompany!
  const serverAPIClient: ServerAPIClient = server.store.apiClient!

  const [cards, setCards] = useState<Card[]>([])
  const [fetching, setFetching] = useState<boolean>(true)

  useEffect(() => {
    // console.log('CompanyCardProvider - load')
    return () => {
      // console.log('CompanyCardProvider - unload')
    }
  }, [])

  const clearCards = (): void => {
    // console.log('CompanyCardProvider - clearCards')
    setCards([])
  }

  const createCard = async (props: Partial<Card>): Promise<Card | undefined> => {
    try {
      console.log('CompanyCardProvider - createCard - props:', props)
      let card: Card
      if (CARDS_MOCK_API_ENABLED) {
        card = { ...DEFAULT_COMPANY_CARD, ...props, id: Date.now() }
        const oldMockCards: Card[] = await mockAPI.getCards()
        const newMockCards: Card[] = [...oldMockCards, card]
        await mockAPI.setCards(newMockCards)
      } else {
        const keys: (keyof Card)[] = ['colour']
        const newProps: Partial<Card> = { ..._.pick(DEFAULT_COMPANY_CARD, keys), ...props }
        // FIXME add response type
        const response = await serverAPIClient.apiPost('/company/card/', newProps, { 'company-id': company.id })
        console.log('CompanyCardProvider - createCard - response:', response)
        // FIXME check response status
        // FIXME add type guard
        card = response.data.message
      }
      const newCards: Card[] = [...cards, card]
      setCards(newCards)
      await user.actions.refreshChannels() // refresh viewer
      return card
    } catch (error) {
      console.error('CompanyCardProvider - createCard - error:', error.message)
    }
  }

  const createCardInteractive = async (props: Partial<Card>): Promise<Card | undefined> => {
    try {
      // console.log('CompanyCardProvider - createCardInteractive - props:', props)
      const card: Card | undefined = await createCard(props)
      if (!card) throw Error('create card failed')
      // window.alert(`"${card.name}" has been created successfully.`) // FIXME add toast
      return card
    } catch (error) {
      console.error('CompanyCardProvider - createCardInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  const deleteCard = async (cardId: number): Promise<boolean> => {
    try {
      // console.log('CompanyCardProvider - deleteCard - cardId:', cardId)
      const card: Card = _.find(cards, { id: cardId })!
      if (CARDS_MOCK_API_ENABLED) {
        const oldMockCards: Card[] = await mockAPI.getCards()
        const newMockCards: Card[] = _.without(oldMockCards, card)
        await mockAPI.setCards(newMockCards)
      } else {
        // FIXME add response type
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await serverAPIClient.apiDelete(`/company/card/${cardId}`, {}, { 'company-id': company.id })
        // console.log('CompanyCardProvider - deleteCard - response:', response)
        // FIXME check response status
      }
      const newCards: Card[] = _.without(cards, card)
      setCards(newCards)
      await user.actions.refreshChannels() // refresh viewer
      return true
    } catch (error) {
      console.error('CompanyCardProvider - deleteCard - error:', error.message)
      return false
    }
  }

  const deleteCardInteractive = async (cardId: number): Promise<boolean> => {
    try {
      // console.log('CompanyCardProvider - deleteCardInteractive - cardId:', cardId)
      const card: Card = _.find(cards, { id: cardId })!
      const message: string = _.some(card.notices)
        ? `WARNING: "${card.name}" is used by ${_.size(card.notices)} ${OBJECT_NOTICE_NAME}(s). Are you sure you want to delete it?`
        : `Are you sure you want to delete "${card.name}"?`
      if (!window.confirm(message)) return false // FIXME add custom dialog box
      const result: boolean = await deleteCard(cardId)
      if (!result) throw Error('delete card failed')
      // window.alert(`"${card.name}" has been deleted successfully.`) // FIXME add toast
      return true
    } catch (error) {
      console.error('CompanyCardProvider - deleteCardInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
      return false
    }
  }

  const duplicateCard = async (cardId: number): Promise<Card | undefined> => {
    try {
      // console.log('CompanyCardProvider - duplicateCard - cardId:', cardId)
      const oldCard: Card | undefined = _.find(cards, { id: cardId })!
      const keys: (keyof Card)[] = [
        'background_opacity',
        'background_size',
        'colour',
        'colour_scheme',
        'description',
        'footer',
        'message',
        'permission_all_projects',
        'permission_specific_projects',
        'title'
      ]
      const props: Partial<Card> = {
        ..._.pick(oldCard, keys),
        ...(oldCard.background && { background_id: oldCard.background.id }),
        name: oldCard.entity_type === 'PROJECT'
          ? `${oldCard.name} ${OBJECT_COMPANY_SHORTNAME} copy`
          : `${oldCard.name} copy`
      }
      const newCard: Card | undefined = await createCard(props)
      if (!newCard) throw Error('create card failed')
      return newCard
    } catch (error) {
      console.error('CompanyCardProvider - duplicateCard - error:', error.message)
    }
  }

  const duplicateCardInteractive = async (cardId: number): Promise<Card | undefined> => {
    try {
      // console.log('CompanyCardProvider - duplicateCardInteractive - cardId:', cardId)
      const oldCard: Card | undefined = _.find(cards, { id: cardId })!
      const message: string = oldCard.entity_type === 'PROJECT'
        ? `"${oldCard.name}" is an ${OBJECT_PROJECT_NAME} ${OBJECT_CARD_NAME}. Are you sure you want to duplicate it? This will make a ${OBJECT_COMPANY_SHORTNAME} copy.`
        : `Are you sure you want to duplicate "${oldCard.name}"?`
      if (!window.confirm(message)) return // FIXME add custom dialog box
      const newCard: Card | undefined = await duplicateCard(cardId)
      if (!newCard) throw Error('duplicate card failed')
      // window.alert(`"${newCard.name}" has been created successfully.`) // FIXME add toast
      return newCard
    } catch (error) {
      console.error('CompanyCardProvider - duplicateCardInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  // fetches company cards & project cards
  const fetchCards = async (): Promise<Card[] | undefined> => {
    try {
      // console.log('CompanyCardProvider - fetchCards')
      setCards([])
      setFetching(true)
      let newCards: Card[]
      if (CARDS_MOCK_API_ENABLED) {
        newCards = await mockAPI.getCards()
      } else {
        // FIXME add response type
        const response = await serverAPIClient.apiGet('/company/card', { 'company-id': company.id })
        // console.log('CompanyCardProvider - fetchCards - response:', response)
        // FIXME check response status
        // FIXME add type guard
        newCards = response.data.message
      }
      setCards(newCards)
      setFetching(false)
      return newCards
    } catch (error) {
      console.error('CompanyCardProvider - fetchCards - error:', error.message)
    }
  }

  const fetchCardsInteractive = async (): Promise<Card[] | undefined> => {
    try {
      // console.log('CompanyCardProvider - fetchCardsInteractive')
      const newCards: Card[] | undefined = await fetchCards()
      if (!newCards) throw Error('fetch cards failed')
      return newCards
    } catch (error) {
      console.error('CompanyCardProvider - fetchCardsInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  const getCards = (): Card[] => {
    // console.log('CompanyCardProvider - getCards')
    return _.orderBy(cards, ['entity_type', 'name'])
  }

  const getFetching = (): boolean => {
    // console.log('CompanyCardProvider - getFetching')
    return fetching
  }

  const updateCard = async (cardId: number, props: Partial<Card>): Promise<Card | undefined> => {
    try {
      // console.log('CompanyCardProvider - updateCard - cardId:', cardId, 'props:', props)
      const oldCard: Card = _.find(cards, { id: cardId })!
      let newCard: Card
      if (CARDS_MOCK_API_ENABLED) {
        newCard = { ...oldCard, ...props }
        const oldMockCards: Card[] = await mockAPI.getCards()
        const newMockCards: Card[] = _.map(oldMockCards, card => card.id === oldCard.id ? newCard : card)
        await mockAPI.setCards(newMockCards)
      } else {
        // FIXME add response type
        const response = await serverAPIClient.apiPut(`/company/card/${cardId}`, props, { 'company-id': company.id })
        // console.log('CompanyCardProvider - updateCard - response:', response)
        // FIXME check response status
        // FIXME add type guard
        newCard = response.data.message
      }
      const newCards: Card[] = _.map(cards, card => card.id === oldCard.id ? newCard : card)
      setCards(newCards)
      await user.actions.refreshChannels() // refresh viewer
      return newCard
    } catch (error) {
      console.error('CompanyCardProvider - updateCard - error:', error.message)
    }
  }

  const updateCardInteractive = async (cardId: number, props: Partial<Card>): Promise<Card | undefined> => {
    try {
      // console.log('CompanyCardProvider - updateCardInteractive - cardId:', cardId, 'props:', props)
      const oldCard: Card | undefined = _.find(cards, { id: cardId })!
      if (
        _.some(oldCard.notices) &&
        !window.confirm(`WARNING: "${oldCard.name}" is used by ${_.size(oldCard.notices)} ${OBJECT_NOTICE_NAME}(s). Are you sure you want to edit it?`) // FIXME add custom dialog box
      ) return
      const newCard: Card | undefined = await updateCard(cardId, props)
      if (!newCard) throw Error('update card failed')
      // window.alert(`"${newCard.name}" has been updated successfully.`) // FIXME add toast
      return newCard
    } catch (error) {
      console.error('CompanyCardProvider - updateCardInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  return (
    <CompanyCardContext.Provider value={{
      clearCards,
      createCard,
      createCardInteractive,
      deleteCard,
      deleteCardInteractive,
      duplicateCard,
      duplicateCardInteractive,
      fetchCards,
      fetchCardsInteractive,
      getCards,
      getFetching,
      updateCard,
      updateCardInteractive
    }}>
      {props.children}
    </CompanyCardContext.Provider>
  )
}

export default CompanyCardProvider
