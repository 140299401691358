import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import * as ROUTES from 'src/constants/routes'
import {
  OBJECT_CARD_NAME,
  OBJECT_CARD_NAME_TITLE,
  OBJECT_PROJECT_NAME,
  SECTION_MANAGER_SUFFIX_NAME
} from 'src/constants/strings'
import ArkHighlightedText from 'src/core/components/ArkHighlightedText'
import ArkIcon from 'src/core/components/ArkIcon'
import ArkListItem, { ArkListItemDetail } from 'src/core/components/ArkListItem'
import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkManagerListView from 'src/core/components/ArkManagerListView/ArkManagerListView'
import { Card, Project } from 'src/core/models'
import ProjectCardProvider, { ProjectCardContextValue, useProjectCard } from 'src/core/providers/ProjectCardProvider'
import CompanyImageProvider from 'src/core/providers/CompanyImageProvider'
import { IUserContext, useUser } from 'src/core/providers/UserProvider'
import CardCreateUpdateModal from 'src/manager/common/cards/CardCreateUpdateModal'
import CardEditorModal from 'src/manager/common/cards/CardEditorModal'
import CardSidebar from 'src/manager/common/cards/CardSidebar'
import { getCardTypeText, getFilteredCards } from 'src/manager/common/cards/utilities'

import ArkProjectManagerPage from '../../components/ArkProjectManagerPage/ArkProjectManagerPage'

/**
 * similar to <CompanyCardsPage>. changes made here should be considered there
 */

const ProjectCardsPage = () => {
  const projectCard: ProjectCardContextValue = useProjectCard()
  const user: IUserContext = useUser()

  const [filter, setFilter] = useState<string>('')
  const [selectedCardId, setSelectedCardId] = useState<number>()
  const [showCreateUpdateModal, setShowCreateUpdateModal] = useState<boolean>(false)
  const [showEditorModal, setShowEditorModal] = useState<boolean>(false)

  const cards: Card[] = projectCard.getCards()
  const project: Project = user.store.selectedProject!
  const selectedCard: Card | undefined = _.find(cards, { id: selectedCardId })

  useEffect(() => {
    // console.log('ProjectCardsPage - load')
    (async (): Promise<void> => {
      await projectCard.fetchCardsInteractive()
      // console.log('ProjectCardsPage - loaded')
    })()
    return () => {
      // console.log('ProjectCardsPage - unload')
      projectCard.clearCards()
    }
  }, [])

  const onCreateUpdateSubmit = async (props: Partial<Card>) => {
    // console.log('ProjectCardsPage - onCreateUpdateSubmit - props:', props)

    // create
    if (!selectedCard) {
      const card: Card | undefined = await projectCard.createCardInteractive(props)
      if (!card) return
      setShowCreateUpdateModal(false)
      setSelectedCardId(card.id)
      setShowEditorModal(true)
      return
    }

    // update
    if (!await projectCard.updateCardInteractive(selectedCard.id, props)) return
    setShowCreateUpdateModal(false)
  }

  const onDeleteClick = async () => {
    // console.log('ProjectCardsPage - onDeleteClick')
    if (!await projectCard.deleteCardInteractive(selectedCard!.id)) return
    setSelectedCardId(undefined)
  }

  const onDuplicateClick = async () => {
    // console.log('ProjectCardsPage - onDuplicateClick')
    const card: Card | undefined = await projectCard.duplicateCardInteractive(selectedCard!.id)
    if (!card) return
    setSelectedCardId(card.id)
  }

  const onEditorSubmit = async (props: Partial<Card>) => {
    // console.log('ProjectCardsPage - onEditorSubmit - props:', props)
    if (!await projectCard.updateCardInteractive(selectedCard!.id, props)) return
    setShowEditorModal(false)
  }

  const rightSidebarComponent: JSX.Element | undefined = selectedCard && (
    <CardSidebar
      card={selectedCard}
      onDeleteClick={onDeleteClick}
      onDuplicateClick={onDuplicateClick}
      onEditorClick={() => setShowEditorModal(true)}
      onUpdateClick={() => setShowCreateUpdateModal(true)}
      section='project'
    />
  )

  const listItem = (card: Card) => (
    <ArkListItem
      active={card.id === selectedCardId}
      description={card.description}
      filter={filter}
      key={card.id}
      leftComponent={<ArkIcon name='card' />}
      onClick={() => setSelectedCardId(card.id)}
      rightComponent={
        <ArkListItemDetail title='Type'>
          <ArkHighlightedText highlight={filter}>{getCardTypeText(card).toUpperCase()}</ArkHighlightedText>
        </ArkListItemDetail>
      }
      title={card.name}
    />
  )

  return (
    <>
      <ArkProjectManagerPage
        onRightSidebarClose={() => setSelectedCardId(undefined)}
        rightSidebar={rightSidebarComponent}
      >
        <ArkManagerContentView
          breadcrumb={[{
            path: ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_VIEW, project.id),
            title: ROUTES.formatBreadcrumbRootTitle(project.name, `${OBJECT_PROJECT_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`)
          }]}
          title={OBJECT_CARD_NAME_TITLE}
        >
          <ArkManagerListView
            filter={filter || undefined}
            filteredItems={getFilteredCards(cards, filter)}
            filterForm={(
              <ArkManagerFilterForm
                filterTitle='Filter by name, description or type'
                filterValue={filter}
                onFilterChange={setFilter}
              />
            )}
            itemRow={listItem}
            items={cards}
            loading={projectCard.getFetching()}
            onAdd={() => {
              setSelectedCardId(undefined)
              setShowCreateUpdateModal(true)
            }}
            onClearFilter={() => setFilter('')}
            selectedItem={selectedCard}
            topbarAddItemTitle={`CREATE ${OBJECT_CARD_NAME}`}
          />
        </ArkManagerContentView>
      </ArkProjectManagerPage>
      <CardCreateUpdateModal
        card={selectedCard}
        onClose={() => setShowCreateUpdateModal(false)}
        onSubmit={onCreateUpdateSubmit}
        open={showCreateUpdateModal}
        section='project'
      />
      {selectedCard && (
        <CardEditorModal
          card={selectedCard}
          open={showEditorModal}
          onClose={() => setShowEditorModal(false)}
          onSubmit={onEditorSubmit}
        />
      )}
    </>
  )
}

const Providers = () => (
  <CompanyImageProvider>
    <ProjectCardProvider>
      <ProjectCardsPage />
    </ProjectCardProvider>
  </CompanyImageProvider>
)

export default Providers
