import React from 'react'
import { OBJECT_COMPANY_NAME, OBJECT_PROJECT_NAME_PLURAL } from 'src/constants/strings'

import ArkInfoView from '../../../core/components/ArkInfoView'
import ArkViewerPage from '../../components/ArkViewerPage/ArkViewerPage'

class ViewerNoProjectsPage extends React.Component {
  render () {
    return (
      <ArkViewerPage disableSectionMainMenu>
        <ArkInfoView
          iconName='warning'
          title={'No available ' + OBJECT_PROJECT_NAME_PLURAL}
          message={<div>
            <p>You don&apos;t currently have access to view any {OBJECT_PROJECT_NAME_PLURAL} for this {OBJECT_COMPANY_NAME}.</p>
            <p>Please contact the person who invited you to gain access.</p>
          </div>}
        />
      </ArkViewerPage>)
  }
}
export default ViewerNoProjectsPage
