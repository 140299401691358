/**
 * ArkCompanyDropdownRenderer
 */

import React, { useContext } from 'react'

import { withUserContext } from '../../../core/providers'
import { UserContext } from '../../providers/UserProvider'
import ArkCompanyDropdown from '../ArkCompanyDropdown'

export interface ArkCompanyDropdownRendererProps {
  className?: string
}

const ArkCompanyDropdownRenderer = (props: ArkCompanyDropdownRendererProps) => {
  const { className } = props

  const { actions, store } = useContext(UserContext)
  const { deselectCurrentCompany, selectCompany } = actions
  const { selectedCompany, userCompanies } = store

  const onSelect = (id: number) => {
    id ? selectCompany(id) : deselectCurrentCompany()
  }

  return (
    <ArkCompanyDropdown
      className={className}
      companies={userCompanies || []}
      onSelect={onSelect}
      selectedId={selectedCompany?.id}
    />
  )
}

export default withUserContext(ArkCompanyDropdownRenderer)
