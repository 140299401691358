import React, { useContext, useEffect, useRef } from 'react'

import { StreamhubVideoEngineContext } from '../../providers/StreamhubVideoEngineProvider'
import { IVideoEngineDataSlice, IVideoEngineServer } from '../../models/StreamhubVideoEngineModels'

import StreamhubVideoEngineServerSelectView from './StreamhubVideoEngineServerSelectView'
import StreamhubVideoEngineDataSliceSelectView from './StreamhubVideoEngineDataSliceSelectView'
import StreamhubVideoEngineView from './StreamhubVideoEngineView'

import { Header } from 'semantic-ui-react'

import styles from '../Streamhub.module.css'

interface IProps {
}

const StreamhubVideoEnginesView = (_props: IProps) => {
  const mounted = useRef(false)

  const videoEngineContext = useContext(StreamhubVideoEngineContext)

  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()
  const [videoEngineServers, setVideoEngineServers] = React.useState<Array<IVideoEngineServer> | undefined>()
  const [videoEngineDataSlices, setVideoEngineDataSlices] = React.useState<Array<IVideoEngineDataSlice> | undefined>()

  const [videoEngineServer, setVideoEngineServer] = React.useState<IVideoEngineServer | undefined>()
  const [videoEngineDataSlice, setVideoEngineDataSlice] = React.useState<IVideoEngineDataSlice | undefined>()

  // -------

  const loadData = async () => {
    console.log('StreamhubVideoEnginesView - loadData')
    if (loading) return
    setLoading(true)
    if (error) setError(undefined)
    try {
      const servers = await videoEngineContext.actions.fetchVideoEngineServers()
      console.log('StreamhubVideoEnginesView - loadData - servers:', servers)
      setVideoEngineServers(servers)

      const dataSlices = await videoEngineContext.actions.fetchVideoEngineDataSlices()
      console.log('StreamhubVideoEnginesView - loadData - dataSlices:', dataSlices)
      setVideoEngineDataSlices(dataSlices)

      // TESTING: auto select the primary production video engine by default
      const primaryProductionVideoEngine = servers.find((server) => server.name.toLowerCase().includes('production'))
      if (primaryProductionVideoEngine) setVideoEngineServer(primaryProductionVideoEngine)

      // TESTING: auto select the 'Full data slice' by default
      const fullDataSlice = dataSlices.find((dataSlice) => dataSlice.name.toLowerCase() === 'full data slice')
      if (fullDataSlice) setVideoEngineDataSlice(fullDataSlice)
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }

  // -------

  useEffect(() => {
    console.log('StreamhubVideoEnginesView - MOUNT')
    mounted.current = true
    loadData()
    return () => {
      console.log('StreamhubVideoEnginesView - UNMOUNT')
      mounted.current = false
    }
  }, [])

  // -------

  useEffect(() => {
    console.log('StreamhubVideoEnginesView - useEffect - videoEngineServer:', videoEngineServer)
  }, [videoEngineServer])

  // -------

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>
  if (!videoEngineServers) return <div>No video engine servers found</div>
  if (!videoEngineDataSlices) return <div>No video engine data slices found</div>

  return (
    <div className={styles.videoEngines}>
      <div className={styles.contentHeader}>
        <div className={styles.contentHeaderMain}>
          <Header as='h2' inverted>Video Engines</Header>
        </div>
        <div className={styles.contentHeaderFilter}>
          <div className={styles.videoEnginesSelectFields}>
            <StreamhubVideoEngineServerSelectView
              videoEngineServers={videoEngineServers}
              selectedVideoEngineServer={videoEngineServer}
              onServerSelect={(serverId: string) => {
                console.log('StreamhubVideoEnginesView - onServerSelect - serverId:', serverId)
                const server = videoEngineServers.find((server) => server.id === serverId)
                console.log('StreamhubVideoEnginesView - onServerSelect - server:', server)
                setVideoEngineServer(server)
              }}
            />
            <StreamhubVideoEngineDataSliceSelectView
              dataSlices={videoEngineDataSlices}
              selectedDataSlice={videoEngineDataSlice}
              onDataSliceSelect={(dataSliceId: string) => {
                console.log('StreamhubVideoEnginesView - onDataSliceSelect - dataSliceId:', dataSliceId)
                const dataSlice = videoEngineDataSlices.find((dataSlice) => dataSlice.id === dataSliceId)
                console.log('StreamhubVideoEnginesView - onDataSliceSelect - dataSlice:', dataSlice)
                setVideoEngineDataSlice(dataSlice)
              }}
            />
            {/* <div>Selected Video Engine Server: {videoEngineServer?.name ?? '-'}</div>
            <div>Selected Video Engine Data Slice: {videoEngineDataSlice?.name ?? '-'}</div> */}
          </div>
        </div>
      </div>
      {videoEngineServer && videoEngineDataSlice && (
        <StreamhubVideoEngineView videoEngineServer={videoEngineServer} videoEngineDataSlice={videoEngineDataSlice} />
      )}
    </div>
  )
}

export default StreamhubVideoEnginesView
