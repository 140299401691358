import React from 'react'
import { OBJECT_PROJECT_NAME } from 'src/constants/strings'

import ArkInfoView from '../../../core/components/ArkInfoView'
import ArkViewerPage from '../../components/ArkViewerPage/ArkViewerPage'

const ViewerNoViewAccessPage = () => (
  <ArkViewerPage disableSectionMainMenu>
    <ArkInfoView
      iconName='denied'
      title='VIEWING ACCESS DENIED'
      message={<div>You don&apos;t currently have access to view this {OBJECT_PROJECT_NAME}.</div>}
    />
  </ArkViewerPage>
)

export default ViewerNoViewAccessPage
