import React from 'react'
import { Input, InputProps } from 'semantic-ui-react'

import ArkFormLabel from '../ArkForm/ArkFormLabel'
import ArkIconButton from '../ArkIconButton'
import styles from './ArkInput.module.css'

export interface ArkInputProps extends InputProps {
  label?: string
  onClear?: () => void
  required?: boolean
}

const ArkInput = (props: ArkInputProps) => {
  const { className, label, onClear, required, ...otherProps } = props

  const showClear: boolean = onClear && props.value

  return (
    <div>
      {label && (
        <ArkFormLabel required={required}>{label}</ArkFormLabel>
      )}
      <div className={styles.container}>
        <Input
          className={`${
            styles.input
          } ${
            showClear && styles.inputClear
          } ${
            className
          }`}
          {...otherProps}
        />
        {showClear && (
          <ArkIconButton
            className={styles.clearButton}
            color='var(--bd-lighter)'
            hoverColor='var(--tx-light)'
            name='close'
            onClick={onClear}
            size={18}
          />
        )}
      </div>
    </div>
  )
}

export default ArkInput
