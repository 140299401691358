import React from 'react'

import * as ROUTES from '../../../constants/routes'

import UserProfileView from './UserProfile/UserProfileView'
import UserEditNameView from './UserEdit/UserEditNameView'
import UserPhoneVerifyView from './UserPhone/UserPhoneVerifyView'
import User2FAManageView from './User2FAPage/User2FAManageView'
import UserAuthSessionsView from './UserAuthSessions/UserAuthSessionsView'
import { User } from 'src/core/models'

export enum UserAccountSection {
  profile = 'profile',
  settings = 'settings'
}

export enum UserAccountSectionPage {
  profileEditName = 'profileEditName',
  profileEditEmail = 'profileEditEmail',
  profileVerifyPhone = 'profileVerifyPhone',
  settings2FA = 'settings2FA',
  settingsSessions = 'settingsSessions'
}

export type UserAccountMenuBackCallback = () => void

export type UserAccountMenuItem = {
  key: UserAccountSection | UserAccountSectionPage
  type: 'section' | 'page'
  title: string
  path: string
  menus?: Array<UserAccountMenuItem>
  menuHeader?: React.ReactNode
  menuFooter?: React.ReactNode
  page?: (onBack: UserAccountMenuBackCallback) => React.ReactNode
  showMenuItem?: (user?: User) => boolean // optionally control whether to show/add this menu item depending on some dynamic confition, defaults to always show the menu if this isn't set
}

// NB: make sure to declare each route in UserAccountRouter as well!
export const userAccountMenus: Array<UserAccountMenuItem> = [
  {
    key: UserAccountSection.profile,
    type: 'section',
    title: 'PROFILE',
    path: ROUTES.ACCOUNT_PROFILE,
    menus: [
      {
        key: UserAccountSectionPage.profileEditName,
        type: 'page',
        title: 'EDIT NAME',
        path: ROUTES.ACCOUNT_PROFILE_EDIT_NAME,
        page: (onBack) => (<UserEditNameView onCancel={onBack} />)
      },
      // {
      //   key: UserAccountSectionPage.profileEditEmail,
      //   title: 'Edit Email',
      //   type: 'page',
      //   path: ROUTES.ACCOUNT_PROFILE_EDIT_EMAIL
      // }
      {
        key: UserAccountSectionPage.profileVerifyPhone, // TODO: only show this if the user phone isn't verified
        type: 'page',
        title: 'VERIFY PHONE NUMBER',
        path: ROUTES.ACCOUNT_PROFILE_VERIFY_PHONE,
        page: (onBack) => (<UserPhoneVerifyView onCancel={onBack} onBack={onBack} />),
        showMenuItem: (user?: User) => !(user?.phoneVerified ?? false)
      }
    ],
    menuHeader: (<UserProfileView />)
    // menuFooter: (<>USER FOOTER HERE</>)
  },
  {
    key: UserAccountSection.settings,
    type: 'section',
    title: 'SETTINGS',
    path: ROUTES.ACCOUNT_SETTINGS,
    menus: [
      {
        key: UserAccountSectionPage.settings2FA,
        type: 'page',
        title: '2FA',
        path: ROUTES.ACCOUNT_SETTINGS_2FA,
        page: (_onBack) => (<User2FAManageView />)
      },
      {
        key: UserAccountSectionPage.settingsSessions,
        type: 'page',
        title: 'LOGIN SESSIONS',
        path: ROUTES.ACCOUNT_SETTINGS_SESSIONS,
        page: (_onBack) => (<UserAuthSessionsView />)
      }
    ]
  }
]

export {}
