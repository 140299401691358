import ServerAPIClient from './ServerAPIClient'
import { ServerError, ServerErrorCodes, ServerCompanyInviteAlreadyAcceptedError, ServerCompanyInviteExpiredError, ServerCompanyInviteOtherUserError } from './ServerAPIErrors'

import { Company, UserCompany, UserCompanyRole, CompanyUser, CompanyInviteUserDetails, CompanyInviteUserStatus, CompanyTranscoderSettings, CompanyLoginService } from '../models'
import { CompanyLoginServiceConfigOptions, ICompanyAddData, ICompanyUpdateData, isCompanyLoginServiceConfigOptionsOktaOIDC, isCompanyLoginServiceConfigOptionsOktaSAML } from '../models/company'
import { DEMO_MODE_SUPPORT_ENABLED } from 'src/constants/config'

export interface CompanyInfoUpdateResult {
  company?: Company
  warnings?: Array<Error>
}

class ServerCompanyAPI {
  private _apiClient: ServerAPIClient

  constructor (apiClient: ServerAPIClient) {
    this._apiClient = apiClient
  }

  // access: god user only
  getAllCompanies = async (): Promise<Array<Company> | null> => {
    try {
      const response = await this._apiClient.apiGet('/company/all')
      const companies: Array<Company> = []
      if (response.data && response.data.result && response.data.result) {
        const companiesData = response.data.result
        for (const companyData of companiesData) {
          // NB: CompanyCounts aren't currently supplied for this endpoint (just the direct company data itself)
          const company = Company.fromJSON(companyData.id, companyData)
          if (company) {
            companies.push(company)
          }
        }
      }
      // sort/order the companies array by company name
      // companies.sort((a: Company, b: Company) => a.name.localeCompare(b.name))
      // sort/order the org array by org name using a more intelligent natural sort
      companies.sort((a: Company, b: Company) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
      return companies
    } catch (error) {
      console.error('ServerCompanyAPI - getAllCompanies - error: ', error)
      throw error
    }
  }

  // access: any user
  getUserCompanies = async (): Promise<Array<UserCompany> | null> => {
    try {
      const response = await this._apiClient.apiGet('/viewer/company')
      const companies: Array<UserCompany> = []
      if (response.data && response.data.result) {
        const companiesData = response.data.result
        for (const companyData of companiesData) {
          // TODO: parse company_roles & any other additional data added alongside the company entry?
          if (companyData.company && companyData.company.id) {
            // NB: CompanyCounts aren't currently supplied for this endpoint (just the direct company data itself)
            const company = UserCompany.fromJSON(companyData.company.id, { ...companyData.company, ...{ company_role: companyData.company_role } })
            if (company) {
              companies.push(company)
            }
          }
        }
      }
      // sort/order the companies array by company name
      // companies.sort((a: UserCompany, b: UserCompany) => a.name.localeCompare(b.name))
      // sort/order the org array by org name using a more intelligent natural sort
      companies.sort((a: UserCompany, b: UserCompany) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
      return companies
    } catch (error) {
      console.error('ServerCompanyAPI - getUserCompanies - error: ', error)
      throw error
    }
  }

  addCompany = async (companyData: ICompanyAddData): Promise<Company> => {
    const data: {[key: string]: any} = {
      name: companyData.name
    }
    if (companyData.isActive !== undefined) data.flag_is_active = companyData.isActive
    if (DEMO_MODE_SUPPORT_ENABLED && companyData.isDemo !== undefined) data.flag_demo = companyData.isDemo
    if (companyData.maxUsers !== undefined) data.max_users = companyData.maxUsers
    if (companyData.projectDefaultMaxUsers !== undefined) data.default_max_users_project = companyData.projectDefaultMaxUsers
    // console.log('ServerCompanyAPI - addCompany - data(api): ', data)
    try {
      const response = await this._apiClient.apiPost('/company', data, {})
      let company: Company | null = null
      if (response.status === 201 && response.data && response.data.result && response.data.result) {
        const _companyData = response.data.result
        company = Company.fromJSON(_companyData.id, _companyData)
      }
      if (!company) throw new Error('Invalid response')
      return company
    } catch (error) {
      console.error('ServerCompanyAPI - addCompany - error: ', error)
      throw error
    }
  }

  // update regular company fields - NB: see updateCompanyInfo for addition info fields (like transcoder settings)
  updateCompany = async (companyId: number, companyData: ICompanyUpdateData): Promise<Company> => {
    // console.log('ServerCompanyAPI - updateCompany - companyId:', companyId, ' companyData: ', companyData)
    const data: {[key: string]: any} = {}
    // TODO: use the `Company` `propertyToJSONKeyMap` function (or a field specific helper that uses it) to map the field names instead of hard-coding them here?
    if (companyData.name !== undefined) data.name = companyData.name
    if (companyData.isActive !== undefined) data.flag_is_active = companyData.isActive
    if (DEMO_MODE_SUPPORT_ENABLED && companyData.isDemo !== undefined) data.flag_demo = companyData.isDemo
    if (companyData.maxUsers !== undefined) data.max_users = companyData.maxUsers
    if (companyData.projectDefaultMaxUsers !== undefined) data.default_max_users_project = companyData.projectDefaultMaxUsers
    // TODO: halt if no fields to update
    // TODO: add support for other (non-info) fields...
    // TODO: ...should we update enforce_2fa via a regular company update, or maybe require it to make a dedicated api call??
    console.log('ServerCompanyAPI - updateCompany - data: ', data)
    try {
      const response = await this._apiClient.apiPut('/company', data, { 'company-id': companyId })
      let company: Company | null = null
      if (response.status === 200 && response.data && response.data.result && response.data.result) {
        const _companyData = response.data.result
        company = Company.fromJSON(_companyData.id, _companyData)
      }
      if (!company) throw new Error('Invalid response')
      return company
    } catch (error) {
      console.error('ServerCompanyAPI - updateCompany - error: ', error)
      throw error
    }
  }

  deleteCompany = async (companyId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiDelete('/company', undefined, { 'company-id': companyId })
      // NB: TEMP checking for 201 response as a success as thats what the api currently returns
      // TODO: remove this once the api fixes it to reuturn a 200 response
      if (response.status === 200 || response.status === 201) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerCompanyAPI - deleteCompany - error: ', error)
      throw error
    }
  }

  // -------

  // TESTING: this endpoint is for company or site admins only - it loads additional settings/info (mainly company transcoder settings)
  // NB: NOT used or tested yet, just stubbed out!
  getCompanyInfo = async (companyId: number): Promise<Company | null> => {
    try {
      const response = await this._apiClient.apiGet('/company/info', { 'company-id': companyId })
      if (response.data && response.data.result && response.data.result && response.data.result.company) {
        const data = response.data.result
        const companyData = data.company
        // TODO: return a standard Company model, or something specific to this endpoint (similar to the ProjectSummary model?)
        // UPDATE: currently returning the standard Company model, but it now contains options `transcoderSettings` & `counts` fields
        // TESTING: add in the count fields that are now also returned by this endpoint
        const companyExtendedData = {
          ...companyData,
          users_active_count: data.users_active_count,
          users_pending_count: data.users_pending_count,
          users_invited_count: data.users_invited_count,
          projects_count: data.projects_count,
          programs_count: data.programs_count
        }
        return Company.fromJSON(companyId, companyExtendedData)
      }
      return null
    } catch (error) {
      console.error('ServerCompanyAPI - getCompanyInfo - error: ', error)
      throw error
    }
  }

  // NB: this calls the regular company update endpoint, but specically passes in info fields like transcoder settings instead of the base fields like name
  updateCompanyInfo = async (companyId: number, values: {[key: string]: any}): Promise<CompanyInfoUpdateResult> => {
    // TESTING: convert the class property keys to their json equivalent
    // refs: https://stackoverflow.com/a/39283005 & https://stackoverflow.com/a/62380351
    // const jsonFieldKey = (transcoderJSONKeyMap as any)[fieldName]
    // if (jsonFieldKey) {
    //   data[jsonFieldKey] = values[fieldName]
    // }
    const companyJSONKeyMap = Company.propertyToJSONKeyMap()
    const transcoderJSONKeyMap = CompanyTranscoderSettings.propertyToJSONKeyMap()
    console.log('ServerCompanyAPI - getCompanyInfo - companyJSONKeyMap: ', companyJSONKeyMap, ' transcoderJSONKeyMap: ', transcoderJSONKeyMap)
    const data: {[key: string]: any} = {}
    for (const fieldName of Object.keys(values)) {
      console.log('ServerCompanyAPI - getCompanyInfo - fieldName: ', fieldName, ' = ', (transcoderJSONKeyMap as any)[fieldName])
      // check if the field has a company transcoder json key mapped
      if (Object.prototype.hasOwnProperty.call(transcoderJSONKeyMap, fieldName)) {
        const jsonKey = transcoderJSONKeyMap[fieldName as keyof typeof transcoderJSONKeyMap]
        data[jsonKey] = values[fieldName]
      } else if (Object.prototype.hasOwnProperty.call(companyJSONKeyMap, fieldName)) {
        // check if the field has a company json key mapped
        const jsonKey = companyJSONKeyMap[fieldName as keyof typeof companyJSONKeyMap]
        data[jsonKey] = values[fieldName]
      } else {
        // no json key map for this value key - use it directly
        data[fieldName] = values[fieldName]
      }
    }
    console.log('ServerCompanyAPI - updateCompanyInfo - data: ', data, ' length: ', Object.keys(data).length)
    // TODO: halt if data is empty
    if (Object.keys(data).length === 0) {
      throw new Error('No valid fields to update')
    }
    // TODO: don't allow certain fields to be edited via this call, force them to use the more standard updateCompany function instead? (e.g. company name, force 2fa etc.), if so whitelist or blacklist?
    try {
      const response = await this._apiClient.apiPut('/company', data, { 'company-id': companyId })
      console.log('ServerCompanyAPI - updateCompanyInfo - response: ', response)
      if (response.data && response.data.result && response.data.result) {
        const companyData = response.data.result
        // NB: company counts data/fields aren't returned from this endpoint currently (only the get company info endpoint itself)
        const company = Company.fromJSON(companyData.id, companyData)
        // check for any warnings along with the updated company data
        const warnings: Array<Error> = []
        if (response.data.warnings && response.data.warnings.length > 0) {
          for (const warning of response.data.warnings) {
            if (warning.message) {
              const warningError = Error(warning.message)
              warnings.push(warningError)
            }
          }
        }
        const result: CompanyInfoUpdateResult = {
          company: company ?? undefined,
          warnings: warnings.length > 0 ? warnings : undefined
        }
        return result
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerCompanyAPI - updateCompanyInfo - error: ', error)
      throw error
    }
  }

  // triggers a transcoder settings sync/apply to all projects within the company
  // returns true if the sync was applied, false if it wasn't needed to be applied (all still ok), throws an error if any issues
  syncCompanyTranscoderSettingsToAllProjects = async (companyId: number, force: boolean = false): Promise<boolean> => {
    console.log('ServerCompanyAPI - syncCompanyTranscoderSettingsToAllProjects - companyId: ', companyId, ' force:', force)
    try {
      const response = await this._apiClient.apiGet('/company/sync_configuration' + (force ? '?force=true' : ''), { 'company-id': companyId })
      console.log('ServerCompanyAPI - syncCompanyTranscoderSettingsToAllProjects - response: ', response)
      if (response.status === 200) {
        return true
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerCompanyAPI - syncCompanyTranscoderSettingsToAllProjects - error: ', error)
      if (error instanceof ServerError) {
        console.error('ServerCompanyAPI - syncCompanyTranscoderSettingsToAllProjects - ServerError - error: ', error)
        // TODO: does this handling apply to the company version of this endpoint?? (duped from the project version)
        if (error.statusCode === 409 || error.statusCode === 422) {
          // already syncronised - returning false to indicate it wasn't needed (& always throws an error if any other actual issue)
          return false
        }
      }
      throw error
    }
  }

  // -------

  getCompanyLoginServices = async (companyId: number): Promise<Array<CompanyLoginService>> => {
    try {
      const response = await this._apiClient.apiGet('/company/login_service', { 'company-id': companyId })
      console.log('ServerCompanyAPI - getCompanyLoginServices - response.data: ', response.data)
      const loginServices: Array<CompanyLoginService> = []
      if (response.data && response.data.result && response.data.result && typeof response.data.result === 'object') {
        const loginServicesData = response.data.result
        for (const loginServiceData of loginServicesData) {
          const loginService = CompanyLoginService.fromJSON(loginServiceData)
          if (loginService) loginServices.push(loginService)
        }
      } else {
        throw new Error('Invalid response')
      }
      return loginServices
    } catch (error) {
      console.error('ServerCompanyAPI - getCompanyLoginServices - error: ', error)
      throw error
    }
  }

  addCompanyLoginService = async (companyId: number, loginServiceId: number, configOptions?: CompanyLoginServiceConfigOptions): Promise<CompanyLoginService> => {
    console.log('ServerCompanyAPI - addCompanyLoginService - companyId:', companyId, ' loginServiceId:', loginServiceId, ' configOptions:', configOptions)
    const data: {[key: string]: any} = {
      login_service_id: loginServiceId
    }
    if (configOptions) {
      if (isCompanyLoginServiceConfigOptionsOktaOIDC(configOptions)) {
        console.log('ServerCompanyAPI - addCompanyLoginService - isCompanyLoginServiceConfigOptionsOktaOIDC...')
        data.login_service_domain = configOptions.domain
        data.access_token = configOptions.accessToken
        data.audience = configOptions.audience
        data.issuer = configOptions.issuer
        data.client_id = configOptions.clientId
        data.native_client_id = configOptions.nativeClientId
        data.native_redirect_uri = configOptions.nativeRedirectURI
      } else if (isCompanyLoginServiceConfigOptionsOktaSAML(configOptions)) {
        console.log('ServerCompanyAPI - addCompanyLoginService - isCompanyLoginServiceConfigOptionsOktaSAML...')
        data.login_service_domain = configOptions.domain
        data.access_token = configOptions.accessToken
        data.issuer = configOptions.issuer
        data.client_id = configOptions.clientId
        data.entry_point = configOptions.entryPoint
        data.certificate = configOptions.cert
      } else {
        throw new Error('Invalid config option type')
      }
    }
    console.log('ServerCompanyAPI - addCompanyLoginService - data:', data)
    try {
      const response = await this._apiClient.apiPost('/company/login_service', data, { 'company-id': companyId })
      console.log('ServerCompanyAPI - addCompanyLoginService - response.data: ', response.data)
      // TODO: also check response status? returns 201 on succcess
      if (response.status === 201 && response.data && response.data.result) {
        const loginServiceData = response.data.result
        const loginService = CompanyLoginService.fromJSON(loginServiceData)
        if (loginService) return loginService
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerCompanyAPI - addCompanyLoginService - error: ', error)
      throw error
    }
  }

  // update the company login service config options (e.g. domain, access token etc.)
  // NB: not currently supporting updates to the base login service/method object, only its (sub) config options, but could extend this to support other base fields as well if needed
  updateCompanyLoginService = async (companyId: number, loginServiceInstanceId: number, configOptions: CompanyLoginServiceConfigOptions, enabled?: boolean): Promise<CompanyLoginService> => {
    console.log('ServerCompanyAPI - updateCompanyLoginService - companyId:', companyId, ' loginServiceInstanceId:', loginServiceInstanceId, ' configOptions', configOptions, ' enabled:', enabled)
    const data: {[key: string]: any} = {}
    if (isCompanyLoginServiceConfigOptionsOktaOIDC(configOptions)) {
      data.login_service_domain = configOptions.domain
      data.access_token = configOptions.accessToken
      data.audience = configOptions.audience
      data.issuer = configOptions.issuer
      data.client_id = configOptions.clientId
      data.native_client_id = configOptions.nativeClientId
      data.native_redirect_uri = configOptions.nativeRedirectURI
    } else if (isCompanyLoginServiceConfigOptionsOktaSAML(configOptions)) {
      data.login_service_domain = configOptions.domain
      data.access_token = configOptions.accessToken
      data.issuer = configOptions.issuer
      data.client_id = configOptions.clientId
      data.entry_point = configOptions.entryPoint
      if (configOptions.cert) data.certificate = configOptions.cert // NB: not currently supporting editing of the cert field by default (may allow it to be overwritten in the future, so adding it if set)
    } else {
      throw new Error('Invalid config option type')
    }
    if (enabled !== undefined) data.enabled = enabled
    console.log('ServerCompanyAPI - updateCompanyLoginService - data:', data)
    try {
      const response = await this._apiClient.apiPut('/company/login_service/' + loginServiceInstanceId, data, { 'company-id': companyId })
      console.log('ServerCompanyAPI - updateCompanyLoginService - response.data:', response.data)
      if (response.status === 200 && response.data && response.data.result) {
        const loginServiceData = response.data.result
        const loginService = CompanyLoginService.fromJSON(loginServiceData)
        if (loginService) return loginService
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerCompanyAPI - updateCompanyLoginService - error: ', error)
      throw error
    }
  }

  enableDisableCompanyLoginService = async (companyId: number, loginServiceInstanceId: number, enabled: boolean): Promise<CompanyLoginService> => {
    console.log('ServerCompanyAPI - enableDisableCompanyLoginService - companyId:', companyId, ' loginServiceInstanceId:', loginServiceInstanceId, ' enabled:', enabled)
    const data: {[key: string]: any} = {
      enabled: enabled
    }
    try {
      const response = await this._apiClient.apiPut('/company/login_service/' + loginServiceInstanceId, data, { 'company-id': companyId })
      console.log('ServerCompanyAPI - enableDisableCompanyLoginService - response.data:', response.data)
      if (response.status === 200 && response.data && response.data.result) {
        const loginServiceData = response.data.result
        const loginService = CompanyLoginService.fromJSON(loginServiceData)
        if (loginService) return loginService
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerCompanyAPI - enableDisableCompanyLoginService - error: ', error)
      throw error
    }
  }

  deleteCompanyLoginService = async (companyId: number, loginServiceInstanceId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiDelete('/company/login_service/' + loginServiceInstanceId, undefined, { 'company-id': companyId })
      console.log('ServerCompanyAPI - deleteCompanyLoginService - response.data: ', response.data)
      // NB: TEMP checking for 201 response as a success as thats what the api currently returns
      // TODO: remove this once the api fixes it to reuturn a 200 response
      if (response.status === 200) {
        return true
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerCompanyAPI - deleteCompanyLoginService - error: ', error)
      throw error
    }
  }

  // -------

  getCompanyUsers = async (companyId: number): Promise<Array<CompanyUser> | null> => {
    try {
      const response = await this._apiClient.apiGet('/company/users', { 'company-id': companyId })
      const users: Array<CompanyUser> = []
      if (response.data && response.data.result) {
        const companyUsersData = response.data.result
        for (const companyUserData of companyUsersData) {
          // NB: merging in the additional user company specific fields into the main user json so its all parses in one
          if (companyUserData.user) {
            const user = CompanyUser.fromJSON(companyUserData.user.id, { ...companyUserData.user, ...{ company_role: companyUserData.company_role, company_status: companyUserData.company_status } })
            if (user) {
              users.push(user)
            }
          }
        }
      }
      // sort/order the users array by company role & then user name (NB: the User name() returns the full name if set, or email as a fallback)
      users.sort((a: CompanyUser, b: CompanyUser) => {
        const aRole = a.companyRole && a.companyRole > UserCompanyRole.unknown ? a.companyRole : UserCompanyRole.member
        const bRole = b.companyRole && b.companyRole > UserCompanyRole.unknown ? b.companyRole : UserCompanyRole.member
        // return aRole - bRole || a.name().localeCompare(b.name())
        return aRole - bRole || a.name().localeCompare(b.name(), navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true })
      })
      return users
    } catch (error) {
      console.error('ServerCompanyAPI - getCompanyUsers - error: ', error)
      throw error
    }
  }

  // NB: we only support company invites by email
  // NB: although the endpoint also supports inviting an existing user by their uid (id_type=id), only site-admin/god would be able to see users across companies, so no real use for it here?
  inviteUserToCompany = async (companyId: number, email: string, firstName?: string, lastName?: string, companyRole?: UserCompanyRole): Promise<boolean> => {
    const cRole = companyRole ?? UserCompanyRole.member
    const data: {[key: string]: any} = {
      email: email,
      company_role_id: cRole
    }
    if (firstName && firstName !== '') data.name = firstName
    if (lastName && lastName !== '') data.last_name = lastName
    try {
      const response = await this._apiClient.apiPost('/company/users/invite?id_type=email', data, { 'company-id': companyId })
      // TODO: also check response status? returns 201 on succcess
      if (response.data && response.data.result && response.data.result) {
        // const resultData = response.data.result
        return true
      }
      return false
    } catch (error) {
      console.error('ServerCompanyAPI - inviteUserToCompany - error: ', error)
      throw error
    }
  }

  reinviteUserToCompany = async (companyId: number, userId: number) => {
    try {
      const response = await this._apiClient.apiGet('/company/users/invite/' + userId, { 'company-id': companyId })
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerCompanyAPI - reinviteUserToCompany - error: ', error)
      // TODO: 404 response = Pending Invitation not found for this user in this company.
      // TODO: 500 response = Cannot invite again the user.
      throw error
    }
  }

  // TODO: user (not admin) access
  lookupUserCompanyInvite = async (inviteToken: string): Promise<CompanyInviteUserDetails | null> => {
    try {
      // NB: the invite/details endpoint no longer returns a ServerCompanyInviteAlreadyAcceptedError (but the invite/accept still does)
      // NB: instead it now returns a 200 success error for 'already accepted' invites with the inviteStatus set accordingly
      // NB: this is so the api can return other details with this response, including company id etc.
      const response = await this._apiClient.apiGet('/company/users/invite/details/' + inviteToken)
      if (response && response.data && response.data.result && response.data.result.user) {
        const inviteData = response.data.result
        const userData = inviteData.user
        if (userData && userData.email) {
          const companyInviteUserData: CompanyInviteUserDetails = {
            email: userData.email,
            firstName: userData.name,
            lastName: userData.last_name,
            companyId: inviteData.company?.id, // NB: only set if the user is logged in
            companyName: inviteData.company?.name,
            companyRole: inviteData.company_role.id ?? UserCompanyRole.unknown,
            userStatus: userData.status?.id,
            inviteStatus: inviteData.invitation_status?.id ?? CompanyInviteUserStatus.unknown
          }
          return companyInviteUserData
        }
      }
      return null
    } catch (error) {
      console.error('ServerCompanyAPI - lookupUserCompanyInvite - error: ', error)
      if (error instanceof ServerError) {
        console.error('ServerCompanyAPI - lookupUserCompanyInvite - error.statusCode: ', error.statusCode, ' error.errorCode: ', error.errorCode)
        if (error.statusCode === 401 && error.errorCode === ServerErrorCodes.inviteExpired) {
          console.error('ServerCompanyAPI - lookupUserCompanyInvite - error - INVITE EXPIRED...')
          throw new ServerCompanyInviteExpiredError()
        }
      }

      throw error
    }
  }

  // TODO: user (not admin) access
  acceptUserCompanyInvite = async (inviteToken: string): Promise<UserCompany | null> => {
    try {
      const response = await this._apiClient.apiGet('/company/users/invite/accept/' + inviteToken)
      if (response.status === 200) {
        if (response.data && response.data.result) {
          const companyData = response.data.result
          return UserCompany.fromJSON(companyData.id, companyData)
        }
      }
      return null
    } catch (error) {
      console.error('ServerCompanyAPI - acceptUserCompanyInvite - error: ', error)
      if (error instanceof ServerError) {
        if (error.statusCode === 309 && error.errorCode === ServerErrorCodes.inviteAlreadyAccepted) {
          console.error('ServerCompanyAPI - acceptUserCompanyInvite - error - INVITE ALREADY ACCEPTED...')
          throw new ServerCompanyInviteAlreadyAcceptedError()
        } else if (error.statusCode === 401 && error.errorCode === ServerErrorCodes.inviteTokenBelongsToOtherUser) {
          throw new ServerCompanyInviteOtherUserError()
        }
      }

      throw error
    }
  }

  removeUserFromCompany = async (companyId: number, userId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiDelete('/company/users/' + userId, undefined, { 'company-id': companyId })
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerCompanyAPI - removeUserFromCompany - error: ', error)
      throw error
    }
  }

  updateUserCompanyRole = async (companyId: number, userId: number, companyRole: UserCompanyRole): Promise<boolean> => {
    const cRole = companyRole !== UserCompanyRole.unknown ? companyRole : UserCompanyRole.member
    const data: {[key: string]: any} = {
      user_id: userId,
      new_company_role_id: cRole
    }
    try {
      const response = await this._apiClient.apiPut('/company/roles/edit?id_type=id', data, { 'company-id': companyId })
      // TODO: also check response status? returns 201 on succcess
      if (response.data && response.data.result && response.data.result) {
        // const resultData = response.data.result
        return true
      }
      return false
    } catch (error) {
      console.error('ServerCompanyAPI - updateUserCompanyRole - error: ', error)
      throw error
    }
  }
}

export default ServerCompanyAPI
