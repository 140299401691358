import React from 'react'

import { withUserContext, IUserMultiContext, withProjectAdminContext, IProjectAdminMultiContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import ArkProjectManagerPage from 'src/manager/project/components/ArkProjectManagerPage/ArkProjectManagerPage'
import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'

import { ProjectSettingsWatermarkView } from './ProjectSettingsWatermarkView'

import { OBJECT_PROJECT_NAME, SECTION_MANAGER_SUFFIX_NAME, PAGE_SETTINGS_NAME, PAGE_SETTINGS_WATERMARK_NAME } from 'src/constants/strings'

interface IProps extends IUserMultiContext, IProjectAdminMultiContext {}
interface IState {}

class ProjectSettingsWatermarkPage extends React.Component<IProps, IState> {
  render () {
    return (<ArkProjectManagerPage>
      {this.renderContent()}
    </ArkProjectManagerPage>)
  }

  renderContent () {
    const company = this.props.userContext.store.selectedCompany
    const project = this.props.userContext.store.selectedProject
    if (!company || !project) return null
    return (
      <ArkManagerContentView
        title={PAGE_SETTINGS_WATERMARK_NAME}
        breadcrumb={[{
          path: ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_VIEW, project.id),
          title: ROUTES.formatBreadcrumbRootTitle(project.name, `${OBJECT_PROJECT_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`)
        }, {
          path: ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_SETTINGS, project.id),
          title: PAGE_SETTINGS_NAME
        }]}
      >
        <ProjectSettingsWatermarkView />
      </ArkManagerContentView>
    )
  }
}
export default withProjectAdminContext(withUserContext(ProjectSettingsWatermarkPage))
