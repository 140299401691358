/**
 * ChecklistProvider
 */

import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import {
  PROJECT_CHECKLIST_MOCK_DATA,
  PROJECT_CHECKLIST_MOCK_DATA_CREATE_PROGRAM,
  PROJECT_CHECKLIST_MOCK_DATA_CREATE_CHANNEL,
  PROJECT_CHECKLIST_MOCK_DATA_CREATE_GROUP,
  PROJECT_CHECKLIST_MOCK_DATA_ADD_PROGRAM_TO_CHANNEL,
  PROJECT_CHECKLIST_MOCK_DATA_ADD_CHANNEL_TO_GROUP,
  PROJECT_CHECKLIST_MOCK_DATA_ADD_USER_TO_PROJECT,
  PROJECT_CHECKLIST_MOCK_DATA_ADD_USER_TO_GROUP,
  PROJECT_CHECKLIST_MOCK_DATA_USER_VIEW_PROGRAM
} from 'src/constants/config'
import { UserContext } from 'src/core/providers/UserProvider'
import { ResponsiveContext } from 'src/core/providers/ResponsiveProvider'
import { NodeGraphContext } from '../nodeGraph/NodeGraphProvider'
import {
  getSomeChannels,
  getSomeChannelsInGroups,
  getSomeChannelsInUserGroups,
  getSomeOtherUsers,
  getSomePrograms,
  getSomeProgramsConnectedToUsers,
  getSomeProgramsInChannels,
  getSomeUserGroups,
  getSomeUsersInGroupsWithChannels,
  getSomeUsersInUserGroups
} from './utilities'

interface IChecklistActions {
  setShowChecklist: (show: boolean) => void
}

interface IChecklistStore {
  isCreateProgramEnabled: boolean
  isCreateProgramChecked: boolean
  isCreateChannelEnabled: boolean
  isCreateChannelChecked: boolean
  isCreateGroupEnabled: boolean
  isCreateGroupChecked: boolean
  isAddProgramToChannelEnabled: boolean
  isAddProgramToChannelChecked: boolean
  isAddChannelToGroupEnabled: boolean
  isAddChannelToGroupChecked: boolean
  isAddChannelToGroupDefaultGroup: boolean
  isAddUserToProjectEnabled: boolean
  isAddUserToProjectChecked: boolean
  isAddUserToGroupEnabled: boolean
  isAddUserToGroupChecked: boolean
  isAddUserToGroupDefaultGroup: boolean
  isUserCanViewProgramEnabled: boolean
  isUserCanViewProgramChecked: boolean
  isChecklistComplete: boolean
  showChecklist: boolean
  showChecklistCard: boolean
  showChecklistModal: boolean
}

interface IChecklistContext {
  actions: IChecklistActions;
  store: IChecklistStore;
}

interface ChecklistProviderProps {
  children: ReactNode
}

export const ChecklistContext = createContext<IChecklistContext>({} as IChecklistContext)

const ChecklistProvider = (props: ChecklistProviderProps) => {
  const { children } = props

  const { store: responsiveStore } = useContext(ResponsiveContext)
  const { isMobile } = responsiveStore

  const { store: nodeGraphStore } = useContext(NodeGraphContext)
  const { unfilteredData } = nodeGraphStore

  const { actions: userActions, store: userStore } = useContext(UserContext)
  const { selectedCompany, selectedProject, userCache } = userStore

  const [showChecklistCard, setShowChecklistCard] = useState(true)
  const [showChecklistModal, setShowChecklistModal] = useState(false)

  const isCreateProgramEnabled = true
  const isCreateProgramChecked = PROJECT_CHECKLIST_MOCK_DATA
    ? PROJECT_CHECKLIST_MOCK_DATA_CREATE_PROGRAM
    : getSomePrograms(unfilteredData)
  const isCreateChannelEnabled = isCreateProgramEnabled && isCreateProgramChecked
  const isCreateChannelChecked = PROJECT_CHECKLIST_MOCK_DATA
    ? PROJECT_CHECKLIST_MOCK_DATA_CREATE_CHANNEL
    : getSomeChannels(unfilteredData)
  const isCreateGroupEnabled = isCreateChannelEnabled && isCreateChannelChecked
  const isCreateGroupChecked = PROJECT_CHECKLIST_MOCK_DATA
    ? PROJECT_CHECKLIST_MOCK_DATA_CREATE_GROUP
    : getSomeUserGroups(unfilteredData)
  const isAddProgramToChannelEnabled = isCreateChannelEnabled && isCreateChannelChecked
  const isAddProgramToChannelChecked = PROJECT_CHECKLIST_MOCK_DATA
    ? PROJECT_CHECKLIST_MOCK_DATA_ADD_PROGRAM_TO_CHANNEL
    : getSomeProgramsInChannels(unfilteredData)
  const isAddChannelToGroupEnabled = isAddProgramToChannelEnabled && isAddProgramToChannelChecked
  const isAddChannelToGroupChecked = PROJECT_CHECKLIST_MOCK_DATA
    ? PROJECT_CHECKLIST_MOCK_DATA_ADD_CHANNEL_TO_GROUP
    : getSomeChannelsInGroups(unfilteredData)
  const isAddChannelToGroupDefaultGroup = isAddChannelToGroupChecked && !getSomeChannelsInUserGroups(unfilteredData)
  const isAddUserToProjectEnabled = isAddChannelToGroupEnabled && isAddChannelToGroupChecked
  const isAddUserToProjectChecked = PROJECT_CHECKLIST_MOCK_DATA
    ? PROJECT_CHECKLIST_MOCK_DATA_ADD_USER_TO_PROJECT
    : getSomeOtherUsers(unfilteredData)
  const isAddUserToGroupEnabled = isAddUserToProjectEnabled && isAddUserToProjectChecked
  const isAddUserToGroupChecked = PROJECT_CHECKLIST_MOCK_DATA
    ? PROJECT_CHECKLIST_MOCK_DATA_ADD_USER_TO_GROUP
    : getSomeUsersInGroupsWithChannels(unfilteredData)
  const isAddUserToGroupDefaultGroup = isAddUserToGroupChecked && !getSomeUsersInUserGroups(unfilteredData)
  const isUserCanViewProgramEnabled = isAddUserToGroupEnabled && isAddUserToGroupChecked
  const isUserCanViewProgramChecked = PROJECT_CHECKLIST_MOCK_DATA
    ? PROJECT_CHECKLIST_MOCK_DATA_USER_VIEW_PROGRAM
    : getSomeProgramsConnectedToUsers(unfilteredData)
  const isChecklistComplete = isUserCanViewProgramEnabled && isUserCanViewProgramChecked

  const setShowChecklist = (show: boolean) => {
    if (isMobile) {
      setShowChecklistModal(show)
    } else {
      setShowChecklistCard(show)
    }
  }

  // load options from user cache
  useEffect(() => {
    if (!userCache || !selectedCompany || !selectedProject || !unfilteredData) return
    if (isChecklistComplete) {
      setShowChecklist(false)
      return
    }
    const projectCache = userCache.getSelectedCompanyProjectCache(selectedCompany.id, selectedProject.id)
    if (!projectCache) return
    setShowChecklist(!projectCache.hideChecklist)
  }, [selectedProject, unfilteredData])

  // save options to user cache
  useEffect(() => {
    if (!userCache || !selectedCompany || !selectedProject) return
    const projectCache = userCache.getSelectedCompanyProjectCache(selectedCompany.id, selectedProject.id)
    if (!projectCache) return
    projectCache.hideChecklist = !showChecklistCard
    userCache.setSelectedCompanyProjectCache(selectedCompany.id, selectedProject.id, projectCache)
    userActions.saveUserCache(userCache)
  }, [showChecklistCard])

  const actions: IChecklistActions = {
    setShowChecklist
  }

  const store: IChecklistStore = {
    isCreateProgramEnabled,
    isCreateProgramChecked,
    isCreateChannelEnabled,
    isCreateChannelChecked,
    isCreateGroupEnabled,
    isCreateGroupChecked,
    isAddProgramToChannelEnabled,
    isAddProgramToChannelChecked,
    isAddChannelToGroupEnabled,
    isAddChannelToGroupChecked,
    isAddChannelToGroupDefaultGroup,
    isAddUserToProjectEnabled,
    isAddUserToProjectChecked,
    isAddUserToGroupEnabled,
    isAddUserToGroupChecked,
    isAddUserToGroupDefaultGroup,
    isUserCanViewProgramEnabled,
    isUserCanViewProgramChecked,
    isChecklistComplete,
    showChecklist: isMobile ? showChecklistModal : showChecklistCard,
    showChecklistCard,
    showChecklistModal
  }

  return (
    <ChecklistContext.Provider value={{ actions, store }}>
      {children}
    </ChecklistContext.Provider>
  )
}

export default ChecklistProvider
