/**
 * ArkIcon
 *
 * https://iconscout.com/unicons
 * https://fonts.google.com/icons
 */

import React, { FC, SVGProps } from 'react'

import { ReactComponent as Activity } from 'src/assets/icons/activity.svg'
import { ReactComponent as Admin } from 'src/assets/icons/admin.svg'
import { ReactComponent as AdminFilled } from 'src/assets/icons/admin-filled.svg'
import { ReactComponent as AngleDoubleDown } from 'src/assets/icons/angle-double-down.svg'
import { ReactComponent as AngleDoubleUp } from 'src/assets/icons/angle-double-up.svg'
import { ReactComponent as AngleLeft } from 'src/assets/icons/angle-left.svg'
import { ReactComponent as AngleRight } from 'src/assets/icons/angle-right.svg'
import { ReactComponent as ArrowRight } from 'src/assets/icons/arrow-right.svg'
import { ReactComponent as Ban } from 'src/assets/icons/ban.svg'
import { ReactComponent as Bars } from 'src/assets/icons/bars.svg'
import { ReactComponent as Card } from 'src/assets/icons/card.svg'
import { ReactComponent as Channel } from 'src/assets/icons/channel.svg'
import { ReactComponent as CheckCircle } from 'src/assets/icons/check-circle.svg'
import { ReactComponent as CheckCircleSolid } from 'src/assets/icons/check-circle-solid.svg'
import { ReactComponent as Close } from 'src/assets/icons/times-circle.svg'
import { ReactComponent as Clipboard } from 'src/assets/icons/clipboard.svg'
import { ReactComponent as Company } from 'src/assets/icons/company.svg'
import { ReactComponent as Copy } from 'src/assets/icons/copy.svg'
import { ReactComponent as Dashboard } from 'src/assets/icons/dashboard.svg'
import { ReactComponent as Denied } from 'src/assets/icons/denied.svg'
import { ReactComponent as Edit } from 'src/assets/icons/edit.svg'
import { ReactComponent as ExclamationCircle } from 'src/assets/icons/exclamation-circle.svg'
import { ReactComponent as ExclamationCircleSolid } from 'src/assets/icons/exclamation-circle-solid.svg'
import { ReactComponent as Eye } from 'src/assets/icons/eye.svg'
import { ReactComponent as EyeSlash } from 'src/assets/icons/eye-slash.svg'
import { ReactComponent as Filter } from 'src/assets/icons/filter.svg'
import { ReactComponent as Fit } from 'src/assets/icons/fit.svg'
import { ReactComponent as FullscreenOn } from 'src/assets/icons/fullscreen-on.svg'
import { ReactComponent as FullscreenOff } from 'src/assets/icons/fullscreen-off.svg'
import { ReactComponent as Group } from 'src/assets/icons/group.svg'
import { ReactComponent as GroupCompany } from 'src/assets/icons/group-company.svg'
import { ReactComponent as GroupCompanyFilled } from 'src/assets/icons/group-company-filled.svg'
import { ReactComponent as MinusCircle } from 'src/assets/icons/minus-circle.svg'
import { ReactComponent as MinusCircleSolid } from 'src/assets/icons/minus-circle-solid.svg'
import { ReactComponent as Mixer } from 'src/assets/icons/mixer.svg'
import { ReactComponent as Notice } from 'src/assets/icons/notice.svg'
import { ReactComponent as Offline } from 'src/assets/icons/video-slash.svg'
import { ReactComponent as PauseCircle } from 'src/assets/icons/pause-circle.svg'
import { ReactComponent as PlayCircle } from 'src/assets/icons/play-circle.svg'
import { ReactComponent as Program } from 'src/assets/icons/program.svg'
import { ReactComponent as Project } from 'src/assets/icons/project.svg'
import { ReactComponent as Restart } from 'src/assets/icons/restart.svg'
import { ReactComponent as Save } from 'src/assets/icons/save.svg'
import { ReactComponent as Search } from 'src/assets/icons/search.svg'
import { ReactComponent as Settings } from 'src/assets/icons/settings.svg'
import { ReactComponent as Sliders } from 'src/assets/icons/sliders.svg'
import { ReactComponent as Sort } from 'src/assets/icons/sort-amount-down.svg'
import { ReactComponent as SortAlt } from 'src/assets/icons/sort-amount-up.svg'
import { ReactComponent as Streamhub } from 'src/assets/icons/streamhub.svg'
import { ReactComponent as Support } from 'src/assets/icons/support.svg'
import { ReactComponent as UserCompany } from 'src/assets/icons/user-company.svg'
import { ReactComponent as UserProject } from 'src/assets/icons/user-project.svg'
import { ReactComponent as VideoFeed } from 'src/assets/icons/video.svg'
import { ReactComponent as Viewer } from 'src/assets/icons/viewer.svg'
import { ReactComponent as VolumeMute } from 'src/assets/icons/volume-mute.svg'
import { ReactComponent as VolumeOff } from 'src/assets/icons/volume-off.svg'
import { ReactComponent as Warning } from 'src/assets/icons/exclamation-triangle.svg'
import { ReactComponent as ZoomIn } from 'src/assets/icons/zoom-in.svg'
import { ReactComponent as ZoomOut } from 'src/assets/icons/zoom-out.svg'
import { ReactComponent as PadlockClosed } from 'src/assets/icons/padlock.svg'

// custom icons
import { ReactComponent as UserOrgAdmin } from 'src/assets/icons/user-oa-filled.svg'
import { ReactComponent as UserProjectAdmin } from 'src/assets/icons/user-pa-filled.svg'
import { ReactComponent as UserProjectManager } from 'src/assets/icons/user-pm-filled.svg'
import { ReactComponent as UserGuest } from 'src/assets/icons/user-guest-filled.svg'
import { ReactComponent as UserLink } from 'src/assets/icons/user-link-filled.svg'

import { ReactComponent as ChannelFilled } from 'src/assets/icons/channel-filled.svg'
import { ReactComponent as GroupFilled } from 'src/assets/icons/group-filled.svg'
import { ReactComponent as ProgramFilled } from 'src/assets/icons/program-filled.svg'
import { ReactComponent as UserFilled } from 'src/assets/icons/user-filled.svg'

import { ReactComponent as Broadcast } from 'src/assets/icons/broadcast.svg'

import { ReactComponent as VideoEngine } from 'src/assets/icons/video-engine.svg'

// debug icons
import { ReactComponent as Debug } from 'src/assets/icons/debug.svg'

export type IconName =
  | 'activity'
  | 'admin'
  | 'admin-filled'
  | 'angle-double-down'
  | 'angle-double-up'
  | 'angle-left'
  | 'angle-right'
  | 'arrow-right'
  | 'ban'
  | 'bars'
  | 'broadcast'
  | 'card'
  | 'channel'
  | 'channel-filled'
  | 'check-circle'
  | 'check-circle-solid'
  | 'close'
  | 'clipboard'
  | 'company'
  | 'copy'
  | 'dashboard'
  | 'debug'
  | 'denied'
  | 'edit'
  | 'exclamation-circle'
  | 'exclamation-circle-solid'
  | 'eye'
  | 'eye-slash'
  | 'filter'
  | 'fit'
  | 'fullscreen-on'
  | 'fullscreen-off'
  | 'group'
  | 'group-filled'
  | 'group-company'
  | 'group-company-filled'
  | 'minus-circle'
  | 'minus-circle-solid'
  | 'mixer'
  | 'notice'
  | 'offline'
  | 'pause-circle'
  | 'play-circle'
  | 'program'
  | 'program-filled'
  | 'project'
  | 'restart'
  | 'save'
  | 'search'
  | 'settings'
  | 'sliders'
  | 'sort'
  | 'sort-alt'
  | 'streamhub'
  | 'support'
  | 'user-filled'
  | 'user-company'
  | 'user-project'
  | 'user-org-admin'
  | 'user-project-admin'
  | 'user-project-manager'
  | 'user-link'
  | 'user-guest'
  | 'viewer'
  | 'video-quality' // alias
  | 'video-feed'
  | 'volume-mute'
  | 'volume-off'
  | 'warning'
  | 'zoom-in'
  | 'zoom-out'
  | 'padlock-closed'
  | 'video-engine';

interface ArkIconProps {
  className?: string;
  color?: string;
  name: IconName;
  size?: number;
}

const ArkIcon = (props: ArkIconProps) => {
  const { className, color = 'var(--tx-light)', name, size = 28 } = props

  let Icon: FC<SVGProps<SVGSVGElement>>
  switch (name) {
    case 'activity': Icon = Activity; break
    case 'admin': Icon = Admin; break
    case 'admin-filled': Icon = AdminFilled; break
    case 'angle-double-down': Icon = AngleDoubleDown; break
    case 'angle-double-up': Icon = AngleDoubleUp; break
    case 'angle-left': Icon = AngleLeft; break
    case 'angle-right': Icon = AngleRight; break
    case 'arrow-right': Icon = ArrowRight; break
    case 'ban': Icon = Ban; break
    case 'bars': Icon = Bars; break
    case 'broadcast': Icon = Broadcast; break
    case 'card': Icon = Card; break
    case 'channel': Icon = Channel; break
    case 'channel-filled': Icon = ChannelFilled; break
    case 'check-circle': Icon = CheckCircle; break
    case 'check-circle-solid': Icon = CheckCircleSolid; break
    case 'close': Icon = Close; break
    case 'clipboard': Icon = Clipboard; break
    case 'company': Icon = Company; break
    case 'copy': Icon = Copy; break
    case 'dashboard': Icon = Dashboard; break
    case 'debug': Icon = Debug; break
    case 'denied': Icon = Denied; break
    case 'edit': Icon = Edit; break
    case 'exclamation-circle': Icon = ExclamationCircle; break
    case 'exclamation-circle-solid': Icon = ExclamationCircleSolid; break
    case 'eye': Icon = Eye; break
    case 'eye-slash': Icon = EyeSlash; break
    case 'filter': Icon = Filter; break
    case 'fit': Icon = Fit; break
    case 'fullscreen-on': Icon = FullscreenOn; break
    case 'fullscreen-off': Icon = FullscreenOff; break
    case 'group': Icon = Group; break
    case 'group-filled': Icon = GroupFilled; break
    case 'group-company': Icon = GroupCompany; break
    case 'group-company-filled': Icon = GroupCompanyFilled; break
    case 'minus-circle': Icon = MinusCircle; break
    case 'minus-circle-solid': Icon = MinusCircleSolid; break
    case 'mixer': Icon = Mixer; break
    case 'notice': Icon = Notice; break
    case 'offline': Icon = Offline; break
    case 'pause-circle': Icon = PauseCircle; break
    case 'play-circle': Icon = PlayCircle; break
    case 'program': Icon = Program; break
    case 'program-filled': Icon = ProgramFilled; break
    case 'project': Icon = Project; break
    case 'restart': Icon = Restart; break
    case 'save': Icon = Save; break
    case 'search': Icon = Search; break
    case 'video-quality': // alias
    case 'settings': Icon = Settings; break
    case 'sliders': Icon = Sliders; break
    case 'sort': Icon = Sort; break
    case 'sort-alt': Icon = SortAlt; break
    case 'streamhub': Icon = Streamhub; break
    case 'support': Icon = Support; break
    case 'user-filled': Icon = UserFilled; break
    case 'user-company': Icon = UserCompany; break
    case 'user-org-admin': Icon = UserOrgAdmin; break
    case 'user-project-admin': Icon = UserProjectAdmin; break
    case 'user-project-manager': Icon = UserProjectManager; break
    case 'user-guest': Icon = UserGuest; break
    case 'user-link': Icon = UserLink; break
    case 'user-project': Icon = UserProject; break
    case 'video-feed': Icon = VideoFeed; break
    case 'viewer': Icon = Viewer; break
    case 'volume-mute': Icon = VolumeMute; break
    case 'volume-off': Icon = VolumeOff; break
    case 'warning': Icon = Warning; break
    case 'zoom-in': Icon = ZoomIn; break
    case 'zoom-out': Icon = ZoomOut; break
    case 'padlock-closed': Icon = PadlockClosed; break
    case 'video-engine': Icon = VideoEngine; break
  }

  return (
    <Icon
      className={className}
      fill={color}
      height={size}
      // style={{ backgroundColor: 'rgba(255, 0, 0, 0.25)' }} // DEBUG
    />
  )
}

export default ArkIcon
