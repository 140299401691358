import { IVideoEngineDataSlice, IVideoEngineLiveStream, IVideoEngineRealtimeStats, IVideoEngineRealtimeStream, IVideoEngineServer } from '../models/StreamhubVideoEngineModels'
import StreamhubAPIClient from './StreamhubAPIClient'

export default class StreamhubVideoEngineAPI {
  apiClient: StreamhubAPIClient

  constructor (apiClient: StreamhubAPIClient) {
    this.apiClient = apiClient
  }

  // -------

  fetchVideoEngineServers = async (): Promise<Array<IVideoEngineServer>> => {
    try {
      const response = await this.apiClient.apiGet('/video-engine/servers', {})
      const servers: Array<IVideoEngineServer> = []
      if (response.data && response.data.servers) {
        const serversData = response.data.servers
        for (const serverData of serversData) {
          const server = serverData as IVideoEngineServer // TODO: add a concrete `VideoEngineServer` class with a `VideoEngineServer.fromJSON(data)` helper & flip to use it here?
          if (server) servers.push(server)
        }
      }
      return servers
    } catch (error) {
      console.error('StreamhubVideoEngineAPI - fetchVideoEngineServers - error: ', error)
      throw error
    }
  }

  fetchVideoEngineServer = async (serverId: string): Promise<IVideoEngineServer> => {
    try {
      const response = await this.apiClient.apiGet('/video-engine/servers/' + serverId, {})
      if (response.data && response.data.server) {
        return response.data.server as IVideoEngineServer // TODO: add a concrete `VideoEngineServer` class with a `VideoEngineServer.fromJSON(data)` helper & flip to use it here?
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('StreamhubVideoEngineAPI - fetchVideoEngineServer - error: ', error)
      throw error
    }
  }

  fetchVideoEngineDataSlices = async (): Promise<Array<IVideoEngineDataSlice>> => {
    try {
      const response = await this.apiClient.apiGet('/video-engine/data-slices', {})
      const dataSlices: Array<IVideoEngineDataSlice> = []
      if (response.data && response.data.dataSlices) {
        const dataSlicesData = response.data.dataSlices
        for (const dataSliceData of dataSlicesData) {
          const dataSlice = dataSliceData as IVideoEngineDataSlice // TODO: add a concrete `VideoEngineDataSlice` class with a `VideoEngineDataSlice.fromJSON(data)` helper & flip to use it here?
          if (dataSlice) dataSlices.push(dataSlice)
        }
      }
      return dataSlices
    } catch (error) {
      console.error('StreamhubVideoEngineAPI - fetchVideoEngineDataSlices - error: ', error)
      throw error
    }
  }

  fetchVideoEngineRealtimeStats = async (serverId: string, dataSliceId: string, streamPath?: string): Promise<IVideoEngineRealtimeStats> => {
    try {
      const args = streamPath ? ['streamPath=' + streamPath] : []
      const response = await this.apiClient.apiGet('/video-engine/stats/realtime/' + serverId + '/' + dataSliceId + '?' + args.join('&'), {})
      if (response.data && response.data.realtimeStats) {
        return response.data.realtimeStats as IVideoEngineRealtimeStats // TODO: add a concrete `VideoEngineRealtimeStats` class with a `VideoEngineRealtimeStats.fromJSON(data)` helper & flip to use it here?
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('StreamhubVideoEngineAPI - fetchVideoEngineRealtimeStats - error: ', error)
      throw error
    }
  }

  fetchVideoEngineRealtimeStreams = async (serverId: string, dataSliceId: string): Promise<Array<IVideoEngineRealtimeStream>> => {
    try {
      const response = await this.apiClient.apiGet('/video-engine/streams/realtime/' + serverId + '/' + dataSliceId, {})
      if (response.data && response.data.streams) {
        return response.data.streams as Array<IVideoEngineRealtimeStream> // TODO: add a concrete `VideoEngineRealtimeStream` class with a `VideoEngineRealtimeStream.fromJSON(data)` helper & flip to use it here?
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('StreamhubVideoEngineAPI - fetchVideoEngineRealtimeStreams - error: ', error)
      throw error
    }
  }

  // status = optionally filter by the status field (values: 'online' or 'offline')
  // protocol = optionally filter by the protocol field (e.g values: 'MPEGTS' to show only the passthrough streams, 'ENCODER' to show only the transcoded ones, likely others not yet known)
  fetchVideoEngineLiveStreams = async (serverId: string, status?: string, protocol?: string): Promise<Array<IVideoEngineLiveStream>> => {
    try {
      const args: Array<string> = []
      if (status) args.push('status=' + status)
      if (protocol) args.push('protocol=' + protocol)
      const response = await this.apiClient.apiGet('/video-engine/streams/live/' + serverId + '?' + args.join('&'), {})
      if (response.data && response.data.liveStreams) {
        return response.data.liveStreams as Array<IVideoEngineLiveStream> // TODO: add a concrete `VideoEngineLiveStream` class with a `VideoEngineLiveStream.fromJSON(data)` helper & flip to use it here?
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('StreamhubVideoEngineAPI - fetchVideoEngineLiveStreams - error: ', error)
      throw error
    }
  }

  // -------
}
