/**
 * CompanyLoginServiceSettingsView
 *
 * WARNING:
 *  - this settings view currently ONLY supports Okta properly
 *  - original trial Auth0 support was only partially implemented & isn't currently used, so is being hidden for now
 * NB:
 *  - if re-enabling Auth0 support in the future (or adding or SSO service types), various areas of this view will need extending
 *  - so different service types can be properly supported instead of hard-coded to be okta specific
 */
import React, { useContext, useEffect, useState } from 'react'

import { Company, CompanyLoginService, CompanyLoginServiceConfigOptions, CompanyLoginServiceConfigOptionsOktaOIDC, CompanyLoginServiceConfigOptionsOktaSAML, UserCompany } from 'src/core/models'
import { CompanyAdminContext, GlobalConfigContext, UserContext } from 'src/core/providers'

import { CompanyLoginServiceConfigFormOktaOIDC } from './CompanyLoginServiceConfigFormOktaOIDC'
import { CompanyLoginServiceConfigFormOktaSAML } from './CompanyLoginServiceConfigFormOktaSAML'

import ArkConfirmModal from 'src/core/components/ArkConfirmModal'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkModal from 'src/core/components/ArkModal'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkRadio, { ArkRadioProps } from 'src/core/components/ArkRadio'

import { COMPANY_LOGIN_SERVICE_SSO_OKTA_SAML_ENABLED, COMPANY_LOGIN_SERVICE_TYPE_AUTH0, COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC, COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML } from 'src/constants/config'

import { OBJECT_COMPANY_NAME } from 'src/constants/strings'

import styles from './CompanySettingsView.module.css'

enum CompanyLoginServiceSaveResult {
  Enabled,
  Disabled,
  Updated
}

export interface CompanyLoginServiceSettingsViewProps {
  company: Company | UserCompany
}

const CompanyLoginServiceSettingsView = (props: CompanyLoginServiceSettingsViewProps) => {
  const { company } = props

  const userContext = useContext(UserContext)
  const companyAdminContext = useContext(CompanyAdminContext)
  const { store: configStore } = useContext(GlobalConfigContext)

  const isSiteAdmin = userContext.actions.isSiteAdmin()

  const [showConfirmAlert, setShowConfirmAlert] = useState<boolean>(false)

  const [showConfigModalOktaOIDC, setShowConfigModalOktaOIDC] = useState<boolean>(false)
  const [showConfigModalOktaSAML, setShowConfigModalOktaSAML] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false) // NB: only used until the Company object contains the login service settings/data (currently requires a dedicated api call)
  const [loadingError, setLoadingError] = useState<Error | undefined>(undefined)

  const [loginServiceInstanceIdOktaOIDC, setLoginServiceInstanceIdOktaOIDC] = useState<number | undefined>(undefined)
  const [loginServiceInstanceIdOktaSAML, setLoginServiceInstanceIdOktaSAML] = useState<number | undefined>(undefined)
  const [loginServiceInstanceIdAuth0, setLoginServiceInstanceIdAuth0] = useState<number | undefined>(undefined)

  // TODO: is this actually needed anymore? looks like it was only previously needed for a confirm option after submitting the enable sso form?
  // TODO: ..but we now only show the confirm when disabling, which we don't need these options for?
  // TODO: ..its only seems to be used as a fallback when enabling/adding a new login service currently, which we already pass in the same value directly?
  // TODO: ..if that is the case, remove this & any of its usage, plus rename the confirm modal to be delete/disable specific???? <<<<
  const [loginServiceConfigOptions, setLoginServiceConfigOptions] = useState<CompanyLoginServiceConfigOptions | undefined>(undefined)

  const [loginServices, setLoginServices] = useState<Array<CompanyLoginService> | undefined>(undefined)

  const [saving, setSaving] = useState<boolean>(false)
  const [saveResult, setSaveResult] = useState<CompanyLoginServiceSaveResult | undefined>(undefined)
  const [saveError, setSaveError] = useState<Error | undefined>(undefined)

  // individual settings specific to this form
  // TODO: once the api company result includes the login service settings/data, we can set these values directly here instead of via `loadData`
  const [companyValues, setCompanyValues] = useState({
    // NB: updated once `loadData` has loaded the company login service settings/data
    enableOktaOIDC: false,
    enableOktaSAML: false,
    enableAuth0: false
  })
  const [companyValuesSaved, setCompanyValuesSaved] = useState({
    // NB: updated once `loadData` has loaded the company login service settings/data
    enableOktaOIDC: false,
    enableOktaSAML: false,
    enableAuth0: false
  })
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])

  // -------

  const companySettingsChanges = () => {
    const _changes: Array<string> = []
    if (companyValues) {
      for (const fieldName of Object.keys(companyValues)) {
        const oldValue = companyValuesSaved !== undefined ? (companyValuesSaved as any)[fieldName] : undefined
        const newValue = (companyValues as any)[fieldName]
        if (oldValue !== newValue) {
          _changes.push(fieldName)
        }
      }
    }
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  const resetSaveResults = () => {
    setSaveResult(undefined)
    setSaveError(undefined)
  }

  const toggleElement = (serviceType: number, valueKey: string, showSettingsBtn: boolean = false, siteAdminOnly: boolean = false) => {
    const value = (companyValues as any)[valueKey]
    console.log('CompanyLoginServiceSettingsView - toggleElement - serviceType:', serviceType, ' valueKey:', valueKey, ' value:', value)
    if (siteAdminOnly && !isSiteAdmin) return (value ? 'ENABLED' : <span className={styles.propertyValueOff}>DISABLED</span>) // just display the value if the user isn't a site admin or higher
    const disabled = saving
    // allow editing of the service settings if the service is enabled OR if its disabled but the login service entry exists (so we can still edit its config options), vs the entry not existing at all yet (its created when its first enabled)
    const loginService = loginServices?.find((lm: CompanyLoginService) => lm.serviceType === serviceType)
    const loginServiceDisabled = loginService ? !loginService.enabled : false
    const canEditSettings = showSettingsBtn && (value === true || (loginService !== undefined && loginServiceDisabled))
    return (
      <>
        <ArkRadio
          toggle
          checked={value}
          disabled={disabled}
          onChange={(event: React.FormEvent<HTMLInputElement>, data: ArkRadioProps) => {
            console.log('CompanyLoginServiceSettingsView - toggleElement - radio - onChange - valueKey:', valueKey, ' data.checked:', data.checked)
            setCompanyValues({ ...companyValues, [valueKey]: data.checked })
            resetSaveResults()
            // NB: see the useEffect that runs settingsChangeCount & updates setProgramChangeCount from it after the setValue state update completes
            // TESTING: auto prompt the user to confirm the change as soon as its made (no submit button)
            const savedValue = (companyValuesSaved as any)[valueKey]
            if (data.checked !== savedValue) {
              // TESTING: okta now shows the config options form when enabling it before the final confirmation (or perhaps in place of it)
              if (valueKey === 'enableOktaOIDC' && data.checked) {
                setShowConfigModalOktaOIDC(true)
              } else if (valueKey === 'enableOktaSAML' && data.checked) {
                setShowConfigModalOktaSAML(true)
              } else {
                setShowConfirmAlert(true)
              }
            }
          }}
        />
        {showSettingsBtn && (
          <ArkIconButton
            className={styles.menuButton}
            name='settings'
            onClick={() => {
              console.log('CompanyLoginServiceSettingsView - toggleElement - settings-btn - onClick - valueKey:', valueKey)
              if (valueKey === 'enableOktaOIDC') {
                setShowConfigModalOktaOIDC(true)
              } else if (valueKey === 'enableOktaSAML') {
                setShowConfigModalOktaSAML(true)
              } else {
                console.error('CompanyLoginServiceSettingsView - toggleElement - settings-btn - onClick - ERROR: unknown valueKey:', valueKey)
              }
            }}
            size={24}
            disabled={!canEditSettings}
          />
        )}
      </>
    )
  }

  // -------

  const loadData = async () => {
    console.log('CompanyLoginServiceSettingsView - loadData')
    if (loadingError) return
    if (loading) return
    setLoading(true)

    // DEBUG ONLY: mock submit...
    // await new Promise((resolve) => setTimeout(resolve, 2000)) // DBG ONLY
    // setLoadingError(new Error('Loading'))

    // load & check if the company has the okta login service enabled
    // NB: ONLY currently supports/handles one login service being enabled, if multiple are only the first is currently used/loaded
    // TODO: if we want to support multiple login services being enabled, we'll need to update the form/data to handle multiple entries
    try {
      const _loginServices = await companyAdminContext.actions.getCompanyLoginServices(company.id)
      console.log('CompanyLoginServiceSettingsView - loadData - _loginServices:', _loginServices)
      if (_loginServices && _loginServices.length > 0) {
        setLoginServices(_loginServices)
        const loginServiceOktaOIDC = _loginServices.find((lm: CompanyLoginService) => lm.serviceType === COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC)
        const loginServiceOktaSAML = _loginServices.find((lm: CompanyLoginService) => lm.serviceType === COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML)
        const loginServiceAuth0 = _loginServices.find((lm: CompanyLoginService) => lm.serviceType === COMPANY_LOGIN_SERVICE_TYPE_AUTH0)
        if (loginServiceOktaOIDC) {
          // note/track the org/company specific id for the okta login service
          setLoginServiceInstanceIdOktaOIDC(loginServiceOktaOIDC.instanceId)
          // update the form values (NB: make sure to update the saved values first so the field-changes check works correctly)
          setCompanyValuesSaved({ ...companyValues, enableOktaOIDC: loginServiceOktaOIDC.enabled })
          setCompanyValues({ ...companyValues, enableOktaOIDC: loginServiceOktaOIDC.enabled })
          // resetSaveResults()
        }
        if (loginServiceOktaSAML) {
          // note/track the org/company specific id for the okta login service
          setLoginServiceInstanceIdOktaSAML(loginServiceOktaSAML.instanceId)
          // update the form values (NB: make sure to update the saved values first so the field-changes check works correctly)
          setCompanyValuesSaved({ ...companyValues, enableOktaSAML: loginServiceOktaSAML.enabled })
          setCompanyValues({ ...companyValues, enableOktaSAML: loginServiceOktaSAML.enabled })
          // resetSaveResults()
        }
        if (loginServiceAuth0) {
          // note/track the org/company specific id for the okta login service
          setLoginServiceInstanceIdAuth0(loginServiceAuth0.instanceId)
          // update the form values (NB: make sure to update the saved values first so the field-changes check works correctly)
          setCompanyValuesSaved({ ...companyValues, enableAuth0: loginServiceAuth0.enabled })
          setCompanyValues({ ...companyValues, enableAuth0: loginServiceAuth0.enabled })
          // resetSaveResults()
        }
      } else {
        setLoginServices(undefined)
      }

      setLoading(false)
    } catch (error) {
      setLoadingError(new Error('Loading'))
      setLoading(false)
    }
  }

  useEffect(() => {
    async function loadAsync () {
      await loadData()
    }
    if (!loading) {
      loadAsync()
    }
  }, [])

  // -------

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = companySettingsChanges()
    setChanges(_changes)
  }, [companyValues])

  // -------

  const onCancel = () => {
    console.log('CompanyLoginServiceSettingsView - onCancel')
  }

  const onClose = () => {
    console.log('CompanyLoginServiceSettingsView - onClose - saving: ', saving)
    setShowConfirmAlert(false)
    if (!saving) setCompanyValues(companyValuesSaved) // reset the form back to the saved values
  }

  // add, enabled or disable the sso login service
  // - if toggling on the sso login service it will now 'add' it if it doesn't exist yet, or 'enable' it if it already does but is currently disabled
  // - if toggling off the sso login service it will only 'disable' it if it exists, theres now a dedicated delete button to actually remove it (clearing all its config values along with it)
  // NB: we allow the config options to be passed in directly as the `useState` based `setLoginServiceConfigOptions` may not have updated yet
  const addEnableOrDisableLoginService = async (_configOptions?: CompanyLoginServiceConfigOptions) => {
    console.log('CompanyLoginServiceSettingsView - addEnableOrDisableLoginService - companyValues:', companyValues, ' companyValuesSaved:', companyValuesSaved, ' changes:', changes, ' loginServiceConfigOptions:', loginServiceConfigOptions, ' _configOptions:', _configOptions)
    if (saving) return
    setSaving(true)
    resetSaveResults()

    // // DEBUG ONLY: mock submit...
    // const mockSuccess = async () => {
    //   await new Promise((resolve) => setTimeout(resolve, 2000)) // DBG ONLY
    //   const force2faNewVal = !companyValuesSaved.force2fa
    //   setCompanyValuesSaved({ force2fa: force2faNewVal })
    //   setCompanyValues({ force2fa: force2faNewVal })
    //   setSaveResult(true)
    // }
    // const mockError = async () => {
    //   await new Promise((resolve) => setTimeout(resolve, 2000)) // DBG ONLY
    //   setSaveError(new Error('DEBUG ERROR'))
    //   setSaveResult(false)
    //   setCompanyValues(companyValuesSaved) // reset the form back to the saved values
    //   setChanges([])
    // }
    // // await mockSuccess()
    // await mockError()

    let loginServiceId: number | undefined
    let loginServiceEnable: boolean | undefined
    try {
      // handle the sso login service change (NB: only handles a single sso toggle change at a time)
      if (changes.length > 0) {
        if (changes.includes('enableOktaOIDC')) {
          loginServiceId = COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC
          loginServiceEnable = companyValues.enableOktaOIDC
        } else if (changes.includes('enableOktaSAML')) {
          loginServiceId = COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML
          loginServiceEnable = companyValues.enableOktaSAML
        } else if (changes.includes('enableAuth0')) {
          loginServiceId = COMPANY_LOGIN_SERVICE_TYPE_AUTH0
          loginServiceEnable = companyValues.enableAuth0
        }
        console.log('CompanyLoginServiceSettingsView - addEnableOrDisableLoginService - loginServiceId:', loginServiceId, ' loginServiceEnable:', loginServiceEnable)
        // make sure we have a login service id & enable/disable status to process
        if (loginServiceId !== undefined && loginServiceEnable !== undefined) {
          let loginServiceInstanceId: number | undefined
          if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC) {
            loginServiceInstanceId = loginServiceInstanceIdOktaOIDC
          } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML) {
            loginServiceInstanceId = loginServiceInstanceIdOktaSAML
          } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_AUTH0) {
            loginServiceInstanceId = loginServiceInstanceIdAuth0
          }
          console.log('CompanyLoginServiceSettingsView - addEnableOrDisableLoginService - loginServiceInstanceId:', loginServiceInstanceId)
          // add/enable the login service
          if (loginServiceEnable) {
            // TODO: check if the login service already exists & just needs enabling, or if it needs to be added first
            if (loginServiceInstanceId !== undefined) {
              // TODO: login service entry already exists - enable it (if its not already)
              console.log('CompanyLoginServiceSettingsView - addEnableOrDisableLoginService - TODO: enable existing login service <<<<')
              // UPDATE: now calling via `updateLoginServiceConfigOptions` from the form response instead of handling here
            } else {
              // login service doesn't exist - add it
              const addedCompanyLoginService = await companyAdminContext.actions.addCompanyLoginService(company.id, loginServiceId, _configOptions ?? loginServiceConfigOptions)
              console.log('CompanyLoginServiceSettingsView - addEnableOrDisableLoginService - addCompanyLoginService - addedCompanyLoginService:', addedCompanyLoginService)
              if (addedCompanyLoginService) {
                // note/track the org/company specific id for the sso login service
                // & update the form values (NB: make sure to update the saved values first so the field-changes check works correctly)
                if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC) {
                  setLoginServiceInstanceIdOktaOIDC(addedCompanyLoginService.instanceId)
                  setCompanyValuesSaved({ ...companyValues, enableOktaOIDC: addedCompanyLoginService.serviceType === loginServiceId })
                  setCompanyValues({ ...companyValues, enableOktaOIDC: addedCompanyLoginService.serviceType === loginServiceId })
                } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML) {
                  setLoginServiceInstanceIdOktaSAML(addedCompanyLoginService.instanceId)
                  setCompanyValuesSaved({ ...companyValues, enableOktaSAML: addedCompanyLoginService.serviceType === loginServiceId })
                  setCompanyValues({ ...companyValues, enableOktaSAML: addedCompanyLoginService.serviceType === loginServiceId })
                } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_AUTH0) {
                  setLoginServiceInstanceIdAuth0(addedCompanyLoginService.instanceId)
                  setCompanyValuesSaved({ ...companyValues, enableAuth0: addedCompanyLoginService.serviceType === loginServiceId })
                  setCompanyValues({ ...companyValues, enableAuth0: addedCompanyLoginService.serviceType === loginServiceId })
                }
                // // NB: TEMP: only okta is currently supported for config options (& updating them)
                // const loginServiceInstanceId: number | undefined = loginServiceInstanceIdOkta // TODO: not set yet - needs to be grabbed from the response instead (& also set the state var as well?)
                // console.log('CompanyLoginServiceSettingsView - addEnableOrDisableLoginService - addCompanyLoginService - loginServiceInstanceId:', loginServiceInstanceId)
                // if (loginServiceInstanceId) {
                //   const _loginServices = loginServices ? [...loginServices] : []
                //   const _loginServicesIndex = loginServices?.indexOf(loginServices.find((lm: CompanyLoginService) => lm.instanceId === loginServiceInstanceId) as CompanyLoginService)
                //   if (_loginServicesIndex !== undefined && _loginServicesIndex >= 0) {
                //     _loginServices[_loginServicesIndex] = addedCompanyLoginService
                //   } else {
                //     _loginServices.push(addedCompanyLoginService)
                //   }
                //   setLoginServices(_loginServices)
                //   console.log('CompanyLoginServiceSettingsView - addEnableOrDisableLoginService - addCompanyLoginService - _loginServices:', _loginServices)
                // }
                // setLoginServiceConfigOptions(addedCompanyLoginService.configOptions) // TODO: when is this used? should it also be updated when the login service is added?
                // TESTING: easier to just reload all the service data after adding a new entry...
                await loadData()
                setChanges([])
                setSaveResult(CompanyLoginServiceSaveResult.Enabled)
              } else {
                throw new Error('Invalid response')
              }
            }
          } else { // disabling the login service
            if (loginServiceInstanceId !== undefined) {
              // const deleteResult = await companyAdminContext.actions.deleteCompanyLoginService(company.id, loginServiceInstanceId)
              // console.log('CompanyLoginServiceSettingsView - addEnableOrDisableLoginService - deleteCompanyLoginService - deleteResult:', deleteResult)
              // if (deleteResult) {
              const disableResult = await companyAdminContext.actions.enableDisableCompanyLoginService(company.id, loginServiceInstanceId, false)
              console.log('CompanyLoginServiceSettingsView - addEnableOrDisableLoginService - deleteCompanyLoginService - disableResult:', disableResult)
              if (disableResult) {
                // clear the previous org/company specific id for the sso login service (no longer exists)
                // & update the form values (NB: make sure to update the saved values first so the field-changes check works correctly)
                if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC) {
                  // setLoginServiceInstanceIdOkta(undefined) // NB: commented out - don't wipe the instance id now we 'disable' here instead of 'delete' (so we can still edit its config options)
                  setCompanyValuesSaved({ ...companyValues, enableOktaOIDC: false })
                  setCompanyValues({ ...companyValues, enableOktaOIDC: false })
                } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML) {
                  // setLoginServiceInstanceIdOkta(undefined) // NB: commented out - don't wipe the instance id now we 'disable' here instead of 'delete' (so we can still edit its config options)
                  setCompanyValuesSaved({ ...companyValues, enableOktaSAML: false })
                  setCompanyValues({ ...companyValues, enableOktaSAML: false })
                } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_AUTH0) {
                  // setLoginServiceInstanceIdAuth0(undefined) // NB: commented out - don't wipe the instance id now we 'disable' here instead of 'delete' (so we can still edit its config options)
                  setCompanyValuesSaved({ ...companyValues, enableAuth0: false })
                  setCompanyValues({ ...companyValues, enableAuth0: false })
                }
                // NB: TEMP: only okta is currently supported for config options (& updating them)
                let loginServiceInstanceId: number | undefined
                if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC) {
                  loginServiceInstanceId = loginServiceInstanceIdOktaOIDC
                } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML) {
                  loginServiceInstanceId = loginServiceInstanceIdOktaSAML
                }
                if (loginServiceInstanceId) {
                  const _loginServices = loginServices ? [...loginServices] : []
                  const _loginServicesIndex = loginServices?.indexOf(loginServices.find((lm: CompanyLoginService) => lm.instanceId === loginServiceInstanceId) as CompanyLoginService)
                  if (_loginServicesIndex !== undefined && _loginServicesIndex >= 0) {
                    // _loginServices.splice(_loginServicesIndex, 1)
                    _loginServices[_loginServicesIndex].enabled = false
                  }
                  setLoginServices(_loginServices)
                  console.log('CompanyLoginServiceSettingsView - addEnableOrDisableLoginService - addCompanyLoginService - _loginServices:', _loginServices)
                }
                // setLoginServiceConfigOptions(undefined) // TODO: when is this used? should it also be updated/reset when the login service is deleted?
                setChanges([])
                setSaveResult(CompanyLoginServiceSaveResult.Disabled)
              } else {
                throw new Error('Invalid response')
              }
            } else {
              throw new Error('Missing SSO service details')
            }
          }
        } else {
          console.log('CompanyLoginServiceSettingsView - addEnableOrDisableLoginService - WARNING: NO login service id and/or status value set')
          throw new Error('Missing SSO service details')
        }
      } else {
        console.log('CompanyLoginServiceSettingsView - addEnableOrDisableLoginService - WARNING: NO sso changes to submit')
        throw new Error('No SSO changes to save')
      }
    } catch (error) {
      console.error('CompanyLoginServiceSettingsView - addEnableOrDisableLoginService - error:', error)
      setSaveResult(loginServiceEnable ? CompanyLoginServiceSaveResult.Enabled : CompanyLoginServiceSaveResult.Disabled)
      setSaveError(error)
      setCompanyValues(companyValuesSaved) // reset the form back to the saved values
      setChanges([])
    }
    setSaving(false)
  }

  const updateLoginServiceConfigOptions = async (loginServiceId: number, _configOptions: CompanyLoginServiceConfigOptions, enabled?: boolean) => {
    console.log('CompanyLoginServiceSettingsView - updateLoginServiceConfigOptions - loginServiceId:', loginServiceId, ' _configOptions:', _configOptions, ' enabled:', enabled)
    if (saving) return
    setSaving(true)
    resetSaveResults()
    try {
      let loginServiceInstanceId: number | undefined
      if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC) {
        loginServiceInstanceId = loginServiceInstanceIdOktaOIDC
      } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML) {
        loginServiceInstanceId = loginServiceInstanceIdOktaSAML
      } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_AUTH0) {
        loginServiceInstanceId = loginServiceInstanceIdAuth0
      }
      console.log('CompanyLoginServiceSettingsView - updateLoginServiceConfigOptions - loginServiceInstanceId:', loginServiceInstanceId)
      if (loginServiceInstanceId) {
        const updateResult = await companyAdminContext.actions.updateCompanyLoginService(company.id, loginServiceInstanceId, _configOptions, enabled)
        console.log('CompanyLoginServiceSettingsView - updateLoginServiceConfigOptions - updateResult:', updateResult, ' enabled:', enabled)
        if (updateResult) {
          // update the login service entry (in the state array of login services)
          const _loginServices = loginServices ? [...loginServices] : []
          const _loginServicesIndex = loginServices?.indexOf(loginServices.find((lm: CompanyLoginService) => lm.instanceId === loginServiceInstanceId) as CompanyLoginService)
          if (_loginServicesIndex !== undefined && _loginServicesIndex >= 0) _loginServices[_loginServicesIndex] = updateResult
          setLoginServices(_loginServices)
          // update the dedicated login config options state var (while we have one)
          setLoginServiceConfigOptions(updateResult.configOptions)
          // TESTING: also handling the `enabled` status change here as well (if its been passed in)
          if (enabled !== undefined) {
            if (updateResult.serviceType === COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC) {
              setCompanyValuesSaved({ ...companyValues, enableOktaOIDC: enabled })
              setCompanyValues({ ...companyValues, enableOktaOIDC: enabled })
            } else if (updateResult.serviceType === COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML) {
              setCompanyValuesSaved({ ...companyValues, enableOktaSAML: enabled })
              setCompanyValues({ ...companyValues, enableOktaSAML: enabled })
            } else if (updateResult.serviceType === COMPANY_LOGIN_SERVICE_TYPE_AUTH0) {
              setCompanyValuesSaved({ ...companyValues, enableAuth0: enabled })
              setCompanyValues({ ...companyValues, enableAuth0: enabled })
            }
          }
          setChanges([])
          // NB: disabling shouldn't happen here (it should go via `addEnableOrDisableLoginService` instead), but just incase it does we set the save result accordingly...
          if (enabled === true) {
            console.log('CompanyLoginServiceSettingsView - updateLoginServiceConfigOptions - CompanyLoginServiceSaveResult.Enabled')
            setSaveResult(CompanyLoginServiceSaveResult.Enabled)
          } else if (enabled === false) {
            console.log('CompanyLoginServiceSettingsView - updateLoginServiceConfigOptions - CompanyLoginServiceSaveResult.Disabled')
            setSaveResult(CompanyLoginServiceSaveResult.Disabled)
          } else { // enabled === undefined
            console.log('CompanyLoginServiceSettingsView - updateLoginServiceConfigOptions - CompanyLoginServiceSaveResult.Updated')
            setSaveResult(CompanyLoginServiceSaveResult.Updated)
          }
        } else {
          console.log('CompanyLoginServiceSettingsView - updateLoginServiceConfigOptions - updateCompanyLoginService - TODO: <<<<<')
          // TODO: <<<<
        }
      } else {
        throw new Error('Missing SSO service details')
      }
    } catch (error) {
      console.error('CompanyLoginServiceSettingsView - updateLoginServiceConfigOptions - error:', error)
      // NB: disabling shouldn't happen here (it should go via `addEnableOrDisableLoginService` instead), but just incase it does we set the save result accordingly...
      if (enabled === true) {
        setSaveResult(CompanyLoginServiceSaveResult.Enabled)
      } else if (enabled === false) {
        setSaveResult(CompanyLoginServiceSaveResult.Disabled)
      } else {
        setSaveResult(CompanyLoginServiceSaveResult.Updated)
      }
      setSaveError(error)
      setCompanyValues(companyValuesSaved) // reset the form back to the saved values
      setChanges([])
    }
    setSaving(false)
  }

  const updateLoginServiceConfigOptionsOktaOIDC = async (_configOptions: CompanyLoginServiceConfigOptions, enabled?: boolean) => {
    updateLoginServiceConfigOptions(COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC, _configOptions, enabled)
  }

  const updateLoginServiceConfigOptionsOktaSAML = async (_configOptions: CompanyLoginServiceConfigOptions, enabled?: boolean) => {
    updateLoginServiceConfigOptions(COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML, _configOptions, enabled)
  }

  // NB: currently hard-coded for just okta
  const deleteLoginService = async (loginServiceId: number) => {
    console.log('CompanyLoginServiceSettingsView - deleteLoginService')
    if (saving) return
    setSaving(true)
    resetSaveResults()
    try {
      let loginServiceInstanceId: number | undefined
      if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC) {
        loginServiceInstanceId = loginServiceInstanceIdOktaOIDC
      } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML) {
        loginServiceInstanceId = loginServiceInstanceIdOktaSAML
      } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_AUTH0) {
        loginServiceInstanceId = loginServiceInstanceIdAuth0
      }
      console.log('CompanyLoginServiceSettingsView - deleteLoginService - loginServiceInstanceId:', loginServiceInstanceId)
      if (loginServiceInstanceId !== undefined) {
        const deleteResult = await companyAdminContext.actions.deleteCompanyLoginService(company.id, loginServiceInstanceId)
        console.log('CompanyLoginServiceSettingsView - deleteLoginService - deleteResult:', deleteResult)
        if (deleteResult) {
          // clear the previous org/company specific id for the sso login service (no longer exists)
          // & update the form values (NB: make sure to update the saved values first so the field-changes check works correctly)
          if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC) {
            setLoginServiceInstanceIdOktaOIDC(undefined)
            setCompanyValuesSaved({ ...companyValues, enableOktaOIDC: false })
            setCompanyValues({ ...companyValues, enableOktaOIDC: false })
          } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML) {
            setLoginServiceInstanceIdOktaSAML(undefined)
            setCompanyValuesSaved({ ...companyValues, enableOktaSAML: false })
            setCompanyValues({ ...companyValues, enableOktaSAML: false })
          // } else if (loginServiceId === COMPANY_LOGIN_SERVICE_TYPE_AUTH0) {
          //   // setLoginServiceInstanceIdAuth0(undefined) // NB: commented out - don't wipe the instance id now we 'disable' here instead of 'delete' (so we can still edit its config options)
          //   setCompanyValuesSaved({ ...companyValues, enableAuth0: false })
          //   setCompanyValues({ ...companyValues, enableAuth0: false })
          }
          // NB: TEMP: only okta is currently supported for config options (& updating them)
          // const loginServiceInstanceId: number | undefined = loginServiceInstanceIdOkta
          // if (loginServiceInstanceId) {
          //   const _loginServices = loginServices ? [...loginServices] : []
          //   const _loginServicesIndex = loginServices?.indexOf(loginServices.find((lm: CompanyLoginService) => lm.instanceId === loginServiceInstanceId) as CompanyLoginService)
          //   if (_loginServicesIndex !== undefined && _loginServicesIndex >= 0) {
          //     // _loginServices.splice(_loginServicesIndex, 1)
          //     _loginServices[_loginServicesIndex].enabled = false
          //   }
          //   setLoginServices(_loginServices)
          //   console.log('CompanyLoginServiceSettingsView - deleteLoginService - _loginServices:', _loginServices)
          // }
          setLoginServiceConfigOptions(undefined) // TODO: when is this used? should it also be updated/reset when the login service is deleted?
          // TESTING: easier to just reload all the service data after adding a new entry...
          await loadData()
          setChanges([])
          setSaveResult(CompanyLoginServiceSaveResult.Disabled)
        } else {
          throw new Error('Invalid response')
        }
      } else {
        throw new Error('Missing SSO service details')
      }
    } catch (error) {
      console.error('CompanyLoginServiceSettingsView - deleteLoginService - error:', error)
      setSaveError(error)
      setChanges([])
    }
    setSaving(false)
  }

  const deleteLoginServiceOktaOIDC = async () => {
    deleteLoginService(COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC)
  }

  const deleteLoginServiceOktaSAML = async () => {
    deleteLoginService(COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML)
  }

  // -------

  const onSubmit = async () => {
    const isEnabling2FA = companyValues.enableOktaOIDC
    console.log('CompanyLoginServiceSettingsView - onSubmit - isEnabling2FA:', isEnabling2FA)
    addEnableOrDisableLoginService()
  }

  // -------

  const renderConfirmModal = () => {
    const isEnabling2FA = companyValues.enableOktaOIDC
    return (
      <ArkConfirmModal
        show={showConfirmAlert}
        title={<>{isEnabling2FA ? 'Enable' : 'Disable'} {OBJECT_COMPANY_NAME} Okta SSO?</>}
        message={<>
          <p>Are you sure you want to {isEnabling2FA ? 'enable' : 'disable'} Okta SSO for this {OBJECT_COMPANY_NAME}?</p>
          {/* {isEnabling2FA && (<p>All {OBJECT_COMPANY_NAME} users will be forced to enable &amp; use 2FA to login each time.</p>)}
          {!isEnabling2FA && (<p>All {OBJECT_COMPANY_NAME} users will no longer be forced to use 2FA to login.</p>)} */}
        </>}
        onCancel={onCancel}
        onConfirm={onSubmit}
        onClose={onClose}
      />
    )
  }

  // -------

  // const showConfigModalForm = () => {
  //   setShowConfigModal(true)
  // }

  const hideConfigModalFormOktaOIDC = () => {
    setShowConfigModalOktaOIDC(false)
  }

  const onCloseConfigModalOktaOIDC = () => {
    console.log('CompanyLoginServiceSettingsView - onCloseConfigModalOktaOIDC')
    hideConfigModalFormOktaOIDC()
  }

  // NB: currently only needed & used for the okta sso config options (auth0 has the options set by default api/server side)
  const renderConfigModalOktaOIDC = () => {
    const loginServiceOktaOIDC = loginServices?.find((lm: CompanyLoginService) => lm.serviceType === COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC)
    const toggleValue = (companyValues as any).enableOktaOIDC
    const isEnabling = (toggleValue === true && (loginServiceOktaOIDC === undefined || (loginServiceOktaOIDC !== undefined && loginServiceOktaOIDC.enabled === false)))
    const isDisabled = toggleValue === false && (loginServiceOktaOIDC !== undefined && loginServiceOktaOIDC.enabled !== true) // NB: this will be false if isEnabling is true
    console.log('CompanyLoginServiceSettingsView - renderConfigModalOktaOIDC - showConfigModalOktaOIDC:', showConfigModalOktaOIDC, ' loginServiceOktaOIDC:', loginServiceOktaOIDC, ' toggleValue:', toggleValue, ' isEnabling:', isEnabling, ' isDisabled:', isDisabled)
    return (
      <ArkModal
        size='tiny'
        open={showConfigModalOktaOIDC}
        onClose={() => {
          onCloseConfigModalOktaOIDC()
          if (!saving) setCompanyValues(companyValuesSaved) // reset the form back to the saved values
        }}
      >
        <CompanyLoginServiceConfigFormOktaOIDC
          configOptions={loginServiceOktaOIDC?.configOptions as CompanyLoginServiceConfigOptionsOktaOIDC}
          isEnabling={isEnabling}
          isDisabled={isDisabled}
          onCancel={() => {
            setLoginServiceConfigOptions(undefined) // TESTING HERE - TODO: what if the values were loaded from the api? (do we need to reset them back to the saved values?) <<<<<<
            hideConfigModalFormOktaOIDC()
            if (!saving) setCompanyValues(companyValuesSaved) // reset the form back to the saved values
          }}
          onConfirm={(_configOptions: CompanyLoginServiceConfigOptions) => {
            console.log('CompanyLoginServiceSettingsView - renderConfigModalOktaOIDC - onConfirm - _configOptions:', _configOptions, ' loginServiceOktaOIDC:', loginServiceOktaOIDC, ' loginServiceOktaOIDC?.configOptions:', loginServiceOktaOIDC?.configOptions)
            setLoginServiceConfigOptions(_configOptions)
            hideConfigModalFormOktaOIDC()
            // check if we're adding the login service or (re)enabling and/or editing an existing entry
            if (!loginServiceOktaOIDC) {
              // adding the login service (doesn't currently exist)
              addEnableOrDisableLoginService(_configOptions) // NB: we pass in the config options directly as the `useState` based `setLoginServiceConfigOptions` may not have updated yet
            } else {
              console.log('CompanyLoginServiceSettingsView - renderConfigModalOktaOIDC - onConfirm - isEnabling:', isEnabling, ' toggleValue:', toggleValue)
              // editing an existing login service (either enabling it or updating its config options regardless of its current status)
              updateLoginServiceConfigOptionsOktaOIDC(_configOptions, isEnabling ? true : undefined)
            }
          }}
          onDelete={() => {
            console.log('CompanyLoginServiceSettingsView - renderConfigModalOktaOIDC - onDelete')
            hideConfigModalFormOktaOIDC()
            deleteLoginServiceOktaOIDC()
          }}
        />
      </ArkModal>
    )
  }

  const hideConfigModalFormOktaSAML = () => {
    setShowConfigModalOktaSAML(false)
  }

  const onCloseConfigModalOktaSAML = () => {
    console.log('CompanyLoginServiceSettingsView - onCloseConfigModalOktaSAML')
    hideConfigModalFormOktaSAML()
  }

  const renderConfigModalOktaSAML = () => {
    const loginServiceOktaSAML = loginServices?.find((lm: CompanyLoginService) => lm.serviceType === COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML)
    const toggleValue = (companyValues as any).enableOktaSAML
    const isEnabling = (toggleValue === true && (loginServiceOktaSAML === undefined || (loginServiceOktaSAML !== undefined && loginServiceOktaSAML.enabled === false)))
    const isDisabled = toggleValue === false && (loginServiceOktaSAML !== undefined && loginServiceOktaSAML.enabled !== true) // NB: this will be false if isEnabling is true
    console.log('CompanyLoginServiceSettingsView - renderConfigModalOktaSAML - showConfigModalOktaSAML:', showConfigModalOktaSAML, ' loginServiceOktaSAML:', loginServiceOktaSAML, ' toggleValue:', toggleValue, ' isEnabling:', isEnabling, ' isDisabled:', isDisabled)
    return (
      <ArkModal
        size='tiny'
        open={showConfigModalOktaSAML}
        onClose={() => {
          onCloseConfigModalOktaSAML()
          if (!saving) setCompanyValues(companyValuesSaved) // reset the form back to the saved values
        }}
      >
        <CompanyLoginServiceConfigFormOktaSAML
          configOptions={loginServiceOktaSAML?.configOptions as CompanyLoginServiceConfigOptionsOktaSAML}
          isEnabling={isEnabling}
          isDisabled={isDisabled}
          onCancel={() => {
            setLoginServiceConfigOptions(undefined) // TESTING HERE - TODO: what if the values were loaded from the api? (do we need to reset them back to the saved values?) <<<<<<
            hideConfigModalFormOktaSAML()
            if (!saving) setCompanyValues(companyValuesSaved) // reset the form back to the saved values
          }}
          onConfirm={(_configOptions: CompanyLoginServiceConfigOptions) => {
            console.log('CompanyLoginServiceSettingsView - renderConfigModalOktaSAML - onConfirm - _configOptions:', _configOptions, ' loginServiceOktaSAML:', loginServiceOktaSAML, ' loginServiceOktaSAML?.configOptions:', loginServiceOktaSAML?.configOptions)
            setLoginServiceConfigOptions(_configOptions)
            hideConfigModalFormOktaSAML()
            // check if we're adding the login service or (re)enabling and/or editing an existing entry
            if (!loginServiceOktaSAML) {
              // adding the login service (doesn't currently exist)
              addEnableOrDisableLoginService(_configOptions) // NB: we pass in the config options directly as the `useState` based `setLoginServiceConfigOptions` may not have updated yet
            } else {
              console.log('CompanyLoginServiceSettingsView - renderConfigModalOktaSAML - onConfirm - isEnabling:', isEnabling, ' toggleValue:', toggleValue)
              // editing an existing login service (either enabling it or updating its config options regardless of its current status)
              updateLoginServiceConfigOptionsOktaSAML(_configOptions, isEnabling ? true : undefined)
            }
          }}
          onDelete={() => {
            console.log('CompanyLoginServiceSettingsView - renderConfigModalOktaSAML - onDelete')
            hideConfigModalFormOktaSAML()
            deleteLoginServiceOktaSAML()
          }}
        />
      </ArkModal>
    )
  }

  const renderConfigModals = () => {
    return (
      <>
        {renderConfigModalOktaOIDC()}
        {renderConfigModalOktaSAML()}
      </>
    )
  }

  // -------

  const is2FAEnabled = companyValuesSaved.enableOktaOIDC

  return (
    <div className={styles.loginServiceSettings}>
      <div className={styles.content}>
        <ArkPanel>
          <ArkPanel.Properties titleWidth={220}>
            {(saveResult !== undefined || saveError !== undefined) && (
              <>
                {saveResult !== undefined && saveError === undefined && (
                  <ArkPanel.PropertyRow>
                    <ArkPanel.PropertyRowMessage positive>
                      {/*
                      // OLD:
                      <ArkMessage.Header>Okta SSO is now {is2FAEnabled ? 'enabled' : 'disabled'} for this {OBJECT_COMPANY_NAME} (saveResult: {saveResult})</ArkMessage.Header>
                      {is2FAEnabled && (<p>All users for this {OBJECT_COMPANY_NAME} with an Okta SSO account will be required to login with it.</p>)}
                      {!is2FAEnabled && (<p>All {OBJECT_COMPANY_NAME} users will need to login with a {configStore.config.appName} account.</p>)}
                      */}
                      {/*
                      // NEW:
                      */}
                      {saveResult === CompanyLoginServiceSaveResult.Enabled && (
                        <>
                          <ArkMessage.Header>Okta SSO is now enabled for this {OBJECT_COMPANY_NAME}</ArkMessage.Header>
                          <p>All users for this {OBJECT_COMPANY_NAME} with an Okta SSO account will be required to login with it.</p>
                        </>
                      )}
                      {saveResult === CompanyLoginServiceSaveResult.Disabled && (
                        <>
                          <ArkMessage.Header>Okta SSO is now disabled for this {OBJECT_COMPANY_NAME}</ArkMessage.Header>
                          <p>All {OBJECT_COMPANY_NAME} users will need to login with a {configStore.config.appName} account.</p>
                        </>
                      )}
                      {saveResult === CompanyLoginServiceSaveResult.Updated && (
                        <>
                          <ArkMessage.Header>Okta SSO settings updated for this {OBJECT_COMPANY_NAME}</ArkMessage.Header>
                          {is2FAEnabled && (<p>Okta SSO is currently enabled. All users for this {OBJECT_COMPANY_NAME} with an Okta SSO account will be required to login with it.</p>)}
                          {!is2FAEnabled && (<p>Okta SSO is currently disabled. All {OBJECT_COMPANY_NAME} users will need to login with a {configStore.config.appName} account.</p>)}
                        </>
                      )}
                    </ArkPanel.PropertyRowMessage>
                  </ArkPanel.PropertyRow>
                )}
                {saveError !== undefined && (
                  <ArkPanel.PropertyRow>
                    <ArkPanel.PropertyRowMessage negative>
                      <ArkMessage.Header>Error</ArkMessage.Header>
                      <p>{saveError.message}</p>
                    </ArkPanel.PropertyRowMessage>
                  </ArkPanel.PropertyRow>
                )}
              </>
            )}
            <ArkPanel.PropertyRow
              title='Okta SSO (OpenID):'
              value={
                !loading
                  ? (
                    !loadingError
                      ? (toggleElement(COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC, 'enableOktaOIDC', true))
                      : <div className={styles.loadingError}>Error: {loadingError?.message}</div>
                  )
                  : <ArkLoader small className={styles.loading} />
              }
              hint={<>{saving && (<ArkLoader small className={styles.loading} />)}</>} // NB: if adding an actual hint, only show it when not 'saving' so the loader shows in its place
              hasChanges={hasChanges('enableOktaOIDC')}
              titleClassName={styles.propertyTitle}
              valueClassName={styles.propertyValue}
              titleSize={'xlarge'}
            />

            {COMPANY_LOGIN_SERVICE_SSO_OKTA_SAML_ENABLED && (
              <ArkPanel.PropertyRow
                title='Okta SSO (SAML):'
                value={
                  !loading
                    ? (
                      !loadingError
                        ? (toggleElement(COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML, 'enableOktaSAML', true))
                        : <div className={styles.loadingError}>Error: {loadingError?.message}</div>
                    )
                    : <ArkLoader small className={styles.loading} />
                }
                hint={<>{saving && (<ArkLoader small className={styles.loading} />)}</>} // NB: if adding an actual hint, only show it when not 'saving' so the loader shows in its place
                hasChanges={hasChanges('enableOktaSAML')}
                titleClassName={styles.propertyTitle}
                valueClassName={styles.propertyValue}
                titleSize={'xlarge'}
              />
            )}

            {/*
            // NB: Auth0 SSO support is currently disabled/skipped (not used), so we're hiding the option for now
            <ArkPanel.PropertyRow
              title='Auth0 SSO:'
              value={
                !loading
                  ? (
                    !loadingError
                      ? (toggleElement(COMPANY_LOGIN_SERVICE_TYPE_AUTH0, 'enableAuth0', false))
                      : <div className={styles.loadingError}>Error: {loadingError?.message}</div>
                  )
                  : <ArkLoader small className={styles.loading} />
              }
              hint={<>{saving && (<ArkLoader small className={styles.loading} />)}</>} // NB: if adding an actual hint, only show it when not 'saving' so the loader shows in its place
              hasChanges={hasChanges('enableAuth0')}
              titleClassName={styles.propertyTitle}
              valueClassName={styles.propertyValue}
              titleSize={'xlarge'}
            /> */}
          </ArkPanel.Properties>
        </ArkPanel>
        {renderConfirmModal()}
        {renderConfigModals()}
      </div>
    </div>
  )
}

export { CompanyLoginServiceSettingsView }
