import React, { useContext, useEffect, useRef, useState } from 'react'

import { CompanyAdminContext } from 'src/core/providers'

import { CompanyGroup, GroupUserOperation, User } from 'src/core/models'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkButton from 'src/core/components/ArkButton'
import ArkDataMappingForm, { ArkDataMappingItemChanges, ArkManagerFilteredItem } from 'src/core/components/ArkDataMappingForm/ArkDataMappingForm'
import ArkHighlightedText from 'src/core/components/ArkHighlightedText'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_COMPANY_SHORTNAME, OBJECT_GROUP_NAME, OBJECT_USER_NAME_PLURAL } from 'src/constants/strings'

import styles from './CompanyGroupUsersPanel.module.css'
import dataMappingFormStyles from 'src/core/components/ArkDataMappingForm/ArkDataMappingForm.module.css'

interface IProps {
  companyId: number
  group: CompanyGroup
  onChange?: Function
}

const CompanyGroupUsersPanel = (props: IProps) => {
  const mounted = useRef(false)

  const { companyId, group, onChange: _onChange } = props

  const companyAdminContext = useContext(CompanyAdminContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [companyUsers, setCompanyUsers] = useState<Array<User>>([])
  // const [groupUsers, setGroupUsers] = useState<Array<User>>([])

  const [filteredCompanyUsers, setFilteredCompanyUsers] = useState<Array<ArkManagerFilteredItem<User>> | undefined>()
  const [filter, setFilter] = useState<string | undefined>()

  const [selectedIds, setSelectedIds] = useState<Array<number>>([])

  // const [savedIds, setSavedIds] = useState<Array<number> | undefined>() // NB: not currently used, so skipping it
  // const [saveErrors, setSaveErrors] = useState<Map<number, any> | undefined>() // NB: not currently used, so skipping it

  const [showForm, setShowForm] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasUpdated, setHasUpdated] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const resetForm = () => {
    if (isSubmitting) return // don't allow resetting while submitting
    setIsSubmitting(false)
    setHasUpdated(false)
    setError(undefined)
    // setSavedIds(undefined)
    // setSaveErrors(undefined)
  }

  const resetView = () => {
    resetForm()
    setShowForm(false)
  }

  // -------

  // TESTING: equivalent to the componentDidUpdate method `if (this.props.group.id !== prevProps.group.id) { this.resetView() }`
  useEffect(() => {
    if (group.id) {
      resetView()
    }
  }, [group.id])

  // -------

  const _loadCompanyUsers = async () => {
    if (companyId) {
      try {
        const companyUsers = await companyAdminContext.actions.getCompanyUsers(companyId)
        return companyUsers
      } catch (error) {
        console.error('CompanyGroupUsersPanel - _loadCompanyUsers - error: ', error)
        throw error
      }
    }
  }

  const _loadGroupUsers = async () => {
    if (companyId && group.id) {
      try {
        const groupUsers = await companyAdminContext.actions.getCompanyGroupUsers(companyId, group.id)
        return groupUsers
      } catch (error) {
        console.error('CompanyGroupUsersPanel - _loadGroupUsers - error: ', error)
        throw error
      }
    }
  }

  const loadUsersData = async () => {
    console.log('CompanyGroupUsersPanel - loadUsersData')
    if (loading === true) return false
    try {
      setLoading(true)
      setError(undefined)
      const companyUsers = await _loadCompanyUsers()
      const groupUsers = await _loadGroupUsers()
      // TESTING: re-set the selectedIds to show the mappings saved from the api
      // NB: this will override any edits/changes to the form selection by the user, if this gets called after initial load
      const selectedIds: Array<number> = []
      if (groupUsers) {
        for (const groupUser of groupUsers) {
          selectedIds.push(groupUser.id)
        }
      }
      console.log('CompanyGroupUsersPanel - loadUsersData - selectedIds: ', selectedIds)
      setLoading(false)
      setCompanyUsers(companyUsers || [])
      // setGroupUsers(groupUsers || [])
      setSelectedIds(selectedIds)
    } catch (error) {
      console.error('CompanyGroupUsersPanel - loadUsersData - error: ', error)
      setLoading(false)
      setCompanyUsers([])
      // setGroupUsers([])
      setSelectedIds([])
      setError(error as Error)
    }
  }

  // -------

  const onShowForm = async () => {
    console.log('CompanyGroupUsersPanel - onShowForm')
    setShowForm(true)
    setError(undefined)
    await loadUsersData() // TESTING: moved this to only trigger when the form should show (avoid making api queries till we need the data)
  }

  // TODO: should this be called?
  // const onHideForm = () => {
  //   console.log('CompanyGroupUsersPanel - onHideForm')
  //   setShowForm(false)
  //   setError(undefined)
  // }

  // -------

  // TESTING: takes the result of ArkDataMappingItemChanges (additions & deletions) & converts it to the format the api endpoint expects
  // TODO: move to the relevant api class so its re-usable else-where...
  const generateGroupUserUpdateOperations = (groupId: number, itemChanges: ArkDataMappingItemChanges) => {
    const addOperations: Array<GroupUserOperation> = []
    const delOperations: Array<GroupUserOperation> = []
    for (const addItem of itemChanges.add) {
      addOperations.push({
        operation: 0, // 0 === ADD
        // group_id: groupId,
        user_id: addItem
      })
    }
    for (const delItem of itemChanges.del) {
      delOperations.push({
        operation: 2, // 2 == DELETE
        // group_id: groupId,
        user_id: delItem
      })
    }
    return [...delOperations, ...addOperations]
  }

  // -------

  const saveChanges = async (companyId: number, group: CompanyGroup, selectedIds: Array<number>, selectedIdChanges: ArkDataMappingItemChanges) => {
    setIsSubmitting(true)
    setHasUpdated(false)
    setError(undefined)
    // setSavedIds(undefined)
    // setSaveErrors(undefined)

    // convert the list off adds & dels to an operations list in the format the api expects
    const operations = generateGroupUserUpdateOperations(group.id, selectedIdChanges)
    console.log('CompanyGroupUsersPanel - saveChanges - operations: ', operations)

    if (operations.length === 0) {
      console.log('CompanyGroupUsersPanel - saveChanges - 0 operations - HALT')
      // TODO: show an error/warning?
      setIsSubmitting(false)
      setHasUpdated(false)
      return
    }

    let savedIds: Array<number> | undefined
    let newSelectedIds: Array<number> | undefined
    let saveErrors: Map<number, any> | undefined
    let hasUpdated = false
    let error: Error | undefined

    try {
      // run all the add & delete mapping operations
      const result = await companyAdminContext.actions.updateCompanyGroupUsers(companyId, group.id, operations)
      console.log('CompanyGroupUsersPanel - saveChanges - updateCompanyGroupUsers - result: ', result, ' typeof: ', typeof result, ' instanceof Map: ', result instanceof Map)

      if (result === true) { // all operations succeeded
        if (mounted.current) {
          hasUpdated = true
          savedIds = operations.map(operation => operation.user_id)
          newSelectedIds = selectedIds
        }
      } else if (result instanceof Map) { // 1 or more operations had errors (but some may have still succeeded)
        // all operations failed
        if (result.size === operations.length) {
          hasUpdated = false
          savedIds = []
          newSelectedIds = selectedIds // TESTING: revert to the previous form selection before the submit
          console.log('CompanyGroupUsersPanel - saveChanges - updateCompanyGroupUsers - ALL FAILED - newSelectedIds: ', newSelectedIds)
        } else { // only some operations failed
          hasUpdated = true // set true if some added/updated ok
          savedIds = [] // get a list of successful operations
          for (const operation of operations) {
            if (result.has(operation.user_id) === false) {
              savedIds.push(operation.user_id)
            }
          }
          newSelectedIds = selectedIds.filter((selectedId) => !result.has(selectedId)) // TODO: does this only account for adding/enabling an id, not removing one??
          console.log('CompanyGroupUsersPanel - saveChanges - updateCompanyGroupUsers - SOME FAILED - newSelectedIds:', newSelectedIds)
        }
        saveErrors = result // the result contains errors for each operation that failed
        error = { message: 'A problem occurred saving one or more of the changes, please try again.' } as any
      } else { // general/fallback error (with the whole api call)
        hasUpdated = false
        error = { message: 'A problem occurred saving the changes, please try again.' } as any
      }

      console.log('CompanyGroupUsersPanel - saveChanges - hasUpdated:', hasUpdated, ' savedIds:', savedIds, ' newSelectedIds:', newSelectedIds, ' saveErrors:', saveErrors, ' error:', error)
      if (mounted.current) {
        setIsSubmitting(false)
        setHasUpdated(hasUpdated)
        setSelectedIds(newSelectedIds ?? [])
        // setSavedIds(savedIds)
        // setSaveErrors(saveErrors)
        setError(error)
      }
      if (hasUpdated) {
        if (_onChange) _onChange()
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setHasUpdated(hasUpdated)
        // setSavedIds(savedIds)
        // setSaveErrors(saveErrors)
        setError(error as Error)
      }
    }
  }

  // -------

  const filterProjectUsers = (_filter: string) => {
    if (loading) return
    // NB: we currently filter out items even if they're enabled/mapped, but when you submit they will still submit as enabled/mapped (we just don't visually show them for now)
    // TODO: should we show filtered out items that are enabled/mapped in some way, to make it obvious they'll remain selected when you save the mapping form?
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredCompanyUsers = filter
      ? companyUsers.reduce<Array<ArkManagerFilteredItem<User>>>((r, user) => {
        // TODO: also allow filtering by user email here (if we actually show it in the listing & not just the name??)
        let nameMatch = false
        // let emailMatch = false
        if (user.name().toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        // if (group.desc?.toLowerCase().includes(filter.toLowerCase())) {
        //   emailMatch = true
        // }
        // console.log('filterUsers - user.name(): ', user.name(), ' nameMatch: ', nameMatch, ' emailMatch: ', emailMatch)
        if (nameMatch) { // || emailMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          // if (emailMatch) matchingFields.push('email')
          const filteredItem: ArkManagerFilteredItem<User> = {
            item: user,
            matchingFields
          }
          r.push(filteredItem)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<User>>)
      : undefined
    setFilter(filter)
    setFilteredCompanyUsers(filteredCompanyUsers)
  }

  // const clearFilteredProjectPrograms = () => {
  //   setFilter(undefined)
  //   setFilteredCompanyUsers(undefined)
  // }

  // -------

  const renderProjectUserFilterForm = () => {
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        className={dataMappingFormStyles.filterForm}
        filterTitle='Filter by name'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          filterProjectUsers(filter)
        }}
      />
    )
  }

  // -------

  if (!group) return null
  return (
    <ArkPanel bordered={showForm} title={showForm ? OBJECT_COMPANY_SHORTNAME + ' ' + OBJECT_GROUP_NAME + ' ' + OBJECT_USER_NAME_PLURAL : null}>

      {!showForm && (
        <ArkButton type="button" fluid size="large" loading={loading} onClick={onShowForm}>EDIT {OBJECT_COMPANY_SHORTNAME} {OBJECT_GROUP_NAME} {OBJECT_USER_NAME_PLURAL}</ArkButton>
      )}

      {showForm && (
        <>
          <ArkDataMappingForm
            id="group-users"
            // title="Group Users"
            sourceItems={companyUsers /* .map(companyUser => { return { id: companyUser.id, title: companyUser.name() } }) */}
            mappedIds={selectedIds}
            isLoading={loading}
            isSaving={isSubmitting}
            successMessage={hasUpdated && 'The ' + OBJECT_GROUP_NAME + ' ' + OBJECT_USER_NAME_PLURAL + ' have been updated.' }{/* TODO: add details of how many where added/updated/removed from the chhanel? */...[]}
            errorMessage={error && error.message}
            // disabled={group.isDefaultGroup}
            // disabledMessage={'You cannot disable ' + OBJECT_USER_NAME_PLURAL + ' in the ' + OBJECT_PROJECT_NAME + ' default ' + OBJECT_GROUP_NAME + '.'}
            onCancel={() => { resetView() }}
            onSave={(mappedIds?: Array<number>, changes?: ArkDataMappingItemChanges) => {
              console.log('CompanyGroupUsersPanel - ArkDataMappingForm - onSave - mappedIds: ', mappedIds, ' changes: ', changes)
              if (mappedIds && changes) {
                saveChanges(companyId, group, mappedIds, changes)
              }
            }}
            // filtering & custom item rendering support:
            filterForm={renderProjectUserFilterForm()}
            filterText={filter}
            filteredSourceItems={filteredCompanyUsers}
            itemCompare={(newItem: User, oldItem: User) => {
              return newItem.id === oldItem.id && newItem.name() === oldItem.name()
            }}
            itemRow={(user: User, _isMapped: boolean) => {
              return (
                <div className={styles.userRow}>
                  <ArkAvatar
                    type={user.userAvatarType()}
                    name={user.name()}
                    size='30'
                    filter={filter}
                    filterHighlight={true}
                  />
                  <ArkHighlightedText highlight={filter} text={user.name()} className={styles.text} />
                </div>
              )
            }}
            onClearFilter={() => filterProjectUsers('')}
          />
          <ArkSpacer />
        </>
      )}

    </ArkPanel>
  )
}

export default CompanyGroupUsersPanel
