import React, { useContext } from 'react'

import { ArkSectionSubMenuItem } from '../../models'
import { NavContext, NavSection, UserContext } from 'src/core/providers'
import { LayoutContext } from 'src/core/providers/LayoutProvider'
import { ResponsiveContext } from 'src/core/providers/ResponsiveProvider'
import { IconName } from '../ArkIcon'
import ArkSpacer from '../ArkSpacer'
import ArkMainMenuButton from '../ArkMainMenuButton'
import ArkSectionSwitcher, { ArkSectionSwitcherProps } from '../ArkSectionSwitcher/ArkSectionSwitcher'

import { ArkSectionSubMenuItemFormatPathCallback } from 'src/core/models/menus'

import * as ROUTES from '../../../constants/routes'

import styles from './ArkSectionSidebarMenu.module.css'

interface IProps extends ArkSectionSwitcherProps {
  mainMenuItems?: Array<ArkSectionSubMenuItem>
  bottomMenuItems?: Array<ArkSectionSubMenuItem>
}

const ArkSectionSidebarMenu = (props: IProps) => {
  const { mainMenuItems, bottomMenuItems } = props

  const { store: layoutStore } = useContext(LayoutContext)
  const { leftSidebarState } = layoutStore

  const { actions: navActions, store: navStore } = useContext(NavContext)
  const { goto, matchPath } = navActions
  const { currentPath, currentSection } = navStore

  const { store: responsiveStore } = useContext(ResponsiveContext)
  const { isDesktop } = responsiveStore

  const isCompact = isDesktop && leftSidebarState !== 'full'

  const { store: userStore } = useContext(UserContext)
  const { selectedCompany } = userStore

  // returns true if the passed in menu link path matches the current page's path (taking path args into account)
  const isMenuLinkCurrentPath = (path: string) => {
    const matchCurrentExact = matchPath(path, currentPath, true)
    if (matchCurrentExact) return true
    // TESTING: (viewer section only) - catch viewer channel (sub)paths, still consider the base viewer path to be active (so it highlights it in the sidebar menu)
    if (currentSection === NavSection.viewer && path === ROUTES.VIEW_PROJECT) {
      const matchViewChannel = matchPath(ROUTES.VIEW_PROJECT_CHANNEL, currentPath ?? '', true)
      return matchViewChannel != null
    }
    return path === currentPath // NB: fallback direct comparison only (no placeholder handling)
  }

  const renderSidebarMenuLink = (
    path: string,
    title: string,
    iconName: IconName,
    projectId?: number,
    formatPath?: ArkSectionSubMenuItemFormatPathCallback, // NB: used by the `viewerMenuItmes` projects link to handle channelId's being in the url (the default path formatting here only handles the `projectId`)
    disable?: boolean,
    target?: string // NB: used by `constants/menus.ts` for the `supportMenuItems` entry to open the support url in a new tab instead of current one
  ) => {
    const key = title // TODO: make this more unique (incase theres any dupe titles?)
    let formattedPath = path.replace(':projectId', projectId ? '' + projectId : '')
    if (formatPath) {
      formattedPath = formatPath(path)
    }

    return (
      <ArkMainMenuButton
        active={isMenuLinkCurrentPath(path)}
        compact={isDesktop && leftSidebarState !== 'full'}
        disabled={disable}
        iconName={iconName}
        key={key}
        onClick={() => {
          if (target) {
            if (!disable) window.open(formattedPath, target)
          } else {
            goto(disable ? '#' : formattedPath)
          }
        }}
        title={title}
      />
    )
  }

  const renderSidebarMenuItem = (menuItem: ArkSectionSubMenuItem) => {
    if (menuItem.hidden === true) return null
    return renderSidebarMenuLink(
      menuItem.path,
      menuItem.title,
      menuItem.iconName,
      menuItem.projectId,
      menuItem.formatPath,
      menuItem.disable,
      menuItem.target
    )
  }

  const renderSidebarMenu = () => {
    // NB: we no longer hide the menu items if you don't have access to the section but instead render them dimmed-out & disabled so `showMainMenu` & `showBottomMenu` checks were removed from here
    return (
      <>
        {mainMenuItems && mainMenuItems.length > 0 && (
          <>
            {mainMenuItems.map((menuItem: ArkSectionSubMenuItem) => renderSidebarMenuItem(menuItem))}
          </>
        )}
        {bottomMenuItems && bottomMenuItems.length > 0 && (
          <>
            <ArkSpacer grow />
            {bottomMenuItems.map((menuItem: ArkSectionSubMenuItem) => renderSidebarMenuItem(menuItem))}
          </>
        )}
      </>
    )
  }

  const renderSidebarDemoBanner = () => {
    if (!selectedCompany || !selectedCompany.isDemo) return null
    return (
      <div className={styles.demoBanner + (isCompact ? ' ' + styles.demoBannerCompact : '')}>
        DEMO
      </div>
    )
  }

  return (
    <>
      {renderSidebarDemoBanner()}
      <ArkSectionSwitcher />
      {renderSidebarMenu()}
    </>
  )
}

export default ArkSectionSidebarMenu
