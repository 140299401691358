import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { Breadcrumb, BreadcrumbSectionProps, Header, Menu, Segment, SemanticShorthandCollection } from 'semantic-ui-react'

import * as ROUTES from '../../../../constants/routes'
import { NavContext, UserContext } from '../../../providers'
import { OnboardingContext } from '../../../providers/OnboardingProvider'
import ArkButton from '../../../components/ArkButton'
import ArkCenterLayout from '../../../components/ArkCenterLayout'
import ArkIconButton from '../../../components/ArkIconButton'
import ArkPage from '../../../components/ArkPage/ArkPage'
import ArkSpacer from '../../../components/ArkSpacer'

import { UserAccountContext } from '../UserAccountProvider'
import { UserAccountMenuItem, userAccountMenus, UserAccountSection, UserAccountSectionPage } from '../UserAccountData'

import styles from './UserAccountPage.module.css'

interface IProps {
  section?: UserAccountSection
  sectionPage?: UserAccountSectionPage
}

const UserAccountPage = (props: IProps) => {
  const { actions: navActions } = useContext(NavContext)

  const { actions: onboardingActions } = useContext(OnboardingContext)
  const { reset } = onboardingActions

  const { actions: userAccountActions, store: userAccountStore } = useContext(UserAccountContext)
  const { store: userStore } = useContext(UserContext)

  const { section, sectionPage } = userAccountStore

  const [isOnboardingReset, setIsOnboardingReset] = useState(false)

  useEffect(() => {
    userAccountActions.setSection(props.section)
    userAccountActions.setSectionPage(props.sectionPage)
  }, [props.section, props.sectionPage])

  /**
   * menu helpers
   */

  const getMenuItem = (section?: UserAccountSection, sectionPage?: UserAccountSectionPage) => {
    if (section) {
      let sectionMenuItem: UserAccountMenuItem | undefined
      for (const menuItem of userAccountMenus) {
        if (menuItem.type === 'section' && menuItem.key === section) {
          sectionMenuItem = menuItem
          break
        }
      }
      let pageMenuItem: UserAccountMenuItem | undefined
      if (sectionPage && sectionMenuItem && sectionMenuItem.menus) {
        for (const menuItem of sectionMenuItem.menus) {
          if (menuItem.type === 'page' && menuItem.key === sectionPage) {
            pageMenuItem = menuItem
            break
          }
        }
      }
      if (pageMenuItem) return pageMenuItem
      return sectionMenuItem
    }
    return undefined
  }

  const getParentMenuItems = (menuItem: UserAccountMenuItem) => {
    // NB: currently limited to 2 levels deep, make this recursive if we want to support more levels down the line..
    for (const mI of userAccountMenus) {
      if (mI.menus) {
        for (const subMI of mI.menus) {
          if (subMI.key === menuItem.key) {
            return [mI]
          }
        }
      }
    }
    return undefined
  }

  /**
   * actions
   */

  // NB: now acts as a sort of user account 'back' button, going back to the parent section if in a section page, & if in the root user account page it'll goto the home page instead
  const onCloseClick = () => {
    // console.log('UserAccountPage - onCloseClick - section:', props.section, ' sectionPage:', props.sectionPage)
    const menuItem = getMenuItem(props.section, props.sectionPage)
    // console.log('UserAccountPage - onCloseClick - menuItem:', menuItem)
    if (menuItem) {
      const parentMenuItems = getParentMenuItems(menuItem)
      // console.log('UserAccountPage - onCloseClick - parentMenuItems:', parentMenuItems)
      if (parentMenuItems && parentMenuItems.length > 0) {
        // TESTING: use the first (or should it be last?) parent menu item
        const parentMenuItem = parentMenuItems[0]
        navActions.goto(parentMenuItem.path)
        return
      }
    }
    if (props.section) {
      navActions.goto(ROUTES.ACCOUNT)
    } else {
      navActions.goto(ROUTES.HOME)
    }
  }

  const onMenuItemClick = (menuItem: UserAccountMenuItem, _section?: UserAccountSection, _sectionPage?: UserAccountSectionPage) => {
    navActions.goto(menuItem.path)
  }

  const onResetOnboardingReset = () => {
    setIsOnboardingReset(true)
    reset()
  }

  /**
   * render
   */

  const renderMenu = (menuItems: Array<UserAccountMenuItem>, menuItem?: UserAccountMenuItem, section?: UserAccountSection, sectionPage?: UserAccountSectionPage) => {
    const items: Array<React.ReactNode> = []
    for (const menuItem of menuItems) {
      if (menuItem.showMenuItem !== undefined) {
        const showMenuItem = menuItem.showMenuItem(userStore.user)
        if (!showMenuItem) {
          continue
        }
      }
      items.push(<Menu.Item key={menuItem.key} name={menuItem.key} onClick={() => onMenuItemClick(menuItem, section, sectionPage)}>{menuItem.title}</Menu.Item>)
    }
    return (
      <>
        {(menuItem?.menuHeader) && (menuItem?.menuHeader)}
        <Menu className={styles.menu} vertical fluid inverted>{items}</Menu>
        {(menuItem?.menuFooter) && (menuItem?.menuFooter)}
      </>
    )
  }

  const renderPage = (menuItem: UserAccountMenuItem) => {
    if (menuItem.page) {
      return menuItem.page(() => {
        const parentMenuItems = getParentMenuItems(menuItem)
        if (parentMenuItems && parentMenuItems.length > 0) {
          const parentMenuItem = parentMenuItems[(parentMenuItems.length - 1)]
          onMenuItemClick(parentMenuItem)
        }
      })
    }
    return null
  }

  const renderBreadcrumbs = () => {
    const menuItem = getMenuItem(section, sectionPage)
    const parentMenuItems = menuItem && getParentMenuItems(menuItem)

    const sections: SemanticShorthandCollection<BreadcrumbSectionProps> = [
      { content: 'ACCOUNT', key: 'root', onClick: menuItem ? () => navActions.goto(ROUTES.ACCOUNT) : undefined }
    ]
    _.each(parentMenuItems, parentMenuItem => sections.push(
      { content: parentMenuItem.title, key: parentMenuItem.key, onClick: () => onMenuItemClick(parentMenuItem, section, sectionPage) }
    ))
    menuItem && sections.push({ content: menuItem.title, key: menuItem.key })

    const onBackClick = () => {
      const backMenuItem = _.last(parentMenuItems)
      if (backMenuItem) {
        onMenuItemClick(backMenuItem, section, sectionPage)
      } else {
        navActions.goto(ROUTES.ACCOUNT)
      }
    }

    return (
      <>
        {menuItem && (
          <ArkButton basic className={styles.breadcrumbBack} inverted onClick={onBackClick} size='mini'>
            <i className='angle icon left' />
          </ArkButton>
        )}
        <Breadcrumb className={styles.breadcrumb} icon='right angle' sections={sections} />
      </>
    )
  }

  const renderMenuItem = () => {
    const menuItem = getMenuItem(section, sectionPage)
    if (!menuItem) return renderMenu(userAccountMenus)
    if (menuItem.type === 'page') {
      return renderPage(menuItem)
    } else if (menuItem.type === 'section') {
      return renderMenu(menuItem.menus || [], menuItem)
    }
  }

  const renderRootPage = () => {
    return renderMenu(userAccountMenus)
  }

  const renderOnboardingComponent = () => {
    if (section !== UserAccountSection.settings || sectionPage) return
    return (
      <ArkButton disabled={isOnboardingReset} onClick={onResetOnboardingReset} size='small'>
        Reset Helpful Hints
      </ArkButton>
    )
  }

  return (
    <ArkPage>
      <ArkCenterLayout>
        <Segment className={styles.segment} inverted>
          <div className={styles.header}>
            <Header as='h2' className={styles.headerTitle} inverted>User Account</Header>
            <ArkSpacer grow />
            <ArkIconButton name='close' onClick={onCloseClick} />
            <ArkSpacer negative />
          </div>
          {renderBreadcrumbs()}
          <ArkSpacer size={20} />
          {renderMenuItem() || renderRootPage()}
          {renderOnboardingComponent()}
        </Segment>
      </ArkCenterLayout>
    </ArkPage>
  )
}

export default UserAccountPage
