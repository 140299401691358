import React, { useContext, useEffect, useRef } from 'react'

import { ProjectAdminContext } from 'src/core/providers'

import { ProjectGroup } from 'src/core/models'

import ArkCheckbox, { ArkCheckboxProps } from 'src/core/components/ArkCheckbox'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkMessage from 'src/core/components/ArkMessage'

import { OBJECT_COMPANY_NAME, OBJECT_GROUP_NAME } from 'src/constants/strings'

import styles from './ProjectGroupAccessToggle.module.css'

export type ProjectGroupAccessToggleOnChangeCallback = (value: boolean) => void

interface IProps {
  companyId: number
  projectId: number
  group: ProjectGroup
  showStatusText?: boolean
  onChange?: ProjectGroupAccessToggleOnChangeCallback
}

const ProjectGroupAccessToggle = (props: IProps) => {
  const mounted = useRef(false)

  const { companyId, projectId, group, showStatusText, onChange: _onChange } = props

  const projectAdminContext = useContext(ProjectAdminContext)

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [submitValue, setSubmitValue] = React.useState<boolean>(false)
  const [, setHasSaved] = React.useState<boolean>(false) // hasSaved // TODO: do we need to show a success message?
  const [error, setError] = React.useState<Error | undefined>(undefined)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const reset = () => {
    setIsSubmitting(false)
    setSubmitValue(false)
    setHasSaved(false)
    setError(undefined)
  }

  // -------

  // TESTING: equivalent to the componentDidUpdate method `if (this.props.group?.id !== prevProps.group?.id) { this.reset() }`
  useEffect(() => {
    if (group?.id) {
      reset()
    }
  }, [group?.id])

  // -------

  const updateProjectGroupAccessEnabled = async (companyId: number, projectId: number, groupId: number, accessEnabled: boolean) => {
    try {
      setIsSubmitting(true)
      setSubmitValue(accessEnabled)
      setError(undefined)
      await new Promise(resolve => setTimeout(resolve, 500)) // add a brief delay, so the user see's the loading indicator on quick api calls (rather than showing a success message, this makes it kind of obvious it did something & so worked)
      // throw new Error('TEST ERROR') // DEBUG ONLY - force an error instead of calling the api to make styling it easier
      const result = await projectAdminContext.actions.updateProjectGroupAccessEnabled(companyId, projectId, groupId, accessEnabled)
      if (result && _onChange) _onChange(accessEnabled) // NB: its up to the calling code to update the local users projectAccessEnabled value (or reload it from the api)
      if (mounted.current) {
        setIsSubmitting(false)
        setHasSaved(result)
      }
    } catch (error) {
      console.error('ProjectGroupAccessToggle - updateProjectGroupAccessEnabled - error: ', error)
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
      // TODO: need to also revert the checkbox toggle status to reflect it failed <<<<
    }
  }

  // -------

  const isMirroredGroup = group.isMirroredGroup()
  const isMirroredGroupEnabled = isMirroredGroup && group.mirrorGroup?.enabled
  const isMirroredGroupDisabled = isMirroredGroup && !group.mirrorGroup?.enabled
  const groupEnabled = (isMirroredGroup ? (isMirroredGroupEnabled === false ? false : group.enabled) : group.enabled) // if its a mirrored group & the parent mirror group is disabled, then consider the group disabled, otherwise use the project group's local enabled value instead
  const accessEnabled = isSubmitting ? submitValue : groupEnabled // use the new value while submitting (& on success the group will be updated to use the new value)
  const _showStatusText = showStatusText !== undefined ? showStatusText : false
  return (
    <>
      <div className={styles.accessToggle}>
        {!isSubmitting && (
          <ArkCheckbox
            toggle
            // defaultChecked={user.projectAccessEnabled}
            checked={accessEnabled}
            disabled={isMirroredGroupDisabled || isSubmitting}
            disabledMessage={isMirroredGroupDisabled ? `⚠️ Mirrored ${OBJECT_GROUP_NAME} disabled at ${OBJECT_COMPANY_NAME} level` : undefined}
            onChange={async (event: React.FormEvent<HTMLInputElement>, data: ArkCheckboxProps) => {
              event.preventDefault()
              event.stopPropagation()
              const newAccessEnabled = data.checked ?? false
              if (newAccessEnabled !== group.enabled) {
                await updateProjectGroupAccessEnabled(companyId, projectId, group.id, newAccessEnabled)
              }
            }}
          />
        )}
        {isSubmitting && <ArkLoader small />}
        {!isSubmitting && _showStatusText && (<span className={styles.status}>&nbsp;{accessEnabled ? '(ENABLED)' : '(DISABLED)'}</span>)}
      </div>
      {error && (
        <div>
          <ArkMessage size='small' compact error className={styles.error}>
            <ArkMessage.Header>Update Error</ArkMessage.Header>
            <p>{error.message}</p>
          </ArkMessage>
        </div>
      )}
    </>
  )
}

export default ProjectGroupAccessToggle
