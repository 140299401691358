/**
 * ArkBrowserNotice
 */

import React, { ReactNode, useContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'

import { isChrome } from 'src/core/utilities/platform'
import ArkButton from '../ArkButton'
import ArkCheckbox from '../ArkCheckbox'
import ArkModal from '../ArkModal'
import ArkResponsive from '../ArkResponsive'
import ArkSpacer from '../ArkSpacer'

import styles from './ArkBrowserNotice.module.css'
import { GlobalConfigContext } from 'src/core/providers'

const COOKIE_NAME = 'hideBrowserNotice'
const EXPIRES = 1 / 24 * 8 // 8 hours
const EXPIRES_DONT_SHOW = 90 // 90 days
// const EXPIRES = 1 / 24 / 60 * 1 // DEBUG - 1 minute
// const EXPIRES_DONT_SHOW = 1 / 24 / 60 * 5 // DEBUG - 5 minutes

export interface ArkBrowserNoticeProps {
  children: ReactNode
}

const ArkBrowserNotice = ({ children }: ArkBrowserNoticeProps) => {
  const { store: configStore } = useContext(GlobalConfigContext)
  const [hideChecked, setHideChecked] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (isChrome()) return
    if (Cookies.get(COOKIE_NAME)) return
    setOpen(true)
  }, [])

  const onCloseClick = () => {
    const expires = hideChecked ? EXPIRES_DONT_SHOW : EXPIRES
    Cookies.set(COOKIE_NAME, '1', { expires })
    setOpen(false)
  }

  return (
    <>
      {children}
      <ArkResponsive desktopOnly>
        <ArkModal onClose={onCloseClick} open={open}>
          <h1>Recommended Browser</h1>
          <p>{`${configStore.config.appName} is designed to work best with `}<b>{'Google Chrome'}</b>{' browser. Using this browser could result in a degraded performance. Please consider switching to Google Chrome browser.'}</p>
          <p>
            You can download {' '}
            <a className={styles.link} href="https://www.google.com/chrome/">Google Chrome here</a>
            .
          </p>
          <ArkSpacer size={20} />
          <div className={styles.bottom}>
            <ArkCheckbox
              checked={hideChecked}
              label={'Don\'t show this message again'}
              onChange={(_event, data) => setHideChecked(!!data.checked)}
            />
            <ArkButton color='blue' onClick={onCloseClick}>Continue anyway</ArkButton>
          </div>
        </ArkModal>
      </ArkResponsive>
    </>
  )
}

export default ArkBrowserNotice
