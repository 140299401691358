/**
 * Config
 */

import { PlayerResolution } from 'src/core/types/player'
import { APIServerType } from './server_types'

// Debug
export const DEBUG_MODE = process.env.NODE_ENV === 'development'

// Responsive
export const RESPONSIVE_MOBILE_BREAKPOINT = 1024

// Server
// console.log('config - window._env_: ', window._env_)
const serverType = window._env_?.API_SERVER_TYPE ?? process.env.REACT_APP_API_SERVER_TYPE
export const API_SERVER_TYPE: APIServerType = serverType !== undefined && (<any>Object).values(APIServerType).includes(serverType) ? (serverType as APIServerType) : APIServerType.Production // chk the loaded type is on of the APIServerTypes - ref: https://stackoverflow.com/a/47755096
export const API_SERVER_URL = window._env_?.API_SERVER_URL ?? process.env.REACT_APP_API_SERVER_URL
export const WEB_SERVER_URL = window._env_?.WEB_SERVER_URL ?? process.env.REACT_APP_WEB_SERVER_URL
export const WEB_CONFIG_OVERRIDE = window._env_?.WEB_CONFIG_OVERRIDE && window._env_?.WEB_CONFIG_OVERRIDE.length > 0 ? window._env_?.WEB_CONFIG_OVERRIDE : (process.env.REACT_APP_WEB_CONFIG_OVERRIDE && process.env.REACT_APP_WEB_CONFIG_OVERRIDE.length > 0 ? process.env.REACT_APP_WEB_CONFIG_OVERRIDE : undefined)
if (DEBUG_MODE) console.log('config - API_SERVER_TYPE: ', API_SERVER_TYPE, ' API_SERVER_URL: ', API_SERVER_URL, ' WEB_SERVER_URL: ', WEB_SERVER_URL, ' WEB_CONFIG_OVERRIDE:', WEB_CONFIG_OVERRIDE)

// Mock API - Currently implemented for cards, images and notices
export const MOCK_API_ENABLED = DEBUG_MODE

// Demo Mode (org/company level demo controls - NB: not yet fully implemented/supported)
export const DEMO_MODE_SUPPORT_ENABLED = false // rls: false (for now) - org level demo mode control - allows time limited demo org access if/when the api server supports it (currently still WIP as of Aug '23)
export const DEMO_BOOKING_URL_PROD = 'https://reprostream.com/my-bookings/'
export const DEMO_BOOKING_URL_STAGING = 'https://staging.reprostream.com/my-bookings/'

// Quicklink (guest/shortlinks)
export const QUICKLINK_DEBUG_MODE = false // rls: false - when enabled the /quickview page no longer auto logs in guest links &/or redirects, & instead prints out a load of related debug details & shows buttons to manually trigger the guest login, & redirect steps separately
export const QUICKLINK_DEBUG_DELAY = 0 // rls: 0 - add a delay in seconds to each api call/action triggered as part of the quickview handling (e.g. before logging in, & selecting company & project) (NB: this does NOT require QUICKLINK_DEBUG_MODE to be enabled, so we can use this to test UI in normal quickview mode)

// ArkLog
export const ARK_LOG_ENABLED = true // Override `console.log`
export const ARK_LOG_FILTER = '' // Filter logs with a regular expression
// export const ARK_LOG_FILTER = '^Company'
export const ARK_LOG_SILENCED = !DEBUG_MODE // Silence all logs except errors

// Nav - Menus
export const MENUS_WIP_PAGES_HIDDEN = !DEBUG_MODE // rls false - show WIP pages in navigation menus

// Audio levels
export const AUDIO_LEVELS_ENABLED = true
export const AUDIO_LEVELS_INTERVAL = 100 // ms

// Audio meters
export const AUDIO_METERS_MULTIPLIER = 1.25

// Cards
export const CARDS_ENABLED: boolean = true
export const CARDS_MOCK_API_ENABLED: boolean = MOCK_API_ENABLED && false

// Images
export const IMAGES_MOCK_API_ENABLED: boolean = MOCK_API_ENABLED && false

// Notices
export const NOTICES_ENABLED: boolean = true
export const NOTICES_MOCK_API_ENABLED: boolean = MOCK_API_ENABLED && false

// Popup
export const POPUP_MOUSE_LEAVE_DELAY = 1500

// Program Viewer
export const PROGRAM_360_ENABLED = true
export const PROGRAM_360_PREFER_PASSTHROUGH_DEFAULT = true
export const PROGRAM_BUFFER_MAX = 3000 // ms
export const PROGRAM_BUFFER_MIN = 100 // ms
export const PROGRAM_BUFFER_OPTION_BALANCED = 500 // ms
export const PROGRAM_BUFFER_OPTION_FAST = 100 // ms
export const PROGRAM_BUFFER_OPTION_STABLE = 1500 // ms
export const PROGRAM_BUFFER_STEP = 50 // ms
export const PROGRAM_CONTROLS_AUTOHIDE_ENABLED = true
export const PROGRAM_CONTROLS_AUTOHIDE_IDLE_DELAY = 5000 // ms
export const PROGRAM_CONTROLS_COMPACT_SIZE = 500 // px
export const PROGRAM_CONTROLS_SHOW_DEBUG_INFO = false
export const PROGRAM_OPTIONS_SHOW_ADVANCED = true
export const PROGRAM_OPTIONS_SHOW_ADVANCED_PARAMETERS = true
export const PROGRAM_OPTIONS_SHOW_BUFFER = true
export const PROGRAM_WATCHDOG_ENABLED = true
export const PROGRAM_WATCHDOG_CONNECT_DELAY = 10000 // ms
export const PROGRAM_WATCHDOG_RECONNECT_DELAY = 5000 // ms
export const PROGRAM_WATCHDOG_RESTART_DELAY = 20000 // ms
export const PROGRAM_WATCHDOG_SHOW_DEBUG_INFO = false

// Program Manager
export const PROGRAM_MANAGER_SHOW_SPLIT_PROTOCOL_URLS_FOR_NON_SRT = false // rls: false - NB: SRT will show the split details regardless, this controls if its shown for other protocols
export const PROGRAM_MANAGER_CUSTOM_SRT_PORT_MODAL_ENABLED = true // rls: TBD - use the custom SRT port modal sub-form when editing program srt ports, instead of just the basic inline port input fields (NB: disable this while its WIP)

// Color
export const DEFAULT_PICKER_COLOR = '#ABB8C3'

// Solo
export const SOLO_ENABLED = true
export const AUTO_SOLO_ENABLED = SOLO_ENABLED && true

// Online status
export const STATUS_ENABLED: boolean = true
export const STATUS_DEBUG_POLL_INTERVAL_ENABLED: boolean = DEBUG_MODE && false
export const STATUS_DEBUG_POLL_INTERVAL: number = 5000 // 5 seconds
export const STATUS_MOCK_API_ENABLED: boolean = MOCK_API_ENABLED && false
export const STATUS_MOCK_API_RANDOM: boolean = false

// Video player
export const VIDEO_PLAYER_ABR_RESOLUTIONS: PlayerResolution[] = [
  '1080p',
  '720p',
  '540p'
]
export const VIDEO_PLAYER_AUDIO_MULTIPLIER = 0.8 // TODO old (WebRTC)
export const VIDEO_PLAYER_SUPPORTED_RESOLUTIONS: PlayerResolution[] = [
  '1080p',
  '720p',
  '540p',
  '360p'
]

// Project Checklist
export const PROJECT_CHECKLIST_ENABLED = true
export const PROJECT_CHECKLIST_MOCK_DATA = false
export const PROJECT_CHECKLIST_MOCK_DATA_CREATE_PROGRAM = false
export const PROJECT_CHECKLIST_MOCK_DATA_CREATE_CHANNEL = false
export const PROJECT_CHECKLIST_MOCK_DATA_CREATE_GROUP = false
export const PROJECT_CHECKLIST_MOCK_DATA_ADD_PROGRAM_TO_CHANNEL = false
export const PROJECT_CHECKLIST_MOCK_DATA_ADD_CHANNEL_TO_GROUP = false
export const PROJECT_CHECKLIST_MOCK_DATA_ADD_USER_TO_PROJECT = false
export const PROJECT_CHECKLIST_MOCK_DATA_ADD_USER_TO_GROUP = false
export const PROJECT_CHECKLIST_MOCK_DATA_USER_VIEW_PROGRAM = false

// Project Node Graph
export const PROJECT_NODE_GRAPH_ENABLED = true
export const PROJECT_NODE_GRAPH_DAGRE_ENABLED = true
export const PROJECT_NODE_GRAPH_MOCK_DATA = false
export const PROJECT_NODE_GRAPH_SELECTION_ENABLED = false
export const PROJECT_NODE_GRAPH_VIEW_BUTTONS_ENABLED = false

// Project Manager - Groups
export const PROJECT_MGR_GROUP_TYPE_PARENT_ENABLED = false // rls: false (for now) - hides the parent group type UI/features, so you can't create nested groups for the time being
export const PROJECT_MGR_GROUP_PROGRAMS_ENABLED = false // rls: false - hide/show the group>programs mapping sidebar panel (post MVP/launch feature)

// Project Watermarks
export const PROJECT_WATERMARK_DEBUG_ENABLED = false // rls: false - hides the debug ui & features on the project watermark settings page
export const PROJECT_WATERMARK_ORIGINS_ENABLED = false // rls: false (for now) - hides the origin/default-posiiton ui features, defaults to TL alignment for the timebeing

// Breadcrumbs - controls how the section (company/project) breadrcumbs will display
export const BREADCRUMB_ROOT_SHOW_SELECTION_NAME = true // when enabled will show the selected company/project name in the root breadcrumb item, otherwise will show the section title e.g. 'project manager'

// Company/Project/Channel dropdowns
export const USER_SELECTION_DROPDOWNS_DESELECT_ENABLED = DEBUG_MODE

// Company/Project User
export const USER_EMAIL_TRUNCATE_LENGTH = 30 // max chars to show for an email before its truncated (with a hover popup to view the full length email)

// Support Page
export const SUPPORT_AUTO_REDIRECT_ON_LOAD = false // rls: true - whenever you goto a /support url it'll auto redirect to the external support url instead

// API Server Selection/Switching (DEBUG/DEV ONLY)
// NB: now set to only be enabled for dev & staging server deployments, not production (relies on what is set in the relevant .env file or env-config.js override if specified)
export const SERVER_SWITCHING_ENABLED = [APIServerType.Staging, APIServerType.Dev, APIServerType.Local].includes(API_SERVER_TYPE) // (process.env.NODE_ENV === 'development')

// Hotlink (temp debug)
export const HOTLINK_ENABLED = true // rls: false (for now) - hides the recently added hotlink support from all UI (project settings, program edit form, & player debug reload btn) until the player auto handles refreshing expired hotlink auth tokens in a fairly reliable way

// Project Guest
export const PROJECT_GUEST_EDIT_ENABLED = true // rls: false (for now) - we disable editing of shared guest (link) accounts until the api fixes a bug that causes issues with editing them (NB: only site-admins/gods can add/edit a guest currently as well)
export const PROJECT_GUEST_SHOW_DESC = true // rls: false (for now) - disabling guest descriptions for the time being until we can edit guest users to be able to update this, & until we've decided how/where to show the descriptions in the UI
export const PROJECT_GUEST_PROFILE_ACCESS_ENABLED = false // rls: false - guests should currently not have any profile area access, placed behind a config var incase we decide certain parts of the profile should still be accessible
export const PROJECT_GUEST_LINK_GENERATOR_DEBUG = false // rls: false - enable the debug output on the guest share link configurator when working on it

// Company Login Services (SSO)
export const COMPANY_LOGIN_SERVICE_SSO_AUTO_LOGIN = true // rls: true - when enabled the SSO login process will auto perform all the SSO related auth steps
export const COMPANY_LOGIN_SERVICE_SSO_DEBUG = false // rls: false - when enabled the SSO login process will show a debug page where you trigger the various SSO related auth steps manually instead of it auto performing all (inc. allowing logging-out/invalidating the 3rd party SSO access token)
// export const COMPANY_LOGIN_SERVICE_SSO_WIPE_TOKEN_PRE_LOGIN = false // rls: false - when enabled the SSO login process will wipe the 3rd party SSO access token before the user starts the SSO process (& so log them out of the SSO provider), this should force them to see the SSO login page instead of it auto returning/redirecting with the cached token (useful for testing/debugging) UPDATE: not currently working
export const COMPANY_LOGIN_SERVICE_SSO_WIPE_TOKEN_POST_LOGIN = true // rls: TESTING: true (was false) - when enabled the SSO login process will wipe the 3rd party SSO access token after the user completes the SSO process (& so log them out of the SSO provider), this should force them to see the SSO login page the next SSO login attempt, instead of it auto returning/redirecting with the cached token (useful for testing/debugging), NB: this shouldn't currently effect our api session management as once they login via SSO we flip to our own auth token tracking instead of the SSO provider (but this may change in the future, to allow SSO provider side logout control) - TODO: can this be disabled again, see commit `39e70556e46790177b261f8d901e6922d4792c34` & ones after it for a related alternative fix that might make this unnecessary again? (needs full testing to be sure)
// export const COMPANY_LOGIN_SERVICE_SSO_FORCE_OKTA = false // rls: false - TEMP: flips the SSO login to use okta instead of auth0 (VERY WIP & only partially implemented so far!)
export const COMPANY_LOGIN_SERVICE_SSO_OKTA_SAML_ENABLED = false // rls: false (for now) - enable okta SAML SSO login & admin controls support (as its currently VERY WIP & only partially implemented so far)
export const COMPANY_LOGIN_SERVICE_TYPE_EMAIL = 1 // TEMP: hardcode the email id for now until we wire up the /config api response in a dynamic way
export const COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC = 2 // TEMP: hardcode the okta (openid) id for now until we wire up the /config api response in a dynamic way
export const COMPANY_LOGIN_SERVICE_TYPE_AUTH0 = 3 // TEMP: hardcode the auth0 id for now until we wire up the /config api response in a dynamic way
export const COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML = 4 // TEMP: hardcode the okta (saml) id for now until we wire up the /config api response in a dynamic way
// SSO - Auth0 config options
export const COMPANY_LOGIN_SERVICE_AUTH0_CONFIG = {
  domain: 'repro.uk.auth0.com',
  clientId: 'HiTQeW3bZGQY8AWrCWDYV6EmV0uZbiHU',
  callbackUrlPath: '/login/sso?callback=1&service=auth0', // NB: relative to the base url
  audience: 'https://repro.uk.auth0.com/api/v2/'
}
// SSO - Okta OIDC config options
export const COMPANY_LOGIN_SERVICE_OKTA_OIDC_CONFIG = {
  loginCallbackUrlPath: '/login/sso?callback=1&service=okta', // NB: relative to the base url
  registerCallbackUrlPath: '/register/sso?callback=1&service=okta' // NB: relative to the base url
}
// SSO - Okta SAML config options
export const COMPANY_LOGIN_SERVICE_OKTA_SAML_CONFIG = {
}
