import React, { ReactNode, useEffect, useRef, useState } from 'react'

import {
  PROGRAM_WATCHDOG_CONNECT_DELAY,
  PROGRAM_WATCHDOG_ENABLED,
  PROGRAM_WATCHDOG_RECONNECT_DELAY,
  PROGRAM_WATCHDOG_RESTART_DELAY,
  PROGRAM_WATCHDOG_SHOW_DEBUG_INFO
} from 'src/constants/config'

import styles from './ProgramWatchdog.module.css'

export enum ProgramWatchdogStatus {
  Connecting = 'connecting',
  Playing = 'playing',
  Reconnecting = 'reconnecting',
  Restarting = 'restarting'
}

interface ProgramWatchdogProps {
  children: ReactNode
  currentTime: number
  disabled?: boolean
  onStatusChange: (status: ProgramWatchdogStatus) => void
  programId: number
}

const ProgramWatchdog = ({ children, currentTime, disabled, onStatusChange, programId }: ProgramWatchdogProps) => {
  if (!PROGRAM_WATCHDOG_ENABLED || disabled) return <>{children}</>

  const currentTimeRef = useRef<number>(0)
  const previousTimeRef = useRef<number>(0)

  const playingDateRef = useRef<number>(0)
  const reconnectingDateRef = useRef<number>(0)

  const statusRef = useRef<ProgramWatchdogStatus>(ProgramWatchdogStatus.Connecting)
  const [status, setStatus] = useState<ProgramWatchdogStatus>(ProgramWatchdogStatus.Connecting)

  useEffect(() => {
    currentTimeRef.current = currentTime
  }, [currentTime])

  useEffect(() => {
    statusRef.current = status
    onStatusChange(status)
  }, [status])

  useEffect(() => {
    console.log('Watchdog - load - program:', programId)
    const loadDate = Date.now()

    const timer = setInterval(async () => {
      // console.log('Watchdog - interval - program:', programId, 'status:', statusRef.current)

      if (currentTimeRef.current && currentTimeRef.current !== previousTimeRef.current) {
        playingDateRef.current = Date.now()
      }
      previousTimeRef.current = currentTimeRef.current

      switch (statusRef.current) {
        case ProgramWatchdogStatus.Connecting: {
          // connecting > playing
          if (playingDateRef.current) {
            setStatus(ProgramWatchdogStatus.Playing)
            break
          }

          // connecting > reconnecting
          if (Date.now() - loadDate > PROGRAM_WATCHDOG_CONNECT_DELAY) {
            reconnectingDateRef.current = Date.now()
            setStatus(ProgramWatchdogStatus.Reconnecting)
            break
          }

          break
        }

        case ProgramWatchdogStatus.Playing: {
          // playing > restarting
          if (Date.now() - playingDateRef.current > PROGRAM_WATCHDOG_RECONNECT_DELAY) {
            reconnectingDateRef.current = Date.now()
            setStatus(ProgramWatchdogStatus.Reconnecting)
            break
          }

          break
        }

        case ProgramWatchdogStatus.Reconnecting: {
          // reconnecting > playing
          if (playingDateRef.current === Date.now()) {
            setStatus(ProgramWatchdogStatus.Playing)
            break
          }

          // reconnecting > restarting
          if (Date.now() - reconnectingDateRef.current > PROGRAM_WATCHDOG_RESTART_DELAY) {
            setStatus(ProgramWatchdogStatus.Restarting)
            break
          }

          break
        }

        case ProgramWatchdogStatus.Restarting: {
          // restarting > reconnecting
          reconnectingDateRef.current = Date.now()
          setStatus(ProgramWatchdogStatus.Reconnecting)
          break
        }
      }
    }, 1000)

    return () => {
      console.log('Watchdog - unload - program:', programId)
      clearInterval(timer)
    }
  }, [])

  return (
    <>
      {children}
      {PROGRAM_WATCHDOG_SHOW_DEBUG_INFO && (
        <pre className={styles.debug}>{`Watchdog Status: ${status}`}</pre>
      )}
    </>
  )
}

export default ProgramWatchdog
