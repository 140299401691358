import React, { useContext } from 'react'

import { ProjectAdminContext } from 'src/core/providers'

import { ProjectGroup } from 'src/core/models'

import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { OBJECT_GROUP_NAME } from 'src/constants/strings'

export type ProjectGroupDeleteCallback = (group: ProjectGroup) => void

interface IProps {
  companyId: number
  projectId: number
  group: ProjectGroup
  onDidDelete?: ProjectGroupDeleteCallback
  className?: string
}

const ProjectGroupDeleteView = (props: IProps) => {
  const { companyId, projectId, group, onDidDelete: _onDidDelete, className } = props

  const projectAdminContext = useContext(ProjectAdminContext)

  const onDelete = async (_itemId: number): Promise<boolean> => {
    // NB: DON'T catch the error/exception here, let it throw (or if you do, rethrow it so the calling code can catch & display it)
    const deleteResult = await projectAdminContext.actions.deleteProjectGroup(companyId, projectId, group.id)
    console.log('ProjectGroupDeleteView - onDelete - deleteProjectGroup - deleteResult: ', deleteResult)
    return deleteResult
  }
  if (!group) return (<></>)
  return (
    <div className={(className ? ' ' + className : '')}>
      <ArkSpacer />
      <ArkManagerDeleteButton
        itemId={group.id}
        itemName={group.name}
        itemTypeName={OBJECT_GROUP_NAME}
        buttonTitle={'DELETE ' + OBJECT_GROUP_NAME}
        onDelete={onDelete}
        onDeleteComplete={() => { if (_onDidDelete) _onDidDelete(group) }}
        onDeleteAllowed={() => { return !group.isDefaultGroup }}
        notAllowedTitle={'Default group warning'}
        notAllowedMsg={'The default group cannot be deleted'}
      />
    </div>
  )
}

export default ProjectGroupDeleteView
