/**
 * ArkDropdown
 */

import React from 'react'

import { Dropdown, DropdownProps } from 'semantic-ui-react'

import styles from './ArkDropdown.module.css'

export interface ArkDropdownProps extends DropdownProps {}

// SUI dropdown work-arounds & tweaks:
// ADDED HERE:
// - added selectOnBlur={false} to stop it auto selecting the 1st one when you click outside an open dropdown when its current value isn't set
// -- ref: https://github.com/Semantic-Org/Semantic-UI-React/issues/3130
// ADD TO CALLING CODE IF NEEDED:
// - using a custom dropdown 'trigger' to render the current selection in a custom way when the dropdown is closed
// -- this allows us to customise & style whats shown when the dropdown isn't open/active (which by default doesn't show the description content like it does in the open list/menu)
// -- this also seems to stop a quirk of the SUI dropdown, clicking outside the dropdown when its open no longer selects the 1st item if none was selected before
// - ALSO using the 'text' prop as well as 'trigger', although we only use it to show the 'Select X' text
// -- by leaving this in tact it seems to stop another quirk of the SUI dropdown, when its open & a project isn't selected..
// -- ..the first item in the list/menu is auto shown in place of the 'Please X' text, if you close it without selecting an item it'll go back to 'Project X'
// -- this doesn't seem to break or intefere with the new trigger usage, so leaving it in to fix this quirk (tried other suggested work-arounds, but none seemed to work with the trigger till I stumbled on this)
// -- ref: 'text' prop: https://github.com/Semantic-Org/Semantic-UI-React/issues/3130#issuecomment-753670066
// - NOT using the 'selection' prop as we normally do
// -- everything (that we use) seems to work ok when using trigger & selection enabled
// -- but when you select an item from the dropdown it throws a warning error about it, although the selection fires ok
// -- so we now no longer set the 'selection' prop, BUT doing so breaks the styling of the dropdown
// -- WORK-AROUND: we now manually add the 'selection' class to the selection (directly as a string), so the SUI css kicks in & styles it as expected
const ArkDropdown = (props: ArkDropdownProps) =>
  <Dropdown selectOnBlur={false} {...props} className={`${styles.dropdown} ${props.className || ''}`} />
export default ArkDropdown
