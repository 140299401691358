import React from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'

import ArkCenterLayout from '../../../components/ArkCenterLayout'
import ArkPage from '../../../components/ArkPage/ArkPage'

import LoginPasswordResetStage1Form from './LoginPasswordResetStage1Form'
import LoginPasswordResetStage2Form from './LoginPasswordResetStage2Form'

import { Header, Segment } from 'semantic-ui-react'

interface IProps extends RouteComponentProps<{resetToken: string}> {
}
interface IState {
}

class LoginPasswordResetPage extends React.Component<IProps, IState> {
  render () {
    const resetToken = this.props.match.params.resetToken
    if (resetToken) {
      return this._renderResetToken()
    }
    return this._renderResetForm()
  }

  _renderResetForm () {
    return (
      <ArkPage>
        <ArkCenterLayout>
          <Segment inverted>
            <Header as="h2" textAlign="center">Reset Password</Header>
            <LoginPasswordResetStage1Form />
          </Segment>
        </ArkCenterLayout>
      </ArkPage>
    )
  }

  _renderResetToken () {
    const resetToken = this.props.match.params.resetToken
    return (
      <ArkPage>
        <ArkCenterLayout>
          <Segment inverted>
            <Header as="h2" textAlign="center">Reset Password Confirmation</Header>
            <LoginPasswordResetStage2Form resetToken={resetToken} />
          </Segment>
        </ArkCenterLayout>
      </ArkPage>
    )
  }
}

export default withRouter(LoginPasswordResetPage)
