import React, { useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import { ProjectAdminContext, UserContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import { Channel, ProjectGroup, ProjectUser } from 'src/core/models'

import ArkProjectManagerPage from 'src/manager/project/components/ArkProjectManagerPage/ArkProjectManagerPage'
import ProjectGroupSidebar from './ProjectGroupSidebar'
import ProjectGroupForm, { GroupFormMode } from './ProjectGroupForm'
import ProjectGroupListItem from './ProjectGroupListItem'

import ArkProjectStatusBanner from 'src/core/components/ArkProjectStatusBanner'
import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkManagerListView, { ArkManagerFilteredItem } from 'src/core/components/ArkManagerListView/ArkManagerListView'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkModal from 'src/core/components/ArkModal'

import { OBJECT_GROUP_NAME, OBJECT_GROUP_NAME_PLURAL, OBJECT_PROJECT_NAME, SECTION_MANAGER_SUFFIX_NAME } from 'src/constants/strings'

interface IProps {}

const ProjectGroupsPage = (_props: IProps) => {
  const mounted = useRef(false)

  const userContext = useContext(UserContext)
  const projectAdminContext = useContext(ProjectAdminContext)

  const [loadingGroups, setLoadingGroups] = useState<boolean>(false)
  const [groups, setGroups] = useState<Array<ProjectGroup>>([])
  const [filteredGroups, setFilteredGroups] = useState<Array<ArkManagerFilteredItem<ProjectGroup>> | undefined>()
  const [filter, setFilter] = useState<string | undefined>()
  const [selectedGroup, setSelectedGroup] = useState<ProjectGroup | undefined>()
  const [selectedSubGroup, setSelectedSubGroup] = useState<ProjectGroup | undefined>()
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false)
  const [groupUsers, setGroupUsers] = useState<Array<ProjectUser> | undefined>()
  const [loadingChannels, setLoadingChannels] = useState<boolean>(false)
  const [groupChannels, setGroupChannels] = useState<Array<Channel> | undefined>()
  const [editGroup, setEditGroup] = useState<ProjectGroup | undefined>()
  const [showGroupFormModal, setShowGroupFormModal] = useState<boolean>(false)

  const company = userContext.store.selectedCompany
  const project = userContext.store.selectedProject
  if (!company || !project) return null

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const loadGroupUsers = async (groupId: number) => {
    if (loadingUsers === true) return false
    const company = userContext.store.selectedCompany
    const project = userContext.store.selectedProject
    if (company && project && groupId) {
      try {
        setLoadingUsers(true)
        const users = await projectAdminContext.actions.getProjectGroupUsers(company.id, project.id, groupId)
        if (mounted.current) {
          setLoadingUsers(false)
          setGroupUsers(users || [])
        }
      } catch (error) {
        console.error('ProjectGroupsPage - loadChannels - error: ', error)
        // TODO: add an error prop & display an error message if this happens
        if (mounted.current) {
          setLoadingUsers(false)
          setGroupUsers([])
        }
      }
    }
  }

  const loadGroupChannels = async (groupId: number) => {
    if (loadingChannels === true) return false
    const company = userContext.store.selectedCompany
    const project = userContext.store.selectedProject
    if (company && project && groupId) {
      try {
        setLoadingChannels(true)
        const channels = await projectAdminContext.actions.getProjectGroupChannels(company.id, project.id, groupId)
        if (mounted.current) {
          setLoadingChannels(false)
          setGroupChannels(channels || [])
        }
      } catch (error) {
        console.error('ProjectGroupsPage - loadChannels - error: ', error)
        // TODO: add an error prop & display an error message if this happens
        if (mounted.current) {
          setLoadingChannels(false)
          setGroupChannels([])
        }
      }
    }
  }

  // -------

  const selectGroup = (group?: ProjectGroup) => {
    console.log('ProjectGroupsPage - selectGroup - group: ', group)
    setSelectedGroup(group)
    setSelectedSubGroup(undefined)
    if (group) {
      loadGroupUsers(group.id)
      loadGroupChannels(group.id)
    }
  }

  const selectSubGroup = (group?: ProjectGroup, subGroup?: ProjectGroup) => {
    console.log('ProjectGroupsPage - selectSubGroup - group: ', group, ' subGroup: ', subGroup)
    setSelectedGroup(group)
    setSelectedSubGroup(subGroup)
    if (subGroup) {
      loadGroupUsers(subGroup.id)
      loadGroupChannels(subGroup.id)
    }
  }

  // -------

  const filterGroups = (_filter: string) => {
    if (loadingGroups) return
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredGroups = filter
      ? groups.reduce<Array<ArkManagerFilteredItem<ProjectGroup>>>((r, group) => {
        let nameMatch = false
        let descMatch = false
        if (group.name.toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        if (group.desc?.toLowerCase().includes(filter.toLowerCase())) {
          descMatch = true
        }
        if (nameMatch || descMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          if (descMatch) matchingFields.push('desc')
          const filteredUser: ArkManagerFilteredItem<ProjectGroup> = {
            item: group,
            matchingFields
          }
          r.push(filteredUser)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<ProjectGroup>>)
      : undefined
    if (selectedGroup && (!(filteredGroups?.find((filteredGroup) => filteredGroup.item.id === selectedGroup.id)))) {
      selectGroup(undefined) // if a user was selected but isn't in the filtered list deselect them
    }
    setFilter(filter)
    setFilteredGroups(filteredGroups)
  }

  // const clearFilteredGroups = () => {
  //   setFilter(undefined)
  //   setFilteredGroups(undefined)
  // }

  // -------

  const loadGroups = async () => {
    if (loadingGroups === true) return false
    const company = userContext.store.selectedCompany
    const project = userContext.store.selectedProject
    if (company && project) {
      try {
        setLoadingGroups(true)
        const _groups = await projectAdminContext.actions.getProjectGroups(company.id, project.id)
        // console.log('ProjectGroupsPage - loadGroups - _groups:', _groups)
        if (mounted.current) {
          setLoadingGroups(false)
          setGroups(_groups || [])
          setFilteredGroups(undefined)
        }
        if (mounted.current && filter) filterGroups(filter) // re-filter if it was active
        return _groups
      } catch (error) {
        console.error('ProjectGroupsPage - loadGroups - error: ', error)
        // TODO: add an error prop & display an error message if this happens
        if (mounted.current) {
          setLoadingGroups(false)
          setGroups([])
          setFilteredGroups(undefined)
        }
      }
    }
    return false
  }

  const updateProjectGroup = (updatedGroup: ProjectGroup) => {
    // update our groups cache with the supplied group object (instead of needing to re-query the api for it)
    if (groups) {
      const groupIndex = groups.findIndex((g: ProjectGroup) => g.id === updatedGroup.id)
      if (groupIndex >= 0) {
        const _groups = [...groups] // make a shallow copy
        _groups[groupIndex] = updatedGroup
        setGroups(_groups)
      }
    }
    // TODO: will this update as expected, or should the `_groups` array from above be used instead? (as the state var may not have updated yet?) <<<<
    // trigger the sidepanel to update with the updated group object
    if (selectedGroup && groups) {
      const newGroup = groups.find((g) => g.id === selectedGroup?.id)
      setSelectedGroup(newGroup)
    }
  }

  // -------

  useEffect(() => {
    loadGroups()
  }, [])

  // -------

  const showAddGroupModal = () => {
    setShowGroupFormModal(true)
    setEditGroup(undefined)
  }

  const showEditGroupModal = (group: ProjectGroup) => {
    setShowGroupFormModal(true)
    setEditGroup(group)
  }

  const hideGroupModal = () => {
    // NB: DON'T clear/reset `editGroup` here, see `didHideGroupModal` which is called once the modal has actually closed & we can safely reset it then
    // NB: this stops a saved edit form flipping the 'updated' success text to 'created' briefly while the modal closes if we reset it straight away here
    // NB: & also stops the form title flipping from edit to add as it closes via the cancel button
    setShowGroupFormModal(false)
  }

  // triggered via modal callbacks once the modal has already closed
  const didHideGroupModal = () => {
    setShowGroupFormModal(false)
    setEditGroup(undefined)
  }

  // -------

  const onEdit = (_group: ProjectGroup) => {
    showEditGroupModal(_group)
  }

  const onDidDelete = (_group: ProjectGroup) => {
    // trigger a programs data re-load so the deleted project no longer shows
    loadGroups()
    selectGroup(undefined)
  }

  // -------

  const renderGroupAddEditForm = (group?: ProjectGroup) => {
    const company = userContext.store.selectedCompany
    const project = userContext.store.selectedProject
    const parentGroups = groups.filter((group: ProjectGroup) => group.isParentGroup)
    const mirroredGroups = groups.filter((group: ProjectGroup) => group.isMirroredGroup())
    if (!company || !project) return null
    return (
      <ProjectGroupForm
        mode={editGroup ? GroupFormMode.Edit : GroupFormMode.Add}
        companyId={company.id}
        projectId={project.id}
        group={group}
        parentGroups={parentGroups}
        mirroredGroups={mirroredGroups}
        onCancel={() => { hideGroupModal() }}
        onDidSave={async () => {
          // trigger a channels data re-load to show the newly added one
          const _groups = await loadGroups()
          console.log('ProjectGroupsPage - renderGroupAddEditForm - onDidSave - _groups: ', _groups)
          if (!mounted.current) return
          // re-select with the updated group/sub-group so any changes are shown/used
          // NB: now using the returned `_groups` array directly as the state `groups` var may not have updated by the time we reach here
          if (_groups) {
            if (selectedSubGroup && group && selectedSubGroup.id === group.id) {
              const updatedGroup = _.find(_groups, (grp) => grp.id === selectedGroup?.id)
              const updatedSubGroup = _.find(updatedGroup?.subGroups, (grp) => grp.id === selectedSubGroup?.id)
              selectSubGroup(selectedGroup, updatedSubGroup)
            } else if (selectedGroup && group && selectedGroup.id === group.id) {
              const updatedGroup = _.find(_groups, (grp) => grp.id === selectedGroup?.id)
              selectGroup(updatedGroup)
            }
          }
          // NB: we don't auto close/hide the modal form when it saves, leave it up for a success message to show & the user to dismiss manually
        }}
        onClose={() => { hideGroupModal() }}
        insideModal={true}
      />
    )
  }

  const renderGroupFormModal = () => {
    return (
      <ArkModal open={showGroupFormModal} onClose={() => didHideGroupModal()}>
        {renderGroupAddEditForm(editGroup)}
      </ArkModal>
    )
  }

  // -------

  const renderGroupTableRowContent = (group: ProjectGroup, isSelected: boolean, filter?: string) => {
    return (
      <ProjectGroupListItem
        companyId={company.id}
        projectId={project.id}
        group={group}
        active={isSelected}
        filter={filter}
        key={group.id}
        onClick={() => selectGroup(group)}
        // onEditClick={() => this.showEditGroupModal(group)}
        onGroupUpdated={updateProjectGroup}
      />
    )
  }

  // -------

  const renderGroupFilterForm = () => {
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        filterTitle='Filter by name or description'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          filterGroups(filter)
        }}
      />
    )
  }

  // -------

  const rightSidebar = selectedGroup && (
    <ProjectGroupSidebar
      companyId={company.id}
      projectId={project.id}
      group={selectedSubGroup ?? selectedGroup}
      onChange={() => {
        // TODO: can this be merged with `onGroupUpdated` & instead of reloading all groups, just update the specific group like that callback does in `updateProjectGroup`??? <<<<
        // trigger a data re-load to show changes to any groups (e.g. users/channels were added/removed from a group)
        loadGroups()
        // re-select the group so it reloads its extra data incase they've changed
        if (selectedGroup && selectedSubGroup) {
          selectSubGroup(selectedGroup, selectedSubGroup)
        } else if (selectedGroup) {
          selectGroup(selectedGroup)
        }
      }}
      onEdit={onEdit}
      onDidDelete={onDidDelete}
      onGroupUpdated={updateProjectGroup}
      loadingUsers={loadingUsers}
      loadingChannels={loadingChannels}
      groupUsers={groupUsers}
      groupChannels={groupChannels}
    />
  )

  return (
    <ArkProjectManagerPage
      onRightSidebarClose={() => setSelectedGroup(undefined)}
      rightSidebar={rightSidebar}
    >
      <ArkManagerContentView
        title={OBJECT_GROUP_NAME_PLURAL}
        breadcrumb={[{
          path: ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_VIEW, project.id),
          title: ROUTES.formatBreadcrumbRootTitle(project.name, `${OBJECT_PROJECT_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`)
        }]}
      >
        <ArkProjectStatusBanner />
        <ArkManagerListView
          loading={loadingGroups}
          items={groups}
          selectedItem={selectedGroup}
          itemRow={(group: ProjectGroup, isSelected: boolean) => {
            return renderGroupTableRowContent(group, isSelected, filter)
          }}
          // topbar={this.topBarContent}
          topbarAddItemTitle={'CREATE ' + OBJECT_GROUP_NAME}
          onAdd={() => showAddGroupModal()}
          // FILTERING:
          filter={filter}
          filteredItems={filteredGroups}
          filterForm={renderGroupFilterForm()}
          onClearFilter={() => filterGroups('') }
        />
        {renderGroupFormModal()}
      </ArkManagerContentView>
    </ArkProjectManagerPage>
  )
}
export default ProjectGroupsPage
