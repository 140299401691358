import React from 'react'

import * as ROUTES from 'src/constants/routes'
import { withUserContext, IUserMultiContext } from 'src/core/providers'

import ArkCompanyManagerPage from 'src/manager/company/components/ArkCompanyManagerPage/ArkCompanyManagerPage'
import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import CompanySettingsView from './CompanySettingsView'

import { PAGE_SETTINGS_NAME, SECTION_COMPANY_NAME, SECTION_MANAGER_SUFFIX_NAME } from 'src/constants/strings'

interface IProps extends IUserMultiContext {}
interface IState {}

class CompanySettingsPage extends React.Component<IProps, IState> {
  render () {
    const company = this.props.userContext.store.selectedCompany
    if (!company) return (<></>) // NB: the calling router should catch this & stop this page from loading if no company is selected
    return (<ArkCompanyManagerPage>
      <ArkManagerContentView
        title={PAGE_SETTINGS_NAME}
        breadcrumb={[{
          path: ROUTES.COMPANY_MANAGER,
          title: ROUTES.formatBreadcrumbRootTitle(company.name, `${SECTION_COMPANY_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`)
        }]}
      >
        <CompanySettingsView />
      </ArkManagerContentView>
    </ArkCompanyManagerPage>)
  }
}
export default withUserContext(CompanySettingsPage)
