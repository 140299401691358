import React from 'react'
import PhoneInput from 'react-phone-input-2'

import { Label, LabelProps, SemanticShorthandItem } from 'semantic-ui-react'

import 'react-phone-input-2/lib/semantic-ui.css'
import styles from './ArkFormPhoneNoField.module.css'

export interface ArkFormPhoneNoFieldResult {
  value: string
}
export type ArkFormPhoneNoFieldChangeCallback = (result: ArkFormPhoneNoFieldResult) => void
export type ArkFormPhoneNoFieldResultCallback = (result: ArkFormPhoneNoFieldResult) => void

interface IProps {
  label?: string | React.ReactNode
  required?: boolean
  defaultValue?: string
  large?: boolean // increases the text input size
  dropdownPosition?: 'bottom' | 'right' // default bottom
  onChange?: ArkFormPhoneNoFieldChangeCallback
  onChangeComplete?: ArkFormPhoneNoFieldResultCallback
  error?: boolean | SemanticShorthandItem<LabelProps> // NB: mimicing the SUI Form field error prop
}
interface IState {
  value: string
}

class ArkFormPhoneNoField extends React.Component<IProps, IState> {
  defaultValue = ''
  constructor (props: IProps) {
    super(props)
    this.state = {
      value: props.defaultValue ?? this.defaultValue
    }
  }

  render () {
    return (
      <>
        <div className={
          'field' +
          ' ' + styles.field +
          (this.props.required ? ' required' : '') +
          (this.props.large ? ' large' : '') +
          (this.props.dropdownPosition === 'right' ? ' alignRight' : '')
        }>
          {this.props.label && (<label>{this.props.label}</label>)}
          <PhoneInput
            country={'gb'}
            placeholder=''
            countryCodeEditable={false}
            preferredCountries={['gb', 'it']}
            value={this.state.value}
            onChange={(value, data, event, formattedValue) => {
              console.log('ArkFormPhoneNoField - render - PhoneInput - onChange - value: ', value, ' formattedValue: ', formattedValue)
              this.setState({ value: '+' + value })
              if (this.props.onChange) this.props.onChange({ value: '+' + value })
            }}
          />
          <div className={styles.notice}>You may need to omit the first 0 of your phone number after the country code.</div>
          {this.props.error && this.renderError()}
        </div>
      </>
    )
  }

  renderError = () => {
    const error = this.props.error
    if (error && typeof error !== 'boolean' && (error as any).content) {
      // mimic the SUI Form errors
      return (<Label color='red' prompt {...(error as any)} />)
    }
    return null
  }

  // -------

  // onColourChange = (color: ColorResult, _event: React.ChangeEvent<HTMLInputElement>) => {
  //   console.log('ArkFormPhoneNoField - onColourChange - color: ', color)
  //   this.setState({ colour: color.hex })
  // }

  // onColourChangeComplete = (color: ColorResult, _event: React.ChangeEvent<HTMLInputElement>) => {
  //   console.log('ArkFormPhoneNoField - onColourChangeComplete - color: ', color)
  //   this.setState({ colour: color.hex })
  //   if (this.props.onChangeComplete) this.props.onChangeComplete({ colour: color.hex })
  // }
}

export default ArkFormPhoneNoField
