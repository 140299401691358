import React from 'react'
import { Link, withRouter, RouteComponentProps, matchPath } from 'react-router-dom'

import {
  AuthSSOProvider,
  withAuthContext, IAuthMultiContext, AuthStatus,
  withGlobalConfigProvider, IGlobalConfigMultiContext,
  withNavContext, INavMultiContext,
  withServerContext, IServerMultiContext
} from '../../../providers'
import { AuthLoginServiceType, IAuthLoginService } from 'src/core/models'

import ArkButton from '../../../components/ArkButton'
import ArkCenterLayout from '../../../components/ArkCenterLayout'
import ArkPage from '../../../components/ArkPage/ArkPage'
import * as ROUTES from '../../../../constants/routes'

import LoginEmailLookupForm from './LoginEmailLookupForm'
import LoginEmailPasswordForm from './LoginEmailPasswordForm'
import Login2FAForm from './Login2FAForm'
import LoginNotUserView from './LoginNotUserView'
import LoginSSOView from './LoginSSOView'

import { Divider, Header, Label, Segment } from 'semantic-ui-react'

import styles from './LoginPage.module.css'

enum LoginPageStage {
  email, password, sso, tfa
}

interface IProps extends IAuthMultiContext, INavMultiContext, IServerMultiContext, IGlobalConfigMultiContext, RouteComponentProps {}
interface IState {
  loginStage: LoginPageStage
  loginService?: IAuthLoginService // NB: only used for SSO logins here (so we know which sso service to use & its config details)
  email?: string
  isSSOCallback: boolean
}

class LoginPage extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = {
      loginStage: LoginPageStage.email,
      isSSOCallback: false
    }
  }

  componentDidMount () {
    console.log('LoginPage - componentDidMount - cacheEmail:', this.props.authContext.store.cacheEmail)
    let authEmail: string | undefined
    if (this.props.authContext.store.cacheEmail && this.props.authContext.store.cacheType === 'login') {
      console.log('LoginPage - componentDidMount - cacheEmail set + cacheType === login - clear cache...')
      authEmail = this.props.authContext.store.cacheEmail
      // this.props.authContext.actions.cacheClear() // NB: no longer clearing the cache here so page refreshes mid login/register resume with the cached email
    }

    if (authEmail) {
      console.log('LoginPage - componentDidMount - authEmail:', authEmail)
      // UPDATE: instead of skipping the email lookup stage when an email was cached (e.g redirected from the register page)
      // UPDATE: ..we auto re-run the lookup so we can check the login service type/provider & handle it accordingly (instead of caching that as well from the register page, to avoid stale cache issues or potential abuse of the cache)
      // this.setState({ email: authEmail, loginStage: LoginPageStage.password })
      this.setState({ email: authEmail, loginStage: LoginPageStage.email })
    }

    // check if this is an sso callback route/path (redirected back from the external okta/auth0 login service/provider)
    // ..if so treat this as an sso login stage & hand over further sso processing to the dedicated sso view & its provider (it figures out which login service etc.)
    // NB: we don't have access to the AuthSSOProvider at this level/commponent, so we manually check the route/path here
    // NB: now only setting to true if theres args in the sso callback url, don't consider it a callback if they aren't (as they've already been parsed?)
    const isSSOCallbackPath = matchPath(this.props.location.pathname, { path: ROUTES.LOGIN_SSO, exact: false, strict: false })
    const hasSSOCallbackArgs = this.props.location.search !== ''
    console.log('LoginPage - componentDidMount - isSSOCallbackPath:', isSSOCallbackPath, ' hasSSOCallbackArgs:', hasSSOCallbackArgs, ' this.props.location:', this.props.location)
    if (isSSOCallbackPath && hasSSOCallbackArgs && this.state.loginStage !== LoginPageStage.sso) {
      this.setState({ loginStage: LoginPageStage.sso, isSSOCallback: true }) // TODO: reset/update if the page url changes while this page/component remains loaded?
    }
  }

  componentDidUpdate (prevProps: IProps) {
    // check if the auth providers status changes to tfa required, if so show the tfa input form
    if (prevProps.authContext.store.authStatus !== AuthStatus.tfa && this.props.authContext.store.authStatus === AuthStatus.tfa) {
      console.log('LoginPage - componentDidUpdate - authStatus CHANGED TO 2FA...')
      this.setState({ loginStage: LoginPageStage.tfa })
    }
  }

  render () {
    const { loginStage, loginService } = this.state
    let page: JSX.Element | null = null
    switch (loginStage) {
      case LoginPageStage.email: page = this._renderLoginEmailLookupPage(); break
      case LoginPageStage.password: page = this._renderLoginEmailPasswordPage(); break
      case LoginPageStage.sso: page = this._renderLoginSSOPage(loginService); break
      case LoginPageStage.tfa: page = this._renderLogin2FAPage(); break
    }

    return (
      <ArkPage>
        <ArkCenterLayout>
          <Segment inverted>{page}</Segment>
        </ArkCenterLayout>
      </ArkPage>
    )
  }

  _renderLoginEmailLookupPage = () => {
    const globalConfig = this.props.configContext.store.config
    return (
      <>
        <Header as="h2" textAlign="center">Login</Header>
        {this._renderLoginEmailLookupForm()}
        {globalConfig.registrationEnabled
          ? <div className={styles.register}>
            <Divider horizontal inverted>Or</Divider>
            <Label size="large">
              <p>Not registered yet?</p>
            </Label>
            <ArkButton fluid size="large" onClick={() => {
              this.props.navContext.actions.goto(ROUTES.REGISTER)
            }}>
              Register
            </ArkButton>
          </div>
          : <></>}
      </>
    )
  }

  _renderLoginEmailLookupForm = () => {
    return (
      <LoginEmailLookupForm
        email={this.state.email}
        autoRun={this.state.email !== undefined}
        onEmailLookup={(email: string, loginService: IAuthLoginService) => {
          console.log('LoginPage - onEmailLookup - email:', email, ' loginService:', loginService)
          const isNewUser = loginService.type === AuthLoginServiceType.NewUser
          const loginServiceType = isNewUser ? (loginService.ssoConfig?.type ?? AuthLoginServiceType.NewUser) : loginService.type
          console.log('LoginPage - onEmailLookup - isNewUser:', isNewUser, ' loginServiceType:', loginServiceType)
          if (!isNewUser && loginServiceType === AuthLoginServiceType.EmailPassword) {
            this.setState({
              loginStage: LoginPageStage.password,
              loginService: loginService,
              email
            })
          } else if (!isNewUser && (loginServiceType === AuthLoginServiceType.SSOOktaOIDC || loginServiceType === AuthLoginServiceType.SSOOktaSAML)) {
            this.setState({
              loginStage: LoginPageStage.sso,
              loginService: loginService,
              email
            })
          } else if (!isNewUser && loginServiceType === AuthLoginServiceType.SSOAuth0) {
            this.setState({
              loginStage: LoginPageStage.sso,
              loginService: loginService,
              email
            })
          } else if (!isNewUser) {
            console.log('RegisterPage - onEmailLookup - WARNING: !isNewUser - loginServiceType:', loginServiceType, ' - UNKNOWN/UNHANDLED LOGIN SERVICE TYPE <<<<')
            // TODO: throw/show an error??
          } else {
            console.log('LoginPage - onEmailLookup - isNewUser - GOTO REGISTER PAGE...')
            // redirect to the register page & cache the email so the loaded page can check for it & skip the email entry step
            this.props.authContext.actions.cacheRegisterEmail(email)
            this.props.navContext.actions.goto(ROUTES.REGISTER)
          }
        }}
      />
    )
  }

  _renderLoginEmailPasswordPage = () => {
    const { email } = this.state
    if (!email) return (<></>)
    return (
      <>
        <Header as="h2" textAlign="center">Enter your password</Header>
        <p style={{ textAlign: 'center' }}>
          Enter your password {this.state.email ? 'for ' + this.state.email + ' ' : ''}
        </p>
        {this._renderLoginEmailPasswordForm(email)}
        <LoginNotUserView
          email={email}
          onClick={() => {
            this.props.authContext.actions.cacheClear()
            // NB: now clearing the `email` state here otherwise it'll auto re-run the email lookup & block you from changing the email
            this.setState({ loginStage: LoginPageStage.email, loginService: undefined, email: undefined })
          }}
        />
        <div style={{ textAlign: 'center', paddingTop: 12, textDecoration: 'underline' }}>
          <Link to={ROUTES.LOGIN_PASSWORD_FORGOT}>Forgot your password?</Link>
        </div>
      </>
    )
  }

  _renderLoginSSOPage = (loginService?: IAuthLoginService) => {
    const { email, isSSOCallback } = this.state
    console.log('LoginPage - _renderLoginSSOPage - email:', email, ' loginService:', loginService)
    const apiClient = this.props.serverContext.store.apiClient
    const authApi = this.props.serverContext.store.authApi
    // NB: we might not have the email available here if we're coming back from the okta/auth0 callback..
    // ..so render/call it regardless & let it check/handle internally (loads the email from the cache if its set etc.)
    if (!apiClient || !authApi) return null
    return (
      <AuthSSOProvider apiClient={apiClient} authApi={authApi}>
        <LoginSSOView
          email={email}
          loginService={loginService}
          isSSOCallback={isSSOCallback}
          onCancel={this._onLoginCancel}
        />
      </AuthSSOProvider>
    )
  }

  _renderLoginEmailPasswordForm = (email: string) => {
    return (
      <LoginEmailPasswordForm
        email={email}
        onLoginSuccess={(result: boolean) => {
          // TESTING: if login failed, check if 2fa is required
          // TODO: get the callback to indicate if 2fa is needed in the return values, save the extra lookups here...
          if (!result) {
            // TODO:
            // TODO: PORT <<<<
            // TODO:
            console.log('LoginPage - LoginEmailPasswordForm - onLoginSuccess - CHECK IF 2FA - TODO: <<<<<<<<<')
            // check if TFA is required
            // TODO: wire this up to the provider, it won't work like this with the provider?
            // const authActions = this.props.authContext.actions
            // console.log('LoginPage - onSubmit - isLoggedIn: ', authActions.isLoggedIn(), ' requiresTFACode: ', authActions.requiresTFACode())
            // if (!authActions.isLoggedIn() && authActions.requiresTFACode()) {
            //   this.setState({ loginStage: LoginPageStage.tfa })
            // }
          }
        }}
      />
    )
  }

  _renderLogin2FAPage = () => {
    const { email } = this.state
    if (!email) return (<></>)
    return (
      <>
        <Login2FAForm />
        <LoginNotUserView
          email={email ?? ''}
          title={'Cancel'}
          onClick={() => {
            this.props.authContext.actions.cacheClear()
            // NB: now clearing the `email` state here otherwise it'll auto re-run the email lookup & block you from changing the email
            this.setState({ loginStage: LoginPageStage.email, loginService: undefined, email: undefined })
          }}
        />
        {/* TODO: add a suitable support page/section for login, including 2fa & link to it here... */}
        {/* <div className={styles.tfaHelp}>
          <span className={styles.tfaHelpLink} onClick={() => { console.log('TODO!!') }}>2FA code not working?</span>
        </div> */}
      </>
    )
  }

  _onLoginCancel = () => {
    console.log('LoginPage - _onLoginCancel')
    this.props.authContext.actions.cacheClear()
    this.props.authContext.actions.clearAuthError()
    this.setState({ loginStage: LoginPageStage.email, loginService: undefined, email: undefined, isSSOCallback: false })
  }
}

export default withGlobalConfigProvider(withRouter(withNavContext(withAuthContext(withServerContext(LoginPage)))))
