/**
 * ProjectGroupForm
 * - A form for adding & editing project groups
 * - Supports creating standard (native project) groups, & now also mirrored org groups, or cloned org groups (which end up as standard project groups with the users natively added to the project)
 *
 * NOTE: parent group handling is disabled & was partially renamed/edited but NOT finished/tested when added org group support & different 'create group types/sources' (& also further tweaked when field change tracking was added & the field name was renamed slightly)
 * TODO: decide if we want to keep the parent group handling & if so decide if & when it should be shown & used (e.g. can org groups be set as parents or have a parent set? or should they disable any parent features?)
 */
import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import _ from 'lodash'

import { ProjectAdminContext } from 'src/core/providers'

import { ProjectGroup, CompanyGroup, CompanyUser, ProjectUser, GroupUserOperation, ProjectInviteUser, ProjectInviteUserType, UserProjectRole } from 'src/core/models'
import { IProjectGroupAddData, IProjectGroupUpdateData } from 'src/core/models/group'

import { OBJECT_COMPANY_NAME, OBJECT_COMPANY_SHORTNAME, OBJECT_GROUP_NAME, OBJECT_PROJECT_NAME, OBJECT_USER_NAME, OBJECT_USER_NAME_PLURAL } from 'src/constants/strings'
import { PROJECT_MGR_GROUP_TYPE_PARENT_ENABLED } from 'src/constants/config'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkButton from 'src/core/components/ArkButton'
import ArkDivider from 'src/core/components/ArkDivider'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps, ArkFormFieldOption /*, ArkFormFieldPlaceholder */ } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { capitalize } from 'src/core/utilities/strings'

import styles from './ProjectGroupForm.module.css'

const PROJECT_GROUP_CLONE_DEBUG_USER_PROJECT_SKIP_ADD = false // rls = false - skip adding new users to the project when cloning a group (for testing)
const PROJECT_GROUP_CLONE_DEBUG_USER_PROJECT_FORCE_ERROR = false // rls = false - force a per user error when adding new users to the project while cloning a group (for testing)

const formSchema = yup.object().shape({
  name: yup.string().min(4).max(50).label(`${capitalize(OBJECT_GROUP_NAME)} Name`),
  desc: yup.string().min(0).max(255).label(`${capitalize(OBJECT_GROUP_NAME)} Description`),
  parentGroupId: yup.number().transform((currentVal, originalVal) => {
    // TODO: why is a null value coming through as a string of null sometimes (when editing an existing group with no parent group set??)
    return originalVal === null || originalVal === 'null' ? null : currentVal
  }).nullable().min(0)
})

export enum GroupFormMode {
  Add = 'add',
  Edit = 'edit',
}

enum CreateGroupType {
  Standard = 1,
  MirrorOrg = 2
}

enum CreateGroupSource {
  New = 1,
  CloneOrg = 2
}

interface IProjectGroupFormValues {
  name: string
  desc: string
  isParentGroup: boolean
  parentGroupId: number | undefined
  cloneCompanyGroupId: number | undefined
  mirrorCompanyGroupId: number | undefined
}

interface IProps {
  mode: GroupFormMode
  companyId: number
  projectId: number
  group?: ProjectGroup
  parentGroups?: Array<ProjectGroup> // TODO: type? should we add a distinct `ParentGroup` type instead? <<<<
  mirroredGroups?: Array<ProjectGroup> // a list of any mirrored groups already assigned to this project
  onCancel?: Function
  onDidSave?: Function
  onClose?: Function
  insideModal?: boolean // ArkForm prop - enable when showing this form within a modal (so fieldset label bg's match)
}

const ProjectGroupForm = (props: IProps) => {
  const mounted = useRef(false)

  const {
    mode,
    companyId,
    projectId,
    group,
    parentGroups,
    mirroredGroups,
    onCancel: _onCancel,
    onDidSave: _onDidSave,
    onClose: _onClose,
    insideModal
  } = props

  const projectAdminContext = useContext(ProjectAdminContext)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSaved, setHasSaved] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()
  // clone (& mirror?) group creation - user results
  const [isCloning, setIsCloning] = useState<boolean>(false)
  const [hasCloned, setHasCloned] = useState<boolean>(false)
  const [cloneUsersAddedToProject, setCloneUsersAddedToProject] = useState<Map<number, { user: CompanyUser }> | undefined>()
  const [cloneUsersAddedToGroup, setCloneUsersAddedToGroup] = useState<Map<number, { user: CompanyUser }> | undefined>()
  const [cloneUserErrors, setCloneUserErrors] = useState<Map<number, { user: CompanyUser, cloneStage: 'project' | 'group', error: Error }> | undefined>()

  // tracked field values (NB: some are only used at submit time & aren't tracked here)
  const [createGroupType, setCreateGroupType] = useState<CreateGroupType | undefined>()
  const [createGroupSource, setCreateGroupSource] = useState<CreateGroupSource | undefined>()

  const [loadingCompanyGroups, setLoadingCompanyGroups] = useState<boolean>(false)
  const [companyGroups, setCompanyGroups] = useState<Array<CompanyGroup> | undefined>()
  const [companyGroupsError, setCompanyGroupsError] = useState<Error | undefined>()

  const [loadingCompanyUsers, setLoadingCompanyUsers] = useState<boolean>(false)
  const [companyGroupUsers, setCompanyGroupUsers] = useState<Array<CompanyUser> | undefined>()
  const [companyGroupUsersError, setCompanyGroupUsersError] = useState<Error | undefined>()

  const [loadingProjectUsers, setLoadingProjectUsers] = useState<boolean>(false)
  const [projectUsers, seProjectUsers] = useState<Array<ProjectUser> | undefined>()
  const [projectUsersError, setProjectUsersError] = useState<Error | undefined>()

  // form field values (saved & current)
  const [savedValues, setSavedValues] = useState<IProjectGroupFormValues>({
    name: group?.name ?? '',
    desc: group?.desc ?? '',
    isParentGroup: group?.isParentGroup ?? false,
    parentGroupId: group?.parentGroupId ?? undefined,
    cloneCompanyGroupId: undefined,
    mirrorCompanyGroupId: undefined
  })
  const [formValues, setFormValues] = useState<IProjectGroupFormValues>(savedValues)
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])

  // -------

  // load the available visible org/company groups we can mirror or clone from (NB: only visible groups are return, org groups control which projects they are visible too)
  const loadVisibleCompanyGroups = async () => {
    if (loadingCompanyGroups) return
    try {
      setLoadingCompanyGroups(true)
      setCompanyGroupsError(undefined)
      const _companyGroups = await projectAdminContext.actions.getVisibleCompanyGroupsForProject(companyId, projectId)
      console.log('ProjectGroupForm - loadVisibleCompanyGroups - _companyGroups: ', _companyGroups)
      setCompanyGroups(_companyGroups)
      setLoadingCompanyGroups(false)
    } catch (error) {
      if (mounted.current) {
        setLoadingCompanyGroups(false)
        setCompanyGroups(undefined)
        setCompanyGroupsError(error)
      }
    }
  }

  // -------

  const loadVisibleGroupUsers = async (groupId: number) => {
    if (loadingCompanyUsers === true) return false
    if (companyId && groupId) {
      try {
        setLoadingCompanyUsers(true)
        const users = await projectAdminContext.actions.getVisibleCompanyGroupUsers(companyId, groupId)
        console.log('ProjectGroupForm - loadVisibleGroupUsers - users: ', users)
        // // DEBUG ONLY: >>> START <<<
        // // DEBUG ONLY: add extra mock users so we can test rendering of them (also flip the `const users` line above to `let users` so we can overwrite it below)
        // // NB: you have to refresh the page, or reload the form for this to kick in (otherwise it'll keep using the already cached state var instead)
        // const mockUsers = []
        // for (let i = 1; i <= 100; i++) {
        //   mockUsers.push(new CompanyUser(999000 + i, 'mockuser' + i, 'Mock User', '' + i))
        // }
        // users = [...(users || []), ...mockUsers]
        // // DEBUG ONLY: >>> END <<<
        if (mounted.current) {
          setLoadingCompanyUsers(false)
          setCompanyGroupUsers(users || undefined)
        }
      } catch (error) {
        console.error('ProjectGroupForm - loadVisibleGroupUsers - error: ', error)
        if (mounted.current) {
          setLoadingCompanyUsers(false)
          setCompanyGroupUsers(undefined)
          setCompanyGroupUsersError(error)
        }
      }
    }
  }

  // -------

  // load the current project users so we can check if any are already assigned if creating a mirrored or cloned group
  const loadProjectUsers = async () => {
    if (loadingProjectUsers === true) return false
    if (companyId && projectId) {
      try {
        setLoadingProjectUsers(true)
        const users = await projectAdminContext.actions.getProjectUsers(companyId, projectId)
        console.log('ProjectGroupForm - loadProjectUsers - users: ', users)
        if (mounted.current) {
          setLoadingProjectUsers(false)
          seProjectUsers(users ?? undefined)
        }
      } catch (error) {
        console.error('ProjectGroupForm - loadProjectUsers - error: ', error)
        if (mounted.current) {
          setLoadingProjectUsers(false)
          seProjectUsers(undefined)
          setProjectUsersError(undefined)
        }
      }
    }
  }

  // -------

  useEffect(() => {
    mounted.current = true
    if (mode === GroupFormMode.Add) {
      // load the project users on init in 'add' mode, incase we need it (instead of trying to load it at specific moments & dealing with not reloading etc. & also other api calls)
      loadProjectUsers()
      // also load all the visible/available company groups we can mirror or clone from
      loadVisibleCompanyGroups()
    }
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const getFormValueChanges = () => {
    const _changes: Array<string> = []
    if (formValues) {
      for (const fieldName of Object.keys(formValues)) {
        const oldValue = savedValues !== undefined ? (savedValues as any)[fieldName] : undefined
        const newValue = (formValues as any)[fieldName]
        // console.log('ProjectGroupForm - getFormValueChanges - fieldName:', fieldName, ' oldValue:', oldValue, ` (${typeof oldValue})`, ' newValue:', newValue, ` (${typeof newValue})`)
        if (!_.isEqual(oldValue, newValue)) {
          if (
            // NB: added extra check if comparing an empty object/array to the an undefined old value
            !(oldValue === undefined && newValue !== undefined && typeof newValue === 'object' && newValue.length === 0) &&
            // NB: added extra check if comparing an empty string to an undefined old value
            !(oldValue === undefined && newValue !== undefined && typeof newValue === 'string' && newValue === '')
          ) {
            _changes.push(fieldName)
          }
        }
      }
    }
    // console.log('ProjectGroupForm - getFormValueChanges - _changes:', _changes)
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  const setFormValue = (fieldKey: string, value: any) => {
    console.log('ProjectGroupForm - setFormValue - fieldKey:', fieldKey, ' value:', value)
    setFormValues({
      ...formValues,
      [fieldKey]: value
    })
  }

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = getFormValueChanges()
    setChanges(_changes)
    console.log('ProjectGroupForm - useEffect[formValues] - formValues:', formValues, ' savedValues:', savedValues, ' _changes:', _changes)
  }, [formValues])

  // -------

  // -------

  const updateGroupNameAndDescForSelectedCompanyGroup = (companyGroupId: number, type: 'mirror' | 'clone') => {
    if (mode === GroupFormMode.Edit) return
    const companyGroup = companyGroups?.find((group) => group.id === companyGroupId)
    console.log('ProjectGroupForm - updateGroupNameAndDescForSelectedCompanyGroup - companyGroupId:', companyGroupId, ' type:', type, ' companyGroup:', companyGroup)
    if (companyGroup) {
      let name = companyGroup.name
      if (type === 'mirror') name = name + ' (Mirror)'
      if (type === 'clone') name = name + ' (Clone)'
      setFormValues({
        ...formValues,
        name: name,
        desc: companyGroup.desc ?? ''
      })
    } else {
      // clear the name & desc if the company group is not found
      setFormValues({
        ...formValues,
        name: '',
        desc: ''
      })
    }
  }

  useEffect(() => {
    console.log('ProjectGroupForm - useEffect[formValues - mirrorCompanyGroupId/cloneCompanyGroupId] - formValues:', formValues)
    if (formValues.mirrorCompanyGroupId !== undefined) updateGroupNameAndDescForSelectedCompanyGroup(formValues.mirrorCompanyGroupId, 'mirror')
  }, [formValues.mirrorCompanyGroupId])

  useEffect(() => {
    console.log('ProjectGroupForm - useEffect[formValues - mirrorCompanyGroupId/cloneCompanyGroupId] - formValues:', formValues)
    if (formValues.cloneCompanyGroupId !== undefined) updateGroupNameAndDescForSelectedCompanyGroup(formValues.cloneCompanyGroupId, 'clone')
  }, [formValues.cloneCompanyGroupId])

  // -------

  const _addGroup = async (name: string, desc?: string, mirrorCompanyGroupId?: number, parentGroupId?: number, isParentGroup?: boolean): Promise<ProjectGroup | undefined> => {
    if (isSubmitting) return
    setIsSubmitting(true)
    setError(undefined)
    try {
      const groupData: IProjectGroupAddData = {
        name: name
      }
      groupData.desc = desc !== undefined && desc.length > 0 ? desc : undefined
      groupData.isParentGroup = isParentGroup !== undefined ? isParentGroup : false
      groupData.parentGroupId = parentGroupId
      groupData.mirrorGroupId = mirrorCompanyGroupId
      console.log('ProjectGroupForm - addGroup - groupData:', groupData)
      const savedGroup = await projectAdminContext.actions.addProjectGroup(companyId, projectId, groupData)
      console.log('ProjectGroupForm - addGroup - savedGroup: ', savedGroup)
      if (mounted.current) {
        setIsSubmitting(false)
        setHasSaved(true)
        setSavedValues(formValues) // update the saved values to match the current form values
      }
      if (_onDidSave) _onDidSave()
      return savedGroup
      // throw new Error('TODO: Implement addProjectGroup')
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
    return undefined
  }

  const addStandardNewGroup = async (name: string, desc?: string, parentGroupId?: number, isParentGroup: boolean = false) => {
    console.log('ProjectGroupForm - addStandardNewGroup - name:', name, ' desc:', desc, ' parentGroupId:', parentGroupId, ' isParentGroup:', isParentGroup)
    return await _addGroup(name, desc, undefined, parentGroupId, isParentGroup)
  }

  const addStandardCloneGroup = async (cloneGroupId: number, name: string, desc?: string, parentGroupId?: number) => {
    console.log('ProjectGroupForm - addStandardCloneGroup - cloneGroupId:', cloneGroupId, ' name:', name, ' desc:', desc, ' parentGroupId:', parentGroupId)
    // create the new project group first
    // TODO: should we prevent the `_onDidSave` callback within `_addGroup` firing until the full clone process has finished? (although if we fail mid way through cloning we still likely want to trigger this so the partial cloned group will show straight away??)
    // NB: for now we leave the default `_onDidSave` callback to fire here & also at the end of the clone process (so the parent component can update again with the correct user count)
    const newProjectGroup = await _addGroup(name, desc, undefined, parentGroupId, undefined)
    console.log('ProjectGroupForm - addStandardCloneGroup - newProjectGroup:', newProjectGroup)
    if (newProjectGroup) {
      setIsCloning(true)
      setHasCloned(false)
      setError(undefined)
      setCloneUsersAddedToProject(undefined)
      setCloneUsersAddedToGroup(undefined)
      setCloneUserErrors(undefined)
      await new Promise(resolve => setTimeout(resolve, 500)) // add a brief delay, so the user see's the loading indicator on quick api calls (rather than showing a success message, this makes it kind of obvious it did something & so worked)
      // if the group was added successfully, add the users from the cloned org group to the new project group (inviting/adding them to the project first if needed)
      if (companyGroupUsers) {
        const _cloneUsersAddedToProject: Map<number, { user: CompanyUser }> = new Map()
        const _cloneUsersAddedToGroup: Map<number, { user: CompanyUser }> = new Map()
        const _cloneUserErrors: Map<number, { user: CompanyUser, cloneStage: 'project' | 'group', error: Error }> = new Map()
        // we first need to 'invite' (add) any new org users to this project that don't have direct access, before we can assign them to the new project group
        // split the list of group users into two, one with 'new to this project' users (not directly members of this project) & the second with existing project users (existing direct users of this project (not just mirrored))
        // console.log('ProjectGroupForm - addStandardCloneGroup - projectUsers:', projectUsers, ' companyGroupUsers:', companyGroupUsers)
        const newProjectUsers = companyGroupUsers?.filter((user) => {
          return !projectUsers?.find((projectUser) => projectUser.id === user.id && projectUser.projectDirectUser)
        })
        const existingProjectUsers = companyGroupUsers?.filter((user) => {
          return !!projectUsers?.find((projectUser) => projectUser.id === user.id && projectUser.projectDirectUser)
        })
        console.log('ProjectGroupForm - addStandardCloneGroup - newProjectUsers:', newProjectUsers, ' existingProjectUsers:', existingProjectUsers)
        if (newProjectUsers && newProjectUsers.length > 0) {
          if (!PROJECT_GROUP_CLONE_DEBUG_USER_PROJECT_SKIP_ADD) {
            const inviteUsers: Array<ProjectInviteUser> = []
            for (const user of newProjectUsers) {
              inviteUsers.push({ type: ProjectInviteUserType.user, userId: user.id, role: UserProjectRole.member })
            }
            const inviteNewUsersResult = await projectAdminContext.actions.inviteUsersToProject(companyId, projectId, inviteUsers)
            console.log('ProjectGroupForm - addStandardCloneGroup - inviteNewUsersResult:', inviteNewUsersResult)
            if (inviteNewUsersResult.ok.length > 0) {
              for (const inviteResult of inviteNewUsersResult.ok) {
                const user = newProjectUsers.find((user) => user.id === inviteResult.userId)
                if (inviteResult.userId && user) _cloneUsersAddedToProject.set(inviteResult.userId, { user: user })
              }
            }
            if (inviteNewUsersResult.error.length > 0) {
              for (const inviteResult of inviteNewUsersResult.error) {
                const user = newProjectUsers.find((user) => user.id === inviteResult.userId)
                if (inviteResult.userId && user) _cloneUserErrors.set(inviteResult.userId, { user: user, cloneStage: 'project', error: inviteResult.error || Error('An error occurred inviting the user to the project.') })
              }
            }
          }
          // DEBUG ONLY: set all new project add/invite users to error for testing (commment out the above inviteUsersResult code as well)
          if (PROJECT_GROUP_CLONE_DEBUG_USER_PROJECT_FORCE_ERROR) {
            for (const user of newProjectUsers) {
              _cloneUserErrors.set(user.id, { user: user, cloneStage: 'project', error: Error('DUMMY ERROR: An error occurred inviting the user to the project.') })
            }
          }
          console.log('ProjectGroupForm - addStandardCloneGroup - _cloneUsersAddedToProject:', _cloneUsersAddedToProject, ' _cloneUserErrors:', _cloneUserErrors)
        }
        const addOperations: Array<GroupUserOperation> = []
        const skipUserIds = _cloneUserErrors.size > 0 ? Array.from(_cloneUserErrors.keys()) : []
        for (const user of companyGroupUsers) {
          if (skipUserIds.includes(user.id)) continue // don't add users to the group if they needed inviting first & that errored (see `userResultsError`)
          addOperations.push({
            operation: 0, // 0 === ADD
            user_id: user.id
          })
        }
        console.log('ProjectGroupForm - addStandardCloneGroup - addOperations:', addOperations)
        if (addOperations.length === 0) {
          console.log('ProjectGroupForm - addStandardCloneGroup - no users to add to the project group (errors occurred inviting new users)')
          if (mounted.current) {
            setIsCloning(false)
            setHasCloned(true) // NB: doesn't really matter if this is true/false here
            setCloneUsersAddedToProject(_cloneUsersAddedToProject)
            setCloneUsersAddedToGroup(_cloneUsersAddedToGroup)
            setCloneUserErrors(_cloneUserErrors)
            setError(undefined)
          }
          return // stop the process here if there are no users to add to the group (due to either the source org group having no users to add, or user project add/invite errors above)
        }
        try {
          const result = await projectAdminContext.actions.updateProjectGroupUsers(companyId, projectId, newProjectGroup.id, addOperations)
          console.log('ProjectGroupForm - addStandardCloneGroup - updateProjectGroupUsers - result: ', result, ' typeof: ', typeof result, ' instanceof Map: ', result instanceof Map)
          let hasUpdated = false
          let error: Error | undefined
          if (result === true) { // all operations succeeded
            if (mounted.current) {
              hasUpdated = true
            }
            for (const operation of addOperations) {
              const user = companyGroupUsers.find((user) => user.id === operation.user_id)
              if (user) _cloneUsersAddedToGroup.set(operation.user_id, { user: user })
            }
          } else if (result instanceof Map) { // 1 or more operations had errors (but some may have still succeeded)
            // all operations failed
            if (result.size === addOperations.length) {
              hasUpdated = false
            } else { // only some operations failed
              hasUpdated = true // set true if some added/updated ok
            }
            // the result contains errors for each operation that failed (any that succeeded are not included in the result map)
            for (const [userId, errorData] of result) {
              console.log('ProjectGroupForm - addStandardCloneGroup - updateProjectGroupUsers - userId:', userId, ' errorData:', errorData)
              const user = companyGroupUsers.find((user) => user.id === userId)
              if (user) _cloneUserErrors.set(userId, { user: user, cloneStage: 'group', error: errorData.error_message ? Error(errorData.error_message) : Error('An error occurred adding the user to the group.') })
            }
            for (const operation of addOperations) {
              console.log('ProjectGroupForm - addStandardCloneGroup - updateProjectGroupUsers - result instanceof Map - operation:', operation, ' operation.user_id:', operation.user_id, ' result.has(operation.user_id):', result.has(operation.user_id), ' result:', result)
              if (result.has(operation.user_id)) continue
              const user = companyGroupUsers.find((user) => user.id === operation.user_id)
              if (user) _cloneUsersAddedToGroup.set(operation.user_id, { user: user })
            }
            console.log('ProjectGroupForm - addStandardCloneGroup - updateProjectGroupUsers - result instanceof Map - _cloneUsersAddedToGroup:', _cloneUsersAddedToGroup)
            error = new Error('A problem occurred adding one or more users to the group.')
          } else { // general/fallback error (with the whole api call)
            hasUpdated = false
            error = new Error('A problem occurred adding users to the group.')
          }
          console.log('ProjectGroupForm - addStandardCloneGroup - updateProjectGroupUsers - hasUpdated:', hasUpdated, ' _cloneUserErrors:', _cloneUserErrors, ' error:', error) // ' savedIds:', savedIds
          if (mounted.current) {
            setIsCloning(false)
            setHasCloned(hasUpdated) // true
            setCloneUsersAddedToProject(_cloneUsersAddedToProject)
            setCloneUsersAddedToGroup(_cloneUsersAddedToGroup)
            setCloneUserErrors(_cloneUserErrors)
            setError(error)
          }
          if (_onDidSave) _onDidSave() // (re)trigger the did save callback, so the parent component can update with the correct user count etc.
        } catch (error) {
          if (mounted.current) {
            setIsCloning(false)
            setError(error)
          }
        }
      }
    } else {
      // group failed to add, so don't try to add the users (the original add group error should show directly, so no action needed here)
    }
  }

  const addMirrorGroup = async (mirrorGroupId: number, name: string, desc?: string, parentGroupId?: number) => {
    console.log('ProjectGroupForm - addMirrorGroup - mirrorGroupId:', mirrorGroupId, ' name:', name, ' desc:', desc, ' parentGroupId:', parentGroupId)
    return await _addGroup(name, desc, mirrorGroupId, parentGroupId, false)
  }

  // NB: only basic fields can be updated for any group type, so can be reused for all group types
  const updateGroup = async () => {
    if (isSubmitting || !group) return
    setIsSubmitting(true)
    setError(undefined)
    try {
      const groupData: IProjectGroupUpdateData = {}
      if (changes.includes('name')) groupData.name = formValues.name
      if (changes.includes('desc')) groupData.desc = formValues.desc
      if (changes.includes('parentGroupId')) groupData.parentGroupId = formValues.parentGroupId
      console.log('ProjectGroupForm - updateGroup - groupData:', groupData)
      const savedGroup = await projectAdminContext.actions.updateProjectGroup(companyId, projectId, group.id, groupData)
      console.log('ProjectGroupForm - updateGroup - savedGroup:', savedGroup)
      if (savedGroup) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
          setSavedValues(formValues) // update the saved values to match the current form values
        }
        if (_onDidSave) _onDidSave()
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          setError(Error('A problem occurred updating the ' + OBJECT_GROUP_NAME + ', please try again.'))
        }
      }
      // throw new Error('TODO: Implement updateProjectGroup')
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('ProjectGroupForm - onFormSubmit - fieldValues: ', fieldValues)
    // NB: use the local state vars for form field values updated via the onValueChanged handler
    // const { parentGroup } = fieldValues // name, desc, createGroupType, createGroupSource, isParentGroup, mirrorCompanyGroup, cloneCompanyGroup
    // const parentGroupId = formValues.parentGroupId // parentGroup && parentGroup !== '' ? parseInt(parentGroup) : undefined
    console.log('ProjectGroupForm - onFormSubmit - createGroupType:', createGroupType, ' createGroupSource:', createGroupSource, ' formValues:', formValues) // ' groupName:', groupName, ' groupDesc:', groupDesc, ' parentGroupId:', parentGroupId, ' isParentGroup:', isParentGroup, ' mirrorCompanyGroup:', mirrorCompanyGroup, ' cloneCompanyGroup:', cloneCompanyGroup)
    if (!formValues.name || formValues.name === '') {
      console.warn('ProjectGroupForm - onFormSubmit - companyName not set')
      return
    }
    if (error) setError(undefined)
    if (props.mode === GroupFormMode.Add) {
      if (createGroupType === CreateGroupType.Standard) {
        if (createGroupSource === CreateGroupSource.New) {
          addStandardNewGroup(formValues.name, formValues.desc, formValues.parentGroupId)
        } else if (createGroupSource === CreateGroupSource.CloneOrg) {
          if (formValues.cloneCompanyGroupId) {
            addStandardCloneGroup(formValues.cloneCompanyGroupId, formValues.name, formValues.desc, formValues.parentGroupId)
          } else {
            console.warn('ProjectGroupForm - onFormSubmit - no cloneCompanyGroup selected')
          }
        } else {
          console.warn('ProjectGroupForm - onFormSubmit - invalid createGroupSource')
        }
      } else if (createGroupType === CreateGroupType.MirrorOrg) {
        if (formValues.mirrorCompanyGroupId) {
          addMirrorGroup(formValues.mirrorCompanyGroupId, formValues.name, formValues.desc, formValues.parentGroupId)
        } else {
          console.warn('ProjectGroupForm - onFormSubmit - no mirrorCompanyGroup selected')
        }
      } else {
        console.warn('ProjectGroupForm - onFormSubmit - invalid createGroupType')
      }
    } else if (props.mode === GroupFormMode.Edit) {
      updateGroup()
    }
  }

  const onValueChanged = (fieldKey: string, fieldValue: any, _oldFieldValue: any) => {
    console.log('ProjectGroupForm - onValueChanged - fieldKey:', fieldKey, ' fieldValue:', fieldValue, ' (' + typeof fieldValue + ')', ' oldFieldValue:', _oldFieldValue)
    // update form values with the change (if its for a field we're tracking changes in)
    if (fieldKey in savedValues) {
      console.log('ProjectGroupForm - onValueChanged - fieldKey:', fieldKey, ' is in savedValues - update formValues - fieldValue:', fieldValue, ' == ', {
        ...formValues,
        [fieldKey]: fieldValue
      })
      setFormValues({
        ...formValues,
        [fieldKey]: fieldValue
      })
    }
    // field specific handling
    // if (fieldKey === 'groupName') {
    //   setGroupName(fieldValue)
    // } else if (fieldKey === 'groupDesc') {
    //   setGroupDesc(fieldValue)
    // } else if (fieldKey === 'isParentGroup') {
    //   setIsParentGroup(fieldValue === GroupType.parent)
    // } else
    if (fieldKey === 'createGroupType') {
      // GroupType change:
      const _fieldValue = typeof fieldValue === 'number' ? fieldValue : undefined
      setCreateGroupType(_fieldValue)
      // clear the source if the type is changed
      setCreateGroupSource(undefined) // if (_fieldValue === undefined)
      // clear the mirrot & clone org group if the source is changed
      if (formValues.mirrorCompanyGroupId) setFormValue('mirrorCompanyGroupId', undefined) // if (mirrorCompanyGroup) setMirrorCompanyGroup(undefined)
      if (formValues.cloneCompanyGroupId) setFormValue('cloneCompanyGroupId', undefined) // if (cloneCompanyGroup) setCloneCompanyGroup(undefined)
      // clear the group users for any source change
      if (companyGroupUsers) setCompanyGroupUsers(undefined)
    } else if (fieldKey === 'createGroupSource') {
      // GroupSource change:
      const _fieldValue = typeof fieldValue === 'number' ? fieldValue : undefined
      setCreateGroupSource(_fieldValue)
      // clear the clone org group if the source is changed
      if (formValues.cloneCompanyGroupId) setFormValue('cloneCompanyGroupId', undefined) // if (cloneCompanyGroup) setCloneCompanyGroup(undefined)
      // clear the group users for any source change
      if (companyGroupUsers) setCompanyGroupUsers(undefined)
    } else if (fieldKey === 'mirrorCompanyGroupId' || fieldKey === 'cloneCompanyGroupId') {
      // Mirror/Clone CompanyGroup change:
      const _fieldValue = typeof fieldValue === 'number' ? fieldValue : undefined
      // if (fieldKey === 'mirrorCompanyGroupId') {
      //   // setFormValue('mirrorCompanyGroupId', _fieldValue) // setMirrorCompanyGroup(_fieldValue) // NB: already updated above (by the main setFormValues call)
      //   // if (_fieldValue !== undefined) updateGroupNameAndDescForSelectedCompanyGroup(_fieldValue, 'mirror') // UPDATE: now moved to a useEffect hook, as it broke/overwrote other setFormValue calls above when it was here
      // }
      // if (fieldKey === 'cloneCompanyGroupId') {
      //   // setFormValue('cloneCompanyGroupId', _fieldValue) // setCloneCompanyGroup(_fieldValue) // NB: already updated above (by the main setFormValues call)
      //   // if (_fieldValue !== undefined) updateGroupNameAndDescForSelectedCompanyGroup(_fieldValue, 'clone') // UPDATE: now moved to a useEffect hook, as it broke/overwrote other setFormValue calls above when it was here
      // }
      // console.log('ProjectGroupForm - onValueChanged - fieldKey:', fieldKey, ' _fieldValue:', _fieldValue)
      // clear the group users from any previous selection
      if (companyGroupUsers) setCompanyGroupUsers(undefined)
      // load the users for the selected org/company group
      if (_fieldValue) loadVisibleGroupUsers(_fieldValue)
    }
  }

  const onCancel = () => {
    console.log('ProjectGroupForm - onCancel')
    if (_onCancel) _onCancel()
  }

  const onClose = () => {
    console.log('ProjectGroupForm - onClose')
    if (_onClose) _onClose()
  }

  // -------

  const _renderGroupUsersList = (groupUsersType: 'new' | 'existing' | 'clone', groupUsers?: Array<CompanyUser>) => {
    if (loadingCompanyUsers) return null
    const groupUserCount = groupUsers?.length ?? 0
    return (
      <div className={styles.groupUsers + ' ' + styles.list}>
        {groupUsers && groupUsers.length > 0 && (
          <ul>
            {groupUsers.map((user: CompanyUser) => {
              return (
                <li key={'group_user_' + user.id}>
                  <ArkAvatar
                    type={user.userAvatarType()}
                    name={user.name()}
                    size="30"
                  />
                </li>
              )
            })}
          </ul>
        )}
        {(groupUserCount === 0 && groupUsersType !== 'clone') && (<span>⚠️ No {(groupUsersType === 'new' ? 'new' : 'existing')} {OBJECT_USER_NAME_PLURAL} assigned to this {OBJECT_GROUP_NAME}</span>)}
      </div>
    )
  }
  const renderCompanyGroupUserSummary = () => {
    console.log('ProjectGroupForm - renderCompanyGroupUserSummary - formValues:', formValues)
    const companyGroupId = formValues.mirrorCompanyGroupId ?? formValues.cloneCompanyGroupId // mirrorCompanyGroup ?? cloneCompanyGroup
    const companyGroup = companyGroupId ? companyGroups?.find((group) => group.id === companyGroupId) : undefined
    console.log('ProjectGroupForm - renderCompanyGroupUserSummary - companyGroupId:', companyGroupId, ' companyGroup:', companyGroup, ' companyGroupUsers:', companyGroupUsers, ' companyGroups:', companyGroups)
    if (!companyGroup) return null
    // get a list of group users that are new to this project (filter out any users that already have access to the project (either directly assigned to the project, or via a mirrored group))
    const newCompanyGroupUsers = companyGroupUsers?.filter((user) => {
      return !projectUsers?.find((projectUser) => projectUser.id === user.id)
    })
    // get a list of group users that already have access to this project (filter out any users that don't have access to the project yet)
    const existingCompanyGroupUsers = companyGroupUsers?.filter((user) => {
      return projectUsers?.find((projectUser) => projectUser.id === user.id)
    })
    const newCompanyGroupUserCount = newCompanyGroupUsers?.length ?? 0
    const existingCompanyGroupUserCount = existingCompanyGroupUsers?.length ?? 0
    console.log('ProjectGroupForm - renderCompanyGroupUserSummary - companyGroup:', companyGroup, ' companyGroupUsers:', companyGroupUsers, ' newCompanyGroupUsers:', newCompanyGroupUsers, ' existingCompanyGroupUsers:', existingCompanyGroupUsers, ' newCompanyGroupUserCount:', newCompanyGroupUserCount, ' existingCompanyGroupUserCount:', existingCompanyGroupUserCount)
    return (
      <div className={styles.orgGroupUserSummary}>
        {loadingCompanyUsers && <div className={styles.loader}><ArkLoader small inline /></div>}
        {!loadingCompanyUsers && (
          <div>
            <div className={styles.orgGroupUserSummaryInfo}>
              This will create a new {OBJECT_GROUP_NAME} of <span className={styles.orgGroupUserCount}>{companyGroupUsers?.length ?? 0}</span> {OBJECT_USER_NAME_PLURAL}.
            </div>
            <ul className={styles.orgGroupUsersBreakdown}>
              {existingCompanyGroupUserCount > 0 && (
                <li>
                  <div className={styles.orgGroupUserBreakdownInfo}><span className={styles.orgGroupUserCount}>{existingCompanyGroupUserCount}</span>/<span className={styles.orgGroupUserCount}>{companyGroupUsers?.length ?? 0}</span> {OBJECT_USER_NAME_PLURAL} who already have access to this {OBJECT_PROJECT_NAME}:<br /></div>
                  {_renderGroupUsersList('existing', existingCompanyGroupUsers)}
                </li>
              )}
              {newCompanyGroupUserCount > 0 && (
                <li>
                  <div className={styles.orgGroupUserBreakdownInfo}><span className={styles.orgGroupUserCount}>{newCompanyGroupUserCount}</span>/<span className={styles.orgGroupUserCount}>{companyGroupUsers?.length ?? 0}</span> {OBJECT_USER_NAME_PLURAL} who will be added to this {OBJECT_PROJECT_NAME}:</div>
                  {_renderGroupUsersList('new', newCompanyGroupUsers)}
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    )
  }

  // -------

  if (loadingCompanyGroups || loadingProjectUsers) return <ArkLoaderView message='Loading' />

  // -------

  const isDefaultGroup = mode === GroupFormMode.Edit ? (group?.isDefaultGroup ?? false) : false

  const formFields: Array<ArkFormField> = []

  // CreateGroupType
  const createGroupTypeOptions: Array<ArkFormFieldOption> = [
    // { key: 'select', text: 'Select ' + capitalize(OBJECT_GROUP_NAME) + ' Type', value: 0 },
    { key: 'standard', text: 'New Standard ' + capitalize(OBJECT_GROUP_NAME), value: CreateGroupType.Standard },
    { key: 'mirrorOrg', text: 'Mirror from ' + capitalize(OBJECT_COMPANY_SHORTNAME) + ' ' + capitalize(OBJECT_GROUP_NAME), value: CreateGroupType.MirrorOrg }
  ]

  // CreateGroupSource
  const createGroupSourceOptions: Array<ArkFormFieldOption> = [
    // { key: 'select', text: 'Select ' + capitalize(OBJECT_GROUP_NAME) + ' Source', value: 0 },
    { key: 'new', text: 'Create new empty ' + capitalize(OBJECT_GROUP_NAME), value: CreateGroupSource.New },
    { key: 'cloneOrg', text: 'Clone and import users from ' + capitalize(OBJECT_COMPANY_SHORTNAME) + ' ' + capitalize(OBJECT_GROUP_NAME), value: CreateGroupSource.CloneOrg }
  ]

  if (mode === GroupFormMode.Add) {
    formFields.push({
      type: ArkFormFieldType.Dropdown,
      key: 'createGroupType',
      label: OBJECT_GROUP_NAME + ' Type',
      placeholder: 'Select ' + capitalize(OBJECT_GROUP_NAME) + ' Type',
      required: true,
      value: createGroupType, // NB: powered by the locally tracked state
      options: createGroupTypeOptions,
      fieldProps: {
        scrolling: true, // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
        clearable: true
      }
    })

    if (createGroupType && createGroupType === CreateGroupType.Standard) {
      formFields.push({
        type: ArkFormFieldType.Dropdown,
        key: 'createGroupSource',
        label: OBJECT_GROUP_NAME + ' Source',
        placeholder: 'Select ' + capitalize(OBJECT_GROUP_NAME) + ' Source',
        required: true,
        value: createGroupSource, // NB: powered by the locally tracked state
        options: createGroupSourceOptions,
        fieldProps: {
          scrolling: true, // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
          clearable: true
        }
      })
    }
  }

  const showMainForm = (
    mode === GroupFormMode.Edit ||
    (mode === GroupFormMode.Add && createGroupType &&
      (
        (createGroupType === CreateGroupType.Standard && createGroupSource) ||
        (createGroupType === CreateGroupType.MirrorOrg)
      )
    )
  )

  if (showMainForm) {
    formFields.push({
      type: ArkFormFieldType.Field,
      key: 'divider_1',
      content: (<ArkDivider />)
    })

    const showMirrorOrgGroup = createGroupType && createGroupType === CreateGroupType.MirrorOrg
    const showCloneOrgGroup = createGroupType && createGroupType === CreateGroupType.Standard && createGroupSource && createGroupSource === CreateGroupSource.CloneOrg

    const companyGroupOptions: Array<ArkFormFieldOption> = []
    // companyGroupOptions.push({ key: 'company_group_none', text: 'None', value: 0 })
    if (companyGroups) {
      for (const companyGroup of companyGroups) {
        // if showing 'mirrored org groups' - check if this companyGroup is already mirrored to this project & disable it if so (don't disable if showing 'clone org groups')
        const isAssigned = mirroredGroups?.findIndex((mirroredGroup) => mirroredGroup.mirrorGroupId === companyGroup.id) !== -1
        companyGroupOptions.push({
          key: 'company_group_' + companyGroup.id,
          value: companyGroup.id,
          text: companyGroup.name,
          disabled: showMirrorOrgGroup && isAssigned
        })
      }
    }
    console.log('ProjectGroupForm - companyGroupOptions:', companyGroupOptions)

    let companyGroupUsersPreviewField: ArkFormField | undefined
    if (loadingCompanyUsers || companyGroupUsers) {
      console.log('ProjectGroupForm - companyGroupUsersPreviewField - formValues:', formValues)
      companyGroupUsersPreviewField = {
        type: ArkFormFieldType.Field,
        key: 'companyGroupUserSummary',
        content: renderCompanyGroupUserSummary()
      }
    }
    console.log('ProjectGroupForm - loadingCompanyUsers:', loadingCompanyUsers, ' companyGroupUsers:', companyGroupUsers, ' companyGroupUsersPreviewField:', companyGroupUsersPreviewField)

    if (showMirrorOrgGroup) {
      console.log('ProjectGroupForm - showMirrorOrgGroup:', showMirrorOrgGroup, ' mirrorCompanyGroupId:', formValues.mirrorCompanyGroupId)
      formFields.push({
        type: ArkFormFieldType.Fieldset,
        key: 'mirrorCompanyGroupFieldset',
        label: 'Mirror ' + OBJECT_COMPANY_SHORTNAME + ' ' + OBJECT_GROUP_NAME,
        fields: [
          {
            type: ArkFormFieldType.Field,
            key: 'mirrorCompanyGroupInfo',
            content: (
              <p>All {OBJECT_USER_NAME_PLURAL} assigned to the selected {OBJECT_COMPANY_SHORTNAME} {OBJECT_GROUP_NAME} will be automatically have access to this {OBJECT_PROJECT_NAME}. Any {OBJECT_USER_NAME} access changes can only be made at the {OBJECT_COMPANY_NAME} level to the source {OBJECT_COMPANY_SHORTNAME} {OBJECT_GROUP_NAME}. Any changes there will be mirrored in this {OBJECT_GROUP_NAME}. </p>
            )
          },
          {
            type: ArkFormFieldType.Dropdown,
            key: 'mirrorCompanyGroupId',
            label: OBJECT_COMPANY_SHORTNAME + ' ' + OBJECT_GROUP_NAME,
            placeholder: 'Select ' + capitalize(OBJECT_COMPANY_SHORTNAME) + ' ' + capitalize(OBJECT_GROUP_NAME),
            required: false,
            value: formValues.mirrorCompanyGroupId, // NB: powered by the locally tracked state
            options: companyGroupOptions,
            disabled: props.mode === GroupFormMode.Edit,
            fieldProps: {
              scrolling: true, // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
              clearable: true
            },
            className: hasChanges('mirrorCompanyGroupId') ? styles.hasChanged : undefined
          },
          ...(companyGroupUsersPreviewField !== undefined ? [companyGroupUsersPreviewField] : [])
        ],
        collapsible: false,
        collapsed: false
      })
    }

    if (showCloneOrgGroup) {
      console.log('ProjectGroupForm - showCloneOrgGroup:', showCloneOrgGroup, ' mirrorCompanyGroupId:', formValues.mirrorCompanyGroupId)
      formFields.push({
        type: ArkFormFieldType.Fieldset,
        key: 'cloneCompanyGroupFieldset',
        label: 'Clone from ' + OBJECT_COMPANY_SHORTNAME + ' ' + OBJECT_GROUP_NAME,
        fields: [
          {
            type: ArkFormFieldType.Field,
            key: 'cloneCompanyGroupInfo',
            content: (
              <p>All {OBJECT_USER_NAME_PLURAL} assigned to the selected {OBJECT_COMPANY_SHORTNAME} {OBJECT_GROUP_NAME} will be automatically added to this {OBJECT_PROJECT_NAME}. Once this {OBJECT_GROUP_NAME} is cloned it can be edited just like any standard {OBJECT_PROJECT_NAME} {OBJECT_GROUP_NAME}.</p>
            )
          },
          {
            type: ArkFormFieldType.Dropdown,
            key: 'cloneCompanyGroupId',
            label: OBJECT_COMPANY_SHORTNAME + ' ' + OBJECT_GROUP_NAME,
            placeholder: 'Select ' + capitalize(OBJECT_COMPANY_SHORTNAME) + ' ' + capitalize(OBJECT_GROUP_NAME),
            required: false,
            value: formValues.cloneCompanyGroupId, // NB: powered by the locally tracked state
            options: companyGroupOptions,
            // disabled: props.mode === GroupFormMode.Edit, // NB: not shown when editing a group, so no need to disable it
            fieldProps: {
              scrolling: true, // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
              clearable: true
            },
            className: hasChanges('cloneCompanyGroupId') ? styles.hasChanged : undefined
          },
          ...(companyGroupUsersPreviewField !== undefined ? [companyGroupUsersPreviewField] : [])
        ],
        collapsible: false,
        collapsed: false
      })
    }

    formFields.push({
      type: ArkFormFieldType.Fieldset,
      key: 'groupDetailsFieldset',
      label: OBJECT_GROUP_NAME + ' Details',
      fields: [
        {
          type: ArkFormFieldType.Input,
          key: 'name',
          label: OBJECT_GROUP_NAME + ' name',
          required: true,
          value: formValues.name, // NB: powered by the locally tracked state
          className: hasChanges('name') ? styles.hasChanged : undefined
        },
        {
          type: ArkFormFieldType.Input,
          key: 'desc',
          label: OBJECT_GROUP_NAME + ' description',
          required: false,
          value: formValues.desc, // NB: powered by the locally tracked state
          className: hasChanges('desc') ? styles.hasChanged : undefined
        }
      ],
      collapsible: false,
      collapsed: false
    })

    if (PROJECT_MGR_GROUP_TYPE_PARENT_ENABLED) {
      // NB: `isParentGroup` field is disabled on the edit form (only allowed to set it when creating a new group)
      const isParentGroupOptions: Array<ArkFormFieldOption> = [
        { key: 'project', text: OBJECT_PROJECT_NAME, value: 0 },
        { key: 'parent', text: 'Parent', value: 1 }
      ]
      formFields.push({
        type: ArkFormFieldType.Radio,
        key: 'isParentGroup', // NB: was previously named `groupType` but renamed when adding various org group features
        label: OBJECT_GROUP_NAME + ' type',
        required: true,
        // defaultValue: group?.isParentGroup ? 1 : 0,
        value: formValues.isParentGroup ? 1 : 0, // TODO: will this work, as `isParentGroup` is a boolean, not a number? or does the state var need to be changed to a number (or added as a 2nd state var alongside the bool & used here in the form field?)
        options: isParentGroupOptions,
        disabled: (mode === GroupFormMode.Edit),
        className: hasChanges('isParentGroup') ? styles.hasChanged : undefined
      })

      const disableParentGroup = isDefaultGroup || formValues.isParentGroup
      const currentParentGroupId = (group?.parentGroupId !== undefined && group?.parentGroupId !== null && !disableParentGroup ? (group?.parentGroupId) : 0)
      const parentGroupOptions: Array<ArkFormFieldOption> = []
      parentGroupOptions.push({ key: 'group_' + 'none', text: 'No parent ' + OBJECT_GROUP_NAME, value: 0 })
      if (parentGroups) {
        for (const parentGroup of parentGroups) {
          parentGroupOptions.push({
            key: 'group_' + parentGroup.id,
            text: parentGroup.name,
            value: parentGroup.id
          })
        }
      }
      formFields.push({
        type: ArkFormFieldType.Dropdown,
        key: 'parentGroupId',
        label: 'Parent ' + OBJECT_GROUP_NAME,
        required: false,
        defaultValue: currentParentGroupId, // TODO: can't use both `defaultValue` & `value`? should flip `currentParentGroupId` to be a state var & power the field directly from it, like other fields in this form?
        value: (disableParentGroup ? 0 : undefined), // NB: force the parentGroupId to 0 (no parent) when disabling the field for GroupType.org mode
        options: parentGroupOptions,
        disabled: disableParentGroup,
        fieldProps: { scrolling: true }, // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
        className: hasChanges('parentGroupId') ? styles.hasChanged : undefined
      })
    }
  }

  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      {
        type: ArkFormFieldType.CancelButton,
        key: 'cancel',
        label: 'CANCEL',
        fieldProps: { onClick: onCancel, floated: 'left' }
      },
      {
        type: ArkFormFieldType.OKButton,
        key: 'submit',
        label: (mode === GroupFormMode.Edit ? 'SAVE' : 'ADD'),
        fieldProps: { loading: isSubmitting || isCloning, floated: 'right' },
        disabled: changes.length === 0,
        disabledTooltip: 'No Changes to Save'
      }
    ],
    fieldProps: { widths: 'equal' }
  })

  const cloneUsersProject: Array<CompanyUser> = [] // array of new users successfully added to the project & group
  const cloneUsersGroup: Array<CompanyUser> = [] // array of existing users successfully added to the group (filtered out new users added to the project & handled in the above array)
  const cloneUserAddedToProjectMessages: Array<React.ReactChild> = []
  const cloneUserAddedToGroupMessages: Array<React.ReactChild> = []
  if (cloneUsersAddedToProject && cloneUsersAddedToProject.size > 0) {
    console.log('ProjectGroupForm - cloneUsersAddedToProject:', cloneUsersAddedToProject)
    for (const [userId, userData] of cloneUsersAddedToProject) {
      console.log('ProjectGroupForm - cloneUsersAddedToProject - userId:', userId, ' userData:', userData)
      const userError = !!cloneUserErrors?.get(userId)
      if (!userError) cloneUsersProject.push(userData.user)
      if (!userError) cloneUserAddedToProjectMessages.push(<>Added {OBJECT_USER_NAME} <strong>{userData.user.name()}</strong> to the {OBJECT_PROJECT_NAME}</>)
    }
  }
  if (cloneUsersAddedToGroup && cloneUsersAddedToGroup.size > 0) {
    console.log('ProjectGroupForm - cloneUsersAddedToGroup:', cloneUsersAddedToGroup)
    for (const [userId, userData] of cloneUsersAddedToGroup) {
      console.log('ProjectGroupForm - cloneUsersAddedToGroup - userId:', userId, ' userData:', userData)
      // skip if already added to the project
      if (cloneUsersAddedToProject?.has(userId)) continue
      cloneUsersGroup.push(userData.user)
      cloneUserAddedToGroupMessages.push(<>Added {OBJECT_USER_NAME} <strong>{userData.user.name()}</strong> to the {OBJECT_GROUP_NAME}</>)
    }
  }
  const cloneUserProjectErrors: Array<CompanyUser> = []
  const cloneUserGroupErrors: Array<CompanyUser> = []
  const cloneUserProjectErrorMessages: Array<React.ReactChild> = []
  const cloneUserGroupErrorMessages: Array<React.ReactChild> = []
  if (cloneUserErrors && cloneUserErrors.size > 0) {
    // console.log('ProjectGroupForm - cloneUserErrors:', cloneUserErrors)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [userId, errorData] of cloneUserErrors) {
      // console.log('ProjectGroupForm - cloneUserErrors - userId:', userId, ' errorData:', errorData)
      if (errorData.cloneStage === 'project') {
        cloneUserProjectErrors.push(errorData.user)
        cloneUserProjectErrorMessages.push(<>Error adding {OBJECT_USER_NAME} <strong>{errorData.user.name()}</strong> to the {OBJECT_PROJECT_NAME}: {errorData.error.message}</>)
      } else if (errorData.cloneStage === 'group') {
        cloneUserGroupErrors.push(errorData.user)
        cloneUserGroupErrorMessages.push(<>Error adding {OBJECT_USER_NAME} <strong>{errorData.user.name()}</strong> to the {OBJECT_GROUP_NAME}: {errorData.error.message}</>)
      }
    }
  }

  const companyGroupUserCount = companyGroupUsers?.length ?? 0
  const cloneUsersAddedToProjectCount = cloneUserAddedToProjectMessages.length // NB: use the pre-processed 'project messages' array as it takes errors into account as well
  const cloneUsersAddedToGroupCount = cloneUserAddedToGroupMessages.length // NB: use the pre-processed 'group messages' array as it takes errors into account as well
  const cloneUsersAddedCount = cloneUsersAddedToProjectCount + cloneUsersAddedToGroupCount
  const cloneUserProjectErrorCount = cloneUserProjectErrorMessages.length
  const cloneUserGroupErrorCount = cloneUserGroupErrorMessages.length
  const cloneUserErrorCount = cloneUserProjectErrorCount + cloneUserGroupErrorCount
  // console.log('ProjectGroupForm - cloneUsersAddedToProjectCount:', cloneUsersAddedToProjectCount, ' cloneUsersAddedToGroupCount:', cloneUsersAddedToGroupCount, ' cloneUsersAddedCount:', cloneUsersAddedCount, ' cloneUserProjectErrorCount:', cloneUserProjectErrorCount, ' cloneUserGroupErrorCount:', cloneUserGroupErrorCount, ' cloneUserErrorCount:', cloneUserErrorCount)

  return (
    <>
      <ArkHeader as="h2" inverted>
        {mode === GroupFormMode.Edit ? 'Edit' : 'Add'} {OBJECT_PROJECT_NAME} {OBJECT_GROUP_NAME}{formValues.cloneCompanyGroupId !== undefined && (<> &gt; Clone {OBJECT_GROUP_NAME}</>)}
      </ArkHeader>

      {hasSaved && (<>
        <ArkMessage positive>
          <ArkMessage.Header>{OBJECT_GROUP_NAME} {mode === GroupFormMode.Edit ? 'Updated' : 'Created'}</ArkMessage.Header>
          <p>The {OBJECT_GROUP_NAME} has been {mode === GroupFormMode.Edit ? 'updated' : 'created'} successfully</p>
        </ArkMessage>
        {mode === GroupFormMode.Add && formValues.cloneCompanyGroupId && (
          <>
            <ArkSpacer />
            {isCloning && (<div><ArkLoader className={styles.cloneLoading} horizontal message={<>Importing {capitalize(OBJECT_USER_NAME_PLURAL)}...</>} /></div>)}
            {!isCloning && (
              <ArkMessage positive={cloneUserErrorCount === 0} warning={cloneUserErrorCount > 0} className={styles.orgGroupUsersImportResult}>
                <ArkMessage.Header>{OBJECT_GROUP_NAME} {OBJECT_USER_NAME} Import</ArkMessage.Header>
                {hasCloned && (
                  <>
                    {capitalize(OBJECT_GROUP_NAME)} clone complete.
                  </>
                )}
                <>
                  {(cloneUsersAddedCount > 0 || cloneUserErrorCount > 0) && (
                    <>
                      <>
                        {cloneUsersAddedToProjectCount > 0
                          ? (
                            <p>
                              <span className={styles.cloneUserCount}>{cloneUsersAddedToProjectCount}</span> new {cloneUsersAddedToProjectCount === 1 ? <>{OBJECT_USER_NAME} was</> : <>{OBJECT_USER_NAME_PLURAL} were</>} added to the {OBJECT_PROJECT_NAME} &amp; {OBJECT_GROUP_NAME} successfully:
                            </p>
                          )
                          : null}
                        {/* {cloneUserAddedToProjectMessages.length > 0 && (
                          <ul>
                            {cloneUserAddedToProjectMessages.map((addedMessage, index) => (
                              <li key={'group_user_project_added_' + index}>{addedMessage}</li>
                            ))}
                          </ul>
                        )} */}
                        {cloneUsersProject.length > 0 && _renderGroupUsersList('clone', cloneUsersProject)}
                      </>
                      <>
                        {cloneUsersAddedToGroupCount > 0
                          ? (
                            <p>
                              <span className={styles.cloneUserCount}>{cloneUsersAddedToGroupCount}</span> existing {OBJECT_PROJECT_NAME} {cloneUsersAddedToGroupCount === 1 ? <>{OBJECT_USER_NAME} was</> : <>{OBJECT_USER_NAME_PLURAL} were</>} added to the {OBJECT_GROUP_NAME} successfully:
                            </p>
                          )
                          : null}
                        {/* {cloneUserAddedToGroupMessages.length > 0 && (
                          <ul>
                            {cloneUserAddedToGroupMessages.map((addedMessage, index) => (
                              <li key={'group_user_group_added_' + index}>{addedMessage}</li>
                            ))}
                          </ul>
                        )} */}
                        {cloneUsersGroup.length > 0 && _renderGroupUsersList('clone', cloneUsersGroup)}
                      </>
                      <>
                        {cloneUserProjectErrorCount > 0
                          ? (
                            <p>
                              <span className={styles.cloneUserWarning}>WARNING:</span> <span className={styles.cloneUserCount}>{cloneUserProjectErrorCount}</span> new {cloneUserProjectErrorCount === 1 ? OBJECT_USER_NAME : OBJECT_USER_NAME_PLURAL} failed to add to the {OBJECT_PROJECT_NAME} &amp; {OBJECT_GROUP_NAME}:
                            </p>
                          )
                          : null}
                        {/* {cloneUserProjectErrorMessages.length > 0 && (
                          <ul>
                            {cloneUserProjectErrorMessages.map((errorMessage, index) => (
                              <li key={'group_user_project_error_' + index}>{errorMessage}</li>
                            ))}
                          </ul>
                        )} */}
                        {cloneUserProjectErrors.length > 0 && _renderGroupUsersList('clone', cloneUserProjectErrors)}
                      </>
                      <>
                        {cloneUserGroupErrorCount > 0
                          ? (
                            <p>
                              <span className={styles.cloneUserWarning}>WARNING:</span> <span className={styles.cloneUserCount}>{cloneUserGroupErrorCount}</span> existing {OBJECT_PROJECT_NAME} {cloneUserGroupErrorCount === 1 ? OBJECT_USER_NAME : OBJECT_USER_NAME_PLURAL} failed to add to the {OBJECT_GROUP_NAME}:
                            </p>
                          )
                          : null}
                        {/* {cloneUserGroupErrorMessages.length > 0 && (
                          <ul>
                            {cloneUserGroupErrorMessages.map((errorMessage, index) => (
                              <li key={'group_user_group_error_' + index}>{errorMessage}</li>
                            ))}
                          </ul>
                        )} */}
                        {cloneUserGroupErrors.length > 0 && _renderGroupUsersList('clone', cloneUserGroupErrors)}
                      </>
                    </>
                  )}
                  {(cloneUsersAddedCount === 0 && cloneUserErrorCount === 0) && (
                    <>
                      {companyGroupUserCount === 0 && (<p>The source {OBJECT_GROUP_NAME} has 0 {OBJECT_USER_NAME_PLURAL} so this {OBJECT_GROUP_NAME} is empty.</p>)}
                      {companyGroupUserCount > 0 && (<p>No {OBJECT_USER_NAME_PLURAL} were added to the {OBJECT_GROUP_NAME}.</p>)}
                    </>
                  )}
                  {/* TODO: project count as well ?? */}
                </>
              </ArkMessage>
            )}
          </>
        )}
        <ArkButton type="button" color="blue" fluid basic size="large" disabled={isCloning} onClick={onClose} style={{ marginTop: 15 }}>
          OK
        </ArkButton>
      </>)}

      {!hasSaved && (
        <>
          <p>Only admins and managers will see these {OBJECT_GROUP_NAME} details, they are not visible to standard viewing members.</p>
          <ArkForm
            formKey="group"
            inverted
            formError={error ?? companyGroupsError ?? companyGroupUsersError ?? projectUsersError}
            formFields={formFields}
            formSchema={formSchema}
            onFormSubmit={onFormSubmit}
            onValueChanged={onValueChanged}
            updateFieldValuesOnExternalChange={true}
            showLabels={true}
            insideModal={insideModal}
          >
          </ArkForm>
        </>
      )}
    </>
  )
}

export default ProjectGroupForm
