/**
 * ArkPagination
 */

import React from 'react'

import { Pagination, PaginationProps } from 'semantic-ui-react'

import styles from './ArkPagination.module.css'

export interface ArkPaginationProps extends PaginationProps {}

const ArkPagination = (props: ArkPaginationProps) =>
  <Pagination {...props} className={`${styles.pagination} ${props.className || ''}`} />
export default ArkPagination
