import React from 'react'

import { companyMenuItems, supportMenuItems } from 'src/constants/menus'
import { withUserContext, IUserMultiContext } from 'src/core/providers'

import ArkPage from 'src/core/components/ArkPage/ArkPage'
import ArkSectionSidebarMenu from 'src/core/components/ArkSectionSidebarMenu/ArkSectionSidebarMenu'

import _ from 'lodash'

interface IProps extends IUserMultiContext {
  children?: React.ReactNode
  args?: any // PrivateRoute optional args (object with any number of key:value entries specified at the router declaration level)
  rightSidebar?: React.ReactNode
  hideSidebars?: boolean
  disableSectionMainMenu?: boolean
  onRightSidebarClose: () => void
}
interface IState {}

class ArkCompanyManagerPage extends React.Component<IProps, IState> {
  render () {
    const { children, onRightSidebarClose } = this.props
    return (
      <ArkPage
        leftSidebar={this.sidebarLeftContent()}
        onRightSidebarClose={onRightSidebarClose}
        rightSidebar={this.sidebarRightContent()}
        section="company"
        showSelectCompany
      >
        {children}
      </ArkPage>
    )
  }

  sidebarLeftContent = () => {
    if (this.props.hideSidebars) return null
    const mainMenuItems = companyMenuItems()

    // disable the main menu items if the parent says so, or if the parent user data selections are loading/updating
    const loadingParentData = this.props.userContext.store.loadingCompanyData
    if (this.props.disableSectionMainMenu || loadingParentData) {
      _.forEach(mainMenuItems, (item) => { item.disable = true })
    }

    const bottomMenuItems = supportMenuItems()
    return (
      <ArkSectionSidebarMenu
        mainMenuItems={mainMenuItems}
        bottomMenuItems={bottomMenuItems}
      />
    )
  }

  sidebarRightContent = () => {
    if (this.props.hideSidebars) return null
    if (!this.props.rightSidebar) return null
    return (<>
      {this.props.rightSidebar}
    </>)
  }

  footerContent = () => {
    return (<></>)
  }
}
export default withUserContext(ArkCompanyManagerPage)
