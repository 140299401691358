import { OBJECT_PROGRAM_NAME_PLURAL } from 'src/constants/strings'

import { OnlineStatus } from '../models'

export const getOnlineStatusColor = (onlineStatus: OnlineStatus): string => {
  switch (onlineStatus) {
    case 'online':
      return 'var(--green)'
    case 'partial':
      return 'var(--yellow)'
    case 'offline':
      return 'var(--red)'
    case 'unknown':
    default:
      return 'var(--grey)'
  }
}

export const getChannelOnlineStatusDescription = (onlineStatus: OnlineStatus): string => {
  switch (onlineStatus) {
    case 'online':
      return `All ${OBJECT_PROGRAM_NAME_PLURAL} online`
    case 'partial':
      return `Some ${OBJECT_PROGRAM_NAME_PLURAL} offline`
    case 'offline':
      return `All ${OBJECT_PROGRAM_NAME_PLURAL} offline`
    case 'unknown':
    default:
      return 'Checking…'
  }
}

export const getOnlineStatusText = (onlineStatus: OnlineStatus): string => {
  switch (onlineStatus) {
    case 'online':
      return 'Online'
    case 'partial':
      return 'Partial'
    case 'offline':
      return 'Offline'
    case 'unknown':
    default:
      return 'Checking…'
  }
}

export const guardOnlineStatus = (onlineStatus: OnlineStatus): OnlineStatus => {
  switch (onlineStatus) {
    case 'online':
      return 'online'
    case 'partial':
      return 'partial'
    case 'offline':
      return 'offline'
    default:
      return 'unknown'
  }
}
