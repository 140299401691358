/**
 * VideoPlayer360
 */

import React, { useEffect, useRef } from 'react'
import videojs, { VideoJsPlayer } from 'video.js'
import 'video.js/dist/video-js.css'

import styles from './VideoPlayer360.module.css'

require('videojs-vr')

interface VideoJsVrPlayer extends VideoJsPlayer {
  vr: ({ projection }: { projection: string }) => void
}

export interface VideoPlayer360Props {
  active: boolean;
  imageData?: ImageData
}

const VideoPlayer360 = (props: VideoPlayer360Props) => {
  const { active, imageData } = props

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const playerRef = useRef<VideoJsVrPlayer>()
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (!canvasRef.current || !imageData) return
    const context = canvasRef.current.getContext('2d', { alpha: false })
    if (!context) return
    context.putImageData(imageData, 0, 0)
  }, [imageData])

  useEffect(() => {
    if (!videoRef.current) return
    playerRef.current = videojs(videoRef.current, {
      autoplay: true,
      controls: false,
      fluid: true,
      muted: true
    }) as VideoJsVrPlayer
    playerRef.current.vr({ projection: '360' })
  }, [videoRef])

  useEffect(() => {
    if (!canvasRef.current || !playerRef.current) return
    const element = playerRef.current.children()[0] as unknown as HTMLVideoElement
    const stream = canvasRef.current.captureStream()
    element.srcObject = stream
  }, [canvasRef, playerRef])

  return (
    <div className={`${styles.container} ${active ? styles.active : ''}`}>
      <canvas
        className={styles.canvas}
        height={imageData?.height}
        ref={canvasRef}
        width={imageData?.width}
      />
      <video className={styles.video} ref={videoRef} />
    </div>
  )
}

export default VideoPlayer360
