import React from 'react'

import ArkAdminPage from '../../components/ArkAdminPage/ArkAdminPage'

import ArkAccessDeniedView from '../../../core/components/ArkAccessDeniedView/ArkAccessDeniedView'

interface IProps {}
interface IState {}

class AdminAccessDeniedPage extends React.Component<IProps, IState> {
  render () {
    return (<ArkAdminPage hideSidebars>
      <ArkAccessDeniedView />
    </ArkAdminPage>)
  }
}
export default AdminAccessDeniedPage
