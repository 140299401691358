import ServerAPIClient from './ServerAPIClient'
import { ProjectGroup, CompanyGroup, Channel, GroupChannelOperation, GroupUserOperation, Program, GroupProgramOperation, ProjectUser, UserProjectRole, CompanyUser, UserCompanyRole, Project } from '../models'
import { ICompanyGroupAddData, ICompanyGroupUpdateData, IProjectGroupAddData, IProjectGroupUpdateData } from '../models/group'

class ServerGroupAPI {
  private _apiClient: ServerAPIClient

  constructor (apiClient: ServerAPIClient) {
    this._apiClient = apiClient
  }

  // -------

  getCompanyGroups = async (companyId: number): Promise<Array<CompanyGroup> | null> => {
    try {
      const response = await this._apiClient.apiGet('/group?flag_org_groups=true', { 'company-id': companyId })
      const allGroups: Array<CompanyGroup> = []
      if (response.data && response.data.result && response.data.result) {
        const groupsData = response.data.result
        for (const groupData of groupsData) {
          const group = CompanyGroup.fromJSON(groupData.id, groupData)
          if (group) {
            allGroups.push(group)
          }
        }
      }
      // sort/order the groups array by name
      // NB: now also sorts the default group to the top/first
      allGroups.sort((a: CompanyGroup, b: CompanyGroup) => {
        if (a.isDefaultGroup) return -1
        if (b.isDefaultGroup) return 1
        // return a.name.localeCompare(b.name)
        // sort/order the groups array by name using a more intelligent natural sort
        return a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true })
      })
      // NB: org/company groups do NOT support sub/parent groups, so we don't need to build a parent/sub tree structure like we do with project groups
      return allGroups
    } catch (error) {
      console.error('ServerGroupAPI - getCompanyGroups - error: ', error)
      throw error
    }
  }

  getCompanyGroup = async (_companyId: number, _groupId: number): Promise<CompanyGroup | null> => {
    throw new Error('Not implemented') // TODO: <<<<
  }

  addCompanyGroup = async (companyId: number, groupData: ICompanyGroupAddData): Promise<CompanyGroup> => {
    const data: {[key: string]: any} = {}
    data.flag_org_group = true // indicates its an org group
    data.name = groupData.name
    if (groupData.desc !== undefined) data.description = groupData.desc
    if (groupData.isVisibleToAllProjects !== undefined) data.always_allow_org_group_visibility = groupData.isVisibleToAllProjects
    // data.allow_permissions = false // TODO: is this supported with org groups? <<<
    // data.flag_parent_group = 0 // TODO: TEMP ADDED: until the api can fix a minor bug that seems to require this field to be set, remove this entry once the api fix is applied
    console.log('ServerGroupAPI - addCompanyGroup - companyId:', companyId, ' groupData:', groupData, ' data:', data)
    try {
      const response = await this._apiClient.apiPost('/group', data, { 'company-id': companyId })
      if (response.status === 201 && response.data && response.data.result) {
        const groupData = response.data.result
        const group = CompanyGroup.fromJSON(groupData.id, groupData)
        if (group) return group
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerGroupAPI - addCompanyGroup - error: ', error)
      throw error
    }
  }

  updateCompanyGroup = async (companyId: number, groupId: number, groupData: ICompanyGroupUpdateData): Promise<CompanyGroup> => {
    const data: {[key: string]: any} = {}
    if (groupData.name !== undefined) data.name = groupData.name
    if (groupData.desc !== undefined) data.description = groupData.desc.length > 0 ? groupData.desc : null
    if (groupData.isVisibleToAllProjects !== undefined) data.always_allow_org_group_visibility = groupData.isVisibleToAllProjects
    console.log('ServerGroupAPI - updateCompanyGroup - companyId:', companyId, ' groupId:', groupId, ' groupData:', groupData, ' data:', data)
    try {
      const response = await this._apiClient.apiPut('/group/' + groupId, data, { 'company-id': companyId })
      if (response.status === 200 && response.data && response.data.result) {
        const groupData = response.data.result
        const group = CompanyGroup.fromJSON(groupData.id, groupData)
        if (group) return group
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerGroupAPI - updateCompanyGroup - error: ', error)
      throw error
    }
  }

  deleteCompanyGroup = async (companyId: number, groupId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiDelete('/group/' + groupId, undefined, { 'company-id': companyId })
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerGroupAPI - deleteCompanyGroup - error: ', error)
      throw error
    }
  }

  // -------

  updateCompanyGroupAccessEnabled = async (companyId: number, groupId: number, accessEnabled: boolean): Promise<CompanyGroup> => {
    const data: {[key: string]: any} = {
      enabled: accessEnabled
    }
    try {
      const response = await this._apiClient.apiPut('/group/' + groupId, data, { 'company-id': companyId })
      if (response.data && response.data.result) {
        const groupData = response.data.result
        const group = CompanyGroup.fromJSON(groupData.id, groupData)
        if (group) return group
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerGroupAPI - updateCompanyGroupAccessEnabled - error: ', error)
      throw error
    }
  }

  // -------

  // returns a list of all users assigned to the org group
  // - accessible by whoever can see the org group
  // - org admins can see all org group users
  // - if the org group has visibility enabled for a project, then the project managers & admins can call this to see the org groups users
  //   (this can be used to preview the org group users that will be mirrored or cloned into a project group when creating one from an org group)
  // NB: throws an error if you pass in a project (non-org/company) group id (see `getProjectGroupUsers` for project group specific users)
  getCompanyGroupUsers = async (companyId: number, groupId: number): Promise<Array<CompanyUser> | null> => {
    try {
      // NB: this endpoint can now return both project or org/company group users so we check for the `flag_org_group`...
      // NB: ...& consider it an error if it returns a project groups users when we're expecting org/company group users
      const response = await this._apiClient.apiGet('/group/' + groupId + '/users', { 'company-id': companyId })
      if (response.data && response.data.result && response.data.result && response.data.result.users && response.data.result.flag_org_group !== undefined) {
        if (!response.data.result.flag_org_group) { // throw an error if we're expecting org/company group users but got project group users (wrong group type)
          throw new Error('Invalid group type')
        }
        const users: Array<CompanyUser> = []
        const groupUsersData = response.data.result.users
        for (const userData of groupUsersData) {
          const user = CompanyUser.fromJSON(
            userData.user.id,
            {
              ...userData.user,
              ...{ company_role: userData.company_role },
              ...{ company_status: userData.company_status }
            }
          )
          if (user) {
            users.push(user)
          }
        }
        // TODO:
        // TODO: do we want to sort by role for group users? (we do currently with project users, although those also sort other higher roles as well...)
        // TODO: compare to the project group users sort - should additional higher roles be added to the company group users sort? <<<<
        // TODO:
        // sort/order the users array by company role & then user name (NB: the User name() returns the full name if set, or email as a fallback)
        users.sort((a: CompanyUser, b: CompanyUser) => {
          const aRole = a.companyRole && a.companyRole > UserCompanyRole.unknown ? a.companyRole : UserCompanyRole.member
          const bRole = b.companyRole && b.companyRole > UserCompanyRole.unknown ? b.companyRole : UserCompanyRole.member
          // return aRole - bRole || a.name().localeCompare(b.name())
          return aRole - bRole || a.name().localeCompare(b.name(), navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true })
        })
        return users
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerGroupAPI - getCompanyGroupUsers - error: ', error)
      throw error
    }
  }

  // adds & deletes group user mappings
  // returns true if all operations (add/del) went ok, or a map/object of user id's with their error responses if any did (but others may have added ok)
  // NB: API v0.3.29 changed this endpoint to make it group specific (adding the group id into the endpoint path, & removing it from the operations array entries), we never used the previous multi-group support, so it was a minor change to our existing code
  updateCompanyGroupUsers = async (companyId: number, groupId: number, operations: Array<GroupUserOperation>): Promise<boolean | Map<number, any>> => {
    const data: {[key: string]: any} = {
      users_group: operations
    }
    try {
      const response = await this._apiClient.apiPost('/group/' + groupId + '/users', data, { 'company-id': companyId })
      if (response.data && response.data.result && response.data.result) {
        // NB: the results of this endpoint only include entries for any errors, if its not in the response it was actioned ok
        if (response.data.result.usersKo && response.data.result.usersKo.length > 0) {
          const usersGroupsKo = response.data.result.usersKo
          const operationErrors = new Map<number, any>()
          for (const userGroupsKo of usersGroupsKo) {
            operationErrors.set(parseInt(userGroupsKo.user_id), userGroupsKo)
          }
          return operationErrors
        }
      }
      return true
    } catch (error) {
      console.error('ServerGroupAPI - updateCompanyGroupUsers - error: ', error)
      throw error
    }
  }

  // -------

  // returns an array of projects (or just their ids?) the specified org/company group has visibility enabled for (so those projects can use the org groups to mirror from)
  getCompanyGroupProjectVisibility = async (companyId: number, groupId: number): Promise<Array<Project>> => {
    try {
      const response = await this._apiClient.apiGet('/group/org/visibility/' + groupId, { 'company-id': companyId })
      console.log('ServerGroupAPI - getCompanyGroupProjectVisibility - response: ', response)
      const projects: Array<Project> = []
      if (response.data && response.data.result) {
        const projectsData = response.data.result
        for (const projectData of projectsData) {
          const project = Project.fromJSON(projectData.id, projectData)
          if (project) {
            projects.push(project)
          }
        }
      }
      // sort/order the projects array by project name
      // projects.sort((a: Project, b: Project) => a.name.localeCompare(b.name))
      // sort/order the projects array by project name using a more intelligent natural sort
      projects.sort((a: Project, b: Project) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
      return projects
    } catch (error) {
      console.error('ServerGroupAPI - getCompanyGroupProjectVisibility - error: ', error)
      throw error
    }
  }

  updateCompanyGroupProjectVisibility = async (companyId: number, groupId: number, projectId: number, visible: boolean): Promise<boolean> => {
    console.log('ServerGroupAPI - updateCompanyGroupProjectVisibility - companyId:', companyId, ' groupId:', groupId, ' projectId:', projectId, ' visible:', visible)
    if (visible) {
      const data: {[key: string]: any} = { org_group_id: groupId }
      try {
        const response = await this._apiClient.apiPost('/group/org/visibility', data, { 'company-id': companyId, 'project-id': projectId })
        console.log('ServerGroupAPI - updateCompanyGroupProjectVisibility - enable - response:', response)
        if (response.status === 201 || response.status === 200) {
          return true
        }
        throw new Error('Invalid response')
      } catch (error) {
        console.error('ServerGroupAPI - updateCompanyGroupAccessEnabled - enable - error: ', error)
        throw error
      }
    } else {
      try {
        const response = await this._apiClient.apiDelete('/group/org/visibility/' + groupId, undefined, { 'company-id': companyId, 'project-id': projectId })
        console.log('ServerGroupAPI - updateCompanyGroupProjectVisibility - disable - response:', response)
        if (response.status === 200) {
          return true
        }
        throw new Error('Invalid response')
      } catch (error) {
        console.error('ServerGroupAPI - updateCompanyGroupAccessEnabled - disable - error: ', error)
        throw error
      }
    }
  }

  // -------

  // returns project groups only the user has access too
  getProjectGroups = async (companyId: number, projectId: number): Promise<Array<ProjectGroup>> => {
    try {
      const response = await this._apiClient.apiGet('/group', { 'company-id': companyId, 'project-id': projectId })
      // parse all groups from the response into a flat array (ignores sub group mapping at this point)
      const allGroups: Array<ProjectGroup> = []
      if (response.data && response.data.result && response.data.result) {
        const groupsData = response.data.result
        for (const groupData of groupsData) {
          const group = ProjectGroup.fromJSON(groupData.id, groupData)
          if (group) {
            allGroups.push(group)
          }
        }
      }
      // sort/order the groups array by name (before we then build the parent/sub tree structure)
      // NB: now also sorts the default group to the top/first
      allGroups.sort((a: ProjectGroup, b: ProjectGroup) => {
        if (a.isDefaultGroup) return -1
        if (b.isDefaultGroup) return 1
        // return a.name.localeCompare(b.name)
        // sort/order the groups array by name using a more intelligent natural sort
        return a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true })
      })
      // convert the list of all groups into a tiered tree with each group having a list of their sub groups within them (if any)
      // first add groups to their relevant parent's subGroups array
      for (const group of allGroups) {
        if (group.parentGroupId !== undefined && group.parentGroupId !== null) {
          let parentGroup: ProjectGroup | null = null
          for (const group2 of allGroups) {
            if (group2.id === group.parentGroupId) {
              parentGroup = group2
              break
            }
          }
          if (parentGroup) {
            if (!parentGroup.subGroups) {
              parentGroup.subGroups = []
            }
            parentGroup.subGroups.push(group)
          }
        }
      }
      // create a return array of just the top level groups (no parent set)
      const tieredGroups: Array<ProjectGroup> = []
      for (const group of allGroups) {
        if (group.parentGroupId === undefined || group.parentGroupId === null) {
          tieredGroups.push(group)
        }
      }
      return tieredGroups
    } catch (error) {
      console.error('ServerGroupAPI - getProjectGroups - error: ', error)
      throw error
    }
  }

  getProjectGroup = async (companyId: number, projectId: number, groupId: number): Promise<ProjectGroup> => {
    try {
      const response = await this._apiClient.apiGet('/group/' + groupId, { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result) {
        const groupData = response.data.result
        const group = ProjectGroup.fromJSON(groupData.id, groupData)
        if (group) return group
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerGroupAPI - getProjectGroup - error: ', error)
      throw error
    }
  }

  addProjectGroup = async (companyId: number, projectId: number, groupData: IProjectGroupAddData): Promise<ProjectGroup> => { // groupType: GroupType = GroupType.project // name: string, desc?: string, mirrorGroupId?: number, parentGroupId?: number, isParentGroup?: boolean
    try {
      const data: {[key: string]: any} = {}
      data.name = groupData.name
      data.allow_permissions = false // TODO: <<<<
      if (groupData.mirrorGroupId) data.mirrored_group_id = groupData.mirrorGroupId
      if (groupData.parentGroupId) data.parent_group_id = groupData.parentGroupId
      if (groupData.isParentGroup) data.flag_parent_group = true
      if (groupData.desc) data.description = groupData.desc
      const response = await this._apiClient.apiPost('/group', data, { 'company-id': companyId, 'project-id': projectId })
      if (response.status === 201 && response.data && response.data.result) {
        const groupData = response.data.result
        const group = ProjectGroup.fromJSON(groupData.id, groupData)
        if (group) return group
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerGroupAPI - addProjectGroup - error: ', error)
      throw error
    }
  }

  updateProjectGroup = async (companyId: number, projectId: number, groupId: number, groupData: IProjectGroupUpdateData): Promise<boolean> => {
    // NB: you cannot change the groupType (flag_parent_group field) once a group has been created
    const data: {[key: string]: any} = {}
    if (groupData.name !== undefined) data.name = groupData.name
    if (groupData.desc !== undefined) data.description = groupData.desc.length > 0 ? groupData.desc : null
    if (groupData.parentGroupId) data.parent_group_id = groupData.parentGroupId
    try {
      const response = await this._apiClient.apiPut('/group/' + groupId, data, { 'company-id': companyId, 'project-id': projectId })
      if (response.status === 200 && response.data && response.data.result) {
        return true
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerGroupAPI - updateProjectGroup - error: ', error)
      throw error
    }
  }

  deleteProjectGroup = async (companyId: number, projectId: number, groupId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiDelete('/group/' + groupId, undefined, { 'company-id': companyId, 'project-id': projectId })
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerGroupAPI - deleteProjectGroup - error: ', error)
      throw error
    }
  }

  // -------

  updateProjectGroupAccessEnabled = async (companyId: number, projectId: number, groupId: number, accessEnabled: boolean): Promise<ProjectGroup> => {
    const data: {[key: string]: any} = {
      enabled: accessEnabled
    }
    try {
      const response = await this._apiClient.apiPut('/group/' + groupId, data, { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result) {
        const groupData = response.data.result
        const group = ProjectGroup.fromJSON(groupData.id, groupData)
        if (group) return group
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerGroupAPI - updateProjectGroupAccessEnabled - error: ', error)
      throw error
    }
  }

  // -------

  getProjectGroupUsers = async (companyId: number, projectId: number, groupId: number): Promise<Array<ProjectUser> | null> => {
    try {
      // NB: this endpoint can now return both project or org/company group users so we check for the `flag_org_group`...
      // NB: ...& consider it an error if it returns an org groups users when we're expecting project group users
      const response = await this._apiClient.apiGet('/group/' + groupId + '/users', { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result.users && response.data.result.flag_org_group !== undefined) {
        if (response.data.result.flag_org_group) { // throw an error if we're expecting project group users but got org group users (wrong group type)
          throw new Error('Invalid group type')
        }
        const users: Array<ProjectUser> = []
        const groupUsersData = response.data.result.users
        for (const userData of groupUsersData) {
          const user = ProjectUser.fromJSON(
            userData.user.id,
            {
              ...userData.user,
              ...{ company_role: userData.company_role },
              ...{ company_status: userData.company_status },
              ...{ project_id: projectId, project_owner: userData.owner, project_role: userData.project_role /*, project_access_enabled: userData.enabled ?? false */ },
              ...{ flag_direct_user: userData.flag_direct_user }
            }
          )
          if (user) {
            users.push(user)
          }
        }
        // sort/order the users array by project role & then user name (NB: the User name() returns the full name if set, or email as a fallback)
        users.sort((a: ProjectUser, b: ProjectUser) => {
          const aProjectRole = a.projectRole && a.projectRole > UserProjectRole.unknown ? a.projectRole : UserProjectRole.member
          const bProjectRole = b.projectRole && b.projectRole > UserProjectRole.unknown ? b.projectRole : UserProjectRole.member
          // return aRole - bRole || a.name().localeCompare(b.name())
          // sort/order the users array by user name using a more intelligent natural sort
          // return aProjectRole - bProjectRole || a.name().localeCompare(b.name(), navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true })
          // TESTING: now also taking all user roles into account: site admin, org admin, project admin, project manager, & guest roles/types
          // nudge the project role up by +10, then set org admin as 5, & site-admin/god as 1, & guest as 20 to push it to the end
          const aRoleAll = a.isSiteAdmin() ? 1 : (a.isCompanyAdmin() ? 5 : (!a.isGuest ? (aProjectRole + 10) : 20))
          const bRoleAll = b.isSiteAdmin() ? 1 : (b.isCompanyAdmin() ? 5 : (!b.isGuest ? (bProjectRole + 10) : 20))
          return aRoleAll - bRoleAll || a.name().localeCompare(b.name(), navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true })
        })
        return users
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerGroupAPI - getProjectGroupUsers - error: ', error)
      throw error
    }
  }

  // adds & deletes group user mappings
  // returns true if all operations (add/del) went ok, or a map/object of user id's with their error responses if any did (but others may have added ok)
  // NB: API v0.3.29 changed this endpoint to make it group specific (adding the group id into the endpoint path, & removing it from the operations array entries), we never used the previous multi-group support, so it was a minor change to our existing code
  updateProjectGroupUsers = async (companyId: number, projectId: number, groupId: number, operations: Array<GroupUserOperation>): Promise<boolean | Map<number, any>> => {
    const data: {[key: string]: any} = {
      users_group: operations
    }
    try {
      const response = await this._apiClient.apiPost('/group/' + groupId + '/users', data, { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result) {
        // NB: the results of this endpoint only include entries for any errors, if its not in the response it was actioned ok
        if (response.data.result.usersKo && response.data.result.usersKo.length > 0) {
          const usersGroupsKo = response.data.result.usersKo
          const operationErrors = new Map<number, any>()
          for (const userGroupsKo of usersGroupsKo) {
            operationErrors.set(parseInt(userGroupsKo.user_id), userGroupsKo)
          }
          return operationErrors
        }
      }
      return true
    } catch (error) {
      console.error('ServerGroupAPI - updateProjectGroupUsers - error: ', error)
      throw error
    }
  }

  // -------

  getProjectGroupChannels = async (companyId: number, projectId: number, groupId: number): Promise<Array<Channel> | null> => {
    try {
      const response = await this._apiClient.apiGet('/group/' + groupId + '/channels', { 'company-id': companyId, 'project-id': projectId })
      const channels: Array<Channel> = []
      if (response.data && response.data.result && response.data.result) {
        const groupChannelsData = response.data.result
        for (const channelData of groupChannelsData) {
          const channel = Channel.fromJSON(channelData.id, channelData)
          if (channel) {
            channels.push(channel)
          }
        }
      }
      return channels
    } catch (error) {
      console.error('ServerGroupAPI - getProjectGroupChannels - error: ', error)
      throw error
    }
  }

  // adds & deletes group channel mappings
  // returns true if all operations (add/del) went ok, or a map/object of channel id's with their error responses if any did (but others may have added ok)
  // NB: although the endpoint supports mapping channels across multiple groups, we currently only support working with a single group at a time to keep the logic simpler
  updateProjectGroupChannels = async (companyId: number, projectId: number, groupId: number, operations: Array<GroupChannelOperation>): Promise<boolean | Map<number, any>> => {
    const data: {[key: string]: any} = {
      channels_groups: operations
    }
    try {
      const response = await this._apiClient.apiPost('/group/channels', data, { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result) {
        // NB: the results of this endpoint only include entries for any errors, if its not in the response it was actioned ok
        if (response.data.result.channelsGroupsKo && response.data.result.channelsGroupsKo.length > 0) {
          const channelsGroupsKo = response.data.result.channelsGroupsKo
          const operationErrors = new Map<number, any>()
          for (const channelGroupsKo of channelsGroupsKo) {
            operationErrors.set(parseInt(channelGroupsKo.channel_id), channelGroupsKo)
          }
          return operationErrors
        }
      }
      return true
    } catch (error) {
      console.error('ServerGroupAPI - updateProjectGroupChannels - error: ', error)
      throw error
    }
  }

  // -------

  getProjectGroupPrograms = async (companyId: number, projectId: number, groupId: number): Promise<Array<Program> | null> => {
    try {
      const response = await this._apiClient.apiGet('/group/' + groupId + '/programs', { 'company-id': companyId, 'project-id': projectId })
      const programs: Array<Program> = []
      if (response.data && response.data.result && response.data.result) {
        const groupProgramsData = response.data.result
        for (const programData of groupProgramsData) {
          const program = Program.fromJSON(programData.id, programData)
          if (program) {
            programs.push(program)
          }
        }
      }
      return programs
    } catch (error) {
      console.error('ServerGroupAPI - getProjectGroupPrograms - error: ', error)
      throw error
    }
  }

  // adds & deletes group program mappings
  // returns true if all operations (add/del) went ok, or a map/object of channel id's with their error responses if any did (but others may have added ok)
  // NB: although the endpoint supports mapping channels across multiple groups, we currently only support working with a single group at a time to keep the logic simpler
  updateProjectGroupPrograms = async (companyId: number, projectId: number, groupId: number, operations: Array<GroupProgramOperation>): Promise<boolean | Map<number, any>> => {
    const data: {[key: string]: any} = {
      programs_groups: operations
    }
    try {
      const response = await this._apiClient.apiPost('/group/programs', data, { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result) {
        // NB: the results of this endpoint only include entries for any errors, if its not in the response it was actioned ok
        if (response.data.result.programsGroupsKo && response.data.result.programsGroupsKo.length > 0) {
          const programsGroupsKo = response.data.result.programsGroupsKo
          const operationErrors = new Map<number, any>()
          for (const programGroupsKo of programsGroupsKo) {
            operationErrors.set(parseInt(programGroupsKo.program_id), programGroupsKo)
          }
          return operationErrors
        }
      }
      return true
    } catch (error) {
      console.error('ServerGroupAPI - updateProjectGroupPrograms - error: ', error)
      throw error
    }
  }

  // -------

  getVisibleCompanyGroupsForProject = async (companyId: number, projectId: number): Promise<Array<CompanyGroup>> => {
    try {
      const response = await this._apiClient.apiGet('/group/org/visibility', { 'company-id': companyId, 'project-id': projectId })
      console.log('ServerGroupAPI - getVisibleCompanyGroupsForProject - response: ', response)
      const groups: Array<CompanyGroup> = []
      if (response.data && response.data.result && response.data.result) {
        const groupsData = response.data.result
        for (const groupData of groupsData) {
          const group = CompanyGroup.fromJSON(groupData.id, groupData)
          if (group) {
            groups.push(group)
          }
        }
      }
      // sort/order the groups array by name
      // NB: now also sorts the default group to the top/first
      groups.sort((a: CompanyGroup, b: CompanyGroup) => {
        if (a.isDefaultGroup) return -1
        if (b.isDefaultGroup) return 1
        // return a.name.localeCompare(b.name)
        // sort/order the groups array by name using a more intelligent natural sort
        return a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true })
      })
      // NB: org/company groups do NOT support sub/parent groups, so we don't need to build a parent/sub tree structure like we do with project groups
      return groups
    } catch (error) {
      console.error('ServerGroupAPI - getVisibleCompanyGroupsForProject - error: ', error)
      throw error
    }
  }

  // -------
}

export default ServerGroupAPI
