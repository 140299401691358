import { BaseModel } from './base_model'

export class ActionPermissions extends BaseModel {
  allowScreenshots: boolean
  allowShareLinks: boolean

  constructor (
    allowScreenshots: boolean,
    allowShareLinks: boolean
  ) {
    super()
    this.allowScreenshots = allowScreenshots
    this.allowShareLinks = allowShareLinks
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : ActionPermissions | null {
    if (!json) return null
    return new ActionPermissions(
      json.flag_screenshots,
      json.flag_share_link
    )
  }
}
