import React, { Component } from 'react'

import { StreamhubStreamsContext, IStreamhubStreamsContext, StreamhubStreamAction, StreamhubStreamStatus, StreamhubStreamsStatus } from 'src/admin/pages/streamhub/providers/StreamhubStreamsProvider'
import { StreamhubSourcesContext, IStreamhubSourcesContext } from 'src/admin/pages/streamhub/providers/StreamhubSourcesProvider'

import { IResponsiveMultiContext, withResponsiveContext } from 'src/core/providers/ResponsiveProvider'

import InputSource from 'src/admin/pages/streamhub/models/InputSource'
import OutputStream, { IOutputStreamStatus } from 'src/admin/pages/streamhub/models/OutputStream'
import { STREAMHUB_TABLE_TYPE } from 'src/admin/pages/streamhub/config/config'

import StreamhubStreamsTable, { StreamhubStreamsActionType } from './StreamhubStreamsTable'
import StreamhubStreamForm, { ArkTestStreamFormMode } from './StreamhubStreamForm'
import StreamhubStreamFilterForm, { StreamhubStreamFilterFormStatus, StreamhubStreamFilterValues } from './StreamhubStreamFilterForm'

import ArkButton from 'src/core/components/ArkButton'
import ArkConfirmModal from 'src/core/components/ArkConfirmModal'
import ArkLoaderView from 'src/core/components/ArkLoader'
import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'

import { formatDate, formatDateTime } from 'src/core/utilities/date'

import { Table, Modal, Image, Header, Message } from 'semantic-ui-react'

import styles from '../Streamhub.module.css'

interface IProps extends IResponsiveMultiContext {
  onDataChanged?: Function
}
interface IState {
  loadingData: boolean
  editStream?: OutputStream // TODO: move this up to the streams provider? (use the current store.stream var(s) or add something dedicated? or just leave it here?)
  showFormModal: boolean
  formSaved: boolean
  formError?: Error
  showStartSelectedStreamsModal: boolean
  showStopSelectedStreamsModal: boolean
  showRestartSelectedStreamsModal: boolean
  filterValues: StreamhubStreamFilterValues
  filterUrlArgsLoaded: boolean
  selectedStreamIds: Array<number>
}

class StreamhubStreamsView extends Component<IProps & IStreamhubStreamsContext & { sourcesContext: IStreamhubSourcesContext }, IState> {
  private _isMounted: boolean = false

  private _defaultFilterValues: StreamhubStreamFilterValues = { status: 0 }

  constructor (props: IProps & IStreamhubStreamsContext & { sourcesContext: IStreamhubSourcesContext }) {
    super(props)
    this.state = {
      loadingData: false,
      showFormModal: false,
      formSaved: false,
      showStartSelectedStreamsModal: false,
      showStopSelectedStreamsModal: false,
      showRestartSelectedStreamsModal: false,
      filterValues: { ...this._defaultFilterValues },
      filterUrlArgsLoaded: false,
      selectedStreamIds: []
    }
  }

  componentDidMount () {
    console.log('StreamhubStreamsView - componentDidMount - window.location.hash(BEFORE):', window.location.hash)
    this._isMounted = true
    this.loadPage()
    console.log('StreamhubStreamsView - componentDidMount - window.location.hash(AFTER):', window.location.hash)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  // -------

  loadPage = async () => {
    await this.loadData()
    this.loadFilterUrlArgs()
  }

  // -------

  render () {
    return (<>
      {this.renderStreams()}
    </>)
  }

  // -------

  renderStreams = () => {
    const loading = this.props.store.streamsStatus === StreamhubStreamsStatus.loading
    const isFilterActive = this.isFilterActive()
    const allStreams = this.props.store.streams
    const streams = !loading && allStreams && isFilterActive ? this.filterStreams(allStreams) : allStreams
    const isMobile = this.props.responsiveContext.store.isMobile
    return (
      <div className={`${styles.streams}${isMobile ? ' ' + styles.mobile : ''}`}>
        <div className={styles.contentHeader}>
          <div className={styles.contentHeaderMain}>
            <Header as='h2' inverted>Streams</Header>
            <div className={styles.listSummary}>
              {this.renderStreamsSummary(streams, allStreams, isFilterActive)}
            </div>
            <div className={styles.actions}>
              {this.renderStreamActions(streams, allStreams, isFilterActive)}
            </div>
          </div>
          <div className={styles.contentHeaderFilter}>
            {this.renderStreamsFilterForm()}
          </div>
        </div>

        {this.renderAllStreamsTable(streams)}

        <div style={{ marginBottom: 20 }}></div>

        {this.renderStreamFormModal()}
        {this.renderConfirmStartSelectedStreamsModal(streams, isFilterActive)}
        {this.renderConfirmStopSelectedStreamsModal(streams, isFilterActive)}
        {this.renderConfirmRestartSelectedStreamsModal(streams, isFilterActive)}
      </div>
    )
  }

  // -------

  renderAllStreamsTable = (streams?: Array<OutputStream>) => {
    // NB: only render the table once the streams & sources data has been loaded (added the `loadingData` state var to track both, instead of only the streams check in the previous version of the loading check)
    const loading = this.props.store.streamsStatus === StreamhubStreamsStatus.loading || this.state.loadingData
    if (loading) return <ArkLoaderView message='Loading' />
    const showHeader = true
    if (this.props.store.streamsError) {
      return (
        <Message negative>
          <Message.Header>Error</Message.Header>
          <p>{this.props.store.streamsError.message ?? ''}</p>
        </Message>
      )
    }
    const isFilterActive = this.isFilterActive()
    if (!streams || streams.length === 0) {
      return (
        <Message warning>
          <Message.Header>{isFilterActive ? <>No Matching Streams</> : <>No Streams</>}</Message.Header>
        </Message>
      )
    }
    return (
      <>
        {/* NEW: TanStack Table (React Table) based table rendering */}
        {(STREAMHUB_TABLE_TYPE === 1 || STREAMHUB_TABLE_TYPE === 2) && (
          <StreamhubStreamsTable
            streams={streams}
            onAction={this.onStreamAction}
            onSelectChange={this.onStreamSelectChange}
          />
        )}

        {/* OLD: Semantic UI based table rendering */}
        {(STREAMHUB_TABLE_TYPE === 0 || STREAMHUB_TABLE_TYPE === 2) && (
          <Table celled inverted className={styles.table + ' ' + styles.streamsTable}>
            {showHeader && (
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  {/* <Table.HeaderCell>Id</Table.HeaderCell> */}
                  <Table.HeaderCell>Stream Details</Table.HeaderCell>
                  {/* <Table.HeaderCell>Source</Table.HeaderCell> */}
                  {/* <Table.HeaderCell>Process</Table.HeaderCell> */}
                  {/* <Table.HeaderCell>Duration</Table.HeaderCell> */}
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  {/* <Table.HeaderCell>Started At</Table.HeaderCell> */}
                  <Table.HeaderCell>URL</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            )}
            <Table.Body>
              {this.renderAllStreamsTableRows(streams)}
            </Table.Body>
          </Table>
        )}

        {/* {activeStreams.length > 0 && (
          <>
            <ArkButton inverted onClick={() => { this.restartAllStreams() }}>RESTART ALL STREAMS</ArkButton>
            <ArkButton inverted onClick={() => { this.stopAllStreams() }}>STOP ALL STREAMS</ArkButton>
          </>
        )} */}
      </>
    )
  }

  // TODO: DEPRECIATE: once the new `StreamhubStreamsTable` component is fully implemented & tested
  renderAllStreamsTableRows = (streams: Array<OutputStream>) => {
    const { selectedStream /*, streamUpdates */ } = this.props.store
    return streams.map((stream: OutputStream) => {
      const streamSource = this.props.sourcesContext.store.sources?.find((streamSource) => streamSource.id === stream.sourceId)
      const streamStatus = stream.status // streamUpdates?.get(stream.id)
      const streamUpdating = streamStatus === IOutputStreamStatus.starting || streamStatus === IOutputStreamStatus.stopping || streamStatus === IOutputStreamStatus.restarting
      const streamUpdateError = streamStatus === IOutputStreamStatus.error
      const streamRetrying = streamUpdateError && stream.retryEnabled && stream.retryCount < stream.retryMaxAttempts
      const streamRetriesFailed = streamUpdateError && stream.retryEnabled && stream.retryCount >= stream.retryMaxAttempts
      let streamUrl = stream.url
      // TESTING: hide any url args (mainly to hide the long passphrase, but generally just to shorten it to the key url path)
      if (streamUrl?.includes('?')) {
        const queryIndex = streamUrl.indexOf('?')
        if (queryIndex >= 0) {
          streamUrl = streamUrl.substring(0, queryIndex)
        }
      }
      // if (stream.id === 1) { console.log('renderAllStreamsTableRows - stream: ', stream) }
      const programData = stream.programData
      return (
        <Table.Row key={'stream_' + stream.id} active={selectedStream && selectedStream.id === stream.id} className={styles.streamRow} onClick={() => { this.props.actions.selectStream(stream) }}>
          <Table.Cell className={styles.streamIdAndImg} collapsing>
            <div className={styles.idRow + ' ' + styles.idStream}>Stream ID: {stream.id}</div>
            {streamSource && (<Image src={this.props.sourcesContext.actions.getSourcePreviewImageURL(streamSource, 100)} />)}
            <div className={styles.idRow + ' ' + styles.idSource}>Source: {stream.sourceId}</div>
          </Table.Cell>
          {/* <Table.Cell>{stream.id}</Table.Cell> */}
          <Table.Cell className={styles.streamDetailsCell}>
            <div className={styles.streamDetails}>
              <div className={styles.streamName}>{stream.name ? stream.name : ''}</div>
              {programData && (
                <>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>server:</div><div className={styles.detailsValue}>{programData.server}</div>
                  </div>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>org:</div><div className={styles.detailsValue}>{programData.companyName} ({programData.companyId})</div>
                  </div>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>project:</div><div className={styles.detailsValue}>{programData.projectName} ({programData.projectId})</div>
                  </div>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>program:</div><div className={styles.detailsValue}>{programData.programName} ({programData.programId})</div>
                  </div>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>program updated:</div><div className={styles.detailsValue}>{programData.updatedAt ? formatDate(programData.updatedAt) : ''}</div>
                  </div>
                </>
              )}
            </div>
          </Table.Cell>
          {/* <Table.Cell>{stream.sourceId}</Table.Cell> */}
          {/* <Table.Cell>{stream.pid ? stream.pid : '-'}</Table.Cell> */}
          {/* <Table.Cell>{stream.duration ? stream.duration : '-'}</Table.Cell> */}{/* NB: not really using `duration` any more these days to hiding the field to free up column space */}
          <Table.Cell>
            <div className={styles.statusRow}>
              <div className={styles.statusTitle}>enabled:</div><div className={styles.statusValue}>{stream.isEnabled ? 'ENABLED' : 'DISABLED'}</div>
            </div>
            <div className={styles.statusRow}>
              <div className={styles.statusTitle}>active:</div><div className={styles.statusValue}>{streamUpdateError ? 'ERROR' : (stream.isActive ? 'ACTIVE' : 'STOPPED')}</div>
            </div>
            <div className={styles.statusRow}>
              <div className={styles.statusTitle}>status:</div><div className={styles.statusValue}>{stream.status !== undefined ? IOutputStreamStatus[stream.status] : 'N/A'}</div>
            </div>
            {(stream.status === IOutputStreamStatus.running) && (
              <>
                <div className={styles.statusRow}>
                  <div className={styles.statusTitle}>started:</div>
                  <div className={styles.statusValue}>
                    {stream.isActive && stream.startedAt ? formatDateTime(stream.startedAt) : (!streamRetrying && !streamRetriesFailed ? '-' : '')}
                  </div>
                </div>
                <div className={styles.statusRow}>
                  <div className={styles.statusTitle}>process:</div>
                  <div className={styles.statusValue}>{stream.pid ? stream.pid : '-'}</div>
                </div>
              </>
            )}
            {(streamRetrying || streamRetriesFailed) && (
              <div>
                {!streamRetriesFailed && (
                  <>
                    ERROR STARTING<br />
                    &gt; RETRY DELAY: {stream.retryDelay} secs<br />
                    &gt; RETRY ATTEMPTS: {stream.retryCount} / {stream.retryMaxAttempts}<br />
                  </>
                )}
                {streamRetriesFailed && (
                  <>
                    ERROR STARTING<br />
                    RETRY ATTEMPTS FAILED
                  </>
                )}
              </div>
            )}
            {/* </Table.Cell><Table.Cell> */}{/* NB: was 'started at' - now moved to the 'status' column */}
          </Table.Cell>
          <Table.Cell>{streamUrl}</Table.Cell>
          <Table.Cell className={styles.tableActions} textAlign='right' style={{ minWidth: 200, verticalAlign: 'middle' }}>
            <div className={styles.actionButtons}>
              {streamStatus === IOutputStreamStatus.running && (
                <ArkButton size='mini' className={styles.restartButton} onClick={() => { this.restartStream(stream.id) }}>R</ArkButton>
              )}
              <div className={styles.startStopButtonWrapper}>
                <ArkButton
                  color={streamUpdateError ? 'orange' : (stream.isActive ? 'red' : 'green')}
                  size='mini'
                  loading={streamUpdating || streamRetrying}
                  style={{ display: 'block', color: 'red !important' }}
                  onClick={() => {
                    console.log('StreamhubStreamsView - renderAllStreamsTableRows - streamStatus: ', streamStatus, ' stream: ', stream)
                    if (streamStatus === IOutputStreamStatus.running || streamRetrying) {
                      this.stopStream(stream.id)
                    } else if (streamStatus === IOutputStreamStatus.stopped || streamStatus === IOutputStreamStatus.error || streamStatus === IOutputStreamStatus.initial) {
                      this.startStream(stream.id)
                    }
                  }}
                  className={streamUpdateError ? styles.buttonError : ''}
                >
                  {streamStatus === IOutputStreamStatus.running ? 'STOP' : 'START'}
                </ArkButton>
                {streamUpdateError && (
                  <div className={styles.startError}>
                    ERROR
                    {stream.errorMsg && (<div className={styles.startErrorMsg}>{stream.errorMsg}</div>)}
                  </div>
                )}
              </div>
              <ArkButton size='mini' className={styles.buttonLeftPad} onClick={() => { this.showEditStreamModal(stream.id) }}>EDIT</ArkButton>
              <ArkManagerDeleteButton
                className={styles.buttonLeftPad}
                itemId={stream.id}
                itemName={'Stream ' + stream.id}
                itemTypeName='Stream'
                buttonTitle='X'
                onDelete={this.onDeleteStream}
                onDeleteComplete={this.onDeleteStreamComplete}
                fluid={false}
                size='mini'
                disabled={stream.isActive}
                style={{ display: 'inline' }}
                buttonStyle={{ fontSize: '12px', padding: '8px 10px', marginLeft: 6 }}
              />
            </div>
          </Table.Cell>
        </Table.Row>
      )
    })
  }

  // -------

  renderStreamsSummary = (currentStreams?: Array<OutputStream>, allStreams?: Array<OutputStream>, isFilterActive: boolean = false) => {
    const currentStreamsCount = currentStreams && currentStreams.length > 0 ? currentStreams.length : 0
    const allStreamsCount = allStreams && allStreams.length > 0 ? allStreams.length : 0
    const currentActiveStreamsCount = currentStreams ? currentStreams.filter((s) => s.isActive).length : 0
    const allActiveStreamsCount = allStreams ? allStreams.filter((s) => s.isActive).length : 0
    const selectedStreamsCount = this.state.selectedStreamIds.length
    return (
      <>
        Showing: {isFilterActive ? <>{currentStreamsCount} / </> : null}{allStreamsCount} stream{allStreamsCount !== 1 ? 's' : ''}
        &nbsp;({isFilterActive ? <>{currentActiveStreamsCount} / </> : null}{allActiveStreamsCount} active)
        {selectedStreamsCount > 0 ? <>&nbsp;({selectedStreamsCount} / {currentStreamsCount} selected)</> : null}
      </>
    )
  }

  // NB: updated to work with the new multi-select handling (instead of whatever is showing on the table with/without filtering applied)
  renderStreamActions = (currentStreams?: Array<OutputStream>, allStreams?: Array<OutputStream>, _isFilterActive: boolean = false) => {
    // TODO: should 'active' take into account starting/restarting as well? (& the stopping for 'inactive' too?)
    const selectedStreamIds = this.state.selectedStreamIds
    const selectedStreamsCount = selectedStreamIds.length
    const selectedActiveStreamsCount = selectedStreamsCount > 0 && currentStreams ? currentStreams.filter((s) => s.isActive && selectedStreamIds.includes(s.id)).length : 0
    const selectedInactiveStreamsCount = selectedStreamsCount > 0 && currentStreams ? currentStreams.filter((s) => !s.isActive && selectedStreamIds.includes(s.id)).length : 0
    return (
      <>
        <ArkButton size='mini' className={styles.addStream} onClick={() => { this.showAddStreamModal() }}>ADD STREAM</ArkButton>
        {/* {currentActiveStreamsCount > 0 && ( */}
        <>
          <div className={styles.divider}></div>
          {/* OLD: multi-restart/stop based on whats showing (filtered or all) */}
          {/* <ArkButton
            color='orange'
            size='mini'
            className={styles.restartAllStreams}
            onClick={() => { this.setState({ showRestartAllStreamsModal: true }) }}
            disabled={currentActiveStreamsCount === 0}
          >
            RESTART {isFilterActive ? <>{currentActiveStreamsCount}</> : <>ALL ({currentActiveStreamsCount})</>} STREAM{currentActiveStreamsCount !== 1 ? 'S' : ''}
          </ArkButton>
          <ArkButton
            color='red'
            size='mini'
            className={styles.stopAllStreams}
            onClick={() => { this.setState({ showStopAllStreamsModal: true }) }}
            disabled={currentActiveStreamsCount === 0}
          >
            STOP {isFilterActive ? <>{currentActiveStreamsCount}</> : <>ALL ({currentActiveStreamsCount})</>} STREAM{currentActiveStreamsCount !== 1 ? 'S' : ''}
          </ArkButton> */}

          {/* NEW: multi-restart/stop based on new multi-select handling */}
          <ArkButton
            color='green'
            size='mini'
            className={styles.startAllStreams}
            onClick={() => { this.setState({ showStartSelectedStreamsModal: true }) }}
            disabled={selectedInactiveStreamsCount === 0}
          >
            START {selectedInactiveStreamsCount} / {selectedStreamsCount}{/* STREAM{selectedStreamsCount !== 1 ? 'S' : ''} */}
          </ArkButton>
          <ArkButton
            color='orange'
            size='mini'
            className={styles.restartAllStreams}
            onClick={() => { this.setState({ showRestartSelectedStreamsModal: true }) }}
            disabled={selectedActiveStreamsCount === 0}
          >
            RESTART {selectedActiveStreamsCount} / {selectedStreamsCount}{/* STREAM{selectedStreamsCount !== 1 ? 'S' : ''} */}
          </ArkButton>
          <ArkButton
            color='red'
            size='mini'
            className={styles.stopAllStreams}
            onClick={() => { this.setState({ showStopSelectedStreamsModal: true }) }}
            disabled={selectedActiveStreamsCount === 0}
          >
            STOP {selectedActiveStreamsCount} / {selectedStreamsCount}{/* STREAM{selectedStreamsCount !== 1 ? 'S' : ''} */}
          </ArkButton>
        </>
        {/* )} */}
        <div className={styles.divider}></div>
        <ArkButton color='blue' size='mini' className={styles.refreshBtn} onClick={async () => { this.onDataChanged() }}>REFRESH</ArkButton>
      </>
    )
  }

  // -------

  renderStreamsFilterForm = () => {
    const { filterValues, filterUrlArgsLoaded } = this.state
    if (!filterUrlArgsLoaded) return null // don't load the filter form until the url args have been loaded (if any)
    return (
      <StreamhubStreamFilterForm
        autoComplete={false}
        filterValues={filterValues}
        tagSuggestions={this.props.store.streamTags}
        onFilterChange={(fieldKey: string, value?: string | number | Array<string>) => {
          // console.log('StreamhubStreamsView - renderStreamsFilterForm - onFilterChange - fieldKey:', fieldKey, ' value:', value)
          const prevFilterValues = this.state.filterValues
          const newFilterValues: StreamhubStreamFilterValues = { ...prevFilterValues }
          switch (fieldKey) {
            case 'name': if (newFilterValues.name === undefined || typeof newFilterValues.name === 'string') { newFilterValues.name = value as string } break
            case 'url': if (newFilterValues.url === undefined || typeof newFilterValues.url === 'string') { newFilterValues.url = value as string } break
            case 'server': if (newFilterValues.server === undefined || typeof newFilterValues.server === 'string') { newFilterValues.server = value as string } break
            case 'org': if (newFilterValues.org === undefined || typeof newFilterValues.org === 'string') { newFilterValues.org = value as string } break
            case 'project': if (newFilterValues.project === undefined || typeof newFilterValues.project === 'string') { newFilterValues.project = value as string } break
            case 'program': if (newFilterValues.program === undefined || typeof newFilterValues.program === 'string') { newFilterValues.program = value as string } break
            case 'status': if (newFilterValues.status === undefined || typeof newFilterValues.status === 'number') { newFilterValues.status = value as number } break
            case 'tags': {
              if (newFilterValues.tags === undefined || (typeof newFilterValues.tags === 'object' && Array.isArray(newFilterValues.tags))) {
                newFilterValues.tags = value as Array<string>
                // console.log('StreamhubStreamsView - renderStreamsFilterForm - onFilterChange - tags - value:', value, ' newFilterValues.tags:', newFilterValues.tags)
              }
              break
            }
            default: {
              console.warn('StreamhubStreamsView - renderStreamsFilterForm - onFilterChange - ERROR: UNHANDLED FIELD KEY (or incorrect value type) - fieldKey:', fieldKey, ' value:', value)
              return
            }
          }
          this.setState({ filterValues: newFilterValues })
          this.updateFilterUrlArgs(newFilterValues)
        }}
        onFilterClear={() => {
          console.log('StreamhubStreamsView - renderStreamsFilterForm - onFilterClear')
          const newFilterValues = { ...this._defaultFilterValues }
          this.setState({ filterValues: newFilterValues })
          this.updateFilterUrlArgs(newFilterValues)
        }}
      />
    )
  }

  isFilterActive = () => {
    const { filterValues } = this.state
    if (filterValues.name !== undefined) return true
    if (filterValues.url !== undefined) return true
    if (filterValues.server !== undefined) return true
    if (filterValues.org !== undefined) return true
    if (filterValues.project !== undefined) return true
    if (filterValues.program !== undefined) return true
    if (filterValues.status !== undefined && filterValues.status !== 0) return true
    if (filterValues.tags !== undefined && filterValues.tags.length > 0) return true
    return false
  }

  filterStreams = (streams: Array<OutputStream>) => {
    const { filterValues } = this.state
    if (!this.isFilterActive()) return streams
    const filteredStreams: Array<OutputStream> = []
    for (const stream of streams) {
      const nameOk = (filterValues.name !== undefined ? (stream.name !== undefined && stream.name.toLowerCase().indexOf(filterValues.name.toLowerCase()) >= 0) : true)
      const urlOk = (filterValues.url !== undefined ? (stream.url !== undefined && stream.url.toLowerCase().indexOf(filterValues.url.toLowerCase()) >= 0) : true)
      const serverOk = (filterValues.server !== undefined ? (stream.programData !== undefined && stream.programData.server !== undefined && stream.programData.server.toLowerCase().indexOf(filterValues.server.toLowerCase()) >= 0) : true)
      const orgOk = (filterValues.org !== undefined ? (stream.programData !== undefined && stream.programData.companyName !== undefined && stream.programData.companyName.toLowerCase().indexOf(filterValues.org.toLowerCase()) >= 0) : true)
      const projectOk = (filterValues.project !== undefined ? (stream.programData !== undefined && stream.programData.projectName !== undefined && stream.programData.projectName.toLowerCase().indexOf(filterValues.project.toLowerCase()) >= 0) : true)
      const programOk = (filterValues.program !== undefined ? (stream.programData !== undefined && stream.programData.programName !== undefined && stream.programData.programName.toLowerCase().indexOf(filterValues.program.toLowerCase()) >= 0) : true)
      const statusOk = (filterValues.status !== undefined
        ? (filterValues.status === StreamhubStreamFilterFormStatus.running
          ? (stream.isActive || stream.status === IOutputStreamStatus.starting || stream.status === IOutputStreamStatus.restarting)
          : (filterValues.status === StreamhubStreamFilterFormStatus.stopped
            ? (!stream.isActive || stream.status === IOutputStreamStatus.stopping || stream.status === IOutputStreamStatus.initial || stream.status === IOutputStreamStatus.error) // NB: ALSO including 'error' streams in the 'stopped' status filter
            : (filterValues.status === StreamhubStreamFilterFormStatus.error
              ? (stream.status === IOutputStreamStatus.error)
              : true
            )))
        : true)
      const tagsOk = (filterValues.tags !== undefined && filterValues.tags.length > 0 ? stream.tags && filterValues.tags.every((tag) => stream.tags?.includes(tag)) : true)
      if (nameOk && urlOk && serverOk && orgOk && projectOk && programOk && statusOk && tagsOk) {
        filteredStreams.push(stream)
      }
    }
    return filteredStreams
  }

  // updates the url with the current filter args, so they can be used on page reloads to re-apply the filters
  updateFilterUrlArgs = (filterValues: StreamhubStreamFilterValues) => {
    // console.log('StreamhubStreamsView - updateFilterUrlArgs - filterValues:', filterValues)
    // window.location.hash = '#new-hash' // NB: adds to history
    // window.location.replace('#new-hash') // NB: skips history
    let filterHash = ''
    let filterKey: keyof typeof filterValues
    for (filterKey in filterValues) {
      // console.log('StreamhubStreamsView - updateFilterUrlArgs - filterKey:', filterKey, ' filterValues[filterKey]:', filterValues[filterKey])
      if (filterValues[filterKey] !== undefined) {
        if (filterKey === 'status' && filterValues[filterKey] === 0) continue // skip the default status value (it has a value, all others are undefined by default)
        if (filterKey === 'tags') {
          if (filterValues[filterKey] !== undefined && filterValues[filterKey]!.length > 0) filterHash += (filterHash.length > 0 ? '&' : '') + filterKey + '=' + filterValues[filterKey]?.join(',')
        } else {
          filterHash += (filterHash.length > 0 ? '&' : '') + filterKey + '=' + filterValues[filterKey]
        }
      }
    }
    // console.log('StreamhubStreamsView - updateFilterUrlArgs - filterHash:', filterHash)
    // console.log('StreamhubStreamsView - updateFilterUrlArgs - window.location.hash(BEFORE):', window.location.hash)
    if (filterHash.length > 0) {
      window.location.replace('#' + filterHash)
    } else {
      // window.location.replace('')
      window.history.replaceState({}, document.title, window.location.pathname)
    }
  }

  // applies the filter args from the url (if any) to the filter state vars (for use on page load/refresh)
  loadFilterUrlArgs = () => {
    console.log('StreamhubStreamsView - loadFilterUrlArgs - window.location.hash:', window.location.hash)
    if (window.location.hash.length > 0 && window.location.hash.startsWith('#')) {
      const filterHash = window.location.hash.substring(1)
      const filterArgs = filterHash.split('&')
      const filterValues: StreamhubStreamFilterValues = { ...this._defaultFilterValues }
      for (const filterArg of filterArgs) {
        const filterParts = filterArg.split('=')
        if (filterParts.length === 2) {
          const filterKey = filterParts[0] as keyof typeof filterValues
          const filterValue = decodeURI(filterParts[1])
          if (filterKey === 'status') {
            filterValues.status = parseInt(filterValue)
          } else if (filterKey === 'tags') {
            const tags = filterValue.split(',')
            console.log('StreamhubStreamsView - loadFilterUrlArgs - tags:', tags)
            if (tags.length > 0) filterValues.tags = tags
          } else {
            filterValues[filterKey] = filterValue
          }
        }
      }
      // console.log('StreamhubStreamsView - loadFilterUrlArgs - filterValues:', filterValues)
      this.setState({ filterValues, filterUrlArgsLoaded: true })
    } else {
      this.setState({ filterUrlArgsLoaded: true })
    }
  }

  // -------

  loadData = async () => {
    console.log('StreamhubStreamsView - loadData - start')
    this.setState({ loadingData: true })
    await this.props.actions.fetchStreams()
    await this.props.sourcesContext.actions.fetchSources()
    this.setState({ loadingData: false })
    console.log('StreamhubStreamsView - loadData - end')
  }

  onDataChanged = () => {
    this.loadData() // TODO: await before calling the callback?
    if (this.props.onDataChanged) this.props.onDataChanged()
  }

  // -------

  // StreamhubStreamsTable - StreamhubStreamsActionCallback
  onStreamAction = async (streamId: number, actionType: StreamhubStreamsActionType): Promise<boolean | undefined> => {
    console.log('StreamhubStreamsView - onStreamAction - streamId:', streamId, ' actionType:', actionType)
    switch (actionType) {
      case StreamhubStreamsActionType.start:
        await this.startStream(streamId)
        break
      case StreamhubStreamsActionType.stop:
        await this.stopStream(streamId)
        break
      case StreamhubStreamsActionType.restart:
        await this.restartStream(streamId)
        break
      case StreamhubStreamsActionType.edit:
        this.showEditStreamModal(streamId)
        break
      case StreamhubStreamsActionType.delete:
        return await this.onDeleteStream(streamId)
      case StreamhubStreamsActionType.deleteComplete:
        this.onDeleteStreamComplete()
        break
      default: return Promise.resolve(false)
    }
  }

  onStreamSelectChange = (selectedStreamIds: Array<number>) => {
    console.log('StreamhubStreamsView - onStreamSelectChange - selectedStreamIds:', selectedStreamIds)
    this.setState({ selectedStreamIds })
    // if (selectedStreamIds.length === 1) {
    //   const selectedStream = this.getStream(selectedStreamIds[0])
    //   if (selectedStream) {
    //     this.props.actions.selectStream(selectedStream)
    //   }
    // }
  }

  // -------

  showAddStreamModal = () => {
    this.setState({ editStream: undefined, showFormModal: true, formSaved: false })
  }

  showEditStreamModal = (streamId: number) => {
    const stream = this.getStream(streamId)
    this.setState({ editStream: stream, showFormModal: true, formSaved: false })
  }

  hideStreamModal = () => {
    this.setState({ editStream: undefined, showFormModal: false })
  }

  // -------

  // ArkManagerDeleteButton onDelete callback, return true on success, or throw an error to have it displayed by the delete modal
  onDeleteStream = async (streamId: number) => {
    await this.props.actions.deleteStreamWithId(streamId)
    if (this.props.store.streamAction === StreamhubStreamAction.delete) {
      if (this.props.store.streamStatus === StreamhubStreamStatus.done) {
        return true
      } else if (this.props.store.streamStatus === StreamhubStreamStatus.error && this.props.store.streamError) {
        throw this.props.store.streamError // throw the error so the ArkManagerDeleteButton handling can catch & display it
      }
    }
    return false
  }

  // ArkManagerDeleteButton onDeleteComplete callback, called once the success result has been dismissed by the user
  onDeleteStreamComplete = () => {
    this.onDataChanged()
  }

  // -------

  renderStreamFormModal = () => {
    return (
      <Modal
        onClose={() => this.hideStreamModal()}
        /* onOpen={() => this.setState({ showProjectFormModal: true })} */
        open={this.state.showFormModal}
        trigger={null /* <ArkButton>Show Modal</ArkButton> */}
        closeOnEscape={true}
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <Modal.Content>
          <StreamhubStreamForm
            mode={this.state.editStream ? ArkTestStreamFormMode.Edit : ArkTestStreamFormMode.Add}
            stream={this.state.editStream}
            streamSources={this.props.sourcesContext.store.sources ?? []}
            tagSuggestions={this.props.store.streamTags}
            error={this.state.formError}
            onGetStreamSourcePreviewImageURL={(source: InputSource, width?: number) => {
              return this.props.sourcesContext.actions.getSourcePreviewImageURL(source, width)
            }}
            onCancel={() => { this.hideStreamModal() }}
            onSave={async (data: any) => {
              if (this.state.editStream) {
                await this.props.actions.updateStream(
                  this.state.editStream.id,
                  data.sourceId, data.name,
                  data.url,
                  data.duration,
                  data.retryEnabled,
                  data.retryDelay,
                  data.retryMaxAttempts,
                  data.programData,
                  undefined, // NB: isTemp (not used/supported from here currently)
                  data.tags
                )
              } else {
                await this.props.actions.createStream(
                  data.sourceId,
                  data.name,
                  data.url,
                  data.duration,
                  data.retryEnabled,
                  data.retryDelay,
                  data.retryMaxAttempts,
                  data.programData,
                  undefined, // NB: isTemp (not used/supported from here currently)
                  data.tags
                )
              }
              // NB: ignore the source provider results if they aren't for the performed action
              if (this.props.store.streamAction === StreamhubStreamAction.create || this.props.store.streamAction === StreamhubStreamAction.update) {
                if (this.props.store.streamStatus === StreamhubStreamStatus.done) {
                  // TODO: change to call the local onDataChanged, but add an optional stream arg to it, so we can pass it directly in the callback
                  this.loadData() // reload sources
                  if (this.props.onDataChanged) this.props.onDataChanged(this.props.store.stream)
                  if (this._isMounted) this.setState({ formSaved: true, editStream: this.props.store.stream })
                  return true
                } else if (this.props.store.streamError) {
                  if (this._isMounted) this.setState({ formError: this.props.store.streamError, formSaved: false })
                  return false
                }
              }
              return false
              // NB: we don't auto close/hide the modal form when it saves, leave it up for a success message to show & the user to dismiss manually
            }}
            onDelete={() => {
              // trigger a data re-load so the deleted one no longer shows
              // this.loadPrograms()
              this.onDataChanged()
              // NB: we don't auto close/hide the modal form when it deletes, leave it up for a delete success message to show & the user to dismiss manually
            }}
            onClose={() => { this.hideStreamModal() }}
          />
        </Modal.Content>
      </Modal>
    )
  }

  // -------

  renderConfirmStartSelectedStreamsModal = (currentStreams?: Array<OutputStream>, _isFilterActive: boolean = false) => {
    // const currentInactiveStreams = currentStreams ? currentStreams.filter((s) => !s.isActive) : undefined
    // const currentInactiveStreamsCount = currentInactiveStreams ? currentInactiveStreams.length : 0
    // TODO: should 'active' take into account starting/restarting as well? (& the stopping for 'inactive' too?)
    const selectedStreamIds = this.state.selectedStreamIds
    const selectedStreamsCount = selectedStreamIds.length
    const selectedInactiveStreams = selectedStreamsCount > 0 && currentStreams ? currentStreams.filter((s) => !s.isActive && selectedStreamIds.includes(s.id)) : []
    const selectedInactiveStreamsCount = selectedInactiveStreams.length
    // console.log('StreamhubStreamsView - renderConfirmStartSelectedStreamsModal - selectedInactiveStreams: ', selectedInactiveStreams)
    return (
      <ArkConfirmModal
        show={this.state.showStartSelectedStreamsModal}
        title={<>Start {selectedInactiveStreamsCount} stream{selectedInactiveStreamsCount !== 1 ? 's' : ''}?</>}
        message={<>
          <p>Are you sure you want to START {selectedInactiveStreamsCount} stream{selectedInactiveStreamsCount !== 1 ? 's' : ''}?</p>
          <p>Streams:</p>
          <ul>
            {selectedInactiveStreams.map((stream) => {
              // console.log('StreamhubStreamsView - renderConfirmStartSelectedStreamsModal - stream: ', stream)
              return (
                <li key={'startSelectedStream_' + stream.id}>{stream.name ? stream.name : 'Stream ' + stream.id}</li>
              )
            })}
          </ul>
        </>}
        onCancel={() => this.setState({ showStartSelectedStreamsModal: false })}
        onClose={() => this.setState({ showStartSelectedStreamsModal: false })}
        onConfirm={() => {
          this.setState({ showStartSelectedStreamsModal: false })
          const selectedInactiveStreamIds = selectedInactiveStreams.map((s) => s.id)
          // console.log('StreamhubStreamsView - renderConfirmStartSelectedStreamsModal - selectedInactiveStreamIds:', selectedInactiveStreamIds)
          this.startStreams(selectedInactiveStreamIds)
        }}
      />
    )
  }

  renderConfirmStopSelectedStreamsModal = (currentStreams?: Array<OutputStream>, _isFilterActive: boolean = false) => {
    // const currentActiveStreams = currentStreams ? currentStreams.filter((s) => s.isActive) : undefined
    // const currentActiveStreamsCount = currentActiveStreams ? currentActiveStreams.length : 0
    // TODO: should 'active' take into account starting/restarting as well? (& the stopping for 'inactive' too?)
    const selectedStreamIds = this.state.selectedStreamIds
    const selectedStreamsCount = selectedStreamIds.length
    const selectedActiveStreams = selectedStreamsCount > 0 && currentStreams ? currentStreams.filter((s) => s.isActive && selectedStreamIds.includes(s.id)) : []
    const selectedActiveStreamsCount = selectedActiveStreams.length
    // console.log('StreamhubStreamsView - renderConfirmStopSelectedStreamsModal - selectedActiveStreams: ', selectedActiveStreams)
    return (
      <ArkConfirmModal
        show={this.state.showStopSelectedStreamsModal}
        title={<>Stop {selectedActiveStreamsCount} stream{selectedActiveStreamsCount !== 1 ? 's' : ''}?</>}
        message={<>
          <p>Are you sure you want to STOP {selectedActiveStreamsCount} stream{selectedActiveStreamsCount !== 1 ? 's' : ''}?</p>
          <p>Streams:</p>
          <ul>
            {selectedActiveStreams.map((stream) => {
              // console.log('StreamhubStreamsView - renderConfirmStopSelectedStreamsModal - stream: ', stream)
              return (
                <li key={'stopSelectedStream_' + stream.id}>{stream.name ? stream.name : 'Stream ' + stream.id}</li>
              )
            })}
          </ul>
        </>}
        onCancel={() => this.setState({ showStopSelectedStreamsModal: false })}
        onClose={() => this.setState({ showStopSelectedStreamsModal: false })}
        onConfirm={() => {
          this.setState({ showStopSelectedStreamsModal: false })
          const selectedActiveStreamIds = selectedActiveStreams.map((s) => s.id)
          console.log('StreamhubStreamsView - renderConfirmStopSelectedStreamsModal - selectedActiveStreamIds:', selectedActiveStreamIds)
          this.stopStreams(selectedActiveStreamIds)
        }}
      />
    )
  }

  renderConfirmRestartSelectedStreamsModal = (currentStreams?: Array<OutputStream>, _isFilterActive: boolean = false) => {
    // const currentActiveStreams = currentStreams ? currentStreams.filter((s) => s.isActive) : undefined
    // const currentActiveStreamsCount = currentActiveStreams ? currentActiveStreams.length : 0
    // TODO: should 'active' take into account starting/restarting as well? (& the stopping for 'inactive' too?)
    const selectedStreamIds = this.state.selectedStreamIds
    const selectedStreamsCount = selectedStreamIds.length
    const selectedActiveStreams = selectedStreamsCount > 0 && currentStreams ? currentStreams.filter((s) => s.isActive && selectedStreamIds.includes(s.id)) : []
    const selectedActiveStreamsCount = selectedActiveStreams.length
    // console.log('StreamhubStreamsView - renderConfirmRestartSelectedStreamsModal - selectedActiveStreams: ', selectedActiveStreams)
    return (
      <ArkConfirmModal
        show={this.state.showRestartSelectedStreamsModal}
        title={<>Restart {selectedActiveStreamsCount} stream{selectedActiveStreamsCount !== 1 ? 's' : ''}?</>}
        message={<>
          <p>Are you sure you want to RESTART {selectedActiveStreamsCount} stream{selectedActiveStreamsCount !== 1 ? 's' : ''}?</p>
          <p>Streams:</p>
          <ul>
            {selectedActiveStreams.map((stream) => {
              // console.log('StreamhubStreamsView - renderConfirmRestartSelectedStreamsModal - stream: ', stream)
              return (
                <li key={'restartSelectedStream_' + stream.id}>{stream.name ? stream.name : 'Stream ' + stream.id}</li>
              )
            })}
          </ul>
        </>}
        onCancel={() => this.setState({ showRestartSelectedStreamsModal: false })}
        onClose={() => this.setState({ showRestartSelectedStreamsModal: false })}
        onConfirm={() => {
          this.setState({ showRestartSelectedStreamsModal: false })
          const selectedActiveStreamIds = selectedActiveStreams.map((s) => s.id)
          console.log('StreamhubStreamsView - renderConfirmRestartSelectedStreamsModal - selectedActiveStreamIds:', selectedActiveStreamIds)
          this.restartStreams(selectedActiveStreamIds)
        }}
      />
    )
  }

  // -------

  getStream = (streamId: number) => {
    if (this.props.store.streams && this.props.store.streams.length > 0) {
      return this.props.store.streams.find((stream) => stream.id === streamId)
    }
    return undefined
  }

  // getActiveStreams = (): Array<OutputStream> => {
  //   const activeStreams: Array<OutputStream> = []
  //   if (this.props.store.streams && this.props.store.streams.length > 0) {
  //     for (const stream of this.props.store.streams) {
  //       if (stream.isActive) {
  //         activeStreams.push(stream)
  //       }
  //     }
  //   }
  //   return activeStreams
  // }

  // -------

  startStream = async (streamId: number) => {
    console.log('AdminTestStreamsPage - startStream - streamId: ', streamId)
    try {
      const startResult = await this.props.actions.startStream(streamId)
      if (startResult) {
        // TODO: show a success message
        // this.onDataChanged() // NB: provider now auto updates the relevant stream entry from the response without an extra api call
      }
    } catch (error) {
      console.error('AdminTestStreamsPage - startStream - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  startStreams = async (streamIds: Array<number>) => {
    try {
      // TODO: check & handle if no streamIds?
      const restartResults = await this.props.actions.startStreams(streamIds)
      console.log('AdminTestStreamsPage - startStreams - restartResults: ', restartResults)
    } catch (error) {
      console.error('AdminTestStreamsPage - startStreams - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  restartStream = async (streamId: number) => {
    try {
      const restartResult = await this.props.actions.restartStream(streamId)
      console.log('AdminTestStreamsPage - restartStream - restartResult: ', restartResult)
    } catch (error) {
      console.error('AdminTestStreamsPage - restartStream - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  restartStreams = async (streamIds: Array<number>) => {
    try {
      // TODO: check & handle if no streamIds?
      const restartResults = await this.props.actions.restartStreams(streamIds)
      console.log('AdminTestStreamsPage - restartStreams - restartResults: ', restartResults)
    } catch (error) {
      console.error('AdminTestStreamsPage - restartStreams - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  restartAllActiveStreams = async () => {
    try {
      const restartResult = await this.props.actions.restartAllActiveStreams()
      if (restartResult) {
        // TODO: show a success message
        this.onDataChanged()
      }
    } catch (error) {
      console.error('AdminTestStreamsPage - restartAllStreams - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  stopStream = async (streamId: number) => {
    try {
      const stopResult = await this.props.actions.stopStream(streamId)
      if (stopResult) {
        // TODO: show a success message
        // this.onDataChanged() // NB: provider now auto updates the relevant stream entry from the response without an extra api call
      }
    } catch (error) {
      console.error('AdminTestStreamsPage - stopStream - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  stopStreams = async (streamIds: Array<number>) => {
    try {
      // TODO: check & handle if no streamIds?
      const stopResults = await this.props.actions.stopStreams(streamIds)
      if (stopResults) {
        // TODO: show a success message
        // this.onDataChanged() // NB: provider now auto updates the relevant stream entry from the response without an extra api call
      }
    } catch (error) {
      console.error('AdminTestStreamsPage - stopStreams - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  stopAllActiveStreams = async () => {
    try {
      const stopResult = await this.props.actions.stopAllActiveStreams()
      if (stopResult) {
        // TODO: show a success message
        this.onDataChanged()
      }
    } catch (error) {
      console.error('AdminTestStreamsPage - stopAllStreams - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  // -------

  startQuickStream = async (sourceId: number, url: string) => {
    try {
      const startResult = await this.props.actions.startQuickStream(sourceId, url)
      if (startResult) {
        // TODO: show a success message
        this.onDataChanged()
      }
    } catch (error) {
      console.error('AdminTestStreamsPage - startQuickStream - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }
  // TODO: stopQuickStream
  // TODO: stopAllQuickStreams

  // -------
}

export class StreamhubStreamsViewWithContext extends Component<IProps, {}> {
  render () {
    return (
      <StreamhubStreamsContext.Consumer>
        {(streamsContext) => { // { status }
          if (streamsContext === null) {
            throw new Error('StreamhubStreamConsumer must be used within a StreamhubStreamProvider')
          }
          // console.log('StreamhubStreamsViewWithContext - render - StreamhubStreamsContext.Consumer - streamsContext.store.streams: ', streamsContext.store.streams)
          return (
            <StreamhubSourcesContext.Consumer>
              {(sourcesContext) => { // { status }
                if (sourcesContext === null) {
                  throw new Error('StreamhubSourceConsumer must be used within a StreamhubSourcesProvider')
                }
                // console.log('StreamhubStreamsViewWithContext - render - StreamhubSourcesContext.Consumer - sourcesContext.store.sources: ', sourcesContext.store.sources)
                return (
                  <StreamhubStreamsView
                    {...this.props}
                    {...streamsContext}
                    {...{ sourcesContext: sourcesContext }}
                  />
                )
              }}
            </StreamhubSourcesContext.Consumer>
          )
        }}
      </StreamhubStreamsContext.Consumer>
    )
  }
}

const StreamhubStreamsViewWithContext2 = withResponsiveContext(StreamhubStreamsViewWithContext)

export default StreamhubStreamsViewWithContext2 // StreamhubStreamsViewWithContext
export { StreamhubStreamsView }
