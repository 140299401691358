import { BaseModel } from './base_model'
import { Notice, Program } from './'

export class Channel extends BaseModel {
  id: number
  name: string

  projectId: number
  colour?: string
  enableAutoSolo: boolean // TODO: ??
  playbackLayoutId: number // TODO: ??

  programCount?: number

  programs?: Array<Program>

  notices: Notice[]

  constructor (
    id: number,
    name: string,
    projectId: number,
    colour?: string,
    enableAutoSolo: boolean = false,
    playbackLayoutId: number = 1, // TODO: create an enum to represent the available playback layouts & set the default here with it?
    programCount?: number,
    notices: Notice[] = []
  ) {
    super()
    this.id = id
    this.name = name
    this.projectId = projectId
    this.colour = colour
    this.enableAutoSolo = enableAutoSolo
    this.playbackLayoutId = playbackLayoutId
    this.programCount = programCount
    this.notices = notices
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (id: number, json: any, loadPrograms: boolean = false) : Channel | null {
    if (!json) {
      return null
    }
    const channel = new Channel(
      id,
      json.name,
      json.project_id,
      json.colour,
      json.flag_auto_solo,
      json.playback_layout?.id,
      json.programs_count,
      json.notices
    )
    if (loadPrograms) {
      const programs: Array<Program> = []
      if (json.programs) {
        const programIds: Array<number> = []
        const programsData = json.programs
        for (const programData of programsData) {
          const program = Program.fromJSON(programData.id, programData)
          if (program && !programIds.includes(program.id)) { // NB: work-around to filter out dupes the api is currently sending
            programs.push(program)
            programIds.push(program.id)
          }
        }
      }
      // sort/order the programs array by program name
      // programs.sort((a: Program, b: Program) => a.name.localeCompare(b.name))
      // sort/order the programs array by program name using a more intelligent natural sort
      programs.sort((a, b) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
      channel.programs = programs
    }
    return channel
  }

  getProgram = (programId: number) : Program | null => {
    if (this.programs && this.programs.length > 0) {
      for (const program of this.programs) {
        if (program.id === programId) {
          return program
        }
      }
    }
    return null
  }
}
