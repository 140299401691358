import ArkPanel, { ArkPanelProps } from './ArkPanel'
// import ArkPropertiesPanel from './ArkPropertiesPanel'

// interface ArkPanelAll extends ArkPanelProps {
//   Properties: typeof ArkPropertiesPanel
// }
// declare const ArkPanel: ArkPanelAll

export default ArkPanel
// export * from './ArkPanel'
export type { ArkPanelProps }
