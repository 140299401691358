import React from 'react'

import { Program } from 'src/core/models'

import ArkPopup from 'src/core/components/ArkPopup'
import ArkIconButton from 'src/core/components/ArkIconButton'

export interface ProjectProgramHotlinkIconProps {
  program: Program
  triggerClassName?: string
  popupClassName?: string
  contentClassName?: string
  titleClassName?: string
}

const ProjectProgramHotlinkIcon = (props: ProjectProgramHotlinkIconProps) => {
  const { program, triggerClassName, popupClassName, contentClassName, titleClassName } = props
  return (
    <ArkPopup
      key={'program_' + program.id}
      className={popupClassName}
      position='bottom left'
      mouseLeaveDelay={500}
      title='Hotlink protection on:'
      titleClassName={titleClassName}
      // open={true} // DEBUG ONLY: (temp) uncomment to show the popup at all times to make styling easy
      trigger={
        <ArkIconButton
          className={triggerClassName}
          name='padlock-closed'
          size={18}
          color='orange'
        />
      }
    >
      <div className={contentClassName}>
        {program.hotlinkProtocols?.join(', ')}
      </div>
    </ArkPopup>
  )
}

export default ProjectProgramHotlinkIcon
