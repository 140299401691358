/**
 * ChecklistModal
 */

import React, { useContext } from 'react'

import ArkModal from 'src/core/components/ArkModal'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { ChecklistContext } from './ChecklistProvider'
import ChecklistView from './ChecklistView'

import styles from './ChecklistModal.module.css'

const ChecklistModal = () => {
  const { actions, store } = useContext(ChecklistContext)
  const { setShowChecklist } = actions
  const { showChecklistModal } = store

  if (!showChecklistModal) return null

  return (
    <ArkModal className={styles.modal} onClose={() => setShowChecklist(false)} open>
      <div className={styles.title}>Project set up checklist</div>
      <ArkSpacer />
      <ChecklistView />
    </ArkModal>
  )
}

export default ChecklistModal
