/**
 * ArkOnboarding
 */

import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { Popup } from 'semantic-ui-react'

import { OnboardingContext } from '../../providers/OnboardingProvider'
import ArkButton from '../ArkButton'
import ArkSpacer from '../ArkSpacer'

import styles from './ArkOnboarding.module.css'

export interface OnboardingProps {
  children: ReactNode
}

const ArkOnboarding = (props: OnboardingProps) => {
  const { children } = props

  const { actions, store } = useContext(OnboardingContext)
  const { hideCurrentPopup } = actions
  const { currentPopupProps } = store

  const [show, setShow] = useState(false)
  const [targetRect, setTargetRect] = useState<DOMRect>()

  // update target position
  useEffect(() => {
    const timer = setInterval(() => {
      if (!currentPopupProps) return
      const element = document.getElementById(currentPopupProps.id)
      setTargetRect(element ? element.getBoundingClientRect() : undefined)
    }, 250)
    return () => clearInterval(timer)
  }, [currentPopupProps])

  const newShow = !!(currentPopupProps && targetRect)

  useEffect(() => {
    setTimeout(() => setShow(newShow))
  }, [newShow])

  /**
   * actions
   */

  const onHideClick = () => {
    setShow(false)
    setTimeout(() => {
      hideCurrentPopup()
      setTargetRect(undefined)
    }, 500)
  }

  // hide on target click
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!targetRect) return
      if (
        event.clientX < targetRect.x ||
        event.clientX > targetRect.x + targetRect.width ||
        event.clientY < targetRect.y ||
        event.clientY > targetRect.y + targetRect.height
      ) {
        return
      }
      onHideClick()
    }
    if (targetRect) document.addEventListener('click', listener)
    else document.removeEventListener('click', listener)
    return () => document.removeEventListener('click', listener)
  }, [targetRect])

  /**
   * render
   */

  if (!newShow) return <>{children}</>

  const { content, offset } = currentPopupProps

  const targetStyle = {
    height: targetRect.height,
    left: targetRect.x,
    top: targetRect.y,
    width: targetRect.width
  }

  // fix left border
  if (targetStyle.left < 0) {
    targetStyle.width = targetStyle.width + targetStyle.left
    targetStyle.left = 0
  }

  const targetComponent = (
    <div
      className={`${styles.target} ${show ? styles.show : ''}`}
      style={targetStyle}
    >
      <div className={styles.background} />
      <div className={styles.border} />
      <div className={`${styles.preventClick} ${styles.top}`} />
      <div className={`${styles.preventClick} ${styles.right}`} />
      <div className={`${styles.preventClick} ${styles.bottom}`} />
      <div className={`${styles.preventClick} ${styles.left}`} />
    </div>
  )

  const popupComponent = (
    <Popup
      className={`${styles.popup} ${show ? styles.show : ''}`}
      inverted
      offset={offset}
      open
      trigger={targetComponent}
      data-test-id="ark-onboarding-popup" // e2e testing identifier
    >
      {content}
      <ArkSpacer />
      <ArkButton floated='right' onClick={onHideClick} size='tiny'>
        Got it
      </ArkButton>
    </Popup>
  )

  return (
    <>
      {children}
      <div className={styles.container}>
        {popupComponent}
      </div>
    </>
  )
}

export default ArkOnboarding
