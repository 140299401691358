import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import _ from 'lodash'

import { ProjectAdminContext, ServerConfigContext } from 'src/core/providers'
import { Program, Project, UserProject } from 'src/core/models'
import { IProgramAddData, IProgramUpdateData } from 'src/core/models/program'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldOption, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkHint, { HintType, PopupPosition, PopupSize } from 'src/core/components/ArkHint'
import ArkIcon from 'src/core/components/ArkIcon'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkModal from 'src/core/components/ArkModal'
import ArkPreviewCopyView from 'src/core/components/ArkPreviewCopyView'

import ProgramPortForm from './ProgramPortForm'

import { DEFAULT_PICKER_COLOR, HOTLINK_ENABLED, PROGRAM_MANAGER_CUSTOM_SRT_PORT_MODAL_ENABLED } from 'src/constants/config'
import { OBJECT_PROGRAM_NAME } from 'src/constants/strings'

import styles from './ProgramForm.module.css'

const formSchema = yup.object().shape({
  // base fields:
  name: yup.string().required().min(4).max(50).label(OBJECT_PROGRAM_NAME + ' name'),
  shortName: yup.string().required().min(1).max(3).label(OBJECT_PROGRAM_NAME + ' short name'),
  colour: yup.string().required().label('Colour'),
  isAudioOnly: yup.boolean().optional().label('Audio Only'),
  is360Video: yup.boolean().optional().label('360° Video'),
  // srt general:
  // srtLatency: yup.number().min(0).optional().label('SRT Latency').typeError('SRT latency must be a number'),
  // srtMaxBandwidth: yup.number().min(-1).optional().label('SRT Max Bandwidth').typeError('SRT max bandwidth must be a number'),
  // srt input:
  srtInputKeyLength: yup.number().min(0).optional().label('SRT Input Key Length').typeError('SRT input key length must be a number'),
  srtInputPassphraseEnabled: yup.boolean().optional().label('SRT Enable Input Passphrase'),
  srtInputLatency: yup.number().min(0).optional().label('SRT Input Latency').typeError('SRT input latency must be a number'),
  srtInputMaxBandwidth: yup.number().min(-1).optional().label('SRT Input Max Bandwidth').typeError('SRT input max bandwidth must be a number'),
  // srt output:
  srtOutputKeyLength: yup.number().min(0).optional().label('SRT Output Key Length').typeError('SRT output key length must be a number'),
  srtOutputPassphraseEnabled: yup.boolean().optional().label('SRT Enable Output Passphrase'),
  srtOutpuLatency: yup.number().min(0).optional().label('SRT Output Latency').typeError('SRT output latency must be a number'),
  srtOutpuMaxBandwidth: yup.number().min(-1).optional().label('SRT Output Max Bandwidth').typeError('SRT output max bandwidth must be a number'),
  // srt ports:
  srtCustomPortsEnabled: yup.boolean().optional().label('SRT Enable Custom Ports'),
  srtInputPort: yup.number().transform((val, orig) => orig === '' ? undefined : val).min(0).optional().label('SRT Input Port').typeError('SRT input port must be a number'), // NB: convert empty string to undefined - ref: https://github.com/jquense/yup/issues/360#issuecomment-1778836314
  srtOutputPort: yup.number().transform((val, orig) => orig === '' ? undefined : val).min(0).optional().label('SRT Output Port').typeError('SRT output port must be a number'), // NB: convert empty string to undefined - ref: https://github.com/jquense/yup/issues/360#issuecomment-1778836314
  // hotlink:
  hotlinkEnabled: yup.boolean().optional().label('Enable Hotlink'),
  hotlinkProtocols: yup.array().of(yup.string()).optional().label('Hotlink Protocols')
})

export enum ProgramFormMode {
  Add = 'add',
  Edit = 'edit',
}

interface IProps {
  mode: ProgramFormMode
  companyId: number
  project: UserProject | Project
  program?: Program
  onCancel?: Function
  onSave?: Function
  onDelete?: Function // NB: not currently supporting deleting via this form
  onClose?: Function
  insideModal?: boolean // ArkForm prop - enable when showing this form within a modal (so fieldset label bg's match)
}

const ProgramForm = (props: IProps) => {
  const { mode, project, program, onCancel: _onCancel, onSave, onClose: _onClose, insideModal } = props // companyId, onDelete,

  const mounted = useRef(false)

  const { actions: projectAdminActions } = useContext(ProjectAdminContext) // store: projectAdminStore
  const { store: serverConfigStore } = useContext(ServerConfigContext) // actions: serverConfigActions

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSaved, setHasSaved] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>(undefined)

  const [showSRTPortModal, setShowSRTPortModal] = useState<boolean>(false)
  const [srtPortModalInOutType, setSRTPortModalInOutType] = useState<'input' | 'output'>('input')

  // form field values (saved & current)
  const srtInputData = program?.getSRTInputData()
  const srtOutputData = program?.getSRTOutputData()
  const [savedValues, setSavedValues] = useState({
    // base fields:
    name: program?.name ?? '',
    shortName: program?.shortName ?? undefined,
    colour: program?.colour ?? DEFAULT_PICKER_COLOR, // undefined, // NB: default to the default picker colour if not set (so changes are detected inc. when reverted)
    isAudioOnly: program?.isAudioOnly ?? undefined,
    is360Video: program?.is360Video ?? undefined,
    // srt general (for API versions BEFORE v0.3.28):
    // TODO: DEPRECIATE `srtLatency` & `srtMaxBandwidth` fields (once all API servers are running v0.3.28+)
    // NB: the api returns these with both the input & output fields BUT both are the same value (BEFORE API v0.3.28) (& we submit as a single value)
    // NB: ..so we just grab the value off one of the srt input/output fields (shouldn't matter which)
    // srtLatency: (srtInputData?.extraFields?.latency ? srtInputData?.extraFields?.latency as number : undefined),
    // srtMaxBandwidth: (srtInputData?.extraFields?.max_bandwidth ? srtInputData?.extraFields?.max_bandwidth as number : undefined),
    // srt input:
    srtInputPassphraseEnabled: (srtInputData?.extraFields?.enable_passphrase === true || (mode === ProgramFormMode.Add)),
    srtInputKeyLength: (srtInputData?.extraFields?.key_length !== undefined ? srtInputData?.extraFields?.key_length as number : 0), // TODO: default to 0 or undefined when not set?
    srtInputLatency: (srtInputData?.extraFields?.latency ? srtInputData?.extraFields?.latency as number : undefined), // ADDED: API v0.3.28+
    srtInputMaxBandwidth: (srtInputData?.extraFields?.max_bandwidth ? srtInputData?.extraFields?.max_bandwidth as number : undefined), // ADDED: API v0.3.28+
    // srt output:
    srtOutputPassphraseEnabled: (srtOutputData?.extraFields?.enable_passphrase === true || (mode === ProgramFormMode.Add)),
    srtOutputKeyLength: (srtOutputData?.extraFields?.key_length !== undefined ? srtOutputData?.extraFields?.key_length as number : 0), // TODO: default to 0 or undefined when not set?
    srtOutputLatency: (srtOutputData?.extraFields?.latency ? srtOutputData?.extraFields?.latency as number : undefined), // ADDED: API v0.3.28+
    srtOutputMaxBandwidth: (srtOutputData?.extraFields?.max_bandwidth ? srtOutputData?.extraFields?.max_bandwidth as number : undefined), // ADDED: API v0.3.28+
    // srt ports:
    srtCustomPortsEnabled: (program?.srtCustomPortsEnabled ?? false),
    srtInputPort: (program?.srtCustomPortsEnabled === true ? program?.srtInputPort : undefined), // NB: if custom ports are disabled, don't load the previous port value (as it may have been re-assigned to another program)
    srtOutputPort: (program?.srtCustomPortsEnabled === true ? program?.srtOutputPort : undefined), // NB: if custom ports are disabled, don't load the previous port value (as it may have been re-assigned to another program)
    // hotlink:
    hotlinkEnabled: program?.hotlinkEnabled ?? undefined,
    hotlinkProtocols: program?.hotlinkProtocols ?? undefined // NB: always load the hotlink enabled protocols even if the program wide `hotlinkEnabled` flag is disabled (the api stores the values, so we want to maintain their state for when the user re-enables hotlinking)
  })
  const [formValues, setFormValues] = useState(savedValues)
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])
  // note which fields are numeric, so they can be parsed as numbers when onValueChange is called
  const numericFields: Array<string> = ['srtInputKeyLength', 'srtOutputKeyLength', 'srtInputPort', 'srtOutputPort', 'srtInputLatency', 'srtInputMaxBandwidth', 'srtOutputLatency', 'srtOutputMaxBandwidth'] // 'srtLatency', 'srtMaxBandwidth'

  // -------

  const load = async () => {
    console.log('ProgramForm - load - program?.hotlinkProtocols:', program?.hotlinkProtocols)

    // TODO: do we need to do this, when we already have the `Program.hotlinkProtocols` array??? <<<<
    // UPDATE:
    // UPDATE: trying skipping this & relying on the `hotlinkProtocols` array from the program instance instead (while refactoring this form to add 'has changes' support)
    // UPDATE:
    /*
    // loop through all protocols this project allows & see which (if any) currently have hotlink auth enabled (regardless of the current overall hotlinkEnabled value)
    // NB: we loop through all project protocols, even if some maynot support hotlinking, as those won't be able to return an enabled value so no harm in doing so (& less work than filtering them out here)
    const enabledProjectProtocols = project.transcoderSettings?.protocols ?? []
    console.log('ProgramForm - load - enabledProjectProtocols: ', enabledProjectProtocols)
    const hotlinkProtocols: Array<string> = enabledProjectProtocols.filter((protocol) => {
      const isHotlinkEnabledForProtocol = program?.isHotlinkEnabledForProtocol(protocol) ?? false // (props.mode === ProgramFormMode.Add) // NB: defaults off when adding a new program for early usage until its tested in more detail, otherwise off if its not set in an existing program
      if (isHotlinkEnabledForProtocol) return protocol
      return null
    })
    console.log('ProgramForm - load - hotlinkProtocols: ', hotlinkProtocols)
    setHotlinkProtocols(hotlinkProtocols)
    */
  }

  // -------

  useEffect(() => {
    // mount
    console.log('ProgramForm - mount')
    mounted.current = true
    load()
    // unmount
    return () => {
      console.log('ProgramForm - unmount')
      mounted.current = false
    }
  }, [])

  // -------

  const getFormValueChanges = () => {
    const _changes: Array<string> = []
    if (formValues) {
      for (const fieldName of Object.keys(formValues)) {
        const oldValue = savedValues !== undefined ? (savedValues as any)[fieldName] : undefined
        const newValue = (formValues as any)[fieldName]
        // console.log('VideoEngineForm - getFormValueChanges - fieldName:', fieldName, ' oldValue:', oldValue, ` (${typeof oldValue})`, ' newValue:', newValue, ` (${typeof newValue})`)
        if (!_.isEqual(oldValue, newValue)) {
          if (
            // NB: added extra check if comparing an empty object/array to the an undefined old value
            !(oldValue === undefined && newValue !== undefined && typeof newValue === 'object' && newValue.length === 0) &&
            // NB: added extra check if comparing an empty string to an undefined old value
            !(oldValue === undefined && newValue !== undefined && typeof newValue === 'string' && newValue === '')
          ) {
            _changes.push(fieldName)
          }
        }
      }
    }
    // console.log('ProgramForm - getFormValueChanges - _changes:', _changes)
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    // TESTING: special handling for hotlink protocols (as they're stored as an array of strings & not at the root/base level like normal fields)
    if (valueKey.startsWith('hotlinkEnabled_')) {
      const protocol = valueKey.substring('hotlinkEnabled_'.length)
      const oldValue = savedValues.hotlinkProtocols?.includes(protocol) ?? false
      const currentValue = formValues.hotlinkProtocols?.includes(protocol) ?? false
      // console.log('ProgramForm - hasChanges - valueKey:', valueKey, ' protocol:', protocol, ' oldValue:', oldValue, ` (${typeof oldValue})`, ' currentValue:', currentValue, ` (${typeof currentValue})`, ' === ', (oldValue !== currentValue))
      return oldValue !== currentValue
    }
    return (changes && changes.includes(valueKey))
  }

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = getFormValueChanges()
    setChanges(_changes)
  }, [formValues])

  // -------

  const hintElement = (hintType: HintType, iconSize: number, popupSize: PopupSize, title: string, message: string | React.ReactNode, popupPosition?: PopupPosition, disabled?: boolean, otherProps?: {[key: string] : any}) => {
    return (
      <ArkHint
        className={styles.programHint}
        type={hintType}
        iconSize={iconSize}
        popupSize={popupSize}
        title={title}
        message={message}
        popupPosition={popupPosition}
        disabled={disabled}
        // open={true} // DEBUG ONLY
        {...otherProps}
      ></ArkHint>
    )
  }

  const infoHintElement = (title: string, message: string | React.ReactNode, disabled?: boolean, otherProps?: {[key: string] : any}) => {
    return hintElement(
      'info-circle',
      22,
      'small',
      title,
      message,
      undefined, // 'bottom left', // NB: leave position undefined for auto positioning to kick in
      disabled,
      otherProps
    )
  }

  // -------

  const addProgram = async () => {
    if (isSubmitting) return
    const { companyId, project } = props
    setIsSubmitting(true)
    if (error) setError(undefined)
    try {
      // base fields:
      const programData: IProgramAddData = {
        name: formValues.name
      }
      programData.shortName = formValues.shortName
      programData.colour = formValues.colour
      programData.isAudioOnly = formValues.isAudioOnly
      programData.is360Video = formValues.is360Video
      // srt general:
      // programData.srtLatency = formValues.srtLatency
      // programData.srtMaxBandwidth = formValues.srtMaxBandwidth
      // srt input:
      programData.srtInputKeyLength = formValues.srtInputKeyLength
      programData.srtInputPassphraseEnabled = formValues.srtInputPassphraseEnabled
      programData.srtInputLatency = formValues.srtInputLatency
      programData.srtInputMaxBandwidth = formValues.srtInputMaxBandwidth
      // srt output:
      programData.srtOutputKeyLength = formValues.srtOutputKeyLength
      programData.srtOutputPassphraseEnabled = formValues.srtOutputPassphraseEnabled
      programData.srtOutputLatency = formValues.srtOutputLatency
      programData.srtOutputMaxBandwidth = formValues.srtOutputMaxBandwidth
      // srt ports:
      programData.srtCustomPortsEnabled = formValues.srtCustomPortsEnabled
      programData.srtInputPort = formValues.srtInputPort
      programData.srtOutputPort = formValues.srtOutputPort
      // hotlink:
      programData.hotlinkEnabled = HOTLINK_ENABLED ? (formValues.hotlinkEnabled ?? false) : false
      programData.hotlinkProtocols = formValues.hotlinkProtocols
      console.log('ProgramForm - addProgram - programData: ', programData)

      const savedProgram = await projectAdminActions.addCompanyProjectProgram(
        companyId,
        project.id,
        programData
      )
      console.log('ProgramForm - addProject - savedProgram: ', savedProgram)
      if (savedProgram) {
        // NB: the called code triggers a full UserProvider `reloadUserData` which triggers various UI to update with the latest data once it completes
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
          setSavedValues(formValues) // update the saved values to match the current form values
        }
        if (onSave) onSave()
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          setError(Error('A problem occurred adding the program, please try again.'))
        }
      }
    } catch (_error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(_error)
      }
    }
  }

  const updateProgram = async () => {
    const { companyId, project, program } = props
    if (isSubmitting || !program) return
    if (changes.length === 0) {
      console.log('ProgramForm - updateProgram - no changes to save')
      return // no changes to save (NB: currently relying on the submit button being disabled when there are no changes, this is just a backup check, so should be no need to throw an error/warning here)
    }
    setIsSubmitting(true)
    if (error) setError(undefined)
    try {
      console.log('ProgramForm - updateProgram - changes:', changes, ' formValues:', formValues)
      const programData: IProgramUpdateData = {}
      // base fields:
      if (changes.includes('name')) programData.name = formValues.name
      if (changes.includes('shortName')) programData.shortName = formValues.shortName
      if (changes.includes('colour')) programData.colour = formValues.colour
      if (changes.includes('isAudioOnly')) programData.isAudioOnly = formValues.isAudioOnly
      if (changes.includes('is360Video')) programData.is360Video = formValues.is360Video
      // srt general:
      // if (changes.includes('srtLatency')) programData.srtLatency = formValues.srtLatency
      // if (changes.includes('srtMaxBandwidth')) programData.srtMaxBandwidth = formValues.srtMaxBandwidth
      // srt input:
      if (changes.includes('srtInputKeyLength')) programData.srtInputKeyLength = formValues.srtInputKeyLength
      if (changes.includes('srtInputPassphraseEnabled')) programData.srtInputPassphraseEnabled = formValues.srtInputPassphraseEnabled
      if (changes.includes('srtInputLatency')) programData.srtInputLatency = formValues.srtInputLatency
      if (changes.includes('srtInputMaxBandwidth')) programData.srtInputMaxBandwidth = formValues.srtInputMaxBandwidth
      // srt output:
      if (changes.includes('srtOutputKeyLength')) programData.srtOutputKeyLength = formValues.srtOutputKeyLength
      if (changes.includes('srtOutputPassphraseEnabled')) programData.srtOutputPassphraseEnabled = formValues.srtOutputPassphraseEnabled
      if (changes.includes('srtOutputLatency')) programData.srtOutputLatency = formValues.srtOutputLatency
      if (changes.includes('srtOutputMaxBandwidth')) programData.srtOutputMaxBandwidth = formValues.srtOutputMaxBandwidth
      // srt ports (NB: include the enable flag if either the in/out port has changed):
      // TODO: if the enabled flag is false but the ports have changed, should we catch this (before here) & flag with an error/warning, as the ports won't be saved without the enable flag api side
      if (changes.includes('srtCustomPortsEnabled') || changes.includes('srtInputPort') || changes.includes('srtOutputPort')) programData.srtCustomPortsEnabled = formValues.srtCustomPortsEnabled
      if (changes.includes('srtInputPort')) programData.srtInputPort = formValues.srtInputPort
      if (changes.includes('srtOutputPort')) programData.srtOutputPort = formValues.srtOutputPort
      // hotlink:
      if (changes.includes('hotlinkEnabled')) programData.hotlinkEnabled = HOTLINK_ENABLED ? (formValues.hotlinkEnabled ?? false) : HOTLINK_ENABLED
      if (changes.includes('hotlinkProtocols')) programData.hotlinkProtocols = formValues.hotlinkProtocols
      console.log('ProgramForm - updateProgram - programData: ', programData)

      const savedProgram = await projectAdminActions.updateCompanyProjectProgram(companyId, project.id, program.id, programData)
      console.log('ProgramForm - updateProgram - savedProgram: ', savedProgram)
      if (savedProgram) {
        // NB: the called code triggers a full UserProvider `reloadUserData` which triggers various UI to update with the latest data once it completes
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
          setSavedValues(formValues) // update the saved values to match the current form values
        }
        if (onSave) onSave()
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          setError(Error('A problem occurred updating the program, please try again.'))
        }
      }
    } catch (_error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(_error)
      }
    }
  }

  // -------

  // NB: now using the `formValues` state object for all field values (updated via `onFormValueChanged`)
  // NB: ..it also pre-parses numeric fields as numbers instead of strings so we don't need to do that manually with the supplied `fieldValues` object passed in
  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('ProgramForm - onFormSubmit - fieldValues: ', fieldValues, ' formValues: ', formValues)
    // halt & warn if hotlink is enabled but no protocols have been enabled for it (the api will throw an error otherwise)
    if (HOTLINK_ENABLED && formValues.hotlinkEnabled && (!formValues.hotlinkProtocols || formValues.hotlinkProtocols.length === 0)) {
      setError(new Error('You have selected Hotlink Protection but have not enabled any protocols. Either enable at least one protocol or deselect Hotlink Protection.'))
      return
    }
    // halt & warn if custom ports are enabled but the input or output port are not set, or are the same
    if (formValues.srtCustomPortsEnabled && (!formValues.srtInputPort || !formValues.srtOutputPort)) {
      setError(new Error('You have enabled custom SRT ports but have not set both the input and output ports. Please set both ports or disable custom ports.'))
      return
    } else if (formValues.srtCustomPortsEnabled && (formValues.srtInputPort === formValues.srtOutputPort)) {
      setError(new Error('Input and output SRT ports must be different.'))
      return
    }
    if (mode === ProgramFormMode.Add) {
      addProgram()
    } else if (mode === ProgramFormMode.Edit) {
      updateProgram()
    }
  }

  const onFormValueChanged = async (fieldKey: string, fieldValue: any, _oldFieldValue: any) => {
    // console.log('ProgramForm - onFormValueChanged - fieldKey: ', fieldKey, ' fieldValue: ', fieldValue, ' typeof: ', typeof fieldValue, ' _oldFieldValue: ', _oldFieldValue)
    // TESTING: special handling for hotlink protocols (as they're stored as an array of strings)
    if (fieldKey.startsWith('hotlinkEnabled_')) {
      // parse the protocol from the field key
      const protocol = fieldKey.substring('hotlinkEnabled_'.length)
      // console.log('ProgramForm - onFormValueChanged - protocol:', protocol) // , ' hotlinkProtocols(RAW-BEFORE):', formValues.hotlinkProtocols)
      const _hotlinkProtocols = [...(formValues.hotlinkProtocols ?? [])] // copy the array so we can modify it
      // console.log('ProgramForm - onFormValueChanged - _hotlinkProtocols(BEFORE):', _hotlinkProtocols)
      let _hotlinkProtocolsChanged = false
      if (fieldValue && !_hotlinkProtocols.includes(protocol)) {
        _hotlinkProtocols.push(protocol)
        _hotlinkProtocolsChanged = true
      } else if (!fieldValue && _hotlinkProtocols.includes(protocol)) {
        const index = _hotlinkProtocols.indexOf(protocol)
        if (index >= 0) {
          _hotlinkProtocols.splice(index, 1)
          _hotlinkProtocolsChanged = true
        }
      }
      // console.log('ProgramForm - onFormValueChanged - _hotlinkProtocolsChanged:', _hotlinkProtocolsChanged)
      // console.log('ProgramForm - onFormValueChanged - _hotlinkProtocols(AFTER):', _hotlinkProtocols)
      if (_hotlinkProtocolsChanged) {
        setFormValues({
          ...formValues,
          hotlinkProtocols: _hotlinkProtocols
        })
      }
    } else if (numericFields.includes(fieldKey) && fieldValue !== '') { // TESTING: numeric field handling (if the field isn't empty)
      let fieldValueInt = parseInt(fieldValue)
      if (isNaN(fieldValueInt)) {
        fieldValueInt = 0 // NB: non numeric values will be set to 0 for now (TODO: or should they be set as empty string like the default empty form field value currently is?)
      }
      // console.log('ProgramForm - onFormValueChanged - numeric field - fieldKey:', fieldKey, ' fieldValue:', fieldValue, ' fieldValueInt:', fieldValueInt)
      setFormValues({
        ...formValues,
        [fieldKey]: fieldValueInt
      })
    } else {
      setFormValues({
        ...formValues,
        [fieldKey]: fieldValue
      })
    }
  }

  const shouldEnterKeySubmit = (): boolean => {
    // console.log('ProgramForm - shouldEnterKeySubmit - showSRTPortModal:', showSRTPortModal)
    // ignore enter key submit when the SRT port modal is open (otherwise this main form can submit when the port sub-modal form is open & has focus)
    return !showSRTPortModal
  }

  const onCancel = () => {
    if (_onCancel) _onCancel()
  }

  const onClose = () => {
    if (_onClose) _onClose()
  }

  // -------

  const showSRTPortEditModal = (portType: 'input' | 'output') => {
    if (!PROGRAM_MANAGER_CUSTOM_SRT_PORT_MODAL_ENABLED) return // NB: only show the custom SRT port modal if enabled (instead we fallback to direct editing without the modal while its still WIP)
    setShowSRTPortModal(true)
    setSRTPortModalInOutType(portType)
  }

  // const hideSRTPortEditModal = () => {
  //   setShowSRTPortModal(false)
  // }

  const didHideSRTPortEditModal = () => {
    setShowSRTPortModal(false)
  }

  const renderSRTPortForm = () => {
    return (<ProgramPortForm
      companyId={props.companyId}
      projectId={project.id}
      programId={program?.id}
      videoEngineId={project.videoEngine?.id}
      portType={srtPortModalInOutType}
      port={formValues[srtPortModalInOutType === 'input' ? 'srtInputPort' : 'srtOutputPort']}
      onCancel={didHideSRTPortEditModal}
      onApply={(portType, port) => {
        console.log('ProgramForm - renderSRTPortForm - onApply - portType:', portType, ' port:', port)
        if (portType === 'input') {
          setFormValues({
            ...formValues,
            srtInputPort: port
          })
        } else if (portType === 'output') {
          setFormValues({
            ...formValues,
            srtOutputPort: port
          })
        }
        didHideSRTPortEditModal()
      }}
    />)
  }

  const renderSRTPortModal = () => {
    return (
      <ArkModal
        open={showSRTPortModal}
        size='tiny'
        className={styles.srtPortModal}
        onClose={() => didHideSRTPortEditModal()}
      >
        {renderSRTPortForm()}
      </ArkModal>
    )
  }

  // -------

  // const onSrtInputPortClick = () => {
  //   showSRTPortEditModal('input')
  // }

  // const onSrtOutputPortClick = () => {
  //   showSRTPortEditModal('output')
  // }

  // -------

  const serverConfig = serverConfigStore.serverConfig
  const programDefaults = serverConfig?.programDefaults
  // console.log('ProgramForm - render - programDefaults: ', programDefaults)
  const defaultSrtKeyLength: number | undefined = programDefaults?.srtKeyLength
  const defaultSrtLatency: number | undefined = programDefaults?.srtLatency // NB: default value is currently shared for in/out
  const defaultSrtMaxBandwidth: number | undefined = programDefaults?.srtMaxBandwidth // NB: default value is currently shared for in/out
  // console.log('ProgramForm - render - defaultSrt... KeyLength: ', defaultSrtKeyLength, 'Latency: ', defaultSrtLatency, 'MaxBandwidth: ', defaultSrtMaxBandwidth)

  // check if the 'audio only' flag is enabled for this program but this project doesn't have an audio only protocol enabled (currently only 'icecast')
  const showAudioOnlyNoProtocolWarning = (formValues.isAudioOnly && project.transcoderSettings !== undefined && !project.transcoderSettings.hasAudioOnlyProtocolsEnabled())
  // check if both the 'audio only' & '360 video' flags are enabled & warn if so
  const showAudioOnlyAnd360VideoWarning = (formValues.isAudioOnly && formValues.is360Video)
  // console.log('ProgramForm - render - showAudioOnlyNoProtocolWarning:', showAudioOnlyNoProtocolWarning, ' showAudioOnlyAnd360VideoWarning:', showAudioOnlyAnd360VideoWarning, ' project.transcoderSettings:', project.transcoderSettings, ' hasAudioOnlyProtocolsEnabled:', project.transcoderSettings?.hasAudioOnlyProtocolsEnabled())

  // disable srt output options when the audio only flag/mode is enabled
  const disableSRTOutput = formValues.isAudioOnly

  const formFields: Array<ArkFormField> = []

  formFields.push(
    {
      type: ArkFormFieldType.Group,
      key: 'detailsAndFlagsGroup',
      fields: [
        {
          type: ArkFormFieldType.Fieldset,
          key: 'detailsFieldset',
          label: OBJECT_PROGRAM_NAME + ' details',
          className: styles.detailsFieldset,
          fields: [
            {
              type: ArkFormFieldType.Group,
              key: 'detailsGroup',
              slimline: true,
              fields: [
                {
                  type: ArkFormFieldType.Colour,
                  key: 'colour',
                  label: 'Colour',
                  required: false,
                  defaultValue: savedValues.colour, // program?.colour ?? DEFAULT_PICKER_COLOR, // undefined,
                  fieldProps: { width: 4 },
                  className: hasChanges('colour') ? styles.hasChanged : undefined
                  // hint: this.infoHintElement('TESTING HINT', 'BLAH...'),
                  // wrapperProps: { style: { marginRight: 15 } }
                },
                {
                  type: ArkFormFieldType.Input,
                  key: 'name',
                  label: OBJECT_PROGRAM_NAME + ' name',
                  required: true,
                  defaultValue: savedValues.name, // program?.name ?? undefined,
                  fieldProps: { width: 10 },
                  className: hasChanges('name') ? styles.hasChanged : undefined
                  // hint: this.infoHintElement('TESTING HINT', 'BLAH...'),
                  // wrapperProps: { style: { width: '250px', marginRight: 10 } },
                  // description: 'Test description'
                },
                {
                  type: ArkFormFieldType.Input,
                  key: 'shortName',
                  label: 'Short name',
                  required: true,
                  defaultValue: savedValues.shortName, // program?.shortName ?? undefined,
                  fieldProps: { maxLength: 3 /*, width: 2 */ },
                  className: hasChanges('shortName') ? styles.hasChanged : undefined,
                  hint: infoHintElement(
                    'Short Name',
                    (<>
                      <p>A 3 character alias to assign to the program.</p>
                    </>)
                  )
                  // wrapperProps: { style: { width: '100px' } }
                }
              ]
              // fieldProps: { widths: 'equal' }
            }
          ],
          fieldProps: { style: { flexGrow: 1, flexBasis: '67%', minWidth: '200px' } },
          collapsible: false,
          collapsed: false
        },
        {
          type: ArkFormFieldType.Fieldset,
          key: 'flagsFieldset',
          label: OBJECT_PROGRAM_NAME + ' flags',
          className: styles.flagsFieldset,
          fields: [
            ...(showAudioOnlyNoProtocolWarning
              ? [{
                type: ArkFormFieldType.Field,
                key: 'flagsWarningAudioOnlyNoProtocol',
                wrapperProps: { className: styles.flagsWarningField },
                content: (
                  (<div className={styles.flagsWarningNotice}>
                    <div className={styles.warningIcon}><ArkIcon name='warning' size={22} color={'#f3be0e'} /></div>
                    <div className={styles.warningText}>
                      Audio only program without audio only protocol set.
                    </div>
                  </div>))
              }]
              : []),
            ...(showAudioOnlyAnd360VideoWarning
              ? [{
                type: ArkFormFieldType.Field,
                key: 'flagsWarningAudioOnlyAnd360Video',
                wrapperProps: { className: styles.flagsWarningField },
                content: (
                  (<div className={styles.flagsWarningNotice}>
                    <div className={styles.warningIcon}><ArkIcon name='warning' size={22} color={'#f3be0e'} /></div>
                    <div className={styles.warningText}>
                      Audio only will override 360 video and no video will be shown.
                    </div>
                  </div>))
              }]
              : []),
            {
              type: ArkFormFieldType.Radio,
              key: 'isAudioOnly',
              label: (<label className={styles.flagLabel}><div className={styles.flagTitle}><span>Audio Only</span></div></label>),
              required: false,
              toggle: true,
              defaultValue: savedValues.isAudioOnly, // program?.isAudioOnly,
              hint: infoHintElement('Audio Only Program', 'This will flag the program as audio only. Make sure your input type is an audio based protocol like IceCast.'),
              hintInline: true,
              hintProps: { className: styles.flagHint },
              wrapperProps: { className: styles.flagWrapper },
              className: hasChanges('isAudioOnly') ? styles.hasChanged : undefined
            },
            {
              type: ArkFormFieldType.Radio,
              key: 'is360Video',
              label: (<label className={styles.flagLabel}><div className={styles.flagTitle}><span>360° Video</span></div></label>),
              required: false,
              toggle: true,
              defaultValue: savedValues.is360Video, // program?.is360Video,
              hint: infoHintElement('360° Video (⚠️ BETA!)', '\nThis will flag the program as 360° video and will set iOS players to decode video into an interactive 360 degree panning video.'),
              hintInline: true,
              hintProps: { className: styles.flagHint },
              wrapperProps: { className: styles.flagWrapper },
              className: hasChanges('is360Video') ? styles.hasChanged : undefined
            }
          ],
          fieldProps: { style: { flexGrow: 1, flexBasis: '33%', minWidth: '200px' } },
          collapsible: false,
          collapsed: false
        }
      ],
      fieldProps: { widths: 'equal', style: { justifyContent: 'space-between', gap: '10px' } }
    }
  )

  const _hotlinkEnabled = formValues.hotlinkEnabled
  const _hotlinkProtocols = formValues.hotlinkProtocols ?? []

  // load the array of all protocols this project currently allows/supports, & then filter to get a list of all that also support hotlink auth
  const enabledProjectProtocols = project.transcoderSettings?.protocols ?? [] // all protocols this project allows/supports
  const supportedAuthProtocols = serverConfig?.supportedAuthProtocols ?? [] // the list of all (server wide) protocols that support hotlink auth
  const enabledAuthProjectProtocols = enabledProjectProtocols.filter((protocol) => supportedAuthProtocols.includes(protocol))
  // console.log('ProgramForm - render - supportedAuthProtocols:', supportedAuthProtocols, ' enabledAuthProjectProtocols(BEFORE SORT):', enabledAuthProjectProtocols)

  // TESTING: move/force the SLDP protocol to the start of the enabledAuthProjectProtocols array & SRT to the bottom
  if (enabledAuthProjectProtocols.includes('SLDP') && enabledAuthProjectProtocols.indexOf('SLDP') !== 0) {
    enabledAuthProjectProtocols.splice(enabledAuthProjectProtocols.indexOf('SLDP'), 1) // remove the SLDP element
    enabledAuthProjectProtocols.unshift('SLDP') // insert the SLDP element to the front of the array
  }
  if (enabledAuthProjectProtocols.includes('SRT')) {
    enabledAuthProjectProtocols.splice(enabledAuthProjectProtocols.indexOf('SRT'), 1) // remove the SRT element
    enabledAuthProjectProtocols.push('SRT') // insert the SRT element to the end of the array
  }
  // console.log('ProgramForm - render - enabledAuthProjectProtocols(AFTER SORT):', enabledAuthProjectProtocols)

  if (HOTLINK_ENABLED) {
    formFields.push({
      type: ArkFormFieldType.Fieldset,
      key: 'hotlinkFieldset',
      label: <div className={styles.hotlinkTitle}><ArkIcon name='company' size={24} className={styles.hotlinkTitleIcon} /><span>Hotlink Protection</span></div>,
      className: styles.hotlinkFieldset,
      fields: [
        {
          type: ArkFormFieldType.Checkbox,
          key: 'hotlinkEnabled',
          label: 'Enable Hotlink Protection',
          required: false,
          defaultValue: savedValues.hotlinkEnabled, // _hotlinkEnabled,
          // fieldProps: { style: { marginTop: 20, marginBottom: 8, marginLeft: 0, border: '1px solid red', flexGrow: 0 } },
          className: hasChanges('hotlinkEnabled') ? styles.hasChanged : undefined,
          hint: infoHintElement(
            'Enable Hotlink Protection',
            (<p>Hotlink Protections secures outgoing program streams so that they can only be played using dedicated RePro viewing apps.</p>)
          ),
          hintInline: true // collapse the field so it doesn't fill the available space (makes the hint but up close to it instead of the far right)
          // hintProps: { style: { marginTop: 20, marginBottom: 8, marginLeft: 0 } }
          // wrapperProps: { style: { marginTop: 20, marginBottom: 8, marginLeft: 0, border: '1px solid red' } }
        },
        {
          type: ArkFormFieldType.Fieldset,
          key: 'hotlinkProtocolsFieldset',
          label: '',
          fields: [
            // loop through all protocols that support hotlink auth that are enabled for this project & add a checkbox for each
            // so the user can then control which to enable hotlinking on for this program
            ...enabledAuthProjectProtocols.map((protocol) => {
              return {
                type: ArkFormFieldType.Radio,
                key: 'hotlinkEnabled_' + protocol,
                label: (<label className={styles.hotlinkProtocolLabel}><div className={styles.hotlinkProtocolTitle}><ArkIcon name='company' size={20} className={styles.hotlinkProtocolTitleIcon} /><span>{protocol}</span></div></label>), // {hasChanges('hotlinkEnabled_' + protocol) ? ' (CHANGED)' : ''}
                className: hasChanges('hotlinkEnabled_' + protocol) ? styles.hasChanged : undefined,
                required: false,
                // options: [{ key: 'member', text: '', value: true }] as unknown as Array<ArkFormFieldOption>,
                toggle: true,
                // defaultValue: hotlinkProtocols.includes(protocol),
                value: _hotlinkProtocols.includes(protocol),
                disabled: !_hotlinkEnabled,
                // fieldProps: { style: { minWidth: 85 } }, // set a min width for each hotlink protocol option so the hint icons for each line up
                hint: infoHintElement(
                  protocol + ' Hotlink Protection',
                  (<>
                    {(protocol === 'SRT' && (
                      <>
                        <p>SRT Hotlink protection, when on, will disable the current program output passphrase.</p>
                        <p>Turn off SRT Hotlink Protection in case hardware decoders or software fail to access the program SRT output.</p>
                      </>)
                    )}
                    {(protocol === 'SLDP' && (
                      <>
                        <p>SLDP Hotlink Protection protects all standard ABR and Passthrough streams on web and iOS.</p>
                      </>)
                    )}
                    {/* TOOD: hints for other protocols (maybe more a default fallback for any we don't specially list above) */}
                  </>)
                  ,
                  (!_hotlinkEnabled)
                ),
                hintInline: true // collapse the field so it doesn't fill the available space (makes the hint but up close to it instead of the far right)
                // hintProps: { style: { marginTop: 20, marginBottom: 8, marginLeft: 0 } }
              }
            })
          ],
          collapsible: false,
          collapsed: false,
          bordered: false,
          fieldProps: { style: { margin: 0, padding: '0px 0px 0px 20px' } }
        },
        ...(_hotlinkEnabled && _hotlinkProtocols.includes('SRT')
          ? [
            {
              type: ArkFormFieldType.Field,
              key: 'hotlinkProtocolsSRTNotice',
              content: (
                (<p className={styles.hotlinkProtocolsSRTNotice}>
                  Warning: when SRT has hotlink protection applied it will have encryption turned off and will likely make using decoding hardware become impossible.
                </p>)
              )
            }
          ]
          : [])
      ]
    })
  }

  // the server only accepts/uses specific key length values (TODO: move this to a server side config & power it off that instead of hard-coding here?)
  const srtKeyLengthOptions: Array<ArkFormFieldOption> = []
  srtKeyLengthOptions.push({ key: 'default', text: 'default' + (defaultSrtKeyLength ? ' (' + defaultSrtKeyLength + ')' : ''), value: 0 })
  srtKeyLengthOptions.push({ key: '16', text: '16', value: 16 })
  srtKeyLengthOptions.push({ key: '24', text: '24', value: 24 })
  srtKeyLengthOptions.push({ key: '32', text: '32', value: 32 })

  // NB: srt protocol values are now nested within other input/output specific protocol vars with a helper to access them, so we preload them here for easy reference below
  const _srtInputKeyLength = srtInputData?.extraFields?.key_length ? srtInputData?.extraFields?.key_length as number : 0 // NB: was: `(program?.srtInputKeyLength ? program.srtInputKeyLength : 0)`
  const _srtInputPassphraseEnabled = srtInputData?.extraFields?.enable_passphrase === true
  const _srtInputPassphrase = srtInputData?.pass
  const _srtOutputKeyLength = srtOutputData?.extraFields?.key_length ? srtOutputData?.extraFields?.key_length as number : 0 // NB: was: `(program?.srtOutputKeyLength ? program.srtOutputKeyLength : 0),`
  const _srtOutputPassphraseEnabled = srtOutputData?.extraFields?.enable_passphrase === true
  const _srtOutputPassphrase = srtOutputData?.pass

  const _showCustomPortInOutFields = formValues.srtCustomPortsEnabled || formValues.srtInputPort !== undefined || formValues.srtOutputPort !== undefined

  formFields.push(
    {
      type: ArkFormFieldType.Group,
      key: 'srtOptionsGroup',
      fields: [
        {
          type: ArkFormFieldType.Fieldset,
          key: 'srtInputOptionsFieldset',
          label: 'SRT Input Options',
          className: styles.srtInputOptions,
          fields: [
            {
              type: ArkFormFieldType.Checkbox,
              key: 'srtInputPassphraseEnabled',
              label: 'Enable Input Passphrase',
              className: styles.srtInputPassphraseEnable + (hasChanges('srtInputPassphraseEnabled') ? ' ' + styles.hasChanged : ''),
              required: false,
              defaultValue: savedValues.srtInputPassphraseEnabled,
              // fieldProps: { style: { marginTop: 10, marginBottom: 8, marginLeft: 0 } },
              hint: infoHintElement(
                'SRT Input Options',
                (<>
                  <p>Control whether the SRT input requires an encryption passphrase and the length of that passphrase.</p>
                  <p>Enabling will generate a new passphrase of the selected length.</p>
                  <p>Disabling is less secure and means anyone could input video if they know the URL.</p>
                </>)
              ),
              hintProps: { style: { marginTop: 10, marginBottom: 8, marginLeft: 0 } }
            },
            {
              type: ArkFormFieldType.Dropdown,
              key: 'srtInputKeyLength',
              label: 'SRT Input Key Length' /* (16, 24 or 32) */,
              className: hasChanges('srtInputKeyLength') ? styles.hasChanged : undefined,
              required: false,
              defaultValue: _srtInputKeyLength, // NB: was: (program?.srtInputKeyLength ? program.srtInputKeyLength : 0),
              options: srtKeyLengthOptions,
              disabled: !formValues.srtInputPassphraseEnabled,
              hint: infoHintElement(
                'Input SRT Key Length',
                (<>
                  <p>Length of the Input SRT Passphrase.</p>
                </>),
                !formValues.srtInputPassphraseEnabled
              ),
              wrapperProps: { style: { marginBottom: '8px' } }, // NB: current default vertical spacing/margin within a fieldset (but no group?) seems to be 0, temp forcing to add a little space between feeds
              fieldProps: { scrolling: true } // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
            },
            // {
            //   type: ArkFormFieldType.Input,
            //   key: 'inputSrtPassphrase',
            //   label: 'Input SRT passphrase',
            //   required: false,
            //   disabled: true,
            //   defaultValue: (program?.inputSrtPassphrase ? '' + program.inputSrtPassphrase : undefined),
            //   hint: this.infoHintElement('TESTING HINT', 'BLAH...')
            // },
            {
              type: ArkFormFieldType.Field,
              key: 'inputSrtPassphraseView',
              // label: 'SRT Input Passphrase',
              content: (
                (
                  program !== undefined
                    ? <>
                      <label>SRT Input Passphrase</label>
                      <ArkPreviewCopyView title='SRT Input Passphrase' value={_srtInputPassphraseEnabled ? _srtInputPassphrase : undefined} compactPopup />
                      {(formValues.srtInputPassphraseEnabled && !_srtInputPassphraseEnabled ? <span className={styles.programPassSaveFirst}>available after saving</span> : null)}
                    </>
                    : (formValues.srtInputPassphraseEnabled
                      ? <><label>SRT Input Passphrase</label><span className={styles.programPassSaveFirst}>available after saving</span></>
                      : null)
                )
              ),
              disabled: !formValues.srtInputPassphraseEnabled
              // hint: this.infoHintElement('TESTING HINT', 'BLAH...')
            },
            {
              type: ArkFormFieldType.Input,
              key: 'srtInputLatency',
              label: 'SRT Input Latency', // `(ms)` // TODO: current css styling capitalises the `M` in `Ms` so not adding it)
              className: styles.srtLatency + (hasChanges('srtInputLatency') ? ' ' + styles.hasChanged : ''),
              required: false,
              defaultValue: savedValues.srtInputLatency, // (_srtLatency !== undefined ? '' + _srtLatency : undefined),
              placeholder: defaultSrtLatency !== undefined ? '' + defaultSrtLatency : undefined,
              hint: infoHintElement(
                'SRT Input Latency (ms)',
                (<>
                  <p>Set the desired latency for the SRT pipeline. A lower number means less delay but also less reliable.</p>
                  <p>NOTE: SRT Latency can also be set on the streaming input device - the pipeline will work to whichever is the highest number (the largest delay).</p>
                  <p>Your default value should be 4 times the RTT of the link. E.g. if you have 200ms RTT, the &quot;latency&quot; parameters should not be less than 800ms.</p>
                  <p>Never set less than 120ms. If you&apos;d like to make low latency optimisation on good quality networks, this value shouldn&apos;t be set less than 2.5 times the RTT.</p>
                </>),
                undefined,
                { flowing: true, position: 'top right' }
              ),
              // NB: only add a description if we have the default value from the /config api loaded (we should do normally)
              // TODO: get the /config api endpoint to supply the recommended min/max range instead of hard-coding here?
              description: (defaultSrtLatency
                ? (<>
                  <div>Recommended: 120ms - 5000ms</div>
                  <div>Default: {defaultSrtLatency}ms</div>
                </>)
                : undefined)
              // wrapperProps: { style: { marginBottom: '8px' } } // NB: current default vertical spacing/margin within a fieldset (but no group?) seems to be 0, temp forcing to add a little space between feeds
            },
            {
              type: ArkFormFieldType.Input,
              key: 'srtInputMaxBandwidth',
              label: 'SRT Input Max Bandwidth',
              className: styles.srtInputMaxBandwidth + (hasChanges('srtInputMaxBandwidth') ? ' ' + styles.hasChanged : ''),
              required: false,
              defaultValue: savedValues.srtInputMaxBandwidth, // (_srtMaxBandwidth !== undefined ? '' + _srtMaxBandwidth : undefined) ?? undefined,
              placeholder: defaultSrtMaxBandwidth !== undefined ? '' + defaultSrtMaxBandwidth : undefined,
              hint: infoHintElement(
                'SRT Input Max Bandwidth',
                (<>
                  <p>Max bandwidth is used to stop SRT becoming a bandwidth hog, which can happen if network conditions are not great.</p>
                  <p>It will continue to try resend dropped packets but this will consume throughput bandwidth.</p>
                  <p>It is recommended that you set it to double your expecting source stream bandwidth. E.g. if your video is 2Mbps then set it to 4Mbps.</p>
                  <p>Value is in bytes and NOT bits, thus if you want to set it to 2Mbps then you need to enter 250000 as the value.</p>
                </>),
                undefined,
                { flowing: true, position: 'top right' }
              ),
              description: (defaultSrtMaxBandwidth !== undefined
                ? (<>
                  <div>Note: &quot;-1&quot; = No Max, Default: {defaultSrtMaxBandwidth}</div>
                </>)
                : undefined)
            }
          ],
          // fieldProps: { widths: 2, inline: false, grouped: false },
          collapsible: false,
          collapsed: false,
          fieldProps: { style: { flexGrow: 1, flexBasis: '33%', minWidth: '200px' } }
        },
        {
          type: ArkFormFieldType.Fieldset,
          key: 'srtOutputOptionsFieldset',
          label: 'SRT Output Options',
          className: styles.srtOutputOptions,
          fields: [
            ...(disableSRTOutput
              ? [
                {
                  type: ArkFormFieldType.Field,
                  key: 'outputSrtAudioOnlyWarning',
                  wrapperProps: { className: styles.srtOutputWarningField },
                  content: (
                    (<div className={styles.srtOutputWarningNotice}>
                      <div className={styles.warningIcon}><ArkIcon name='warning' size={22} color={'#f3be0e'} /></div>
                      <div className={styles.warningText}>
                        SRT Output is disabled when audio only is enabled.
                      </div>
                    </div>))
                }
              ]
              : []
            ),
            {
              type: ArkFormFieldType.Checkbox,
              key: 'srtOutputPassphraseEnabled',
              label: 'Enable Output Passphrase',
              className: styles.srtOutputPassphraseEnable + (hasChanges('srtOutputPassphraseEnabled') ? ' ' + styles.hasChanged : ''),
              required: false,
              defaultValue: savedValues.srtOutputPassphraseEnabled,
              disabled: ((_hotlinkEnabled && _hotlinkProtocols.includes('SRT')) || disableSRTOutput),
              // fieldProps: { style: { marginTop: 10, marginBottom: 8, marginLeft: 0 } },
              hint: infoHintElement(
                'SRT Output Options',
                (<>
                  <p>Control whether the SRT output requires an encryption passphrase and the length of that passphrase. </p>
                  <p>Enabling will generate a new passphrase of the selected length.</p>
                  <p>Disabling is less secure and means anyone could view the output video if they know the URL.</p>
                </>),
                ((_hotlinkEnabled && _hotlinkProtocols.includes('SRT')) || disableSRTOutput)
              ),
              hintProps: { style: { marginTop: 10, marginBottom: 8, marginLeft: 0 } }
            },
            {
              type: ArkFormFieldType.Dropdown,
              key: 'srtOutputKeyLength',
              label: 'SRT Output Key Length' /* (16, 24 or 32) */,
              className: hasChanges('srtOutputKeyLength') ? styles.hasChanged : undefined,
              required: false,
              defaultValue: _srtOutputKeyLength, // NB: was: (program?.srtOutputKeyLength ? program.srtOutputKeyLength : 0),
              options: srtKeyLengthOptions,
              disabled: !formValues.srtOutputPassphraseEnabled || (_hotlinkEnabled && _hotlinkProtocols.includes('SRT')) || disableSRTOutput,
              hint: infoHintElement(
                'SRT Output Key Length',
                (<>
                  <p>Length of the Output SRT Passphrase.</p>
                </>),
                !formValues.srtOutputPassphraseEnabled || (_hotlinkEnabled && _hotlinkProtocols.includes('SRT')) || disableSRTOutput
              ),
              wrapperProps: { style: { marginBottom: '8px' } }, // NB: current default vertical spacing/margin within a fieldset (but no group?) seems to be 0, temp forcing to add a little space between feeds
              fieldProps: { scrolling: true } // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
            },
            // {
            //   type: ArkFormFieldType.Input,
            //   key: 'outputSrtPassphrase',
            //   label: 'Output SRT passphrase',
            //   required: false,
            //   disabled: true,
            //   defaultValue: (program?.outputSrtPassphrase ? '' + program.outputSrtPassphrase : undefined),
            //   hint: this.infoHintElement('TESTING HINT', 'BLAH...')
            // },
            {
              type: ArkFormFieldType.Field,
              key: 'outputSrtPassphraseView',
              // label: 'SRT Output Passphrase',
              content: (
                (
                  program !== undefined
                    ? <>
                      <label>SRT Output Passphrase</label>
                      <ArkPreviewCopyView title='SRT Output Passphrase' value={_srtOutputPassphraseEnabled ? _srtOutputPassphrase : undefined} compactPopup />
                      {(formValues.srtOutputPassphraseEnabled && !_srtOutputPassphraseEnabled && !disableSRTOutput ? <span className={styles.programPassSaveFirst}>available after saving</span> : null)}
                    </>
                    : (formValues.srtOutputPassphraseEnabled && !disableSRTOutput
                      ? <><label>SRT Output Passphrase</label><span className={styles.programPassSaveFirst}>available after saving</span></>
                      : null)
                )
              ),
              disabled: !formValues.srtOutputPassphraseEnabled || (_hotlinkEnabled && _hotlinkProtocols.includes('SRT')) || disableSRTOutput
              // hint: this.infoHintElement('TESTING HINT', 'BLAH...')
            },
            {
              type: ArkFormFieldType.Input,
              key: 'srtOutputLatency',
              label: 'SRT Output Latency', // `(ms)` // TODO: current css styling capitalises the `M` in `Ms` so not adding it)
              className: styles.srtLatency + (hasChanges('srtOutput') ? ' ' + styles.hasChanged : ''),
              required: false,
              defaultValue: savedValues.srtOutputLatency, // (_srtLatency !== undefined ? '' + _srtLatency : undefined),
              placeholder: defaultSrtLatency !== undefined ? '' + defaultSrtLatency : undefined,
              hint: infoHintElement(
                'SRT Output Latency (ms)',
                (<>
                  <p>Set the desired latency for the SRT pipeline. A lower number means less delay but also less reliable.</p>
                  <p>NOTE: SRT Latency can also be set on the streaming input device - the pipeline will work to whichever is the highest number (the largest delay).</p>
                  <p>Your default value should be 4 times the RTT of the link. E.g. if you have 200ms RTT, the &quot;latency&quot; parameters should not be less than 800ms.</p>
                  <p>Never set less than 120ms. If you&apos;d like to make low latency optimisation on good quality networks, this value shouldn&apos;t be set less than 2.5 times the RTT.</p>
                </>),
                undefined,
                { flowing: true, position: 'top right' }
              ),
              // NB: only add a description if we have the default value from the /config api loaded (we should do normally)
              // TODO: get the /config api endpoint to supply the recommended min/max range instead of hard-coding here?
              description: (defaultSrtLatency
                ? (<>
                  <div>Recommended: 120ms - 5000ms</div>
                  <div>Default: {defaultSrtLatency}ms</div>
                </>)
                : undefined)
              // wrapperProps: { style: { marginBottom: '8px' } } // NB: current default vertical spacing/margin within a fieldset (but no group?) seems to be 0, temp forcing to add a little space between feeds
            },
            {
              type: ArkFormFieldType.Input,
              key: 'srtOutputMaxBandwidth',
              label: 'SRT Output Max Bandwidth',
              className: styles.srtOutputMaxBandwidth + (hasChanges('srtOutputMaxBandwidth') ? ' ' + styles.hasChanged : ''),
              required: false,
              defaultValue: savedValues.srtOutputMaxBandwidth, // (_srtMaxBandwidth !== undefined ? '' + _srtMaxBandwidth : undefined) ?? undefined,
              placeholder: defaultSrtMaxBandwidth !== undefined ? '' + defaultSrtMaxBandwidth : undefined,
              hint: infoHintElement(
                'SRT Output Max Bandwidth',
                (<>
                  <p>Max bandwidth is used to stop SRT becoming a bandwidth hog, which can happen if network conditions are not great.</p>
                  <p>It will continue to try resend dropped packets but this will consume throughput bandwidth.</p>
                  <p>It is recommended that you set it to double your expecting source stream bandwidth. E.g. if your video is 2Mbps then set it to 4Mbps.</p>
                  <p>Value is in bytes and NOT bits, thus if you want to set it to 2Mbps then you need to enter 250000 as the value.</p>
                </>),
                undefined,
                { flowing: true, position: 'top right' }
              ),
              description: (defaultSrtMaxBandwidth !== undefined
                ? (<>
                  <div>Note: &quot;-1&quot; = No Max, Default: {defaultSrtMaxBandwidth}</div>
                </>)
                : undefined)
            }
          ],
          // fieldProps: { widths: 2, inline: false, grouped: false },
          collapsible: false,
          collapsed: false,
          fieldProps: { style: { flexGrow: 1, flexBasis: '33%', minWidth: '200px' } }
        },
        {
          type: ArkFormFieldType.Fieldset,
          key: 'srtPortsFieldset',
          label: 'SRT Ports',
          className: styles.srtPorts,
          fields: [
            {
              type: ArkFormFieldType.Checkbox,
              key: 'srtCustomPortsEnabled',
              label: 'Enable Custom SRT Ports',
              className: styles.srtCustomPortsEnabled + (hasChanges('srtCustomPortsEnabled') ? ' ' + styles.hasChanged : ''),
              required: false,
              defaultValue: savedValues.srtCustomPortsEnabled,
              // disabled: ((_hotlinkEnabled && _hotlinkProtocols.includes('SRT')) || disableSRTOutput),
              hint: infoHintElement(
                'SRT Custom Ports',
                (<>
                  {/* TODO: fix the wording... */}
                  <p>Optionally specify custom input &amp; output SRT ports.</p>
                </>)
              ),
              hintProps: { style: { marginTop: 10, marginBottom: 8, marginLeft: 0 } }
            },
            {
              type: ArkFormFieldType.Input,
              key: 'srtInputPort',
              label: 'SRT Input Port',
              className: styles.srtInputPort + (hasChanges('srtInputPort') ? ' ' + styles.hasChanged : '') + (!_showCustomPortInOutFields ? ' ' + styles.srtInputPortsHidden : ''),
              required: false,
              // defaultValue: savedValues.srtInputPort, // program?.srtInputPort ?? undefined,
              value: formValues.srtInputPort ?? '', // NB: flipped to direct form value usage, so we can update the value in an external form/ui instead of only via this form
              disabled: !formValues.srtCustomPortsEnabled,
              fieldProps: {
                onClick: () => {
                  console.log('ProgramForm - srtInputPort - onClick')
                  showSRTPortEditModal('input')
                },
                type: undefined
              }
              // placeholder: defaultSrtLatency !== undefined ? '' + defaultSrtLatency : undefined,
            },
            {
              type: ArkFormFieldType.Input,
              key: 'srtOutputPort',
              label: 'SRT Output Port',
              className: styles.srtOutputPort + (hasChanges('srtOutputPort') ? ' ' + styles.hasChanged : '') + (!_showCustomPortInOutFields ? ' ' + styles.srtInputPortsHidden : ''),
              required: false,
              // defaultValue: savedValues.srtOutputPort, // program?.srtOutputPort ?? undefined,
              value: formValues.srtOutputPort ?? '', // NB: flipped to direct form value usage, so we can update the value in an external form/ui instead of only via this form
              disabled: !formValues.srtCustomPortsEnabled,
              fieldProps: {
                onClick: () => {
                  console.log('ProgramForm - srtOutputPort - onClick')
                  showSRTPortEditModal('output')
                }
              }
              // placeholder: defaultSrtLatency !== undefined ? '' + defaultSrtLatency : undefined,
            },
            {
              type: ArkFormFieldType.Field,
              key: 'srtCustomPortsDisabledNotice',
              wrapperProps: { className: styles.srtCustomPortsDisabledNotice + (_showCustomPortInOutFields ? ' ' + styles.srtInputPortsHidden : '') },
              content: (
                (<div className={styles.srtCustomPortsDisable}>
                  Ports will be automatically allocated
                </div>))
            }
            // { type: ArkFormFieldType.Button, key: 'srtOutputPortBtn', label: 'EDIT SRT OUTPUT PORT', fieldProps: { onClick: onSrtOutputPortClick } },
            // { type: ArkFormFieldType.Button, key: 'srtInputPortBtn', label: 'EDIT SRT INPUT PORT', fieldProps: { onClick: onSrtInputPortClick } }
          ],
          // fieldProps: { widths: 2, inline: false, grouped: false },
          collapsible: false,
          collapsed: false,
          fieldProps: { style: { flexGrow: 1, flexBasis: '33%', minWidth: '200px' } }
        }
      ],
      fieldProps: { widths: 'equal', style: { justifyContent: 'space-between', gap: '10px' } }
    }
    // {
    //   type: ArkFormFieldType.Fieldset,
    //   key: 'srtGeneralOptionsFieldset',
    //   label: 'SRT General Options',
    //   className: styles.srtGeneralOptions,
    //   fields: [
    //     {
    //       type: ArkFormFieldType.Group,
    //       key: 'srtOptionsGroup',
    //       fields: [
    //         {
    //           type: ArkFormFieldType.Input,
    //           key: 'srtLatency',
    //           label: 'SRT Latency', // `(ms)` // TODO: current css styling capitalises the `M` in `Ms` so not adding it)
    //           className: styles.srtLatency + (hasChanges('srtLatency') ? ' ' + styles.hasChanged : ''),
    //           required: false,
    //           defaultValue: savedValues.srtLatency, // (_srtLatency !== undefined ? '' + _srtLatency : undefined),
    //           placeholder: defaultSrtLatency !== undefined ? '' + defaultSrtLatency : undefined,
    //           hint: infoHintElement(
    //             'SRT Latency (ms)',
    //             (<>
    //               <p>Set the desired latency for the SRT pipeline. A lower number means less delay but also less reliable.</p>
    //               <p>NOTE: SRT Latency can also be set on the streaming input device - the pipeline will work to whichever is the highest number (the largest delay).</p>
    //               <p>Your default value should be 4 times the RTT of the link. E.g. if you have 200ms RTT, the &quot;latency&quot; parameters should not be less than 800ms.</p>
    //               <p>Never set less than 120ms. If you&apos;d like to make low latency optimisation on good quality networks, this value shouldn&apos;t be set less than 2.5 times the RTT.</p>
    //             </>),
    //             undefined,
    //             { flowing: true, position: 'top right' }
    //           ),
    //           // NB: only add a description if we have the default value from the /config api loaded (we should do normally)
    //           // TODO: get the /config api endpoint to supply the recommended min/max range instead of hard-coding here?
    //           description: (defaultSrtLatency
    //             ? (<>
    //               <div>Recommended: 120ms - 5000ms</div>
    //               <div>Default: {defaultSrtLatency}ms</div>
    //             </>)
    //             : undefined)
    //           // wrapperProps: { style: { marginBottom: '8px' } } // NB: current default vertical spacing/margin within a fieldset (but no group?) seems to be 0, temp forcing to add a little space between feeds
    //         },
    //         {
    //           type: ArkFormFieldType.Input,
    //           key: 'srtMaxBandwidth',
    //           label: 'SRT max bandwidth',
    //           className: styles.srtMaxBandwidth + (hasChanges('srtMaxBandwidth') ? ' ' + styles.hasChanged : ''),
    //           required: false,
    //           defaultValue: savedValues.srtMaxBandwidth, // (_srtMaxBandwidth !== undefined ? '' + _srtMaxBandwidth : undefined) ?? undefined,
    //           placeholder: defaultSrtMaxBandwidth !== undefined ? '' + defaultSrtMaxBandwidth : undefined,
    //           hint: infoHintElement(
    //             'SRT Max Bandwidth',
    //             (<>
    //               <p>Max bandwidth is used to stop SRT becoming a bandwidth hog, which can happen if network conditions are not great.</p>
    //               <p>It will continue to try resend dropped packets but this will consume throughput bandwidth.</p>
    //               <p>It is recommended that you set it to double your expecting source stream bandwidth. E.g. if your video is 2Mbps then set it to 4Mbps.</p>
    //               <p>Value is in bytes and NOT bits, thus if you want to set it to 2Mbps then you need to enter 250000 as the value.</p>
    //             </>),
    //             undefined,
    //             { flowing: true, position: 'top right' }
    //           ),
    //           description: (defaultSrtMaxBandwidth !== undefined
    //             ? (<>
    //               <div>Note: &quot;-1&quot; = No Max, Default: {defaultSrtMaxBandwidth}</div>
    //             </>)
    //             : undefined)
    //         }
    //       ],
    //       fieldProps: { widths: 'equal', style: { justifyContent: 'flex-start', gap: '90px' } } // NB: spacing the fields to roughly match the columns in the fieldset above it
    //     }
    //   ],
    //   // fieldProps: { widths: 2, inline: false, grouped: false },
    //   collapsible: false,
    //   collapsed: false,
    //   fieldProps: { style: { flexGrow: 1, flexBasis: '33%', minWidth: '200px' } }
    // }
  )

  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      {
        type: ArkFormFieldType.CancelButton,
        key: 'cancel',
        label: 'CANCEL',
        fieldProps: { onClick: onCancel, floated: 'left' }
      },
      {
        type: ArkFormFieldType.OKButton,
        key: 'submit',
        label: (mode === ProgramFormMode.Edit ? 'SAVE' : 'ADD'),
        fieldProps: { loading: isSubmitting, floated: 'right' },
        disabled: changes.length === 0,
        disabledTooltip: 'No Changes to Save'
      }
    ],
    fieldProps: { widths: 'equal' }
  })

  // -------

  return (
    <>
      <ArkHeader as="h2" inverted>
        {mode === ProgramFormMode.Edit ? 'Edit' : 'Add'} {OBJECT_PROGRAM_NAME}
      </ArkHeader>

      {hasSaved && (<>
        <ArkMessage positive>
          <ArkMessage.Header>Program {mode === ProgramFormMode.Edit ? 'Updated' : 'Created'}</ArkMessage.Header>
          <ArkMessage.Item>The program has been {mode === ProgramFormMode.Edit ? 'updated' : 'created'} successfully</ArkMessage.Item>
        </ArkMessage>
        <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={onClose} style={{ marginTop: 15 }}>
          OK
        </ArkButton>
      </>)}

      {!hasSaved && (
        <ArkForm
          className={styles.programForm}
          formKey="programForm"
          inverted
          formError={error}
          formFields={formFields}
          formSchema={formSchema}
          onFormSubmit={onFormSubmit}
          onValueChanged={onFormValueChanged}
          shouldEnterKeySubmit={shouldEnterKeySubmit}
          tabFocusEnabled={!showSRTPortModal}
          showLabels={true}
          insideModal={insideModal}
          // NB: the parent `ProjectProgramsPage` disables `esc to close` on this forms modal, so it doesn't close while the port sub-modal form is open (& we currently have no way to know when its open, so we just disable it all the time for now)
        />)}

      {renderSRTPortModal()}
    </>
  )
}

export default ProgramForm
