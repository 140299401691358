import React, { useContext, useEffect, useRef, useState } from 'react'

import { NavContext, ProjectAdminContext, UserContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import { ProjectGroup, User, GroupUserOperation } from 'src/core/models'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkButton from 'src/core/components/ArkButton'
import ArkDataMappingForm, { ArkDataMappingItemChanges, ArkManagerFilteredItem } from 'src/core/components/ArkDataMappingForm/ArkDataMappingForm'
import ArkHighlightedText from 'src/core/components/ArkHighlightedText'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_COMPANY_SHORTNAME, OBJECT_GROUP_NAME, OBJECT_PROJECT_NAME, OBJECT_USER_NAME_PLURAL } from 'src/constants/strings'

import styles from './ProjectGroupUsersPanel.module.css'
import dataMappingFormStyles from 'src/core/components/ArkDataMappingForm/ArkDataMappingForm.module.css'

interface IProps {
  companyId: number
  projectId: number
  group: ProjectGroup
  onChange?: Function
}

const ProjectGroupUsersPanel = (props: IProps) => {
  const mounted = useRef(false)

  const { companyId, projectId, group, onChange: _onChange } = props

  const userContext = useContext(UserContext)
  const projectAdminContext = useContext(ProjectAdminContext)
  const navContext = useContext(NavContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [projectUsers, setProjectUsers] = useState<Array<User>>([])
  // const [groupUsers, setGroupUsers] = useState<Array<User>>([])

  const [filteredProjectUsers, setFilteredProjectUsers] = useState<Array<ArkManagerFilteredItem<User>> | undefined>()
  const [filter, setFilter] = useState<string | undefined>()

  const [selectedIds, setSelectedIds] = useState<Array<number>>([])

  // const [savedIds, setSavedIds] = useState<Array<number> | undefined>() // NB: not currently used, so skipping it
  // const [saveErrors, setSaveErrors] = useState<Map<number, any> | undefined>() // NB: not currently used, so skipping it

  const [showForm, setShowForm] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasUpdated, setHasUpdated] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const resetForm = () => {
    if (isSubmitting) return // don't allow resetting while submitting
    setIsSubmitting(false)
    setHasUpdated(false)
    setError(undefined)
    // setSavedIds(undefined)
    // setSaveErrors(undefined)
  }

  const resetView = () => {
    resetForm()
    setShowForm(false)
  }

  // -------

  // TESTING: equivalent to the componentDidUpdate method `if (this.props.group.id !== prevProps.group.id) { this.resetView() }`
  useEffect(() => {
    if (group.id) {
      resetView()
    }
  }, [group.id])

  // -------

  const _loadProjectUsers = async () => {
    if (companyId && projectId) {
      try {
        const projectUsers = await projectAdminContext.actions.getProjectUsers(companyId, projectId)
        // filter out non-direct (mirror group) users from the list (as we can't assign them to group if they aren't an actual member of the project)
        const filteredProjectUsers = projectUsers ? projectUsers.filter((projectUser) => projectUser.isProjectDirectUser()) : undefined
        return filteredProjectUsers
      } catch (error) {
        console.error('ProjectGroupUsersPanel - _loadProjectUsers - error: ', error)
        throw error
      }
    }
  }

  const _loadGroupUsers = async () => {
    if (companyId && projectId && group.id) {
      try {
        const groupUsers = await projectAdminContext.actions.getProjectGroupUsers(companyId, projectId, group.id)
        return groupUsers
      } catch (error) {
        console.error('ProjectGroupUsersPanel - _loadGroupUsers - error: ', error)
        throw error
      }
    }
  }

  const loadUserssData = async () => {
    console.log('ProjectGroupUsersPanel - loadUserssData')
    if (loading === true) return false
    try {
      setLoading(true)
      setError(undefined)
      const projectUsers = await _loadProjectUsers()
      const groupUsers = await _loadGroupUsers()
      // TESTING: re-set the selectedIds to show the mappings saved from the api
      // NB: this will override any edits/changes to the form selection by the user, if this gets called after initial load
      const selectedIds: Array<number> = []
      if (groupUsers) {
        for (const groupUser of groupUsers) {
          selectedIds.push(groupUser.id)
        }
      }
      console.log('ProjectGroupUsersPanel - loadUserssData - selectedIds: ', selectedIds)
      setLoading(false)
      setProjectUsers(projectUsers || [])
      // setGroupUsers(groupUsers || [])
      setSelectedIds(selectedIds)
    } catch (error) {
      console.error('ProjectGroupUsersPanel - loadUserssData - error: ', error)
      setLoading(false)
      setProjectUsers([])
      // setGroupUsers([])
      setSelectedIds([])
      setError(error as Error)
    }
  }

  // -------

  const onShowForm = async () => {
    console.log('ProjectGroupUsersPanel - onShowForm')
    setShowForm(true)
    setError(undefined)
    await loadUserssData() // TESTING: moved this to only trigger when the form should show (avoid making api queries till we need the data)
  }

  // TODO: should this be called?
  // const onHideForm = () => {
  //   console.log('ProjectGroupUsersPanel - onHideForm')
  //   setShowForm(false)
  //   setError(undefined)
  // }

  // -------

  const onGotoOrgGroupAdmin = () => {
    console.log('ProjectGroupUsersPanel - onGotoOrgGroupAdmin')
    const orgGroupId = group.mirrorGroupId
    navContext.actions.goto(ROUTES.COMPANY_MANAGER_GROUPS.replace(':groupId?', (orgGroupId ? '' + orgGroupId : '')))
  }

  // TESTING: takes the result of ArkDataMappingItemChanges (additions & deletions) & converts it to the format the api endpoint expects
  // TODO: move to the relevant api class so its re-usable else-where...
  const generateGroupUserUpdateOperations = (groupId: number, itemChanges: ArkDataMappingItemChanges) => {
    const addOperations: Array<GroupUserOperation> = []
    const delOperations: Array<GroupUserOperation> = []
    for (const addItem of itemChanges.add) {
      addOperations.push({
        operation: 0, // 0 === ADD
        // group_id: groupId,
        user_id: addItem
      })
    }
    for (const delItem of itemChanges.del) {
      delOperations.push({
        operation: 2, // 2 == DELETE
        // group_id: groupId,
        user_id: delItem
      })
    }
    return [...delOperations, ...addOperations]
  }

  // -------

  const saveChanges = async (companyId: number, projectId: number, group: ProjectGroup, selectedIds: Array<number>, selectedIdChanges: ArkDataMappingItemChanges) => {
    setIsSubmitting(true)
    setHasUpdated(false)
    setError(undefined)
    // setSavedIds(undefined)
    // setSaveErrors(undefined)

    // convert the list off adds & dels to an operations list in the format the api expects
    const operations = generateGroupUserUpdateOperations(group.id, selectedIdChanges)
    console.log('ProjectGroupUsersPanel - saveChanges - operations: ', operations)

    if (operations.length === 0) {
      console.log('ProjectGroupUsersPanel - saveChanges - 0 operations - HALT')
      // TODO: show an error/warning?
      setIsSubmitting(false)
      setHasUpdated(false)
      return
    }

    let savedIds: Array<number> | undefined
    let newSelectedIds: Array<number> | undefined
    let saveErrors: Map<number, any> | undefined
    let hasUpdated = false
    let error: Error | undefined

    try {
      // run all the add & delete mapping operations
      const result = await projectAdminContext.actions.updateProjectGroupUsers(companyId, projectId, group.id, operations)
      console.log('ProjectGroupUsersPanel - saveChanges - updateProjectGroupUsers - result: ', result, ' typeof: ', typeof result, ' instanceof Map: ', result instanceof Map)

      if (result === true) { // all operations succeeded
        if (mounted.current) {
          hasUpdated = true
          savedIds = operations.map(operation => operation.user_id)
          newSelectedIds = selectedIds
        }
      } else if (result instanceof Map) { // 1 or more operations had errors (but some may have still succeeded)
        // all operations failed
        if (result.size === operations.length) {
          hasUpdated = false
          savedIds = []
          newSelectedIds = selectedIds // TESTING: revert to the previous form selection before the submit
          console.log('ProjectGroupUsersPanel - saveChanges - updateProjectGroupUsers - ALL FAILED - newSelectedIds: ', newSelectedIds)
        } else { // only some operations failed
          hasUpdated = true // set true if some added/updated ok
          savedIds = [] // get a list of successful operations
          for (const operation of operations) {
            if (result.has(operation.user_id) === false) {
              savedIds.push(operation.user_id)
            }
          }
          newSelectedIds = selectedIds.filter((selectedId) => !result.has(selectedId)) // TODO: does this only account for adding/enabling an id, not removing one??
          console.log('ProjectGroupUsersPanel - saveChanges - updateProjectGroupUsers - SOME FAILED - newSelectedIds:', newSelectedIds)
        }
        saveErrors = result // the result contains errors for each operation that failed
        error = { message: 'A problem occurred saving one or more of the changes, please try again.' } as any
      } else { // general/fallback error (with the whole api call)
        hasUpdated = false
        error = { message: 'A problem occurred saving the changes, please try again.' } as any
      }

      console.log('ProjectGroupUsersPanel - saveChanges - hasUpdated:', hasUpdated, ' savedIds:', savedIds, ' newSelectedIds:', newSelectedIds, ' saveErrors:', saveErrors, ' error:', error)
      if (mounted.current) {
        setIsSubmitting(false)
        setHasUpdated(hasUpdated)
        setSelectedIds(newSelectedIds ?? [])
        // setSavedIds(savedIds)
        // setSaveErrors(saveErrors)
        setError(error)
      }
      if (hasUpdated) {
        if (_onChange) _onChange()
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setHasUpdated(hasUpdated)
        // setSavedIds(savedIds)
        // setSaveErrors(saveErrors)
        setError(error as Error)
      }
    }
  }

  // -------

  const filterProjectUsers = (_filter: string) => {
    if (loading) return
    // NB: we currently filter out items even if they're enabled/mapped, but when you submit they will still submit as enabled/mapped (we just don't visually show them for now)
    // TODO: should we show filtered out items that are enabled/mapped in some way, to make it obvious they'll remain selected when you save the mapping form?
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredProjectUsers = filter
      ? projectUsers.reduce<Array<ArkManagerFilteredItem<User>>>((r, user) => {
        // TODO: also allow filtering by user email here (if we actually show it in the listing & not just the name??)
        let nameMatch = false
        // let emailMatch = false
        if (user.name().toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        // if (group.desc?.toLowerCase().includes(filter.toLowerCase())) {
        //   emailMatch = true
        // }
        // console.log('filterUsers - user.name(): ', user.name(), ' nameMatch: ', nameMatch, ' emailMatch: ', emailMatch)
        if (nameMatch) { // || emailMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          // if (emailMatch) matchingFields.push('email')
          const filteredItem: ArkManagerFilteredItem<User> = {
            item: user,
            matchingFields
          }
          r.push(filteredItem)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<User>>)
      : undefined
    setFilter(filter)
    setFilteredProjectUsers(filteredProjectUsers)
  }

  // const clearFilteredProjectPrograms = () => {
  //   setFilter(undefined)
  //   setFilteredProjectUsers(undefined)
  // }

  // -------

  const renderProjectUserFilterForm = () => {
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        className={dataMappingFormStyles.filterForm}
        filterTitle='Filter by name'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          filterProjectUsers(filter)
        }}
      />
    )
  }

  // -------

  if (!group) return null

  const isCompanyAdminOrHigher = userContext.actions.isCompanyAdminOrHigher()
  let disableEdits: boolean = false
  let disableMsg: string | React.ReactNode | undefined
  if (group.isDefaultGroup) {
    disableEdits = true
    disableMsg = <>You cannot disable {OBJECT_USER_NAME_PLURAL} in the {OBJECT_PROJECT_NAME} default {OBJECT_GROUP_NAME}.</>
  } else if (group.isMirroredGroup()) {
    disableEdits = true
    disableMsg = (
      <>
        Mirrored {OBJECT_GROUP_NAME} {OBJECT_USER_NAME_PLURAL} must be edited at the {OBJECT_COMPANY_SHORTNAME} {OBJECT_GROUP_NAME} level.<br />
        {isCompanyAdminOrHigher && (
          <>
            <ArkButton fluid size='large' color='blue' className={styles.mirroredGroupAdminBtn} onClick={onGotoOrgGroupAdmin}>EDIT {OBJECT_COMPANY_SHORTNAME} {OBJECT_GROUP_NAME}</ArkButton>
          </>
        )}
      </>
    )
  }
  return (
    <ArkPanel bordered={showForm} title={showForm ? OBJECT_GROUP_NAME + ' ' + OBJECT_USER_NAME_PLURAL : null}>

      {!showForm && (
        <ArkButton type="button" fluid size="large" loading={loading} onClick={onShowForm}>EDIT {OBJECT_GROUP_NAME} {OBJECT_USER_NAME_PLURAL}</ArkButton>
      )}

      {showForm && (
        <>
          <ArkDataMappingForm
            id="group-users"
            // title="Group Users"
            sourceItems={projectUsers /* .map(projectUser => { return { id: projectUser.id, title: projectUser.name() } }) */}
            mappedIds={selectedIds}
            isLoading={loading}
            isSaving={isSubmitting}
            successMessage={hasUpdated && 'The ' + OBJECT_GROUP_NAME + ' ' + OBJECT_USER_NAME_PLURAL + ' have been updated.' }{/* TODO: add details of how many where added/updated/removed from the chhanel? */...[]}
            errorMessage={error && error.message}
            disabled={disableEdits}
            disabledMessage={disableMsg && ( // NB: mimic the `disabledMessage` rendering used within `ArkDataMappingForm`, customised here so we can conditionally add buttons/elements under the message
              <ArkMessage color='yellow' className={dataMappingFormStyles.msg}>
                <ArkMessage.Header>Please Note</ArkMessage.Header>
                <p>{disableMsg}</p>
              </ArkMessage>
            )}
            onCancel={() => { resetView() }}
            onSave={(mappedIds?: Array<number>, changes?: ArkDataMappingItemChanges) => {
              console.log('ProjectGroupUsersPanel - ArkDataMappingForm - onSave - mappedIds: ', mappedIds, ' changes: ', changes)
              if (mappedIds && changes) {
                saveChanges(companyId, projectId, group, mappedIds, changes)
              }
            }}
            // filtering & custom item rendering support:
            filterForm={renderProjectUserFilterForm()}
            filterText={filter}
            filteredSourceItems={filteredProjectUsers}
            itemCompare={(newItem: User, oldItem: User) => {
              return newItem.id === oldItem.id && newItem.name() === oldItem.name()
            }}
            itemRow={(user: User, _isMapped: boolean) => {
              return (
                <div className={styles.userRow}>
                  <ArkAvatar
                    type={user.userAvatarType()}
                    name={user.name()}
                    size='30'
                    filter={filter}
                    filterHighlight={true}
                  />
                  <ArkHighlightedText highlight={filter} text={user.name()} className={styles.text} />
                </div>
              )
            }}
            onClearFilter={() => filterProjectUsers('')}
          />
          <ArkSpacer />
        </>
      )}

    </ArkPanel>
  )
}

export default ProjectGroupUsersPanel
