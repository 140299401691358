import React from 'react'
import _ from 'lodash'

import { withUserContext, IUserMultiContext } from 'src/core/providers'
import { projectMenuItems, supportMenuItems } from 'src/constants/menus'

import ArkPage from 'src/core/components/ArkPage/ArkPage'
import ArkSectionSidebarMenu from 'src/core/components/ArkSectionSidebarMenu/ArkSectionSidebarMenu'

interface IProps extends IUserMultiContext {
  children?: React.ReactNode
  args?: any // PrivateRoute optional args (object with any number of key:value entries specified at the router declaration level)
  rightSidebar?: React.ReactNode
  hideSidebars?: boolean
  disableSectionMainMenu?: boolean
  onRightSidebarClose?: () => void
}
interface IState {}

class ArkProjectManagerPage extends React.Component<IProps, IState> {
  render () {
    const { children, onRightSidebarClose } = this.props
    return (
      <ArkPage
        leftSidebar={this.sidebarLeftContent()}
        onRightSidebarClose={onRightSidebarClose}
        rightSidebar={this.sidebarRightContent()}
        section="project"
        showSelectCompany
        showSelectProject
      >
        {children}
      </ArkPage>
    )
  }

  sidebarLeftContent = () => {
    if (this.props.hideSidebars) return null
    // const company = this.props.userContext.store.selectedCompany
    const project = this.props.userContext.store.selectedProject

    // check if the user has access to the specified project (if one is selected)
    // UPDATE: the section sidebar menu no longer uses these checks & just shows the menu items dimmed-out/disabled if the user doesn't have access
    // let showProjectMenu = false
    // if (company && project) {
    //   const isCompanyAdmin = company?.userCompanyRole === UserCompanyRole.admin
    //   let isProjectAdminOrManager = false
    //   if (project instanceof UserProject) {
    //     isProjectAdminOrManager = project.userProjectRole === UserProjectRole.admin || project.userProjectRole === UserProjectRole.manager
    //   }
    //   showProjectMenu = !(!project || (!isCompanyAdmin && !isProjectAdminOrManager))
    // }

    const mainMenuItems = projectMenuItems(project?.id)

    // disable the main menu items if the parent says so, or if the parent user data selections are loading/updating
    const loadingParentData = this.props.userContext.store.loadingCompanyData || this.props.userContext.store.loadingProjectData
    if (this.props.disableSectionMainMenu || loadingParentData) {
      _.forEach(mainMenuItems, (item) => { item.disable = true })
    }

    const bottomMenuItems = supportMenuItems()
    return (
      <ArkSectionSidebarMenu
        mainMenuItems={mainMenuItems}
        bottomMenuItems={bottomMenuItems}
      />
    )
  }

  sidebarRightContent = () => {
    if (this.props.hideSidebars) return null
    if (!this.props.rightSidebar) return null
    return (<>
      {this.props.rightSidebar}
    </>)
  }
}
export default withUserContext(ArkProjectManagerPage)
