import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

import { CompanyAdminContext } from 'src/core/providers'
import { CompanyVideoEngine } from 'src/core/models'
import { IProjectUpdateData, Project, PROJECT_NO_VIDEO_ENGINE_ID } from 'src/core/models/project'

import ArkHeader from 'src/core/components/ArkHeader'
import ArkModal from 'src/core/components/ArkModal'
import ArkForm, { ArkFormField, ArkFormFieldOption, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'

import { OBJECT_PROJECT_NAME, OBJECT_VIDEO_ENGINE_NAME } from 'src/constants/strings'

import styles from './ProjectVideoEngineForm.module.css'

const formSchema = yup.object().shape({
  // TODO: <<<<
})

export interface ProjectVideoEngineFormProps {
  companyId: number
  project?: Project
  videoEngines?: Array<CompanyVideoEngine>
  defaultVideoEngineId?: number
  onCancel?: Function
  onSave?: Function
  insideModal?: boolean // ArkForm prop - enable when showing this form within a modal (so fieldset label bg's match)
}

const ProjectVideoEngineForm = (props: ProjectVideoEngineFormProps) => {
  const mounted = useRef(false)

  const { companyId, project, videoEngines, defaultVideoEngineId, onCancel, onSave, insideModal } = props

  const companyAdminContext = useContext(CompanyAdminContext)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSaved, setHasSaved] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>(undefined)

  // individual settings specific to this form
  const [projectValues, setProjectValues] = useState({
    videoEngineId: project?.videoEngine?.id ?? defaultVideoEngineId
  })
  const [projectValuesSaved, setProjectValuesSaved] = useState({
    videoEngineId: project?.videoEngine?.id ?? defaultVideoEngineId
  })
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const projectSettingsChanges = () => {
    const _changes: Array<string> = []
    if (projectValues) {
      for (const fieldName of Object.keys(projectValues)) {
        const oldValue = projectValuesSaved !== undefined ? (projectValuesSaved as any)[fieldName] : undefined
        const newValue = (projectValues as any)[fieldName]
        // console.log('ProjectVideoEngineForm - projectSettingsChanges - fieldName:', fieldName, ' oldValue:', oldValue, ' newValue:', newValue)
        if (oldValue !== newValue) {
          _changes.push(fieldName)
        }
      }
    }
    // console.log('ProjectForm - companySettingsChanges - _changes:', _changes)
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  const resetSaveResults = () => {
    setHasSaved(false)
    setError(undefined)
  }

  // -------

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = projectSettingsChanges()
    setChanges(_changes)
  }, [projectValues])

  // -------

  const updateProjectVideoEngine = async (videoEngineId?: number) => {
    if (isSubmitting || !project) return
    resetSaveResults()
    setIsSubmitting(true)
    try {
      const projectData: IProjectUpdateData = {}
      if (hasChanges('videoEngineId')) projectData.videoEngineId = videoEngineId
      console.log('ProjectVideoEngineForm - updateProjectVideoEngine - projectData: ', projectData)
      // TODO: halt if no changes are detected?
      const savedProject = await companyAdminContext.actions.updateCompanyProject(companyId, project.id, projectData)
      console.log('ProjectVideoEngineForm - updateProjectVideoEngine - savedProject: ', savedProject)
      if (savedProject) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
          setProjectValuesSaved(projectValues)
        }
        if (onSave) onSave()
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          throw new Error('A problem occurred updating the ' + OBJECT_PROJECT_NAME + ', please try again.')
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('ProjectVideoEngineForm - onFormSubmit - fieldValues: ', fieldValues)
    const { videoEngineId } = fieldValues
    updateProjectVideoEngine(videoEngineId)
  }

  const onValueChanged = (fieldKey: string, fieldValue: any, oldFieldValue: any) => {
    console.log('ProjectVideoEngineForm - onValueChanged - fieldKey: ', fieldKey, ' fieldValue: ', fieldValue, ' oldFieldValue: ', oldFieldValue)
    setProjectValues({
      ...projectValues,
      [fieldKey]: fieldValue
    })
    // NB: if the form can remain on screen after saving, call `resetSaveResults()` here
  }

  const _onCancel = () => {
    if (onCancel) onCancel()
  }

  // -------

  const formFields: Array<ArkFormField> = []

  // TODO: visually indicate if a video engine is currently enabled/disabled?
  // TODO: can we assign a project to a video engine thats currently disabled? ANSWER: yes
  // TODO: add support for assigning to no video engine as well
  const videoEngineOptions: Array<ArkFormFieldOption> = []
  // videoEngineOptions.push({ key: 've_none', children: <div className={styles.videoEngineOption + ' ' + styles.videoEngineOptionNone}>{('[No ' + OBJECT_VIDEO_ENGINE_NAME + ']').toUpperCase()}</div>, value: PROJECT_NO_VIDEO_ENGINE_ID }) // add a 'no video engine' option
  videoEngineOptions.push({ key: 've_none', text: <div className={styles.videoEngineOption + ' ' + styles.videoEngineOptionNone}>{('[No ' + OBJECT_VIDEO_ENGINE_NAME + ']').toUpperCase()}</div>, value: PROJECT_NO_VIDEO_ENGINE_ID, className: styles.selectedVideoEngineNone }) // add a 'no video engine' option
  let noVideoEnginesData = false
  if (videoEngines) {
    for (const videoEngine of videoEngines) {
      const isDefault = videoEngine.id === defaultVideoEngineId
      const isActive = videoEngine.isActive
      videoEngineOptions.push({
        key: 've_' + videoEngine.id,
        // children: (
        //   <div className={styles.videoEngineOption}>
        //     <span className={styles.title}>{videoEngine.name + (isDefault ? ' (Default)' : '')}</span>
        //     <span className={styles.status + ' ' + (isActive ? styles.statusEnabled : styles.statusDisabled)}>{isActive ? 'ENABLED' : 'DISABLED'}</span>
        //   </div>
        // ),
        text: videoEngine.name + (isDefault ? ' (Default)' : ''),
        description: (<span className={styles.status + ' ' + (isActive ? styles.statusEnabled : styles.statusDisabled)}>{isActive ? 'ENABLED' : 'DISABLED'}</span>),
        value: videoEngine.id
      })
    }
  } else {
    // only add this if no video engines are loaded/returned (NB: shouldn't happen in normal use, but added just in case the new video engines data fails to load)
    // NB: if editing a project show it as keeping the 'current' server rather than the 'default' one (incase its assigned a different server, but we can't currently tell with this fallback handling)
    const defaultTitle = 'Current ' + OBJECT_VIDEO_ENGINE_NAME
    videoEngineOptions.push({ key: 've_default', text: defaultTitle, value: 0 })
    noVideoEnginesData = true
  }
  // video engine - custom dropdown trigger (selected item display)
  let selectedVideoEngine = videoEngines?.find((ve) => ve.id === projectValues.videoEngineId)
  const selectedVideoEngineIsActive: boolean | undefined = selectedVideoEngine?.isActive ?? undefined
  const selectedVideoEngineNone = projectValues.videoEngineId === -1
  if (projectValues.videoEngineId === -1) {
    selectedVideoEngine = { id: -1, name: ('[No ' + OBJECT_VIDEO_ENGINE_NAME + ']').toUpperCase(), isActive: true, domain: '', ip: '', companyId: undefined } as CompanyVideoEngine
  }
  const videoEngineDropdownTrigger = selectedVideoEngine
    ? (
      <div className={styles.videoEngineDropdownTrigger + ' ' + (selectedVideoEngineNone ? styles.videoEngineNone : '') + ' item'}>
        <span className={'text'}>{selectedVideoEngine.name}</span>
        {selectedVideoEngineIsActive !== undefined && (<span className={'description' + ' ' + styles.status + ' ' + (selectedVideoEngineIsActive ? styles.statusEnabled : styles.statusDisabled)}>{selectedVideoEngineIsActive ? 'ENABLED' : 'DISABLED'}</span>)}
      </div>
    )
    : null

  formFields.push(
    {
      type: ArkFormFieldType.Fieldset,
      key: 'videoEngineFieldset',
      // label: OBJECT_VIDEO_ENGINE_NAME,
      className: styles.videoEngineFieldset,
      fields: [
        {
          type: ArkFormFieldType.Dropdown,
          key: 'videoEngineId',
          label: OBJECT_VIDEO_ENGINE_NAME,
          required: false,
          // defaultValue: !noVideoEnginesData ? (mode === ProjectFormMode.Edit ? project?.videoEngine?.id : _defaultVideoEngineId) : 0, // if no video engines are loaded we show a generic 'default' entry instead with an id/value of 0, set that to show/select
          defaultValue: !noVideoEnginesData ? defaultVideoEngineId : 0, // if no video engines are loaded we show a generic 'default' entry instead with an id/value of 0, set that to show/select
          options: videoEngineOptions,
          disabled: noVideoEnginesData,
          className: styles.videoEngineDropdown + (hasChanges('videoEngineId') ? ' ' + styles.hasChanged : ''),
          fieldProps: {
            scrolling: true, // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
            trigger: videoEngineDropdownTrigger,
            onClick: () => {
              console.log('ProjectVideoEngineForm - videoEngineId - onClick')
            },
            type: undefined
          }
        }
      ],
      // fieldProps: { style: { flexGrow: 1, flexBasis: '33%', minWidth: '200px' } },
      collapsible: false,
      collapsed: false
    }
  )

  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      {
        type: ArkFormFieldType.CancelButton,
        key: 'cancel',
        label: 'CANCEL',
        fieldProps: { onClick: _onCancel, floated: 'left' }
      },
      {
        type: ArkFormFieldType.OKButton,
        key: 'submit',
        label: 'SAVE',
        fieldProps: { loading: isSubmitting, floated: 'right' },
        disabled: changes.length === 0,
        disabledTooltip: 'No Changes to Save'
      }
    ],
    fieldProps: { widths: 'equal' }
  })

  // -------

  return (
    <>
      <ArkHeader as="h2" inverted>
        Edit {OBJECT_PROJECT_NAME} {OBJECT_VIDEO_ENGINE_NAME}
      </ArkHeader>

      {/* TODO: success (& error?) display <<<< */}

      {!hasSaved && (
        <ArkForm
          className={styles.projectVEForm}
          formKey="projectVEForm"
          inverted
          formError={error}
          formFields={formFields}
          formSchema={formSchema}
          onFormSubmit={onFormSubmit}
          onValueChanged={onValueChanged}
          // shouldEnterKeySubmit={shouldEnterKeySubmit}
          // tabFocusEnabled={!showVEFormModal}
          showLabels={true}
          insideModal={insideModal}
        />)}
    </>
  )
}

export interface ProjectVideoEngineModalFormProps extends ProjectVideoEngineFormProps{
  show: boolean
  onCloseModal: () => void
}

const ProjectVideoEngineModalForm = (props: ProjectVideoEngineModalFormProps) => {
  const { show, onCloseModal: _onCloseModal, ...otherProps } = props
  const onCloseModal = () => {
    _onCloseModal()
  }
  return (
    <ArkModal
      open={show}
      onClose={() => onCloseModal()}
    >
      <ProjectVideoEngineForm {...otherProps} />
    </ArkModal>
  )
}

export default ProjectVideoEngineForm
export { ProjectVideoEngineModalForm }
