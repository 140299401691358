import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

import { CompanyAdminContext, ServerConfigContext, UserContext } from 'src/core/providers'

import { CompanyVideoEngine, Project } from 'src/core/models'
import { IProjectAddData, IProjectUpdateData, PROJECT_NO_VIDEO_ENGINE_ID } from 'src/core/models/project'

import { ProjectVideoEngineModalForm } from './ProjectVideoEngineForm'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldOption, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkMessage from 'src/core/components/ArkMessage'

import { OBJECT_PROJECT_NAME, OBJECT_PROJECT_NAME_PLURAL, OBJECT_VIDEO_ENGINE_NAME } from 'src/constants/strings'

import styles from './ProjectForm.module.css'

const formSchema = yup.object().shape({
  name: yup.string().min(4).max(25).required().label(OBJECT_PROJECT_NAME_PLURAL + ' name'),
  desc: yup.string().min(0).max(255).label('Description')
  // TODO: add newer fields
})

export enum ProjectFormMode {
  Add = 'add',
  Edit = 'edit',
}

interface IProps {
  mode: ProjectFormMode
  companyId: number
  project?: Project
  onCancel?: Function
  onSave?: Function
  onClose?: Function
  insideModal?: boolean // ArkForm prop - enable when showing this form within a modal (so fieldset label bg's match)
  // NB: not currently supporting deleting via this form
}

const ProjectForm = (props: IProps) => {
  const mounted = useRef(false)

  const { mode, companyId, project, onCancel, onClose, onSave, insideModal } = props

  const companyAdminContext = useContext(CompanyAdminContext)
  const userContext = useContext(UserContext)
  const serverConfigContext = useContext(ServerConfigContext)

  const isSiteAdmin = userContext.actions.isSiteAdmin()

  const _defaultVideoEngineId = serverConfigContext.store.serverConfig?.streamingDefaults.streamingServerId
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [videoEngines, setVideoEngines] = useState<Array<CompanyVideoEngine> | undefined>(undefined)
  // const [defaultVideoEngineId, setDefaultVideoEngineId] = useState<number | undefined>(undefined)
  const [videoEngineError, setVideoEngineError] = useState<Error | undefined>(undefined)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSaved, setHasSaved] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>(undefined)

  const [showVEFormModal, setShowVEFormModal] = useState<boolean>(false)

  // individual settings specific to this form
  const [projectValues, setProjectValues] = useState({
    name: project?.name ?? '',
    desc: project?.desc ?? '',
    maxUsers: project?.projectMaxUsers ?? undefined,
    videoEngineId: project?.videoEngine?.id ?? _defaultVideoEngineId
  })
  const [projectValuesSaved, setProjectValuesSaved] = useState({
    name: project?.name ?? '',
    desc: project?.desc ?? '',
    maxUsers: project?.projectMaxUsers ?? undefined,
    videoEngineId: project?.videoEngine?.id ?? _defaultVideoEngineId
  })
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const loadData = async () => {
    console.log('ProjectForm - loadData')
    try {
      setIsLoading(true)
      if (error) setError(undefined)
      if (videoEngineError) setVideoEngineError(undefined)

      // const _defaultVideoEngineId = serverConfigContext.store.serverConfig?.streamingDefaults.streamingServerId
      // console.log('ProjectForm - loadData - _defaultVideoEngineId:', _defaultVideoEngineId)
      // setDefaultVideoEngineId(_defaultVideoEngineId)

      const companyVideoEngines = await companyAdminContext.actions.getAllCompanyVideoEngines(companyId)
      console.log('ProjectForm - loadData - companyVideoEngines:', companyVideoEngines)

      // NB: if no engines are returned (null or empty array) show an error message
      if (!companyVideoEngines || companyVideoEngines.length === 0) {
        throw new Error('WARNING: Failed to load available ' + OBJECT_VIDEO_ENGINE_NAME + 's, you can still ' + (mode === ProjectFormMode.Edit ? 'update' : 'create') + ' a ' + OBJECT_PROJECT_NAME + (mode === ProjectFormMode.Edit ? ' keeping the current' : ' with the default') + ' ' + OBJECT_VIDEO_ENGINE_NAME + '.')
      } else {
        setVideoEngines(companyVideoEngines ?? undefined)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      // NB: currently re-using the main form error state var
      // TODO: should this otherwise be flipped to more of a 'warning' instead (we can still create a project with the default engine when this happens, just not select a specific one, if multiple should be available but failed to load)
      setVideoEngineError(error)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  // -------

  const projectSettingsChanges = () => {
    const _changes: Array<string> = []
    if (projectValues) {
      for (const fieldName of Object.keys(projectValues)) {
        const oldValue = projectValuesSaved !== undefined ? (projectValuesSaved as any)[fieldName] : undefined
        const newValue = (projectValues as any)[fieldName]
        // console.log('ProjectForm - projectSettingsChanges - fieldName:', fieldName, ' oldValue:', oldValue, ' newValue:', newValue)
        if (oldValue !== newValue) {
          _changes.push(fieldName)
        }
      }
    }
    // console.log('ProjectForm - companySettingsChanges - _changes:', _changes)
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  const resetSaveResults = () => {
    setHasSaved(false)
    setError(undefined)
  }

  // -------

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = projectSettingsChanges()
    setChanges(_changes)
  }, [projectValues])

  // -------

  const addProject = async (
    name: string,
    desc?: string,
    videoEngineId?: number,
    maxUsers?: number
  ) => {
    if (isSubmitting) return
    console.log('ProjectForm - addProject - name: ', name, ' desc: ', desc, ' videoEngineId:', videoEngineId, ' maxUsers: ', maxUsers)
    resetSaveResults()
    setIsSubmitting(true)
    try {
      const projectData: IProjectAddData = {
        name,
        desc
      }
      projectData.videoEngineId = videoEngineId
      if (isSiteAdmin) projectData.maxUsers = maxUsers
      console.log('ProjectForm - addProject - projectData: ', projectData)
      const savedProject = await companyAdminContext.actions.addCompanyProject(companyId, projectData)
      console.log('ProjectForm - addProject - savedProject: ', savedProject)
      if (savedProject) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
          setProjectValuesSaved(projectValues)
        }
        if (onSave) onSave()
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          throw new Error('A problem occurred adding the ' + OBJECT_PROJECT_NAME + ', please try again.')
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  const updateProject = async (
    name: string,
    desc?: string,
    videoEngineId?: number,
    maxUsers?: number
  ) => {
    if (isSubmitting || !project) return
    resetSaveResults()
    setIsSubmitting(true)
    try {
      const projectData: IProjectUpdateData = {}
      if (hasChanges('name')) projectData.name = name
      if (hasChanges('desc')) projectData.desc = desc
      if (hasChanges('videoEngineId')) projectData.videoEngineId = videoEngineId // TODO: DEPRECIATE THIS? no longer handle project VE updates here, use a dedicated form instead now (see `ProjectVideoEngineForm`) NB: no rush, this is just currently ignored/skipped when no change is detected
      if (isSiteAdmin && hasChanges('maxUsers')) projectData.maxUsers = maxUsers
      console.log('ProjectForm - updateProject - projectData: ', projectData)
      // TODO: halt if no changes are detected?
      const savedProject = await companyAdminContext.actions.updateCompanyProject(companyId, project.id, projectData)
      console.log('ProjectForm - updateProject - savedProject: ', savedProject)
      if (savedProject) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
          setProjectValuesSaved(projectValues)
        }
        if (onSave) onSave()
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          throw new Error('A problem occurred updating the ' + OBJECT_PROJECT_NAME + ', please try again.')
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('ProjectForm - onFormSubmit - fieldValues: ', fieldValues)
    const { name, desc, maxUsers, videoEngineId } = fieldValues
    if (mode === ProjectFormMode.Add) {
      addProject(name, desc, videoEngineId, maxUsers)
    } else if (mode === ProjectFormMode.Edit) {
      updateProject(name, desc, videoEngineId, maxUsers)
    }
  }

  const onValueChanged = (fieldKey: string, fieldValue: any, _oldFieldValue: any) => {
    // console.log('ProjectForm - onValueChanged - fieldKey: ', fieldKey, ' fieldValue: ', fieldValue, ' oldFieldValue: ', oldFieldValue)
    setProjectValues({
      ...projectValues,
      [fieldKey]: fieldValue
    })
    // NB: if the form can remain on screen after saving, call `resetSaveResults()` here
  }

  const _onCancel = () => {
    if (onCancel) onCancel()
  }

  // triggers after dismissing the success message (so the company was created/updated ok)
  const _onClose = () => {
    if (!isSubmitting) setProjectValues(projectValuesSaved) // reset the form back to the saved values
    if (onClose) onClose()
  }

  // -------

  // wait for the initial data to load (so the video engine dropdown/select options & default are loaded & available before rendering the form)
  if (isLoading) return <ArkLoaderView message='Loading' />

  // -------

  const showVEModal = () => {
    setShowVEFormModal(true)
  }

  const renderVEModal = () => {
    return (
      <ProjectVideoEngineModalForm
        companyId={companyId}
        project={project}
        videoEngines={videoEngines}
        defaultVideoEngineId={_defaultVideoEngineId}
        show={showVEFormModal}
        onCancel={() => setShowVEFormModal(false)}
        onCloseModal={() => setShowVEFormModal(false)}
      />
    )
  }

  const shouldEnterKeySubmit = (): boolean => {
    // console.log('ProgramForm - shouldEnterKeySubmit - showSRTPortModal:', showSRTPortModal)
    // ignore enter key submit when the VE form modal is open (otherwise this main form can submit when the port sub-modal form is open & has focus)
    return !showVEFormModal
  }

  // -------

  const formFields: Array<ArkFormField> = []

  // TODO: visually indicate if a video engine is currently enabled/disabled?
  // TODO: can we assign a project to a video engine thats currently disabled? ANSWER: yes
  // TODO: add support for assigning to no video engine as well
  const videoEngineOptions: Array<ArkFormFieldOption> = []
  // videoEngineOptions.push({ key: 've_none', children: <div className={styles.videoEngineOption + ' ' + styles.videoEngineOptionNone}>{('[No ' + OBJECT_VIDEO_ENGINE_NAME + ']').toUpperCase()}</div>, value: PROJECT_NO_VIDEO_ENGINE_ID }) // add a 'no video engine' option
  videoEngineOptions.push({ key: 've_none', text: <div className={styles.videoEngineOption + ' ' + styles.videoEngineOptionNone}>{('[No ' + OBJECT_VIDEO_ENGINE_NAME + ']').toUpperCase()}</div>, value: PROJECT_NO_VIDEO_ENGINE_ID, className: styles.selectedVideoEngineNone }) // add a 'no video engine' option
  let noVideoEnginesData = false
  if (videoEngines) {
    for (const videoEngine of videoEngines) {
      const isDefault = videoEngine.id === _defaultVideoEngineId
      const isActive = videoEngine.isActive
      videoEngineOptions.push({
        key: 've_' + videoEngine.id,
        // children: (
        //   <div className={styles.videoEngineOption}>
        //     <span className={styles.title}>{videoEngine.name + (isDefault ? ' (Default)' : '')}</span>
        //     <span className={styles.status + ' ' + (isActive ? styles.statusEnabled : styles.statusDisabled)}>{isActive ? 'ENABLED' : 'DISABLED'}</span>
        //   </div>
        // ),
        text: videoEngine.name + (isDefault ? ' (Default)' : ''),
        description: (<span className={styles.status + ' ' + (isActive ? styles.statusEnabled : styles.statusDisabled)}>{isActive ? 'ENABLED' : 'DISABLED'}</span>),
        value: videoEngine.id
      })
    }
  } else {
    // only add this if no video engines are loaded/returned (NB: shouldn't happen in normal use, but added just in case the new video engines data fails to load)
    // NB: if editing a project show it as keeping the 'current' server rather than the 'default' one (incase its assigned a different server, but we can't currently tell with this fallback handling)
    const defaultTitle = (mode === ProjectFormMode.Edit ? 'Current ' : 'Default ') + OBJECT_VIDEO_ENGINE_NAME
    videoEngineOptions.push({ key: 've_default', text: defaultTitle, value: 0 })
    noVideoEnginesData = true
  }
  // video engine - custom dropdown trigger (selected item display)
  let selectedVideoEngine = videoEngines?.find((ve) => ve.id === projectValues.videoEngineId)
  const selectedVideoEngineIsActive: boolean | undefined = selectedVideoEngine?.isActive ?? undefined
  const selectedVideoEngineNone = projectValues.videoEngineId === -1
  if (projectValues.videoEngineId === -1) {
    selectedVideoEngine = { id: -1, name: ('[No ' + OBJECT_VIDEO_ENGINE_NAME + ']').toUpperCase(), isActive: true, domain: '', ip: '', companyId: undefined } as CompanyVideoEngine
  }
  const videoEngineDropdownTrigger = selectedVideoEngine
    ? (
      <div className={styles.videoEngineDropdownTrigger + ' ' + (selectedVideoEngineNone ? styles.videoEngineNone : '') + ' item'}>
        <span className={'text'}>{selectedVideoEngine.name}</span>
        {selectedVideoEngineIsActive !== undefined && (<span className={'description' + ' ' + styles.status + ' ' + (selectedVideoEngineIsActive ? styles.statusEnabled : styles.statusDisabled)}>{selectedVideoEngineIsActive ? 'ENABLED' : 'DISABLED'}</span>)}
      </div>
    )
    : null
  // project edit/update only - show the current video engine (non editable)
  let videoEngineCurrentDisplay = 'N/A'
  if (selectedVideoEngine) {
    videoEngineCurrentDisplay = selectedVideoEngine.name + (selectedVideoEngine.isActive ? ' (ENABLED)' : ' (DISABLED)')
  }

  formFields.push(
    {
      type: ArkFormFieldType.Group,
      key: 'detailsAndFlagsGroup',
      fields: [
        {
          type: ArkFormFieldType.Fieldset,
          key: 'detailsFieldset',
          label: OBJECT_PROJECT_NAME + ' details',
          className: styles.detailsFieldset,
          fields: [
            {
              type: ArkFormFieldType.Input,
              key: 'name',
              label: OBJECT_PROJECT_NAME + ' name',
              required: true,
              defaultValue: project?.name ?? undefined,
              className: hasChanges('name') ? styles.hasChanged : undefined
            },
            {
              type: ArkFormFieldType.Input,
              key: 'desc',
              label: 'Description',
              required: false,
              defaultValue: project?.desc ?? undefined,
              className: hasChanges('desc') ? styles.hasChanged : undefined
            }
          ],
          fieldProps: { style: { flexGrow: 1, flexBasis: '67%', minWidth: '200px' } },
          collapsible: false,
          collapsed: false
        },
        {
          type: ArkFormFieldType.Fieldset,
          key: 'settingsFieldset',
          label: OBJECT_PROJECT_NAME + ' Settings',
          className: styles.flagsFieldset,
          fields: [
            ...((mode === ProjectFormMode.Add) // only allow project video engine selection when adding/creating a new project (we use a dedicated form for editing the video engine of an existing project)
              ? [{
                type: ArkFormFieldType.Dropdown,
                key: 'videoEngineId',
                label: OBJECT_VIDEO_ENGINE_NAME,
                required: false,
                // defaultValue: !noVideoEnginesData ? (mode === ProjectFormMode.Edit ? project?.videoEngine?.id : _defaultVideoEngineId) : 0, // if no video engines are loaded we show a generic 'default' entry instead with an id/value of 0, set that to show/select
                defaultValue: !noVideoEnginesData ? _defaultVideoEngineId : 0, // if no video engines are loaded we show a generic 'default' entry instead with an id/value of 0, set that to show/select
                options: videoEngineOptions,
                disabled: noVideoEnginesData,
                className: styles.videoEngineDropdown + (hasChanges('videoEngineId') ? ' ' + styles.hasChanged : ''),
                fieldProps: {
                  scrolling: true, // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
                  trigger: videoEngineDropdownTrigger,
                  onClick: () => {
                    console.log('ProjectForm - videoEngineId - onClick')
                  },
                  type: undefined
                }
              }]
              : []),
            ...((mode === ProjectFormMode.Edit) // when editing an existing project just show the current video engine (non editable) with a button to load a dedicated sub-form for project VE migration
              ? [
                {
                  type: ArkFormFieldType.Group,
                  key: 'videoEngineGroup',
                  className: styles.videoEngineGroup,
                  fields: [
                    {
                      type: ArkFormFieldType.Field,
                      key: 'videoEngineCurrent',
                      wrapperProps: { className: styles.videoEngineLabelField },
                      content: (
                        (<div className={'field' + ' ' + styles.videoEngineLabel}>
                          <label>{OBJECT_VIDEO_ENGINE_NAME}:</label>
                          <span>{videoEngineCurrentDisplay}</span>
                        </div>))
                    },
                    {
                      type: ArkFormFieldType.Button,
                      key: 'videoEngineEditBtn',
                      label: 'Edit',
                      required: false,
                      className: styles.videoEngineEditBtn,
                      fieldProps: { size: 'small', onClick: showVEModal }
                    }
                  ]
                  // fieldProps: { widths: 'equal' }
                }
              ]
              : []),
            ...(isSiteAdmin // only show the max users field if the user is a site admin
              ? [{
                type: ArkFormFieldType.NumberInput,
                key: 'maxUsers',
                label: OBJECT_PROJECT_NAME + ' Max Users', // (<label className={styles.flagLabel}><div className={styles.flagTitle}><span>Max Users</span></div></label>),
                required: false,
                defaultValue: project?.projectMaxUsers ?? undefined,
                wrapperProps: { className: styles.flagWrapper },
                className: hasChanges('maxUsers') ? styles.hasChanged : undefined,
                fieldProps: { width: '112px', darkMode: false }
              }]
              : [])
          ],
          fieldProps: { style: { flexGrow: 1, flexBasis: '33%', minWidth: '200px' } },
          collapsible: false,
          collapsed: false
        }
      ],
      fieldProps: { widths: 'equal', style: { justifyContent: 'space-between', gap: '10px' } }
    }
  )

  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      {
        type: ArkFormFieldType.CancelButton,
        key: 'cancel',
        label: 'CANCEL',
        fieldProps: { onClick: _onCancel, floated: 'left' }
      },
      {
        type: ArkFormFieldType.OKButton,
        key: 'submit',
        label: (mode === ProjectFormMode.Edit ? 'SAVE' : 'CREATE'),
        fieldProps: { loading: isSubmitting, floated: 'right' },
        disabled: changes.length === 0
      }
    ],
    fieldProps: { widths: 'equal' }
  })

  return (
    <>
      <ArkHeader as="h2" inverted>
        {mode === ProjectFormMode.Edit ? 'Edit' : 'Add'} {OBJECT_PROJECT_NAME}
      </ArkHeader>

      {hasSaved && (<>
        <ArkMessage positive>
          <ArkMessage.Header>{OBJECT_PROJECT_NAME} {mode === ProjectFormMode.Edit ? 'Updated' : 'Created'}</ArkMessage.Header>
          <ArkMessage.Item>The {OBJECT_PROJECT_NAME} has been {mode === ProjectFormMode.Edit ? 'updated' : 'created'} successfully</ArkMessage.Item>
        </ArkMessage>
        <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={_onClose} style={{ marginTop: 15 }}>
          OK
        </ArkButton>
      </>)}

      {!hasSaved && (
        <ArkForm
          className={styles.projectForm}
          formKey="projectForm"
          inverted
          formError={error ?? videoEngineError}
          formFields={formFields}
          formSchema={formSchema}
          onFormSubmit={onFormSubmit}
          onValueChanged={onValueChanged}
          shouldEnterKeySubmit={shouldEnterKeySubmit}
          tabFocusEnabled={!showVEFormModal}
          showLabels={true}
          insideModal={insideModal}
        />)}

      {renderVEModal()}
    </>
  )
}

export default ProjectForm
