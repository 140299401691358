/**
 * CompanyGroupListItem
 */

import React from 'react'

import { CompanyGroup } from 'src/core/models'

import CompanyGroupAccessToggle from './CompanyGroupAccessToggle'

import ArkListItem, { ArkListItemDetail } from 'src/core/components/ArkListItem'
import ArkSpacer from 'src/core/components/ArkSpacer'

import styles from './CompanyGroupListItem.module.css'

interface IProps {
  companyId: number
  group: CompanyGroup
  active: boolean
  filter?: string
  elementId?: string
  onClick: () => void
  onEditClick?: () => void
  onGroupUpdated: (group: CompanyGroup) => void
}

const CompanyGroupListItem = (props: IProps) => {
  const {
    companyId,
    group,
    active,
    filter,
    elementId,
    onClick,
    onEditClick,
    onGroupUpdated
  } = props

  const rightComponent = (
    <ArkListItemDetail
      hintMessage='...' // TODO: <<<<
      hintTitle='Toggle Enable/Disable group access'
      title='Access'
    >
      <CompanyGroupAccessToggle
        companyId={companyId}
        group={group}
        onChange={(value: boolean) => {
          console.log('CompanyGroupListItem - CompanyGroupAccessToggle - onChange - value:', value)
          if (value !== group.enabled) {
            group.enabled = value
            onGroupUpdated(group)
          }
        }}
        showStatusText={false}
      />
    </ArkListItemDetail>
  )

  const rightComponentDesktop = (
    <>
      <ArkListItemDetail horizontal title='Users:'>
        <div className={styles.detailText}>{group.usersCount}</div>
      </ArkListItemDetail>
      <ArkSpacer />
    </>
  )

  return (
    <ArkListItem
      active={active}
      className={styles.listItem}
      description={group.desc}
      disabled={!group.enabled}
      filter={filter}
      id={elementId}
      onClick={onClick}
      onEditClick={onEditClick}
      rightComponent={rightComponent}
      rightComponentDesktop={rightComponentDesktop}
      title={group.name}
    />
  )
}

export default CompanyGroupListItem
