import React, { useContext, useEffect, useState } from 'react'

import { CompanyAdminContext, UserContext } from 'src/core/providers'
import { Company, UserCompany } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkHint, { HintType, PopupPosition, PopupSize } from 'src/core/components/ArkHint'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkNumberInput from 'src/core/components/ArkNumberInput'
import ArkPanel from 'src/core/components/ArkPanel'

import { OBJECT_COMPANY_NAME, OBJECT_PROJECT_NAME, PAGE_SETTINGS_NAME } from 'src/constants/strings'

import styles from './CompanySettingsView.module.css'

export interface CompanyProjectSettingsViewProps {
  company: Company | UserCompany
}

const CompanyProjectSettingsView = (props: CompanyProjectSettingsViewProps) => {
  const { company } = props

  const userContext = useContext(UserContext)
  const companyAdminContext = useContext(CompanyAdminContext)

  const isSiteAdmin = userContext.actions.isSiteAdmin()
  const isCompanyAdminOrHigher = userContext.actions.isCompanyAdminOrHigher()

  const [saving, setSaving] = useState<boolean>(false)
  const [saveResult, setSaveResult] = useState<boolean>(false)
  const [saveError, setSaveError] = useState<Error | undefined>(undefined)
  const [saveWarnings, setSaveWarnings] = useState<Array<Error> | undefined>(undefined)

  // individual settings specific to this form
  const [companyValues, setCompanyValues] = useState({
    maxGuestsPerProject: company.maxGuestsPerProject ?? 0
  })
  const [companyValuesSaved, setCompanyValuesSaved] = useState({
    maxGuestsPerProject: company.maxGuestsPerProject ?? 0
  })
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])

  // -------

  const companySettingsChanges = () => {
    const _changes: Array<string> = []
    if (companyValues) {
      for (const fieldName of Object.keys(companyValues)) {
        const oldValue = companyValuesSaved !== undefined ? (companyValuesSaved as any)[fieldName] : undefined
        const newValue = (companyValues as any)[fieldName]
        if (oldValue !== newValue) {
          _changes.push(fieldName)
        }
      }
    }
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  const resetSaveResults = () => {
    setSaveResult(false)
    setSaveError(undefined)
    setSaveWarnings(undefined)
  }

  const numberElement = (valueKey: string, siteAdminOnly: boolean = false) => {
    const value = (companyValues as any)[valueKey]
    if ((siteAdminOnly && !isSiteAdmin) || !isCompanyAdminOrHigher) return value ?? 'NO LIMIT' // just display the value if the user isn't a site admin
    return (
      <ArkNumberInput
        className={styles.numberInput}
        value={'' + value}
        minValue={0}
        maxValue={10}
        allowEmptyValue={true}
        size='mini'
        maxLength={3}
        onChange={(newValue: string) => {
          console.log('CompanyProgramSettings - numberElement - onChange - newValue: ', newValue)
          const newValueInt = newValue.length > 0 ? parseInt(newValue) : 0
          setCompanyValues({
            ...companyValues,
            [valueKey]: newValueInt
          })
          resetSaveResults()
        }}
      />
    )
  }

  const hintElement = (hintType: HintType, hintIconSize: number, hintPopupSize: PopupSize, hintTitle: string, hintMessage: string, hintPopupPosition: PopupPosition) => {
    return (
      <ArkHint type={hintType} iconSize={hintIconSize} popupSize={hintPopupSize} title={hintTitle} message={hintMessage} popupPosition={hintPopupPosition}></ArkHint>
    )
  }

  // -------

  const onSave = async () => {
    const companyId = userContext.store.selectedCompany?.id
    if (!companyId) {
      // TODO: handle if no company is selected? (shouldn't be if this page is showing/loaded?)
      return
    }
    const changedValues: {[key: string]: any} = {}
    let changesCount = 0
    for (const fieldName of changes) {
      const newValue = (companyValues as any)[fieldName]
      changedValues[fieldName] = newValue
      changesCount++
    }
    console.log('CompanyProgramSettings - onSave - changedValues: ', changedValues, ' changesCount: ', changesCount)
    if (changesCount > 0) {
      resetSaveResults()
      setSaving(true)
      // await new Promise((resolve) => setTimeout(resolve, 1000)) // DEBUG ONLY
      try {
        // update/save the company info - returns a new Company object with the latest values
        const updateResult = await companyAdminContext.actions.updateCompanyInfo(companyId, changedValues)
        const newCompanyValuesSaved = { maxGuestsPerProject: updateResult.company?.maxGuestsPerProject ?? 0 }
        setCompanyValuesSaved(newCompanyValuesSaved)
        setCompanyValues(newCompanyValuesSaved) // reset the form back to the saved values
        setChanges([])
        setSaveResult(true)
        // TODO: only show a success if no warnings as well? (for the currently single maxGuestsPerProject we might not get any warnings, but if we add others here we'll likely have to consider it?)
        // check for warnings
        if (updateResult.warnings) {
          setSaveWarnings(updateResult.warnings)
          // setSaveWarnings([...updateResult.warnings, new Error('DEBUG')]) // DBG ONLY - simulate multiple warnings
        }
      } catch (error) {
        console.error('CompanyProgramSettings - onSave - error: ', error)
        setSaveResult(false)
        setSaveError(error)
      }
      setSaving(false)
    }
  }

  const onReset = async () => {
    setCompanyValues(companyValuesSaved) // reset the form data back to the saved values
    setChanges([])
    resetSaveResults()
  }

  // -------

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = companySettingsChanges()
    setChanges(_changes)
  }, [companyValues])

  // -------

  // if (loading) return <ArkLoaderView message='Loading' />
  return (
    <div className={styles.transcoderSettings}>
      <div className={styles.content}>
        <ArkPanel bordered>
          <ArkPanel.Header title={OBJECT_COMPANY_NAME + ' ' + OBJECT_PROJECT_NAME + ' ' + PAGE_SETTINGS_NAME} bottomBorder className={styles.transcoderHeader}></ArkPanel.Header>
          <>
            <ArkPanel.Properties>
              {(saveResult || saveError || saveWarnings) && (
                <>
                  {saveResult && (
                    <ArkPanel.PropertyRow>
                      <ArkPanel.PropertyRowMessage positive>
                        <ArkMessage.Header>Saved</ArkMessage.Header>
                        <p>Your changes have been saved</p>
                      </ArkPanel.PropertyRowMessage>
                    </ArkPanel.PropertyRow>
                  )}
                  {saveError && (
                    <ArkPanel.PropertyRow>
                      <ArkPanel.PropertyRowMessage negative>
                        <ArkMessage.Header>Error</ArkMessage.Header>
                        <p>{saveError.message}</p>
                      </ArkPanel.PropertyRowMessage>
                    </ArkPanel.PropertyRow>
                  )}
                  {saveWarnings && saveWarnings.length > 0 && (
                    <ArkPanel.PropertyRow>
                      <ArkPanel.PropertyRowMessage warning>
                        <ArkMessage.Header>Warning</ArkMessage.Header>
                        {saveWarnings.length === 1 && (
                          <p>{saveWarnings[0].message}</p>
                        )}
                        {saveWarnings.length > 1 && (
                          <ArkMessage.List>
                            {saveWarnings.map((saveWarning: Error, index: number) => (
                              <ArkMessage.Item key={'warning_' + index}>{saveWarning.message}</ArkMessage.Item>
                            ))}
                          </ArkMessage.List>
                        )}
                      </ArkPanel.PropertyRowMessage>
                    </ArkPanel.PropertyRow>
                  )}
                </>
              )}
              {/* <ArkPanel.PropertyRow
                title='Current Programs:'
                value={companyCounts?.programsCount ?? 'N/A'}
                hasChanges={false}
              /> */}
              <ArkPanel.PropertyRow
                title='Max Guest-links:'
                value={numberElement('maxGuestsPerProject', true)}
                hint={hintElement(
                  'info-circle',
                  22,
                  'small',
                  'Maximum guests per project for this organisation',
                  '', // TODO: <<<<
                  'bottom left'
                )}
                hasChanges={hasChanges('maxGuestsPerProject')}
                titleSize={'xlarge'}
              />
            </ArkPanel.Properties>
            {isCompanyAdminOrHigher && (
              <ArkPanel.Footer className={styles.transcoderFooter} topBorder>
                <div className={styles.footerWrapper}>
                  {changes.length === 0 && (<div></div>)}
                  <div className={styles.buttons}>
                    <ArkButton type='button' size='medium' color='red' disabled={changes.length === 0 || saving} onClick={onReset}>CLEAR CHANGES</ArkButton>
                    <ArkButton type='button' size='medium' color='green' disabled={changes.length === 0} loading={saving} onClick={onSave}>SAVE CHANGES</ArkButton>
                  </div>
                </div>
              </ArkPanel.Footer>
            )}
          </>
        </ArkPanel>
      </div>
    </div>
  )
}

export { CompanyProjectSettingsView }
