import React from 'react'

import ArkViewerPage from '../../components/ArkViewerPage/ArkViewerPage'
import ArkCompanySelectView from '../../../core/components/ArkCompanySelectView'

class ViewerCompaniesSelectPage extends React.Component {
  render () {
    return (
      <ArkViewerPage disableSectionMainMenu>
        <ArkCompanySelectView />
      </ArkViewerPage>
    )
  }
}

export default ViewerCompaniesSelectPage
