import React, { useContext, useEffect, useState } from 'react'

import { CompanyAdminContext, UserContext } from 'src/core/providers'
import { CompanyTranscoderSettings } from 'src/core/models/transcoder_settings'
import { CompanyCounts } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkHint, { HintType, PopupPosition, PopupSize } from 'src/core/components/ArkHint'
import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkNumberInput from 'src/core/components/ArkNumberInput'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkRadio, { ArkRadioProps } from 'src/core/components/ArkRadio'

import CompanyProgramTranscodeSyncView from './CompanyProgramTranscodeSyncView'

import { OBJECT_COMPANY_NAME, OBJECT_PROGRAM_NAME, PAGE_SETTINGS_NAME } from 'src/constants/strings'

import styles from './CompanySettingsView.module.css'

const CompanyProgramSettingsView = () => {
  const userContext = useContext(UserContext)
  const companyAdminContext = useContext(CompanyAdminContext)

  const isSiteAdmin = userContext.actions.isSiteAdmin()
  const isCompanyAdminOrHigher = userContext.actions.isCompanyAdminOrHigher()

  const [loading, setLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<Error | undefined>(undefined)

  const [saving, setSaving] = useState<boolean>(false)
  const [saveResult, setSaveResult] = useState<boolean>(false)
  const [saveError, setSaveError] = useState<Error | undefined>(undefined)
  const [saveWarnings, setSaveWarnings] = useState<Array<Error> | undefined>(undefined)

  // NB: we track required vs forced sync handling separately
  const [isSyncing, setIsSyncing] = useState<boolean>(false)
  const [syncMode, setSyncMode] = useState<undefined | 'required' | 'force'>(undefined)
  const [syncRequiredResult, setSyncRequiredResult] = useState<boolean | undefined>(undefined)
  const [syncRequiredError, setSyncRequiredError] = useState<Error | undefined>(undefined)
  const [syncForcedResult, setSyncForcedResult] = useState<boolean | undefined>(undefined)
  const [syncForcedError, setSyncForcedError] = useState<Error | undefined>(undefined)

  const [projectsNeedSyncCount, setProjectsNeedSyncCount] = useState<number | undefined>(undefined)
  const [projectsTotalCount, setProjectsTotalCount] = useState<number | undefined>(undefined)

  // current company transcoder settings
  const [transcoderSettings, setTranscoderSettings] = useState<CompanyTranscoderSettings | undefined>(undefined)
  // individual program transcoder settings specific to this form
  const [transcoderValues, setTranscoderValues] = useState({
    allowSLDPPassthrough: false,
    allowSRTPassthrough: false,
    allowABR1080: false,
    allowABR720: false,
    allowWatermarking: false,
    maxProgramsGPU: 0
  })
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])
  // additional company related data
  const [companyCounts, setCompanyCounts] = useState<CompanyCounts | undefined>(undefined)

  // -------

  const transcoderSettingsChanges = () => {
    const _changes: Array<string> = []
    if (transcoderValues) {
      for (const fieldName of Object.keys(transcoderValues)) {
        const oldValue = transcoderSettings !== undefined ? (transcoderSettings as any)[fieldName] : undefined
        const newValue = (transcoderValues as any)[fieldName]
        if (oldValue !== newValue) {
          _changes.push(fieldName)
        }
      }
    }
    return _changes
  }

  const dbgChanges = () => {
    if (changes) {
      console.log('CompanyProgramSettings - dbgChanges - changes: ', changes)
      for (const fieldName of changes) {
        // console.log('CompanyProgramSettings - dbgChanges - fieldName: ', fieldName)
        const oldValue = transcoderSettings !== undefined ? (transcoderSettings as any)[fieldName] : undefined
        const newValue = (transcoderValues as any)[fieldName]
        console.log('CompanyProgramSettings - dbgChanges - fieldName: ', fieldName, ' oldValue: ', oldValue, ' newValue: ', newValue)
      }
    }
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  const resetLoadingResults = () => {
    setLoadingError(undefined)
  }

  const resetSaveResults = () => {
    setSaveResult(false)
    setSaveError(undefined)
    setSaveWarnings(undefined)
  }

  const resetSyncResults = () => {
    setSyncMode(undefined)
    setSyncRequiredResult(undefined)
    setSyncRequiredError(undefined)
    setSyncForcedResult(undefined)
    setSyncForcedError(undefined)
  }

  const toggleElement = (valueKey: string, siteAdminOnly: boolean = false) => {
    const value = (transcoderValues as any)[valueKey]
    if ((siteAdminOnly && !isSiteAdmin) || !isCompanyAdminOrHigher) return (value ? 'ENABLED' : <span className={styles.propertyValueOff}>DISABLED</span>) // just display the value if the user isn't a site admin
    return (
      <ArkRadio
        toggle
        checked={value}
        onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>, data: ArkRadioProps) => {
          console.log('CompanyProgramSettings - toggleElement - onClick - data: ', data)
          // TODO: likely prompt the user to confirm the action before triggering the relevant api call & updating the toggle value here
          // TODO: will need to track the form specific value in the state here so we can actually toggle/flip it...
        }}
        onChange={(event: React.FormEvent<HTMLInputElement>, data: ArkRadioProps) => {
          console.log('CompanyProgramSettings - toggleElement - onChange - data: ', data)
          setTranscoderValues({
            ...transcoderValues,
            [valueKey]: data.checked
          })
          resetSaveResults()
          resetSyncResults()
          // NB: see the useEffect that runs transcoderSettingsChangeCount & updates setProgramChangeCount from it after the setValue state update completes
        }}
      />
    )
  }

  const numberElement = (valueKey: string, siteAdminOnly: boolean = false) => {
    const value = (transcoderValues as any)[valueKey]
    if ((siteAdminOnly && !isSiteAdmin) || !isCompanyAdminOrHigher) return value ?? 'NO LIMIT' // just display the value if the user isn't a site admin
    return (
      <ArkNumberInput
        className={styles.numberInput}
        value={'' + value}
        minValue={0}
        maxValue={100}
        allowEmptyValue={true}
        size='mini'
        maxLength={3}
        onChange={(newValue: string) => {
          console.log('CompanyProgramSettings - numberElement - onChange - newValue: ', newValue)
          const newValueInt = newValue.length > 0 ? parseInt(newValue) : 0
          setTranscoderValues({
            ...transcoderValues,
            [valueKey]: newValueInt
          })
          resetSaveResults()
          resetSyncResults()
        }}
      />
    )
  }

  const hintElement = (hintType: HintType, hintIconSize: number, hintPopupSize: PopupSize, hintTitle: string, hintMessage: string, hintPopupPosition: PopupPosition) => {
    return (
      <ArkHint type={hintType} iconSize={hintIconSize} popupSize={hintPopupSize} title={hintTitle} message={hintMessage} popupPosition={hintPopupPosition}></ArkHint>
    )
  }

  // -------

  const _applyCompanyTranscoderSettings = (_transcoderSettings?: CompanyTranscoderSettings) => {
    setTranscoderSettings(_transcoderSettings)
    if (_transcoderSettings) {
      setTranscoderValues({
        ...transcoderValues,
        allowSLDPPassthrough: _transcoderSettings.allowSLDPPassthrough,
        allowSRTPassthrough: _transcoderSettings.allowSRTPassthrough,
        allowABR1080: _transcoderSettings.allowABR1080,
        allowABR720: _transcoderSettings.allowABR720,
        allowWatermarking: _transcoderSettings.allowWatermarking,
        maxProgramsGPU: _transcoderSettings.maxProgramsGPU
      })
    }
  }

  const loadCompanyProjectSyncCounts = () => {
    // NB: only works for company admins or site admin (lower permission users don't have access to all projects to get the counts)
    const adminProjects = userContext.store.adminProjects
    console.log('CompanyProgramSettings - loadCompanyProjectSyncCounts - adminProjects: ', adminProjects)
    let projectSyncCount = 0
    if (adminProjects) {
      for (const adminProject of adminProjects) {
        if (adminProject.transcoderSynced === false) {
          projectSyncCount++
        }
      }
      setProjectsNeedSyncCount(projectSyncCount)
      setProjectsTotalCount(adminProjects.length)
    }
    console.log('CompanyProgramSettings - loadCompanyProjectSyncCounts - projectSyncCount: ', projectSyncCount)
  }

  const loadCompanyInfo = async () => {
    console.log('CompanyProgramSettings - loadCompanyInfo')
    if (loading) return // halt/ignore if already loading
    const companyId = userContext.store.selectedCompany?.id
    if (!companyId) {
      // TODO: handle if no company is selected? (shouldn't be if this page is showing/loaded?)
      return
    }
    resetLoadingResults()
    setLoading(true)
    try {
      const company = await companyAdminContext.actions.getCompanyInfo(companyId)
      console.log('CompanyProgramSettings - loadCompanyInfo - company: ', company)
      _applyCompanyTranscoderSettings(company?.transcoderSettings)
      setCompanyCounts(company?.counts)
    } catch (error) {
      setLoadingError(error)
    }
    setLoading(false)
  }

  const onSave = async () => {
    if (isSyncing) return // don't allow saving while a sync is going on
    const companyId = userContext.store.selectedCompany?.id
    if (!companyId) {
      // TODO: handle if no company is selected? (shouldn't be if this page is showing/loaded?)
      return
    }
    const changedValues: {[key: string]: any} = {}
    let changesCount = 0
    for (const fieldName of changes) {
      const newValue = (transcoderValues as any)[fieldName]
      changedValues[fieldName] = newValue
      changesCount++
    }
    console.log('CompanyProgramSettings - onSave - changedValues: ', changedValues, ' changesCount: ', changesCount)
    if (changesCount > 0) {
      resetSaveResults()
      resetSyncResults()
      setSaving(true)
      // await new Promise((resolve) => setTimeout(resolve, 1000)) // DEBUG ONLY
      try {
        // update/save the company info - returns a new Company object with the latest values
        const updateResult = await companyAdminContext.actions.updateCompanyInfo(companyId, changedValues)
        const company = updateResult.company
        _applyCompanyTranscoderSettings(company?.transcoderSettings)
        console.log('CompanyProgramSettings - onSave - company: ', company)
        setSaveResult(true)
        // check for warnings
        if (updateResult.warnings) {
          setSaveWarnings(updateResult.warnings)
          // setSaveWarnings([...updateResult.warnings, new Error('DEBUG')]) // DBG ONLY - simulate multiple warnings
        }
      } catch (error) {
        console.error('CompanyProgramSettings - onSave - error: ', error)
        setSaveResult(false)
        setSaveError(error)
      }
      setSaving(false)
    }
  }

  const onReset = async () => {
    _applyCompanyTranscoderSettings(transcoderSettings)
    resetSaveResults()
    resetSyncResults()
  }

  const onSyncTranscoder = async (force?: boolean) => {
    if (!isCompanyAdminOrHigher || isSyncing) return
    const companyId = userContext.store.selectedCompany?.id
    if (!companyId) return
    try {
      console.log('CompanyProgramSettings - onSyncTranscoder - force:', force)
      resetSyncResults()
      setIsSyncing(true)
      setSyncMode(force ? 'force' : 'required')
      if (!force) {
        setSyncRequiredResult(undefined)
        setSyncRequiredError(undefined)
      } else {
        setSyncForcedResult(undefined)
        setSyncForcedError(undefined)
      }
      const _syncResult = await companyAdminContext.actions.syncCompanyTranscoderSettingsToAllProjects(companyId, force)
      console.log('CompanyProgramSettings - onSyncTranscoder - _syncResult: ', _syncResult)
      // DEBUG ONLY - START
      // await new Promise((resolve) => setTimeout(resolve, 1000)) // DEBUG ONLY
      // // throw new Error('DEBUG')
      // const _syncResult = true
      // DEBUG ONLY - END
      if (!force) {
        setSyncRequiredResult(_syncResult)
      } else {
        setSyncForcedResult(_syncResult)
      }
    } catch (error) {
      console.error('CompanyProgramSettings - onSyncTranscoder - error: ', error)
      if (!force) {
        setSyncRequiredResult(undefined)
        setSyncRequiredError(error)
      } else {
        setSyncForcedResult(undefined)
        setSyncForcedError(error)
      }
    }
    setIsSyncing(false)
    setSyncMode(undefined)
  }

  // -------

  // load the company info on component init/mount
  // NB: run an async function via useEffect - ref: https://stackoverflow.com/a/53572588
  useEffect(() => {
    async function loadAsync () {
      await loadCompanyInfo()
    }
    loadAsync()
  }, [])

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = transcoderSettingsChanges()
    setChanges(_changes)
  }, [transcoderValues])

  // listen out for changes to admin projects
  useEffect(() => {
    loadCompanyProjectSyncCounts()
  }, [userContext.store.adminProjects])

  // DBG ONLY: log out when a field is changed from its saved default
  useEffect(() => {
    dbgChanges()
  }, [changes])

  // -------

  // TODO: what if a company currently has 0 projects? (nothing to sync & so should just halt with null?)
  // const showTranscoderSync = isCompanyAdminOrHigher && ((projectsNeedSyncCount !== undefined && projectsNeedSyncCount > 0) || (syncResult !== undefined || syncError !== undefined))
  const showTranscoderSync = isCompanyAdminOrHigher && projectsTotalCount !== undefined && projectsTotalCount > 0 // don't show transcoder sync if no projects (even the force sync option), as nothing to actually sync

  // -------

  if (loading) return <ArkLoaderView message='Loading' />
  return (
    <div className={styles.transcoderSettings}>
      <div className={styles.content}>
        <ArkPanel bordered>
          <ArkPanel.Header title={OBJECT_COMPANY_NAME + ' ' + OBJECT_PROGRAM_NAME + ' ' + PAGE_SETTINGS_NAME} bottomBorder className={styles.transcoderHeader}></ArkPanel.Header>
          {loading && !loadingError && (
            <ArkPanel.Properties>
              <ArkPanel.PropertyRow>
                <ArkPanel.PropertyRowContent>
                  <ArkLoaderView message='Loading' className={styles.loading} />
                </ArkPanel.PropertyRowContent>
              </ArkPanel.PropertyRow>
            </ArkPanel.Properties>
          )}
          {!loading && loadingError && (
            <ArkPanel.Properties>
              <ArkPanel.PropertyRow>
                <ArkPanel.PropertyRowMessage negative>
                  <ArkMessage.Header>Error</ArkMessage.Header>
                  <p>{loadingError.message}</p>
                </ArkPanel.PropertyRowMessage>
              </ArkPanel.PropertyRow>
            </ArkPanel.Properties>
          )}
          {!loading && !loadingError && (<>
            <ArkPanel.Properties>
              {(saveResult || saveError || saveWarnings) && (
                <>
                  {saveResult && (
                    <ArkPanel.PropertyRow>
                      <ArkPanel.PropertyRowMessage positive>
                        <ArkMessage.Header>Saved</ArkMessage.Header>
                        <p>Your changes have been saved</p>
                      </ArkPanel.PropertyRowMessage>
                    </ArkPanel.PropertyRow>
                  )}
                  {saveError && (
                    <ArkPanel.PropertyRow>
                      <ArkPanel.PropertyRowMessage negative>
                        <ArkMessage.Header>Error</ArkMessage.Header>
                        <p>{saveError.message}</p>
                      </ArkPanel.PropertyRowMessage>
                    </ArkPanel.PropertyRow>
                  )}
                  {saveWarnings && saveWarnings.length > 0 && (
                    <ArkPanel.PropertyRow>
                      <ArkPanel.PropertyRowMessage warning>
                        <ArkMessage.Header>Warning</ArkMessage.Header>
                        {saveWarnings.length === 1 && (
                          <p>{saveWarnings[0].message}</p>
                        )}
                        {saveWarnings.length > 1 && (
                          <ArkMessage.List>
                            {saveWarnings.map((saveWarning: Error, index: number) => (
                              <ArkMessage.Item key={'warning_' + index}>{saveWarning.message}</ArkMessage.Item>
                            ))}
                          </ArkMessage.List>
                        )}
                      </ArkPanel.PropertyRowMessage>
                    </ArkPanel.PropertyRow>
                  )}
                </>
              )}
              <ArkPanel.PropertyRow
                title='SLDP Passthrough:'
                value={toggleElement('allowSLDPPassthrough')}
                hint={hintElement(
                  'info-circle',
                  22,
                  'small',
                  'Low Delay Passthrough (Web and iOS app)',
                  'Allows a non-watermarked, non transcoded passthrough version that can viewed on web and iOS using low delay delivery method.',
                  'bottom left'
                )}
                hasChanges={hasChanges('allowSLDPPassthrough')}
                titleClassName={styles.propertyTitle}
                valueClassName={styles.propertyValue}
                titleSize={'xlarge'}
              />
              <ArkPanel.PropertyRow
                title='SRT Passthrough:'
                value={toggleElement('allowSRTPassthrough')}
                hint={hintElement(
                  'info-circle',
                  22,
                  'small',
                  'SRT Passthrough (iOS app only)',
                  'Allows a non-watermarked, non transcoded passthrough version that can viewed on iOS only using Secure Reliable Transport method.',
                  'bottom left'
                )}
                hasChanges={hasChanges('allowSRTPassthrough')}
                titleSize={'xlarge'}
              />
              <ArkPanel.PropertyDivider />
              <ArkPanel.PropertyRow
                title='ABR FHD:'
                value={toggleElement('allowABR1080', true)}
                hint={hintElement(
                  'info-circle',
                  22,
                  'small',
                  'Adaptive Bitrate up to 1080p',
                  'Adaptive Bitrate will allow various resolutions up to 1080p FHD to ensure video continues to stream at lower resolutions when bandwidth is restricted.',
                  'bottom left'
                )}
                hasChanges={hasChanges('allowABR1080')}
                titleSize={'xlarge'}
              />
              <ArkPanel.PropertyRow
                title='ABR HD:'
                value={toggleElement('allowABR720', true)}
                hint={hintElement(
                  'info-circle',
                  22,
                  'small',
                  'Adaptive Bitrate up to 720p',
                  'Adaptive Bitrate will allow various resolutions up to 720p HD to ensure video continues to stream at lower resolutions when bandwidth is restricted.',
                  'bottom left'
                )}
                hasChanges={hasChanges('allowABR720')}
                titleSize={'xlarge'}
              />
              <ArkPanel.PropertyRow
                title='Allow Watermarking:'
                value={toggleElement('allowWatermarking', true)}
                hint={hintElement(
                  'info-circle',
                  22,
                  'small',
                  'Protect ABR video with a watermark',
                  'Watermarks can be added to transcoded ABR video. Watermarks are not able to be shown on SLDP and SRT passthrough so to enforce watermark viewing SLDP and SRT passthrough need to be disabled above.',
                  'bottom left'
                )}
                hasChanges={hasChanges('allowWatermarking')}
                titleSize={'xlarge'}
              />
              <ArkPanel.PropertyRow
                title='Current Programs:'
                value={companyCounts?.programsCount ?? 'N/A'}
                hasChanges={false}
                titleSize={'xlarge'}
              />
              <ArkPanel.PropertyRow
                title='Max Programs:'
                value={numberElement('maxProgramsGPU', true)}
                hint={hintElement(
                  'info-circle',
                  22,
                  'small',
                  'Maximum programs for this organisation',
                  'Setting this will restrict how many programs an organisation can create across all projects. You can not set a limit that is lower than the current program count.',
                  'bottom left'
                )}
                hasChanges={hasChanges('maxProgramsGPU')}
                titleSize={'xlarge'}
              />
              {showTranscoderSync && (
                <>
                  <ArkPanel.PropertyDivider />
                  <ArkPanel.PropertyRow>
                    <ArkPanel.PropertyRowContent className={styles.transcoderSync}>
                      <div className={styles.transcoderSyncTitle}>Transcoder Settings Sync</div>
                      {/* NB: only show the 'x projects require syncing' prompt when one or more projects currently require it */}
                      {projectsNeedSyncCount !== undefined && projectsNeedSyncCount > 0 && (
                        <CompanyProgramTranscodeSyncView
                          className={styles.transcoderSyncRequired}
                          projectsTotalCount={projectsTotalCount}
                          projectsNeedSyncCount={projectsNeedSyncCount}
                          syncing={isSyncing && syncMode === 'required'}
                          allow={!isSyncing}
                          syncResult={syncRequiredResult}
                          syncError={syncRequiredError}
                          onSyncTranscoder={() => onSyncTranscoder(false)}
                        />
                      )}
                      {/* NB: show the 'force sync ALL projects' prompt at all times apart from when the required prompt above covers ALL projects */}
                      {/* {(!projectsNeedSyncCount || !projectsTotalCount || projectsNeedSyncCount < projectsTotalCount) && ( */}
                      <CompanyProgramTranscodeSyncView
                        className={styles.transcoderSyncForce}
                        projectsTotalCount={projectsTotalCount}
                        projectsNeedSyncCount={projectsNeedSyncCount}
                        syncing={isSyncing && syncMode === 'force'}
                        allow={!isSyncing}
                        syncResult={syncForcedResult}
                        syncError={syncForcedError}
                        onSyncTranscoder={() => onSyncTranscoder(true)}
                        force={true}
                      />
                      {/* )} */}
                    </ArkPanel.PropertyRowContent>
                  </ArkPanel.PropertyRow>
                </>
              )}
            </ArkPanel.Properties>
            {isCompanyAdminOrHigher && (
              <ArkPanel.Footer className={styles.transcoderFooter} topBorder>
                <div className={styles.footerWrapper}>
                  {/* {changes.length > 0 && (<>{changes.length} CHANGES: {changes.join(', ')}</>)} */}
                  {/* {changes.length === 0 && (<>NO CHANGES</>)} */}
                  {/* {changes.length > 0 && (
                    <div className={styles.saveChanges}>
                      Save changes made
                    </div>
                  )} */}
                  {changes.length === 0 && (<div></div>)}
                  <div className={styles.buttons}>
                    <ArkButton type='button' size='medium' color='red' disabled={changes.length === 0 || saving || isSyncing} onClick={onReset}>CLEAR CHANGES</ArkButton>
                    <ArkButton type='button' size='medium' color='green' disabled={changes.length === 0 || isSyncing} loading={saving} onClick={onSave}>SAVE CHANGES</ArkButton>
                  </div>
                </div>
              </ArkPanel.Footer>
            )}
          </>)}
        </ArkPanel>
      </div>
    </div>
  )
}

export { CompanyProgramSettingsView }
