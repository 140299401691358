import React, { ReactNode } from 'react'
import { Popup } from 'semantic-ui-react'

import { OnlineStatus } from 'src/core/models'
import {
  getChannelOnlineStatusDescription,
  getOnlineStatusColor,
  getOnlineStatusText
} from 'src/core/utilities/status'

import styles from './ArkOnlineStatusIndicator.module.css'

export interface ArkOnlineStatusIndicatorProps {
  className?: string;
  onlineStatus: OnlineStatus
  size?: number;
  variant: 'channel' | 'program'
}

const ArkOnlineStatusIndicator = (props: ArkOnlineStatusIndicatorProps) => {
  const trigger: ReactNode = (
    <div className={props.className}>
      <div className={styles.container}>
        <div
          className={styles.indicator}
          style={{
            backgroundColor: getOnlineStatusColor(props.onlineStatus),
            ...props.size && {
              borderWidth: props.size / 10,
              height: props.size,
              width: props.size
            }
          }}
        />
      </div>
    </div>
  )

  return (
    <Popup inverted position="right center" trigger={trigger}>
      {props.variant === 'channel'
        ? getChannelOnlineStatusDescription(props.onlineStatus)
        : getOnlineStatusText(props.onlineStatus)
      }
    </Popup>
  )
}

export default ArkOnlineStatusIndicator
