import React from 'react'

import styles from './WatermarkScreenBackground.module.css'

import SMPTEHDBarsImg from 'src/assets/screen/smpte-hd-bars.png' // ref: https://commons.wikimedia.org/wiki/File:SMPTE_Color_Bars_16x9.svg
import ColorHDBarsImg from 'src/assets/screen/test-background-colour-1920x1080.jpg'
import GreyscaleHDBarsImg from 'src/assets/screen/test-background-greyscale-zigzags-1920x1080.jpg'

export enum WatermarkScreenBackgroundMode {
  Colour, // uniform flat colour
  // SMPTEBars, // traditional - ref: SMPTE Engineering Guideline EG 1-1990
  // SMPTEHDBars, // modern HD - ref: SMPTE RP 219-2002
  // Countdown, // animated countdown timer
  Image, // allows any image to be set as a background
}

export enum WatermarkScreenBackgroundPreset {
  ColourWhite,
  ColourGrey,
  ColourBlack,
  Image01,
  Image02,
  Image03
}

export interface WatermarkScreenBackgroundProps {
  size: { width: number, height: number }
  preset?: WatermarkScreenBackgroundPreset
  mode?: WatermarkScreenBackgroundMode
  colour?: string // NB: mode must equal .Colour
  image?: string // NB: mode must equal .Image
  opacity?: number
  children?: React.ReactNode
  className?: string
}

const WatermarkScreenBackground = (props: WatermarkScreenBackgroundProps) => {
  const { size, preset, mode: _mode, colour: _colour, image: _image, opacity, children, className } = props

  let mode = _mode ?? WatermarkScreenBackgroundMode.Colour
  let colour = _colour // ?? '#ffffff'
  let image = _image

  // NB: preset's take priority over indivdual args
  if (preset !== undefined) {
    switch (preset) {
      case WatermarkScreenBackgroundPreset.ColourWhite:
        mode = WatermarkScreenBackgroundMode.Colour
        colour = '#ffffff'
        break
      case WatermarkScreenBackgroundPreset.ColourGrey:
        mode = WatermarkScreenBackgroundMode.Colour
        colour = '#333333'
        break
      case WatermarkScreenBackgroundPreset.ColourBlack:
        mode = WatermarkScreenBackgroundMode.Colour
        colour = '#000000'
        break
      case WatermarkScreenBackgroundPreset.Image01:
        mode = WatermarkScreenBackgroundMode.Image
        image = `url(${SMPTEHDBarsImg})`
        break
      case WatermarkScreenBackgroundPreset.Image02:
        mode = WatermarkScreenBackgroundMode.Image
        image = `url(${ColorHDBarsImg})`
        break
      case WatermarkScreenBackgroundPreset.Image03:
        mode = WatermarkScreenBackgroundMode.Image
        image = `url(${GreyscaleHDBarsImg})`
        break
    }
  }

  let style: React.CSSProperties = {}
  switch (mode) {
    case WatermarkScreenBackgroundMode.Colour:
      style = {
        backgroundColor: colour,
        opacity: opacity ?? 1.0
      }
      break
    case WatermarkScreenBackgroundMode.Image:
      style = {
        backgroundImage: image,
        backgroundSize: 'contain',
        opacity: opacity ?? 1.0
      }
      break
  }
  return (
    <div
      className={`${styles.screenBG}${className ? ' ' + className : ''}`}
      style={{
        width: size.width,
        height: size.height,
        ...style
      }}
    >
      {children}
    </div>
  )
}

export default WatermarkScreenBackground
