import React, { useEffect, useState } from 'react'
import { RowData, RowSelectionState, createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { IVideoEngineLiveStream } from 'src/admin/pages/streamhub/models/StreamhubVideoEngineModels'
import { STREAMHUB_SERVERS } from '../../config/config'

// import ArkCheckbox from 'src/core/components/ArkCheckbox'
// import { CheckboxProps } from 'semantic-ui-react'

import styles from '../Streamhub.module.css'

// extend the column meta object to include custom values - ref: https://tanstack.com/table/v8/docs/api/core/column-def#meta
declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    cellClassName?: string
    headerClassName?: string
  }
}

export enum StreamhubVideoEngineLiveStreamsActionType {
}

export type StreamhubVideoEngineLiveStreamsActionCallback = (liveStreamId: string, actionType: StreamhubVideoEngineLiveStreamsActionType) => Promise<boolean | undefined>
export type StreamhubVideoEngineLiveStreamsSelectionChangeCallback = (selectedLiveStreamIds: Array<string>) => void

interface IProps {
  liveStreams?: Array<IVideoEngineLiveStream>
  // onAction?: StreamhubVideoEngineLiveStreamsActionCallback
  onSelectChange?: StreamhubVideoEngineLiveStreamsSelectionChangeCallback
}

const StreamhubVideoEngineLiveStreamsTable = (props: IProps) => {
  const { liveStreams, onSelectChange } = props

  // const sourcesContext = useContext(StreamhubSourcesContext)

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const streamhubServerLocalIPs = STREAMHUB_SERVERS.map((server) => server.localIP)

  const columnHelper = createColumnHelper<IVideoEngineLiveStream>()
  const columns = React.useMemo(
    () => [
      // NB: temp disabling the select column for now (as we can't perform any actions on the video engine 'live streams' data yet)
      // columnHelper.display({
      //   id: 'select',
      //   header: ({ table }) => (
      //     <ArkCheckbox
      //       checked={table.getIsAllRowsSelected()}
      //       indeterminate={table.getIsSomeRowsSelected()}
      //       // NB: by default toggling when some rows are selected will select all rows
      //       // NB: via getToggleAllRowsSelectedHandler / getToggleAllPageRowsSelectedHandler directly, or calling toggleAllRowsSelected / toggleAllPageRowsSelected manually
      //       // onChange={table.getToggleAllRowsSelectedHandler()} // or getToggleAllPageRowsSelectedHandler
      //       // ALTERNATIVE: use a custom handler to invert the default `indeterminate` toggle behavior, & instead deselect all rows when some are selected
      //       onChange={(_event: React.FormEvent<HTMLInputElement>, _data: CheckboxProps) => {
      //         const allRowsSelected = table.getIsAllRowsSelected() // getIsAllPageRowsSelected
      //         const someRowsSelected = table.getIsSomeRowsSelected() // getIsSomePageRowsSelected
      //         console.log('StreamhubStreamsTable - columns - select header - onChange - allRowsSelected:', allRowsSelected, ' someRowsSelected:', someRowsSelected)
      //         table.toggleAllRowsSelected(!(allRowsSelected || someRowsSelected)) // toggleAllPageRowsSelected
      //       }}
      //     />
      //   ),
      //   cell: ({ row }) => (
      //     <ArkCheckbox
      //       checked={row.getIsSelected()}
      //       disabled={!row.getCanSelect()}
      //       onChange={row.getToggleSelectedHandler()}
      //       // label='Programs'
      //       // onChange={(_event, data) => setShowPrograms(!!data.checked)}
      //       // toggle
      //     />
      //   ),
      //   meta: {
      //     headerClassName: styles.streamSelect
      //   }
      // }),
      // columnHelper.display({
      //   id: 'preview',
      //   header: () => '',
      //   cell: data => {
      //     // const liveStream = data.row.original
      //     // const streamSource = sourcesContext.store.sources?.find((streamSource) => streamSource.id === stream.sourceId)
      //     return (
      //       <>
      //         {/* <div className={styles.idRow + ' ' + styles.idStream}>Stream ID: {stream.id}</div>
      //         {streamSource && (<Image src={sourcesContext.actions.getSourcePreviewImageURL(streamSource, 100)} />)}
      //         <div className={styles.idRow + ' ' + styles.idSource}>Source: {stream.sourceId}</div> */}
      //       </>
      //     )
      //   },
      //   meta: {
      //     cellClassName: styles.streamIdAndImg
      //   }
      // }),
      columnHelper.display({
        id: 'summary',
        header: () => 'Live Stream',
        cell: data => {
          const liveStream = data.row.original
          const liveStreamData = liveStream.data
          const companyId = liveStreamData?.companyId
          const projectId = liveStreamData?.projectId
          const programId = liveStreamData?.programGUID
          return (
            <div className={styles.streamSummary}>
              {(companyId || projectId || programId) && (
                <div className={styles.ids}>
                  <div><span className={styles.idTitle}>company:</span><span className={styles.idValue}>{companyId ?? 'N/A'}</span></div>
                  <div><span className={styles.idTitle}>project:</span><span className={styles.idValue}>{projectId ?? 'N/A'}</span></div>
                  <div><span className={styles.idTitle}>program:</span><span className={styles.idValue}>{programId ?? 'N/A'}</span></div>
                </div>
              )}
              <div className={styles.streamPath}><span className={styles.idTitle}>live stream:</span><span className={styles.idValue}>/{liveStream.application}/{liveStream.stream}</span></div>
              {liveStream.description && liveStream.description.length > 0 && (<div className={styles.streamDesc}><span className={styles.idTitle}>description:</span><span className={styles.idValue}>{liveStream.description}</span></div>)}
              {liveStream.tags && liveStream.tags.length > 0 && (<div className={styles.streamTags}><span className={styles.idTitle}>tags:</span><span className={styles.idValue}>{liveStream.tags.join(', ').trim()}</span></div>)}
            </div>
          )
        },
        meta: {
          cellClassName: styles.streamDetailsCell
        }
      }),
      columnHelper.display({
        id: 'status',
        header: () => 'Status',
        cell: data => {
          const liveStream = data.row.original
          const streamStatus = liveStream.status
          return (
            <div className={styles.statusRow}>
              <div className={styles.statusTitle}></div><div className={styles.statusValue}>{streamStatus}</div>
            </div>
          )
        }
      }),
      columnHelper.display({
        id: 'details',
        header: () => 'Details',
        cell: data => {
          const liveStream = data.row.original
          return (
            <div className={styles.streamDetails}>
              <div className={styles.detailsRow}>
                <div className={styles.detailsTitle}>protocol:</div><div className={styles.detailsValue}>{liveStream.protocol}</div>
              </div>
              <div className={styles.detailsRow}>
                <div className={styles.detailsTitle}>video codec:</div><div className={styles.detailsValue}>{liveStream.video_codec}</div>
              </div>
              <div className={styles.detailsRow}>
                <div className={styles.detailsTitle}>audio codec:</div><div className={styles.detailsValue}>{liveStream.audio_codec}</div>
              </div>
              <div className={styles.detailsRow}>
                <div className={styles.detailsTitle}>bandwidth:</div><div className={styles.detailsValue}>{liveStream.bandwidth}</div>
              </div>
              <div className={styles.detailsRow}>
                <div className={styles.detailsTitle}>resolution:</div><div className={styles.detailsValue}>{liveStream.resolution}</div>
              </div>
            </div>
          )
        }
      }),
      columnHelper.display({
        id: 'publisher',
        header: () => 'Publisher',
        cell: data => {
          const liveStream = data.row.original
          return (
            <div className={styles.streamDetails}>
              <div className={styles.detailsRow}>
                <div className={styles.detailsTitle}>publisher ip:</div><div className={styles.detailsValue}>{liveStream.publisher_ip ?? '-'}{(liveStream.publisher_ip && streamhubServerLocalIPs.includes(liveStream.publisher_ip)) ? ' (streamhub)' : ''}</div>
              </div>
              <div className={styles.detailsRow}>
                <div className={styles.detailsTitle}>publish time:</div><div className={styles.detailsValue}>{liveStream.publish_time ?? '-'}</div>
              </div>
            </div>
          )
        }
      })
      // columnHelper.display({
      //   id: 'actions',
      //   header: () => 'Actions',
      //   cell: data => {
      //     // const liveStream = data.row.original
      //     return (
      //       <div className={styles.actionButtons}>
      //       </div>
      //     )
      //   },
      //   meta: {
      //     cellClassName: styles.tableActions
      //   }
      // })
    ],
    []
  )

  const table = useReactTable({
    data: liveStreams ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection
    },
    getRowId: row => `${row.id}` // use a custom (string) table row id instead of just the index
  })

  // listen for changes to the row selection state & trigger the `onSelectChange` callback when it changes
  useEffect(() => {
    // console.log('StreamhubStreamsTable - useEffect - rowSelection:', rowSelection)
    // TODO: flip to use `rowSelection` directly? (or just use the `table` state directly?)
    const selectedStreamIds = table.getRowModel().rows.filter(row => row.getIsSelected()).map(row => row.original.id)
    // console.log('StreamhubStreamsTable - useEffect - selectedStreamIds:', selectedStreamIds)
    if (onSelectChange) onSelectChange(selectedStreamIds)
  }, [rowSelection])

  // listen for changes to the `streams` data source (e.g. from external filtering) & clear any selected rows that are no longer in the data source
  useEffect(() => {
    // console.log('StreamhubStreamsTable - useEffect(streams) - streams.length:', streams?.length)
    const currentRowSelectionCount = Object.keys(rowSelection).length
    // console.log('StreamhubStreamsTable - useEffect(streams) - currentRowSelectionCount:', currentRowSelectionCount)
    if (currentRowSelectionCount > 0) {
      // console.log('StreamhubStreamsTable - useEffect(streams) - check if any selected rows are no longer in the data source...')
      const validIds: Array<string> = []
      for (const streamIdKey in rowSelection) {
        const streamId = streamIdKey // parseInt(streamIdKey)
        // console.log('StreamhubStreamsTable - useEffect(streams) - streamId:', streamId)
        const stream = liveStreams?.find(liveStream => liveStream.id === streamId)
        // console.log('StreamhubStreamsTable - useEffect(streams) - streamId:', streamId, ' stream:', stream)
        if (stream) validIds.push(streamId)
      }
      // console.log('StreamhubStreamsTable - useEffect(streams) - validIds:', validIds)
      if (validIds.length !== Object.keys(rowSelection).length) {
        // console.log('StreamhubStreamsTable - useEffect(streams) - WARNING: validIds !== rowSelection.length - clear any selected rows that are no longer in the data source...')
        const newRowSelection = validIds.map(id => ({ [id]: true })).reduce((acc, val) => ({ ...acc, ...val }), {})
        console.log('StreamhubStreamsTable - useEffect(streams) - newRowSelection:', newRowSelection, ' oldRowSelection:', rowSelection)
        table.setRowSelection(newRowSelection)
      }
      // clear any selected rows if the data source changes
      // table.setRowSelection([])
    }
  }, [liveStreams])

  return (
    <div className="flex justify-center h-screen">
      <table className={styles.table + ' ' + styles.streamsTable}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} className={header.column.columnDef.meta?.headerClassName}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} className={styles.streamRow}>{/* onClick={row.getToggleSelectedHandler()} */}
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} className={cell.column.columnDef.meta?.cellClassName}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div/>
    </div>
  )
}

export default StreamhubVideoEngineLiveStreamsTable
