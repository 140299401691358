import React from 'react'

import { withUserContext, IUserMultiContext } from '../../../core/providers'
import { Company } from '../../../core/models'

import ArkBorder from '../ArkBorder'
import ArkButton from '../ArkButton'
import ArkIcon from '../ArkIcon'
import ArkInfoView from '../ArkInfoView'
import ArkLoaderView from '../ArkLoaderView'
import ArkModal from '../ArkModal'
// import ArkSpacer from '../ArkSpacer'

import { OBJECT_COMPANY_NAME, OBJECT_COMPANY_NAME_PLURAL } from 'src/constants/strings'

import { List } from 'semantic-ui-react'

import styles from './ArkCompanySelectView.module.css'

interface IProps extends IUserMultiContext {}
interface IState {
  showModal: boolean
  loadingCompany: boolean
}

class ArkCompanySelectView extends React.Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      showModal: true,
      loadingCompany: false
    }
  }

  render () {
    return (
      <>
        {/* <h1>{OBJECT_COMPANY_NAME} select:</h1>
        {this.renderCompanyList()} */}
        {this.renderNotice()}
        {this.renderCompanyListModal()}
      </>
    )
  }

  renderNotice () {
    return (
      <ArkInfoView
        iconName='warning'
        title={'Select ' + OBJECT_COMPANY_NAME}
        message={
          <div>
            <p>Please select an {OBJECT_COMPANY_NAME}.</p>
            <ArkButton color="blue" fluid basic size="large" onClick={() => this.setState({ showModal: true }) }>SHOW {OBJECT_COMPANY_NAME_PLURAL}</ArkButton>
          </div>
        }
      />
    )
  }

  renderCompanyListModal = () => {
    return (
      <ArkModal
        header={'Choose ' + OBJECT_COMPANY_NAME}
        size='tiny'
        scrolling
        disablePadding
        open={this.state.showModal}
        onClose={() => this.setState({ showModal: false })}
        className={styles.modal}
      >
        <ArkBorder title={'Your ' + OBJECT_COMPANY_NAME_PLURAL}>
          {this.renderCompanyList()}
        </ArkBorder>
        {this.state.loadingCompany && (
          <div className={styles.loadingOverlay}><ArkLoaderView message='Loading' /></div>
        )}
      </ArkModal>
    )
  }

  renderCompanyList = () => {
    const userCompanies = this.props.userContext.store.userCompanies ?? []
    return (
      <List divided relaxed selection animated verticalAlign='middle' size='large' inverted>
        {userCompanies.map((company: Company) => (
          <List.Item key={'company_' + company.id} onClick={() => { this.selectCompany(company.id) }}>
            <List.Content>
              <div className='listIcon'>
                <ArkIcon name='company' size={28}/>
              </div>
              <div className='listBody'>
                <List.Header>{company.name}</List.Header>
                <List.Description>company id: {company.id}</List.Description>
              </div>
            </List.Content>
          </List.Item>
        ))}
      </List>
    )
  }

  selectCompany = async (companyId: number) => {
    this.setState({ loadingCompany: true })
    // await new Promise(resolve => setTimeout(resolve, 1000)) // DEBUG ONLY <<<
    await this.props.userContext.actions.selectCompany(companyId)
    if (this._isMounted) {
      this.setState({ loadingCompany: false })
    }
  }
}

export default withUserContext(ArkCompanySelectView)
