import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

import { ProjectAdminContext, ServerProjectAvailablePortRanges } from 'src/core/providers'
import { IServerVideoEnginePortStatusResult, ServerVideoEngineInvalidPortError, ServerVideoEnginePortStatus, ServerVideoEnginePortType } from 'src/core/services/ServerVideoEngineAPI'

import ArkLoader from 'src/core/components/ArkLoader'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'

import { OBJECT_PROGRAM_NAME } from 'src/constants/strings'

import styles from './ProgramForm.module.css'

const PROGRAM_PORT_REQUIRE_CHECK = true // rls: true - require port changes to run 'check port' before being able to apply/save (disable this to test fallback port handling in the main program form, submitting with invalid port values etc.)

export type ProgramPortFormApplyCallback = (portType: 'input' | 'output', port?: number) => void

const formSchema = yup.object().shape({
  srtPort: yup.number().transform((val, orig) => orig === '' ? undefined : val).min(0).optional().label('SRT Port').typeError('SRT port must be a number') // NB: convert empty string to undefined - ref: https://github.com/jquense/yup/issues/360#issuecomment-1778836314
})

interface IProps {
  companyId: number
  projectId: number
  programId?: number
  videoEngineId?: number
  portType: 'input' | 'output'
  port?: number
  onCancel?: Function
  // onClose?: Function
  onApply?: ProgramPortFormApplyCallback
}

const ProgramPortForm = (props: IProps) => {
  const { companyId, projectId, programId, videoEngineId, portType, port: _port, onCancel: _onCancel, onApply } = props
  const protTypeTitle = portType === 'input' ? 'Input' : 'Output'

  const mounted = useRef(false)

  const { actions: projectAdminActions } = useContext(ProjectAdminContext)

  const [loadingAvailablePorts, setLoadingAvailablePorts] = useState<boolean>(false)
  const [availablePortRanges, setAvailablePortRanges] = useState<ServerProjectAvailablePortRanges | undefined>(undefined)
  const [availablePortsError, setAvailablePortsError] = useState<Error | undefined>(undefined)

  const [checking, setChecking] = useState<boolean>(false)
  const [checkResult, setCheckResult] = useState<IServerVideoEnginePortStatusResult | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)

  const [port, setPort] = useState<number | undefined>(_port)
  const [hasChanged, setHasChanged] = useState<boolean>(false) // NB: a more basic single field version of the `changes` detection we use in other forms

  // -------

  const loadAvailablePortRanges = async () => {
    console.log('ProgramPortForm - loadAvailablePortRanges')
    if (loadingAvailablePorts) return
    try {
      setLoadingAvailablePorts(true)
      if (availablePortsError) setAvailablePortsError(undefined)
      if (availablePortRanges) setAvailablePortRanges(undefined)
      // if (!videoEngineId) {
      //   throw new Error('Invalid Video Engine')
      // }
      const availablePorts = await projectAdminActions.getProjectAvailablePorts(companyId, projectId)
      console.log('ProgramPortForm - loadAvailablePortRanges - availablePorts: ', availablePorts)
      if (mounted.current) {
        setAvailablePortRanges(availablePorts ?? undefined)
        setLoadingAvailablePorts(false)
      }
    } catch (error) {
      console.error('ProgramPortForm - loadAvailablePortRanges - error: ', error)
      if (mounted.current) {
        setAvailablePortsError(error)
        setLoadingAvailablePorts(false)
      }
    }
  }

  // -------

  useEffect(() => {
    mounted.current = true
    loadAvailablePortRanges()
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const onCancel = () => {
    console.log('ProgramPortForm - onCancel')
    if (_onCancel) _onCancel()
  }

  // -------

  const onCheckPort = async () => {
    console.log('ProgramPortForm - onCheckPort')
    if (checking) return
    try {
      if (error) setError(undefined)
      setChecking(true)
      if (checkResult) setCheckResult(undefined)
      if (!videoEngineId) {
        throw new Error('Invalid videoEngineId') // TODO: wording?
      }
      if (!port) {
        throw new Error('Invalid port') // TODO: wording?
      }
      const portStatusResult = await projectAdminActions.checkProjectProgramPortStatus(companyId, projectId, videoEngineId, port)
      console.log('ProgramPortForm - onCheckPort - portStatusResult: ', portStatusResult)
      setCheckResult(portStatusResult)
      setChecking(false)
    } catch (error) {
      console.error('ProgramPortForm - onCheckPort - error: ', error)
      if (error instanceof ServerVideoEngineInvalidPortError) {
        console.error('ProgramPortForm - onCheckPort - error == ServerVideoEngineInvalidPortError')
        setError(new Error('Invalid Port - Port number is out of valid range for the current video engine server'))
      } else {
        setError(error)
      }
      setChecking(false)
    }
  }

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    // console.log('ProgramPortForm - onFormSubmit - fieldValues: ', fieldValues)
    let srtPort: number | undefined = fieldValues.srtPort && fieldValues.srtPort.length > 0 ? parseInt(fieldValues.srtPort) : undefined
    if (srtPort && isNaN(srtPort)) srtPort = undefined
    console.log('ProgramPortForm - onFormSubmit - srtPort: ', srtPort)
    if (onApply) onApply(portType, srtPort)
  }

  const onFormValueChanged = async (fieldKey: string, fieldValue: any, _oldFieldValue: any) => {
    // console.log('ProgramPortForm - onFormValueChanged - fieldKey: ', fieldKey, ' fieldValue: ', fieldValue, ' typeof: ', typeof fieldValue, ' _oldFieldValue: ', _oldFieldValue)
    if (fieldKey === 'srtPort') {
      const newPort = fieldValue && fieldValue.length > 0 ? parseInt(fieldValue) : undefined
      // console.log('ProgramPortForm - onFormValueChanged - newPort: ', newPort, ' _port: ', _port)
      if (newPort !== port) {
        setPort(newPort)
        const _hasChanged = newPort !== _port
        // console.log('ProgramPortForm - onFormValueChanged - _hasChanged: ', _hasChanged)
        setHasChanged(_hasChanged)
      }
      // clear previous check port result/error if set
      if (checkResult) setCheckResult(undefined)
      if (error) setError(undefined)
    }
  }

  // -------

  const renderAvailablePorts = () => {
    const portRanges = availablePortRanges && availablePortRanges.srt ? availablePortRanges.srt[portType] : undefined
    const portRangeCount = portRanges ? portRanges.length : 0
    return (
      <div className={styles.availablePorts}>
        <h3>Valid {protTypeTitle} Ports:</h3>
        {loadingAvailablePorts && <div className={styles.loadingPorts}><ArkLoader inline /></div>}
        {!loadingAvailablePorts && portRanges && (
          <div className={styles.portRanges}>
            {portRanges.map((range, index) => {
              const isLastPortRange = index === portRangeCount - 1
              const portRangeValue = (range.start === range.end ? (<>{range.start}</>) : (<>{range.start}-{range.end}</>))
              return (<div key={index} className={styles.portRange + ' ' + (!isLastPortRange ? styles.portRangeDivide : styles.portRangeLast)}>{portRangeValue}{!isLastPortRange ? <>,</> : null}</div>)
            })}
          </div>
        )}
        {!loadingAvailablePorts && !portRanges && (
          <div>N/A</div>
        )}
      </div>
    )
  }

  const renderCheckPortResult = () => {
    if (!checkResult && !checking) return null
    const portTypeMatch = checkResult && checkResult.portType === (portType === 'input' ? ServerVideoEnginePortType.Input : ServerVideoEnginePortType.Output)
    // TODO: can we tell if already assigned to another program - if it belongs to this org and/or project or someone elses? (incase they want to re-assign it if its available within their access?)
    const portAssignedToThisProgram = checkResult && (checkResult.portStatus === ServerVideoEnginePortStatus.Assigned) && checkResult.programId === programId
    return (
      <div className={styles.checkPortResult}>
        {/* <h3>Check Port Result:</h3> */}
        {checking && <div className={styles.checkingPorts}><ArkLoader inline /></div>}
        {!checking && checkResult && (
          <div className={styles.checkResult}>
            {portTypeMatch && checkResult.portStatus === ServerVideoEnginePortStatus.Free && (
              <div className={styles.checkResultAvailable}>Port Available</div>
            )}
            {checkResult.portStatus === ServerVideoEnginePortStatus.Assigned && (
              <>
                {portAssignedToThisProgram && portTypeMatch && (<div className={styles.checkResultAvailable}>Port assigned to this program <span>(already assigned to this program as its {portType} port)</span></div>)}
                {portAssignedToThisProgram && !portTypeMatch && (<div className={styles.checkResultInput}>Port Unavailable <span>(already assigned to this program as its {portType} port)</span></div>)}
                {!portAssignedToThisProgram && portTypeMatch && (<div className={styles.checkResultInput}>Port Unavailable <span>(already assigned to another program)</span></div>)}
              </>
            )}
            {!portTypeMatch && checkResult.portStatus === ServerVideoEnginePortStatus.Invalid && (
              <div className={styles.checkResultInvalid}>Port number is out of valid range.</div>
            )}
            {!portTypeMatch && !portAssignedToThisProgram && checkResult.portStatus !== ServerVideoEnginePortStatus.Invalid && (
              <div className={styles.checkResultInvalid}>Port number is out of valid range for available {portType} ports.</div>
            )}
          </div>
        )}

        {/* DEBUG OUTPUT: */}
        {/* <pre>{JSON.stringify(checkResult, null, 2)}</pre> */}

        {/* TESTING: positive / warning / negative */}
        {/* <ArkMessage warning>
          <ArkMessage.Header>Port is Available</ArkMessage.Header>
          <ArkMessage.Item>BLAH</ArkMessage.Item>
        </ArkMessage> */}
      </div>
    )
  }

  // -------

  const formFields: Array<ArkFormField> = []

  formFields.push(
    {
      type: ArkFormFieldType.Group,
      key: 'portGroup',
      className: styles.portGroup,
      fields: [
        {
          type: ArkFormFieldType.Input,
          key: 'srtPort',
          label: `SRT ${protTypeTitle} Port`,
          className: styles.srtPort + (hasChanged ? ' ' + styles.hasChanged : ''),
          required: false,
          defaultValue: _port,
          fieldProps: {
            autoFocus: true,
            fluid: false
            // onClick: () => {
            //   console.log('ProgramPortForm - srtInputPort - onClick')
            //   // showSRTPortEditModal('input')
            // }
          }
          // placeholder: defaultSrtLatency !== undefined ? '' + defaultSrtLatency : undefined,
        },
        {
          type: ArkFormFieldType.Button,
          key: 'checkPortBtn',
          label: 'CHECK PORT',
          className: styles.checkPortBtn,
          fieldProps: { onClick: onCheckPort, size: 'medium', basic: true, color: 'yellow' }
        }
      ]
      // fieldProps: { style: { justifyContent: 'space-between', gap: '10px' } } // flex-start // widths: 'equal'
    }
  )

  formFields.push(
    {
      type: ArkFormFieldType.Field,
      key: 'checkPortResult',
      wrapperProps: { className: styles.checkPortResultWrapper },
      content: (
        (
          <div className={styles.checkPortResult}>
            {renderCheckPortResult()}
          </div>
        )
      )
    }
  )

  // formFields.push({
  //   type: ArkFormFieldType.Input,
  //   key: 'srtPortTest',
  //   label: 'Test',
  //   // className: styles.srtPort + (hasChanged ? ' ' + styles.hasChanged : ''),
  //   required: false
  //   // fieldProps: { tabIndex: -1 } // inert: true
  // })

  const portTypeMatch = checkResult && checkResult.portType === (portType === 'input' ? ServerVideoEnginePortType.Input : ServerVideoEnginePortType.Output)
  const canApply = !(!hasChanged || (PROGRAM_PORT_REQUIRE_CHECK && (!checkResult || checkResult.portStatus !== ServerVideoEnginePortStatus.Free || !portTypeMatch)))
  let disabledTooltip = (!canApply ? (!hasChanged ? 'No Changes to Save' : 'Check Port First') : undefined)
  if (PROGRAM_PORT_REQUIRE_CHECK && !canApply && checkResult && checkResult.portStatus !== ServerVideoEnginePortStatus.Free) {
    if (checkResult.portStatus === ServerVideoEnginePortStatus.Invalid) {
      disabledTooltip = 'Invalid Port Number'
    }
    if (!portTypeMatch) {
      disabledTooltip = 'Port Unavailable'
    }
  }
  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      {
        type: ArkFormFieldType.CancelButton,
        key: 'cancel',
        label: 'CANCEL',
        fieldProps: { onClick: onCancel, floated: 'left' }
      },
      {
        type: ArkFormFieldType.OKButton,
        key: 'submit',
        label: 'APPLY',
        fieldProps: { floated: 'right' }, // loading: isSubmitting,
        disabled: !canApply,
        disabledTooltip: disabledTooltip
      }
    ],
    fieldProps: { widths: 'equal' }
  })

  return (
    <div className={styles.programPortEdit}>
      <h1>{OBJECT_PROGRAM_NAME} SRT {protTypeTitle} Port</h1>
      {renderAvailablePorts()}
      {/* {renderCheckPortResult()} */}
      <ArkForm
        className={styles.programPortForm}
        formKey="srtPortForm"
        inverted
        formError={error ?? availablePortsError}
        formFields={formFields}
        formSchema={formSchema}
        onFormSubmit={onFormSubmit}
        onValueChanged={onFormValueChanged}
        // shouldIgnoreTabFocus={() => {
        //   console.log('ProgramPortForm - shouldIgnoreTabFocus === false')
        //   return false
        // }}
        showLabels={true}
        insideModal={true}
      />
    </div>
  )
}

export default ProgramPortForm
