import React from 'react'
import ArkViewerPage from '../../components/ArkViewerPage/ArkViewerPage'

class ViewerSettingsPage extends React.Component {
  render () {
    return (<ArkViewerPage>
      <div className="viewer-settings">
        <div className="header-wrapper">
          <div className="header-grid">
            <div className="header-icon"><p>ICON</p></div>
            <div className="header-title"><p>TITLE</p></div>
            <div className="header-widget"><p>USERS</p></div>
            <div className="header-widget"><p>GROUPS</p></div>
            <div className="header-widget"><p>PROGRAMS</p></div>
            <div className="header-widget"><p>CHANNELS</p></div>
          </div>
        </div>
        <div className="wrapper">
          <div className="grid">
            <div className="settings-general"><p>GENERAL</p></div>
            <div className="settings-permissions"><p>PERMISSIONS</p></div>
            <div className="settings-users"><p>USERS</p></div>
            <div className="settings-security"><p>SECURITY</p></div>
            <div className="settings-conference"><p>CONFERENCE</p></div>
          </div>
        </div>
      </div>
    </ArkViewerPage>)
  }
}
export default ViewerSettingsPage
