import React from 'react'
import { SECTION_COMPANY_NAME, SECTION_MANAGER_SUFFIX_NAME } from 'src/constants/strings'

import ArkCompanyManagerPage from 'src/manager/company/components/ArkCompanyManagerPage/ArkCompanyManagerPage'

interface IProps {}
interface IState {}

class CompanyActivityPage extends React.Component<IProps, IState> {
  render () {
    return (<ArkCompanyManagerPage>
      <div className="company-manager-activity">
        {SECTION_COMPANY_NAME + ' ' + SECTION_MANAGER_SUFFIX_NAME} &gt; ACTIVITY
      </div>
    </ArkCompanyManagerPage>)
  }
}
export default CompanyActivityPage
