import React, { useContext, useEffect, useRef, useState } from 'react'

import { CompanyAdminContext, UserContext } from 'src/core/providers'
import { Company, UserCompany } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkHint, { HintType, PopupPosition, PopupSize } from 'src/core/components/ArkHint'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkNumberInput from 'src/core/components/ArkNumberInput'
import ArkPanel from 'src/core/components/ArkPanel'

import { OBJECT_COMPANY_NAME, OBJECT_PROJECT_NAME, OBJECT_USER_NAME, PAGE_SETTINGS_NAME } from 'src/constants/strings'

import styles from './CompanySettingsView.module.css'

interface CompanyUserValues {
  maxUsers: number
  projectDefaultMaxUsers?: number
}

// TODO: access checks currently only work for the currently selected company & project, so remove the props & grab them from the provider instead?
// TODO: (as it can't be used any other way unless access checks are expanded to work outside of current user selections)
// NB: same company/project checks (& limitations) as ProjectTranscoderSettingsView
export interface CompanyUserSettingsViewProps {
  company: Company | UserCompany
}

const CompanyUserSettingsView = (props: CompanyUserSettingsViewProps) => {
  const { company } = props

  const mounted = useRef(false)

  const userContext = useContext(UserContext)
  const companyAdminContext = useContext(CompanyAdminContext)

  const isSiteAdmin = userContext.actions.isSiteAdmin()
  const isCompanyAdminOrHigher = userContext.actions.isCompanyAdminOrHigher()

  const [saving, setSaving] = useState<boolean>(false)
  const [saveResult, setSaveResult] = useState<boolean>(false)
  const [saveError, setSaveError] = useState<Error | undefined>(undefined)
  const [saveWarnings, setSaveWarnings] = useState<Array<Error> | undefined>(undefined)

  // individual program settings specific to this form
  const [companyValues, setCompanyValues] = useState<CompanyUserValues>({
    maxUsers: company.maxUsers,
    projectDefaultMaxUsers: company.projectDefaultMaxUsers
  })
  const [companyValuesSaved, setCompanyValuesSaved] = useState<CompanyUserValues>({
    maxUsers: company.maxUsers,
    projectDefaultMaxUsers: company.projectDefaultMaxUsers
  })
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])

  // -------

  const companySettingsChanges = () => {
    const _changes: Array<string> = []
    if (companyValues) {
      for (const fieldName of Object.keys(companyValues)) {
        const oldValue = companyValuesSaved !== undefined ? (companyValuesSaved as any)[fieldName] : undefined
        const newValue = (companyValues as any)[fieldName]
        if (oldValue !== newValue) {
          _changes.push(fieldName)
        }
      }
    }
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  const resetSaveResults = () => {
    setSaveResult(false)
    setSaveError(undefined)
    setSaveWarnings(undefined)
  }

  const numberElement = (valueKey: string, siteAdminOnly: boolean = false) => {
    const value = (companyValues as any)[valueKey]
    if ((siteAdminOnly && !isSiteAdmin) || !isCompanyAdminOrHigher) return value ?? 'NO LIMIT' // just display the value if the user isn't a site admin
    return (
      <ArkNumberInput
        className={styles.numberInput}
        value={'' + (value !== undefined ? value : '')}
        minValue={0}
        maxValue={1000}
        allowEmptyValue={true}
        size='mini'
        maxLength={4}
        onChange={(newValue: string) => {
          // console.log('ProjectUserSettingsView - numberElement - onChange - newValue: ', newValue)
          const newValueInt = newValue.length > 0 ? parseInt(newValue) : 0
          setCompanyValues({
            ...companyValues,
            [valueKey]: newValueInt
          })
          resetSaveResults()
        }}
      />
    )
  }

  const hintElement = (hintType: HintType, hintIconSize: number, hintPopupSize: PopupSize, hintTitle: string, hintMessage: string, hintPopupPosition: PopupPosition) => {
    return (
      <ArkHint type={hintType} iconSize={hintIconSize} popupSize={hintPopupSize} title={hintTitle} message={hintMessage} popupPosition={hintPopupPosition}></ArkHint>
    )
  }

  // -------

  const _applyCompanyValues = (newCompanyValues: CompanyUserValues) => {
    setCompanyValues(newCompanyValues)
  }

  // -------

  const onSave = async () => {
    console.log('CompanyUserSettingsView - onSave')
    // const companyId = userContext.store.selectedCompany?.id
    // if (!companyId) {
    //   // TODO: handle if no company is selected? (shouldn't be if this page is showing/loaded?)
    //   return
    // }
    if (saving) return
    const changedValues: {[key: string]: any} = {}
    let changesCount = 0
    for (const fieldName of changes) {
      const newValue = (companyValues as any)[fieldName]
      changedValues[fieldName] = newValue
      changesCount++
    }
    console.log('CompanyUserSettingsView - onSave - changedValues: ', changedValues, ' changesCount: ', changesCount)
    // NB: currently only supporting `maxUsers` or `projectDefaultMaxUsers` here, so hard-coded the check & the api update data to keep this code simpler for now
    if (changesCount > 0 && (changedValues.maxUsers !== undefined || changedValues.projectDefaultMaxUsers !== undefined)) {
      resetSaveResults()
      setSaving(true)
      // await new Promise((resolve) => setTimeout(resolve, 1000)) // DEBUG ONLY
      try {
        // update/save the company info - returns a new Company object with the latest values
        const updateResult = await companyAdminContext.actions.updateCompanyInfo(company.id, changedValues)
        console.log('CompanyUserSettingsView - onSave - updateResult: ', updateResult)
        if (updateResult && updateResult.company) {
          if (mounted.current) {
            const newCompanyValuesSaved: CompanyUserValues = { maxUsers: updateResult.company.maxUsers, projectDefaultMaxUsers: updateResult.company.projectDefaultMaxUsers }
            setCompanyValuesSaved(newCompanyValuesSaved)
            setCompanyValues(newCompanyValuesSaved) // reset the form back to the newly saved values
            setChanges([])
            setSaveResult(true)
          }
        }
        // TODO: only show a success if no warnings as well? (for a single field updates we might not get any warnings, but if we multiple fields are submitted at once & some fail we'll likely have to consider it? or will all fail if one does?)
        // check for warnings
        if (updateResult.warnings) {
          setSaveWarnings(updateResult.warnings)
          // setSaveWarnings([...updateResult.warnings, new Error('DEBUG')]) // DBG ONLY - simulate multiple warnings
        }
        if (!updateResult || !updateResult.company) {
          throw new Error('Invalid response')
        }
      } catch (error) {
        if (mounted.current) {
          setSaveResult(false)
          setSaveError(error)
          setCompanyValues(companyValuesSaved) // reset the form back to the saved values
          setChanges([])
        }
      }
      setSaving(false)
    } else {
      console.log('CompanyUserSettingsView - onSave - WARNING: no valid changes to save')
    }
  }

  const onReset = async () => {
    _applyCompanyValues(companyValuesSaved) // reset the form data back to the saved values
    setChanges([])
    resetSaveResults()
  }

  // -------

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = companySettingsChanges()
    setChanges(_changes)
  }, [companyValues])

  // TESTING: listen out for changes to the company data & update the form values if the tracked values change
  // TODO: is this ok to handle like this, should the form be reset if the base value has changed since it was edited, or should the user be warned its changed or similar?
  useEffect(() => {
    console.log('CompanyUserSettingsView - useEffect(project) - company:', company)
    if (company.maxUsers !== companyValues.maxUsers || company.projectDefaultMaxUsers !== companyValues.projectDefaultMaxUsers) {
      console.log('CompanyUserSettingsView - useEffect(project) - maxUsers and/or projectDefaultMaxUsers changed - maxUsers from:', companyValues.maxUsers, ' to:', company.maxUsers, ' projectDefaultMaxUsers from:', companyValues.projectDefaultMaxUsers, ' to:', company.projectDefaultMaxUsers)
      setCompanyValues({
        ...companyValues,
        maxUsers: company.maxUsers,
        projectDefaultMaxUsers: company.projectDefaultMaxUsers
      })
    }
  }, [company])

  // -------

  useEffect(() => {
    console.log('CompanyUserSettingsView - MOUNTED')
    mounted.current = true
    return () => {
      mounted.current = false
      console.log('CompanyUserSettingsView - UN-MOUNTED')
    }
  }, [])

  // -------

  // if (loading) return <ArkLoaderView message='Loading' />
  return (
    <div className={styles.userSettings}>
      <div className={styles.content}>
        <ArkPanel bordered>
          <ArkPanel.Header title={OBJECT_COMPANY_NAME + ' ' + OBJECT_USER_NAME + ' ' + PAGE_SETTINGS_NAME} bottomBorder className={styles.header}></ArkPanel.Header>
          <>
            <ArkPanel.Properties>
              {(saveResult || saveError || saveWarnings) && (
                <>
                  {saveResult && (
                    <ArkPanel.PropertyRow>
                      <ArkPanel.PropertyRowMessage positive>
                        <ArkMessage.Header>Saved</ArkMessage.Header>
                        <p>Your changes have been saved</p>
                      </ArkPanel.PropertyRowMessage>
                    </ArkPanel.PropertyRow>
                  )}
                  {saveError && (
                    <ArkPanel.PropertyRow>
                      <ArkPanel.PropertyRowMessage negative>
                        <ArkMessage.Header>Error</ArkMessage.Header>
                        <p>{saveError.message}</p>
                      </ArkPanel.PropertyRowMessage>
                    </ArkPanel.PropertyRow>
                  )}
                  {saveWarnings && saveWarnings.length > 0 && (
                    <ArkPanel.PropertyRow>
                      <ArkPanel.PropertyRowMessage warning>
                        <ArkMessage.Header>Warning</ArkMessage.Header>
                        {saveWarnings.length === 1 && (
                          <p>{saveWarnings[0].message}</p>
                        )}
                        {saveWarnings.length > 1 && (
                          <ArkMessage.List>
                            {saveWarnings.map((saveWarning: Error, index: number) => (
                              <ArkMessage.Item key={'warning_' + index}>{saveWarning.message}</ArkMessage.Item>
                            ))}
                          </ArkMessage.List>
                        )}
                      </ArkPanel.PropertyRowMessage>
                    </ArkPanel.PropertyRow>
                  )}
                </>
              )}
              <ArkPanel.PropertyRow
                title={OBJECT_COMPANY_NAME + ' Max Users:'}
                value={numberElement('maxUsers', true)}
                hint={hintElement(
                  'info-circle',
                  22,
                  'small',
                  'Maximum total users for this company',
                  '', // TODO: <<<<
                  'bottom left'
                )}
                hasChanges={hasChanges('maxUsers')}
                titleSize={'xlarge'}
              />
              <ArkPanel.PropertyRow
                title={OBJECT_PROJECT_NAME + ' Default Max Users'}
                value={numberElement('projectDefaultMaxUsers', true)}
                hint={hintElement(
                  'info-circle',
                  22,
                  'small',
                  'Maximum default project users for this company',
                  '', // TODO: <<<<
                  'bottom left'
                )}
                hasChanges={hasChanges('projectDefaultMaxUsers')}
                titleSize={'xlarge'}
              />
            </ArkPanel.Properties>
            {isCompanyAdminOrHigher && (
              <ArkPanel.Footer className={styles.userFooter} topBorder>
                <div className={styles.footerWrapper}>
                  {changes.length === 0 && (<div></div>)}
                  <div className={styles.buttons}>
                    <ArkButton type='button' size='medium' color='red' disabled={changes.length === 0 || saving} onClick={onReset}>CLEAR CHANGES</ArkButton>
                    <ArkButton type='button' size='medium' color='green' disabled={changes.length === 0} loading={saving} onClick={onSave}>SAVE CHANGES</ArkButton>
                  </div>
                </div>
              </ArkPanel.Footer>
            )}
          </>
        </ArkPanel>
      </div>
    </div>
  )
}

export { CompanyUserSettingsView }
