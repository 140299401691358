import React, { useEffect, useRef } from 'react'
import { IVideoEngineDataSlice } from '../../models/StreamhubVideoEngineModels'
import ArkDropdown, { ArkDropdownProps } from 'src/core/components/ArkDropdown'

import styles from './StreamhubVideoEngines.module.css'

interface IProps {
  dataSlices: Array<IVideoEngineDataSlice>
  selectedDataSlice?: IVideoEngineDataSlice
  onDataSliceSelect: (dataSliceId: string) => void
}

const StreamhubVideoEngineDataSliceSelectView = (props: IProps) => {
  const mounted = useRef(false)

  const { dataSlices, selectedDataSlice, onDataSliceSelect } = props

  // -------

  useEffect(() => {
    console.log('StreamhubVideoEngineDataSliceSelectView - MOUNT')
    mounted.current = true
    return () => {
      console.log('StreamhubVideoEngineDataSliceSelectView - UNMOUNT')
      mounted.current = false
    }
  }, [])

  // -------

  const renderDataSlicesDropdown = (dataSlices: Array<IVideoEngineDataSlice>, currentDataSlice?: IVideoEngineDataSlice) => {
    const options: Array<{}> = []
    let index = 0
    for (const dataSlice of dataSlices) {
      options.push(
        {
          key: 'slice_' + index,
          text: dataSlice.name,
          value: '' + dataSlice.id
        }
      )
      index++
    }
    return (
      <div className={styles.selectDataSlice}>
        <ArkDropdown
          fluid selection
          // className="server-select"
          placeholder='Select Video Engine Data Slice'
          options={options}
          value={currentDataSlice?.id ?? undefined}
          onChange={(event: React.SyntheticEvent<HTMLElement, Event>, data: ArkDropdownProps) => {
            const dataSliceId = data.value as string
            console.log('StreamhubVideoEngineDataSliceSelectView - renderDataSlicesDropdown - dataSliceId:', dataSliceId)
            onDataSliceSelect(dataSliceId)
          }}
        />
      </div>
    )
  }

  // -------

  return (
    <>
      {renderDataSlicesDropdown(dataSlices, selectedDataSlice)}
    </>
  )
}

export default StreamhubVideoEngineDataSliceSelectView
