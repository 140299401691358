/**
 * ArkSectionButton
 */

import React, { ReactNode } from 'react'

import ArkIcon, { IconName } from '../ArkIcon'
import ArkSpacer from '../ArkSpacer'

import styles from './ArkSectionButton.module.css'

/**
 * button
 */

interface ArkSectionButtonProps {
  active?: boolean
  compact?: boolean
  disabled?: boolean
  hidden?: boolean
  iconName: IconName
  onClick: () => void
  title: string
}

const ArkSectionButton = (props: ArkSectionButtonProps) => {
  const { active, compact, disabled, hidden, iconName, onClick, title } = props

  return (
    <div
      className={`${
        styles.button
      } ${
        active ? styles.active : ''
      } ${
        compact ? styles.compact : ''
      } ${
        disabled ? styles.disabled : ''
      } ${
        hidden ? styles.hidden : ''
      }`}
      onClick={onClick}
    >
      <ArkIcon name={iconName} size={24} />
      <ArkSpacer small />
      <div className={styles.title}>{title}</div>
      <ArkIcon className={styles.chevron} name='angle-right' size={18} />
    </div>
  )
}

export default ArkSectionButton

/**
 * group
 */

export interface ArkSectionButtonGroupProps {
  children: ReactNode
  compact?: boolean
  popup?: boolean
}

export const ArkSectionButtonGroup = (props: ArkSectionButtonGroupProps) => {
  const { children, compact, popup } = props

  return (
    <div className={`${styles.group} ${compact ? styles.compact : ''} ${popup ? styles.popup : ''}`}>
      {children}
    </div>
  )
}
