/**
 * Channel utilities
 */

import { ChannelGridCoords } from '../types/channel'

export const createMatrix = ({ x, y }: ChannelGridCoords = { x: 0, y: 0 }) => {
  const matrix = [
    [false, false, false],
    [false, false, false],
    [false, false, false]
  ]
  for (let y2 = 0; y2 <= y - 1; y2++) {
    for (let x2 = 0; x2 <= x - 1; x2++) {
      matrix[y2][x2] = true
    }
  }
  return matrix
}

export const getAutoCoords = (size: number): ChannelGridCoords => {
  const isLandscape = window.innerWidth > window.innerHeight * (16 / 9)
  switch (size) {
    case 1:
      return { x: 1, y: 1 }
    case 2:
      return isLandscape ? { x: 2, y: 1 } : { x: 1, y: 2 }
    case 3:
      return isLandscape ? { x: 3, y: 1 } : { x: 1, y: 3 }
    case 4:
      return { x: 2, y: 2 }
    case 5:
    case 6:
      return isLandscape ? { x: 2, y: 3 } : { x: 3, y: 2 }
    case 7:
    case 8:
    case 9:
    default:
      return { x: 3, y: 3 }
  }
}

export const getCoords = (matrix: boolean[][]) => {
  for (let y = matrix.length - 1; y >= 0; y--) {
    for (let x = matrix[0].length - 1; x >= 0; x--) {
      if (matrix[y][x]) {
        return { x: x + 1, y: y + 1 }
      }
    }
  }
}
