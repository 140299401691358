/**
 * BaseNode
 */

import React, { ReactNode } from 'react'
import { Handle, NodeProps, Position } from 'react-flow-renderer'

import { PROJECT_NODE_GRAPH_VIEW_BUTTONS_ENABLED } from 'src/constants/config'
import { OBJECT_COMPANY_SHORTNAME } from 'src/constants/strings'
import ArkIcon, { IconName } from 'src/core/components/ArkIcon'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkPopup, { ArkPopupProps } from 'src/core/components/ArkPopup'
import ArkSpacer from 'src/core/components/ArkSpacer'

import styles from './BaseNode.module.css'

interface BaseNodeProps extends NodeProps {
  children: ReactNode
  iconName?: IconName
  popupBody?: ReactNode
  popupName: string;
  popupPosition?: ArkPopupProps['position'];
  popupTitle: string;
}

const BaseNode = (props: BaseNodeProps) => {
  const { children, data, iconName, popupBody, popupName, popupPosition = 'top center', popupTitle } = props
  if (!data) return null
  const { blurred, focused, onViewClick, selected } = data

  const mainComponent = (
    <div className={`${styles.nodeOuter} ${blurred && styles.nodeBlurred} ${focused && styles.nodeFocused}`}>
      <div className={styles.nodeInner}>
        <Handle className={styles.handle} position={Position.Top} type="target" />
        <Handle className={styles.handle} position={Position.Bottom} type="source" />
        {children}
        {iconName && <ArkIcon className={styles.icon} color='var(--bg-dark-card)' name={iconName} />}
        <div className={`${styles.selected} ${!selected && styles.selectedHidden}`} />
      </div>
    </div>
  )

  return (
    <ArkPopup
      className={styles.popup}
      color='var(--bd-mid)'
      open={selected}
      position={popupPosition}
      trigger={mainComponent}
    >
      <div className={styles.popupTitle}>{popupTitle}</div>
      <div className={styles.popupName}>{popupName}</div>
      {!!popupBody && (
        <>
          {data.disabled && (
            <div className={styles.popupDisabledContainer}>
              <ArkIcon color='var(--th-danger)' name='ban' size={12} />
              <ArkSpacer small />
              <div className={styles.popupDisabledText}>
                {data.mirrored ? `${OBJECT_COMPANY_SHORTNAME} disabled` : 'Disabled'}
              </div>
            </div>
          )}
          <ArkSpacer small />
          {popupBody}
        </>
      )}
      {PROJECT_NODE_GRAPH_VIEW_BUTTONS_ENABLED && (
        <ArkIconButton className={styles.popupEditButton} name='arrow-right' onClick={onViewClick} />
      )}
    </ArkPopup>
  )
}

export default BaseNode
