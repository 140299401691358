import React, { useContext, useEffect, useState } from 'react'

import { AuthContext, AuthStatus } from 'src/core/providers'
import { AuthSession } from 'src/core/models'
import { UserAccountContext } from '../UserAccountProvider'

import ArkLoaderView from 'src/core/components/ArkLoaderView'

import { formatDateTime } from 'src/core/utilities/date'

import { Icon, List } from 'semantic-ui-react'
import ArkButton from 'src/core/components/ArkButton'

import styles from './UserAuthSessionsView.module.css'
// import ArkDivider from 'src/core/components/ArkDivider'

interface IProps {}

const UserAuthSessionsView = (_props: IProps) => {
  const { store: authStore, actions: authActions } = useContext(AuthContext)
  const userAccountContext = useContext(UserAccountContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [authSessions, setAuthSessions] = useState<Array<AuthSession>>()
  const [loggingOutSessions, setLoggingOutSessions] = useState<Array<number>>([])
  const [updatingSessions, setUpdatingSessions] = useState<Array<number>>([])
  const [loggingOutAllSessions, setLoggingOutAllSessions] = useState<boolean>(false)

  const loadAuthSessions = async () => {
    setLoading(true)
    // trigger the parent modal to resize to fit the new content
    userAccountContext.actions.contentChanged()

    try {
      const authSessions = await authActions.getUserAuthSessions()
      console.log('UserAuthSessionsView - useEffect - fetchData - authSessions: ', authSessions)
      setAuthSessions(authSessions)
    } catch (error) {
      console.error('UserAuthSessionsView - useEffect - fetchData - error: ', error)
    }

    setLoading(false)
    // trigger the parent modal to resize to fit the new content
    userAccountContext.actions.contentChanged()
  }

  // on load (& authStatus change)
  useEffect(() => {
    if (authStore.authStatus === AuthStatus.loggedIn) {
      loadAuthSessions()
    }
  }, [authStore.authStatus])

  const reloadAuthSessions = async () => {
    return await loadAuthSessions()
  }

  const logoutLocally = () => {
    // TESTING: trigger a logout so we redirect away from here
    // NB: we're already logged out by this point (or at least the auth token has been invalidated), this mainly triggers the redirect & clears the cached token etc.
    authActions.logout()
  }

  const logoutAuthSession = async (authSession: AuthSession) => {
    console.log('UserAuthSessionsView - logoutAuthSession - authSession: ', authSession)

    // TODO: replace this with a custom react based confirm ui once this user account section can handle nested/embedded popups/modals....
    // NB: used a browser level confirm/alert for the time being....
    const confirmResult = confirm('Logout ' + (authSession.deviceName ?? authSession.deviceUUID) + '?')
    if (!confirmResult) { return }

    if (!loggingOutSessions.includes(authSession.id)) {
      // indicate this auth session is logging out
      const loggingOutSessionsAdded = [...loggingOutSessions, authSession.id]
      setLoggingOutSessions(loggingOutSessionsAdded)

      let loggedOutSessionResult = false
      try {
        // trigger the logout for this auth session
        // await new Promise((resolve) => setTimeout(resolve, 1000))
        loggedOutSessionResult = await authActions.logoutUserAuthSession(authSession.deviceUUID)
      } catch (error) {
        console.error('UserAuthSessionsView - logoutAuthSession - logoutUserAuthSession - error: ', error)
      }

      // remove the auth session from the logging-out list
      const loggingOutSessionsRemoved = [...loggingOutSessionsAdded]
      const index = loggingOutSessionsRemoved.indexOf(authSession.id)
      if (index >= 0) {
        loggingOutSessionsRemoved.splice(index, 1)
        setLoggingOutSessions(loggingOutSessionsRemoved)
      }

      // on logout session success remove the session from the main list
      if (loggedOutSessionResult && authSessions && authSessions.length > 0) {
        const authSessionsUpdated = authSessions.filter((aS) => aS.id !== authSession.id)
        setAuthSessions(authSessionsUpdated)
        // trigger the parent modal to resize to fit the new content
        userAccountContext.actions.contentChanged()
      }

      // TODO: if the logout was for the current auth session - handle in the same way as logging out all devices & redirect to login/home
      const currentDeviceUUID = authActions.getAuthDeviceUUID()
      const isCurrentSession = authSession.deviceUUID === currentDeviceUUID
      if (isCurrentSession) {
        console.log('UserAuthSessionsView - logoutAuthSession - CURRENT SESSION LOGGED OUT - TRIGGER LOGOUT UI SIDE...')
        logoutLocally()
      }
    }
  }

  const logoutAllAuthSessions = async () => {
    // TODO: replace this with a custom react based confirm ui once this user account section can handle nested/embedded popups/modals....
    // NB: used a browser level confirm/alert for the time being....
    const confirmResult = confirm('Logout all devices?')
    if (!confirmResult) { return }
    console.log('UserAuthSessionsView - confirmResult: ', confirmResult)

    setLoggingOutAllSessions(true)

    // await new Promise((resolve) => setTimeout(resolve, 1000))

    let loggedOutAllSessionsResult = false
    try {
      loggedOutAllSessionsResult = await authActions.logoutAllUserAuthSessions()
    } catch (error) {
      console.error('UserAuthSessionsView - logoutAllAuthSessions - logoutAllUserAuthSessions - error: ', error)
    }

    setLoggingOutAllSessions(false)

    if (loggedOutAllSessionsResult) {
      logoutLocally()
    }
  }

  // prompts the user to update/customise the device name for the specified auth session
  const updateAuthSessionDeviceName = async (authSession: AuthSession) => {
    // TODO: replace this with a custom react based form input once this user account section can handle nested/embedded popups/modals....
    // NB: used a browser level prompt/alert for the time being....
    const newDeviceName = prompt('Update Device Name:', authSession.deviceName ?? '')
    if (newDeviceName && (authSession.deviceName === undefined || newDeviceName !== authSession.deviceName)) {
      console.log('UserAuthSessionsView - updateAuthSessionDeviceName - UPDATE DEVICE NAME - FROM: ', authSession.deviceName, ' TO: ', newDeviceName)

      // indicate this auth session is being updated
      const updatingSessionsAdded = [...updatingSessions, authSession.id]
      setUpdatingSessions(updatingSessionsAdded)

      let updateResult = false
      try {
        updateResult = await authActions.updateUserAuthSessionDeviceName(authSession.deviceUUID, newDeviceName)
        console.log('UserAuthSessionsView - updateAuthSessionDeviceName - updateResult: ', updateResult)
      } catch (error) {
        console.error('UserAuthSessionsView - updateAuthSessionDeviceName - updateUserAuthSessionDeviceName - error: ', error)
      }

      // add a slight delay so you always see the spinner/loading ui to make it more obvious it updated without showing a confirmation msg/indicator
      await new Promise((resolve) => setTimeout(resolve, 500))

      if (updateResult) {
        const newAuthSessions = [...authSessions ?? []]
        newAuthSessions.map((aS) => {
          if (aS.id === authSession.id) { aS.deviceName = newDeviceName }
          return aS
        })
        setAuthSessions(newAuthSessions)
      }

      // remove the auth session from the updating list
      const updatingSessionsRemoved = [...updatingSessionsAdded]
      const index = updatingSessionsRemoved.indexOf(authSession.id)
      if (index >= 0) {
        updatingSessionsRemoved.splice(index, 1)
        setUpdatingSessions(updatingSessionsRemoved)
      }
    }
  }

  return (
    <div className={styles.authSessions}>
      <h1>Login Sessions</h1>

      {loading && (<ArkLoaderView message='Loading' className={styles.loading} />)}

      {!loading && (<>

        <ArkButton icon size='medium' color='grey' className={styles.refresh} disabled={loading} onClick={reloadAuthSessions}>
          <Icon name="refresh" />
        </ArkButton>

        {(!authSessions || authSessions.length === 0) && (<>No Sessions Found</>)}

        {authSessions && authSessions.length > 0 && (<>
          <div>
            You are currently logged in across {authSessions.length} devices and/or browsers.
          </div>
          <List divided relaxed inverted className={styles.list}>
            {authSessions.map((authSession, _index) => {
              const isMobile = false // TODO: once the api supports device type, port it to here, also support tablet specific icons as well as mobile/desktop? <<<
              const currentDeviceUUID = authActions.getAuthDeviceUUID()
              const isCurrentSession = authSession.deviceUUID === currentDeviceUUID
              const isLoggingOutSession = loggingOutSessions.includes(authSession.id)
              const isUpdatingSession = updatingSessions.includes(authSession.id)
              const deviceTitle = authSession.deviceName ?? authSession.deviceUUID
              return (
                <List.Item key={'authSession_' + authSession.id}>
                  <List.Content floated='right'>
                    <ArkButton basic size='tiny' color='red' onClick={() => logoutAuthSession(authSession)} loading={isLoggingOutSession}>
                      Logout Device
                    </ArkButton>
                  </List.Content>
                  <List.Icon name={isMobile ? 'mobile alternate' : 'desktop'} size='large' verticalAlign='middle' />
                  <List.Content>
                    <List.Header>
                      {deviceTitle}
                      {isCurrentSession ? ' (CURRENT)' : ''}
                      <ArkButton icon size='small' className={styles.edit} loading={isUpdatingSession} onClick={() => updateAuthSessionDeviceName(authSession)}>
                        <Icon name="edit" />
                      </ArkButton>
                    </List.Header>
                    <List.Description>{authSession.lastActivity ? 'last active: ' + formatDateTime(authSession.lastActivity) : ''}</List.Description>
                  </List.Content>
                </List.Item>
              )
            })}
          </List>
        </>)}

        {/* <ArkDivider /> */}

        <div className={styles.logoutAll}>
          <ArkButton basic size='tiny' color='red' onClick={() => logoutAllAuthSessions()} loading={loggingOutAllSessions}>
            Logout All Devices
          </ArkButton>
        </div>
      </>)}

    </div>
  )
}
export default UserAuthSessionsView
