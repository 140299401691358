/**
 * ArkPage
 */

import React, { ReactNode, useContext, useEffect } from 'react'

// grab the app build version from the `package.json` file
// TODO: is there a way to import just the version from package.json without importing the whole file?
// TODO: would `package.json` be included in the final build if it wasn't referenced here? if not, can we instead add a build script that extracts the version into a js file we import & use here perhaps?
// NB: previously did it using `import { version } from...` but that now results in the following error..
//     ..since certain package (eslint?) updates: `Should not import the named export 'version' (imported as 'version') from default-exporting module (only default export is available soon)`
// import { version } from '../../../../package.json'
import packageInfo from '../../../../package.json'

import { DEBUG_MODE } from '../../../constants/config'
import { GlobalConfigContext } from 'src/core/providers'
import { LayoutContext, Section } from '../../providers/LayoutProvider'
import { ResponsiveContext } from '../../providers/ResponsiveProvider'
import { ServerContext } from '../../providers/ServerProvider'
import ArkPageHeader from '../ArkPageHeader'
import ArkIcon from '../ArkIcon'
import ArkIconButton from '../ArkIconButton'
import ArkResponsive from '../ArkResponsive'
import ArkSpacer from '../ArkSpacer'
import LeftSidebar from './LeftSidebar'
import RightSidebar from './RightSidebar'

import styles from './ArkPage.module.css'

export interface ArkPageProps {
  children?: ReactNode
  leftSidebar?: ReactNode
  noScroll?: boolean // FIXME remove after completing mobile responsiveness
  onRightSidebarClose?: () => void
  rightSidebar?: ReactNode
  section?: Section
  showSelectCompany?: boolean
  showSelectProject?: boolean
}

const ArkPage = (props: ArkPageProps) => {
  const {
    children,
    leftSidebar,
    noScroll,
    onRightSidebarClose,
    rightSidebar,
    section,
    showSelectCompany,
    showSelectProject
  } = props

  const { actions: layoutActions, store: layoutStore } = useContext(LayoutContext)
  const { setCurrentSection, setIsLeftSidebarOpen } = layoutActions
  const { isLeftSidebarOpen } = layoutStore

  const { store: responsiveStore } = useContext(ResponsiveContext)
  const { isMobile } = responsiveStore

  const { store: serverStore } = useContext(ServerContext)
  const { apiServerType } = serverStore

  const { store: configStore } = useContext(GlobalConfigContext)

  useEffect(() => {
    setCurrentSection(section)
  }, [section])

  const headerComponent = (
    <>
      <div className={styles.header}>
        <ArkPageHeader showSelectCompany={showSelectCompany} showSelectProject={showSelectProject} />
      </div>
      {leftSidebar && (
        <ArkResponsive mobileOnly>
          <ArkIconButton
            active={isLeftSidebarOpen}
            className={styles.menuButton}
            name='bars'
            onClick={() => setIsLeftSidebarOpen(!isLeftSidebarOpen)}
            size={24}
          />
        </ArkResponsive>
      )}
    </>
  )

  const footerComponent = (
    <footer className={styles.footer}>
      <div>{`Version: ${packageInfo.version}`}</div>
      {process.env.NODE_ENV && <div>{`-${process.env.NODE_ENV}`}</div>}
      {DEBUG_MODE && apiServerType && (
        <>
          <ArkSpacer />
          <div>{`(${apiServerType})`}</div>
        </>
      )}
      {configStore.config.showPoweredByFooter && (
        <>
          <ArkSpacer large />
          <a className={styles.poweredBy} href='https://reprostream.com/' rel="noreferrer" target='_blank'>
            <div className={styles.poweredByText}>Powered by</div>
            <ArkSpacer small />
            <img alt="RePro Stream" className={styles.poweredByLogo} src='/logo.svg' />
          </a>
        </>
      )}
      {DEBUG_MODE && (
        <>
          <ArkSpacer />
          <ArkIcon color='var(--red)' name='debug' />
        </>
      )}
    </footer>
  )

  return (
    <div className={`${styles.container} ${isMobile ? styles.mobile : ''}`}>
      {headerComponent}
      <div className={styles.mainContainer}>
        {leftSidebar && (
          <LeftSidebar>
            {leftSidebar}
          </LeftSidebar>
        )}
        <main className={`${styles.main} ${noScroll ? styles.noScroll : ''}`}>
          {children}
        </main>
        {rightSidebar && (
          <RightSidebar onClose={onRightSidebarClose}>
            {rightSidebar}
          </RightSidebar>
        )}
      </div>
      {footerComponent}
    </div>
  )
}

export default ArkPage
