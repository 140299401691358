/**
 * ArkPanel
 */

import React from 'react'

import styles from './ArkPanel.module.css'

import { Item } from 'semantic-ui-react'
import ArkIcon, { IconName } from '../ArkIcon'

export interface ArkPanelHeaderProps {
  title?: string | React.ReactNode
  icon?: React.ReactNode
  iconName?: IconName
  description?: string | React.ReactNode
  meta?: string | React.ReactNode
  children?: React.ReactNode
  className?: string
  bottomBorder?: boolean
}

const ArkPanelHeader = (props: ArkPanelHeaderProps) => {
  const { title, icon, iconName, description, meta, children, className, bottomBorder } = props
  return (
    <Item.Group className={styles.headerPanel + (bottomBorder ? ' ' + styles.bordered : '') + (className ? ' ' + className : '')}>
      <Item>
        {(icon || iconName) && (
          <Item.Image size='mini'>
            {React.isValidElement(icon) && (icon)}
            {!icon && iconName && (<ArkIcon color='var(--tx-light)' name={iconName} size={36} />)}
          </Item.Image>
        )}
        <Item.Content>
          <Item.Header>{title}</Item.Header>
          {description && (<Item.Description className={styles.desc}>{description}</Item.Description>)}
          {meta && (<Item.Meta className={styles.meta}>{meta}</Item.Meta>)}
          {children}
        </Item.Content>
      </Item>
    </Item.Group>
  )
}

export default ArkPanelHeader
