import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import _ from 'lodash'

import { CompanyAdminContext } from 'src/core/providers'

import { CompanyGroup, GroupUserOperation, Project, ProjectUser } from 'src/core/models'
import { ICompanyGroupAddData, ICompanyGroupUpdateData, ProjectGroup } from 'src/core/models/group'

import { OBJECT_COMPANY_SHORTNAME, OBJECT_GROUP_NAME, OBJECT_PROJECT_NAME, OBJECT_PROJECT_NAME_PLURAL, OBJECT_USER_NAME, OBJECT_USER_NAME_PLURAL, SECTION_COMPANY_NAME } from 'src/constants/strings'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldOption, ArkFormFieldType, ArkFormFieldValues, ArkFormProps /* ArkFormFieldOption, ArkFormFieldPlaceholder */ } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { capitalize, pluralize } from 'src/core/utilities/strings'

import styles from './CompanyGroupForm.module.css'

const formSchema = yup.object().shape({
  name: yup.string().min(4).max(50).label(`${capitalize(OBJECT_COMPANY_SHORTNAME)} ${capitalize(OBJECT_GROUP_NAME)} Name`),
  desc: yup.string().min(0).max(255).label(`${capitalize(OBJECT_COMPANY_SHORTNAME)} ${capitalize(OBJECT_GROUP_NAME)} Description`)
  // parentGroup: yup.number().transform((currentVal, originalVal) => {
  //   // TODO: why is a null value coming through as a string of null sometimes (when editing an existing group with no parent group set??)
  //   return originalVal === null || originalVal === 'null' ? null : currentVal
  // }).nullable().min(0)
})

export enum GroupFormMode {
  Add = 'add',
  Edit = 'edit'
}

enum CreateGroupSource {
  New = 1,
  CloneProject = 2
}

export type CompanyGroupDidSaveCallback = (group: CompanyGroup, formMode: GroupFormMode) => void

interface IProps {
  mode: GroupFormMode
  companyId: number
  group?: CompanyGroup
  onCancel?: Function
  onDidSave?: CompanyGroupDidSaveCallback
  onClose?: Function
  insideModal?: boolean // ArkForm prop - enable when showing this form within a modal (so fieldset label bg's match)
}

const CompanyGroupForm = (props: IProps) => {
  const mounted = useRef(false)

  const { mode, companyId, group, onCancel: _onCancel, onDidSave: _onDidSave, onClose: _onClose, insideModal } = props

  const companyAdminContext = useContext(CompanyAdminContext)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSaved, setHasSaved] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()
  // clone group creation - user results
  const [cloneProjectId, setCloneProjectId] = useState<number | undefined>()
  const [cloneGroupId, setCloneGroupId] = useState<number | undefined>()
  const [isCloning, setIsCloning] = useState<boolean>(false)
  const [hasCloned, setHasCloned] = useState<boolean>(false)
  const [cloneUsersAddedToGroup, setCloneUsersAddedToGroup] = useState<Map<number, { user: ProjectUser }> | undefined>()
  const [cloneUserErrors, setCloneUserErrors] = useState<Map<number, { user: ProjectUser, error: Error }> | undefined>()

  // tracked field values (NB: some are only used at submit time & aren't tracked here)
  const [createGroupSource, setCreateGroupSource] = useState<CreateGroupSource | undefined>(CreateGroupSource.New)

  const [loadingCompanyProjects, setLoadingCompanyProjects] = useState<boolean>(false)
  const [companyProjects, setCompanyProjects] = useState<Array<Project> | undefined>()
  const [companyProjectsError, setCompanyProjectsError] = useState<Error | undefined>()

  const [loadingProjectGroups, setLoadingProjectGroups] = useState<boolean>(false)
  const [projectsGroups, setProjectGroups] = useState<Array<ProjectGroup> | undefined>()
  const [projectGroupsError, setProjectGroupsError] = useState<Error | undefined>()

  const [loadingGroupUsers, setLoadingGroupUsers] = useState<boolean>(false)
  const [groupUsers, setGroupUsers] = useState<Array<ProjectUser> | undefined>()
  const [groupUsersError, setGroupUsersError] = useState<Error | undefined>()

  // form field values (saved & current)
  const [savedValues, setSavedValues] = useState({
    name: group?.name ?? '',
    desc: group?.desc ?? '',
    isVisibleToAllProjects: group?.isVisibleToAllProjects ?? false
  })
  const [formValues, setFormValues] = useState(savedValues)
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])

  // -------

  const loadCompanyProjects = async () => {
    if (loadingCompanyProjects) return
    try {
      setLoadingCompanyProjects(true)
      setCompanyProjectsError(undefined)
      await new Promise(resolve => setTimeout(resolve, 500)) // DEBUG ONLY: simulate loading delay
      const projects = await companyAdminContext.actions.getAllCompanyProjects(companyId)
      console.log('CompanyGroupForm - loadCompanyProjects - projects:', projects)
      setCompanyProjects(projects)
      setLoadingCompanyProjects(false)
    } catch (error) {
      console.error('CompanyGroupForm - loadCompanyProjects - error:', error)
      if (mounted.current) {
        setLoadingCompanyProjects(false)
        setCompanyProjects(undefined)
        setCompanyProjectsError(error)
      }
    }
  }

  // -------

  // NB: we pass in the `projectId` as the state `cloneProjectId` var may not be updated when we call this
  const loadProjectGroups = async (projectId: number) => {
    console.log('CompanyGroupForm - loadProjectGroups - projectId:', projectId)
    if (loadingProjectGroups === true) return
    try {
      setLoadingProjectGroups(true)
      setProjectGroupsError(undefined)
      await new Promise(resolve => setTimeout(resolve, 500)) // DEBUG ONLY: simulate loading delay
      const groups = await companyAdminContext.actions.getCompanyProjectGroups(companyId, projectId)
      console.log('CompanyGroupForm - loadProjectGroups - groups:', groups)
      if (mounted.current) {
        setLoadingProjectGroups(false)
        setProjectGroups(groups || [])
      }
    } catch (error) {
      console.error('CompanyGroupForm - loadProjectGroups - error:', error)
      if (mounted.current) {
        setLoadingProjectGroups(false)
        setProjectGroups(undefined)
        setProjectGroupsError(error)
      }
    }
  }

  // -------

  // NB: we pass in the `groupId` as the state `cloneGroupId` var may not be updated when we call this
  const loadGroupsUsers = async (groupId: number) => {
    console.log('CompanyGroupForm - loadGroupsUsers - cloneProjectId:', cloneProjectId, ' groupId:', groupId)
    if (loadingGroupUsers === true || !cloneProjectId || !groupId) return
    try {
      setLoadingGroupUsers(true)
      setGroupUsersError(undefined)
      await new Promise(resolve => setTimeout(resolve, 500)) // DEBUG ONLY: simulate loading delay
      const users = await companyAdminContext.actions.getCompanyProjectGroupUsers(companyId, cloneProjectId, groupId)
      console.log('CompanyGroupForm - loadGroupsUsers - users:', users)
      if (mounted.current) {
        setLoadingGroupUsers(false)
        setGroupUsers(users || [])
      }
    } catch (error) {
      console.error('CompanyGroupForm - loadProjectGroups - error:', error)
      if (mounted.current) {
        setLoadingGroupUsers(false)
        setGroupUsers(undefined)
        setGroupUsersError(error)
      }
    }
  }

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const getFormValueChanges = () => {
    const _changes: Array<string> = []
    if (formValues) {
      for (const fieldName of Object.keys(formValues)) {
        const oldValue = savedValues !== undefined ? (savedValues as any)[fieldName] : undefined
        const newValue = (formValues as any)[fieldName]
        // console.log('CompanyGroupForm - getFormValueChanges - fieldName:', fieldName, ' oldValue:', oldValue, ` (${typeof oldValue})`, ' newValue:', newValue, ` (${typeof newValue})`)
        if (!_.isEqual(oldValue, newValue)) {
          if (
            // NB: added extra check if comparing an empty object/array to the an undefined old value
            !(oldValue === undefined && newValue !== undefined && typeof newValue === 'object' && newValue.length === 0) &&
            // NB: added extra check if comparing an empty string to an undefined old value
            !(oldValue === undefined && newValue !== undefined && typeof newValue === 'string' && newValue === '')
          ) {
            _changes.push(fieldName)
          }
        }
      }
    }
    // console.log('CompanyGroupForm - getFormValueChanges - _changes:', _changes)
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = getFormValueChanges()
    setChanges(_changes)
    // console.log('CompanyGroupForm - useEffect[formValues] - formValues:', formValues, ' savedValues:', savedValues, ' _changes:', _changes)
  }, [formValues])

  // -------

  const _addGroup = async (onDidSaveEnabled: boolean = true) => {
    if (isSubmitting) return
    setIsSubmitting(true)
    setError(undefined)
    try {
      const groupData: ICompanyGroupAddData = {
        name: formValues.name
      }
      groupData.desc = formValues.desc.length > 0 ? formValues.desc : undefined
      groupData.isVisibleToAllProjects = formValues.isVisibleToAllProjects
      console.log('CompanyGroupForm - addGroup - groupData:', groupData)
      const savedGroup = await companyAdminContext.actions.addCompanyGroup(companyId, groupData)
      console.log('CompanyGroupForm - addGroup - savedGroup:', savedGroup)
      if (savedGroup) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
          setSavedValues(formValues) // update the saved values to match the current form values
        }
        if (_onDidSave && onDidSaveEnabled) _onDidSave(savedGroup, mode)
        return savedGroup
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          setError(Error('A problem occurred adding the ' + OBJECT_GROUP_NAME + ', please try again.'))
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
    return undefined
  }

  const addNewGroup = async () => {
    return await _addGroup()
  }

  const addCloneGroup = async () => {
    if (!cloneProjectId) return // TODO: throw/set an error?
    const projectId = cloneProjectId
    // create the new company/org group first
    // NB: disable the default `_onDidSave` callback firing after the initial group creation, we instead trigger it at the end of the clone process
    // TODO: if we fail mid way through cloning we still likely want to trigger this so the partial cloned group will show straight away??
    const onDidSaveEnabled = false
    const newCompanyGroup = await _addGroup(onDidSaveEnabled)
    console.log('CompanyGroupForm - addCloneGroup - newCompanyGroup:', newCompanyGroup)
    if (newCompanyGroup) {
      setIsCloning(true)
      setHasCloned(false)
      setError(undefined)
      setCloneUsersAddedToGroup(undefined)
      setCloneUserErrors(undefined)
      await new Promise(resolve => setTimeout(resolve, 500)) // add a brief delay, so the user see's the loading indicator on quick api calls (rather than showing a success message, this makes it kind of obvious it did something & so worked)
      // if the group was added successfully, add the users from the cloned org group to the new project group (inviting/adding them to the project first if needed)
      if (groupUsers) {
        const _cloneUsersAddedToGroup: Map<number, { user: ProjectUser }> = new Map()
        const _cloneUserErrors: Map<number, { user: ProjectUser, error: Error }> = new Map()
        const addOperations: Array<GroupUserOperation> = []
        // const skipUserIds = _cloneUserErrors.size > 0 ? Array.from(_cloneUserErrors.keys()) : []
        for (const user of groupUsers) {
          // if (skipUserIds.includes(user.id)) continue // don't add users to the group if they needed inviting first & that errored (see `userResultsError`)
          addOperations.push({
            operation: 0, // 0 === ADD
            user_id: user.id
          })
        }
        console.log('CompanyGroupForm - addCloneGroup - addOperations:', addOperations)
        if (addOperations.length === 0) {
          console.log('CompanyGroupForm - addCloneGroup - no users to add to the project group (errors occurred inviting new users)')
          if (mounted.current) {
            setIsCloning(false)
            setHasCloned(true) // NB: doesn't really matter if this is true/false here
            setCloneUsersAddedToGroup(_cloneUsersAddedToGroup)
            setCloneUserErrors(_cloneUserErrors)
            setError(undefined)
          }
          if (_onDidSave) _onDidSave(newCompanyGroup, mode) // (re)trigger the did save callback, so the parent component can update with the correct user count etc.
          return // stop the process here if there are no users to add to the group (due to either the source org group having no users to add, or user project add/invite errors above)
        }
        try {
          const result = await companyAdminContext.actions.updateCompanyProjectGroupUsers(companyId, projectId, newCompanyGroup.id, addOperations)
          console.log('CompanyGroupForm - addCloneGroup - updateProjectGroupUsers - result: ', result, ' typeof: ', typeof result, ' instanceof Map: ', result instanceof Map)
          let hasUpdated = false
          let error: Error | undefined
          if (result === true) { // all operations succeeded
            console.log('CompanyGroupForm - addCloneGroup - updateProjectGroupUsers - result === true - all users added ok')
            if (mounted.current) {
              hasUpdated = true
            }
            for (const operation of addOperations) {
              const user = groupUsers.find((user) => user.id === operation.user_id)
              if (user) _cloneUsersAddedToGroup.set(operation.user_id, { user: user })
            }
          } else if (result instanceof Map) { // 1 or more operations had errors (but some may have still succeeded)
            // all operations failed
            if (result.size === addOperations.length) {
              hasUpdated = false
            } else { // only some operations failed
              hasUpdated = true // set true if some added/updated ok
            }
            // the result contains errors for each operation that failed (any that succeeded are not included in the result map)
            for (const [userId, errorData] of result) {
              console.log('CompanyGroupForm - addCloneGroup - updateProjectGroupUsers - userId:', userId, ' errorData:', errorData)
              const user = groupUsers.find((user) => user.id === userId)
              if (user) _cloneUserErrors.set(userId, { user: user, error: errorData.error_message ? Error(errorData.error_message) : Error('An error occurred adding the user to the group.') })
            }
            for (const operation of addOperations) {
              console.log('CompanyGroupForm - addCloneGroup - updateProjectGroupUsers - result instanceof Map - operation:', operation, ' operation.user_id:', operation.user_id, ' result.has(operation.user_id):', result.has(operation.user_id), ' result:', result)
              if (result.has(operation.user_id)) continue
              const user = groupUsers.find((user) => user.id === operation.user_id)
              if (user) _cloneUsersAddedToGroup.set(operation.user_id, { user: user })
            }
            console.log('CompanyGroupForm - addCloneGroup - updateProjectGroupUsers - result instanceof Map - _cloneUsersAddedToGroup:', _cloneUsersAddedToGroup)
            error = new Error('A problem occurred adding one or more users to the group.')
          } else { // general/fallback error (with the whole api call)
            hasUpdated = false
            error = new Error('A problem occurred adding users to the group.')
          }
          console.log('CompanyGroupForm - addCloneGroup - updateProjectGroupUsers - hasUpdated:', hasUpdated, ' _cloneUserErrors:', _cloneUserErrors, ' error:', error) // ' savedIds:', savedIds
          if (mounted.current) {
            setIsCloning(false)
            setHasCloned(hasUpdated) // true
            setCloneUsersAddedToGroup(_cloneUsersAddedToGroup)
            setCloneUserErrors(_cloneUserErrors)
            setError(error)
          }
          if (_onDidSave) _onDidSave(newCompanyGroup, mode) // (re)trigger the did save callback, so the parent component can update with the correct user count etc.
        } catch (error) {
          if (mounted.current) {
            setIsCloning(false)
            setError(error)
          }
          // TESTING: still fire the callback as the initial group was added successfully, just the user import failed
          if (_onDidSave) _onDidSave(newCompanyGroup, mode) // (re)trigger the did save callback, so the parent component can update with the correct user count etc.
        }
      } else { // no users to add (`groupUsers === undefined`) - clean up...
        if (mounted.current) {
          setIsCloning(false)
          setHasCloned(false) // TODO: false or true here? (NB: shouldn't really matter here)
          setCloneUsersAddedToGroup(undefined)
          setCloneUserErrors(undefined)
          setError(error)
        }
        if (_onDidSave) _onDidSave(newCompanyGroup, mode) // (re)trigger the did save callback, so the parent component can update with the correct user count etc.
      }
    } else {
      // group failed to add, so don't try to add the users (the original add group error should show directly, so no action needed here)
    }
  }

  const updateGroup = async () => {
    if (isSubmitting || !group) return
    setIsSubmitting(true)
    setError(undefined)
    try {
      const groupData: ICompanyGroupUpdateData = {}
      if (changes.includes('name')) groupData.name = formValues.name
      if (changes.includes('desc')) groupData.desc = formValues.desc
      if (changes.includes('isVisibleToAllProjects')) groupData.isVisibleToAllProjects = formValues.isVisibleToAllProjects
      console.log('CompanyGroupForm - updateGroup - groupData:', groupData)
      const savedGroup = await companyAdminContext.actions.updateCompanyGroup(companyId, group.id, groupData)
      console.log('CompanyGroupForm - updateGroup - savedGroup:', savedGroup)
      if (savedGroup) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
          setSavedValues(formValues) // update the saved values to match the current form values
        }
        if (_onDidSave) _onDidSave(savedGroup, mode)
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          setError(Error('A problem occurred updating the ' + OBJECT_GROUP_NAME + ', please try again.'))
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('CompanyGroupForm - onFormSubmit - fieldValues:', fieldValues, ' formValues:', formValues)
    if (props.mode === GroupFormMode.Add) {
      if (createGroupSource === CreateGroupSource.New) {
        addNewGroup()
      } else if (createGroupSource === CreateGroupSource.CloneProject) {
        addCloneGroup()
      } else {
        console.warn('CompanyGroupForm - onFormSubmit - no createGroupSource selected')
      }
    } else if (props.mode === GroupFormMode.Edit) {
      updateGroup()
    }
  }

  const onValueChanged = (fieldKey: string, fieldValue: any, _oldFieldValue: any) => {
    // console.log('CompanyGroupForm - onValueChanged - fieldKey:', fieldKey, ' fieldValue:', fieldValue, ' oldFieldValue:', _oldFieldValue)
    if (fieldKey in savedValues) {
      setFormValues({
        ...formValues,
        [fieldKey]: fieldValue
      })
    } else if (fieldKey === 'createGroupSource') {
      const _fieldValue = typeof fieldValue === 'number' ? fieldValue : undefined
      setCreateGroupSource(_fieldValue)
      // load the company projects if the user selects the clone project option for the first time
      if (_fieldValue === CreateGroupSource.CloneProject && !companyProjects) {
        loadCompanyProjects()
      }
    } else if (fieldKey === 'cloneProjectId') {
      const _fieldValue = typeof fieldValue === 'number' ? fieldValue : undefined
      setCloneProjectId(_fieldValue)
      if (cloneGroupId) setCloneGroupId(undefined) // clear the selected project group (if one is set) when the project changes
      if (groupUsers) setGroupUsers(undefined) // clear the group users when the project changes
      // load the project groups for the selected project
      if (_fieldValue) {
        loadProjectGroups(_fieldValue)
      } else {
        setProjectGroups(undefined)
      }
    } else if (fieldKey === 'cloneGroupId') {
      const _fieldValue = typeof fieldValue === 'number' ? fieldValue : undefined
      setCloneGroupId(_fieldValue)
      if (groupUsers) setGroupUsers(undefined) // clear the group users when the project group changes
      // load the project group users for the selected project group
      if (_fieldValue) {
        loadGroupsUsers(_fieldValue)
      } else {
        setGroupUsers(undefined)
      }
    }
  }

  const onCancel = () => {
    console.log('CompanyGroupForm - onCancel')
    if (_onCancel) _onCancel()
  }

  const onClose = () => {
    console.log('CompanyGroupForm - onClose')
    if (_onClose) _onClose()
  }

  // -------

  const _renderGroupUsersList = (groupUsers?: Array<ProjectUser>) => {
    if (loadingGroupUsers) return null
    const groupUserCount = groupUsers?.length ?? 0
    return (
      <div className={styles.groupUsers + ' ' + styles.list}>
        {groupUsers && groupUsers.length > 0 && (
          <ul>
            {groupUsers.map((user: ProjectUser) => {
              return (
                <li key={'group_user_' + user.id}>
                  <ArkAvatar
                    type={user.userAvatarType()}
                    name={user.name()}
                    size="30"
                  />
                </li>
              )
            })}
          </ul>
        )}
        {(groupUserCount === 0) && (<span>⚠️ No {OBJECT_USER_NAME_PLURAL} assigned to this {OBJECT_GROUP_NAME}</span>)}
      </div>
    )
  }
  const renderProjectGroupUserSummary = () => {
    // console.log('CompanyGroupForm - renderProjectGroupUserSummary - formValues:', formValues)
    const projectGroupId = cloneGroupId
    const projectGroup = projectGroupId ? projectsGroups?.find((group) => group.id === projectGroupId) : undefined
    // console.log('CompanyGroupForm - renderProjectGroupUserSummary - projectGroupId:', projectGroupId, ' projectGroup:', projectGroup, ' groupUsers:', groupUsers)
    if (!projectGroup) return null
    const groupUsersCount = groupUsers?.length ?? 0
    return (
      <div className={styles.orgGroupUserSummary}>
        {loadingGroupUsers && <div className={styles.loader}><ArkLoader small inline /></div>}
        {!loadingGroupUsers && (
          <div>
            <div className={styles.orgGroupUserSummaryInfo}>
              This will create a new {OBJECT_GROUP_NAME} of <span className={styles.orgGroupUserCount}>{groupUsersCount}</span> {pluralize(OBJECT_USER_NAME, groupUsersCount)}{groupUsersCount > 0 ? ':' : '.'}
            </div>
            <ul className={styles.orgGroupUsersBreakdown}>
              {groupUsersCount > 0 && (
                <li>
                  {/* <div className={styles.orgGroupUserBreakdownInfo}><span className={styles.orgGroupUserCount}>{groupUsersCount}</span> {pluralize(OBJECT_USER_NAME, groupUsersCount)} will be added to this {OBJECT_GROUP_NAME}:<br /></div> */}
                  {_renderGroupUsersList(groupUsers)}
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    )
  }

  // -------

  const formFields: Array<ArkFormField> = []

  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'groupDetails',
    label: 'Details',
    fields: [
      {
        type: ArkFormFieldType.Input,
        key: 'name',
        label: SECTION_COMPANY_NAME + ' ' + OBJECT_GROUP_NAME + ' name',
        required: true,
        defaultValue: group?.name ?? undefined,
        className: hasChanges('name') ? styles.hasChanged : undefined
      },
      {
        type: ArkFormFieldType.Input,
        key: 'desc',
        label: SECTION_COMPANY_NAME + ' ' + OBJECT_GROUP_NAME + ' description',
        required: false,
        defaultValue: group?.desc ?? undefined,
        className: hasChanges('desc') ? styles.hasChanged : undefined
      }
    ],
    collapsible: false,
    collapsed: false
  })

  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'groupPermissions',
    label: 'Permissions',
    fields: [
      {
        type: ArkFormFieldType.Field,
        key: 'isVisibleToAllProjectsLabel',
        content: (<>Specify which {OBJECT_PROJECT_NAME_PLURAL} can use this {OBJECT_COMPANY_SHORTNAME} {OBJECT_GROUP_NAME}.</>)
      },
      {
        type: ArkFormFieldType.Radio,
        key: 'isVisibleToAllProjects',
        label: 'All ' + OBJECT_PROJECT_NAME_PLURAL,
        required: false,
        toggle: true,
        defaultValue: group?.isVisibleToAllProjects !== undefined ? group?.isVisibleToAllProjects : false, // (mode === GroupFormMode.Add ? false : false) // NB: default to false for new groups
        // wrapperProps: { className: styles.flagWrapper },
        className: hasChanges('isVisibleToAllProjects') ? styles.hasChanged : undefined
      }
    ],
    collapsible: false,
    collapsed: false
  })

  if (mode === GroupFormMode.Add) {
    // CreateGroupSource
    const createGroupSourceOptions: Array<ArkFormFieldOption> = [
      { key: 'new', text: 'Create new empty ' + capitalize(OBJECT_GROUP_NAME), value: CreateGroupSource.New },
      { key: 'cloneProject', text: 'Clone and import users from ' + capitalize(OBJECT_PROJECT_NAME) + ' ' + capitalize(OBJECT_GROUP_NAME), value: CreateGroupSource.CloneProject }
    ]
    formFields.push({
      type: ArkFormFieldType.Fieldset,
      key: 'groupSourceFieldset',
      label: 'Source',
      fields: [
        {
          type: ArkFormFieldType.Dropdown,
          key: 'createGroupSource',
          label: OBJECT_GROUP_NAME + ' Source',
          placeholder: 'Select ' + capitalize(OBJECT_GROUP_NAME) + ' Source',
          required: true,
          value: createGroupSource, // NB: powered by the locally tracked state
          options: createGroupSourceOptions,
          fieldProps: {
            scrolling: true, // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
            clearable: true
          }
        }
      ],
      collapsible: false,
      collapsed: false
    })
  }

  if (createGroupSource === CreateGroupSource.CloneProject) {
    const cloneProjectOptions: Array<ArkFormFieldOption> = []
    if (companyProjects) {
      for (const project of companyProjects) {
        cloneProjectOptions.push({ key: 'project_' + project.id, text: project.name, value: project.id })
      }
    }
    const cloneGroupOptions: Array<ArkFormFieldOption> = []
    if (projectsGroups) {
      for (const group of projectsGroups) {
        cloneGroupOptions.push({ key: 'group_' + group.id, text: group.name, value: group.id })
      }
    }
    formFields.push({
      type: ArkFormFieldType.Fieldset,
      key: 'cloneProjectFieldset',
      label: 'Clone ' + capitalize(OBJECT_PROJECT_NAME) + ' ' + capitalize(OBJECT_GROUP_NAME),
      fields: [
        ...(loadingCompanyProjects
          ? [
            {
              type: ArkFormFieldType.Field,
              key: 'cloneProjectLoading',
              content: (<><ArkLoader small inline /></>)
            }
          ]
          : []
        ),
        ...(!loadingCompanyProjects
          ? [
            {
              type: ArkFormFieldType.Dropdown,
              key: 'cloneProjectId',
              label: OBJECT_PROJECT_NAME,
              placeholder: 'Select ' + capitalize(OBJECT_PROJECT_NAME),
              required: true,
              value: cloneProjectId, // NB: powered by the locally tracked state
              options: cloneProjectOptions,
              fieldProps: {
                scrolling: true, // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
                clearable: true,
                loading: loadingCompanyProjects
              }
            }
          ]
          : []
        ),
        ...((!loadingCompanyProjects && cloneProjectId) && loadingProjectGroups
          ? [
            {
              type: ArkFormFieldType.Field,
              key: 'cloneGroupLoading',
              content: (<><ArkLoader small inline /></>)
            }
          ]
          : []
        ),
        ...((!loadingCompanyProjects && cloneProjectId) && !loadingProjectGroups
          ? [
            {
              type: ArkFormFieldType.Dropdown,
              key: 'cloneGroupId',
              label: OBJECT_GROUP_NAME,
              placeholder: 'Select ' + capitalize(OBJECT_GROUP_NAME),
              required: true,
              value: cloneGroupId, // NB: powered by the locally tracked state
              options: cloneGroupOptions,
              fieldProps: {
                scrolling: true, // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
                clearable: true,
                loading: loadingProjectGroups
              }
            }
          ]
          : []
        ),
        ...((!loadingCompanyProjects && cloneProjectId) && (!loadingProjectGroups && cloneGroupId) && loadingGroupUsers
          ? [
            {
              type: ArkFormFieldType.Field,
              key: 'cloneGroupUsersLoading',
              content: (<><ArkLoader small inline /></>)
            }
          ]
          : []
        ),
        ...((!loadingCompanyProjects && cloneProjectId) && (!loadingProjectGroups && cloneGroupId) && !loadingGroupUsers
          ? [
            {
              type: ArkFormFieldType.Field,
              key: 'cloneGroupUserSummary',
              content: renderProjectGroupUserSummary()
            }
          ]
          : []
        )
      ],
      collapsible: false,
      collapsed: false
    })
  }

  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      {
        type: ArkFormFieldType.CancelButton,
        key: 'cancel',
        label: 'CANCEL',
        fieldProps: { onClick: onCancel, floated: 'left' }
      },
      {
        type: ArkFormFieldType.OKButton,
        key: 'submit',
        label: (mode === GroupFormMode.Edit ? 'SAVE' : 'ADD'),
        fieldProps: { loading: isSubmitting, floated: 'right' },
        disabled: changes.length === 0,
        disabledTooltip: 'No Changes to Save'
      }
    ],
    fieldProps: { widths: 'equal' }
  })

  // clone results
  const projectGroupUserCount = groupUsers?.length ?? 0
  const cloneUsersAddedCount = cloneUsersAddedToGroup?.size ?? 0
  const cloneUsersErrorCount = cloneUserErrors?.size ?? 0
  const cloneUsersGroup: Array<ProjectUser> = [] // array of existing users successfully added to the group
  if (cloneUsersAddedToGroup && cloneUsersAddedToGroup.size > 0) {
    for (const [, user] of cloneUsersAddedToGroup) {
      cloneUsersGroup.push(user.user)
    }
  }
  const cloneUserGroupErrors: Array<ProjectUser> = []
  if (cloneUserErrors && cloneUserErrors.size > 0) {
    for (const [, { user }] of cloneUserErrors) {
      cloneUserGroupErrors.push(user)
    }
  }

  return (
    <>
      <ArkHeader as="h2" inverted>
        {mode === GroupFormMode.Edit ? 'Edit' : 'Add'} {SECTION_COMPANY_NAME} {OBJECT_GROUP_NAME}
      </ArkHeader>

      {hasSaved && (<>
        <ArkMessage positive>
          <ArkMessage.Header>{SECTION_COMPANY_NAME} {OBJECT_GROUP_NAME} {mode === GroupFormMode.Edit ? 'Updated' : 'Created'}</ArkMessage.Header>
          <p>The {SECTION_COMPANY_NAME} {OBJECT_GROUP_NAME} has been {mode === GroupFormMode.Edit ? 'updated' : 'created'} successfully</p>
        </ArkMessage>
        {mode === GroupFormMode.Add && cloneProjectId && cloneGroupId && (
          <>
            <ArkSpacer />
            {isCloning && (<div><ArkLoader className={styles.cloneLoading} horizontal message={<>Importing {capitalize(OBJECT_USER_NAME_PLURAL)}...</>} /></div>)}
            {!isCloning && (
              <ArkMessage positive={cloneUsersErrorCount === 0} warning={cloneUsersErrorCount > 0} className={styles.orgGroupUsersImportResult}>
                <ArkMessage.Header>{OBJECT_GROUP_NAME} {OBJECT_USER_NAME} Import</ArkMessage.Header>
                {hasCloned && (
                  <>
                    {capitalize(OBJECT_GROUP_NAME)} clone complete.
                  </>
                )}
                <>
                  {(cloneUsersAddedCount > 0 || cloneUsersErrorCount > 0) && (
                    <>
                      <>
                        {cloneUsersAddedCount > 0
                          ? (
                            <p>
                              <span className={styles.cloneUserCount}>{cloneUsersAddedCount}</span> {cloneUsersAddedCount === 1 ? <>{OBJECT_USER_NAME} was</> : <>{OBJECT_USER_NAME_PLURAL} were</>} added to the {OBJECT_GROUP_NAME} successfully:
                            </p>
                          )
                          : null}
                        {cloneUsersAddedCount > 0 && _renderGroupUsersList(cloneUsersGroup)}
                      </>
                      <>
                        {cloneUsersErrorCount > 0
                          ? (
                            <p>
                              <span className={styles.cloneUserWarning}>WARNING:</span> <span className={styles.cloneUserCount}>{cloneUsersErrorCount}</span> {cloneUsersErrorCount === 1 ? OBJECT_USER_NAME : OBJECT_USER_NAME_PLURAL} failed to add to the {OBJECT_GROUP_NAME}:
                            </p>
                          )
                          : null}
                        {cloneUsersErrorCount > 0 && _renderGroupUsersList(cloneUserGroupErrors)}
                      </>
                    </>
                  )}
                  {(cloneUsersAddedCount === 0 && cloneUsersErrorCount === 0) && (
                    <>
                      {projectGroupUserCount === 0 && (<p>The source {OBJECT_GROUP_NAME} has 0 {OBJECT_USER_NAME_PLURAL} so this {OBJECT_GROUP_NAME} is empty.</p>)}
                      {projectGroupUserCount > 0 && (<p>No {OBJECT_USER_NAME_PLURAL} were added to the {OBJECT_GROUP_NAME}.</p>)}
                    </>
                  )}
                  {/* TODO: project count as well ?? */}
                </>
              </ArkMessage>
            )}
          </>
        )}
        <ArkButton type="button" color="blue" fluid basic size="large" disabled={isCloning} onClick={onClose} style={{ marginTop: 15 }}>
          OK
        </ArkButton>
      </>)}

      {!hasSaved && (
        <>
          <p>Only admins and managers will see these {OBJECT_GROUP_NAME} details, they are not visible to standard viewing members.</p>
          <ArkForm
            formKey="group"
            inverted
            formError={error ?? companyProjectsError ?? projectGroupsError ?? groupUsersError}
            formFields={formFields}
            formSchema={formSchema}
            onFormSubmit={onFormSubmit}
            onValueChanged={onValueChanged}
            showLabels={true}
            insideModal={insideModal}
          >
          </ArkForm>
        </>
      )}
    </>
  )
}

export default CompanyGroupForm
