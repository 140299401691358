import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import _ from 'lodash'

import { SiteAdminContext } from 'src/core/providers'

import { Company, VideoEngine } from 'src/core/models'
import { IVideoEngineAddData, IVideoEngineUpdateData } from 'src/core/models/video_engine'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldOption, ArkFormFieldType, ArkFormFieldValues, ArkFormMultiError, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkMessage from 'src/core/components/ArkMessage'

import { OBJECT_COMPANY_NAME, OBJECT_COMPANY_NAME_PLURAL, OBJECT_VIDEO_ENGINE_NAME } from 'src/constants/strings'

import styles from './VideoEngineForm.module.css'

const formSchema = yup.object().shape({
  name: yup.string().min(4).max(25).label(OBJECT_VIDEO_ENGINE_NAME + ' Name')
  // TODO: add other fields <<<<
})

// TESTING: dynamic field key validation (with the keys at the root level)
// ref: https://github.com/jquense/yup/issues/130#issuecomment-339549828
// UPDATE: disabled/skipped for now, as the per-field inline errors don't really work/look ok within the port range fields by default & will need tweaking to fit
// const mapValues = require('lodash/mapValues')
// const baseSchema = {
//   name: yup.string().min(4).max(25).label(OBJECT_VIDEO_ENGINE_NAME + ' Name')
//   // TODO: add other fields <<<<
// }
// const formSchema = yup.lazy(obj => yup.object(
//   mapValues(obj, (v: any, k: any) => {
//     console.log('VideoEngineForm - formSchema - k:', k, ' v:', v)
//     if ((k.startsWith('srtInputPortRanges_') || k.startsWith('srtOutputPortRanges_')) && (k.endsWith('_start'))) {
//       console.log('VideoEngineForm - formSchema - PORT RANGE FIELD - START - k:', k, ' v:', v)
//       return yup.number().integer().required().min(1).max(65535).label('Start Port').typeError('Must be between 1-65535')
//     } else if ((k.startsWith('srtInputPortRanges_') || k.startsWith('srtOutputPortRanges_')) && (k.endsWith('_end'))) {
//       console.log('VideoEngineForm - formSchema - PORT RANGE FIELD - END - k:', k, ' v:', v)
//       return yup.number().integer().required().min(1).max(65535).label('End Port').typeError('Must be between 1-65535')
//     } else {
//       return (baseSchema as any)[k]
//     }
//   })
// ))

export enum VideoEngineFormMode {
  Add = 'add',
  Edit = 'edit',
}

interface IProps {
  mode: VideoEngineFormMode
  videoEngine?: VideoEngine
  companies: Array<Company>
  onCancel?: Function
  onSave?: Function
  onClose?: Function
  insideModal?: boolean // ArkForm prop - enable when showing this form within a modal (so fieldset label bg's match)
  // NB: not currently supporting deleting via this form
}

const VideoEngineForm = (props: IProps) => {
  const mounted = useRef(false)

  const { videoEngine, mode, onCancel, onClose, onSave, insideModal } = props

  const siteAdminContext = useContext(SiteAdminContext)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSaved, setHasSaved] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>(undefined)

  // individual settings specific to this form
  const [formValues, setFormValues] = useState({
    // base fields
    name: videoEngine?.name ?? '',
    domain: videoEngine?.domain ?? '',
    ip: videoEngine?.ip ?? '',
    isActive: videoEngine?.isActive ?? (mode === VideoEngineFormMode.Add ? true : undefined),
    // org
    companyId: videoEngine?.companyId ?? -1,
    // control
    controlServerKey: videoEngine?.controlServerKey ?? '',
    controlClientId: videoEngine?.controlClientId ?? '',
    controlAPIKey: videoEngine?.controlAPIKey ?? '',
    controlGroupId: videoEngine?.controlGroupId ?? '',
    // ports
    sldpPort: videoEngine?.sldpPort ?? '',
    sldpPortWS: videoEngine?.sldpPortWS ?? '',
    sldpPortWSS: videoEngine?.sldpPortWSS ?? '',
    rtmpPort: videoEngine?.rtmpPort ?? '',
    rtmpsPort: videoEngine?.rtmpsPort ?? '',
    rtspPort: videoEngine?.rtspPort ?? '',
    icecastInputPort: videoEngine?.icecastInputPort ?? '',
    icecastPlaybackPort: videoEngine?.icecastPlaybackPort ?? '',
    srtInputPortRanges: videoEngine?.srtInputPortRanges ? _.cloneDeep(videoEngine?.srtInputPortRanges) : [], // NB: deep clone the arrays to avoid direct mutations to the underlying object
    srtOutputPortRanges: videoEngine?.srtOutputPortRanges ? _.cloneDeep(videoEngine?.srtOutputPortRanges) : [],
    // transcoders
    templateABRId: videoEngine?.templateABRId ?? '',
    templateTranscoderBaseId: videoEngine?.templateTranscoderBaseId ?? '',
    templateTranscoderFHDId: videoEngine?.templateTranscoderFHDId ?? '',
    templateTranscoderHDId: videoEngine?.templateTranscoderHDId ?? '',
    // watermark
    watermarkEnvAccessKeyId: videoEngine?.watermarkEnvAccessKeyId ?? '',
    watermarkEnvBucket: videoEngine?.watermarkEnvBucket ?? '',
    watermarkEnvPath: videoEngine?.watermarkEnvPath ?? '',
    watermarkEnvSecretAccessKey: videoEngine?.watermarkEnvSecretAccessKey ?? '',
    watermarkLocalPath: videoEngine?.watermarkLocalPath ?? ''
  })
  const [savedValues, setSavedValues] = useState({
    // base fields
    name: videoEngine?.name ?? '',
    domain: videoEngine?.domain ?? '',
    ip: videoEngine?.ip ?? '',
    isActive: videoEngine?.isActive ?? (mode === VideoEngineFormMode.Add ? true : undefined),
    // org
    companyId: videoEngine?.companyId ?? -1,
    // control
    controlServerKey: videoEngine?.controlServerKey ?? '',
    controlClientId: videoEngine?.controlClientId ?? '',
    controlAPIKey: videoEngine?.controlAPIKey ?? '',
    controlGroupId: videoEngine?.controlGroupId ?? '',
    // ports
    sldpPort: videoEngine?.sldpPort ?? '',
    sldpPortWS: videoEngine?.sldpPortWS ?? '',
    sldpPortWSS: videoEngine?.sldpPortWSS ?? '',
    rtmpPort: videoEngine?.rtmpPort ?? '',
    rtmpsPort: videoEngine?.rtmpsPort ?? '',
    rtspPort: videoEngine?.rtspPort ?? '',
    icecastInputPort: videoEngine?.icecastInputPort ?? '',
    icecastPlaybackPort: videoEngine?.icecastPlaybackPort ?? '',
    srtInputPortRanges: videoEngine?.srtInputPortRanges ? _.cloneDeep(videoEngine?.srtInputPortRanges) : [], // NB: deep clone the arrays to avoid direct mutations to the underlying object
    srtOutputPortRanges: videoEngine?.srtOutputPortRanges ? _.cloneDeep(videoEngine?.srtOutputPortRanges) : [],
    // transcoders
    templateABRId: videoEngine?.templateABRId ?? '',
    templateTranscoderBaseId: videoEngine?.templateTranscoderBaseId ?? '',
    templateTranscoderFHDId: videoEngine?.templateTranscoderFHDId ?? '',
    templateTranscoderHDId: videoEngine?.templateTranscoderHDId ?? '',
    // watermark
    watermarkEnvAccessKeyId: videoEngine?.watermarkEnvAccessKeyId ?? '',
    watermarkEnvBucket: videoEngine?.watermarkEnvBucket ?? '',
    watermarkEnvPath: videoEngine?.watermarkEnvPath ?? '',
    watermarkEnvSecretAccessKey: videoEngine?.watermarkEnvSecretAccessKey ?? '',
    watermarkLocalPath: videoEngine?.watermarkLocalPath ?? ''
  })
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])
  // note which fields are numeric, so they can be parsed as numbers when onValueChange is called
  // TODO: memoize this?
  const numericFields = ['companyId', 'sldpPort', 'sldpPortWS', 'sldpPortWSS', 'rtmpPort', 'rtmpsPort', 'rtspPort', 'icecastInputPort', 'icecastPlaybackPort']

  const generateRandomFieldHash = () => [...Array(10)].map(() => Math.random().toString(36)[2]).join('') // ref: https://stackoverflow.com/a/47496558
  const [formFieldHashes, setFormFieldHashes] = useState({ // unique hash for each field to force re-render when the field indexes change (e.g. when removing a port range)
    srtInputPortRanges: generateRandomFieldHash(),
    srtOutputPortRanges: generateRandomFieldHash()
  })

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const getFormValueChanges = () => {
    const _changes: Array<string> = []
    if (formValues) {
      for (const fieldName of Object.keys(formValues)) {
        const oldValue = savedValues !== undefined ? (savedValues as any)[fieldName] : undefined
        const newValue = (formValues as any)[fieldName]
        // console.log('VideoEngineForm - getFormValueChanges - fieldName:', fieldName, ' oldValue:', oldValue, ' newValue:', newValue)
        if (typeof oldValue === 'object' && typeof newValue === 'object') {
          // console.log('VideoEngineForm - getFormValueChanges - object comparison...')
          if (!_.isEqual(oldValue, newValue)) {
            // TESTING: look for differences between the arrays (& ideally get the indexes for all changes)
            // UPDATE: skipping this for now & just noting if anything in the array has changed
            // const diff1 = _.differenceWith(newValue, oldValue, _.isEqual)
            // const diff2 = _.differenceWith(oldValue, newValue, _.isEqual)
            // const intersection1 = _.intersectionBy(oldValue, newValue, _.isEqual)
            // console.log('VideoEngineForm - getFormValueChanges - diff1:', diff1, ' diff2:', diff2, ' intersection1:', intersection1)
            _changes.push(fieldName)
          }
        } else {
          if (oldValue !== newValue) {
            _changes.push(fieldName)
          }
        }
      }
    }
    // console.log('VideoEngineForm - getFormValueChanges - _changes:', _changes)
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  const resetSaveResults = () => {
    setHasSaved(false)
    setError(undefined)
  }

  // -------

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = getFormValueChanges()
    setChanges(_changes)
  }, [formValues])

  // -------

  const addVideoEngine = async (fieldValues: ArkFormFieldValues) => {
    if (isSubmitting) return
    resetSaveResults()
    setIsSubmitting(true)
    try {
      console.log('VideoEngineForm - addVideoEngine - fieldValues: ', fieldValues)
      const requiredFields = ['name', 'domain', 'ip', 'isActive']
      const { name, domain, ip, isActive } = fieldValues // grab the required fields
      const saveData: IVideoEngineAddData = {
        name,
        domain,
        ip,
        isActive
      }
      const videoEngineJSONKeyMap = VideoEngine.propertyToJSONKeyMap()
      for (const fieldName of Object.keys(videoEngineJSONKeyMap)) {
        const _fieldName = fieldName.includes('.') ? fieldName.split('.')[1] : fieldName // remove any parent object prefix
        if (_fieldName === 'id') continue // skip the id field (assigned server side)
        if (requiredFields.includes(_fieldName)) continue // skip the required fields (already added above)
        if (hasChanges(_fieldName)) { // skip any empty fields
          (saveData as any)[_fieldName] = fieldValues[_fieldName]
        }
      }
      // TESTING: add the port ranges (from the dedicated form values state vars)
      // NB: these aren't added above from the raw form fieldValues data as the port ranges are stored in a different format (& with a randomised hash in the key as well)
      // TODO: ONLY add if there are any fields set (has changes from an empty starting point), BUT add both if either are set (?) see the update handling for more info
      console.log('VideoEngineForm - addVideoEngine - changes:', changes)
      saveData.srtInputPortRanges = formValues.srtInputPortRanges
      saveData.srtOutputPortRanges = formValues.srtOutputPortRanges
      console.log('VideoEngineForm - addVideoEngine - saveData: ', saveData)

      const savedVideoEngine = await siteAdminContext.actions.addVideoEngine(saveData)
      console.log('VideoEngineForm - addVideoEngine - savedVideoEngine: ', savedVideoEngine)
      if (savedVideoEngine) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
          setSavedValues(formValues)
        }
        if (onSave) onSave()
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          throw new Error('A problem occurred adding the ' + OBJECT_VIDEO_ENGINE_NAME + ', please try again.')
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  const updateVideoEngine = async (fieldValues: ArkFormFieldValues) => {
    if (isSubmitting || !videoEngine) return
    resetSaveResults()
    setIsSubmitting(true)
    try {
      const saveData: IVideoEngineUpdateData = {}

      // if (hasChanges('name')) saveData.name = name
      // if (hasChanges('isActive')) saveData.isActive = isActive

      const videoEngineJSONKeyMap = VideoEngine.propertyToJSONKeyMap()
      for (const fieldName of Object.keys(videoEngineJSONKeyMap)) {
        if (fieldName === 'id') continue // skip the id field (not editable)
        if (hasChanges(fieldName)) { // fieldValues[fieldName] !== undefined) {
          (saveData as any)[fieldName] = fieldValues[fieldName]
        }
      }
      // TESTING: add the port ranges (from the dedicated form values state vars)
      // NB: these aren't added above from the raw form fieldValues data as the port ranges are stored in a different format (& with a randomised hash in the key as well)
      // UPDATE: if one of the SRT input/output port range fields has changed, both must be submitted (or the api returns a `warning` response with a 200 http status)
      // console.log('VideoEngineForm - updateVideoEngine - changes:', changes)
      if (changes.includes('srtInputPortRanges') || changes.includes('srtOutputPortRanges')) saveData.srtInputPortRanges = formValues.srtInputPortRanges
      if (changes.includes('srtInputPortRanges') || changes.includes('srtOutputPortRanges')) saveData.srtOutputPortRanges = formValues.srtOutputPortRanges
      console.log('VideoEngineForm - updateVideoEngine - saveData: ', saveData)
      // TODO: throw an error/warning if no changes have been made? (so nothing to save/udpate)
      const savedVideoEngine = await siteAdminContext.actions.updateVideoEngine(videoEngine.id, saveData)
      console.log('VideoEngineForm - updateVideoEngine - savedVideoEngine: ', savedVideoEngine)
      if (savedVideoEngine) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
          setSavedValues(formValues)
        }
        if (onSave) onSave()
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          throw new Error('A problem occurred adding the ' + OBJECT_VIDEO_ENGINE_NAME + ', please try again.')
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('VideoEngineForm - onFormSubmit - fieldValues: ', fieldValues)
    // TESTING: port range error handling
    // NB: done here instead of inline via yup/formSchema as the line error displays don't look good in the port range fields currently
    const errorMsgs: Array<string> = []
    if (error) setError(undefined) // reset previous local/component-level errors (if any)
    if (changes.includes('srtInputPortRanges')) {
      const srtInputPortRanges = formValues.srtInputPortRanges
      for (const range of srtInputPortRanges) {
        if (range.start > range.end || range.start === 0 || range.end === 0) {
          console.error('VideoEngineForm - onFormSubmit - PORT RANGE ERROR - srtInputPortRanges - invalid range:', range)
          errorMsgs.push('Invalid SRT input port range: ' + range.start + ' - ' + range.end)
        }
      }
    }
    if (changes.includes('srtOutputPortRanges')) {
      const srtOutputPortRanges = formValues.srtOutputPortRanges
      for (const range of srtOutputPortRanges) {
        if (range.start > range.end || range.start === 0 || range.end === 0) {
          console.error('VideoEngineForm - onFormSubmit - PORT RANGE ERROR - srtOutputPortRanges - invalid range:', range)
          errorMsgs.push('Invalid SRT output port range: ' + range.start + ' - ' + range.end)
        }
      }
    }
    if (errorMsgs.length > 0) {
      setError(new ArkFormMultiError(errorMsgs))
      return // halt - don't save/update the video engine entry when any port range errors are found
    }
    if (mode === VideoEngineFormMode.Add) {
      addVideoEngine(fieldValues)
    } else if (mode === VideoEngineFormMode.Edit) {
      updateVideoEngine(fieldValues)
    }
  }

  const onValueChanged = (fieldKey: string, fieldValue: any, _oldFieldValue: any) => {
    // console.log('VideoEngineForm - onValueChanged - fieldKey: ', fieldKey, ' fieldValue: ', fieldValue, ' oldFieldValue: ', oldFieldValue)
    // TESTING: special handling for port ranges, check if the fieldKey is a port range field
    if (fieldKey.startsWith('srtInputPortRanges') || fieldKey.startsWith('srtOutputPortRanges')) {
      // parse the index & start/end values from the fieldKey
      const parts = fieldKey.split('_')
      if (parts.length !== 4) {
        console.error('VideoEngineForm - onValueChanged - PORT RANGE FIELD - invalid fieldKey (parts):', fieldKey)
        return
      }
      const fieldValueInt = parseInt(fieldValue)
      if (isNaN(fieldValueInt)) {
        console.error('VideoEngineForm - onValueChanged - PORT RANGE FIELD - invalid fieldValue - fieldKey:', fieldKey, ' fieldValue:', fieldValue, ' fieldValueInt:', fieldValueInt)
        return
      }
      const rangeFieldKey = parts[0]
      const rangeIndex = parseInt(parts[2])
      const rangeFieldType = parts[3] === 'start' ? 'start' : parts[3] === 'end' ? 'end' : undefined
      if (!rangeFieldType) {
        console.error('VideoEngineForm - onValueChanged - PORT RANGE FIELD - invalid fieldKey (field type):', fieldKey)
        return
      }
      // console.log('VideoEngineForm - onValueChanged - PORT RANGE FIELD - rangeFieldKey: ', rangeFieldKey, ' rangeFieldType:', rangeFieldType, ' rangeIndex:', rangeIndex, ' fieldValue: ', fieldValue, ' oldFieldValue: ', oldFieldValue)
      if (rangeFieldKey === 'srtInputPortRanges' || rangeFieldKey === 'srtOutputPortRanges') {
        const oldRanged = (formValues as any)[rangeFieldKey]
        const newRanges = [...oldRanged]
        // console.log('VideoEngineForm - onValueChanged - PORT RANGE FIELD - oldRanged:', oldRanged)
        const range = newRanges[rangeIndex]
        if (rangeFieldType === 'start') {
          range.start = fieldValueInt
        } else if (rangeFieldType === 'end') {
          range.end = fieldValueInt
        }
        // console.log('VideoEngineForm - onValueChanged - PORT RANGE FIELD - newRanges:', newRanges)
        // console.log('VideoEngineForm - onValueChanged - PORT RANGE FIELD - savedValues(BEFORE):', savedValues)
        setFormValues({
          ...formValues,
          [rangeFieldKey]: newRanges
        })
        // console.log('VideoEngineForm - onValueChanged - PORT RANGE FIELD - savedValues(AFTER):', savedValues)
      } else {
        console.error('VideoEngineForm - onValueChanged - PORT RANGE FIELD - invalid fieldKey (rangeFieldKey):', fieldKey)
      }
      return
    }
    // TESTING: numeric field handling (if the field isn't empty)
    // NB: initially looked to compare the old or saved value type to determine if the new value should be converted to a number, but if they are undefined that won't work, so now listing all numeric fields instead (towards the top of this component)
    if (numericFields.includes(fieldKey) && fieldValue !== '') {
      let fieldValueInt = parseInt(fieldValue)
      if (isNaN(fieldValueInt)) {
        fieldValueInt = 0 // NB: non numeric values will be set to 0 for now (TODO: or should they be set as empty string like the default empty form field value currently is?)
      }
      setFormValues({
        ...formValues,
        [fieldKey]: fieldValueInt
      })
    } else {
      setFormValues({
        ...formValues,
        [fieldKey]: fieldValue
      })
    }
    // NB: if the form can remain on screen after saving, call `resetSaveResults()` here
  }

  const _onCancel = () => {
    if (onCancel) onCancel()
  }

  // triggers after dismissing the success message
  const _onClose = async () => {
    if (!isSubmitting) setFormValues(savedValues) // reset the form back to the saved values
    if (onClose) onClose()
  }

  // -------

  const _createInputTextField = (fieldKey: string, label: string, defaultValue?: string | number | boolean, required: boolean = false, className?: string, width: number | undefined = 10) => {
    let _className = className
    if (hasChanges(fieldKey)) { if (_className) _className += ' ' + styles.hasChanged; else _className = styles.hasChanged }
    return {
      type: ArkFormFieldType.Input,
      key: fieldKey,
      label: label,
      required: required,
      defaultValue: defaultValue,
      fieldProps: { width: width },
      className: _className // hasChanges(fieldKey) ? styles.hasChanged : undefined
    }
  }

  const _createInputNumberRangeField = (rangeIndex: number, fieldKey: string, fieldHash: string, label: string, defaultValue?: { start: number, end: number }, required: boolean = false, className?: string, width: number | undefined = 10) => {
    const classNameStart = styles.inputNumberRangeStart
    const classNameEnd = styles.inputNumberRangeEnd
    return {
      type: ArkFormFieldType.Group,
      key: fieldKey + '_' + fieldHash + '_' + rangeIndex,
      className: styles.inputNumberRangeGroup + (className ? ' ' + className : ''),
      fields: [
        {
          type: ArkFormFieldType.Input,
          key: fieldKey + '_' + fieldHash + '_' + rangeIndex + '_start',
          // key: fieldKey + '[' + fieldHash + '][' + rangeIndex + '][start]',
          // key: fieldKey + '.' + fieldHash + '.' + rangeIndex + '.start',
          label: (rangeIndex === 0 ? 'Start' : undefined),
          required: required,
          defaultValue: defaultValue?.start,
          fieldProps: { width: width },
          className: classNameStart
        },
        {
          type: ArkFormFieldType.Input,
          key: fieldKey + '_' + fieldHash + '_' + rangeIndex + '_end',
          // key: fieldKey + '[' + fieldHash + '][' + rangeIndex + '][end]',
          // key: fieldKey + '.' + fieldHash + '.' + rangeIndex + '.end',
          label: (rangeIndex === 0 ? 'End' : undefined),
          required: required,
          defaultValue: defaultValue?.end,
          fieldProps: { width: width },
          className: classNameEnd
        },
        {
          type: ArkFormFieldType.Button,
          key: fieldKey + '_' + fieldHash + '_remove',
          label: 'x',
          className: styles.inputNumberRangeRemoveBtn,
          fieldProps: {
            size: 'mini',
            compact: true,
            onClick: () => {
              // console.log('VideoEngineForm - _createInputNumberRangeFields - REMOVE - rangeIndex:', rangeIndex)
              const newRanges = [...(formValues as any)[fieldKey]]
              // console.log('VideoEngineForm - _createInputNumberRangeFields - REMOVE - newRanges(BEFORE):', newRanges)
              newRanges.splice(rangeIndex, 1)
              // console.log('VideoEngineForm - _createInputNumberRangeFields - REMOVE - newRanges(AFTER):', newRanges)
              setFormValues({
                ...formValues,
                [fieldKey]: newRanges
              })
              // update the field hash to force a re-render (as indexes may have changed)
              const newFieldHashes = { ...formFieldHashes }
              setFormFieldHashes({
                ...newFieldHashes,
                [fieldKey]: generateRandomFieldHash()
              })
            }
          }
          // disabled: changes.length === 0
        }
      ]
      // fieldProps: { widths: 'equal', style: { justifyContent: 'space-between', alignItems: 'center', gap: '10px' } }
    }
  }

  const _createInputNumberRangeFields = (fieldKey: string, label: string, defaultValue?: Array<{ start: number, end: number }>, required: boolean = false, className?: string, width: number | undefined = 10) => {
    const currentFieldValue = (formValues as any)[fieldKey]
    const currentFieldHash = (formFieldHashes as any)[fieldKey]
    // console.log('VideoEngineForm - _createInputNumberRangeFields - fieldKey: ', fieldKey, ' defaultValue:', defaultValue, ' currentFieldValue: ', currentFieldValue, ' currentFieldHash:', currentFieldHash)
    return {
      type: ArkFormFieldType.Fieldset,
      key: fieldKey,
      label: label,
      className: className + ' ' + styles.inputNumberRangeFields + ' ' + (hasChanges(fieldKey) ? styles.hasChanged : undefined),
      fields: [
        ...(currentFieldValue && currentFieldValue.length > 0
          ? currentFieldValue.map((range: { start: number, end: number }, index: number) => {
            const isLast = index === currentFieldValue.length - 1
            const rangeClassName = isLast ? styles.inputNumberRangeGroupLast : undefined
            return _createInputNumberRangeField(index, fieldKey, currentFieldHash, label, range, required, rangeClassName, width)
          })
          : []),
        {
          type: ArkFormFieldType.Button,
          key: fieldKey + '_add',
          label: 'ADD',
          className: styles.inputNumberRangeFieldsAddBtn,
          fieldProps: {
            size: 'mini',
            compact: true,
            onClick: () => {
              // console.log('VideoEngineForm - _createInputNumberRangeFields - ADD ' + label)
              const newRanges = [...currentFieldValue]
              newRanges.push({ start: 0, end: 0 })
              setFormValues({
                ...formValues,
                [fieldKey]: newRanges
              })
            }
          }
          // disabled: changes.length === 0
        }
      ],
      fieldProps: { style: { flexGrow: 1 /*, flexBasis: '67%', minWidth: '200px' */ } },
      collapsible: false,
      collapsed: false
    }
  }

  const formFields: Array<ArkFormField> = []

  const companyOptions: Array<ArkFormFieldOption> = []
  companyOptions.push({ key: 'company_none', text: <div className={styles.companyOption + ' ' + styles.videoEngineOptionNone}>{('[ALL ' + OBJECT_COMPANY_NAME_PLURAL + ']').toUpperCase()}</div>, value: -1, className: styles.selectedCompanyNone }) // add an 'all companies' (no selection) option
  for (const company of props.companies) {
    companyOptions.push({ key: 'company_' + company.id, text: company.name, value: company.id })
  }

  formFields.push(
    {
      type: ArkFormFieldType.Group,
      key: 'detailsAndSettingsGroup',
      className: styles.detailsAndSettingsGroup,
      fields: [
        {
          type: ArkFormFieldType.Fieldset,
          key: 'detailsFieldset',
          label: 'Details', // OBJECT_VIDEO_ENGINE_NAME +
          className: styles.detailsFieldset,
          fields: [
            {
              type: ArkFormFieldType.Group,
              key: 'detailsGroup',
              slimline: true,
              fields: [
                {
                  type: ArkFormFieldType.Input,
                  key: 'name',
                  label: OBJECT_VIDEO_ENGINE_NAME + ' name',
                  required: true,
                  defaultValue: videoEngine?.name ?? undefined,
                  fieldProps: { width: 10 },
                  className: hasChanges('name') ? styles.hasChanged : undefined
                },
                {
                  type: ArkFormFieldType.Input,
                  key: 'domain',
                  label: OBJECT_VIDEO_ENGINE_NAME + ' domain',
                  required: true,
                  defaultValue: videoEngine?.domain ?? undefined,
                  fieldProps: { width: 10 },
                  className: hasChanges('domain') ? styles.hasChanged : undefined
                },
                {
                  type: ArkFormFieldType.Input,
                  key: 'ip',
                  label: OBJECT_VIDEO_ENGINE_NAME + ' IP',
                  required: true,
                  defaultValue: videoEngine?.ip ?? undefined,
                  fieldProps: { width: 10 },
                  className: hasChanges('ip') ? styles.hasChanged : undefined
                }
              ]
            }
          ],
          fieldProps: { style: { flexGrow: 1, flexBasis: '67%', minWidth: '200px' } },
          collapsible: false,
          collapsed: false
        },
        {
          type: ArkFormFieldType.Fieldset,
          key: 'flagsFieldset',
          label: 'Settings', // OBJECT_VIDEO_ENGINE_NAME +
          className: styles.flagsFieldset,
          fields: [
            {
              type: ArkFormFieldType.Radio,
              key: 'isActive',
              label: (<label className={styles.flagLabel}><div className={styles.flagTitle}><span>Active</span></div></label>),
              required: false,
              toggle: true,
              defaultValue: videoEngine?.isActive ?? (mode === VideoEngineFormMode.Add ? true : undefined), // NB: default to true when adding a new one, otherwise leaving as undefined for unknown so the value won't be used unless toggled on/off manually
              wrapperProps: { className: styles.flagWrapper },
              className: hasChanges('isActive') ? styles.hasChanged : undefined
            },
            {
              type: ArkFormFieldType.Dropdown,
              key: 'companyId',
              label: OBJECT_COMPANY_NAME,
              required: false,
              defaultValue: videoEngine?.companyId ?? -1,
              options: companyOptions,
              className: styles.companiesDropdown + (hasChanges('companyId') ? ' ' + styles.hasChanged : ''),
              fieldProps: {
                scrolling: true // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
              }
            }
          ],
          fieldProps: { style: { flexGrow: 1, flexBasis: '33%', minWidth: '200px' } },
          collapsible: false,
          collapsed: false
        }
      ],
      fieldProps: { widths: 'equal', style: { justifyContent: 'space-between', gap: '10px' } }
    },
    {
      type: ArkFormFieldType.Group,
      key: 'controlsAndPortsGroup',
      className: styles.controlsAndPortsGroup,
      fields: [
        {
          type: ArkFormFieldType.Fieldset,
          key: 'controlFieldset',
          label: 'Control', // OBJECT_VIDEO_ENGINE_NAME +
          className: styles.controlFieldset,
          fields: [
            _createInputTextField('controlServerKey', 'Control Server Key', videoEngine?.controlServerKey ?? undefined, false, styles.controlField),
            _createInputTextField('controlClientId', 'Control Client Id', videoEngine?.controlClientId ?? undefined, false, styles.controlField),
            _createInputTextField('controlAPIKey', 'Control API Key', videoEngine?.controlAPIKey ?? undefined, false, styles.controlField),
            _createInputTextField('controlGroupId', 'Control Group Id', videoEngine?.controlGroupId ?? undefined, false, styles.controlField)
          ],
          fieldProps: { style: { flexGrow: 1, flexBasis: '50%', minWidth: '300px' } },
          collapsible: false,
          collapsed: false
        },
        {
          type: ArkFormFieldType.Fieldset,
          key: 'portsFieldset',
          label: 'Ports', // OBJECT_VIDEO_ENGINE_NAME +
          className: styles.portsFieldset,
          fields: [
            _createInputNumberRangeFields('srtInputPortRanges', 'SRT In', videoEngine?.srtInputPortRanges ?? undefined, false, styles.portField),
            _createInputNumberRangeFields('srtOutputPortRanges', 'SRT Out', videoEngine?.srtOutputPortRanges ?? undefined, false, styles.portField),
            _createInputTextField('sldpPort', 'SLDP Out', videoEngine?.sldpPort ?? undefined, false, styles.portField),
            _createInputTextField('sldpPortWS', 'SLDP WS Out', videoEngine?.sldpPortWS ?? undefined, false, styles.portField),
            _createInputTextField('sldpPortWSS', 'SLDP WSS Out', videoEngine?.sldpPortWSS ?? undefined, false, styles.portField),
            _createInputTextField('rtmpPort', 'RTMP In/Out', videoEngine?.rtmpPort ?? undefined, false, styles.portField),
            _createInputTextField('rtmpsPort', 'RTMPS In/Out', videoEngine?.rtmpsPort ?? undefined, false, styles.portField),
            _createInputTextField('rtspPort', 'RTSP In/Out', videoEngine?.rtspPort ?? undefined, false, styles.portField),
            _createInputTextField('icecastInputPort', 'Icecast In', videoEngine?.icecastInputPort ?? undefined, false, styles.portField),
            _createInputTextField('icecastPlaybackPort', 'Icecast Out', videoEngine?.icecastPlaybackPort ?? undefined, false, styles.portField)
          ],
          fieldProps: { style: { flexGrow: 1, flexBasis: '50%', minWidth: '300px' } },
          collapsible: false,
          collapsed: false
        }
      ],
      fieldProps: { widths: 'equal', style: { justifyContent: 'space-between', gap: '10px' } }
    },
    {
      type: ArkFormFieldType.Group,
      key: 'transcodersAndWatermarkGroup',
      className: styles.transcodersAndWatermarkGroup,
      fields: [
        {
          type: ArkFormFieldType.Fieldset,
          key: 'transcodersFieldset',
          label: 'Transcoders', // OBJECT_VIDEO_ENGINE_NAME +
          className: styles.transcodersFieldset,
          fields: [
            _createInputTextField('templateABRId', 'Template ABR Id', videoEngine?.templateABRId ?? undefined, false, styles.templateField),
            _createInputTextField('templateTranscoderBaseId', 'Template Base Id', videoEngine?.templateTranscoderBaseId ?? undefined, false, styles.templateField),
            _createInputTextField('templateTranscoderFHDId', 'Template FHD Id', videoEngine?.templateTranscoderFHDId ?? undefined, false, styles.templateField),
            _createInputTextField('templateTranscoderHDId', 'Template HD Id', videoEngine?.templateTranscoderHDId ?? undefined, false, styles.templateField)
          ],
          fieldProps: { style: { flexGrow: 1, flexBasis: '50%', minWidth: '300px' } },
          collapsible: false,
          collapsed: false
        },
        {
          type: ArkFormFieldType.Fieldset,
          key: 'watermarkFieldset',
          label: 'Watermark', // OBJECT_VIDEO_ENGINE_NAME +
          className: styles.watermarkFieldset,
          fields: [
            _createInputTextField('watermarkEnvAccessKeyId', 'Env Access Key Id', videoEngine?.watermarkEnvAccessKeyId ?? undefined, false, styles.watermarkField),
            _createInputTextField('watermarkEnvBucket', 'Env Bucket', videoEngine?.watermarkEnvBucket ?? undefined, false, styles.watermarkField),
            _createInputTextField('watermarkEnvPath', 'Env Path', videoEngine?.watermarkEnvPath ?? undefined, false, styles.watermarkField),
            _createInputTextField('watermarkEnvSecretAccessKey', 'Env Secret', videoEngine?.watermarkEnvSecretAccessKey ?? undefined, false, styles.watermarkField),
            _createInputTextField('watermarkLocalPath', 'Local Path', videoEngine?.watermarkLocalPath ?? undefined, false, styles.watermarkField)
          ],
          fieldProps: { style: { flexGrow: 1, flexBasis: '50%', minWidth: '300px' } },
          collapsible: false,
          collapsed: false
        }
      ],
      fieldProps: { widths: 'equal', style: { justifyContent: 'space-between', gap: '10px' } }
    }
  )

  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      {
        type: ArkFormFieldType.CancelButton,
        key: 'cancel',
        label: 'CANCEL',
        fieldProps: { onClick: _onCancel, floated: 'left' }
      },
      {
        type: ArkFormFieldType.OKButton,
        key: 'submit',
        label: (mode === VideoEngineFormMode.Edit ? 'SAVE' : 'CREATE'),
        fieldProps: { loading: isSubmitting, floated: 'right' },
        disabled: changes.length === 0,
        disabledTooltip: 'No Changes to Save'
      }
    ],
    fieldProps: { widths: 'equal' }
  })

  return (
    <>
      <ArkHeader as="h2" inverted>
        {mode === VideoEngineFormMode.Edit ? 'Edit' : 'Add'} {OBJECT_VIDEO_ENGINE_NAME}
      </ArkHeader>

      {hasSaved && (<>
        <ArkMessage positive>
          <ArkMessage.Header>{OBJECT_VIDEO_ENGINE_NAME} {mode === VideoEngineFormMode.Edit ? 'Updated' : 'Created'}</ArkMessage.Header>
          <ArkMessage.Item>The {OBJECT_VIDEO_ENGINE_NAME} has been {mode === VideoEngineFormMode.Edit ? 'updated' : 'created'} successfully</ArkMessage.Item>
        </ArkMessage>
        <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={_onClose} style={{ marginTop: 15 }}>
          OK
        </ArkButton>
      </>)}

      {!hasSaved && (
        <ArkForm
          className={styles.videoEngineForm}
          formKey="videoEngine"
          inverted
          formError={error}
          formFields={formFields}
          formSchema={formSchema}
          onFormSubmit={onFormSubmit}
          onValueChanged={onValueChanged}
          showLabels={true}
          insideModal={insideModal}
        />)}
    </>
  )
}

export default VideoEngineForm
