import React, { ReactNode } from 'react'

import styles from './ArkFieldset.module.css'

export interface ArkFieldsetProps {
  children: ReactNode
  className?: string
  legend?: string
}

const ArkFieldset = (props: ArkFieldsetProps) => (
  <fieldset className={`${styles.fieldset} ${props.className}`}>
    {props.legend && <legend className={styles.legend}>{props.legend}</legend>}
    {props.children}
  </fieldset>
)

export default ArkFieldset
