/**
 * CompanyProjectListItem
 */

import React from 'react'

import ArkIcon from 'src/core/components/ArkIcon'
import ArkListItem, { ArkListItemDetail } from 'src/core/components/ArkListItem'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { Project, ProjectVideoEngine } from 'src/core/models'

import styles from './CompanyProjectListItem.module.css'

interface CompanyProjectListItemProps {
  active: boolean
  filter?: string
  onClick: () => void
  onEditClick?: () => void
  project: Project
  projectVideoEngine?: ProjectVideoEngine
  defaultVideoEngine?: ProjectVideoEngine
}

const CompanyProjectListItem = (props: CompanyProjectListItemProps) => {
  const {
    active,
    filter,
    onClick,
    onEditClick,
    project,
    projectVideoEngine,
    defaultVideoEngine
  } = props

  const projectVideoEngineIsDefault = projectVideoEngine?.id === defaultVideoEngine?.id
  const projectVideoEngineIsActive = projectVideoEngine?.isActive
  const projectVideoEngineTitle = projectVideoEngine ? projectVideoEngine.name : 'N/A'
  const projectVideoEngineDesc = projectVideoEngine ? ((projectVideoEngineIsDefault ? ' (Default)' : '') + (projectVideoEngineIsActive === true ? ' (ENABLED)' : '(DISABLED)')) : ''

  const rightComponentDesktop = (
    <div className={styles.details}>
      <ArkListItemDetail title='Video Engine' className={styles.videoEngine}>
        {projectVideoEngineTitle}{projectVideoEngineDesc.length > 0 ? (<><br />{projectVideoEngineDesc}</>) : null}
      </ArkListItemDetail>
      <ArkSpacer />
      <ArkListItemDetail title='Max Users'>
        {project.projectMaxUsers}
      </ArkListItemDetail>
      <ArkSpacer />
      <ArkListItemDetail title='2FA'>
        {project.force2fa ? 'REQUIRED' : 'OPTIONAL'}
      </ArkListItemDetail>
    </div>
  )

  return (
    <ArkListItem
      active={active}
      description={project.desc}
      filter={filter}
      leftComponent={<ArkIcon name='project' size={28} />}
      onClick={onClick}
      onEditClick={onEditClick}
      rightComponentDesktop={rightComponentDesktop}
      title={project.name}
    />
  )
}

export default CompanyProjectListItem
