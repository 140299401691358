import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import _ from 'lodash'

import { NOTICES_MOCK_API_ENABLED } from 'src/constants/config'
import { OBJECT_CHANNEL_NAME } from 'src/constants/strings'

import { Card, DEFAULT_NOTICE, Notice, Project, UserCompany } from '../models'
import ServerAPIClient from '../services/ServerAPIClient'
import { MockAPIContextValue, useMockAPI } from './MockAPIProvider'
import { ProjectCardContextValue, useProjectCard } from './ProjectCardProvider'
import { IServerContext, useServer } from './ServerProvider'
import { IUserContext, useUser } from './UserProvider'

/**
 * interactive functions include dialog boxes (e.g. 'Are you sure you want to delete "Notice A"?')
 */

export type ProjectNoticeContextValue = {
  clearNotices: () => void,
  createNotice: (props: Partial<Notice>) => Promise<Notice | undefined>
  createNoticeInteractive: (props: Partial<Notice>) => Promise<Notice | undefined>
  deleteNotice: (noticeId: number) => Promise<boolean>
  deleteNoticeInteractive: (noticeId: number) => Promise<boolean>
  duplicateNotice: (noticeId: number) => Promise<Notice | undefined>
  duplicateNoticeInteractive: (noticeId: number) => Promise<Notice | undefined>
  fetchNotices: () => Promise<Notice[] | undefined>
  fetchNoticesInteractive: () => Promise<Notice[] | undefined>
  getNotices: () => Notice[]
  getFetching: () => boolean
  updateNotice: (noticeId: number, props: Partial<Notice>) => Promise<Notice | undefined>
  updateNoticeInteractive: (noticeId: number, props: Partial<Notice>) => Promise<Notice | undefined>
}

const ProjectNoticeContext = createContext<ProjectNoticeContextValue>({} as ProjectNoticeContextValue)

export const useProjectNotice = () => useContext(ProjectNoticeContext)

type ProjectNoticeProviderProps = {
  children: ReactNode
}

const ProjectNoticeProvider = (props: ProjectNoticeProviderProps) => {
  const mockAPI: MockAPIContextValue = useMockAPI()
  const projectCard: ProjectCardContextValue = useProjectCard()
  const server: IServerContext = useServer()
  const user: IUserContext = useUser()

  const cards: Card[] = NOTICES_MOCK_API_ENABLED ? projectCard.getCards() : []
  const company: UserCompany = user.store.selectedCompany!
  const project: Project = user.store.selectedProject!
  const serverAPIClient: ServerAPIClient = server.store.apiClient!

  const [fetching, setFetching] = useState<boolean>(true)
  const [notices, setNotices] = useState<Notice[]>([])

  useEffect(() => {
    // console.log('ProjectNoticeProvider - load')
    return () => {
      // console.log('ProjectNoticeProvider - unload')
    }
  }, [])

  const clearNotices = (): void => {
    // console.log('ProjectNoticeProvider - clearNotices')
    setNotices([])
  }

  const createNotice = async (props: Partial<Notice>): Promise<Notice | undefined> => {
    try {
      // console.log('ProjectNoticeProvider - createNotice - props:', props)
      let notice: Notice
      if (NOTICES_MOCK_API_ENABLED) {
        notice = {
          ...DEFAULT_NOTICE,
          ...props,
          id: Date.now(),
          project_id: project.id,
          // eslint-disable-next-line react/prop-types
          ...(props.card_id && { card: _.find(cards, { id: props.card_id }) })
        }
        const oldMockNotices: Notice[] = await mockAPI.getNotices()
        const newMockNotices: Notice[] = [...oldMockNotices, notice]
        await mockAPI.setNotices(newMockNotices)
      } else {
        const keys: (keyof Notice)[] = [] // unused
        const newProps: Partial<Notice> = { ...props, ..._.pick(DEFAULT_NOTICE, keys) }
        // FIXME add response type
        const response = await serverAPIClient.apiPost('/projects/notice/', newProps, {
          'company-id': company.id,
          'project-id': project.id
        })
        // console.log('ProjectNoticeProvider - createNotice - response:', response)
        // FIXME check response status
        // FIXME add type guard
        notice = response.data.message
      }
      const newNotices: Notice[] = [...notices, notice]
      setNotices(newNotices)
      await user.actions.refreshChannels() // refresh viewer
      return notice
    } catch (error) {
      console.error('ProjectNoticeProvider - createNotice - error:', error.message)
    }
  }

  const createNoticeInteractive = async (props: Partial<Notice>): Promise<Notice | undefined> => {
    try {
      // console.log('ProjectNoticeProvider - createNoticeInteractive - props:', props)
      const notice: Notice | undefined = await createNotice(props)
      if (!notice) throw Error('create notice failed')
      // window.alert(`"${notice.name}" has been created successfully.`) // FIXME add toast
      return notice
    } catch (error) {
      console.error('ProjectNoticeProvider - createNoticeInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  const deleteNotice = async (noticeId: number): Promise<boolean> => {
    try {
      // console.log('ProjectNoticeProvider - deleteNotice - noticeId:', noticeId)
      const notice: Notice = _.find(notices, { id: noticeId })!
      if (NOTICES_MOCK_API_ENABLED) {
        const oldMockNotices: Notice[] = await mockAPI.getNotices()
        const newMockNotices: Notice[] = _.without(oldMockNotices, notice)
        await mockAPI.setNotices(newMockNotices)
      } else {
        // FIXME add response type
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await serverAPIClient.apiDelete(`/projects/notice/${noticeId}`, {}, {
          'company-id': company.id,
          'project-id': project.id
        })
        // console.log('ProjectNoticeProvider - deleteNotice - response:', response)
        // FIXME check response status
      }
      const newNotices: Notice[] = _.without(notices, notice)
      setNotices(newNotices)
      await user.actions.refreshChannels() // refresh viewer
      return true
    } catch (error) {
      console.error('ProjectNoticeProvider - deleteNotice - error:', error.message)
      return false
    }
  }

  const deleteNoticeInteractive = async (noticeId: number): Promise<boolean> => {
    try {
      // console.log('ProjectNoticeProvider - deleteNoticeInteractive - noticeId:', noticeId)
      const notice: Notice = _.find(notices, { id: noticeId })!
      const message: string = _.some(notice.channels)
        ? `WARNING: "${notice.name}" is used by ${_.size(notice.channels)} ${OBJECT_CHANNEL_NAME}(s). Are you sure you want to delete it?`
        : `Are you sure you want to delete "${notice.name}"?`
      if (!window.confirm(message)) return false // FIXME add custom dialog box
      const result: boolean = await deleteNotice(noticeId)
      if (!result) throw Error('delete notice failed')
      // window.alert(`"${notice.name}" has been deleted successfully.`) // FIXME add toast
      return true
    } catch (error) {
      console.error('ProjectNoticeProvider - deleteNoticeInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
      return false
    }
  }

  const duplicateNotice = async (noticeId: number): Promise<Notice | undefined> => {
    try {
      // console.log('ProjectNoticeProvider - duplicateNotice - noticeId:', noticeId)
      const oldNotice: Notice | undefined = _.find(notices, { id: noticeId })!
      const keys: (keyof Notice)[] = [
        'all_channels',
        'channels',
        'conditions',
        'description',
        'dismissible',
        'dismissible_admin',
        'enabled',
        'priority'
      ]
      const props: Partial<Notice> = {
        ..._.pick(oldNotice, keys),
        ...(oldNotice.card && { card_id: oldNotice.card.id }),
        name: `${oldNotice.name} copy`
      }
      const newNotice: Notice | undefined = await createNotice(props)
      if (!newNotice) throw Error('create notice failed')
      return newNotice
    } catch (error) {
      console.error('ProjectNoticeProvider - duplicateNotice - error:', error.message)
    }
  }

  const duplicateNoticeInteractive = async (noticeId: number): Promise<Notice | undefined> => {
    try {
      // console.log('ProjectNoticeProvider - duplicateNoticeInteractive - noticeId:', noticeId)
      const oldNotice: Notice | undefined = _.find(notices, { id: noticeId })!
      if (!window.confirm(`Are you sure you want to duplicate "${oldNotice.name}"?`)) return // FIXME add custom dialog box
      const newNotice: Notice | undefined = await duplicateNotice(noticeId)
      if (!newNotice) throw Error('duplicate notice failed')
      // window.alert(`"${newNotice.name}" has been created successfully.`) // FIXME add toast
      return newNotice
    } catch (error) {
      console.error('ProjectNoticeProvider - duplicateNoticeInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  const fetchNotices = async (): Promise<Notice[] | undefined> => {
    try {
      // console.log('ProjectNoticeProvider - fetchNotices')
      setNotices([])
      setFetching(true)
      let newNotices: Notice[]
      if (NOTICES_MOCK_API_ENABLED) {
        const mockNotices: Notice[] = await mockAPI.getNotices()
        newNotices = _.filter(mockNotices, notice => notice.project_id === project.id)
      } else {
        // FIXME add response type
        const response = await serverAPIClient.apiGet('/projects/notice', {
          'company-id': company.id,
          'project-id': project.id
        })
        // console.log('ProjectNoticeProvider - fetchNotices - response:', response)
        // FIXME check response status
        // FIXME add type guard
        newNotices = response.data.message
      }
      setNotices(newNotices)
      setFetching(false)
      return newNotices
    } catch (error) {
      console.error('ProjectNoticeProvider - fetchNotices - error:', error.message)
    }
  }

  const fetchNoticesInteractive = async (): Promise<Notice[] | undefined> => {
    try {
      // console.log('ProjectNoticeProvider - fetchNoticesInteractive')
      const newNotices: Notice[] | undefined = await fetchNotices()
      if (!newNotices) throw Error('fetch notices failed')
      return newNotices
    } catch (error) {
      console.error('ProjectNoticeProvider - fetchNoticesInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  const getNotices = (): Notice[] => {
    // console.log('ProjectNoticeProvider - getNotices')
    return _.orderBy(notices, ['enabled', 'priority', 'name'], ['desc'])
  }

  const getFetching = (): boolean => {
    // console.log('ProjectNoticeProvider - getFetching')
    return fetching
  }

  const updateNotice = async (noticeId: number, props: Partial<Notice>): Promise<Notice | undefined> => {
    try {
      // console.log('ProjectNoticeProvider - updateNotice - noticeId:', noticeId, 'props:', props)
      const oldNotice: Notice = _.find(notices, { id: noticeId })!
      let newNotice: Notice
      if (NOTICES_MOCK_API_ENABLED) {
        newNotice = {
          ...oldNotice,
          ...props,
          // eslint-disable-next-line react/prop-types
          ...(props.card_id && { card: _.find(cards, { id: props.card_id }) })
        }
        const oldMockNotices: Notice[] = await mockAPI.getNotices()
        const newMockNotices: Notice[] = _.map(
          oldMockNotices,
          notice => notice.id === oldNotice.id ? newNotice : notice
        )
        await mockAPI.setNotices(newMockNotices)
      } else {
        // FIXME add response type
        const response = await serverAPIClient.apiPut(`/projects/notice/${noticeId}`, props, {
          'company-id': company.id,
          'project-id': project.id
        })
        // console.log('ProjectNoticeProvider - updateNotice - response:', response)
        // FIXME check response status
        // FIXME add type guard
        newNotice = response.data.message
      }
      const newNotices: Notice[] = _.map(notices, notice => notice.id === oldNotice.id ? newNotice : notice)
      setNotices(newNotices)
      await user.actions.refreshChannels() // refresh viewer
      return newNotice
    } catch (error) {
      console.error('ProjectNoticeProvider - updateNotice - error:', error.message)
    }
  }

  const updateNoticeInteractive = async (noticeId: number, props: Partial<Notice>): Promise<Notice | undefined> => {
    try {
      // console.log('ProjectNoticeProvider - updateNoticeInteractive - noticeId:', noticeId, 'props:', props)
      const oldNotice: Notice | undefined = _.find(notices, { id: noticeId })!
      if (
        _.some(oldNotice.channels) &&
        !window.confirm(`WARNING: "${oldNotice.name}" is used by ${_.size(oldNotice.channels)} ${OBJECT_CHANNEL_NAME.toLocaleLowerCase()}(s). Are you sure you want to edit it?`) // FIXME add custom dialog box
      ) return
      const newNotice: Notice | undefined = await updateNotice(noticeId, props)
      if (!newNotice) throw Error('update notice failed')
      // window.alert(`"${newNotice.name}" has been updated successfully.`) // FIXME add toast
      return newNotice
    } catch (error) {
      console.error('ProjectNoticeProvider - updateNoticeInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  return (
    <ProjectNoticeContext.Provider value={{
      clearNotices,
      createNotice,
      createNoticeInteractive,
      deleteNotice,
      deleteNoticeInteractive,
      duplicateNotice,
      duplicateNoticeInteractive,
      fetchNotices,
      fetchNoticesInteractive,
      getNotices,
      getFetching,
      updateNotice,
      updateNoticeInteractive
    }}>
      {props.children}
    </ProjectNoticeContext.Provider>
  )
}

export default ProjectNoticeProvider
