import axios, { AxiosError } from 'axios' //, { AxiosRequestConfig, AxiosPromise }
import { APIServerType } from 'src/constants/server_types'
import { STREAMHUB_DATA_SOURCE_OVERRIDE } from '../config/config'

// refs:
//  https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/

class StreamhubAPIClient {
  public apiBaseUrl: string
  public authToken: string | null = null
  public authDeviceUUID: string | null = null
  public apiServerType: APIServerType | null = null

  constructor (apiBaseUrl: string, authToken?: string, authDeviceUUID?: string, apiServerType?: APIServerType) {
    console.log('StreamhubAPIClient - init - authDeviceUUID: ', authDeviceUUID, ' apiServerType: ', apiServerType)
    this.apiBaseUrl = apiBaseUrl
    this.authToken = authToken ?? null
    this.authDeviceUUID = authDeviceUUID ?? null
    this.apiServerType = apiServerType ?? null
  }

  async apiGet (apiPath: string, headers?: any) {
    console.log('StreamhubAPIClient - apiGet - apiPath: ', apiPath, ' headers: ', headers, ' authToken: ', this.authToken)
    try {
      const config = this._apiConfig(headers)
      const response = await axios.get<any>(this.apiBaseUrl + apiPath, config)
      console.log('StreamhubAPIClient - apiGet - apiPath: ', apiPath, ' response: ', response)
      return response
    } catch (error) {
      console.error('StreamhubAPIClient - apiGet - error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      // throw error
      throw this.apiParseErrorResponse(error) // TESTING:
    }
  }

  async apiPost (apiPath: string, data: Object, headers?: any) {
    console.log('StreamhubAPIClient - apiPost - apiPath: ', apiPath, ' data: ', data, ' headers: ', headers, ' authToken: ', this.authToken)
    try {
      const config = this._apiConfig(headers)
      const response = await axios.post<any>(this.apiBaseUrl + apiPath, data, config)
      console.log('StreamhubAPIClient - apiPost - apiPath: ', apiPath, ' response: ', response)
      return response
    } catch (error) {
      console.error('StreamhubAPIClient - apiPost - error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      // throw error
      throw this.apiParseErrorResponse(error) // TESTING:
    }
  }

  async apiPut (apiPath: string, data: Object, headers?: any) {
    console.log('StreamhubAPIClient - apiPut - apiPath: ', apiPath, ' data: ', data, ' authToken: ', this.authToken)
    try {
      const config = this._apiConfig(headers)
      const response = await axios.put<any>(this.apiBaseUrl + apiPath, data, config)
      console.log('StreamhubAPIClient - apiPut - apiPath: ', apiPath, ' response: ', response)
      return response
    } catch (error) {
      console.error('StreamhubAPIClient - apiPut - error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      // throw error
      throw this.apiParseErrorResponse(error) // TESTING:
    }
  }

  async apiPatch (apiPath: string, data: Object, headers?: any) {
    console.log('StreamhubAPIClient - apiPatch - apiPath: ', apiPath, ' data: ', data, ' authToken: ', this.authToken)
    try {
      const config = this._apiConfig(headers)
      const response = await axios.patch<any>(this.apiBaseUrl + apiPath, data, config)
      console.log('StreamhubAPIClient - apiPatch - apiPath: ', apiPath, ' response: ', response)
      return response
    } catch (error) {
      console.error('StreamhubAPIClient - apiPatch - error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      // throw error
      throw this.apiParseErrorResponse(error) // TESTING:
    }
  }

  async apiDelete (apiPath: string, data?: Object, headers?: any) {
    console.log('StreamhubAPIClient - apiDelete - apiPath: ', apiPath, ' authToken: ', this.authToken)
    try {
      const config = this._apiConfig(headers, data)
      const response = await axios.delete<any>(this.apiBaseUrl + apiPath, config)
      console.log('StreamhubAPIClient - apiDelete - apiPath: ', apiPath, ' response: ', response)
      return response
    } catch (error) {
      console.error('StreamhubAPIClient - apiDelete - error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      // throw error
      throw this.apiParseErrorResponse(error) // TESTING:
    }
  }

  apiParseErrorResponse (error: Error) : Error {
    // console.log('StreamhubAPIClient - apiParseErrorResponse - error: ', error)
    // if (this._isAxiosError(error)) {
    // }
    return error
  }

  _isAxiosError (error: any): error is AxiosError {
    // ref: https://www.reddit.com/r/typescript/comments/f91zlt/how_do_i_check_that_a_caught_error_matches_a/fipdbxd?utm_source=share&utm_medium=web2x&context=3
    return (error as AxiosError).isAxiosError !== undefined
  }

  private _apiConfig (headers?: any, data?: Object) {
    return {
      headers: this._apiHeaders(headers),
      // TESTING: moved here, was mistakenly added within the 'headers' section before - TODO: not tested this change but it may fix the issue mentioned above
      ...(data ? { data: data } : {}) // add data to a delete call - ref: https://stackoverflow.com/a/56210828
    }
  }

  private _apiHeaders (headers?: any) {
    return {
      ...(this.authToken ? { Authorization: `Bearer ${this.authToken}` } : {}),
      ...(this.authDeviceUUID ? { 'device-uuid': this.authDeviceUUID } : {}),
      ...(this.apiServerType ? { server: this.apiServerType } : {}),
      ...(STREAMHUB_DATA_SOURCE_OVERRIDE ? { 'data-source': STREAMHUB_DATA_SOURCE_OVERRIDE } : {}),
      ...headers
    }
  }
}

export default StreamhubAPIClient
export { StreamhubAPIClient }
