import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import {
  STATUS_DEBUG_POLL_INTERVAL,
  STATUS_DEBUG_POLL_INTERVAL_ENABLED,
  STATUS_ENABLED,
  STATUS_MOCK_API_ENABLED
} from 'src/constants/config'

import {
  DEFAULT_PROJECT_STATUS,
  OnlineStatus,
  Project,
  ProjectStatus,
  ProjectStatusChannel,
  ProjectStatusProgram,
  UserCompany
} from '../models'
import { useMockAPI } from './MockAPIProvider'
import { useServer } from './ServerProvider'
import { useUser } from './UserProvider'
import ServerAPIClient from '../services/ServerAPIClient'
import { guardOnlineStatus } from '../utilities/status'

const MIN_POLL_INTERVAL: number = 10000 // 10 seconds

type ProjectStatusContextValue = {
  getChannelOnlineStatus: (id: number) => OnlineStatus
  getProgramOnlineStatus: (id: number) => OnlineStatus
}

const ProjectStatusContext = createContext<ProjectStatusContextValue>({} as ProjectStatusContextValue)

export const useProjectStatus = () => useContext(ProjectStatusContext)

type ProjectStatusProviderProps = {
  children: ReactNode
}

export const ProjectStatusProvider = (props: ProjectStatusProviderProps) => {
  if (!STATUS_ENABLED) return <>{props.children}</>

  const mockAPI = useMockAPI()
  const server = useServer()
  const user = useUser()

  const loaded = useRef<boolean>(false)
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  const [projectStatus, setProjectStatus] = useState<ProjectStatus>(DEFAULT_PROJECT_STATUS)

  const company: UserCompany = user.store.selectedCompany!
  const project: Project = user.store.selectedProject!
  const serverAPIClient: ServerAPIClient = server.store.apiClient!

  const fetchProjectStatus = async (): Promise<void> => {
    try {
      // console.log('ProjectStatusProvider - fetchProjectStatus')
      let newProjectStatus: ProjectStatus
      if (STATUS_MOCK_API_ENABLED) {
        const mockProjectStatus: ProjectStatus = await mockAPI.getProjectStatus()
        if (mockProjectStatus.id !== project.id) {
          console.log('ProjectStatusProvider - fetchProjectStatus - incorrect project id')
          return
        }
        newProjectStatus = mockProjectStatus
      } else {
        // FIXME add response type
        const response = await serverAPIClient.apiGet('/projects/status', {
          'company-id': company.id,
          'project-id': project.id
        })
        // console.log('ProjectStatusProvider - fetchProjectStatus - response:', response)
        // FIXME check response status
        // FIXME add type guard
        newProjectStatus = response.data.result
      }
      if (!loaded.current) {
        console.log('ProjectStatusProvider - fetchProjectStatus - not loaded')
        return
      }
      setProjectStatus({ ...newProjectStatus })
      timeout.current = setTimeout(
        fetchProjectStatus,
        STATUS_DEBUG_POLL_INTERVAL_ENABLED
          ? STATUS_DEBUG_POLL_INTERVAL
          : Math.max(newProjectStatus.poll_interval, MIN_POLL_INTERVAL) // fail-safe in case the backend doesn't return a poll interval
      )
    } catch (error) {
      console.error('ProjectStatusProvider - fetchProjectStatus - error:', error.message)
    }
  }

  useEffect(() => {
    // console.log('ProjectStatusProvider - load')
    loaded.current = true
    fetchProjectStatus()
    return () => {
      // console.log('ProjectStatusProvider - unload')
      loaded.current = false
      clearTimeout(timeout.current)
    }
  }, [company.id, project.id])

  const getChannelOnlineStatus = (id: number): OnlineStatus => {
    // console.log('ProjectStatusProvider - getChannelOnlineStatus - id:', id)
    const channel: ProjectStatusChannel | undefined = _.find(projectStatus.channels, { id })
    if (!channel) return 'unknown'
    return guardOnlineStatus(channel.online)
  }

  const getProgramOnlineStatus = (id: number): OnlineStatus => {
    // console.log('ProjectStatusProvider - getProgramOnlineStatus - id:', id)
    const program: ProjectStatusProgram | undefined = _.find(projectStatus.programs, { id })
    if (!program) return 'unknown'
    return guardOnlineStatus(program.online)
  }

  // console.log('ProjectStatusProvider - render')

  return (
    <ProjectStatusContext.Provider value={{
      getChannelOnlineStatus,
      getProgramOnlineStatus
    }}>
      {props.children}
    </ProjectStatusContext.Provider>
  )
}

/**
 * legacy support (higher-order component)
 */

export interface ProjectStatusProps {
  projectStatus: ProjectStatusContextValue
}

export const withProjectStatusContext = (Component: any): (props: any) => JSX.Element => {
  const NewComponent = (props: any): JSX.Element => (
    <ProjectStatusContext.Consumer>
      {(projectStatus) => (<Component {...{ ...props, projectStatus }} />)}
    </ProjectStatusContext.Consumer>
  )
  return NewComponent
}
