/**
 * EmptyNode
 */

import React from 'react'
import { Node, NodeProps } from 'react-flow-renderer'

import ArkSpacer from 'src/core/components/ArkSpacer'

import { NodeDataEmpty } from '../types'

import styles from './EmptyNode.module.css'

export type EmptyNodeType = Node<NodeDataEmpty>

const EmptyNode = (props: NodeProps<NodeDataEmpty>) => {
  const { data } = props
  if (!data) return null
  const { numberHidden, onHiddenClick, title } = data

  return (
    <div className={styles.container}>
      <div className={styles.label}>{`No ${title.toLowerCase()}`}</div>
      {!!numberHidden && (
        <>
          <ArkSpacer small />
          <div className={styles.hidden} onClick={onHiddenClick}>{`(${numberHidden} hidden)`}</div>
        </>
      )}
    </div>
  )
}

export default EmptyNode
