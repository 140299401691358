import React from 'react'

import ArkViewerPage from 'src/viewer/components/ArkViewerPage/ArkViewerPage'
import ArkInactiveOrgView from 'src/core/components/ArkInactiveOrgView'

const ViewerInactiveOrgPage = () => (
  <ArkViewerPage disableSectionMainMenu>
    <ArkInactiveOrgView />
  </ArkViewerPage>
)

export default ViewerInactiveOrgPage
