import React, { useContext, useEffect, useState } from 'react'

import { AuthContext, AuthSSOContext } from 'src/core/providers'
import { AuthLoginServiceSSOType, IAuthLoginServiceSSOConfig } from 'src/core/models'

import { COMPANY_LOGIN_SERVICE_SSO_AUTO_LOGIN, COMPANY_LOGIN_SERVICE_SSO_DEBUG, COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC } from 'src/constants/config'

import ArkButton from 'src/core/components/ArkButton'
import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkMessage from 'src/core/components/ArkMessage'

import LoginNotUserView from '../../Login/LoginNotUserView'

interface OktaOIDCSSOLoginViewProps {
  ssoConfig: IAuthLoginServiceSSOConfig
  email?: string
  register?: boolean
  isSSOCallback?: boolean // true if this is loaded after/from a redirect from the SSO provider
  onCancel?: () => void
  onRegisterCallback?: (accessToken: string, email?: string) => void
}

const OktaOIDCSSOLoginView = (props: OktaOIDCSSOLoginViewProps) => {
  const { ssoConfig, email, register, isSSOCallback, onCancel, onRegisterCallback } = props

  const authContext = useContext(AuthContext)
  const authSSOContext = useContext(AuthSSOContext)

  // const { isInitialised, isInitialising } = authSSOContext.store
  const {
    isLoadingConfig,
    isInitialised,
    isInitialising,
    isRunning,
    isRedirecting,
    isAuthenticated,
    userEmail,
    cachedEmail,
    ssoServiceType,
    ssoServiceConfig,
    ssoAccessToken: accessToken,
    ssoError,
    oktaUser
  } = authSSOContext.store

  const { authError } = authContext.store

  const [dbgError, setDbgError] = useState<Error | undefined>()

  const oktaOpts = {}

  // load/init the SSO provider first, auto run login/register if enabled
  useEffect(() => {
    async function runAsync () {
      try {
        if (!COMPANY_LOGIN_SERVICE_SSO_AUTO_LOGIN) {
          await authSSOContext.actions.setupSSOService(AuthLoginServiceSSOType.SSOOktaOIDC, ssoConfig, email ?? cachedEmail, register)
        } else {
          console.log('OktaSSOLoginView - auto-run - ssoServiceType:', ssoServiceType, ' ssoServiceConfig:', ssoServiceConfig, ' accessToken:', accessToken)
          if (ssoServiceType === AuthLoginServiceSSOType.SSOOktaOIDC && ssoServiceConfig) {
            // auto run login/register
            await authSSOContext.actions.setupSSOServiceAndLogin(ssoServiceType, ssoServiceConfig, userEmail, isSSOCallback ?? false, isSSOCallback ?? false, register)

            // UPDATE: moved to a useEffect hook once certain authSSOContext based state vars are set (see further down) <<<<
            // // TESTING: register handling needs extra logic to handle the redirect/callback flow here (login is handled via the `setupSSOServiceAndLogin` dircetly)
            // console.log('OktaSSOLoginView - auto-run - isSSOCallback:', isSSOCallback, ' isAuthenticated:', isAuthenticated, ' register:', register)
            // if ((isSSOCallback || isAuthenticated) && register) {
            //   console.log('OktaSSOLoginView - auto-run - accessToken:', accessToken)
            //   // NB: the accessToken state var from within the `AuthSSOProvider` maynot have updated yet & so might still be undefined here (even though it's been set in the provider)
            //   // NB: so we manually call `oktaOIDCGetAccessToken` to grab it if that happens as a fallback (vs making `setupSSOServiceAndLogin` return it, as theres a lot of different response outcomes for that function, & don't really want to change this code to wait for a state var to change as that'll also be complex to follow & debug down the line..)
            //   let _accessToken = accessToken
            //   if (!_accessToken) _accessToken = await authSSOContext.actions.oktaOIDCGetAccessToken()
            //   if (!_accessToken) throw new Error('NO ACCESS TOKEN')
            //   if (authContext.store.cacheEmail !== email) {
            //     console.log('OktaSSOLoginView - auto-run - cacheEmail:', authContext.store.cacheEmail, ' email:', email, ' - update cacheEmail....')
            //     authContext.actions.cacheSSOEmail(email) // TESTING HERE <<<<<
            //   }
            //   if (onRegisterCallback) onRegisterCallback(_accessToken)
            // }
            console.log('OktaSSOLoginView - auto-run (end)')
          } else {
            // TODO: here or in the provider?
          }
        }
      } catch (error) {
        console.error('OktaSSOLoginView - auto-run - error:', error)
        // TODO: ?
      }
    }
    if (!isInitialised && !isInitialising && !ssoError) runAsync()
  }, [isInitialised, isInitialising, ssoError])

  // TESTING: auto-run register result handling (once the relevant authSSOContext state vars have been updated)
  useEffect(() => {
    console.log('OktaSSOLoginView - useEffect - register:', register, ' isInitialised:', isInitialised, ' isInitialising:', isInitialising, ' isRunning:', isRunning, ' isRedirecting:', isRedirecting, ' isAuthenticated:', isAuthenticated, ' accessToken:', accessToken)
    if (register) { // COMPANY_LOGIN_SERVICE_SSO_AUTO_LOGIN &&
      if (isAuthenticated && accessToken) {
        console.log('OktaSSOLoginView - useEffect - isAuthenticated + accessToken...')

        if (authContext.store.cacheEmail !== email) {
          console.log('OktaSSOLoginView - useEffect - isAuthenticated + accessToken - cacheEmail:', authContext.store.cacheEmail, ' email:', email, ' - update cacheEmail....')
          authContext.actions.cacheSSOEmail(email) // TESTING HERE <<<<<
        }

        // TODO: only call this once... (set a local state var to indicate if this has already been called? although calling code should unload this view once the callback has fired...)
        if (onRegisterCallback) onRegisterCallback(accessToken, email)
      }
      // TODO: what about error result handling?
    }
  }, [isAuthenticated, accessToken]) // isInitialised, isInitialising, isRunning, isRedirecting,

  if (isInitialising || isRedirecting) return (<ArkLoaderView message={'Loading'} />)
  if (isRunning) return (<ArkLoaderView message={'Logging In'} />)

  return (
    <div>
      <div>
        {/* <h1>Okta Login</h1> */}
        {(ssoError || authError || dbgError) && (
          <>
            <ArkMessage negative>
              <ArkMessage.Header>{register ? 'Registration' : 'Login'} Error</ArkMessage.Header>
              {/* <ArkMessage.Item>{error.message}</ArkMessage.Item> */}
              <p>{ssoError?.message || authError?.message || dbgError?.message}</p>
            </ArkMessage>
            <LoginNotUserView
              email={email ?? cachedEmail ?? ''}
              title={'Go Back'}
              onClick={() => {
                if (onCancel) onCancel()
              }}
            />
          </>
        )}
        {/* TODO: show a back/cancel button if we're not loading/logging-in etc??? */}
      </div>
      {COMPANY_LOGIN_SERVICE_SSO_DEBUG && (
        <>
          <br />
          <ArkButton fluid onClick={async () => {
            console.log('OktaSSOLoginView - load-and-login btn - onClick - email:', email, ' register:', register)
            try {
              // TODO: may not yet return `isAuthenticated` status? (at least not for all providers and/or pre/post submit & callback stages...)
              await authSSOContext.actions.setupSSOServiceAndLogin(AuthLoginServiceSSOType.SSOOktaOIDC, ssoConfig, email ?? cachedEmail, isSSOCallback ?? false, true, register)
              // // TESTING: register handling needs extra logic to handle the redirect/callback flow here (login is handled via the `setupSSOServiceAndLogin` dircetly)
              // console.log('OktaSSOLoginView - load-and-login btn - onClick - isSSOCallback:', isSSOCallback, ' isAuthenticated:', isAuthenticated, ' register:', register)
              // if ((isSSOCallback || isAuthenticated) && register) {
              //   console.log('OktaSSOLoginView - load-and-login btn - onClick - accessToken:', accessToken)
              //   // NB: the accessToken state var from within the `AuthSSOProvider` maynot have updated yet & so might still be undefined here (even though it's been set in the provider)
              //   // NB: so we manually call `oktaOIDCGetAccessToken` to grab it if that happens as a fallback (vs making `setupSSOServiceAndLogin` return it, as theres a lot of different response outcomes for that function, & don't really want to change this code to wait for a state var to change as that'll also be complex to follow & debug down the line..)
              //   let _accessToken = accessToken
              //   if (!_accessToken) _accessToken = await authSSOContext.actions.oktaOIDCGetAccessToken()
              //   if (!_accessToken) throw new Error('NO ACCESS TOKEN')

              //   if (authContext.store.cacheEmail !== email) {
              //     console.log('OktaSSOLoginView - load-and-login btn - onClick - cacheEmail:', authContext.store.cacheEmail, ' email:', email, ' - update cacheEmail....')
              //     authContext.actions.cacheSSOEmail(email) // TESTING HERE <<<<<
              //   }

              //   if (onRegisterCallback) onRegisterCallback(_accessToken)
              // }
              console.log('OktaSSOLoginView - load-and-login btn - onClick (end)')
            } catch (error) {
              console.error('OktaSSOLoginView - load-and-login btn - onClick error:', error)
            }
          }}>OKTA (OPENID) - LOAD AND {register ? 'REGISTER' : 'LOGIN'} {!isSSOCallback && !isAuthenticated ? <>(STAGE 1/2)</> : <>(STAGE 2/2)</>}</ArkButton>
          <br />
          <div>
            <ArkButton fluid onClick={async () => {
              console.log('OktaSSOLoginView - isAuthenticated btn - onClick - email:', email)
              const isAuthenticated = await authSSOContext.actions.isAuthenticated()
              console.log('OktaSSOLoginView - isAuthenticated btn - onClick - isAuthenticated:', isAuthenticated)
            }}>OKTA - isAuthenticated</ArkButton>
            <ArkButton fluid onClick={async () => {
              const isLoginRedirect = authSSOContext.actions.oktsIsLoginRedirect()
              console.log('OktaSSOLoginView - isLoginRedirect btn - onClick - isLoginRedirect:', isLoginRedirect)
            }}>OKTA - isLoginRedirect</ArkButton>
          </div>
          <br />
          <div>
            <ArkButton fluid onClick={async () => {
              await authSSOContext.actions.oktaCheckSession()
            }}>OKTA - CHECK SESSION</ArkButton>
            <ArkButton fluid onClick={async () => {
              console.log('OktaSSOLoginView - okta-login-btn - onClick - email:', email, ' oktaOpts:', oktaOpts)
              if (email) {
                await new Promise(resolve => setTimeout(resolve, 500))
                authContext.actions.cacheSSOEmail(email) // TESTING HERE <<<<<
                authSSOContext.actions.saveAuthSSOCache(AuthLoginServiceSSOType.SSOOktaOIDC, email) // TESTING HERE <<<<<
                await authSSOContext.actions.oktaOIDCSignInWithRedirect(email, oktaOpts)
              } else if (!email && cachedEmail !== undefined) {
                // TESTING: if attempting to trigger the sso login after a page refresh/reload, load the email from the cache as it won't be available from the previous page (passed in via the parent component normally)
                // TODO: need to trigger the AuthSSOProvider to update the email as it won't be set for the pre-fill when we go this route?
                await new Promise(resolve => setTimeout(resolve, 500))
                await authSSOContext.actions.oktaOIDCSignInWithRedirect(cachedEmail, oktaOpts)
              } else {
                console.log('OktaSSOLoginView - okta-login-btn - onClick - ERROR: NO EMAIL')
                // TODO: port (to the provider?)
                // setError(new Error('NO EMAIL'))
              }
            }}>OKTA - LOGIN TO OKTA</ArkButton>
          </div>
          <br />
          <div>
            <ArkButton fluid onClick={async () => {
              try {
                await authSSOContext.actions.oktaOIDCHandleRedirect()
              } catch (error) {
                setDbgError(error)
              }
            }}>OKTA - handleRedirect</ArkButton>
            <ArkButton fluid onClick={async () => {
              const updateState = true
              const accessToken = await authSSOContext.actions.oktaOIDCGetAccessToken(updateState)
              console.log('OktaSSOLoginView - getAccessToken btn - onClick - accessToken:', accessToken)
            }}>OKTA - getAccessToken</ArkButton>
          </div>
          <br />
          {isAuthenticated && (
            <div>
              {oktaUser && oktaUser.email && accessToken && (
                <ArkButton fluid onClick={async () => {
                  const companyServiceId = authSSOContext.store.ssoServiceConfig?.companyServiceId
                  if (companyServiceId !== undefined) {
                    authContext.actions.loginWithSSOToken(oktaUser.email!, COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC, companyServiceId, accessToken)
                  }
                }}>API - LOGIN WITH ACCESS TOKEN</ArkButton>
              )}

              <br />
              <ArkButton fluid onClick={async () => {
                await authSSOContext.actions.logoutSSOProvider()
              }}>OKTA - LOGOUT<br />(RESET AND CLEAR OKTA ACCESS)</ArkButton>
              <br />
            </div>
          )}
          <div>
            <h3>Okta Debug:</h3>
            <div>email (props): {email ?? '-'}</div>
            <div>email (cache): {cachedEmail ?? '-'}</div>
            {/* <div>email (token): {tokenEmail ?? '-'}</div> */}
            <div>email (used): {userEmail ?? '-'}</div>
            <br />
            <div>okta - isSSOCallback: {isSSOCallback ? 'YES' : 'NO'}</div>
            {/* <div>okta - hasAuthParams: {hasAuthParams ? 'YES' : 'NO'}</div> */}
            <div>okta - isLoadingConfig: {isLoadingConfig ? 'YES' : 'NO'}</div>
            <div>okta - isInitialised: {isInitialised ? 'YES' : 'NO'}</div>
            <div>okta - isInitialising: {isInitialising ? 'YES' : 'NO'}</div>
            <div>okta - isRunning: {isRunning ? 'YES' : 'NO'}</div>
            <div>okta - isRedirecting: {isRedirecting ? 'YES' : 'NO'}</div>
            <div>okta - isAuthenticated: {isInitialised ? (isAuthenticated ? 'YES' : 'NO') : '-'}</div>
            <div>okta - hasAccessToken: {isInitialised ? (accessToken ? 'YES' : 'NO') : '-'}</div>
            <br />
            {oktaUser && (<div>
              <div>okta - user.name: {oktaUser.name}</div>
              <div>okta - email: {oktaUser.email}</div>
              <div>okta - email-verified: {oktaUser.email_verified ? 'YES' : 'NO'}</div>
            </div>)}
          </div>
        </>
      )}

      {/*
        NB: NOT for normal use, only for testing (or partial debugging)
        NB: this ONLY shows if `COMPANY_LOGIN_SERVICE_SSO_AUTO_LOGIN` is disabled & SSO debug mode is also disabled
        (helps when testing certain SSO/Okta features, stopping it from auto redirecting to okta while not in full SSO debug mode)
      */}
      {!COMPANY_LOGIN_SERVICE_SSO_DEBUG && !COMPANY_LOGIN_SERVICE_SSO_AUTO_LOGIN && (
        <>
          {/* isInitialised: {isInitialised ? 'YES' : 'NO'}<br />
          isInitialising: {isInitialising ? 'YES' : 'NO'}<br />
          isRunning: {isRunning ? 'YES' : 'NO'}<br />
          isRedirecting: {isRedirecting ? 'YES' : 'NO'}<br />
          isAuthenticated: {isAuthenticated ? 'YES' : 'NO'}<br />
          ssoServiceType: {ssoServiceType}<br />
          ssoServiceConfig: {JSON.stringify(ssoServiceConfig)}<br /> */}
          {(ssoServiceType === AuthLoginServiceSSOType.SSOOktaOIDC && ssoServiceConfig) && (
            <ArkButton fluid onClick={async () => {
              // run okta login/register (redirects to the okta login page)
              await authSSOContext.actions.setupSSOServiceAndLogin(ssoServiceType, ssoServiceConfig, userEmail, isSSOCallback ?? false, isSSOCallback ?? false, register)
            }}>LOGIN WITH OKTA</ArkButton>
          )}
          {(ssoServiceType !== AuthLoginServiceSSOType.SSOOktaOIDC || ssoServiceConfig === undefined) && (
            <ArkMessage negative>
              <ArkMessage.Header>Error</ArkMessage.Header>
              <p>Failed to load Okta details.</p>
            </ArkMessage>
          )}
        </>
      )}
    </div>
  )
}

export default OktaOIDCSSOLoginView
