import React, { useContext, useEffect, useState } from 'react'

import { NavContext, UserContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import User2FAEnableView from './User2FAEnableView'
import User2FADisableView from './User2FADisableView'

import ArkButton from 'src/core/components/ArkButton'

import { Message } from 'semantic-ui-react'

import styles from './User2FAManageView.module.css'

interface IProps {}

const User2FAManageView = (_props: IProps) => {
  const userContext = useContext(UserContext)
  const { actions: navActions } = useContext(NavContext)

  const user = userContext.store.user
  if (!user) return null

  // note the current user 2fa enabled status
  // NB: the site wide user data is updated when 2fa is enabled/disabled & the specific components are unmounted
  // NB: so we now track the changes here & show the success message when it transitions to enabled or disabled (errors are within the dedicated view components still)
  const [tfaEnabled, setTfaEnabled] = useState<boolean>(user.tfaEnabled)
  const [show2faEnabledSuccess, setShow2faEnabledSuccess] = useState(false)
  const [show2faDisabledSuccess, setShow2faDisabledSuccess] = useState(false)

  const clearMessages = () => {
    if (show2faEnabledSuccess) setShow2faEnabledSuccess(false)
    if (show2faDisabledSuccess) setShow2faDisabledSuccess(false)
  }

  const render2faCurrentlyDisabled = () => {
    return (
      <User2FAEnableView
        onStartEnable={() => { clearMessages() }}
      />
    )
  }

  const render2faCurrentlyEnabled = () => {
    return (
      <User2FADisableView
        onStartDisable={() => { clearMessages() }}
      />
    )
  }

  const render2faEnabledMessage = () => {
    const redirectFrom = navActions.getRedirectPath()
    console.log('User2FAManageView - render2faEnabledMessage - redirectFrom:', redirectFrom)
    return (
      <Message positive className={styles.successMsg} onDismiss={() => { clearMessages() }}>
        <Message.Header>2FA Enabled</Message.Header>
        <div className={styles.successMsgContent}>
          <p>2FA is now enabled on your account.</p>
          <ArkButton fluid positive onClick={() => {
            console.log('User2FAManageView - render2faEnabledMessage - OK BUTTON - onClick - redirectFrom:', redirectFrom)
            navActions.goto(redirectFrom ? redirectFrom.pathname : ROUTES.ACCOUNT_SETTINGS)
          }}>
            {redirectFrom ? <>OK</> : <>OK</>}
          </ArkButton>
        </div>
      </Message>
    )
  }

  const render2faDisabledMessage = () => {
    return (
      <Message positive className={styles.successMsg} onDismiss={() => { clearMessages() }}>
        <Message.Header>2FA Disabled</Message.Header>
        <div className={styles.successMsgContent}>
          <p>2FA is now disabled on your account.</p>
          <ArkButton fluid positive onClick={() => { navActions.goto(ROUTES.ACCOUNT_SETTINGS) }}>
            OK
          </ArkButton>
        </div>
      </Message>
    )
  }
  // on init/mount note the current user 2fa enabled status
  useEffect(() => {
    console.log('User2FAManageView - mount - user.tfaEnabled: ', user.tfaEnabled, ' tfaEnabled: ', tfaEnabled)
    const redirectFrom = navActions.getRedirectPath()
    console.log('User2FAManageView - mount - redirectFrom:', redirectFrom)
  }, [])

  // catch when the users 2FA value changes
  useEffect(() => {
    console.log('User2FAManageView - useEffect - user.tfaEnabled: ', user.tfaEnabled)
    if (user.tfaEnabled !== tfaEnabled) {
      console.log('User2FAManageView - useEffect - user.tfaEnabled CHANGED FROM: ', tfaEnabled, ' TO: ', user.tfaEnabled)
      setTfaEnabled(user.tfaEnabled)
      if (user.tfaEnabled) setShow2faEnabledSuccess(true)
      if (!user.tfaEnabled) setShow2faDisabledSuccess(true)
    }
  }, [user.tfaEnabled])

  return (
    <div className={styles.manage2FA}>
      {show2faEnabledSuccess && (render2faEnabledMessage())}
      {show2faDisabledSuccess && (render2faDisabledMessage())}
      {user.tfaEnabled === false && !show2faDisabledSuccess && render2faCurrentlyDisabled() /* NB: don't show the disable button while the 'disable success' message is showing (you have to back out & return) */}
      {user.tfaEnabled === true && !show2faEnabledSuccess && render2faCurrentlyEnabled() /* NB: don't show the disable button while the 'enabled success' message is showing (you have to back out & return) */}
    </div>
  )
}
export default User2FAManageView
