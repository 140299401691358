/**
 * GroupNode
 */

import React from 'react'
import { NodeProps } from 'react-flow-renderer'

import { pluralize } from 'src/core/utilities/strings'

import { NodeDataGroup } from '../types'
import BaseNode from './BaseNode'

import styles from './GroupNode.module.css'

const GroupNode = (props: NodeProps<NodeDataGroup>) => {
  const { data } = props
  if (!data) return null
  const { mirrored, name, numberOfChannels, numberOfPrograms, numberOfUsers } = data

  const popupBody = (
    <>
      <div>{`${numberOfPrograms} ${pluralize('program', numberOfPrograms)}`}</div>
      <div>{`${numberOfChannels} ${pluralize('channel', numberOfChannels)}`}</div>
      <div>{`${numberOfUsers} ${pluralize('user', numberOfUsers)}`}</div>
    </>
  )

  return (
    <BaseNode
      iconName={mirrored ? 'group-company-filled' : 'group-filled'}
      popupBody={popupBody}
      popupName={name}
      popupPosition='right center'
      popupTitle='Group'
      {...props}
    >
      <div className={styles.container}>
        <div className={styles.name}>{name}</div>
      </div>
    </BaseNode>
  )
}

export default GroupNode
