import React from 'react'

import { Company, UserCompany } from 'src/core/models'

import { Company2FASettingsView } from './Company2FASettingsView'
import { CompanyLoginServiceSettingsView } from './CompanyLoginServiceSettingsView'

import ArkPanel from 'src/core/components/ArkPanel'

import { OBJECT_COMPANY_NAME, PAGE_SETTINGS_NAME } from 'src/constants/strings'

import styles from './CompanySettingsView.module.css'

export interface CompanyAccessSettingsViewProps {
  company: Company | UserCompany
}

const CompanyAccessSettingsView = (props: CompanyAccessSettingsViewProps) => {
  const { company } = props
  return (
    <div className={styles.accessSettings}>
      <div className={styles.content}>
        <ArkPanel bordered>
          <ArkPanel.Header title={OBJECT_COMPANY_NAME + ' ' + 'access' + ' ' + PAGE_SETTINGS_NAME} bottomBorder className={styles.header}></ArkPanel.Header>
          <CompanyLoginServiceSettingsView company={company} />
          <ArkPanel.PropertyDivider />
          <Company2FASettingsView company={company} />
        </ArkPanel>
      </div>
    </div>
  )
}

export { CompanyAccessSettingsView }
