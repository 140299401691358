import React, { useContext, useEffect, useRef } from 'react'

import { StreamhubVideoEngineContext } from '../../providers/StreamhubVideoEngineProvider'
import { IVideoEngineDataSlice, IVideoEngineLiveStream, IVideoEngineRealtimeStats, IVideoEngineServer } from '../../models/StreamhubVideoEngineModels'

import StreamhubVideoEngineRealtimeStatsView from './StreamhubVideoEngineRealtimeStatsView'
import StreamhubVideoEngineLiveStreamsView from './StreamhubVideoEngineLiveStreamsView'

import ArkButton from 'src/core/components/ArkButton'

interface IProps {
  videoEngineServer: IVideoEngineServer
  videoEngineDataSlice: IVideoEngineDataSlice
}

const StreamhubVideoEngineView = (props: IProps) => {
  const mounted = useRef(false)

  const { videoEngineServer, videoEngineDataSlice } = props

  const videoEngineContext = useContext(StreamhubVideoEngineContext)

  const [realtimeStats, setRealtimeStats] = React.useState<IVideoEngineRealtimeStats | undefined>()
  const [loadingRealtimeStats, setLoadingRealtimeStats] = React.useState<boolean>(false)
  const [errorRealtimeStats, setErrorRealtimeStats] = React.useState<Error | undefined>()

  const [liveStreams, setLiveStreams] = React.useState<Array<IVideoEngineLiveStream> | undefined>()
  const [loadingLiveStreams, setLoadingLiveStreams] = React.useState<boolean>(false)
  const [errorLiveStreams, setErrorLiveStreams] = React.useState<Error | undefined>()

  // -------

  useEffect(() => {
    console.log('StreamhubVideoEngineView - MOUNT')
    mounted.current = true
    return () => {
      console.log('StreamhubVideoEngineView - UNMOUNT')
      mounted.current = false
    }
  }, [])

  // -------

  const loadRealtimeStats = async () => {
    console.log('StreamhubVideoEngineView - loadRealtimeStats')
    if (loadingRealtimeStats) return
    setLoadingRealtimeStats(true)
    if (errorRealtimeStats) setErrorRealtimeStats(undefined)
    try {
      const realtimeStats = await videoEngineContext.actions.fetchVideoEngineRealtimeStats(videoEngineServer.id, videoEngineDataSlice.id)
      console.log('StreamhubVideoEngineView - loadRealtimeStats - realtimeStats:', realtimeStats)
      setRealtimeStats(realtimeStats)
    } catch (error) {
      setErrorRealtimeStats(error)
    }
    setLoadingRealtimeStats(false)
  }

  const loadLiveStreams = async () => {
    console.log('StreamhubVideoEngineView - loadLiveStreams')
    if (loadingLiveStreams) return
    setLoadingLiveStreams(true)
    if (errorRealtimeStats) setErrorLiveStreams(undefined)
    try {
      const liveStreams = await videoEngineContext.actions.fetchVideoEngineLiveStreams(videoEngineServer.id)
      console.log('StreamhubVideoEngineView - loadLiveStreams - liveStreams:', liveStreams)
      setLiveStreams(liveStreams)
    } catch (error) {
      setErrorLiveStreams(error)
    }
    setLoadingLiveStreams(false)
  }

  // -------

  return (
    <div>
      <h3>Video Engine: {videoEngineServer.name} ({videoEngineServer.status})</h3>
      <div>Data Slice: {videoEngineDataSlice.name}</div>
      <div>
        <br />
        <ArkButton loading={loadingRealtimeStats} onClick={() => { loadRealtimeStats() }}>REALTIME STATS</ArkButton>
        <ArkButton loading={loadingLiveStreams} onClick={() => { loadLiveStreams() }}>LIVE STREAMS</ArkButton>
        <br /><br />
      </div>
      {realtimeStats && (
        <>
          <StreamhubVideoEngineRealtimeStatsView realtimeStats={realtimeStats} error={errorRealtimeStats} />
          <br />
        </>
      )}
      {liveStreams && (
        <>
          <StreamhubVideoEngineLiveStreamsView liveStreams={liveStreams} error={errorLiveStreams} />
        </>
      )}
    </div>
  )
}

export default StreamhubVideoEngineView
