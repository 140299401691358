/**
 * StreamhubVideoEngineLiveStreamsFilterForm
 */

import React, { useContext } from 'react'
import * as yup from 'yup'

import { ResponsiveContext } from 'src/core/providers/ResponsiveProvider'

import ArkForm, { ArkFormField, ArkFormFieldOption, ArkFormFieldType, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkIcon from 'src/core/components/ArkIcon'

import styles from './StreamhubVideoEngineLiveStreamsFilterForm.module.css'

export type StreamhubVideoEngineLiveStreamsFilterFormOnFilterChange = (fieldKey: string, value?: string | number | Array<string>) => void

export enum StreamhubVideoEngineLiveStreamsFilterFormStatus {
  all = 0, online = 1, offline = 2
}

export enum StreamhubVideoEngineLiveStreamsFilterFormPublisherType {
  all = 0, isStreamhub = 1, isExternal = 2
}

export interface StreamhubVideoEngineLiveStreamsFilterValues {
  application?: string
  stream?: string
  status?: StreamhubVideoEngineLiveStreamsFilterFormStatus
  protocol?: string
  publisherType?: StreamhubVideoEngineLiveStreamsFilterFormPublisherType
}

interface StreamhubVideoEngineLiveStreamsFilterFormProps {
  autoComplete?: boolean
  className?: string
  filterValues: StreamhubVideoEngineLiveStreamsFilterValues // NB: requiring the calling code to track & pass in the current filter values so we can support clearing it (from anywhere)
  formSchema?: yup.AnySchema // ObjectSchema
  onFilterChange?: StreamhubVideoEngineLiveStreamsFilterFormOnFilterChange
  onFilterClear?: Function
}

const StreamhubVideoEngineLiveStreamsFilterForm = (props: StreamhubVideoEngineLiveStreamsFilterFormProps) => {
  const { autoComplete, className, filterValues, formSchema } = props

  const responsiveContext = useContext(ResponsiveContext)
  const isMobile = responsiveContext.store.isMobile

  const onFilterChange = (_event: React.FormEvent<HTMLFormElement>, data: ArkFormProps) => {
    // console.log('StreamhubStreamFilterForm - onFilterChange - data.name:', data.name, ' data.value:', data.value)
    if (props.onFilterChange) props.onFilterChange(data.name, (data.value && data.value.length > 0 ? data.value : undefined))
  }

  const onClearFilters = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, _data: object) => {
    // console.log('StreamhubStreamFilterForm - onClearFilters')
    if (props.onFilterClear) props.onFilterClear()
  }

  // NB: currently used by the select fields
  const onValueChanged = (fieldKey: string, fieldValue: any, _oldFieldValue: any) => {
    // console.log('StreamhubStreamFilterForm - onValueChanged - fieldKey:', fieldKey, ' fieldValue:', fieldValue)
    if (props.onFilterChange) {
      props.onFilterChange(fieldKey, (
        fieldValue !== undefined &&
        (
          (typeof fieldValue === 'string' && fieldValue.length > 0) ||
          (typeof fieldValue === 'number') ||
          (typeof fieldValue === 'object' && Array.isArray(fieldValue) && fieldValue.length > 0)
        )
          ? fieldValue
          : undefined))
    }
  }

  const inputField = (key: string, value?: string, placeholder?: string, defaultValue?: string) => {
    // console.log('StreamhubStreamFilterForm - inputField - key:', key, ' value:', value, ' placeholder:', placeholder, ' hasChanges:', (value !== defaultValue))
    return {
      type: ArkFormFieldType.Input,
      key: key,
      value: value ?? '',
      placeholder: placeholder,
      fieldProps: {
        /* fluid: true, */
        width: 13,
        onChange: onFilterChange,
        autoComplete: autoComplete === false ? 'off' : undefined
        // reset/clear button
        // NB: currently decided to skip this on each filer input field to save space overall
        // icon: (
        //   <ArkButton
        //     className={styles.clearFilter}
        //     onClick={() => {
        //       console.log('onFilterClear')
        //       if (props.onFilterChange) props.onFilterChange(key, undefined)
        //     }}
        //   >
        //     <ArkIcon name='close' size={28} color='var(--bd-lighter)' />
        //   </ArkButton>
        // )
      },
      className: (value !== defaultValue ? styles.hasChanges : undefined)
    }
  }

  const selectField = (key: string, options: Array<ArkFormFieldOption>, value?: string | number, label?: string, className?: string, defaultValue?: string | number) => {
    // console.log('StreamhubStreamFilterForm - selectField - key:', key, ' value:', value, ' options:', options)
    // NB: using the form wide `onValueChanged` callback to listen & handle for selection changes (& trigger the `onFilterChange` callback)
    return {
      type: ArkFormFieldType.Dropdown, // Select,
      key: key,
      label: label,
      value: value,
      options: options,
      fieldProps: {
        scrolling: true,
        width: 13
      },
      className: (className !== undefined ? className : '') + (value !== defaultValue ? ' ' + styles.hasChanges : '')
    }
  }

  const formFields: Array<ArkFormField> = []
  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'filters',
    fields: [
      { type: ArkFormFieldType.Field, key: 'searchIcon', content: (<ArkIcon name='search' />), className: styles.searchIcon },
      inputField('application', filterValues.application, 'application'),
      inputField('stream', filterValues.stream, 'stream'),
      inputField('protocol', filterValues.protocol, 'protocol'),
      selectField('status', [{ key: 'all', text: 'status: all', value: 0 }, { key: 'online', text: 'online', value: 1 }, { key: 'offline', text: 'offline', value: 2 }], filterValues.status, undefined, styles.statusDropdown, 0),
      selectField('publisherType', [{ key: 'all', text: 'publisher: all', value: 0 }, { key: 'isStreamhub', text: 'streamhub passthrough', value: 1 }, { key: 'isExternal', text: 'external/transcoded', value: 2 }], filterValues.publisherType ?? 0, undefined, styles.statusDropdown, 0),
      {
        type: ArkFormFieldType.Button,
        key: 'clearFilters',
        label: <ArkIcon name='close' size={24} color='var(--bd-lighter)' />, // 'X',
        fieldProps: { onClick: onClearFilters, size: 'medium' },
        className: styles.clearAllFilters
      }
    ],
    slimline: true,
    fieldProps: { inline: true },
    className: styles.filters
  })

  // const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
  //   console.log('StreamhubStreamFilterForm - onFormSubmit - fieldValues: ', fieldValues)
  // }

  const renderForm = () => (
    <ArkForm
      className={`${styles.form}${isMobile ? ' ' + styles.mobile : ''}${(className ? ' ' + className : '')}`}
      formKey="videEngineLiveStreamsFilterForm"
      inverted
      // formError={error}
      formFields={formFields}
      formSchema={formSchema}
      // onFormSubmit={onFormSubmit}
      onValueChanged={onValueChanged}
      showLabels={true}
      enterKeySubmits={false} // disable enter key submit (not needed for this form, we update as inputs change)
    />
  )

  return (
    renderForm()
  )
}

export default StreamhubVideoEngineLiveStreamsFilterForm
