import React from 'react'

import ArkPanel from 'src/core/components/ArkPanel'
import { Program } from 'src/core/models'

import { PROGRAM_MANAGER_SHOW_SPLIT_PROTOCOL_URLS_FOR_NON_SRT } from 'src/constants/config'
import { OBJECT_PROGRAM_NAME } from 'src/constants/strings'

import ArkPreviewCopyView from 'src/core/components/ArkPreviewCopyView'

import styles from './ProjectProgramProtocolURLsView.module.css'

interface ProjectProgramProtocolURLsViewProps {
  program: Program
}

const ProjectProgramProtocolURLsView = (props: ProjectProgramProtocolURLsViewProps) => {
  const { program } = props
  const displayOutputs = program.getDisplayOutputs()
  if ((!program.inputs || program.inputs.size === 0) && (!displayOutputs || displayOutputs.size === 0)) return null
  return (
    <>
      <ArkPanel>
        <ArkPanel.Properties>
          {program.inputs && program.inputs.size > 0 && (
            <ArkPanel.PropertySection title={OBJECT_PROGRAM_NAME + ' Inputs'} collapsible collapsed={false} titleClassName={styles.panelTitle}>
              {Array.from(program.inputs.values()).map(input => {
                const protocol = input.protocol
                const protocolTitle = input.protocol.toUpperCase()
                const showSplitFields = PROGRAM_MANAGER_SHOW_SPLIT_PROTOCOL_URLS_FOR_NON_SRT || protocol === 'srt'
                return (
                  <ArkPanel.PropertySection key={'protocol_input_' + protocol} title={protocolTitle + ' Input'} collapsible collapsed={false} titleClassName={styles.panelTitle}>
                    <ArkPanel.PropertyRow title='Full Input URL:' value={<ArkPreviewCopyView title={protocolTitle + ' Full Input URL'} value={input.fullURL} compactPopup />} titleSize={'large'} />
                    {showSplitFields && input.baseURL && (
                      <ArkPanel.PropertySection title={'Split ' + protocolTitle + ' Input Details'} collapsible collapsed>
                        <ArkPanel.PropertyRow title='Input URL:' value={<ArkPreviewCopyView title={protocolTitle + ' Input URL'} value={input.baseURL} compactPopup />} titleSize={'medium'} />
                        {input.extraFields?.ip && (<ArkPanel.PropertyRow title='Input IP:' value={<ArkPreviewCopyView title={protocolTitle + ' Input IP'} value={input.extraFields.ip} compactPopup />} titleSize={'medium'} />)}
                        {input.port && (<ArkPanel.PropertyRow title='Input Port:' value={<ArkPreviewCopyView title={protocolTitle + ' Input Port'} value={'' + input.port} compactPopup />} titleSize={'medium'} />)}
                        {input.user && (<ArkPanel.PropertyRow title='Input Username:' value={<ArkPreviewCopyView title={protocolTitle + ' Input Username'} value={input.user} compactPopup />} titleSize={'medium'} />)}
                        {input.pass && (<ArkPanel.PropertyRow title='Input Password:' value={<ArkPreviewCopyView title={protocolTitle + ' Input Password'} value={input.pass} compactPopup />} titleSize={'medium'} />)}
                      </ArkPanel.PropertySection>
                    )}
                  </ArkPanel.PropertySection>
                )
              })}
            </ArkPanel.PropertySection>
          )}
          {displayOutputs && displayOutputs.size > 0 && (
            <ArkPanel.PropertySection title={OBJECT_PROGRAM_NAME + ' Output'} collapsible collapsed={false} titleClassName={styles.panelTitle}>
              {Array.from(displayOutputs.values()).map(output => {
                const protocol = output.protocol
                const protocolTitle = output.protocol.toUpperCase()
                const showSplitFields = PROGRAM_MANAGER_SHOW_SPLIT_PROTOCOL_URLS_FOR_NON_SRT || protocol === 'srt'
                return (
                  <ArkPanel.PropertySection key={'protocol_output_' + protocol} title={protocolTitle + ' Output'} collapsible collapsed={false} titleClassName={styles.panelTitle}>
                    <ArkPanel.PropertyRow title='Full Output URL:' value={<ArkPreviewCopyView title={protocolTitle + ' Full Output URL'} value={output.fullURL} compactPopup />} titleSize={'large'} />
                    {showSplitFields && output.baseURL && (
                      <ArkPanel.PropertySection title={'Split ' + protocolTitle + ' Output Details'} collapsible collapsed>
                        <ArkPanel.PropertyRow title='Output URL:' value={<ArkPreviewCopyView title={protocolTitle + ' Output URL'} value={output.baseURL} compactPopup />} titleSize={'medium'} />
                        {output.extraFields?.ip && (<ArkPanel.PropertyRow title='Output IP:' value={<ArkPreviewCopyView title={protocolTitle + ' Output IP'} value={output.extraFields.ip} compactPopup />} titleSize={'medium'} />)}
                        {output.port && (<ArkPanel.PropertyRow title='Output Port:' value={<ArkPreviewCopyView title={protocolTitle + ' Output Port'} value={'' + output.port} compactPopup />} titleSize={'medium'} />)}
                        {output.user && (<ArkPanel.PropertyRow title='Output Username:' value={<ArkPreviewCopyView title={protocolTitle + 'OutputInput Username'} value={output.user} compactPopup />} titleSize={'medium'} />)}
                        {output.pass && (<ArkPanel.PropertyRow title='Output Password:' value={<ArkPreviewCopyView title={protocolTitle + ' Output Password'} value={output.pass} compactPopup />} titleSize={'medium'} />)}
                      </ArkPanel.PropertySection>
                    )}
                  </ArkPanel.PropertySection>
                )
              })}
            </ArkPanel.PropertySection>
          )}
        </ArkPanel.Properties>
      </ArkPanel>
    </>
  )
}

export default ProjectProgramProtocolURLsView
