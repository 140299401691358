import { BaseModel } from './base_model'

// base transcoder settings common across both company & project levels
// NB: you usually shouldn't deal with this directly, see CompanyTranscoderSettings & ProjectTranscoderSettings instead
export class TranscoderSettings extends BaseModel {
  allowSLDPPassthrough: boolean
  allowSRTPassthrough: boolean

  constructor (
    allowSLDPPassthrough: boolean,
    allowSRTPassthrough: boolean
  ) {
    super()
    this.allowSLDPPassthrough = allowSLDPPassthrough
    this.allowSRTPassthrough = allowSRTPassthrough
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : TranscoderSettings | null {
    if (!json) return null
    return new TranscoderSettings(
      json.flag_allow_sldp_passthrough,
      json.flag_allow_srt_passthrough
    )
  }

  // TESTING: maps our class property keys to their json equivalent
  static propertyToJSONKeyMap () {
    return {
      allowSLDPPassthrough: 'flag_allow_sldp_passthrough',
      allowSRTPassthrough: 'flag_allow_srt_passthrough'
    }
  }
}

// company specific transcoder settings
export class CompanyTranscoderSettings extends TranscoderSettings {
  allowABR1080: boolean
  allowABR720: boolean
  maxProgramsGPU: number
  maxProgramsCPU: number
  allowWatermarking: boolean
  // watermarkFilename?: string
  // watermarkWidth?: number
  // watermarkX?: number
  // watermarkY?: number

  constructor (
    allowSLDPPassthrough: boolean,
    allowSRTPassthrough: boolean,
    allowABR1080: boolean,
    allowABR720: boolean,
    maxProgramsGPU: number,
    maxProgramsCPU: number,
    allowWatermarking: boolean
    // watermarkFilename?: string,
    // watermarkWidth?: number,
    // watermarkX?: number,
    // watermarkY?: number
  ) {
    super(allowSLDPPassthrough, allowSRTPassthrough)
    this.allowABR1080 = allowABR1080
    this.allowABR720 = allowABR720
    this.maxProgramsGPU = maxProgramsGPU
    this.maxProgramsCPU = maxProgramsCPU
    this.allowWatermarking = allowWatermarking
    // this.watermarkFilename = watermarkFilename ?? undefined
    // this.watermarkWidth = watermarkWidth ?? undefined
    // this.watermarkX = watermarkX ?? undefined
    // this.watermarkY = watermarkY ?? undefined
  }

  static fromJSON (json: any) : CompanyTranscoderSettings | null {
    if (!json) return null
    return new CompanyTranscoderSettings(
      json.flag_allow_sldp_passthrough,
      json.flag_allow_srt_passthrough,
      json.flag_allow_abr1080,
      json.flag_allow_abr720,
      json.max_gpu_programs,
      json.max_cpu_programs,
      json.flag_allow_watermarking
      // json.watermarking_image_path ?? undefined,
      // json.watermarking_image_width ?? undefined,
      // json.watermarking_position_x ?? undefined,
      // json.watermarking_position_y ?? undefined
    )
  }

  // TESTING: maps our class property keys to their json equivalent
  static propertyToJSONKeyMap () {
    return {
      allowSLDPPassthrough: 'flag_allow_sldp_passthrough',
      allowSRTPassthrough: 'flag_allow_srt_passthrough',
      allowABR1080: 'flag_allow_abr1080',
      allowABR720: 'flag_allow_abr720',
      maxProgramsGPU: 'max_gpu_programs',
      maxProgramsCPU: 'max_cpu_programs',
      allowWatermarking: 'flag_allow_watermarking'
      // watermarkFilename: 'watermarking_image_path',
      // watermarkWidth: 'watermarking_image_width',
      // watermarkX: 'watermarking_position_x',
      // watermarkY: 'watermarking_position_y'
    }
  }
}

export class ProjectWatermarkFileInfo extends BaseModel {
  fileId: number
  filenameOriginal: string
  filenameSaved: string
  isOriginal: boolean
  fileType: 'original' | 'edited'
  contentType: string
  fileExtension: string
  timestamp: string // TODO: convert the unix timestamp string to a Date object

  constructor (
    fileId: number,
    filenameOriginal: string,
    filenameSaved: string,
    isOriginal: boolean,
    fileType: 'original' | 'edited',
    contentType: string,
    fileExtension: string,
    timestamp: string
  ) {
    super()
    this.fileId = fileId
    this.filenameOriginal = filenameOriginal
    this.filenameSaved = filenameSaved
    this.isOriginal = isOriginal
    this.fileType = fileType
    this.contentType = contentType
    this.fileExtension = fileExtension
    this.timestamp = timestamp
  }

  static fromJSON (json: any) : ProjectWatermarkFileInfo | null {
    if (!json) return null
    return new ProjectWatermarkFileInfo(
      json.id,
      json.file_name_original,
      json.file_name,
      json.is_original,
      json.is_original ? 'original' : 'edited',
      json.content_type,
      json.ext,
      json.timestamp
    )
  }
}

// project specific transcoder settings
export class ProjectTranscoderSettings extends TranscoderSettings {
  transcodersEnabled: boolean
  protocols: Array<string> // enabled protocols for this project
  requiredProtocols: Array<string> // protocols that can't be disabled for this project (might only be a site wide setting for now)
  audioOnlyProtocols: Array<string> // protocols that only support audio
  hotlinkValidMins?: number
  watermarkFilename?: string
  watermarkWidth?: number
  watermarkX?: number
  watermarkY?: number
  watermarkOpacity?: number
  watermarkFlipX?: boolean
  watermarkFlipY?: boolean
  watermarkExtraData?: {[key: string]: any}
  watermarkFiles?: { original?: ProjectWatermarkFileInfo, edited?: ProjectWatermarkFileInfo }

  constructor (
    allowSLDPPassthrough: boolean,
    allowSRTPassthrough: boolean,
    transcodersEnabled: boolean,
    protocols: Array<string> = [], // TODO: from api v0.1.93+ this is required - can/should remove the default fallback once all servers run this api version (or newer)
    requiredProtocols: Array<string>,
    audioOnlyProtocols: Array<string>,
    hotlinkValidMins?: number,
    watermarkFilename?: string,
    watermarkWidth?: number,
    watermarkX?: number,
    watermarkY?: number,
    watermarkOpacity?: number,
    watermarkFlipX?: boolean,
    watermarkFlipY?: boolean,
    watermarkExtraData?: {[key: string]: any},
    watermarkFiles?: { original?: ProjectWatermarkFileInfo, edited?: ProjectWatermarkFileInfo }
  ) {
    super(allowSLDPPassthrough, allowSRTPassthrough)
    this.transcodersEnabled = transcodersEnabled
    this.protocols = protocols
    this.requiredProtocols = requiredProtocols
    this.audioOnlyProtocols = audioOnlyProtocols
    this.hotlinkValidMins = hotlinkValidMins
    this.watermarkFilename = watermarkFilename ?? undefined
    this.watermarkWidth = watermarkWidth ?? undefined
    this.watermarkX = watermarkX ?? undefined
    this.watermarkY = watermarkY ?? undefined
    this.watermarkOpacity = watermarkOpacity ?? undefined
    this.watermarkFlipX = watermarkFlipX ?? undefined
    this.watermarkFlipY = watermarkFlipY ?? undefined
    this.watermarkExtraData = watermarkExtraData ?? undefined
    this.watermarkFiles = watermarkFiles ?? undefined
  }

  static fromJSON (json: any) : ProjectTranscoderSettings | null {
    if (!json) return null
    return new ProjectTranscoderSettings(
      json.flag_allow_sldp_passthrough,
      json.flag_allow_srt_passthrough,
      json.transcoders_enabled,
      this.parseEnabledAppProtocols(json.app_protocols),
      this.parseRequiredAppProtocols([]), // FIXME add support once the api adds support for these (see the function for more details)
      this.parseAudioOnlyProtocols([]), // FIXME decide if the api should supply this info in some way (see the function for more details)
      json.auth_valid_minutes ?? undefined,
      json.watermarking_image_path ?? undefined,
      json.watermarking_image_width ?? undefined,
      json.watermarking_position_x ?? undefined,
      json.watermarking_position_y ?? undefined,
      json.watermarking_image_data?.opacity ?? undefined,
      json.watermarking_image_data?.flip_x ?? undefined,
      json.watermarking_image_data?.flip_y ?? undefined,
      json.watermarking_image_data ?? undefined,
      this.parseWatermarkFiles(json.watermarks) // watermarkFiles
    )
  }

  // TESTING: maps our class property keys to their json equivalent
  static propertyToJSONKeyMap () {
    return {
      allowSLDPPassthrough: 'flag_allow_sldp_passthrough',
      allowSRTPassthrough: 'flag_allow_srt_passthrough',
      transcodersEnabled: 'transcoders_enabled',
      protocols: 'app_protocols',
      hotlinkValidMins: 'auth_valid_minutes',
      watermarkFilename: 'watermarking_image_path',
      watermarkWidth: 'watermarking_image_width',
      watermarkX: 'watermarking_position_x',
      watermarkY: 'watermarking_position_y',
      watermarkExtraData: 'watermarking_image_data'
      // NB: these values must be saved within the watermarkExtraData entry: watermarkOpacity, watermarkFlipX, watermarkFlipY
    }
  }

  static parseEnabledAppProtocols (enabledProtocolsData: Array<string>) {
    const enabledProtocols: Array<string> = []
    if (enabledProtocolsData && typeof enabledProtocolsData === 'object' && Array.isArray(enabledProtocolsData) && enabledProtocolsData.length > 0) {
      enabledProtocolsData.forEach(protocol => enabledProtocols.push(protocol))
    }
    // TEMP: hard-coding SRT as always enabled client-side for the time being
    // FIXME: see if the api can always return SRT if we can never disable it like it already does SLDP (it currently NEVER returns SRT for any protocol fields as its not a direct wmspanel option, but we need it to map to what we present to the user)
    if (!enabledProtocols.includes('SRT')) {
      enabledProtocols.push('SRT')
    }
    return enabledProtocols
  }

  static parseRequiredAppProtocols (requiredProtocolsData: Array<string>) {
    const requiredProtocols: Array<string> = []
    if (requiredProtocolsData && typeof requiredProtocolsData === 'object' && Array.isArray(requiredProtocolsData) && requiredProtocolsData.length > 0) {
      requiredProtocolsData.forEach(protocol => requiredProtocols.push(protocol))
    }
    // TEMP: hard-coding these client-side for the time being
    // FIXME: see if the api can return these (& would they be project specific & available here, or only site wide & add support to the config endpoint instead?)
    const requiredProtocolsTemp = ['SLDP', 'SRT']
    requiredProtocolsTemp.forEach(protocol => !requiredProtocols.includes(protocol) ? requiredProtocols.push(protocol) : null)
    return requiredProtocols
  }

  static parseAudioOnlyProtocols (_audioOnlyProtocolsData: Array<string>) {
    // TEMP: hard-coding these client-side for the time being
    // FIXME: see if the api can/should return these or indicate which of the avilable protocols only support audio?
    return ['ICECAST']
  }

  static parseWatermarkFiles (watermarksJson: any) {
    let watermarkFiles: { original?: ProjectWatermarkFileInfo, edited?: ProjectWatermarkFileInfo } | undefined
    if (watermarksJson !== undefined) {
      watermarkFiles = {
        original: ProjectWatermarkFileInfo.fromJSON(watermarksJson.original) ?? undefined,
        edited: ProjectWatermarkFileInfo.fromJSON(watermarksJson.edited) ?? undefined
      }
      if (watermarkFiles.original === undefined && watermarkFiles.edited === undefined) {
        watermarkFiles = undefined
      }
    }
    return watermarkFiles
  }

  // returns true if one or more audio only protocols are enabled
  // NB: currently only checks for 'icecast' but may expand to others in the future
  // NB: allows optionally supplying the list of protocols to check instead of using the current enabled protocols (for use in edit forms where the values may have changes but not been saved yet)
  hasAudioOnlyProtocolsEnabled (_protocols?: Array<string>) {
    console.log('ProjectTranscoderSettings - hasAudioOnlyProtocolsEnabled - protocols(enabled):', (_protocols || this.protocols), ' audioOnlyProtocols(available):', this.audioOnlyProtocols)
    for (const audioOnlyProtocol of this.audioOnlyProtocols) {
      if ((_protocols || this.protocols).includes(audioOnlyProtocol)) {
        return true
      }
    }
    return false
  }
}
