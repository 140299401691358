import React from 'react'

import ArkCompanyManagerPage from 'src/manager/company/components/ArkCompanyManagerPage/ArkCompanyManagerPage'
import ArkCompanySelectView from 'src/core/components/ArkCompanySelectView'

class CompanyCompaniesSelectPage extends React.Component {
  render () {
    return (
      <ArkCompanyManagerPage disableSectionMainMenu>
        <ArkCompanySelectView />
      </ArkCompanyManagerPage>
    )
  }
}

export default CompanyCompaniesSelectPage
