import React, { useContext, useEffect } from 'react'

import { AuthSSOContext } from 'src/core/providers'
import { AuthLoginServiceSSOType, IAuthLoginService } from 'src/core/models'

import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkMessage from 'src/core/components/ArkMessage'

import Auth0SSOLoginView from '../SSO/Auth0/Auth0SSOLoginView'
import OktaOIDCSSOLoginView from '../SSO/Okta/OktaOIDCSSOLoginView'
import OktaSAMLSSOLoginView from '../SSO/Okta/OktaSAMLSSOLoginView'
import LoginNotUserView from './LoginNotUserView'

import { COMPANY_LOGIN_SERVICE_SSO_DEBUG, COMPANY_LOGIN_SERVICE_SSO_OKTA_SAML_ENABLED } from 'src/constants/config'
import { OBJECT_COMPANY_NAME } from 'src/constants/strings'

interface LoginSSOViewProps {
  email?: string // NB: if loaded via an sso callback route we won't have the email passed in, we must load it from the cache
  loginService?: IAuthLoginService // NB: if loaded via an sso callback route we won't know which login service its for & will have to (re)call the check-email api endpoint to find out before continuing
  isSSOCallback?: boolean // true if this is loaded after/from a redirect from the SSO provider
  onCancel?: () => void
}

const LoginSSOView = (props: LoginSSOViewProps) => {
  const { email: pEmail, loginService: pLoginService, isSSOCallback, onCancel } = props

  const authSSOContext = useContext(AuthSSOContext)

  const {
    isLoadingConfig,
    isInitialised,
    isInitialising,
    isRunning,
    isRedirecting,
    isAuthenticated,
    userEmail,
    cachedEmail,
    ssoServiceType,
    ssoServiceConfig,
    ssoAccessToken: accessToken,
    ssoError: error
  } = authSSOContext.store

  useEffect(() => {
    async function runAsync () {
      console.log('LoginSSOView - useEffect - !isInitialised && !isInitialising - runAsync - pEmail(props):', pEmail, ' pLoginService(props):', pLoginService) //, ' sLoginService(state):', sLoginService)
      await authSSOContext.actions.loadSSOConfigAndEmail(pLoginService, pEmail)
    }
    if (!isLoadingConfig && !ssoServiceConfig && !error) runAsync() // only attempt to run on component mount/init
  }, [isLoadingConfig, ssoServiceConfig, error])

  const _renderDebug = () => {
    return (
      <>
        <h3>LoginSSOView DBG:</h3>
        <div>email (props): {pEmail ?? '-'}</div>
        <div>email (cache): {cachedEmail ?? '-'}</div>
        <div>email (used): {userEmail ?? '-'}</div>
        {/* <div>email (state): {sEmail ?? '-'}</div> */}
        <br />
        {/* <div>loginServiceType (props): {pLoginService ? pLoginService.type : '-'}</div> */}
        {/* <div>loginServiceType (state): {sLoginService ? sLoginService.type : '-'}</div> */}
        {/* <div>loginService - isLoading(check-email): {isLoading ? 'YES' : 'NO'}</div> */}
        <div>ssoServiceType (state): {ssoServiceType ?? '-'}</div>
        <div>ssoServiceConfig (state): {ssoServiceConfig ? JSON.stringify(ssoServiceConfig) : '-'}</div>
        <br />
        <div>sso - isSSOCallback: {isSSOCallback ? 'YES' : 'NO'}</div>
        {/* <div>sso - hasAuthParams: {hasAuthParams ? 'YES' : 'NO'}</div> */}
        <div>sso - isLoadingConfig: {isLoadingConfig ? 'YES' : 'NO'}</div>
        <div>sso - isInitialised: {isInitialised ? 'YES' : 'NO'}</div>
        <div>sso - isInitialising: {isInitialising ? 'YES' : 'NO'}</div>
        <div>sso - isRunning: {isRunning ? 'YES' : 'NO'}</div>
        <div>sso - isRedirecting: {isRedirecting ? 'YES' : 'NO'}</div>
        <div>sso - isAuthenticated: {isInitialised ? (isAuthenticated ? 'YES' : 'NO') : '-'}</div>
        <div>sso - hasAccessToken: {isInitialised ? (accessToken ? 'YES' : 'NO') : '-'}</div>
        <br />
        <hr />
      </>
    )
  }

  const showOktaLogin = !isLoadingConfig && ssoServiceConfig && (ssoServiceType === AuthLoginServiceSSOType.SSOOktaOIDC || ssoServiceType === AuthLoginServiceSSOType.SSOOktaSAML)
  const showAuth0Login = !isLoadingConfig && ssoServiceConfig && ssoServiceType === AuthLoginServiceSSOType.SSOAuth0
  const invalidSSODetails = !isLoadingConfig && (!ssoServiceType || !ssoServiceConfig)

  // TEMP: show a warning message & block SSO login if the Okta SAML SSO service is not currently enabled (until we have finalised SSO SAML support)
  if (!isLoadingConfig && ssoServiceType === AuthLoginServiceSSOType.SSOOktaSAML && !COMPANY_LOGIN_SERVICE_SSO_OKTA_SAML_ENABLED) {
    return (
      <>
        <ArkMessage warning>
          <ArkMessage.Header>Okta SAML SSO Not Supported</ArkMessage.Header>
          <p>Okta SAML SSO support is not currently enabled.</p>
          <p>Please contact your {OBJECT_COMPANY_NAME} admin or our support if you&apos;re seeing this message.</p>
        </ArkMessage>
        <LoginNotUserView
          email={userEmail ?? ''}
          title={'Go Back'}
          onClick={() => { if (onCancel) onCancel() }}
        />
      </>
    )
  }

  return (
    <>
      <div>
        {/* <h1>SSO Login</h1> */}

        {/**
          * NB: ONLY show an (SSO) error if we're not showing the okta/auth0 SSO view, as that shows the same SSO error already
          * NB: OR if a local error, currently only `invalidSSODetails` is detected
          * NB: refreshing the sso callback page currently causes an 'Email error' to be shown here when the okta view isn't showing, so we do still need this error display as well...
          */}
        {((error && !showOktaLogin && !showAuth0Login) || invalidSSODetails) && (
          <>
            <ArkMessage negative>
              <ArkMessage.Header>Login Error</ArkMessage.Header>
              {error && (<p>{error?.message}</p>)}
              {invalidSSODetails && (<p>Invalid SSO Details</p>)}
            </ArkMessage>
            <LoginNotUserView
              email={userEmail ?? ''}
              title={'Go Back'}
              onClick={() => { if (onCancel) onCancel() }}
            />
          </>
        )}
      </div>
      {COMPANY_LOGIN_SERVICE_SSO_DEBUG && _renderDebug()}
      {isLoadingConfig && (<ArkLoaderView message={'Loading'} />)}
      {!isLoadingConfig && ssoServiceConfig && (ssoServiceType === AuthLoginServiceSSOType.SSOOktaOIDC) && (
        <OktaOIDCSSOLoginView
          ssoConfig={ssoServiceConfig}
          email={userEmail}
          isSSOCallback={isSSOCallback} /* this.state.isSSORedirectPage << TODO: << */
          onCancel={onCancel}
        />
      )}
      {!isLoadingConfig && ssoServiceConfig && (ssoServiceType === AuthLoginServiceSSOType.SSOOktaSAML) && (
        <OktaSAMLSSOLoginView
          ssoConfig={ssoServiceConfig}
          email={userEmail}
          isSSOCallback={isSSOCallback} /* this.state.isSSORedirectPage << TODO: << */
          onCancel={onCancel}
        />
      )}
      {!isLoadingConfig && ssoServiceConfig && ssoServiceType === AuthLoginServiceSSOType.SSOAuth0 && (
        <Auth0SSOLoginView
          email={userEmail}
          isSSOCallback={isSSOCallback} /* this.state.isSSORedirectPage << TODO: << */
          onCancel={onCancel}
        />
      )}
    </>
  )
}

export default LoginSSOView
