import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

import { CompanyAdminContext } from 'src/core/providers'
import { Company, CompanyUser, UserCompanyRole } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkPanel from 'src/core/components/ArkPanel'

import { OBJECT_COMPANY_NAME, OBJECT_USER_NAME, ROLE_ADMIN_NAME } from 'src/constants/strings'

import styles from './CompanyUserSidebar.module.css'

const formSchema = yup.object().shape({
  admin: yup.boolean().optional().label(OBJECT_COMPANY_NAME + ' ' + ROLE_ADMIN_NAME)
})

interface IProps {
  company: Company
  user: CompanyUser
  onChange?: Function
}

const CompanyUserRolePanel = (props: IProps) => {
  const mounted = useRef(false)

  const { company, user, onChange } = props

  const companyAdminContext = useContext(CompanyAdminContext)

  const [showForm, setShowForm] = useState<boolean>(false)
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSaved, setHasSaved] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const reset = () => {
    setShowForm(false)
    setHasChanges(false)
    setIsSubmitting(false)
    setHasSaved(false)
    setError(undefined)
  }

  // -------

  useEffect(() => {
    reset()
  }, [user?.id])

  // -------

  const onShowForm = () => {
    setShowForm(true)
    setError(undefined)
  }

  // NB: not currently used/needed?
  // const onHideForm = () => {
  //   setShowForm(false)
  //   setHasChanges(false)
  //   setError(undefined)
  // }

  // -------

  const updateCompanyUserRole = async (admin: boolean) => {
    if (!user) { return } // NB: shouldn't be able to get here, so not showing an error if it happens
    try {
      const companyRole = admin ? UserCompanyRole.admin : UserCompanyRole.member
      const updateResult = await companyAdminContext.actions.updateUserCompanyRole(company.id, user.id, companyRole)
      console.log('CompanyUserRolePanel - updateCompanyUserRole - updateResult: ', updateResult)
      if (updateResult) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
        }
        if (onChange) onChange()
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('CompanyUserRolePanel - onFormSubmit - fieldValues: ', fieldValues)
    updateCompanyUserRole(fieldValues.admin ?? false)
  }

  const onValueChanged = (fieldKey: string, fieldValue: any, oldFieldValue: any) => {
    if (fieldKey === 'admin') {
      console.log('CompanyUserRolePanel - onValueChanged - fieldKey: ', fieldKey, ' fieldValue: ', fieldValue, ' oldFieldValue: ', oldFieldValue)
      const isAdmin = !!(user.companyRole && user.companyRole === UserCompanyRole.admin)
      const valueChanged = fieldValue !== isAdmin
      if ((valueChanged && !hasChanges) || (!valueChanged && hasChanges)) {
        setHasChanges(valueChanged)
      }
    }
  }

  const onCancel = () => {
    reset()
  }

  const onClose = () => {
    reset()
  }

  // -------

  if (!user) return (<></>)

  const isAdmin = !!(user.companyRole && user.companyRole === UserCompanyRole.admin)

  const formFields: Array<ArkFormField> = []
  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'companyUserRole',
    // label: 'Edit Company User Role',
    fields: [
      { type: ArkFormFieldType.FormErrorPlaceholder, key: 'formError' },
      {
        type: ArkFormFieldType.Group,
        key: 'detailsGroup',
        fields: [
          { type: ArkFormFieldType.Checkbox, key: 'admin', label: ROLE_ADMIN_NAME, required: false, defaultValue: isAdmin }
        ]
      },
      {
        type: ArkFormFieldType.Group,
        key: 'buttons',
        fields: [
          { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { onClick: onCancel, floated: 'left' } },
          { type: ArkFormFieldType.OKButton, key: 'submit', label: 'SAVE', disabled: !hasChanges, disabledTooltip: 'No Changes to Save', fieldProps: { loading: isSubmitting, floated: 'right' } }
        ],
        fieldProps: { widths: 'equal' },
        slimline: true
      }
    ],
    collapsible: false,
    collapsed: false,
    slimline: true,
    bordered: false
  })

  return (
    <ArkPanel bordered={showForm} title={showForm ? OBJECT_USER_NAME + ' ' + OBJECT_COMPANY_NAME + ' ROLE' : null}>

      {hasSaved && (
        <div className={styles.roleUpdated}>
          <ArkMessage positive>
            <ArkMessage.Header>{OBJECT_USER_NAME + ' ' + OBJECT_COMPANY_NAME} role updated</ArkMessage.Header>
            <ArkMessage.Item>The {OBJECT_USER_NAME + ' ' + OBJECT_COMPANY_NAME} role has been updated</ArkMessage.Item>
          </ArkMessage>
          <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={onClose} style={{ marginTop: 15 }}>
            OK
          </ArkButton>
        </div>
      )}

      {!showForm && (
        <ArkButton type="button" positive fluid size="large" onClick={onShowForm}>EDIT {OBJECT_USER_NAME + ' ' + OBJECT_COMPANY_NAME} ROLE</ArkButton>
      )}

      {showForm && !hasSaved && (
        <ArkForm
          formKey="companyUserRoleForm"
          formError={error}
          formFields={formFields}
          formSchema={formSchema}
          onFormSubmit={onFormSubmit}
          onValueChanged={onValueChanged}
          showLabels={true}
        />
      )}

    </ArkPanel>
  )
}

export default CompanyUserRolePanel
