export type OnlineStatus =
  | 'online' // channel: all programs are online
  | 'partial' // channel: some programs are offline
  | 'offline' // channel: all programs are offline
  | 'unknown'

export type ProjectStatusChannel = {
  id: number, // channel_id
  online: 'online' | 'partial' | 'offline'
}

export type ProjectStatusProgram = {
  id: number, // program_id
  online: 'online' | 'offline'
}

export type ProjectStatus = {
  id: number // project_id
  poll_interval: number // ms
  channels: ProjectStatusChannel[]
  programs: ProjectStatusProgram[]
}

export const DEFAULT_PROJECT_STATUS: ProjectStatus = {
  id: 0,
  poll_interval: 0,
  channels: [],
  programs: []
}
