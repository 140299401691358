import React, { useEffect, useState } from 'react'

import { CompanyLoginServiceConfigOptionsOktaOIDC } from 'src/core/models'

import ArkConfirmModal, { ArkConfirmModalMode } from 'src/core/components/ArkConfirmModal'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkHint, { HintType, PopupPosition, PopupSize } from 'src/core/components/ArkHint'
import ArkMessage from 'src/core/components/ArkMessage'

import { OBJECT_COMPANY_NAME } from 'src/constants/strings'

import styles from './CompanyLoginServiceConfigForm.module.css'

export interface CompanyLoginServiceConfigFormOktaOIDCProps {
  configOptions?: CompanyLoginServiceConfigOptionsOktaOIDC
  isEnabling?: boolean // if the login service already exists but is being re-enabled (after previously being disabled since its initial creation)
  isDisabled?: boolean
  onCancel: Function
  onConfirm: (configOptions: CompanyLoginServiceConfigOptionsOktaOIDC) => void
  onDelete?: Function
}

const CompanyLoginServiceConfigFormOktaOIDC = (props: CompanyLoginServiceConfigFormOktaOIDCProps) => {
  const { configOptions, isEnabling, isDisabled, onCancel: _onCancel, onConfirm: _onConfirm /*, onClose: _onClose */, onDelete: _onDelete } = props

  const [configOptionValues, setConfigOptionValues] = useState<CompanyLoginServiceConfigOptionsOktaOIDC>(configOptions ?? {
    domain: '',
    accessToken: '',
    audience: '',
    issuer: '',
    clientId: '',
    nativeClientId: '',
    nativeRedirectURI: ''
  })
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false)
  const [showDisableBeforeDeleteModal, setShowDisableBeforeDeleteModal] = useState<boolean>(false)

  // -------

  const configOptionChanges = () => {
    const _changes: Array<string> = []
    if (configOptionValues) {
      for (const fieldName of Object.keys(configOptionValues)) {
        const oldValue = configOptions !== undefined ? (configOptions as any)[fieldName] : undefined
        const newValue = (configOptionValues as any)[fieldName]
        if (oldValue !== newValue && !(oldValue === undefined && newValue === '')) {
          _changes.push(fieldName)
        }
      }
    }
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = configOptionChanges()
    setChanges(_changes)
  }, [configOptionValues])

  // -------

  const onValueChanged = (fieldKey: string, fieldValue: any, _oldFieldValue: any) => {
    // console.log('CompanyLoginServiceConfigFormOktaOIDC - onValueChanged - fieldKey:', fieldKey, ' fieldValue:', fieldValue)
    setConfigOptionValues({ ...configOptionValues, [fieldKey]: fieldValue })
  }

  const onCancel = () => {
    console.log('CompanyLoginServiceConfigFormOktaOIDC - onCancel')
    if (_onCancel) _onCancel()
  }

  const onConfirm = (fieldValues: ArkFormFieldValues) => {
    console.log('CompanyLoginServiceConfigFormOktaOIDC - onConfirm - fieldValues:', fieldValues)
    if (_onConfirm) _onConfirm(fieldValues as CompanyLoginServiceConfigOptionsOktaOIDC)
  }

  const onDelete = () => {
    console.log('CompanyLoginServiceConfigFormOktaOIDC - onDelete - configOptions:', configOptions, ' isEnabling:', isEnabling, ' isDisabled:', isDisabled)
    // don't allow deletion if its currently enabled, prompt to disable it first
    // NB: `isDisabled` will be false if its disabled but currently being re-enabled, so we also check for `isEnabling` to allow deletion in that case
    if (!isDisabled && !isEnabling) {
      console.log('CompanyLoginServiceConfigFormOktaOIDC - onDelete - PROMPT TO DISABLE FIRST <<<<')
      setShowDisableBeforeDeleteModal(true)
      return
    }
    setShowConfirmDeleteModal(true)
  }
  const onDeleteConfirm = () => {
    console.log('CompanyLoginServiceConfigFormOktaOIDC - onDeleteConfirm')
    if (_onDelete) _onDelete()
  }

  // const onClose = () => {
  //   console.log('CompanyLoginServiceConfigFormOktaOIDC - onClose')
  //   if (_onClose) _onClose()
  // }

  // -------

  const hintElement = (hintType: HintType, iconSize: number, popupSize: PopupSize, title: string, message: string | React.ReactNode, popupPosition?: PopupPosition, disabled?: boolean, otherProps?: {[key: string] : any}) => {
    return (
      <ArkHint
        className={styles.programHint}
        type={hintType}
        iconSize={iconSize}
        popupSize={popupSize}
        title={title}
        message={message}
        popupPosition={popupPosition}
        disabled={disabled}
        // open={true} // DEBUG ONLY
        {...otherProps}
      ></ArkHint>
    )
  }

  const infoHintElement = (title: string, message: string | React.ReactNode, disabled?: boolean, otherProps?: {[key: string] : any}) => {
    return hintElement(
      'info-circle',
      22,
      'small',
      title,
      message,
      undefined, // 'bottom left', // NB: leave position undefined for auto positioning to kick in
      disabled,
      otherProps
    )
  }

  // -------

  const showEnablingNotice = isEnabling
  const showDisabledWarning = isDisabled && !isEnabling

  const formFields: Array<ArkFormField> = []
  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'detailsFieldset',
    // label: OBJECT_PROGRAM_NAME + ' details',
    // className: styles.detailsFieldset,
    fields: [
      ...(showEnablingNotice
        ? [
          {
            type: ArkFormFieldType.Field,
            key: 'enablingNoticeLabel',
            content: (
              <ArkMessage warning visible>
                <ArkMessage.Header>ENABLE OKTA?</ArkMessage.Header>
                <div>
                  <p>
                    Confirm your Okta login service details below before enabling.
                  </p>
                </div>
              </ArkMessage>
            )
          }
        ]
        : []),
      ...(showDisabledWarning
        ? [
          {
            type: ArkFormFieldType.Field,
            key: 'disabledWarningLabel',
            content: (
              <ArkMessage warning visible>
                <ArkMessage.Header>OKTA DISABLED</ArkMessage.Header>
                <div>
                  <p>
                    This Okta login service is currently disabled.<br />
                    You can edit the settings below while its disabled.<br />
                    Enable it using the Okta toggle onm the settings page.
                  </p>
                </div>
              </ArkMessage>
            )
          }
        ]
        : []),
      {
        type: ArkFormFieldType.Input,
        key: 'domain',
        label: 'Okta Domain',
        placeholder: 'e.g. "dev-123456.okta.com"',
        required: true,
        defaultValue: configOptions?.domain ?? '',
        className: hasChanges('domain') ? styles.hasChanged : undefined,
        hint: infoHintElement(
          'Okta Domain:',
          <>This is found when you are signed into your Okta dashboard. Depdending on your set up it will either look something like &quot;dev-123456.okta.com&quot; or &quot;dev-123456-admin.okta.com&quot;. If you have a custom domain, it may be something like &quot;mycompany-admin.okta.com&quot;. You can use what you see in the URL address bar after the &quot;https://&quot; and up to and including the &quot;.com&quot;. If you are unsure you can view our <a href="https://docs.reprostream.com/security/okta-integration" target="_blank" rel="noreferrer">Okta Integration Guide</a> documentation or contact your Okta administrator.</>
        )
      },
      {
        type: ArkFormFieldType.Input,
        key: 'accessToken',
        label: 'API Token',
        placeholder: '',
        required: true,
        defaultValue: configOptions?.accessToken ?? '',
        className: hasChanges('accessToken') ? styles.hasChanged : undefined,
        hint: infoHintElement(
          'API Token:',
          <>This is found in your okta dashboard under &quot;Security&quot; → &quot;API&quot; → &quot;Tokens&quot;. If you do not have a token, you can use the &quot;default&quot; token or create a new one. If you are unsure you can view our <a href="https://docs.reprostream.com/security/okta-integration" target="_blank" rel="noreferrer">Okta Integration Guide</a> documentation or contact your Okta administrator.</>
        )
      },
      {
        type: ArkFormFieldType.Input,
        key: 'audience',
        label: 'API Authorisation Server Audience',
        placeholder: 'e.g. "api://default"',
        required: true,
        defaultValue: configOptions?.audience ?? '',
        className: hasChanges('audience') ? styles.hasChanged : undefined,
        hint: infoHintElement(
          'API Authorisation Server Audience:',
          <>This is found in your Okta dashboard under &quot;Security&quot; → &quot;API&quot; on the &quot;Authorization Servers&quot; tab and is in the &quot;Audience&quot; column - the default looks something like &quot;api://default&quot;. Please note this is not to be confused with &quot;Audience&quot; token in your Okta Application&apos;s page&apos;s &quot;Sign On&quot; tab. If you are unsure you can view our <a href="https://docs.reprostream.com/security/okta-integration" target="_blank" rel="noreferrer">Okta Integration Guide</a> documentation or contact your Okta administrator.</>
        )
      },
      {
        type: ArkFormFieldType.Input,
        key: 'issuer',
        label: 'API Authorisation Server Issuer',
        placeholder: 'e.g. "https://dev-123456.okta.com/oauth2/default"',
        required: true,
        defaultValue: configOptions?.issuer ?? '',
        className: hasChanges('issuer') ? styles.hasChanged : undefined,
        hint: infoHintElement(
          'API Authorisation Server Issuer:',
          <>This is found next to the above value &quot;Audience&quot; in your Okta dashboard under &quot;Security&quot; → &quot;API&quot; on the &quot;Authorization Servers&quot; tab and is in the &quot;Issuer&quot; column. It can look something like &quot;https://dev-123456.okta.com/oauth2/default&quot; or &quot;https://subdomain.mydomain.com/oauth2/abcdcef123456&quot; if you have a custom domain set up. If you are unsure you can view our <a href="https://docs.reprostream.com/security/okta-integration" target="_blank" rel="noreferrer">Okta Integration Guide</a> documentation or contact your Okta administrator.</>
        )
      },
      {
        type: ArkFormFieldType.Input,
        key: 'clientId',
        label: 'Application Client ID',
        placeholder: '',
        required: true,
        defaultValue: configOptions?.clientId ?? '',
        className: hasChanges('clientId') ? styles.hasChanged : undefined,
        hint: infoHintElement(
          'Application Client ID:',
          <>This is found in your Okta dashboard under &quot;Applications&quot; → &quot;Applications&quot; then look for the &quot;Client ID:&quot; under your application name in the list. If you are unsure you can view our <a href="https://docs.reprostream.com/security/okta-integration" target="_blank" rel="noreferrer">Okta Integration Guide</a> documentation or contact your Okta administrator.</>
        )
      },
      {
        type: ArkFormFieldType.Input,
        key: 'nativeClientId',
        label: 'Native (Mobile) Application Client ID',
        placeholder: '',
        required: false,
        defaultValue: configOptions?.nativeClientId ?? '',
        className: hasChanges('nativeClientId') ? styles.hasChanged : undefined,
        hint: infoHintElement(
          'Native (Mobile) Application Client ID:',
          <>This is found in your Okta dashboard under &quot;Applications&quot; → &quot;Applications&quot; then look for the &quot;Client ID:&quot; under your mobile application name in the list. If you are unsure you can view our <a href="https://docs.reprostream.com/security/okta-integration" target="_blank" rel="noreferrer">Okta Integration Guide</a> documentation or contact your Okta administrator.</>
        )
      },
      {
        type: ArkFormFieldType.Input,
        key: 'nativeRedirectURI',
        label: 'Native (Mobile) Redirect URI',
        placeholder: '',
        required: false,
        defaultValue: configOptions?.nativeRedirectURI ?? '',
        className: hasChanges('nativeRedirectURI') ? styles.hasChanged : undefined,
        hint: infoHintElement(
          'Native (Mobile) Redirect URI:',
          <>This is found in your Okta dashboard under &quot;Applications&quot; → &quot;Applications&quot; then look for the &quot;Sign-in redirect URIs:&quot; under your mobile application name in the list. If you are unsure you can view our <a href="https://docs.reprostream.com/security/okta-integration" target="_blank" rel="noreferrer">Okta Integration Guide</a> documentation or contact your Okta administrator.</>
        )
      }
    ],
    collapsible: false,
    collapsed: false
  })
  const isCreating = configOptions === undefined
  const showDeleteButton = configOptions !== undefined
  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      {
        type: ArkFormFieldType.CancelButton,
        key: 'cancel',
        label: 'CANCEL',
        className: styles.cancelButton,
        fieldProps: { onClick: onCancel, fluid: false, style: { width: 120 } }
      },
      {
        type: ArkFormFieldType.DeleteButton,
        key: 'delete',
        label: 'DELETE',
        className: styles.deleteButton,
        fieldProps: { onClick: onDelete, fluid: false, style: { width: 120, display: (!showDeleteButton ? 'none' : 'block') } },
        disabled: !showDeleteButton
      },
      {
        type: ArkFormFieldType.OKButton,
        key: 'submit',
        label: (isCreating ? 'SET UP' : (isEnabling ? 'ENABLE' : 'UPDATE')),
        className: styles.okButton,
        fieldProps: {
          /* loading: isSubmitting, */
          floated: 'right',
          fluid: false,
          style: { width: 120 }
          // ...(mode === ArkConfirmModalMode.warning && { color: 'orange', basic: false })
        },
        disabled: changes.length === 0 && (isEnabling === undefined || isEnabling === false),
        disabledTooltip: 'No Changes to Save'
      }
    ],
    fieldProps: { widths: 'equal' /* widths: 1, floated: 'right' */, style: { border: '1px solid red !important' } },
    slimline: true
  })

  // -------

  const renderConfirmDeleteModal = () => {
    return (
      <ArkConfirmModal
        show={showConfirmDeleteModal}
        title={<>Delete Okta SSO Config?</>}
        message={<>
          <p>Are you sure you want to delete all Okta SSO config settings?</p>
        </>}
        onCancel={() => setShowConfirmDeleteModal(false)}
        onClose={() => setShowConfirmDeleteModal(false)}
        onConfirm={() => {
          setShowConfirmDeleteModal(false)
          onDeleteConfirm()
        }}
      />
    )
  }

  const renderDisableBeforeDeleteModal = () => {
    return (
      <ArkConfirmModal
        show={showDisableBeforeDeleteModal}
        title={<>Disable Okta SSO before deleting</>}
        message={<>
          <p>To delete the Okta SSO config you must disable the Okta toggle on the settings page first.</p>
        </>}
        mode={ArkConfirmModalMode.warning}
        hideCancelButton={true}
        // onCancel={() => setShowDisableBeforeDeleteModal(false)}
        onClose={() => setShowDisableBeforeDeleteModal(false)}
        onConfirm={() => {
          setShowDisableBeforeDeleteModal(false)
        }}
      />
    )
  }

  return (
    <>
      <div>
        <ArkHeader as='h2' inverted>{OBJECT_COMPANY_NAME} Okta SSO Config - OpenID</ArkHeader>
        <div>Enter the OpenID config details for your Okta SSO account.</div>
      </div>
      <ArkForm
        formKey="modalConfirm" // WARNING: there should never be more than 1 of these on the page at a time? (or we should require a unique key & append/use it here to work around it, so the form handling works as expected? (enter key handling))
        inverted
        formFields={formFields}
        // formError={error}
        onFormSubmit={onConfirm}
        onValueChanged={onValueChanged}
        autoComplete="off"
      ></ArkForm>
      {renderConfirmDeleteModal()}
      {renderDisableBeforeDeleteModal()}
    </>
  )
}

export { CompanyLoginServiceConfigFormOktaOIDC }
