export class BaseModel {
  // TESTING: convert objects to maps (if the keys are all strings), needed as json decoding seems to return an Object not a Map?
  // ref: https://2ality.com/2015/08/es6-map-json.html
  static objToStrMap (obj: any) {
    if (obj === undefined) { return obj }
    const strMap = new Map()
    for (const k of Object.keys(obj)) {
      strMap.set(k, obj[k])
    }
    return strMap
  }

  // TESTING: support additonal TS object types with JSON stringify & parse
  // ref: https://stackoverflow.com/a/56150320
  replacer (key: any, value: any) {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()) // or with spread: value: [...value]
      }
    } else {
      return value
    }
  }

  static reviver (key: string, value: any) {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value)
      }
    }
    return value
  }
}
