import React, { useContext } from 'react'

import ArkLargeButton, { ArkLargeButtonGroup } from '../../components/ArkLargeButton'
import { NavSection, SectionSwitcherContext } from '../../providers'

import { OBJECT_PROJECT_NAME, SECTION_ADMIN_NAME, SECTION_COMPANY_NAME, SECTION_MANAGER_SUFFIX_NAME, SECTION_VIEWER_NAME } from 'src/constants/strings'

import styles from './ArkSectionSwitcherLargeView.module.css'

const ArkSectionSwitcherLargeView = () => {
  const { actions: sectionActions, store: sectionsStore } = useContext(SectionSwitcherContext)
  const { showSiteAdminLink, showCompanyAdminLink, showProjectManagerLink, showProjectViewerLink } = sectionsStore
  return (
    <div className={styles.view}>
      <ArkLargeButtonGroup>
        <ArkLargeButton
          iconName='admin'
          onClick={() => sectionActions.gotoSection(NavSection.admin)}
          show={showSiteAdminLink}
          title={SECTION_ADMIN_NAME + ' TOOLS'}
        />
        <ArkLargeButton
          iconName='company'
          onClick={() => sectionActions.gotoSection(NavSection.company)}
          show={showCompanyAdminLink}
          title={SECTION_COMPANY_NAME + ' ' + SECTION_MANAGER_SUFFIX_NAME}
        />
        <ArkLargeButton
          iconName='project'
          onClick={() => sectionActions.gotoSection(NavSection.project)}
          show={showProjectManagerLink}
          title={OBJECT_PROJECT_NAME + ' ' + SECTION_MANAGER_SUFFIX_NAME}
        />
        <ArkLargeButton
          iconName='viewer'
          onClick={() => sectionActions.gotoSection(NavSection.viewer)}
          show={showProjectViewerLink}
          title={'OPEN ' + SECTION_VIEWER_NAME}
        />
      </ArkLargeButtonGroup>
    </div>
  )
}

export default ArkSectionSwitcherLargeView
