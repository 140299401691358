import React from 'react'

import ArkAdminPage from '../../components/ArkAdminPage/ArkAdminPage'

interface IProps {}
interface IState {}

class AdminHomePage extends React.Component<IProps, IState> {
  render () {
    return (<ArkAdminPage>
      <div className="admin-home">
        ADMIN
      </div>
    </ArkAdminPage>)
  }
}
export default AdminHomePage
