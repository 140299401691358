import React from 'react'

import ArkProjectManagerPage from '../../components/ArkProjectManagerPage/ArkProjectManagerPage'
import ArkInactiveOrgView from 'src/core/components/ArkInactiveOrgView'

class ProjectManagerInactiveOrgPage extends React.Component {
  render () {
    return (
      <ArkProjectManagerPage disableSectionMainMenu>
        <ArkInactiveOrgView />
      </ArkProjectManagerPage>)
  }
}
export default ProjectManagerInactiveOrgPage
